import { withCloseAfterExited } from "@10duke/dukeui";
import {
  default as View,
  RevokeInvitationModalProps
} from "./revoke-invitation-modal-view";

const RevokeInvitationModal = withCloseAfterExited<RevokeInvitationModalProps>(
  View
);
export default RevokeInvitationModal;
