import { useState } from "react";
import {FeedbackContainerProps,withFeedback} from "@10duke/dukeui";
import DeviceClientsView, {
  DeviceClientsProps as _DeviceClientsProps,
  DeviceClientsStateProps,
  DeviceClientsDOMProps as _DeviceClientsDOMProps,
  DeviceClientsModalVisibilityProps as _DeviceClientsModalVisibilityProps,
} from "./device-clients-view";
import {Client} from "../../../model/Client";
export { ModalKeys } from "./device-clients-view";

export type DeviceClientsDOMProps = _DeviceClientsDOMProps;
export type DeviceClientsModalVisibilityProps = _DeviceClientsModalVisibilityProps;

export interface DeviceClientsProps extends Omit<_DeviceClientsProps, keyof DeviceClientsStateProps> {}

function DeviceClients(props: DeviceClientsProps & FeedbackContainerProps) {
  const [selected, setSelected] = useState([] as Client[]);
  const [activeSearch, onSetActiveSearch] = useState("");
  const viewProps: _DeviceClientsProps = {
    selected: selected,
    activeSearch: activeSearch,
    onSetActiveSearch: onSetActiveSearch,
    onSetSelected: setSelected,
    ...props,
  };
  return <DeviceClientsView {...viewProps} />;
}
export default withFeedback<DeviceClientsProps & FeedbackContainerProps>(DeviceClients);
