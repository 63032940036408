import { connect } from "react-redux";
import OrganizationUtils from "../../../utils/organization";
import {
  UnassignAdminModalDataProps,
  UnassignAdminModalProps as _UnassignAdminModalProps,
} from "./unassign-admin-modal-view";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import {
  clearError,
  getUser,
  listOrganizationRoles,
  removeUserFromOrgRole,
} from "../../../actions";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";
import UnassignAdminModal from "./unassign-admin-modal-container";
import InProgressUtils from "../../../utils/in-progress";
import UserUtils, { UserWithStatus } from "../../../utils/user";
import AuthenticationUtils from "../../../utils/authentication";

export interface UnassignAdminModalProps
  extends Omit<_UnassignAdminModalProps, keyof UnassignAdminModalDataProps> {
  // for some reason in this case the omit does not remove all keys with keyof  RemoveGroupModalDataProps like it does
  // elsewhere. So far I have not been able to figure out a way to inspect/debug what is actually there to be removed.
}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _UnassignAdminModalProps,
  UnassignAdminModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _UnassignAdminModalProps,
  UnassignAdminModalProps
>;

const sender: ActionSender = { key: "unassign-admin" };

function mapStateToProps(
  state: AppState,
  ownProps: UnassignAdminModalProps
): ReduxStateProps {
  const user = ownProps.userId
    ? OrganizationUtils.selectOrganizationUser(ownProps.userId, state)
    : undefined;
  const orgAdmins = OrganizationUtils.selectOrganizationAdminIds(state, false);
  let userWithExtra: UserWithStatus | undefined = undefined;
  if (user && orgAdmins) {
    userWithExtra = UserUtils.userWithStatus({
      ...user,
      isOrgAdmin: orgAdmins.includes(user.id as string),
    } as any);
  }
  return {
    actorId: AuthenticationUtils.selectAuthenticationUserId(state) as string,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    orgAdminRoleId: OrganizationUtils.selectOrgAdminRoleId(state),
    user: userWithExtra,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    AuthenticationUtils.compareAuthenticationUserIdStates(next, prev) &&
    OrganizationUtils.compareOrganizationUsersStates(next, prev) &&
    OrganizationUtils.compareOrganizationRolesStates(next, prev) &&
    OrganizationUtils.compareOrganizationRoleUserIdsStates(next, prev)
  );
}
const dispatchActions: ReduxDispatchProps = {
  onLoadOrgadminRole: addSenderArgument(sender, listOrganizationRoles),
  onUnassignAdmin: addSenderArgument(sender, removeUserFromOrgRole),
  onLoadUser: addSenderArgument(sender, getUser),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  UnassignAdminModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(UnassignAdminModal);
