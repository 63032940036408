import RolesComp, { ModalKeys } from "../../components/pages/roles";
import { useParams, useHistory, useLocation } from "react-router-dom";
export { ModalKeys } from "../../components/pages/roles";
function Roles() {
  const history = useHistory();
  const location = useLocation();
  let { modal, roleId, secondaryId } = useParams<any>();
  const resolveBase = () => {
    let base: string = location.pathname;
    if (modal) {
      base = base.replace(`/${modal}`, "");
    }
    if (roleId) {
      base = base.replace(`/${roleId}`, "");
    }
    if (secondaryId) {
      base = base.replace(`/${secondaryId}`, "");
    }

    if (base.endsWith("/")) {
      base = base.substr(0, base.length - 1);
    }
    return base;
  };
  const onShowModal = (key: ModalKeys, rlId?: string, secId?: string) => {
    let path: string[] = [resolveBase()];
    if (rlId) {
      path.push(rlId);
    }
    path.push(key);
    if (secId) {
      path.push(secId);
    }
    history.push(path.join("/"));
  };

  const onHideModal = (callback?: () => void) => {
    history.push(resolveBase());

    if (callback && typeof callback === "function") {
      callback();
    }
  };
  return (
    <RolesComp
      showModal={{ key: modal as ModalKeys, roleId, secondaryId }}
      onShowModal={onShowModal}
      onHideModal={onHideModal}
    />
  );
}
export default Roles;
