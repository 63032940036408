import React, {useEffect, HTMLAttributes, useContext} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {IconLibrary, TooltipWrapper, FeedbackEntry, Feedback, ConfirmModal, Button} from "@10duke/dukeui";
import { UserWithLicenseUsage } from "../../../model/User";
import UserUtils from "../../../utils/user";
import { License } from "../../../model/entitlement/License";
import LicenseUtils from "../../../utils/licensed-item";
import Table, { TableColumn } from "../../table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as ActionTypes from "../../../actions/actionTypes";
import LicenseValidityBadge, {
  resolveLicenseValidity,
} from "../../badges/license-validity-badge";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import { ModalKeys } from "../../pages/users";
import UserStatusBadge from "../../badges/user-status-badge";
import { ClosableModalProps } from "../closable-modal-props";
import NavigateAfterAction from "../../navigate-after-action";
import { UserWithStatusValues } from "../../../localization/user";
import {LicenseUsageByUsersLabels } from "../../../localization/license-usage-by-users";
import { LicenseValues } from "../../../localization/license";
import "./license-usage-modal-view.scss";
import { LicenseConsumptionLabels } from "../../../localization/license-consumption";
import {
  UserLicensedItemAssignmentsLabels,
  UserLicensedItemAssignmentsValues,
} from "../../../localization/user-licensed-item-assignments";
import { UserLicenseAssignmentRequestErrors } from "../../../localization/user-license-assignment-request/";
import {ReleaseLicenseLeaseAction} from "../../../actions/actionTypes";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import {LicenseAssignmentWithSessions} from "../../../model/entitlement/LicenseAssignmentWithSessions";
import {ClientWithLicenseUsage} from "../../../model/Client";
import {TableFilter} from "../../table/table-container";
import {TableFilterViewProps} from "../../table/table-filter/table-filter-view";

//<editor-fold desc="Props">

export function isClient(c: UserWithLicenseUsage|ClientWithLicenseUsage) {
  if((c as ClientWithLicenseUsage).type) {
    return true
  }
  return false
}

/**
 * Resolves user name to display. Not sure how its possible that the user object is null and considered a client
 * despite the sanity checks.
 * @param user
 * @param intl
 */
const resolveUserName = (
  user: UserWithLicenseUsage | ClientWithLicenseUsage | undefined,
  intl: { formatMessage: (v: any, v2?: any) => string }
) => !!user && !isClient(user) ?
  UserUtils.resolveDisplayName(
    user,
    intl.formatMessage(UserWithStatusValues.displayName.undefined)
  ) : (user as ClientWithLicenseUsage)?.name;

export interface LicenseUsageModalStateProps {
  onSetActiveSearch: (s: string) => void;
  activeSearch?: string;
  onToggleReservation: (id: string) => void;
  toUpdate: any[];

  onToggleDenial: (id: string) => void;
  availableSeats: number|undefined;
  onAddLeaseToBeReleased: (release: {id:string, assignment: LicenseAssignmentWithSessions}) => void;
  onRemoveLeaseToBeReleased: (release: {id:string, assignment: LicenseAssignmentWithSessions}) => void;
  releaseLeases: {id:string, assignment: LicenseAssignmentWithSessions}[];
}
export interface LicenseUsageModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface LicenseUsageModalInputProps {
  enableUsers:boolean;
  enableClients:boolean;
}
export interface LicenseUsageModalAdminProps {
  adminsLoaded: boolean;
  onLoadAdmins: () => void;
}
export interface LicenseUsageModalLicenseIdProps {
  licenseId?: string | undefined;
}
export interface LicenseUsageModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {
  onShowFeedback: (feedback: FeedbackEntry | FeedbackEntry[]) => void;
}
export interface LicenseUsageModalDataProps
  extends LicenseUsageModalAdminProps,
    Pick<ClosableModalProps, "isReady"> {
  license?: License | undefined | null;
  onLoadLicense?: (id: string) => void;
  onApplyUsers?: (
      licenseId: string,
      usrs: UserWithLicenseUsage[] | undefined
  ) => Promise<ActionTypes.MultiAction | undefined>;
  onApplyClients?: (
      licenseId: string,
      usrs: UserWithLicenseUsage[] | undefined
  ) => Promise<ActionTypes.MultiAction | undefined>;

  users?: (UserWithLicenseUsage|ClientWithLicenseUsage)[] | null;
  onLoadUsers?: (licenseId: string, entId: string, includeClients: boolean) => void;
  isReady: boolean;
  onReleaseLicenseLease: (release: { id: string, assignment?: LicenseAssignmentWithSessions }[]) => Promise<ReleaseLicenseLeaseAction>;
}
export interface LicenseUsageModalProps
  extends LicenseUsageModalDOMProps,
    LicenseUsageModalLicenseIdProps,
    LicenseUsageModalVisibilityProps,
    LicenseUsageModalStateProps,
      LicenseUsageModalInputProps,
    LicenseUsageModalDataProps {}
//</editor-fold>

function LicenseUsageModal(props: LicenseUsageModalProps) {
  //<editor-fold desc="Local variables">
  let {
    show,
    onClose,
    onShowFeedback,
    toUpdate,
    onToggleReservation,
    onToggleDenial,
    onSetActiveSearch,
    activeSearch,
    license,
    licenseId,
    onLoadLicense,
    onLoadUsers,
    onApplyUsers,
    onApplyClients,
    users,
    adminsLoaded,
    onLoadAdmins,
    availableSeats,
    isReady,
    onExited,
    onReleaseLicenseLease,
    onAddLeaseToBeReleased,
    onRemoveLeaseToBeReleased,
      enableUsers,
      enableClients,
    releaseLeases,
  } = props;

  const { conf } = useContext(UIConfiguration);
  const toReserve = toUpdate.length
    ? toUpdate.filter((a) => a.addReservation).map((a) => a.id)
    : [];
  const toRelease = toUpdate.length
    ? toUpdate.filter((a) => a.removeReservation).map((a) => a.id)
    : [];
  const toDeny = toUpdate.length
    ? toUpdate.filter((a) => a.addDenial).map((a) => a.id)
    : [];
  const toAllow = toUpdate.length
    ? toUpdate.filter((a) => a.removeDenial).map((a) => a.id)
    : [];
  // this is more like a variable than a hook
  const intl = useIntl();

  if (show && license === undefined && !(licenseId || onLoadLicense)) {
    throw new Error(
      "LicenseUsageModal: Required props missing, either license or licenseId && onLoadLicense must be defined"
    );
  }
  if (show && (!onApplyUsers || !onApplyClients)) {
    throw new Error(
      "LicenseUsageModal: Required props missing, onApplyUsers and onApplyClients must be defined"
    );
  }
  //</editor-fold>
  useEffect(() => {
    if (show && !adminsLoaded && onLoadAdmins) {
      onLoadAdmins();
    }
  }, [show, adminsLoaded, onLoadAdmins]);
  const licenseObjId = license ? license.id : undefined;
  const entId = license ? license.entitlementId : undefined;
  useEffect(() => {
    if (
      show &&
      adminsLoaded &&
      (licenseObjId === undefined ||
        (licenseObjId && licenseObjId !== licenseId)) &&
      licenseId &&
      onLoadLicense
    ) {
      onLoadLicense(licenseId);
    }
  }, [show, adminsLoaded, licenseObjId, licenseId, onLoadLicense]);
  useEffect(() => {
    if (
      show &&
      adminsLoaded &&
      !users &&
      onLoadUsers &&
      licenseObjId &&
      entId
    ) {
      onLoadUsers(
        licenseObjId,
        entId,
        enableClients
      );
    }
  }, [enableClients, show, adminsLoaded, users, onLoadUsers, licenseObjId, entId]);
  const hasSeats = (!license?.effectiveLicenseModel || !license?.effectiveLicenseModel.credits || license?.effectiveLicenseModel.credits?.type === "seatCount");
  const columns: TableColumn[] = [
    {
      key: "id",
      label: intl.formatMessage(LicenseUsageByUsersLabels.id),
      isTechnical: true,
      hidden: true,
    },
    {
      key: "name",
      isDummy: true,
      label: intl.formatMessage(LicenseUsageByUsersLabels.name),
      sortable: true,
      className: "name-column" + (hasSeats ? " with-seats" : ""),
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const t = props.rendererData.resolveValue(props.row);
        if (!isClient(props.row)) {
          return (
              <>
            <span key={"link_" + props.row.id} className={"link-holder"}>
              <NavigateAfterAction
                  href={"/users/" + props.row.id + "/" + ModalKeys.show}
                  action={onClose}
              >
                <strong>{t[0]}</strong>
                {t[1] && <small>{" <" + t[1] + ">"}</small>}
              </NavigateAfterAction>
            </span>
            <UserStatusBadge status={UserUtils.resolveUserStatus(props.row)} />
          </>
        );
        } else {
          return (
              <>
            <span key={"link_" + props.row.id} className={"link-holder"}>
              <NavigateAfterAction
                  href={"/device-clients/" + props.row.id + "/" + ModalKeys.show}
                  action={onClose}
              >
                <strong>{t[0]}</strong>
                {t[1] && <small>{" <" + t[1] + ">"}</small>}
              </NavigateAfterAction>
            </span>
              </>
          );
        }
      },
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const t = props.rendererData.resolveValue(props.row);
        return (
          <>
            {t[0]}{t[1] && <>, {t[1]}</>}
          </>
        );
      },
      rendererData: {
        resolveValue: (itm: any) => {
          const status = UserUtils.resolveUserStatus(itm);
          return [
            resolveUserName(itm, intl),
            itm.email,
            status
              ? intl.formatMessage((UserWithStatusValues.status as any)[status])
              : status,
          ];
        },
      },
    },
    {
      key: "hasReservation",
      hidden: !hasSeats,
      label: intl.formatMessage(LicenseUsageByUsersLabels.hasReservation),
      tooltip: intl.formatMessage({
        defaultMessage:
          "Reserving a license will guarantee the user will always have access to a license. Reserved licenses are not available to other users.",
        description: "Cell heading tooltip",
      }),
      sortable: true,
      align: "center",
      rendererData: {
        availableSeats: availableSeats,
        deactivated: license && license.active === false,
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return (
          <div
            className={
              !props.cell &&
              (props.rendererData.deactivated ||
              (props.rendererData.availableSeats !== undefined && props.rendererData.availableSeats <= 0))
                ? "tooltip-disabled-wrapper d-block"
                : "d-block"
            }
          >
            <Button
              data-test-reserve={!props.cell ? props.row.id : undefined}
              data-test-release={props.cell ? props.row.id : undefined}
              variant={props.cell ? "success" : "outline-secondary"}
              size={"sm"}
              className={"btn w-100"}
              disabled={
                !props.cell &&
                (props.rendererData.deactivated ||
                    (props.rendererData.availableSeats !== undefined && props.rendererData.availableSeats <= 0))
              }
              action={() => {
                onToggleReservation(props.row.id);
              }}
              icon={props.cell ? (
                  <FontAwesomeIcon icon={IconLibrary.icons.faCheck} />
              ) : undefined}
            >
                {props.cell
                  ? intl.formatMessage({
                      defaultMessage: "Release",
                      description:
                        "Button label for toggling license seat reservation",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Reserve",
                      description:
                        "Button label for toggling license seat reservation",
                    })}
            </Button>
          </div>
        );
      },
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return props.rendererData.deactivated ? (
          props.cell ? (
            <>
              <FormattedMessage
                defaultMessage="Releasing a license is not available as the license has been deactivated."
                description={"tooltip for disabled release license cell"}
              />
            </>
          ) : (
            <>
              <FormattedMessage
                defaultMessage="Reserving a license is not available as the license has been deactivated."
                description={"tooltip for disabled reserve license cell"}
              />
            </>
          )
        ) : undefined;
      },
    },
    {
      key: "hasDenial",
      tooltip: intl.formatMessage({
        defaultMessage: "Blocked users are not able to consume a license.",
        description: "Cell heading tooltip",
      }),
      label: intl.formatMessage(LicenseUsageByUsersLabels.hasDenial),
      sortable: true,
      align: "center",
      rendererData: {
        deactivated: license && license.active === false,
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return (
          <div
            className={
              props.rendererData.deactivated
                ? "tooltip-disabled-wrapper d-block"
                : "d-block"
            }
          >
            <Button
              data-test-deny={!props.cell ? props.row.id : undefined}
              data-test-allow={props.cell ? props.row.id : undefined}
              disabled={props.rendererData.deactivated}
              size={"sm"}
              variant={props.cell ? "danger" : "outline-secondary"}
              className={"btn w-100"}
              action={() => {
                onToggleDenial(props.row.id);
              }}
              icon={props.cell ? <FontAwesomeIcon icon={IconLibrary.icons.faBan} /> : undefined}
            >
              {props.cell
                  ? intl.formatMessage({
                      defaultMessage: "Release",
                      description:
                        "Button label for toggling license seat denial",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Block",
                      description:
                        "Button label for toggling license seat denial",
                    })}
            </Button>
          </div>
        );
      },
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return props.rendererData.deactivated ? (
          props.cell ? (
            <>
              <FormattedMessage
                defaultMessage="Releasing a blocked license is not available as the license has been deactivated."
                description={"tooltip for disabled deny license cell"}
              />
            </>
          ) : (
            <>
              <FormattedMessage
                defaultMessage="Blocking a license is not available as the license has been deactivated."
                description={"tooltip for disabled release denial license cell"}
              />
            </>
          )
        ) : undefined;
      },
    },
    {
      key: "isConsuming",
      sortable: true,
      isDummy: true,
      label: intl.formatMessage(UserLicensedItemAssignmentsLabels.isConsuming),
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const t = props.rendererData.resolveValue(props.row);
        return (
          <>
            {t.length ? (
              t.map((m: any) => {
                let from = m.from;
                if (from === "now()") {
                  from = intl.formatDate(new Date(), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                } else if (from) {
                  from = intl.formatDate(new Date(from), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                }
                let to = m.to;
                if (to === "now()") {
                  to = intl.formatDate(new Date(), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                } else if (to) {
                  to = intl.formatDate(new Date(to), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                }
                const isToBeRemoved = releaseLeases.find((f) => f.id === m.leaseId)
                return (
                    <div className={"usage-details" + (isToBeRemoved ? " to-be-removed" : "")}>
                    {conf.functionality?.enableReleaseLicenseLease && <Button
                          variant={"unstyled"}
                          className={"btn release"}
                          type={"button"}
                          disabled={isClient(props.row)}
                          action={() => {
                            if (isToBeRemoved) {
                              onRemoveLeaseToBeReleased({ id: m.leaseId, assignment: m.assignment });
                            } else {
                              onAddLeaseToBeReleased({ id: m.leaseId, assignment: m.assignment });
                            }
                          }}
                          tooltip={
                            !isClient(props.row) ? (
                                    isToBeRemoved ?
                                        <FormattedMessage
                                            defaultMessage="Cancel license lease removal"
                                            description={"tooltip for cancel remove license lease button"}
                                        />
                                        :
                                        <FormattedMessage
                                            defaultMessage="Remove license lease"
                                            description={"tooltip for remove license lease button"}
                                        />
                                ) :
                                <FormattedMessage
                                    defaultMessage="Removing Client license lease is not supported"
                                    description={"tooltip for remove client license lease not supported"}
                                />
                          }
                          icon={isToBeRemoved ? <FontAwesomeIcon icon={IconLibrary.icons.faBan} fixedWidth={true} /> :
                              <FontAwesomeIcon icon={IconLibrary.icons.faTrashAlt} fixedWidth={true} />}
                      />
                    }
                    <TooltipWrapper
                        tipKey={"details_" + m.leaseId}
                        placement={"auto-start"}
                        tip={
                          (
                              <div className="usage-details">
                          {m.id ? (
                                <div>
                                  <strong className="d-inline-block">
                                    {intl.formatMessage(
                                        LicenseConsumptionLabels.hardwareId
                                    )}
                                  </strong>
                                  <span>{m.id}</span>
                                </div>
                            ) : undefined}
                            {m.name ? (
                              <div>
                                <strong className="d-inline-block">
                                  {intl.formatMessage(
                                    LicenseConsumptionLabels.hardwareName
                                  )}
                                </strong>
                                <span className={"value"}>{m.name}</span>
                              </div>
                            ) : undefined}
                            <div>
                              <FormattedMessage
                                  defaultMessage="The license lease is valid from {from} until {to}"
                                  description={
                                    "Validity information for the license lease. from = formatted date with time for start of validity, to = formatted date with time for end of validity"
                                  }
                                  values={{
                                    from: from,
                                    to: to,
                                  }}
                              />
                            </div>
                        </div>)}
                    >
                      <div className={'usage-details-content'}>
                    {m.name ? (
                      <div>
                        <strong className="d-inline-block">
                          {intl.formatMessage(
                            LicenseConsumptionLabels.hardwareName
                          )}
                        </strong>
                        <span>{m.name}</span>
                      </div>
                    ) : undefined}
                    {m.id ? (
                    <div>
                      <strong className="d-inline-block">
                        {intl.formatMessage(
                          LicenseConsumptionLabels.hardwareId
                        )}
                      </strong>
                      <span>{m.id}</span>
                    </div>
                    ) : undefined}
                    <div>
                      <strong className="d-inline-block">
                        {intl.formatMessage(LicenseConsumptionLabels.from)}
                      </strong>
                      <span>{from}</span>
                    </div>
                    <div>
                      <strong className="d-inline-block">
                        {intl.formatMessage(LicenseConsumptionLabels.until)}
                      </strong>
                      <span>{to}</span>
                    </div>
                  </div>
                    </TooltipWrapper>
                  </div>
                );
              })
            ) : (
                <TooltipWrapper
                    tipKey={"not-used"}
                    placement={"auto-start"}
                    tip={<>
                      {intl.formatMessage(
                          UserLicensedItemAssignmentsValues.isConsuming.false
                      )}
                    </>}
                >
                  <div className="usage-details no-usage">
                    <FontAwesomeIcon
                        icon={IconLibrary.icons.faTimes}
                        className={"icon d-inline-block"}
                    />
                  </div>
                </TooltipWrapper>
            )}
            {conf.functionality?.enableReleaseLicenseLease && t.length > 1 && t.length !== releaseLeases.length ?
                  <Button
                      variant={"outline-danger"}
                      size={"sm"}
                      className={"release-all btn btn-block"}
                      type={"button"}
                      action={() => {
                        onAddLeaseToBeReleased(t.map((m: any) => {
                          return {id: m.leaseId, assignment: m.assignment};
                        }).filter((f: string) => !!f));
                      }}
                      tooltip={
                        <FormattedMessage
                            defaultMessage="Remove all license leases"
                            description={"tooltip for remove license lease button"}
                        />
                      }
                  >
                    <FormattedMessage
                        defaultMessage="Remove all"
                        description={"label for remove all license leases button"}
                    />
                  </Button>
                : undefined }
          </>
        );
      },
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return undefined;
      },
      rendererData: {
        resolveValue: (itm: UserWithLicenseUsage) => {
          // Excessive ? operator use is needed as it seems that the state can change while this is running
          return itm.assignments
              ?.map((a) => {
                return a?.sessions?.map((s) => {
                    const t = s?.anchors
                      ? s?.anchors.map((anchor, ind) => {
                          const leaseId = s?.leases && s?.leases.length ? (s?.leases[ind]?.id) : undefined;
                          return { name: anchor?.externalName, id: anchor?.externalId, leaseId, assignment: a };
                        })
                      : [{}];
                    return t.map((m, i) => {
                      return { from: s?.validFrom, to: s?.validUntil, ...m };
                    });
                  });

              })
              .flat(2)
              .filter((f) => !!f) || [];
        },
      },
    },
  ];
  let filters: TableFilter[]|undefined = undefined;
  if (conf.functionality?.licenses?.usageEnableUsers === true && conf.functionality?.licenses?.usageEnableClients === true) {
    filters = [{
      key: "hideClients",
      initial: false,
      view: (props: TableFilterViewProps) => <>{
        intl.formatMessage({
          defaultMessage: "Hide device client usage",
          description:
              "Label for filter toggle that controls visibility of device client usage",
        })
      }</>,
      apply: (itm: UserWithLicenseUsage | ClientWithLicenseUsage) => {
        return !isClient(itm);
      },
    }, {
      key: "hideUsers",
      initial: false,
      view: (props: TableFilterViewProps) => <>{
        intl.formatMessage({
          defaultMessage: "Hide user usage",
          description:
              "Label for filter toggle that controls visibility of user usage",
        })
      }</>,
      apply: (itm: UserWithLicenseUsage | ClientWithLicenseUsage) => {
        return isClient(itm);
      },
    }];
  }
  return (
    <ConfirmModal
      onExited={onExited}
      onReloadData={() => {
        if (licenseId && onLoadLicense) {
          onLoadLicense(licenseId);
        }
        if (licenseId && entId && onLoadUsers) {
          onLoadUsers(licenseId, entId, enableClients);
        }
        if (onLoadAdmins) {
          onLoadAdmins();
        }
      }}
      isReady={isReady}
      size={"lg"}
      id={"license-usage-modal"}
      skipConfirm={show && !license}
      data-test-license-usage-modal={license ? license.id : true}
      title={
        license
          ? intl.formatMessage(
              {
                defaultMessage: "{name}: usage",
                description:
                  "modal heading. 'name' = name of the licensed item",
              },
              {
                name: license.licensedItem
                  ? LicenseUtils.resolveDisplayName(
                      license.licensedItem,
                      intl.formatMessage(
                        LicenseValues.licensedItem.displayName.undefined
                      )
                    )
                  : intl.formatMessage(
                      LicenseValues.licensedItem.displayName.undefined
                    ),
              }
            )
          : intl.formatMessage({
              defaultMessage: "License not found",
              description: "modal heading when the license is not found",
            })
      }
      confirmTitle={
        license
          ? intl.formatMessage(
              {
                defaultMessage: "{name}: confirm changes",
                description:
                  "modal heading for confirming action, 'name' = name of the licensed item",
              },
              {
                name: license.licensedItem
                  ? LicenseUtils.resolveDisplayName(
                      license.licensedItem,
                      intl.formatMessage(
                        LicenseValues.licensedItem.displayName.undefined
                      )
                    )
                  : intl.formatMessage(
                      LicenseValues.licensedItem.displayName.undefined
                    ),
              }
            )
          : undefined
      }
      acceptButton={{
        label: intl.formatMessage({
          defaultMessage: "Confirm",
          description: "confirm changes button label",
        }),
      }}
      cancelButton={{
        label: intl.formatMessage({
          defaultMessage: "Cancel",
          description: "cancel confirmation button label",
        }),
      }}
      confirmContent={
        <>
          <p data-test-confirm-changes-message>
            <FormattedMessage
              defaultMessage="Are you sure you wish to apply the following changes to the license usage?"
              description="confirm changes message"
            />
          </p>
          {conf.functionality?.enableReleaseLicenseLease && releaseLeases.length > 0 && (
              <>
                <strong>
                  <FormattedMessage
                      defaultMessage="Release license leases"
                      description={"heading for summary of license leases to release"}
                  />
                </strong>
                <ul>
                  {releaseLeases.filter((f, i, r) => r.findIndex((fi) => fi.id === f.id) === i).map((u) => (
                      <li key={"toRelease" + u.id}>
                        {resolveUserName(
                            users ? users.find((val) => !!val.assignments?.find((s) => s.id === u.assignment?.id)) : undefined,
                            intl
                        )}
                      </li>
                  ))}
                </ul>
              </>
          )}
          {toReserve.length > 0 && (
            <>
              <strong>
                <FormattedMessage
                  defaultMessage="Add reservation"
                  description={"heading for summary of reservations to add"}
                />
              </strong>
              <ul>
                {toReserve.map((u) => (
                  <li key={"toReserve" + (u as string)}>
                    {resolveUserName(
                      users ? users.find((val) => val.id === u) : undefined,
                      intl
                    )}
                  </li>
                ))}
              </ul>
            </>
          )}
          {toRelease.length > 0 && (
            <>
              <strong>
                <FormattedMessage
                  defaultMessage="Release reservation"
                  description={"heading for summary of reservations to remove"}
                />
              </strong>
              <ul>
                {toRelease.map((u) => (
                  <li key={"toRelease" + (u as string)}>
                    {resolveUserName(
                      users ? users.find((val) => val.id === u) : undefined,
                      intl
                    )}
                  </li>
                ))}
              </ul>
            </>
          )}
          {toDeny.length > 0 && (
            <>
              <strong>
                <FormattedMessage
                  defaultMessage="Block usage"
                  description={"heading for summary of denials to add"}
                />
              </strong>
              <ul>
                {toDeny.map((u) => (
                  <li key={"toDeny" + (u as string)}>
                    {resolveUserName(
                      users ? users.find((val) => val.id === u) : undefined,
                      intl
                    )}
                  </li>
                ))}
              </ul>
            </>
          )}
          {toAllow.length > 0 && (
            <>
              <strong>
                <FormattedMessage
                  defaultMessage="Allow usage"
                  description={"heading for summary of denials to remove"}
                />
              </strong>
              <ul>
                {toAllow.map((u) => (
                  <li key={"toAllow" + (u as string)}>
                    {resolveUserName(
                      users ? users.find((val) => val.id === u) : undefined,
                      intl
                    )}
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      }
      show={show}
      onClose={onClose}
      backdrop={toUpdate.length > 0 ? "static" : true}
      primaryButton={{
        label: license
          ? intl.formatMessage({
              defaultMessage: "Apply",
              description: "primary button label",
            })
          : intl.formatMessage({
              defaultMessage: "Close",
              description: "clsoe button label",
            }),
        disabled: show && license && toUpdate.length === 0 && releaseLeases.length === 0? true : false,
      }}
      onPrimaryAction={() => {
        if (show && license && users && onApplyUsers && onApplyClients) {
          const releaseHandler = (releaseResult: any|undefined) => {
            const reservationHandler = (ru: 'skipped'|any, rc?: any) => {
              const feedbacks: FeedbackEntry[] = [];
              if (ru !== 'skipped' && typeof ru !== "string") {
                let success;
                let failure;
                let resu;
                if (!!ru && !!ru.results && !!ru.results.length && !!ru.results[0]) {
                  resu = ru
                      .results[0] as ActionTypes.ManageUsersLicenseAssignmentsAction;
                  if (resu.userAssignments) {
                    success = Object.keys(resu.userAssignments)
                        .map((id) =>
                            users ? users.find((val) => val.id === id) : undefined
                        )
                        .filter((val) => !!val);
                  }
                  if (resu.errors) {
                    failure = Object.keys(resu.errors)
                        .map((id) =>
                            users ? users.find((val) => val.id === id) : undefined
                        )
                        .filter((val) => !!val);
                  }
                }
                let resc;
                if (!!rc && !!rc.results && !!rc.results.length && !!rc.results[0]) {
                  resc = rc
                      .results[0] as ActionTypes.ManageClientsLicenseAssignmentsAction;
                  if (resc.clientAssignments) {
                    success = success ?
                        success.concat(...Object.keys(resc.clientAssignments)
                          .map((id) =>
                              users ? users.find((val) => val.id === id) : undefined
                          )
                          .filter((val) => !!val)) :
                        Object.keys(resc.clientAssignments)
                          .map((id) =>
                              users ? users.find((val) => val.id === id) : undefined
                          )
                          .filter((val) => !!val);
                  }
                  if (resc.errors) {
                    failure = failure ?
                        failure.concat(...Object.keys(resc.errors)
                          .map((id) =>
                              users ? users.find((val) => val.id === id) : undefined
                          )
                          .filter((val) => !!val)) :
                        Object.keys(resc.errors)
                            .map((id) =>
                                users ? users.find((val) => val.id === id) : undefined
                            )
                            .filter((val) => !!val);
                  }
                }
                if (resu || resc) {
                  if (success && success.length) {
                    if (failure && failure.length) {
                      // some succeeded, some failed
                      feedbacks.push({
                        id: "manageAssignments_success_" + license?.id,
                        msg: intl.formatMessage(
                            {
                              defaultMessage:
                                  "Updated {name} assignments for {userNames}.",
                              description:
                                  "Success notification for updated license assignments, when some assignments also failed. 'name' = name of the license, 'userNames' = a list of targeted users",
                            },
                            {
                              name:
                                  "<strong>" +
                                  (license && license.licensedItem
                                      ? LicenseUtils.resolveDisplayName(
                                          license.licensedItem,
                                          intl.formatMessage(
                                              LicenseValues.licensedItem.displayName
                                                  .undefined
                                          )
                                      )
                                      : intl.formatMessage(
                                          LicenseValues.licensedItem.displayName.undefined
                                      )) +
                                  "</strong>",
                              userNames:
                                  "<strong>" +
                                  success
                                      .map((usr) => resolveUserName(usr, intl))
                                      .join(", ") +
                                  "</strong>",
                            }
                        ),
                        autoClose: true,
                        type: "success",
                      });
                    } else {
                      // All succeeded
                      feedbacks.push({
                        id: "manageAssignments_success_" + license?.id,
                        msg: intl.formatMessage(
                            {
                              defaultMessage: "Updated {name} assignments",
                              description:
                                  "Success notification for updated license assignments. 'name' = name of the license",
                            },
                            {
                              name:
                                  "<strong>" +
                                  (license && license.licensedItem
                                      ? LicenseUtils.resolveDisplayName(
                                          license.licensedItem,
                                          intl.formatMessage(
                                              LicenseValues.licensedItem.displayName
                                                  .undefined
                                          )
                                      )
                                      : intl.formatMessage(
                                          LicenseValues.licensedItem.displayName.undefined
                                      )) +
                                  "</strong>",
                            }
                        ),
                        autoClose: true,
                        type: "success",
                      });
                    }
                  }
                  if ((resu?.errors || resc?.errors) && failure && failure.length) {
                    const errorsByMessage: { [ key: string ]: string[] } = {};
                    let errKeys;
                    if (resu && resu.errors) {
                      errKeys = Object.keys(resu.errors);
                      for (let i = 0; i < errKeys.length; i += 1) {
                        errorsByMessage[resu.errors[errKeys[i]].error] =
                            errorsByMessage[resu.errors[errKeys[i]].error]
                                ? errorsByMessage[resu.errors[errKeys[i]].error].concat(
                                    errKeys[i]
                                )
                                : [errKeys[i]];
                      }
                    }
                    if (resc && resc.errors) {
                      errKeys = Object.keys(resc.errors);
                      for (let i = 0; i < errKeys.length; i += 1) {
                        errorsByMessage[resc.errors[errKeys[i]].error] =
                            errorsByMessage[resc.errors[errKeys[i]].error]
                                ? errorsByMessage[resc.errors[errKeys[i]].error].concat(
                                    errKeys[i]
                                )
                                : [errKeys[i]];
                      }
                    }
                    const errorMessages = Object.keys(errorsByMessage);
                    if (errorMessages.length === 1) {
                      // Single failure cause
                      if (success && success.length) {
                        // with some succeeding
                        feedbacks.push({
                          id: "manageAssignments_partial_error_" + license?.id,
                          msg: intl.formatMessage(
                              {
                                defaultMessage:
                                    "Updating {name} assignments for {userNames} failed due to: {error}",
                                description:
                                    "Failure notification for updated license assignments, when there are multiple causes or successful assignments. 'name' = name of the license, 'userNames' = list of targeted users, 'error' = error message.",
                              },
                              {
                                name:
                                    "<strong>" +
                                    (license && license.licensedItem
                                        ? LicenseUtils.resolveDisplayName(
                                            license.licensedItem,
                                            intl.formatMessage(
                                                LicenseValues.licensedItem.displayName
                                                    .undefined
                                            )
                                        )
                                        : intl.formatMessage(
                                            LicenseValues.licensedItem.displayName
                                                .undefined
                                        )) +
                                    "</strong>",
                                error: !!(UserLicenseAssignmentRequestErrors as any)[
                                    errorMessages[0]
                                    ]
                                    ? "<strong>" +
                                    intl.formatMessage(
                                        (UserLicenseAssignmentRequestErrors as any)[
                                            errorMessages[0]
                                            ]
                                    ) +
                                    "</strong>"
                                    : "<strong>" +
                                    intl.formatMessage({
                                      defaultMessage: "Unknown error.",
                                      description:
                                          "License assignment failure error fallback message",
                                    }) +
                                    "</strong>",
                                userNames:
                                    "<strong>" +
                                    failure
                                        .filter(
                                            (usr) =>
                                                errorsByMessage[errorMessages[0]].findIndex(
                                                    (id: string) =>
                                                        usr && (usr.id as string) === id
                                                ) >= 0
                                        )
                                        .map((usr) => resolveUserName(usr, intl))
                                        .join(", ") +
                                    "</strong>",
                              }
                          ),
                          type: "danger",
                        });
                      } else {
                        // without succeeding
                        feedbacks.push({
                          id: "manageAssignments_error_" + license?.id,
                          msg: intl.formatMessage(
                              {
                                defaultMessage:
                                    "Updating {name} assignments failed due to: {error}",
                                description:
                                    "Failure notification for updated license assignments. 'name' = name of the license, 'error' = error message",
                              },
                              {
                                name:
                                    "<strong>" +
                                    (license && license.licensedItem
                                        ? LicenseUtils.resolveDisplayName(
                                            license.licensedItem,
                                            intl.formatMessage(
                                                LicenseValues.licensedItem.displayName
                                                    .undefined
                                            )
                                        )
                                        : intl.formatMessage(
                                            LicenseValues.licensedItem.displayName
                                                .undefined
                                        )) +
                                    "</strong>",
                                error: !!(UserLicenseAssignmentRequestErrors as any)[
                                    errorMessages[0]
                                    ]
                                    ? "<strong>" +
                                    intl.formatMessage(
                                        (UserLicenseAssignmentRequestErrors as any)[
                                            errorMessages[0]
                                            ]
                                    ) +
                                    "</strong>"
                                    : "<strong>" +
                                    intl.formatMessage({
                                      defaultMessage: "Unknown error.",
                                      description:
                                          "License assignment failure error fallback message",
                                    }) +
                                    "</strong>",
                              }
                          ),
                          type: "danger",
                        });
                      }
                    } else if (errorMessages.length > 1) {
                      // Multiple causes
                      for (let i = 0; i < errorMessages.length; i += 1) {
                        feedbacks.push({
                          id:
                              "manageAssignments_partial_error_" +
                              license?.id +
                              errorMessages[i],
                          msg: intl.formatMessage(
                              {
                                defaultMessage:
                                    "Updating {name} assignments for {userNames} failed due to: {error}",
                                description:
                                    "Failure notification for updated license assignments, when there are multiple causes or successful assignments. 'name' = name of the license, 'userNames' = list of targeted users, 'error' = error message.",
                              },
                              {
                                name:
                                    (license && license.licensedItem
                                        ? LicenseUtils.resolveDisplayName(
                                            license.licensedItem,
                                            intl.formatMessage(
                                                LicenseValues.licensedItem.displayName
                                                    .undefined
                                            )
                                        )
                                        : intl.formatMessage(
                                            LicenseValues.licensedItem.displayName
                                                .undefined
                                        )) + "</strong>",
                                error: !!(UserLicenseAssignmentRequestErrors as any)[
                                    errorMessages[0]
                                    ]
                                    ? "<strong>" +
                                    intl.formatMessage(
                                        (UserLicenseAssignmentRequestErrors as any)[
                                            errorMessages[0]
                                            ]
                                    ) +
                                    "</strong>"
                                    : "<strong>" +
                                    intl.formatMessage({
                                      defaultMessage: "Unknown error.",
                                      description:
                                          "License assignment failure error fallback message",
                                    }) +
                                    "</strong>",
                                userNames:
                                    "<strong>" +
                                    failure
                                        .filter(
                                            (usr) =>
                                                errorsByMessage[errorMessages[i]].findIndex(
                                                    (id: string) =>
                                                        usr && (usr.id as string) === id
                                                ) >= 0
                                        )
                                        .map((usr) => resolveUserName(usr, intl))
                                        .join(", ") +
                                    "</strong>",
                              }
                          ),
                          type: "danger",
                        });
                      }
                    }
                  }
                }
              }
              if (releaseResult && releaseResult.result) {
                const resultArray = releaseResult.result.length !== undefined ? releaseResult.result : [releaseResult.result];
                const invalidReleaseResults: any[] = [];
                const validReleaseResults = releaseLeases.map((rl) => {
                  if (resultArray.find((rrr: any) => rrr[rl.id])) {
                    return rl;
                  } else {
                    invalidReleaseResults.push(rl);
                    return undefined;
                  }
                }).filter((f) => !!f);
                if (validReleaseResults && validReleaseResults.length) {
                  // success
                  feedbacks.push({
                    id: "releaseLease_success_" + license?.id,
                    msg: intl.formatMessage(
                        {
                          defaultMessage:
                              "Released {name} {leaseCount, plural, =1 {lease} other {leases} } for {userNames}.",
                          description:
                              "Success notification for removing license lease. 'leaseCount' = Amount of leases for choosing plural/singular term for 'lease' in translation, 'name' = name of the license, 'userNames' = a list of targeted users",
                        },
                        {
                          leaseCount: validReleaseResults.length,
                          name: "<strong>" +  license?.licensedItem
                              ? LicenseUtils.resolveDisplayName(
                                  license?.licensedItem || {}, // the sanity check 2 lines above this is somehow does not suffice, and we had to add this stupid and useless object fallback value to satisfy some whining component
                                  intl.formatMessage(
                                      LicenseValues.licensedItem.displayName.undefined
                                  )
                              )
                              : intl.formatMessage(
                                  LicenseValues.licensedItem.displayName.undefined
                              ) + "</strong>",
                          userNames: validReleaseResults.map(
                              (r: any) =>
                                  "<strong>" +
                                  resolveUserName(users?.find((usr) => {
                                    return usr.assignments?.find((ass) => ass.id === r.assignment.id);
                                  }), intl) +
                                  "</strong>"
                          ).filter((f, i, arr) => arr.indexOf(f) === i).join(", "),
                        }
                    ),
                    autoClose: true,
                    type: "success",
                  });
                }
                if (invalidReleaseResults.length) {
                  // failure
                  feedbacks.push({
                    id: "releaseLease_failed_" + license?.id,
                    msg: intl.formatMessage(
                        {
                          defaultMessage:
                              "Releasing {name}'s {leaseCount, plural, =1 {lease} other {leases} } failed for: {userNames}.",
                          description:
                              "Failure notification for removing license lease. 'leaseCount' = Amount of leases for choosing plural/singular term for 'lease' in translation, 'name' = name of the license, 'licenses' = a list of targeted users",
                        },
                        {
                          leaseCount: invalidReleaseResults.length,
                          name: "<strong>" +  license?.licensedItem
                              ? LicenseUtils.resolveDisplayName(
                                  license?.licensedItem || {}, // the sanity check 2 lines above this is somehow does not suffice, and we had to add this stupid and useless object fallback value to satisfy some whining component
                                  intl.formatMessage(
                                      LicenseValues.licensedItem.displayName.undefined
                                  )
                              )
                              : intl.formatMessage(
                              LicenseValues.licensedItem.displayName.undefined
                          ) + "</strong>",
                          userNames: invalidReleaseResults.map(
                              (r: any) =>
                                  "<strong>" +
                                  resolveUserName(users?.find((usr) => {
                                    return usr.assignments?.find((ass) => ass.id === r.assignment.id);
                                  }), intl) +
                                  "</strong>"
                          ).filter((f, i, arr) => arr.indexOf(f) === i).join(", "),
                        }
                    ),
                    type: "danger",
                  });
                }
              }
              if (feedbacks.length) {
                onShowFeedback(feedbacks);
              }
            };
            if (onApplyUsers && onApplyClients && license && toUpdate.length > 0) {
              const mapped = toUpdate.map((a) => {
                delete a.addDenial;
                delete a.removeDenial;
                delete a.addReservation;
                delete a.removeReservation;
                return a;
              });
              if (enableUsers && enableClients) {
                onApplyUsers(
                    license.id as string,
                    mapped
                ).then((ru: any) => {
                  return onApplyClients ? onApplyClients(
                          license?.id as string,
                          mapped
                      ).then((rc: any) => {
                        return reservationHandler(ru, rc);
                      }) :
                      undefined;
                });
              } else if (enableUsers) {
                return onApplyUsers(
                    license.id as string,
                    mapped
                ).then((ru: any) => {
                  return reservationHandler(ru);
                });
              } else if (enableClients) {
                return onApplyClients(
                    license.id as string,
                    mapped
                ).then((rc: any) => {
                  return reservationHandler({}, rc);
                });
              }
            } else {
              reservationHandler('skipped')
            }
          };
          if (releaseLeases.length && conf.functionality?.enableReleaseLicenseLease) {
            onReleaseLicenseLease(releaseLeases).then(releaseHandler);
          } else {
            releaseHandler(undefined);
          }
        }
        onClose();
      }}
      secondaryButton={
        show && license
          ? {
              label: intl.formatMessage({
                defaultMessage: "Cancel",
                description: "secondary button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={show && license ? onClose : () => {}}
    >
      <>
        {show && license && (
          <>
            <Table<UserWithLicenseUsage|ClientWithLicenseUsage>
              disableLoadingIndicator={!isReady}
              maxRows={15}
              compact={true}
              reset={false}
              pagination={false}
              data={users ? users : !isReady ? undefined : []}
              identifyingColumn={"id"}
              columns={columns}
              search={(users || []).length > TABLE_SEARCH_THRESHOLD}
              onSearch={onSetActiveSearch}
              activeSearch={activeSearch}
              filters={filters}
              header={
                <div className={"header-content"}>
                  <LicenseValidityBadge
                    validFrom={license.validFrom}
                    validUntil={license.validUntil}
                    validity={resolveLicenseValidity(license)}
                  />

                  {hasSeats && (
                  <span
                    className={
                      availableSeats === 0 ? "seats no-seats" : "seats"
                    }
                  >
                    <FormattedMessage
                      defaultMessage="Available seats remaining: {availableSeats} "
                      description={
                        "Table heading. 'availableSeats' = the amount of available seats"
                      }
                      values={{
                        availableSeats: availableSeats !== undefined ? availableSeats : intl.formatMessage(LicenseValues.seatsAvailable.undefined),
                      }}
                    />
                  </span>
                  )}
                </div>
              }
            />
          </>
        )}
        {!!show && !!isReady && !license && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p>
              <FormattedMessage
                defaultMessage="Something went wrong and the license could not be loaded. The license may have been removed or you don't have sufficient access rights."
                description="title to be shown when there is no license to display"
              />
            </p>
          </Feedback>
        )}
      </>
    </ConfirmModal>
  );
}

export default LicenseUsageModal;
