import { ReactNode } from "react";
import { useIntl, IntlProvider, FormattedMessage } from "react-intl";
import {Feedback, Button} from "@10duke/dukeui";
import LanguageContext from "../../language-context";
import DefaultLanguageErrorScreen from "../screens/default-language-error-screen";
import "./localize-view.scss";
import { LocaleManager, ResolvedLocaleData } from "../../utils/locale";
import { Helmet } from "react-helmet";

export interface LocalizeProps {
  children: ReactNode;

  defaultNotAvailable: string | undefined;

  defaultMessagesReady: boolean;

  notAvailable: string | undefined;

  messages: { [key: string]: any } | undefined | null;
  changeLanguage: (l: string) => void;

  notAvailableDismissed: boolean;
  setNotAvailableDismissed: (b: boolean) => void;
  restoreStoredNotification: boolean;
  dismissRestoreStoredNotification: () => void;

  resolvedLocaleData: ResolvedLocaleData;
}
export function asIntlLocale(s: string) {
  const parts = s.toLowerCase().split(/-|_/);
  if (parts.length > 1) {
    parts[1] = parts[1].toUpperCase();
    return parts.join("-");
  } else {
    return parts[0];
  }
}
export function asDukeLocale(s: string) {
  const parts = s.toLowerCase().split(/-|_/);
  if (parts.length > 1) {
    return parts.join("-");
  } else {
    return parts[0];
  }
}

export default function Localize(props: LocalizeProps) {
  const {
    defaultNotAvailable,
    defaultMessagesReady,
    notAvailable,
    messages,
    changeLanguage,
    notAvailableDismissed,
    setNotAvailableDismissed,
    restoreStoredNotification,
    dismissRestoreStoredNotification,
    resolvedLocaleData,
  } = props;
  const defaultLocale = resolvedLocaleData?.defaultLocale as string;
  const storedLocale = resolvedLocaleData.stored;
  const language = resolvedLocaleData?.locale?.value as string;
  const defaultLocaleLabel = LocaleManager.resolveLocaleLabel(
    resolvedLocaleData.defaultLocale as string
  );

  const languageNames = new Intl.DisplayNames(
    [!notAvailable ? asIntlLocale(language as string) : defaultLocale],
    { type: "language" }
  );
  return (
    <LanguageContext.Provider
      value={{
        ...resolvedLocaleData,
        changeLanguage: changeLanguage,
      }}
    >
      <IntlProvider
        defaultLocale={asIntlLocale(defaultLocale)}
        locale={asIntlLocale(language as string)}
        messages={!!messages ? messages : undefined}
      >
        <Helmet htmlAttributes={{ lang: asIntlLocale(language as string) }} />
        {defaultMessagesReady && !!messages && props.children}
        {notAvailable && (
          <LocaleNotAvailablePrompt
            show={notAvailableDismissed}
            onClose={() => {
              setNotAvailableDismissed(true);
            }}
            locale={notAvailable}
            def={defaultLocaleLabel}
            defCode={defaultLocale}
          />
        )}
        {defaultNotAvailable && <DefaultLanguageErrorScreen />}
        {restoreStoredNotification && (
          <Feedback
            id="restoreStoredLocaleNotification"
            type={"info"}
            show={restoreStoredNotification}
            onClose={dismissRestoreStoredNotification}
            data-test-restore-stored-locale
          >
            <h4 className={"alert-title"}>
              <FormattedMessage
                defaultMessage="Current and stored language settings do not match."
                description="Notification title to show when the page is rendered in a language that is different than the one user has selected before."
              />
            </h4>
            <p>
              <FormattedMessage
                defaultMessage="The page is in {currentLanguage}, but you've previously used {rememberedLanguage}. Please verify your language choice"
                description="Notification copy to show when the page is rendered in a language that is different than the one user has selected before. 'currentLanguage' = name of current language, 'rememberedLanguage' = name of previously used language"
                values={{
                  currentLanguage: languageNames.of(language),
                  rememberedLanguage: languageNames.of(storedLocale as string),
                }}
              />
            </p>
            <Button
              data-test-restore-stored-locale-button
              variant={"primary"}
              className={"btn custom-base"}
              action={() => changeLanguage(storedLocale as string)}
            >
              {LocaleManager.resolveLocaleLabel(storedLocale as string, null) ||
                languageNames.of(storedLocale as string)}
            </Button>
            <Button
              data-test-dismiss-restore-stored
              variant={"primary"}
              className={"btn custom-base"}
              action={() => changeLanguage(language as string)}
            >
              {LocaleManager.resolveLocaleLabel(language)}
            </Button>
          </Feedback>
        )}
      </IntlProvider>
    </LanguageContext.Provider>
  );
}

/**
 * declaring this internally used snippet as "component" to allow use of intl within.
 */
function LocaleNotAvailablePrompt(props: {
  show: boolean;
  onClose: () => void;
  locale: string;
  def: string;
  defCode: string;
}) {
  const intl = useIntl();
  const languageNames = new Intl.DisplayNames([props.defCode], {
    type: "language",
  });

  return (
    <Feedback
      id="localeNotAvailablePrompt"
      type={"danger"}
      show={!props.show}
      onClose={props.onClose}
      asChild={true}
      dismissible={true}
    >
      <h4 className={"alert-heading"}>
        {intl.formatMessage(
          {
            defaultMessage: "The requested language {locale} is not available",
            description:
              "Notification heading for missing language. 'locale' = the requested locale code",
          },
          {
            locale: <strong>{languageNames.of(props.locale)}</strong>,
          }
        )}
      </h4>
      <p>
        {intl.formatMessage(
          {
            defaultMessage:
              "Rendering content with the default language: {locale}.",
            description:
              "Notification copy for missing localization, 'locale' = the name of the configured default language",
          },
          {
            locale: <strong>{props.def}</strong>,
          }
        )}
      </p>
    </Feedback>
  );
}
