import { connect } from "react-redux";
import ViewRoleModal, {
  ViewRoleModalProps as _ViewRoleModalProps,
} from "./view-role-modal-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import {
  getOrganizationRole,
  listPermissionsOfOrganizationRole,
  replaceOrganizationRole,
  listPermissions,
  setPermissionsOfOrganizationRole,
  listOrganizationRolesInOrganizationRole,
  listOrganizationRoles,
  setOrganizationRolesOfOrganizationRole,
  clearError,
} from "../../../actions";
import OrganizationUtils from "../../../utils/organization";
import { ViewRoleModalDataProps } from "./view-role-modal-view";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";
import PermissionsUtils from "../../../utils/permissions";
import { PermissionWithGrantedActions } from "../../../model/PermissionWithGrantedActions";

export type ViewRoleModalProps = Omit<
  _ViewRoleModalProps,
  keyof ViewRoleModalDataProps
>;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ViewRoleModalProps,
  ViewRoleModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ViewRoleModalProps,
  ViewRoleModalProps
>;

const sender: ActionSender = { key: "view-role-modal" };
/**
 * @param state
 * @param ownProps
 */
function mapStateToProps(
  state: AppState,
  ownProps: ViewRoleModalProps
): ReduxStateProps {
  const orgAdminRoleId = OrganizationUtils.selectOrgAdminRoleId(state);
  let role: any = ownProps.roleId
    ? OrganizationUtils.selectOrganizationRole(ownProps.roleId, state)
    : undefined;
  if (role) {
    role = { ...role };
    role.type = orgAdminRoleId === role.id ? "orgadmin" : "role";
  }
  const roles = OrganizationUtils.selectOrganizationRoles(state);
  const permissions = PermissionsUtils.selectPermissions(state);
  const rolePermissions = ownProps.roleId
    ? OrganizationUtils.selectOrganizationRolePermissions(
        ownProps.roleId,
        state
      )?.map((p) => {
        const pp = !!permissions
          ? permissions.find((p2) => p2.id === p.permissionId)
          : undefined;

        return {
          id: p.permissionId,
          grantedActions: !!p.grantedActions
            ? {
                allowedActions: p.grantedActions,
              }
            : { allowedActions: [] },
          name: !!pp && !!pp.name ? pp.name : "", // ignoring the fact that pp may in rare cases be missing.
        } as PermissionWithGrantedActions;
      })
    : undefined;
  // populate the permissions with the assigned actions
  const permissionsWithActions = PermissionsUtils.selectPermissions(state)?.map(
    (p) => {
      const t: PermissionWithGrantedActions = {
        ...p,
        grantedActions: {
          allowedActions: rolePermissions
            ? rolePermissions.find((rp) => rp.id === p.id)?.grantedActions
                ?.allowedActions || []
            : [],
        },
      } as PermissionWithGrantedActions;
      return t;
    }
  );
  const toAllowForDebuginspect = {
    role,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    permissions: permissionsWithActions,
    roles: !!roles ? roles.filter((r) => r.id !== ownProps.roleId) : roles,
    organizationId: OrganizationUtils.selectSelectedOrganizationId(state),
    includedRoles: OrganizationUtils.selectOrganizationRoleOrganizationRoles(
      ownProps.roleId as string,
      state
    ),
    rolePermissions,
  };
  return toAllowForDebuginspect;
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationRolesStates(next, prev) &&
    OrganizationUtils.compareOrganizationRolePermissionStates(next, prev) &&
    OrganizationUtils.compareOrganizationRoleOrganizationRoleStates(
      next,
      prev
    ) &&
    PermissionsUtils.comparePermissionStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onUpdateRole: addSenderArgument(sender, replaceOrganizationRole),
  onLoadRoleIncludedRoles: addSenderArgument(
    sender,
    listOrganizationRolesInOrganizationRole
  ),
  onLoadRolePermissions: addSenderArgument(
    sender,
    listPermissionsOfOrganizationRole
  ),
  onLoadPermissions: addSenderArgument(sender, listPermissions), // listOrganizationEntitlements),
  onLoadRoles: addSenderArgument(sender, listOrganizationRoles),
  onLoadRole: addSenderArgument(sender, getOrganizationRole),
  onSetRolePermissions: addSenderArgument(
    sender,
    setPermissionsOfOrganizationRole
  ),
  onSetIncludedRoles: addSenderArgument(
    sender,
    setOrganizationRolesOfOrganizationRole
  ),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ViewRoleModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ViewRoleModal);
