import * as ActionTypes from "../actions/actionTypes";
import { OrgRoleOrgRoleIdsByOrgRoleId } from "../store/OrganizationRoleState";

export default function orgRoleOrgRoleIds(
  state: OrgRoleOrgRoleIdsByOrgRoleId,
  action: ActionTypes.AppAction
): OrgRoleOrgRoleIdsByOrgRoleId | null {
  const currentState = state || ({} as OrgRoleOrgRoleIdsByOrgRoleId);
  switch (action.type) {
    case ActionTypes.SET_ORG_ROLES_OF_ORG_ROLE:
      const rolesOfOrgRole2 =
        action as ActionTypes.SetOrganizationRolesOfOrgRoleAction;
      const roleIds2 = rolesOfOrgRole2.roles.map((role) => role.id as string);
      return { ...currentState, [rolesOfOrgRole2.orgRoleId]: roleIds2 };

    case ActionTypes.LIST_ORG_ROLES_OF_ORG_ROLE:
      const rolesOfOrgRole =
        action as ActionTypes.ListOrganizationRolesOfOrgRoleAction;
      const roleIds = rolesOfOrgRole.roles.map((role) => role.id as string);
      return { ...currentState, [rolesOfOrgRole.orgRoleId]: roleIds };
    default:
      return state || null;
  }
}
