import { HTMLAttributes } from "react";
import { useIntl } from "react-intl";
import { SummaryCard, FieldList, RelativeTime } from "@10duke/dukeui";
import { Organization } from "../../../model/Organization";
import { OrganizationLabels } from "../../../localization/organization";
import "./organization-card-view.scss";

interface OrganizationCardDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface OrganizationCardProps extends OrganizationCardDOMProps {
  isReady: boolean;
  organization?: Organization;
  onLoadOrganization?: () => void;

  moreAction: (() => void) | null;
}

function OrganizationCard(props: OrganizationCardProps) {
  const { children, organization, moreAction, isReady, onLoadOrganization } =
    props;
  if (!organization) {
    throw new Error("No organization");
  }
  const intl = useIntl();
  const headerButton = moreAction
    ? {
        label: intl.formatMessage({
          defaultMessage: "Edit",
          description: "label for the edit button",
        }),
        action: moreAction,
      }
    : undefined;
  const fields: any = {};
  const date = new Date().getTime();
  if (organization) {
    fields.created = {
      label: intl.formatMessage(OrganizationLabels.created),
      value: organization.created ? (
        <RelativeTime time={organization.created} />
      ) : (
        "-"
      ),
    };
    if (organization.modified) {
      const itemClass =
        organization.modified &&
        new Date(organization.modified).getTime() - date > -3600000 * 24 * 7
          ? "text-info"
          : undefined;
      fields.updated = {
        label: intl.formatMessage(OrganizationLabels.modified),
        value: organization.modified ? (
          <RelativeTime time={organization.modified} />
        ) : (
          "-"
        ),
        itemClass: itemClass,
      };
    }
  }
  return (
    <SummaryCard
      id={"organization-card"}
      onReloadData={() => {
        if (onLoadOrganization) {
          onLoadOrganization();
        }
      }}
      isReady={isReady}
      data-test-organization-card
      title={intl.formatMessage({
        defaultMessage: "Organization",
        description: "heading for the card",
      })}
      headerButton={headerButton}
      footer={<FieldList inline={true} fields={fields} />}
    >
      <FieldList
        fields={{
          name: {
            label: intl.formatMessage(OrganizationLabels.name),
            value: organization.name,
            oneLiner: true,
          },
          description: {
            label: intl.formatMessage(OrganizationLabels.description),
            value: organization.description,
            itemClass: "description",
          },
        }}
      />
      {children}
    </SummaryCard>
  );
}
export default OrganizationCard;
