import { useEffect, useRef, useState } from "react";
import View, {
  ManageRoleMembersModalProps as _ManageRoleMembersModalProps,
  ManageRoleMembersModalStateProps,
} from "./manage-role-members-modal-view";
import { User } from "../../../model/User";
import _ from "lodash";
import { withCloseAfterExited } from "@10duke/dukeui";
const isArrayEqual = (x: Array<any>, y: Array<any>) => {
  const t = _(x).xorWith(y, _.isEqual).isEmpty();
  return t;
};

export type ManageRoleMembersModalProps = Omit<
  _ManageRoleMembersModalProps,
  keyof ManageRoleMembersModalStateProps
>;
const ViewWithCloseAfterExited =
  withCloseAfterExited<_ManageRoleMembersModalProps>(View);
export default function ManageRoleMembersModal(
  props: ManageRoleMembersModalProps
) {
  const { show, roleMembers, users, ...other } = props;

  const roleMembersRef = useRef(roleMembers);
  if (!_.isEqual(roleMembersRef.current, roleMembers)) {
    roleMembersRef.current = roleMembers;
  }
  const roleMembersRefCurrent = roleMembersRef.current;

  const usersRef = useRef(users);
  if (!_.isEqual(usersRef.current, users)) {
    usersRef.current = users;
  }
  const usersRefCurrent = usersRef.current;

  const [activeSearch, onSetActiveSearch] = useState("");
  const [editedMembers, setEditedMembers] = useState<string[] | undefined>(
    roleMembers
  );
  const [membersToRemove, setMembersToRemove] = useState<User[]>([]);
  const [membersToAdd, setMembersToAdd] = useState<User[]>([]);
  useEffect(() => {
    setEditedMembers((cur) => {
      if (
        cur &&
        roleMembersRefCurrent &&
        isArrayEqual(cur, roleMembersRefCurrent)
      ) {
        // filter out items possibly removed from users
        setMembersToRemove((v) =>
          v.filter((u) =>
            usersRefCurrent
              ? usersRefCurrent.findIndex((ui) => u.id === ui.id) >= 0
              : false
          )
        );
        setMembersToAdd((v) =>
          v.filter((u) =>
            usersRefCurrent
              ? usersRefCurrent.findIndex((ui) => u.id === ui.id) >= 0
              : false
          )
        );
        return cur.filter((v) =>
          usersRefCurrent
            ? usersRefCurrent.findIndex((u) => u.id === v) >= 0
            : false
        );
      } else {
        // incomimg memberships changed, so reset.
        setMembersToRemove([]);
        setMembersToAdd([]);
        return roleMembersRefCurrent
          ? _.cloneDeep(roleMembersRefCurrent)
          : roleMembersRefCurrent;
      }
    });
  }, [
    roleMembersRefCurrent,
    usersRefCurrent,
    setEditedMembers,
    setMembersToRemove,
    setMembersToAdd,
    onSetActiveSearch,
  ]);

  useEffect(() => {
    if (!show) {
      setEditedMembers(undefined);
      setMembersToRemove([]);
      setMembersToAdd([]);
      onSetActiveSearch("");
    }
  }, [show, setEditedMembers]);

  const onSetMembers = (members: User[]) => {
    let newEdited: string[] | undefined = members.map((u) => u.id as string);
    if (newEdited) {
      const nm = (
        roleMembers && newEdited
          ? roleMembers.filter((x) =>
              newEdited ? !newEdited.includes(x) : false
            )
          : []
      )
        .map((id) => {
          return users ? users.find((val) => val.id === id) : undefined;
        })
        .filter((val) => val !== undefined) as User[];
      setMembersToRemove(nm);
      const nn = (
        newEdited
          ? newEdited.filter((x) =>
              roleMembers ? !roleMembers.includes(x) : true
            )
          : []
      )
        .map((id) => {
          return users ? users.find((val) => val.id === id) : undefined;
        })
        .filter((val) => val !== undefined) as User[];
      setMembersToAdd(nn);
    } else {
      console.error("this should never happen");
    }
    setEditedMembers(newEdited);
  };

  return (
    <ViewWithCloseAfterExited
      {...other}
      show={show}
      roleMembers={editedMembers}
      users={users}
      onSetActiveSearch={onSetActiveSearch}
      activeSearch={activeSearch}
      membersToRemove={membersToRemove}
      membersToAdd={membersToAdd}
      selected={
        editedMembers && users
          ? (editedMembers
              .map((m) => users.find((u) => u.id === m))
              .filter((u) => !!u) as User[])
          : []
      }
      onSetSelected={onSetMembers}
    />
  );
}
