import * as ActionTypes from "../actions/actionTypes";
import {ClientGroupIdsByEntitlementId} from "../store/EntitlementState";


export default function entitlementClientGroupIds(
    state: ClientGroupIdsByEntitlementId,
    action: ActionTypes.AppAction
): ClientGroupIdsByEntitlementId | null {
  const currentState = state || ({} as ClientGroupIdsByEntitlementId);

  switch (action.type) {

    case ActionTypes.LIST_LICENSE_CONSUMING_ORG_CLIENT_GROUPS: {
      const actionResult = action as ActionTypes.ListLicenseConsumingOrgClientGroupsAction;
      return {
        ...currentState,
        [actionResult.entId]: actionResult.groups.map((g) => g.id as string)
      };
    }
    case ActionTypes.START_AUTHN:
      return null;
    default:
      return state || null;
  }
}
