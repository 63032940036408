import { connect } from "react-redux";
import ViewGroupModal, {
  ViewGroupModalProps as _ViewGroupModalProps,
} from "./view-group-modal-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import {
  clearError,
  getOrganizationGroup,
  replaceOrganizationGroup,
} from "../../../actions";
import {
  listEntitlementsConsumedByOrgGroup,
  listOrganizationEntitlements,
  setEntitlementsConsumedByOrgGroup,
} from "../../../actions/entActions";
import OrganizationUtils from "../../../utils/organization";
import { ViewGroupModalDataProps } from "./view-group-modal-view";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import EntitlementUtils from "../../../utils/entitlement";
import { addSenderArgument } from "../../../actions/actionHelpers";

export type ViewGroupModalProps = Omit<
  _ViewGroupModalProps,
  keyof ViewGroupModalDataProps
>;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ViewGroupModalProps,
  ViewGroupModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ViewGroupModalProps,
  ViewGroupModalProps
>;

const sender: ActionSender = { key: "view-group-modal" };
/**
 * @param state
 * @param ownProps
 */
function mapStateToProps(
  state: AppState,
  ownProps: ViewGroupModalProps
): ReduxStateProps {
  return {
    group: ownProps.groupId
      ? OrganizationUtils.selectOrganizationGroup(ownProps.groupId, state)
      : undefined,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    entitlements: OrganizationUtils.selectOrganizationEntitlements(state),
    organizationId: OrganizationUtils.selectSelectedOrganizationId(state),
    groupEntitlements: ownProps.groupId
      ? EntitlementUtils.selectGroupEntitlements(ownProps.groupId, state)
      : undefined,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationGroupsStates(next, prev) &&
    EntitlementUtils.compareGroupEntitlementsStates(next, prev) &&
    OrganizationUtils.compareOrganizationEntitlementsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onUpdateGroup: addSenderArgument(sender, replaceOrganizationGroup),
  onLoadGroupEntitlements: addSenderArgument(
    sender,
    listEntitlementsConsumedByOrgGroup
  ),
  onLoadEntitlements: addSenderArgument(sender, listOrganizationEntitlements),
  onLoadGroup: addSenderArgument(sender, getOrganizationGroup),
  onSetGroupEntitlements: addSenderArgument(
    sender,
    setEntitlementsConsumedByOrgGroup
  ),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ViewGroupModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ViewGroupModal);
