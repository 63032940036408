/**
 * 10Duke Entitlement API
 * 3.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
    baseUrl: "https://entitlement.10duke.com",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {
    server1: "https://entitlement.10duke.com"
};
export type ResourceBase = {
    id?: string;
    created?: string;
    authorId?: string;
    modified?: string;
    editorId?: string;
};
export type LicensedItemReference = {
    id: string;
    name?: string;
};
export type LicenseModelReference = {
    id: string;
    name?: string;
};
export type ProductItem = ResourceBase & {
    licensedItem?: LicensedItemReference;
    licenseModel?: LicenseModelReference;
    seatCount?: number;
    seatReservationCount?: string;
    useCount?: number;
    useTime?: number;
    allowedVersions?: string;
    allowedVersionsDescription?: string;
};
export type ProductItems = ProductItem[];
export type ApiError = {
    error: string;
    error_description?: string;
    error_uri?: string;
};
export type ResourceWithValidity = ResourceBase & {
    validFrom?: string;
    validUntil?: string;
};
export type Product = ResourceWithValidity & {
    name?: string;
    description?: string;
    active?: boolean;
};
export type Products = Product[];
export type LicenseProvisioningRequest = object;
export type LicenseContractReference = {
    "type": string;
    externalContractId: string;
};
export type LicensedItems = LicensedItem[];
export type LicensedItem = ResourceBase & {
    name?: string;
    displayName?: string;
    description?: string;
    "type"?: string;
    aggregatedItems?: LicensedItems;
};
export type LicenseCreditConsumptionSettings = {
    allowedOveruse?: string;
    "type": string;
};
export type LicenseAssignmentConstraints = {
    anchors?: ("user" | "device")[];
    reservations?: "advance" | "lazy" | "optional";
    reassignments?: "unlimited" | "limited" | "prohibited";
    maxCount?: number;
};
export type ModeSpecificLicenseSessionConstraints = {
    maxCount?: number;
};
export type TotalLicenseSessionConstraints = {
    maxCount?: number;
};
export type LicenseSessionConstraints = {
    anchors?: ("device" | "resource")[];
    online?: ModeSpecificLicenseSessionConstraints;
    offline?: ModeSpecificLicenseSessionConstraints;
    total?: TotalLicenseSessionConstraints;
};
export type LeaseExtensionConstraints = {
    allowed?: boolean;
    authorization?: "leaseId" | "optionalLeaseId" | "none";
};
export type LeaseReleaseConstraints = {
    allow?: boolean;
    cooldown?: number;
};
export type ModeSpecificLicenseLeaseConstraints = {
    maxLeaseTime?: number;
    extension?: LeaseExtensionConstraints;
    release?: LeaseReleaseConstraints;
};
export type LicenseLeaseConstraints = {
    anchors?: "process"[];
    online?: ModeSpecificLicenseLeaseConstraints;
    offline?: ModeSpecificLicenseLeaseConstraints;
};
export type LicenseModel = ResourceBase & {
    name?: string;
    description?: string;
    credits?: LicenseCreditConsumptionSettings;
    assignments?: LicenseAssignmentConstraints;
    sessions?: LicenseSessionConstraints;
    leases?: LicenseLeaseConstraints;
    locking?: "none" | "exclusive";
    versions?: "allowAll" | "constrain";
};
export type EffectiveLicenseModel = LicenseModel;
export type LicenseScope = {
    "type"?: string;
    externalId?: string;
    externalName?: string;
};
export type LicenseScopes = LicenseScope[];
export type License = ResourceWithValidity & {
    active?: boolean;
    entitlementId?: string;
    licensedItem?: LicensedItem;
    licenseModelId?: string;
    licenseModelName?: string;
    effectiveLicenseModel?: EffectiveLicenseModel;
    scopeBindings?: LicenseScopes;
    seatsTaken?: number;
    seatsReserved?: number;
    seatsTotal?: number;
    useCountConsumed?: number;
    useCountTotal?: number;
    useTimeConsumed?: number;
    useTimeTotal?: number;
};
export type LicenseCreditBase = ResourceWithValidity & {
    active?: boolean;
    licenseId?: string;
    licenseTransactionItemId?: string;
};
export type VersionConstrainedScopedLicenseCreditBase = LicenseCreditBase & {
    allowedVersions?: string;
    allowedVersionsDescription?: string;
    scopeBindings?: LicenseScopes;
};
export type SeatCountCredit = VersionConstrainedScopedLicenseCreditBase & {
    seatCount?: number;
    seatsConsumed?: number;
};
export type SeatCountCredits = SeatCountCredit[];
export type SeatReservationCredit = LicenseCreditBase & {
    seatReservationCount?: number;
    seatReservationsConsumed?: number;
};
export type SeatReservationCredits = SeatReservationCredit[];
export type UseCountCredit = VersionConstrainedScopedLicenseCreditBase & {
    useCount?: number;
    countUsed?: number;
};
export type UseCountCredits = UseCountCredit[];
export type UseTimeCredit = VersionConstrainedScopedLicenseCreditBase & {
    useTime?: number;
    timeUsed?: number;
};
export type UseTimeCredits = UseTimeCredit[];
export type LicenseWithCredits = License & {
    seatCountCredits?: SeatCountCredits;
    seatReservationCredits?: SeatReservationCredits;
    useCountCredits?: UseCountCredits;
    useTimeCredits?: UseTimeCredits;
};
export type LicensesWithCredits = LicenseWithCredits[];
export type LicenseTransactionItem = ResourceBase & {
    externalId?: string;
    productId?: string;
    quantity?: number;
    licenseValidFrom?: string;
    licenseValidUntil?: string;
    licenses?: LicensesWithCredits;
    licenseProperties?: {
        [key: string]: string;
    };
};
export type LicenseTransaction = ResourceBase & {
    "type"?: string;
    externalId?: string;
    processed?: string;
    cancelled?: string;
    contract?: LicenseContractReference;
    items?: LicenseTransactionItem[];
};
export type LicenseTransactionRequest = LicenseProvisioningRequest & {
    transaction?: LicenseTransaction;
};
export type LicenseProvisioningResult = object;
export type LicenseTransactionProcessingResult = LicenseProvisioningResult & {
    transaction?: LicenseTransaction;
};
export type LicenseRevokeResult = object;
export type SuspendLicensesOfTransactionResult = LicenseRevokeResult & {
    transaction?: LicenseTransaction;
};
export type ResumeLicensesOfTransactionResult = {
    transaction?: LicenseTransaction;
};
export type Entitlement = ResourceBase & {
    "type"?: string;
    name?: string;
    description?: string;
    isDefault?: boolean;
};
export type OrganizationGroup = ResourceBase & {
    organizationId?: string;
    name?: string;
    "type"?: string;
    description?: string;
};
export type ObjectIds = string[];
export type ClientGroup = ResourceBase & {
    organizationId?: string;
    name?: string;
    "type"?: string;
    description?: string;
};
export type UserIdentityBase = ResourceBase & {
    displayName?: string;
};
export type LicenseeBase = ResourceBase & {
    objectType?: string;
};
export type LicenseConsumerBase = ResourceBase & {
    objectType?: string;
};
export type UserProfile = UserIdentityBase & LicenseeBase & LicenseConsumerBase & {
    email?: string;
    firstName?: string;
    lastName?: string;
    validFrom?: string;
    validUntil?: string;
    lastSignOn?: string;
    nickname?: string;
    picture?: string;
    phoneNumber?: string;
    professionalTitle?: string;
};
export type LicenseAssignment = ResourceWithValidity & {
    "type"?: "reserved" | "denied" | "floating";
};
export type ExternalEntityReference = {
    "type": string;
    externalId: string;
    externalName?: string;
};
export type LicenseLeaseAnchor = ExternalEntityReference;
export type LicenseLeaseAnchors = LicenseLeaseAnchor[];
export type LicenseLease = ResourceWithValidity & {
    mode?: "Online" | "Offline";
    version?: string;
    anchors?: LicenseLeaseAnchors;
};
export type LicenseLeases = LicenseLease[];
export type LicenseSessionAnchor = ResourceBase & ExternalEntityReference;
export type LicenseSessionAnchors = LicenseSessionAnchor[];
export type LicenseSession = ResourceWithValidity & {
    leases?: LicenseLeases;
    anchors?: LicenseSessionAnchors;
};
export type LicenseSessions = LicenseSession[];
export type LicenseAssignmentWithSessions = LicenseAssignment & {
    sessions?: LicenseSessions;
};
export type LicenseAssignmentsWithSessions = LicenseAssignmentWithSessions[];
export type UserProfileWithAssignmentsAndSessions = UserProfile & {
    assignments?: LicenseAssignmentsWithSessions;
};
export type TechnicalActor = UserIdentityBase & LicenseConsumerBase & {
    username?: string;
};
export type TechnicalUserWithAssignmentsAndSessions = TechnicalActor & {
    assignments?: LicenseAssignmentsWithSessions;
};
export type DeviceClient = LicenseConsumerBase & {
    name?: string;
};
export type DeviceClientWithAssignmentsAndSessions = DeviceClient & {
    assignments?: LicenseAssignmentsWithSessions;
};
export type LicenseUsersWithAssignmentsAndSessions = (({
    objectType: "User";
} & UserProfileWithAssignmentsAndSessions) | ({
    objectType: "TechnicalUser";
} & TechnicalUserWithAssignmentsAndSessions) | ({
    objectType: "DeviceClient";
} & DeviceClientWithAssignmentsAndSessions))[];
export type LicenseUsage = LicenseWithCredits & {
    users?: LicenseUsersWithAssignmentsAndSessions;
};
export type LicenseAssignments = LicenseAssignment[];
export type Organization = LicenseeBase & {
    name?: string;
    "type"?: string;
    description?: string;
};
export type LicenseOwner = ({
    objectType: "User";
} & UserProfile) | ({
    objectType: "Organization";
} & Organization);
export type LicenseWithOwner = LicenseWithCredits & {
    owner?: LicenseOwner;
};
export type LicenseWithOwnerAndSingleUserAssignments = LicenseWithOwner & {
    assignments?: LicenseAssignments;
};
export type LicensesWithOwnerAndSingleUserAssignments = LicenseWithOwnerAndSingleUserAssignments[];
export type LicenseWithOwnerAndSingleUserAssignmentsWithSessions = LicenseWithOwner & {
    assignments?: LicenseAssignmentsWithSessions;
};
export type LicensesWithOwnerAndSingleUserAssignmentsWithSessions = LicenseWithOwnerAndSingleUserAssignmentsWithSessions[];
export type LicenseActivationRequest = {
    grantType: string;
    acceptLocalizedAgreementIds?: string[];
    declineLocalizedAgreementIds?: string[];
};
/**
 * List licensed items of a product package
 */
export function listProductItems(productId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ProductItems;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/products/${encodeURIComponent(productId)}/product-items`, {
        ...opts
    });
}
/**
 * Get a product package
 */
export function getProduct(productId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Product;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/products/${encodeURIComponent(productId)}`, {
        ...opts
    });
}
/**
 * List products
 */
export function listProducts({ filter }: {
    filter?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Products;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/products${QS.query(QS.explode({
        filter
    }))}`, {
        ...opts
    });
}
/**
 * Provision organization licenses in a transaction
 */
export function processOrganizationLicenseTransaction(orgId: string, licenseTransactionRequest: LicenseTransactionRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicenseTransactionProcessingResult;
    } | {
        status: 409;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/license-transactions`, oazapfts.json({
        ...opts,
        method: "POST",
        body: licenseTransactionRequest
    }));
}
/**
 * Update organization licenses of a transaction
 */
export function updateOrganizationLicenseTransaction(orgId: string, licenseTransactionRequest: LicenseTransactionRequest, { allowDeleteLicenses }: {
    allowDeleteLicenses?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicenseTransactionProcessingResult;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/license-transactions${QS.query(QS.explode({
        allowDeleteLicenses
    }))}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: licenseTransactionRequest
    }));
}
/**
 * Get an organization license transaction
 */
export function getOrganizationLicenseTransaction(orgId: string, transactionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicenseTransaction;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/license-transactions/${encodeURIComponent(transactionId)}`, {
        ...opts
    });
}
/**
 * Suspend organization licenses of a transaction
 */
export function suspendOrganizationLicenses(orgId: string, transactionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SuspendLicensesOfTransactionResult;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/license-transactions/${encodeURIComponent(transactionId)}/suspend-licenses`, {
        ...opts,
        method: "PUT"
    });
}
/**
 * Resume suspended organization licenses of a transaction
 */
export function resumeOrganizationLicenses(orgId: string, transactionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ResumeLicensesOfTransactionResult;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/license-transactions/${encodeURIComponent(transactionId)}/resume-licenses`, {
        ...opts,
        method: "PUT"
    });
}
/**
 * List an organization's entitlements
 */
export function listOrganizationEntitlements(orgId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Entitlement[];
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements`, {
        ...opts
    });
}
/**
 * Create an entitlement for an organization
 */
export function addOrganizationEntitlement(orgId: string, entitlement: Entitlement, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: Entitlement;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: 409;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements`, oazapfts.json({
        ...opts,
        method: "POST",
        body: entitlement
    }));
}
/**
 * Get an organization's entitlement
 */
export function getOrganizationEntitlement(orgId: string, entId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Entitlement;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}`, {
        ...opts
    });
}
/**
 * Update an organization's entitlement
 */
export function updateOrganizationEntitlement(orgId: string, entId: string, entitlement: Entitlement, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Entitlement;
    } | {
        status: 403;
        data: ApiError;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: entitlement
    }));
}
/**
 * Delete an organization's entitlement
 */
export function deleteOrganizationEntitlement(orgId: string, entId: string, { moveLicensesToDefaultEntitlement }: {
    moveLicensesToDefaultEntitlement?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 204;
    } | {
        status: 403;
        data: ApiError;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}${QS.query(QS.explode({
        moveLicensesToDefaultEntitlement
    }))}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List user groups with access to an entitlement
 */
export function listLicenseConsumingOrgGroups(orgId: string, entId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroup[];
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/consuming-groups`, {
        ...opts
    });
}
/**
 * Replace user groups that have access to an entitlement
 */
export function setLicenseConsumingOrgGroups(orgId: string, entId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: 409;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/consuming-groups`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Grant a user group access to an entitlement
 */
export function addLicenseConsumingOrgGroup(orgId: string, entId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: 409;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/consuming-groups/${encodeURIComponent(groupId)}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Remove a user group's access to an entitlement
 */
export function removeLicenseConsumingOrgGroup(orgId: string, entId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/consuming-groups/${encodeURIComponent(groupId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get IDs of users with access to an entitlement
 */
export function listEntitlementConsumingUsers(orgId: string, entId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ObjectIds;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/consuming-users`, {
        ...opts
    });
}
/**
 * Grant a user group access to an entitlement
 */
export function addEntitlementConsumedByOrgGroup(orgId: string, entId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: 409;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/groups/${encodeURIComponent(groupId)}/consumed-entitlements/${encodeURIComponent(entId)}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Remove a user group's access to an entitlement
 */
export function removeEntitlementConsumedByOrgGroup(orgId: string, entId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/groups/${encodeURIComponent(groupId)}/consumed-entitlements/${encodeURIComponent(entId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List client groups with access to an entitlement
 */
export function listLicenseConsumingCliGroups(orgId: string, entId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientGroup[];
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/consuming-client-groups`, {
        ...opts
    });
}
/**
 * Replace client groups that have access to an entitlement
 */
export function setLicenseConsumingCliGroups(orgId: string, entId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: 409;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/consuming-client-groups`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Grant a client group access to an entitlement
 */
export function addLicenseConsumingCliGroup(orgId: string, entId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: 409;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/consuming-client-groups/${encodeURIComponent(groupId)}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Remove a client group's access to an entitlement
 */
export function removeLicenseConsumingCliGroup(orgId: string, entId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/consuming-client-groups/${encodeURIComponent(groupId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get IDs of clients with access to an entitlement
 */
export function listEntitlementConsumingClients(orgId: string, entId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ObjectIds;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/consuming-clients`, {
        ...opts
    });
}
/**
 * List entitlements a user group has access to
 */
export function listEntitlementsConsumedByOrgGroup(orgId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Entitlement[];
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/groups/${encodeURIComponent(groupId)}/consumed-entitlements`, {
        ...opts
    });
}
/**
 * Replace entitlements a user group has access to
 */
export function setEntitlementsConsumedByOrgGroup(orgId: string, groupId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: 409;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/groups/${encodeURIComponent(groupId)}/consumed-entitlements`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * List entitlements a client group has access to
 */
export function listEntitlementsConsumedByCliGroup(orgId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Entitlement[];
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/client-groups/${encodeURIComponent(groupId)}/consumed-entitlements`, {
        ...opts
    });
}
/**
 * Replace entitlements a client group has access to
 */
export function setEntitlementsConsumedByCliGroup(orgId: string, groupId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: 409;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/client-groups/${encodeURIComponent(groupId)}/consumed-entitlements`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Grant a client group access to an entitlement
 */
export function addEntitlementConsumedByCliGroup(orgId: string, entId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: 409;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/client-groups/${encodeURIComponent(groupId)}/consumed-entitlements/${encodeURIComponent(entId)}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Remove a client group's access to an entitlement
 */
export function removeEntitlementConsumedByCliGroup(orgId: string, entId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/client-groups/${encodeURIComponent(groupId)}/consumed-entitlements/${encodeURIComponent(entId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List an organization's licenses
 */
export function listEntitlementLicenses(orgId: string, entId: string, { includeEffectiveModel }: {
    includeEffectiveModel?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicensesWithCredits;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/licenses${QS.query(QS.explode({
        includeEffectiveModel
    }))}`, {
        ...opts
    });
}
/**
 * Get current usage of a license
 */
export function queryLicenseUsage(orgId: string, entId: string, licenseId: string, { includeEffectiveModel }: {
    includeEffectiveModel?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicenseUsage;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/licenses/${encodeURIComponent(licenseId)}/usage${QS.query(QS.explode({
        includeEffectiveModel
    }))}`, {
        ...opts
    });
}
/**
 * Get a user's assignments for a license
 */
export function getLicenseAssignments(orgId: string, entId: string, licenseId: string, userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicenseAssignments;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/licenses/${encodeURIComponent(licenseId)}/users/${encodeURIComponent(userId)}/assignments`, {
        ...opts
    });
}
/**
 * Manage a user's assignments for a license
 */
export function manageLicenseAssignments(orgId: string, entId: string, licenseId: string, userId: string, licenseAssignments: LicenseAssignments, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicenseAssignments;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/licenses/${encodeURIComponent(licenseId)}/users/${encodeURIComponent(userId)}/assignments`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: licenseAssignments
    }));
}
/**
 * Get a client's assignments for a license
 */
export function getClientLicenseAssignments(orgId: string, entId: string, licenseId: string, clientId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicenseAssignments;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/licenses/${encodeURIComponent(licenseId)}/clients/${encodeURIComponent(clientId)}/assignments`, {
        ...opts
    });
}
/**
 * Manage a client's assignments for a license
 */
export function manageClientLicenseAssignments(orgId: string, entId: string, licenseId: string, clientId: string, licenseAssignments: LicenseAssignments, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicenseAssignments;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/licenses/${encodeURIComponent(licenseId)}/clients/${encodeURIComponent(clientId)}/assignments`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: licenseAssignments
    }));
}
/**
 * Get licenses available to a user
 */
export function queryAvailableLicenses(userId: string, { product, ownerOrganizationId, includeEffectiveModel, includeAggregatedLicensedItems, onlyValidLicenses }: {
    product?: string;
    ownerOrganizationId?: string;
    includeEffectiveModel?: boolean;
    includeAggregatedLicensedItems?: boolean;
    onlyValidLicenses?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicensesWithOwnerAndSingleUserAssignments;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/users/${encodeURIComponent(userId)}/available-licenses${QS.query(QS.explode({
        product,
        ownerOrganizationId,
        includeEffectiveModel,
        includeAggregatedLicensedItems,
        onlyValidLicenses
    }))}`, {
        ...opts
    });
}
/**
 * Get licenses in use by a user
 */
export function queryUserLicenseUsage(userId: string, { includeEffectiveModel, includeAggregatedLicensedItems }: {
    includeEffectiveModel?: boolean;
    includeAggregatedLicensedItems?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicensesWithOwnerAndSingleUserAssignmentsWithSessions;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/users/${encodeURIComponent(userId)}/license-usage${QS.query(QS.explode({
        includeEffectiveModel,
        includeAggregatedLicensedItems
    }))}`, {
        ...opts
    });
}
/**
 * Provision user licenses with an activation code
 */
export function activateUserLicenses(userId: string, licenseActivationRequest: LicenseActivationRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicenseTransactionProcessingResult;
    } | {
        status: number;
        data: ApiError;
    }>(`/users/${encodeURIComponent(userId)}/activate-licenses`, oazapfts.json({
        ...opts,
        method: "POST",
        body: licenseActivationRequest
    }));
}
/**
 * Provision user licenses in a transaction
 */
export function processUserLicenseTransaction(userId: string, licenseTransactionRequest: LicenseTransactionRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicenseTransactionProcessingResult;
    } | {
        status: 409;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/users/${encodeURIComponent(userId)}/license-transactions`, oazapfts.json({
        ...opts,
        method: "POST",
        body: licenseTransactionRequest
    }));
}
/**
 * Update user licenses of a transaction
 */
export function updateUserLicenseTransaction(userId: string, licenseTransactionRequest: LicenseTransactionRequest, { allowDeleteLicenses }: {
    allowDeleteLicenses?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicenseTransactionProcessingResult;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/users/${encodeURIComponent(userId)}/license-transactions${QS.query(QS.explode({
        allowDeleteLicenses
    }))}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: licenseTransactionRequest
    }));
}
/**
 * Get a user license transaction
 */
export function getUserLicenseTransaction(userId: string, transactionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicenseTransaction;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/users/${encodeURIComponent(userId)}/license-transactions/${encodeURIComponent(transactionId)}`, {
        ...opts
    });
}
/**
 * Suspend user licenses of a transaction
 */
export function suspendUserLicenses(userId: string, transactionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SuspendLicensesOfTransactionResult;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/users/${encodeURIComponent(userId)}/license-transactions/${encodeURIComponent(transactionId)}/suspend-licenses`, {
        ...opts,
        method: "PUT"
    });
}
/**
 * Resume suspended user licenses of a transaction
 */
export function resumeUserLicenses(userId: string, transactionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ResumeLicensesOfTransactionResult;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/users/${encodeURIComponent(userId)}/license-transactions/${encodeURIComponent(transactionId)}/resume-licenses`, {
        ...opts,
        method: "PUT"
    });
}
/**
 * Get licenses available to a client
 */
export function queryClientAvailableLicenses(clientId: string, { product, ownerOrganizationId, includeEffectiveModel, includeAggregatedLicensedItems, onlyValidLicenses }: {
    product?: string;
    ownerOrganizationId?: string;
    includeEffectiveModel?: boolean;
    includeAggregatedLicensedItems?: boolean;
    onlyValidLicenses?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicensesWithOwnerAndSingleUserAssignments;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/clients/${encodeURIComponent(clientId)}/available-licenses${QS.query(QS.explode({
        product,
        ownerOrganizationId,
        includeEffectiveModel,
        includeAggregatedLicensedItems,
        onlyValidLicenses
    }))}`, {
        ...opts
    });
}
/**
 * Get licenses in use by a client
 */
export function queryClientLicenseUsage(clientId: string, { includeEffectiveModel, includeAggregatedLicensedItems }: {
    includeEffectiveModel?: boolean;
    includeAggregatedLicensedItems?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicensesWithOwnerAndSingleUserAssignmentsWithSessions;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/clients/${encodeURIComponent(clientId)}/license-usage${QS.query(QS.explode({
        includeEffectiveModel,
        includeAggregatedLicensedItems
    }))}`, {
        ...opts
    });
}
/**
 * Provision organization licenses with an activation code
 */
export function activateOrganizationLicenses(orgId: string, licenseActivationRequest: LicenseActivationRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicenseTransactionProcessingResult;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/activate-licenses`, oazapfts.json({
        ...opts,
        method: "POST",
        body: licenseActivationRequest
    }));
}
/**
 * Move a license
 */
export function moveLicense(orgId: string, entId: string, licenseId: string, destEntId: string, { includeEffectiveModel }: {
    includeEffectiveModel?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: LicenseWithCredits;
    } | {
        status: 404;
        data: ApiError;
    } | {
        status: 409;
        data: ApiError;
    } | {
        status: number;
        data: ApiError;
    }>(`/organizations/${encodeURIComponent(orgId)}/entitlements/${encodeURIComponent(entId)}/licenses/${encodeURIComponent(licenseId)}/move/${encodeURIComponent(destEntId)}${QS.query(QS.explode({
        includeEffectiveModel
    }))}`, {
        ...opts,
        method: "PUT"
    });
}
