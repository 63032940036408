import {useCallback, useEffect, useRef, useState} from "react";
import TableView, {
  TableStateProps,
  TableProps as _TableProps,
  TableColumn as _TableColumn,
  TABLE_VIEW_SIZE,
} from "./table-view";
import appStorageFactory from "application-storage";
import { IAppStorage } from "application-storage/dist";
import cloneDeep from "lodash/cloneDeep";
import _ from "lodash";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { createRBT2Sort } from "./table-utils";
import { isAddErrorAction } from "../../actions/actionTypes";
import * as ActionTypes from "../../actions/actionTypes";
import { TableFilterInternal } from "./filter-tools/filter-tools-view";
import {TableFilterViewProps} from "./table-filter/table-filter-view";

export type TableColumn = _TableColumn;

export interface TableProps<D>
  extends Omit<_TableProps<D>, keyof TableStateProps> {
  onLoadData?: () => Promise<any>;
  /*
   * Unique key used for persisting ui state
   */
  persistStateKey?: string;
  /**
   *
   */
  allowColumnSort?: boolean;

  filters?: TableFilter[];
}

export interface TableFilter {
  key: string,
  initial: boolean,
  view: (props: TableFilterViewProps) => JSX.Element,
  apply: (itm: any) => boolean,
}
interface ColumnSelection {
  key: string;
  hidden?: boolean;
}

/**
 * Converts and array if columns to an array of ColumnSelection for storing into localstorage as a json string.
 * @param columns Columns to convert
 */
function toColumnSelection(
  columns: TableColumn[] | undefined
): ColumnSelection[] | undefined {
  if (columns) {
    return columns.map((col: TableColumn) => ({
      key: col.key,
      hidden: col.hidden,
    }));
  } else {
    return undefined;
  }
}
function storageValueToFilters(
  storage: IAppStorage | undefined,
  filters?: TableFilterInternal[] | undefined
) {
  const filterSelection: any[] = storage ? storage.getValue() : undefined;
  if (filterSelection) {
    const filts = filterSelection
      .map((sel) => {
        let f = filters?.find((val) => val.key === sel.key);
        if (f) {
          if (f.isActive !== sel.isActive) {
            f?.handler(sel.isActive);
          }
          return {
            ...f,
            isActive: sel.isActive,
            handler: (active: boolean) => {
              storage?.setValue(
                filters?.map((fs) => {
                  if (fs.key === f?.key) {
                    return {
                      key: fs.key,
                      isActive: active,
                    };
                  } else {
                    return {
                      key: fs.key,
                      isActive: fs.isActive,
                    };
                  }
                })
              );
              f?.handler(active);
            },
          } as TableFilterInternal;
        } else {
          return undefined;
        }
      })
        // remove stored not found from available set
        .filter((f) => !!f)
        // add available set
        .concat(...(filters || []))
        // remove duplicates leaving the first encounter
        .filter((f, i, list) => list.findIndex((fi) => !!f && fi?.key === f.key) === i)
    ;
    return filts.length > 0 ? filts as TableFilterInternal[] : undefined;
  } else {
    const rv = filters?.map((f) => {
      return {
        ...f,
        handler: (active: boolean) => {
          storage?.setValue(
            filters?.map((fs) => {
              if (fs.key === f?.key) {
                return {
                  key: fs.key,
                  isActive: active,
                };
              } else {
                return {
                  key: fs.key,
                  isActive: fs.isActive,
                };
              }
            })
          );
          f?.handler(active);
        },
      } as TableFilterInternal;
    });
    return rv && rv.length > 0 ? rv as TableFilterInternal[] : undefined;
  }
}
/**
 * Overrides columns with storage value if found. If storage value includes columns that are not available in columns,
 * the storage value is ignored.
 * @param storage Storage instance to read values from.
 * @param columns Default columns.
 */
function storageValueToColumns(
  storage: IAppStorage | undefined,
  columns: TableColumn[]
): TableColumn[] {
  const columnSelection: ColumnSelection[] = storage
    ? storage.getValue()
    : undefined;
  if (columnSelection) {
    const cols = columnSelection.map((sel) => {
      let c = columns.find((val) => val.key === sel.key);
      if (c) {
        c = cloneDeep(c);
        c.hidden = sel.hidden;
      }
      return c;
    });
    if (cols.findIndex((v) => v === undefined) >= 0) {
      return cloneDeep(columns);
    } else {
      return cols as TableColumn[];
    }
  } else {
    return cloneDeep(columns);
  }
}

/**
 * The underlying table supports hierarchical column field definition, added similar support for resolving column
 * value for search
 * @param row
 * @param colkey
 */
function resolveColumnValue<D>(row: D, col: TableColumn) {
  let retVal = undefined;
  if (col && row) {
    if (col.isDummy) {
      if (col.rendererData && col.rendererData.resolveValue) {
        retVal = col.rendererData.resolveValue(row, col.rendererData);
      }
    } else if (col.key) {
      retVal = (row as any)[col.key];
      if (col.key.indexOf(".")) {
        let tmp = col.key.split(".");
        const parts = tmp
          .map((part) => {
            let rv: string | string[] = part;
            if (part.indexOf("[") && part.endsWith("]")) {
              rv = part.split("[").map((spart) => {
                return spart.replace("]", "");
              });
            }
            return rv;
          })
          .flat(1);
        let v: any = row;
        parts.forEach((part) => {
          if (v) {
            if (
              !isNaN(part as any as number) &&
              v[part as any as number] !== undefined
            ) {
              v = v[part as any as number];
            } else {
              v = v[part];
            }
          }
        });
        retVal = v;
      }
      if (typeof retVal === "boolean" && retVal) {
        // in case of boolean columns with true value, add the column label as searchable text so that it can be used
        // to filter table and only show rows with true as value
        retVal = col.label;
      }
    }
  }
  return retVal;
}

export default function Table<D>(props: TableProps<D>) {
  const {
    persistStateKey,
    columns,
    data,
    activeSearch,
    onSearch,
    sort,
    allowColumnSort,
    onLoadData,
    filters,
    ...other
  } = props;
  const columnsRef = useRef(columns);
  if (!_.isEqual(columnsRef.current, columns)) {
    columnsRef.current = columns;
  }
  const columnsRefCurrent = columnsRef.current;

  const filtersRef = useRef(filters);
  if (!_.isEqual(filtersRef.current, filters)) {
    filtersRef.current = filters;
  }
  const filtersRefRefCurrent = filtersRef.current;

  const [filterState, setFilterState] = useState<{ [key: string]: boolean }|undefined>(undefined);
  /**
   * A reusable callback for creating a map function to use in e.g. effects when converting TableFilter to TableFilterInternal
   */
  const convertToTableFilter = useCallback((myFilterState: { [key: string]: boolean }|undefined, myFilterStorage: IAppStorage | undefined) => {
    return (m: TableFilter) => {
      const handler = (active: boolean) => {
        setFilterState((prev) => {
          let newState: { [key: string]: boolean }|undefined;
          if (prev) {
            if (prev[m.key] === active) {
              newState = prev;
            } else {
              newState = {
                ...prev,
                [m.key]: active,
              };
            }
          } else {
            newState = {
              [m.key]: active,
            };
          }
          myFilterStorage?.setValue(
              newState && Object.keys(newState)?.map((fs) => {
                if (fs === m?.key) {
                  return {
                    key: fs,
                    isActive: active,
                  };
                } else {
                  return {
                    key: fs,
                    // the false should not occur
                    isActive: newState ? newState[fs] : false,
                  };
                }
              })
          );
          return newState;
        });
      }
      const isActive = myFilterState && Object.hasOwn(myFilterState, m.key) ? myFilterState[m.key] : m.initial;
      return {
        key: m.key,
        isActive: isActive,
        handler: handler,
        content: <m.view isActive={m.initial} setActive={handler}></m.view>
      } as TableFilterInternal;
    };
  }, []);
  const [filterStorage, setFilterStorage] = useState(() =>
      persistStateKey
          ? appStorageFactory(persistStateKey + "-filters", "local")
          : undefined
  );
  const [sFilters, setFilters] = useState<TableFilterInternal[] | undefined>(
      storageValueToFilters(
          filterStorage,
          filtersRefRefCurrent?.map(convertToTableFilter(filterState, filterStorage)).filter((f) => !!f) as TableFilterInternal[] | undefined)
  );
  useEffect(() => {
    if (!!filtersRefRefCurrent) {
      setFilters(filtersRefRefCurrent.map(convertToTableFilter(filterState, filterStorage)));
    }
  }, [convertToTableFilter, filterState, filterStorage, filtersRefRefCurrent]);

  const [columnStorage, setColumnStorage] = useState(() =>
    persistStateKey
      ? appStorageFactory(persistStateKey + "-columns", "local")
      : undefined
  );

  const [sortStorage, setSortStorage] = useState(() =>
    persistStateKey
      ? appStorageFactory(persistStateKey + "-sort", "local")
      : undefined
  );
  const [searchStorage, setSearchStorage] = useState(() =>
    persistStateKey
      ? appStorageFactory(persistStateKey + "-search", "local")
      : undefined
  );
  const [viewColumns, setViewColumnState] = useState(() =>
    storageValueToColumns(columnStorage, columns)
  );

  const [activeSort, setActiveSort] = useState(() =>
    persistStateKey
      ? (sortStorage && sortStorage.getValue()) || cloneDeep(sort)
      : cloneDeep(sort)
  );
  const [sActiveSearch, setActiveSearch] = useState<string|undefined>(() =>
      persistStateKey
          ? (searchStorage && searchStorage.getValue()) || activeSearch
          : activeSearch);
  const [initialSearch, setInitialSearch] = useState<string|undefined>(activeSearch);
  const [loading, setLoading] = useState<boolean>(false);


  const setViewColumns = useCallback(
    (cols: TableColumn[]) => {
      setViewColumnState(
        cols.map((v) => {
          v.disableDragging = !allowColumnSort;
          return v;
        })
      );
    },
    [setViewColumnState, allowColumnSort]
  );
  /**
   * As the table component does not support removing a sort, we need to add an index column when initializing
   * for the initial order and sort on that instead. Init postponed to effect to avoid flash of
   * unfiltered/searched/sorted content
   */
  const [cdata, setData] = useState<D[]|undefined>(undefined);
  const [loadingMore, setLoadingMore] = useState(false);
  const [visibleItemCount, setVisibleItemCountState] =
    useState<number>(TABLE_VIEW_SIZE);
  const setVisibleItemCount = useCallback(
    (n: number | ((prev: number) => number)) => {
      setLoadingMore(true);
      setVisibleItemCountState(n);
      setLoadingMore(false);
    },
    []
  );
  const dataRef = useRef(data);
  if (!_.isEqual(dataRef.current, data)) {
    dataRef.current = data;
  }
  const dataRefCurrent = dataRef.current;
  const infiniteScroll = true;
  const datalength = cdata ? cdata.length : 0;
  const [loadMoreSensor] = useInfiniteScroll({
    loading: loadingMore,
    hasNextPage: visibleItemCount < datalength,
    onLoadMore: () => {
      setVisibleItemCount((prev) => prev + TABLE_VIEW_SIZE);
    },
    disabled: infiniteScroll && datalength === 0,
  });
  const [loadLessSensor] = useInfiniteScroll({
    loading: loadingMore,
    hasNextPage: true,
    onLoadMore: () => {
      setVisibleItemCount((prev) => TABLE_VIEW_SIZE);
    },
    disabled: infiniteScroll && visibleItemCount <= TABLE_VIEW_SIZE,
  });

  useEffect(() => {
    if (!loading) {
      let nd = dataRefCurrent
        ? dataRefCurrent.map((d, index) => ({ index, ...d }))
        : dataRefCurrent;
      if (nd && nd.length && sActiveSearch && sActiveSearch.length > 0) {
        nd = nd.filter((itm: any) => {
          let retVal: boolean;
          if (sActiveSearch && sActiveSearch.length > 0) {
            retVal = false;
            for (let i = 0; i < columns.length; i += 1) {
              const colVal = resolveColumnValue<D>(itm, columns[i]);
              retVal = colVal
                ? colVal
                    .toString()
                    .toLowerCase()
                    .indexOf(sActiveSearch ? sActiveSearch.toLowerCase() : "") >=
                  0
                : false;
              if (retVal) {
                break;
              }
            }
          } else {
            retVal = true;
          }
          return retVal;
        });
      }
      if (nd && nd.length && filtersRefRefCurrent) {
        const toApply: ((itm:any) => boolean)[] = [];
        filtersRefRefCurrent.forEach((f) => {
          const actv = filterState && Object.hasOwn(filterState, f.key) ? filterState[f.key] : f.initial;
          if (actv) {
            toApply.push(f.apply);
          }
        });
        nd = nd.filter((d) => {
          let incl = true;
          for (let i = 0; i < toApply.length; i += 1) {
            incl = toApply[i](d);
            if (!incl) {
              break;
            }
          }
          return incl;
        });
      }
      setData(nd);
    }
    setActiveSearch(sActiveSearch);
  }, [dataRefCurrent, sActiveSearch, filtersRefRefCurrent, filterState, loading, columns]);
  useEffect(() => {
    if (persistStateKey && !columnStorage) {
      setColumnStorage(
        appStorageFactory(persistStateKey + "-columns", "local")
      );
    }
    if (persistStateKey && !filterStorage) {
      setFilterStorage(
        appStorageFactory(persistStateKey + "-filters", "local")
      );
    }
    if (persistStateKey && !sortStorage) {
      setSortStorage(appStorageFactory(persistStateKey + "-sort", "local"));
    }
    if (persistStateKey && !searchStorage) {
      setSearchStorage(appStorageFactory(persistStateKey + "-search", "local"));
    }
  }, [
    columnStorage,
    filterStorage,
    sortStorage,
    searchStorage,
    persistStateKey,
  ]);
  useEffect(() => {
    setViewColumns(storageValueToColumns(columnStorage, columnsRefCurrent));
  }, [setViewColumns, columnsRefCurrent, columnStorage]);

  useEffect(() => {
    if (sortStorage) {
      if (activeSort && activeSort.column === "index") {
        // instead of storing the "unsorted" sort by index, we can just remove
        if (sortStorage.getValue()) {
          sortStorage.removeValue();
        }
      } else if (activeSort) {
        sortStorage.setValue(activeSort);
      }
    }
  }, [activeSort, sortStorage]);
  useEffect(() => {
    const currentSearch = searchStorage
      ? searchStorage.getValue() || sActiveSearch
      : sActiveSearch;
    if (currentSearch !== sActiveSearch) {
      if (onSearch) {
        onSearch(currentSearch);
      }
    }
    setVisibleItemCount(TABLE_VIEW_SIZE);
  }, [sActiveSearch, searchStorage, onSearch, setVisibleItemCount]);
  useEffect(() => {
    if (activeSearch !== undefined) {
      setActiveSearch((prev) => prev !== activeSearch ? activeSearch : prev);
      setInitialSearch((prev) => prev !== undefined ? prev : activeSearch);
    }
  }, [activeSearch]);
  const findColumn = useCallback(
    (id: string) => {
      const column = viewColumns.find((c) => `${c.key}` === id);
      return {
        column,
        index: column ? viewColumns.indexOf(column) : -1,
      };
    },
    [viewColumns]
  );
  const [loadFailed, setLoadFailed] = useState(
    !loading && data === undefined && !onLoadData ? true : false
  );
  const dataIsUndefined = data === undefined;

  useEffect(() => {
    if (loading && onLoadData) {
      const dataLoadFailedCallback = (err: any) => {
        setLoading(false);
        setLoadFailed(true);
      };
      setVisibleItemCount(TABLE_VIEW_SIZE);
      const s = new Date().getTime();
      setData(undefined);
      onLoadData()
        .then((x: any) => {
          if (
            isAddErrorAction(x) ||
            (x.type === ActionTypes.MULTI_ACTION &&
              x.results?.find((r: any) => isAddErrorAction(r)))
          ) {
            dataLoadFailedCallback(x);
          } else {
            const r = new Date().getTime();
            if (r - s < 250) {
              setTimeout(() => {
                setLoading(false);
                setLoadFailed(false);
              }, r - s);
            } else {
              // not sure why, but these have to be in a setTimeout or the framework will not render the loading indicator
              // at all
              setTimeout(() => {
                setLoading(false);
                setLoadFailed(false);
              }, 1);
            }
          }
        })
        .catch(dataLoadFailedCallback);
    }
  }, [loading, onLoadData, setVisibleItemCount]);
  const dataLoader = useCallback(() => {
    if (onLoadData) {
      setLoading(true);
      // We can't actually do the loading here, as react state is conveniently async and loading here would effectively
      // cause the loading true state to be completely ignored. As new requirements require the data to be wrapped in
      // another setState call elsewhere before passing to this table, the data load would trigger an infinite loop
      // (loading is always false, once data is loaded it's also false for the first pass, due to the async nature,
      // which causes another load to be triggered => infinite loading loop)
    } else if (dataIsUndefined) {
      setLoading(false);
      setLoadFailed(true);
    }
  }, [dataIsUndefined, onLoadData]);
  useEffect(() => {
    if (dataIsUndefined && !loading && !loadFailed) {
      dataLoader();
    } else if (!dataIsUndefined && loadFailed && !loading ) {
      // data is not undefined, but the loading has completed, so loading has not actually failed
      // State needs to be set, but can't be set with dataloader as that would cause either infinite reloads or no
      // loads at all depending on props due to delay with async state updates.
      setLoadFailed(false);
    }
  }, [dataIsUndefined, dataLoader, loading, loadFailed]);
  const moveColumn = useCallback(
    (id: string, atIndex: number) => {
      const { column, index } = findColumn(id);
      const t: any[] = cloneDeep(viewColumns);
      t.splice(index, 1);
      t.splice(atIndex, 0, column);
      setViewColumns(t);

      if (columnStorage) {
        const vsel = toColumnSelection(t);
        const csel = toColumnSelection(columnsRefCurrent);
        if (JSON.stringify(vsel) !== JSON.stringify(csel)) {
          columnStorage.setValue(vsel);
        } else if (columnStorage.getValue()) {
          columnStorage.removeValue();
        }
      }
    },
    [findColumn, viewColumns, setViewColumns, columnStorage, columnsRefCurrent]
  );
  const onSort = useCallback(
    (column: string, ascending: boolean) => {
      let newSort;
      if (
        activeSort &&
        column === activeSort.column &&
        ascending === false &&
        activeSort.ascending === true
      ) {
        newSort = sort || { column: "index", ascending: true };
      } else {
        newSort = { column, ascending };
      }
      setActiveSort(newSort);
    },
    [activeSort, sort, setActiveSort]
  );

  const [tableHolderElement, setTableHolderElement] = useState<any>(undefined);
  const rtb2Sort = createRBT2Sort(onSort, activeSort);
  const viewProps: _TableProps<D> = {
    tableHolderElement: tableHolderElement,
    setTableHolderElement: setTableHolderElement,
    loadFailed: loadFailed,
    activeSearch: sActiveSearch,
    findColumn: findColumn,
    moveColumn: moveColumn,
    visibleItemCount: visibleItemCount,
    setVisibleItemCount: setVisibleItemCount,
    data: cdata,
    sort: activeSort,
    onSearch: (s: string) => {
      if (searchStorage) {
        if (s && s !== "") {
          searchStorage.setValue(s);
        } else if (searchStorage.getValue()) {
          searchStorage.removeValue();
        }
      }
      setActiveSearch(s);
      if (props.onSearch) {
        props.onSearch(s);
      }
    },
    onSort: onSort,
    onToggleColumn: (key: string, visible: boolean) => {
      const t = viewColumns.map((itm: TableColumn) => {
        if (itm.key === key) {
          itm.hidden = !visible;
        }
        return itm;
      });
      setViewColumns(t);

      if (columnStorage) {
        const vsel = toColumnSelection(t);
        const csel = toColumnSelection(columns);
        if (JSON.stringify(vsel) !== JSON.stringify(csel)) {
          columnStorage.setValue(vsel);
        } else if (columnStorage.getValue()) {
          columnStorage.removeValue();
        }
      }
    },
    columns: viewColumns,
    loadingMore: loadingMore,
    loadMoreSensor: loadMoreSensor,
    loadLessSensor: loadLessSensor,
    rtb2Sort: rtb2Sort,
    ...other,
  };
  viewProps.onReset = () => {
    setVisibleItemCount(TABLE_VIEW_SIZE);
    if (columnStorage) {
      columnStorage.removeValue();
    }
    if (filterStorage) {
      filterStorage.removeValue();
    }
    setFilterState(undefined);

    const t = cloneDeep(columns);
    setViewColumns(t);
    if (columnStorage) {
      const vsel = toColumnSelection(t);
      const csel = toColumnSelection(columns);
      if (JSON.stringify(vsel) !== JSON.stringify(csel)) {
        columnStorage.setValue(vsel);
      } else if (columnStorage.getValue()) {
        columnStorage.removeValue();
      }
    }
    setActiveSort(sort || { column: "index", ascending: true });
    setActiveSearch(initialSearch);
    if (viewProps.onSearch) {
      viewProps.onSearch(initialSearch !== undefined ? initialSearch : "");
    }
    if (props.onReset) {
      props.onReset();
    }
  };
  viewProps.onLoadData = dataLoader;
  viewProps.filters = sFilters && sFilters.length === 0 ? undefined : sFilters;

  return <TableView<D> {...viewProps} />;
}
