import { useIntl } from "react-intl";
import { StatusBadge, StatusBadgeItem } from "@10duke/dukeui";
import { UserWithStatusValues } from "../../../localization/user";
import "./user-status-badge-view.scss";

//<editor-fold desc="Props">
export interface UserStatusBadgeProps {
  status: "inactive" | "admin" | "member" | "suspended" | undefined;
}
//</editor-fold>

function UserStatusBadge(props: UserStatusBadgeProps) {
  //<editor-fold desc="Local variables">
  const intl = useIntl();
  let { status } = props;
  const d: StatusBadgeItem = {
    label: undefined,
    tip: undefined,
    badgeStyle: "",
  };
  if (status === "inactive") {
    d.label = intl.formatMessage(UserWithStatusValues.status.inactive);
    d.tip = intl.formatMessage(
      {
        defaultMessage: "{value}: The user has not logged in for 30 days.",
        description:
          "Tooltip content for user status 'inactive'. 'value' = status label",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
      }
    );
    d.badgeStyle = "user inactive";
  } else if (status === "admin") {
    d.label = intl.formatMessage(UserWithStatusValues.status.admin);
    d.tip = intl.formatMessage(
      {
        defaultMessage: "{value}: The user is an organization admin.",
        description:
          "Tooltip content for user status 'admin'. 'value' = status label",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
      }
    );
    d.badgeStyle = "user admin";
  } else if (status === "suspended") {
    d.label = intl.formatMessage(UserWithStatusValues.status.suspended);
    d.tip = intl.formatMessage(
      {
        defaultMessage:
          "{value}: A suspended user is not able to sign in anymore in order to access their profile information or any applications.",
        description:
          "Tooltip content for user status 'suspended'. 'value' = status label",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
      }
    );
    d.badgeStyle = "user suspended";
  } else if (status === "member") {
    d.label = intl.formatMessage(UserWithStatusValues.status.member);
    d.tip = intl.formatMessage(
      {
        defaultMessage:
          "{value}: The user is an active member of the organization.",
        description:
          "Tooltip content for user status 'member'. 'value' = status label",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
      }
    );
    d.badgeStyle = "user member";
  }
  return <StatusBadge item={d} />;
}
export default UserStatusBadge;
