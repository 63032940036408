import { useIntl } from "react-intl";
import { StatusBadge } from "@10duke/dukeui";
import { OrganizationGroupInvitationValues } from "../../../localization/organization-group-invitation";
import "./invitation-state-badge-view.scss";

//<editor-fold desc="Props">
export interface InvitationStateBadgeProps {
  state:
    | "deliveryRequested"
    | "updated"
    | "created"
    | "revoked"
    | "accepted"
    | "declined"
    | "expired"
    | undefined;
  validFrom: string | undefined;
  validUntil: string | undefined;
}
//</editor-fold>

function InvitationStateBadge(props: InvitationStateBadgeProps) {
  //<editor-fold desc="Local variables">
  const intl = useIntl();
  let {
    state,
    validUntil,
  } = props;
  const d: any = {};
  if (state === "deliveryRequested") {
    d.label = intl.formatMessage(
      OrganizationGroupInvitationValues.invitationState.deliveryRequested
    );
    d.tip = intl.formatMessage(
      {
        defaultMessage: "{value}: Waiting for response from the recipient.",
        description:
          "Tooltip content for invitation status 'deliveryRequested'. 'value' = status label",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
      }
    );
    d.badgeStyle = "invitation pending";
  } else if (state === "updated") {
    d.label = intl.formatMessage(
      OrganizationGroupInvitationValues.invitationState.updated
    );
    d.tip = intl.formatMessage(
      {
        defaultMessage: "{value}: The invitation has been 'updated'.",
        description:
          "Tooltip content for invitation status 'updated'. 'value' = status label",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
      }
    );
    d.badgeStyle = "invitation updated";
  } else if (state === "created") {
    d.label = intl.formatMessage(
      OrganizationGroupInvitationValues.invitationState.created
    );
    d.tip = intl.formatMessage(
      {
        defaultMessage:
          "{value}: The invitation has been created, but not sent to the recipient.",
        description:
          "Tooltip content for invitation status 'created'. 'value' = status label",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
      }
    );
    d.badgeStyle = "invitation unsent";
  } else if (state === "revoked") {
    d.label = intl.formatMessage(
        OrganizationGroupInvitationValues.invitationState.revoked
    );
    d.tip = intl.formatMessage(
        {
          defaultMessage: "{value}: The invitation has been revoked.",
          description:
              "Tooltip content for invitation status 'revoked'. 'value' = status label",
        },
        {
          value: <strong key={"value"}>{d.label}</strong>,
        }
    );
    d.badgeStyle = "invitation revoked";
  } else if (state === "expired") {
    d.label = intl.formatMessage(
        OrganizationGroupInvitationValues.invitationState.expired
    );
    d.tip = intl.formatMessage(
        {
          defaultMessage: "{value}: The invitation has expired {validUntil}",
          description:
              "Tooltip content for invitation status 'expired'. 'value' = status label, 'validUntil' = formatted date for end of validity",
        },
        {
          value: <strong key={"value"}>{d.label}</strong>,
          validUntil: intl.formatDate(
              validUntil === "now()" ? new Date() : validUntil
          ),
        }
    );
    d.badgeStyle = "invitation expired";
  } else if (state === "accepted") {
    d.label = intl.formatMessage(
      OrganizationGroupInvitationValues.invitationState.accepted
    );
    d.tip = intl.formatMessage(
      {
        defaultMessage: "{value}: The recipient has accepted the invitation.",
        description:
          "Tooltip content for invitation status 'accepted'. 'value' = status label",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
      }
    );
    d.badgeStyle = "invitation accepted";
  } else if (state === "declined") {
    d.label = intl.formatMessage(
      OrganizationGroupInvitationValues.invitationState.declined
    );
    d.tip = intl.formatMessage(
      {
        defaultMessage: "{value}: The recipient has declined the invitation.",
        description:
          "Tooltip content for invitation status 'declined'. 'value' = status label",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
      }
    );
    d.badgeStyle = "invitation declined";
  }
  return <StatusBadge item={d} />;
}
export default InvitationStateBadge;
