import { DownloadLicenseModalDataProps } from "./download-license-modal-view";
import DownloadLicenseModal, {
  DownloadLicenseModalProps as _DownloadLicenseModalProps,
} from "./download-license-modal-container";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import { AppState } from "../../../store/AppState";
import InProgressUtils from "../../../utils/in-progress";
import { addSenderArgument } from "../../../actions/actionHelpers";
import { clearError, listOrganizationRolesWithUsers } from "../../../actions";
import { connect } from "react-redux";
import LicenseUtils from "../../../utils/license";
import OrganizationUtils from "../../../utils/organization";
import { downloadLicenses } from "../../../actions/downloadLicenseAction";
import { queryLicenseUsage } from "../../../actions/entActions";

export type DownloadLicenseModalProps = Omit<
  _DownloadLicenseModalProps,
  keyof DownloadLicenseModalDataProps
>;
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _DownloadLicenseModalProps,
  DownloadLicenseModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _DownloadLicenseModalProps,
  DownloadLicenseModalProps
>;

const sender = { key: "download-license" };

function mapStateToProps(
  state: AppState,
  ownProps: DownloadLicenseModalProps
): ReduxStateProps {
  return {
    license: ownProps.licenseId
      ? LicenseUtils.selectLicenseWithUsageAndAssignments(
          ownProps.licenseId,
          state
        )
      : undefined,
    users: OrganizationUtils.selectOrganizationUsers(state),
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationUsersStates(next, prev) &&
    LicenseUtils.compareLicensesStates(next, prev) &&
    LicenseUtils.compareLicenseUsageAndAssignmentsStates(next, prev) &&
    OrganizationUtils.compareOrganizationLicenseIdsStates(next, prev)
  );
}

// Why is this so different from the rest? Why do I need to add this typing or what ever to get this accepted???
const onLoadLicense: (...args: any[]) => any = (licenseId: string) => {
  return queryLicenseUsage(sender, "any", licenseId);
};
const dispatchActions: ReduxDispatchProps = {
  onLoadLicense: onLoadLicense,
  onLoadUsers: addSenderArgument(sender, listOrganizationRolesWithUsers),
  onDownloadLicense: addSenderArgument(sender, downloadLicenses),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  DownloadLicenseModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(DownloadLicenseModal);
