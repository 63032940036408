import { connect } from "react-redux";
import OrganizationUtils from "../../../utils/organization";
import {
  ViewUserModalDataProps,
  ViewUserModalProps as _ViewUserModalProps,
} from "./view-user-modal-view";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import { clearError, getUser } from "../../../actions";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";
import ViewUserModal from "./view-user-modal-container";
import InProgressUtils from "../../../utils/in-progress";
import UserUtils, { UserWithStatus } from "../../../utils/user";

export interface ViewUserModalProps
  extends Omit<_ViewUserModalProps, keyof ViewUserModalDataProps> {
  // for some reason in this case the omit does not remove all keys with keyof  RemoveGroupModalDataProps like it does
  // elsewhere. So far I have not been able to figure out a way to inspect/debug what is actually there to be removed.
}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ViewUserModalProps,
  ViewUserModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ViewUserModalProps,
  ViewUserModalProps
>;

const sender: ActionSender = { key: "vi-user" };

function mapStateToProps(
  state: AppState,
  ownProps: ViewUserModalProps
): ReduxStateProps {
  const user = ownProps.userId
    ? OrganizationUtils.selectOrganizationUser(ownProps.userId, state)
    : undefined;
  let userWithExtra: UserWithStatus | undefined = undefined;
  if (user) {
    userWithExtra = UserUtils.userWithStatus({
      ...user,
    });
  }
  return {
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    user: userWithExtra,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationUsersStates(next, prev)
  );
}
const dispatchActions: ReduxDispatchProps = {
  onLoadUser: addSenderArgument(sender, getUser),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ViewUserModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ViewUserModal);
