import { connect } from "react-redux";
import { ProgressIndicator, ProgressIndicatorProps } from "@10duke/dukeui";
import {PickReduxDispatchProps, PickReduxStateProps} from "../../util/typeUtil";
import {AppState} from "../../store/AppState";
import InProgressUtils from "../../utils/in-progress";

interface InProgressProps extends Omit<ProgressIndicatorProps, "show"> {}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
    ProgressIndicatorProps,
  InProgressProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
    ProgressIndicatorProps,
  InProgressProps
>;
function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    show: InProgressUtils.selectInProgressLength(state) > 0
  };
}
function areStatesEqual(next: AppState, prev: AppState): boolean {
  return InProgressUtils.compareInProgressStates(next, prev);
}
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  InProgressProps,
  AppState
>(mapStateToProps, {}, null, {
  areStatesEqual
})(ProgressIndicator);
