import { connect } from "react-redux";
import OrganizationUtils from "../../../utils/organization";
import {
  RemoveRoleModalDataProps,
  RemoveRoleModalProps as _RemoveRoleModalProps,
} from "./remove-role-modal-view";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import {
  clearError,
  deleteOrganizationRole,
  getOrganizationRole,
  listOrganizationRoles,
} from "../../../actions";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";
import RemoveRoleModal from "./remove-role-modal-container";
import InProgressUtils from "../../../utils/in-progress";

export interface RemoveRoleModalProps
  extends Omit<_RemoveRoleModalProps, keyof RemoveRoleModalDataProps> {}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _RemoveRoleModalProps,
  RemoveRoleModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _RemoveRoleModalProps,
  RemoveRoleModalProps
>;

const sender: ActionSender = { key: "remove-role" };

function mapStateToProps(
  state: AppState,
  ownProps: RemoveRoleModalProps
): ReduxStateProps {
  const orgAdminRoleId = OrganizationUtils.selectOrgAdminRoleId(state);
  return {
    orgAdminRoleId,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    role: ownProps.roleId
      ? OrganizationUtils.selectOrganizationRole(ownProps.roleId, state)
      : undefined,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationRolesStates(next, prev)
  );
}
const dispatchActions: ReduxDispatchProps = {
  onRemoveRole: addSenderArgument(sender, deleteOrganizationRole),
  onLoadRole: addSenderArgument(sender, getOrganizationRole),
  onLoadOrgadminRole: addSenderArgument(sender, listOrganizationRoles),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  RemoveRoleModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(RemoveRoleModal);
