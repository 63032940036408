import * as ActionTypes from "./actionTypes";
import { ActionSender } from "../model/ActionSender";
import { ThunkDispatch, ThunkAction } from "redux-thunk";
import { AppState } from "../store/AppState";
import { addApiError } from "./errorActions";
import { errorToApiError } from "../api";
import { clearInProgress, startInProgress } from "./inProgressActions";
import { getSelectedOrSingleOrgId } from "../store";
import { Dispatch } from "redux";

/**
 * Adds the sender as first argument when actionCreater is called.
 * @param sender The action sender to add
 * @param actionCreator The action creator getting called.
 */
export function addSenderArgument(
  sender: ActionSender,
  actionCreator: (s: ActionSender, ...args: any[]) => any
): (...args: any[]) => any {
  return (...args: any[]) => actionCreator(sender, ...args);
}
/**
 * Ensures selected organization id for the caller. Primarily uses
 * orgId given by the caller. If caller has not given orgId, uses
 * orgId selected in the application.
 * @param orgId Organization id optionally given by the caller
 */
export function ensureSelectedOrgId(orgId?: string): string {
  const orgIdOrDefault = orgId ? orgId : getSelectedOrSingleOrgId();
  if (!orgIdOrDefault) {
    throw new Error(
      "Organization must be selected for loading organization users"
    );
  }
  return orgIdOrDefault;
}

/**
 * Builds thunk action with progress and error handling.
 * @template A Type of the action dispatched by the thunk
 * @template R Return type of the operation executed by the thunk. The operation
 *    result can be used for building the action to dispatch.
 * @param sender Component that triggered the action
 * @param actionType The action type to be used as "type" value of the
 *    action that is eventually dispatched.
 * @param operation The operation to run before dispatching action,
 *    result of which can be used to populate fields of the dispatched action.
 * @param resultToDispatch Builds the event object to dispatch.
 *
 *    This method is first called with the undefined actionResult parameter for
 *    building action representing initial / input state of the operation. This is
 *    used for in-progress and error communication.
 *
 *    After successfully completing the operation, this method is called with
 *    actionResult that is the value returned by the operation. This is used
 *    for sending the actual action that communicates the successful operation
 *    result.
 */
export function buildActionThunk<A extends ActionTypes.AppAction, R>(
  sender: ActionSender,
  actionType: string,
  operation: () => Promise<R>,
  resultToDispatch: (type: string, actionResult?: R) => A
): ActionTypes.AppThunkAction<A> {
  return async (
    dispatch: ThunkDispatch<AppState, unknown, ActionTypes.AppAction>
  ): Promise<A | ActionTypes.AddErrorAction<A>> => {
    const inputAction = resultToDispatch(actionType, undefined);
    const inProgr = await dispatch(
      startInProgress(sender, actionType, inputAction)
    );

    try {
      const result = await operation();
      const dispatchAction = resultToDispatch(actionType, result);
      return dispatch(dispatchAction);
    } catch (err) {
      return dispatch(
        addApiError(
          sender,
          actionType,
          errorToApiError(err as Error),
          inputAction,
          inProgr.operation.operationId
        )
      );
    } finally {
      dispatch(clearInProgress(inProgr.operation.operationId));
    }
  };
}

/**
 * Operation executed as part of a composite action (multi-action). Each operation
 * returns an event that is dispatched to redux. If operation fails, it is expected
 * to throw an AddErrorAction that is dispatched to redux.
 */
export interface EventOperation<A extends ActionTypes.AppAction> {
  func?: () => A;
  thunk?: ActionTypes.AppThunkAction<A>;
  promise?: Promise<A>;
}

/**
 * Provides event operations to be used as parts of a composite action (multi-action).
 */
export interface EventOperationProvider<A extends ActionTypes.AppAction> {
  /**
   * Called for getting the next operation to execute as a part of a multi-action.
   * @param sender Component that triggered the multi-action for which event operations are provided.
   * @param multiAction Current status of the multi-action being executed.
   * @returns EventOperation describing the next action, or null if there are no more actions to execute.
   */
  next(
    sender: ActionSender,
    multiAction: ActionTypes.MultiAction
  ): EventOperation<A> | null;
}

/**
 * Builds an EventOperationProvider for the given array of EventOperations.
 * @param eventOperations The operations to run. Each operation is expected either to return
 *    an action object describing successfully executed operation, or to throw an AddErrorAction
 *    object describing operation error.
 * @returns EventOperationProvider that provides the given EventOperations.
 */
export function buildEventOperationProviderForArray<
  A extends ActionTypes.AppAction
>(eventOperations: EventOperation<A>[]): EventOperationProvider<A> {
  return new (class implements EventOperationProvider<A> {
    private i: number;
    private operations: EventOperation<A>[];
    constructor(operations: EventOperation<A>[]) {
      this.i = 0;
      this.operations = operations;
    }
    next(
      sender: ActionSender,
      multiAction: ActionTypes.MultiAction
    ): EventOperation<A> | null {
      if (this.i > this.operations.length) {
        return null;
      }

      return this.operations[this.i++];
    }
  })(eventOperations);
}

/**
 * Builds a thunk action for multiple internally dispatched actions, with progress and error handling.
 * @template A Type of the pseudo-action returned by the thunk. If type other than MultiAction
 *    is specified, the resultBuilder function should also be specified for building the action
 *    object to return.
 * @param sender Component that triggered the action
 * @param eventOperationProvider Provider for the operations to run. Each provided operation is expected either
 *    to return an action object describing successfully executed operation, or to throw an AddErrorAction
 *    object describing operation error.
 * @param continueAfterError Specifies whether processing should be continued if an error is
 *    thrown by an operation. Default is false.
 * @param resultBuilder Called after proocessing the multi-action is completed to allow the caller to extend
 *    the result. If not given, the result MultiAction object built by the multi-action execution is
 *    returned as such.
 * @returns Returns a MultiAction event object. This MultiAction itself has not been dispatched,
 *    but the "results" field of the object contains all the actually dispatched actions.
 *    There will always be one object in "results" for each executed operation, in the same order.
 *    However, if execution is discontinued by an error, there will be no item in "results"
 *    for operations that were not executed.
 */
export function buildMultiActionThunk<
  A extends ActionTypes.MultiAction = ActionTypes.MultiAction
>(
  sender: ActionSender,
  eventOperationProvider: EventOperationProvider<ActionTypes.AppAction>,
  continueAfterError: boolean = false,
  resultBuilder:
    | ((result: ActionTypes.MultiAction) => A)
    | undefined = undefined
): ActionTypes.AppThunkAction<A> {
  return async (
    dispatch: ThunkDispatch<AppState, unknown, ActionTypes.AppAction>
  ): Promise<A> => {
    let retValue: ActionTypes.MultiAction = {
      type: ActionTypes.MULTI_ACTION,
      results: [],
    };
    const inProgr = await dispatch(
      startInProgress(sender, ActionTypes.MULTI_ACTION, retValue)
    );

    try {
      for (
        let eventOperation = eventOperationProvider.next(sender, retValue);
        eventOperation != null;
        eventOperation = eventOperationProvider.next(sender, retValue)
      ) {
        try {
          const dispatchAction = await executeOperation(eventOperation);
          retValue = {
            ...retValue,
            results: [...retValue.results, dispatchAction],
          };
          dispatch(dispatchAction);
        } catch (err) {
          const addErrorAction = ActionTypes.isAddErrorAction(err)
            ? (err as ActionTypes.AddErrorAction<ActionTypes.AppAction>)
            : addApiError(
                sender,
                ActionTypes.MULTI_ACTION,
                errorToApiError(err as Error),
                retValue,
                inProgr.operation.operationId
              );
          retValue = {
            ...retValue,
            results: [...retValue.results, addErrorAction],
          };
          dispatch(addErrorAction);
          if (!continueAfterError) {
            break;
          }
        }
      }
    } finally {
      dispatch(clearInProgress(inProgr.operation.operationId));
    }

    return resultBuilder ? resultBuilder(retValue) : (retValue as A);
  };
}

/**
 * Does type conversion to allow assigning the given object
 * to variable of type T, event if the given object is undefined.
 *
 * Please note that this call is unsafe. Intended to be used
 * when buildActionThunk calls resultToDispatch method for building
 * operation input state object that does not have result object
 * available.
 *
 * @param obj Optional object
 * @returns The given object typed to T. If the given object is undefined,
 *    the returned value is still undefined.
 */
export function forceUndefined<T>(obj?: T): T {
  return obj as T;
}

/**
 * Executes the given operation. If result of the operation is an AddErrorAction,
 * this method throws the AddErrorAction.
 *
 * @param operation The operation to execute.
 * @returns Action for communicating result of the executed operation.
 */
async function executeOperation<A extends ActionTypes.AppAction>(
  operation: EventOperation<A>
): Promise<A> {
  let resultAction;
  if (operation.thunk) {
    resultAction = await executeThunk(operation.thunk);
  } else if (operation.func) {
    resultAction = operation.func();
  } else if (operation.promise) {
    resultAction = await operation.promise;
  } else {
    throw new Error("Found no operation to execute");
  }

  if (ActionTypes.isAddErrorAction(resultAction)) {
    const addError = resultAction as ActionTypes.AddErrorAction<any>;
    throw addError;
  }

  return resultAction;
}

/**
 * Executes the given thunk.
 * @param thunk The thunk action to execute.
 */
async function executeThunk<A extends ActionTypes.AppAction>(
  thunk: ActionTypes.AppThunkAction<A>
): Promise<A | ActionTypes.AddErrorAction<A>> {
  const dispatch: Dispatch<A> = <T extends A>(action: T) => {
    if (typeof action === "function") {
      const asyncAction = action as ThunkAction<T, any, {}, T>;
      return asyncAction(dispatch, () => {}, {});
    }
    return action;
  };
  return await thunk(dispatch, () => ({}), undefined);
}

/**
 * Find first AddError action from list of actions.
 * Returns undefined if none found.
 *
 * @param results source array of actions.
 */
export function findFirstAddErrorAction<A extends ActionTypes.AppAction>(
  results: ActionTypes.ActionResult<ActionTypes.AppAction>[]
): ActionTypes.AddErrorAction<A> | undefined {
  const error:
    | ActionTypes.AppAction
    | ActionTypes.AddErrorAction<ActionTypes.AppAction>
    | undefined = results.find((result) =>
    ActionTypes.isAddErrorAction(result)
  );

  if (error) {
    return error as ActionTypes.AddErrorAction<A>;
  }

  return undefined;
}
