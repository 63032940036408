import { useEffect, useRef, useState } from "react";
import ActionErrorNotificationView, {
  ActionErrorNotificationProps as _ActionErrorNotificationProps,
  CombinedError,
} from "./action-error-notification-view";
import _ from "lodash";
import { AppError } from "../../model/AppError";
import { AppAction, ClearErrorAction } from "../../actions/actionTypes";

export interface ActionErrorNotificationProps
  extends Pick<_ActionErrorNotificationProps, "onClearError"> {
  errors?: AppError<AppAction>[];
  onClearError: (errorId: string) => ClearErrorAction;
}

export default function ActionErrorNotification(
  props: ActionErrorNotificationProps
) {
  const { errors, ...other } = props;

  const errorsRef = useRef(errors);
  if (!_.isEqual(errorsRef.current, errors)) {
    errorsRef.current = errors;
  }
  const errorsRefCurrent = errorsRef.current;

  const [combinedErrors, onSetCombinedErrors] = useState<CombinedError[]>([]);

  useEffect(() => {
    const newErrors: CombinedError[] = [];
    if (errorsRefCurrent) {
      errorsRefCurrent.forEach((er) => {
        const existing = newErrors.find(
          (ce) =>
            ce.sender === er.actionSender?.key &&
            ce.operationType === er.operationType
        );
        if (existing) {
          existing.errors.push(er);
        } else {
          newErrors.push({
            id: er.errorId,
            sender: er.actionSender?.key || "unknown",
            operationType: er.operationType,
            errors: [er],
          });
        }
      });
    }
    onSetCombinedErrors(newErrors);
  }, [errorsRefCurrent]);

  return (
    <ActionErrorNotificationView combinedErrors={combinedErrors} {...other} />
  );
}
