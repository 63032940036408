import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import { buildActionThunk, forceUndefined } from "./actionHelpers";
import { OrganizationRole } from "../model/OrganizationRole";

/**
 * Lists organization roles assigned to a user.
 * @param sender Component requesting for the action
 * @param userId The user id
 */
export function listOrganizationRolesOfUser(
  sender: ActionSender,
  userId: string
): ActionTypes.AppThunkAction<ActionTypes.ListOrgRolesOfUserAction> {
  return buildActionThunk<
    ActionTypes.ListOrgRolesOfUserAction,
    OrganizationRole[]
  >(
    sender,
    ActionTypes.LIST_ORG_ROLES_OF_USER,
    async () => await idpApi.listOrganizationRolesOfUser(userId),
    (type, roles) => ({
      type,
      roles: forceUndefined(roles),
      userId,
    })
  );
}

/**
 * Adds a user to an organization role.
 * @param sender Component requesting for the action
 * @param orgRoleId The role id
 * @param userId The user id
 */
export function addOrganizationRoleForUser(
  sender: ActionSender,
  orgRoleId: string,
  userId: string
): ActionTypes.AppThunkAction<ActionTypes.AddOrgRoleForUserAction> {
  return buildActionThunk<ActionTypes.AddOrgRoleForUserAction, void>(
    sender,
    ActionTypes.ADD_ORG_ROLE_FOR_USER,
    async () => await idpApi.addOrganizationRoleForUser(orgRoleId, userId),
    (type) => ({
      type,
      orgRoleId,
      userId,
    })
  );
}
export function addOrganizationRolesForUser(
  sender: ActionSender,
  orgRoleIds: string[],
  userId: string
): ActionTypes.AppThunkAction<ActionTypes.AddOrgRolesForUserAction> {
  return buildActionThunk<ActionTypes.AddOrgRolesForUserAction, void>(
    sender,
    ActionTypes.ADD_ORG_ROLES_FOR_USER,
    async () => await idpApi.addOrganizationRolesForUser(orgRoleIds, userId),
    (type) => ({
      type,
      orgRoleIds,
      userId,
    })
  );
}

export function removeOrganizationRolesOfUser(
  sender: ActionSender,
  orgRoleIds: string[],
  userId: string
): ActionTypes.AppThunkAction<ActionTypes.RemoveOrgRolesOfUserAction> {
  return buildActionThunk<ActionTypes.RemoveOrgRolesOfUserAction, void>(
    sender,
    ActionTypes.REMOVE_ORG_ROLES_OF_USER,
    async () => await idpApi.removeOrganizationRolesOfUser(orgRoleIds, userId),
    (type) => ({
      type,
      orgRoleIds,
      userId,
    })
  );
}
