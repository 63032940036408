import { defineMessages } from "react-intl";
import {ClientLabels} from "../client";


/**
 * Localized labels for Client fields
 */
export const Oauth20ClientLabels = {
  ...ClientLabels,
  // Add AuthorEditorFields if needed
  ...defineMessages({
    clientId: {
      defaultMessage: "clientId",
      description:
        "Field label for device client id (Oauth20Client.id)"
    },
  })
};


export const Oauth20ClientValues = {
};
