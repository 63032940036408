import { defineMessages } from "react-intl";
import { ValidityFieldsLabels } from "../validity-fields";

/**
 * Localized labels for "UI Model" License fields
 */
export const LicenseConsumptionLabels = {
  //...BaseObjLabels,
  // Add AuthorEditorFields if needed
  ...ValidityFieldsLabels,
  ...defineMessages({
    from: {
      defaultMessage: "From:",
      description:
        "Short field label for the start time of license lease period (LicenseConsumption.from)",
    },
    until: {
      defaultMessage: "Until:",
      description:
        "Short field label for the end of license lease period (LicenseConsumption.to)",
    },
    hardwareId: {
      defaultMessage: "HW ID:",
      description:
        "Field label for hardware id field (LicenseConsumption.hardwareId)",
    },
    hardwareName: {
      defaultMessage: "HW Name:",
      description:
        "Field label for hardware name field (LicenseConsumption.hardwareName)",
    },
  }),
};
