import { useEffect, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {FeedbackEntry, Feedback, Modal} from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import {
  ClearErrorAction, DeleteOrgClientAction, GetOrgClientAction,
  isAddErrorAction,
} from "../../../actions/actionTypes";
import {Client} from "../../../model/Client";

//<editor-fold desc="Props">

export interface RemoveDeviceClientModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface RemoveDeviceClientModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  client?: Client | null;
  onDeleteClient: (id: string) => Promise<DeleteOrgClientAction>;
  onLoadClient: (id: string) => Promise<GetOrgClientAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface RemoveDeviceClientModalProps
  extends RemoveDeviceClientModalDOMProps,
    RemoveDeviceClientModalDataProps,
    Omit<ClosableModalProps, "isReady"> {
  clientId?: string;
  onShowFeedback: (feedback: FeedbackEntry) => void;
}
//</editor-fold>

function RemoveDeviceClientModal(props: RemoveDeviceClientModalProps) {
  //<editor-fold desc="Local variables">
  let {
    client,
    clientId,
    show,
    onClose,
    onDeleteClient,
    onLoadClient,
    onShowFeedback,
    isReady,
    onExited,
    onClearError,
  } = props;

  const clientObjId = client ? client.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!clientId &&
      (clientObjId === undefined || (!!clientObjId && clientObjId !== clientId)) &&
      !!onLoadClient
    ) {
      onLoadClient(clientId).then((res) => {
        if (!clientObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, clientId, clientObjId, onLoadClient, onClearError]);
  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>

  return (
    <Modal
      onReloadData={() => {
        if ((client || clientId) && onLoadClient) {
          onLoadClient(client ? (client.id as string) : (clientId as string)).then(
            (res) => {
              if (!client && isAddErrorAction(res)) {
                // only clear error if no data exists, leave if previous data is still available and
                // let the api error notification handler show error
                onClearError(res.error?.errorId);
              }
            }
          );
        }
      }}
      onExited={onExited}
      isReady={isReady}
      data-test-remove-client-modal={client ? (client.id as string) : true}
      title={
        !isReady || client
          ? intl.formatMessage(
              {
                defaultMessage: "Remove {name}",
                description: "modal heading. 'name' = the name of the device client",
              },
              {
                name: client?.name || "",
              }
            )
          : intl.formatMessage({
              defaultMessage: "Device client not found",
              description: "modal heading when device client not found",
            })
      }
      show={show}
      onClose={onClose}
      primaryButton={
        client
          ? {
              label: intl.formatMessage({
                defaultMessage: "Remove",
                description: "primary button label",
              }),
            }
          : {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
      }
      onPrimaryAction={() => {
        if (show && client) {
          onDeleteClient(client.id as string).then(
            (res) => {
              if (isAddErrorAction(res)) {
                onClearError(res.error?.errorId);
                onShowFeedback({
                  id: "remove_" + (client ? (client.id as string) : ""),
                  msg: intl.formatMessage(
                    {
                      defaultMessage:
                        "Removing {name} failed: {msg}",
                      description:
                        "Failure notification. 'name' = name of the device client, 'msg' = error message",
                    },
                    {
                      name:
                        "<strong>" + (client?.name || "") + "</strong>",
                      msg: res.error.apiError.error_description,
                    }
                  ),
                  type: "danger",
                });
              } else {
                onShowFeedback({
                  id: "remove_" + (client ? (client.id as string) : ""),
                  msg: intl.formatMessage(
                    {
                      defaultMessage: "{name} removed.",
                      description:
                        "Success notification. 'name' =  name of the device client",
                    },
                    {
                      name:
                        "<strong>"+ (client?.name || "") + "</strong>",
                    }
                  ),
                  autoClose: true,
                  type: "success",
                });
              }
            },
            (rej) => {
              onClearError(rej.error?.errorId);
              onShowFeedback({
                id: "remove_" + (client ? (client.id as string) : ""),
                msg: intl.formatMessage(
                  {
                    defaultMessage:
                      "Removing {name} failed: {msg}",
                    description:
                      "Failure notification. 'name' = name of the device client, 'msg' = error message",
                  },
                  {
                    name:
                      "<strong>"+ (client?.name || "") + "</strong>",
                  }
                ),
                type: "danger",
              });
            }
          );
        }
        onClose();
      }}
      secondaryButton={
        client
          ? {
              label: intl.formatMessage({
                defaultMessage: "Cancel",
                description: "secondary button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={client ? onClose : undefined}
    >
      <>
        {show && client && (
          <p>
            <FormattedMessage
              defaultMessage="Are you sure you wish to remove {name}?"
              description="confirm action message. 'name' = name of the device client"
              values={{
                name: <strong>{client?.name}</strong>,
              }}
            />
          </p>
        )}
        {isReady && show && !client && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p>
              <FormattedMessage
                defaultMessage="Something went wrong and the device client could not be loaded. The device client may have been removed or you don't have sufficient access rights."
                description="message to be shown when there is no device client to display"
              />
            </p>
          </Feedback>
        )}
      </>
    </Modal>
  );
}

export default RemoveDeviceClientModal;
