import {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    HTMLAttributes,
    useId,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {IconLibrary, TooltipWrapper, FormInput, FormInputUtils, FeedbackEntry, Feedback, Modal, Button} from "@10duke/dukeui";
import { OrganizationGroupInvitation } from "../../../model/OrganizationGroupInvitation";
import { FormattedMessage, useIntl } from "react-intl";
import { ClosableModalProps } from "../closable-modal-props";
import {
    ClearErrorAction,
    CreateOrgGroupInvitationAction,
    isAddErrorAction,
    SendOrgGroupInvitationAction,
} from "../../../actions/actionTypes";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { getEnvParam } from "../../../util/env";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
import Table from "../../table/table-container";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import { ModalKeys } from "../../pages/groups";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import "./create-invitation-modal-view.scss";
import RecipientListItem from "./recipient-list-item";
import isEmail from "../../../util/isEmail";
import NavigateAfterAction from "../../navigate-after-action";
import UserUtils from "../../../utils/user";
import { OrganizationGroupInvitationLabels } from "../../../localization/organization-group-invitation";
import { asDukeLocale } from "../../localize/localize-view";
import {hasAction} from "../../../ui-configuration/configuration-tools";

//<editor-fold desc="Utils">
export interface InvitationRecipient {
    email: string;
    recipientName: string;
}

export const CREATE_INVITATION_CSV_IMPORT_LIMIT = 250;

function processResults(
    result: InvitationRecipient[],
    recipients: InvitationRecipient[]
) {
    const retVal: {
        [key: string]: InvitationRecipient[];
    } = {
        valid: [],
        missingData: [],
        duplicates: [],
    };
    result.forEach((v, ind: number) => {
        if (!isEmail(v.email) || v.recipientName === "") {
            retVal.missingData.push(v);
        } else if (result.findIndex((vi) => vi.email === v.email) !== ind) {
            retVal.duplicates.push(v);
        } else if (recipients.findIndex((vi) => vi.email === v.email) >= 0) {
            retVal.duplicates.push(v);
        } else {
            retVal.valid.push(v);
        }
    });
    return retVal;
}
/**
 * Environment parameter for invitation welcome url.
 */
const APP_INVITATION_WELCOME_URL = "REACT_APP_INVITATION_WELCOME_URL";

//</editor-fold>

//<editor-fold desc="Props">

export interface CreateInvitationModalDOMProps
    extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}

export interface CreateInvitationModalStateProps {
    onSetSelected: (selection: OrganizationGroup[]) => void;
    onSetSelectionTouched: (b: boolean) => void;
    selectionTouched: boolean;
    dirtySelection: boolean;
    activeSearch?: string;
    onSetActiveSearch: (s: string) => void;
    selectionFixed: boolean;
    fileInputRef: any;
    onFileChanged: () => Promise<any>;
    setImportIssue: (obj: any) => void;
    importIssue: any;
    editIndex: number;
    setEditIndex: (n: number) => void;
    recipients: InvitationRecipient[];
    addRecipient: (add: InvitationRecipient | InvitationRecipient[]) => void;
    removeRecipient: (index: number) => void;
    updateRecipient: (index: number, itm: InvitationRecipient) => void;
    disableListButton: number;
    disableDuplicatedListButton: number;
    disableMissingDataListButton: number;
    missingDataRecipients: InvitationRecipient[];
    setMissingDataRecipients: (r: InvitationRecipient[]) => void;
    editMissingDataIndex: number;
    setEditMissingDataIndex: (n: number) => void;
    duplicatedDataRecipients: InvitationRecipient[];
    setDuplicatedDataRecipients: (r: InvitationRecipient[]) => void;
    editDuplicatedDataIndex: number;
    setEditDuplicatedDataIndex: (n: number) => void;
    submitting: boolean;
    setSubmitting: (b: boolean) => void;
    showAllDuplicated: boolean;
    setShowAllDuplicated: (b: boolean) => void;
    showAllMissingData: boolean;
    setShowAllMissingData: (b: boolean) => void;
    showRecipients: boolean;
    setShowRecipients: (b: boolean) => void;
}
export interface CreateInvitationModalVisibilityProps
    extends Pick<
        ClosableModalProps,
        "show" | "onClose" | "onExited"
    > {
    onShowFeedback: (feedback: FeedbackEntry) => void;
}
export interface CreateInvitationModalInputProps
    extends CreateInvitationModalVisibilityProps {
    selected?: OrganizationGroup[];
}
export interface CreateInvitationModalDataProps
    extends Pick<ClosableModalProps, "isReady"> {
    organizationId: string | null;
    organizationName: string;
    orgAdminRoleId?: string | null;
    onCreateInvitation: (
        invitation: OrganizationGroupInvitation
    ) => Promise<CreateOrgGroupInvitationAction | SendOrgGroupInvitationAction>;
    onLoadOrgadminRole?: () => void;
    userName?: string;
    groups: OrganizationGroup[] | undefined;
    onLoadGroups?: () => void;
    onClearError: (errorId: string) => ClearErrorAction;
}

export interface CreateInvitationModalProps
    extends CreateInvitationModalDOMProps,
        CreateInvitationModalStateProps,
        CreateInvitationModalDataProps,
        CreateInvitationModalInputProps {}
//</editor-fold>
function resolveDisablePrimary(
    hasSelection: boolean,
    formIsValid: boolean,
    formIsDirty: boolean,
    errors: any | undefined | null,
    formIsValid_add: boolean,
    formIsDirty_add: boolean,
    errors_add: any | undefined | null,
    recipients: InvitationRecipient[],
    editOpen: boolean,
    duplicatedDataRecipients: InvitationRecipient[],
    missingDataRecipients: InvitationRecipient[]
) {
    return editOpen ||
    (duplicatedDataRecipients && duplicatedDataRecipients.length) ||
    (missingDataRecipients && missingDataRecipients.length) ||
    !hasSelection ||
    !formIsValid ||
    (!formIsDirty_add && recipients.length === 0) ||
    (!formIsValid_add && formIsDirty_add) ||
    (errors !== undefined && errors !== null && Object.keys(errors).length > 0)
        ? true
        : (recipients.length > 0 ||
            (formIsValid_add &&
                formIsDirty_add &&
                (!errors_add || Object.keys(errors_add).length <= 0))) &&
        recipients.length <= CREATE_INVITATION_CSV_IMPORT_LIMIT
            ? false
            : true;
}
function CreateInvitationModal(props: CreateInvitationModalProps) {
    //<editor-fold desc="Local variables">
    let {
        selectionFixed,
        onCreateInvitation,
        show,
        onClose,
        onShowFeedback,
        organizationId,
        userName,
        organizationName,
        orgAdminRoleId,
        groups,
        onLoadOrgadminRole,
        selected,
        onSetSelected,
        activeSearch,
        onSetActiveSearch,
        selectionTouched,
        onLoadGroups,
        onSetSelectionTouched,
        isReady,
        fileInputRef,
        onFileChanged,
        importIssue,
        setImportIssue,
        setEditIndex,
        editIndex,
        recipients,
        addRecipient,
        removeRecipient,
        updateRecipient,
        disableListButton,
        missingDataRecipients,
        setMissingDataRecipients,
        editMissingDataIndex,
        setEditMissingDataIndex,
        duplicatedDataRecipients,
        setDuplicatedDataRecipients,
        editDuplicatedDataIndex,
        setEditDuplicatedDataIndex,
        submitting,
        setSubmitting,
        disableDuplicatedListButton,
        disableMissingDataListButton,
        showAllDuplicated,
        setShowAllDuplicated,
        showAllMissingData,
        setShowAllMissingData,
        showRecipients,
        setShowRecipients,
        onExited,
        dirtySelection,
        onClearError,
    } = props;
    const handleUpload = () => {
        fileInputRef.current?.click();
    };
    // this is more like a variable than a hook
    const intl = useIntl();
    if (show && !orgAdminRoleId && !onLoadOrgadminRole) {
        throw new Error(
            "CreateInvitationModal: Required props missing. orgAdminRoleId or onLoadOrgadminRole must be provided"
        );
    }

    const { conf } = useContext(UIConfiguration);
    const groupConf =
        conf.functionality && conf.functionality.groups
            ? conf.functionality.groups
            : {};
    //</editor-fold>

    //<editor-fold desc="Hooks">
    const defaultValues_add = useMemo<{
        email: string;
        recipientName: string;
    }>(
        () => ({
            email: "",
            recipientName: "",
        }),
        []
    );
    const defaultValues_edit = useMemo<InvitationRecipient>(() => {
        let retVal;
        if (editIndex >= 0 && editIndex < recipients.length) {
            retVal = { ...recipients[editIndex] };
        } else if (
            editMissingDataIndex >= 0 &&
            editMissingDataIndex < missingDataRecipients.length
        ) {
            retVal = { ...missingDataRecipients[editMissingDataIndex] };
        } else if (
            editDuplicatedDataIndex >= 0 &&
            editDuplicatedDataIndex < duplicatedDataRecipients.length
        ) {
            retVal = { ...duplicatedDataRecipients[editDuplicatedDataIndex] };
        } else {
            retVal = {
                email: "",
                recipientName: "",
            };
        }
        return retVal;
    }, [
        editIndex,
        recipients,
        editMissingDataIndex,
        missingDataRecipients,
        editDuplicatedDataIndex,
        duplicatedDataRecipients,
    ]);
    const defaultValues = useMemo<{
        assignAdmin: boolean;
        invitationScopeInformation: string;
    }>(
        () => ({
            assignAdmin: false,
            invitationScopeInformation: intl.formatMessage(
                {
                    defaultMessage:
                        "{admin} has invited you to create an account with {organization}.",
                    description:
                        "Default invitation message value. 'admin' = name of the inviter, 'organization' = name of the targeted organization.",
                },
                {
                    admin: userName,
                    organization: organizationName,
                }
            ),
        }),
        [intl, userName, organizationName]
    );
    const {
        register,

        // setValue,
        handleSubmit,

        // control,
        watch,

        formState,
        reset,
        trigger,
    } = useForm({
        mode: "onTouched",
        defaultValues: defaultValues,
    });

    const { errors } = formState;

    const {
        register: register_add,

        // control: control_add,
        watch: watch_add,

        formState: formState_add,
        reset: reset_add,
        trigger: trigger_add,
    } = useForm({
        mode: "onChange",
        defaultValues: defaultValues_add,
    });

    const {
        // setValue: setValue_add,
        // handleSubmit: handleSubmit_add,
        errors: errors_add,
    } = formState_add;

    const {
        register: register_edit,

        // setValue: setValue_add,
        handleSubmit: handleSubmit_edit,

        // control: control_add,
        watch: watch_edit,

        formState: formState_edit,
        reset: reset_edit,
        trigger: trigger_edit,
    } = useForm({
        mode: "onChange",
        defaultValues: defaultValues_edit,
    });

    const { errors: errors_edit } = formState_edit;

    const formValues = watch();
    const formValues_add = watch_add();
    const formValues_edit = watch_edit();
    const hasSelection = selected ? selected.length > 0 : false;
    const formIsValid = formState.isValid;
    const formIsDirty = formState.isDirty;
    const formIsValid_add = formState_add.isValid;
    const formIsDirty_add = formState_add.isDirty;
    const formIsValid_edit = formState_edit.isValid;

    const resolveEditFormValidity = FormInputUtils.validityResolver({
        ...formState_edit,
        ...{
            touchedFields: {
                email: true,
                recipientName: true,
            },
        },
    });

    const idPrefix = useId();
    useEffect(() => {
        reset(defaultValues);
    }, [show, reset, defaultValues]);

    useEffect(() => {
        reset_add(defaultValues_add);
    }, [show, reset_add, defaultValues_add]);
    useEffect(() => {
        reset_edit(defaultValues_edit);
        const timer = setTimeout(() => {
            trigger_edit();
        }, 50);
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [show, reset_edit, trigger_edit, defaultValues_edit]);
    useEffect(() => {
        if (show && orgAdminRoleId === undefined && onLoadOrgadminRole) {
            onLoadOrgadminRole();
        }
    }, [show, orgAdminRoleId, onLoadOrgadminRole]);

    useEffect(() => {
        if (show && groups === undefined && onLoadGroups) {
            onLoadGroups();
        }
    }, [show, groups, onLoadGroups]);

    const handleRecipientResults = useCallback(
        (result: InvitationRecipient[], recipients: InvitationRecipient[]) => {
            const t = processResults(result, recipients);
            setImportIssue(undefined);
            setShowAllDuplicated(false);
            setEditIndex(-1);
            setEditMissingDataIndex(-1);
            setEditDuplicatedDataIndex(-1);
            if (t.valid && t.valid.length) {
                addRecipient(t.valid);
            }
            if (t.missingData && t.missingData.length) {
                setMissingDataRecipients(t.missingData);
            }
            if (t.duplicates && t.duplicates.length) {
                setDuplicatedDataRecipients(t.duplicates);
            }
            setTimeout(() => {
                trigger();
                trigger_add();
            }, 50);
        },
        [
            setImportIssue,
            setShowAllDuplicated,
            setEditIndex,
            setEditMissingDataIndex,
            setEditDuplicatedDataIndex,
            addRecipient,
            setMissingDataRecipients,
            setDuplicatedDataRecipients,
            trigger,
            trigger_add,
        ]
    );
    //</editor-fold>

    const onSubmitInvitation = (d: any) => {
        return Promise.allSettled(
            d.recipients.map((r: any) => {
                let data: any = {
                    email: r.email,
                    recipientName: r.recipientName,
                    groupIds: d.groupIds,
                    invitationScopeInformation: d.invitationScopeInformation,
                };
                data.organizationId = organizationId as string;
                data.organizationRoleIds = d.assignAdmin ? [orgAdminRoleId] : [];

                const loc = asDukeLocale(intl.locale);
                /* eslint-disable no-template-curly-in-string */
                /* tslint:disable:no-template-curly-in-string */
                // Create the url with the constructor to ensure that current domain is used as base, in case the url is relative
                data.memberWelcomeUrl = new URL(
                    getEnvParam(APP_INVITATION_WELCOME_URL).replace(
                        "${locale}",
                        !!loc && intl.locale !== intl.defaultLocale ? "/" + loc : ""
                    ),
                    window.location.href
                ).toString();
                /* tslint:enable:no-template-curly-in-string */
                /* eslint-enable no-template-curly-in-string */
                data.inviterName = userName;
                data.validFrom = "now()";
                if (!!loc) {
                    const l = loc.split("-");
                    if (l.length === 2) {
                        data.recipientCountryCode = l[1].toUpperCase();
                    }
                    data.recipientLanguageCode = l[0];
                }
                return onCreateInvitation(data as OrganizationGroupInvitation);
            })
        );
    };
    /**
     * This flag must be resolved here, trying the same code in jsx causes react to mess things up with useless and
     * unrelated error messages. This may or may not be related to how we use the useForm and reacts redraw logic.
     */
    const collapsedRecipientsHasErrors =
        !showRecipients &&
        recipients.length > 3 &&
        (importIssue ||
            missingDataRecipients.length > 0 ||
            duplicatedDataRecipients.length > 0 ||
            !formIsValid_edit ||
            !formIsValid_add);

    return (
        <Modal
            onExited={onExited}
            className={"create-invitation-modal"}
            isReady={submitting ? false : isReady}
            onReloadData={() => {
                if (onLoadGroups) {
                    onLoadGroups();
                }
                if (onLoadOrgadminRole) {
                    onLoadOrgadminRole();
                }
            }}
            data-test-create-invitation-modal
            title={
                selectionFixed
                    ? intl.formatMessage(
                        {
                            defaultMessage: "Invite users to {groups}",
                            description:
                                "modal heading when a preselected target group exists. 'groups' = list of targeted group names",
                        },
                        {
                            groups: selected ? selected.map((s) => s.name).join(", ") : "",
                        }
                    )
                    : intl.formatMessage({
                        defaultMessage: "Invite users",
                        description:
                            "modal heading when there is no preselected group target",
                    })
            }
            show={show}
            onClose={onClose}
            backdrop={
                formIsDirty ||
                dirtySelection ||
                formIsDirty_add ||
                recipients.length ||
                importIssue ||
                duplicatedDataRecipients.length ||
                missingDataRecipients.length
                    ? "static"
                    : true
            }
            primaryButton={{
                label: intl.formatMessage({
                    defaultMessage: "Invite",
                    description: "primary button label",
                }),
                disabled: resolveDisablePrimary(
                    hasSelection,
                    formIsValid,
                    formIsDirty,
                    errors,
                    formIsValid_add,
                    formIsDirty_add,
                    errors_add,
                    recipients,
                    editIndex !== -1,
                    duplicatedDataRecipients,
                    missingDataRecipients
                ),
                tooltip:
                    (errors !== undefined &&
                        errors !== null &&
                        Object.keys(errors).length > 0) ||
                    (errors_add !== undefined &&
                        errors_add !== null &&
                        Object.keys(errors_add).length > 0) ||
                    (errors_edit !== undefined &&
                        errors_edit !== null &&
                        Object.keys(errors_edit).length > 0) ||
                    (selectionTouched && !hasSelection) ||
                    (duplicatedDataRecipients && duplicatedDataRecipients.length) ||
                    (missingDataRecipients && missingDataRecipients.length)
                        ? intl.formatMessage({
                            defaultMessage:
                                "Please correct errors before sending the invitation.",
                            description:
                                "tooltip for the disabled primary button when there are validation errors",
                        })
                        : (!selectionTouched && !hasSelection) || // no group selection, no error displayed
                        ((!recipients || recipients.length === 0) && !formIsValid_add) // no recipients
                            ? intl.formatMessage({
                                defaultMessage: "Please fill in the fields first.",
                                description:
                                    "tooltip for the disabled primary button when there is nothing to create/send",
                            })
                            : editIndex !== -1
                                ? intl.formatMessage({
                                    defaultMessage:
                                        "Please apply or cancel the active edits in the recipient list.",
                                    description:
                                        "tooltip for the disabled primary button when there are unapplied edits in the inputs",
                                })
                                : recipients.length > CREATE_INVITATION_CSV_IMPORT_LIMIT
                                    ? intl.formatMessage(
                                        {
                                            defaultMessage:
                                                "Only {max} recipients can be invited at a time, please remove recipients to send invite.",
                                            description:
                                                "tooltip for the disabled primary button when there are too many recipients. 'max' = maximum number allowed",
                                        },
                                        { max: CREATE_INVITATION_CSV_IMPORT_LIMIT }
                                    )
                                    : undefined,
            }}
            onPrimaryAction={() => {
                setSubmitting(true);
                setTimeout(() => {
                    handleSubmit(
                        (data: any) => {
                            const d = {
                                ...data,
                                groupIds: selected ? selected.map((s) => s.id as string) : [],
                                recipients: recipients,
                            };
                            if (
                                formValues_add &&
                                formValues_add.email &&
                                formValues_add.recipientName &&
                                formIsValid_add
                            ) {
                                if (d.recipients) {
                                    d.recipients.push({
                                        email: formValues_add.email,
                                        recipientName: formValues_add.recipientName,
                                    });
                                } else {
                                    d.recipients = [
                                        {
                                            email: formValues_add.email,
                                            recipientName: formValues_add.recipientName,
                                        },
                                    ];
                                }
                            }
                            onSubmitInvitation(d).then((res) => {
                                const tmp = (res as any as any[]).map((r, ind) => ({
                                    ...r.value,
                                    index: ind,
                                }));
                                const suc = tmp.filter(
                                    (v) =>
                                        v.results &&
                                        !v.results.find((r: any) => isAddErrorAction(r))
                                );
                                const fail = tmp.filter(
                                    (v) =>
                                        !v.results ||
                                        v.results.find((r: any) => isAddErrorAction(r))
                                );
                                if (suc && suc.length) {
                                    let emails: string;
                                    if (suc.length <= 3) {
                                        emails = suc
                                            .map((r: any) =>
                                                r &&
                                                r.results &&
                                                r.results[0] &&
                                                r.results[0].invitation &&
                                                r.results[0].invitation.email
                                                    ? r.results[0].invitation.email
                                                    : false
                                            )
                                            .filter((r) => !!r)
                                            .join(", ");
                                    } else {
                                        emails = intl.formatMessage(
                                            {
                                                defaultMessage: "{count} recipients",
                                                description:
                                                    "injected into other copies in place of email address list when there are too many to show. count is the number of addresses",
                                            },
                                            { count: suc.length }
                                        );
                                    }
                                    onShowFeedback({
                                        id: "invitation_success",
                                        msg: intl.formatMessage(
                                            {
                                                defaultMessage: "Invitation sent to {email}.",
                                                description:
                                                    "success notification for sent invitation. 'email' = list of recipients email addresses or fallback if too many",
                                            },
                                            {
                                                email: "<strong>" + emails + "</strong>",
                                            }
                                        ),
                                        autoClose: true,
                                        type: "success",
                                    });
                                }
                                if (!!fail && fail.length) {
                                    fail.forEach((f) => {
                                        f.results?.forEach((fr: any) => {
                                            onClearError(fr.error?.errorId);
                                        });
                                    });
                                    const create = fail.filter(
                                        (f) =>
                                            !!f.results &&
                                            f.results.find(
                                                (fr: any) =>
                                                    fr.error?.operationType ===
                                                    "CREATE_ORG_GROUP_INVITATION"
                                            )
                                    );
                                    const send = fail.filter(
                                        (f) =>
                                            !!f.results &&
                                            !!f.results.find(
                                                (fr: any) =>
                                                    fr.error?.operationType ===
                                                    "SEND_ORG_GROUP_INVITATION"
                                            )
                                    );
                                    const fallback = fail.filter(
                                        (f) =>
                                            !!f.results &&
                                            !!f.results.find(
                                                (fr: any) =>
                                                    fr.error &&
                                                    fr.error?.operationType !==
                                                    "SEND_ORG_GROUP_INVITATION" &&
                                                    fr.error?.operationType !==
                                                    "CREATE_ORG_GROUP_INVITATION"
                                            )
                                    );
                                    const resolveEmailsLabel = (list: any[]) => {
                                        let retVal: string;
                                        if (list.length <= 3) {
                                            retVal = list
                                                .map((r: any) =>
                                                    r &&
                                                    r.index >= 0 &&
                                                    d &&
                                                    d.recipients &&
                                                    d.recipients[r.index] &&
                                                    d.recipients[r.index].email
                                                        ? d.recipients[r.index].email
                                                        : false
                                                )
                                                .filter((r) => !!r)
                                                .join(", ");
                                        } else {
                                            retVal = intl.formatMessage(
                                                {
                                                    defaultMessage: "{count} recipients",
                                                    description:
                                                        "injected into other copies in place of email address list when there are too many to show. count is the number of addresses",
                                                },
                                                {
                                                    count: list.length,
                                                }
                                            );
                                        }
                                        return retVal;
                                    };
                                    if (create && create.length) {
                                        let emails: string = resolveEmailsLabel(create);
                                        onShowFeedback({
                                            id: "invitation_createFailed",
                                            msg: intl.formatMessage(
                                                {
                                                    defaultMessage:
                                                        "Creating invitation failed for {email}.",
                                                    description:
                                                        "failure notification for creating invitation. 'email' = list of recipients email addresses or fallback if too many",
                                                },
                                                {
                                                    email: "<strong>" + emails + "</strong>",
                                                }
                                            ),
                                            type: "danger",
                                        });
                                    }
                                    if (send && send.length) {
                                        let emails: string = resolveEmailsLabel(send);
                                        onShowFeedback({
                                            id: "invitation_sendFailed",
                                            msg: intl.formatMessage(
                                                {
                                                    defaultMessage:
                                                        "Sending invitation email failed for {email}.",
                                                    description:
                                                        "failure notification for Sending invitation. 'email' = list of recipients email addresses or fallback if too many",
                                                },
                                                {
                                                    email: "<strong>" + emails + "</strong>",
                                                }
                                            ),
                                            type: "danger",
                                        });
                                    }
                                    if (fallback && fallback.length) {
                                        let emails: string = resolveEmailsLabel(fallback);
                                        onShowFeedback({
                                            id: "invitation_failed",
                                            msg: intl.formatMessage(
                                                {
                                                    defaultMessage: "Inviting user failed for {email}.",
                                                    description:
                                                        "failure notification for unspecified invitation error. 'email' = list of recipients email addresses or fallback if too many",
                                                },
                                                {
                                                    email: "<strong>" + emails + "</strong>",
                                                }
                                            ),
                                            type: "danger",
                                        });
                                    }
                                }
                                setSubmitting(false);
                                onClose();
                            });
                        },
                        () => {
                            // this should never happen as we validate input before submit
                            setSubmitting(false);
                        }
                    )();
                }, 1);
            }}
            secondaryButton={{
                label: intl.formatMessage({
                    defaultMessage: "Cancel",
                    description: "secondary button label",
                }),
            }}
            onSecondaryAction={onClose}
        >
            <>
                <Feedback show={true} type="info" asChild={true}>
                    <p>
                        <FormattedMessage
                            defaultMessage="Users can be invited by uploading user data in CSV format. The CSV file should contain column headers on the first row to allow mapping data. The CSV must contain values for at least the following required data fields {fields}. File limit is {limit} entries per CSV file."
                            description={
                                "CSV upload info 1. 'fields' = list of required fields, 'limit' = max entries per file"
                            }
                            values={{
                                fields: ["email", "recipientName"].map((v, i, a) => (
                                    <span key={v}>
                    <strong key={v}>
                      {intl.formatMessage(
                          (OrganizationGroupInvitationLabels as any)[v]
                      )}
                    </strong>
                                        {i < a.length - 1 ? ", " : ""}
                  </span>
                                )),
                                limit: <strong>{CREATE_INVITATION_CSV_IMPORT_LIMIT}</strong>,
                            }}
                        />
                    </p>
                    <p>
                        <FormattedMessage
                            defaultMessage="Each user invited will receive an email from no-reply@edtech.ti.com. The email provides instructions on how to accept the invitation and create an account. Please ensure that users can receive email from no-reply@edtech.ti.com."
                            description={"CSV upload info 2"}
                        />
                    </p>
                </Feedback>
                <div className={"recipients-heading"}>
                    <h4 className={collapsedRecipientsHasErrors ? "has-errors" : ""}>
                        {recipients && recipients.length > 3 && (
                            <a
                                href={"#show-all"}
                                className={"inherit"}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowRecipients(!showRecipients);
                                }}
                            >
                                {intl.formatMessage({
                                    defaultMessage: "Recipients",
                                    description: "section heading",
                                })}{" "}
                                {(recipients && recipients.length > 0) ||
                                (formIsValid_add && formIsDirty_add) ? (
                                    <span>
                    (
                                        {recipients
                                            ? recipients.length +
                                            (formIsValid_add && formIsDirty_add ? 1 : 0)
                                            : formIsValid_add && formIsDirty_add
                                                ? 1
                                                : undefined}
                                        )
                  </span>
                                ) : undefined}
                                {collapsedRecipientsHasErrors ? (
                                    <TooltipWrapper
                                        tip={intl.formatMessage({
                                            defaultMessage: "Unresolved errors",
                                            description:
                                                "tooltip content for collapsed section with unresolved errors",
                                        })}
                                        tipKey={"recipientTitle"}
                                        placement={"auto"}
                                    >
                                        <FontAwesomeIcon
                                            icon={IconLibrary.icons.faExclamationTriangle}
                                            className={"icon"}
                                        />
                                    </TooltipWrapper>
                                ) : !showRecipients &&
                                recipients.length > 3 &&
                                (editIndex >= 0 ||
                                    editDuplicatedDataIndex >= 0 ||
                                    editMissingDataIndex >= 0) ? (
                                    <TooltipWrapper
                                        tip={intl.formatMessage({
                                            defaultMessage: "Active edit is open.",
                                            description:
                                                "tooltip content for collapsed section with unapplied changes",
                                        })}
                                        tipKey={"recipientTitle"}
                                        placement={"auto"}
                                    >
                                        <FontAwesomeIcon icon={IconLibrary.icons.faPen} className={"icon edit"} />
                                    </TooltipWrapper>
                                ) : undefined}
                                {showRecipients && (
                                    <FontAwesomeIcon icon={IconLibrary.icons.faChevronDown} className={"icon"} />
                                )}
                                {!showRecipients && (
                                    <FontAwesomeIcon icon={IconLibrary.icons.faChevronRight} className={"icon"} />
                                )}
                            </a>
                        )}
                        {(!recipients || recipients.length <= 3) && (
                            <>
                                {intl.formatMessage({
                                    defaultMessage: "Recipients",
                                    description: "section heading",
                                })}{" "}
                                {(recipients && recipients.length > 0) ||
                                (formIsValid_add && formIsDirty_add) ? (
                                    <span>
                    (
                                        {recipients
                                            ? recipients.length +
                                            (formIsValid_add && formIsDirty_add ? 1 : 0)
                                            : formIsValid_add && formIsDirty_add
                                                ? 1
                                                : undefined}
                                        )
                  </span>
                                ) : undefined}
                            </>
                        )}
                    </h4>
                    <div
                        className={"import-button-holder"}
                    >
                        <Button
                            type={"button"}
                            data-test-upload-csv-button
                            disabled={recipients.length >= CREATE_INVITATION_CSV_IMPORT_LIMIT}
                            size={"sm"}
                            variant={"primary"}
                            className={"import-button btn custom-base"}
                            action={handleUpload}
                            tooltip={
                                recipients.length >= CREATE_INVITATION_CSV_IMPORT_LIMIT
                                    ? intl.formatMessage({
                                        defaultMessage:
                                            "Maximum amount of invitation recipients reached.",
                                        description:
                                            "tooltip for disabled upload recipients button when max count has been reached",
                                    })
                                    : undefined
                            }
                        >
                            {intl.formatMessage({
                                defaultMessage: "Import CSV",
                                description: "upload recipients button label",
                            })}
                        </Button>
                    </div>
                </div>
                <div
                    className={
                        !showRecipients && recipients.length > 3 ? "d-none" : undefined
                    }
                >
                    {importIssue && (
                        <Feedback
                            show={true}
                            type={"danger"}
                            asChild={true}
                            id={idPrefix + "importIssue"}
                        >
                            <>
                                {!importIssue.hasOwnProperty("emailIndex") &&
                                    importIssue.tooManyItems && (
                                        <p data-test-import-issue="invalid">
                                            {intl.formatMessage(
                                                {
                                                    defaultMessage:
                                                        "The uploaded file contains {count} items, only {max} can be invited at a time.",
                                                    description:
                                                        "error message for when csv contains too many items. 'count' = item count, 'max' = the limit",
                                                },
                                                {
                                                    count: importIssue.tooManyItems,
                                                    max: CREATE_INVITATION_CSV_IMPORT_LIMIT,
                                                }
                                            )}
                                        </p>
                                    )}
                                {!importIssue.hasOwnProperty("emailIndex") &&
                                    !importIssue.tooManyItems && (
                                        <p data-test-import-issue="invalid">
                                            {intl.formatMessage({
                                                defaultMessage: "The uploaded file is not a valid CSV.",
                                                description:
                                                    "error message for when the uploaded file could not be read",
                                            })}
                                        </p>
                                    )}
                                {importIssue.hasOwnProperty("emailIndex") && (
                                    <>
                                        {importIssue.emailIndex === -1 && (
                                            <p data-test-import-issue="data">
                                                {intl.formatMessage({
                                                    defaultMessage:
                                                        "The CSV did not contain any valid email addresses.",
                                                    description:
                                                        "error message for when email column could not be resolved from the uploaded file",
                                                })}
                                            </p>
                                        )}
                                        {importIssue.emailIndex !== -1 &&
                                            importIssue.nameIndex === -1 && (
                                                <>
                                                    <p data-test-import-issue="data">
                                                        {intl.formatMessage({
                                                            defaultMessage:
                                                                "The CSV had more than 2 columns and we could not identify the Name column. Please select the Name column to import recipients or dismiss this error to continue.",
                                                            description:
                                                                "error message for when name column could not be resolved from the uploaded file",
                                                        })}
                                                    </p>
                                                    <FormControl
                                                        data-test-select-column
                                                        as={"select"}
                                                        onChange={(e: any) => {
                                                            if (
                                                                e &&
                                                                e.target &&
                                                                e.target.value &&
                                                                parseInt(e.target.value) >= 0
                                                            ) {
                                                                const t = { ...importIssue };
                                                                t.nameIndex = parseInt(e.target.value);
                                                                const result = t.output
                                                                    .map((r: any, ind: number) => {
                                                                        if (!t.firstRowIsColumns || ind > 0) {
                                                                            return {
                                                                                email: r[t.emailIndex],
                                                                                recipientName: r[t.nameIndex],
                                                                            };
                                                                        } else {
                                                                            return undefined;
                                                                        }
                                                                    })
                                                                    .filter((v: any) => !!v);
                                                                handleRecipientResults(result, recipients);
                                                            }
                                                        }}
                                                    >
                                                        <option key={"-1"} value={-1}>
                                                            {intl.formatMessage({
                                                                defaultMessage: "Select the name column",
                                                                description:
                                                                    "Name column mapping dropdown default option",
                                                            })}
                                                        </option>
                                                        {importIssue.output[0].map(
                                                            (c: string, ind: number) =>
                                                                ind !== importIssue.emailIndex ? (
                                                                    <option key={"" + ind} value={ind}>
                                                                        {c}
                                                                    </option>
                                                                ) : undefined
                                                        )}
                                                    </FormControl>
                                                </>
                                            )}
                                    </>
                                )}
                            </>
                            <Button
                                data-test-resolve-import-issue
                                variant={"danger"}
                                className={"btn custom-base ci-feedback-btn"}
                                action={() => {
                                    setImportIssue(undefined);
                                    setShowAllDuplicated(false);
                                }}
                                type={"button"}
                            >
                                {intl.formatMessage({
                                    defaultMessage: "Dismiss",
                                    description: "dismiss file errors button label",
                                })}
                            </Button>
                        </Feedback>
                    )}
                    <Feedback
                        id={idPrefix + "missing-data"}
                        show={missingDataRecipients && missingDataRecipients.length > 0}
                        type={"danger"}
                        asChild={true}
                    >
                        <p>
                            {intl.formatMessage({
                                defaultMessage:
                                    "The imported CSV contained entries with invalid or missing data. Please fix or dismiss entries before continuing.",
                                description:
                                    "Error message for invalid data entries in uploaded file",
                            })}
                        </p>
                        <Form noValidate>
                            <div className={"content"}>
                                {missingDataRecipients && (
                                    <>
                                        <h6>
                      <span>
                        {intl.formatMessage(
                            {
                                defaultMessage: "Invalid recipients ({count})",
                                description:
                                    "Error heading for invalid recipients. 'count' = the amount of invalid recipients",
                            },
                            {
                                count: (
                                    <strong>{missingDataRecipients.length}</strong>
                                ),
                            }
                        )}
                      </span>
                                            {missingDataRecipients.length > 3 && showAllMissingData && (
                                                <Button
                                                    type={"button"}
                                                    size={"sm"}
                                                    variant={"secondary"}
                                                    className="btn custom-base"
                                                    action={() => {
                                                        setShowAllMissingData(false);
                                                    }}
                                                >
                                                    {intl.formatMessage({
                                                        defaultMessage: "Show less",
                                                        description:
                                                            "collapse invalid recipient errors button label",
                                                    })}
                                                </Button>
                                            )}
                                        </h6>
                                        <ul className={"list-unstyled"}>
                                            {missingDataRecipients.map((r, ind) =>
                                                showAllMissingData || ind < 3 ? (
                                                    <RecipientListItem
                                                        key={r.email + ind}
                                                        item={r}
                                                        showEdit={editMissingDataIndex === ind}
                                                        onShowEdit={(f: boolean) => {
                                                            if (f) {
                                                                setEditMissingDataIndex(ind);
                                                                setEditIndex(-1);
                                                                setEditDuplicatedDataIndex(-1);
                                                            } else {
                                                                setEditMissingDataIndex(-1);
                                                            }
                                                        }}
                                                        disableEditButtons={
                                                            disableMissingDataListButton === ind
                                                        }
                                                        onDismissItem={() => {
                                                            setMissingDataRecipients(
                                                                missingDataRecipients.filter(
                                                                    (v, index) => index !== ind
                                                                )
                                                            );
                                                        }}
                                                        resolveEditFormValidity={resolveEditFormValidity}
                                                        formValues={formValues_edit}
                                                        register={register_edit}
                                                        errors={errors_edit}
                                                        formIsValid={formIsValid_edit}
                                                        onApplyItem={() => {
                                                            handleSubmit_edit((data: any) => {
                                                                addRecipient(data);
                                                                setMissingDataRecipients(
                                                                    missingDataRecipients.filter(
                                                                        (v, vind) => vind !== ind
                                                                    )
                                                                );
                                                                setEditMissingDataIndex(-1);
                                                                setTimeout(() => {
                                                                    trigger_add();
                                                                }, 50);
                                                            })();
                                                        }}
                                                        isDuplicate={(value: string) => {
                                                            let retVal =
                                                                recipients.findIndex(
                                                                    (f) =>
                                                                        f.email.toLowerCase() ===
                                                                        value.toLowerCase()
                                                                ) >= 0;
                                                            if (!retVal) {
                                                                const fInd = missingDataRecipients.findIndex(
                                                                    (f) =>
                                                                        f.email.toLowerCase() ===
                                                                        value.toLowerCase()
                                                                );
                                                                retVal = fInd >= 0 && fInd !== ind;
                                                            }
                                                            return retVal;
                                                        }}
                                                        editTip={intl.formatMessage({
                                                            defaultMessage: "Fix entry",
                                                            description:
                                                                "Edit invalid recipient button tooltip",
                                                        })}
                                                        dismissTip={intl.formatMessage({
                                                            defaultMessage: "Dismiss entry",
                                                            description:
                                                                "Remove invalid recipient button tooltip",
                                                        })}
                                                    />
                                                ) : undefined
                                            )}
                                            {missingDataRecipients.length > 3 && (
                                                <>
                                                    {!showAllMissingData && (
                                                        <li>
                                                            <a
                                                                href={"#show-all"}
                                                                className={"toggle-all"}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowAllMissingData(true);
                                                                    return false;
                                                                }}
                                                            >
                                                                {intl.formatMessage(
                                                                    {
                                                                        defaultMessage: "Show all ({count})",
                                                                        description:
                                                                            "show all invalid recipient errors button label. 'count' = amount of invalid recipients",
                                                                    },
                                                                    {
                                                                        count: missingDataRecipients.length,
                                                                    }
                                                                )}
                                                            </a>
                                                        </li>
                                                    )}
                                                    {showAllMissingData && (
                                                        <li>
                                                            <a
                                                                href={"#hide-all"}
                                                                className={"toggle-all"}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowAllMissingData(false);
                                                                    return false;
                                                                }}
                                                            >
                                                                {intl.formatMessage({
                                                                    defaultMessage: "Show less",
                                                                    description:
                                                                        "collapse invalid recipient errors button label",
                                                                })}
                                                            </a>
                                                        </li>
                                                    )}
                                                </>
                                            )}
                                        </ul>
                                    </>
                                )}
                            </div>
                            <Button
                                data-test-dismiss-all-button
                                type={"button"}
                                variant={"danger"}
                                className="btn custom-base ci-feedback-btn"
                                action={() => {
                                    setMissingDataRecipients([]);
                                    setEditMissingDataIndex(-1);
                                }}
                            >
                                {intl.formatMessage({
                                    defaultMessage: "Dismiss all",
                                    description: "Dismiss all invalid recipients button label",
                                })}
                            </Button>
                        </Form>
                    </Feedback>
                    <Feedback
                        id={idPrefix + "duplicated-data"}
                        show={
                            duplicatedDataRecipients && duplicatedDataRecipients.length > 0
                        }
                        type={"danger"}
                        asChild={true}
                    >
                        <p>
                            {intl.formatMessage({
                                defaultMessage:
                                    "The imported CSV contained entries with duplicate emails. Please fix or dismiss entries before continuing.",
                                description:
                                    "Error message for duplicated emails in uploaded file",
                            })}
                        </p>
                        <Form noValidate>
                            <div className={"content"}>
                                {duplicatedDataRecipients && (
                                    <>
                                        <h6>
                      <span>
                        {intl.formatMessage(
                            {
                                defaultMessage: "Duplicated recipients ({count}).",
                                description:
                                    "Error title for duplicated emails in uploaded file. 'count' = the amount of duplicates",
                            },
                            {
                                count: (
                                    <strong>{duplicatedDataRecipients.length}</strong>
                                ),
                            }
                        )}
                      </span>
                                            {duplicatedDataRecipients.length > 3 &&
                                                showAllDuplicated && (
                                                    <Button
                                                        type={"button"}
                                                        variant={"secondary"}
                                                        size={"sm"}
                                                        className="btn custom-base"
                                                        action={() => {
                                                            setShowAllDuplicated(false);
                                                        }}
                                                    >
                                                        {intl.formatMessage({
                                                            defaultMessage: "Show less",
                                                            description:
                                                                "collapse invalid recipient errors button label",
                                                        })}
                                                    </Button>
                                                )}
                                        </h6>
                                        <ul className={"list-unstyled"}>
                                            {duplicatedDataRecipients.map((r, ind) =>
                                                showAllDuplicated || ind < 3 ? (
                                                    <RecipientListItem
                                                        key={r.email + ind}
                                                        item={r}
                                                        showEdit={editDuplicatedDataIndex === ind}
                                                        onShowEdit={(f: boolean) => {
                                                            if (f) {
                                                                setEditDuplicatedDataIndex(ind);
                                                                setEditMissingDataIndex(-1);
                                                                setEditIndex(-1);
                                                            } else {
                                                                setEditDuplicatedDataIndex(-1);
                                                            }
                                                        }}
                                                        disableEditButtons={
                                                            disableDuplicatedListButton === ind
                                                        }
                                                        onDismissItem={() => {
                                                            setDuplicatedDataRecipients(
                                                                duplicatedDataRecipients.filter(
                                                                    (v, index) => index !== ind
                                                                )
                                                            );
                                                        }}
                                                        resolveEditFormValidity={resolveEditFormValidity}
                                                        formValues={formValues_edit}
                                                        register={register_edit}
                                                        errors={errors_edit}
                                                        formIsValid={formIsValid_edit}
                                                        onApplyItem={() => {
                                                            handleSubmit_edit((data: any) => {
                                                                addRecipient(data);
                                                                setDuplicatedDataRecipients(
                                                                    duplicatedDataRecipients.filter(
                                                                        (v, vind) => vind !== ind
                                                                    )
                                                                );
                                                                setEditDuplicatedDataIndex(-1);
                                                                setTimeout(() => {
                                                                    trigger_add();
                                                                }, 50);
                                                            })();
                                                        }}
                                                        isDuplicate={(value: string) => {
                                                            let retVal =
                                                                recipients.findIndex(
                                                                    (f) =>
                                                                        f.email.toLowerCase() ===
                                                                        value.toLowerCase()
                                                                ) >= 0;
                                                            if (!retVal) {
                                                                const fInd = duplicatedDataRecipients.findIndex(
                                                                    (f) =>
                                                                        f.email.toLowerCase() ===
                                                                        value.toLowerCase()
                                                                );
                                                                retVal = fInd >= 0 && fInd !== ind;
                                                            }
                                                            return retVal;
                                                        }}
                                                        editTip={intl.formatMessage({
                                                            defaultMessage: "Fix entry",
                                                            description:
                                                                "Edit invalid recipient button tooltip",
                                                        })}
                                                        dismissTip={intl.formatMessage({
                                                            defaultMessage: "Dismiss entry",
                                                            description:
                                                                "Remove invalid recipient button tooltip",
                                                        })}
                                                    />
                                                ) : undefined
                                            )}
                                            {duplicatedDataRecipients.length > 3 && (
                                                <>
                                                    {!showAllDuplicated && (
                                                        <li>
                                                            <a
                                                                href={"#show-all"}
                                                                className={"toggle-all"}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowAllDuplicated(true);
                                                                    return false;
                                                                }}
                                                            >
                                                                {intl.formatMessage(
                                                                    {
                                                                        defaultMessage: "Show all ({count})",
                                                                        description:
                                                                            "show all invalid recipient errors button label. 'count' = amount of invalid recipients",
                                                                    },
                                                                    {
                                                                        count: duplicatedDataRecipients.length,
                                                                    }
                                                                )}
                                                            </a>
                                                        </li>
                                                    )}
                                                    {showAllDuplicated && (
                                                        <li>
                                                            <a
                                                                href={"#show-less"}
                                                                className={"toggle-all"}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowAllDuplicated(false);
                                                                    return false;
                                                                }}
                                                            >
                                                                {intl.formatMessage({
                                                                    defaultMessage: "Show less",
                                                                    description:
                                                                        "collapse invalid recipient errors button label",
                                                                })}
                                                            </a>
                                                        </li>
                                                    )}
                                                </>
                                            )}
                                        </ul>
                                    </>
                                )}
                            </div>
                            <Button
                                data-test-dismiss-all-button
                                type={"button"}
                                variant={"danger"}
                                className="btn custom-base ci-feedback-btn"
                                action={() => {
                                    setDuplicatedDataRecipients([]);
                                    setEditDuplicatedDataIndex(-1);
                                }}
                            >
                                {intl.formatMessage({
                                    defaultMessage: "Dismiss all",
                                    description: "Dismiss all invalid recipients button label",
                                })}
                            </Button>
                        </Form>
                    </Feedback>
                    <Form noValidate className={"recipients"} data-test-recipients>
                        <ul className={"list-unstyled"}>
                            {recipients
                                ? recipients.map((r, ind) => {
                                    return (
                                        <RecipientListItem
                                            key={r.email + ind}
                                            item={r}
                                            showEdit={editIndex === ind}
                                            onShowEdit={(f: boolean) => {
                                                if (f) {
                                                    setEditIndex(ind);
                                                    setEditMissingDataIndex(-1);
                                                    setEditDuplicatedDataIndex(-1);
                                                } else {
                                                    setEditIndex(-1);
                                                }
                                            }}
                                            disableEditButtons={disableListButton === ind}
                                            onDismissItem={() => {
                                                removeRecipient(ind);
                                                setTimeout(() => {
                                                    trigger_add();
                                                }, 50);
                                            }}
                                            resolveEditFormValidity={resolveEditFormValidity}
                                            formValues={formValues_edit}
                                            register={register_edit}
                                            errors={errors_edit}
                                            formIsValid={formIsValid_edit}
                                            onApplyItem={() => {
                                                handleSubmit_edit((data: any) => {
                                                    updateRecipient(ind, data);
                                                    setTimeout(() => {
                                                        trigger_add();
                                                    }, 50);
                                                })();
                                            }}
                                            isDuplicate={(value: string) => {
                                                let retVal: number = recipients.findIndex(
                                                    (f) => f.email.toLowerCase() === value.toLowerCase()
                                                );
                                                return retVal >= 0 && retVal !== ind;
                                            }}
                                            editTip={intl.formatMessage({
                                                defaultMessage: "Edit recipient",
                                                description: "Edit recipient button tooltip",
                                            })}
                                            dismissTip={intl.formatMessage({
                                                defaultMessage: "Remove recipient",
                                                description: "Remove recipient button tooltip",
                                            })}
                                        />
                                    );
                                })
                                : undefined}
                        </ul>
                    </Form>
                    <Form noValidate className={"new-recipient"}>
                        <fieldset className={"input-group"}>
                            <FormGroup
                                controlId={idPrefix + "recipientName"}
                                className={"input-group-input-wrapper"}
                            >
                                <Form.Control
                                    placeholder={intl.formatMessage(
                                        OrganizationGroupInvitationLabels.recipientName
                                    )}
                                    data-test-create-invitation-modal-recipient-name
                                    {...register_add("recipientName", {
                                        onChange: () => {
                                            setTimeout(() => {
                                                trigger_add("email");
                                            }, 50);
                                        },
                                        validate: {
                                            empty: (value: any) => {
                                                return !(
                                                    (recipients.length <= 0 ||
                                                        !!formValues_add["email"]) &&
                                                    value === ""
                                                );
                                            },
                                        },
                                    })}
                                    {...{
                                        className:
                                            (FormInputUtils.validityResolver({
                                                errors: formState_add.errors,
                                                touchedFields: {
                                                    ...formState_add.touchedFields,
                                                    ...{
                                                        email: !!formValues_add["email"],
                                                        recipientName: !!formValues_add["recipientName"],
                                                    },
                                                },
                                            })("recipientName") === true
                                                ? "is-valid"
                                                : FormInputUtils.validityResolver({
                                                    errors: formState_add.errors,
                                                    touchedFields: {
                                                        ...formState_add.touchedFields,
                                                        ...{
                                                            email: !!formValues_add["email"],
                                                            recipientName:
                                                                !!formValues_add["recipientName"],
                                                        },
                                                    },
                                                })("recipientName") === false
                                                    ? "is-invalid"
                                                    : "") +
                                            ((formValues_add ? !!formValues_add.recipientName : false)
                                                ? " has-value"
                                                : ""),
                                    }}
                                ></Form.Control>
                                <Form.Label>
                                    {intl.formatMessage(
                                        OrganizationGroupInvitationLabels.recipientName
                                    )}
                                </Form.Label>
                                {errors_add &&
                                    errors_add.recipientName &&
                                    errors_add.recipientName.type &&
                                    errors_add.recipientName.type === "empty" && (
                                        <Form.Control.Feedback type="invalid">
                                            <FontAwesomeIcon
                                                icon={IconLibrary.icons.faExclamationCircle}
                                                className={"icon"}
                                            />
                                            <span className={"copy"}>
                        {intl.formatMessage(
                            {
                                defaultMessage: "{recipientName} is required.",
                                description:
                                    "Field validation error. 'recipientName' = Field label for the name of the recipient (OrganizationGroupInvitation.recipientName)",
                            },
                            {
                                recipientName: intl.formatMessage(
                                    OrganizationGroupInvitationLabels.recipientName
                                ),
                            }
                        )}
                      </span>
                                        </Form.Control.Feedback>
                                    )}
                            </FormGroup>
                            <FormGroup
                                controlId={idPrefix + "email"}
                                className={"input-group-input-wrapper"}
                            >
                                <Form.Control
                                    data-test-create-invitation-modal-recipient-email
                                    placeholder={intl.formatMessage(
                                        OrganizationGroupInvitationLabels.email
                                    )}
                                    {...register_add("email", {
                                        onChange: () => {
                                            setTimeout(() => {
                                                trigger_add("recipientName");
                                            }, 50);
                                        },
                                        validate: {
                                            empty: (value: any) => {
                                                return !(
                                                    (recipients.length <= 0 ||
                                                        !!formValues_add["recipientName"]) &&
                                                    value === ""
                                                );
                                            },
                                            duplicate: (value: any) => {
                                                return !(
                                                    recipients.length > 0 &&
                                                    recipients.findIndex((v: any) => v.email === value) >=
                                                    0
                                                );
                                            },
                                        },
                                        pattern: {
                                            value: UserUtils.EMAIL_VALIDATION_PATTERN,
                                            message: intl.formatMessage(
                                                {
                                                    defaultMessage: "{email} is not valid.",
                                                    description:
                                                        "Field validation error. 'email' = Field label for invitation recipient email (OrganizationGroupInvitation.email)",
                                                },
                                                {
                                                    email: intl.formatMessage(
                                                        OrganizationGroupInvitationLabels.email
                                                    ),
                                                }
                                            ),
                                        },
                                    })}
                                    {...{
                                        className:
                                            (FormInputUtils.validityResolver({
                                                errors: formState_add.errors,
                                                touchedFields: {
                                                    ...formState_add.touchedFields,
                                                    ...{
                                                        email: !!formValues_add["email"],
                                                        recipientName: !!formValues_add["recipientName"],
                                                    },
                                                },
                                            })("email") === true
                                                ? "is-valid"
                                                : FormInputUtils.validityResolver({
                                                    errors: formState_add.errors,
                                                    touchedFields: {
                                                        ...formState_add.touchedFields,
                                                        ...{
                                                            email: !!formValues_add["email"],
                                                            recipientName:
                                                                !!formValues_add["recipientName"],
                                                        },
                                                    },
                                                })("email") === false
                                                    ? "is-invalid"
                                                    : "") +
                                            ((formValues_add ? !!formValues_add.email : false)
                                                ? " has-value"
                                                : ""),
                                    }}
                                />

                                <Form.Label>
                                    {intl.formatMessage(OrganizationGroupInvitationLabels.email)}
                                </Form.Label>
                                {errors_add &&
                                    errors_add.email &&
                                    errors_add.email.type &&
                                    errors_add.email.type === "empty" && (
                                        <Form.Control.Feedback type="invalid">
                                            <FontAwesomeIcon
                                                icon={IconLibrary.icons.faExclamationCircle}
                                                className={"icon"}
                                            />
                                            <span className={"copy"}>
                        {intl.formatMessage(
                            {
                                defaultMessage: "{email} is required.",
                                description:
                                    "Field validation error. 'email' = Field label for invitation recipient email (OrganizationGroupInvitation.email)",
                            },
                            {
                                email: intl.formatMessage(
                                    OrganizationGroupInvitationLabels.email
                                ),
                            }
                        )}
                      </span>
                                        </Form.Control.Feedback>
                                    )}
                                {errors_add &&
                                    errors_add.email &&
                                    errors_add.email.type &&
                                    errors_add.email.type === "duplicate" && (
                                        <Form.Control.Feedback type="invalid">
                                            <FontAwesomeIcon
                                                icon={IconLibrary.icons.faExclamationCircle}
                                                className={"icon"}
                                            />
                                            <span className={"copy"}>
                        {intl.formatMessage(
                            {
                                defaultMessage: "{email} must be unique.",
                                description:
                                    "Field validation error. 'email' = Field label for invitation recipient email (OrganizationGroupInvitation.email)",
                            },
                            {
                                email: intl.formatMessage(
                                    OrganizationGroupInvitationLabels.email
                                ),
                            }
                        )}
                      </span>
                                        </Form.Control.Feedback>
                                    )}
                                {errors_add &&
                                    errors_add.email &&
                                    errors_add.email.type &&
                                    errors_add.email.type === "pattern" && (
                                        <Form.Control.Feedback type="invalid">
                                            <FontAwesomeIcon
                                                icon={IconLibrary.icons.faExclamationCircle}
                                                className={"icon"}
                                            />
                                            <span className={"copy"}>{errors_add.email.message}</span>
                                        </Form.Control.Feedback>
                                    )}
                            </FormGroup>
                            <Button
                                data-test-add-recipient
                                type={"button"}
                                variant={"primary"}
                                className={"btn custom-base"}
                                disabled={
                                    recipients.length >= CREATE_INVITATION_CSV_IMPORT_LIMIT ||
                                    !(formIsValid_add && formIsDirty_add) ||
                                    !formValues_add.email ||
                                    !formValues_add.recipientName
                                }
                                action={() => {
                                    setEditIndex(-1);
                                    setEditMissingDataIndex(-1);
                                    addRecipient({
                                        email: formValues_add.email,
                                        recipientName: formValues_add.recipientName,
                                    });
                                    setTimeout(() => {
                                        trigger();
                                    }, 50);
                                    reset_add(defaultValues_add);
                                }}
                                icon={<FontAwesomeIcon
                                    icon={IconLibrary.icons.faPlus} />}
                                tooltip={
                                    recipients.length >= CREATE_INVITATION_CSV_IMPORT_LIMIT
                                        ? intl.formatMessage({
                                            defaultMessage:
                                                "Maximum amount of invitation recipients reached.",
                                            description:
                                                "tooltip for add recipient button when max count has been reached",
                                        })
                                        : !(formIsValid_add && formIsDirty_add) ||
                                        !formValues_add.email ||
                                        !formValues_add.recipientName
                                            ? intl.formatMessage({
                                                defaultMessage:
                                                    "Fill in the inputs with valid values to add a new recipient.",
                                                description:
                                                    "tooltip for add recipient button when the fields are empty",
                                            })
                                            : intl.formatMessage({
                                                defaultMessage: "Add new recipient",
                                                description: "tooltip for add recipient button",
                                            })
                                }
                            />
                        </fieldset>
                        <input
                            data-test-upload-csv-input
                            ref={fileInputRef}
                            onChange={(e) => {
                                onFileChanged()
                                    .then((result) => {
                                        setShowRecipients(true);
                                        setMissingDataRecipients([]);
                                        setDuplicatedDataRecipients([]);

                                        handleRecipientResults(result, recipients);
                                    })
                                    .catch((err) => {
                                        setShowRecipients(true);
                                        setImportIssue(err);
                                        setShowAllDuplicated(false);
                                        setMissingDataRecipients([]);
                                        setEditMissingDataIndex(-1);
                                        setDuplicatedDataRecipients([]);
                                        setEditDuplicatedDataIndex(-1);
                                    });
                                if (e && e.target && e.target.value) {
                                    e.target.value = "";
                                }
                            }}
                            className="d-none"
                            type="file"
                        />
                    </Form>
                </div>
                <Form noValidate>
                    {isReady && !selectionFixed && (
                        <Form.Group className={"form-table"}>
                            <Table<OrganizationGroup>
                                maxRows={5}
                                compact={true}
                                className={
                                    selectionTouched && (!selected || selected.length === 0)
                                        ? "invalid"
                                        : undefined
                                }
                                header={
                                    <Form.Label>
                                        {intl.formatMessage({
                                            defaultMessage: "Invite user to selected user group(s)",
                                            description: "field label",
                                        })}
                                    </Form.Label>
                                }
                                data-test-select-groups
                                search={(groups || []).length > TABLE_SEARCH_THRESHOLD}
                                activeSearch={activeSearch}
                                onSearch={onSetActiveSearch}
                                columnToggle={false}
                                reset={false}
                                data={groups || []}
                                pagination={false}
                                identifyingColumn={"id"}
                                selection={{
                                    multi: true,
                                    selectAll: true,
                                }}
                                onFocus={() => {
                                    onSetSelectionTouched(true);
                                }}
                                onSelectionChanged={onSetSelected}
                                selected={selected}
                                columns={[
                                    {
                                        key: "id",
                                        label: intl.formatMessage(
                                            OrganizationGroupInvitationLabels.id
                                        ),
                                        isTechnical: true,
                                        hidden: true,
                                    },
                                    {
                                        key: "name",
                                        label: intl.formatMessage({
                                            defaultMessage: "User group",
                                            description: "Column heading for the groups to select",
                                        }),
                                        sortable: true,
                                        renderer: (props: {
                                            cell: any;
                                            row: any;
                                            rowIndex: Number;
                                            rendererData: any;
                                        }) => {
                                            return (
                                                <>
                                                    {hasAction(groupConf.rowActions, 'show') ? (
                                                        <NavigateAfterAction
                                                            href={
                                                                "/groups/" + props.row.id + "/" + ModalKeys.show
                                                            }
                                                            action={onClose}
                                                        >
                                                            {props.cell}
                                                        </NavigateAfterAction>
                                                    ) :  props.cell}
                                                </>
                                            );
                                        },
                                        tipRenderer: (props: {
                                            cell: any;
                                            row: any;
                                            rowIndex: Number;
                                            rendererData: any;
                                        }) => {
                                            return (
                                                <>
                                                    <FormattedMessage
                                                        defaultMessage="Click to select or deselect"
                                                        description="tooltip for table name cells"
                                                    />
                                                </>
                                            );
                                        },
                                    },
                                ]}
                            />
                            {selectionTouched && !hasSelection ? (
                                <Form.Control.Feedback type="invalid" className={"d-block"}>
                                    <FontAwesomeIcon
                                        icon={IconLibrary.icons.faExclamationCircle}
                                        className={"icon"}
                                    />
                                    <span className={"copy"}>
                    {intl.formatMessage({
                        defaultMessage: "At least one group must be selected.",
                        description: "Field validation error",
                    })}
                  </span>
                                </Form.Control.Feedback>
                            ) : undefined}
                        </Form.Group>
                    )}
                    {orgAdminRoleId && (
                        <Form.Group
                            controlId={idPrefix + "assignAdmin"}
                            className={"form-group"}
                        >
                            <Form.Check
                                data-test-create-invitation-modal-assign-admin
                                {...register("assignAdmin")}
                                className={
                                    FormInputUtils.validityResolver(formState)("assignAdmin") === true
                                        ? "is-valid"
                                        : FormInputUtils.validityResolver(formState)("assignAdmin") === false
                                            ? "is-invalid"
                                            : undefined
                                }
                                onClick={() => {
                                    trigger("assignAdmin");
                                }}
                                onBlur={() => {
                                    trigger("assignAdmin");
                                }}
                                type={"checkbox"}
                                label={intl.formatMessage({
                                    defaultMessage: "Assign Admin access",
                                    description:
                                        "checkbox label. When selected, assigns admin access to all invited users.",
                                })}
                            />
                        </Form.Group>
                    )}
                    <FormInput
                        type={"textarea"}
                        data-test-create-invitation-modal-invitation-scope-information
                        label={intl.formatMessage(
                            OrganizationGroupInvitationLabels.invitationScopeInformation
                        )}
                        field="invitationScopeInformation"
                        register={register}
                        registerOptions={{
                            required: true,
                        }}
                        hasValue={!!formValues["invitationScopeInformation"]}
                        resolveValidity={FormInputUtils.validityResolver(formState)}
                    >
                        {errors &&
                            errors.invitationScopeInformation &&
                            errors.invitationScopeInformation.type &&
                            errors.invitationScopeInformation.type === "required" && (
                                <Form.Control.Feedback type="invalid">
                                    <FontAwesomeIcon
                                        icon={IconLibrary.icons.faExclamationCircle}
                                        className={"icon"}
                                    />
                                    <span className={"copy"}>
                    {intl.formatMessage(
                        {
                            defaultMessage:
                                "{invitationScopeInformation} is required.",
                            description:
                                "Field validation error. 'message' = Field label for a custom message attached to the invitation (OrganizationGroupInvitation.invitationScopeInformation)",
                        },
                        {
                            invitationScopeInformation: intl.formatMessage(
                                OrganizationGroupInvitationLabels.invitationScopeInformation
                            ),
                        }
                    )}
                  </span>
                                </Form.Control.Feedback>
                            )}
                    </FormInput>
                </Form>
            </>
        </Modal>
    );
}

export default CreateInvitationModal;
