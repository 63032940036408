import { useEffect, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FeedbackEntry, Feedback } from "@10duke/dukeui";
import { Modal } from "@10duke/dukeui";
import { User } from "../../../model/User";
import {
  AddUserToOrgRoleAction,
  ClearErrorAction,
  GetUserAction,
  isAddErrorAction,
} from "../../../actions/actionTypes";
import UserUtils from "../../../utils/user";
import { UserValues } from "../../../localization/user";
import {ClosableModalProps} from "../closable-modal-props";

//<editor-fold desc="Props">

export interface UserWithAdminStatus extends User {
  isOrgAdmin: boolean;
}

const resolveUserName = (
  user: User | undefined | null,
  intl: { formatMessage: (v: any, v2?: any) => string }
) =>
  UserUtils.resolveDisplayName(
    user,
    intl.formatMessage(UserValues.displayName.undefined)
  );

export interface AssignAdminModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface AssignAdminModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  onAssignAdmin: (
    roleId: string,
    userId: string
  ) => Promise<AddUserToOrgRoleAction>;
  onLoadUser: (id: string) => Promise<GetUserAction>;
  user?: UserWithAdminStatus | null;
  orgAdminRoleId?: string;
  onLoadOrgadminRole: () => void;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface AssignAdminModalProps
  extends AssignAdminModalDOMProps,
    AssignAdminModalDataProps,
    Omit<ClosableModalProps, "isReady"> {
  onShowFeedback: (feedback: FeedbackEntry) => void;
  userId?: string;
}
//</editor-fold>

function AssignAdminModal(props: AssignAdminModalProps) {
  //<editor-fold desc="Local variables">
  let {
    userId,
    user,
    show,
    onClose,
    orgAdminRoleId,
    onAssignAdmin,
    onLoadUser,
    onShowFeedback,
    isReady,
    onExited,
    onLoadOrgadminRole,
    onClearError,
  } = props;

  const userObjId = user ? user.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!userId &&
      (userObjId === undefined || (!!userObjId && userObjId !== userId)) &&
      !!onLoadUser
    ) {
      onLoadUser(userId).then((res) => {
        if (!userObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, userId, userObjId, onLoadUser, onClearError]);
  useEffect(() => {
    if (show && orgAdminRoleId === undefined && onLoadOrgadminRole) {
      onLoadOrgadminRole();
    }
  }, [show, orgAdminRoleId, onLoadOrgadminRole]);
  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>

  return (
    <Modal
      onReloadData={() => {
        if ((user || userId) && onLoadUser) {
          onLoadUser(user ? (user.id as string) : (userId as string)).then(
            (res) => {
              if (!user && isAddErrorAction(res)) {
                // only clear error if no data exists, leave if previous data is still available and
                // let the api error notification handler show error
                onClearError(res.error?.errorId);
              }
            }
          );
        }
        if (onLoadOrgadminRole) {
          onLoadOrgadminRole();
        }
      }}
      onExited={onExited}
      isReady={isReady}
      data-test-assign-admin-modal={user ? user.id : true}
      title={
        !isReady || user
          ? intl.formatMessage({
              defaultMessage: "Assign Admin access",
              description: "modal heading",
            })
          : intl.formatMessage({
              defaultMessage: "User not found",
              description: "modal heading when user not found",
            })
      }
      show={show}
      onClose={onClose}
      primaryButton={
        user && !user.isOrgAdmin
          ? {
              label: intl.formatMessage({
                defaultMessage: "Assign",
                description: "primary button label",
              }),
            }
          : {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
      }
      onPrimaryAction={() => {
        if (!!show && !!user && !user.isOrgAdmin && !!orgAdminRoleId) {
          onAssignAdmin(orgAdminRoleId, user.id as string).then((res) => {
            if (!isAddErrorAction(res)) {
              onShowFeedback({
                id: "assignAdmin_" + (user ? (user.id as string) : ""),
                msg: intl.formatMessage(
                  {
                    defaultMessage: "Admin access assigned for {name}.",
                    description:
                      "Success notification. 'name' = user display name",
                  },
                  {
                    name:
                      "<strong>" + resolveUserName(user, intl) + "</strong>",
                  }
                ),
                autoClose: true,
                type: "success",
              });
            } else {
              onClearError(res.error?.errorId);
              onShowFeedback({
                id: "assignAdmin_" + (!!user ? (user.id as string) : ""),
                msg: intl.formatMessage(
                  {
                    defaultMessage: "Assigning Admin access for {name} failed.",
                    description:
                      "Failure notification. 'name' = user display name",
                  },
                  {
                    name:
                      "<strong>" + resolveUserName(user, intl) + "</strong>",
                  }
                ),
                type: "danger",
              });
            }
          });
        }
        onClose();
      }}
      secondaryButton={
        user && !user.isOrgAdmin
          ? {
              label: intl.formatMessage({
                defaultMessage: "Cancel",
                description: "secondary button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={user ? onClose : undefined}
    >
      <>
        {show && user && (
          <>
            {user.isOrgAdmin && (
              <Feedback type={"danger"} show={true} asChild={true}>
                <p>
                  <FormattedMessage
                    defaultMessage="{name} already has Admin access."
                    description="message to be shown when the user is already an admin. 'name' = user display name"
                    values={{
                      name: <strong>{resolveUserName(user, intl)}</strong>,
                    }}
                  />
                </p>
              </Feedback>
            )}
            {!user.isOrgAdmin && (
              <p>
                <FormattedMessage
                  defaultMessage="Are you sure you wish to assign Admin access for {name}?"
                  description="Confirm action message. 'name' = user display name"
                  values={{
                    name: <strong>{resolveUserName(user, intl)}</strong>,
                  }}
                />
              </p>
            )}
          </>
        )}
        {isReady && show && !user && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p>
              <FormattedMessage
                defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
                description="message to be shown when there is no user to display"
              />
            </p>
          </Feedback>
        )}
      </>
    </Modal>
  );
}

export default AssignAdminModal;
