import * as ActionTypes from "../actions/actionTypes";
import {ClientInvitationsById} from "../store/ClientInvitationState";
export default function orgClientInvitations(
  state: ClientInvitationsById,
  action: ActionTypes.AppAction
): ClientInvitationsById | null {
  const currentState = state || ({} as ClientInvitationsById);
  switch (action.type) {
    case ActionTypes.CREATE_AND_SEND_ORG_CLIENT_GROUP_INVITATION:
      const sendOrgInvitation = action as ActionTypes.CreateAndSendOrgClientGroupInvitationAction;
      return {
        ...currentState,
        [sendOrgInvitation.invitation.id as string]: sendOrgInvitation.invitation
      };

    case ActionTypes.LIST_ORG_CLIENT_GROUP_INVITATIONS:
      const listOrgInvitations = action as ActionTypes.ListOrgClientGroupInvitationsAction;
      const newOrgInvitationsById = listOrgInvitations.invitations.reduce<
          ClientInvitationsById
      >((map, invitation) => {
        map[invitation.id as string] = invitation;
        return map;
      }, {});
      return { ...currentState, ...newOrgInvitationsById };

    case ActionTypes.GET_ORG_CLIENT_GROUP_INVITATION:
      const getOrgInvitation = action as ActionTypes.GetOrgClientGroupInvitationAction;
      return { ...currentState, [getOrgInvitation.invitation.id as string]: getOrgInvitation.invitation };

    case ActionTypes.SEND_ORG_CLIENT_GROUP_INVITATION:
      const sendOrgDeviceInvitation = action as ActionTypes.SendOrgClientGroupInvitationAction;
      return {
        ...currentState,
        [sendOrgDeviceInvitation.invitation
            .id as string]: sendOrgDeviceInvitation.invitation
      };
    case ActionTypes.REVOKE_ORG_CLIENT_GROUP_INVITATION:
      const revokeOrgInvitation = action as ActionTypes.RevokeOrgClientGroupInvitationAction;
      return {
        ...currentState,
        [revokeOrgInvitation.invitation
            .id as string]: revokeOrgInvitation.invitation
      };
    case ActionTypes.DELETE_ORG_CLIENT_GROUP_INVITATION:
      const delOrgInvitation = action as ActionTypes.DeleteOrgClientGroupInvitationAction;
      const { [delOrgInvitation.invitationId]: _, ...remaining } = currentState;
      return remaining;
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
