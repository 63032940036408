import DashboardComp, { ModalKeys } from "../../components/pages/dashboard";
import { useHistory, useLocation, useParams } from "react-router-dom";
export { ModalKeys } from "../../components/pages/dashboard";
function Dashboard() {
  const history = useHistory();
  const location = useLocation();
  let { modal, id } = useParams<any>();
  const onShowModal = (key: ModalKeys, newId?: string) => {
    let base: string | undefined;
    if (modal) {
      if (id) {
        base = location.pathname.replace(`/${id}/${modal}`, "");
      } else {
        base = location.pathname.replace(`/${modal}`, "");
      }
    } else {
      base = location.pathname;
    }
    if (base && base.endsWith("/")) {
      base = base.substr(0, base.length - 1);
    }
    if (newId) {
      history.push(`${base}/${newId}/${key}`);
    } else {
      history.push(`${base}/${key}`);
    }
  };
  const onHideModal = (callback?: () => void) => {
    if (id) {
      history.push(location.pathname.replace(`/${id}/${modal}`, ""));
    } else {
      history.push(location.pathname.replace(`/${modal}`, ""));
    }
    if (callback && typeof callback === "function") {
      callback();
    }
  };
  return (
    <DashboardComp
      showModal={{ key: modal as ModalKeys, id: id }}
      onShowModal={onShowModal}
      onHideModal={onHideModal}
    />
  );
}
export default Dashboard;
