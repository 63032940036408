import { AppState } from "../store/AppState";

function selectNotInProgress(senderKey: string, state: AppState): boolean {
  const { inProgress } = state;
  let isReady;
  if (
    inProgress &&
    inProgress.length &&
    inProgress.findIndex(ip => ip.actionSender.key === senderKey) >= 0
  ) {
    isReady = false;
  } else {
    isReady = true;
  }
  return isReady;
}
function selectInProgressLength(state: AppState): number {
  return state.inProgress?.length || 0;
}
function compareInProgressStates(next: AppState, prev: AppState) {
  return next.inProgress === prev.inProgress;
}
const InProgressUtils = {
  selectNotInProgress,
  selectInProgressLength,
  compareInProgressStates
};
export default InProgressUtils;
