import { useEffect, useRef, useState } from "react";
import View, {
  ViewRoleModalProps as _ViewRoleModalProps,
  ViewRoleModalStateProps,
} from "./view-role-modal-view";
import _ from "lodash";
import { withCloseAfterExited } from "@10duke/dukeui";
import { OrganizationRole } from "../../../model/OrganizationRole";
import { PermissionWithGrantedActions } from "../../../model/PermissionWithGrantedActions";

export type ViewRoleModalProps = Omit<
  _ViewRoleModalProps,
  keyof ViewRoleModalStateProps
>;
const ViewWithCloseAfterExited =
  withCloseAfterExited<_ViewRoleModalProps>(View);
export default function ViewRoleModalModal(props: ViewRoleModalProps) {
  const { show, permissions, rolePermissions, roles, includedRoles, ...other } =
    props;

  const permissionsRef = useRef(permissions);
  if (!_.isEqual(permissionsRef.current, permissions)) {
    permissionsRef.current = permissions;
  }
  const permissionsRefCurrent = permissionsRef.current;
  const [permissionsWithActions, setPermissionsWithActions] = useState<
    PermissionWithGrantedActions[] | undefined
  >(permissions);

  useEffect(() => {
    if (permissionsRefCurrent) {
      setPermissionsWithActions(permissionsRefCurrent);
    }
  }, [permissionsRefCurrent, setPermissionsWithActions]);
  useEffect(() => {
    if (!show) {
      setPermissionsWithActions(permissionsRefCurrent);
    }
  }, [show, permissionsRefCurrent, setPermissionsWithActions]);

  const rolePermissionsRef = useRef(rolePermissions);
  if (!_.isEqual(rolePermissionsRef.current, rolePermissions)) {
    rolePermissionsRef.current = rolePermissions;
  }
  const rolePermissionsRefCurrent = rolePermissionsRef.current;

  const [editedPermissions, setEditedPermissions] = useState<
    PermissionWithGrantedActions[] | undefined
  >(rolePermissions);
  const [hasPermissionChanges, setHasPermissionChanges] =
    useState<boolean>(false);
  const [activePermissionsSearch, onSetActivePermissionsSearch] = useState("");

  useEffect(() => {
    if (rolePermissionsRefCurrent) {
      setEditedPermissions(rolePermissionsRefCurrent);
      setHasPermissionChanges(false);
    }
  }, [
    rolePermissionsRefCurrent,
    setEditedPermissions,
    setHasPermissionChanges,
  ]);

  useEffect(() => {
    if (!show) {
      setEditedPermissions(undefined);
      setHasPermissionChanges(false);
    }
  }, [show, setEditedPermissions]);

  const onSetPermissions = (ents: PermissionWithGrantedActions[]) => {
    let newEdited: PermissionWithGrantedActions[] = ents;
    if (rolePermissions && newEdited) {
      const rolePermissionIds = rolePermissions
        .map(
          (v) => (v.id as string) + v.grantedActions?.allowedActions?.join(",")
        )
        .sort((a, b) => {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          }
          return 0;
        })
        .join("");
      const newEditedIds = newEdited
        .map(
          (v) => (v.id as string) + v.grantedActions?.allowedActions?.join(",")
        )
        .sort((a, b) => {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          }
          return 0;
        })
        .join("");
      setHasPermissionChanges(rolePermissionIds !== newEditedIds);
    }
    setEditedPermissions(newEdited);
  };

  const includedRolesRef = useRef(includedRoles);
  if (!_.isEqual(includedRolesRef.current, includedRoles)) {
    includedRolesRef.current = includedRoles;
  }
  const includedRolesRefCurrent = includedRolesRef.current;

  const [editedRoles, setEditedRoles] = useState<
    OrganizationRole[] | undefined
  >(includedRoles);
  const [hasRoleChanges, setHasRoleChanges] = useState<boolean>(false);
  const [activeRoleSearch, onSetActiveRoleSearch] = useState("");

  useEffect(() => {
    if (includedRolesRefCurrent) {
      setEditedRoles(includedRolesRefCurrent);
      setHasRoleChanges(false);
    }
  }, [includedRolesRefCurrent, setEditedRoles, setHasRoleChanges]);

  useEffect(() => {
    if (!show) {
      setEditedRoles(undefined);
      setHasRoleChanges(false);
    }
  }, [show, setEditedRoles]);

  const onSetRoles = (ents: OrganizationRole[]) => {
    let newEdited: OrganizationRole[] = ents;
    if (includedRoles && newEdited) {
      const roleRoleIds = includedRoles
        .map((v) => v.id as string)
        .sort((a, b) => {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          }
          return 0;
        })
        .join("");
      const newEditedIds = newEdited
        .map((v) => v.id as string)
        .sort((a, b) => {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          }
          return 0;
        })
        .join("");
      setHasRoleChanges(roleRoleIds !== newEditedIds);
    } else if (includedRoles || newEdited) {
      setHasRoleChanges(true);
    } else {
      setHasRoleChanges(false);
    }
    setEditedRoles(newEdited);
  };

  const onUpdateActions = (
    obj: PermissionWithGrantedActions,
    value: string[]
  ) => {
    const newPerm = permissionsWithActions?.map((p) => {
      if (p.id === obj.id) {
        return {
          ...p,
          grantedActions: {
            allowedActions: value,
          },
        };
      } else {
        return p;
      }
    });
    setPermissionsWithActions(newPerm);
    onSetPermissions(
      editedPermissions
        ? editedPermissions.map(
            (p) =>
              newPerm?.find(
                (f) => f.id === p.id
              ) as PermissionWithGrantedActions
          )
        : []
    );
  };
  return (
    <ViewWithCloseAfterExited
      {...other}
      show={show}
      hasPermissionChanges={hasPermissionChanges}
      rolePermissions={editedPermissions}
      permissions={permissionsWithActions}
      selectedPermissions={editedPermissions || []}
      onSetSelectedPermissions={onSetPermissions}
      activePermissionsSearch={activePermissionsSearch}
      onSetActivePermissionsSearch={onSetActivePermissionsSearch}
      hasRoleChanges={hasRoleChanges}
      includedRoles={editedRoles}
      roles={roles}
      selectedRoles={editedRoles || []}
      onSetSelectedRoles={onSetRoles}
      activeRoleSearch={activeRoleSearch}
      onSetActiveRoleSearch={onSetActiveRoleSearch}
      onUpdateActions={onUpdateActions}
    />
  );
}
