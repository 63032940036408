import { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import Table, { TableColumn } from "../../table";
import { Dropdown } from "react-bootstrap";
import "./invitations-view.scss";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
import { Link } from "react-router-dom";
import RevokeInvitationModal from "../../modals/revoke-invitation-modal";
import RemoveInvitationModal from "../../modals/remove-invitation-modal";
import ResendInvitationModal from "../../modals/resend-invitation-modal";
import ViewInvitationModal from "../../modals/view-invitation-modal";
import ViewGroupModal from "../../modals/view-group-modal";
import CreateInvitationModal from "../../modals/create-invitation-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import InvitationStateBadge from "../../badges/invitation-state-badge";
import {
  OrganizationGroupInvitationLabels,
  OrganizationGroupInvitationValues,
} from "../../../localization/organization-group-invitation";
import { OrganizationGroupValues } from "../../../localization/organization-group";
import CreateDeviceClientInvitationModal from "../../modals/create-device-client-invitation-modal";
import {hasAction, resolveColumns, resolveFilters} from "../../../ui-configuration/configuration-tools";
import {OrganizationGroupInvitationWithExpiredStatus} from "../../../utils/invitation";
import {TableFilter} from "../../table/table-container";
import {HideLabelTableFilterView, TableFilterViewProps} from "../../table/table-filter/table-filter-view";
import {ClientGroupInvitationWithExpiredStatus} from "../../../utils/deviceClientGroupInvitation";
import {ClientGroup} from "../../../model/ClientGroup";
import {
  ClientGroupInvitationLabels
} from "../../../localization/client-group-invitation";
import ViewDeviceClientGroupModal from "../../modals/view-device-client-group-modal/view-device-client-group-modal-redux";
import ViewDeviceClientInvitationModal from "../../modals/view-device-client-invitation-modal";
import ResendDeviceClientInvitationModal from "../../modals/resend-device-client-invitation-modal";
import RemoveDeviceClientInvitationModal from "../../modals/remove-device-client-invitation-modal";
import RevokeDeviceClientInvitationModal from "../../modals/revoke-device-client-invitation-modal";
import {ClientGroupValues} from "../../../localization/client-group/client-group";
import HeaderActions from "../../table/header-actions";
import {IconLibrary, FeedbackContainerProps, ShowFeedback, Page, PageDOMProps, Button} from "@10duke/dukeui";

export enum ModalKeys {
  show = "show",
  showClientInvitation = "showClientInvitation",
  showGroup = "showGroup",
  showClientGroup = "showClientGroup",
  revoke = "revoke",
  revokeClientInvitation = "revokeClientInvitation",
  resend = "resend",
  resendClientInvitation = "resendClientInvitation",
  remove = "remove",
  removeClientInvitation = "removeClientInvitation",
  create = "create",
  createClientInvitation = "createClientInvitation",
}
//<editor-fold desc="Props">

export interface UIInvitation extends OrganizationGroupInvitationWithExpiredStatus, ClientGroupInvitationWithExpiredStatus {
  invitationType: "user"|"client",
}

export interface InvitationsModalVisibilityProps {
  showModal?: { key: ModalKeys; invitationId?: string; secondaryId?: string };
  onShowModal: (
    key: ModalKeys,
    invitationId?: string,
    secondaryId?: string
  ) => void;
  onHideModal: (callback?: () => void) => void;
  // defined here as it originates form the same route wrapper
  enableClients?: boolean;
  enableUsers?: boolean;
}
export interface InvitationsDOMProps extends PageDOMProps {}

export interface InvitationsStateProps extends FeedbackContainerProps {
  selected: UIInvitation[];
  onSetSelected: (selection: UIInvitation[]) => void;
}
export interface InvitationsProps
  extends InvitationsStateProps,
    InvitationsDOMProps,
    InvitationsModalVisibilityProps {
  userName?: string;
  userId?: string;
  invitations?: UIInvitation[];
  groups?: OrganizationGroup[];
  clientGroups?: ClientGroup[];
  orgAdminRoleId?: string;
  onLoadInvitations: () => Promise<any>;
  onLoadGroups: () => void;
  onLoadClientGroups: () => void;
  onLoadOrganizationRoles: () => void;
}
//</editor-fold>

//<editor-fold desc="Messages">
//</editor-fold>

//<editor-fold desc="Utils">

const resolveRowClasses = (
  row: UIInvitation,
  rowIndex: number
) => {
  return row.invitationState;
};
/**
 * Internal utility for creating a invitation status filter
 * @param status
 * @param initial
 * @param intl
 */
function createStatusFilter(status: "deliveryRequested"|"updated"|"created"|"revoked"|"accepted"|"declined"|"expired",
                            initial: boolean): TableFilter {
  return {
    key: "hide" + status.replace(/^\w/, (c) => c.toUpperCase()),
    initial: initial,
    view: (props: TableFilterViewProps) => <HideLabelTableFilterView
        label={OrganizationGroupInvitationValues.invitationState[status]}
        {...props}
    />,
    apply: (i: OrganizationGroupInvitationWithExpiredStatus) => {
      return i.invitationState !==  status;
    },
  };
}
//</editor-fold>

function Invitations(props: InvitationsProps) {
  //<editor-fold desc="Local variables">
  const {
    invitations,
    groups,
    clientGroups,
    onLoadInvitations,
    onLoadGroups,
    onLoadClientGroups,
    onLoadOrganizationRoles,
    selected,
    onSetSelected,
    onShowModal,
    onHideModal,
    showModal,
    onShowFeedback,
    onHideFeedback,
    feedback,
    orgAdminRoleId,
    userName,
    userId,
    enableClients,
    enableUsers,
    ...others
  } = props;
  // this is more like a variable than a hook
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: "Manage invitations",
    description: "Heading for the Manage invitations page",
  });
  const description = intl.formatMessage({
    defaultMessage: "Invitation management",
    description: "window meta description for the Manage invitations page",
  });

  const { conf } = useContext(UIConfiguration);
  const invitationsConf =
    conf.functionality && conf.functionality.invitations
      ? conf.functionality.invitations
      : {};

  let columns: TableColumn[] = [
    {
      key: "id",
      label: intl.formatMessage(OrganizationGroupInvitationLabels.id),
      isTechnical: true,
      hidden: true,
    },
    {
      key: "invitationStatus",
      isDummy: true,
      sortable: true,
      label: intl.formatMessage(
        OrganizationGroupInvitationLabels.invitationState
      ),
      rendererData: {
        resolveValue: (itm: any, rd: any) =>
          intl.formatMessage(
            (OrganizationGroupInvitationValues.invitationState as any)[
              itm.invitationState as string
            ]
          ),
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return (
          <InvitationStateBadge
            state={props.row.invitationState}
            validUntil={props.row.validUntil}
            validFrom={props.row.validFrom}
          />
        );
      },
      tipRenderer: () => false,
    },
    {
      key: "email",
      sortable: true,
      label: intl.formatMessage(OrganizationGroupInvitationLabels.email),
    },
    {
      key: "recipientName",
      sortable: true,
      label: intl.formatMessage(
          OrganizationGroupInvitationLabels.recipientName
      ),
    },
    invitationsConf.enableClientInvitations ? {
      key: "clientName",
      sortable: true,
      label: intl.formatMessage(
          ClientGroupInvitationLabels.clientName
      ),
    } : undefined,
    invitationsConf.enableClientInvitations ? {
      key: "allowClientId",
      sortable: true,
      label: intl.formatMessage(
          ClientGroupInvitationLabels.allowClientId
      ),
    } : undefined,
    {
      key: "groups",
      sortable: true,
      isDummy: true,
      // this column shows both kinds of groups so the specific labels cannot be used
      label: intl.formatMessage({
        defaultMessage: "groups",
        description:
            "Field label for targeted organization user groups or device client groups",
      }),
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const g: (OrganizationGroup|ClientGroup)[] = props.rendererData.resolveValue(
          props.row,
          props.rendererData
        );
        return (
          <>
            {props.row.invitationType === 'client' && props.rendererData.invitationsConf.showGroup &&
              g &&
              g.map((val: ClientGroup, ind) => {
                if (val) {
                  return (
                      <span key={"link_" + val.id}>
                        <Link
                            to={
                                "/invitations/" + ModalKeys.showClientGroup + "/" + val.id
                            }
                        >
                          {val.name
                              ? val.name
                              : intl.formatMessage(
                                  ClientGroupValues.name.undefined
                              )}
                        </Link>
                            {ind < g.length - 1 ? <>, </> : undefined}
                      </span>
                  );
                }
                return null;
              })}
            {props.row.invitationType === 'user' && props.rendererData.invitationsConf.showGroup &&
                g &&
                g.map((val: OrganizationGroup, ind) => {
                  if (val) {
                    return (
                        <span key={"link_" + val.id}>
                          <Link
                              to={
                                  "/invitations/" + ModalKeys.showGroup + "/" + val.id
                              }
                          >
                            {val.name
                                ? val.name
                                : intl.formatMessage(
                                    OrganizationGroupValues.name.undefined
                                )}
                          </Link>
                          {ind < g.length - 1 ? <>, </> : undefined}
                        </span>
                    );
                  }
                  return null;
                })}
            {!props.rendererData.invitationsConf.showGroup &&
              props.row.invitationType === 'user' &&
              g &&
              g.map((val: OrganizationGroup, ind) => {
                if (val) {
                  return (
                    <span key={"link_" + val.id}>
                      {val.name
                        ? val.name
                        : intl.formatMessage(
                            OrganizationGroupValues.name.undefined
                          )}
                      {ind < g.length - 1 ? <>, </> : undefined}
                    </span>
                  );
                }
                return null;
              })}
            {!props.rendererData.invitationsConf.showGroup &&
                props.row.invitationType === 'client' &&
                g &&
                g.map((val: OrganizationGroup, ind) => {
                  if (val) {
                    return (
                        <span key={"link_" + val.id}>
                      {val.name
                          ? val.name
                          : intl.formatMessage(
                              ClientGroupValues.name.undefined
                          )}
                          {ind < g.length - 1 ? <>, </> : undefined}
                    </span>
                    );
                  }
                  return null;
                })
            }
          </>
        );
      },
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const g: OrganizationGroup[] = props.rendererData.resolveValue(
          props.row,
          props.rendererData
        );
        return (
          <>
            {g &&
              g.map((val: OrganizationGroup, ind) => {
                let t;
                if (val) {
                  t = val && val.name ? val.name : "unknown";
                  if (ind < g.length - 1) {
                    t = t + ", ";
                  }
                }
                return t;
              })}
          </>
        );
      },
      rendererData: {
        groups,
        clientGroups,
        invitationsConf: invitationsConf,
        resolveValue: (itm: any, rd: any) => {
          if (itm.invitationType === 'user') {
            return itm.groupIds
                .map((val: string) => {
                  const g = rd.groups
                      ? rd.groups.find((v: OrganizationGroup) => v.id === val)
                      : undefined;
                  if (g) {
                    g.toString = () => g.name;
                  }
                  return g;
                })
                .sort((a: OrganizationGroup, b: OrganizationGroup) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                });
          } else if (itm.invitationType === 'client') {
            return itm.groupIds
                .map((val: string) => {
                  const g = rd.clientGroups
                      ? rd.clientGroups.find((v: ClientGroup) => v.id === val)
                      : undefined;
                  if (g) {
                    g.toString = () => g.name;
                  }
                  return g;
                })
                .sort((a: ClientGroup, b: ClientGroup) => {
                  if (a.name < b.name) {
                    return -1;
                  }
                  if (a.name > b.name) {
                    return 1;
                  }
                  return 0;
                });
          } else {
            return undefined;
          }
        },
      },
    },
    invitationsConf.enableUserInvitations ? {
      key: "orgAdmin",
      sortable: true,
      isDummy: true,
      hidden: true,
      label: intl.formatMessage(OrganizationGroupInvitationLabels.isOrgAdmin),
      align: "center",
      rendererData: {
        resolveValue: (itm: any, rd: any) => {
          return itm.organizationRoleIds?.find(
            (val: string) => val === orgAdminRoleId
          )
            ? [
                true,
                intl.formatMessage(
                  OrganizationGroupInvitationValues.isOrgAdmin.true
                ),
              ]
            : [
                false,
                intl.formatMessage(
                  OrganizationGroupInvitationValues.isOrgAdmin.false
                ),
              ];
        },
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        if (props.row.invitationType === "user") {
          const g: any[] = props.rendererData.resolveValue(
            props.row,
            props.rendererData
          );
          return (
            <>
              {g[0] === true && (
                <span className={"indicator indicator-active"}>
                  <FontAwesomeIcon icon={IconLibrary.icons.faCheck}
                                   className={"icon d-inline-block"}  />
                </span>
              )}
              {g[0] === false && (
                <span className={"indicator indicator-inactive"}>
                  <FontAwesomeIcon icon={IconLibrary.icons.faTimes}
                                   className={"icon d-inline-block"} />
                </span>
                  )}
                </>
            );
        } else {
          return "";
        }
      },
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        if (props.row.invitationType === "user") {
          const g: any[] = props.rendererData.resolveValue(
              props.row,
              props.rendererData
          );
          return (
              <>
                {g[0] === true && (
                    <span className={"i-indicator"}>
                <FontAwesomeIcon icon={IconLibrary.icons.faCheck} className={"icon"}/> {g[1]}
              </span>
                )}
                {g[0] === false && (
                    <span className={"i-indicator"}>
                <FontAwesomeIcon icon={IconLibrary.icons.faTimes} className={"icon"}/> {g[1]}
              </span>
                )}
              </>
          );
        }
      },
    } : undefined,
    {
      key: "validFrom",
      label: intl.formatMessage(OrganizationGroupInvitationLabels.validFrom),
      sortable: true,
      hidden: true,
      rendererData: {
        resolveValue: (itm: any) => {
          let retVal;
          const d = itm.validFrom;
          if (d === "now()") {
            retVal = intl.formatDate(new Date());
          } else if (d) {
            retVal = intl.formatDate(new Date(d));
          }
          return retVal;
        },
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return <>{props.rendererData.resolveValue(props.row)}</>;
      },
    },
    {
      key: "validUntil",
      label: intl.formatMessage(OrganizationGroupInvitationLabels.validUntil),
      sortable: true,
      hidden: true,
      rendererData: {
        resolveValue: (itm: any) => {
          let retVal;
          const d = itm.validUntil;
          if (d === "now()") {
            retVal = intl.formatDate(new Date());
          } else if (d) {
            retVal = intl.formatDate(new Date(d));
          }
          return retVal;
        },
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return <>{props.rendererData.resolveValue(props.row)}</>;
      },
    },
  ].filter((f) => !!f) as TableColumn[];
  //</editor-fold>

  if (invitationsConf.columns && invitationsConf.columns.length > 0) {
    columns = resolveColumns(columns, invitationsConf.columns);
  }
  //<editor-fold desc="Hooks">
  // resets selection when users are undefined, and clears out selected items that are not part of updated data
  useEffect(() => {
    if (selected.length) {
      if (invitations === undefined) {
        onSetSelected([]);
      } else {
        const ids = invitations.map(
          (inv: UIInvitation) => inv.id
        );
        const newS = selected.filter((itm: any) => {
          return ids.indexOf(itm.id) >= 0;
        });
        if (newS.length !== selected.length) {
          onSetSelected(newS);
        }
      }
    }
  }, [invitations, selected, onSetSelected]);
  useEffect(() => {
    if (groups === undefined) {
      onLoadGroups();
    }
  }, [groups, onLoadGroups]);
  useEffect(() => {
    if (clientGroups === undefined) {
      onLoadClientGroups();
    }
  }, [clientGroups, onLoadClientGroups]);

  useEffect(() => {
    if (orgAdminRoleId === undefined) {
      onLoadOrganizationRoles();
    }
  }, [orgAdminRoleId, onLoadOrganizationRoles]);
  const filters: TableFilter[] = resolveFilters([
    createStatusFilter('deliveryRequested', false),
    createStatusFilter('updated', false),
    createStatusFilter('created', false),
    createStatusFilter('revoked', false),
    createStatusFilter('accepted', false),
    createStatusFilter('declined', false),
    createStatusFilter('expired', false),
    {
      key: "hideClients",
      initial: false,
      view: (props: TableFilterViewProps) => <>{
        intl.formatMessage({
          defaultMessage: "Hide device client invitations",
          description:
              "Label for filter toggle that controls visibility of device client invitations",
        })
      }</>,
      apply: (itm: UIInvitation) => {
        return  itm.invitationType !== "client";
      },
    },
    {
      key: "hideUsers",
      initial: false,
      view: (props: TableFilterViewProps) => <>{
        intl.formatMessage({
          defaultMessage: "Hide user invitations",
          description:
              "Label for filter toggle that controls visibility of user invitations",
        })
      }</>,
      apply: (itm: UIInvitation) => {
        return  itm.invitationType !== "user";
      },
    },
   ], invitationsConf?.filters);
  //</editor-fold>
  return (
    <Page
      header={<h1>{title}</h1>}
      data-test-invitations-page
      id={"invitations-page"}
      meta={{
        title,
        description,
      }}
      {...others}
    >
      <ShowFeedback
        idPrefix={"invitations_"}
        onHideFeedback={onHideFeedback}
        feedback={feedback}
      />
      <Table<UIInvitation>
        data-test-invitations-table
        persistStateKey={"invitations-table"}
        allowColumnSort={true}
        selection={{
          multi: false,
          selectAll: false,
        }}
        filters={filters}
        header={<HeaderActions actions={invitationsConf.actions} actionRenderer={(action) => {
          let retVal = undefined;
          if (action.key === 'create') {
            if (invitationsConf.enableClientInvitations && invitationsConf.enableUserInvitations) {
              retVal = (
                  <Dropdown
                    key={action.key}
                    id={'selectInvitationType'}
                    onSelect={(key, e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onShowModal(key as ModalKeys);
                    }}
                  >
                    <Dropdown.Toggle as={"button"}
                        data-test-create-invitation-modal-trigger
                        className={"btn btn-primary custom-base"}
                    >
                      {intl.formatMessage({
                        defaultMessage: "Create",
                        description: "Label for the create invitation button",
                      })}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item data-test-create-invitation-modal-trigger eventKey={ModalKeys.create}>
                        {intl.formatMessage({
                          defaultMessage: "New user invitation",
                          description: "Label for the create user invitation dropdown",
                        })}
                      </Dropdown.Item>
                      <Dropdown.Item data-test-create-device-client-invitation-modal-trigger
                                     eventKey={ModalKeys.createClientInvitation}>
                        {intl.formatMessage({
                          defaultMessage: "New device client invitation",
                          description: "Label for the create device client invitation dropdown",
                        })}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>);
            } else if (invitationsConf.enableClientInvitations && !invitationsConf.enableUserInvitations) {
              retVal = (<Button
                  key={action.key}
                  data-test-create-invitation-modal-trigger
                  variant={"primary"}
                  className={"btn custom-base"}
                  action={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onShowModal(ModalKeys.createClientInvitation);
                  }}
              >
                {intl.formatMessage({
                  defaultMessage: "Create",
                  description: "Label for the create invitation button",
                })}
              </Button>);
            } else if (invitationsConf.enableUserInvitations) {
              retVal = (<Button
                  key={action.key}
                  data-test-create-invitation-modal-trigger
                  variant={"primary"}
                  className={"btn custom-base"}
                  action={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      onShowModal(ModalKeys.create);
                  }}
              >
                {intl.formatMessage({
                  defaultMessage: "Create",
                  description: "Label for the create invitation button",
                })}
              </Button>);
            }
          } else if (!!action.url) {
            retVal = (
                <Button
                    key={action.key}
                    data-test-custom-action={action.key}
                    action={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      window.open(action.url, '_blank');
                    }}
                    variant={"primary"}
                    className={"btn custom-base"}
                >
                  {intl.formatMessage({
                    defaultMessage: "{action, select, other {{action}}}",
                    description: "Custom header action label. Prints action key by default. Inject `actionKey {Label}` after the `select,` and before the `other` to provide translation for custom action. Only use space as separator with multiple actions, no comma.",
                  }, {
                    action: action.key
                  })}
                </Button>
            );
          }
          return retVal;
        }} />}
        search={true}
        rowClasses={resolveRowClasses}
        selected={selected}
        onSelectionChanged={onSetSelected}
        columns={columns}
        columnToggle
        reload={true}
        onLoadData={onLoadInvitations}
        data={invitations ? invitations : undefined}
        identifyingColumn={"id"}
        pagination={false}
        rowTools={(props: { rowEntry: UIInvitation }) => (
                invitationsConf.rowActions?.map((m) => {
                  let retVal = undefined;
                  if (!m.disabled) {
                    if (m.key === 'show') {
                      if (props.rowEntry.invitationType === 'user' && invitationsConf.enableUserInvitations) {
                        retVal = (<Dropdown.Item
                            data-test-row-tool-invitation-details={
                              props.rowEntry.id
                            }
                            onClick={(e: any) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onShowModal(ModalKeys.show, props.rowEntry.id);
                            }}
                        >
                          {intl.formatMessage({
                            defaultMessage: "View details",
                            description: "Label for the tool",
                          })}
                        </Dropdown.Item>);
                      } else if (props.rowEntry.invitationType === 'client' && invitationsConf.enableClientInvitations) {
                        retVal = (<Dropdown.Item
                            data-test-row-tool-invitation-details={
                              props.rowEntry.id
                            }
                            onClick={(e: any) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onShowModal(ModalKeys.showClientInvitation, props.rowEntry.id);
                            }}
                        >
                          {intl.formatMessage({
                            defaultMessage: "View details",
                            description: "Label for the tool",
                          })}
                        </Dropdown.Item>);
                      }
                    } else if (m.key === 'resend') {
                      if (props.rowEntry.invitationState !== "declined" &&
                          props.rowEntry.invitationState !== "revoked" &&
                          props.rowEntry.invitationState !== "accepted") {
                        if (props.rowEntry.invitationType === 'user' && invitationsConf.enableUserInvitations) {
                          retVal = (<Dropdown.Item
                              data-test-row-tool-invitation-item
                              data-test-row-tool-resend-invitation={
                                props.rowEntry.id
                              }
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShowModal(
                                    ModalKeys.resend,
                                    props.rowEntry.id
                                );
                              }}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Resend",
                              description: "Label for the tool",
                            })}
                          </Dropdown.Item>);
                        } else if (props.rowEntry.invitationType === 'client' && invitationsConf.enableClientInvitations) {
                          retVal = (<Dropdown.Item
                              data-test-row-tool-invitation-item
                              data-test-row-tool-resend-device-client-invitation={
                                props.rowEntry.id
                              }
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShowModal(
                                    ModalKeys.resendClientInvitation,
                                    props.rowEntry.id
                                );
                              }}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Resend",
                              description: "Label for the tool",
                            })}
                          </Dropdown.Item>);
                        }
                      }
                    } else if (m.key === 'revoke') {
                      if (props.rowEntry.invitationState !== "declined" &&
                          props.rowEntry.invitationState !== "revoked" &&
                          props.rowEntry.invitationState !== "accepted") {
                        if (props.rowEntry.invitationType === "user" && invitationsConf.enableUserInvitations) {
                          retVal = (<Dropdown.Item
                              data-test-row-tool-revoke-device-client-invitation={
                                props.rowEntry.id
                              }
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShowModal(
                                    ModalKeys.revoke,
                                    props.rowEntry.id
                                );
                              }}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Revoke",
                              description: "Label for the tool",
                            })}
                          </Dropdown.Item>);
                        } else if (props.rowEntry.invitationType === 'client' && invitationsConf.enableClientInvitations) {
                          retVal = (<Dropdown.Item
                              data-test-row-tool-revoke-device-client-invitation={
                                props.rowEntry.id
                              }
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShowModal(
                                    ModalKeys.revokeClientInvitation,
                                    props.rowEntry.id
                                );
                              }}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Revoke",
                              description: "Label for the tool",
                            })}
                          </Dropdown.Item>);
                        }
                      }
                    } else if (m.key === 'remove') {
                      if (props.rowEntry.invitationType === 'user' && invitationsConf.enableUserInvitations) {
                        retVal = (<Dropdown.Item
                            data-test-row-tool-remove-invitation={props.rowEntry.id}
                            onClick={(e: any) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onShowModal(ModalKeys.remove, props.rowEntry.id);
                            }}
                        >
                          {intl.formatMessage({
                            defaultMessage: "Remove",
                            description: "Label for the tool",
                          })}
                        </Dropdown.Item>);
                      } else if (props.rowEntry.invitationType === 'client' && invitationsConf.enableClientInvitations) {
                        retVal = (<Dropdown.Item
                            data-test-row-tool-remove-invitation={props.rowEntry.id}
                            onClick={(e: any) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onShowModal(ModalKeys.removeClientInvitation, props.rowEntry.id);
                            }}
                        >
                          {intl.formatMessage({
                            defaultMessage: "Remove",
                            description: "Label for the tool",
                          })}
                        </Dropdown.Item>);
                      }
                    } else if (!!m.url) {
                      retVal = (
                          <Dropdown.Item
                              data-test-row-tool-custom={m.key}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                window.open(m.url, '_blank');
                              }}
                              className={'custom-action'}
                          >
                            <span>{intl.formatMessage({
                              defaultMessage: "{action, select, other {{action}}}",
                              description: "Custom row action label. Prints action key by default. Inject `actionKey {Label}` after the `select,` and before the `other` to provide translation for custom action. Only use space as separator with multiple actions, no comma.",
                            }, {
                              action: m.key
                            })}</span>
                            <FontAwesomeIcon icon={IconLibrary.icons.faExternalLinkAlt} className={'icon'} fixedWidth={true} />
                          </Dropdown.Item>
                      );
                    }
                  }
                  return retVal;
                })
        )}
      />
      {(hasAction(invitationsConf.rowActions, 'show') && invitationsConf.enableUserInvitations) && (
        <ViewInvitationModal
          show={showModal ? showModal.key === ModalKeys.show : false}
          onClose={onHideModal}
          invitationId={showModal?.invitationId}
        />
      )}
      {(hasAction(invitationsConf.rowActions, 'show') && invitationsConf.enableClientInvitations) && (
          <ViewDeviceClientInvitationModal
              show={showModal ? showModal.key === ModalKeys.showClientInvitation : false}
              onClose={onHideModal}
              invitationId={showModal?.invitationId}
          />
      )}
      {(hasAction(invitationsConf.rowActions, 'resend') && invitationsConf.enableUserInvitations) && (
          <ResendInvitationModal
              show={showModal ? showModal.key === ModalKeys.resend : false}
              onClose={onHideModal}
              invitationId={showModal?.invitationId}
              onShowFeedback={onShowFeedback}
          />
      )}
      {(hasAction(invitationsConf.rowActions, 'resend') && invitationsConf.enableClientInvitations) && (
          <ResendDeviceClientInvitationModal
              show={showModal ? showModal.key === ModalKeys.resendClientInvitation : false}
              onClose={onHideModal}
              invitationId={showModal?.invitationId}
              onShowFeedback={onShowFeedback}
          />
      )}
      {(hasAction(invitationsConf.rowActions, 'revoke') && invitationsConf.enableUserInvitations) && (
          <RevokeInvitationModal
              show={showModal ? showModal.key === ModalKeys.revoke : false}
              onClose={onHideModal}
              invitationId={showModal?.invitationId}
              onShowFeedback={onShowFeedback}
          />
      )}
      {(hasAction(invitationsConf.rowActions, 'revoke') && invitationsConf.enableClientInvitations) && (
          <RevokeDeviceClientInvitationModal
              show={showModal ? showModal.key === ModalKeys.revokeClientInvitation : false}
              onClose={onHideModal}
              invitationId={showModal?.invitationId}
              onShowFeedback={onShowFeedback}
          />
      )}
      {(hasAction(invitationsConf.rowActions, 'remove') && invitationsConf.enableUserInvitations) && (
          <RemoveInvitationModal
              show={showModal ? showModal.key === ModalKeys.remove : false}
              onClose={onHideModal}
              invitationId={showModal?.invitationId}
              onShowFeedback={onShowFeedback}
          />
      )}
      {(hasAction(invitationsConf.rowActions, 'remove') && invitationsConf.enableClientInvitations) && (
          <RemoveDeviceClientInvitationModal
              show={showModal ? showModal.key === ModalKeys.removeClientInvitation : false}
              onClose={onHideModal}
              invitationId={showModal?.invitationId}
              onShowFeedback={onShowFeedback}
          />
      )}
      {(invitationsConf.showGroup && invitationsConf.enableUserInvitations) && (
          <ViewGroupModal
              show={showModal ? showModal.key === ModalKeys.showGroup : false}
              onClose={onHideModal}
              onShowFeedback={onShowFeedback}
              groupId={showModal?.secondaryId}
          />
      )}
      {(invitationsConf.showGroup && invitationsConf.enableClientInvitations) && (
          <ViewDeviceClientGroupModal
              show={showModal ? showModal.key === ModalKeys.showClientGroup : false}
              onShowFeedback={onShowFeedback}
              onClose={onHideModal}
              edit={false}
              allowEdit={false}
              clientGroupId={showModal ? showModal.secondaryId : undefined}
          />
      )}
      {(hasAction(invitationsConf.actions, 'create') && invitationsConf.enableUserInvitations) && (
          <CreateInvitationModal
              show={showModal ? showModal.key === ModalKeys.create : false}
              onClose={onHideModal}
              onShowFeedback={onShowFeedback}
          />
      )}
      {(hasAction(invitationsConf.actions, 'create') && invitationsConf.enableClientInvitations) && (
          <CreateDeviceClientInvitationModal
              show={showModal ? showModal.key === ModalKeys.createClientInvitation : false}
              onClose={onHideModal}
              onShowFeedback={onShowFeedback}
          />
      )}
    </Page>
  );
}

export default Invitations;
