import { createStore, applyMiddleware, compose, StoreEnhancer } from "redux";
import { thunk, ThunkMiddleware, ThunkDispatch } from "redux-thunk";
import rootReducer from "../reducers";
import { Authentication } from "../model/Authentication";
import { AppState } from "./AppState";
import * as ActionTypes from "../actions/actionTypes";
import { PendingAuthentication } from "../model/PendingAuthentication";
import { saveState, loadState } from "./localStorage";
import { lazyThrottle } from "../util/throttle";
import _debug from "debug";
import { LogoutCompleted } from "../model/LogoutCompleted";
import { AppError } from "../model/AppError";
const debug = _debug("store");

/**
 * Empty / initial Redux store state.
 */
export const EMPTY_STATE: AppState = {
  appOrganizationIds: null,
  authentication: null,
  entitlements: null,
  errors: [],
  inProgress: [],
  licenseAssignments: null,
  licenses: null,
  licenseUsage: null,
  logoutCompleted: null,
  organizations: null,
  orgEntitlementIds: null,
  entitlementUserIds: null,
  entitlementClientIds: null,
  orgGroupEntitlementIds: null,
  orgEntitlementGroupIds: null,
  orgGroups: null,
  orgClients: null,
  orgClientIds: null,
  orgClientGroups: null,
  orgClientGroupIds: null,
  orgClientGroupClientIds: null,
  orgClientClientGroupIds: null,
  orgClientGroupEntitlementIds: null,
  orgEntitlementClientGroupIds: null,
  orgGroupUserIds: null,
  orgInvitations: null,
  orgLicenseIds: null,
  orgOrgGroupIds: null,
  orgOrgInvitationIds: null,
  orgOrgRoleIds: null,
  orgRoles: null,
  orgRoleUserIds: null,
  orgRoleGrantedPermissions: null,
  orgRoleOrgRoleIds: null,
  orgUserIds: null,
  pendingAuthentication: null,
  permissions: null,
  selectedOrganizationId: null,
  userAvailableLicenses: null,
  clientAvailableLicenses: null,
  userOrgGroupIds: null,
  userOrgRoleIds: null,
  users: null,
  clientInvitations: null,
  orgClientInvitationIds: null,
} as any;

/**
 * Empty / initial Redux store state.
 */
export const STATE_WITH_EMPTY_VALUES: AppState = {
  appOrganizationIds: {},
  authentication: {},
  entitlements: {},
  errors: [],
  inProgress: [],
  licenseAssignments: {},
  licenses: {},
  licenseUsage: {},
  logoutCompleted: {},
  organizations: {},
  orgEntitlementIds: {},
  entitlementUserIds: {},
  entitlementClientIds: {},
  orgGroupEntitlementIds: {},
  orgEntitlementGroupIds: {},
  orgGroups: {},
  orgClients: {},
  orgClientIds: {},
  orgClientGroups: {},
  orgClientGroupIds: {},
  orgClientGroupClientIds: {},
  orgClientClientGroupIds: {},
  orgClientGroupEntitlementIds: {},
  orgEntitlementClientGroupIds: {},
  orgGroupUserIds: {},
  orgInvitations: {},
  orgLicenseIds: {},
  orgOrgGroupIds: {},
  orgOrgInvitationIds: {},
  orgOrgRoleIds: {},
  orgRoles: {},
  orgRoleUserIds: {},
  orgUserIds: {},
  pendingAuthentication: {},
  permissions: {},
  selectedOrganizationId: null,
  userAvailableLicenses: {},
  clientAvailableLicenses: {},
  userOrgGroupIds: {},
  users: {},
  clientInvitations: {},
  orgClientInvitationIds: {},
} as any;

// Initialize the store
const persistedState =
  (global as any).stateForTests || loadState() || EMPTY_STATE;
debug("initial redux state: %o", persistedState);
const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const thunkMiddlewareEnhancer = applyMiddleware(
  thunk as ThunkMiddleware<AppState, ActionTypes.AppAction>
);
const enhancers: StoreEnhancer<
  {
    dispatch: ThunkDispatch<AppState, undefined, ActionTypes.AppAction>;
  },
  {}
> = composeEnhancers(thunkMiddlewareEnhancer);
const store = createStore(rootReducer, persistedState, enhancers);

// Auto-save store to local storage
store.subscribe(
  lazyThrottle(() => {
    saveStore();
  }, 1000)
);

/**
 * Saves store to local storage, selecting which parts of store need to be saved.
 */
export function saveStore(): void {
  const fullState = store.getState();
  const stateToStore: AppState = {
    pendingAuthentication: fullState.pendingAuthentication,
    selectedOrganizationId: fullState.selectedOrganizationId,
  };
  saveState(stateToStore);
}

export function getAuthentication(): Authentication | undefined {
  return store.getState() && store.getState().authentication;
}

export function getPendingAuthentication(): PendingAuthentication | undefined {
  return store.getState() && store.getState().pendingAuthentication;
}

export function getLogoutCompleted(): LogoutCompleted | undefined {
  return store.getState() && store.getState().logoutCompleted;
}

export function getSelectedOrgId(): string | undefined {
  return store.getState() && store.getState().selectedOrganizationId;
}

export function getSingleOrgId(): string | undefined {
  const appOrgIds = store.getState() && store.getState().appOrganizationIds;
  return appOrgIds && appOrgIds.length === 1 ? appOrgIds[0] : undefined;
}

export function getSelectedOrSingleOrgId(): string | undefined {
  return getSelectedOrgId() || getSingleOrgId();
}

export function hasErrors(): boolean {
  return (
    store.getState() &&
    store.getState().errors !== undefined &&
    store.getState().errors !== null &&
    (store.getState().errors as AppError<any>[]).length > 0
  );
}

export default store;
