import { useContext, useEffect } from "react";
import {
    TooltipWrapper,
    FeedbackContainerProps,
    ShowFeedback,
    Page,
    PageDOMProps,
    Button,
    IconLibrary
} from "@10duke/dukeui";
import { useIntl } from "react-intl";
import Table, { TableColumn } from "../../table";
import { Dropdown } from "react-bootstrap";
import { OrganizationRole } from "../../../model/OrganizationRole";
import ViewRoleModal from "../../modals/view-role-modal";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import RoleTypeBadge from "../../badges/role-type-badge";
import { OrganizationRoleLabels } from "../../../localization/organization-role";
import {hasAction, resolveColumns} from "../../../ui-configuration/configuration-tools";
import RemoveRoleModal from "../../modals/remove-role-modal";
import CreateOrganizationRoleModal from "../../modals/create-organization-role-modal";
import ManageRoleMembersModal from "../../modals/manage-role-members-modal";
import {ClientGroup} from "../../../model/ClientGroup";
import HeaderActions from "../../table/header-actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export enum ModalKeys {
  show = "show",
  edit = "edit",
  remove = "remove",
  members = "members",
  create = "create",
}
//<editor-fold desc="Props">

export interface RolesModalVisibilityProps {
  showModal?: { key: ModalKeys; roleId?: string; secondaryId?: string };
  onShowModal: (key: ModalKeys, roleId?: string, secondaryId?: string) => void;
  onHideModal: (callback?: () => void) => void;
}
export interface RolesDOMProps extends PageDOMProps {}

export interface RolesStateProps extends FeedbackContainerProps {
  selected: OrganizationRole[];
  onSetSelected: (selection: OrganizationRole[]) => void;
}
export interface RolesProps
  extends RolesStateProps,
    RolesDOMProps,
    RolesModalVisibilityProps {
  userName?: string;
  userId?: string;
  roles?: OrganizationRole[];
  onLoadRoles: () => Promise<any>;
  orgAdminRoleId?: string;
}
//</editor-fold>

//<editor-fold desc="Messages">
//</editor-fold>

//<editor-fold desc="Utils">

const resolveRowClasses = (row: OrganizationRole, rowIndex: number) => {
  return (row as any).type;
};

const resolveRole = (
  roleId: string | undefined,
  roles: OrganizationRole[] | undefined
) => {
  let retVal: OrganizationRole | undefined | null;
  if (roleId && roles) {
    retVal = roles.find((val) => val.id === roleId);
    if (!retVal) {
      retVal = null;
    }
  }
  return retVal;
};

//</editor-fold>

function Roles(props: RolesProps) {
  //<editor-fold desc="Local variables">
  const {
    roles,
    onLoadRoles,
    selected,
    onSetSelected,
    onShowModal,
    onHideModal,
    showModal,
    onShowFeedback,
    onHideFeedback,
    feedback,
    userName,
    userId,
    orgAdminRoleId,
    ...others
  } = props;

  const modalRole: OrganizationRole | undefined | null = showModal
    ? resolveRole(showModal.roleId, roles)
    : undefined;

  // this is more like a variable than a hook
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: "Manage roles",
    description: "heading for the Manage roles page",
  });
  const description = intl.formatMessage({
    defaultMessage: "Organization role management",
    description: "window meta description for the Manage roles screen",
  });

  const { conf } = useContext(UIConfiguration);
  const roleConf =
    conf.functionality && conf.functionality.roles
      ? conf.functionality.roles
      : {};

  let columns: TableColumn[] = [
    {
      key: "id",
      label: intl.formatMessage(OrganizationRoleLabels.id),
      isTechnical: true,
      hidden: true,
    },
    {
      key: "type",
      sortable: true,
      label: intl.formatMessage(OrganizationRoleLabels.type),
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return <RoleTypeBadge type={props.cell} />;
      },
      tipRenderer: () => false,
    },
    {
      key: "name",
      sortable: true,
      label: intl.formatMessage(OrganizationRoleLabels.name),
    },
    {
      key: "description",
      sortable: true,
      label: intl.formatMessage(OrganizationRoleLabels.description),
    },
  ];
  if (roleConf.columns && roleConf.columns.length > 0) {
    columns = resolveColumns(columns, roleConf.columns);
  }
  //</editor-fold>

  //<editor-fold desc="Hooks">

  // resets selection when users are undefined, and clears out selected items that are not part of updated data
  useEffect(() => {
    if (selected.length) {
      if (roles === undefined) {
        onSetSelected([]);
      } else {
        const ids = roles.map((grp: OrganizationRole) => grp.id);
        const newS = selected.filter((itm: any) => {
          return ids.indexOf(itm.id) >= 0;
        });
        if (newS.length !== selected.length) {
          onSetSelected(newS);
        }
      }
    }
  }, [roles, selected, onSetSelected]);
  //</editor-fold>
  return (
    <Page
      header={<h1>{title}</h1>}
      data-test-roles-page
      id={"roles-page"}
      meta={{
        title,
        description,
      }}
      {...others}
    >
      <ShowFeedback
        idPrefix={"roles_"}
        onHideFeedback={onHideFeedback}
        feedback={feedback}
      />
      <Table<OrganizationRole>
        data-test-roles-table
        persistStateKey={"roles-table"}
        allowColumnSort={true}
        selection={{
          multi: false,
          selectAll: false,
        }}
        header={<HeaderActions actions={roleConf.actions} actionRenderer={(action) => {
            let retVal = undefined;
            if (action.key === 'create') {
                retVal = (
                    <Button
                        key={action.key}
                        data-test-create-organization-role-trigger
                        action={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onShowModal(ModalKeys.create);
                        }}
                        variant={"primary"}
                        className={"btn custom-base"}
                    >
                        {intl.formatMessage({
                            defaultMessage: "Create",
                            description: "create role button label",
                        })}
                    </Button>
                );
            } else if (!!action.url) {
                retVal = (
                    <Button
                        key={action.key}
                        data-test-custom-action={action.key}
                        action={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.open(action.url, '_blank');
                        }}
                        variant={"primary"}
                        className={"btn custom-base"}
                    >
                        {intl.formatMessage({
                            defaultMessage: "{action, select, other {{action}}}",
                            description: "Custom header action label. Prints action key by default. Inject `actionKey {Label}` after the `select,` and before the `other` to provide translation for custom action. Only use space as separator with multiple actions, no comma.",
                        }, {
                            action: action.key
                        })}
                    </Button>
                );
            }
            return retVal;
        }} />
        }
        search={true}
        rowClasses={resolveRowClasses}
        selected={selected}
        onSelectionChanged={onSetSelected}
        columns={columns}
        columnToggle
        reload={true}
        onLoadData={onLoadRoles}
        data={roles ? roles : undefined}
        identifyingColumn={"id"}
        pagination={false}
        rowTools={(props: { rowEntry: ClientGroup }) => (
                roleConf.rowActions?.map((m) => {
                    let retVal = undefined;
                  if (!m.disabled) {
                    if (m.key === 'show') {
                        retVal = (
                          <Dropdown.Item
                              data-test-row-tool-role-details={props.rowEntry.id}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShowModal(ModalKeys.show, props.rowEntry.id);
                              }}
                          >
                            {intl.formatMessage({
                              defaultMessage: "View details",
                              description: "Label for the tool",
                            })}
                          </Dropdown.Item>
                      );
                    } else if (m.key === 'members') {
                        retVal = (
                          <Dropdown.Item
                              data-test-row-tool-role-members={props.rowEntry.id}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShowModal(ModalKeys.members, props.rowEntry.id);
                              }}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Members",
                              description: "Label for the tool",
                            })}
                          </Dropdown.Item>
                      );
                    } else if (m.key === 'remove') {
                      if (props.rowEntry.id === orgAdminRoleId) {
                          retVal = (
                            <TooltipWrapper
                                tip={intl.formatMessage({
                                  defaultMessage: 'The "admin" role cannot be removed.',
                                  description:
                                      "tooltip for disabled admin role removal",
                                })}
                                tipKey={"removeBlockedTip"}
                                placement={"auto"}
                                wrapDisabled={true}
                                className={"d-block"}
                            >
                              <Dropdown.Item
                                  data-test-row-tool-remove-role={props.rowEntry.id}
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onShowModal(ModalKeys.remove, props.rowEntry.id);
                                  }}
                                  disabled={true}
                              >
                                {intl.formatMessage({
                                  defaultMessage: "Remove",
                                  description: "Label for the tool",
                                })}
                              </Dropdown.Item>
                            </TooltipWrapper>
                        );
                      } else {
                          retVal = (
                            <Dropdown.Item
                                data-test-row-tool-remove-role={props.rowEntry.id}
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onShowModal(ModalKeys.remove, props.rowEntry.id);
                                }}
                            >
                              {intl.formatMessage({
                                defaultMessage: "Remove",
                                description: "Label for the tool",
                              })}
                            </Dropdown.Item>
                        );
                      }
                    } else if (!!m.url) {
                        retVal = (
                          <Dropdown.Item
                              data-test-row-tool-custom={m.key}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                window.open(m.url, '_blank');
                              }}
                              className={'custom-action'}
                          >
                              <span>{intl.formatMessage({
                                  defaultMessage: "{action, select, other {{action}}}",
                                  description: "Custom row action label. Prints action key by default. Inject `actionKey {Label}` after the `select,` and before the `other` to provide translation for custom action. Only use space as separator with multiple actions, no comma.",
                              }, {
                                  action: m.key
                              })}</span>
                              <FontAwesomeIcon icon={IconLibrary.icons.faExternalLinkAlt} className={'icon'} fixedWidth={true} />
                          </Dropdown.Item>
                      );
                    }
                  }
                  return retVal;
                  })
        )}
      />
      {hasAction(roleConf.rowActions, 'show') && (
        <ViewRoleModal
          show={
            showModal && showModal.roleId
              ? showModal.key === ModalKeys.show ||
                (roleConf.allowEdit && showModal.key === ModalKeys.edit)
                ? true
                : false
              : false
          }
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
          edit={showModal ? showModal.key === ModalKeys.edit : false}
          allowEdit={roleConf.edit ? true : false}
          roleId={showModal ? showModal.roleId : undefined}
        />
      )}
      {hasAction(roleConf.rowActions, 'remove') && (
        <RemoveRoleModal
          show={
            showModal && showModal.roleId
              ? showModal.key === ModalKeys.remove
              : false
          }
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
          roleId={showModal ? showModal.roleId : undefined}
        />
      )}
      {hasAction(roleConf.actions, 'create') && (
        <CreateOrganizationRoleModal
          show={showModal ? showModal.key === ModalKeys.create : false}
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
        />
      )}
      {hasAction(roleConf.rowActions, 'members') && (
        <ManageRoleMembersModal
          show={
            showModal
              ? showModal.key === ModalKeys.members && modalRole !== undefined
              : false
          }
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
          roleId={showModal ? showModal.roleId : undefined}
        />
      )}
    </Page>
  );
}
export default Roles;
