import * as Oazapfts from "oazapfts/lib/runtime";
import { ApiError } from "../../gen/api/entitlement/entitlement-client";
import { handleApiCall } from "../api-util/ApiResponseUtil";
import { DownloadLicenseRequest } from "../../model/DownloadLicenseRequest";
import { AuthzApi } from "../AuthzApi";
export const defaults: Oazapfts.RequestOpts = {
  baseUrl: "http://localhost:8080",
};
const oazapfts = Oazapfts.runtime(defaults);
class RealAuthz implements AuthzApi {
  initialize(initProvider: (name: string) => string): void {

    defaults.baseUrl = initProvider("authzApiBase");
    defaults.headers = {};
    defaults.headers["Authorization"] = "Bearer " + initProvider("accessToken");
    defaults.cache = "no-cache";
  }
  consumeLicenseForTokenDownload(data: DownloadLicenseRequest): Promise<any> {
    return handleApiCall(
      oazapfts.fetchJson<
        | {
            status: 200;
            data: any;
          }
        | {
            status: 404;
            data: ApiError;
          }
        | {
            status: number;
            data: ApiError;
          }
      >(
        `.${data.doConsume ? "jwt" : "json"}?${data.license.name}&licenseId=${
          data.license.licenseId
        }&hw=${data.hardwareId}&onBehalfOfId=${
          data.onBehalfOfId
        }&consumptionMode=${data.consumptionMode}&doConsume=${data.doConsume}${
          data.version !== undefined ? "&version=" + data.version : ""
        }`
      )
    );
  }

  /**
   *
   */
  releaseLicenseConsumptionById(ids: string[]): Promise<any> {
    const idstring = ids.join('&');
    return handleApiCall(
        oazapfts.fetchJson<
            | {
          status: 200;
          data: any;
        }
            | {
          status: 404;
          data: ApiError;
        }
            | {
          status: number;
          data: ApiError;
        }
        >(
            `.json?release&${idstring}`
        )
    );
  };
}
export default RealAuthz;
