import {
  DeleteClientGroupResult,
  DeleteOrganizationGroupResult,
  IdpApi, RemoveClientGroupsOfClientResult,
  RemoveOrgGroupOfUserResult,
  RemoveOrgGroupsOfUserResult,
  RemoveUsersFromOrgGroupResult,
  SetOrgGroupsOfUserResult,
  SetUsersInOrgGroupResult,
} from "../IdpApi";
import { User, UserForCreate } from "../../model/User";
import { Organization } from "../../model/Organization";
import { OrganizationGroup } from "../../model/OrganizationGroup";
import { OrganizationRole } from "../../model/OrganizationRole";
import { OrganizationGroupInvitation } from "../../model/OrganizationGroupInvitation";
import { PermissionGrantsForPermission } from "../../model/PermissionGrantsForPermission";
import { InternalPermissionWithGrantedActions } from "../../model/InternalPermissionWithGrantedActions";
import * as IdpClient from "../../gen/api/idp/idp-client";
import {
  createMembershipRemovingResult,
  createOrganizationRemovingResult,
  handleApiCall,
  handleApiCallWithFullResponse,
} from "../api-util/ApiResponseUtil";
import { InternalPermission } from "../../model/InternalPermission";
import { ensureSelectedOrgId } from "../../actions/actionHelpers";
import { InitialUIConfiguration } from "../../ui-configuration/configuration-provider";
import {Client} from "../../model/Client";
import {ClientGroup} from "../../model/ClientGroup";
import {ClientGroupInvitation} from "../../model/ClientGroupInvitation";

const defaultListQueryHeaders: { limit: number } = { limit: -1 };

class RealIdp implements IdpApi {
  initialize(initProvider: (name: string) => string): void {
    IdpClient.defaults.baseUrl = initProvider("idpApiBase");
    IdpClient.defaults.headers = IdpClient.defaults.headers || {};
    (IdpClient.defaults.headers as any)["Authorization"] =
      "Bearer " + initProvider("accessToken");
    IdpClient.defaults.cache = "no-cache";
  }

  getApiInfo(): Promise<{ version: string; [key: string]: any }> {
    return new Promise((resolve, reject) => {
      fetch(IdpClient.defaults.baseUrl + "/openapi?info")
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(
              `Loading api information failed: ${response.status} (${response.statusText})`
            );
          }
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getAppOrganizations(userId?: string): Promise<Organization[]> {
    return handleApiCall(
      IdpClient.listAuthorizedOrganizationsOfUser(
        userId as string,
        defaultListQueryHeaders
      )
    );
  }

  getOrganization(orgId?: string): Promise<Organization> {
    return handleApiCall(IdpClient.getOrganization(orgId as string));
  }

  replaceOrganization(org: Organization): Promise<Organization> {
    return handleApiCall(
      IdpClient.replaceOrganization({ ...org, id: org.id as string })
    );
  }

  setMfaRequired(required: boolean, orgId?: string): Promise<void> {
    // Note: IdentityRest service does not support this yet.
    throw new Error("Method not implemented.");
  }

  listAllOrganizationUsers(orgId?: string): Promise<User[]> {
    return handleApiCall(
      IdpClient.listOrganizationUsers(orgId as string, defaultListQueryHeaders)
    );
  }

  listOrganizationGroups(orgId?: string): Promise<OrganizationGroup[]> {
    return handleApiCall(
        IdpClient.listOrganizationGroups(orgId as string, defaultListQueryHeaders)
    );
  }
  listOrganizationClients(orgId?: string): Promise<Client[]> {
    return handleApiCall(
        IdpClient.listOrganizationClients(orgId as string, defaultListQueryHeaders)
    );
  }

  listOrganizationClientGroups(orgId?: string): Promise<ClientGroup[]> {
    return handleApiCall(
        IdpClient.listOrganizationClientGroups(orgId as string, defaultListQueryHeaders)
    );
  }

  /**
   * Create client group
   */
  createOrganizationClientGroup(orgId: string, clientGroup: ClientGroup): Promise<ClientGroup> {
    return handleApiCall(
        IdpClient.createOrganizationClientGroup(orgId, {
          ...clientGroup,
          id: clientGroup.id as string,
        })
    )
  }
  async deleteOrganizationClientGroup(
      clientGroupId: string,
      organizationId?: string
  ): Promise<DeleteClientGroupResult> {
    const orgId: string = organizationId || ensureSelectedOrgId();
    const response = await handleApiCallWithFullResponse(
        IdpClient.deleteOrganizationClientGroup(orgId as string, clientGroupId)
    );
    return createMembershipRemovingResult(response);
  }

  getOrganizationClientGroup(
      orgclientGroupId: string,
      orgId?: string
  ): Promise<ClientGroup> {
    return handleApiCall(
        IdpClient.getOrganizationClientGroup(orgId as string, orgclientGroupId)
    );
  }

  /**
   * Replace a client group's details
   */
  replaceOrganizationClientGroup(organizationId: string, clientGroup: ClientGroup): Promise<ClientGroup> {
    return handleApiCall(
        IdpClient.replaceOrganizationClientGroup(organizationId as string, {
          ...clientGroup,
          id: clientGroup.id as string,
        })
    );
  }
  createOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return handleApiCall(
      IdpClient.createOrganizationGroup(orgId as string, {
        ...orgGroup,
        id: orgGroup.id as string,
      })
    );
  }

  replaceOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return handleApiCall(
      IdpClient.replaceOrganizationGroup(orgId as string, {
        ...orgGroup,
        id: orgGroup.id as string,
      })
    );
  }

  getOrganizationGroup(
    orgGroupId: string,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return handleApiCall(
      IdpClient.getOrganizationGroup(orgId as string, orgGroupId)
    );
  }
  async deleteOrganizationGroup(
    orgGroupId: string,
    orgId?: string,
    opts?: {
      cleanUpLicenseReservations?: boolean;
      returnNoMembershipsUserIds?: boolean;
    }
  ): Promise<DeleteOrganizationGroupResult> {
    const response = await handleApiCallWithFullResponse(
      IdpClient.deleteOrganizationGroup(orgId as string, orgGroupId, opts)
    );
    return createMembershipRemovingResult(response);
  }

  listOrganizationRoles(orgId?: string): Promise<OrganizationRole[]> {
    return handleApiCall(
      IdpClient.listOrganizationRoles(orgId as string, defaultListQueryHeaders)
    );
  }
  listOrganizationRolesInOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<OrganizationRole[]> {
    return handleApiCall(
      IdpClient.listOrganizationRolesInOrganizationRole(
        orgId as string,
        orgRoleId,
        defaultListQueryHeaders
      )
    );
  }
  public async setOrganizationRolesInOrganizationRole(
    orgId: string,
    orgRoleId: string,
    roles: string[]
  ): Promise<void> {
    return handleApiCall(
      IdpClient.setOrganizationRolesInOrganizationRole(
        orgId as string,
        orgRoleId,
        roles
      )
    );
  }
  createOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    return handleApiCall(
      IdpClient.createOrganizationRole(orgId as string, {
        ...orgRole,
        id: orgRole.id as string,
      })
    );
  }

  replaceOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    return handleApiCall(
      IdpClient.replaceOrganizationRole(orgId as string, {
        ...orgRole,
        id: orgRole.id as string,
      })
    );
  }

  getOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<OrganizationRole> {
    return handleApiCall(
      IdpClient.getOrganizationRole(orgId as string, orgRoleId as string)
    );
  }

  async deleteOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<void> {
    await handleApiCall(
      IdpClient.deleteOrganizationRole(orgId as string, orgRoleId)
    );
  }

  getUser(userId: string): Promise<User> {
    return handleApiCall(IdpClient.getUser(userId));
  }

  async deleteUser(userId: string): Promise<void> {
    await handleApiCall(IdpClient.deleteUser(userId));
  }

  replaceUser(user: User): Promise<User> {
    return handleApiCall(
      IdpClient.replaceUser({ ...user, id: user.id as string })
    );
  }

  setUserSuspended(suspended: boolean, userId: string): Promise<User> {
    return handleApiCall(IdpClient.setUserActive(userId, !suspended));
  }

  async deleteOtpCredential(userId: string): Promise<void> {
    await handleApiCall(IdpClient.deleteOtpCredential(userId));
  }

  listOrganizationGroupsOfUser(userId: string, organizationId?: string): Promise<OrganizationGroup[]> {
    return handleApiCall(
      IdpClient.listOrganizationGroupsOfUser(userId, { organizationId, ...defaultListQueryHeaders})
    );
  }
  listClientGroupsOfClient(clientId: string, organizationId?: string): Promise<ClientGroup[]> {
    return handleApiCall(
        IdpClient.listClientGroupsOfClient(clientId, { organizationId, ...defaultListQueryHeaders})
    );
  }

  listOrganizationRolesOfUser(userId: string): Promise<OrganizationRole[]> {
    return handleApiCall(
      IdpClient.listOrganizationRolesOfUser(userId, defaultListQueryHeaders)
    );
  }

  async addOrganizationGroupForUser(
    groupId: string,
    userId: string
  ): Promise<void> {
    await handleApiCall(IdpClient.addOrganizationGroupForUser(userId, groupId));
  }

  async addOrganizationRoleForUser(
    roleId: string,
    userId: string
  ): Promise<void> {
    await handleApiCall(IdpClient.addOrganizationRoleForUser(userId, roleId));
  }

  async removeOrganizationGroupOfUser(
    groupId: string,
    userId: string
  ): Promise<RemoveOrgGroupOfUserResult> {
    const response = await handleApiCallWithFullResponse(
      IdpClient.removeOrganizationGroupOfUser(userId, groupId, {
        cleanUpLicenseReservations: true,
        returnNoMembershipsOrgIds: true,
      })
    );
    return createOrganizationRemovingResult(response);
  }

  listUsersInOrganizationGroup(
    groupId: string,
    orgId?: string
  ): Promise<User[]> {
    return handleApiCall(
      IdpClient.listUsersInOrganizationGroup(
        orgId as string,
        groupId,
        defaultListQueryHeaders
      )
    );
  }

  async addUsersToOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    await handleApiCall(
      IdpClient.addUsersToOrganizationGroup(orgId as string, groupId, userIds)
    );
  }

  async setUsersInOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string,
    opts?: {
      cleanUpLicenseReservations?: boolean;
      returnNoMembershipsUserIds?: boolean;
    }
  ): Promise<SetUsersInOrgGroupResult> {
    const response = await handleApiCallWithFullResponse(
      IdpClient.setUsersInOrganizationGroup(
        orgId as string,
        groupId,
        userIds,
        opts
      )
    );

    return createMembershipRemovingResult(response);
  }

  async removeUsersFromOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string,
    opts?: {
      cleanUpLicenseReservations?: boolean;
      returnNoMembershipsUserIds?: boolean;
    }
  ): Promise<RemoveUsersFromOrgGroupResult> {
    const response = await handleApiCallWithFullResponse(
      IdpClient.removeUsersFromOrganizationGroup(
        orgId as string,
        groupId,
        userIds,
        opts
      )
    );
    return createMembershipRemovingResult(response);
  }

  listOrganizationsOrganizationGroupInvitations(
    orgId?: string
  ): Promise<OrganizationGroupInvitation[]> {
    return handleApiCall(
      IdpClient.listOrganizationsOrganizationGroupInvitations(
        orgId as string,
        defaultListQueryHeaders
      )
    );
  }

  getOrganizationsOrganizationGroupInvitation(
    invitationId: string,
    orgId?: string
  ): Promise<OrganizationGroupInvitation> {
    return handleApiCall(
      IdpClient.getOrganizationsOrganizationGroupInvitation(
        orgId as string,
        invitationId
      )
    );
  }
  getOrganizationClientGroupInvitation(
      invitationId: string,
      orgId?: string
  ): Promise<ClientGroupInvitation> {
    return handleApiCall(
        IdpClient.getOrganizationClientGroupInvitation(
            orgId as string,
            invitationId
        )
    );
  }

  createOrganizationGroupInvitation(
    invitation: OrganizationGroupInvitation
  ): Promise<OrganizationGroupInvitation> {
    return handleApiCall(
      IdpClient.createOrganizationGroupInvitation({
        ...invitation,
        id: invitation.id as string,
      })
    );
  }

  sendOrganizationClientGroupInvitation(
      invitationId: string,
      orgId?:string
  ): Promise<ClientGroupInvitation> {
    return handleApiCall(
        IdpClient.sendOrganizationClientGroupInvitation(orgId as string, invitationId)
    );
  }
  sendOrganizationGroupInvitation(
    invitationId: string
  ): Promise<OrganizationGroupInvitation> {
    return handleApiCall(
      IdpClient.sendOrganizationGroupInvitation(invitationId)
    );
  }

  revokeOrganizationGroupInvitation(
    invitationId: string
  ): Promise<OrganizationGroupInvitation> {
    return handleApiCall(
      IdpClient.revokeOrganizationGroupInvitation(invitationId)
    );
  }
  revokeOrganizationClientGroupInvitation(
      invitationId: string,
      orgId?:string
  ): Promise<ClientGroupInvitation> {
    return handleApiCall(
        IdpClient.revokeOrganizationClientGroupInvitation(invitationId, ensureSelectedOrgId(orgId))
    );
  }
  async deleteOrganizationGroupInvitation(invitationId: string): Promise<void> {
    await handleApiCall(
      IdpClient.deleteOrganizationGroupInvitation(invitationId)
    );
  }

  /**
   * Delete a client group invitation
   */
  async deleteOrganizationClientGroupInvitation(invitationId: string, orgId?:string): Promise<void> {
    await handleApiCall(
        IdpClient.deleteOrganizationClientGroupInvitation(ensureSelectedOrgId(orgId), invitationId)
    );
  }

  listPermissionsOfOrganizationRole(
    orgRoleId: string
  ): Promise<InternalPermissionWithGrantedActions[]> {
    return handleApiCall(
      IdpClient.listPermissionsOfOrganizationRole(
        orgRoleId,
        defaultListQueryHeaders
      )
    );
  }
  listPermissions(): Promise<InternalPermission[]> {
    if (InitialUIConfiguration["identity-api"]?.includeOrganizationIdHeader) {
      const orgId: string = ensureSelectedOrgId();
      return handleApiCall(
        IdpClient.listPermissions({
          domain: "organization",
          ...defaultListQueryHeaders
        }, {
          ...IdpClient.defaults,
          headers: {
            ...IdpClient.defaults.headers,
            tendukeOrganizationId: orgId,
          },
        })
      );
    } else {
      return handleApiCall(IdpClient.listPermissions({
        domain: "organization",
        ...defaultListQueryHeaders
      }));
    }
  }

  async addPermissionsForOrganizationRole(
    grants: PermissionGrantsForPermission[],
    orgRoleId: string
  ): Promise<void> {
    await handleApiCall(
      IdpClient.addPermissionsForOrganizationRole(
        orgRoleId,
        grants.map((grant) => ({
          ...grant,
          id: grant.id as string,
          permissionId: grant.permissionId as string,
        }))
      )
    );
  }

  async setPermissionsOfOrganizationRole(
    grants: PermissionGrantsForPermission[],
    orgRoleId: string
  ): Promise<void> {
    await handleApiCall(
      IdpClient.setPermissionsOfOrganizationRole(
        orgRoleId,
        grants.map((grant) => ({
          ...grant,
          id: grant.id as string,
          permissionId: grant.permissionId as string,
        }))
      )
    );
  }

  async removePermissionsOfOrganizationRole(
    permissionIds: string[],
    orgRoleId: string
  ): Promise<void> {
    await handleApiCall(
      IdpClient.removePermissionsOfOrganizationRole(orgRoleId, permissionIds)
    );
  }

  async removePermissionOfOrganizationRole(
    permissionId: string,
    orgRoleId: string
  ): Promise<void> {
    await handleApiCall(
      IdpClient.removePermissionOfOrganizationRole(orgRoleId, permissionId)
    );
  }

  listUsersInOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<User[]> {
    return handleApiCall(
      IdpClient.listUsersInOrganizationRole(
        orgId as string,
        orgRoleId,
        defaultListQueryHeaders
      )
    );
  }

  async addUsersToOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    await handleApiCall(
      IdpClient.addUsersToOrganizationRole(orgId as string, orgRoleId, userIds)
    );
  }

  getUserInOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<User> {
    return handleApiCall(
      IdpClient.getUserInOrganizationRole(orgId as string, orgRoleId, userId)
    );
  }

  async addUserToOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    await handleApiCall(
      IdpClient.addUserToOrganizationRole(orgId as string, orgRoleId, userId)
    );
  }

  async removeUsersFromOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    await handleApiCall(
      IdpClient.removeUsersFromOrganizationRole(
        orgId as string,
        orgRoleId,
        userIds
      )
    );
  }

  async removeUserFromOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    await handleApiCall(
      IdpClient.removeUserFromOrganizationRole(
        orgId as string,
        orgRoleId,
        userId
      )
    );
  }

  async setUsersInOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    await handleApiCall(
      IdpClient.setUsersInOrganizationRole(orgId as string, orgRoleId, userIds)
    );
  }

  async addOrganizationGroupsForUser(
    groupIds: string[],
    userId: string
  ): Promise<void> {
    await handleApiCall(
      IdpClient.addOrganizationGroupsForUser(userId, groupIds)
    );
  }

  async addClientGroupsForClient(
      groupIds: string[],
      clientId: string
  ): Promise<void> {
    await handleApiCall(
        IdpClient.addClientGroupsForClient(clientId, groupIds)
    );
  }
  async addOrganizationRolesForUser(
    roleIds: string[],
    userId: string
  ): Promise<void> {
    await handleApiCall(IdpClient.addOrganizationRolesForUser(userId, roleIds));
  }

  async setOrganizationGroupsOfUser(
    groupIds: string[],
    userId: string
  ): Promise<SetOrgGroupsOfUserResult> {
    const response = await handleApiCallWithFullResponse(
      IdpClient.setOrganizationGroupsOfUser(userId, groupIds, {
        cleanUpLicenseReservations: true,
        returnNoMembershipsOrgIds: true,
      })
    );
    return createOrganizationRemovingResult(response);
  }

  async removeOrganizationGroupsOfUser(
    groupIds: string[],
    userId: string
  ): Promise<RemoveOrgGroupsOfUserResult> {
    const response = await handleApiCallWithFullResponse(
      IdpClient.removeOrganizationGroupsOfUser(userId, groupIds, {
        cleanUpLicenseReservations: true,
        returnNoMembershipsOrgIds: true,
      })
    );
    return createOrganizationRemovingResult(response);
  }

  async removeClientGroupsOfClient(
      groupIds: string[],
      clientId: string
  ): Promise<RemoveClientGroupsOfClientResult> {
   return await handleApiCall(
        IdpClient.removeClientGroupsOfClient(clientId, groupIds)
    );
  }
  async removeOrganizationRolesOfUser(
    roleIds: string[],
    userId: string
  ): Promise<void> {
    await handleApiCall(
      IdpClient.removeOrganizationRolesOfUser(userId, roleIds)
    );
  }

  async addUserToOrganizationGroup(
    groupId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    await handleApiCall(
      IdpClient.addUserToOrganizationGroup(orgId as string, groupId, userId)
    );
  }

  async removeUserFromOrganizationGroup(
    groupId: string,
    userId: string,
    orgId?: string,
    opts?: {
      returnNoMembershipsUserIds: boolean;
      cleanUpLicenseReservations: boolean;
    }
  ): Promise<void> {
    await handleApiCall(
      IdpClient.removeUserFromOrganizationGroup(
        orgId as string,
        groupId,
        userId,
        opts
      )
    );
  }

  importUsers(users: User[]): Promise<User[]> {
    const usersWithMandatoryId = users.map((user) => {
      return { ...user, id: user.id as string };
    });

    return handleApiCall(IdpClient.importUsers(usersWithMandatoryId));
  }

  importOrganizationUsers(
    users: UserForCreate[],
    orgId?: string
  ): Promise<UserForCreate[]> {
    const usersWithMandatoryId = users.map((user) => {
      return { ...user, id: user.id as string };
    });

    return handleApiCall(
      IdpClient.importOrganizationUsers(orgId as string, usersWithMandatoryId, {
        allowUpdateExisting: true,
      })
    );
  }

  /**
   * List client group invitations
   */
  listOrganizationClientGroupInvitations(organizationId: string): Promise<ClientGroupInvitation[]> {
    return handleApiCall(
        IdpClient.listOrganizationClientGroupInvitations(organizationId)
    );
  }
  /**
   * Create and send a client group invitation
   */
  createAndSendOrganizationClientGroupInvitation(organizationId: string, clientGroupInvitation: ClientGroupInvitation): Promise<ClientGroupInvitation> {
    return handleApiCall(
        IdpClient.createAndSendOrganizationClientGroupInvitation(organizationId, clientGroupInvitation)
    );
  };

  listClientsInOrganizationClientGroup(organizationId: string, groupId: string): Promise<Client[]> {
    return handleApiCall(
        IdpClient.listClientsInOrganizationClientGroup(organizationId, groupId)
    );
  }
  addClientsToOrganizationClientGroup(organizationId: string, groupId: string, body: string[]): Promise<void> {
    return handleApiCall(
        IdpClient.addClientsToOrganizationClientGroup(organizationId, groupId, body)
    );

  }
  removeClientsFromOrganizationClientGroup(organizationId: string, groupId: string, body: string[]): Promise<void> {
    return handleApiCall(
        IdpClient.removeClientsFromOrganizationClientGroup(organizationId, groupId, body)
    );
  }
  getClient(clientId: string): Promise<Client> {
    return handleApiCall(
        IdpClient.getClient(clientId)
    );
  }
  deleteOrganizationClient(organizationId: string, clientId: string): Promise<void>  {
    return handleApiCall(
        IdpClient.deleteOrganizationClient(organizationId, clientId)
    );
  }
  replaceOrganizationClient(organizationId: string, client: Client): Promise<Client> {
    return handleApiCall(
        IdpClient.replaceOrganizationClient(organizationId, client)
    );
  }
}

export default RealIdp;
