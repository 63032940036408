import { AppState } from "../store/AppState";
import * as ActionTypes from "../actions/actionTypes";
import appOrganizationIds from "./appOrganizationIdsReducer";
import authentication from "./authenticationReducer";
import errors from "./errorsReducer";
import entitlements from "./entitlementsReducer";
import inProgress from "./inProgressReducer";
import licenseAssignments from "./licenseAssignmentsReducer";
import licenses from "./licensesReducer";
import licenseUsage from "./licenseUsageReducer";
import logoutCompleted from "./logoutCompletedReducer";
import organizations from "./organizationsReducer";
import orgEntitlementIds from "./orgEntitlementIdsReducer";
import orgGroupEntitlementIds from "./orgGroupEntitlementIdsReducer";
import orgGroups from "./orgGroupsReducer";
import orgGroupUserIds from "./orgGroupUserIdsReducer";
import orgInvitations from "./orgInvitationsReducer";
import orgLicenseIds from "./orgLicenseIdsReducer";
import orgOrgGroupIds from "./orgOrgGroupIdsReducer";
import orgOrgInvitationIds from "./orgOrgInvitationIdsReducer";
import orgOrgRoleIds from "./orgOrgRoleIdsReducer";
import orgRoles from "./orgRolesReducer";
import orgRoleUserIds from "./orgRoleUserIdsReducer";
import orgRoleGrantedPermissions from "./orgRoleGrantedPermissionsReducer";
import orgUserIds from "./orgUserIdsReducer";
import pendingAuthentication from "./pendingAuthenticationReducer";
import permissions from "./permissionsReducer";
import selectedOrganizationId from "./selectedOrganizationIdReducer";
import userAvailableLicenses from "./userAvailableLicensesReducer";
import userOrgGroupIds from "./userOrgGroupIdsReducer";
import userOrgRoleIds from "./userOrgRoleIdsReducer";
import users from "./usersReducer";
import orgRoleOrgRoleIds from "./orgRoleOrgRoleIdsReducer";
import { AppAction } from "../actions/actionTypes";
import clients from "./clientsReducer";
import orgClientIdsReducer from "./orgClientIdsReducer";
import clientGroups from "./clientGroupsReducer";
import orgClientGroupIdsReducer from "./orgClientGroupIdsReducer";
import clientClientGroupIds from "./clientClientGroupIdsReducer";
import orgClientGroupEntitlementIds from "./orgClientGroupEntitlementIdsReducer";
import orgClientInvitations from "./orgClientInvitationsReducer";
import orgOrgClientInvitationIds from "./orgOrgClientInvitationIdsReducer";
import orgClientGroupClientIdsReducer from "./orgClientGroupClientIdsReducer";
import clientAvailableLicenses from "./clientAvailableLicensesReducer";
import entitlementUsers from "./entitlementUsersReducer";
import entitlementClients from "./entitlementClientsReducer";
import entitlementClientGroupIds from "./entitlementClientGroupIdsReducer";
import entitlementGroupIds from "./entitlementGroupIdsReducer";

/**
 * Combined reducer for working independently for different branches of the state tree.
 * This is not exposed directly, but only via root reducer that may work on the
 * whole state.
 */

const appReducer: (state: any, action: AppAction) => AppState = (
  state,
  action
) =>
  ({
    appOrganizationIds: appOrganizationIds(state?.appOrganizationIds, action),
    authentication: authentication(state?.authentication, action),
    errors: errors(state?.errors, action),
    entitlements: entitlements(state?.entitlements, action),
    entitlementUserIds: entitlementUsers(state?.entitlementUserIds, action),
    entitlementClientIds: entitlementClients(state?.entitlementClientIds, action),
    inProgress: inProgress(state?.inProgress, action),
    licenseAssignments: licenseAssignments(
      state?.licenseAssignments,
      action,
      state?.licenseUsage
    ),
    licenses: licenses(state?.licenses, action),
    licenseUsage: licenseUsage(state?.licenseUsage, action),
    logoutCompleted: logoutCompleted(state?.logoutCompleted, action),
    organizations: organizations(state?.organizations, action),
    orgEntitlementIds: orgEntitlementIds(state?.orgEntitlementIds, action),
    orgGroupEntitlementIds: orgGroupEntitlementIds(
        state?.orgGroupEntitlementIds,
        action
    ),
    orgEntitlementGroupIds: entitlementGroupIds(
        state?.orgEntitlementGroupIds,
        action
    ),
    orgClientGroupEntitlementIds: orgClientGroupEntitlementIds(
        state?.orgClientGroupEntitlementIds,
        action
    ),
    orgEntitlementClientGroupIds: entitlementClientGroupIds(
        state?.orgEntitlementClientGroupIds,
        action
    ),
    orgClients: clients(state?.orgClients, action),
    orgClientIds: orgClientIdsReducer(state?.orgClientIds, action),
    orgClientGroups: clientGroups(state?.orgClientGroups, action),
    orgClientGroupIds: orgClientGroupIdsReducer(state?.orgClientGroupIds, action),
    orgClientGroupClientIds: orgClientGroupClientIdsReducer(state?.orgClientGroupClientIds, action),
    orgGroups: orgGroups(state?.orgGroups, action),
    orgGroupUserIds: orgGroupUserIds(state?.orgGroupUserIds, action),
    orgInvitations: orgInvitations(state?.orgInvitations, action),
    orgLicenseIds: orgLicenseIds(state?.orgLicenseIds, action),
    orgOrgGroupIds: orgOrgGroupIds(state?.orgOrgGroupIds, action),
    orgOrgInvitationIds: orgOrgInvitationIds(
      state?.orgOrgInvitationIds,
      action
    ),
    orgOrgRoleIds: orgOrgRoleIds(state?.orgOrgRoleIds, action),
    orgRoles: orgRoles(state?.orgRoles, action),
    orgRoleUserIds: orgRoleUserIds(state?.orgRoleUserIds, action),
    orgRoleGrantedPermissions: orgRoleGrantedPermissions(
      state?.orgRoleGrantedPermissions,
      action
    ),
    orgUserIds: orgUserIds(state?.orgUserIds, action),
    pendingAuthentication: pendingAuthentication(
      state?.pendingAuthentication,
      action
    ),
    permissions: permissions(state?.permissions, action),
    selectedOrganizationId: selectedOrganizationId(
      state?.selectedOrganizationId,
      action
    ),
    userAvailableLicenses: userAvailableLicenses(
        state?.userAvailableLicenses,
        action
    ),
    clientAvailableLicenses: clientAvailableLicenses(
        state?.clientAvailableLicenses,
        action
    ),
    orgClientClientGroupIds: clientClientGroupIds(state?.orgClientClientGroupIds, action),
    userOrgGroupIds: userOrgGroupIds(state?.userOrgGroupIds, action),
    userOrgRoleIds: userOrgRoleIds(state?.userOrgRoleIds, action),
    users: users(state?.users, action),
    orgRoleOrgRoleIds: orgRoleOrgRoleIds(state?.orgRoleOrgRoleIds, action),
    clientInvitations: orgClientInvitations(state?.clientInvitations, action),
    orgClientInvitationIds: orgOrgClientInvitationIds(state?.orgClientInvitationIds, action),
  } as AppState);

const rootReducer = (
  state?: AppState,
  action: ActionTypes.AppAction = { type: "NOOP" }
): AppState => {
  if (action.type === ActionTypes.CLEAR_ALL) {
    return appReducer(undefined, action);
  }
  if (action.type === ActionTypes.SET_ORG) {
    const newState = {
      // OrganizationState
      organizations: state?.organizations,
      appOrganizationIds: state?.appOrganizationIds,
      selectedOrganizationId: state?.selectedOrganizationId,

      // InProgress
      inProgress: state?.inProgress,

      // AuthenticationState
      pendingAuthentication: state?.pendingAuthentication,
      authentication: state?.authentication,
      logoutCompleted: state?.logoutCompleted,
    };
    return appReducer(newState as any, action);
  }

  let newState = state;
  let act = action;
  if (act.type === ActionTypes.SET_USERS_IN_ORG_GROUP) {
    // Handle SET_USERS_IN_ORG_GROUP in two parts, first invoking
    // REMOVE_USERS_FROM_ORG_GROUP here and then letting SET_USERS_IN_ORG_GROUP
    // be handled in a similar manner as ADD_USERS_TO_ORG_GROUP
    const setUsersInOrgGroup = act as ActionTypes.SetUsersInOrgGroupAction;
    const currentUsersOfOrgGroup =
      newState &&
      newState.orgGroupUserIds &&
      newState.orgGroupUserIds[setUsersInOrgGroup.orgGroupId]
        ? newState.orgGroupUserIds[setUsersInOrgGroup.orgGroupId]
        : [];
    if (currentUsersOfOrgGroup.length > 0) {
      const removeCurrentUsers: ActionTypes.RemoveUsersFromOrgGroupAction = {
        type: ActionTypes.REMOVE_USERS_FROM_ORG_GROUP,
        orgGroupId: setUsersInOrgGroup.orgGroupId,
        userIds: currentUsersOfOrgGroup,
        orgId: setUsersInOrgGroup.orgId,
        membershipRemovingResult: setUsersInOrgGroup.membershipRemovingResult,
      };
      newState = appReducer(state as any, removeCurrentUsers);
    }
    const addUsersToOrgGroup: ActionTypes.AddUsersToOrgGroupAction = {
      ...setUsersInOrgGroup,
      type: ActionTypes.ADD_USERS_TO_ORG_GROUP,
    };
    act = addUsersToOrgGroup;
  }

  return appReducer(newState as any, act);
};

export default rootReducer;
