import { useEffect, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {FeedbackEntry, Feedback, Modal} from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import {
  ClearErrorAction, DeleteOrgClientGroupInvitationAction,
  GetOrgClientGroupInvitationAction,
  isAddErrorAction,
} from "../../../actions/actionTypes";
import {ClientGroupInvitation} from "../../../model/ClientGroupInvitation";

//<editor-fold desc="Props">

export interface RemoveDeviceClientInvitationModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface RemoveDeviceClientInvitationModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  invitation?: ClientGroupInvitation | null;
  onRemoveInvitation: (id: string) => Promise<DeleteOrgClientGroupInvitationAction>;
  onLoadInvitation: (id: string) => Promise<GetOrgClientGroupInvitationAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface RemoveDeviceClientInvitationModalProps
  extends RemoveDeviceClientInvitationModalDOMProps,
    RemoveDeviceClientInvitationModalDataProps,
    Omit<ClosableModalProps, "isReady"> {
  onShowFeedback: (feedback: FeedbackEntry) => void;
  invitationId?: string;
}
//</editor-fold>

function RemoveDeviceClientInvitationModal(props: RemoveDeviceClientInvitationModalProps) {
  //<editor-fold desc="Local variables">
  let {
    invitationId,
    invitation,
    onLoadInvitation,
    onRemoveInvitation,
    show,
    onClose,
    onShowFeedback,
    isReady,
    onExited,
    onClearError,
  } = props;

  // this is more like a variable than a hook
  const intl = useIntl();

  const invitationObjId = invitation ? invitation.id : undefined;

  useEffect(() => {
    if (
      !!show &&
      !!onLoadInvitation &&
      !!invitationId &&
      (invitationObjId === undefined ||
        (!!invitationObjId && invitationObjId !== invitationId))
    ) {
      onLoadInvitation(invitationId).then((res) => {
        if (!invitationObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, onLoadInvitation, invitationId, invitationObjId, onClearError]);
  //</editor-fold>

  return (
    <Modal
      onReloadData={() => {
        if ((invitation || invitationId) && onLoadInvitation) {
          onLoadInvitation(
            invitation ? (invitation.id as string) : (invitationId as string)
          ).then((res) => {
            if (!invitation && isAddErrorAction(res)) {
              // only clear error if no data exists, leave if previous data is still available and
              // let the api error notification handler show error
              onClearError(res.error?.errorId);
            }
          });
        }
      }}
      isReady={isReady}
      onExited={onExited}
      data-test-remove-invitation-modal={invitation ? invitation.id : true}
      title={intl.formatMessage({
        defaultMessage: "Remove invitation",
        description: "modal heading",
      })}
      show={show}
      onClose={onClose}
      primaryButton={
        !!invitation
          ? {
              label: intl.formatMessage({
                defaultMessage: "Remove",
                description: "primary button label",
              }),
            }
          : {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
      }
      onPrimaryAction={() => {
        if (show && invitation) {
          onRemoveInvitation(invitation.id as string).then((res) => {
            if (!isAddErrorAction(res)) {
              onShowFeedback({
                id: "removeInvitation_" + res.invitationId,
                msg: intl.formatMessage(
                  {
                    defaultMessage: "Invitation for {email} removed.",
                    description:
                      "success notification. 'email' = email of the invitation recipient",
                  },
                  {
                    email:
                      "<strong>" +
                      (invitation ? invitation.email : "impossible") +
                      "</strong>",
                  }
                ),
                autoClose: true,
                type: "success",
              });
            } else {
              onClearError(res.error?.errorId);
              onShowFeedback({
                id:
                  "removeInvitation_" +
                  (invitation ? invitation.id : "impossible"),
                msg: intl.formatMessage(
                  {
                    defaultMessage:
                      "Removing invitation for {email} failed: {msg}.",
                    description:
                      "failure notification. 'email' = invitation recipient email, 'msg' = error message",
                  },
                  {
                    email:
                      "<strong>" +
                      (invitation ? invitation.email : "impossible") +
                      "</strong>",
                    msg: res.error.apiError.error_description,
                  }
                ),
                type: "danger",
              });
            }
          });
        }
        onClose();
      }}
      secondaryButton={
        invitation
          ? {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={invitation ? onClose : undefined}
    >
      <>
        {show && invitation && (
          <p>
            <FormattedMessage
              defaultMessage="Are you sure you wish to remove the invitation for {email} from this organization?"
              description={
                "Confirm  action message. 'email' = email of the recipient"
              }
              values={{
                email: (
                  <strong>
                    {invitation ? invitation.email : "impossible"}
                  </strong>
                ),
              }}
            />
          </p>
        )}
        {isReady && show && !invitation && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p data-test-invitation-not-found>
              <FormattedMessage
                defaultMessage="No matching invitation found."
                description="message to be shown when there is no invitation to display"
              />
            </p>
          </Feedback>
        )}
      </>
    </Modal>
  );
}

export default RemoveDeviceClientInvitationModal;
