const REACT_APP_BASE_ENV_PARAM = "REACT_APP_BASE";
export const REACT_APP_MOCK_DATA_DELAY_MIN = "REACT_APP_MOCK_DATA_DELAY_MIN";
export const REACT_APP_MOCK_DATA_DELAY_MAX = "REACT_APP_MOCK_DATA_DELAY_MAX";
/**
 * Environment parameter for server base url (no trailing slash).
 */
const SRV_BASE_ENV_PARAM = "REACT_APP_SRV_BASE";
const SRV_BASE_DEFAULT = "";
/**
 * Gets base URL of the server (no trailing slash).
 */
export function getSrvBase(): string {
  return getEnvParam(SRV_BASE_ENV_PARAM, SRV_BASE_DEFAULT);
}
export function getAppBase(): string {
  return getEnvParam(REACT_APP_BASE_ENV_PARAM, "");
}
export function getEnvParam(envParam: string, defaultValue?: string): string {
  const apiImplParam = process.env[envParam];
  if (apiImplParam === undefined) {
    if (defaultValue === undefined) {
      throw new Error(`${envParam} env parameter must be specified`);
    }
    return defaultValue;
  }
  return apiImplParam;
}

export function envParamExists(envParam: string): boolean {
  const apiImplParam = process.env[envParam];
  return !(apiImplParam === undefined);
}
