import { useEffect, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {FeedbackEntry, Feedback, Modal} from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import { User } from "../../../model/User";
import {
  ClearErrorAction,
  GetUserAction,
  isAddErrorAction,
  SetUserSuspendedAction,
} from "../../../actions/actionTypes";
import UserUtils, { UserWithStatus } from "../../../utils/user";
import { UserValues } from "../../../localization/user";

//<editor-fold desc="Props">

const resolveUserName = (
  user: User | undefined | null,
  intl: { formatMessage: (v: any, v2?: any) => string }
) =>
  UserUtils.resolveDisplayName(
    user,
    intl.formatMessage(UserValues.displayName.undefined)
  );
export interface UnsuspendUserModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface UnsuspendUserModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  user: UserWithStatus | undefined | null;
  onUnsuspendUser: (id: string) => Promise<SetUserSuspendedAction>;
  onLoadUser: (id: string) => Promise<GetUserAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface UnsuspendUserModalProps
  extends UnsuspendUserModalDOMProps,
    UnsuspendUserModalDataProps,
    Omit<ClosableModalProps, "isReady"> {
  onShowFeedback: (feedback: FeedbackEntry) => void;
  userId?: string;
}
//</editor-fold>

function UnsuspendUserModal(props: UnsuspendUserModalProps) {
  //<editor-fold desc="Local variables">
  let {
    user,
    userId,
    show,
    onClose,
    onUnsuspendUser,
    onLoadUser,
    onShowFeedback,
    isReady,
    onExited,
    onClearError,
  } = props;

  const userObjId = user ? user.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!userId &&
      (userObjId === undefined || (!!userObjId && userObjId !== userId)) &&
      !!onLoadUser
    ) {
      onLoadUser(userId).then((res) => {
        if (!userObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, userId, userObjId, onLoadUser, onClearError]);
  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>

  return (
    <Modal
      onReloadData={() => {
        if ((user || userId) && onLoadUser) {
          onLoadUser(user ? (user.id as string) : (userId as string)).then(
            (res) => {
              if (!user && isAddErrorAction(res)) {
                // only clear error if no data exists, leave if previous data is still available and
                // let the api error notification handler show error
                onClearError(res.error?.errorId);
              }
            }
          );
        }
      }}
      isReady={isReady}
      onExited={onExited}
      data-test-unsuspend-user-modal={user ? (user.id as string) : true}
      title={
        !isReady || user
          ? intl.formatMessage(
              {
                defaultMessage: "Unsuspend {name}",
                description: "modal heading. 'name' = name of the user",
              },
              {
                name: resolveUserName(user, intl),
              }
            )
          : intl.formatMessage({
              defaultMessage: "User not found",
              description: "modal heading when user not found",
            })
      }
      show={show}
      onClose={onClose}
      primaryButton={
        user && user.status === "suspended"
          ? {
              label: intl.formatMessage({
                defaultMessage: "Unsuspend",
                description: "primary button label",
              }),
            }
          : {
              label: intl.formatMessage({
                defaultMessage: "close",
                description: "close button label",
              }),
            }
      }
      onPrimaryAction={() => {
        if (show && user && user.status === "suspended") {
          onUnsuspendUser(user.id as string).then(
            (res) => {
              if (!isAddErrorAction(res)) {
                onShowFeedback({
                  id: "unsuspend_" + (user ? (user.id as string) : ""),
                  msg: intl.formatMessage(
                    {
                      defaultMessage: "{name} has been unsuspended.",
                      description:
                        "Success notification. 'name' = name of the user",
                    },
                    {
                      name:
                        "<strong>" + resolveUserName(user, intl) + "</strong>",
                    }
                  ),
                  autoClose: true,
                  type: "success",
                });
              } else {
                onClearError(res.error?.errorId);
                onShowFeedback({
                  id: "unsuspend_" + (user ? (user.id as string) : ""),
                  msg: intl.formatMessage(
                    {
                      defaultMessage: "Unsuspending {name} failed: {msg}",
                      description:
                        "Failure notification. 'name' = name of the user, 'msg' = error message",
                    },
                    {
                      name:
                        "<strong>" + resolveUserName(user, intl) + "</strong>",
                      msg: res.error.apiError.error_description,
                    }
                  ),
                  type: "danger",
                });
              }
            },
            (rej) => {}
          );
        }
        onClose();
      }}
      secondaryButton={
        user && user.status === "suspended"
          ? {
              label: intl.formatMessage({
                defaultMessage: "Cancel",
                description: "secondary button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={
        user && user.status === "suspended" ? onClose : undefined
      }
    >
      <>
        {show && user && (
          <>
            {user.status === "suspended" && (
              <p>
                <FormattedMessage
                  defaultMessage="Are you sure you wish to unsuspend {name}?"
                  description={"Confirm action copy. 'name' = name of the user"}
                  values={{
                    name: <strong>{resolveUserName(user, intl)}</strong>,
                  }}
                />
              </p>
            )}
            {user.status !== "suspended" && (
              <Feedback type={"danger"} show={true} asChild={true}>
                <p>
                  <FormattedMessage
                    defaultMessage="{name} is not suspended."
                    description="message to be shown when the user is not suspended"
                    values={{
                      name: <strong>{resolveUserName(user, intl)}</strong>,
                    }}
                  />
                </p>
              </Feedback>
            )}
          </>
        )}
        {isReady && show && !user && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p>
              <FormattedMessage
                defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
                description="message to be shown when there is no user to display"
              />
            </p>
          </Feedback>
        )}
      </>
    </Modal>
  );
}

export default UnsuspendUserModal;
