import { useState } from "react";
import APIVersionErrorScreenView, {
  APIVersionErrorScreenProps as _APIVersionErrorScreenProps,
} from "./api-version-error-screen-view";

type APIVersionErrorScreenProps = Omit<
  _APIVersionErrorScreenProps,
  "showError" | "onShowError"
>;
function APIVersionErrorScreen(props: APIVersionErrorScreenProps) {
  const [showError, onShowError] = useState<boolean>(false);
  return (
    <APIVersionErrorScreenView
      {...props}
      showError={showError}
      onShowError={onShowError}
    />
  );
}
export default APIVersionErrorScreen;
