import * as ActionTypes from "../actions/actionTypes";
import { PendingAuthentication } from "../model/PendingAuthentication";

export default function pendingAuthentication(
  state: PendingAuthentication,
  action: ActionTypes.AppAction
): PendingAuthentication | null {
  switch (action.type) {
    case ActionTypes.START_AUTHN:
      const startAuthnAction = action as ActionTypes.StartAuthnAction;
      return {
        url: startAuthnAction.url,
        codeVerifier: startAuthnAction.codeVerifier,
        nonce: startAuthnAction.nonce,
        nonceIssuedAt: startAuthnAction.nonceIssuedAt
      };
    case ActionTypes.SET_AUTHN:
    case ActionTypes.CLEAR_AUTHN_STATUS:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
