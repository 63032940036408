import { connect } from "react-redux";
import ManageUsersGroupsModal, {
  ManageUsersGroupsModalProps as _ManageUsersGroupsModalProps,
} from "./manage-users-groups-modal-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import OrganizationUtils from "../../../utils/organization";
import {
  addOrganizationGroupsForUser,
  clearError,
  getUser,
  listOrganizationGroups,
  listOrganizationGroupsOfUser,
  removeOrganizationGroupsOfUser,
} from "../../../actions";
import { ManageUsersGroupsModalDataProps } from "./manage-users-groups-modal-view";
import InProgressUtils from "../../../utils/in-progress";
import AuthenticationUtils from "../../../utils/authentication";
import UserUtils from "../../../utils/user";
import { addSenderArgument } from "../../../actions/actionHelpers";

export type ManageUsersGroupsModalProps = Omit<
  _ManageUsersGroupsModalProps,
  keyof ManageUsersGroupsModalDataProps
>;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ManageUsersGroupsModalProps,
  ManageUsersGroupsModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ManageUsersGroupsModalProps,
  ManageUsersGroupsModalProps
>;

const sender = { key: "manage-users-groups" };

function mapStateToProps(
  state: AppState,
  ownProps: ManageUsersGroupsModalProps
): ReduxStateProps {
  const groups = OrganizationUtils.selectOrganizationGroups(state);
  return {
    actorId: AuthenticationUtils.selectAuthenticationUserId(state) as string,
    groups,
    employeesGroupId: groups?.find((g) => g.type === "employees")?.id,
    user: ownProps.userId
      ? UserUtils.selectUser(ownProps.userId, state)
      : undefined,
    usersGroups: ownProps.userId
      ? UserUtils.selectUserGroupIds(ownProps.userId, state)
      : undefined,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    AuthenticationUtils.compareAuthenticationUserIdStates(next, prev) &&
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationGroupsStates(next, prev) &&
    UserUtils.compareUsersState(next, prev) &&
    UserUtils.compareUserGroupIdsState(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadGroups: addSenderArgument(sender, listOrganizationGroups),
  onLoadUsersGroups: addSenderArgument(sender, listOrganizationGroupsOfUser),
  onAddGroups: addSenderArgument(sender, addOrganizationGroupsForUser),
  onRemoveGroups: addSenderArgument(sender, removeOrganizationGroupsOfUser),
  onLoadUser: addSenderArgument(sender, getUser),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ManageUsersGroupsModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ManageUsersGroupsModal);
