import { useIntl } from "react-intl";
import { LicenseWithCredits } from "../../../model/entitlement/LicenseWithCredits";
import {
  isAboutToExpire,
  isScheduled,
  isValid,
} from "../../../util/objectUtil";
import { StatusBadge } from "@10duke/dukeui";
import { LicenseValues } from "../../../localization/license";
import "./license-validity-badge-view.scss";

export enum LicenseValidityType {
  valid = "valid",
  invalid = "invalid",
  expiring = "expiring",
  scheduled = "scheduled",
  deactivated = "deactivated",
}

//<editor-fold desc="Props">
export interface LicenseValidityBadgeProps {
  validity: LicenseValidityType | undefined;
  validFrom: string | undefined;
  validUntil: string | undefined;
}
//</editor-fold>

export function resolveLicenseValidity(
  l: LicenseWithCredits
): LicenseValidityType | undefined {
  let retVal: LicenseValidityType | undefined;
  if (l) {
    if (!l.active) {
      retVal = LicenseValidityType.deactivated;
    } else {
      const validity = isValid(l);
      const aboutToExpire = validity ? isAboutToExpire(l) : false;
      if (validity) {
        if (aboutToExpire) {
          retVal = LicenseValidityType.expiring;
        } else {
          retVal = LicenseValidityType.valid;
        }
      } else {
        const scheduled = isScheduled(l);
        if (scheduled) {
          retVal = LicenseValidityType.scheduled;
        } else {
          retVal = LicenseValidityType.invalid;
        }
      }
    }
  }
  return retVal;
}
function LicenseValidityBadge(props: LicenseValidityBadgeProps) {
  //<editor-fold desc="Local variables">
  const intl = useIntl();
  let { validity, validFrom, validUntil } = props;
  const d: any = {};
  if (validity === LicenseValidityType.valid) {
    d.label = intl.formatMessage(LicenseValues.validityStatus.valid);
    if (validFrom && validUntil) {
      d.tip = intl.formatMessage(
        {
          defaultMessage:
            "{value}: The license is valid from {validFrom} until {validUntil}.",
          description:
            "Tooltip content for license validity status 'valid'. 'value' = status label, 'validFrom' = formatted date for  start of validity, 'validUntil' = formatted date for end of validity",
        },
        {
          ...{
            value: <strong key={"value"}>{d.label}</strong>,
            validFrom: intl.formatDate(
              validFrom === "now()" ? new Date() : validFrom
            ),
            validUntil: intl.formatDate(
              validUntil === "now()" ? new Date() : validUntil
            ),
          },
        }
      );
    } else if (validFrom) {
      d.tip = intl.formatMessage(
        {
          defaultMessage: "{value}: The license is valid from {validFrom}.",
          description:
            "Tooltip content for license validity status 'valid'. 'value' = status label, 'validFrom' = formatted date for  start of validity",
        },
        {
          ...{
            value: <strong key={"value"}>{d.label}</strong>,
            validFrom: intl.formatDate(
              validFrom === "now()" ? new Date() : validFrom
            ),
          },
        }
      );
    } else if (validUntil) {
      d.tip = intl.formatMessage(
        {
          defaultMessage: "{value}: The license is valid until {validUntil}.",
          description:
            "Tooltip content for license validity status 'valid'. 'value' = status label, 'validUntil' = formatted date for end of validity",
        },
        {
          ...{
            value: <strong key={"value"}>{d.label}</strong>,
            validUntil: intl.formatDate(
              validUntil === "now()" ? new Date() : validUntil
            ),
          },
        }
      );
    } else {
      d.tip = intl.formatMessage(
        {
          defaultMessage: "{value}: The license is valid.",
          description:
            "Tooltip content for license validity status 'valid'. 'value' = status label",
        },
        {
          ...{
            value: <strong key={"value"}>{d.label}</strong>,
          },
        }
      );
    }
    d.badgeStyle = "license valid";
  } else if (validity === LicenseValidityType.invalid) {
    d.label = intl.formatMessage(LicenseValues.validityStatus.invalid);
    if (
      validUntil &&
      (validUntil === "now()" ||
        new Date().getTime() > new Date(validUntil).getTime())
    ) {
      d.tip = intl.formatMessage(
        {
          defaultMessage: "{value}: The license has expired on {validUntil}.",
          description:
            "Tooltip for license validity status 'invalid' when the license has expired. 'value' = status label, 'validUntil' = formatted date for end of validity",
        },
        {
          value: <strong key={"value"}>{d.label}</strong>,
          validUntil: intl.formatDate(
            validUntil === "now()" ? new Date() : validUntil
          ),
        }
      );
    } else {
      d.tip = intl.formatMessage(
        {
          defaultMessage: "{value}: The license is not valid.",
          description:
            "Tooltip content for license validity status 'invalid' when there are no start or end of validity. 'value' = status label",
        },
        {
          value: <strong key={"value"}>{d.label}</strong>,
        }
      );
    }
    d.badgeStyle = "license invalid";
  } else if (validity === LicenseValidityType.expiring) {
    d.label = intl.formatMessage(LicenseValues.validityStatus.expiring);
    d.tip = intl.formatMessage(
      {
        defaultMessage: "{value}: The license will expire {validUntil}.",
        description:
          "Tooltip for license validity status 'expiring'. 'value' = status label, validUntil' = formatted date for end of validity",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
        validUntil: intl.formatDate(
          validUntil === "now()" ? new Date() : validUntil
        ),
      }
    );
    d.badgeStyle = "license expiring";
  } else if (validity === LicenseValidityType.scheduled) {
    d.label = intl.formatMessage(LicenseValues.validityStatus.scheduled);
    d.tip = intl.formatMessage(
      {
        defaultMessage:
          "{value}: The license is not yet valid. Validity starts from {validFrom}.",
        description:
          "Tooltip content for license validity status 'scheduled' when there is a future valid from date. 'value' = status label, 'validFrom' = formatted date for start of validity,",
      },
      {
        ...{
          value: <strong key={"value"}>{d.label}</strong>,
          validFrom: intl.formatDate(validFrom),
        },
      }
    );
    d.badgeStyle = "license scheduled";
  } else if (validity === LicenseValidityType.deactivated) {
    d.label = intl.formatMessage(LicenseValues.validityStatus.deactivated);
    d.tip = intl.formatMessage(
      {
        defaultMessage: "{value}: The license has been deactivated.",
        description:
          "Tooltip content for license validity status 'deactivated'. 'value' = status label",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
      }
    );
    d.badgeStyle = "license deactivated";
  }
  return <StatusBadge data-test-license-validity={validity} item={d} />;
}
export default LicenseValidityBadge;
