import { ButtonGroup, Form } from "react-bootstrap";
import { IconLibrary, FormInput, Button } from "@10duke/dukeui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InvitationRecipient } from "./create-device-client-invitation-modal-view";
import UserUtils from "../../../utils/user";
import {FormattedMessage, useIntl} from "react-intl";
import { UseFormRegister } from "react-hook-form";
import {ClientGroupInvitationLabels} from "../../../localization/client-group-invitation";
export interface RecipientListItemProps {
  item: InvitationRecipient;
  showEdit: boolean;
  onShowEdit: (f: boolean) => void;
  disableEditButtons: boolean;
  onDismissItem: () => void;
  resolveEditFormValidity: (f: string) => boolean | undefined;
  formValues: any;
  register: UseFormRegister<InvitationRecipient>;
  errors: any;
  formIsValid: boolean;
  onApplyItem: () => void;
  editTip: string;
  dismissTip: string;
}
export default function RecipientListItem(props: RecipientListItemProps) {
  const {
    item,
    showEdit,
    onShowEdit,
    disableEditButtons,
    onDismissItem,
    resolveEditFormValidity,
    formValues,
    register,
    errors,
    formIsValid,
    onApplyItem,
    editTip,
    dismissTip,
  } = props;
  const intl = useIntl();
  return (
    <li className={"recipient-list-item" + (showEdit ? ' edit' : '')}>
      {!showEdit && (
        <>
          <FormattedMessage
              defaultMessage={"{clientName} ({email})"}
              description={
                "Short textual representation of the invitations key values shown as a list item with edit buttons etc. supports variables: 'email', 'recipientName', 'clientName', 'clientId'"
              }
              values={{
                email: item.email,
                recipientName: item.recipientName,
                clientName: item.clientName,
                clientId: item.clientId,
              }}
          />
          <ButtonGroup size={"sm"}>
            <Button
              data-test-edit-button
              type={"button"}
              disabled={disableEditButtons}
              variant={"primary"}
              className={"btn custom-base"}
              action={() => {
                onShowEdit(true);
              }}
              tooltip={editTip}
              icon={<FontAwesomeIcon icon={IconLibrary.icons.faPen} fixedWidth={true} />}
            />
            <Button
              data-test-dismiss-button
              type={"button"}
              disabled={disableEditButtons}
              variant={"danger"}
              className="btn custom-base"
              action={() => {
                onDismissItem();
                onShowEdit(false);
              }}
              tooltip={dismissTip}
              icon={<FontAwesomeIcon
                  icon={IconLibrary.icons.faTrashAlt}
                  fixedWidth={true}
              />}
            />
          </ButtonGroup>
        </>
      )}
      {showEdit && (<>
        <div className={"control-wrapper"}>
          <fieldset>
            <legend>
              <FormattedMessage
                  defaultMessage="Send to"
                  description="Heading for the email recipient inputs"
              />
            </legend>
            <FormInput
                data-test-recipient-name
                label={intl.formatMessage(ClientGroupInvitationLabels.recipientName)}
                field={"recipientName"}
                register={register}
                registerOptions={{
                  required: true,
                }}
                hasValue={!!item?.recipientName}
                resolveValidity={resolveEditFormValidity}
            >{errors &&
                errors.recipientName &&
                errors.recipientName.type &&
                errors.recipientName.type === "required" && (
                    <Form.Control.Feedback type="invalid">
                      <FontAwesomeIcon
                          icon={IconLibrary.icons.faExclamationCircle}
                          className={"icon"}
                      />
                      {intl.formatMessage(
                          {
                            defaultMessage: "{recipientName} is required.",
                            description:
                                "Field validation error. 'recipientName' = Field label for the name of the recipient (ClientGroupInvitation.recipientName)",
                          },
                          {
                            recipientName: intl.formatMessage(
                                ClientGroupInvitationLabels.recipientName
                            ),
                          }
                      )}
                    </Form.Control.Feedback>
                )}

            </FormInput>
            <FormInput
                data-test-email
                label={intl.formatMessage(ClientGroupInvitationLabels.email)}
                field={"email"}
                register={register}
                registerOptions={{
                  required: true,
                  pattern: {
                    value: UserUtils.EMAIL_VALIDATION_PATTERN,
                    message: intl.formatMessage(
                        {
                          defaultMessage: "{email} is not valid.",
                          description:
                              "Field validation error. 'email' = Field label for invitation recipient email (ClientGroupInvitation.email)",
                        },
                        {
                          email: intl.formatMessage(
                              ClientGroupInvitationLabels.email
                          ),
                        }
                    ),
                  }}
                }
                hasValue={!!item.email}
                resolveValidity={resolveEditFormValidity}
            >
              {errors &&
                  errors.email &&
                  errors.email.type &&
                  errors.email.type === "required" && (
                      <Form.Control.Feedback type="invalid">
                        <FontAwesomeIcon
                            icon={IconLibrary.icons.faExclamationCircle}
                            className={"icon"}
                        />
                        {intl.formatMessage(
                            {
                              defaultMessage: "{email} is required.",
                              description:
                                  "Field validation error. 'email' = Field label for invitation recipient email (ClientGroupInvitation.email)",
                            },
                            {
                              email: intl.formatMessage(
                                  ClientGroupInvitationLabels.email
                              ),
                            }
                        )}
                      </Form.Control.Feedback>
                  )}
              {errors &&
                  errors.email &&
                  errors.email.type &&
                  errors.email.type === "pattern" && (
                      <Form.Control.Feedback type="invalid">
                        <FontAwesomeIcon
                            icon={IconLibrary.icons.faExclamationCircle}
                            className={"icon"}
                        />
                        {errors.email.message}
                      </Form.Control.Feedback>
                  )}
              {errors &&
                  errors.email &&
                  errors.email.type &&
                  errors.email.type === "validate" && (
                      <Form.Control.Feedback type="invalid">
                        <FontAwesomeIcon
                            icon={IconLibrary.icons.faExclamationCircle}
                            className={"icon"}
                        />
                        {intl.formatMessage(
                            {
                              defaultMessage: "{email} must be unique.",
                              description:
                                  "Field validation error. 'email' = Field label for invitation recipient email (ClientGroupInvitation.email)",
                            },
                            {
                              email: intl.formatMessage(
                                  ClientGroupInvitationLabels.email
                              ),
                            }
                        )}
                      </Form.Control.Feedback>
                  )}
            </FormInput>
          </fieldset>
          <fieldset>
            <legend>
              <FormattedMessage
                  defaultMessage="Device client details"
                  description="Heading for the device client inputs"
              />
            </legend>
            <FormInput
                data-test-client-name
                label={intl.formatMessage(
                    ClientGroupInvitationLabels.clientName
                )}
                field={"clientName"}
                register={register}
                registerOptions={{
                  required: true,
                }}
                hasValue={!!formValues?.clientName}
                resolveValidity={resolveEditFormValidity}
            >{errors &&
                errors.clientName &&
                errors.clientName.type &&
                errors.clientName.type === "required" && (
                    <Form.Control.Feedback type="invalid">
                      <FontAwesomeIcon
                          icon={IconLibrary.icons.faExclamationCircle}
                          className={"icon"}
                      />
                      {intl.formatMessage(
                          {
                            defaultMessage: "{clientName} is required.",
                            description:
                                "Field validation error. 'clientName' = Field label for the name of the device client (DeviceClientGroupInvitation.clientName)",
                          },
                          {
                            recipientName: intl.formatMessage(
                                ClientGroupInvitationLabels.clientName
                            ),
                          }
                      )}
                    </Form.Control.Feedback>
                )}</FormInput>
            <FormInput
                data-test-client-id
                label={intl.formatMessage(ClientGroupInvitationLabels.allowClientId)}
                field={"clientId"}
                register={register}
                registerOptions={{
                  required: false,
                }}
                hasValue={!!item?.clientId}
            >
              <Form.Text className={"info-feedback"}>
                <FontAwesomeIcon icon={IconLibrary.icons.faInfoCircle} className={"icon"} />
                <FormattedMessage
                    defaultMessage='Leave empty to allow any device client.'
                    description={
                      "field info for client invitation clientId. Limited width available in ui, try to keep as short as the default."
                    }
                />
              </Form.Text>
            </FormInput>
          </fieldset>
        </div>
        <div className={"button-wrapper"}>
        <Button
            data-test-resolve-button
            type={"button"}
            variant={"primary"}
            className="btn custom-base"
            disabled={!formIsValid}
            action={onApplyItem}
            icon={IconLibrary.customIcons.applyEdit}
            tooltip={
              formIsValid
                  ? intl.formatMessage({
                    defaultMessage: "Apply",
                    description:
                        "tooltip for recipient list item apply button",
                  })
                  : intl.formatMessage({
                    defaultMessage: "Fix errors to apply",
                    description:
                        "tooltip for disabled recipient list item apply button",
                  })
            }
        />
        <Button
            data-test-cancel-button
            type={"button"}
            variant={"danger"}
            className="btn custom-base"
            action={() => {
              onShowEdit(false);
            }}
            tooltip={intl.formatMessage({
              defaultMessage: "Cancel",
              description: "tooltip for recipient list item cancel button",
            })}
            icon={IconLibrary.customIcons.cancelEdit}
        />
    </div>
      </>)}
    </li>
  );
}
