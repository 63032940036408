import * as ActionTypes from "../actions/actionTypes";
import {ConsumerAvailableLicensesByConsumerId} from "../store/LicenseState";
import {
  removeFromStateById,
} from "../util/ReducerUtils";
// the entitlement side mixes users and clients from time to time, as the state shape is identical we reuse user type
export default function clientAvailableLicenses(
  state: ConsumerAvailableLicensesByConsumerId,
  action: ActionTypes.AppAction
): ConsumerAvailableLicensesByConsumerId | null {
  const currentState = state || ({} as ConsumerAvailableLicensesByConsumerId);
  switch (action.type) {
    case ActionTypes.QUERY_CLIENT_AVAILABLE_LICENSES:
      const queryAvailableLicenses =
        action as ActionTypes.QueryClientAvailableLicensesAction;
      const clientId = queryAvailableLicenses.clientId;
      const licenses = queryAvailableLicenses.licenses;
      const newAvailableLicenses = licenses.map((lic) => ({
        licenseId: lic.id as string,
        assignmentIds: lic.assignments
          ? lic.assignments.map((ass) => ass.id as string)
          : [],
      }));
      return { ...currentState, [clientId]: newAvailableLicenses };

    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

function deleteLicense(
  licenseId: string,
  currentState: ConsumerAvailableLicensesByConsumerId
): ConsumerAvailableLicensesByConsumerId {
  let result = currentState;

  for (const userId in currentState) {
    const userAvailableLicensesAfterDelete = result[userId].filter(
      (userAvailableLicense) => userAvailableLicense.licenseId !== licenseId
    );
    result = { ...result, [userId]: userAvailableLicensesAfterDelete };
  }

  return result;
}

function handleErrorAction(
  currentState: ConsumerAvailableLicensesByConsumerId,
  action: ActionTypes.AppAction
): ConsumerAvailableLicensesByConsumerId {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  const type = errorAction.error.action.type;

  if (
    type === ActionTypes.GET_USER &&
    errorAction.error.apiError.error === "404"
  ) {
    const typedError =
      action as ActionTypes.AddErrorAction<ActionTypes.GetUserAction>;
    finalState = removeFromStateById<ConsumerAvailableLicensesByConsumerId>(
      [typedError.error.action?.userId as string],
      currentState
    );
  } else if (
    type === ActionTypes.QUERY_LICENSE_USAGE ||
    type === ActionTypes.GET_USER_LICENSE_ASSIGNMENTS
  ) {
    if (errorAction.error.apiError.error === "404") {
      const typedError =
        action as ActionTypes.AddErrorAction<ActionTypes.QueryLicenseUsageAction>;
      finalState = deleteLicense(
        typedError.error.action?.licenseId as string,
        currentState
      );
    }
  }

  return finalState;
}
