import * as ActionTypes from "../actions/actionTypes";
import { EntitlementsById } from "../store/EntitlementState";

export default function entitlements(
  state: EntitlementsById,
  action: ActionTypes.AppAction
): EntitlementsById | null {
  const currentState = state || ({} as EntitlementsById);
  switch (action.type) {
    case ActionTypes.LIST_ORG_ENTITLEMENTS:
      const listEntitlements = action as ActionTypes.ListEntitlementsAction;
      const newEntitlementsById = listEntitlements.entitlements.reduce<
        EntitlementsById
      >((map, entitlement) => {
        map[entitlement.id as string] = entitlement;
        return map;
      }, {});
      return { ...currentState, ...newEntitlementsById };
    case ActionTypes.LIST_ENTITLEMENTS_CONSUMED_BY_ORG_GROUP: {
      const actionResult = action as ActionTypes.ListEntitlementsConsumedByOrgGroup;
      const newEntitlementsById = actionResult.entitlements.reduce<
          EntitlementsById
      >((map, entitlement) => {
        map[entitlement.id as string] = entitlement;
        return map;
      }, {});
      return { ...currentState, ...newEntitlementsById };
    }
    case ActionTypes.LIST_ENTITLEMENTS_CONSUMED_BY_ORG_CLIENT_GROUP: {
      const actionResult = action as ActionTypes.ListEntitlementsConsumedByOrgClientGroup;
      const newEntitlementsById = actionResult.entitlements.reduce<
          EntitlementsById
      >((map, entitlement) => {
        map[entitlement.id as string] = entitlement;
        return map;
      }, {});
      return { ...currentState, ...newEntitlementsById };
    }
    case ActionTypes.GET_ORG_ENTITLEMENT:
      const actionResult = action as ActionTypes.GetOrgEntitlementAction;
      return {
        ...currentState,
        [actionResult.entitlement.id as string]: actionResult.entitlement
      };
    case ActionTypes.START_AUTHN:
      return null;
    default:
      return state || null;
  }
}
