import { connect } from "react-redux";
import OrganizationUtils from "../../../utils/organization";
import {
  AssignAdminModalDataProps,
  AssignAdminModalProps as _AssignAdminModalProps,
} from "./assign-admin-modal-view";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import {
  addUserToOrgRole,
  clearError,
  getUser,
  listOrganizationRoles,
} from "../../../actions";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";
import AssignAdminModal from "./assign-admin-modal-container";
import InProgressUtils from "../../../utils/in-progress";
import UserUtils, { UserWithStatus } from "../../../utils/user";

export interface AssignAdminModalProps
  extends Omit<_AssignAdminModalProps, keyof AssignAdminModalDataProps> {}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _AssignAdminModalProps,
  AssignAdminModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _AssignAdminModalProps,
  AssignAdminModalProps
>;

const sender: ActionSender = { key: "assign-admin" };

function mapStateToProps(
  state: AppState,
  ownProps: AssignAdminModalProps
): ReduxStateProps {
  const user = ownProps.userId
    ? OrganizationUtils.selectOrganizationUser(ownProps.userId, state)
    : undefined;
  const orgAdmins = OrganizationUtils.selectOrganizationAdminIds(state, false);
  let userWithExtra: UserWithStatus | undefined = undefined;
  if (user && orgAdmins) {
    userWithExtra = UserUtils.userWithStatus({
      ...user,
      isOrgAdmin: orgAdmins.includes(user.id as string),
    } as any);
  }
  return {
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    orgAdminRoleId: OrganizationUtils.selectOrgAdminRoleId(state),
    user: userWithExtra,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationUsersStates(next, prev) &&
    OrganizationUtils.compareOrganizationRolesStates(next, prev) &&
    OrganizationUtils.compareOrganizationRoleUserIdsStates(next, prev)
  );
}
const dispatchActions: ReduxDispatchProps = {
  onLoadOrgadminRole: addSenderArgument(sender, listOrganizationRoles),
  onAssignAdmin: addSenderArgument(sender, addUserToOrgRole),
  onLoadUser: addSenderArgument(sender, getUser),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  AssignAdminModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(AssignAdminModal);
