import { AppState } from "../store/AppState";
import { Entitlement } from "../model/entitlement/Entitlement";
import { OrganizationGroup } from "../model/OrganizationGroup";
import {ClientGroup} from "../model/ClientGroup";
import {User} from "../model/User";
import {DeviceClient} from "../gen/api/entitlement/entitlement-client";

function selectEntitlement(
  id: string,
  state: AppState
): Entitlement | undefined {
  return !!id && !!state.entitlements ? state.entitlements[id] : undefined;
}
function selectEntitlementGroups(
    id: string,
    state: AppState
): OrganizationGroup[] | undefined {
  return !!id && !!state.orgEntitlementGroupIds && !!state.orgEntitlementGroupIds[id] && !!state.orgGroups
      ? (state.orgEntitlementGroupIds[id].map(groupId =>
              state.orgGroups ? state.orgGroups[groupId] : undefined
          ) as OrganizationGroup[]).filter((f) => !!f)
      : undefined;
}
function selectEntitlementClientGroups(
    id: string,
    state: AppState
): ClientGroup[] | undefined {
  return !!id && !!state.orgEntitlementClientGroupIds && !!state.orgEntitlementClientGroupIds[id] && !!state.orgClientGroups
      ? (state.orgEntitlementClientGroupIds[id].map(groupId =>
          state.orgClientGroups ? state.orgClientGroups[groupId] : undefined
      ) as ClientGroup[]).filter((f) => !!f)
      : undefined;
}
function selectOrgEntitlementUsers(
    orgId: string,
    entId: string,
    state: AppState
): User[] | undefined {
  return !!orgId &&
  !!entId &&
  !!state.users &&
  !!state.orgUserIds &&
  !!state.orgUserIds[orgId] &&
  !!state.orgEntitlementIds &&
  !!state.orgEntitlementIds[orgId] &&
  !!state.orgEntitlementIds[orgId].find((e) => e === entId) &&
  !!state.entitlementUserIds &&
  !!state.entitlementUserIds[entId]
      ? state.entitlementUserIds[entId]
          .filter((f) => !!state?.orgUserIds?.[orgId].find((ou) => ou === f))
          .map((uid) => state?.users?.[uid]) as User[]
      : undefined;
}
function selectOrgEntitlementClients(
    orgId: string,
    entId: string,
    state: AppState
): DeviceClient[] | undefined {
  return !!orgId &&
  !!entId &&
  !!state.orgClients &&
  !!state.orgClientIds &&
  !!state.orgClientIds[orgId] &&
  !!state.orgEntitlementIds &&
  !!state.orgEntitlementIds[orgId] &&
  !!state.orgEntitlementIds[orgId].find((e) => e === entId) &&
  !!state.entitlementClientIds &&
  !!state.entitlementClientIds[entId]
      ? state.entitlementClientIds[entId]
          .filter((f) => !!state?.orgClientIds?.[orgId].find((ou) => ou === f))
          .map((uid) => state?.orgClients?.[uid]) as DeviceClient[]
      : undefined;
}
function selectGroupEntitlements(
  id: string,
  state: AppState
): Entitlement[] | undefined {
  return !!id &&
    !!state.orgGroupEntitlementIds &&
    !!state.orgGroupEntitlementIds[id] &&
    !!state.entitlements
    ? (state.orgGroupEntitlementIds[id].map(entId =>
        state.entitlements ? state.entitlements[entId] : undefined
      ) as Entitlement[])
    : undefined;
}
function selectClientGroupEntitlements(
    id: string,
    state: AppState
): Entitlement[] | undefined {
  return !!id &&
  !!state.orgClientGroupEntitlementIds &&
  !!state.orgClientGroupEntitlementIds[id] &&
  !!state.entitlements
      ? (state.orgClientGroupEntitlementIds[id].map(entId =>
          state.entitlements ? state.entitlements[entId] : undefined
      ) as Entitlement[])
      : undefined;
}
function compareEntitlementsStates(next: AppState, prev: AppState): boolean {
  return next.entitlements === prev.entitlements;
}
function compareEntitlementGroupsStates(
    next: AppState,
    prev: AppState
): boolean {
  return (
      next.orgEntitlementGroupIds === prev.orgEntitlementGroupIds &&
      next.orgGroups === prev.orgGroups
  );
}
function compareEntitlementClientGroupsStates(
    next: AppState,
    prev: AppState
): boolean {
  return (
      next.orgEntitlementClientGroupIds === prev.orgEntitlementClientGroupIds &&
      next.orgClientGroups === prev.orgClientGroups
  );
}
function compareGroupEntitlementsStates(
    next: AppState,
    prev: AppState
): boolean {
  return (
      next.orgGroupEntitlementIds === prev.orgGroupEntitlementIds &&
      next.orgGroups === prev.orgGroups
  );
}
function compareClientGroupEntitlementsStates(
    next: AppState,
    prev: AppState
): boolean {
  return (
      next.orgClientGroupEntitlementIds === prev.orgClientGroupEntitlementIds &&
      next.orgClientGroups === prev.orgClientGroups
  );
}
function compareEntitlementUsersStates(
    next: AppState,
    prev: AppState
): boolean {
  return (
      next.entitlementUserIds === prev.entitlementUserIds
  );
}
function compareEntitlementClientsStates(
    next: AppState,
    prev: AppState
): boolean {
  return (
      next.entitlementClientIds === prev.entitlementClientIds
  );
}
const EntitlementUtils = {
  selectEntitlement,
  selectEntitlementGroups,
  compareEntitlementsStates,
  compareEntitlementGroupsStates,
  selectClientGroupEntitlements,
  selectGroupEntitlements,
  compareEntitlementClientGroupsStates,
  selectEntitlementClientGroups,
  selectOrgEntitlementUsers,
  selectOrgEntitlementClients,
  compareEntitlementUsersStates,
  compareEntitlementClientsStates,
  compareClientGroupEntitlementsStates,
  compareGroupEntitlementsStates,
};
export default EntitlementUtils;
