import { connect } from "react-redux";
import { AppState } from "../../../store/AppState";
import LicenseUsageModal, {
  LicenseUsageModalProps as _LicenseUsageModalProps,
} from "./license-usage-modal-container";
import {
  manageClientsLicenseAssignmentsAndQueryAvailableLicenses,
  manageUsersLicenseAssignmentsAndQueryAvailableLicenses,
  queryLicenseUsage,
} from "../../../actions/entActions";
import {
  convertAssignmentsToSingleAssignmentForSettingAssignmentsCall,
  getLicenseAssignmentsForManageReservationsAndDenials,
} from "../../../util/licenseUtil";
import OrganizationUtils from "../../../utils/organization";
import {
  listOrganizationRolesWithUsers,
  queryOrganizationLicenseEntitlementUsersAndLicenseUsage,
} from "../../../actions";
import { ActionSender } from "../../../model/ActionSender";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import UserUtils from "../../../utils/user";
import { UserWithLicenseUsage } from "../../../model/User";
import { LicenseUser } from "../../../model/entitlement/LicenseUser";
import {isClient, LicenseUsageModalDataProps} from "./license-usage-modal-view";
import InProgressUtils from "../../../utils/in-progress";
import LicenseUtils from "../../../utils/license";
import { LicenseAssignment } from "../../../model/entitlement/LicenseAssignment";
import { addSenderArgument } from "../../../actions/actionHelpers";
import {releaseLicenseLeaseAction} from "../../../actions/releaseLicenseLeaseAction";
import {ClientWithLicenseUsage} from "../../../model/Client";
import EntitlementUtils from "../../../utils/entitlement";

// Own props, i.e. props that this component takes as input
export type LicenseUsageModalProps = Omit<
  _LicenseUsageModalProps,
  keyof LicenseUsageModalDataProps
>;
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _LicenseUsageModalProps,
  LicenseUsageModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _LicenseUsageModalProps,
  LicenseUsageModalProps
>;

const sender: ActionSender = { key: "license-usage-modal" };

function mapStateToProps(
  state: AppState,
  ownProps: LicenseUsageModalProps
): ReduxStateProps {
  const { licenseId } = ownProps;
  const orgAdmins = OrganizationUtils.selectOrganizationAdminIds(state, false);
  let license = licenseId
    ? LicenseUtils.selectLicenseWithUsageAndAssignments(licenseId, state)
    : undefined;
  // this requires the license, so no point in trying without it
  let users: (UserWithLicenseUsage|ClientWithLicenseUsage)[]|undefined = license && ownProps.enableUsers
    ? LicenseUtils.selectOrganizationLicenseEntitlementUsersWithLicenseReservation(license, state)
    : (ownProps.enableUsers ? undefined : []);
  let clients = license && ownProps.enableClients
      ? LicenseUtils.selectOrganizationLicenseEntitlementClientsWithLicenseReservation(license, state)
      : (ownProps.enableClients ? undefined : []);

  if (!!users && !!orgAdmins) {
    users = users.map((usr) => {
      const u = !!usr ? {...usr} : usr;
      if (u && license) {
        u.isConsuming = UserUtils.isConsuming(u, license);
      }
      (u as any).isOrgAdmin = orgAdmins
          ? orgAdmins.indexOf(u.id as string) !== -1
          : false;
      return u;
    });
  }
  if (!!clients && ownProps.enableClients) {
    users = (users || []).concat(...(clients.map((c) => {
      const cc = !!c ? {...c} : c;
      if (cc && license) {
        cc.isConsuming = UserUtils.isConsuming(cc, license);
      }
      (cc as any).isOrgAdmin = false;
      return cc;
    }) || []));
  }

  return {
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    users: !!users && !!clients ? users : undefined,
    license,
    adminsLoaded: !!orgAdmins,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationRolesStates(next, prev) &&
    OrganizationUtils.compareOrganizationRoleUserIdsStates(next, prev) &&
    OrganizationUtils.compareOrganizationUsersStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientsStates(next, prev) &&
    LicenseUtils.compareLicensesStates(next, prev) &&
    LicenseUtils.compareLicenseUsageAndAssignmentsStates(next, prev) &&
    EntitlementUtils.compareEntitlementUsersStates(next, prev) &&
    EntitlementUtils.compareEntitlementClientsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadLicense: (licenseId: string) =>
    queryLicenseUsage(sender, "any", licenseId),
  onLoadUsers: addSenderArgument(sender, queryOrganizationLicenseEntitlementUsersAndLicenseUsage),
  onApplyUsers: onApplyUsers,
  onApplyClients: onApplyClients,
  onLoadAdmins: addSenderArgument(sender, listOrganizationRolesWithUsers),
  onReleaseLicenseLease: addSenderArgument(sender, releaseLicenseLeaseAction),
};

function onApplyUsers(
  licenseId: string,
  usrsorclients: (UserWithLicenseUsage|ClientWithLicenseUsage)[] | undefined
): any {
  let toSet: { [userId: string]: LicenseAssignment[] } | undefined = undefined;
  const usrs = usrsorclients?.map(
      (u) =>
          !isClient(u) ? u as UserWithLicenseUsage : undefined)
      .filter((f) => !!f) as UserWithLicenseUsage[]
  if (usrs) {
    const changed = getLicenseAssignmentsForManageReservationsAndDenials(
      usrs
        .filter((v) => v.hasReservation)
        .map((v) => {
          const t = { ...v };
          delete t.hasReservation;
          delete t.hasDenial;
          return t as LicenseUser;
        }),
      usrs
        .filter((v) => v.hasDenial)
        .map((v) => {
          const t = { ...v };
          delete t.hasReservation;
          delete t.hasDenial;
          return t as LicenseUser;
        }),
      usrs
        .filter((v) => !v.hasReservation && !v.hasDenial)
        .map((v) => {
          const t = { ...v };
          delete t.hasReservation;
          delete t.hasDenial;
          return t as LicenseUser;
        })
    );
    toSet = changed;
    const keys = Object.keys(changed);
    for (let i = 0; i < keys.length; i += 1) {
      toSet[keys[i]] =
        convertAssignmentsToSingleAssignmentForSettingAssignmentsCall(
          changed[keys[i]]
        );
    }
  }
  if (!toSet) {
    throw Error("Nothing to apply");
  }
  return manageUsersLicenseAssignmentsAndQueryAvailableLicenses(
        sender,
        [licenseId],
        [toSet]
  );
}
function onApplyClients(
    licenseId: string,
    usrsorclients: (UserWithLicenseUsage|ClientWithLicenseUsage)[] | undefined
): any {
  let clientToSet: { [clientId: string]: LicenseAssignment[] } | undefined = undefined;
  const clnts = usrsorclients?.map(
      (u) =>
          isClient(u) ? u as ClientWithLicenseUsage : undefined)
      .filter((f) => !!f) as ClientWithLicenseUsage[]
  if (clnts) {
    const changed = getLicenseAssignmentsForManageReservationsAndDenials(
        clnts
            .filter((v) => v.hasReservation)
            .map((v) => {
              const t = { ...v };
              delete t.hasReservation;
              delete t.hasDenial;
              return t as LicenseUser;
            }),
        clnts
            .filter((v) => v.hasDenial)
            .map((v) => {
              const t = { ...v };
              delete t.hasReservation;
              delete t.hasDenial;
              return t as LicenseUser;
            }),
        clnts
            .filter((v) => !v.hasReservation && !v.hasDenial)
            .map((v) => {
              const t = { ...v };
              delete t.hasReservation;
              delete t.hasDenial;
              return t as LicenseUser;
            })
    );
    clientToSet = changed;
    const keys = Object.keys(changed);
    for (let i = 0; i < keys.length; i += 1) {
      clientToSet[keys[i]] =
          convertAssignmentsToSingleAssignmentForSettingAssignmentsCall(
              changed[keys[i]]
          );
    }
  }
  if (!clientToSet) {
    throw Error("Nothing to apply");
  }
 return manageClientsLicenseAssignmentsAndQueryAvailableLicenses(
        sender,
        [licenseId],
        [clientToSet]
  );
}

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  LicenseUsageModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(LicenseUsageModal);
