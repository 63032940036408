import { createRoot, Root } from "react-dom/client";
import "./scss/styles.scss";
import App from "./components/app/";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store, {
  getAuthentication,
  getLogoutCompleted,
  hasErrors,
} from "./store";
import { loadOrgs } from "./actions";
import AppGatekeeperAuthenticator from "./util/AppGatekeeperAuthenticator";
import { entApi, idpApi, initializeApis } from "./api";
import LanguageContext from "./language-context";
import {
  ApiImplementation,
  getAuthzUrl,
  getClientId,
  getJwksUrl,
  getLogoutPath,
  getSelectedIdpApiImpl,
  getSloUrl,
  getTokenUrl,
} from "./api/ApiImplementation";
import _debug from "debug";
import LogoutScreen from "./components/screens/logout-screen";
import ErrorScreen from "./components/screens/error-screen";
import { getEnvParam } from "./util/env";
import {
  InitialUIConfiguration,
  UIConfigurationProvider,
} from "./ui-configuration/configuration-provider";
import Localize from "./components/localize/localize-container";
import idpInfo from "./gen/api/idp/info.json";
import entInfo from "./gen/api/entitlement/info.json";
import APIVersionErrorScreen from "./components/screens/api-version-error-screen";
import {LocaleManager} from "./utils/locale";
import {applyColorMode, XWindowEvents, isValidVersion} from "@10duke/dukeui";

const debug = _debug("App:bootstrap");

const currentUrl = new URL(window.location.href);

const idpAuthzUrl = getAuthzUrl();
debug("authz url: %s", idpAuthzUrl.toString());
const idpTokenUrl = getTokenUrl();
debug("token url: %s", idpTokenUrl.toString());
const idpSloUrl = getSloUrl();
debug("single logout url: %s", idpSloUrl.toString());
const idpJwksUrl = getJwksUrl();
debug("JWKS url: %s", idpJwksUrl.toString());
const clientId = getClientId();
debug("client_id: %s", clientId);
const reactAppBase = getEnvParam("REACT_APP_BASE", "/");
debug("appBase: %s", reactAppBase);
const authnResponseUrl = new URL(
  `${currentUrl.protocol}//${currentUrl.host}${reactAppBase}`
);
debug("callback_uri: %s", authnResponseUrl.toString());
const logoutPath = getLogoutPath();
debug("local logout route: %s", logoutPath);
const apiImpl = getSelectedIdpApiImpl();
debug("API implementation: %s", ApiImplementation[apiImpl]);
const idTokenIatLeeway: number = InitialUIConfiguration.security
  ?.idTokenIatLeeway as number;
debug("ID token iat leeway (seconds): %d", idTokenIatLeeway);

const authenticator = new AppGatekeeperAuthenticator(
  idpAuthzUrl,
  idpTokenUrl,
  idpSloUrl,
  clientId,
  idpJwksUrl,
  authnResponseUrl,
  logoutPath,
  apiImpl === ApiImplementation.internalMock,
  idTokenIatLeeway
);
/**
 * Subscribe to store changes and detect auth changes & errors
 */
const unsubcribe = store.subscribe(() => {
  let root: Root;
  let auth = getAuthentication();
  let logoutCompleted = getLogoutCompleted();
  const errors = hasErrors();
  let externalLogout: boolean | undefined;
  // One of these should be activated by the authenticator
  if (auth || logoutCompleted || errors) {
    // unsubscribe this handler to avoid this handler being hit again after the first time
    unsubcribe();
    XWindowEvents.subscribeLogout((event) => {
      if (auth) {
        externalLogout = true;
        renderApp();
      }
    });
    /**
     * Initializes the APIs, if possible. Api init requires an auth state, reject if no auth.
     */
    const apiInit = () => {
      return new Promise<void>((resolve, reject) => {
        if (auth) {
          initializeApis();
          resolve();
        } else {
          reject();
        }
      });
    };
    /**
     * Loads the API info from the server
     */
    const loadApiInfo = () => {
      return Promise.all([idpApi.getApiInfo(), entApi.getApiInfo()]);
    };
    /**
     * Validates api versions against api versions used to generate API clients.
     * @param res
     */
    const validateApis = (res: { version: string; [key: string]: any }[]) => {
      const genIdpV = idpInfo.version;
      const genEntV = entInfo.version;
      return Promise.all([
        isValidVersion(res[0].version, genIdpV),
        isValidVersion(res[1].version, genEntV),
      ]).then((isValid) => {
        if (
          !isValid[1] &&
          genEntV === "2.0.0" &&
          res[1].version.startsWith("1.")
        ) {
          // 1.X and 2.0.0 are compatible, so override result to allow usage.
          isValid[1] = true;
        }
        if (!isValid[0] || !isValid[1]) {
          throw res.map((f, ind) => (isValid[ind] ? null : f));
        }
      });
    };
    const preRender = () => {
      store.dispatch(loadOrgs({ key: "index" }));
      const container = document.getElementById("root");
      root = createRoot(container!);
    };
    /**
     * Renders the primary app once all the initialization steps have been successfully passed.
     */
    const renderApp = () => {
      // Not sure if the error screens are relevant here, as they also exist separately in init error
      // handling. TODO: low priority, figure out how to check if these are needed and remove if not

      root.render(
        <UIConfigurationProvider>
          <Localize>
            {auth && (
              <Provider store={store}>
                <LanguageContext.Consumer>
                  {(context) => (
                    <App
                      localeInPath={context.path}
                      externalLogout={externalLogout}
                    />
                  )}
                </LanguageContext.Consumer>
              </Provider>
            )}
            {!auth && logoutCompleted && <LogoutScreen />}
            {!auth && !logoutCompleted && errors && (
              <ErrorScreen errors={store.getState().errors} />
            )}
          </Localize>
        </UIConfigurationProvider>
      );
    };
    /**
     * Render init phase errors and logout screen. Logout screen must be separated from the primary app due to auth being
     * required to initialize and validate apis.
     * @param apis Array of invalid api info objects
     */
    const handleInitErrors = (apis?: any[]) => {
      const container = document.getElementById("root");
      const root = createRoot(container!);
      root.render(
        <Localize>
          {!auth && logoutCompleted && <LogoutScreen />}
          {!auth && !logoutCompleted && errors && (
            <ErrorScreen errors={store.getState().errors} />
          )}
          {!logoutCompleted && !errors && <APIVersionErrorScreen apis={apis} />}
        </Localize>
      );
    };
    apiInit()
      .then(loadApiInfo)
      .then(validateApis)
      .then(preRender)
      .then(renderApp)
      .catch(handleInitErrors);
  }
});


// try immediately to avoid flashing
applyColorMode();
// set once loaded to ensure
document.addEventListener("DOMContentLoaded", () => applyColorMode());

// triggers the authentication process
authenticator.handleAuthentication().then((r) => {
  if (!!r) {
    // The authentication is completed with a non event firing window.location rewrite,
    // so we need to trigger locale resolver.
    LocaleManager.updateState();
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
