import { withCloseAfterExited } from "@10duke/dukeui";
import {
  default as View,
  ViewDeviceClientInvitationModalProps
} from "./view-device-client-invitation-modal-view";

const ViewDeviceClientInvitationModal = withCloseAfterExited<ViewDeviceClientInvitationModalProps>(
  View
);
export default ViewDeviceClientInvitationModal;
