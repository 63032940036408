import { connect } from "react-redux";
import OrganizationUtils from "../../../utils/organization";
import Roles, {
  RolesDOMProps,
  RolesProps as _RolesProps,
  RolesModalVisibilityProps,
} from "./roles-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import { listOrganizationRoles } from "../../../actions";
import { ActionSender } from "../../../model/ActionSender";
import AuthenticationUtils from "../../../utils/authentication";
import { addSenderArgument } from "../../../actions/actionHelpers";

export { ModalKeys } from "./roles-container";

export interface RolesProps extends RolesDOMProps, RolesModalVisibilityProps {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_RolesProps, RolesProps>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<_RolesProps, RolesProps>;

const sender: ActionSender = { key: "roles" };

function mapStateToProps(state: AppState): ReduxStateProps {
  let roles: any[] | undefined =
    OrganizationUtils.selectOrganizationRoles(state);
  const orgAdminRoleId = OrganizationUtils.selectOrgAdminRoleId(state);
  if (roles) {
    roles = roles.map((rl) => {
      const role = { ...rl };
      role.type = orgAdminRoleId === role.id ? "orgadmin" : "role";
      return role;
    });
  }
  return {
    userName: AuthenticationUtils.selectAuthenticationUserDisplayName(state),
    userId: AuthenticationUtils.selectAuthenticationUserId(state),
    roles,
    orgAdminRoleId,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    AuthenticationUtils.compareAuthenticationUserDisplayNameStates(
      next,
      prev
    ) &&
    AuthenticationUtils.compareAuthenticationUserIdStates(next, prev) &&
    OrganizationUtils.compareOrganizationRoleOrganizationRoleStates(
      next,
      prev
    ) &&
    OrganizationUtils.compareOrganizationRolesStates(next, prev)
  );
}
const dispatchActions: ReduxDispatchProps = {
  onLoadRoles: addSenderArgument(sender, listOrganizationRoles),
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  RolesProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(Roles);
