import {HTMLAttributes, useContext, useEffect} from "react";
import {useIntl} from "react-intl";
import { SummaryCard, IconLibrary, FieldList, TooltipWrapper } from "@10duke/dukeui";
import {Link} from "react-router-dom";
import {ButtonGroup} from "react-bootstrap";
import {ModalKeys} from "../../pages/invitations";
import {sortToLatestByCreatedOrUpdated} from "../../../util/objectUtil";
import InvitationStateBadge from "../../badges/invitation-state-badge";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import {OrganizationGroupInvitationValues} from "../../../localization/organization-group-invitation";
import {UIInvitation} from "../../pages/invitations/invitations-view";
import "./invitations-card-view.scss";
import {hasAction} from "../../../ui-configuration/configuration-tools";

interface InvitationsCardDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface InvitationsCardProps extends InvitationsCardDOMProps {
  isReady: boolean;
  invitations?: UIInvitation[] | null;
  onLoadInvitations: () => {};
  pendingCount?: number;
  unsentCount?: number;
  updatedCount?: number;
  revokedCount?: number;
  acceptedCount?: number;
  declinedCount?: number;
  expiredCount?: number;

  moreAction: () => void;
  enableClients?: boolean;
  enableUsers?: boolean;
}

function InvitationsCard(props: InvitationsCardProps) {
  const {
    invitations,
    onLoadInvitations,
    pendingCount,
    unsentCount,
    updatedCount,
    revokedCount,
    acceptedCount,
    declinedCount,
    expiredCount,
    moreAction,
    isReady,
  } = props;
  const intl = useIntl();
  const { conf } = useContext(UIConfiguration);
  const invitationsConf =
    conf.functionality && conf.functionality.invitations
      ? conf.functionality.invitations
      : {};
  const headerButton = {
    label: intl.formatMessage({
      defaultMessage: "View",
      description: "label for the view button",
    }),
    action: moreAction,
  };
  if (!onLoadInvitations && invitations === undefined) {
    throw new Error(
      "GroupsCard: Required props missing. Either groups or onLoadGroups must be defined"
    );
  }
  const isInvitationsUndefined = invitations === undefined;
  useEffect(() => {
    if (isInvitationsUndefined && onLoadInvitations) {
      onLoadInvitations();
    }
  }, [isInvitationsUndefined, onLoadInvitations]);
  const fields: any = {
    total: {
      label: intl.formatMessage(SummaryCard.TotalLabel),
      value: invitations ? invitations.length : 0,
      itemClass: "total",
    },
  };
  if (unsentCount) {
    fields.unsent = {
      label: intl.formatMessage(
        OrganizationGroupInvitationValues.invitationState.created
      ),
      value: unsentCount,
      itemClass: "unsent",
    };
  }
  if (revokedCount) {
    fields.revoked = {
      label: intl.formatMessage(
        OrganizationGroupInvitationValues.invitationState.revoked
      ),
      value: revokedCount,
      itemClass: "revoked",
    };
  }
  if (declinedCount) {
    fields.declined = {
      label: intl.formatMessage(
        OrganizationGroupInvitationValues.invitationState.declined
      ),
      value: declinedCount,
      itemClass: "declined",
    };
  }
  if (acceptedCount) {
    fields.accepted = {
      label: intl.formatMessage(
        OrganizationGroupInvitationValues.invitationState.accepted
      ),
      value: acceptedCount,
      itemClass: "accepted",
    };
  }
  if (updatedCount) {
    fields.updated = {
      label: intl.formatMessage(
        OrganizationGroupInvitationValues.invitationState.updated
      ),
      value: updatedCount,
      itemClass: "updated",
    };
  }
  if (pendingCount) {
    fields.pending = {
      label: intl.formatMessage(
          OrganizationGroupInvitationValues.invitationState.deliveryRequested
      ),
      value: pendingCount,
      itemClass: "pending",
    };
  }
  if (expiredCount) {
    fields.expired = {
      label: intl.formatMessage(
          OrganizationGroupInvitationValues.invitationState.expired
      ),
      value: expiredCount,
      itemClass: "expired",
    };
  }
  return (
    <SummaryCard
      className={"invitations-card"}
      onReloadData={() => {
        if (onLoadInvitations) {
          onLoadInvitations();
        }
      }}
      isReady={isReady}
      data-test-invitations-card
      title={intl.formatMessage({
        defaultMessage: "Invitations",
        description: "heading for the card",
      })}
      headerButton={headerButton}
      items={
        invitations && invitations.length
          ? invitations
              // then unsent invitations
              .filter((i) => i.invitationState === "created")
              .sort(sortToLatestByCreatedOrUpdated)
              .concat(
                  invitations
                      // then revoked invitations
                      .filter((i) => i.invitationState === "revoked")
                      .sort(sortToLatestByCreatedOrUpdated)
              )
              .concat(
                  invitations
                      // then expired invitations
                      .filter((i) => i.invitationState === "expired")
                      .sort(sortToLatestByCreatedOrUpdated)
              )
              // then closed invitations
              .concat(
                invitations
                  .filter((i) => i.invitationState === "declined")
                  .sort(sortToLatestByCreatedOrUpdated)
              )
              .concat(
                invitations
                  .filter((i) => i.invitationState === "accepted")
                  .sort(sortToLatestByCreatedOrUpdated)
              )
              // and finally the rest
              .concat(
                invitations
                  .filter(
                    (i) =>
                      i.invitationState !== "created" &&
                      i.invitationState !== "expired" &&
                      i.invitationState !== "accepted" &&
                      i.invitationState !== "declined" &&
                      i.invitationState !== "revoked"
                  )
                  .sort(sortToLatestByCreatedOrUpdated)
              )
              .map((i) => {
                const Wrapper = hasAction(invitationsConf.rowActions, 'show') ? Link : "span";
                const wrapperProps: any = {
                  className: "item-copy",
                };
                if (hasAction(invitationsConf.rowActions, 'show')) {
                  wrapperProps.to =
                    (("/invitations/" + i.id) as string) + "/" + (i.invitationType === 'user' ? ModalKeys.show : ModalKeys.showClientInvitation);
                }
                return (
                  <>
                    <Wrapper {...wrapperProps}>
                      <TooltipWrapper
                        tipKey={"invitation_" + i.id}
                        tip={i.email}
                        placement={"top"}
                      >
                        <span className={"label"}>{i.email}</span>
                      </TooltipWrapper>
                      <InvitationStateBadge
                          state={i.invitationState}
                          validUntil={i.validUntil}
                          validFrom={i.validFrom}
                      />
                    </Wrapper>
                    {(hasAction(invitationsConf.rowActions, 'resend') ||
                        hasAction(invitationsConf.rowActions, 'revoke') ||
                        hasAction(invitationsConf.rowActions, 'remove')) && (
                      <ButtonGroup size={"sm"}>
                        {i.invitationState !== "declined" &&
                          i.invitationState !== "accepted" &&
                          i.invitationState !== "revoked" &&
                          i.invitationState !== "expired" && (
                            <>
                              {hasAction(invitationsConf.rowActions, 'resend') && (
                                <Link
                                  className={"btn btn-secondary custom-base"}
                                  to={
                                    (("/invitations/" + i.id) as string) +
                                    "/" +
                                      (i.invitationType === 'user' ? ModalKeys.resend : ModalKeys.resendClientInvitation)
                                  }
                                >
                                  {intl.formatMessage({
                                    defaultMessage: "Resend",
                                    description:
                                      "Label for the resend button. Can be translated as 'send' if it helps keeping the translation short, no need to emphasize that something is sent again.",
                                  })}
                                </Link>
                              )}
                              {hasAction(invitationsConf.rowActions, 'revoke') &&
                                i.invitationState !== "created" && (
                                  <Link
                                    className={"btn btn-secondary custom-base"}
                                    to={
                                      (("/invitations/" + i.id) as string) +
                                      "/" +
                                        (i.invitationType === 'user' ? ModalKeys.revoke : ModalKeys.revokeClientInvitation)
                                    }
                                  >
                                    {intl.formatMessage({
                                      defaultMessage: "Revoke",
                                      description:
                                        "label for the revoke button",
                                    })}
                                  </Link>
                                )}
                            </>
                          )}
                        {hasAction(invitationsConf.rowActions, 'remove') &&
                          (i.invitationState === "created" ||
                            i.invitationState === "expired" ||
                            i.invitationState === "declined" ||
                            i.invitationState === "accepted" ||
                            i.invitationState === "revoked") && (
                            <Link
                              className={"btn btn-secondary custom-base"}
                              to={
                                (("/invitations/" + i.id) as string) +
                                "/" +
                                  (i.invitationType === 'user' ? ModalKeys.remove : ModalKeys.removeClientInvitation)
                              }
                            >
                              {intl.formatMessage({
                                defaultMessage: "Remove",
                                description: "label for the remove button",
                              })}
                            </Link>
                          )}
                      </ButtonGroup>
                    )}
                  </>
                );
              })
          : undefined
      }
      footer={<FieldList inline={true} fields={fields} />}
    >
      {invitations && invitations.length === 0 && (
        <div data-test-no-items className={"no-data"}>
          <div className={"icon"}>{IconLibrary.customIcons.noData}</div>
          <p>
            {intl.formatMessage({
              defaultMessage: "No invitations to show.",
              description: "display value for no invitations",
            })}
          </p>
        </div>
      )}
    </SummaryCard>
  );
}
export default InvitationsCard;
