import { useEffect, useMemo, useState } from "react";
import View, {
  ImportCSVModalProps as _ImportCSVModalProps,
  ImportCSVModalDataProps as _ImportCSVModalDataProps,
  ImportCSVModalStateProps,
} from "./import-csv-modal-view";
import { TableColumn } from "../../table";
import { camelCase } from "change-case";
import { CSVData } from "./import-csv-utils";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
import { withCloseAfterExited } from "@10duke/dukeui";
import isEmail from "../../../util/isEmail";
import { parse } from "csv-parse/browser/esm";
const ViewWithCloseAfterExited =
  withCloseAfterExited<_ImportCSVModalProps>(View);
export interface ImportCSVModalDataProps extends _ImportCSVModalDataProps {
  onLoadGroups: () => void;
}
export interface ImportCSVModalProps
  extends Omit<_ImportCSVModalProps, keyof ImportCSVModalStateProps>,
    ImportCSVModalDataProps {}
const resolveFilteredSelection = (
  groups: OrganizationGroup[] | undefined,
  selected: OrganizationGroup[]
) => {
  let filteredSelection: OrganizationGroup[] | undefined =
    groups && selected.length
      ? (selected.filter((v) =>
          groups.find((gv) => gv.id === v.id)
        ) as OrganizationGroup[])
      : [];
  filteredSelection =
    filteredSelection.length !== selected.length && selected.length
      ? filteredSelection
      : undefined;
  return filteredSelection;
};
export default function ImportCSVModal(props: ImportCSVModalProps) {
  const { show, groups, onLoadGroups, ...other } = props;

  const [hasFile, setHasFile] = useState<boolean>(false);
  const [importFailed, onSetImportFailed] = useState<boolean>(false);

  const [importErrors, onSetImportErrors] = useState<any[] | undefined>(
    undefined
  );
  const [csvFile, setCsvFile] = useState<File | undefined>(undefined);
  const [csvData, setCsvData] = useState<CSVData[] | undefined | null>(
    undefined
  );
  const [columns, setColumns] = useState<TableColumn[] | undefined>(undefined);
  const [columnLabels, setColumnLabels] = useState<{ [key: string]: string }>({
    tmp_id: "tmp_id",
  });
  const [dataMapping, setDataMapping] = useState<{
    [key: string]: string | null;
  } | null>(null);
  const [importData, setImportData] = useState<{ [key: string]: any }[]>([]);
  const [importColumns, setImportColumns] = useState<TableColumn[]>([]);
  const [duplicatesFound, setDuplicatesFound] = useState(0);
  const [invalidEmailsFound, setInvalidEmailsFound] = useState(0);
  const [activeSearch, onSetActiveSearch] = useState("");

  const [selected, onSetSelected] = useState<OrganizationGroup[]>([]);
  const filteredSelection = useMemo(
    () => resolveFilteredSelection(groups, selected),
    [groups, selected]
  );
  const groupsIsUndefined = groups === undefined;
  useEffect(() => {
    if (show && groupsIsUndefined && onLoadGroups) {
      onLoadGroups();
    }
    if (show && filteredSelection) {
      // filter out selection that has disappeared from groups
      onSetSelected(filteredSelection);
    }
  }, [show, groupsIsUndefined, filteredSelection, onLoadGroups, onSetSelected]);

  useEffect(() => {
    if (dataMapping && csvData && csvData.length) {
      const tv = Object.values(dataMapping);
      const tk = Object.keys(dataMapping);
      const idFieldInd = tv.indexOf("email");
      let scroll = false;
      const filteredLength = csvData
        .map((v) => v[tk[idFieldInd]])
        .filter((itm, pos, arr) => arr.indexOf(itm) === pos).length;
      if (idFieldInd >= 0 && filteredLength !== csvData.length) {
        setDuplicatesFound(csvData.length - filteredLength);
        scroll = true;
      } else {
        setDuplicatesFound(0);
      }
      const filteredByEmailLength = csvData
        .map((v) => v[tk[idFieldInd]])
        .filter((itm, pos, arr) => isEmail(itm)).length;
      if (idFieldInd >= 0 && filteredByEmailLength !== csvData.length) {
        setInvalidEmailsFound(csvData.length - filteredByEmailLength);
        scroll = true;
      } else {
        setInvalidEmailsFound(0);
      }

      if (scroll) {
        let scrollHandlerRetry = 0;
        const scrollHandler = () => {
          const el = document.querySelector(".import-csv-modal th.error");
          if (!el) {
            // element possibly not rendered yet, try again
            scrollHandlerRetry += 1;
            if (scrollHandlerRetry < 10) {
              setTimeout(scrollHandler, 10);
            }
          } else {
            el.scrollIntoView({ block: "center", inline: "nearest" });
          }
        };
        setTimeout(scrollHandler, 10);
      }
    } else {
      setDuplicatesFound(0);
      setInvalidEmailsFound(0);
    }
  }, [dataMapping, csvData]);

  useEffect(() => {
    if (csvFile) {
      try {
        const reader = new FileReader();
        reader.onload = async (e) => {
          if (e && e.target && e.target.result) {
            const labels: { [key: string]: string } = {
              tmp_id: "tmp_id",
            };
            parse(
              typeof e.target.result !== "string"
                ? new Buffer(e.target.result)
                : e.target.result,
              {
                bom: true,
                delimiter: [",", ";"],
                skip_empty_lines: true,
                ignore_last_delimiters: true,
                columns: (cols: string[]) =>
                  cols.map((col) => {
                    // Transform the csv column name into a nicer technical key, store original into labels for
                    // display later
                    const cc = camelCase(col);
                    let ccc = cc;
                    // ensure unique key
                    let ind = 1;
                    while (labels[ccc]) {
                      ccc += cc + ind;
                      ind += 1;
                    }
                    labels[ccc] = col;
                    return ccc;
                  }),
                trim: true,
              },
              (err: any, output: any) => {
                if (!err && output) {
                  const t = output.map((v: any, i: number) => {
                    return {
                      tmp_id: i,
                      ...v,
                    };
                  });
                  setCsvData(t);
                } else {
                  // The error object contains additional information about what wen wrong, but displaying it in
                  // detail requires too much effort for now. So instead we just set data as null, which is then
                  // rendered as "parsing failed"
                  setCsvData(null);
                }
              }
            );
            setColumnLabels(labels);
          }
        };
        reader.readAsText(csvFile as any as Blob);
      } catch (e) {
        setCsvData(null);
      }
    } else {
      setCsvData(undefined);
    }
  }, [csvFile]);

  const stateProps = {
    hasFile,
    setHasFile,
    csvFile,
    setCsvFile,
    csvData,
    setCsvData,
    columns,
    setColumns,
    columnLabels,
    setColumnLabels,
    dataMapping,
    setDataMapping,
    importData,
    setImportData,
    importColumns,
    setImportColumns,
    duplicatesFound,
    setDuplicatesFound,
    invalidEmailsFound,
    setInvalidEmailsFound,
    activeSearch,
    onSetActiveSearch,
    selected,
    onSetSelected,
    importErrors,
    onSetImportErrors,
    importFailed,
    onSetImportFailed,
    onReloadData: () => {
      if (onLoadGroups) {
        onLoadGroups();
      }
    },
  };
  return (
    <ViewWithCloseAfterExited
      {...other}
      {...stateProps}
      show={show}
      groups={groups}
    />
  );
}
