import { useEffect, HTMLAttributes } from "react";
import {FormattedDate, FormattedMessage, useIntl} from "react-intl";
import { FieldList, Feedback, Modal } from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import InvitationStateBadge from "../../badges/invitation-state-badge";
import {
  ClearErrorAction,
  GetOrgOrgGroupInvitationAction,
  isAddErrorAction,
} from "../../../actions/actionTypes";
import {ClientGroupInvitationWithExpiredStatus} from "../../../utils/deviceClientGroupInvitation";
import {ClientGroupInvitationLabels} from "../../../localization/client-group-invitation";

//<editor-fold desc="Props">

export interface ViewDeviceClientInvitationModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface ViewDeviceClientInvitationModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  invitation?: ClientGroupInvitationWithExpiredStatus | null;
  onLoadInvitation: (id: string) => Promise<GetOrgOrgGroupInvitationAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface ViewDeviceClientInvitationModalProps
  extends ViewDeviceClientInvitationModalDOMProps,
    ViewDeviceClientInvitationModalDataProps,
    Pick<ClosableModalProps, "show" | "onClose" | "onExited"> {
  invitationId?: string;
}
//</editor-fold>

function ViewDeviceClientInvitationModal(props: ViewDeviceClientInvitationModalProps) {
  //<editor-fold desc="Local variables">
  let {
    invitationId,
    invitation,
    show,
    onClose,
    onExited,
    onLoadInvitation,
    isReady,
    onClearError,
  } = props;

  const invitationObjId = invitation ? invitation.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!onLoadInvitation &&
      !!invitationId &&
      (invitationObjId === undefined ||
        (!!invitationObjId && invitationObjId !== invitationId))
    ) {
      onLoadInvitation(invitationId).then((res) => {
        if (!invitationObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, onLoadInvitation, invitationId, invitationObjId, onClearError]);

  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>

  return (
    <Modal
      onReloadData={() => {
        if ((invitation || invitationId) && onLoadInvitation) {
          onLoadInvitation(
            invitation ? (invitation.id as string) : (invitationId as string)
          ).then((res) => {
            if (!invitation && isAddErrorAction(res)) {
              // only clear error if no data exists, leave if previous data is still available and
              // let the api error notification handler show error
              onClearError(res.error?.errorId);
            }
          });
        }
      }}
      isReady={isReady}
      onExited={onExited}
      data-test-view-invitation-modal
      title={
        invitation
          ? intl.formatMessage({
              defaultMessage: "Invitation details",
              description: "modal heading",
            })
          : intl.formatMessage({
              defaultMessage: "Invitation not found",
              description: "modal heading when invitation was not found",
            })
      }
      show={show}
      onClose={onClose}
      primaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Close",
          description: "primary button label",
        }),
      }}
      onPrimaryAction={onClose}
    >
      {!invitation && (
        <Feedback type={"danger"} show={true} asChild={true}>
          <p>
            <FormattedMessage
              defaultMessage="No matching invitation found."
              description="message to be shown when there is no invitation to display"
            />
          </p>
        </Feedback>
      )}
      {invitation && (
        <>
          <FieldList
            asOneLiners={true}
            fields={{
              clientName: {
                label: intl.formatMessage(
                    ClientGroupInvitationLabels.clientName
                ),
                value: invitation.clientName,
              },
              clientId: {
                label: intl.formatMessage(
                    ClientGroupInvitationLabels.allowClientId
                ),
                value: invitation.allowClientId,
              },
              email: {
                label: intl.formatMessage(
                  ClientGroupInvitationLabels.email
                ),
                value: (
                  <a
                    href={"mailto://" + invitation.email}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {invitation.email}
                  </a>
                ),
              },
              invitationState: {
                label: intl.formatMessage(
                    ClientGroupInvitationLabels.invitationState
                ),
                value: (
                  <InvitationStateBadge
                    state={invitation.invitationState}
                    validUntil={invitation.validUntil}
                    validFrom={invitation.validFrom}
                  />
                ),
              },
              validFrom: {
                itemClass: "d-inline-block",
                label: intl.formatMessage(ClientGroupInvitationLabels.validFrom),
                value: (
                    <>
                      {invitation.validFrom ? (
                          <FormattedDate value={invitation.validFrom === 'now()' ? new Date() : new Date(invitation.validFrom)} />
                      ) : (
                          "-"
                      )}
                    </>
                ),
              },
              validUntil: {
                itemClass: "d-inline-block",
                label: intl.formatMessage(ClientGroupInvitationLabels.validUntil),
                value: (
                    <>
                      {invitation.validUntil ? (
                          <FormattedDate value={invitation.validUntil === 'now()' ? new Date() : new Date(invitation.validUntil)} />
                      ) : (
                          "-"
                      )}
                    </>
                ),
              },
              recipientName: {
                label: intl.formatMessage(
                    ClientGroupInvitationLabels.recipientName
                ),
                value: invitation.recipientName,
              },
              inviterName: {
                label: intl.formatMessage(
                    ClientGroupInvitationLabels.inviterName
                ),
                value: invitation.inviterName,
              },
              invitationScopeInformation: {
                label: intl.formatMessage(
                    ClientGroupInvitationLabels.invitationScopeInformation
                ),
                value: invitation.invitationScopeInformation,
              },
            }}
          />
        </>
      )}
    </Modal>
  );
}

export default ViewDeviceClientInvitationModal;
