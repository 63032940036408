import { withCloseAfterExited } from "@10duke/dukeui";
import {
  default as View,
  RemoveUserModalProps as _RemoveUserModalProps, RemoveUserModalStateProps
} from "./remove-user-modal-view";
import {useEffect, useState} from "react";

const RemoveUserModalWithClose = withCloseAfterExited<_RemoveUserModalProps>(View);

export type RemoveUserModalProps = Omit<
    _RemoveUserModalProps,
    keyof RemoveUserModalStateProps
>;
const RemoveUserModal = (props: RemoveUserModalProps) => {
  const {show} = props;
  const [removeLeases, setRemoveLeases] = useState(false);
  useEffect(() => {
    if (show === false) {
      setRemoveLeases(false);
    }
  }, [show])
  const onToggleRemoveLeases = () => {
    setRemoveLeases((prev) => !prev);
  }
  return <RemoveUserModalWithClose {...props} removeLeases={removeLeases} onToggleRemoveLeases={onToggleRemoveLeases} />;
}
export default RemoveUserModal;
