import { useEffect, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {FeedbackEntry, Feedback, Modal} from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import {
  ClearErrorAction, GetOrgClientGroupInvitationAction,
  isAddErrorAction, RevokeOrgClientGroupInvitationAction,
} from "../../../actions/actionTypes";
import {ClientGroupInvitation} from "../../../model/ClientGroupInvitation";

//<editor-fold desc="Props">

export interface RevokeDeviceClientInvitationModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface RevokeDeviceClientInvitationModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  invitation?: ClientGroupInvitation | null;
  onRevokeInvitation: (id: string) => Promise<RevokeOrgClientGroupInvitationAction>;
  onLoadInvitation: (id: string) => Promise<GetOrgClientGroupInvitationAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface RevokeDeviceClientInvitationModalProps
  extends RevokeDeviceClientInvitationModalDOMProps,
    RevokeDeviceClientInvitationModalDataProps,
    Omit<ClosableModalProps, "isReady"> {
  invitationId?: string;
  onShowFeedback: (feedback: FeedbackEntry) => void;
}
//</editor-fold>

function RevokeDeviceClientInvitationModal(props: RevokeDeviceClientInvitationModalProps) {
  //<editor-fold desc="Local variables">
  let {
    onExited,
    invitationId,
    invitation,
    onRevokeInvitation,
    onLoadInvitation,
    show,
    onClose,
    onShowFeedback,
    isReady,
    onClearError,
  } = props;

  // this is more like a variable than a hook
  const intl = useIntl();

  const invitationObjId = invitation ? invitation.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!onLoadInvitation &&
      !!invitationId &&
      (invitationObjId === undefined ||
        (!!invitationObjId && invitationObjId !== invitationId))
    ) {
      onLoadInvitation(invitationId).then((res) => {
        if (!invitationObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, onLoadInvitation, invitationId, invitationObjId, onClearError]);
  //</editor-fold>

  return (
    <Modal
      onReloadData={() => {
        if ((invitation || invitationId) && onLoadInvitation) {
          onLoadInvitation(
            invitation ? (invitation.id as string) : (invitationId as string)
          ).then((res) => {
            if (!invitation && isAddErrorAction(res)) {
              // only clear error if no data exists, leave if previous data is still available and
              // let the api error notification handler show error
              onClearError(res.error?.errorId);
            }
          });
        }
      }}
      onExited={onExited}
      isReady={isReady}
      data-test-revoke-invitation-modal={invitation ? invitation.id : true}
      title={intl.formatMessage({
        defaultMessage: "Revoke invitation",
        description: "modal heading",
      })}
      show={show}
      onClose={onClose}
      primaryButton={
        invitation &&
        invitation.invitationState !== "revoked" &&
        invitation.invitationState !== "declined" &&
        invitation.invitationState !== "accepted"
          ? {
              label: intl.formatMessage({
                defaultMessage: "Revoke",
                description: "primary button label",
              }),
            }
          : {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
      }
      onPrimaryAction={() => {
        if (
          show &&
          invitation &&
          invitation.invitationState !== "revoked" &&
          invitation.invitationState !== "declined" &&
          invitation.invitationState !== "accepted"
        ) {
          onRevokeInvitation(invitation.id as string).then((res) => {
            if (!isAddErrorAction(res)) {
              onShowFeedback({
                id: ("revoke_" + res.invitation.id) as string,
                msg: intl.formatMessage(
                  {
                    defaultMessage: "Invitation for {name} revoked.",
                    description:
                      "success notification. 'name' = recipient email",
                  },
                  {
                    name:
                      "<strong>" +
                      (invitation
                        ? invitation.email
                        : "impossible yet required outcome") +
                      "</strong>",
                  }
                ),
                autoClose: true,
                type: "success",
              });
            } else {
              onClearError(res.error?.errorId);
              onShowFeedback({
                id: ("revoke_" +
                  (invitation
                    ? invitation.id
                    : "impossible_yet_required_outcome")) as string,
                msg: intl.formatMessage(
                  {
                    defaultMessage:
                      "Revoking invitation for {name} failed: {msg}",
                    description:
                      "failure notification. 'name' = email of the recipient, 'msg' = error message",
                  },
                  {
                    name:
                      "<strong>" +
                      (invitation
                        ? invitation.email
                        : "impossible yet required outcome") +
                      "</strong>",
                    msg: res.error.apiError.error_description,
                  }
                ),
                type: "danger",
              });
            }
          });
        }
        onClose();
      }}
      secondaryButton={
        invitation &&
        invitation.invitationState !== "revoked" &&
        invitation.invitationState !== "declined" &&
        invitation.invitationState !== "accepted"
          ? {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={invitation ? onClose : undefined}
    >
      <>
        {show && invitation && (
          <>
            {invitation.invitationState !== "revoked" &&
            invitation.invitationState !== "declined" &&
            invitation.invitationState !== "accepted" ? (
              <p>
                <FormattedMessage
                  defaultMessage="Are you sure you wish to revoke {name}?"
                  description={
                    "Confirm action message. 'name' = recipient email"
                  }
                  values={{
                    name: <strong>{invitation.email}</strong>,
                  }}
                />
              </p>
            ) : undefined}
            {(invitation.invitationState === "revoked" ||
              invitation.invitationState === "declined" ||
              invitation.invitationState === "accepted") && (
              <Feedback type={"danger"} show={true} asChild={true}>
                <p data-test-invitation-not-revokable>
                  <FormattedMessage
                    defaultMessage="Invitation for {name} has been closed and can no longer be revoked."
                    description="message to be shown when the invitation is not revokable due to it being closed. 'name' = recipient email"
                    values={{
                      name: <strong>{invitation.email}</strong>,
                    }}
                  />
                </p>
              </Feedback>
            )}
          </>
        )}
        {isReady && show && !invitation && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p data-test-invitation-not-found>
              <FormattedMessage
                defaultMessage="No matching invitation found."
                description="message to be shown when there is no invitation to display"
              />
            </p>
          </Feedback>
        )}
      </>
    </Modal>
  );
}

export default RevokeDeviceClientInvitationModal;
