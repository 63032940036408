import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {
  buildActionThunk,
  ensureSelectedOrgId,
  forceUndefined,
} from "./actionHelpers";
import { OrganizationGroup } from "../model/OrganizationGroup";
import {
  RemoveOrgGroupOfUserResult,
  RemoveOrgGroupsOfUserResult,
  SetOrgGroupsOfUserResult,
} from "../api/IdpApi";

/**
 * Lists organization groups assigned to a user.
 * @param sender Component requesting for the action
 * @param userId The user id
 */
export function listOrganizationGroupsOfUser(
  sender: ActionSender,
  userId: string,
  organizationId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListOrgGroupsOfUserAction> {
  const orgId = organizationId || ensureSelectedOrgId();
  return buildActionThunk<
    ActionTypes.ListOrgGroupsOfUserAction,
    OrganizationGroup[]
  >(
    sender,
    ActionTypes.LIST_ORG_GROUPS_OF_USER,
    async () => await idpApi.listOrganizationGroupsOfUser(userId, orgId),
    (type, groups) => ({
      type,
      groups: forceUndefined(groups),
      userId,
    })
  );
}

/**
 * Adds a user to an organization group.
 * @param sender Component requesting for the action
 * @param orgGroupId The group id
 * @param userId The user id
 */
export function addOrganizationGroupForUser(
  sender: ActionSender,
  orgGroupId: string,
  userId: string
): ActionTypes.AppThunkAction<ActionTypes.AddOrgGroupForUserAction> {
  return buildActionThunk<ActionTypes.AddOrgGroupForUserAction, void>(
    sender,
    ActionTypes.ADD_ORG_GROUP_FOR_USER,
    async () => await idpApi.addOrganizationGroupForUser(orgGroupId, userId),
    (type) => ({
      type,
      orgGroupId,
      userId,
    })
  );
}

/**
 * Removes a user from an organization group.
 * @param sender Component requesting for the action
 * @param orgGroupId The group id
 * @param userId The user id
 */
export function removeOrganizationGroupOfUser(
  sender: ActionSender,
  orgGroupId: string,
  userId: string
): ActionTypes.AppThunkAction<ActionTypes.RemoveOrgGroupOfUserAction> {
  const orgId = ensureSelectedOrgId();
  return buildActionThunk<
    ActionTypes.RemoveOrgGroupOfUserAction,
    RemoveOrgGroupOfUserResult
  >(
    sender,
    ActionTypes.REMOVE_ORG_GROUP_OF_USER,
    async () => await idpApi.removeOrganizationGroupOfUser(orgGroupId, userId),
    (type, data) => ({
      type,
      orgGroupId,
      userId,
      orgId,
      organizationRemovingResult: forceUndefined(data),
    })
  );
}

export function addOrganizationGroupsForUser(
  sender: ActionSender,
  orgGroupIds: string[],
  userId: string
): ActionTypes.AppThunkAction<ActionTypes.AddOrgGroupsForUserAction> {
  return buildActionThunk<ActionTypes.AddOrgGroupsForUserAction, void>(
    sender,
    ActionTypes.ADD_ORG_GROUPS_FOR_USER,
    async () => await idpApi.addOrganizationGroupsForUser(orgGroupIds, userId),
    (type) => ({
      type,
      orgGroupIds,
      userId,
    })
  );
}

export function removeOrganizationGroupsOfUser(
  sender: ActionSender,
  orgGroupIds: string[],
  userId: string
): ActionTypes.AppThunkAction<ActionTypes.RemoveOrgGroupsOfUserAction> {
  const orgId = ensureSelectedOrgId();
  return buildActionThunk<
    ActionTypes.RemoveOrgGroupsOfUserAction,
    RemoveOrgGroupsOfUserResult
  >(
    sender,
    ActionTypes.REMOVE_ORG_GROUPS_OF_USER,
    async () =>
      await idpApi.removeOrganizationGroupsOfUser(orgGroupIds, userId),
    (type, data) => ({
      type,
      orgGroupIds,
      userId,
      orgId,
      organizationRemovingResult: forceUndefined(data),
    })
  );
}

export function setOrganizationGroupsOfUser(
  sender: ActionSender,
  orgGroupIds: string[],
  userId: string
): ActionTypes.AppThunkAction<ActionTypes.SetOrgGroupsOfUserAction> {
  const orgId = ensureSelectedOrgId();
  return buildActionThunk<
    ActionTypes.SetOrgGroupsOfUserAction,
    SetOrgGroupsOfUserResult
  >(
    sender,
    ActionTypes.SET_ORG_GROUPS_OF_USER,
    async () => await idpApi.setOrganizationGroupsOfUser(orgGroupIds, userId),
    (type, data) => ({
      type,
      orgGroupIds,
      userId,
      orgId,
      organizationRemovingResult: forceUndefined(data),
    })
  );
}
