import {
  getSelectedIdpApiImpl,
  getSelectedEntApiImpl,
  ApiImplementation,
  getIdpApiBase,
  getEntApiBase,
  getSelectedAuthzApiImpl,
  getAuthzApiBase,
} from "./ApiImplementation";
import { IdpApi } from "./IdpApi";
import MockEnt from "./internalMock/MockEnt";
import MockIdp from "./internalMock/MockIdp";
import MockAuthz from "./internalMock/MockAuthz";
import { ApiError, isInstanceOfApiError } from "../model/ApiError";
import IdpApiWithDefaults from "./api-util/IdpApiWithDefaults";
import _debug from "debug";
import RealIdp from "./real/RealIdp";
import RealEnt from "./real/RealEnt";
import { getAuthentication } from "../store";
import { Authentication } from "../model/Authentication";
import { EntApi } from "./EntApi";
import { setMockDb } from "./internalMock/mockData";
import RealAuthz from "./real/RealAuthz";

const debug = _debug("Api");

function createAuthzApi(): any {
  const authzApiImpl = getSelectedAuthzApiImpl();
  let authzApi: any | undefined = undefined;
  switch (authzApiImpl) {
    case ApiImplementation.internalMock:
      authzApi = new MockAuthz();
      break;
    case ApiImplementation.real:
      authzApi = new RealAuthz();
      break;
    default:
      throw new Error(
        `"${authzApiImpl}" API implementation currently not supported for Authz API`
      );
  }

  return authzApi;
}

function createIdpApi(): IdpApi {
  const idpApiImpl = getSelectedIdpApiImpl();
  let idpApi: IdpApi | undefined = undefined;
  switch (idpApiImpl) {
    case ApiImplementation.internalMock:
      idpApi = new MockIdp();
      break;
    case ApiImplementation.real:
      idpApi = new RealIdp();
      break;
    default:
      throw new Error(
        `"${idpApiImpl}" API implementation currently not supported for IdP API`
      );
  }

  return new IdpApiWithDefaults(idpApi as IdpApi);
}

function createEntApi(): EntApi {
  const entApiImpl = getSelectedEntApiImpl();
  let entApi: EntApi | undefined = undefined;
  switch (entApiImpl) {
    case ApiImplementation.internalMock:
      entApi = new MockEnt();
      break;
    case ApiImplementation.real:
      entApi = new RealEnt();
      break;
    default:
      throw new Error(
        `"${entApiImpl}" API implementation currently not supported for Entitlement API`
      );
  }

  return entApi as EntApi;
}

/**
 * Provider for initialization parameters required by initializing IdP and Entitlement APIs.
 * @param name Name of initialization parameter requested by API initialization.
 */
function initProvider(name: string): string {
  switch (name) {
    case "idpApiBase":
      return getIdpApiBase();
    case "entApiBase":
      return getEntApiBase();
    case "authzApiBase":
      return getAuthzApiBase();
    case "accessToken":
      return (getAuthentication() as Authentication).accessToken;
    default:
      throw Error("invalid initialization parameter " + name);
  }
}

function initializeIdpApi(): void {
  idpApi.initialize(initProvider);
}

function initializeEntApi(): void {
  entApi.initialize(initProvider);
}
function initializeAuthzApi(): void {
  authzApi.initialize(initProvider);
}

function initializeMockDb(): void {
  if (window && (window as any).Cypress && (window as any).INITIAL_DB) {
    setMockDb((window as any).INITIAL_DB);
  }
}

/**
 * Reads error thrown by an API call and returns an ApiError object describing
 * the error in the way errors are described in this application.
 * @param err Error thrown by an API call
 */
export function errorToApiError(err: Error): ApiError {
  if (isInstanceOfApiError(err)) {
    return err;
  }

  debug("Caught an error that is not an ApiError: %o", err);
  return {
    error: (err as any).status || "api_error",
    error_description: err.message,
  };
}

export const idpApi = createIdpApi();
export const entApi = createEntApi();
export const authzApi = createAuthzApi();
export function initializeApis(): void {
  initializeIdpApi();
  initializeEntApi();
  initializeAuthzApi();
  initializeMockDb();
}
