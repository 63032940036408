import { useEffect, HTMLAttributes } from "react";
import {FormattedDate, FormattedMessage, useIntl} from "react-intl";
import {FieldList, IconLibrary, Feedback, Modal} from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import InvitationStateBadge from "../../badges/invitation-state-badge";
import {
  ClearErrorAction,
  GetOrgOrgGroupInvitationAction,
  isAddErrorAction,
  ListOrgRolesAction,
} from "../../../actions/actionTypes";
import {
  OrganizationGroupInvitationLabels,
  OrganizationGroupInvitationValues,
} from "../../../localization/organization-group-invitation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {OrganizationGroupInvitationWithExpiredStatus} from "../../../utils/invitation";
import "./view-invitation-modal-view.scss";

//<editor-fold desc="Props">

export interface ViewInvitationModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface ViewInvitationModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  invitation?: OrganizationGroupInvitationWithExpiredStatus | null;
  onLoadInvitation: (id: string) => Promise<GetOrgOrgGroupInvitationAction>;
  orgAdminRoleId?: string | null;
  onLoadOrganizationRoles: () => Promise<ListOrgRolesAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface ViewInvitationModalProps
  extends ViewInvitationModalDOMProps,
    ViewInvitationModalDataProps,
    Pick<ClosableModalProps, "show" | "onClose" | "onExited"> {
  invitationId?: string;
}
//</editor-fold>

function ViewInvitationModal(props: ViewInvitationModalProps) {
  //<editor-fold desc="Local variables">
  let {
    invitationId,
    invitation,
    show,
    onClose,
    orgAdminRoleId,
    onExited,
    onLoadInvitation,
    onLoadOrganizationRoles,
    isReady,
    onClearError,
  } = props;

  const invitationObjId = invitation ? invitation.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!onLoadInvitation &&
      !!invitationId &&
      (invitationObjId === undefined ||
        (!!invitationObjId && invitationObjId !== invitationId))
    ) {
      onLoadInvitation(invitationId).then((res) => {
        if (!invitationObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, onLoadInvitation, invitationId, invitationObjId, onClearError]);

  useEffect(() => {
    if (!!show && !!onLoadOrganizationRoles && orgAdminRoleId === undefined) {
      onLoadOrganizationRoles();
    }
  }, [show, onLoadOrganizationRoles, orgAdminRoleId]);

  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>

  return (
    <Modal
      id={"view-invitation-modal"}
      onReloadData={() => {
        if ((invitation || invitationId) && onLoadInvitation) {
          onLoadInvitation(
            invitation ? (invitation.id as string) : (invitationId as string)
          ).then((res) => {
            if (!invitation && isAddErrorAction(res)) {
              // only clear error if no data exists, leave if previous data is still available and
              // let the api error notification handler show error
              onClearError(res.error?.errorId);
            }
          });
        }
        if (onLoadOrganizationRoles) {
          onLoadOrganizationRoles();
        }
      }}
      isReady={isReady}
      onExited={onExited}
      data-test-view-invitation-modal
      title={
        invitation
          ? intl.formatMessage({
              defaultMessage: "Invitation details",
              description: "modal heading",
            })
          : intl.formatMessage({
              defaultMessage: "Invitation not found",
              description: "modal heading when invitation was not found",
            })
      }
      show={show}
      onClose={onClose}
      primaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Close",
          description: "primary button label",
        }),
      }}
      onPrimaryAction={onClose}
    >
      {!invitation && (
        <Feedback type={"danger"} show={true} asChild={true}>
          <p>
            <FormattedMessage
              defaultMessage="No matching invitation found."
              description="message to be shown when there is no invitation to display"
            />
          </p>
        </Feedback>
      )}
      {invitation && (
        <>
          <FieldList
            asOneLiners={true}
            fields={{
              email: {
                label: intl.formatMessage(
                  OrganizationGroupInvitationLabels.email
                ),
                value: (
                  <a
                    href={"mailto://" + invitation.email}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {invitation.email}
                  </a>
                ),
              },
              invitationState: {
                label: intl.formatMessage(
                  OrganizationGroupInvitationLabels.invitationState
                ),
                value: (
                  <InvitationStateBadge
                    state={invitation.invitationState}
                    validUntil={invitation.validUntil}
                    validFrom={invitation.validFrom}
                  />
                ),
              },
              validFrom: {
                itemClass: "d-inline-block",
                label: intl.formatMessage(OrganizationGroupInvitationLabels.validFrom),
                value: (
                    <>
                      {invitation.validFrom ? (
                          <FormattedDate value={invitation.validFrom === 'now()' ? new Date() : new Date(invitation.validFrom)} />
                      ) : (
                          "-"
                      )}
                    </>
                ),
              },
              validUntil: {
                itemClass: "d-inline-block",
                label: intl.formatMessage(OrganizationGroupInvitationLabels.validUntil),
                value: (
                    <>
                      {invitation.validUntil ? (
                          <FormattedDate value={invitation.validUntil === 'now()' ? new Date() : new Date(invitation.validUntil)} />
                      ) : (
                          "-"
                      )}
                    </>
                ),
              },
              recipientName: {
                label: intl.formatMessage(
                  OrganizationGroupInvitationLabels.recipientName
                ),
                value: invitation.recipientName,
              },
              isAdmin: {
                label: intl.formatMessage(
                  OrganizationGroupInvitationLabels.isOrgAdmin
                ),
                value: !!invitation.organizationRoleIds.find(
                  (v) => v === orgAdminRoleId
                ) ? (
                  <>
                    <span className={"indicator indicator-active icon"}>
                      <FontAwesomeIcon icon={IconLibrary.icons.faCheck} />
                    </span>{" "}
                    {intl.formatMessage(
                      OrganizationGroupInvitationValues.isOrgAdmin.true
                    )}
                  </>
                ) : (
                  <>
                    <span className={"indicator indicator-inactive icon"}>
                      <FontAwesomeIcon icon={IconLibrary.icons.faTimes} />
                    </span>{" "}
                    {intl.formatMessage(
                      OrganizationGroupInvitationValues.isOrgAdmin.false
                    )}
                  </>
                ),
              },
              inviterName: {
                label: intl.formatMessage(
                  OrganizationGroupInvitationLabels.inviterName
                ),
                value: invitation.inviterName,
              },
              invitationScopeInformation: {
                label: intl.formatMessage(
                  OrganizationGroupInvitationLabels.invitationScopeInformation
                ),
                value: invitation.invitationScopeInformation,
              },
            }}
          />
        </>
      )}
    </Modal>
  );
}

export default ViewInvitationModal;
