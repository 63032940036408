import { connect } from "react-redux";
import { AppState } from "../../../store/AppState";
import OrganizationUtils from "../../../utils/organization";
import OrganizationCard, {
  OrganizationCardProps as _OrganizationCardProps
} from "./organization-card-view";

import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../../util/typeUtil";
import { loadOrgs } from "../../../actions";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";

export interface OrganizationCardProps
  extends Omit<_OrganizationCardProps, "organization" | "isReady"> {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _OrganizationCardProps,
  OrganizationCardProps
>;

// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _OrganizationCardProps,
  OrganizationCardProps
>;
const sender: ActionSender = { key: "organizationCard" };

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    organization: OrganizationUtils.selectSelectedOrganization(state),
    isReady: true
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return OrganizationUtils.compareSelectedOrganizationStates(next, prev);
}

const dispatchActions: ReduxDispatchProps = {
  onLoadOrganization: addSenderArgument(sender, loadOrgs)
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  OrganizationCardProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual
})(OrganizationCard);
