import { connect } from "react-redux";
import CreateDeviceClientInvitationModal, {
  CreateDeviceClientInvitationModalProps as _CreateDeviceClientInvitationModalProps,
} from "./create-device-client-invitation-modal-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import OrganizationUtils from "../../../utils/organization";
import {
  clearError, createAndSendOrgClientGroupInvitation, listOrganizationClientGroups,
} from "../../../actions";
import { CreateDeviceClientInvitationModalDataProps } from "./create-device-client-invitation-modal-view";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import AuthenticationUtils from "../../../utils/authentication";
import { addSenderArgument } from "../../../actions/actionHelpers";

export type CreateDeviceClientInvitationModalProps = Omit<
  _CreateDeviceClientInvitationModalProps,
  keyof CreateDeviceClientInvitationModalDataProps
>;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _CreateDeviceClientInvitationModalProps,
  CreateDeviceClientInvitationModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _CreateDeviceClientInvitationModalProps,
  CreateDeviceClientInvitationModalProps
>;

const sender: ActionSender = { key: "create-device-client-invitation" };
function mapStateToProps(
  state: AppState,
  ownProps: CreateDeviceClientInvitationModalProps
): ReduxStateProps {
  const organization = OrganizationUtils.selectSelectedOrganization(state);
  return {
    organizationId: organization?.id as string,
    organizationName: organization?.name as string,
    userName: AuthenticationUtils.selectAuthenticationUserDisplayName(state),
    clientGroups: OrganizationUtils.selectOrganizationClientGroups(state),
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}
function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    AuthenticationUtils.compareAuthenticationUserDisplayNameStates(
      next,
      prev
    ) &&
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareSelectedOrganizationStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientGroupsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onCreateClientGroupInvitation: addSenderArgument(
    sender,
    createAndSendOrgClientGroupInvitation
  ),
  onLoadClientGroups: addSenderArgument(sender, listOrganizationClientGroups),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  CreateDeviceClientInvitationModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(CreateDeviceClientInvitationModal);
