import { AppState } from "../store/AppState";
import { Authentication } from "../model/Authentication";

function selectAuthentication(state: AppState): Authentication | undefined {
  return state.authentication;
}
function compareAuthenticationUserDisplayNameStates(
  next: AppState,
  prev: AppState
): boolean {
  return (
    next.authentication?.userDisplayName ===
    prev.authentication?.userDisplayName
  );
}
function selectAuthenticationUserDisplayName(
  state: AppState
): string | undefined {
  return state.authentication?.userDisplayName;
}
function compareAuthenticationUserIdStates(
  next: AppState,
  prev: AppState
): boolean {
  return next.authentication?.userId === prev.authentication?.userId;
}
function selectAuthenticationUserId(state: AppState): string | undefined {
  return state.authentication?.userId;
}
function compareAuthenticationUserEmailStates(
  next: AppState,
  prev: AppState
): boolean {
  return next.authentication?.userEmail === prev.authentication?.userEmail;
}
function selectAuthenticationUserEmail(state: AppState): string | undefined {
  return state.authentication?.userEmail;
}
function selectAuthenticationIsValidUntil(state: AppState): number | undefined {
  return !!state.authentication?.accessTokenReceived &&
    !!state.authentication?.authnIssued &&
    !!state.authentication?.authnExpires
    ? state.authentication.authnExpires -
        (state.authentication.accessTokenReceived -
          state.authentication.authnIssued)
    : undefined;
}
function compareAuthenticationIsValidUntilStates(
  next: AppState,
  prev: AppState
): boolean {
  return (
    next.authentication?.accessTokenReceived ===
      prev.authentication?.accessTokenReceived &&
    next.authentication?.authnIssued === prev.authentication?.authnIssued &&
    next.authentication?.authnExpires === prev.authentication?.authnExpires
  );
}
const AuthenticationUtils = {
  selectAuthentication,
  compareAuthenticationUserDisplayNameStates,
  selectAuthenticationUserDisplayName,
  compareAuthenticationUserIdStates,
  selectAuthenticationUserId,
  compareAuthenticationUserEmailStates,
  selectAuthenticationUserEmail,
  selectAuthenticationIsValidUntil,
  compareAuthenticationIsValidUntilStates
};
export default AuthenticationUtils;
