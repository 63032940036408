import {Feedback} from "@10duke/dukeui";
import { AppAction, ClearErrorAction } from "../../actions/actionTypes";
import { AppError } from "../../model/AppError";
import { Collapse, Form } from "react-bootstrap";
import { useState } from "react";
import "./action-error-notification-view.scss";
import { FormattedMessage } from "react-intl";
//<editor-fold desc="Props">
export interface ActionErrorNotificationProps {
  combinedErrors: CombinedError[];
  onClearError: (errorId: string) => ClearErrorAction;
}

export interface CombinedError {
  id: string;
  sender: string;
  operationType: string;
  errors: Array<AppError<AppAction>>;
}

function stringifyAppErrors(errors: Array<AppError<AppAction>>): string {
  return JSON.stringify(
    errors,
    function (key: string, value: any) {
      // "this" should always be the parent object when going through the members
      if (
        (this as any) !== errors &&
        errors.find((t) => t.errorId === (this as AppError<AppAction>).errorId)
      ) {
        // parent object is an error item in the list, so this only applies to members of the "errors" list items
        if (["action", "apiError"].find((acc) => acc === key)) {
          // only include action and apiError as the others are either not relevant or same for all and shown as
          // properties of the wrapping combined error.
          return value;
        } else {
          // removes all other keys from output
          return undefined;
        }
      } else {
        // rest of the hierarchy can be serialized with defaults
        return value;
      }
    },
    2
  );
}

function errorAsString(er: CombinedError): string {
  return (
    '{\n  "component": "' +
    er.sender +
    '",\n' +
    '  "operation": "' +
    er.operationType +
    '",\n' +
    '  "errors": ' +
    stringifyAppErrors(er.errors).replaceAll("\n", "\n  ") +
    "\n}"
  );
}

function ActionErrorNotification(
  props: ActionErrorNotificationProps
): JSX.Element {
  const { combinedErrors, onClearError } = props;

  const [show, setShow] = useState<{ [key: string]: boolean }>({});

  const idPrefix = "actionError";
  return (
    <>
      {combinedErrors &&
        combinedErrors.length > 0 &&
        combinedErrors.map((er) => (
          <Feedback
            className="action-error-notification"
            key={idPrefix + er.id}
            id={idPrefix + er.id}
            type={"danger"}
            show={true}
            onClose={() => {
              er.errors.forEach((err) => {
                onClearError(err.errorId);
              });
            }}
            autoClose={false}
          >
            <>
              <h4>
                <FormattedMessage
                  defaultMessage="Oh no! Something went wrong."
                  description="Api call error notification title"
                />
              </h4>
              <p>
                <FormattedMessage
                  defaultMessage="We couldn't complete your request. Please {reloadLink} and try again."
                  description="Api call error notification message 1. 'reloadLink' = reloadLink label wrapped with reload link element"
                  values={{
                    reloadLink: (
                      <a
                        href="#reload"
                        onClick={(e: any) => {
                          e.preventDefault();
                          window.location.reload();
                        }}
                      >
                        <FormattedMessage
                          defaultMessage="reload the application"
                          description="reloadLink label, injected into the api call error notification message 1"
                        />
                      </a>
                    ),
                  }}
                />
              </p>
              <p>
                <FormattedMessage
                  defaultMessage="If the problem persists, contact your administrator."
                  description="Api call error notification message 2."
                />
              </p>
              <Form.Group className={"form-group mb-0"}>
                <Collapse in={show[er.id]} data-test-error-details>
                  <Form.Control
                    as={"textarea"}
                    className={"details small"}
                    rows={10}
                    readOnly={true}
                    value={errorAsString(er)}
                  ></Form.Control>
                </Collapse>
                <Form.Label
                  className={
                    "toggle-details" +
                    (show[er.id]
                      ? " on-top"
                      : "")
                  }
                >
                  <a
                    href={"#details"}
                    onClick={(e: any) => {
                      e.preventDefault();
                      const t = { ...show };
                      t[er.id] = !t[er.id];
                      setShow(t);
                    }}
                  >
                    <FormattedMessage
                      defaultMessage="{isVisible, select, true {Hide} false {Show} other {Toggle} } error details"
                      description="Label for the toggle visibility of error details link. 'isVisible' = state of target"
                      values={{
                        isVisible: !!show[er.id],
                      }}
                    />
                  </a>
                </Form.Label>
              </Form.Group>
            </>
          </Feedback>
        ))}
    </>
  );
}
export default ActionErrorNotification;
//</editor-fold>
