import { useContext, useEffect } from "react";
import {
    TooltipWrapper,
    FeedbackContainerProps,
    ShowFeedback,
    Page,
    PageDOMProps,
    Button,
    IconLibrary
} from "@10duke/dukeui";
import {useIntl} from "react-intl";
import Table, { TableColumn } from "../../table";
import { Dropdown } from "react-bootstrap";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
import "./users-view.scss";
import UserLicensesModal from "../../modals/user-licenses-modal";
import CreateInvitationModal from "../../modals/create-invitation-modal";
import ViewUserModal from "../../modals/view-user-modal";
import AssignAdminModal from "../../modals/assign-admin-modal";
import UnassignAdminModal from "../../modals/unassign-admin-modal";
import RemoveUserModal from "../../modals/remove-user-modal";
import SuspendUserModal from "../../modals/suspend-user-modal";
import UnsuspendUserModal from "../../modals/unsuspend-user-modal";
import ManageUsersGroupsModal from "../../modals/manage-users-groups-modal";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import { UserWithStatus } from "../../../utils/user";
import UserStatusBadge from "../../badges/user-status-badge";
import ImportCSVModal from "../../modals/import-csv-modal";
import {
  UserWithStatusLabels,
  UserWithStatusValues,
} from "../../../localization/user";
import DownloadUsersLicenseModal from "../../modals/download-users-license-modal";
import {hasAction, resolveColumns, resolveFilters} from "../../../ui-configuration/configuration-tools";
import ManageUsersRolesModal from "../../modals/manage-users-roles-modal";
import {TableFilter} from "../../table/table-container";
import {HideLabelTableFilterView, TableFilterViewProps} from "../../table/table-filter/table-filter-view";
import HeaderActions from "../../table/header-actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export enum ModalKeys {
  invite = "invite",
  suspend = "suspend",
  unsuspend = "unsuspend",
  licenses = "licenses",
  remove = "remove",
  show = "show",
  assignAdmin = "assign-admin",
  unassignAdmin = "unassign-admin",
  groups = "groups",
  roles = "roles",
  import = "import",
  downloadLicense = "download-license",
}
//<editor-fold desc="Props">

export interface UsersModalVisibilityProps {
  showModal?: { key: ModalKeys; userId?: string };
  onShowModal: (key: ModalKeys, userId?: string) => void;
  onHideModal: (callback?: () => void) => void;
}
export interface UsersDOMProps extends PageDOMProps {}

export interface UsersStateProps extends FeedbackContainerProps {
  selected: UserWithStatus[];
  onSetSelected: (selection: UserWithStatus[]) => void;
}

export interface UsersDataProps {
  userName?: string;
  userId?: string;
  orgAdminRoleId?: string;
  users?: UserWithStatus[];
  groups?: OrganizationGroup[];
  onLoadGroups: () => void;
  onLoadUsers: () => Promise<any>;
}
export interface UsersProps
  extends UsersDataProps,
    UsersStateProps,
    UsersDOMProps,
    UsersModalVisibilityProps {
}
//</editor-fold>

//<editor-fold desc="Utils">-

const resolveRowClasses = (row: UserWithStatus, rowIndex: number) => {
  return row.status;
};

/**
 * Internal utility for creating a user status filter
 * @param status
 * @param initial
 * @param intl
 */
function createStatusFilter(status: "admin"|"member"|"suspended"|"inactive", initial: boolean): TableFilter {
  return {
    key: "hide" + status.replace(/^\w/, (c) => c.toUpperCase()),
    initial: initial,
    view: (props: TableFilterViewProps) => <HideLabelTableFilterView
          label={UserWithStatusValues.status[status]}
          {...props}
    />,
    apply: (u: UserWithStatus) => {
      return u.status !== status;
    }
  };
}
//</editor-fold>

function Users(props: UsersProps) {
  //<editor-fold desc="Local variables">
  const {
    users,
    onLoadUsers,
    selected,
    onSetSelected,
    onShowModal,
    onHideModal,
    showModal,
    onShowFeedback,
    onHideFeedback,
    feedback,
    userName,
    userId,
    orgAdminRoleId,
    groups,
    onLoadGroups,
    ...others
  } = props;

  // this is more like a variable than a hook
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: "Manage users",
    description: "Heading for the Manage users page",
  });
  const description = intl.formatMessage({
    defaultMessage: "Organization user management",
    description: "window meta description for the Manage users page",
  });
  let columns: TableColumn[] = [
    {
      key: "id",
      label: intl.formatMessage(UserWithStatusLabels.id),
      isTechnical: true,
      hidden: true,
    },
    {
      key: "status",
      isDummy: true,
      label: intl.formatMessage(UserWithStatusLabels.status),
      sortable: true,
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => (
        <UserStatusBadge
          status={
            props.row.status as "inactive" | "admin" | "member" | "suspended"
          }
        />
      ),
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => false,
      rendererData: {
        resolveValue: (itm: any, rd: any) => {
          return intl.formatMessage(
            (UserWithStatusValues.status as any)[itm.status as string]
          );
        },
      },
    },
    {
      key: "displayName",
      label: intl.formatMessage(UserWithStatusLabels.displayName),
      hidden: true,
      sortable: true,
    },
    {
      key: "firstName",
      label: intl.formatMessage(UserWithStatusLabels.firstName),
      sortable: true,
    },
    {
      key: "nickname",
      label: intl.formatMessage(UserWithStatusLabels.nickname),
      sortable: true,
      hidden: true,
    },
    {
      key: "lastName",
      label: intl.formatMessage(UserWithStatusLabels.lastName),
      sortable: true,
    },
    {
      key: "professionalTitle",
      label: intl.formatMessage(UserWithStatusLabels.professionalTitle),
      sortable: true,
      hidden: true,
    },
    {
      key: "email",
      label: intl.formatMessage(UserWithStatusLabels.email),
      sortable: true,
    },
    {
      key: "phoneNumber",
      label: intl.formatMessage(UserWithStatusLabels.phoneNumber),
      sortable: true,
      hidden: true,
    },
  ];
  //</editor-fold>

  //<editor-fold desc="Hooks">
  const { conf } = useContext(UIConfiguration);
  const usersConf =
    conf.functionality && conf.functionality.users
      ? conf.functionality.users
      : {};

  if (usersConf.columns && usersConf.columns.length > 0) {
    columns = resolveColumns(columns, usersConf.columns);
  }

  const filters: TableFilter[] = resolveFilters([
    createStatusFilter('admin', false),
    createStatusFilter('suspended', false),
    createStatusFilter('inactive', false),
    createStatusFilter('member', false),
  ], usersConf?.filters);


  // resets selection when users are undefined, and clears out selected items that are not part of updated data
  useEffect(() => {
    if (selected.length) {
      if (users === undefined) {
        onSetSelected([]);
      } else {
        const ids = users.map((usr: UserWithStatus) => usr.id);
        const newS = selected.filter((itm: any) => {
          return ids.indexOf(itm.id) >= 0;
        });
        if (newS.length !== selected.length) {
          onSetSelected(newS);
        }
      }
    }
  }, [users, selected, onSetSelected]);

  useEffect(() => {
    if (groups === undefined) {
      onLoadGroups();
    }
  }, [groups, onLoadGroups]);

  //</editor-fold>
  return (
    <Page
      header={<h1>{title}</h1>}
      data-test-users-page
      id={"users-page"}
      meta={{
        title,
        description,
      }}
      {...others}
    >
      <ShowFeedback
        idPrefix={"users_"}
        onHideFeedback={onHideFeedback}
        feedback={feedback}
      />
      <Table<UserWithStatus>
        data-test-users-table
        persistStateKey={"users-table"}
        allowColumnSort={true}
        selection={{
          multi: false,
          selectAll: false,
        }}
        header={<HeaderActions actions={usersConf.actions} actionRenderer={(action) => {
            let retVal = undefined;
            if (action.key === 'invite') {
                retVal = (
                    <Button
                        key={action.key}
                        data-test-invite-user-trigger
                        action={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onShowModal(ModalKeys.invite);
                        }}
                        variant={"primary"}
                        className={"btn custom-base"}
                    >
                        {intl.formatMessage({
                            defaultMessage: "Invite",
                            description: "Label for the Invite button",
                        })}
                    </Button>
                );
            } else if (action.key === 'import') {
                retVal = (
                    <Button
                        key={action.key}
                        data-test-import-users-trigger
                        action={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onShowModal(ModalKeys.import);
                        }}
                        variant={"primary"}
                        className={"btn custom-base"}
                    >
                        {intl.formatMessage({
                            defaultMessage: "Import",
                            description: "Label for the import users button",
                        })}
                    </Button>
                );
            } else if (!!action.url) {
                retVal = (
                    <Button
                        key={action.key}
                        data-test-custom-action={action.key}
                        action={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.open(action.url, '_blank');
                        }}
                        variant={"primary"}
                        className={"btn custom-base"}
                    >
                        {intl.formatMessage({
                            defaultMessage: "{action, select, other {{action}}}",
                            description: "Custom header action label. Prints action key by default. Inject `actionKey {Label}` after the `select,` and before the `other` to provide translation for custom action. Only use space as separator with multiple actions, no comma.",
                        }, {
                            action: action.key
                        })}
                    </Button>
                );
            }
            return retVal;
        }} />
        }
        search={true}
        rowClasses={resolveRowClasses}
        selected={selected}
        onSelectionChanged={onSetSelected}
        columns={columns}
        columnToggle
        reload={true}
        onLoadData={onLoadUsers}
        data={users ? users : undefined}
        identifyingColumn={"id"}
        filters={filters}
        pagination={false}
        rowTools={(props: { rowEntry: UserWithStatus }) => {
                const suspended = props.rowEntry.status === "suspended";
                return usersConf.rowActions?.map((m) => {
                    let retVal = undefined;
                  if (!m.disabled) {
                    if (m.key === 'show') {
                        retVal = (
                          <Dropdown.Item
                              data-test-row-tool-user-details={props.rowEntry.id}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShowModal(ModalKeys.show, props.rowEntry.id);
                              }}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Profile details",
                              description: "Label for the tool",
                            })}
                          </Dropdown.Item>
                      );
                    } else if (m.key === 'roles') {
                        retVal = (
                          <Dropdown.Item
                              data-test-row-tool-user-roles={props.rowEntry.id}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShowModal(ModalKeys.roles, props.rowEntry.id);
                              }}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Roles",
                              description: "Label for the tool",
                            })}
                          </Dropdown.Item>
                      );
                    } else if (m.key === 'groups') {
                        retVal = (
                          <Dropdown.Item
                              data-test-row-tool-user-groups={props.rowEntry.id}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShowModal(ModalKeys.groups, props.rowEntry.id);
                              }}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Groups",
                              description: "Label for the tool",
                            })}
                          </Dropdown.Item>
                      );
                    } else if (m.key === 'licenses') {
                        retVal = (
                          <Dropdown.Item
                              data-test-row-tool-user-licenses={props.rowEntry.id}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShowModal(ModalKeys.licenses, props.rowEntry.id);
                              }}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Licenses",
                              description: "Label for the tool",
                            })}
                          </Dropdown.Item>
                      );
                    } else if (m.key === 'downloadLicense') {
                      if (!suspended) {
                          retVal = (
                            <Dropdown.Item
                                data-test-row-tool-download-license={
                                  props.rowEntry.id
                                }
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onShowModal(
                                      ModalKeys.downloadLicense,
                                      props.rowEntry.id
                                  );
                                }}
                            >
                              {intl.formatMessage({
                                defaultMessage: "Download License",
                                description: "Label for the tool",
                              })}
                            </Dropdown.Item>
                        );
                      } else {
                          retVal = (
                            <TooltipWrapper
                                tip={intl.formatMessage({
                                  defaultMessage:
                                      "License download is not available for suspended users.",
                                  description:
                                      "tooltip for disabled download license tool",
                                })}
                                tipKey={"unassignBlockedTip"}
                                placement={"auto"}
                                wrapDisabled={true}
                                className={"d-block"}
                            >
                              <Dropdown.Item
                                  data-test-row-tool-download-license={
                                    props.rowEntry.id
                                  }
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onShowModal(
                                        ModalKeys.downloadLicense,
                                        props.rowEntry.id
                                    );
                                  }}
                                  disabled={true}
                              >
                                {intl.formatMessage({
                                  defaultMessage: "Download License",
                                  description: "Label for the tool",
                                })}
                              </Dropdown.Item>
                            </TooltipWrapper>
                        );
                      }
                    } else if (m.key === 'assignAdmin') {
                      if (orgAdminRoleId &&
                          !props.rowEntry.isOrgAdmin) {
                          retVal = (<Dropdown.Item
                            data-test-row-tool-assign-admin={props.rowEntry.id}
                            onClick={(e: any) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onShowModal(
                                  ModalKeys.assignAdmin,
                                  props.rowEntry.id
                              );
                            }}
                        >
                          {intl.formatMessage({
                            defaultMessage: "Assign Admin access",
                            description:
                              "Label for the tool that provides/assigns Admin access to the user",
                          })}
                        </Dropdown.Item>);
                      }
                    } else if (m.key === 'unassignAdmin') {
                      if (orgAdminRoleId &&
                          props.rowEntry.isOrgAdmin &&
                          props.rowEntry.id === userId) {
                          retVal = (
                            <TooltipWrapper
                                tip={intl.formatMessage({
                                  defaultMessage: "You can't unassign yourself.",
                                  description:
                                      "tooltip for disabled unassign own admin status",
                                })}
                                tipKey={"unassignBlockedTip"}
                                placement={"auto"}
                                wrapDisabled={true}
                                className={"d-block"}
                            >
                              <Dropdown.Item
                                  data-test-row-tool-unassign-admin={
                                    props.rowEntry.id
                                  }
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onShowModal(
                                        ModalKeys.unassignAdmin,
                                        props.rowEntry.id
                                    );
                                  }}
                                  disabled={true}
                              >
                                {intl.formatMessage({
                                  defaultMessage: "Unassign Admin access",
                                  description:
                                      "Label for the tool that removes/unassigns Admin access from the user",
                                })}
                              </Dropdown.Item>
                            </TooltipWrapper>
                        );
                      } else if (orgAdminRoleId && props.rowEntry.isOrgAdmin) {
                          retVal = (
                            <Dropdown.Item
                                data-test-row-tool-unassign-admin={props.rowEntry.id}
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onShowModal(
                                      ModalKeys.unassignAdmin,
                                      props.rowEntry.id
                                  );
                                }}
                            >
                              {intl.formatMessage({
                                defaultMessage: "Unassign Admin access",
                                description:
                                    "Label for the tool that removes/unassigns Admin access from the user",
                              })}
                            </Dropdown.Item>
                        );
                      }
                    } else if (m.key === 'remove') {
                      if (props.rowEntry.id === userId) {
                          retVal = (<TooltipWrapper
                            tip={intl.formatMessage({
                              defaultMessage: "You can't remove yourself.",
                              description: "tooltip for disabled self removal",
                            })}
                            tipKey={"removeUserBlockedTip"}
                            placement={"auto"}
                            wrapDisabled={true}
                            className={"d-block"}
                        >
                          <Dropdown.Item
                              data-test-row-tool-user-remove={props.rowEntry.id}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShowModal(ModalKeys.remove, props.rowEntry.id);
                              }}
                              disabled={true}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Remove from organization",
                              description: "Label for the tool",
                            })}
                          </Dropdown.Item>
                        </TooltipWrapper>);
                      } else {
                          retVal = (<Dropdown.Item
                            data-test-row-tool-user-remove={props.rowEntry.id}
                            onClick={(e: any) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onShowModal(ModalKeys.remove, props.rowEntry.id);
                            }}
                        >
                          {intl.formatMessage({
                            defaultMessage: "Remove from organization",
                            description: "Label for the tool",
                          })}
                        </Dropdown.Item>);
                      }
                    } else if (m.key === 'unsuspend') {
                        if (suspended) {
                            retVal = (<Dropdown.Item
                                data-test-row-tool-user-item
                                data-test-row-tool-user-unsuspend={props.rowEntry.id}
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onShowModal(ModalKeys.unsuspend, props.rowEntry.id);
                                }}
                            >
                                {intl.formatMessage({
                                    defaultMessage: "Unsuspend",
                                    description: "Label for the tool",
                                })}
                            </Dropdown.Item>);
                        }
                    } else if (m.key === 'suspend') {
                      if (!suspended &&
                          props.rowEntry.id === userId) {
                          retVal = (<TooltipWrapper
                            tip={intl.formatMessage({
                              defaultMessage: "You can't suspend yourself.",
                              description: "tooltip for disabled self suspension",
                            })}
                            tipKey={"suspendUserBlockedTip"}
                            placement={"auto"}
                            wrapDisabled={true}
                            className={"d-block"}
                        >
                          <Dropdown.Item
                              data-test-row-tool-user-suspend={props.rowEntry.id}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShowModal(ModalKeys.suspend, props.rowEntry.id);
                              }}
                              disabled={true}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Suspend",
                              description: "Label for the tool",
                            })}
                          </Dropdown.Item>
                        </TooltipWrapper>);
                      } else if (!suspended) {
                          retVal = (<Dropdown.Item
                            data-test-row-tool-user-suspend={props.rowEntry.id}
                            onClick={(e: any) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onShowModal(ModalKeys.suspend, props.rowEntry.id);
                            }}
                        >
                          {intl.formatMessage({
                            defaultMessage: "Suspend",
                            description: "Label for the tool",
                          })}
                        </Dropdown.Item>);
                      }
                    } else if (!!m.url) {
                        retVal = (
                            <Dropdown.Item
                                data-test-row-tool-custom={m.key}
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    window.open(m.url, '_blank');
                                }}
                                className={'custom-action'}
                            >
                                <span>{intl.formatMessage({
                                    defaultMessage: "{action, select, other {{action}}}",
                                    description: "Custom row action label. Prints action key by default. Inject `actionKey {Label}` after the `select,` and before the `other` to provide translation for custom action. Only use space as separator with multiple actions, no comma.",
                                }, {
                                    action: m.key
                                })}</span>
                                <FontAwesomeIcon icon={IconLibrary.icons.faExternalLinkAlt} className={'icon'} fixedWidth={true} />
                            </Dropdown.Item>
                        );
                    }
                  }
                  return retVal;
                });
              }
        }
      />
      {hasAction(usersConf.actions, 'import') && (
        <ImportCSVModal
          show={showModal ? showModal.key === ModalKeys.import : false}
          onClose={onHideModal}
          onShowFeedback={onShowFeedback}
        />
      )}
      {hasAction(usersConf.rowActions, 'assignAdmin') && orgAdminRoleId && (
        <AssignAdminModal
          show={showModal ? showModal.key === ModalKeys.assignAdmin : false}
          onClose={onHideModal}
          onShowFeedback={onShowFeedback}
          userId={showModal?.userId}
        />
      )}
      {hasAction(usersConf.rowActions, 'unassignAdmin') && orgAdminRoleId && (
        <UnassignAdminModal
          show={showModal ? showModal.key === ModalKeys.unassignAdmin : false}
          onClose={onHideModal}
          onShowFeedback={onShowFeedback}
          userId={showModal?.userId}
        />
      )}

      {hasAction(usersConf.rowActions, 'suspend') && (
        <SuspendUserModal
          show={showModal ? showModal.key === ModalKeys.suspend : false}
          onClose={onHideModal}
          userId={showModal?.userId}
          onShowFeedback={onShowFeedback}
        />
      )}
      {hasAction(usersConf.rowActions, 'unsuspend') && (
        <UnsuspendUserModal
          show={showModal ? showModal.key === ModalKeys.unsuspend : false}
          onClose={onHideModal}
          userId={showModal?.userId}
          onShowFeedback={onShowFeedback}
        />
      )}

      {hasAction(usersConf.rowActions, 'remove') && (
        <RemoveUserModal
          show={showModal ? showModal.key === ModalKeys.remove : false}
          onClose={onHideModal}
          onShowFeedback={onShowFeedback}
          userId={showModal?.userId}
        />
      )}

      {hasAction(usersConf.rowActions, 'show') && (
        <ViewUserModal
          show={showModal ? showModal.key === ModalKeys.show : false}
          onClose={onHideModal}
          userId={showModal?.userId}
        />
      )}

      {hasAction(usersConf.rowActions, 'groups') && (
        <ManageUsersGroupsModal
          show={showModal ? showModal.key === ModalKeys.groups : false}
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
          userId={showModal ? showModal.userId : undefined}
        />
      )}

      {hasAction(usersConf.rowActions, 'roles') && (
        <ManageUsersRolesModal
          show={showModal ? showModal.key === ModalKeys.roles : false}
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
          userId={showModal ? showModal.userId : undefined}
        />
      )}

      {hasAction(usersConf.rowActions, 'licenses') && (
        <UserLicensesModal
          show={showModal ? showModal.key === ModalKeys.licenses : false}
          userId={showModal && showModal.userId}
          onClose={onHideModal}
        />
      )}

      {hasAction(usersConf.rowActions, 'downloadLicense') && (
        <DownloadUsersLicenseModal
          show={showModal ? showModal.key === ModalKeys.downloadLicense : false}
          userId={showModal && showModal.userId}
          onClose={onHideModal}
          onShowFeedback={onShowFeedback}
        />
      )}

      {hasAction(usersConf.actions, 'invite') && (
        <CreateInvitationModal
          show={showModal ? showModal.key === ModalKeys.invite : false}
          onClose={onHideModal}
          onShowFeedback={onShowFeedback}
        />
      )}
    </Page>
  );
}

export default Users;
