import { OrganizationGroupInvitation } from "../../../model/OrganizationGroupInvitation";
import { ORG_1_GROUPS } from "./org1Groups";
import { APP_ORG_1 } from "./appOrganizations";

/*
 * Invitations of mock org 1, "Acme Inc".
 */
/*
invitationState?:
    | "accepted"
    | "created"
    | "declined"
    | "deliveryRequested"
    | "revoked";
*/
const ORG_1_INV_1: OrganizationGroupInvitation = {
  validFrom: "now()",
  organizationRoleIds: [],
  groupIds: [ORG_1_GROUPS[0].id as string],
  organizationId: APP_ORG_1.id as string,
  clientRoleIds: [],
  email: "invitee1@org.test",
  invitationState: "deliveryRequested",
  memberWelcomeUrl: "I wonder what to put here",
  invitationCompletedUrl: "or here",
  recipientName: "Ralph Recipient",
  inviterName: "Ian Inviter",
  id: "145b364b-f89f-4713-8a21-00538e0e3720",
  authorId: "6c3dd9a9-cdcf-4df6-afaf-29f43b005644",
  created: "2020-04-09T08:00:00.000Z",
  // clientData?: string;
  // claimedAt?: string;
  // declinedAt?: string;
  // revokedAt?: string;
  // recipientCountryCode?: string;
  // recipientLanguageCode?: string;
  // invitationScopeInformation?: string;
};

const ORG_1_INV_2: OrganizationGroupInvitation = {
  validFrom: "now()",
  organizationRoleIds: [],
  groupIds: [ORG_1_GROUPS[0].id as string],
  organizationId: APP_ORG_1.id as string,
  clientRoleIds: [],
  email: "invitee2@org.test",
  invitationState: "created",
  memberWelcomeUrl: "I wonder what to put here",
  invitationCompletedUrl: "or here",
  recipientName: "Rhonda Recipient",
  inviterName: "Ian Inviter",
  id: "20e51396-2282-4d1c-b426-50750fe7e0de",
  authorId: "6c3dd9a9-cdcf-4df6-afaf-29f43b005644",
  created: "2020-04-09T08:00:00.000Z",
};
const ORG_1_INV_3: OrganizationGroupInvitation = {
  validFrom: "now()",
  organizationRoleIds: [],
  groupIds: [ORG_1_GROUPS[0].id as string],
  organizationId: APP_ORG_1.id as string,
  clientRoleIds: [],
  email: "invitee3@org.test",
  invitationState: "declined",
  memberWelcomeUrl: "I wonder what to put here",
  invitationCompletedUrl: "or here",
  recipientName: "Ralph Recipient",
  inviterName: "Ian Inviter",
  id: "1801d7e8-4836-44d5-90c3-a61466670c2e",
  authorId: "6c3dd9a9-cdcf-4df6-afaf-29f43b005644",
  created: "2020-04-09T08:00:00.000Z",
  // clientData?: string;
  // claimedAt?: string;
  // declinedAt?: string;
  // revokedAt?: string;
  // recipientCountryCode?: string;
  // recipientLanguageCode?: string;
  // invitationScopeInformation?: string;
};
const ORG_1_INV_4: OrganizationGroupInvitation = {
  validFrom: "now()",
  organizationRoleIds: [],
  groupIds: [ORG_1_GROUPS[0].id as string],
  organizationId: APP_ORG_1.id as string,
  clientRoleIds: [],
  email: "invitee4@org.test",
  invitationState: "revoked",
  memberWelcomeUrl: "I wonder what to put here",
  invitationCompletedUrl: "or here",
  recipientName: "Ralph Recipient",
  inviterName: "Ian Inviter",
  id: "d2b79ba7-3bed-43b6-91c4-6be1bc1d7b35",
  authorId: "6c3dd9a9-cdcf-4df6-afaf-29f43b005644",
  created: "2020-04-09T08:00:00.000Z",
  // clientData?: string;
  // claimedAt?: string;
  // declinedAt?: string;
  // revokedAt?: string;
  // recipientCountryCode?: string;
  // recipientLanguageCode?: string;
  // invitationScopeInformation?: string;
};
const ORG_1_INV_5: OrganizationGroupInvitation = {
  validFrom: "now()",
  organizationRoleIds: [],
  groupIds: [ORG_1_GROUPS[0].id as string],
  organizationId: APP_ORG_1.id as string,
  clientRoleIds: [],
  email: "invitee5@org.test",
  invitationState: "accepted",
  memberWelcomeUrl: "I wonder what to put here",
  invitationCompletedUrl: "or here",
  recipientName: "Ralph Recipient",
  inviterName: "Ian Inviter",
  id: "43212266-c430-4ab1-8850-82dc5f2c50e3",
  authorId: "6c3dd9a9-cdcf-4df6-afaf-29f43b005644",
  created: "2020-04-09T08:00:00.000Z",
  // clientData?: string;
  // claimedAt?: string;
  // declinedAt?: string;
  // revokedAt?: string;
  // recipientCountryCode?: string;
  // recipientLanguageCode?: string;
  // invitationScopeInformation?: string;
};

export const ORG_1_INVITATIONS: OrganizationGroupInvitation[] = [
  ORG_1_INV_1,
  ORG_1_INV_2,
  ORG_1_INV_3,
  ORG_1_INV_4,
  ORG_1_INV_5,
];
