import { EntApi } from "../EntApi";
import { LicenseFieldOpts } from "../../model/entitlement/LicenseFieldOpts";
import { QueryAvailableLicensesOpts } from "../../model/entitlement/QueryAvailableLicensesOpts";
import { LicenseWithOwnerAndSingleUserAssignments } from "../../model/entitlement/LicenseWithOwnerAndSingleUserAssignments";
import { LicenseWithCredits } from "../../model/entitlement/LicenseWithCredits";
import { LicenseUsage } from "../../model/entitlement/LicenseUsage";
import { LicenseAssignment } from "../../model/entitlement/LicenseAssignment";
import { ProductItem } from "../../model/entitlement/ProductItem";
import { LicenseOrder } from "../../model/entitlement/LicenseOrder";
import { OrganizationGroup } from "../../model/OrganizationGroup";
import * as EntClient from "../../gen/api/entitlement/entitlement-client";
import { handleApiCall } from "../api-util/ApiResponseUtil";
import { Entitlement } from "../../model/entitlement/Entitlement";
import {ClientGroup} from "../../model/ClientGroup";

class RealEnt implements EntApi {
  initialize(initProvider: (name: string) => string): void {
    EntClient.defaults.baseUrl = initProvider("entApiBase");
    EntClient.defaults.headers = EntClient.defaults.headers || {};
    (EntClient.defaults.headers as any)["Authorization"] =
      "Bearer " + initProvider("accessToken");
    EntClient.defaults.cache = "no-cache";
  }

  getApiInfo(): Promise<{ version: string; [key: string]: any }> {
    return new Promise((resolve, reject) => {
      fetch(EntClient.defaults.baseUrl + "/openapi?info")
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(
              `Loading api information failed: ${response.status} (${response.statusText})`
            );
          }
        })
        .then((response) => {
          resolve(response);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getProductItems(productId: string): Promise<ProductItem[]> {
    throw new Error("Method not implemented.");
  }
  fulfillLicenseOrder(
    orgId: string,
    licenseOrder: LicenseOrder
  ): Promise<LicenseOrder> {
    throw new Error("Method not implemented.");
  }
  listEntitlementsConsumedByCliGroup(orgId: string, groupId: string): Promise<Entitlement[]> {
    return handleApiCall(EntClient.listEntitlementsConsumedByCliGroup(orgId, groupId));
  }
  listEntitlementConsumingUsers(orgId: string, entId: string): Promise<string[]> {
    return handleApiCall(EntClient.listEntitlementConsumingUsers(orgId, entId));
  }
  listEntitlementConsumingClients(orgId: string, entId: string): Promise<string[]> {
    return handleApiCall(EntClient.listEntitlementConsumingClients(orgId, entId));
  }
  changeLicenseOrder(
    orgId: string,
    licenseOrder: LicenseOrder
  ): Promise<LicenseOrder> {
    throw new Error("Method not implemented.");
  }
  cancelLicenseOrder(orgId: string, orderId: string): Promise<LicenseOrder> {
    throw new Error("Method not implemented.");
  }
  listLicenseConsumingOrgGroups(
      orgId: string,
      entId: string
  ): Promise<OrganizationGroup[]> {
    return handleApiCall(EntClient.listLicenseConsumingOrgGroups(orgId, entId));
  }

  listLicenseConsumingCliGroups(
      orgId: string,
      entId: string
  ): Promise<ClientGroup[]> {
    return handleApiCall(EntClient.listLicenseConsumingCliGroups(orgId, entId));
  }

  async setLicenseConsumingOrgGroups(
    orgId: string,
    entId: string,
    groupIds: string[]
  ): Promise<void> {
    await handleApiCall(
      EntClient.setLicenseConsumingOrgGroups(orgId, entId, groupIds)
    );
  }
  async addLicenseConsumingOrgGroup(
    orgId: string,
    entId: string,
    groupId: string
  ): Promise<void> {
    await handleApiCall(
      EntClient.addLicenseConsumingOrgGroup(orgId, entId, groupId)
    );
  }
  async removeLicenseConsumingOrgGroup(
    orgId: string,
    entId: string,
    groupId: string
  ): Promise<void> {
    await handleApiCall(
      EntClient.removeLicenseConsumingOrgGroup(orgId, entId, groupId)
    );
  }
  listEntitlementLicenses(
    orgId: string,
    entId: string,
    licenseFieldOpts?: LicenseFieldOpts
  ): Promise<LicenseWithCredits[]> {
    return handleApiCall(
      EntClient.listEntitlementLicenses(orgId, entId, licenseFieldOpts)
    );
  }
  queryLicenseUsage(
    orgId: string,
    entId: string,
    licenseId: string
  ): Promise<LicenseUsage> {
    return handleApiCall(EntClient.queryLicenseUsage(orgId, entId, licenseId));
  }
  getLicenseAssignments(
    orgId: string,
    entId: string,
    licenseId: string,
    userId: string
  ): Promise<LicenseAssignment[]> {
    return handleApiCall(
      EntClient.getLicenseAssignments(orgId, entId, licenseId, userId)
    );
  }
  manageLicenseAssignments(
    orgId: string,
    entId: string,
    licenseId: string,
    userId: string,
    licenseAssignments: LicenseAssignment[]
  ): Promise<LicenseAssignment[]> {
    return handleApiCall(
      EntClient.manageLicenseAssignments(
        orgId,
        entId,
        licenseId,
        userId,
        licenseAssignments
      )
    );
  }

  manageClientLicenseAssignments(
      orgId: string,
      entId: string,
      licenseId: string,
      clientId: string,
      licenseAssignments: LicenseAssignment[]
  ): Promise<LicenseAssignment[]> {
    return handleApiCall(
        EntClient.manageClientLicenseAssignments(
            orgId,
            entId,
            licenseId,
            clientId,
            licenseAssignments
        )
    );
  }
  queryAvailableLicenses(
      userId: string,
      queryAvailableLicensesOpts?: QueryAvailableLicensesOpts
  ): Promise<LicenseWithOwnerAndSingleUserAssignments[]> {
    return handleApiCall(
        EntClient.queryAvailableLicenses(userId, queryAvailableLicensesOpts)
    );
  }
  queryClientAvailableLicenses(
      clientId: string,
      queryAvailableLicensesOpts?: QueryAvailableLicensesOpts
  ): Promise<LicenseWithOwnerAndSingleUserAssignments[]> {
    return handleApiCall(
        EntClient.queryClientAvailableLicenses(clientId, queryAvailableLicensesOpts)
    );
  }

  moveLicense(
    orgId: string,
    entId: string,
    licenseId: string,
    destEntId: string,
    licenseFieldOpts?: LicenseFieldOpts
  ): Promise<LicenseWithCredits> {
    throw new Error("Method not implemented.");
  }
  listOrganizationEntitlements(orgId: string): Promise<Entitlement[]> {
    return handleApiCall(EntClient.listOrganizationEntitlements(orgId));
  }
  getOrganizationEntitlement(
    orgId: string,
    entId: string
  ): Promise<Entitlement> {
    return handleApiCall(EntClient.getOrganizationEntitlement(orgId, entId));
  }
  listEntitlementsConsumedByOrgGroup(
    orgId: string,
    groupId: string
  ): Promise<Entitlement[]> {
    return handleApiCall(
      EntClient.listEntitlementsConsumedByOrgGroup(orgId, groupId)
    );
  }
  async setEntitlementsConsumedByOrgGroup(
      orgId: string,
      groupId: string,
      entitlementsIds: string[]
  ): Promise<void> {
    await handleApiCall(
        EntClient.setEntitlementsConsumedByOrgGroup(
            orgId,
            groupId,
            entitlementsIds
        )
    );
  }
  async setEntitlementsConsumedByCliGroup(
      orgId: string,
      groupId: string,
      entitlementsIds: string[]
  ): Promise<void> {
    await handleApiCall(
        EntClient.setEntitlementsConsumedByCliGroup(
            orgId,
            groupId,
            entitlementsIds
        )
    );
  }

  queryClientLicenseUsage(clientId: string, { includeEffectiveModel, includeAggregatedLicensedItems }: {
    includeEffectiveModel?: boolean;
    includeAggregatedLicensedItems?: boolean;
  }): Promise<LicenseWithOwnerAndSingleUserAssignments[]> {
    return handleApiCall(EntClient.queryClientLicenseUsage(clientId, { includeEffectiveModel, includeAggregatedLicensedItems }));
  }
}

export default RealEnt;
