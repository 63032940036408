import * as ActionTypes from "../actions/actionTypes";
import { UserOrgGroupIdsByUserId } from "../store/UserState";
import {
  removeFromStateById,
  removeUsersFromState,
} from "../util/ReducerUtils";

export default function userOrgGroupIds(
  state: UserOrgGroupIdsByUserId,
  action: ActionTypes.AppAction
): UserOrgGroupIdsByUserId | null {
  const currentState = state || ({} as UserOrgGroupIdsByUserId);
  switch (action.type) {
    case ActionTypes.LIST_ORG_GROUPS_OF_USER:
      const listOrgGroupsOfUser =
        action as ActionTypes.ListOrgGroupsOfUserAction;
      const orgGroupIds = listOrgGroupsOfUser.groups.map(
        (group) => group.id as string
      );
      return { ...currentState, [listOrgGroupsOfUser.userId]: orgGroupIds };
    case ActionTypes.ADD_ORG_GROUPS_FOR_USER: {
      const addOrgGroupsForUser =
        action as ActionTypes.AddOrgGroupsForUserAction;
      const beforeAddGroups = (
        currentState[addOrgGroupsForUser.userId] || []
      ).filter((groupId) => !addOrgGroupsForUser.orgGroupIds.includes(groupId));
      const afterAddGroups = [
        ...beforeAddGroups,
        ...addOrgGroupsForUser.orgGroupIds,
      ];
      return { ...currentState, [addOrgGroupsForUser.userId]: afterAddGroups };
    }
    case ActionTypes.SET_ORG_GROUPS_OF_USER: {
      const setOrgGroupsForUser =
        action as ActionTypes.SetOrgGroupsOfUserAction;
      const afterSet = [...setOrgGroupsForUser.orgGroupIds];
      return removeUsersFromState<UserOrgGroupIdsByUserId>(action, {
        ...currentState,
        [setOrgGroupsForUser.userId]: afterSet,
      });
    }
    case ActionTypes.IMPORT_ORGANIZATION_USERS: {
      const importOrganizationUsersAction =
        action as ActionTypes.ImportOrganizationUsersAction;
      const nextState = {
        ...currentState,
      };

      (importOrganizationUsersAction.users || []).forEach((user) => {
        nextState[user.id as string] = [...(user.groupIds || [])];
      });

      return nextState;
    }
    case ActionTypes.REMOVE_ORG_GROUPS_OF_USER: {
      const removeOrgGroupsOfUser =
        action as ActionTypes.RemoveOrgGroupsOfUserAction;
      const afterRemove = (
        currentState[removeOrgGroupsOfUser.userId] || []
      ).filter(
        (groupId) => !removeOrgGroupsOfUser.orgGroupIds.includes(groupId)
      );
      return removeUsersFromState<UserOrgGroupIdsByUserId>(action, {
        ...currentState,
        [removeOrgGroupsOfUser.userId]: afterRemove,
      });
    }
    case ActionTypes.ADD_USERS_TO_ORG_GROUP:
      const addUsersToOrgGroup = action as ActionTypes.AddUsersToOrgGroupAction;
      let afterAddUsersToOrgGroup = currentState;
      for (const userIdToAdd of addUsersToOrgGroup.userIds) {
        const beforeAddUsers = afterAddUsersToOrgGroup[userIdToAdd] || [];
        const afterAddUsers = [
          ...beforeAddUsers,
          addUsersToOrgGroup.orgGroupId,
        ];
        afterAddUsersToOrgGroup = {
          ...afterAddUsersToOrgGroup,
          [userIdToAdd]: afterAddUsers,
        };
      }
      return afterAddUsersToOrgGroup;
    case ActionTypes.REMOVE_USERS_FROM_ORG_GROUP:
      const removeUsersFromOrgGroup =
        action as ActionTypes.RemoveUsersFromOrgGroupAction;
      let afterRemoveUsersFromOrgGroup = currentState;
      for (const userIdToRemove of removeUsersFromOrgGroup.userIds) {
        const beforeRemoveUsers =
          afterRemoveUsersFromOrgGroup[userIdToRemove] || [];
        const afterRemoveUsers = beforeRemoveUsers.filter(
          (orgGroupId) => orgGroupId !== removeUsersFromOrgGroup.orgGroupId
        );
        afterRemoveUsersFromOrgGroup = {
          ...afterRemoveUsersFromOrgGroup,
          [userIdToRemove]: afterRemoveUsers,
        };
      }
      return removeUsersFromState<UserOrgGroupIdsByUserId>(
        action,
        afterRemoveUsersFromOrgGroup
      );

    case ActionTypes.ADD_ORG_GROUP_FOR_USER:
      const addOrgGroupForUser = action as ActionTypes.AddOrgGroupForUserAction;
      const beforeAddGroup = currentState[addOrgGroupForUser.userId] || [];
      const afterAddGroup = [...beforeAddGroup, addOrgGroupForUser.orgGroupId];
      return { ...currentState, [addOrgGroupForUser.userId]: afterAddGroup };
    case ActionTypes.REMOVE_ORG_GROUP_OF_USER:
      const removeOrgGroupOfUser =
        action as ActionTypes.RemoveOrgGroupOfUserAction;
      const beforeRemoveGroup = currentState[removeOrgGroupOfUser.userId] || [];
      const afterRemoveGroup = beforeRemoveGroup.filter(
        (orgGroupId) => removeOrgGroupOfUser.orgGroupId !== orgGroupId
      );
      return removeUsersFromState(action, {
        ...currentState,
        [removeOrgGroupOfUser.userId]: afterRemoveGroup,
      });

    case ActionTypes.REMOVE_USER_FROM_ORG:
    case ActionTypes.DELETE_USER:
    case ActionTypes.SET_USERS_IN_ORG_GROUP: {
      return removeUsersFromState<UserOrgGroupIdsByUserId>(
        action,
        currentState
      );
    }
    case ActionTypes.DELETE_ORG_GROUP: {
      const stateAfterModification: UserOrgGroupIdsByUserId =
        removeUsersFromState<UserOrgGroupIdsByUserId>(action, currentState);

      const deleteOrgGroup = action as ActionTypes.DeleteOrgGroupAction;
      for (const key in stateAfterModification) {
        let usersGroupIds = stateAfterModification[key] || [];
        usersGroupIds = usersGroupIds.filter(
          (groupId) => groupId !== deleteOrgGroup.orgGroupId
        );
        stateAfterModification[key] = usersGroupIds;
      }
      return stateAfterModification;
    }
    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

function handleErrorAction(
  currentState: UserOrgGroupIdsByUserId,
  action: ActionTypes.AppAction
): UserOrgGroupIdsByUserId {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  if (
    errorAction.error.action.type === ActionTypes.GET_USER &&
    errorAction.error.apiError.error === "404"
  ) {
    const typedError =
      action as ActionTypes.AddErrorAction<ActionTypes.GetUserAction>;
    finalState = removeFromStateById(
      [typedError.error.action?.userId as string],
      currentState
    );
  }

  return finalState;
}
