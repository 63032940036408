import { defineMessages } from "react-intl";
import { BaseObjLabels } from "../base-obj";

/**
 * Localized labels for ClientGroup fields
 */
export const ClientLabels = {
  ...BaseObjLabels,
  // Add AuthorEditorFields if needed
  ...defineMessages({
    // organizationId <- not used, ignoring
    name: {
      defaultMessage: "name",
      description:
        "Field label for device client name (Client.name)"
    },
    clientId: {
      defaultMessage: "clientId",
      description:
        "Field label for device client id (Client.id)"
    },
  })
};


export const ClientValues = {
};
