import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./no-organization-to-manage-view.scss";
import { IconLibrary, Page, PageDOMProps } from "@10duke/dukeui";
export interface NoOrganizationToManageDOMProps extends PageDOMProps {}
export interface NoOrganizationToManageProps
  extends NoOrganizationToManageDOMProps {}
function NoOrganizationToManage(props: NoOrganizationToManageProps) {
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: "Not authorized",
    description: "heading for the page",
  });
  const description = intl.formatMessage({
    defaultMessage: "Your account is not authorized.",
    description: "window meta description for the screen",
  });
  return (
    <Page
      id={"no-organization"}
      data-test-no-organization-to-manage
      header={
        <h1>
          <FontAwesomeIcon icon={IconLibrary.icons.faExclamationTriangle} className="icon" />
          {title}
        </h1>
      }
      meta={{
        title,
        description,
      }}
      {...props}
    >
      <p>
        <FormattedMessage
          defaultMessage="Your account is not authorized to manage an organization."
          description={"message for no organizations to manage"}
        />
      </p>
    </Page>
  );
}
export default NoOrganizationToManage;
