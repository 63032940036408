import { FormattedMessage, useIntl } from "react-intl";
import { ListGroup } from "react-bootstrap";
import { TooltipWrapper, Page, PageDOMProps } from "@10duke/dukeui";
import { Organization } from "../../../model/Organization";

//<editor-fold desc="Props">

export interface SelectOrganizationDOMProps extends PageDOMProps {}

export interface SelectOrganizationProps extends SelectOrganizationDOMProps {
  selectedOrganization?: Organization | null;
  organizations: Organization[];
  onSelectOrganization?: (orgId: string) => void;
  disableOrganizationSelect?: boolean;
}
//</editor-fold>

function SelectOrganization(props: SelectOrganizationProps) {
  //<editor-fold desc="Local variables">
  const {
    selectedOrganization,
    organizations,
    onSelectOrganization,
    disableOrganizationSelect,
    ...other
  } = props;
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: "Select Organization",
    description: "Heading for the Select Organization page",
  });
  const description = intl.formatMessage({
    defaultMessage: "Select Organization page",
    description: "window meta description for the Select Organization page",
  });
  const selectHandler = (e: any) => {
    if (onSelectOrganization) {
      onSelectOrganization(e.currentTarget.dataset.key);
    }
  };
  //</editor-fold>

  //<editor-fold desc="Partials">
  const orgList = organizations
    ? organizations.map((org) => {
        return (
          <ListGroup.Item
            key={org.id}
            data-test-select-organization={org.id}
            action
            disabled={disableOrganizationSelect}
            onClick={selectHandler}
            data-key={org.id}
            active={
              selectedOrganization ? selectedOrganization.id === org.id : false
            }
          >
            {org.name}
          </ListGroup.Item>
        );
      })
    : null;
  //</editor-fold>

  return (
    <Page
      data-test-select-organization-page
      header={<h1>{title}</h1>}
      meta={{
        title,
        description,
      }}
      {...other}
    >
      {orgList && (
        <TooltipWrapper
          tipKey={"organizationSelectDisabledTip"}
          tip={
            disableOrganizationSelect ? (
              <FormattedMessage
                defaultMessage="Selecting organization is disabled until all pending actions are completed."
                description="tooltip to be shown when the tool is disabled due to ongoing api call"
              />
            ) : undefined
          }
        >
          <ListGroup>{orgList}</ListGroup>
        </TooltipWrapper>
      )}
      {!orgList && (
        <p>
          <FormattedMessage
            defaultMessage="You have no organizations to manage."
            description="Message to be shown when the user has no organizations to manage."
          />
        </p>
      )}
    </Page>
  );
}
export default SelectOrganization;
