import * as ActionTypes from "../actions/actionTypes";
import { OrgGroupIdsByOrgId } from "../store/OrganizationGroupState";

export default function orgOrgGroupIds(
  state: OrgGroupIdsByOrgId,
  action: ActionTypes.AppAction
): OrgGroupIdsByOrgId | null {
  const currentState = state || ({} as OrgGroupIdsByOrgId);
  switch (action.type) {
    case ActionTypes.LIST_ORG_GROUPS:
      const listOrgGroups = action as ActionTypes.ListOrgGroupsAction;
      const newOrgGroupIds = listOrgGroups.groups.map(
        group => group.id as string
      );
      return { ...currentState, [listOrgGroups.orgId]: newOrgGroupIds };
    case ActionTypes.CREATE_ORG_GROUP:
      const createOrgGroup = action as ActionTypes.CreateOrgGroupAction;
      const beforeCreate = currentState[createOrgGroup.orgId] || [];
      const afterCreate = [...beforeCreate, createOrgGroup.group.id as string];
      return {
        ...currentState,
        [createOrgGroup.orgId]: afterCreate
      };
    case ActionTypes.DELETE_ORG_GROUP:
      const delOrgGroup = action as ActionTypes.DeleteOrgGroupAction;
      return deleteOrgGroup(
        delOrgGroup.orgId,
        delOrgGroup.orgGroupId,
        currentState
      );
    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

function deleteOrgGroup(
  orgId: string,
  orgGroupId: string,
  currentState: OrgGroupIdsByOrgId
): OrgGroupIdsByOrgId {
  const beforeDelete = currentState[orgId] || [];
  const afterDelete = beforeDelete.filter(groupId => groupId !== orgGroupId);
  return {
    ...currentState,
    [orgId]: afterDelete
  };
}

function handleErrorAction(
  currentState: OrgGroupIdsByOrgId,
  action: ActionTypes.AppAction
): OrgGroupIdsByOrgId {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  if (
    errorAction.error.apiError.error === "404" &&
    errorAction.error.action.type === ActionTypes.GET_ORG_GROUP
  ) {
    const typedError = action as ActionTypes.AddErrorAction<
      ActionTypes.GetOrgGroupAction
    >;
    finalState = deleteOrgGroup(
      typedError.error.action?.orgId as string,
      typedError.error.action?.orgGroupId as string,
      currentState
    );
  }

  return finalState;
}
