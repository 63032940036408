import { connect } from "react-redux";
import { AppState } from "../../../store/AppState";
import OrganizationUtils from "../../../utils/organization";
import {
  EditOrganizationModalDataProps,
  EditOrganizationModalProps as _EditOrganizationModalProps,
} from "./edit-organization-modal-view";
import EditOrganizationModal from "./edit-organization-modal-container";

import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import { clearError, loadOrgs, replaceOrg } from "../../../actions";
import InProgressUtils from "../../../utils/in-progress";
import { addSenderArgument } from "../../../actions/actionHelpers";
import { ActionSender } from "../../../model/ActionSender";

export type EditOrganizationModalProps = Omit<
  _EditOrganizationModalProps,
  keyof EditOrganizationModalDataProps
>;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _EditOrganizationModalProps,
  EditOrganizationModalProps
>;

// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _EditOrganizationModalProps,
  EditOrganizationModalProps
>;

const sender: ActionSender = { key: "edit-organization" };

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    organization: OrganizationUtils.selectSelectedOrganization(state),
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    OrganizationUtils.compareSelectedOrganizationStates(next, prev) &&
    InProgressUtils.compareInProgressStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onUpdateOrganization: addSenderArgument(sender, replaceOrg),
  onLoadOrganization: addSenderArgument(sender, loadOrgs),
  onClearError: clearError,
};
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  EditOrganizationModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(EditOrganizationModal);
