import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {
  buildActionThunk,
  ensureSelectedOrgId,
  forceUndefined,
} from "./actionHelpers";
import { Client } from "../model/Client";



export function replaceOrganizationClient(
    sender: ActionSender,
    client: Client,
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ReplaceClientAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ReplaceClientAction, Client>(
      sender,
      ActionTypes.REPLACE_CLIENT,
      async () => await idpApi.replaceOrganizationClient(orgIdOrDefault, client),
      (type, cli) => ({
        type,
        client: forceUndefined(cli),
        orgId: orgIdOrDefault
      })
  );
}
export function deleteOrganizationClient(
    sender: ActionSender,
    clientId: string,
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.DeleteOrgClientAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.DeleteOrgClientAction, void>(
      sender,
      ActionTypes.DELETE_ORG_CLIENT,
      async () => await idpApi.deleteOrganizationClient(orgIdOrDefault, clientId),
      (type) => ({
        type,
        clientId: clientId,
        orgId: orgIdOrDefault
      })
  );
}
export function listOrganizationClients(
  sender: ActionSender,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListOrgClientsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ListOrgClientsAction, Client[]>(
    sender,
    ActionTypes.LIST_ORG_CLIENTS,
    async () => await idpApi.listOrganizationClients(orgIdOrDefault),
    (type, groups) => ({
      type,
      clients: forceUndefined(groups),
      orgId: orgIdOrDefault,
    })
  );
}
export function listClientsInOrganizationClientGroup(
    sender: ActionSender,
    groupId: string,
    orgId?: string,
): ActionTypes.AppThunkAction<ActionTypes.ListOrgClientsInOrgClientGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ListOrgClientsInOrgClientGroupAction, Client[]>(
      sender,
      ActionTypes.LIST_ORG_CLIENTS_IN_ORG_CLIENT_GROUP,
      async () => await idpApi.listClientsInOrganizationClientGroup(orgIdOrDefault, groupId),
      (type, clients) => ({
        type,
        groupId,
        clients: forceUndefined(clients),
        orgId: orgIdOrDefault,
      })
  );
}
export function getClient(
    sender: ActionSender,
    clientId: string,
    orgId?:string
): ActionTypes.AppThunkAction<ActionTypes.GetOrgClientAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.GetOrgClientAction, Client>(
      sender,
      ActionTypes.GET_ORG_CLIENT,
      async () => await idpApi.getClient(clientId),
      (type, client) => ({
        type,
        client: forceUndefined(client),
        clientId: clientId,
        orgId:orgIdOrDefault,
      })
  );
}

export function addClientsToOrganizationClientGroup(
    sender: ActionSender,
    clientGroupId: string,
    clientIds: string[],
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.AddClientsToClientGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.AddClientsToClientGroupAction, void>(
      sender,
      ActionTypes.ADD_CLIENTS_TO_CLIENT_GROUP,
      async () =>
          await idpApi.addClientsToOrganizationClientGroup(
              orgIdOrDefault,
              clientGroupId,
              clientIds
          ),
      (type) => ({
        type,
        clientGroupId,
        clientIds,
        orgId: orgIdOrDefault,
      })
  );
}

export function removeClientsFromOrganizationClientGroup(
    sender: ActionSender,
    clientGroupId: string,
    clientIds: string[],
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.RemoveClientsFromClientGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.RemoveClientsFromClientGroupAction, void>(
      sender,
      ActionTypes.REMOVE_CLIENTS_FROM_CLIENT_GROUP,
      async () =>
          await idpApi.removeClientsFromOrganizationClientGroup(
              orgIdOrDefault,
              clientGroupId,
              clientIds
          ),
      (type) => ({
        type,
        clientGroupId,
        clientIds,
        orgId: orgIdOrDefault,
      })
  );
}
