import { connect } from "react-redux";
import DeviceClients, {
  DeviceClientsDOMProps,
  DeviceClientsProps as _DeviceClientsProps,
  DeviceClientsModalVisibilityProps,
} from "./device-clients-container";
import { AppState } from "../../../store/AppState";
import OrganizationUtils from "../../../utils/organization";

import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";
import {listOrganizationClients} from "../../../actions";

export { ModalKeys } from "./device-clients-container";

export interface DeviceClientsProps extends DeviceClientsDOMProps, DeviceClientsModalVisibilityProps {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_DeviceClientsProps, DeviceClientsProps>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<_DeviceClientsProps, DeviceClientsProps>;

const sender: ActionSender = { key: "device-clients" };

function mapStateToProps(state: AppState): ReduxStateProps {

  return {
    clients: OrganizationUtils.selectOrganizationClients(state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    OrganizationUtils.compareOrganizationClientsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadClients: addSenderArgument(sender, listOrganizationClients),
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  DeviceClientsProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(DeviceClients);
