import { useEffect, useRef, useState } from "react";
import View, {
  LicenseUsageModalStateProps,
  LicenseUsageModalProps as _LicenseUsageModalProps,
} from "./license-usage-modal-view";
import { UserWithLicenseUsage } from "../../../model/User";
import { resolveFreeSeatsForLicense } from "../../../util/licenseUtil";
import _ from "lodash";
import { withCloseAfterExited } from "@10duke/dukeui";
import {LicenseAssignmentWithSessions} from "../../../model/entitlement/LicenseAssignmentWithSessions";
import {ClientWithLicenseUsage} from "../../../model/Client";

const ViewWithCloseAfterExited =
  withCloseAfterExited<_LicenseUsageModalProps>(View);

export type LicenseUsageModalProps = Omit<
  _LicenseUsageModalProps,
  keyof LicenseUsageModalStateProps
>;
export default function LicenseUsageModal(props: LicenseUsageModalProps) {
  const { license, show, users, ...other } = props;

  const [availableSeats, onSetAvailableSeats] = useState(
    license ? resolveFreeSeatsForLicense(license) : 0
  );

  const usersRef = useRef(users);
  if (!_.isEqual(usersRef.current, users)) {
    usersRef.current = users;
  }
  const usersRefCurrent = usersRef.current;

  /**
   * List of users with unapplied changes
   */
  const [toUpdate, onSetToUpdate] = useState<any[]>([]);

  const freeSeats = license ? resolveFreeSeatsForLicense(license) : undefined;
  useEffect(() => {
    if (show) {
      onSetAvailableSeats(freeSeats);
    } else {
      onSetAvailableSeats(0);
    }
  }, [show, freeSeats, onSetAvailableSeats]);

  const [activeSearch, onSetActiveSearch] = useState("");
  useEffect(() => {
    onSetActiveSearch("");
  }, [license, onSetActiveSearch]);
  /**
   * List of users including unapplied reservation/denial state
   */
  const [moddedUsers, onSetModdedUsers] = useState<
      (UserWithLicenseUsage|ClientWithLicenseUsage)[] | undefined | null
  >(users ? ([] as (UserWithLicenseUsage|ClientWithLicenseUsage)[]).concat(users) : users);

  const [showState, setShowState] = useState<boolean>(
    show && moddedUsers !== null && moddedUsers !== undefined
  );

  const [releaseLeases, onReleaseLeases] = useState<{ id: string, assignment: LicenseAssignmentWithSessions}[]>([]);
  const onAddLeaseToBeReleased = (release: {id:string, assignment: LicenseAssignmentWithSessions}) => {
    onReleaseLeases((prev) => {
      return [...prev]
          .concat(release)
          // remove duplicates leaving only the first instance
          .filter((f, i, r) => i === r.findIndex((fi) => fi.id === f.id));
    })
  };
  const onRemoveLeaseToBeReleased = (release: {id:string, assignment: LicenseAssignmentWithSessions}) => {
    onReleaseLeases((prev) => {
      return prev.filter((f) => f.id !== release.id);
    })
  };
  useEffect(() => {
    if (!show) {
      onReleaseLeases([]);
    }
  }, [show]);

  const hasUsers = !!users;
  const hasModdedUsers = !!moddedUsers;
  useEffect(() => {
    if (show && hasUsers && !hasModdedUsers) {
      // delay the show prop until moddedUsers state has been updated to avoid triggering unnecessary load
      setShowState(false);
    } else {
      setShowState(show);
    }
  }, [show, hasUsers, hasModdedUsers]);
  useEffect(() => {
    if (show && usersRefCurrent) {
      onSetModdedUsers(([] as (UserWithLicenseUsage|ClientWithLicenseUsage)[]).concat(usersRefCurrent));
      onSetToUpdate([]);
    }
    if (!show) {
      onSetActiveSearch("");
      onSetModdedUsers(
        usersRefCurrent
          ? ([] as (UserWithLicenseUsage|ClientWithLicenseUsage)[]).concat(usersRefCurrent)
          : usersRefCurrent
      );
      onSetAvailableSeats(0);
      onSetToUpdate([]);
    }
  }, [
    show,
    usersRefCurrent,
    onSetModdedUsers,
    onSetToUpdate,
    onSetActiveSearch,
    onSetAvailableSeats,
  ]);
  const handleStateUpdate = (
    moddedUser: (UserWithLicenseUsage|ClientWithLicenseUsage),
    toUpdateUser: any
  ) => {
    // Update modded user state
    onSetModdedUsers((cusrs) => {
      return cusrs
        ? cusrs.map((v) => (v.id === moddedUser.id ? moddedUser : v))
        : cusrs;
    });
    // Update change tracking state
    onSetToUpdate((ctu) => {
      // add the modified item to the list of changes
      let found = false;
      let retVal = ctu
        ? ctu.map((v) => {
            if (v.id === toUpdateUser.id) {
              found = true;
              return toUpdateUser;
            } else {
              return v;
            }
          })
        : ctu;
      if (!found) {
        retVal.push(toUpdateUser);
      }
      // filter out items without changes
      retVal = retVal.filter(
        (t) =>
          t.addReservation ||
          t.removeReservation ||
          t.addDenial ||
          t.removeDenial
      );
      // update seat count
      const seats = license ? resolveFreeSeatsForLicense(license) : 0;
      if (seats !== undefined) {
        const newSeats =
            seats +
            // add removed reservation count, unless actively consuming
            retVal.filter(
                (v) =>
                    !v.isConsuming &&
                    (v.removeReservation ||
                        (v.addDenial &&
                            (
                                users?.find(
                                    (u: (UserWithLicenseUsage|ClientWithLicenseUsage)) => u.id === v.id
                                ) as UserWithLicenseUsage
                            ).hasReservation))
            ).length -
            // remove added reservation count, unless actively consuming
            retVal.filter((v) => v.addReservation && !v.isConsuming).length;
        onSetAvailableSeats(newSeats);
      } else {
        onSetAvailableSeats(undefined);
      }
      return retVal;
    });
  };
  const newToggleReservation = (id: string) => {
    const tmp = users ? users.find((val) => val.id === id) : undefined;
    const incomingUser = tmp ? { ...tmp } : undefined;
    if (incomingUser) {
      const tmp2 = moddedUsers
        ? moddedUsers.find((val) => val.id === id)
        : undefined;
      const moddedUser = tmp2 ? { ...tmp2 } : undefined;
      const toUpdateUser: any = tmp2 ? { ...tmp2 } : undefined;
      if (moddedUser) {
        if (incomingUser.hasReservation && moddedUser.hasReservation) {
          // console.log('no previous change, remove reservation');
          moddedUser.hasReservation = false;
          toUpdateUser.hasReservation = false;
          toUpdateUser.removeReservation = true;
        } else if (incomingUser.hasReservation && !moddedUser.hasReservation) {
          // console.log('reservation previously removed, add back');
          moddedUser.hasReservation = true;
          toUpdateUser.hasReservation = true;
          if (moddedUser.hasDenial) {
            // console.log('reservation previously removed ny adding denial, restoring');
            moddedUser.hasDenial = false;
            toUpdateUser.hasDenial = false;
          }
        } else if (!incomingUser.hasReservation && !moddedUser.hasReservation) {
          // console.log('no previous change, add reservation');
          moddedUser.hasReservation = true;
          toUpdateUser.hasReservation = true;
          toUpdateUser.addReservation = true;
          if (moddedUser.hasDenial) {
            // console.log('removing existing denial');
            moddedUser.hasDenial = false;
            toUpdateUser.hasDenial = false;
          }
        } else if (!incomingUser.hasReservation && moddedUser.hasReservation) {
          // console.log('reservation previously added, remove back');
          moddedUser.hasReservation = false;
          toUpdateUser.hasReservation = false;
          if (incomingUser.hasDenial) {
            // console.log('originally denied, add release');
            toUpdateUser.removeDenial = true;
          }
        }
        handleStateUpdate(moddedUser, toUpdateUser);
      }
    }
  };
  const newToggleDenial = (id: string) => {
    const tmp = users ? users.find((val) => val.id === id) : undefined;
    const incomingUser = tmp ? { ...tmp } : undefined;
    if (incomingUser) {
      const tmp2 = moddedUsers
        ? moddedUsers.find((val) => val.id === id)
        : undefined;
      const moddedUser = tmp2 ? { ...tmp2 } : undefined;
      const toUpdateUser: any = tmp2 ? { ...tmp2 } : undefined;
      if (moddedUser) {
        if (incomingUser.hasDenial && moddedUser.hasDenial) {
          // console.log('no previous change, remove denial');
          moddedUser.hasDenial = false;
          toUpdateUser.hasDenial = false;
          toUpdateUser.removeDenial = true;
        } else if (incomingUser.hasDenial && !moddedUser.hasDenial) {
          // console.log('denial previously removed, add back');
          moddedUser.hasDenial = true;
          toUpdateUser.hasDenial = true;
          if (moddedUser.hasReservation) {
            // console.log('denial previously removed by adding reservation, restoring');
            moddedUser.hasReservation = false;
            toUpdateUser.hasReservation = false;
          }
        } else if (!incomingUser.hasDenial && !moddedUser.hasDenial) {
          // console.log('no previous change, add denial');
          moddedUser.hasDenial = true;
          toUpdateUser.hasDenial = true;
          toUpdateUser.addDenial = true;
          if (moddedUser.hasReservation) {
            // console.log('removing existing reservation');
            moddedUser.hasReservation = false;
            toUpdateUser.hasReservation = false;
          }
        } else if (!incomingUser.hasDenial && moddedUser.hasDenial) {
          // console.log('denial previously added, remove back');
          moddedUser.hasDenial = false;
          toUpdateUser.hasDenial = false;
          if (incomingUser.hasReservation) {
            // console.log('originally reserved, add release');
            toUpdateUser.removeReservation = true;
          }
        }
        handleStateUpdate(moddedUser, toUpdateUser);
      }
    }
  };
  // props are immediate, state is not. The view needs both, and needs them to be in sync to avoid unnecessary api
  // calls the hide must therefor be immediate to avoid errors. So we can't use the delayed showState for hiding,
  // though it must be used for showing.
  return (
    <ViewWithCloseAfterExited
      {...other}
      availableSeats={availableSeats}
      users={moddedUsers}
      onToggleReservation={newToggleReservation}
      toUpdate={toUpdate}
      onToggleDenial={newToggleDenial}
      show={show === false ? false : showState}
      license={license}
      activeSearch={activeSearch}
      onSetActiveSearch={onSetActiveSearch}
      releaseLeases={releaseLeases}
      onAddLeaseToBeReleased={onAddLeaseToBeReleased}
      onRemoveLeaseToBeReleased={onRemoveLeaseToBeReleased}
    />
  );
}
