import { useState } from "react";
import ColumnToolsView, {
  ColumnToolsProps as _ColumnToolsProps,
  ColumnToolsVisibilityProps,
} from "./column-tools-view";

type ColumnToolsProps = Omit<
  _ColumnToolsProps,
  keyof ColumnToolsVisibilityProps
>;

export default function ColumnTools(props: ColumnToolsProps) {
  const [showColumnTool, setShowColumnTool] = useState(false);

  return (
    <ColumnToolsView
      {...props}
      show={showColumnTool}
      onHide={() => {
        setShowColumnTool(false);
      }}
      onShow={() => {
        setShowColumnTool(true);
      }}
    />
  );
}
