import { defineMessages } from "react-intl";
import { BaseObjLabels } from "../base-obj";

/**
 * Localized labels for OrganizationRole fields
 */
export const OrganizationRoleLabels = {
  ...BaseObjLabels,
  // Add AuthorEditorFields if needed
  ...defineMessages({
    // organizationId <- not used, ignoring
    name: {
      defaultMessage: "name",
      description:
        "Field label for organization role name (OrganizationRole.name)",
    },
    type: {
      defaultMessage: "type",
      description:
        "Field label for organization role type (OrganizationRole.type)",
    },
    description: {
      defaultMessage: "description",
      description:
        "Field label for organization role description (OrganizationRole.description)",
    },
    permissions: {
      defaultMessage: "included permissions",
      description:
        "Field label for organization role permissions (OrganizationRole.permissions)",
    },
    grantedActions: {
      defaultMessage: "actions",
      description:
        "Field label for organization role permission actions (OrganizationRole.permission.grantedActions)",
    },
    organizationRoles: {
      defaultMessage: "included roles",
      description:
        "Field label for organization role organization roles (OrganizationRole.organizationRoles)",
    },
  }),
};

export const OrganizationRoleValues = {
  name: defineMessages({
    undefined: {
      defaultMessage: "Unknown",
      description:
        "Fallback value for missing organization role name (OrganizationRole.name undefined)",
    },
  }),
};
