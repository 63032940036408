import { defineMessages } from "react-intl";
import { BaseObjLabels } from "../base-obj";

/**
 * Localized labels for "UI Model" LicenseModel fields
 */
export const LicenseModelLabels = {
  ...BaseObjLabels,
  // Add AuthorEditorFields if needed
  ...defineMessages({
    modelType: {
      defaultMessage: "model",
      description:
        "Field label for the type of the license model (LicenseModel.modelType)"
    }
  })
};

/**
 * Localized values for LicenseModel fields
 */
export const LicenseModelValues = {
  // no extension needed for now
  modelType: defineMessages({
    requireReservation: {
      defaultMessage: "Named",
      description:
        "value for a model where a license must be assigned to a named user (LicenseModel.modelType requireReservation)"
    },
    optionalReservation: {
      defaultMessage: "Floating",
      description:
        "value for a model where a license may be used by any user with access to a floating license pool (LicenseModel.modelType optionalReservation)"
    }
  })
};
