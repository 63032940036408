import { useContext, useEffect, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {FeedbackEntry, Feedback, ConfirmModal} from "@10duke/dukeui";
import {
  AddClientGroupsForClientAction,
  ClearErrorAction, GetOrgClientAction,
  isAddErrorAction, RemoveClientGroupsOfClientAction,
} from "../../../actions/actionTypes";
import Table from "../../table/table-container";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import { ModalKeys } from "../../pages/groups";
import GroupTypeBadge from "../../badges/group-type-badge";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import { Form } from "react-bootstrap";
import { ClosableModalProps } from "../closable-modal-props";
import NavigateAfterAction from "../../navigate-after-action";
import {ClientGroup} from "../../../model/ClientGroup";
import {Client} from "../../../model/Client";
import {ClientLabels} from "../../../localization/client";
import "./manage-device-clients-device-client-groups-modal-view.scss";
import {hasAction} from "../../../ui-configuration/configuration-tools";


export interface ManageDeviceClientsDeviceClientGroupsModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}

export interface ManageDeviceClientsDeviceClientGroupsModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {
  onShowFeedback: (feedback: FeedbackEntry | FeedbackEntry[]) => void;
}
export interface ManageDeviceClientsDeviceClientGroupsModalInputProps
  extends ManageDeviceClientsDeviceClientGroupsModalVisibilityProps {
  clientId?: string;
}
export interface ManageDeviceClientsDeviceClientGroupsModalStateProps {
  selected: ClientGroup[];
  onSetSelected: (selection: ClientGroup[]) => void;
  groupsToAdd: ClientGroup[];
  groupsToRemove: ClientGroup[];
  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;
}
export interface ManageDeviceClientsDeviceClientGroupsModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  client?: Client | null;
  onLoadClient?: (id: string) => Promise<GetOrgClientAction>;

  clientGroups?: ClientGroup[];
  onLoadClientGroups?: () => void;

  clientsClientGroups?: string[];
  onLoadClientsClientGroups?: (id: string) => void;

  onAddGroups?: (
    groups: string[],
    usrId: string
  ) => Promise<AddClientGroupsForClientAction>;
  onRemoveGroups?: (
    groups: string[],
    usrId: string
  ) => Promise<RemoveClientGroupsOfClientAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface ManageDeviceClientsDeviceClientGroupsModalProps
  extends ManageDeviceClientsDeviceClientGroupsModalDOMProps,
    ManageDeviceClientsDeviceClientGroupsModalInputProps,
    ManageDeviceClientsDeviceClientGroupsModalStateProps,
    ManageDeviceClientsDeviceClientGroupsModalDataProps {}
//</editor-fold>

function ManageDeviceClientsDeviceClientGroupsModal(props: ManageDeviceClientsDeviceClientGroupsModalProps) {
  //<editor-fold desc="Local variables">
  let {
    client,
    clientId,
    onLoadClient,
    clientGroups,
    onLoadClientGroups,
    onAddGroups,
    onRemoveGroups,
    clientsClientGroups,
    onLoadClientsClientGroups,
    show,
    onClose,
    onShowFeedback,
    groupsToAdd,
    groupsToRemove,
    onSetSelected,
    selected,
    activeSearch,
    onSetActiveSearch,
    isReady,
    onExited,
    onClearError,
  } = props;
  if (show && !onAddGroups) {
    throw new Error(
      "ManageDeviceClientsDeviceClientGroupsModal: Required props missing. The onAddGroups is required"
    );
  }
  if (show && !onRemoveGroups) {
    throw new Error(
      "ManageDeviceClientsDeviceClientGroupsModal: Required props missing. The onRemoveGroups is required"
    );
  }
  if (show && client === undefined && (!clientId || !onLoadClient)) {
    throw new Error(
      "ManageDeviceClientsDeviceClientGroupsModal: Required props missing. The onLoadClient is required with clientId"
    );
  }
  if (show && !clientGroups && !onLoadClientGroups) {
    throw new Error(
      "ManageDeviceClientsDeviceClientGroupsModal: Required props missing. Either clientGroups or onLoadClientGroups must be provided"
    );
  }
  if (show && !clientsClientGroups && !onLoadClientsClientGroups) {
    throw new Error(
      "ManageDeviceClientsDeviceClientGroupsModal: Required props missing. Either clientsClientGroups or onLoadClientsClientGroups must be provided"
    );
  }
  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>

  const clientObjId = client ? client.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!onLoadClient &&
      !!clientId &&
      (clientObjId === undefined || (!!clientObjId && clientObjId !== clientId))
    ) {
      onLoadClient(clientId).then((res) => {
        if (!clientObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, onLoadClient, clientId, clientObjId, onClearError]);

  useEffect(() => {
    if (!!show && !clientGroups && !!onLoadClientGroups) {
      onLoadClientGroups();
    }
  }, [show, clientGroups, onLoadClientGroups]);
  const hasClientsClientGroups = !!clientsClientGroups;
  useEffect(() => {
    if (!!show && !!clientObjId && !hasClientsClientGroups && !!onLoadClientsClientGroups) {
      onLoadClientsClientGroups(clientObjId as string);
    }
  }, [show, clientObjId, onLoadClientsClientGroups,hasClientsClientGroups]);

  const { conf } = useContext(UIConfiguration);
  const groupConf =
    conf.functionality && conf.functionality["device-client-groups"]
      ? conf.functionality["device-client-groups"]
      : {};
  return (
    <ConfirmModal
      id="manage-device-clients-device-client-groups-modal"
      onExited={onExited}
      onReloadData={() => {
        if (onLoadClientGroups) {
          onLoadClientGroups();
        }
        if (clientId && onLoadClientsClientGroups) {
          onLoadClientsClientGroups(clientId);
        }
        if (clientId && onLoadClient) {
          onLoadClient(clientId).then((res) => {
            if (!client && isAddErrorAction(res)) {
              // only clear error if no data exists, leave if previous data is still available and
              // let the api error notification handler show error
              onClearError(res.error?.errorId);
            }
          });
        }
      }}
      isReady={isReady}
      data-test-manage-clients-client-groups-modal={client ? client.id : true}
      title={
        client
          ? intl.formatMessage(
              {
                defaultMessage: "{name}: device client groups",
                description: "modal heading. 'name' = name of the device",
              },
              {
                name: client?.name || "",
              }
            )
          : intl.formatMessage({
              defaultMessage: "Device client not found",
              description: "modal heading when device not found",
            })
      }
      confirmTitle={intl.formatMessage(
        {
          defaultMessage: "{name}: confirm device client groups",
          description:
            "modal heading for confirming action. 'name' = name of the device",
        },
        {
          name: client?.name || "",
        }
      )}
      confirmContent={
        <>
          <p data-test-confirm-changes-message>
            <FormattedMessage
              defaultMessage="Are you sure you wish to apply the following changes to the client group connections?"
              description={"Confirm action message"}
            />
          </p>
          {groupsToRemove.length > 0 && (
            <>
              <strong>
                <FormattedMessage
                  defaultMessage="Client groups to remove"
                  description={"heading for summary of client groups to remove"}
                />
              </strong>
              <ul>
                {groupsToRemove.map((g) => (
                  <li key={"toRemove" + (g.id as string)}>{g.name}</li>
                ))}
              </ul>
            </>
          )}
          {groupsToAdd.length > 0 && (
            <>
              <strong>
                <FormattedMessage
                  defaultMessage="Client groups to add"
                  description={"heading for summary of client groups to add"}
                />
              </strong>
              <ul>
                {groupsToAdd.map((g) => (
                  <li key={"toAdd" + (g.id as string)}>{g.name}</li>
                ))}
              </ul>
            </>
          )}
        </>
      }
      acceptButton={{
        label: intl.formatMessage({
          defaultMessage: "Yes",
          description: "confirm action button label",
        }),
      }}
      cancelButton={{
        label: intl.formatMessage({
          defaultMessage: "No",
          description: "cancel confirmation button label",
        }),
      }}
      show={show}
      onClose={onClose}
      skipConfirm={!client}
      backdrop={
        groupsToAdd.length > 0 || groupsToRemove.length > 0 ? "static" : true
      }
      primaryButton={{
        label: !client
          ? intl.formatMessage({
              defaultMessage: "Close",
              description: "close button label",
            })
          : intl.formatMessage({
              defaultMessage: "Apply",
              description: "primary button label",
            }),
        disabled:
          !!client && groupsToAdd.length === 0 && groupsToRemove.length === 0,
        tooltip:
          !!client && groupsToAdd.length === 0 && groupsToRemove.length === 0
            ? intl.formatMessage({
                defaultMessage: "There no changes to apply.",
                description: "tooltip for disabled primary button",
              })
            : undefined,
      }}
      onPrimaryAction={() => {
        if (show && client) {
          if (onAddGroups && onRemoveGroups) {
            const actions = [];
            if (groupsToAdd.length) {
              actions.push(
                onAddGroups(
                  groupsToAdd.map((val) => val.id as string),
                    client.id as string
                ).catch((error) => error)
              );
            }
            if (groupsToRemove.length) {
              actions.push(
                onRemoveGroups(
                  groupsToRemove.map((val) => val.id as string),
                    client.id as string
                ).catch((error) => error)
              );
            }
            if (actions.length) {
              // should aways come here
              Promise.all(actions).then(
                (res) => {
                  const feedback: FeedbackEntry[] = [];
                  let addResult;
                  let removeResult;
                  if (groupsToAdd.length) {
                    addResult = res[0];
                    if (groupsToRemove.length) {
                      removeResult = res[1];
                    }
                  } else if (groupsToRemove.length) {
                    removeResult = res[0];
                  } else {
                    throw Error("This should never happen");
                  }
                  if (addResult && addResult.error) {
                    onClearError(addResult.error?.errorId);
                    feedback.push({
                      id:
                        "manageClientsClientGroups_add_" +
                        (client ? (client.id as string) : ""),
                      msg: intl.formatMessage(
                        {
                          defaultMessage: "{name}: Adding device client groups failed.",
                          description:
                            "failure notification. 'name' = name of the device client",
                        },
                        {
                          name:
                            "<strong>" +
                              (client?.name || "") +
                            "</strong>",
                        }
                      ),
                      type: "danger",
                    });
                  } else if (addResult) {
                    feedback.push({
                      id:
                        "manageClientsClientGroups_add_" +
                        (client ? (client.id as string) : ""),
                      msg: intl.formatMessage(
                        {
                          defaultMessage:
                            "{name}: {count} {count, plural, one {client group} other {client groups}} added.",
                          description:
                            "Success notification. 'name' = name of the device client, 'count' = amount of client groups added",
                        },
                        {
                          name:
                              "<strong>" +
                              (client?.name || "") +
                              "</strong>",
                          count: groupsToAdd.length,
                        }
                      ),
                      autoClose: true,
                      type: "success",
                    });
                  }
                  if (removeResult && removeResult.error) {
                    onClearError(removeResult.error?.errorId);
                    feedback.push({
                      id:
                        "manageClientsClientGroups_remove_" +
                        (client ? (client.id as string) : ""),
                      msg: intl.formatMessage(
                        {
                          defaultMessage: "{name}: Removing client groups failed.",
                          description:
                            "failure notification. 'name' = name of the device client",
                        },
                        {
                          name:
                              "<strong>" +
                              (client?.name || "") +
                              "</strong>",
                        }
                      ),
                      type: "danger",
                    });
                  } else if (removeResult) {
                    feedback.push({
                      id:
                        "manageClientsClientGroups_remove_" +
                        (client ? (client.id as string) : ""),
                      msg: intl.formatMessage(
                        {
                          defaultMessage:
                            "{name}: {count} {count, plural, one {client group} other {client groups}} removed.",
                          description:
                            "Success notification. 'name' = name of the device client, 'count' = amount of client groups removed.",
                        },
                        {
                          name:
                              "<strong>" +
                              (client?.name || "") +
                              "</strong>",
                          count: groupsToRemove.length,
                        }
                      ),
                      autoClose: true,
                      type: "success",
                    });
                  }
                  if (feedback.length) {
                    onShowFeedback(feedback);
                  }
                },
                (rej) => {
                  throw new Error("Should not happen");
                }
              );
            }
          }
        }
        onClose();
      }}
      secondaryButton={
        !!client
          ? {
              label: intl.formatMessage({
                defaultMessage: "Cancel",
                description: "secondary button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={onClose}
    >
      {client && (
        <>
          <Table<ClientGroup>
            disableLoadingIndicator={!isReady}
            maxRows={15}
            compact={true}
            header={
              <Form.Label>
                <FormattedMessage
                  defaultMessage="Select device client groups"
                  description={"field label"}
                />
              </Form.Label>
            }
            data-test-select-client-groups
            search={clientGroups && clientGroups.length > TABLE_SEARCH_THRESHOLD}
            activeSearch={activeSearch}
            onSearch={onSetActiveSearch}
            columnToggle={false}
            reset={false}
            data={clientGroups ? clientGroups : isReady ? [] : undefined}
            pagination={false}
            identifyingColumn={"id"}
            selection={{
              multi: true,
              selectAll: true,
            }}
            onSelectionChanged={onSetSelected}
            selected={selected}
            columns={[
              {
                key: "id",
                label: intl.formatMessage(ClientLabels.id),
                isTechnical: true,
                hidden: true,
              },
              {
                key: "name",
                label: intl.formatMessage({
                  defaultMessage: "Client group",
                  description: "column heading for the client groups to select",
                }),
                sortable: true,
                renderer: (props: {
                  cell: any;
                  row: any;
                  rowIndex: Number;
                  rendererData: any;
                }) => {
                  return (
                    <>
                      <span key={"link" + props.row.id} className={"link-holder"}>
                        {hasAction(groupConf.rowActions, 'show') ? (
                          <NavigateAfterAction
                            href={
                              "/device-client-groups/" + props.row.id + "/" + ModalKeys.show
                            }
                            action={onClose}
                          >
                            {props.cell}
                          </NavigateAfterAction>
                        ) : props.cell}
                      </span>
                      <GroupTypeBadge type={props.row.type} />
                    </>
                  );
                },
                tipRenderer: (props: {
                  cell: any;
                  row: any;
                  rowIndex: Number;
                  rendererData: any;
                }) => {
                  return (
                    <FormattedMessage
                      defaultMessage="Click to select or deselect."
                      description="tooltip for toggling group selection"
                    />
                  );
                },
              },
            ]}
          />
        </>
      )}
      {isReady && !client && (
        <Feedback type={"danger"} show={true} asChild={true}>
          <p>
            <FormattedMessage
              defaultMessage="Something went wrong and the device client could not be loaded. The device client  may have been removed or you don't have sufficient access rights."
              description="message to be shown when there is no device client to display"
            />
          </p>
        </Feedback>
      )}
    </ConfirmModal>
  );
}

export default ManageDeviceClientsDeviceClientGroupsModal;
