import { connect } from "react-redux";
import {
  ViewEntitlementModalProps as _ViewEntitlementModalProps,
  ViewEntitlementModalDataProps,
} from "./view-entitlement-modal-view";
import ViewEntitlementModal from "./view-entitlement-modal-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import {
  getOrganizationEntitlement,
  listEntitlementLicenses, listLicenseConsumingCliGroups,
  listLicenseConsumingOrgGroups,
} from "../../../actions/entActions";
import OrganizationUtils from "../../../utils/organization";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import EntitlementUtils from "../../../utils/entitlement";
import { addSenderArgument } from "../../../actions/actionHelpers";
import {clearError, listOrganizationClientGroups, listOrganizationGroups} from "../../../actions";
import {ThunkDispatch} from "redux-thunk";
import {AppAction} from "../../../actions/actionTypes";
export type ViewEntitlementModalProps = Omit<
  _ViewEntitlementModalProps,
  keyof ViewEntitlementModalDataProps
>;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ViewEntitlementModalProps,
  ViewEntitlementModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ViewEntitlementModalProps,
  ViewEntitlementModalProps
>;

const sender: ActionSender = { key: "view-entitlement-modal" };
/**
 * @param state
 * @param ownProps
 */
function mapStateToProps(
  state: AppState,
  ownProps: ViewEntitlementModalProps
): ReduxStateProps {
  return {
    entitlement: ownProps.entitlementId
      ? EntitlementUtils.selectEntitlement(ownProps.entitlementId, state)
      : undefined,
    entitlementGroups: ownProps.entitlementId
        ? EntitlementUtils.selectEntitlementGroups(ownProps.entitlementId, state)
        : undefined,
    entitlementClientGroups: ownProps.entitlementId
        ? EntitlementUtils.selectEntitlementClientGroups(ownProps.entitlementId, state)
        : undefined,
    entitlementLicenses: ownProps.entitlementId
      ? OrganizationUtils.selectOrganizationLicensesForEntitlement(
          ownProps.entitlementId,
          state
        )
      : undefined,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    EntitlementUtils.compareEntitlementsStates(next, prev) &&
    EntitlementUtils.compareEntitlementGroupsStates(next, prev) &&
    EntitlementUtils.compareEntitlementClientGroupsStates(next, prev) &&
    OrganizationUtils.compareOrganizationLicenseStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadEntitlement: addSenderArgument(sender, getOrganizationEntitlement),
  onLoadEntitlementGroups: (entId: string) => {
    // the second type is an unknown concept, no idea if void is correct
    return (dispatch: ThunkDispatch<AppState, void, AppAction>) => {
      dispatch(listOrganizationGroups(sender));
      dispatch(listLicenseConsumingOrgGroups(sender, undefined, entId));
    }
  },
  onLoadEntitlementClientGroups: (entId: string) => {
    // the second type is an unknown concept, no idea if void is correct
    return (dispatch: ThunkDispatch<AppState, void, AppAction>) => {
      dispatch(listOrganizationClientGroups(sender));
      dispatch(listLicenseConsumingCliGroups(sender, undefined, entId));
    };
  },
  onLoadEntitlementLicenses: addSenderArgument(sender, listEntitlementLicenses),
  onClearError: clearError,
};
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ViewEntitlementModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ViewEntitlementModal);
