import { useContext } from "react";
import { useIntl } from "react-intl";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import EditOrganizationModal from "../../modals/edit-organization-modal";
import GroupsCard from "../../summary-cards/groups-card";
import { useHistory } from "react-router-dom";
import UsersCard from "../../summary-cards/users-card";
import InvitationsCard from "../../summary-cards/invitations-card";
import LicensesCard from "../../summary-cards/licenses-card";
import "./dashboard-view.scss";
import { Organization } from "../../../model/Organization";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OrganizationCard from "../../summary-cards/organization-card";
import RolesCard from "../../summary-cards/roles-card";
import ClientGroupsCard from "../../summary-cards/client-groups-card";
import ClientsCard from "../../summary-cards/clients-card";
import {IconLibrary,FeedbackContainerProps,ShowFeedback, Page, PageDOMProps, Button} from "@10duke/dukeui";

export enum ModalKeys {
  editOrganization = "edit-organization",
}
//<editor-fold desc="Props">
export interface DashboardStateProps extends FeedbackContainerProps {}
export interface DashboardModalProps {
  showModal?: { key: ModalKeys; id?: string };
  onShowModal: (key: ModalKeys, id?: string) => void;
  onHideModal: (callback?: () => void) => void;
}
export interface DashboardDOMProps extends PageDOMProps {}
export interface DashboardProps
  extends DashboardDOMProps,
    DashboardModalProps,
    DashboardStateProps {
  organization?: Organization;
}

function Dashboard(props: DashboardProps) {
  const {
    onHideModal,
    onShowModal,
    onShowFeedback,
    onHideFeedback,
    feedback,
    showModal,
    organization,
    ...other
  } = props;
  if (!organization) {
    throw new Error("No organization");
  }
  const history = useHistory();
  const intl = useIntl();
  const description = intl.formatMessage({
    defaultMessage: "Dashboard",
    description: "window meta description for the dashboard page",
  });
  const { conf } = useContext(UIConfiguration);
  const sectionsConf =
    conf.functionality && conf.functionality.sections
      ? conf.functionality.sections
      : [];
  const dashboardConf =
    conf.functionality && conf.functionality.dashboard
      ? conf.functionality.dashboard
      : {};
  const invitationsConf =
      conf.functionality && conf.functionality.invitations
          ? conf.functionality.invitations
          : {};
  // Some react rule requires key somewhere around here, but it can't actually specify where. There is no list here
  // so key should not be required. Added useless spans to keep react happy.
  const titleOrg = (
    <span key="org">
      {dashboardConf.editOrganization && (
        <Button
          key="editOrgLink"
          data-test-edit-organization
          className="editOrgLink"
          action={() => {
            onShowModal(ModalKeys.editOrganization);
          }}
        >
          <span>{organization?.name}</span>
          <FontAwesomeIcon icon={IconLibrary.icons.faPen} className={"icon"} key="whatever4" />
        </Button>
      )}
      {!dashboardConf.editOrganization && (
        <span key="orgName">{organization?.name}</span>
      )}
    </span>
  );

  return (
    <Page
      data-test-dashboard-page
      id={"dashboard-page"}
      header={
        <h1 key={"header-title"}>
          {intl.formatMessage(
            {
              defaultMessage: "Welcome to {organization} orgadmin",
              description:
                "heading for the dashboard. 'organization' is the name of the organization that acts as a link for edit organization",
            },
            {
              organization: titleOrg,
            }
          )}
        </h1>
      }
      meta={{
        title: intl.formatMessage({
          defaultMessage: "Welcome to orgadmin",
          description: "window meta title for the dashboard page",
        }),
        description,
      }}
      {...other}
    >
      <ShowFeedback
        feedback={feedback}
        idPrefix={"dashboard_"}
        onHideFeedback={onHideFeedback}
      />
      {(sectionsConf.length ||
        dashboardConf.showOrganization ||
        dashboardConf.editOrganization) && (
        <div className={"dashboard-content"}>
          {dashboardConf.showOrganization && (
            <div className={"item"}>
              <OrganizationCard
                moreAction={
                  dashboardConf.editOrganization
                    ? () => {
                        onShowModal(ModalKeys.editOrganization);
                      }
                    : null
                }
                key={"organization"}
              >
                <>
                  {dashboardConf.editOrganization && (
                    <EditOrganizationModal
                      onShowFeedback={onShowFeedback}
                      show={
                        showModal &&
                        showModal.key === ModalKeys.editOrganization
                          ? true
                          : false
                      }
                      onClose={onHideModal}
                    />
                  )}
                </>
              </OrganizationCard>
            </div>
          )}
          {sectionsConf.length > 0
            ? sectionsConf.map((s) => {
                let retVal;
                if (s === "groups") {
                  retVal = (
                    <div className={"item"} key={s}>
                      <GroupsCard
                        moreAction={() => {
                          history.push("/groups");
                        }}
                      />
                    </div>
                  );
                } else if (s === "device-client-groups") {
                  retVal = (
                      <div className={"item"} key={s}>
                        <ClientGroupsCard
                          moreAction={() => {
                            history.push("/device-client-groups");
                          }}
                        />
                      </div>
                  );
                } else if (s === "roles") {
                  retVal = (
                    <div className={"item"} key={s}>
                      <RolesCard
                        moreAction={() => {
                          history.push("/roles");
                        }}
                      />
                    </div>
                  );
                } else if (s === "users") {
                  retVal = (
                    <div className={"item"} key={s}>
                      <UsersCard
                        moreAction={() => {
                          history.push("/users");
                        }}
                      />
                    </div>
                  );
                } else if (s === "device-clients") {
                  retVal = (
                      <div className={"item"} key={s}>
                        <ClientsCard
                          moreAction={() => {
                            history.push("/device-clients");
                          }}
                          key={s}
                        />
                      </div>
                  );
                } else if (s === "invitations") {
                  retVal = (
                    <div className={"item"} key={s}>
                      <InvitationsCard
                        moreAction={() => {
                          history.push("/invitations");
                        }}
                        enableClients={ invitationsConf?.enableClientInvitations === true }
                        enableUsers={ invitationsConf?.enableUserInvitations === true}
                      />
                    </div>
                  );
                } else if (s === "licenses") {
                  retVal = (
                    <div className={"item"} key={s}>
                      <LicensesCard
                        moreAction={() => {
                          history.push("/licenses");
                        }}
                      />
                    </div>
                  );
                }
                return retVal;
              })
            : undefined}
        </div>
      )}
      {!dashboardConf.showOrganization && dashboardConf.editOrganization && (
        <EditOrganizationModal
          key={"edit-organization"}
          onShowFeedback={onShowFeedback}
          show={
            showModal && showModal.key === ModalKeys.editOrganization
              ? true
              : false
          }
          onClose={onHideModal}
        />
      )}
    </Page>
  );
}
export default Dashboard;
