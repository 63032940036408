import { useDrop } from "react-dnd";
import { Dropdown, Form } from "react-bootstrap";
import { IconLibrary, TooltipWrapper } from "@10duke/dukeui";
import { TableColumn } from "../table-view";
import { FormattedMessage, useIntl } from "react-intl";
import "./column-tools-view.scss";
import { DragItem } from "../header-cell/header-cell-view";
import { useId } from "react";

//<editor-fold desc="Props">
export interface ColumnToolsVisibilityProps {
  show?: boolean;
  onShow: () => void;
  onHide: () => void;
}
export interface ColumnToolsProps extends ColumnToolsVisibilityProps {
  columns: TableColumn[];
  onToggleColumn?: (key: string, visible: boolean) => void;
}
//</editor-fold>

export default function ColumnTools(props: ColumnToolsProps) {
  //<editor-fold desc="Local variables">
  const intl = useIntl();
  const { columns, onToggleColumn, show, onShow, onHide } = props;
  const cols = columns.filter((c: TableColumn) => !c.isTechnical);
  const visibleCols = cols.filter((c: TableColumn) => !c.hidden);
  //</editor-fold>

  //<editor-fold desc="Hooks">
  const idPrefix = useId();
  //</editor-fold>
  const onToggleHandler = (isOpen: boolean, metadata: any) => {
    if (show) {
      if (metadata.source !== "select") {
        onHide();
      }
    } else {
      onShow();
    }
  };
  const [{ isOver }, drop] = useDrop({
    accept: "column",
    drop: (item: DragItem) => {
      if (onToggleColumn && visibleCols.length > 1) {
        onToggleColumn(item.id, false);
      }
      return { ...item, hide: true };
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  return (
    <>
      <Dropdown
        className={"btn-group column-tools"}
        show={show}
        onToggle={onToggleHandler}
      >
        <TooltipWrapper
          tipKey={"toolsToggleColumnTip"}
          tip={intl.formatMessage({
            defaultMessage: "Select columns to display.",
            description: "tooltip for the select columns button",
          })}
        >
          <Dropdown.Toggle
              variant={"primary"}
              className={'custom-base'}
            data-test-table-columns-trigger
          >
            <div
              ref={drop}
              className={
                isOver && visibleCols.length > 1
                  ? "icon d-block column-tools-drop is-drag-over is-droppable"
                  : "icon d-block column-tools-drop"
              }
            >
              {IconLibrary.customIcons.editColumns}
            </div>
          </Dropdown.Toggle>
        </TooltipWrapper>

        <Dropdown.Menu
          data-test-table-columns
          popperConfig={{
            modifiers: [
              {
                name: "computeStyles",
                options: {
                  gpuAcceleration: false, // true by default. Without this random conf, unnecessary scrollbars are added to parents
                },
              },
            ],
          }}
        >
          <Dropdown.Header className={"border-bottom"}>
            <FormattedMessage
              defaultMessage="Toggle columns"
              description={"Heading for column selection dropdown"}
            />
          </Dropdown.Header>
          {cols &&
            cols.map((c: TableColumn) => {
              return (
                <TooltipWrapper
                  key={c.key}
                  tipKey={c.key + "LastVisibleTip"}
                  tip={
                    visibleCols.length < 2 && !c.hidden
                      ? intl.formatMessage({
                          defaultMessage:
                            "The last column cannot be removed as a minimum of 1 column must be active at all times.",
                          description:
                            "tooltip for disabled last column remove",
                        })
                      : undefined
                  }
                >
                  <Dropdown.Item
                    data-test-table-column={c.key}
                    onClick={
                      visibleCols.length > 1 || !!c.hidden
                        ? (e: any) => {
                            e.preventDefault();
                            if (onToggleColumn) {
                              onToggleColumn(c.key, !!c.hidden);
                            }
                          }
                        : undefined
                    }
                  >
                    <Form.Check
                      id={idPrefix + c.key}
                      readOnly
                      disabled={visibleCols.length < 2 && !c.hidden}
                      checked={!c.hidden}
                      type={"checkbox"}
                      label={c.label}
                    />
                  </Dropdown.Item>
                </TooltipWrapper>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}
