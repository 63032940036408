import * as ActionTypes from "../actions/actionTypes";
import { OrgRoleGrantedPermissionsByOrgRoleId } from "../store/OrganizationRoleState";
import { PermissionGrantsForPermission } from "../model/PermissionGrantsForPermission";

export default function orgRoleGrantedPermissions(
  state: OrgRoleGrantedPermissionsByOrgRoleId,
  action: ActionTypes.AppAction
): OrgRoleGrantedPermissionsByOrgRoleId | null {
  const currentState = state || ({} as OrgRoleGrantedPermissionsByOrgRoleId);
  switch (action.type) {
    case ActionTypes.LIST_PERMISSIONS_OF_ORG_ROLE:
      const permsOfOrgRole =
        action as ActionTypes.ListPermissionsOfOrgRoleAction;
      return {
        ...currentState,
        [permsOfOrgRole.orgRoleId]: permsOfOrgRole.permissions?.map((p) => {
          return {
            permissionId: p.id,
            grantedActions:
              !!p.grantedActions &&
              !!p.grantedActions.allowedActions &&
              p.grantedActions.allowedActions.length
                ? p.grantedActions.allowedActions
                : undefined,
          } as PermissionGrantsForPermission;
        }),
      };
    case ActionTypes.SET_PERMISSIONS_OF_ORG_ROLE:
      const permsOfOrgRole2 =
        action as ActionTypes.SetPermissionsOfOrgRoleAction;
      return {
        ...currentState,
        [permsOfOrgRole2.orgRoleId]: permsOfOrgRole2.grants,
      };
    default:
      return state || null;
  }
}
