import { createContext, ReactNode, useState } from "react";
import inputConf from "../ui-conf.json";
import {
  DEFAULT_UI_CONFIGURATION,
  OrgadminConfiguration,
} from "./orgadmin-configuration";
import { mergeInDefaults, migrateConfToLatest } from "./configuration-tools";

export function applyConf(c: OrgadminConfiguration): OrgadminConfiguration {
  // store original version of conf, empty conf = use latest default and it's version
  const originalUIConfVersion: string = !!c.version
    ? c.version
    : Object.keys(c).length === 0
    ? (DEFAULT_UI_CONFIGURATION.version as string)
    : "0";
  // transform the inputted conf to the latest conf format
  const uiConf: OrgadminConfiguration = migrateConfToLatest(c as any);
  // create the actual conf by merging together requested and matching default conf
  return mergeInDefaults(uiConf, originalUIConfVersion);
}

let conf: OrgadminConfiguration = applyConf(inputConf as OrgadminConfiguration);

const UIConfiguration = createContext<{
  conf: OrgadminConfiguration;
  applyConf: (c: OrgadminConfiguration) => void;
}>({ conf: conf, applyConf: (c: OrgadminConfiguration) => {} }); //Initialise

export const InitialUIConfiguration = conf;
export default UIConfiguration;

export function UIConfigurationProvider(props: { children?: ReactNode }) {
  const { children } = props;
  const [stateConf, setConfValue] = useState(InitialUIConfiguration);
  return (
    <UIConfiguration.Provider
      value={{
        conf: stateConf,
        applyConf: (c) => {
          setConfValue(applyConf(c));
        },
      }}
    >
      {children}
    </UIConfiguration.Provider>
  );
}
