import { useContext, useEffect, HTMLAttributes } from "react";
import { FormattedMessage, FormattedDate, useIntl } from "react-intl";
import { FieldList,Feedback,Modal } from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import {
  LicenseWithCreditsAndEntitlementName,
  ModalKeys,
} from "../../pages/licenses/licenses-view";
import LicenseValidityBadge, {
  resolveLicenseValidity,
} from "../../badges/license-validity-badge";
import LicenseUtils from "../../../utils/licensed-item";
import { LicensedItem } from "../../../model/entitlement/LicensedItem";
import {
  resolveFreeSeatsForLicense,
  resolveFreeUseCountForLicense,
  resolveFreeUseTimeForLicense,
} from "../../../util/licenseUtil";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import NavigateAfterAction from "../../navigate-after-action";
import { LicenseLabels, LicenseValues } from "../../../localization/license";
import {
  ClearErrorAction,
  isAddErrorAction,
} from "../../../actions/actionTypes";

//<editor-fold desc="Props">

export interface ViewLicenseModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface ViewLicenseModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  license?: LicenseWithCreditsAndEntitlementName | null;
  onLoadLicense: (id: string) => any;
  entitlementsLoaded?: boolean;
  onLoadEntitlements: () => any;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface ViewLicenseModalProps
  extends ViewLicenseModalDOMProps,
    ViewLicenseModalDataProps,
    Omit<ClosableModalProps, "isReady"> {
  licenseId?: string;
}
//</editor-fold>

function ViewLicenseModal(props: ViewLicenseModalProps) {
  //<editor-fold desc="Local variables">
  let {
    licenseId,
    license,
    onLoadLicense,
    show,
    onClose,
    isReady,
    onExited,
    entitlementsLoaded,
    onLoadEntitlements,
    onClearError,
  } = props;

  const licenseObjId = license ? license.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!onLoadLicense &&
      !!licenseId &&
      (licenseObjId === undefined ||
        (!!licenseObjId && licenseObjId !== licenseId))
    ) {
      onLoadLicense(licenseId).then((res: any) => {
        if (!licenseObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, onLoadLicense, licenseId, licenseObjId, onClearError]);
  useEffect(() => {
    if (
      !!show &&
      !!onLoadEntitlements &&
      !!licenseObjId &&
      !entitlementsLoaded
    ) {
      onLoadEntitlements();
    }
  }, [show, onLoadEntitlements, licenseObjId, entitlementsLoaded]);

  const { conf } = useContext(UIConfiguration);
  const licenseConf =
    conf.functionality && conf.functionality.licenses
      ? conf.functionality.licenses
      : {};

  // this is more like a variable than a hook
  const intl = useIntl();
  const fields: any = license
    ? {
        name: {
          label: intl.formatMessage(LicenseLabels.licensedItem.displayName),
          value: LicenseUtils.resolveDisplayName(
            license.licensedItem as LicensedItem,
            intl.formatMessage(LicenseValues.licensedItem.displayName.undefined)
          ),
        },
        state: {
          label: intl.formatMessage(LicenseLabels.validityStatus),
          value: (
            <LicenseValidityBadge
              validFrom={license.validFrom}
              validUntil={license.validUntil}
              validity={resolveLicenseValidity(license)}
            />
          ),
        },
        entitlement: {
          label: intl.formatMessage(LicenseLabels.entitlementName),
          value: licenseConf.showEntitlement ? (
            <NavigateAfterAction
              href={
                "/licenses/" +
                ModalKeys.showEntitlement +
                "/" +
                license.entitlementId
              }
              action={onClose}
            >
              {license.entitlementName}
            </NavigateAfterAction>
          ) : (
            license.entitlementName
          ),
        },
        validFrom: {
          itemClass: "d-inline-block",
          label: intl.formatMessage(LicenseLabels.validFrom),
          value: (
            <>
              {license.validFrom ? (
                <FormattedDate value={new Date(license.validFrom)} />
              ) : (
                "-"
              )}
            </>
          ),
        },
        validUntil: {
          itemClass: "d-inline-block",
          label: intl.formatMessage(LicenseLabels.validUntil),
          value: (
            <>
              {license.validUntil ? (
                <FormattedDate value={new Date(license.validUntil)} />
              ) : (
                "-"
              )}
            </>
          ),
        },
        resolvedAllowedVersions: {
          label: intl.formatMessage(LicenseLabels.resolvedAllowedVersions),
          value: (
            <>
              {license.seatCountCredits &&
              license.seatCountCredits.length &&
              license.seatCountCredits[0].allowedVersionsDescription
                ? license.seatCountCredits[0].allowedVersionsDescription
                : license.useCountCredits &&
                  license.useCountCredits.length &&
                  license.useCountCredits[0].allowedVersionsDescription
                ? license.useCountCredits[0].allowedVersionsDescription
                : license.useTimeCredits &&
                  license.useTimeCredits.length &&
                  license.useTimeCredits[0].allowedVersionsDescription
                ? license.useTimeCredits[0].allowedVersionsDescription
                : "-"}
            </>
          ),
        },
        type: {
          label: intl.formatMessage(
            LicenseLabels.effectiveLicenseModel.modelType
          ),
          value:
            license &&
            license.effectiveLicenseModel &&
            license.effectiveLicenseModel.assignments &&
            license.effectiveLicenseModel.assignments.reservations === "advance"
              ? intl.formatMessage(
                  LicenseValues.effectiveLicenseModel.modelType
                    .requireReservation
                )
              : intl.formatMessage(
                  LicenseValues.effectiveLicenseModel.modelType
                    .optionalReservation
                ),
        },
        creditTypes: {
          label: intl.formatMessage(LicenseLabels.creditTypes),
          value: license.creditTypes,
        },
        seatsReserved: {
          label: intl.formatMessage(LicenseLabels.seatsReserved),
          value: license.seatsReserved,
        },
      }
    : {};
  if (license && license.creditTypes && license.creditTypes.includes("seat")) {
    const tmp = license.seatsTotal;
    fields.seatsTotal = {
      label: intl.formatMessage(LicenseLabels.seatsTotal),
      value: tmp !== undefined ? tmp : intl.formatMessage(LicenseValues.seatsTotal.undefined),
    };
    fields.seatsTaken = {
      label: intl.formatMessage(LicenseLabels.seatsTaken),
      value: license.seatsTaken,
    };
    const tmp2 = resolveFreeSeatsForLicense(license);
    fields.seatsAvailable = {
      label: intl.formatMessage(LicenseLabels.seatsAvailable),
      value: tmp2 !== undefined ? tmp2 : intl.formatMessage(LicenseValues.seatsAvailable.undefined),
    };
  }
  if (license && license.creditTypes && license.creditTypes.includes("count")) {
    fields.useCountTotal = {
      label: intl.formatMessage(
        LicenseLabels.aggregatedUseCountCredits.useCount
      ),
      value:
        license.useCountCredits &&
        license.useCountCredits.length &&
        license.useCountCredits[0] &&
        license.useCountCredits[0].useCount
          ? license.useCountCredits[0].useCount
          : 0,
    };
    fields.useCountTaken = {
      label: intl.formatMessage(
        LicenseLabels.aggregatedUseCountCredits.countUsed
      ),
      value:
        license.useCountCredits &&
        license.useCountCredits.length &&
        license.useCountCredits[0] &&
        license.useCountCredits[0].countUsed
          ? license.useCountCredits[0].countUsed
          : 0,
    };
    fields.useCountAvailable = {
      label: intl.formatMessage(
        LicenseLabels.aggregatedUseCountCredits.useCountAvailable
      ),
      value: resolveFreeUseCountForLicense(license),
    };
  }
  if (license && license.creditTypes && license.creditTypes.includes("time")) {
    fields.useTimeTotal = {
      label: intl.formatMessage(LicenseLabels.aggregatedUseTimeCredits.useTime),
      value:
        license.useTimeCredits &&
        license.useTimeCredits.length &&
        license.useTimeCredits[0] &&
        license.useTimeCredits[0].useTime
          ? license.useTimeCredits[0].useTime
          : 0,
    };
    fields.useTimeTaken = {
      label: intl.formatMessage(
        LicenseLabels.aggregatedUseTimeCredits.timeUsed
      ),
      value:
        license.useTimeCredits &&
        license.useTimeCredits.length &&
        license.useTimeCredits[0] &&
        license.useTimeCredits[0].timeUsed
          ? license.useTimeCredits[0].timeUsed
          : 0,
    };
    fields.useTimeAvailable = {
      label: intl.formatMessage(
        LicenseLabels.aggregatedUseTimeCredits.useTimeAvailable
      ),
      value: resolveFreeUseTimeForLicense(license),
    };
  }
  //</editor-fold>

  return (
    <Modal
      onReloadData={() => {
        if ((!!license || !!licenseId) && !!onLoadLicense) {
          onLoadLicense(
            license ? (license.id as string) : (licenseId as string)
          ).then((res: any) => {
            if (!license && isAddErrorAction(res)) {
              // only clear error if no data exists, leave if previous data is still available and
              // let the api error notification handler show error
              onClearError(res.error?.errorId);
            }
          });
        }
      }}
      onExited={onExited}
      isReady={isReady}
      data-test-view-license-modal
      title={
        !isReady || license
          ? intl.formatMessage({
              defaultMessage: "License details",
              description: "modal heading",
            })
          : intl.formatMessage({
              defaultMessage: "License not found",
              description: "modal heading when license is not found",
            })
      }
      show={show}
      onClose={onClose}
      primaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Close",
          description: "primary button label",
        }),
      }}
      onPrimaryAction={onClose}
    >
      {show && isReady && !license && (
        <Feedback type={"danger"} show={true} asChild={true}>
          <p>
            <FormattedMessage
              defaultMessage="No matching license found."
              description="message to be shown when there is no license to display"
            />
          </p>
        </Feedback>
      )}
      {license && (
        <>
          <FieldList fields={fields} asOneLiners={true} />
        </>
      )}
    </Modal>
  );
}

export default ViewLicenseModal;
