import { User } from "../../../model/User";
import { generateRandomUuid } from "@10duke/dukeui";

/*
 * Users of mock org 1, "Acme Inc".
 */

const ORG_1_USER_1: User = {
  id: "6d862e41-cbc9-4ad5-8951-7d26b0143e79",
  authorId: "6c3dd9a9-cdcf-4df6-afaf-29f43b005644",
  created: "2020-04-09T08:00:00.000Z",
  displayName: "April S. Workman",
  email: "AprilSWorkman@acme.inc",
  firstName: "April",
  lastName: "Workman",
  naturalId: "443-03-XXXX",
  nickname: "Apple",
  phoneNumber: "918-467-7671",
  professionalTitle: "Dressing room attendant",
  validFrom: "2020-04-09T08:00:00.000Z",
  validUntil: "2120-04-09T08:00:00.000Z",
  lastSignOn: "2020-06-01T08:00:00.000Z",
};

const ORG_1_USER_2: User = {
  id: "fa90362e-a03f-4d74-ae2f-2456b07ba47e",
  authorId: "fa90362e-a03f-4d74-ae2f-2456b07ba47e",
  created: "2020-04-09T08:01:00.000Z",
  email: "JoanTGriffin@acme.inc",
  firstName: "Joan",
  lastName: "Griffin",
  validFrom: "2020-04-09T08:01:00.000Z",
};

const ORG_1_USER_3: User = {
  id: "4ef713b7-b50f-4d39-bc9f-8c9043beb225",
  authorId: "4ef713b7-b50f-4d39-bc9f-8c9043beb225",
  created: "2020-04-09T08:02:00.000Z",
  email: "EmilyVWilliams@acme.inc",
  firstName: "Emily",
  lastName: "Williams",
  validFrom: "2020-04-09T08:02:00.000Z",
  lastSignOn: "2020-06-01T08:00:00.000Z",
};

const ORG_1_USER_4: User = {
  id: "de195357-0165-4ef4-87e0-b8106aedfdc6",
  authorId: "de195357-0165-4ef4-87e0-b8106aedfdc6",
  created: "2000-01-01T00:00:00.000Z",
  email: "JaneKShelton@acme.inc",
  firstName: "Jane",
  lastName: "Shelton",
  validFrom: "2000-01-01T00:00:00.000Z",
  validUntil: "2020-01-01T00:00:00.000Z",
  lastSignOn: "2020-12-01T08:00:00.000Z",
};

const ORG_1_USER_5: User = {
  id: "86157d31-122e-472d-ace4-76ca18bdf737",
  authorId: "86157d31-122e-472d-ace4-76ca18bdf737",
  created: "2000-01-01T00:00:00.000Z",
  email: "philip.mcphailure@acme.inc",
  firstName: "Philip",
  lastName: "McPhailure",
  validFrom: "2000-01-01T00:00:00.000Z",
  validUntil: "2020-01-01T00:00:00.000Z",
};

const extraUsersForHugeDataSetTests = [];
for (let i = 0; i < 400; i += 1) {
  extraUsersForHugeDataSetTests.push({
    id: generateRandomUuid(),
    authorId: "86157d31-122e-472d-ace4-76ca18bdf737",
    created: "2000-01-01T00:00:00.000Z",
    email: "xtra-" + i + "@test.test",
    firstName: "xtra " + i,
    lastName: "xtra " + i,
    validFrom: "1900-01-01T00:00:00.000Z",
    lastSignOn: "2020-06-01T08:00:00.000Z",
  });
}
export const ORG_1_USERS: User[] = [
  ORG_1_USER_1,
  ORG_1_USER_2,
  ORG_1_USER_3,
  ORG_1_USER_4,
  ORG_1_USER_5,
].concat(extraUsersForHugeDataSetTests);
