import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { ClosableModalProps } from "../closable-modal-props";
import {
  AddErrorAction,
  ClearErrorAction,
  CreateOrgClientGroupAction,
  isAddErrorAction,
  SetEntitlementsConsumedByOrgGroup,
} from "../../../actions/actionTypes";
import { Entitlement } from "../../../model/entitlement/Entitlement";
import Table from "../../table/table-container";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import {ClientGroup} from "../../../model/ClientGroup";
import {ClientGroupLabels} from "../../../localization/client-group";
import { IconLibrary, FormInput, FormInputUtils,FeedbackEntry, Modal } from "@10duke/dukeui";

//<editor-fold desc="Utils">

//</editor-fold>

//<editor-fold desc="Props">

export interface CreateDeviceClientGroupModalStateProps {
  groupEntitlements: string[];
  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;
  selected: Entitlement[];
  onSetSelected: (selection: Entitlement[]) => void;
}
export interface CreateDeviceClientGroupModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {
  onShowFeedback: (feedback: FeedbackEntry | FeedbackEntry[]) => void;
}
export interface CreateDeviceClientGroupModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  onCreateDeviceClientGroup: (
    clientGroup: ClientGroup
  ) => Promise<CreateOrgClientGroupAction>;
  entitlements?: Entitlement[];
  onLoadEntitlements?: (id: string) => void;
  onSetClientGroupEntitlements: (
      orgId: string,
      clientGroupId: string,
      entitlementIds: string[]
  ) => Promise<SetEntitlementsConsumedByOrgGroup>;
  organizationId?: string;
  onClearError: (errorId: string) => ClearErrorAction;
}

export interface CreateDeviceClientGroupModalProps
  extends CreateDeviceClientGroupModalVisibilityProps,
    CreateDeviceClientGroupModalStateProps,
    CreateDeviceClientGroupModalDataProps {}
//</editor-fold>

function CreateDeviceClientGroupModal(
  props: CreateDeviceClientGroupModalProps
) {
  //<editor-fold desc="Local variables">
  let {
    onCreateDeviceClientGroup,
    show,
    onClose,
    onShowFeedback,
    entitlements,
    onLoadEntitlements,
    organizationId,
    groupEntitlements,
    onSetClientGroupEntitlements,
    onSetActiveSearch,
    activeSearch,
    selected,
    onSetSelected,
    isReady,
    onExited,
    onClearError,
  } = props;
  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>
  if (show && entitlements === undefined && !onLoadEntitlements) {
    throw new Error(
      "CreateDeviceClientGroupModal: Required props missing. Either entitlements or onLoadEntitlements must be provided"
    );
  }
  //<editor-fold desc="Hooks">
  const defaultValues = useMemo(
    () => ({
      name: "",
      description: "",
    }),
    []
  );
  const { register, handleSubmit, formState, watch, reset } = useForm({
    mode: "onTouched",
    defaultValues: defaultValues,
  });

  const { errors } = formState;

  const formValues = watch();
  const formIsDirty = formState.isDirty;
  const formIsValid = formState.isValid;
  useEffect(() => {
    reset(defaultValues);
  }, [show, defaultValues, reset]);
  useEffect(() => {
    if (
      show &&
      organizationId &&
      onLoadEntitlements &&
      entitlements === undefined
    ) {
      onLoadEntitlements(organizationId);
    }
  }, [organizationId, entitlements, onLoadEntitlements, show]);
  //</editor-fold>

  const onSubmitClientGroup = (d: any) => {
    let data: any = { ...d };
    delete data.entitlements;
    return onCreateDeviceClientGroup(data as ClientGroup);
  };
  return (
    <Modal
      onExited={onExited}
      isReady={isReady}
      onReloadData={() => {
        if (onLoadEntitlements && organizationId) {
          onLoadEntitlements(organizationId);
        }
      }}
      data-test-create-device-client-group-modal
      title={intl.formatMessage({
        defaultMessage: "Create Device client group",
        description: "modal heading",
      })}
      show={show}
      backdrop={formState.isDirty || groupEntitlements.length ? "static" : true}
      onClose={onClose}
      primaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Create",
          description: "primary button label",
        }),
        disabled:
          (errors !== undefined &&
            errors !== null &&
            Object.keys(errors).length > 0) ||
          !formIsDirty ||
          !formIsValid,
        tooltip:
          errors !== undefined &&
          errors !== null &&
          Object.keys(errors).length > 0
            ? intl.formatMessage({
                defaultMessage: "Please correct errors before creating device client group.",
                description:
                  "tooltip for disabled primary button when there are validation errors",
              })
            : !formState.isDirty
            ? intl.formatMessage({
                defaultMessage: "Please fill in the fields first.",
                description:
                  "tooltip for disabled primary button when there is nothing to create",
              })
            : "",
      }}
      onPrimaryAction={() => {
        handleSubmit((data: any) => {
          onSubmitClientGroup(data).then((res) => {
            if (!isAddErrorAction(res)) {
              const feedback: FeedbackEntry[] = [
                {
                  id: "create_client_group",
                  msg: intl.formatMessage(
                    {
                      defaultMessage: "Device client group {name} created.",
                      description:
                        "success notification for create device client group. 'name' = name of the created device client group",
                    },
                    {
                      name: "<strong>" + res.clientGroup.name + "</strong>",
                    }
                  ),
                  autoClose: true,
                  type: "success",
                },
              ];
              if (!!groupEntitlements && groupEntitlements.length > 0) {
                onSetClientGroupEntitlements(
                  organizationId as string,
                  res.clientGroup.id as string,
                  groupEntitlements
                ).then((rres) => {
                  if (!isAddErrorAction(rres)) {
                    feedback.push({
                      id: "create_client_group_entitlements",
                      msg: intl.formatMessage(
                        {
                          defaultMessage: "Entitlements added for {name}.",
                          description:
                            "success notification for adding entitlements to created device client group. 'name' = name of the created device client group",
                        },
                        {
                          name: "<strong>" + res.clientGroup.name + "</strong>",
                        }
                      ),
                      autoClose: true,
                      type: "success",
                    });
                  } else {
                    onClearError(
                      (res as any as AddErrorAction<any>).error?.errorId
                    );
                    feedback.push({
                      id:
                        "create_client_group_entitlements" +
                        (res.clientGroup ? (res.clientGroup.id as string) : "not_possible"),
                      msg: intl.formatMessage(
                        {
                          defaultMessage:
                            "Adding entitlements for {name} failed.",
                          description:
                            "failure notification for creating device client group. 'name' = name of the failed device client group",
                        },
                        {
                          name: "<strong>" + res.clientGroup.name + "</strong>",
                        }
                      ),
                      type: "danger",
                    });
                  }
                  onShowFeedback(feedback);
                  onClose();
                });
              } else {
                onShowFeedback(feedback);
                onClose();
              }
            } else {
              onClearError(res.error?.errorId);
              onShowFeedback({
                id: "create_client_group",
                msg: intl.formatMessage({
                  defaultMessage: "Creating device client group failed.",
                  description:
                    "failure notification for unspecified error when creating device client group.",
                }),
                type: "danger",
              });
              onClose();
            }
          });
        })();
      }}
      secondaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Cancel",
          description: "secondary button label",
        }),
      }}
      onSecondaryAction={onClose}
    >
      <>
        <Form noValidate>
          <FormInput
            data-test-create-client-group-modal-name
            label={intl.formatMessage(ClientGroupLabels.name)}
            field="name"
            register={register}
            registerOptions={{
              required: true,
            }}
            hasValue={!!formValues["name"]}
            resolveValidity={FormInputUtils.validityResolver(formState)}
          >
            {errors &&
              errors.name &&
              errors.name.type &&
              errors.name.type === "required" && (
                <Form.Control.Feedback type="invalid">
                  <FontAwesomeIcon
                    icon={IconLibrary.icons.faExclamationCircle}
                    className={"icon"}
                  />
                  <span className={"copy"}>
                    {intl.formatMessage(
                      {
                        defaultMessage: "{name} is required.",
                        description:
                          "Field validation error. 'name' = Field label for device client group name (ClientGroup.name)",
                      },
                      {
                        name: intl.formatMessage(ClientGroupLabels.name),
                      }
                    )}
                  </span>
                </Form.Control.Feedback>
              )}
          </FormInput>
          <FormInput
            type={"textarea"}
            data-test-create-client-group-modal-description
            label={intl.formatMessage(ClientGroupLabels.description)}
            field="description"
            register={register}
            registerOptions={{
              required: false,
            }}
            hasValue={!!formValues["description"]}
            resolveValidity={FormInputUtils.validityResolver(formState)}
          >
          </FormInput>

          {entitlements && (
            <Form.Group>
              <Table<Entitlement>
                disableLoadingIndicator={!isReady}
                maxRows={5}
                compact={true}
                data-test-select-license-groups
                header={
                  <Form.Label>
                    {intl.formatMessage(ClientGroupLabels.entitlements)}
                  </Form.Label>
                }
                search={entitlements.length > TABLE_SEARCH_THRESHOLD}
                activeSearch={activeSearch}
                onSearch={onSetActiveSearch}
                columnToggle={false}
                reset={false}
                data={entitlements}
                pagination={false}
                identifyingColumn={"id"}
                selection={{
                  multi: true,
                  selectAll: true,
                }}
                onSelectionChanged={onSetSelected}
                selected={selected}
                columns={[
                  {
                    key: "id",
                    label: intl.formatMessage(ClientGroupLabels.id),
                    isTechnical: true,
                    hidden: true,
                  },
                  {
                    key: "name",
                    label: intl.formatMessage({
                      defaultMessage: "Entitlements",
                      description:
                        "column heading for the entitlements to select",
                    }),
                    sortable: true,
                  },
                ]}
              />
            </Form.Group>
          )}
        </Form>
      </>
    </Modal>
  );
}

export default CreateDeviceClientGroupModal;
