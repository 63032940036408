import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {
  buildActionThunk,
  ensureSelectedOrgId,
  forceUndefined
} from "./actionHelpers";
import { InternalPermissionWithGrantedActions } from "../model/InternalPermissionWithGrantedActions";
import { PermissionGrantsForPermission } from "../model/PermissionGrantsForPermission";

/**
 * Gets all permissions given for an organization role.
 * @param sender Component requesting for the action
 * @param orgRoleId The organization role id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function listPermissionsOfOrganizationRole(
  sender: ActionSender,
  orgRoleId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListPermissionsOfOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.ListPermissionsOfOrgRoleAction,
    InternalPermissionWithGrantedActions[]
  >(
    sender,
    ActionTypes.LIST_PERMISSIONS_OF_ORG_ROLE,
    async () => await idpApi.listPermissionsOfOrganizationRole(orgRoleId),
    (type, permissions) => ({
      type,
      orgRoleId,
      permissions: forceUndefined(permissions),
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Adds multiple permissions for an organization role.
 * @param sender Component requesting for the action
 * @param grants Array of PermissionGrantsForPermission describing the permissions
 *    and permission actions to add.
 * @param orgRoleId The organization role id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function addPermissionsForOrganizationRole(
  sender: ActionSender,
  grants: PermissionGrantsForPermission[],
  orgRoleId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.AddPermissionsForOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.AddPermissionsForOrgRoleAction, void>(
    sender,
    ActionTypes.ADD_PERMISSIONS_FOR_ORG_ROLE,
    async () =>
      await idpApi.addPermissionsForOrganizationRole(grants, orgRoleId),
    type => ({
      type,
      grants,
      orgRoleId,
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Replaces all current permissions of an organization role with the given permissions.
 * @param sender Component requesting for the action
 * @param grants Array of PermissionGrantsForPermission describing the permissions
 *    and permission actions to set.
 * @param orgRoleId The organization role id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function setPermissionsOfOrganizationRole(
  sender: ActionSender,
  grants: PermissionGrantsForPermission[],
  orgRoleId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.SetPermissionsOfOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.SetPermissionsOfOrgRoleAction, void>(
    sender,
    ActionTypes.SET_PERMISSIONS_OF_ORG_ROLE,
    async () =>
      await idpApi.setPermissionsOfOrganizationRole(grants, orgRoleId),
    type => ({
      type,
      grants,
      orgRoleId,
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Removes permissions given for an organization role.
 * @param sender Component requesting for the action
 * @param permissionIds Array of permission ids to remove
 * @param orgRoleId The organization role id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function removePermissionsOfOrganizationRole(
  sender: ActionSender,
  permissionIds: string[],
  orgRoleId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.RemovePermissionsOfOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.RemovePermissionsOfOrgRoleAction, void>(
    sender,
    ActionTypes.REMOVE_PERMISSIONS_OF_ORG_ROLE,
    async () =>
      await idpApi.removePermissionsOfOrganizationRole(
        permissionIds,
        orgRoleId
      ),
    type => ({
      type,
      permissionIds,
      orgRoleId,
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Removes a permission of an organization role.
 * @param sender Component requesting for the action
 * @param permissionId Id of permission to remove
 * @param orgRoleId The organization role id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function removePermissionOfOrganizationRole(
  sender: ActionSender,
  permissionId: string,
  orgRoleId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.RemovePermissionOfOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.RemovePermissionOfOrgRoleAction, void>(
    sender,
    ActionTypes.REMOVE_PERMISSION_OF_ORG_ROLE,
    async () =>
      await idpApi.removePermissionOfOrganizationRole(permissionId, orgRoleId),
    type => ({
      type,
      permissionId,
      orgRoleId,
      orgId: orgIdOrDefault
    })
  );
}
