import {
  RevokeDeviceClientInvitationModalProps as _RevokeDeviceClientInvitationModalProps,
  RevokeDeviceClientInvitationModalDataProps
} from "./revoke-device-client-invitation-modal-view";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import { ActionSender } from "../../../model/ActionSender";
import { AppState } from "../../../store/AppState";
import InProgressUtils from "../../../utils/in-progress";
import OrganizationUtils from "../../../utils/organization";
import { addSenderArgument } from "../../../actions/actionHelpers";
import {
  clearError, getOrganizationClientGroupInvitation,
  revokeOrgClientGroupInvitation,
} from "../../../actions";
import { connect } from "react-redux";
import RevokeDeviceClientInvitationModal from "./revoke-device-client-invitation-modal-container";

export interface RevokeDeviceClientInvitationModalProps
  extends Omit<
    _RevokeDeviceClientInvitationModalProps,
    keyof RevokeDeviceClientInvitationModalDataProps
  > {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _RevokeDeviceClientInvitationModalProps,
  RevokeDeviceClientInvitationModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _RevokeDeviceClientInvitationModalProps,
  RevokeDeviceClientInvitationModalProps
>;
const sender: ActionSender = { key: "revoke-device-client-invitation" };

function mapStateToProps(
  state: AppState,
  ownProps: RevokeDeviceClientInvitationModalProps
): ReduxStateProps {
  return {
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    invitation: ownProps.invitationId
      ? OrganizationUtils.selectOrganizationClientInvitation(
          ownProps.invitationId,
          state
        )
      : undefined,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientInvitationsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onRevokeInvitation: addSenderArgument(
    sender,
      revokeOrgClientGroupInvitation
  ),
  onLoadInvitation: addSenderArgument(
    sender,
    getOrganizationClientGroupInvitation
  ),
  onClearError: clearError,
};
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  RevokeDeviceClientInvitationModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(RevokeDeviceClientInvitationModal);
