import { useContext, useEffect, useId } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UserLicensedItemAssignments } from "../../../model/entitlement/UserLicensedItemAssignments";
import { resolveFreeSeatsForLicenseAndAssignmentsArray } from "../../../util/licenseUtil";
import { UserWithLicenseUsage } from "../../../model/User";
import { ClosableModalProps } from "../closable-modal-props";
import * as ActionTypes from "../../../actions/actionTypes";
import LicenseUtils from "../../../utils/licensed-item";
import Table, { TableColumn } from "../../table";
import LicenseValidityBadge, {
  resolveLicenseValidity,
} from "../../badges/license-validity-badge";
import { ModalKeys } from "../../pages/licenses";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import NavigateAfterAction from "../../navigate-after-action";
import {
  ClearErrorAction, GetOrgClientAction,
  isAddErrorAction, MANAGE_CLIENTS_LICENSE_ASSIGNMENTS,
} from "../../../actions/actionTypes";
import { LicenseValues } from "../../../localization/license";
import {
  UserLicensedItemAssignmentsLabels,
  UserLicensedItemAssignmentsValues,
} from "../../../localization/user-licensed-item-assignments";
import { LicenseConsumptionLabels } from "../../../localization/license-consumption";
import { Form } from "react-bootstrap";
import {Client} from "../../../model/Client";
import "./device-client-licenses-modal-view.scss";
import {IconLibrary,FeedbackEntry, Feedback, ShowFeedback, ConfirmModal, Button} from "@10duke/dukeui";
import {hasAction} from "../../../ui-configuration/configuration-tools";

function resolveLicensedItemNameFromAssignments(
  licenses: UserLicensedItemAssignments[] | undefined,
  licenseId: string,
  fallback: string
): string {
  let retVal = fallback;
  if (licenses !== undefined && licenseId) {
    const t = licenses
      ? licenses.find(
          (val) =>
            val &&
            val.assignments &&
            val.assignments.length &&
            val.assignments[0] &&
            val.assignments[0].license &&
            val.assignments[0].license.id === licenseId
        )
      : undefined;
    retVal = resolveLicensedItemName(t, fallback);
  }
  return retVal;
}

function resolveLicensedItemName(
  lic: UserLicensedItemAssignments | undefined,
  fallback: string
): string {
  let retVal = lic
    ? lic.licensedItemDisplayName || lic.licensedItemName || fallback
    : fallback;
  return retVal;
}
export interface DeviceClientLicensesModalStateProps {
  licensesChanged?: boolean;
  onToggleReservation: (licenseId: string) => void;
  onToggleDenial: (licenseId: string) => void;
  feedback?: FeedbackEntry[];
  onShowFeedback: (feedback: FeedbackEntry) => void;
  onHideFeedback: (id: string) => void;
  onSetActiveSearch: (s: string) => void;
  activeSearch?: string;
  showAll: boolean;
  onToggleShowAll: () => void;
}
export interface DeviceClientLicensesModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {}
export interface DeviceClientLicensesModalDataProps {
  clientId?: string;
}

export interface DeviceClientLicensesModalProps
  extends DeviceClientLicensesModalStateProps,
      DeviceClientLicensesModalVisibilityProps,
      DeviceClientLicensesModalDataProps,
    Pick<ClosableModalProps, "isReady"> {
  client?: Client;
  onLoadClient: (clientId: string) => Promise<GetOrgClientAction>;
  licenses?: UserLicensedItemAssignments[];
  onLoadLicenses: (clientId: string) => void;
  onApply: (
    lics: UserLicensedItemAssignments[] | undefined,
    userId: string
  ) => Promise<ActionTypes.MultiAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}

export default function DeviceClientLicensesModalView(props: DeviceClientLicensesModalProps) {
  const {
    clientId,
    client,
    onLoadClient,
    show,
    onClose,
    licenses,
    licensesChanged,
    onApply,
    onLoadLicenses,
    onToggleReservation,
    onToggleDenial,
    feedback,
    onShowFeedback,
    onHideFeedback,
    onSetActiveSearch,
    activeSearch,
    isReady,
    onExited,
    showAll,
    onToggleShowAll,
    onClearError,
    ...other
  } = props;
  const idPrefix = useId();
  const { conf } = useContext(UIConfiguration);
  const licenseConf =
    conf.functionality && conf.functionality.licenses
      ? conf.functionality.licenses
      : {};
  const intl = useIntl();

  const columns: TableColumn[] = [
    {
      key: "licenseId",
      label: intl.formatMessage(UserLicensedItemAssignmentsLabels.licenseId),
      isTechnical: true,
      hidden: true,
    },
    {
      key: "name",
      isDummy: true,
      label: intl.formatMessage(
        UserLicensedItemAssignmentsLabels.licensedItemDisplayName
      ),
      sortable: true,
      className: "name-column",
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const t = props.rendererData.resolveValue(props.row);
        return (
          <>
            {t && t.length ? (
              <span className={"link-holder"}>
                {hasAction(licenseConf.rowActions, 'show') ? (
                  <NavigateAfterAction
                    href={
                      (("/licenses/" +
                        (props.row.assignments &&
                        props.row.assignments.length &&
                        props.row.assignments[0] &&
                        props.row.assignments[0].license
                          ? props.row.assignments[0].license.id
                          : undefined)) as string) +
                      "/" +
                      ModalKeys.show
                    }
                    action={onClose}
                  >
                    {t[0]}
                  </NavigateAfterAction>
                ) : (
                  t[0]
                )}
              </span>
            ) : (
              false
            )}
            {t && t.length > 1 ? (
              <>
                <LicenseValidityBadge
                  validUntil={
                    props.row.assignments &&
                    props.row.assignments.length &&
                    props.row.assignments[0] &&
                    props.row.assignments[0].license
                      ? props.row.assignments[0].license.validUntil
                      : undefined
                  }
                  validFrom={
                    props.row.assignments &&
                    props.row.assignments.length &&
                    props.row.assignments[0] &&
                    props.row.assignments[0].license
                      ? props.row.assignments[0].license.validFrom
                      : undefined
                  }
                  validity={resolveLicenseValidity(
                    props.row.assignments &&
                      props.row.assignments.length &&
                      props.row.assignments[0] &&
                      props.row.assignments[0].license
                      ? props.row.assignments[0].license
                      : undefined
                  )}
                />
              </>
            ) : (
              false
            )}
          </>
        );
      },
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const t = props.rendererData.resolveValue(props.row);
        return (
          <>
            {t && t.length ? t[0] : false}
            {t && t.length > 1 ? <>, {t[1]}</> : false}
          </>
        );
      },
      rendererData: {
        resolveValue: (itm: any) => {
          const validity = resolveLicenseValidity(
            itm.assignments &&
              itm.assignments.length &&
              itm.assignments[0] &&
              itm.assignments[0].license
              ? itm.assignments[0].license
              : undefined
          );
          return [
            resolveLicensedItemName(
              itm,
              intl.formatMessage(
                UserLicensedItemAssignmentsValues.licensedItemDisplayName
                  .undefined
              )
            ),
            validity
              ? intl.formatMessage(
                  (LicenseValues.validityStatus as any)[validity]
                )
              : validity,
          ];
        },
      },
    },
    {
      key: "hasReservation",
      isDummy: true,
      label: intl.formatMessage(
        UserLicensedItemAssignmentsLabels.hasReservation
      ),
      tooltip: intl.formatMessage({
        defaultMessage:
          "Reserving a license will guarantee the user will always have access to a license. Reserved licenses are not available to other users.",
        description: "Cell heading tooltip",
      }),
      align: "center",
      sortable: true,
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const freeSeats = resolveFreeSeatsForLicenseAndAssignmentsArray(
          props.row.assignments
        );
        const value = props.rendererData.resolveValue(props.row);
        return (
          <div
            className={
              !value &&
              ((freeSeats !== undefined && freeSeats <= 0) ||
                (props.row.assignments &&
                  props.row.assignments.length &&
                  props.row.assignments[0].license &&
                  props.row.assignments[0].license.active === false))
                ? "tooltip-disabled-wrapper d-block"
                : "d-block"
            }
          >
            <Button
              data-test-reserve={!value ? true : null}
              data-test-release={value ? true : null}
              size={"sm"}
              variant={value ? "success" : "outline-secondary"}
              className={"btn w-100"}
              disabled={
                !value &&
                ((freeSeats !== undefined && freeSeats <= 0) ||
                  (props.row.assignments &&
                    props.row.assignments.length &&
                    props.row.assignments[0].license &&
                    props.row.assignments[0].license.active === false))
              }
              action={() => {
                onToggleReservation(props.row.licenseId);
              }}
              icon={<FontAwesomeIcon icon={IconLibrary.icons.faCheck} />}
            >
              {value
                ? intl.formatMessage({
                    defaultMessage: "Release",
                    description:
                      "Button label for toggling license seat reservation",
                  })
                : intl.formatMessage({
                    defaultMessage: "Reserve",
                    description:
                      "Button label for toggling license seat reservation",
                  })}
            </Button>
          </div>
        );
      },
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const value = props.rendererData.resolveValue(props.row);
        return props.row.assignments &&
          props.row.assignments.length &&
          props.row.assignments[0].license &&
          props.row.assignments[0].license.active === false ? (
          value ? (
            <>
              <FormattedMessage
                defaultMessage="Releasing a license is not available as the license has been deactivated."
                description={"tooltip for disabled license release"}
              />
            </>
          ) : (
            <>
              <FormattedMessage
                defaultMessage="Reserving a license is not available as the license has been deactivated."
                description={"tooltip for disabled license reservation"}
              />
            </>
          )
        ) : undefined;
      },
      rendererData: {
        resolveValue: (itm: any) => {
          const firstLevelAssignment = itm.assignments[0];
          const secondLevelAssignment =
            firstLevelAssignment.assignments &&
            firstLevelAssignment.assignments.length
              ? firstLevelAssignment.assignments[0]
              : undefined;

          return !!(
            secondLevelAssignment && secondLevelAssignment.type === "reserved"
          );
        },
      },
    },
    {
      key: "hasDenial",
      isDummy: true,
      label: intl.formatMessage(UserLicensedItemAssignmentsLabels.hasDenial),
      tooltip: intl.formatMessage({
        defaultMessage: "Blocked users are not able to consume a license.",
        description: "Cell heading tooltip",
      }),
      sortable: true,
      align: "center",
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const value = props.rendererData.resolveValue(props.row);
        return (
          <div
            className={
              props.row.assignments &&
              props.row.assignments.length &&
              props.row.assignments[0].license &&
              props.row.assignments[0].license.active === false
                ? "tooltip-disabled-wrapper d-block"
                : "d-block"
            }
          >
            <Button
              data-test-deny={!value ? true : null}
              data-test-allow={value ? true : null}
              variant={value ? "danger" : "outline-secondary"}
              size={"sm"}
              className={"btn w-100"}
              action={() => {
                onToggleDenial(props.row.licenseId);
              }}
              disabled={
                props.row.assignments &&
                props.row.assignments.length &&
                props.row.assignments[0].license &&
                props.row.assignments[0].license.active === false
              }
              icon={value ? <FontAwesomeIcon icon={IconLibrary.icons.faBan} /> : undefined}
            >
              {value
                ? intl.formatMessage({
                    defaultMessage: "Release",
                    description:
                      "Button label for toggling license seat denial",
                  })
                : intl.formatMessage({
                    defaultMessage: "Block",
                    description:
                      "Button label for toggling license seat denial",
                  })}
            </Button>
          </div>
        );
      },
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const value = props.rendererData.resolveValue(props.row);
        return props.row.assignments &&
          props.row.assignments.length &&
          props.row.assignments[0].license &&
          props.row.assignments[0].license.active === false ? (
          value ? (
            <>
              <FormattedMessage
                defaultMessage="Releasing a blocked license is not available as the license has been deactivated."
                description={"tooltip for disabled license denial release"}
              />
            </>
          ) : (
            <>
              <FormattedMessage
                defaultMessage="Blocking a license is not available as the license has been deactivated."
                description={"tooltip for disabled license denial"}
              />
            </>
          )
        ) : undefined;
      },
      rendererData: {
        resolveValue: (itm: any) => {
          const firstLevelAssignment = itm.assignments[0];
          const secondLevelAssignment =
            firstLevelAssignment.assignments &&
            firstLevelAssignment.assignments.length
              ? firstLevelAssignment.assignments[0]
              : undefined;

          return !!(
            secondLevelAssignment && secondLevelAssignment.type === "denied"
          );
        },
      },
    },
    {
      key: "isConsuming",
      sortable: true,
      isDummy: true,
      label: intl.formatMessage(UserLicensedItemAssignmentsLabels.isConsuming),
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const t = props.rendererData.resolveValue(props.row);
        return (
          <>
            {t.length ? (
              t.map((m: any, ind: number) => {
                let from = m.from;
                if (from === "now()") {
                  from = intl.formatDate(new Date(), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                } else if (from) {
                  from = intl.formatDate(new Date(from), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                }
                let to = m.to;
                if (to === "now()") {
                  to = intl.formatDate(new Date(), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                } else if (to) {
                  to = intl.formatDate(new Date(to), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                }
                return (
                  <div className="usage-details" key={"usage_" + (m.id ? m.id + ind : (m.name ? m.name + ind : ind))}>
                    {m.id ? (
                    <div>
                      <strong className="d-inline-block">
                        {intl.formatMessage(
                          LicenseConsumptionLabels.hardwareId
                        )}
                      </strong>
                      <span>{m.id}</span>
                    </div>
                    ) : undefined}
                    {m.name ? (
                      <div>
                        <strong className="d-inline-block">
                          {intl.formatMessage(
                            LicenseConsumptionLabels.hardwareName
                          )}
                        </strong>
                        <span>{m.name}</span>
                      </div>
                    ) : undefined}
                    <div>
                      <strong className="d-inline-block">
                        {intl.formatMessage(LicenseConsumptionLabels.from)}
                      </strong>
                      <span>{from}</span>
                    </div>
                    <div>
                      <strong className="d-inline-block">
                        {intl.formatMessage(LicenseConsumptionLabels.until)}
                      </strong>
                      <span>{to}</span>
                    </div>
                  </div>
                );
              })
            ) : (
                <div className="usage-details no-usage">
                  <FontAwesomeIcon
                      icon={IconLibrary.icons.faTimes}
                      className={"icon d-inline-block"}
                  />
                </div>
            )}
          </>
        );
      },
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const t = props.rendererData.resolveValue(props.row);
        return (
          <>
            {t.length ? (
              t.map((m: any, ind: number) => {
                let from = m.from;
                if (from === "now()") {
                  from = intl.formatDate(new Date(), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                } else if (from) {
                  from = intl.formatDate(new Date(from), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                }
                let to = m.to;
                if (to === "now()") {
                  to = intl.formatDate(new Date(), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                } else if (to) {
                  to = intl.formatDate(new Date(to), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                }
                return (
                  <div className="usage-details" key={"usage_" + (m.id ? m.id + ind : (m.name ? m.name + ind : ind))}>

                    {m.id ? (
                    <div>
                      <strong className="d-inline-block">
                        {intl.formatMessage(
                          LicenseConsumptionLabels.hardwareId
                        )}
                      </strong>
                      <span>{m.id}</span>
                    </div>
                    ) : undefined}
                    {m.name ? (
                      <div>
                        <strong className="d-inline-block">
                          {intl.formatMessage(
                            LicenseConsumptionLabels.hardwareName
                          )}
                        </strong>
                        <span className={"value"}>{m.name}</span>
                      </div>
                    ) : undefined}
                    <div>
                      <FormattedMessage
                        defaultMessage="The license lease is valid from {from} until {to}"
                        description={
                          "Validity information for the license lease. from = formatted date with time for start of validity, to = formatted date with time for end of validity"
                        }
                        values={{
                          from: from,
                          to: to,
                        }}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="usage-details no-usage">
                {intl.formatMessage(
                    UserLicensedItemAssignmentsValues.isConsuming.false
                )}
              </div>
            )}
          </>
        );
      },
      rendererData: {
        resolveValue: (itm: UserWithLicenseUsage) => {
          let retVal: any[] = [];
          if (itm.assignments && itm.assignments.length) {
            retVal = itm.assignments
              .map((a2: any) => {
                return a2.assignments.map((a: any) => {
                  if (a?.sessions && a?.sessions?.length) {
                    return a?.sessions?.map((s: any) => {
                      const t = s.anchors
                        ? s.anchors.map((sa: any) => {
                            return { name: sa.externalName, id: sa.externalId };
                          })
                        : [{}];
                      return t.map((m: any, i: number) => {
                        return { from: s.validFrom, to: s.validUntil, ...m };
                      });
                    });
                  } else {
                    return null;
                  }
                });
              })
              .flat(3)
              .filter((f: any) => !!f);
          }
          return retVal;
        },
      },
    },
  ];
  useEffect(() => {
    if (
      show &&
        clientId &&
      (client === undefined || clientId !== (client.id as string))
    ) {
      onLoadClient(clientId).then((res) => {
        if (!client && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, client, clientId, onLoadClient, onClearError]);
  useEffect(() => {
    if (show && client && licenses === undefined) {
      onLoadLicenses(client.id as string);
    }
  }, [show, client, licenses, onLoadLicenses]);
  const resourceClient = client?.name || "";
  return (
    <>
      <ShowFeedback
        idPrefix={"client_licenses_modals_"}
        onHideFeedback={onHideFeedback}
        feedback={feedback}
      />
      <ConfirmModal
        id={"client-licenses-modal"}
        onExited={onExited}
        onReloadData={() => {
          if ((clientId || client) && onLoadClient) {
            onLoadClient(client ? (client.id as string) : (clientId as string)).then(
              (res) => {
                if (!client && isAddErrorAction(res)) {
                  // only clear error if no data exists, leave if previous data is still available and
                  // let the api error notification handler show error
                  onClearError(res.error?.errorId);
                }
              }
            );
          }
          if ((clientId || client) && onLoadLicenses) {
            onLoadLicenses(client ? (client.id as string) : (clientId as string));
          }
        }}
        isReady={isReady}
        data-test-device-client-licenses-modal
        {...other}
        title={
          isReady && !client
            ? intl.formatMessage({
                defaultMessage: "Device client not found",
                description: "modal heading when device client is not found",
              })
            : intl.formatMessage(
                {
                  defaultMessage: "{name}: licenses",
                  description: "modal heading. 'name' = name of the device client",
                },
                {
                  name: resourceClient,
                }
              )
        }
        show={show}
        onClose={onClose}
        backdrop={licensesChanged ? "static" : true}
        primaryButton={
          !isReady
            ? {
                label: intl.formatMessage({
                  defaultMessage: "Save",
                  description: "primary button label",
                }),
              }
            : !client
            ? {
                label: intl.formatMessage({
                  defaultMessage: "Close",
                  description: "close button label",
                }),
              }
            : !licenses || licenses.length === 0
            ? {
                label: intl.formatMessage({
                  defaultMessage: "Close",
                  description: "close button label",
                }),
              }
            : {
                label: intl.formatMessage({
                  defaultMessage: "Save",
                  description: "primary button label",
                }),
                disabled: !licensesChanged,
                tooltip: !licensesChanged
                  ? intl.formatMessage({
                      defaultMessage: "No changes to save.",
                      description: "primary button disabled tooltip",
                    })
                  : "",
              }
        }
        onPrimaryAction={
          !isReady
            ? () => {
                if (onClose) {
                  onClose();
                }
              }
            : !client
            ? () => {
                if (onClose) {
                  onClose();
                }
              }
            : !licenses || licenses.length === 0
            ? () => {
                if (onClose) {
                  onClose();
                }
              }
            : () => {
                onApply(licenses, client.id as string).then((result) => {
                  const invalidResults: any[] = [];
                  const validresults = result.results?.filter((res: any) => {
                    if (res.type === MANAGE_CLIENTS_LICENSE_ASSIGNMENTS) {
                      if (res && res.errors && res.errors[clientId as string]) {
                        invalidResults.push(res);
                        return false;
                      } else {
                        return true;
                      }
                    } else {
                      return false;
                    }
                  });
                  if (validresults.length) {
                    // some success, show combined feedback
                    onShowFeedback({
                      id: "manageClientAssignments_success_" + clientId,
                      msg: intl.formatMessage(
                        {
                          defaultMessage:
                            "{name}'s licenses updated for: {licenses}.",
                          description:
                            "Success notification for updating license. 'name' = name of the device client, 'licenses' = a list of targeted licenses",
                        },
                        {
                          name: "<strong>" + resourceClient + "</strong>",
                          licenses: validresults
                            .filter((r: any) => !!r)
                            .map(
                              (r: any) =>
                                "<strong>" +
                                LicenseUtils.resolveDisplayName(
                                  r.licenseUsage.licensedItem,
                                  intl.formatMessage(
                                    LicenseValues.licensedItem.displayName
                                      .undefined
                                  )
                                ) +
                                "</strong>"
                            )
                            .join(", "),
                        }
                      ),
                      autoClose: true,
                      type: "success",
                    });
                  }
                  if (invalidResults.length) {
                    // some fail, show combined feedback
                    onShowFeedback({
                      id: "manageClientAssignments_failure_" + clientId,
                      msg: intl.formatMessage(
                        {
                          defaultMessage:
                            "Updating {name}'s licenses failed for: {licenses}.",
                          description:
                            "Failure notification for updating license. 'name' = name of the device client, 'licenses' =  a list of targeted licenses",
                        },
                        {
                          name: "<strong>" + resourceClient + "</strong>",
                          licenses: invalidResults
                            .map(
                              (r) =>
                                resolveLicensedItemNameFromAssignments(
                                  licenses,
                                  r.licenseId,
                                  intl.formatMessage(
                                    LicenseValues.licensedItem.displayName
                                      .undefined
                                  )
                                ) +
                                " (" +
                                r.errors[clientId as string].message +
                                ")"
                            )
                            .join(", "),
                        }
                      ),
                      type: "danger",
                    });
                  }
                });
                if (onClose) {
                  onClose();
                }
              }
        }
        secondaryButton={
          isReady && client && licenses && licenses.length > 0
            ? {
                label: intl.formatMessage({
                  defaultMessage: "Cancel",
                  description: "secondary button label",
                }),
              }
            : undefined
        }
        onSecondaryAction={onClose}
        skipConfirm={!isReady || !client || !licenses || licenses.length <= 0}
        size={
          isReady && client && licenses && licenses.length > 0 ? "lg" : undefined
        }
        confirmTitle={intl.formatMessage({
          defaultMessage: "Edit Licenses",
          description: "modal heading for confirming action",
        })}
        confirmContent={
          <p>
            <FormattedMessage
              defaultMessage="Are you sure you want to make these changes to {name}'s licenses?"
              description="confirm action message. 'name' = targeted device client name"
              values={{
                name: <strong>{resourceClient}</strong>,
              }}
            />
          </p>
        }
        acceptButton={{
          label: intl.formatMessage({
            defaultMessage: "Yes",
            description: "confirm action button label",
          }),
        }}
        cancelButton={{
          label: intl.formatMessage({
            defaultMessage: "No",
            description: "cancel confirmation button label",
          }),
        }}
      >
        {!isReady && (
          <Table<UserLicensedItemAssignments>
            disableLoadingIndicator={!isReady}
            maxRows={15}
            compact={true}
            reset={false}
            pagination={false}
            data={undefined}
            identifyingColumn={"licenseId"}
            columns={columns}
            search={(licenses || []).length > TABLE_SEARCH_THRESHOLD}
            onSearch={onSetActiveSearch}
            activeSearch={activeSearch}
          />
        )}
        {isReady && !client && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p>
              <FormattedMessage
                defaultMessage="Something went wrong and the device client could not be loaded. The device client may have been removed or you don't have sufficient access rights."
                description="message to be shown when there is no device client to display"
              />
            </p>
          </Feedback>
        )}
        {client && isReady && (!licenses || licenses.length === 0) ? (
          <p>
            <FormattedMessage
              defaultMessage="There are no licenses available for assignment."
              description="message to be shown when there are no licenses"
            />
          </p>
        ) : undefined}
        {isReady && client && licenses && licenses.length > 0 ? (
          <Table<UserLicensedItemAssignments>
            disableLoadingIndicator={!isReady}
            maxRows={15}
            compact={true}
            reset={false}
            pagination={false}
            data={licenses || []}
            identifyingColumn={"licenseId"}
            columns={columns}
            search={
              (licenses || []).length > TABLE_SEARCH_THRESHOLD || !!activeSearch
            }
            onSearch={onSetActiveSearch}
            activeSearch={activeSearch}
            header={
              <div className="checkbox-wrapper">
                {
                  <Form.Check
                    type={"switch"}
                    className={"custom-switch-sm"}
                    label={intl.formatMessage({
                      defaultMessage: "Show only valid licenses",
                      description:
                        "Label for filter toggle that controls visibility of invalid licenses in table",
                    })}
                    onChange={() => {
                      onToggleShowAll();
                    }}
                    id={idPrefix + "showAll"}
                    checked={!showAll}
                    value="1"
                  />
                }
              </div>
            }
          />
        ) : undefined}
      </ConfirmModal>
    </>
  );
}
