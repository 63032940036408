import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import { buildActionThunk, forceUndefined } from "./actionHelpers";
import { User } from "../model/User";

/**
 * Gets data of a user by id.
 * @param sender Component requesting for the action
 * @param userId The user id
 */
export function getUser(
  sender: ActionSender,
  userId: string
): ActionTypes.AppThunkAction<ActionTypes.GetUserAction> {
  return buildActionThunk<ActionTypes.GetUserAction, User>(
    sender,
    ActionTypes.GET_USER,
    async () => await idpApi.getUser(userId),
    (type, user) => ({
      type,
      user: forceUndefined(user),
      userId: userId
    })
  );
}

/**
 * Deletes user with the given id.
 * @param sender Component requesting for the action
 * @param userId The user id
 */
export function deleteUser(
  sender: ActionSender,
  userId: string
): ActionTypes.AppThunkAction<ActionTypes.DeleteUserAction> {
  return buildActionThunk<ActionTypes.DeleteUserAction, void>(
    sender,
    ActionTypes.DELETE_USER,
    async () => await idpApi.deleteUser(userId),
    type => ({
      type,
      userId
    })
  );
}

/**
 * Updates user data by replacing current data with the given data.
 * @param sender Component requesting for the action
 * @param user The new user data
 */
export function replaceUser(
  sender: ActionSender,
  user: User
): ActionTypes.AppThunkAction<ActionTypes.ReplaceUserAction> {
  return buildActionThunk<ActionTypes.ReplaceUserAction, User>(
    sender,
    ActionTypes.REPLACE_USER,
    async () => await idpApi.replaceUser(user),
    (type, user) => ({
      type,
      user: forceUndefined(user)
    })
  );
}

/**
 * Sets user suspended from this instant of time, or removes suspension.
 * (Implementation detail with IdP V1 API: this is done by controlling the
 * user validUntil field).
 * @param sender Component requesting for the action
 * @param suspended true to mark user as suspended, false to mark user as
 *    active / enabled
 * @param userId The user id
 */
export function setUserSuspended(
  sender: ActionSender,
  suspended: boolean,
  userId: string
): ActionTypes.AppThunkAction<ActionTypes.SetUserSuspendedAction> {
  return buildActionThunk<ActionTypes.SetUserSuspendedAction, User>(
    sender,
    ActionTypes.SET_USER_SUSPENDED,
    async () => await idpApi.setUserSuspended(suspended, userId),
    (type, user) => ({
      type,
      user: forceUndefined(user),
      suspended
    })
  );
}

/**
 * Deletes second factor authentication (MFA) configuration (TOTP credential)
 * of the given user. This may be necessary e.g. if user has lost access
 * to her TOTP device.
 * @param sender Component requesting for the action
 * @param userId The user id
 */
export function deleteOtpCredential(
  sender: ActionSender,
  userId: string
): ActionTypes.AppThunkAction<ActionTypes.DeleteOtpCredentialAction> {
  return buildActionThunk<ActionTypes.DeleteOtpCredentialAction, void>(
    sender,
    ActionTypes.DELETE_OTP_CREDENTIAL,
    async () => await idpApi.deleteOtpCredential(userId),
    type => ({
      type,
      userId
    })
  );
}

/**
 * Import users to system.
 * @param sender Component requesting for the action
 * @param users Users to import.
 */
export function importUsers(
  sender: ActionSender,
  users: User[]
): ActionTypes.AppThunkAction<ActionTypes.ImportUsersAction> {
  return buildActionThunk<ActionTypes.ImportUsersAction, User[]>(
    sender,
    ActionTypes.IMPORT_USERS,
    async () => await idpApi.importUsers(users),
    type => ({
      type,
      users
    })
  );
}
