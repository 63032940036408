import { connect } from "react-redux";
import { AppState } from "../../../store/AppState";
import OrganizationUtils from "../../../utils/organization";
import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../../util/typeUtil";
import Dashboard, {
  DashboardProps as _DashboardProps
} from "./dashboard-container";
export { ModalKeys } from "./dashboard-container";

export interface DashboardProps extends Omit<_DashboardProps, "organization"> {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_DashboardProps, DashboardProps>;

// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _DashboardProps,
  DashboardProps
>;

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    organization: OrganizationUtils.selectSelectedOrganization(state)
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return OrganizationUtils.compareSelectedOrganizationStates(next, prev);
}

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  DashboardProps,
  AppState
>(mapStateToProps, {}, null, {
  areStatesEqual
})(Dashboard);
