import { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import Table, { TableColumn } from "../../table";
import { Dropdown } from "react-bootstrap";
import "./device-clients-view.scss";
import CreateDeviceClientInvitationModal from "../../modals/create-device-client-invitation-modal";
import ManageDeviceClientsDeviceClientGroupsModal from "../../modals/manage-device-clients-device-client-groups-modal";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import { hasAction, resolveColumns } from "../../../ui-configuration/configuration-tools";
import DeviceClientLicensesModal from "../../modals/device-client-licenses-modal";
import RemoveDeviceClientModal from "../../modals/remove-device-client-modal";
import {Client} from "../../../model/Client";
import {ClientLabels} from "../../../localization/client";
import ViewDeviceClientModal from "../../modals/view-device-client-modal";
import {FeedbackContainerProps, ShowFeedback, Page, PageDOMProps, Button, IconLibrary} from "@10duke/dukeui";
import HeaderActions from "../../table/header-actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export enum ModalKeys {
  invite = "invite",
  licenses = "licenses",
  remove = "remove",
  show = "show",
  edit = "edit",
  groups = "groups",
}
//<editor-fold desc="Props">

export interface DeviceClientsModalVisibilityProps {
  showModal?: { key: ModalKeys; clientId?: string };
  onShowModal: (key: ModalKeys, clientId?: string) => void;
  onHideModal: (callback?: () => void) => void;
}
export interface DeviceClientsDOMProps extends PageDOMProps {}

export interface DeviceClientsStateProps extends FeedbackContainerProps {
  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;
  selected: Client[];
  onSetSelected: (selection: Client[]) => void;
}
export interface DeviceClientsProps
  extends DeviceClientsStateProps,
      DeviceClientsDOMProps,
      DeviceClientsModalVisibilityProps {
  clients?: Client[];
  onLoadClients: () => Promise<any>;
}
//</editor-fold>

//<editor-fold desc="Utils">

const resolveRowClasses = (row: Client, rowIndex: number) => {
  return "";// row.status;
};

//</editor-fold>

function DeviceClients(props: DeviceClientsProps) {
  //<editor-fold desc="Local variables">
  const {
    selected,
    onSetSelected,
    onShowModal,
    onHideModal,
    showModal,
    activeSearch,
    onSetActiveSearch,
    onShowFeedback,
    onHideFeedback,
    feedback,
    clients,
    onLoadClients,
    ...others
  } = props;

  // this is more like a variable than a hook
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: "Manage Device clients",
    description: "Heading for the Manage device clients page",
  });
  const description = intl.formatMessage({
    defaultMessage: "Organization device client management",
    description: "window meta description for the Manage device clients page",
  });
  let columns: TableColumn[] = [
    {
      key: "id",
      label: intl.formatMessage(ClientLabels.id),
      isTechnical: true,
      hidden: true,
    },
    {
      key: "name",
      label: intl.formatMessage(ClientLabels.name),
      sortable: true,
    },
    {
      key: "clientId",
      label: intl.formatMessage(ClientLabels.clientId),
      sortable: true,
    }
  ];
  //</editor-fold>

  //<editor-fold desc="Hooks">
  const { conf } = useContext(UIConfiguration);
  const devicesConf =
    conf.functionality && conf.functionality["device-clients"]
      ? conf.functionality["device-clients"]
      : {};

  if (devicesConf.columns && devicesConf.columns.length > 0) {
    columns = resolveColumns(columns, devicesConf.columns);
  }
  // resets selection when users are undefined, and clears out selected items that are not part of updated data
  useEffect(() => {
    if (selected.length) {
      if (clients === undefined) {
        onSetSelected([]);
      } else {
        const ids = clients.map((c) => c.id);
        const newS = selected.filter((itm: any) => {
          return ids.indexOf(itm.id) >= 0;
        });
        if (newS.length !== selected.length) {
          onSetSelected(newS);
        }
      }
    }
  }, [clients, selected, onSetSelected]);


  //</editor-fold>
  return (
    <Page
      header={<h1>{title}</h1>}
      data-test-devices-page
      id={"device-clients-page"}
      meta={{
        title,
        description,
      }}
      {...others}
    >
      <ShowFeedback
        idPrefix={"device-clients_"}
        onHideFeedback={onHideFeedback}
        feedback={feedback}
      />
      <Table<Client>
        data-test-devices-table
        persistStateKey={"device-clients-table"}
        allowColumnSort={true}
        selection={{
          multi: false,
          selectAll: false,
        }}
        header={<HeaderActions actions={devicesConf.actions} actionRenderer={(action) => {
            let retVal = undefined;
            if (action.key === 'invite') {
                retVal = (
                    <Button
                        key={action.key}
                        data-test-invite-device-trigger
                        action={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onShowModal(ModalKeys.invite);
                        }}
                        variant={"primary"}
                        className={"btn custom-base"}
                    >
                        {intl.formatMessage({
                            defaultMessage: "Invite",
                            description: "Label for the Invite button",
                        })}
                    </Button>
                );
            } else if (!!action.url) {
                retVal = (
                    <Button
                        key={action.key}
                        data-test-custom-action={action.key}
                        action={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.open(action.url, '_blank');
                        }}
                        variant={"primary"}
                        className={"btn custom-base"}
                    >
                        {intl.formatMessage({
                            defaultMessage: "{action, select, other {{action}}}",
                            description: "Custom header action label. Prints action key by default. Inject `actionKey {Label}` after the `select,` and before the `other` to provide translation for custom action. Only use space as separator with multiple actions, no comma.",
                        }, {
                            action: action.key
                        })}
                    </Button>
                );
            }
            return retVal;
        }} />}
        search={true}
        activeSearch={activeSearch}
        onSearch={onSetActiveSearch}
        rowClasses={resolveRowClasses}
        selected={selected}
        onSelectionChanged={onSetSelected}
        columns={columns}
        columnToggle
        reload={true}
        onLoadData={onLoadClients}
        data={clients ? clients : undefined}
        identifyingColumn={"id"}
        pagination={false}
        rowTools={(props: { rowEntry: Client }) => (
                devicesConf.rowActions?.map((m) => {
                    let retVal = undefined;
                    if (!m.disabled) {
                        if (m.key === 'show') {
                            retVal = (
                                <Dropdown.Item
                                    data-test-row-tool-client-details={props.rowEntry.id}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onShowModal(ModalKeys.show, props.rowEntry.id);
                                    }}
                                >
                                    {intl.formatMessage({
                                        defaultMessage: "Client details",
                                        description: "Label for the tool",
                                    })}
                                </Dropdown.Item>
                            );
                        } else if (m.key === 'groups') {
                            retVal = (
                                <Dropdown.Item
                                    data-test-row-tool-client-groups={props.rowEntry.id}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onShowModal(ModalKeys.groups, props.rowEntry.id);
                                    }}
                                >
                                    {intl.formatMessage({
                                        defaultMessage: "Client groups",
                                        description: "Label for the tool",
                                    })}
                                </Dropdown.Item>
                            );
                        } else if (m.key === 'licenses') {
                            retVal = (
                                <Dropdown.Item
                                    data-test-row-tool-client-licenses={props.rowEntry.id}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onShowModal(ModalKeys.licenses, props.rowEntry.id);
                                    }}
                                >
                                    {intl.formatMessage({
                                        defaultMessage: "Licenses",
                                        description: "Label for the tool",
                                    })}
                                </Dropdown.Item>
                            );
                        } else if (m.key === 'remove') {
                            retVal = (
                                <Dropdown.Item
                                    data-test-row-tool-client-remove={props.rowEntry.id}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onShowModal(ModalKeys.remove, props.rowEntry.id);
                                    }}
                                >
                                    {intl.formatMessage({
                                        defaultMessage: "Remove",
                                        description: "Label for the tool",
                                    })}
                                </Dropdown.Item>
                            );
                        } else if (!!m.url) {
                            retVal = (
                                <Dropdown.Item
                                    data-test-row-tool-custom={m.key}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        window.open(m.url, '_blank');
                                    }}
                                    className={'custom-action'}
                                >
                                    <span>{intl.formatMessage({
                                        defaultMessage: "{action, select, other {{action}}}",
                                        description: "Custom row action label. Prints action key by default. Inject `actionKey {Label}` after the `select,` and before the `other` to provide translation for custom action. Only use space as separator with multiple actions, no comma.",
                                    }, {
                                        action: m.key
                                    })}</span>
                                    <FontAwesomeIcon icon={IconLibrary.icons.faExternalLinkAlt} className={'icon'} fixedWidth={true} />
                                </Dropdown.Item>
                            );
                        }
                    }
                    return retVal;
                })
        )}
      />

      {hasAction(devicesConf.rowActions, 'remove') && (
        <RemoveDeviceClientModal
          show={showModal ? showModal.key === ModalKeys.remove : false}
          onClose={onHideModal}
          onShowFeedback={onShowFeedback}
          clientId={showModal?.clientId}
        />
      )}

      {hasAction(devicesConf.rowActions, 'show') && (
        <ViewDeviceClientModal
          show={showModal ? showModal.key === ModalKeys.show || showModal.key === ModalKeys.edit : false}
          allowEdit={devicesConf.allowEdit ? true : false}
          edit={showModal?.key === ModalKeys.edit}
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
          clientId={showModal?.clientId}
        />
      )}

      {hasAction(devicesConf.rowActions, 'groups') && (
        <ManageDeviceClientsDeviceClientGroupsModal
          show={showModal ? showModal.key === ModalKeys.groups : false}
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
          clientId={showModal ? showModal.clientId : undefined}
        />
      )}

      {hasAction(devicesConf.rowActions, 'licenses') && (
        <DeviceClientLicensesModal
          show={showModal ? showModal.key === ModalKeys.licenses : false}
          clientId={showModal && showModal.clientId}
          onClose={onHideModal}
        />
      )}
      {hasAction(devicesConf?.actions, 'invite') && (
        <CreateDeviceClientInvitationModal
          show={showModal ? showModal.key === ModalKeys.invite : false}
          onClose={onHideModal}
          onShowFeedback={onShowFeedback}
        />
      )}
    </Page>
  );
}

export default DeviceClients;
