import {
  DEFAULT_UI_CONFIGURATION, OrgadminActionConfiguration,
  OrgadminColumnConfiguration,
  OrgadminConfiguration,
  OrgadminFilterConfiguration,
  OrgadminLocalizationConfiguration,
  OrgadminLocalizationLocale, OrgadminMenuItemConfiguration,
} from "./orgadmin-configuration";
import cloneDeep from "lodash/cloneDeep";
import { TableColumn } from "../components/table";
import { compareVersions } from "@10duke/dukeui";
import {TableFilter} from "../components/table/table-container";

/**
 * Simple utility for resolving if conf includes requested action that is not disabled
 * @param conf
 */
export function hasAction(conf: OrgadminActionConfiguration[]|undefined, name: string): boolean {
  return !!conf?.find((f) => !f.disabled && f.key === name);
}
export function resolveColumns(
  uiColumns: TableColumn[],
  confColumns: OrgadminColumnConfiguration[]
): TableColumn[] {
  const tempCols = ([] as TableColumn[]).concat(...uiColumns);
  // ensures that the id column is always first
  let newCols: Array<TableColumn | undefined> = tempCols.splice(0, 1);
  // get actual columns in the order of configuration, with overridden hidden state
  newCols = newCols.concat(
    ...confColumns
      .map((v) => {
        const ind = tempCols.findIndex((cv) => v.key === cv.key);
        if (ind >= 0) {
          // Col found from config, remove from temp holder
          const c = tempCols.splice(ind, 1)[0];
          if (v.disabled) {
            // Remove disabled columns
            return undefined;
          } else {
            if (v.hidden !== undefined) {
              // override hidden state
              c.hidden = v.hidden;
            }
            return c;
          }
        } else {
          // Configured col does not exist, ignoring
          if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
            // we now have some dynamic columns that are not included depending on non-column-definition specific conf values.
            console.warn("Configured column does not exist %o", v.key);
          }
          return undefined;
        }
      })
      .filter((v) => !!v)
  );
  if (tempCols.length) {
    // add the remaining columns to the end
    newCols = newCols.concat(...tempCols);
  }
  if (newCols.filter((v: any) => !v.isTechnical && !v.hidden).length === 0) {
    // No visible columns, 1 is required so we switch the first on technical to visible
    console.warn(
      "Configured columns do not include required visible column %o",
      confColumns
    );
    for (let i = 0; i < newCols.length; i += 1) {
      const yetanothertmpassignment: TableColumn = newCols[i] as TableColumn;
      if (yetanothertmpassignment && !yetanothertmpassignment.isTechnical) {
        yetanothertmpassignment.hidden = false;
        newCols[i] = yetanothertmpassignment;
        break;
      }
    }
  }
  return newCols as TableColumn[];
}

/**
 * Resolves filter setup to use by combining ui supported filters with configuration.
 * @param uiFilters
 * @param confFilters
 */
export function resolveFilters(uiFilters: TableFilter[], confFilters?: OrgadminFilterConfiguration[]): TableFilter[] {
  return (confFilters ?
      confFilters.map((c) => {
        // convert conf to ui model to keep order of conf
        const filt = uiFilters.find((f) => f.key === c.key);
        // not supported and disabled are ignored by returning undefined
        return !filt || c.disabled ? undefined : {
          key: c.key,
          initial: c.active === true,
          apply: filt.apply,
          view: filt.view,
        } as TableFilter;
      }).filter((f) => !!f) as TableFilter[] :
      [] as TableFilter[]
  ).concat(uiFilters.map((m) => {
    const c = confFilters?.find((f) => f.key === m.key);
    if (!c ) {
      // not defined in active conf, return ui default just like with columns.
      return m;
    } else if (c.disabled) {
      // defined as disabled, so remove from filter set.
      return undefined;
    } else {
      // defined and enabled, return with configured default
      return {
        ...m,
        initial: c.active,
      };
    }
  }).filter((f) => !!f) as TableFilter[])
  // remove duplicates leaving only the first
  .filter((f, i, r) => r.findIndex((rf) => rf.key === f.key) === i);
}
/**
 * Utility for resolving a value for a section feature when merging ui conf with defaults
 * @param uiConf Provided ui conf
 * @param defaults Default ui conf for requested conf version
 * @param section Name of section
 * @param feature Name of feature
 */
function resolveSectionFeature(
  uiConf: OrgadminConfiguration,
  defaults: OrgadminConfiguration,
  section: string,
  feature: string
):
  | boolean
  | string
    | OrgadminColumnConfiguration[]
    | OrgadminActionConfiguration[]
    | OrgadminFilterConfiguration[]
  | OrgadminLocalizationLocale[] {
  let retVal: | boolean
      | string
      | OrgadminColumnConfiguration[]
      | OrgadminActionConfiguration[]
      | OrgadminFilterConfiguration[]
      | OrgadminLocalizationLocale[];
  // Can't use strings to even attempt to access properties of a typed object, no matter how well you sanity check and
  // ensure all is good. So we need to use this hack
  // and cast the string to whatever it may be after the keyof typeof.
  type SectionKey = keyof typeof uiConf.functionality;
  const sectionAsSectionKey = section as SectionKey;
  if (
    uiConf.functionality &&
    uiConf.functionality[sectionAsSectionKey] &&
    uiConf.functionality[sectionAsSectionKey][feature] !== undefined
  ) {
    if (feature === 'filters' &&
        defaults.functionality &&
        defaults.functionality[sectionAsSectionKey] &&
        defaults.functionality[sectionAsSectionKey][feature] !== undefined) {
      retVal = (uiConf.functionality[sectionAsSectionKey][feature] as Array<OrgadminFilterConfiguration>)
          // populate defaults to fill partially defined filters
          .concat(...defaults.functionality[sectionAsSectionKey][feature] as Array<OrgadminFilterConfiguration>)
          // remove duplicates
          .filter((f, i, r) => r.findIndex((rf) => rf.key === f.key) === i);
    } else if (feature === 'actions' &&
        defaults.functionality &&
        defaults.functionality[sectionAsSectionKey] &&
        defaults.functionality[sectionAsSectionKey][feature] !== undefined) {
      retVal = (uiConf.functionality[sectionAsSectionKey][feature] as Array<OrgadminActionConfiguration>)
          // populate defaults to fill partially defined filters
          .concat(...defaults.functionality[sectionAsSectionKey][feature] as Array<OrgadminActionConfiguration>)
          // remove duplicates
          .filter((f, i, r) => r.findIndex((rf) => rf.key === f.key) === i);
    }  else if (feature === 'rowActions' &&
        defaults.functionality &&
        defaults.functionality[sectionAsSectionKey] &&
        defaults.functionality[sectionAsSectionKey][feature] !== undefined) {
      retVal = (uiConf.functionality[sectionAsSectionKey][feature] as Array<OrgadminActionConfiguration>)
          // populate defaults to fill partially defined filters
          .concat(...defaults.functionality[sectionAsSectionKey][feature] as Array<OrgadminActionConfiguration>)
          // remove duplicates
          .filter((f, i, r) => r.findIndex((rf) => rf.key === f.key) === i);
    } else {
      retVal = uiConf.functionality[sectionAsSectionKey][feature];
    }
  } else if (
    defaults.functionality &&
    defaults.functionality[sectionAsSectionKey] &&
    defaults.functionality[sectionAsSectionKey][feature] !== undefined
  ) {
    retVal = defaults.functionality[sectionAsSectionKey][feature];
  } else {
    // theoretical, should never happen
    retVal = true;
  }
  return retVal;
}

/**
 * Utility for resolving default values matching the version of the used ui configuration
 * @param version
 */
function resolveDefaults(version: string): OrgadminConfiguration {
  let uiConf = cloneDeep(DEFAULT_UI_CONFIGURATION);
  if (
    compareVersions(DEFAULT_UI_CONFIGURATION.version as string, version) > 0
  ) {
    // old version
    if (compareVersions("1.0.0", version || "") > 0) {
      // version is older than 1.0.0
      // no change in default behaviour, only the conf structure has changed
    }
    if (compareVersions("0.7.0", version || "") > 0) {
      // version is older than 0.7.0
      // new sections are off by default, all new functionality in existing sections is also off so no need to change anything.
    }
    if (compareVersions("0.6.0", version || "") > 0) {
      // version is older than 0.6.0
      // new item uiConf.functionality.enableReleaseLicenseLease = false added, but as default is false there is no
      // change to migrate
    }
    if (compareVersions("0.5.0", version || "") > 0) {
      // version is older than 0.5.0
      if (
          !!uiConf &&
          !!uiConf.functionality &&
          !!uiConf.functionality.invitations &&
          !!uiConf.functionality.invitations.columns
      ) {
        // disable added columns
        uiConf.functionality.invitations.columns = uiConf.functionality.invitations.columns.map((c) => {
          if (c.key === 'validFrom' || c.key === 'validUntil') {
            return {
              ...c,
              disabled: true,
            }
          } else {
            return c;
          }
        })
      }
      if (!!uiConf?.functionality?.groups?.filters) {
        uiConf.functionality.groups.filters = uiConf.functionality.groups.filters.map((f) => {
          return {
            ...f,
            disabled: true,
          };
        });
      }
      if (!!uiConf?.functionality?.roles?.filters) {
        uiConf.functionality.roles.filters = uiConf.functionality.roles.filters.map((f) => {
          return {
            ...f,
            disabled: true,
          };
        });
      }
      if (!!uiConf?.functionality?.invitations?.filters) {
        uiConf.functionality.invitations.filters = uiConf.functionality.invitations.filters.map((f) => {
          return {
            ...f,
            disabled: true,
          };
        });
      }
      if (!!uiConf?.functionality?.users?.filters) {
        uiConf.functionality.users.filters = uiConf.functionality.users.filters.map((f) => {
          return {
            ...f,
            disabled: true,
          };
        });
      }
      if (!!uiConf?.functionality?.licenses?.filters) {
        uiConf.functionality.licenses.filters = uiConf.functionality.licenses.filters.map((f) => {
          // the orgadmin released with conf 0.4.0 included a non configurable filter, so we need to enable it with
          // the new conf
          if (f.key === 'showOnlyValid') {
            return {
              ...f,
              disabled: false,
            };
          } else {
            return {
              ...f,
              disabled: true,
            };
          }
        });
      }
    }
    if (compareVersions("0.4.0", version || "") > 0) {
      // version is older than 0.4.0
      if (
        !!uiConf &&
        !!uiConf.functionality &&
        !!uiConf.functionality.localization
      ) {
        if (!!uiConf.functionality.localization.locales) {
          // remove locale aliases
          uiConf.functionality.localization.locales =
            uiConf.functionality.localization.locales.map((l) => {
              const t = { ...l };
              delete t.alias;
              return t;
            });
        }
        // disable autodetect
        uiConf.functionality.localization.autoDetect = false;
      }
    }
    /*
    * new enableDownloadLicenseVersion added, no need to change anything as default value is false.
    * leaving this code here as documentation
    if (compareVersions("0.3.2", version || "") > 0) {
      // version is older than 0.3.2
      if (!!uiConf) {
        if (!!uiConf.functionality) {
          uiConf.functionality.enableDownloadLicenseVersion = false;
        }
      }
    }*/
    if (compareVersions("0.3.1", version || "") > 0) {
      // version is older than 0.3.1
      // this version disables the new roles management, no need to change anything unless "0.3.0" where the
      // roles are enabled
      if (compareVersions("0.3.0", version || "") === 0) {
        // in "0.3.0" the roles management must remain enabled
        if (!!uiConf) {
          if (!!uiConf.functionality) {
            if (!!uiConf.functionality.sections) {
              uiConf.functionality.sections = ["roles"].concat([
                ...uiConf.functionality.sections,
              ]) as (
                | "groups"
                | "roles"
                | "invitations"
                | "users"
                | "licenses"
              )[];
            }
            if (!!uiConf.functionality.users) {
              uiConf.functionality.users.roles = true;
            }
          }
        }
      }
    }
    if (compareVersions("0.3", version || "") > 0) {
      // version is older than 0.3
      // the new identity-api conf default matches old behaviour, so no need for changes
      // disable the new role management added in 0.3
      if (!!uiConf) {
        if (!!uiConf.functionality) {
          if (!!uiConf.functionality.sections) {
            uiConf.functionality.sections =
              uiConf.functionality.sections.filter((s) => s !== "roles");
          }
          if (!!uiConf.functionality.users) {
            uiConf.functionality.users.roles = false;
          }
        }
      }
    }
    if (compareVersions("0.2", version || "") > 0) {
      // version is older than 0.2
      // disable the new columns added in 0.2 and both variants of the new downloadLicense dialog

      // columns added in this version
      const newColumnKeysV020 = ["resolvedAllowedVersions"];
      // The following objects are actually never undefined in the default conf.
      // We could have separate types for uiconf and default conf, but that would require that everything is typed
      // twice, and kept in perfect sync, just to allow for other to be optional. Adding these useless sanity checks
      // seems stupid but less error prone
      if (
        !!uiConf &&
        !!uiConf.functionality &&
        !!uiConf.functionality.licenses &&
        !!uiConf.functionality.licenses.columns
      ) {
        uiConf.functionality.licenses.columns =
          uiConf?.functionality?.licenses?.columns?.map((col: any) => {
            if (newColumnKeysV020.indexOf(col.key) >= 0) {
              return {
                ...col,
                disabled: true,
              };
            } else {
              return col;
            }
          });
      } else {
        throw new Error(
          "Default UI configuration is not a valid default UI configuration"
        );
      }
      if (!!uiConf && !!uiConf.functionality && !!uiConf.functionality.users) {
        uiConf.functionality.users.downloadLicense = false;
      } else {
        throw new Error(
          "Default UI configuration is not a valid default UI configuration"
        );
      }
      if (
        !!uiConf &&
        !!uiConf.functionality &&
        !!uiConf.functionality.licenses
      ) {
        uiConf.functionality.licenses.downloadLicense = false;
      } else {
        throw new Error(
          "Default UI configuration is not a valid default UI configuration"
        );
      }
    }
    if (compareVersions("0.1.1", version) > 0) {
      // version is older than 0.1.1
      // remove new locales
      if (
        !!uiConf &&
        !!uiConf.functionality &&
        !!uiConf.functionality.localization &&
        !!uiConf.functionality.localization.locales
      ) {
        uiConf.functionality.localization.locales =
          uiConf.functionality.localization.locales.filter(
            (l) => l.value !== "cs" && l.value !== "sk"
          );
      } else {
        throw new Error(
          "Default UI configuration is not a valid default UI configuration"
        );
      }
    }
    /*
       * Disabled as it's not needed, but leaving here in comments for documentation.
      if (compareVersions("0.1", version) > 0) {
        // version is older than 0.1
        // only format was changed, all values remain the same so no need to migrate
      }
      */
  }
  return uiConf;
}

function migrateConfTo_0_1_0(
  conf: OrgadminConfiguration
): OrgadminConfiguration {
  let uiConf = cloneDeep(conf);
  if (compareVersions("0.1", uiConf.version || "") > 0) {
    // transform the old "locale-switcher" to the new "localization" format
    if (
      uiConf.functionality &&
      (uiConf.functionality as any)["locale-switcher"]
    ) {
      if (!uiConf.functionality.localization) {
        // no type exists for the old conf format, so we cast to any
        const clone = cloneDeep(
          (uiConf.functionality as any)["locale-switcher"]
        ) as any;
        if (clone.show !== undefined) {
          clone["show-switcher"] = clone.show;
          delete clone.show;
        }
        uiConf.functionality.localization =
          clone as OrgadminLocalizationConfiguration;
      }
      delete (uiConf.functionality as any)["locale-switcher"];
    }
    uiConf.version = "0.1.0";
  }
  return uiConf;
}

function migrateConfTo_0_1_1(
  conf: OrgadminConfiguration
): OrgadminConfiguration {
  let uiConf = cloneDeep(conf);
  if (compareVersions("0.1.1", uiConf.version || "") > 0) {
    // The structure of the conf is unchanged, only new items added, so no need to change anything but the version
    uiConf.version = "0.1.1";
  }
  return uiConf;
}

function migrateConfTo_0_2_0(
  conf: OrgadminConfiguration
): OrgadminConfiguration {
  let uiConf = cloneDeep(conf);
  if (compareVersions("0.2", uiConf.version || "") > 0) {
    // The structure of the conf is unchanged, only new items added, so no need to change anything but the version
    uiConf.version = "0.2.0";
  }
  return uiConf;
}
function migrateConfTo_0_3_0(
  conf: OrgadminConfiguration
): OrgadminConfiguration {
  let uiConf = cloneDeep(conf);
  if (compareVersions("0.3", uiConf.version || "") > 0) {
    // The structure of the conf is unchanged, only new items added, so no need to change anything but the version
    uiConf.version = "0.3.0";
  }
  return uiConf;
}
function migrateConfTo_0_3_1(
  conf: OrgadminConfiguration
): OrgadminConfiguration {
  let uiConf = cloneDeep(conf);
  if (compareVersions("0.3.1", uiConf.version || "") > 0) {
    // The structure of the conf is unchanged, only new defaults changed
    uiConf.version = "0.3.1";
  }
  return uiConf;
}
function migrateConfTo_0_3_2(
  conf: OrgadminConfiguration
): OrgadminConfiguration {
  let uiConf = cloneDeep(conf);
  if (compareVersions("0.3.2", uiConf.version || "") > 0) {
    // The structure of the conf is unchanged, only new defaults changed
    uiConf.version = "0.3.2";
  }
  return uiConf;
}
function migrateConfTo_0_4_0(
    conf: OrgadminConfiguration
): OrgadminConfiguration {
  let uiConf = cloneDeep(conf);
  if (compareVersions("0.4.0", uiConf.version || "") > 0) {
    // The structure of the conf is unchanged, only new defaults changed
    uiConf.version = "0.4.0";
  }
  return uiConf;
}
function migrateConfTo_0_5_0(
    conf: OrgadminConfiguration
): OrgadminConfiguration {
  let uiConf = cloneDeep(conf);
  if (compareVersions("0.5.0", uiConf.version || "") > 0) {
    uiConf.version = "0.5.0";
    // remove filters if any
    if (!!uiConf.functionality?.groups?.filters) {
      delete uiConf.functionality.groups.filters;
    }
    if (!!uiConf.functionality?.roles?.filters) {
      delete uiConf.functionality.roles.filters;
    }
    if (!!uiConf?.functionality?.invitations?.filters) {
      delete uiConf.functionality.invitations.filters;
    }
    if (!!uiConf?.functionality?.users?.filters) {
      delete uiConf.functionality.users.filters;
    }
    if (!!uiConf?.functionality?.licenses?.filters) {
      delete uiConf.functionality.licenses.filters;
    }
  }
  return uiConf;
}
function migrateConfTo_0_6_0(
    conf: OrgadminConfiguration
): OrgadminConfiguration {
  let uiConf = cloneDeep(conf);
  if (compareVersions("0.6.0", uiConf.version || "") > 0) {
    // The structure of the conf is unchanged, only new items added, so no need to change anything but the version
    uiConf.version = "0.6.0";
  }
  return uiConf;
}

function migrateConfTo_0_7_0(
    conf: OrgadminConfiguration
): OrgadminConfiguration {
  let uiConf = cloneDeep(conf);
  if (compareVersions("0.7.0", uiConf.version || "") > 0) {
    // The structure of the conf is unchanged, only new items added, so no need to change anything but the version
    uiConf.version = "0.7.0";
    if (
        !!uiConf &&
        !!uiConf.functionality &&
        !!uiConf.functionality.invitations
    ) {
      if (uiConf.functionality.invitations.enableClientInvitations) {
        // disable device invitations
        delete uiConf.functionality.invitations.enableClientInvitations;
      }
      if (!uiConf.functionality.invitations.enableUserInvitations) {
        // enable user invitations
        delete uiConf.functionality.invitations.enableUserInvitations;
      }
    }
    if (
        !!uiConf &&
        !!uiConf.functionality &&
        !!uiConf.functionality.sections
    ) {
      // remove the new sections.
      uiConf.functionality.sections = uiConf.functionality.sections.filter(
          (f) => f !== "device-clients" && f !== "device-client-groups");
    }
    if (
        !!uiConf &&
        !!uiConf.functionality &&
        !!uiConf.functionality["device-clients"]
    ) {
      // remove the new section conf.
      delete uiConf.functionality["device-clients"];
    }
    if (
        !!uiConf &&
        !!uiConf.functionality &&
        !!uiConf.functionality["device-client-groups"]
    ) {
      // remove the new section conf.
      delete uiConf.functionality["device-client-groups"];
    }


  }
  return uiConf;
}


function migrateConfTo_1_0_0(
    conf: OrgadminConfiguration
): OrgadminConfiguration {
  let uiConf = cloneDeep(conf);
  if (compareVersions("1.0.0", uiConf.version || "") > 0) {
    uiConf.version = "1.0.0";
    if (!!uiConf && !!uiConf.functionality) {
      if (!!uiConf.functionality.header) {
        delete uiConf.functionality.header;
      }
      const localizationConfWithoutTyping = uiConf.functionality.localization as (any|undefined);
      if (localizationConfWithoutTyping?.["show-switcher"] !== undefined) {
        uiConf.functionality.header = {
          "show-locale-switcher": localizationConfWithoutTyping["show-switcher"],
        };
        delete localizationConfWithoutTyping["show-switcher"]
        uiConf.functionality.localization = localizationConfWithoutTyping as OrgadminLocalizationConfiguration;
      }

      if (!!uiConf.functionality.groups) {
        // Move section actions into "actions"
        if (uiConf.functionality.groups.actions) {
          delete uiConf.functionality.groups.actions;
        }
        if (Object.hasOwn(uiConf.functionality.groups, 'create')) {
          uiConf.functionality.groups.actions = [];
          uiConf.functionality.groups.actions.push({
            key: 'create',
            disabled: !uiConf.functionality.groups.create
          });
          delete uiConf.functionality.groups.create;
        }

        // Move item actions into "rowActions"
        const rowActions = [];
        if (uiConf.functionality.groups.rowActions) {
          delete uiConf.functionality.groups.rowActions;
        }
        if (Object.hasOwn(uiConf.functionality.groups, 'show')) {
          rowActions.push({
            key: 'show',
            disabled: !uiConf.functionality.groups.show
          });
          delete uiConf.functionality.groups.show;
        }
        if (Object.hasOwn(uiConf.functionality.groups, 'members')) {
          rowActions.push({
            key: 'members',
            disabled: !uiConf.functionality.groups.members
          });
          delete uiConf.functionality.groups.members;
        }
        if (Object.hasOwn(uiConf.functionality.groups, 'invite')) {
          rowActions.push({
            key: 'invite',
            disabled: !uiConf.functionality.groups.invite
          });
          delete uiConf.functionality.groups.invite;
        }
        if (Object.hasOwn(uiConf.functionality.groups, 'remove')) {
          rowActions.push({
            key: 'remove',
            disabled: !uiConf.functionality.groups.remove
          });
          delete uiConf.functionality.groups.remove;
        }
        if (Object.hasOwn(uiConf.functionality.groups, 'edit')) {
          uiConf.functionality.groups.allowEdit = uiConf.functionality.groups.edit;
          delete uiConf.functionality.groups.edit;
        }
        uiConf.functionality.groups.rowActions = rowActions;
      }
      if (!!uiConf.functionality.licenses) {
        // Move section actions into "actions"
        if (uiConf.functionality.licenses.actions) {
          delete uiConf.functionality.licenses.actions;
        }
        // Move item actions into "rowActions"
        const rowActions = [];
        if (uiConf.functionality.licenses.rowActions) {
          delete uiConf.functionality.licenses.rowActions;
        }
        if (Object.hasOwn(uiConf.functionality.licenses, 'show')) {
          rowActions.push({
            key: 'show',
            disabled: !uiConf.functionality.licenses.show
          });
          delete uiConf.functionality.licenses.show;
        }
        if (Object.hasOwn(uiConf.functionality.licenses, 'usage')) {
          rowActions.push({
            key: 'usage',
            disabled: !uiConf.functionality.licenses.usage
          });
          delete uiConf.functionality.licenses.usage;
        }
        if (Object.hasOwn(uiConf.functionality.licenses, 'downloadLicense')) {
          rowActions.push({
            key: 'downloadLicense',
            disabled: !uiConf.functionality.licenses.downloadLicense
          });
          delete uiConf.functionality.licenses.downloadLicense;
        }
        uiConf.functionality.licenses.rowActions = rowActions;
      }

      if (!!uiConf.functionality.users) {
        // Move section actions into "actions"
        if (uiConf.functionality.users.actions) {
          delete uiConf.functionality.users.actions;
        }
        const actions: OrgadminActionConfiguration[] = [];
        if (Object.hasOwn(uiConf.functionality.users, 'invite')) {
          actions.push({
            key: 'invite',
            disabled: !uiConf.functionality.users.invite
          });
          delete uiConf.functionality.users.invite;
        }
        if (Object.hasOwn(uiConf.functionality.users, 'import')) {
          actions.push({
            key: 'import',
            disabled: !uiConf.functionality.users.import
          });
          delete uiConf.functionality.users.import;
        }
        if (actions.length) {
          uiConf.functionality.users.actions = actions;
        }

        // Move item actions into "rowActions"
        const rowActions = [];
        if (uiConf.functionality.users.rowActions) {
          delete uiConf.functionality.users.rowActions;
        }
        if (Object.hasOwn(uiConf.functionality.users, 'show')) {
          rowActions.push({
            key: 'show',
            disabled: !uiConf.functionality.users.show
          });
          delete uiConf.functionality.users.show;
        }
        if (Object.hasOwn(uiConf.functionality.users, 'groups')) {
          rowActions.push({
            key: 'groups',
            disabled: !uiConf.functionality.users.groups
          });
          delete uiConf.functionality.users.groups;
        }
        if (Object.hasOwn(uiConf.functionality.users, 'roles')) {
          rowActions.push({
            key: 'roles',
            disabled: !uiConf.functionality.users.roles
          });
          delete uiConf.functionality.users.roles;
        }
        if (Object.hasOwn(uiConf.functionality.users, 'licenses')) {
          rowActions.push({
            key: 'licenses',
            disabled: !uiConf.functionality.users.licenses
          });
          delete uiConf.functionality.users.licenses;
        }
        if (Object.hasOwn(uiConf.functionality.users, 'downloadLicense')) {
          rowActions.push({
            key: 'downloadLicense',
            disabled: !uiConf.functionality.users.downloadLicense
          });
          delete uiConf.functionality.users.downloadLicense;
        }
        if (Object.hasOwn(uiConf.functionality.users, 'assignAdmin')) {
          rowActions.push({
            key: 'assignAdmin',
            disabled: !uiConf.functionality.users.assignAdmin
          });
          delete uiConf.functionality.users.assignAdmin;
        }
        if (Object.hasOwn(uiConf.functionality.users, 'unassignAdmin')) {
          rowActions.push({
            key: 'unassignAdmin',
            disabled: !uiConf.functionality.users.unassignAdmin
          });
          delete uiConf.functionality.users.unassignAdmin;
        }
        if (Object.hasOwn(uiConf.functionality.users, 'suspend')) {
          rowActions.push({
            key: 'suspend',
            disabled: !uiConf.functionality.users.suspend
          });
          delete uiConf.functionality.users.suspend;
        }
        if (Object.hasOwn(uiConf.functionality.users, 'unsuspend')) {
          rowActions.push({
            key: 'unsuspend',
            disabled: !uiConf.functionality.users.unsuspend
          });
          delete uiConf.functionality.users.unsuspend;
        }
        if (Object.hasOwn(uiConf.functionality.users, 'remove')) {
          rowActions.push({
            key: 'remove',
            disabled: !uiConf.functionality.users.remove
          });
          delete uiConf.functionality.users.remove;
        }
        uiConf.functionality.users.rowActions = rowActions;
      }

      if (!!uiConf.functionality.invitations) {
        // Move section actions into "actions"
        if (uiConf.functionality.invitations.actions) {
          delete uiConf.functionality.invitations.actions;
        }
        if (Object.hasOwn(uiConf.functionality.invitations, 'create')) {
          uiConf.functionality.invitations.actions = [];
          uiConf.functionality.invitations.actions.push({
            key: 'create',
            disabled: !uiConf.functionality.invitations.create
          });
          delete uiConf.functionality.invitations.create;
        }

        // Move item actions into "rowActions"
        const rowActions = [];
        if (uiConf.functionality.invitations.rowActions) {
          delete uiConf.functionality.invitations.rowActions;
        }
        if (Object.hasOwn(uiConf.functionality.invitations, 'show')) {
          rowActions.push({
            key: 'show',
            disabled: !uiConf.functionality.invitations.show
          });
          delete uiConf.functionality.invitations.show;
        }
        if (Object.hasOwn(uiConf.functionality.invitations, 'resend')) {
          rowActions.push({
            key: 'resend',
            disabled: !uiConf.functionality.invitations.resend
          });
          delete uiConf.functionality.invitations.resend;
        }
        if (Object.hasOwn(uiConf.functionality.invitations, 'revoke')) {
          rowActions.push({
            key: 'revoke',
            disabled: !uiConf.functionality.invitations.revoke
          });
          delete uiConf.functionality.invitations.revoke;
        }
        if (Object.hasOwn(uiConf.functionality.invitations, 'remove')) {
          rowActions.push({
            key: 'remove',
            disabled: !uiConf.functionality.invitations.remove
          });
          delete uiConf.functionality.invitations.remove;
        }
        uiConf.functionality.invitations.rowActions = rowActions;
      }

      if (!!uiConf.functionality.roles) {
        // Move section actions into "actions"
        if (uiConf.functionality.roles.actions) {
          delete uiConf.functionality.roles.actions;
        }
        if (Object.hasOwn(uiConf.functionality.roles, 'create')) {
          uiConf.functionality.roles.actions = [];
          uiConf.functionality.roles.actions.push({
            key: 'create',
            disabled: !uiConf.functionality.roles.create
          });
          delete uiConf.functionality.roles.create;
        }

        // Move item actions into "rowActions"
        const rowActions = [];
        if (uiConf.functionality.roles.rowActions) {
          delete uiConf.functionality.roles.rowActions;
        }
        if (Object.hasOwn(uiConf.functionality.roles, 'show')) {
          rowActions.push({
            key: 'show',
            disabled: !uiConf.functionality.roles.show
          });
          delete uiConf.functionality.roles.show;
        }
        if (Object.hasOwn(uiConf.functionality.roles, 'members')) {
          rowActions.push({
            key: 'members',
            disabled: !uiConf.functionality.roles.members
          });
          delete uiConf.functionality.roles.members;
        }
        if (Object.hasOwn(uiConf.functionality.roles, 'remove')) {
          rowActions.push({
            key: 'remove',
            disabled: !uiConf.functionality.roles.remove
          });
          delete uiConf.functionality.roles.remove;
        }
        if (Object.hasOwn(uiConf.functionality.roles, 'edit')) {
          uiConf.functionality.roles.allowEdit = uiConf.functionality.roles.edit;
          delete uiConf.functionality.roles.edit;
        }
        uiConf.functionality.roles.rowActions = rowActions;
      }

      if (!!uiConf.functionality["device-client-groups"]) {
        // Move section actions into "actions"
        if (uiConf.functionality["device-client-groups"].actions) {
          delete uiConf.functionality["device-client-groups"].actions;
        }
        if (Object.hasOwn(uiConf.functionality["device-client-groups"], 'create')) {
          uiConf.functionality["device-client-groups"].actions = [];
          uiConf.functionality["device-client-groups"].actions.push({
            key: 'create',
            disabled: !uiConf.functionality["device-client-groups"].create
          });
          delete uiConf.functionality["device-client-groups"].create;
        }

        // Move item actions into "rowActions"
        const rowActions = [];
        if (uiConf.functionality["device-client-groups"].rowActions) {
          delete uiConf.functionality["device-client-groups"].rowActions;
        }
        if (Object.hasOwn(uiConf.functionality["device-client-groups"], 'show')) {
          rowActions.push({
            key: 'show',
            disabled: !uiConf.functionality["device-client-groups"].show
          });
          delete uiConf.functionality["device-client-groups"].show;
        }
        if (Object.hasOwn(uiConf.functionality["device-client-groups"], 'deviceClients')) {
          rowActions.push({
            key: 'deviceClients',
            disabled: !uiConf.functionality["device-client-groups"].deviceClients
          });
          delete uiConf.functionality["device-client-groups"].deviceClients;
        }
        if (Object.hasOwn(uiConf.functionality["device-client-groups"], 'invite')) {
          rowActions.push({
            key: 'invite',
            disabled: !uiConf.functionality["device-client-groups"].invite
          });
          delete uiConf.functionality["device-client-groups"].invite;
        }
        if (Object.hasOwn(uiConf.functionality["device-client-groups"], 'remove')) {
          rowActions.push({
            key: 'remove',
            disabled: !uiConf.functionality["device-client-groups"].remove
          });
          delete uiConf.functionality["device-client-groups"].remove;
        }
        if (Object.hasOwn(uiConf.functionality["device-client-groups"], 'edit')) {
          uiConf.functionality["device-client-groups"].allowEdit = uiConf.functionality["device-client-groups"].edit;
          delete uiConf.functionality["device-client-groups"].edit;
        }
        uiConf.functionality["device-client-groups"].rowActions = rowActions;
      }

      if (!!uiConf.functionality["device-clients"]) {
        // Move section actions into "actions"
        if (uiConf.functionality["device-clients"].actions) {
          delete uiConf.functionality["device-clients"].actions;
        }
        if (Object.hasOwn(uiConf.functionality["device-clients"], 'invite')) {
          uiConf.functionality["device-clients"].actions = [];
          uiConf.functionality["device-clients"].actions.push({
            key: 'invite',
            disabled: !uiConf.functionality["device-clients"].invite
          });
          delete uiConf.functionality["device-clients"].invite;
        }

        // Move item actions into "rowActions"
        const rowActions = [];
        if (uiConf.functionality["device-clients"].rowActions) {
          delete uiConf.functionality["device-clients"].rowActions;
        }
        if (Object.hasOwn(uiConf.functionality["device-clients"], 'show')) {
          rowActions.push({
            key: 'show',
            disabled: !uiConf.functionality["device-clients"].show
          });
          delete uiConf.functionality["device-clients"].show;
        }
        if (Object.hasOwn(uiConf.functionality["device-clients"], 'groups')) {
          rowActions.push({
            key: 'groups',
            disabled: !uiConf.functionality["device-clients"].groups
          });
          delete uiConf.functionality["device-clients"].groups;
        }
        if (Object.hasOwn(uiConf.functionality["device-clients"], 'licenses')) {
          rowActions.push({
            key: 'licenses',
            disabled: !uiConf.functionality["device-clients"].licenses
          });
          delete uiConf.functionality["device-clients"].licenses;
        }
        if (Object.hasOwn(uiConf.functionality["device-clients"], 'remove')) {
          rowActions.push({
            key: 'remove',
            disabled: !uiConf.functionality["device-clients"].remove
          });
          delete uiConf.functionality["device-clients"].remove;
        }
        if (Object.hasOwn(uiConf.functionality["device-clients"], 'edit')) {
          uiConf.functionality["device-clients"].allowEdit = uiConf.functionality["device-clients"].edit;
          delete uiConf.functionality["device-clients"].edit;
        }
        uiConf.functionality["device-clients"].rowActions = rowActions;
      }
    }

  }
  return uiConf;
}
export function migrateConfToLatest(
  conf: OrgadminConfiguration
): OrgadminConfiguration {
  let uiConf = cloneDeep(conf);
  const inputIsEmpty = Object.keys(uiConf).length === 0;
  if (!inputIsEmpty) {
    const versionComparisonResult = compareVersions(
      DEFAULT_UI_CONFIGURATION.version as string,
      uiConf.version || ""
    );
    if (versionComparisonResult > 0) {
      if (process.env.NODE_ENV !== "production") {
        console.warn(
          "UIConfiguration version is outdated (version: %o). Please migrate the configuration to latest (version %o).",
          uiConf.version,
          DEFAULT_UI_CONFIGURATION.version
        );
      }
      uiConf = migrateConfTo_0_1_0(uiConf);
      uiConf = migrateConfTo_0_1_1(uiConf);
      uiConf = migrateConfTo_0_2_0(uiConf);
      uiConf = migrateConfTo_0_3_0(uiConf);
      uiConf = migrateConfTo_0_3_1(uiConf);
      uiConf = migrateConfTo_0_3_2(uiConf);
      uiConf = migrateConfTo_0_4_0(uiConf);
      uiConf = migrateConfTo_0_5_0(uiConf);
      uiConf = migrateConfTo_0_6_0(uiConf);
      uiConf = migrateConfTo_0_7_0(uiConf);
      uiConf = migrateConfTo_1_0_0(uiConf);
    } else if (versionComparisonResult < 0) {
      throw new Error(
        "Configuration version (" +
          uiConf.version +
          ") not supported. Latest version is (" +
          DEFAULT_UI_CONFIGURATION.version +
          ")"
      );
    }
  } else {
    // empty input means "use latest defaults"
    uiConf.version = DEFAULT_UI_CONFIGURATION.version;
  }
  return uiConf;
}

export function mergeInDefaults(
  uiConf: OrgadminConfiguration,
  version: string
): OrgadminConfiguration {
  // resolve the defaults for the conf version
  const defaults = resolveDefaults(version);
  const conf: OrgadminConfiguration = {
    version: uiConf.version,
    "service-name": uiConf["service-name"] || defaults["service-name"],
    functionality: {
      header: {
        "show-locale-switcher": resolveSectionFeature(
            uiConf,
            defaults,
            "header",
            "show-locale-switcher"
        ) as boolean,
        "user-menu": ([] as OrgadminMenuItemConfiguration[]).concat(
            (!!uiConf.functionality?.header?.["user-menu"]
                ? uiConf.functionality?.header?.["user-menu"]
                : defaults.functionality?.header?.["user-menu"]) as OrgadminMenuItemConfiguration[]
            // the cast should be just fine as in reality at lease the defaults will have a value.

        )
      },
      /**
       * This looks way more complicated than it is. This just reads in sections from json or default.
       */
      sections:
        uiConf.functionality && uiConf.functionality.sections
          ? ([] as typeof uiConf.functionality.sections).concat(
              uiConf.functionality.sections
            )
          : defaults &&
            defaults.functionality &&
            defaults.functionality.sections
          ? ([] as typeof defaults.functionality.sections).concat(
              defaults.functionality.sections
            )
          : [],
      dashboard: {
        showOrganization: resolveSectionFeature(
          uiConf,
          defaults,
          "dashboard",
          "showOrganization"
        ) as boolean,
        editOrganization: resolveSectionFeature(
          uiConf,
          defaults,
          "dashboard",
          "editOrganization"
        ) as boolean,
      },
      groups: {
        actions: resolveSectionFeature(
            uiConf,
            defaults,
            "groups",
            "actions"
        ) as OrgadminActionConfiguration[] | undefined,
        rowActions: resolveSectionFeature(
            uiConf,
            defaults,
            "groups",
            "rowActions"
        ) as OrgadminActionConfiguration[] | undefined,
        showEntitlement: resolveSectionFeature(
          uiConf,
          defaults,
          "groups",
          "showEntitlement"
        ) as boolean,
        allowEdit: resolveSectionFeature(
          uiConf,
          defaults,
          "groups",
          "allowEdit"
        ) as boolean,
        columns: resolveSectionFeature(
            uiConf,
            defaults,
            "groups",
            "columns"
        ) as OrgadminColumnConfiguration[],
        filters: resolveSectionFeature(
            uiConf,
            defaults,
            "groups",
            "filters"
        ) as OrgadminFilterConfiguration[],
      },
      "device-client-groups": {
        actions: resolveSectionFeature(
            uiConf,
            defaults,
            "device-client-groups",
            "actions"
        ) as OrgadminActionConfiguration[] | undefined,
        rowActions: resolveSectionFeature(
            uiConf,
            defaults,
            "device-client-groups",
            "rowActions"
        ) as OrgadminActionConfiguration[] | undefined,
        showEntitlement: resolveSectionFeature(
            uiConf,
            defaults,
            "device-client-groups",
            "showEntitlement"
        ) as boolean,
        allowEdit: resolveSectionFeature(
            uiConf,
            defaults,
            "device-client-groups",
            "allowEdit"
        ) as boolean,
        columns: resolveSectionFeature(
            uiConf,
            defaults,
            "device-client-groups",
            "columns"
        ) as OrgadminColumnConfiguration[],
        filters: resolveSectionFeature(
            uiConf,
            defaults,
            "device-client-groups",
            "filters"
        ) as OrgadminFilterConfiguration[],
      },
      roles: {
        actions: resolveSectionFeature(
            uiConf,
            defaults,
            "roles",
            "actions"
        ) as OrgadminActionConfiguration[] | undefined,
        rowActions: resolveSectionFeature(
            uiConf,
            defaults,
            "roles",
            "rowActions"
        ) as OrgadminActionConfiguration[] | undefined,
        allowEdit: resolveSectionFeature(
          uiConf,
          defaults,
          "roles",
          "allowEdit"
        ) as boolean,
        columns: resolveSectionFeature(
          uiConf,
          defaults,
          "roles",
          "columns"
        ) as OrgadminColumnConfiguration[],
        filters: resolveSectionFeature(
            uiConf,
            defaults,
            "roles",
            "filters"
        ) as OrgadminFilterConfiguration[],
      },
      users: {
        actions: resolveSectionFeature(
            uiConf,
            defaults,
            "users",
            "actions"
        ) as OrgadminActionConfiguration[] | undefined,
        rowActions: resolveSectionFeature(
            uiConf,
            defaults,
            "users",
            "rowActions"
        ) as OrgadminActionConfiguration[] | undefined,
        columns: resolveSectionFeature(
          uiConf,
          defaults,
          "users",
          "columns"
        ) as OrgadminColumnConfiguration[],
        filters: resolveSectionFeature(
            uiConf,
            defaults,
            "users",
            "filters"
        ) as OrgadminFilterConfiguration[],
        importShowGroupSelection: resolveSectionFeature(
          uiConf,
          defaults,
          "users",
          "importShowGroupSelection"
        ) as boolean,
      },
      "device-clients": {
        actions: resolveSectionFeature(
            uiConf,
            defaults,
            "device-clients",
            "actions"
        ) as OrgadminActionConfiguration[] | undefined,
        rowActions: resolveSectionFeature(
            uiConf,
            defaults,
            "device-clients",
            "rowActions"
        ) as OrgadminActionConfiguration[] | undefined,
        allowEdit: resolveSectionFeature(
            uiConf,
            defaults,
            "device-clients",
            "allowEdit"
        ) as boolean,
        columns: resolveSectionFeature(
            uiConf,
            defaults,
            "device-clients",
            "columns"
        ) as OrgadminColumnConfiguration[],
        filters: resolveSectionFeature(
            uiConf,
            defaults,
            "device-clients",
            "filters"
        ) as OrgadminFilterConfiguration[],
      },
      invitations: {
        actions: resolveSectionFeature(
            uiConf,
            defaults,
            "invitations",
            "actions"
        ) as OrgadminActionConfiguration[] | undefined,
        rowActions: resolveSectionFeature(
            uiConf,
            defaults,
            "invitations",
            "rowActions"
        ) as OrgadminActionConfiguration[] | undefined,
        enableClientInvitations: resolveSectionFeature(
            uiConf,
            defaults,
            "invitations",
            "enableClientInvitations"
        ) as boolean,
        enableUserInvitations: resolveSectionFeature(
            uiConf,
            defaults,
            "invitations",
            "enableUserInvitations"
        ) as boolean,
        showGroup: resolveSectionFeature(
            uiConf,
            defaults,
            "invitations",
            "showGroup"
        ) as boolean,
        columns: resolveSectionFeature(
          uiConf,
          defaults,
          "invitations",
          "columns"
        ) as OrgadminColumnConfiguration[],
        filters: resolveSectionFeature(
            uiConf,
            defaults,
            "invitations",
            "filters"
        ) as OrgadminFilterConfiguration[],
      },
      licenses: {
        actions: resolveSectionFeature(
            uiConf,
            defaults,
            "licenses",
            "actions"
        ) as OrgadminActionConfiguration[] | undefined,
        rowActions: resolveSectionFeature(
            uiConf,
            defaults,
            "licenses",
            "rowActions"
        ) as OrgadminActionConfiguration[] | undefined,
        usageEnableUsers: resolveSectionFeature(
            uiConf,
            defaults,
            "licenses",
            "usageEnableUsers"
        ) as boolean,
        usageEnableClients: resolveSectionFeature(
            uiConf,
            defaults,
            "licenses",
            "usageEnableClients"
        ) as boolean,
        showEntitlement: resolveSectionFeature(
          uiConf,
          defaults,
          "licenses",
          "showEntitlement"
        ) as boolean,
        columns: resolveSectionFeature(
          uiConf,
          defaults,
          "licenses",
          "columns"
        ) as OrgadminColumnConfiguration[],
        filters: resolveSectionFeature(
            uiConf,
            defaults,
            "licenses",
            "filters"
        ) as OrgadminFilterConfiguration[],
      },
      localization: {
        default: resolveSectionFeature(
          uiConf,
          defaults,
          "localization",
          "default"
        ) as string,
        translationsUrl: resolveSectionFeature(
          uiConf,
          defaults,
          "localization",
          "translationsUrl"
        ) as string,
        autoDetect: resolveSectionFeature(
          uiConf,
          defaults,
          "localization",
          "autoDetect"
        ) as boolean,
        locales: resolveSectionFeature(
          uiConf,
          defaults,
          "localization",
          "locales"
        ) as OrgadminLocalizationLocale[],
      },
      enableDownloadLicenseVersion:
        uiConf.functionality?.enableDownloadLicenseVersion !== undefined &&
        uiConf.functionality?.enableDownloadLicenseVersion !== null
          ? uiConf.functionality.enableDownloadLicenseVersion
          : defaults.functionality?.enableDownloadLicenseVersion,
      enableReleaseLicenseLease:
          uiConf.functionality?.enableReleaseLicenseLease !== undefined &&
          uiConf.functionality?.enableReleaseLicenseLease !== null
              ? uiConf.functionality.enableReleaseLicenseLease
              : defaults.functionality?.enableReleaseLicenseLease,
    },
    "identity-api": {
      includeOrganizationIdHeader:
        uiConf["identity-api"]?.includeOrganizationIdHeader !== undefined &&
        uiConf["identity-api"]?.includeOrganizationIdHeader !== null
          ? uiConf["identity-api"]?.includeOrganizationIdHeader
          : defaults["identity-api"]?.includeOrganizationIdHeader,
    },
    security: {
      idTokenIatLeeway:
        uiConf.security?.idTokenIatLeeway !== undefined &&
        uiConf.security?.idTokenIatLeeway !== null
          ? uiConf.security.idTokenIatLeeway
          : defaults.security?.idTokenIatLeeway,
    },
  };

  if (
    !!conf &&
    !!conf.functionality &&
    !!conf.functionality.groups &&
    !!conf.functionality.groups.allowEdit
  ) {
    // Show is required with edit, so we force it on if edit is on
    conf.functionality.groups.rowActions = conf.functionality.groups.rowActions?.map((m) => (
        {
          ...m,
          ...(m.key === 'show' ? { disabled: false } : {})
        }
    ));
  }
  if (
      !!conf &&
      !!conf.functionality &&
      !!conf.functionality["device-client-groups"] &&
      !!conf.functionality["device-client-groups"].allowEdit
  ) {
    // Show is required with edit, so we force it on if edit is on
    conf.functionality["device-client-groups"].rowActions = conf.functionality["device-client-groups"].rowActions?.map((m) => (
        {
          ...m,
          ...(m.key === 'show' ? { disabled: false } : {})
        }
    ));
  }
  if (
      !!conf &&
      !!conf.functionality &&
      !!conf.functionality["device-clients"] &&
      !!conf.functionality["device-clients"].allowEdit
  ) {
    // Show is required with edit, so we force it on if edit is on
    conf.functionality["device-clients"].rowActions = conf.functionality["device-clients"].rowActions?.map((m) => (
        {
          ...m,
          ...(m.key === 'show' ? { disabled: false } : {})
        }
    ));
  }
  if (
    !!conf &&
    !!conf.functionality &&
    !!conf.functionality.roles &&
    !!conf.functionality.roles.allowEdit
  ) {
    // Show is required with edit, so we force it on if edit is on
    conf.functionality.roles.rowActions = conf.functionality.roles.rowActions?.map((m) => (
        {
          ...m,
          ...(m.key === 'show' ? { disabled: false } : {})
        }
    ));
  }
  return conf;
}
