import { defineMessages } from "react-intl";

/**
 * Localized labels for "UI Model" AggregatedUseTimeCredit fields
 */
export const AggregatedUseTimeCreditLabels = {
  // Add LicenseCreditBase if needed
  ...defineMessages({
    useTime: {
      defaultMessage: "use time: total",
      description:
        "Field label for total sum of use time credits (AggregatedUseTimeCredit.useTime)"
    },
    timeUsed: {
      defaultMessage: "use time: consumed",
      description:
        "Field label for sum of consumed use time credits (AggregatedUseTimeCredit.timeUsed)"
    },
    useTimeAvailable: {
      defaultMessage: "use time: available",
      description:
        "Field label for sum of available use time credits (AggregatedUseTimeCredit.useTimeAvailable)"
    }
  })
};
