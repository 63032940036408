import {useEffect, HTMLAttributes, useContext} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {FeedbackEntry, Feedback, Modal} from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import { User } from "../../../model/User";
import { findFirstAddErrorAction } from "../../../actions/actionHelpers";
import {
  ClearErrorAction,
  GetUserAction,
  isAddErrorAction,
  MultiAction,
} from "../../../actions/actionTypes";
import UserUtils from "../../../utils/user";
import { UserValues } from "../../../localization/user";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import {Form} from "react-bootstrap";

//<editor-fold desc="Props">

const resolveUserName = (
  user: User | undefined | null,
  intl: { formatMessage: (v: any, v2?: any) => string }
) =>
  UserUtils.resolveDisplayName(
    user,
    intl.formatMessage(UserValues.displayName.undefined)
  );
export interface RemoveUserModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface RemoveUserModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  user?: User | null;
  actorId: string;
  onRemoveUser: (id: string, orgId: string|undefined, removeLeases: boolean|undefined) => Promise<MultiAction>;
  onLoadUser: (id: string) => Promise<GetUserAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface RemoveUserModalStateProps {
  onToggleRemoveLeases: () => void;
  removeLeases: boolean;
}
export interface RemoveUserModalProps
  extends RemoveUserModalDOMProps,
    RemoveUserModalDataProps,
      RemoveUserModalStateProps,
    Omit<ClosableModalProps, "isReady"> {
  userId?: string;
  onShowFeedback: (feedback: FeedbackEntry) => void;
}
//</editor-fold>

function RemoveUserModal(props: RemoveUserModalProps) {
  //<editor-fold desc="Local variables">
  let {
    user,
    userId,
    actorId,
    show,
    onClose,
    onRemoveUser,
    onLoadUser,
    onShowFeedback,
    isReady,
    onExited,
    onClearError,
    onToggleRemoveLeases,
    removeLeases
  } = props;

  const userObjId = user ? user.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!userId &&
      (userObjId === undefined || (!!userObjId && userObjId !== userId)) &&
      !!onLoadUser
    ) {
      onLoadUser(userId).then((res) => {
        if (!userObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, userId, userObjId, onLoadUser, onClearError]);

  const { conf } = useContext(UIConfiguration);
  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>

  return (
    <Modal
      onReloadData={() => {
        if ((user || userId) && onLoadUser) {
          onLoadUser(user ? (user.id as string) : (userId as string)).then(
            (res) => {
              if (!user && isAddErrorAction(res)) {
                // only clear error if no data exists, leave if previous data is still available and
                // let the api error notification handler show error
                onClearError(res.error?.errorId);
              }
            }
          );
        }
      }}
      onExited={onExited}
      isReady={isReady}
      data-test-remove-user-modal={user ? (user.id as string) : true}
      title={
        !isReady || user
          ? intl.formatMessage(
              {
                defaultMessage: "Remove {name} from organization",
                description: "modal heading. 'name' = the name of the user",
              },
              {
                name: resolveUserName(user, intl),
              }
            )
          : intl.formatMessage({
              defaultMessage: "User not found",
              description: "modal heading when user not found",
            })
      }
      show={show}
      onClose={onClose}
      primaryButton={
        user && user.id !== actorId
          ? {
              label: intl.formatMessage({
                defaultMessage: "Remove",
                description: "primary button label",
              }),
            }
          : {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
      }
      onPrimaryAction={() => {
        if (show && actorId && user && user.id !== actorId) {
          onRemoveUser(user.id as string, undefined, removeLeases && conf?.functionality?.enableReleaseLicenseLease).then(
            (res) => {
              const firstError = findFirstAddErrorAction(res.results);
              if (firstError) {
                onClearError(firstError.error?.errorId);
                onShowFeedback({
                  id: "remove_" + (user ? (user.id as string) : ""),
                  msg: intl.formatMessage(
                    {
                      defaultMessage:
                        "Removing {name} from organization failed: {msg}",
                      description:
                        "Failure notification. 'name' = name of the user, 'msg' = error message",
                    },
                    {
                      name:
                        "<strong>" + resolveUserName(user, intl) + "</strong>",
                      msg: firstError.error.apiError.error_description,
                    }
                  ),
                  type: "danger",
                });
              } else {
                onShowFeedback({
                  id: "remove_" + (user ? (user.id as string) : ""),
                  msg: intl.formatMessage(
                    {
                      defaultMessage: "{name} removed from organization.",
                      description:
                        "Success notification. 'name' =  name of the user",
                    },
                    {
                      name:
                        "<strong>" + resolveUserName(user, intl) + "</strong>",
                    }
                  ),
                  autoClose: true,
                  type: "success",
                });
              }
            },
            (rej) => {
              onClearError(rej.error?.errorId);
              onShowFeedback({
                id: "remove_" + (user ? (user.id as string) : ""),
                msg: intl.formatMessage(
                  {
                    defaultMessage:
                      "Removing {name} from organization failed: {msg}",
                    description:
                      "Failure notification. 'name' = name of the user, 'msg' = error message",
                  },
                  {
                    name:
                      "<strong>" + resolveUserName(user, intl) + "</strong>",
                  }
                ),
                type: "danger",
              });
            }
          );
        }
        onClose();
      }}
      secondaryButton={
        user && user.id !== actorId
          ? {
              label: intl.formatMessage({
                defaultMessage: "Cancel",
                description: "secondary button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={user && user.id !== actorId ? onClose : undefined}
    >
      <>
        {show && user && (
          <>
            {user.id === actorId && (
              <Feedback type={"danger"} show={true} asChild={true}>
                <p>
                  <FormattedMessage
                    defaultMessage="{name}, you're not allowed to remove yourself."
                    description="message for disabled self removal. 'name' = name of the user"
                    values={{
                      name: <strong>{resolveUserName(user, intl)}</strong>,
                    }}
                  />
                </p>
              </Feedback>
            )}
            {user.id !== actorId && (
              <><p>
                <FormattedMessage
                  defaultMessage="Are you sure you wish to remove {name} from this organization?"
                  description="confirm action message. 'name' = name of the user"
                  values={{
                    name: <strong>{resolveUserName(user, intl)}</strong>,
                  }}
                />
              </p>
              {conf.functionality?.enableReleaseLicenseLease && <p>
                <Form.Check
                    type={"switch"}
                    className={"custom-switch"}
                    label={intl.formatMessage({
                      defaultMessage: "Remove active license leases",
                      description:
                          "Label for toggle that controls if license leases are to be removed as part of user removal",
                    })}
                    onChange={() => {
                      onToggleRemoveLeases();
                    }}
                    id={"removeUserIncludeLeases"}
                    checked={removeLeases}
                    value="1"
                />
              </p>}
              </>
            )}
          </>
        )}
        {isReady && show && !user && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p>
              <FormattedMessage
                defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
                description="message to be shown when there is no user to display"
              />
            </p>
          </Feedback>
        )}
      </>
    </Modal>
  );
}

export default RemoveUserModal;
