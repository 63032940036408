import { DownloadUsersLicenseModalDataProps } from "./download-users-license-modal-view";
import DownloadUsersLicenseModal, {
  DownloadUsersLicenseModalProps as _DownloadUsersLicenseModalProps,
} from "./download-users-license-modal-container";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import { AppState } from "../../../store/AppState";
import UserUtils from "../../../utils/user";
import InProgressUtils from "../../../utils/in-progress";
import { addSenderArgument } from "../../../actions/actionHelpers";
import { clearError, getUser } from "../../../actions";
import { connect } from "react-redux";
import LicenseUtils from "../../../utils/license";
import { queryAvailableLicensesWithUsage } from "../../../actions/queryAvailableLicensesWithUsageMultiAction";
import OrganizationUtils from "../../../utils/organization";
import { sortUserLicensedItemAssignmentsDataForManageReservation } from "../../../util/licenseUtil";
import { downloadLicenses } from "../../../actions/downloadLicenseAction";

export type DownloadUsersLicenseModalProps = Omit<
  _DownloadUsersLicenseModalProps,
  keyof DownloadUsersLicenseModalDataProps
>;
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _DownloadUsersLicenseModalProps,
  DownloadUsersLicenseModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _DownloadUsersLicenseModalProps,
  DownloadUsersLicenseModalProps
>;

const sender = { key: "download-users-license" };

function mapStateToProps(
  state: AppState,
  ownProps: DownloadUsersLicenseModalProps
): ReduxStateProps {
  let licenses = undefined;
  if (ownProps.userId) {
    licenses = LicenseUtils.selectUserLicensedItemAssignments(
      ownProps.userId,
      state
    );
    if (licenses) {
      licenses = licenses.map((l) => {
        return sortUserLicensedItemAssignmentsDataForManageReservation(l);
      });
    }
  }
  return {
    user: ownProps.userId
      ? UserUtils.selectUser(ownProps.userId, state)
      : undefined,
    licenses: licenses,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    UserUtils.compareUsersState(next, prev) &&
    LicenseUtils.compareLicensesStates(next, prev) &&
    LicenseUtils.compareLicenseAssignmentsStates(next, prev) &&
    OrganizationUtils.compareOrganizationLicenseIdsStates(next, prev) &&
    UserUtils.compareUserAvailableLicensesState(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadUser: addSenderArgument(sender, getUser),
  onLoadLicenses: addSenderArgument(sender, queryAvailableLicensesWithUsage),
  onDownloadLicense: addSenderArgument(sender, downloadLicenses),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  DownloadUsersLicenseModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(DownloadUsersLicenseModal);
