import { connect } from "react-redux";
import { AppState } from "../../../store/AppState";
import DeviceClientLicensesModalView, {
  DeviceClientLicensesModalDataProps,
  DeviceClientLicensesModalVisibilityProps,
  DeviceClientLicensesModalProps as _DeviceClientLicensesModalProps,
} from "./device-client-licenses-modal-container";
import {
  manageClientsLicenseAssignmentsAndQueryAvailableLicenses
} from "../../../actions/entActions";
import {
  convertAssignmentsToSingleAssignmentForSettingAssignmentsCall,
  sortUserLicensedItemAssignmentsDataForManageReservation,
} from "../../../util/licenseUtil";
import { UserLicensedItemAssignments } from "../../../model/entitlement/UserLicensedItemAssignments";
import {
  PickReduxStateProps,
  PickReduxDispatchProps,
} from "../../../util/typeUtil";
import {clearError, getClient} from "../../../actions";
import OrganizationUtils from "../../../utils/organization";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import LicenseUtils from "../../../utils/license";
import { addSenderArgument } from "../../../actions/actionHelpers";
import { LicenseAssignment } from "../../../model/entitlement/LicenseAssignment";
import {queryClientAvailableLicensesWithUsage} from "../../../actions/queryClientAvailableLicensesWithUsageMultiAction";

// Own props, i.e. props that this component takes as input
export interface DeviceClientLicensesModalProps extends DeviceClientLicensesModalDataProps, DeviceClientLicensesModalVisibilityProps {}

type ReduxStateProps = PickReduxStateProps<
  _DeviceClientLicensesModalProps,
    DeviceClientLicensesModalProps
>;
type ReduxDispatchProps = PickReduxDispatchProps<
  _DeviceClientLicensesModalProps,
    DeviceClientLicensesModalProps
>;

const sender: ActionSender = { key: "device-client-licenses-modal" };

function mapStateToProps(
  state: AppState,
  ownProps: DeviceClientLicensesModalProps
): ReduxStateProps {
  const { clientId } = ownProps;
  let licenses = undefined;
  if (clientId) {
    licenses = LicenseUtils.selectClientLicensedItemAssignments(clientId, state);
    if (licenses) {
      licenses = licenses.map((l) => {
        return sortUserLicensedItemAssignmentsDataForManageReservation(l);
      });
    }
  }
  return {
    client: !!clientId ? OrganizationUtils.selectOrganizationClient(clientId, state) : undefined,
    licenses,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}
function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientsStates(next, prev) &&
    LicenseUtils.compareLicensesStates(next, prev) &&
    LicenseUtils.compareLicenseAssignmentsStates(next, prev) &&
    OrganizationUtils.compareOrganizationLicenseIdsStates(next, prev) &&
    OrganizationUtils.compareClientAvailableLicensesState(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadClient: addSenderArgument(sender, getClient),
  onApply: onApply,
  onLoadLicenses: addSenderArgument(sender, queryClientAvailableLicensesWithUsage),
  onClearError: clearError,
};

function onApply(
  lics: UserLicensedItemAssignments[] | undefined,
  userId: string
): any {
  if (!lics || !userId) {
    throw new Error("Nothing to set");
  }
  const licenseIds: string[] = [];
  const assignements: { [userId: string]: LicenseAssignment[] }[] = [];
  lics.forEach((lic) => {
    const tmp_ass =
      convertAssignmentsToSingleAssignmentForSettingAssignmentsCall(
        lic.assignments[0].assignments
      );
    if (!!tmp_ass && tmp_ass.length > 0) {
      licenseIds.push(lic.assignments[0].license.id as string);
      assignements.push({
        [userId]: tmp_ass,
      });
    }
  });
  return manageClientsLicenseAssignmentsAndQueryAvailableLicenses(
    sender,
    licenseIds,
    assignements
  );
}

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  DeviceClientLicensesModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(DeviceClientLicensesModalView);
