import { LicenseWithOwner } from "../model/entitlement/LicenseWithOwner";
import { LicenseAssignmentWithSessions } from "../model/entitlement/LicenseAssignmentWithSessions";
import { AppState } from "../store/AppState";
import { UserLicensedItemAssignments } from "../model/entitlement/UserLicensedItemAssignments";
import {
  LicenseAssignmentsById,
  LicensesById,
  LicenseIdsByOrgId,
} from "../store/LicenseState";
import { LicensedItem } from "../model/entitlement/LicensedItem";
import { LicenseAndAssignments } from "../model/entitlement/LicenseAndAssignments";
import { LicenseAssignment } from "../model/entitlement/LicenseAssignment";
import { SeatCountCredit } from "../model/entitlement/SeatCountCredit";
import { License } from "../model/entitlement/License";
import { LicenseWithCredits } from "../model/entitlement/LicenseWithCredits";
import { getValidUntil, isValid } from "./objectUtil";
import { LicenseUsage } from "../model/entitlement/LicenseUsage";
import { ID_ANY } from "../api/EntApi";
import { LicenseUser } from "../model/entitlement/LicenseUser";
import { UsersById } from "../store/UserState";
import { OrgUserIdsByOrgId } from "../store/OrganizationState";
import { UserProfile } from "../model/entitlement/UserProfile";
import { User, UserWithLicenseUsage } from "../model/User";
import {
  isInstanceOfLicenseUserWithAssignmentsAndSessions,
  LicenseUserWithAssignmentsAndSessions,
} from "../model/entitlement/LicenseUserWithAssignmentsAndSessions";
import { LicenseUsersWithLicensedItemReservations } from "../model/entitlement/LicenseUsersWithLicensedItemReservations";
import { LicenseWithOwnerAndSingleUserAssignments } from "../model/entitlement/LicenseWithOwnerAndSingleUserAssignments";
import { UseCountCredit } from "../model/entitlement/UseCountCredit";
import { UseTimeCredit } from "../model/entitlement/UseTimeCredit";

export function resolveFreeSeatsForLicenseAndAssignmentsArray(
  licenseAndAssigmentsArray: LicenseAndAssignments[]
): number|undefined {
  let result: number|undefined|null = null;
  licenseAndAssigmentsArray
    // license validity has no impacy on free seats in license usage modal, so it probably should not have any here either
    // .filter(licenseAndAssigments => isValid(licenseAndAssigments.license))
    .forEach((licenseAndAssigments) => {
      let tmp = resolveFreeSeatsForLicense(licenseAndAssigments.license);
      if (result !== undefined) {
        if (tmp !== undefined) {
          if (result !== null) {
            result += tmp;
          } else {
            result = tmp;
          }
        } else {
          result = tmp;
        }
      }
    });
  return result === null ? 0 : result;
}

export function resolveFreeSeatsForLicense(license: License): number|undefined {
  if (license.seatsTotal !== undefined) {
    const t =
        (license.seatsTotal ? license.seatsTotal : 0) -
        (license.seatsTaken ? license.seatsTaken : 0);
    return t < 0 ? 0 : t;
  } else {
    return undefined;
  }
}

/**
 * The license must be on with aggregated useCount entry for this to work
 * @param license
 */
export function resolveFreeUseCountForLicense(
  license: LicenseWithCredits
): number {
  const t =
    (license.useCountCredits &&
    license.useCountCredits[0] &&
    license.useCountCredits[0].useCount
      ? license.useCountCredits[0].useCount
      : 0) -
    (license.useCountCredits &&
    license.useCountCredits[0] &&
    license.useCountCredits[0].countUsed
      ? license.useCountCredits[0].countUsed
      : 0);
  return t < 0 ? 0 : t;
}
/**
 * The license must be on with aggregated useTime entry for this to work
 * @param license
 */
export function resolveFreeUseTimeForLicense(
  license: LicenseWithCredits
): number {
  const t =
    (license.useTimeCredits &&
    license.useTimeCredits[0] &&
    license.useTimeCredits[0].useTime
      ? license.useTimeCredits[0].useTime
      : 0) -
    (license.useTimeCredits &&
    license.useTimeCredits[0] &&
    license.useTimeCredits[0].timeUsed
      ? license.useTimeCredits[0].timeUsed
      : 0);
  return t < 0 ? 0 : t;
}

export function resolveConsumedSeatsForSeatCountCredits(
  credits: SeatCountCredit[] | undefined
): number {
  let retVal = 0;
  if (credits) {
    credits
      .filter((val) => isValid(val))
      .forEach((val) => {
        retVal += val.seatsConsumed ? val.seatsConsumed : 0;
      });
  }
  return retVal;
}

/**
 * Sorts assignment data contained by the given LicenseUsage object for the purpose of managing
 * reservations.
 *
 * This is meant for the named license case, i.e. license reservation is required for consuming license.
 * @param licenseUsage The LicenseUsage object. LicenseUsage.users.assignments
 *   are sorted by this method.
 */
export function sortLicenseUsageUserAssignmentsForManageReservations(
  licenseUsage: LicenseUsage
): LicenseUsage {
  if (!licenseUsage.users || !licenseUsage.users.length) {
    return licenseUsage;
  }

  const retValue = { ...licenseUsage };
  for (const licenseUser of licenseUsage.users) {
    if (!licenseUser.assignments || !licenseUser.assignments.length) {
      continue;
    }

    licenseUser.assignments = sortAssignmentsForManageReservations(
      licenseUser.assignments
    );
  }

  return retValue;
}

/**
 * Sorts assignment data contained by the given UserLicensedItemAssignments object for the purpose of managing
 * reservations.
 *
 * This is meant for the named license case, i.e. license reservation is required for consuming license.
 * @param userLicensedItemAssignments UserLicensedItemAssignments object. UserLicensedItemAssignments.assignments
 *   and UserLicensedItemAssignments.assignments.assignments are sorted by this method.
 */
export function sortUserLicensedItemAssignmentsDataForManageReservation(
  userLicensedItemAssignments: UserLicensedItemAssignments
): UserLicensedItemAssignments {
  const retValue = { ...userLicensedItemAssignments };

  // License assignments must be sorted before sorting item assignments, item assignment sorting requires them to be sorted
  retValue.assignments.forEach(
    (assignment) =>
      (assignment.assignments = sortAssignmentsForManageReservations(
        assignment.assignments
      ))
  );
  // Seat count credits must be sorted before sorting item assignments, item assignment sorting requires them to be sorted
  retValue.assignments.forEach(
    (assignment) =>
      (assignment.license.seatCountCredits = assignment.license.seatCountCredits
        ? sortSeatCountCredits([
            ...(assignment.license.seatCountCredits as SeatCountCredit[]),
          ])
        : undefined)
  );

  retValue.assignments = sortItemAssignmentsForManageReservations(
    retValue.assignments
  );

  return retValue;
}

/**
 * Sorts the given LicenseAndAssignments for the purpose of managing
 * reservations.
 *
 * This is meant for the named license case, i.e. license reservation is required for consuming license.
 * @param itemAssignments The LicenseAndAssignments objects to sort. Nested license assignments and
 *    seat count credits must be sorted before calling this method, this method relies on them being
 *    in correct sort order.
 * @returns The sorted LicenseAndAssignments objects.
 */
function sortItemAssignmentsForManageReservations(
  itemAssignments: LicenseAndAssignments[]
): LicenseAndAssignments[] {
  const retValue = [...itemAssignments];
  retValue.sort((a, b) => {
    // Get the preferred nested assignment (nested assignments must be sorted before calling this method)
    const aPreferredAssignment =
      a.assignments && a.assignments.length ? a.assignments[0] : undefined;
    const bPreferredAssignment =
      b.assignments && b.assignments.length ? b.assignments[0] : undefined;
    let aAssignmentValidUntil =
      aPreferredAssignment && isValid(aPreferredAssignment)
        ? getValidUntil(aPreferredAssignment)
        : NaN;
    let bAssignmentValidUntil =
      bPreferredAssignment && isValid(bPreferredAssignment)
        ? getValidUntil(bPreferredAssignment)
        : NaN;

    // Prefer valid nested assignment over not valid
    if (
      Number.isNaN(aAssignmentValidUntil as any) &&
      !Number.isNaN(bAssignmentValidUntil as any)
    ) {
      return 1;
    }
    if (
      Number.isNaN(bAssignmentValidUntil as any) &&
      !Number.isNaN(aAssignmentValidUntil as any)
    ) {
      return -1;
    }

    // Prefer reserved nested assignment over not reserverd
    if (
      !Number.isNaN(aAssignmentValidUntil as any) &&
      aPreferredAssignment?.type === "reserved" &&
      bPreferredAssignment?.type !== "reserved"
    ) {
      return -1;
    }
    if (
      !Number.isNaN(bAssignmentValidUntil as any) &&
      bPreferredAssignment?.type === "reserved" &&
      aPreferredAssignment?.type !== "reserved"
    ) {
      return 1;
    }

    // Prefer valid nested seat count credit over not valid
    const aBestSeatCountCredit =
      a.license.seatCountCredits && a.license.seatCountCredits.length
        ? a.license.seatCountCredits[0]
        : undefined;
    const bBestSeatCountCredit =
      b.license.seatCountCredits && b.license.seatCountCredits.length
        ? b.license.seatCountCredits[0]
        : undefined;
    let aSeatCountCreditValidUntil =
      aBestSeatCountCredit && isValid(aBestSeatCountCredit)
        ? getValidUntil(aBestSeatCountCredit)
        : NaN;
    let bSeatCountCreditValidUntil =
      bBestSeatCountCredit && isValid(bBestSeatCountCredit)
        ? getValidUntil(bBestSeatCountCredit)
        : NaN;
    if (
      Number.isNaN(aSeatCountCreditValidUntil as any) &&
      !Number.isNaN(bSeatCountCreditValidUntil as any)
    ) {
      return 1;
    }
    if (
      Number.isNaN(bSeatCountCreditValidUntil as any) &&
      !Number.isNaN(aSeatCountCreditValidUntil as any)
    ) {
      return -1;
    }

    // Prefer licenses with free seats over fully reserved.
    const aSeatsTotal = a.license.seatsTotal ? a.license.seatsTotal : 0;
    const aSeatsTaken = a.license.seatsTaken ? a.license.seatsTaken : 0;
    const aFreeSeats = aSeatsTotal - aSeatsTaken;

    const bSeatsTotal = b.license.seatsTotal ? b.license.seatsTotal : 0;
    const bSeatsTaken = b.license.seatsTaken ? b.license.seatsTaken : 0;
    const bFreeSeats = bSeatsTotal - bSeatsTaken;

    if (aFreeSeats > 0 && aFreeSeats > bFreeSeats) {
      return -1;
    }
    if (bFreeSeats > 0 && bFreeSeats > aFreeSeats) {
      return 1;
    }

    // Prefer valid seat count credit with bigger amount of vacant seats
    const aVacantSeats =
      aBestSeatCountCredit?.seatCount !== undefined
        ? aBestSeatCountCredit?.seatCount -
          (aBestSeatCountCredit?.seatsConsumed || 0)
        : undefined;
    const bVacantSeats =
      bBestSeatCountCredit?.seatCount !== undefined
        ? bBestSeatCountCredit?.seatCount -
          (bBestSeatCountCredit?.seatsConsumed || 0)
        : undefined;
    if (!Number.isNaN(aSeatCountCreditValidUntil as any) && aVacantSeats) {
      if (!bVacantSeats || aVacantSeats > bVacantSeats) {
        return -1;
      }
    }
    if (!Number.isNaN(bSeatCountCreditValidUntil as any) && bVacantSeats) {
      if (!aVacantSeats || bVacantSeats > aVacantSeats) {
        return 1;
      }
    }

    // Prefer nested assignment with better validity
    aAssignmentValidUntil = aPreferredAssignment
      ? getValidUntil(aPreferredAssignment)
      : NaN;
    bAssignmentValidUntil = bPreferredAssignment
      ? getValidUntil(bPreferredAssignment)
      : NaN;
    if (
      !Number.isNaN(aAssignmentValidUntil as any) &&
      !Number.isNaN(bAssignmentValidUntil as any)
    ) {
      if (aAssignmentValidUntil) {
        if (
          !bAssignmentValidUntil ||
          bAssignmentValidUntil > aAssignmentValidUntil
        ) {
          return 1;
        }
      }
      if (bAssignmentValidUntil) {
        if (
          !aAssignmentValidUntil ||
          aAssignmentValidUntil > bAssignmentValidUntil
        ) {
          return -1;
        }
      }
    }

    return 0;
  });

  return retValue;
}

/**
 * Sorts seat count credits by "availability", i.e. by validity and number of vacant seats.
 * @param seatCountCredits The SeatCountCredit objects to sort.
 * @returns The sorted SeatCountCredit objects.
 */
function sortSeatCountCredits(
  seatCountCredits: SeatCountCredit[]
): SeatCountCredit[] {
  const retValue = [...seatCountCredits];
  retValue.sort((a, b) => {
    // Prefer valid seat count credit over not valid
    let aValidUntil = isValid(a) ? getValidUntil(a) : NaN;
    let bValidUntil = isValid(b) ? getValidUntil(b) : NaN;
    if (Number.isNaN(aValidUntil as any) && !Number.isNaN(bValidUntil as any)) {
      return 1;
    }
    if (Number.isNaN(bValidUntil as any) && !Number.isNaN(aValidUntil as any)) {
      return -1;
    }

    // Prefer bigger amount of vacant seats
    const aVacantSeats =
      a.seatCount !== undefined
        ? a.seatCount - (a.seatsConsumed || 0)
        : undefined;
    const bVacantSeats =
      b.seatCount !== undefined
        ? b.seatCount - (b.seatsConsumed || 0)
        : undefined;
    if (!Number.isNaN(aValidUntil as any) && aVacantSeats) {
      if (!bVacantSeats || aVacantSeats > bVacantSeats) {
        return -1;
      }
    }
    if (!Number.isNaN(bValidUntil as any) && bVacantSeats) {
      if (!aVacantSeats || bVacantSeats > aVacantSeats) {
        return 1;
      }
    }

    // Prefer longer validity
    aValidUntil = getValidUntil(a);
    bValidUntil = getValidUntil(b);
    if (aValidUntil) {
      if (!bValidUntil || bValidUntil > aValidUntil) {
        return 1;
      }
    }
    if (bValidUntil) {
      if (!aValidUntil || aValidUntil > bValidUntil) {
        return -1;
      }
    }

    return 0;
  });

  return retValue;
}

/**
 * Sorts assignments for managing reservations.
 *
 * This is meant for the named license case, i.e. license reservation is required for consuming license.
 * @param assignments The assignments to sort.
 * @returns The sorted assignments.
 */
function sortAssignmentsForManageReservations(
  assignments: LicenseAssignment[]
): LicenseAssignment[] {
  const retValue = [...assignments];
  retValue.sort((a, b) => {
    // Prefer valid over not valid
    let aValidUntil = isValid(a) ? getValidUntil(a) : NaN;
    let bValidUntil = isValid(b) ? getValidUntil(b) : NaN;
    if (Number.isNaN(aValidUntil as any) && !Number.isNaN(bValidUntil as any)) {
      return 1;
    }
    if (Number.isNaN(bValidUntil as any) && !Number.isNaN(aValidUntil as any)) {
      return -1;
    }
    // Prefer reserved over not reserved
    if (a.type === "reserved" && b.type !== "reserved") {
      return -1;
    }
    if (b.type === "reserved" && a.type !== "reserved") {
      return 1;
    }
    // Prefer denied over not denied
    if (a.type === "denied" && b.type !== "denied") {
      return -1;
    }
    if (b.type === "denied" && a.type !== "denied") {
      return 1;
    }

    // Prefer longer validity
    aValidUntil = getValidUntil(a);
    bValidUntil = getValidUntil(b);
    if (aValidUntil) {
      if (!bValidUntil || bValidUntil > aValidUntil) {
        return 1;
      }
    }
    if (bValidUntil) {
      if (!aValidUntil || aValidUntil > bValidUntil) {
        return -1;
      }
    }

    return 0;
  });

  return retValue;
}

/**
 * Gets information of assignments that the user has to all licenses available to the user.
 * @param state The application state (the redux store)
 * @param userId The user id
 * @param orgId The organization id
 * @returns Data of user's license assignments, one UserLicensedItemAssignments for each licensed item
 *    available to user. Returns undefined if state not initialized, and empty list if the user
 *    has no licenses available. If organization id is specified, then only licenses owned by that organization
 *    are included in results.
 */
export function getUserLicensedItemAssignments(
  state: AppState,
  userId: string,
  orgId?: string
): UserLicensedItemAssignments[] | undefined {
  if (
    !state.userAvailableLicenses ||
    !state.userAvailableLicenses[userId] ||
    !state.licenses ||
    !state.licenseAssignments
  ) {
    return undefined;
  }

  if (orgId && (!state.orgLicenseIds || !state.orgLicenseIds[orgId])) {
    // store is not properly populated.
    return undefined;
  }

  if (!state.userAvailableLicenses[userId].length) {
    return [];
  }

  const retValue: UserLicensedItemAssignments[] = [];
  const licensesAvailableToUser = state.userAvailableLicenses[userId];
  for (const licenseAvailableToUser of licensesAvailableToUser) {
    // fetch license from store.
    const license = state.licenses[licenseAvailableToUser.licenseId];
    if (!license.licensedItem) {
      console.error(
        "Incomplete license data for license %s, licensedItem missing",
        license.id
      );
      continue;
    }

    if (
      orgId &&
      state.orgLicenseIds &&
      !state.orgLicenseIds[orgId].includes(license.id as string)
    ) {
      // License does not belong to specified organization.
      continue;
    }

    const licensedItem = license.licensedItem as LicensedItem;

    // check if UserLicensedItemAssignments already exists for this licensed item...
    let userLicensedItemAssignment = retValue.find(
      (existingResultObj) =>
        existingResultObj.licensedItemId === licensedItem.id
    );

    // ...if not then create new and add it to returned object.
    if (!userLicensedItemAssignment) {
      userLicensedItemAssignment = {} as UserLicensedItemAssignments;
      userLicensedItemAssignment.licensedItemId = licensedItem.id as string;
      userLicensedItemAssignment.licensedItemName = licensedItem.name as string;
      userLicensedItemAssignment.licensedItemDisplayName =
        licensedItem.displayName;
      userLicensedItemAssignment.userId = userId;
      userLicensedItemAssignment.isConsuming = false;
      retValue.push(userLicensedItemAssignment);
    }

    // Fetch assigments from store for this license.
    const assignments = licenseAvailableToUser.assignmentIds.map(
      (assignmentId) =>
        (state.licenseAssignments as LicenseAssignmentsById)[assignmentId]
    );

    // Construct LicenseAndAssigments structure from license and assigments.
    const licenseAndAssignments: LicenseAndAssignments = {
      license,
      assignments,
    };

    // Add created LicenseAndAssigments to results.
    userLicensedItemAssignment.assignments =
      userLicensedItemAssignment.assignments || ([] as LicenseAndAssignments[]);
    userLicensedItemAssignment.assignments.push(licenseAndAssignments);

    // Check if user is consuming license.
    userLicensedItemAssignment.isConsuming = consumesLicense(
      userId,
      license.id as string,
      state
    );
  }

  return retValue;
}

/**
 * Returns true if user is consuming specified license.
 *
 * @param userId Id of user
 * @param licenseId Id of license
 * @param state Current state of redux store
 */
export function consumesLicense(
  userId: string,
  licenseId: string,
  state: AppState
): boolean {
  // Sanity check for store.
  if (!state.licenseUsage || !state.licenseAssignments) {
    return false;
  }

  // Get LicenseUserDatas from store for specified license.
  const licenseUsage = state.licenseUsage[licenseId];
  if (!licenseUsage) {
    return false;
  }
  const licenseUserDatas = licenseUsage.users;
  if (!licenseUserDatas) {
    return false;
  }

  // Find LicenseUserData for specified user.
  const licenseUserData = licenseUserDatas.find(
    (licenseUserData) => licenseUserData.userId === userId
  );
  if (!licenseUserData) {
    return false;
  }
  if (!licenseUserData.assignmentIds) {
    return false;
  }

  // Retrieve user's assigment and session objects for specified license from store.
  const assignmentsWithSessions = licenseUserData.assignmentIds.map(
    (id) => (state.licenseAssignments as LicenseAssignmentsById)[id]
  )
  // remove undefined assignments that were not found from license assignments, they're likely a leftover from
  // license release that does not have sufficient data to clear them.
  .filter((f) => !!f);
  if (!assignmentsWithSessions) {
    return false;
  }

  // Check validity of sessions.
  for (const licenseAssignmentWithSessions of assignmentsWithSessions) {
    const sessions = licenseAssignmentWithSessions.sessions;
    if (!sessions) {
      continue;
    }

    for (const session of sessions) {
      if (isValid(session)) {
        // Valid session found. User is consuming this license.
        return true;
      }
    }
  }

  return false;
}

/**
 * Gets licenses owned by organization, along with all information currently available for each license.
 * @param orgId The organization id
 * @param entId The required entitlement id, or "any" to return licenses of all entitlements
 * @param state The application state (the redux store)
 * @returns Licenses of the organization, with all data currently populated in the store.
 *  Undefined if no license data found for the given organization.
 */
export function getOrganizationLicenses(
  orgId: string,
  entId: string,
  state: AppState
): (LicenseWithOwner & LicenseUsage)[] | undefined {
  const orgLicenseIds = state.orgLicenseIds
    ? state.orgLicenseIds[orgId]
    : undefined;
  if (!orgLicenseIds || !state.licenses) {
    return;
  }

  let retValue = orgLicenseIds
    .map((licenseId) => (state.licenses as LicensesById)[licenseId])
    .filter((license) => entId === ID_ANY || license.entitlementId === entId)
    .map((license) => populateLicenseAssignments(license, state));

  return retValue;
}

/**
 * Populates current user license assignment data to the given license.
 * @param license License for which usage data is populated
 * @param state The application state (the redux store)
 * @returns LicenseUsage object representing the input license with user license assignment data populated.
 */
export function populateLicenseAssignments<T extends LicenseWithCredits>(
  license: T,
  state: AppState
): LicenseUsage {
  const licenseId = license.id as string;
  if (
    !state.licenseUsage ||
    !state.licenseUsage[licenseId] ||
    !state.licenseAssignments
  ) {
    return license;
  }

  const licWithUsage = license as LicenseUsage;
  const licenseUsers = state.licenseUsage[licenseId].users.map((userData) => {
    let user: LicenseUserWithAssignmentsAndSessions | undefined = undefined;
    const existingLicUsageIndex = licWithUsage.users
      ? licWithUsage.users.findIndex(
          (licUser) => licUser.id === userData.userId
        )
      : -1;
    const existingLicUsage =
      existingLicUsageIndex === -1
        ? undefined
        : (licWithUsage.users as LicenseUserWithAssignmentsAndSessions[])[
            existingLicUsageIndex
          ];
    if (existingLicUsage) {
      user = existingLicUsage;
    }
    if (!user && state.users) {
      user = state.users[userData.userId];
    }
    if (!user) {
      user = {
        id: userData.userId,
      };
    }

    const assignments = userData.assignmentIds.map(
      (assId) => (state.licenseAssignments as LicenseAssignmentsById)[assId]
    )
    // remove undefined assignments that were not found from license assignments, they're likely a leftover from
    // license release that does not have sufficient data to clear them
        .filter((f) => !!f);
    return { ...user, assignments };
  }, {});

  return { ...licWithUsage, users: licenseUsers };
}

/**
 * Aggregates seatCountCredits of the given license to produce one SeatCountCredit object representing
 * current seatCount and current seatsConsumed.
 * @param license LicenseWithCredits object representing license with credit data
 * @returns LicenseUsage object that always has exactly one item in the seatCountCredits array.
 *    For more details about this item, see documentation of the aggregateValidSeatCountCreditData method.
 */
export function aggregateValidSeatCountCreditDataForLicense(
  license: LicenseWithCredits
): LicenseWithCredits {
  return {
    ...license,
    seatCountCredits: [
      aggregateValidSeatCountCreditData(license.seatCountCredits || []),
    ],
  };
}

/**
 * Aggregates SeatCountCredit object to produce one SeatCountCredit object representing current seatCount
 * and current seatsConsumed.
 * @param credits SeatCountCredit objects to aggregate
 * @returns The aggregated SeatCountCredit. seatCount and seatsConsumed are sums of all valid input credits.
 *    validFrom is the earliest validFrom of the input credits, validUntil is the latest validUntil of
 *    the input credits.
 *
 *    If no valid SeatCountCredits found, returns an object with validFrom set to undefined,
 *    seatCount and seatsConsumed set to zero.
 *
 *    Id of the returned object is always undefined.
 */
export function aggregateValidSeatCountCreditData(
  credits: SeatCountCredit[]
): SeatCountCredit {
  const validCredits = credits.filter((credit) => isValid(credit));
  let aggregateBase = validCredits.length
    ? ({
        seatsConsumed: 0,
        validFrom: validCredits[0].validFrom,
        validUntil: validCredits[0].validUntil,
        licenseId: validCredits[0].licenseId,
      } as SeatCountCredit)
    : ({
        seatCount: 0,
        seatsConsumed: 0,
      } as SeatCountCredit);
  let initialSeatCountSet = aggregateBase.seatCount !== undefined && aggregateBase.seatCount >= 0;

  return validCredits.reduce<SeatCountCredit>((aggregated, credit) => {
    if (aggregated.validUntil) {
      if (credit.validUntil) {
        const aggregatedValidUntil = new Date(aggregated.validUntil);
        const creditValidUntil = new Date(credit.validUntil);
        if (creditValidUntil.getTime() > aggregatedValidUntil.getTime()) {
          aggregated.validUntil = credit.validUntil;
        }
      } else {
        aggregated.validUntil = undefined;
      }
    }

    if (aggregated.validFrom) {
      if (credit.validFrom) {
        const aggregatedValidFrom = new Date(aggregated.validFrom);
        const creditValidFrom = new Date(credit.validFrom);
        if (creditValidFrom.getTime() < aggregatedValidFrom.getTime()) {
          aggregated.validFrom = credit.validFrom;
        }
      }
    } else {
      aggregated.validFrom = credit.validFrom;
    }
    if (!initialSeatCountSet) {
      // no real data has been populated, use calue from the credit
      initialSeatCountSet = true;
      aggregated.seatCount = credit.seatCount;
    } else {
      // some real seat data has been added
      if (aggregated.seatCount !== undefined && aggregated.seatCount >= 0) {
        // seat count base is numeric
        if (credit.seatCount) {
          // seat count to process is positive, add to abse
          aggregated.seatCount += credit.seatCount;
        } else if (credit.seatCount === undefined) {
          // seat count to process is undefined, override base
          aggregated.seatCount = undefined;
        }
      } else if (aggregated.seatCount !== undefined) {
        // seat count base is invalid, set from credit
        aggregated.seatCount = credit.seatCount;
      }
    }
    (aggregated.seatsConsumed as number) += credit.seatsConsumed
      ? credit.seatsConsumed
      : 0;
    if (!aggregated.allowedVersions) {
      aggregated.allowedVersions = credit.allowedVersions;
    } else if (!!credit.allowedVersions) {
      aggregated.allowedVersions += ", " + credit.allowedVersions;
    }
    if (!aggregated.allowedVersionsDescription) {
      aggregated.allowedVersionsDescription =
        credit.allowedVersionsDescription || credit.allowedVersions;
    } else if (
      !!credit.allowedVersions ||
      !!credit.allowedVersionsDescription
    ) {
      aggregated.allowedVersionsDescription +=
        ", " + (credit.allowedVersionsDescription || credit.allowedVersions);
    }
    return aggregated;
  }, aggregateBase);
}

/**
 * Aggregates useCountCredits of the given license to produce one UseCountCredit object representing
 * current useCount and current countUsed.
 * @param license LicenseWithCredits object representing license with credit data
 * @returns LicenseUsage object that always has exactly one item in the useCountCredits array.
 *    For more details about this item, see documentation of the aggregateValidUseCountCreditData method.
 */
export function aggregateValidUseCountCreditDataForLicense(
  license: LicenseWithCredits
): LicenseWithCredits {
  return {
    ...license,
    useCountCredits: [
      aggregateValidUseCountCreditData(license.useCountCredits || []),
    ],
  };
}

/**
 * Aggregates UseCountCredit object to produce one UseCountCredit object representing current useCount
 * and current countUsed.
 * @param credits UseCountCredit objects to aggregate
 * @returns The aggregated UseCountCredit. useCount and countUsed are sums of all valid input credits.
 *    validFrom is the earliest validFrom of the input credits, validUntil is the latest validUntil of
 *    the input credits.
 *
 *    If no valid UseCountCredits found, returns an object with validFrom set to undefined,
 *    useCount and countUsed set to zero.
 *
 *    Id of the returned object is always undefined.
 */
export function aggregateValidUseCountCreditData(
  credits: UseCountCredit[]
): UseCountCredit {
  const validCredits = credits.filter((credit) => isValid(credit));
  let aggregateBase = validCredits.length
    ? ({
        useCount: 0,
        countUsed: 0,
        validFrom: validCredits[0].validFrom,
        validUntil: validCredits[0].validUntil,
        licenseId: validCredits[0].licenseId,
      } as UseCountCredit)
    : ({
        useCount: 0,
        countUsed: 0,
      } as UseCountCredit);

  return validCredits.reduce<UseCountCredit>((aggregated, credit) => {
    if (aggregated.validUntil) {
      if (credit.validUntil) {
        const aggregatedValidUntil = new Date(aggregated.validUntil);
        const creditValidUntil = new Date(credit.validUntil);
        if (creditValidUntil.getTime() > aggregatedValidUntil.getTime()) {
          aggregated.validUntil = credit.validUntil;
        }
      } else {
        aggregated.validUntil = undefined;
      }
    }

    if (aggregated.validFrom) {
      if (credit.validFrom) {
        const aggregatedValidFrom = new Date(aggregated.validFrom);
        const creditValidFrom = new Date(credit.validFrom);
        if (creditValidFrom.getTime() < aggregatedValidFrom.getTime()) {
          aggregated.validFrom = credit.validFrom;
        }
      }
    } else {
      aggregated.validFrom = credit.validFrom;
    }

    (aggregated.useCount as number) += credit.useCount ? credit.useCount : 0;
    (aggregated.countUsed as number) += credit.countUsed ? credit.countUsed : 0;

    if (!aggregated.allowedVersions) {
      aggregated.allowedVersions = credit.allowedVersions;
    } else if (!!credit.allowedVersions) {
      aggregated.allowedVersions += ", " + credit.allowedVersions;
    }
    if (!aggregated.allowedVersionsDescription) {
      aggregated.allowedVersionsDescription =
        credit.allowedVersionsDescription || credit.allowedVersions;
    } else if (
      !!credit.allowedVersions ||
      !!credit.allowedVersionsDescription
    ) {
      aggregated.allowedVersionsDescription +=
        ", " + (credit.allowedVersionsDescription || credit.allowedVersions);
    }

    return aggregated;
  }, aggregateBase);
}

/**
 * Aggregates useTimeCredits of the given license to produce one UseTimeCredit object representing
 * current useTime and current timeUsed.
 * @param license LicenseWithCredits object representing license with credit data
 * @returns LicenseUsage object that always has exactly one item in the useTimeCredits array.
 *    For more details about this item, see documentation of the aggregateValidUseTimeCreditData method.
 */
export function aggregateValidUseTimeCreditDataForLicense(
  license: LicenseWithCredits
): LicenseWithCredits {
  return {
    ...license,
    useTimeCredits: [
      aggregateValidUseTimeCreditData(license.useTimeCredits || []),
    ],
  };
}

/**
 * Aggregates UseTimeCredit object to produce one UseTimeCredit object representing current useTime
 * and current timeUsed.
 * @param credits UseTimeCredit objects to aggregate
 * @returns The aggregated UseTimeCredit. useTime and timeUsed are sums of all valid input credits.
 *    validFrom is the earliest validFrom of the input credits, validUntil is the latest validUntil of
 *    the input credits.
 *
 *    If no valid UseTimeCredits found, returns an object with validFrom set to undefined,
 *    useTime and timeUsed set to zero.
 *
 *    Id of the returned object is always undefined.
 */
export function aggregateValidUseTimeCreditData(
  credits: UseTimeCredit[]
): UseTimeCredit {
  const validCredits = credits.filter((credit) => isValid(credit));
  let aggregateBase = validCredits.length
    ? ({
        useTime: 0,
        timeUsed: 0,
        validFrom: validCredits[0].validFrom,
        validUntil: validCredits[0].validUntil,
        licenseId: validCredits[0].licenseId,
      } as UseTimeCredit)
    : ({
        useTime: 0,
        timeUsed: 0,
      } as UseTimeCredit);

  return validCredits.reduce<UseTimeCredit>((aggregated, credit) => {
    if (aggregated.validUntil) {
      if (credit.validUntil) {
        const aggregatedValidUntil = new Date(aggregated.validUntil);
        const creditValidUntil = new Date(credit.validUntil);
        if (creditValidUntil.getTime() > aggregatedValidUntil.getTime()) {
          aggregated.validUntil = credit.validUntil;
        }
      } else {
        aggregated.validUntil = undefined;
      }
    }

    if (aggregated.validFrom) {
      if (credit.validFrom) {
        const aggregatedValidFrom = new Date(aggregated.validFrom);
        const creditValidFrom = new Date(credit.validFrom);
        if (creditValidFrom.getTime() < aggregatedValidFrom.getTime()) {
          aggregated.validFrom = credit.validFrom;
        }
      }
    } else {
      aggregated.validFrom = credit.validFrom;
    }

    (aggregated.useTime as number) += credit.useTime ? credit.useTime : 0;
    (aggregated.timeUsed as number) += credit.timeUsed ? credit.timeUsed : 0;

    if (!aggregated.allowedVersions) {
      aggregated.allowedVersions = credit.allowedVersions;
    } else if (!!credit.allowedVersions) {
      aggregated.allowedVersions += ", " + credit.allowedVersions;
    }
    if (!aggregated.allowedVersionsDescription) {
      aggregated.allowedVersionsDescription =
        credit.allowedVersionsDescription || credit.allowedVersions;
    } else if (
      !!credit.allowedVersions ||
      !!credit.allowedVersionsDescription
    ) {
      aggregated.allowedVersionsDescription +=
        ", " + (credit.allowedVersionsDescription || credit.allowedVersions);
    }

    return aggregated;
  }, aggregateBase);
}

export interface LicenseUsersByReservation {
  licenseId: string;
  usersWithReservation: LicenseUser[];
  usersWithoutReservation: LicenseUser[];
}

/**
 * Gets organization users, sorted based on whether user has reservation to the license with the given id.
 *
 * Requires that organization users and license usage data has been populated to the application state.
 *
 * @param orgId The organization id
 * @param licenseId The license id
 * @param state The application state (the redux store)
 * @returns Object with two user arrays, one for users who have reservation to the license and one for users
 *    with no reservation.
 *
 *    Assignment data of users is included for all users who have assignment data populated. All users
 *    with reservation are guaranteed to have assignment data. Users with no reservation do not have
 *    assignment data if they don't have any kind of assignment.
 *
 *    Returns undefined if the application state is not initialized.
 */
export function getOrganizationUsersByLicenseReservation(
  orgId: string,
  licenseId: string,
  state: AppState
): LicenseUsersByReservation | undefined {
  if (
    !state.licenses ||
    !state.licenses[licenseId] ||
    !state.orgUserIds ||
    !state.orgUserIds[orgId] ||
    !state.users
  ) {
    return;
  }

  const retValue: LicenseUsersByReservation = {
    licenseId,
    usersWithReservation: [],
    usersWithoutReservation: [],
  };

  const orgUserIds = (state.orgUserIds as OrgUserIdsByOrgId)[orgId];
  const orgUsersByUserId = orgUserIds
    .map((userId) => (state.users as UsersById)[userId])
    .map((user) => userToLicenseUser(user))
    .reduce<{ [userId: string]: UserProfile }>((map, user) => {
      map[user.id as string] = user;
      return map;
    }, {});

  const license = (state.licenses as LicensesById)[licenseId] as LicenseUsage;
  const licenseUsers = license.users ? license.users : [];
  const licenseUsersWithoutReservation: {
    [userId: string]: LicenseUserWithAssignmentsAndSessions;
  } = {};

  for (const licenseUser of licenseUsers) {
    if (hasValidReservation(licenseUser)) {
      retValue.usersWithReservation.push(licenseUser);
      delete orgUsersByUserId[licenseUser.id as string];
    } else {
      licenseUsersWithoutReservation[licenseUser.id as string] = licenseUser;
    }
  }

  for (const remainingUserId in orgUsersByUserId) {
    const userWithoutReservation =
      licenseUsersWithoutReservation[remainingUserId] ||
      orgUsersByUserId[remainingUserId];
    retValue.usersWithoutReservation.push(userWithoutReservation);
  }

  return retValue;
}

/**
 * Gets organization users, with additional information about license reservation, denial and consumption to the license with the given id.
 *
 * Requires that organization users and license usage data has been populated to the application state.
 *
 * @param orgId The organization id
 * @param licenseId The license id
 * @param state The application state (the redux store)
 * @returns Array with Users
 *
 *    Assignment data of users is included for all users who have assignment data populated. All users
 *    with reservation are guaranteed to have assignment data. Users with no reservation do not have
 *    assignment data if they don't have any kind of assignment.
 *
 *    Returns undefined if the application state is not initialized.
 */
export function getOrganizationUsersWithLicenseReservation(
  orgId: string,
  license: LicenseUsage,
  state: AppState
): UserWithLicenseUsage[] | undefined {
  if (
    !license ||
    !state.orgUserIds ||
    !state.orgUserIds[orgId] ||
    !state.users
  ) {
    return;
  }

  const orgUserIds = (state.orgUserIds as OrgUserIdsByOrgId)[orgId];
  let orgUsers = orgUserIds.map(
    (userId) =>
      ({ ...(state.users as UsersById)[userId] } as UserWithLicenseUsage)
  );
  // what is this for? .map(user => userToLicenseUser(user))
  /*
      .reduce<{ [userId: string]: UserProfile }>((map, user) => {
        map[user.id as string] = user;
        return map;
      }, {});
  */
  const licenseUsers = license.users ? license.users : [];
  for (let i = 0; i < orgUsers.length; i += 1) {
    const t = licenseUsers.find(
      (u) => u.id === orgUsers[i].id
    ) as UserWithLicenseUsage;
    if (t) {
      t.hasReservation = hasValidReservation(t);
      t.hasDenial = hasValidDenial(t);
      orgUsers[i] = t;
    } else {
      orgUsers[i].hasReservation = false;
      orgUsers[i].hasDenial = false;
    }
  }
  return orgUsers;
}
/**
 * Checks if the given license user has at least one valid reservation.
 * @param licenseUser The license user
 * @returns true if at least one reservation found, false otherwise
 */
function hasValidReservation(
  licenseUser: LicenseUserWithAssignmentsAndSessions
): boolean {
  const validReservedAssignments = licenseUser.assignments
    ? licenseUser.assignments.filter(
        (assignment) => assignment.type === "reserved" && isValid(assignment)
      )
    : [];
  return validReservedAssignments.length > 0;
}
/**
 * Checks if the given license user has at least one valid denial.
 * @param licenseUser The license user
 * @returns true if at least one denial found, false otherwise
 */
function hasValidDenial(
  licenseUser: LicenseUserWithAssignmentsAndSessions
): boolean {
  const validDeniedAssignments = licenseUser.assignments
    ? licenseUser.assignments.filter(
        (assignment) => assignment.type === "denied" && isValid(assignment)
      )
    : [];
  return validDeniedAssignments.length > 0;
}

/**
 * Gets organization users and information of license reservations held by each user.
 *
 * Requires that organization users, organization licenses and license usage data has been populated
 * to the application state.
 *
 * @param orgId The organization id
 * @param state The application state (the redux store)
 * @returns Object with data of licensed items for which at least one license is found,
 *    and with array of organization license users. For each user, there is a map for
 *    data indicating if the user has at least one valid reservation to a license for a licensed item.
 *
 *    Returns undefined if application state is not populated as required.
 */
export function getOrganizationLicenseUsersWithLicensedItemReservations(
  orgId: string,
  state: AppState
): LicenseUsersWithLicensedItemReservations | undefined {
  if (
    !state.licenses ||
    !state.orgLicenseIds ||
    !state.orgLicenseIds[orgId] ||
    !state.orgUserIds ||
    !state.orgUserIds[orgId] ||
    !state.users ||
    !state.licenseAssignments ||
    !state.licenseUsage
  ) {
    return;
  }

  const orgUserIds = (state.orgUserIds as OrgUserIdsByOrgId)[orgId];
  const orgUsersById = orgUserIds
    .map((userId) => (state.users as UsersById)[userId])
    .map((user) => userToLicenseUser(user))
    .reduce<{ [userId: string]: UserProfile }>((map, user) => {
      map[user.id as string] = user;
      return map;
    }, {});

  const orgLicenses = (state.orgLicenseIds as LicenseIdsByOrgId)[orgId]
    .map((licenseId) => (state.licenses as LicensesById)[licenseId])
    .map((license) => populateLicenseAssignments(license, state));

  const licensedItemsById: { [licensedItemId: string]: LicensedItem } = {};
  const licenseUserWithReservationIdsByLicensedItemId: {
    [licensedItemId: string]: Set<string>;
  } = {};
  const licenseUserWithConsumptionIdsByLicensedItemId: {
    [licensedItemId: string]: Set<string>;
  } = {};
  const licenseUsersById: {
    [licenseUserId: string]: LicenseUserWithAssignmentsAndSessions;
  } = {};
  for (const license of orgLicenses) {
    const licensedItem = license.licensedItem as LicensedItem;
    const licensedItemId = licensedItem.id as string;
    licensedItemsById[licensedItemId] = licensedItem;

    if (!license.users) {
      continue;
    }

    if (!licenseUserWithReservationIdsByLicensedItemId[licensedItemId]) {
      licenseUserWithReservationIdsByLicensedItemId[licensedItemId] =
        new Set<string>();
    }
    if (!licenseUserWithConsumptionIdsByLicensedItemId[licensedItemId]) {
      licenseUserWithConsumptionIdsByLicensedItemId[licensedItemId] =
        new Set<string>();
    }

    for (const licenseUser of license.users) {
      const userId = licenseUser.id as string;
      licenseUsersById[userId] = licenseUser;
      if (hasValidReservation(licenseUser)) {
        licenseUserWithReservationIdsByLicensedItemId[licensedItemId].add(
          userId
        );
      }
      if (consumesLicense(userId, license.id as string, state)) {
        licenseUserWithConsumptionIdsByLicensedItemId[licensedItemId].add(
          userId
        );
      }
    }
  }

  const retValue: LicenseUsersWithLicensedItemReservations = {
    users: [],
    licensedItems: licensedItemsById,
  };

  for (const licenseUserId in licenseUsersById) {
    const licenseUser = licenseUsersById[licenseUserId];
    const userId = licenseUser.id as string;
    const licensedItemReservations: { [licensedItemId: string]: boolean } = {};
    const licensedItemConsumptions: { [licensedItemId: string]: boolean } = {};
    for (const licensedItemId in licensedItemsById) {
      licensedItemReservations[licensedItemId] =
        licenseUserWithReservationIdsByLicensedItemId[licensedItemId] &&
        licenseUserWithReservationIdsByLicensedItemId[licensedItemId].has(
          userId
        );
      licensedItemConsumptions[licensedItemId] =
        licenseUserWithConsumptionIdsByLicensedItemId[licensedItemId] &&
        licenseUserWithConsumptionIdsByLicensedItemId[licensedItemId].has(
          userId
        );
    }
    retValue.users.push({
      ...licenseUser,
      licensedItemReservations,
      licensedItemConsumptions,
    });
    delete orgUsersById[userId];
  }

  for (const orgUserId in orgUsersById) {
    const orgUser = orgUsersById[orgUserId];
    retValue.users.push({
      ...orgUser,
      licensedItemReservations: {},
      licensedItemConsumptions: {},
    });
  }

  return retValue;
}

/**
 * Represents a User as a SimpleUserProfile.
 * @param user The User object
 * @returns SimpleUserProfile representing the User
 */
export function userToLicenseUser(user: User): LicenseUser {
  return { ...user, username: user.email };
}

/**
 * Gets array of LicenseAssignment objects to reflect changed license reservation status for users
 * in the given LicenseUser arrays. License usage / assignment data must have been populated for the relevant
 * license before calling this method.
 * @param usersWithReservation List of LicenseUser object for users who should have a reserved assignment.
 * @param usersWithoutReservation List of LicenseUser object for users who should not have a reserved assignment.
 * @returns The changed LicensedAssignments by user id
 */
export function getLicenseAssignmentsForManageReservations(
  usersWithReservation: LicenseUser[],
  usersWithoutReservation: LicenseUser[]
): { [userId: string]: LicenseAssignment[] } {
  const addReservationUsers = usersWithReservation.filter(
    (user) =>
      !isInstanceOfLicenseUserWithAssignmentsAndSessions(user) ||
      !containsValidReservation(
        user.assignments as LicenseAssignmentWithSessions[]
      )
  );
  const addReservationAssignmentsByUserId = addReservationUsers.reduce<{
    [userId: string]: LicenseAssignment[];
  }>((map, user) => {
    map[user.id as string] = isInstanceOfLicenseUserWithAssignmentsAndSessions(
      user
    )
      ? (user.assignments as LicenseAssignmentWithSessions[])
      : [];
    return map;
  }, {});

  const removeReservationUsers = usersWithoutReservation.filter(
    (user) =>
      isInstanceOfLicenseUserWithAssignmentsAndSessions(user) &&
      containsValidReservation(
        user.assignments as LicenseAssignmentWithSessions[]
      )
  );
  const removeReservationAssignmentsByUserId = removeReservationUsers.reduce<{
    [userId: string]: LicenseAssignment[];
  }>((map, user) => {
    map[user.id as string] = isInstanceOfLicenseUserWithAssignmentsAndSessions(
      user
    )
      ? (user.assignments as LicenseAssignmentWithSessions[])
      : [];
    return map;
  }, {});

  const retValue: { [userId: string]: LicenseAssignment[] } = {};
  for (const userId in removeReservationAssignmentsByUserId) {
    const assignments = removeReservationAssignmentsByUserId[userId];
    const removeReservationAssignments =
      removeReservation<LicenseAssignmentWithSessions>(assignments);
    retValue[userId] = removeReservationAssignments;
  }

  for (const userId in addReservationAssignmentsByUserId) {
    const assignments = addReservationAssignmentsByUserId[userId];
    const addReservationAssignments =
      addReservation<LicenseAssignmentWithSessions>(assignments);
    retValue[userId] = addReservationAssignments;
  }

  return retValue;
}
/**
 * Gets array of LicenseAssignment objects to reflect changed license reservation status for users
 * in the given LicenseUser arrays. License usage / assignment data must have been populated for the relevant
 * license before calling this method.
 * @param usersWithReservation List of LicenseUser object for users who should have a reserved assignment.
 * @param usersWithoutReservation List of LicenseUser object for users who should not have a reserved assignment.
 * @param usersWithDenial List of LicenseUser object for users who should have a denial assignment.
 * @param usersWithoutDenial List of LicenseUser object for users who should not have a denial assignment.
 * @returns The changed LicensedAssignments by user id
 */
export function getLicenseAssignmentsForManageReservationsAndDenials(
  usersWithReservation: LicenseUser[],
  usersWithDenial: LicenseUser[],
  usersWithoutAssignments: LicenseUser[]
): { [userId: string]: LicenseAssignment[] } {
  const addReservationUsers = usersWithReservation.filter(
    (user) =>
      !isInstanceOfLicenseUserWithAssignmentsAndSessions(user) ||
      !containsValidReservation(
        user.assignments as LicenseAssignmentWithSessions[]
      )
  );
  const addReservationAssignmentsByUserId = addReservationUsers.reduce<{
    [userId: string]: LicenseAssignment[];
  }>((map, user) => {
    map[user.id as string] = isInstanceOfLicenseUserWithAssignmentsAndSessions(
      user
    )
      ? (user.assignments as LicenseAssignmentWithSessions[])
      : [];
    return map;
  }, {});

  const removeReservationUsers = usersWithoutAssignments.filter(
    (user) =>
      isInstanceOfLicenseUserWithAssignmentsAndSessions(user) &&
      containsValidReservation(
        user.assignments as LicenseAssignmentWithSessions[]
      )
  );
  const removeReservationAssignmentsByUserId = removeReservationUsers.reduce<{
    [userId: string]: LicenseAssignment[];
  }>((map, user) => {
    map[user.id as string] = isInstanceOfLicenseUserWithAssignmentsAndSessions(
      user
    )
      ? (user.assignments as LicenseAssignmentWithSessions[])
      : [];
    return map;
  }, {});

  const addDenialUsers = usersWithDenial.filter(
    (user) =>
      !isInstanceOfLicenseUserWithAssignmentsAndSessions(user) ||
      !containsValidDenial(user.assignments as LicenseAssignmentWithSessions[])
  );
  const addDenialAssignmentsByUserId = addDenialUsers.reduce<{
    [userId: string]: LicenseAssignment[];
  }>((map, user) => {
    map[user.id as string] = isInstanceOfLicenseUserWithAssignmentsAndSessions(
      user
    )
      ? (user.assignments as LicenseAssignmentWithSessions[])
      : [];
    return map;
  }, {});

  const removeDenialUsers = usersWithoutAssignments.filter(
    (user) =>
      isInstanceOfLicenseUserWithAssignmentsAndSessions(user) &&
      containsValidDenial(user.assignments as LicenseAssignmentWithSessions[])
  );
  const removeDenialAssignmentsByUserId = removeDenialUsers.reduce<{
    [userId: string]: LicenseAssignment[];
  }>((map, user) => {
    map[user.id as string] = isInstanceOfLicenseUserWithAssignmentsAndSessions(
      user
    )
      ? (user.assignments as LicenseAssignmentWithSessions[])
      : [];
    return map;
  }, {});

  const retValue: { [userId: string]: LicenseAssignment[] } = {};
  for (const userId in removeReservationAssignmentsByUserId) {
    const assignments = removeReservationAssignmentsByUserId[userId];
    const removeReservationAssignments =
      removeReservation<LicenseAssignmentWithSessions>(assignments);
    retValue[userId] = removeReservationAssignments;
  }

  for (const userId in addReservationAssignmentsByUserId) {
    const assignments = addReservationAssignmentsByUserId[userId];
    const addReservationAssignments =
      addReservation<LicenseAssignmentWithSessions>(assignments);
    retValue[userId] = addReservationAssignments;
  }

  for (const userId in removeDenialAssignmentsByUserId) {
    const assignments = removeDenialAssignmentsByUserId[userId];
    const removeDenialAssignments =
      removeDenial<LicenseAssignmentWithSessions>(assignments);
    retValue[userId] = removeDenialAssignments;
  }

  for (const userId in addDenialAssignmentsByUserId) {
    const assignments = addDenialAssignmentsByUserId[userId];
    const addDenialAssignments =
      addDenial<LicenseAssignmentWithSessions>(assignments);
    retValue[userId] = addDenialAssignments;
  }
  return retValue;
}

/**
 * Modifies the given array of license assignments to add license reservation.
 * @param assignments Array of the current assignments.
 * @returns Array of LicenseAssignment objects for adding reservation.
 */
function addReservation<T extends LicenseAssignment>(
  assignments: T[]
): LicenseAssignment[] {
  const sortedAssignments = sortAssignmentsForManageReservations(assignments);
  if (sortedAssignments.length === 0) {
    sortedAssignments.push({
      type: "reserved",
      validFrom: "now()",
    });
    return sortedAssignments;
  }

  const reservedAssignment: LicenseAssignment = {
    ...sortedAssignments[0],
    type: "reserved",
    validUntil: undefined,
  };
  const now = new Date();
  if (
    !reservedAssignment.validFrom ||
    new Date(reservedAssignment.validFrom).getTime() > now.getTime()
  ) {
    reservedAssignment.validFrom = "now()";
  }

  sortedAssignments[0] = reservedAssignment;

  return sortedAssignments;
}

/**
 * Modifies the given array of license assignments to add license reservation.
 * @param assignments Array of the current assignments.
 * @returns Array of LicenseAssignment objects for denying use.
 */
function addDenial<T extends LicenseAssignment>(
  assignments: T[]
): LicenseAssignment[] {
  const sortedAssignments = sortAssignmentsForManageReservations(assignments);
  if (sortedAssignments.length === 0) {
    sortedAssignments.push({
      type: "denied",
      validFrom: "now()",
    });
    return sortedAssignments;
  }

  const deniedAssignment: LicenseAssignment = {
    ...sortedAssignments[0],
    type: "denied",
    validUntil: undefined,
  };
  const now = new Date();
  if (
    !deniedAssignment.validFrom ||
    new Date(deniedAssignment.validFrom).getTime() > now.getTime()
  ) {
    deniedAssignment.validFrom = "now()";
  }

  sortedAssignments[0] = deniedAssignment;

  return sortedAssignments;
}

/**
 * Modifies the given array of license assignments to remove license reservation.
 * @param assignments Array of the current assignments.
 * @returns Array of LicenseAssignment objects for removing reservation.
 */
function removeReservation<T extends LicenseAssignment>(
  assignments: T[]
): LicenseAssignment[] {
  const validReservationIndex = findIndexOfValidReservation(assignments);
  if (validReservationIndex === -1) {
    return assignments;
  }

  const floatingAssignment: LicenseAssignment = {
    ...assignments[validReservationIndex],
    type: "floating",
  };

  const retValue: LicenseAssignment[] = [...assignments];
  retValue[validReservationIndex] = floatingAssignment;

  return retValue;
}
/**
 * Modifies the given array of license assignments to remove license denial.
 * @param assignments Array of the current assignments.
 * @returns Array of LicenseAssignment objects for removing denial.
 */
function removeDenial<T extends LicenseAssignment>(
  assignments: T[]
): LicenseAssignment[] {
  const validDenialIndex = findIndexOfValidDenial(assignments);
  if (validDenialIndex === -1) {
    return assignments;
  }

  const floatingAssignment: LicenseAssignment = {
    ...assignments[validDenialIndex],
    type: "floating",
  };

  const retValue: LicenseAssignment[] = [...assignments];
  retValue[validDenialIndex] = floatingAssignment;

  return retValue;
}

/**
 * Checks if at least one valid assignment with type "reserved" is found in the given list of license assignments.
 * @param assignments Assignments to check
 * @returns true if a valid "reserved" assignment found, false otherwise
 */
function containsValidReservation<T extends LicenseAssignment>(
  assignments: T[]
): boolean {
  const validReservationIndex = findIndexOfValidReservation(assignments);
  return validReservationIndex !== -1;
}
/**
 * Checks if at least one valid assignment with type "denied" is found in the given list of license assignments.
 * @param assignments Assignments to check
 * @returns true if a valid "denied" assignment found, false otherwise
 */
function containsValidDenial<T extends LicenseAssignment>(
  assignments: T[]
): boolean {
  const validDenialIndex = findIndexOfValidDenial(assignments);
  return validDenialIndex !== -1;
}

/**
 * Finds index of LicenseAssignment describing a valid reservation.
 * @param assignments Assignments to check
 * @returns Index of valid LicenseAssignment object, or -1 if no valid object found.
 */
function findIndexOfValidReservation<T extends LicenseAssignment>(
  assignments: T[]
): number {
  return assignments.findIndex(
    (assignment) => assignment.type === "reserved" && isValid(assignment)
  );
}

/**
 * Finds index of LicenseAssignment describing a valid denial.
 * @param assignments Assignments to check
 * @returns Index of valid LicenseAssignment object, or -1 if no valid object found.
 */
function findIndexOfValidDenial<T extends LicenseAssignment>(
  assignments: T[]
): number {
  return assignments.findIndex(
    (assignment) => assignment.type === "denied" && isValid(assignment)
  );
}

/**
 * Updates stored license data (if stored data exists) with the given new data.
 * @param newLicense New license data.
 * @param oldLicense Old license data, if old license data exists.
 */
export function updateLicense<T extends License>(
  newLicense: T,
  oldLicense?: T
): T {
  let retValue: T;

  if (oldLicense) {
    retValue = { ...oldLicense, ...newLicense };
  } else {
    retValue = newLicense;
  }

  return retValue;
}

/**
 * Updates stored license assignment data (if stored data exists) with the given new data.
 * @param newAssignment New license assignment data.
 * @param oldAssignment Old license assignment data, if old license assignment data exists.
 */
export function updateAssignment(
  newAssignment: LicenseAssignmentWithSessions,
  oldAssignment?: LicenseAssignmentWithSessions
): LicenseAssignmentWithSessions {
  let retValue: LicenseAssignmentWithSessions;
  if (oldAssignment) {
    retValue = { ...oldAssignment, ...newAssignment };
  } else {
    retValue = newAssignment;
  }

  return retValue;
}

/**
 * Aggregates licenses contained by the given UserLicensedItemAssignments object to produce a single license
 * with a single SeatCountCredit. Validity times describe aggregated validity of licenses and credits,
 * seat count describes the current situation.
 * @param userLicensedItemAssignments UserLicensedItemAssignments object
 * @returns Aggregated LicenseWithOwnerAndSingleUserAssignments object that has
 *    assignments, licensedItem, seatCountCredits and validity fields set. Validity fields
 *    of the object represent earliest and latest aggregated validity dates. seatCountCredits
 *    has one item that represents current seat count credit status.
 */
export function aggregateUserLicenses(
  userLicensedItemAssignments: UserLicensedItemAssignments
): LicenseWithOwnerAndSingleUserAssignments {
  const licenseAssignments = userLicensedItemAssignments.assignments.flatMap(
    (itemAssignment) => itemAssignment.assignments
  );

  const licenses = userLicensedItemAssignments.assignments
    .map((itemAssignment) => itemAssignment.license)
    .map(aggregateValidSeatCountCreditDataForLicense);

  let aggregated: LicenseWithCredits;
  // There is always at least one license
  if (licenses.length === 1) {
    aggregated = licenses[0];
  } else {
    aggregated = licenses.reduce<LicenseWithCredits>(
      (aggregated, license) => {
        if (license.seatCountCredits) {
          aggregated.seatCountCredits = [
            ...(aggregated.seatCountCredits as SeatCountCredit[]),
            ...(license.seatCountCredits as SeatCountCredit[]),
          ];
        }
        if (!isValid(license)) {
          return aggregated;
        }

        if (
          !aggregated.validFrom ||
          (license.validFrom &&
            new Date(license.validFrom).getTime() <
              new Date(aggregated.validFrom).getTime())
        ) {
          aggregated.validFrom = license.validFrom;
        }
        if (
          aggregated.validUntil &&
          (!license.validUntil ||
            new Date(license.validUntil).getTime() >
              new Date(aggregated.validUntil).getTime())
        ) {
          aggregated.validUntil = license.validUntil;
        }
        return aggregated;
      },
      { ...licenses[0], seatCountCredits: [] }
    );
    aggregated = aggregateValidSeatCountCreditDataForLicense(aggregated);
  }

  return {
    assignments: licenseAssignments,
    licensedItem: aggregated.licensedItem,
    seatCountCredits: aggregated.seatCountCredits,
    validFrom: aggregated.validFrom,
    validUntil: aggregated.validUntil,
    id: aggregated.id,
  };
}
export function convertAssignmentsToSingleAssignmentForSettingAssignmentsCall<
  T extends LicenseAssignment
>(assignments: T[]): LicenseAssignment[] {
  /*
  console.log('converting assignments %o', assignments);
  if (assignments && assignments.length > 0) {
    console.log('converting assignments 0 state %o validity %o', assignments[0].type, isValid(assignments[0]));
  }
   */
  /*
  There seems to be some cases where this produces incorrect results, though the first assignment should be the one.
  */
  const ass = assignments.length && assignments[0] ? assignments[0] : undefined;
  const deny = ass ? ass.type === "denied" && isValid(ass) : false;
  const reserve = ass ? ass.type === "reserved" && isValid(ass) : false;
  const release = ass ? ass.type === "floating" && isValid(ass) : false;

  /*
  const deny = assignments.filter((a) => a.type === "denied" && isValid(a));
  const reserve = assignments.filter((a) => a.type === "reserved" && isValid(a));
  const release = assignments.filter((a) => a.type === "floating" && isValid(a));
  */
  if (deny) {
    // console.log('converting assignments denial');
    return addDenial([]);
  } else if (reserve) {
    // console.log('converting assignments reservation');
    return addReservation([]);
  } else if (release) {
    // console.log('converting assignments release');
    return [
      {
        type: "floating",
        validFrom: "now()",
      },
    ];
  } else {
    // console.log('converting assignments none');
    return [];
  }
}
