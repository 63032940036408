import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./default-language-error-screen-view.scss";
import {IconLibrary, Screen} from "@10duke/dukeui";
import logo from "../../logo";
//<editor-fold desc="Messages">
//</editor-fold>

//<editor-fold desc="Props">
//</editor-fold>
export default function DefaultLanguageErrorScreen(props: any) {
  const intl = useIntl();
  return (
      <Screen
          id={"default-language-error-screen"}
          data-test-default-language-error-screen
          meta={{
              title: intl.formatMessage({
                  defaultMessage: "Default language not available",
                  description: "heading for the screen",
              }),
              description: intl.formatMessage({
                  defaultMessage: "Default language copy not found.",
                  description: "window meta description for the screen",
              }),
          }}
          header={<img src={logo} alt={""} title={""} className={'logo'}/>}
      >
          <FontAwesomeIcon icon={IconLibrary.icons.faExclamationTriangle} className={"icon"}/>
          <h1>
              <FormattedMessage
                  defaultMessage="Oh no!"
                  description={"language error screen title"}
              />
          </h1>
          <p>
              <FormattedMessage
                  defaultMessage="Default language copy could not be located. Please contact administrator."
                  description={
                      "language error screen message. The administrator here is not the same as the 'admin' used elsewhere, different translation is preferred. This refers to a person responsible for deploying and managing this application."
                  }
              />
          </p>
      </Screen>
  );
}
