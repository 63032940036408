import { useContext, useEffect, useMemo, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { IconLibrary, ProgressIndicator, AppLayout, AppHeaderDropdownVariantContext, NavItem } from "@10duke/dukeui";
import SelectOrganizationPage from "../../components/pages/select-organization";
import Licenses, {
  ModalKeys as LicensesModalKeys,
} from "../../routes/licenses";
import Users, { ModalKeys as UsersModalKeys } from "../../routes/users";
import NotFound from "../../routes/not-found";
import SelectOrganization from "../../routes/select-organization";
import {FormattedMessage, useIntl} from "react-intl";
import InProgress from "../in-progress";
import { getEnvParam } from "../../util/env";
import Dashboard, {
  ModalKeys as DashboardModalKeys,
} from "../../routes/dashboard";
import { UUID_REG_EX_PATTERN } from "@10duke/dukeui";
import Groups, { ModalKeys as GroupsModalKeys } from "../../routes/groups";
import DeviceClientGroups, { ModalKeys as DeviceClientGroupsModalKeys } from "../../routes/device-client-groups";

import Roles, { ModalKeys as RolesModalKeys } from "../../routes/roles";

import Invitations, {
  ModalKeys as InvitationsModalKeys,
} from "../../routes/invitations";
import UIConfiguration from "../../ui-configuration/configuration-provider";
import NoOrganizationToManage from "../pages/no-organization-to-manage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./app-view.scss";
import SessionExpirationWatcher from "../session-expiration-watcher";
import ActionErrorNotification from "../action-error-notification";
import DeviceClients, { ModalKeys as DeviceClientsModalKeys } from "../../routes/device-clients";
import AppMenus from "../app-menus";
import logo from "../logo";
import {OrgadminSectionConfiguration} from "../../ui-configuration/orgadmin-configuration";
import {hasAction} from "../../ui-configuration/configuration-tools";

//<editor-fold desc="Props">
export interface AppProps {
  organizationId: string | undefined;
  hasOrgsToManage: boolean;
  isReady: boolean;
  localeInPath?: string;
  externalLogout?: boolean;
}
//</editor-fold>

function isNavActive(
  match: { path: string; isExact: boolean },
  location: { pathname: string },
  modalValues: string[]
): boolean {
  let retVal: boolean = false;
  if (match) {
    if (match.isExact) {
      retVal = true;
    } else if (modalValues && modalValues.length) {
      let modal = location.pathname.replace(match.path, "");
      if (modal.startsWith("/")) {
        modal = modal.substr(1);
      }
      // at this point the modal is either modal key or selectionId/modalKey[/secondaryid]
      if (modal.indexOf("/") > 0) {
        // pick the part that is not an uuid
        const tmp = modal.split("/");
        for (let i = 0; i < tmp.length; i += 1) {
          if (!tmp[i].match(UUID_REG_EX_PATTERN)) {
            modal = tmp[i];
            break;
          }
        }
      }
      // now we have the modal key for sure
      retVal = modalValues.indexOf(modal) >= 0;
    }
  }
  return retVal;
}
function resolveEnabledModalValues(
  keys: { [key: string]: string },
  conf: OrgadminSectionConfiguration|undefined
): string[] {
  let retVal = { ...keys };
  if (conf) {
    for (let i in keys) {
      if (i === 'edit') {
        // edit requires allowEdit directly from section conf, not actions
        if (conf.allowEdit !== true) {
          delete retVal[i];
        }
      } else if (!hasAction(conf.rowActions, i) && !hasAction(conf.actions, i) && conf[i] !== true) {
        // basic check for actions, row actions and section conf
        delete retVal[i];
      }
    }
    return Object.values(retVal);
  } else {
    // no conf, all disabled
    return [];
  }
}
function App(props: AppProps): JSX.Element {
  //<editor-fold desc="Local variables">
  let {
    organizationId,
    hasOrgsToManage,
    isReady,
    localeInPath,
    externalLogout,
  } = props;
  const intl = useIntl();
  const [reactAppBasePath, setReactAppBasePath] = useState(
    (localeInPath ? "/" + localeInPath : "") + getEnvParam("REACT_APP_BASE", "")
  );
  useEffect(() => {
    setReactAppBasePath(
      (localeInPath ? "/" + localeInPath : "") +
        getEnvParam("REACT_APP_BASE", "")
    );
  }, [localeInPath]);
  const { conf } = useContext(UIConfiguration);
  /**
   * Run through all modal keys and filter out the once disabled byt conf.
   */
  let groupsModalValues: string[] = useMemo(
      () =>
          resolveEnabledModalValues(GroupsModalKeys, conf?.functionality?.groups),
      [conf?.functionality?.groups]
  );
  let deviceClientGroupsConf = conf?.functionality ? conf?.functionality["device-client-groups"] : undefined;
  let deviceClientGroupsModalValues: string[] = useMemo(
      () =>
          resolveEnabledModalValues(DeviceClientGroupsModalKeys, deviceClientGroupsConf),
      [deviceClientGroupsConf]
  );
  let rolesModalValues: string[] = useMemo(
    () => resolveEnabledModalValues(RolesModalKeys, conf?.functionality?.roles),
    [conf?.functionality?.roles]
  );
  let invitationsModalValues: string[] = useMemo(
    () => {
      // the invitation actions require special handling, as they are controlled by generic actions
      // + enable client/user flags
      // first process the generic actions normally
      let retVal = resolveEnabledModalValues(
          InvitationsModalKeys,
          conf?.functionality?.invitations
      );
      // enable client variants if needed
      if (conf?.functionality?.invitations?.enableClientInvitations === true) {
        const deviceKeys = [];
        for (let i = 0; i < retVal.length; i += 1) {
          const key = retVal[i];
          if (key === InvitationsModalKeys.show) {
            deviceKeys.push(InvitationsModalKeys.showClientInvitation)
          } else if (key === InvitationsModalKeys.create) {
            deviceKeys.push(InvitationsModalKeys.createClientInvitation)
          } else if (key === InvitationsModalKeys.remove) {
            deviceKeys.push(InvitationsModalKeys.removeClientInvitation)
          } else if (key === InvitationsModalKeys.resend) {
            deviceKeys.push(InvitationsModalKeys.resendClientInvitation)
          } else if (key === InvitationsModalKeys.revoke) {
            deviceKeys.push(InvitationsModalKeys.revokeClientInvitation)
          } else if (key === InvitationsModalKeys.showGroup) {
            deviceKeys.push(InvitationsModalKeys.showClientGroup)
          }
        }
        retVal = [...retVal, ...deviceKeys];
      }
      // disable user variants if needed
      if (conf?.functionality?.invitations?.enableUserInvitations === false) {
        retVal = retVal.filter((key) => {
          if (key === InvitationsModalKeys.show ||
              key === InvitationsModalKeys.create ||
              key === InvitationsModalKeys.remove ||
              key === InvitationsModalKeys.resend ||
              key === InvitationsModalKeys.revoke ||
              key === InvitationsModalKeys.showGroup) {
            return false;
          } else {
            return true;
          }
        })
      }
      return retVal;
    },
    [conf?.functionality?.invitations]
  );
  let usersModalValues: string[] = useMemo(
      () => resolveEnabledModalValues(UsersModalKeys, conf?.functionality?.users),
      [conf?.functionality?.users]
  );
  let deviceClientsConf = conf?.functionality ? conf?.functionality["device-clients"] : undefined;
  let deviceClientsModalValues: string[] = useMemo(
      () => resolveEnabledModalValues(DeviceClientsModalKeys, deviceClientsConf),
      [deviceClientsConf]
  );

  let licensesModalValues: string[] = useMemo(
    () =>
      resolveEnabledModalValues(
        LicensesModalKeys,
        conf?.functionality?.licenses
      ),
    [conf?.functionality?.licenses]
  );
  let dashboardModalValues: string[] = useMemo(
    () =>
      resolveEnabledModalValues(
        DashboardModalKeys,
        conf?.functionality?.dashboard
      ),
    [conf?.functionality?.dashboard]
  );
  const nav: NavItem[] = useMemo(() => {
    const retVal: NavItem[] = [];
    if (hasOrgsToManage) {
      retVal.push({
        id: "dashboard",
        icon: <FontAwesomeIcon icon={IconLibrary.icons.faHome} />,
        label: intl.formatMessage({
          defaultMessage: "Dashboard",
          description: "primary navigation link label",
        }),
        path: "/",
        isActive: (
          match: { path: string; isExact: boolean },
          location: { pathname: string }
        ) => isNavActive(match, location, dashboardModalValues),
      });
      if (conf?.functionality?.sections) {
        for (let i = 0; i < conf?.functionality?.sections.length; i += 1) {
          const t = conf?.functionality?.sections[i];
          if (t === "groups") {
            retVal.push({
              id: "groups",
              icon: <FontAwesomeIcon icon={IconLibrary.icons.faUsers} fixedWidth={true} />,
              label: intl.formatMessage({
                defaultMessage: "User groups",
                description: "primary navigation link label",
              }),
              path: "/groups",
              isActive: (
                match: { path: string; isExact: boolean },
                location: { pathname: string }
              ) => isNavActive(match, location, groupsModalValues),
            });
          } else if (t === "roles") {
            retVal.push({
              id: "roles",
              icon: IconLibrary.customIcons.roles,
              label: intl.formatMessage({
                defaultMessage: "Roles",
                description: "primary navigation link label",
              }),
              path: "/roles",
              isActive: (
                match: { path: string; isExact: boolean },
                location: { pathname: string }
              ) => isNavActive(match, location, rolesModalValues),
            });
          } else if (t === "invitations") {
            retVal.push({
              id: "invitations",
              icon: <FontAwesomeIcon icon={IconLibrary.icons.faEnvelope} fixedWidth={true} />,
              label: intl.formatMessage({
                defaultMessage: "Invitations",
                description: "primary navigation link label",
              }),
              path: "/invitations",
              isActive: (
                match: { path: string; isExact: boolean },
                location: { pathname: string }
              ) => isNavActive(match, location, invitationsModalValues),
            });
          } else if (t === "users") {
            retVal.push({
              id: "users",
              icon: <FontAwesomeIcon icon={IconLibrary.icons.faUser} fixedWidth={true} />,
              label: intl.formatMessage({
                defaultMessage: "Users",
                description: "primary navigation link label",
              }),
              path: "/users",
              isActive: (
                match: { path: string; isExact: boolean },
                location: { pathname: string }
              ) => isNavActive(match, location, usersModalValues),
            });
          } else if (t === "licenses") {
            retVal.push({
              id: "licenses",
              icon: <FontAwesomeIcon icon={IconLibrary.icons.faBoxes} fixedWidth={true} />,
              label: intl.formatMessage({
                defaultMessage: "Licenses",
                description: "primary navigation link label",
              }),
              path: "/licenses",
              isActive: (
                  match: { path: string; isExact: boolean },
                  location: { pathname: string }
              ) => isNavActive(match, location, licensesModalValues),
            });
          } else if (t === "device-clients") {
            retVal.push({
              id: "device-clients",
              icon: <FontAwesomeIcon icon={IconLibrary.icons.faHdd} fixedWidth={true} />,
              label: intl.formatMessage({
                defaultMessage: "Device clients",
                description: "primary navigation link label",
              }),
              path: "/device-clients",
              isActive: (
                  match: { path: string; isExact: boolean },
                  location: { pathname: string }
              ) => isNavActive(match, location, deviceClientsModalValues),
            });
          } else if (t === "device-client-groups") {
            retVal.push({
              id: "device-client-groups",
              icon: <FontAwesomeIcon icon={IconLibrary.icons.faServer} fixedWidth={true} />,
              label: intl.formatMessage({
                defaultMessage: "Device client groups",
                description: "primary navigation link label",
              }),
              path: "/device-client-groups",
              isActive: (
                  match: { path: string; isExact: boolean },
                  location: { pathname: string }
              ) => isNavActive(match, location, deviceClientGroupsModalValues),
            });
          }
        }
      }
    }
    return retVal;
  }, [
    hasOrgsToManage,
    dashboardModalValues,
    groupsModalValues,
    rolesModalValues,
    invitationsModalValues,
    usersModalValues,
    licensesModalValues,
    intl,
    conf?.functionality?.sections,
    deviceClientGroupsModalValues,
    deviceClientsModalValues
  ]);

  //</editor-fold>
  /**
   * The router key-attribute will magically fix the issue where changing the basename had no effect
   */
  return (
    <Router basename={reactAppBasePath} key={reactAppBasePath}>
      <AppLayout
        disableHeader={!isReady}
        pageNavigation={organizationId ? nav : undefined}
        headerBrand={<>
          <img src={logo} alt={""} className={"logo"}/>
          <span className={"service-name"}>
            <FormattedMessage
                defaultMessage="Orgadmin"
                description="Name of this admin tool in the header, displayed next to logo"
            />
          </span>
        </>}
        headerNavMenus={
          <AppHeaderDropdownVariantContext.Consumer
              children={(dropdownVariant) => (
                  <AppMenus dropdownVariant={dropdownVariant}/>
              )}
          />
        }
      >
        <SessionExpirationWatcher externalLogout={externalLogout} />
        {isReady && (
          <>
            {organizationId ? (
              <Switch>
                <Route
                  path="/organization/select"
                  component={SelectOrganization}
                />
                {conf.functionality &&
                    conf.functionality.sections &&
                    conf.functionality.sections.includes("groups") && (
                        <Route
                            path={`/groups/:groupId(${UUID_REG_EX_PATTERN})?${
                                groupsModalValues.length
                                    ? `/:modal(${groupsModalValues.join(
                                        "|"
                                    )})?/:secondaryId(${UUID_REG_EX_PATTERN})?`
                                    : ""
                            }`}
                            exact
                            component={Groups}
                        />
                    )}
                {conf.functionality &&
                    conf.functionality.sections &&
                    conf.functionality.sections.includes("device-client-groups") && (
                        <Route
                            path={`/device-client-groups/:clientGroupId(${UUID_REG_EX_PATTERN})?${
                                deviceClientGroupsModalValues.length
                                    ? `/:modal(${deviceClientGroupsModalValues.join(
                                        "|"
                                    )})?/:secondaryId(${UUID_REG_EX_PATTERN})?`
                                    : ""
                            }`}
                            exact
                            component={DeviceClientGroups}
                        />
                    )}
                {conf.functionality &&
                  conf.functionality.sections &&
                  conf.functionality.sections.includes("roles") && (
                    <Route
                      path={`/roles/:roleId(${UUID_REG_EX_PATTERN})?${
                        rolesModalValues.length
                          ? `/:modal(${rolesModalValues.join(
                              "|"
                            )})?/:secondaryId(${UUID_REG_EX_PATTERN})?`
                          : ""
                      }`}
                      exact
                      component={Roles}
                    />
                  )}
                {conf.functionality &&
                  conf.functionality.sections &&
                  conf.functionality.sections.includes("invitations") && (
                    <Route
                      path={`/invitations/:invitationId(${UUID_REG_EX_PATTERN})?${
                        invitationsModalValues.length
                          ? `/:modal(${invitationsModalValues.join(
                              "|"
                            )})?/:secondaryId(${UUID_REG_EX_PATTERN})?`
                          : ""
                      }`}
                      exact
                      component={Invitations}
                    />
                  )}
                {conf.functionality &&
                  conf.functionality.sections &&
                  conf.functionality.sections.includes("users") && (
                    <Route
                      path={`/users/:userId(${UUID_REG_EX_PATTERN})?${
                        usersModalValues.length
                          ? `/:modal(${usersModalValues.join("|")})?`
                          : ""
                      }`}
                      exact
                      component={Users}
                    />
                  )}
                {conf.functionality &&
                  conf.functionality.sections &&
                  conf.functionality.sections.includes("licenses") && (
                    <Route
                      path={`/licenses/:licenseId(${UUID_REG_EX_PATTERN})?${
                        licensesModalValues.length
                          ? `/:modal(${licensesModalValues.join(
                              "|"
                            )})?/:secondaryId(${UUID_REG_EX_PATTERN})?`
                          : ""
                      }`}
                      exact
                      component={Licenses}
                    />
                  )}
                {conf.functionality &&
                    conf.functionality.sections &&
                    conf.functionality.sections.includes("device-clients") && (
                        <Route
                            path={`/device-clients/:clientId(${UUID_REG_EX_PATTERN})?${
                                deviceClientsModalValues.length
                                    ? `/:modal(${deviceClientsModalValues.join("|")})?`
                                    : ""
                            }`}
                            exact
                            component={DeviceClients}
                        />
                    )}
                <Route
                  path={`/${
                    dashboardModalValues.length
                      ? `:modal(${dashboardModalValues.join("|")})?`
                      : ""
                  }`}
                  exact
                  component={Dashboard}
                />
                <Route path="/*" component={NotFound} />
              </Switch>
            ) : hasOrgsToManage ? (
              <SelectOrganizationPage />
            ) : (
              <NoOrganizationToManage />
            )}
          </>
        )}
      </AppLayout>
      <ProgressIndicator
          show={!isReady}
          variant={'lg'}
          backdrop={true}
      />
      <div id={"notifications"}>
        <ActionErrorNotification />
        <InProgress
          data-test-progress-indicator
          label={intl.formatMessage({
            defaultMessage: "Processing",
            description: "Progress spinner accessibility label for generic/unknown cause, i.e. wait, the app is doing something"
          })}
        />
      </div>
    </Router>
  );
}

export default App;
