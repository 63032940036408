import {MessageDescriptor, useIntl} from "react-intl";

export interface TableFilterViewProps {
  isActive: boolean,
  setActive: (active: boolean) => void,
}
export interface HideLabelTableFilterViewProps extends TableFilterViewProps {
  label: MessageDescriptor,
}
export function HideLabelTableFilterView(props: HideLabelTableFilterViewProps) {
  const {
    label,
    isActive,
  } = props;
  const intl = useIntl();
  return (<>{intl.formatMessage({
    defaultMessage: "Hide {label}",
    description:
        "Label for filter a toggle that controls visibility of items. 'label': name of the thing to hide/show, e.g. 'suspended' when hiding suspended users from users table . 'isActive': state of the filter toggle, not used in default copy",
  }, {
    label: intl.formatMessage(label),
    isActive: isActive === true,
  })}</>);
}
