import { HTMLAttributes } from "react";
import { useHistory } from "react-router-dom";

//<editor-fold desc="Props">
export interface NavigateAfterActionProps
  extends Omit<HTMLAttributes<HTMLAnchorElement>, "href" | "onClick"> {
  href: string;
  action: (callback: () => void) => void;
}
//</editor-fold>

function NavigateAfterAction(props: NavigateAfterActionProps) {
  //<editor-fold desc="Local variables">
  let { href, action, children, ...others } = props;
  const history = useHistory();
  return (
    <a
      href={href}
      onClick={(e) => {
        e.preventDefault();
        action(() => {
          setTimeout(() => {
            history.push(href);
          }, 1);
        });
      }}
      {...others}
    >
      {children}
    </a>
  );
}
export default NavigateAfterAction;
