import { withCloseAfterExited } from "@10duke/dukeui";
import View, {
  DownloadUsersLicenseModalProps as _DownloadUsersLicenseModalProps,
  DownloadUsersLicenseModalStateProps,
} from "./download-users-license-modal-view";
import { useContext, useRef, useEffect, useState } from "react";
import { UserLicensedItemAssignments } from "../../../model/entitlement/UserLicensedItemAssignments";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import _ from "lodash";
import { resolveLicenseValidity } from "../../badges/license-validity-badge";
import { LicenseValidityType } from "../../badges/license-validity-badge/license-validity-badge-view";

const ViewWithCloseAfterExited =
  withCloseAfterExited<_DownloadUsersLicenseModalProps>(View);

export type DownloadUsersLicenseModalProps = Omit<
  _DownloadUsersLicenseModalProps,
  keyof DownloadUsersLicenseModalStateProps
>;
export default function DownloadUsersLicenseModal(
  props: DownloadUsersLicenseModalProps
) {
  const { show, licenses, userId, ...other } = props;
  const { conf } = useContext(UIConfiguration);
  const showVersion =
    conf.functionality &&
    conf.functionality.enableDownloadLicenseVersion !== null &&
    conf.functionality.enableDownloadLicenseVersion !== undefined
      ? conf.functionality.enableDownloadLicenseVersion
      : false;
  const [formData, onSetFormData] = useState(
    showVersion ? { hardwareId: "", version: "" } : { hardwareId: "" }
  );

  const [failed, onSetFailed] = useState<any[] | undefined>(undefined);
  const [activeSearch, onSetActiveSearch] = useState("");
  const [resultBlob, onSetResultBlob] = useState<Blob | undefined>(undefined);
  const [selected, onSetSelected] = useState<
    Array<UserLicensedItemAssignments>
  >([]);
  const [showAll, setShowAll] = useState(false);

  const licensesRef = useRef(licenses);
  if (!_.isEqual(licensesRef.current, licenses)) {
    licensesRef.current = licenses;
  }
  const licensesRefCurrent = licensesRef.current;
  const [slicenses, setLicenses] = useState<
    UserLicensedItemAssignments[] | undefined
  >(_.cloneDeep(licenses));
  useEffect(() => {
    if (show && userId) {
      if (showAll) {
        setLicenses(
          licensesRefCurrent ? _.cloneDeep(licensesRefCurrent) : undefined
        );
      } else if (licensesRefCurrent) {
        setLicenses(
          _.cloneDeep(licensesRefCurrent).filter((itm) => {
            if (!showAll && !!itm) {
              const validity =
                itm.assignments &&
                itm.assignments.length &&
                itm.assignments[0] &&
                itm.assignments[0].license
                  ? resolveLicenseValidity(itm.assignments[0].license)
                  : undefined;
              return (
                validity === LicenseValidityType.valid ||
                validity === LicenseValidityType.expiring
              );
            } else {
              return true;
            }
          })
        );
      } else {
        setLicenses(undefined);
      }
    } else {
      setLicenses(undefined);
    }
  }, [show, userId, showAll, licensesRefCurrent]);

  useEffect(() => {
    if (!show) {
      onSetActiveSearch("");
      onSetFailed(undefined);
      if (showVersion) {
        onSetFormData({ hardwareId: "", version: "" });
      } else {
        onSetFormData({ hardwareId: "" });
      }
      onSetResultBlob(undefined);
      onSetSelected([]);
    }
  }, [
    show,
    showVersion,
    onSetActiveSearch,
    onSetFailed,
    onSetFormData,
    onSetResultBlob,
    onSetSelected,
  ]);

  const onToggleShowAll = () => {
    setShowAll(!showAll);
  };
  return (
    <ViewWithCloseAfterExited
      {...other}
      userId={userId}
      licenses={slicenses}
      showAll={showAll}
      onToggleShowAll={onToggleShowAll}
      showVersion={showVersion}
      formData={formData}
      onSetFormData={onSetFormData}
      show={show}
      activeSearch={activeSearch}
      onSetActiveSearch={onSetActiveSearch}
      selected={selected}
      onSetSelected={onSetSelected}
      resultBlob={resultBlob}
      onSetResultBlob={onSetResultBlob}
      failed={failed}
      onSetFailed={onSetFailed}
    />
  );
}
