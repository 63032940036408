import { useContext, useEffect, HTMLAttributes } from "react";
import { useIntl } from "react-intl";
import { SummaryCard, IconLibrary, FieldList, TooltipWrapper } from "@10duke/dukeui";
import { OrganizationRole } from "../../../model/OrganizationRole";
import { Link } from "react-router-dom";
import { ModalKeys } from "../../pages/roles";
import { ButtonGroup } from "react-bootstrap";
import { sortToLatestByCreatedOrUpdated } from "../../../util/objectUtil";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import RoleTypeBadge from "../../badges/role-type-badge";
import {hasAction} from "../../../ui-configuration/configuration-tools";

interface RolesCardDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface RolesCardProps extends RolesCardDOMProps {
  isReady: boolean;
  roles?: OrganizationRole[] | null;
  onLoadRoles?: () => {};

  moreAction: () => void;
}

function RolesCard(props: RolesCardProps) {
  const { roles, onLoadRoles, moreAction, isReady } = props;
  const intl = useIntl();
  const { conf } = useContext(UIConfiguration);
  const rolesConf =
    conf.functionality && conf.functionality.roles
      ? conf.functionality.roles
      : {};
  const headerButton = {
    label: intl.formatMessage({
      defaultMessage: "View",
      description: "View button label",
    }),
    action: moreAction,
  };
  if (!onLoadRoles && roles === undefined) {
    throw new Error(
      "RolesCard: Required props missing. Either roles or onLoadRoles must be defined"
    );
  }
  const isRolesUndefined = roles === undefined;
  useEffect(() => {
    if (isRolesUndefined && onLoadRoles) {
      onLoadRoles();
    }
  }, [isRolesUndefined, onLoadRoles]);
  return (
    <SummaryCard
      onReloadData={() => {
        if (onLoadRoles) {
          onLoadRoles();
        }
      }}
      className={"roles-card"}
      isReady={isReady}
      data-test-roles-card
      title={intl.formatMessage({
        defaultMessage: "Roles",
        description: "heading for the card",
      })}
      headerButton={headerButton}
      items={
        roles && roles.length
          ? roles.sort(sortToLatestByCreatedOrUpdated).map((r) => {
              const Wrapper = hasAction(rolesConf.rowActions, 'show') ? Link : "span";
              const wrapperProps: any = {
                className: "item-copy",
              };
              if (hasAction(rolesConf.rowActions, 'show')) {
                wrapperProps.to =
                  (("/roles/" + r.id) as string) + "/" + ModalKeys.show;
              }
              return (
                <>
                  <Wrapper {...wrapperProps}>
                    <TooltipWrapper
                      tipKey={"role_" + r.id}
                      tip={r.name}
                      placement={"top"}
                    >
                      <span className={"label"}>{r.name}</span>
                    </TooltipWrapper>
                    <RoleTypeBadge type={(r as any).type} />
                  </Wrapper>
                  {hasAction(rolesConf.rowActions, 'members') && (
                    <ButtonGroup size={"sm"}>
                      <Link
                        className={"btn btn-secondary custom-base"}
                        to={
                          (("/roles/" + r.id) as string) +
                          "/" +
                          ModalKeys.members
                        }
                      >
                        {intl.formatMessage({
                          defaultMessage: "Members",
                          description: "label for the members button",
                        })}
                      </Link>
                    </ButtonGroup>
                  )}
                </>
              );
            })
          : undefined
      }
      footer={
        <FieldList
          inline={true}
          fields={{
            total: {
              label: intl.formatMessage(SummaryCard.TotalLabel),
              value: roles ? roles.length : 0,
              itemClass: "total",
            },
          }}
        />
      }
    >
      {roles && roles.length === 0 && (
        <div data-test-no-items className={"no-data"}>
          <div className={"icon"}>{IconLibrary.customIcons.noData}</div>
          <p>
            {intl.formatMessage({
              defaultMessage: "No roles to show.",
              description: "display value for no roles",
            })}
          </p>
        </div>
      )}
    </SummaryCard>
  );
}
export default RolesCard;
