import { useEffect, useRef, useState } from "react";
import View, {
  CreateDeviceClientInvitationModalProps as _CreateDeviceClientInvitationModalProps,
  CreateDeviceClientInvitationModalStateProps,
  InvitationRecipient,
} from "./create-device-client-invitation-modal-view";
import _ from "lodash";
import { withCloseAfterExited } from "@10duke/dukeui";
import {ClientGroup} from "../../../model/ClientGroup";


export type CreateDeviceClientInvitationModalProps = Omit<
  _CreateDeviceClientInvitationModalProps,
  keyof CreateDeviceClientInvitationModalStateProps
>;

const ViewWithCloseAfterExited =
  withCloseAfterExited<_CreateDeviceClientInvitationModalProps>(View);

export default function CreateDeviceClientInvitationModal(
  props: CreateDeviceClientInvitationModalProps
) {
  const { show, selected, clientGroups, ...other } = props;

  const groupsRef = useRef(clientGroups);
  if (!_.isEqual(groupsRef.current, clientGroups)) {
    groupsRef.current = clientGroups;
  }
  const groupsRefCurrent = groupsRef.current;
  const selectedRef = useRef(selected);
  if (!_.isEqual(selectedRef.current, selected)) {
    selectedRef.current = selected;
  }
  const selectedRefCurrent = selectedRef.current;

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showRecipients, setShowRecipients] = useState<boolean>(false);

  const [editIndex, setEditIndex] = useState<number>(-1);
  const [recipients, setRecipients] = useState<InvitationRecipient[]>([]);
  const addRecipient = (r: InvitationRecipient | InvitationRecipient[]) => {
    let timer: any;
    setRecipients((cur) => {
      setDisableListButton(cur.length);
      timer = setTimeout(() => {
        setDisableListButton(-1);
      }, 500);
      return cur.concat(r);
    });
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  };
  const updateRecipient = (ind: number, r: InvitationRecipient) => {
    setRecipients((cur) => {
      const t: InvitationRecipient[] = ([] as InvitationRecipient[]).concat(
        cur
      );
      t.splice(ind, 1, r);
      return t;
    });
    setEditIndex(-1);
  };

  const removeRecipient = (ind: number) => {
    setRecipients((cur) => {
      const t: InvitationRecipient[] = ([] as InvitationRecipient[]).concat(
        cur
      );
      t.splice(ind, 1);
      return t;
    });
  };
  const [disableListButton, setDisableListButton] = useState<number>(-1);
  useEffect(() => {
    let timer: any;
    if (editIndex !== -1) {
      setDisableListButton(editIndex);
    }
    if (editIndex === -1) {
      setDisableListButton((cur) => {
        if (cur !== -1) {
          timer = setTimeout(() => {
            setDisableListButton(-1);
          }, 500);
          return cur;
        }
        return -1;
      });
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [editIndex]);
  const [activeSearch, onSetActiveSearch] = useState("");
  const [selectionTouched, onSetSelectionTouched] = useState(false);

  const [sel, onSetSelected] = useState<ClientGroup[]>(selected || []);
  const [dirtySelection, onSetDirtySelection] = useState<boolean>(false);
  const [selectionFixed, onSetSelectionFixed] = useState<boolean>(!!selected);
  useEffect(() => {
    if (!show) {
      setSubmitting(false);
      setEditIndex(-1);
      setRecipients([]);

      onSetActiveSearch("");
      onSetSelectionTouched(false);
      onSetSelected([]);
      onSetSelectionFixed(false);
      onSetDirtySelection(false);
    } else {
      onSetSelected((s) => {
        if (selectedRefCurrent) {
          // filter out potentially removed items
          const t = selectedRefCurrent.filter((v) =>
            groupsRefCurrent
              ? groupsRefCurrent.findIndex((g) => g.id === v.id) >= 0
              : false
          );
          if (t.length <= 0) {
            onSetDirtySelection(false);
            onSetSelectionFixed(false);
          } else {
            onSetSelectionFixed(true);
          }
          return t;
        } else {
          onSetSelectionFixed(false);
          onSetSelectionFixed(false);
          return s.filter((v) =>
            groupsRefCurrent
              ? groupsRefCurrent.findIndex((g) => g.id === v.id) >= 0
              : false
          );
        }
      });
    }
  }, [show, selectedRefCurrent, groupsRefCurrent]);
  const setSelection = (s: ClientGroup[]) => {
    const initialSel = selected ? selected.map((v) => v.id).sort() : [];
    const newSel = s.map((v) => v.id).sort();
    if (initialSel.length !== newSel.length) {
      onSetDirtySelection(true);
    } else {
      let notEqual = false;
      for (let i = 0; i < newSel.length; i += 1) {
        if (newSel[i] !== initialSel[i]) {
          notEqual = true;
          break;
        }
      }
      if (notEqual) {
        onSetDirtySelection(true);
      } else {
        onSetDirtySelection(false);
      }
    }
    onSetSelectionTouched(true);
    onSetSelected(s);
  };

  return (
    <ViewWithCloseAfterExited
      {...other}
      disableListButton={disableListButton}
      recipients={recipients}
      updateRecipient={updateRecipient}
      addRecipient={addRecipient}
      removeRecipient={removeRecipient}
      editIndex={editIndex}
      setEditIndex={setEditIndex}
      show={show}
      clientGroups={clientGroups}
      onSetActiveSearch={onSetActiveSearch}
      activeSearch={activeSearch}
      onSetSelected={setSelection}
      selected={sel}
      selectionTouched={selectionTouched}
      selectionFixed={selectionFixed}
      onSetSelectionTouched={onSetSelectionTouched}
      submitting={submitting}
      setSubmitting={setSubmitting}
      showRecipients={showRecipients}
      setShowRecipients={setShowRecipients}
      dirtySelection={dirtySelection}
    />
  );
}
