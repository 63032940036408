import { defineMessages } from "react-intl";
import { BaseObjLabels } from "../base-obj";

/**
 * Localized labels for "UI Model" Entitlement fields
 */
export const EntitlementLabels = {
  ...BaseObjLabels,
  // Add AuthorEditorFields if needed
  ...defineMessages({
    // type?: string;
    name: {
      defaultMessage: "name",
      description: "Field label for entitlements name (Entitlement.name)"
    },
    // description?: string;
    // isDefault?: boolean;
    groups: {
      defaultMessage: "consuming user groups",
      description:
          "Field label for license consuming groups (Entitlement.groups)"
    },
    clientGroups: {
      defaultMessage: "consuming device client groups",
      description:
          "Field label for license consuming device client groups (Entitlement.clientGroups)"
    },
    licenses: {
      defaultMessage: "assigned licenses",
      description: "Field label for assigned licenses (Entitlement.licenses)"
    }
  })
};

/**
 * Localized values for LicenseModel fields
 */
export const EntitlementValues = {
  // no extension needed for now
  groups: defineMessages({
    undefined: {
      defaultMessage: "No consuming user groups",
      description:
          "value for when there are no license consuming groups (Entitlement.groups undefined or empty)"
    }
  }),
  clientGroups: defineMessages({
    undefined: {
      defaultMessage: "No consuming device client groups",
      description:
          "value for when there are no license consuming device client groups (Entitlement.clientGroups undefined or empty)"
    }
  }),
  licenses: defineMessages({
    undefined: {
      defaultMessage: "No assigned licenses",
      description:
        "value for when there are no assigned licenses (Entitlement.licenses undefined or empty)"
    }
  })
};
