import { connect } from "react-redux";
import OrganizationUtils from "../../../utils/organization";
import {
  ViewDeviceClientModalDataProps,
  ViewDeviceClientModalProps as _ViewDeviceClientModalProps,
} from "./view-device-client-modal-view";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import {clearError, getClient, replaceOrganizationClient} from "../../../actions";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";
import ViewDeviceClientModal from "./view-device-client-modal-container";
import InProgressUtils from "../../../utils/in-progress";

export interface ViewDeviceClientModalProps
  extends Omit<_ViewDeviceClientModalProps, keyof ViewDeviceClientModalDataProps> {
}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ViewDeviceClientModalProps,
  ViewDeviceClientModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ViewDeviceClientModalProps,
  ViewDeviceClientModalProps
>;

const sender: ActionSender = { key: "view-device-client" };

function mapStateToProps(
  state: AppState,
  ownProps: ViewDeviceClientModalProps
): ReduxStateProps {
  const client = ownProps.clientId
    ? OrganizationUtils.selectOrganizationClient(ownProps.clientId, state)
    : undefined;
  return {
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    client: client,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientsStates(next, prev)
  );
}
const dispatchActions: ReduxDispatchProps = {
  onLoadClient: addSenderArgument(sender, getClient),
  onUpdateClient: addSenderArgument(sender, replaceOrganizationClient),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ViewDeviceClientModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ViewDeviceClientModal);
