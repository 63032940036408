import { getAppBase, getSrvBase } from "../util/env";
import { InitialUIConfiguration } from "../ui-configuration/configuration-provider";
import {
  OrgadminConfiguration,
  OrgadminLocalizationLocale,
} from "../ui-configuration/orgadmin-configuration";
import appStorageFactory, { IAppStorage } from "application-storage/dist";

/**
 * Utility function for finding a locale configuration matching given locale code
 */
function createLocaleMatcher(
  localeCode: string
): (l: OrgadminLocalizationLocale) => boolean {
  return (l: OrgadminLocalizationLocale) => {
    return l.value === localeCode || !!l.alias?.find((l) => l === localeCode);
  };
}

/**
 * Used to extract language and region parts from 10Duke flavor ICU locale string: `-` as separator.
 */
export const MATCH_LOCALE_PATTERN = /^([a-zA-Z]{2,3}(?=-|$))(?:-[a-zA-Z]{4}(?=-|$))?(-[a-zA-Z0-9]{2,3}(?=-|$))?(?:-[a-zA-Z-]*)?$/;
/**
 * Resolves requested locale from window location
 */
function resolveLocaleFromPath(): Intl.Locale | null {
  const urlParts: string[] = window.location.href.split(getAppBase());
  const pathParts: string[] = urlParts.length > 0 ? urlParts[0].split("/") : [];
  const requestedPathLocale: string[] | null =
    pathParts.length > 0
      ? pathParts[pathParts.length - 1].match(MATCH_LOCALE_PATTERN)
      : null
  ;
  if (!!requestedPathLocale) {
    const loc = new Intl.Locale(requestedPathLocale[0]);
    if (loc.toString().toLowerCase() !== requestedPathLocale[0]) {
      window.location.replace(window.location.href.replace(requestedPathLocale[0], loc.toString().toLowerCase()));
    }
    return loc;
  }
  return null;
}

/**
 * Resolves a new url matching current view with given locale
 */
function resolveNewUrl(lang: string, defLocale: string) {
  const app_base = getAppBase();
  const srv_base = getSrvBase();
  const cur = resolveLocaleFromPath();
  const nxt_prt = srv_base + (lang !== defLocale ? "/" + lang : "") + app_base;
  let nxt;
  nxt = window.location.href.replace(
    srv_base + (cur ? '/' + cur.toString().toLowerCase() + app_base : app_base),
    nxt_prt
  );
  return nxt;
}

export enum ResolvedFrom {
  path = "path",
  store = "store",
  detected = "detected",
  default = "default",
}

export interface ResolvedLocaleData {
  locale: OrgadminLocalizationLocale | undefined;
  resolvedFrom: ResolvedFrom | undefined;
  path: string | undefined;
  stored: string | undefined;
  navigator: string[] | undefined;
  defaultLocale: string | undefined;
}

class LocaleManagerClass {
  private listeners: Array<(ld: ResolvedLocaleData) => void>;
  private conf: OrgadminConfiguration | undefined;
  /**
   * This should never be undefined, but seems that initializing in constructor requires undefined type.
   * @private
   */
  private localeStorage: IAppStorage | undefined;
  private storedLocale: string | undefined;
  private autoDetect: boolean | undefined;
  private defaultLocale: string | undefined;
  private resolved: ResolvedLocaleData | undefined;

  public constructor() {
    this.listeners = [];
    this.localeStorage = appStorageFactory("locale", "local");
    this.storedLocale = this.localeStorage.getValue();
    this.conf = InitialUIConfiguration;
    this.autoDetect = this.conf?.functionality?.localization?.autoDetect;
    this.defaultLocale = this.conf?.functionality?.localization?.default;

    this.resolveUsedLocale();

    this.addChangeLanguageListener = this.addChangeLanguageListener.bind(this);
    this.removeChangeLanguageListener =
      this.removeChangeLanguageListener.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.browserLanguagechangeHandler =
      this.browserLanguagechangeHandler.bind(this);
    this.localizeUrl = this.localizeUrl.bind(this);
    this.resolveLocaleLabel = this.resolveLocaleLabel.bind(this);
    if (this.autoDetect) {
      window.addEventListener(
        "languagechange",
        this.browserLanguagechangeHandler
      );
    }
  }

  /**
   * Finds the best match for localeCode from configured locales
   * @param localeCode The locale code
   * @param fullMatch If true, uses full match when finding locale
   * @param ignoreRegion If true, uses language part matching when finding match, ignoring the region part.
   */
  private findFromLocales(
    localeCode: string,
    fullMatch: boolean = true,
    ignoreRegion: boolean = true
  ): OrgadminLocalizationLocale | undefined {
    let retVal = undefined;
    if (this.conf?.functionality?.localization?.locales && fullMatch) {
      // exact match
      retVal = this.conf?.functionality?.localization?.locales.find(
        createLocaleMatcher(localeCode)
      );
    }
    if (!retVal && ignoreRegion) {
      const splitted = localeCode.split(/_|-/);
      if (
        this.conf?.functionality?.localization?.locales &&
        splitted.length > 1
      ) {
        // 1st part match
        retVal = this.conf?.functionality?.localization?.locales.find(
          createLocaleMatcher(splitted[0])
        );
      }
    }
    return retVal;
  }
  /**
   * Triggers locale resolver and fires language change events when browser language settings are changed
   * when autoDetect is enabled
   */
  private browserLanguagechangeHandler(e: any) {
    if (this.autoDetect) {
      this.updateState();
    }
  }
  public updateState() {
    this.resolveUsedLocale();
    this.listeners.forEach((l) => l(this.resolved as ResolvedLocaleData));
  }
  /**
   * Resolves the locale for the user, does not guarantee that the localisation exists.
   */
  private resolveUsedLocale() {
    const urlParams = new URLSearchParams(window.location.search);
    const skipResolver = urlParams.has('code');
    let locale: OrgadminLocalizationLocale | undefined = undefined;
    let resolvedFrom: ResolvedFrom | undefined = undefined;
    let requestedPathLocale: Intl.Locale | null = !skipResolver ? resolveLocaleFromPath() : null;
    const navigatorLocales: string[] | undefined = this.autoDetect && !skipResolver
      ? (window.navigator.languages || [window.navigator.language])?.map((l) =>
          l.toLowerCase()
        )
      : undefined;
    if (requestedPathLocale) {
      // path is strongest and overrides the rest
      locale = this.findFromLocales(requestedPathLocale.toString().toLowerCase());
      if (!locale) {
        // when a path locale is not available, we fall back to default and skip possible stored and detected locales
        locale = this.findFromLocales(this.defaultLocale as string);
        resolvedFrom = ResolvedFrom.default;
      } else {
        resolvedFrom = ResolvedFrom.path;
      }
    }
    if (
      (!locale || requestedPathLocale?.toString().toLowerCase() === this.storedLocale) &&
      !skipResolver && this.storedLocale
    ) {
      // no path locale was found, or path is identical to stored explicitly selected locale
      locale = this.findFromLocales(this.storedLocale);
      if (!locale) {
        // when a stored locale is not available, we fall back to default and skip possible detected locales
        locale = this.findFromLocales(this.defaultLocale as string);
        resolvedFrom = ResolvedFrom.default;
      } else {
        resolvedFrom = ResolvedFrom.store;

        // if no path locale was found, but a stored explicitly selected locale was found, we populate it into the path
        // to make the selection clear.
        const newUrl = resolveNewUrl(
          this.storedLocale,
          this.defaultLocale as string
        );
        if (newUrl !== window.location.href) {
          window.history.replaceState(window.history.state, "", newUrl);
          requestedPathLocale = new Intl.Locale(this.storedLocale);
        }
      }
    }
    if (!locale && navigatorLocales && navigatorLocales.length) {
      // no path or explicitly selected locale was found, but browser defined locales are available,
      // find first available
      locale = navigatorLocales
        .map((l) => this.findFromLocales(l, true, false))
        .find((f) => !!f);
      if (!locale) {
        locale = navigatorLocales
          .map((l) => this.findFromLocales(l, false, true))
          .find((f) => !!f);
      }
      resolvedFrom = ResolvedFrom.detected;
    }
    if (!locale || skipResolver) {
      // finally we use configured default
      locale = this.findFromLocales(this.defaultLocale as string);
      resolvedFrom = ResolvedFrom.default;
    }
    this.resolved = {
      locale,
      resolvedFrom,
      path: requestedPathLocale?.toString().toLowerCase(),
      stored: !skipResolver ? this.storedLocale : undefined,
      navigator: navigatorLocales,
      defaultLocale: this.defaultLocale,
    };
  }
  public getResolved() {
    return this.resolved;
  }

  /**
   * Resolves label for locale
   * @param localeCode
   * @param fallback Value to return if locale not available, defaults to localeCode
   */
  public resolveLocaleLabel(localeCode: string, fallback: any = localeCode) {
    return this.findFromLocales(localeCode)?.label || fallback;
  }
  /**
   * Adds current locale to url strings containing the replace token ${locale}. Ignores defaultLocale byt default
   * @param url string url with "${locale}"
   * @param injectDefaultLocale true if default locale should be injected
   */
  public localizeUrl(
    url: string,
    injectDefaultLocale: boolean = false
  ): string {
    let locale =
      !injectDefaultLocale &&
      this.resolved?.locale?.value === this.resolved?.defaultLocale
        ? ""
        : this.resolved?.locale?.value;
    if (!!locale) {
      locale = "/" + locale;
    } else {
      locale = "";
    }
    /* eslint-disable no-template-curly-in-string */
    /* tslint:disable:no-template-curly-in-string */
    const retVal = url.replace("${locale}", locale);
    /* tslint:enable:no-template-curly-in-string */
    /* eslint-enable no-template-curly-in-string */
    return retVal;
  }
  public changeLanguage(lang: string) {
    const t = this.resolved;
    const newLang =
      t?.locale?.value === lang
        ? // if the lang is the same as current, we only set it if there is a need to update storage or path
          (!t?.path && (lang !== t?.defaultLocale || lang !== t?.stored)) ||
          (t?.path && t?.path !== t?.stored)
          ? lang
          : undefined
        : lang;
    if (this.localeStorage && t?.stored !== newLang) {
      if (newLang) {
        this.localeStorage.setValue(newLang);
      } else {
        this.localeStorage.removeValue();
      }
      this.storedLocale = newLang;
    }
    const newUrl = resolveNewUrl(
      newLang || t?.defaultLocale || "",
      t?.defaultLocale as string
    );
    if (newUrl !== window.location.href) {
      window.history.replaceState(window.history.state, "", newUrl);
    }
    this.resolveUsedLocale();
    this.listeners.forEach((l) => l(this.resolved as ResolvedLocaleData));
  }

  public addChangeLanguageListener(listener: (ld: ResolvedLocaleData) => void) {
    if (!this.listeners.find((l) => l === listener)) {
      this.listeners.push(listener);
    }
  }

  public removeChangeLanguageListener(
    listener: (ld: ResolvedLocaleData) => void
  ) {
    const ind = this.listeners.findIndex((l) => l === listener);
    if (ind >= 0) {
      this.listeners.splice(ind, 1);
    }
  }
}

export const LocaleManager = new LocaleManagerClass();
