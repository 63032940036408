import {
  useContext,
  useEffect,
  useMemo,
  HTMLAttributes,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage, useIntl } from "react-intl";
import {IconLibrary, TooltipWrapper, FormInput, FormInputUtils, FeedbackEntry, Modal, Button} from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import {
  ClearErrorAction, CreateAndSendOrgClientGroupInvitationAction,
  isAddErrorAction,
} from "../../../actions/actionTypes";
import {ButtonGroup, Form} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { getEnvParam } from "../../../util/env";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
import Table from "../../table/table-container";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import { ModalKeys } from "../../pages/groups";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import "./create-device-client-invitation-modal-view.scss";
import RecipientListItem from "./recipient-list-item";
import NavigateAfterAction from "../../navigate-after-action";
import UserUtils from "../../../utils/user";
import { asDukeLocale } from "../../localize/localize-view";
import {ClientGroup} from "../../../model/ClientGroup";
import {
  ClientGroupInvitationLabels
} from "../../../localization/client-group-invitation/client-group-invitation";
import {ClientGroupInvitation} from "../../../model/ClientGroupInvitation";
import {hasAction} from "../../../ui-configuration/configuration-tools";

//<editor-fold desc="Utils">

const CREATE_INVITATION_CSV_IMPORT_LIMIT = 250;
export interface InvitationRecipient {
  email: string;
  recipientName: string;
  clientName: string;
  clientId: string;
}
/**
 * Environment parameter for invitation welcome url.
 */
const APP_INVITATION_WELCOME_URL = "REACT_APP_DEVICE_INVITATION_WELCOME_URL";

//</editor-fold>

//<editor-fold desc="Props">

export interface CreateDeviceClientInvitationModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}

export interface CreateDeviceClientInvitationModalStateProps {
  onSetSelected: (selection: ClientGroup[]) => void;
  onSetSelectionTouched: (b: boolean) => void;
  selectionTouched: boolean;
  dirtySelection: boolean;
  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;
  selectionFixed: boolean;
  editIndex: number;
  setEditIndex: (n: number) => void;
  recipients: InvitationRecipient[];
  addRecipient: (add: InvitationRecipient | InvitationRecipient[]) => void;
  removeRecipient: (index: number) => void;
  updateRecipient: (index: number, itm: InvitationRecipient) => void;
  disableListButton: number;
  submitting: boolean;
  setSubmitting: (b: boolean) => void;
  showRecipients: boolean;
  setShowRecipients: (b: boolean) => void;
}
export interface CreateDeviceClientInvitationModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {
  onShowFeedback: (feedback: FeedbackEntry) => void;
}
export interface CreateDeviceClientInvitationModalInputProps
  extends CreateDeviceClientInvitationModalVisibilityProps {
  selected?: OrganizationGroup[];
}
export interface CreateDeviceClientInvitationModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  organizationId: string | null;
  organizationName: string;
  onCreateClientGroupInvitation: (
    invitation: ClientGroupInvitation
  ) => Promise<CreateAndSendOrgClientGroupInvitationAction>;
  userName?: string;
  clientGroups: ClientGroup[] | undefined;
  onLoadClientGroups?: () => void;
  onClearError: (errorId: string) => ClearErrorAction;
}

export interface CreateDeviceClientInvitationModalProps
  extends CreateDeviceClientInvitationModalDOMProps,
    CreateDeviceClientInvitationModalStateProps,
    CreateDeviceClientInvitationModalDataProps,
    CreateDeviceClientInvitationModalInputProps {}
//</editor-fold>
function resolveDisablePrimary(
  hasSelection: boolean,
  formIsValid: boolean,
  formIsDirty: boolean,
  errors: any | undefined | null,
  formIsValid_add: boolean,
  formIsDirty_add: boolean,
  errors_add: any | undefined | null,
  recipients: InvitationRecipient[],
  editOpen: boolean
) {
  return editOpen ||
    !hasSelection ||
    !formIsValid ||
    (!formIsDirty_add && recipients.length === 0) ||
    (!formIsValid_add && formIsDirty_add) ||
    (errors !== undefined && errors !== null && Object.keys(errors).length > 0)
    ? true
    : (recipients.length > 0 ||
        (formIsValid_add &&
          formIsDirty_add &&
          (!errors_add || Object.keys(errors_add).length <= 0))) &&
      recipients.length <= CREATE_INVITATION_CSV_IMPORT_LIMIT
    ? false
    : true;
}
function CreateDeviceClientInvitationModal(props: CreateDeviceClientInvitationModalProps) {
  //<editor-fold desc="Local variables">
  let {
    selectionFixed,
    onCreateClientGroupInvitation,
    show,
    onClose,
    onShowFeedback,
    organizationId,
    userName,
    organizationName,
    clientGroups,
    selected,
    onSetSelected,
    activeSearch,
    onSetActiveSearch,
    selectionTouched,
    onLoadClientGroups,
    onSetSelectionTouched,
    isReady,
    setEditIndex,
    editIndex,
    recipients,
    addRecipient,
    removeRecipient,
    updateRecipient,
    disableListButton,
    submitting,
    setSubmitting,
    showRecipients,
    setShowRecipients,
    onExited,
    dirtySelection,
    onClearError,
  } = props;
  // this is more like a variable than a hook
  const intl = useIntl();

  const { conf } = useContext(UIConfiguration);
  const groupConf =
      conf.functionality && conf.functionality["device-client-groups"]
          ? conf.functionality["device-client-groups"]
          : {};
  //</editor-fold>

  //<editor-fold desc="Hooks">
  const defaultValues_add = useMemo<InvitationRecipient>(
    () => ({
      email: "",
      recipientName: "",
      clientName: "",
      clientId: "",
    }),
    []
  );
  const defaultValues_edit = useMemo<InvitationRecipient>(() => {
    let retVal;
    if (editIndex >= 0 && editIndex < recipients.length) {
      retVal = { ...recipients[editIndex] };
    } else {
      retVal = {
        email: "",
        recipientName: "",
        clientName: "",
        clientId: "",
      };
    }
    return retVal;
  }, [
    editIndex,
    recipients,
  ]);
  const defaultValues = useMemo<{
    invitationScopeInformation: string;
  }>(
    () => ({
      invitationScopeInformation: intl.formatMessage(
        {
          defaultMessage:
            "{admin} has invited you to connect a device client with {organization}.",
          description:
            "Default device client group invitation message value. 'admin' = name of the inviter, 'organization' = name of the targeted organization.",
        },
        {
          admin: userName,
          organization: organizationName,
        }
      ),
    }),
    [intl, userName, organizationName]
  );
  const {
    register,
    // setValue,
    handleSubmit,
    // control,
    watch,
    formState,
    reset,
    trigger,
  } = useForm({
    mode: "onTouched",
    defaultValues: defaultValues,
  });

  const { errors } = formState;

  const {
    register: register_add,
    // setValue: setValue_add,
    // control: control_add,
    watch: watch_add,
    formState: formState_add,
    reset: reset_add,
    trigger: trigger_add,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues_add,
  });

  const {
    // setValue: setValue_add,
    // handleSubmit: handleSubmit_add,
    errors: errors_add,
  } = formState_add;

  const {
    register: register_edit,
    // setValue: setValue_add,
    handleSubmit: handleSubmit_edit,
    // control: control_add,
    watch: watch_edit,
    formState: formState_edit,
    reset: reset_edit,
    trigger: trigger_edit,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues_edit,
  });

  const { errors: errors_edit } = formState_edit;

  const formValues = watch();
  const formValues_add = watch_add();
  const formValues_edit = watch_edit();
  const hasSelection = selected ? selected.length > 0 : false;
  const formIsValid = formState.isValid;
  const formIsDirty = formState.isDirty;
  const formIsValid_add = formState_add.isValid;
  const formIsDirty_add = formState_add.isDirty;
  const formIsValid_edit = formState_edit.isValid;

  const resolveEditFormValidity = FormInputUtils.validityResolver({
    ...formState_edit,
    ...{
      touchedFields: {
        email: true,
        recipientName: true,
      },
    },
  });

  useEffect(() => {
    reset(defaultValues);
  }, [show, reset, defaultValues]);

  useEffect(() => {
    reset_add(defaultValues_add);
  }, [show, reset_add, defaultValues_add]);
  useEffect(() => {
    reset_edit(defaultValues_edit);
    const timer = setTimeout(() => {
      trigger_edit();
    }, 50);
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [show, reset_edit, trigger_edit, defaultValues_edit]);

  useEffect(() => {
    if (show && clientGroups === undefined && onLoadClientGroups) {
      onLoadClientGroups();
    }
  }, [show, clientGroups, onLoadClientGroups]);

  //</editor-fold>

  const onSubmitInvitation = (d: any) => {
    return Promise.allSettled(
      d.recipients.map((r: any) => {
        let data: any = {
          email: r.email,
          recipientName: r.recipientName,
          clientName: r.clientName,
          allowClientId: r.clientId !== "" ? r.clientId : undefined,
          groupIds: d.groupIds,
          invitationScopeInformation: d.invitationScopeInformation,
        };
        data.organizationId = organizationId as string;

        const loc = asDukeLocale(intl.locale);
        /* eslint-disable no-template-curly-in-string */
        /* tslint:disable:no-template-curly-in-string */
        // Create the url with the constructor to ensure that current domain is used as base, in case the url is relative
        data.memberWelcomeUrl = new URL(
          getEnvParam(APP_INVITATION_WELCOME_URL).replace(
            "${locale}",
            !!loc && intl.locale !== intl.defaultLocale ? "/" + loc : ""
          ),
          window.location.href
        ).toString();
        /* tslint:enable:no-template-curly-in-string */
        /* eslint-enable no-template-curly-in-string */
        data.inviterName = userName;
        data.validFrom = "now()";
        if (!!loc) {
          const l = loc.split("-");
          if (l.length === 2) {
            data.recipientCountryCode = l[1].toUpperCase();
          }
          data.recipientLanguageCode = l[0];
        }
        return onCreateClientGroupInvitation(data as ClientGroupInvitation);
      })
    );
  };
  /**
   * This flag must be resolved here, trying the same code in jsx causes react to mess things up with useless and
   * unrelated error messages. This may or may not be related to how we use the useForm and reacts redraw logic.
   */
  const collapsedRecipientsHasErrors =
    !showRecipients &&
    recipients.length > 3 &&
    (!formIsValid_edit ||
      !formIsValid_add);

  return (
    <Modal
      onExited={onExited}
      className={"create-device-client-invitation-modal"}
      isReady={submitting ? false : isReady}
      onReloadData={() => {
        if (onLoadClientGroups) {
          onLoadClientGroups();
        }
      }}
      data-test-create-device-client-invitation-modal
      title={
        selectionFixed
          ? intl.formatMessage(
              {
                defaultMessage: "Invite device clients to {groups}",
                description:
                  "modal heading when a preselected target device client group exists. 'groups' = list of targeted device client group names",
              },
              {
                groups: selected ? selected.map((s) => s.name).join(", ") : "",
              }
            )
          : intl.formatMessage({
              defaultMessage: "Invite device clients",
              description:
                "modal heading when there is no preselected device client group target",
            })
      }
      show={show}
      onClose={onClose}
      backdrop={
        formIsDirty ||
        dirtySelection ||
        formIsDirty_add ||
        recipients.length
          ? "static"
          : true
      }
      primaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Invite",
          description: "primary button label",
        }),
        disabled: resolveDisablePrimary(
          hasSelection,
          formIsValid,
          formIsDirty,
          errors,
          formIsValid_add,
          formIsDirty_add,
          errors_add,
          recipients,
          editIndex !== -1
        ),
        tooltip:
          (errors !== undefined &&
            errors !== null &&
            Object.keys(errors).length > 0) ||
          (errors_add !== undefined &&
            errors_add !== null &&
            Object.keys(errors_add).length > 0) ||
          (errors_edit !== undefined &&
            errors_edit !== null &&
            Object.keys(errors_edit).length > 0) ||
          (selectionTouched && !hasSelection)
            ? intl.formatMessage({
                defaultMessage:
                  "Please correct errors before sending the invitation.",
                description:
                  "tooltip for the disabled primary button when there are validation errors",
              })
            : (!selectionTouched && !hasSelection) || // no group selection, no error displayed
              ((!recipients || recipients.length === 0) && !formIsValid_add) // no recipients
            ? intl.formatMessage({
                defaultMessage: "Please fill in the fields first.",
                description:
                  "tooltip for the disabled primary button when there is nothing to create/send",
              })
            : editIndex !== -1
            ? intl.formatMessage({
                defaultMessage:
                  "Please apply or cancel the active edits in the recipient list.",
                description:
                  "tooltip for the disabled primary button when there are unapplied edits in the inputs",
              })
            : recipients.length > CREATE_INVITATION_CSV_IMPORT_LIMIT
            ? intl.formatMessage(
                {
                  defaultMessage:
                    "Only {max} recipients can be invited at a time, please remove recipients to send invite.",
                  description:
                    "tooltip for the disabled primary button when there are too many recipients. 'max' = maximum number allowed",
                },
                { max: CREATE_INVITATION_CSV_IMPORT_LIMIT }
              )
            : undefined,
      }}
      onPrimaryAction={() => {
        setSubmitting(true);
        setTimeout(() => {
          handleSubmit(
            (data: any) => {
              const d = {
                ...data,
                groupIds: selected ? selected.map((s) => s.id as string) : [],
                recipients: recipients,
              };
              if (
                formValues_add &&
                  formValues_add.email &&
                  formValues_add.recipientName &&
                  formValues_add.clientName &&
                formIsValid_add
              ) {
                if (d.recipients) {
                  d.recipients.push({
                    email: formValues_add.email,
                    recipientName: formValues_add.recipientName,
                    clientName: formValues_add.clientName,
                    clientId: formValues_add.clientId,
                  });
                } else {
                  d.recipients = [
                    {
                      email: formValues_add.email,
                      recipientName: formValues_add.recipientName,
                      clientName: formValues_add.clientName,
                      clientId: formValues_add.clientId,
                    },
                  ];
                }
              }
              onSubmitInvitation(d).then((res) => {
                const tmp = (res as any as any[]).map((r, ind) => ({
                  ...r.value,
                  index: ind,
                }));
                const suc = tmp.filter(
                  (v) => !isAddErrorAction(v)
                );
                const fail = tmp.filter(
                  (v) => isAddErrorAction(v)
                );
                if (suc && suc.length) {
                  let emails: string;
                  if (suc.length <= 3) {
                    emails = suc
                      .map((r: any) =>
                        r &&
                        r.invitation &&
                        r.invitation.email
                          ? r.invitation.email
                          : false
                      )
                      .filter((r) => !!r)
                      .join(", ");
                  } else {
                    emails = intl.formatMessage(
                      {
                        defaultMessage: "{count} recipients",
                        description:
                          "injected into other copies in place of email address list when there are too many to show. count is the number of addresses",
                      },
                      { count: suc.length }
                    );
                  }
                  onShowFeedback({
                    id: "invitation_success",
                    msg: intl.formatMessage(
                      {
                        defaultMessage: "Invitation sent to {email}.",
                        description:
                          "success notification for sent invitation. 'email' = list of recipients email addresses or fallback if too many",
                      },
                      {
                        email: "<strong>" + emails + "</strong>",
                      }
                    ),
                    autoClose: true,
                    type: "success",
                  });
                }
                if (!!fail && fail.length) {
                  fail.forEach((f) => {
                    onClearError(f.error?.errorId);
                  });
                  const resolveEmailsLabel = (list: any[]) => {
                    let retVal: string;
                    if (list.length <= 3) {
                      retVal = list
                        .map((r: any) =>
                          r &&
                          r.index >= 0 &&
                          d &&
                          d.recipients &&
                          d.recipients[r.index] &&
                          d.recipients[r.index].email
                            ? d.recipients[r.index].email
                            : false
                        )
                        .filter((r) => !!r)
                        .join(", ");
                    } else {
                      retVal = intl.formatMessage(
                        {
                          defaultMessage: "{count} recipients",
                          description:
                            "injected into other copies in place of email address list when there are too many to show. count is the number of addresses",
                        },
                        {
                          count: list.length,
                        }
                      );
                    }
                    return retVal;
                  };
                  if (fail && fail.length) {
                    let emails: string = resolveEmailsLabel(fail);
                    onShowFeedback({
                      id: "device_client_invitation_failed",
                      msg: intl.formatMessage(
                        {
                          defaultMessage: "Inviting device client failed for {email}.",
                          description:
                            "failure notification for unspecified device client invitation error. 'email' = list of recipients email addresses or fallback if too many",
                        },
                        {
                          email: "<strong>" + emails + "</strong>",
                        }
                      ),
                      type: "danger",
                    });
                  }
                }
                setSubmitting(false);
                onClose();
              });
            },
            () => {
              // this should never happen as we validate input before submit
              setSubmitting(false);
            }
          )();
        }, 1);
      }}
      secondaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Cancel",
          description: "secondary button label",
        }),
      }}
      onSecondaryAction={onClose}
    >
      <>
        <div className={"recipients-heading"}>
          <h4 className={collapsedRecipientsHasErrors ? "has-errors" : ""}>
            {recipients && recipients.length > 3 && (
              <a
                href={"#show-all"}
                className={"inherit"}
                onClick={(e) => {
                  e.preventDefault();
                  setShowRecipients(!showRecipients);
                }}
              >
                {intl.formatMessage({
                  defaultMessage: "Device clients",
                  description: "section heading",
                })}{" "}
                {(recipients && recipients.length > 0) ||
                (formIsValid_add && formIsDirty_add) ? (
                  <span>
                    (
                    {recipients
                      ? recipients.length +
                        (formIsValid_add && formIsDirty_add ? 1 : 0)
                      : formIsValid_add && formIsDirty_add
                      ? 1
                      : undefined}
                    )
                  </span>
                ) : undefined}
                {collapsedRecipientsHasErrors ? (
                  <TooltipWrapper
                    tip={intl.formatMessage({
                      defaultMessage: "Unresolved errors",
                      description:
                        "tooltip content for collapsed section with unresolved errors",
                    })}
                    tipKey={"recipientTitle"}
                    placement={"auto"}
                  >
                    <FontAwesomeIcon
                      icon={IconLibrary.icons.faExclamationTriangle}
                      className={"icon"}
                    />
                  </TooltipWrapper>
                ) : !showRecipients &&
                  recipients.length > 3 &&
                  (editIndex >= 0) ? (
                  <TooltipWrapper
                    tip={intl.formatMessage({
                      defaultMessage: "Active edit is open.",
                      description:
                        "tooltip content for collapsed section with unapplied changes",
                    })}
                    tipKey={"recipientTitle"}
                    placement={"auto"}
                  >
                    <FontAwesomeIcon
                      icon={IconLibrary.icons.faPen}
                      className={"icon edit"}
                    />
                  </TooltipWrapper>
                ) : undefined}
                {showRecipients && (
                  <FontAwesomeIcon icon={IconLibrary.icons.faChevronDown} className={"icon"} />
                )}
                {!showRecipients && (
                  <FontAwesomeIcon icon={IconLibrary.icons.faChevronRight} className={"icon"} />
                )}
              </a>
            )}
            {(recipients && recipients.length >= 1 && recipients.length <= 3) && (
              <>
                {intl.formatMessage({
                  defaultMessage: "Device clients",
                  description: "section heading",
                })}{" "}
                {(recipients && recipients.length > 0) ||
                (formIsValid_add && formIsDirty_add) ? (
                  <span>
                    (
                    {recipients
                      ? recipients.length +
                        (formIsValid_add && formIsDirty_add ? 1 : 0)
                      : formIsValid_add && formIsDirty_add
                      ? 1
                      : undefined}
                    )
                  </span>
                ) : undefined}
              </>
            )}
          </h4>
        </div>
        <div
          className={
            !showRecipients && recipients.length > 3 ? "d-none" : undefined
          }
        >
          <Form noValidate className={"recipients"} data-test-recipients>
            <ul className={"list-unstyled"}>
              {recipients
                ? recipients.map((r, ind) => {
                    return (
                      <RecipientListItem
                        key={r.email + ind}
                        item={r}
                        showEdit={editIndex === ind}
                        onShowEdit={(f: boolean) => {
                          if (f) {
                            setEditIndex(ind);
                          } else {
                            setEditIndex(-1);
                          }
                        }}
                        disableEditButtons={disableListButton === ind}
                        onDismissItem={() => {
                          removeRecipient(ind);
                          setTimeout(() => {
                            trigger_add();
                          }, 50);
                        }}
                        resolveEditFormValidity={resolveEditFormValidity}
                        formValues={formValues_edit}
                        register={register_edit}
                        errors={errors_edit}
                        formIsValid={formIsValid_edit}
                        onApplyItem={() => {
                          handleSubmit_edit((data: any) => {
                            updateRecipient(ind, data);
                            setTimeout(() => {
                              trigger_add();
                            }, 50);
                          })();
                        }}
                        editTip={intl.formatMessage({
                          defaultMessage: "Edit recipient",
                          description: "Edit recipient button tooltip",
                        })}
                        dismissTip={intl.formatMessage({
                          defaultMessage: "Remove recipient",
                          description: "Remove recipient button tooltip",
                        })}
                      />
                    );
                  })
                : undefined}
            </ul>
          </Form>
          <Form noValidate className={"new-recipient"}>
            { (editIndex !== -1 && (
                !!formValues_add["email"] ||
                !!formValues_add["recipientName"] ||
                !!formValues_add["clientName"] ||
                !!formValues_add["clientId"])) ?
              <div className={"collapsed-add-item" + (!formIsValid_add && formIsDirty_add ? " invalid" : "")}>
                <div className={"recipient-list-item"}>
                  <FormattedMessage
                    defaultMessage={"{clientName} ({email})"}
                    description={
                      "Short textual representation of the invitations key values shown as a list item with edit buttons etc. supports variables: 'email', 'recipientName', 'clientName', 'clientId'"
                    }
                    values={{
                      email: formValues_add["email"] || intl.formatMessage(
                          ClientGroupInvitationLabels.email
                      ),
                      recipientName: formValues_add["recipientName"] || intl.formatMessage(
                          ClientGroupInvitationLabels.recipientName
                      ),
                      clientName: formValues_add["clientName"] || intl.formatMessage(
                          ClientGroupInvitationLabels.clientName
                      ),
                      clientId: formValues_add["clientId"] || intl.formatMessage(
                          ClientGroupInvitationLabels.allowClientId
                      ),
                    }}
                  />
                  <ButtonGroup size={"sm"}>
                    <Button
                        data-test-edit-add-new-button
                        type={"button"}
                        variant={"primary"}
                        className={"btn custom-base"}
                        action={() => {
                          setEditIndex(-1);
                        }}
                        tooltip={intl.formatMessage({
                          defaultMessage: "Edit recipient",
                          description: "Edit recipient button tooltip",
                        })}
                        icon={<FontAwesomeIcon icon={IconLibrary.icons.faPen} fixedWidth={true} />}
                    />
                    <Button
                        data-test-dismiss-button
                        type={"button"}
                        variant={"primary"}
                        className={"btn custom-base"}
                        action={() => {
                          reset_add(defaultValues_add);
                        }}
                        icon={<FontAwesomeIcon
                            icon={IconLibrary.icons.faTrashAlt}
                            fixedWidth={true}
                        />}
                        tooltip={intl.formatMessage({
                          defaultMessage: "Remove recipient",
                          description: "Remove recipient button tooltip",
                        })}
                    />
                  </ButtonGroup>
                </div>
                <div className={'close-edit-to-activate'}>
                  <FormattedMessage
                      defaultMessage={"Close active edit to add more recipients"}
                      description={"Info text to show when an active edit form is blocking the add more form"}
                  />
                </div>
              </div> : (editIndex !== -1 ?  <div className="collapsed-add-item">
                  <div className={'close-edit-to-activate'}>
                    <FormattedMessage
                        defaultMessage={"Close active edit to add more recipients"}
                        description={"Info text to show when an active edit form is blocking the add more form"}
                    />
                  </div>
                </div> : undefined)
            }
            <div className={"control-wrapper" + (editIndex !== -1 ? " collapse" : " collapse show")}>
              <fieldset>
                <legend>
                  <FormattedMessage
                      defaultMessage="Send to"
                      description="Heading for the email recipient inputs"
                  />
                </legend>
                <FormInput
                    data-test-create-invitation-modal-recipient-name
                    label={intl.formatMessage(
                        ClientGroupInvitationLabels.recipientName
                    )}
                    field={"recipientName"}
                    register={register_add}
                    registerOptions={{
                      onChange: () => {
                        console.log('onChange');
                        setTimeout(() => {
                          trigger_add("email");
                        }, 50);
                      },
                      validate: {
                        empty: (value: any) => {
                          return !(
                              (recipients.length <= 0 ||
                                  !!formValues_add["email"]) &&
                              value === ""
                          );
                        },
                      },
                    }}
                    resolveValidity={FormInputUtils.validityResolver({
                      errors: formState_add.errors,
                      touchedFields: {
                        ...formState_add.touchedFields,
                        ...{
                          email: !!formValues_add["email"],
                          recipientName: !!formValues_add["recipientName"],
                        },
                      },
                    })}
                    hasValue={!!formValues_add["recipientName"]}
                >
                  {errors_add &&
                    errors_add.recipientName &&
                    errors_add.recipientName.type &&
                    errors_add.recipientName.type === "empty" && (
                        <Form.Control.Feedback type="invalid">
                          <FontAwesomeIcon
                              icon={IconLibrary.icons.faExclamationCircle}
                              className={"icon"}
                          />
                          <span className={"copy"}>
                          {intl.formatMessage(
                              {
                                defaultMessage: "{recipientName} is required.",
                                description:
                                    "Field validation error. 'recipientName' = Field label for the name of the recipient (ClientGroupInvitation.recipientName)",
                              },
                              {
                                recipientName: intl.formatMessage(
                                    ClientGroupInvitationLabels.recipientName
                                ),
                              }
                          )}
                        </span>
                        </Form.Control.Feedback>
                    )}
                </FormInput>
                <FormInput
                    data-test-create-device-client-group-invitation-modal-recipient-email
                    label={intl.formatMessage(ClientGroupInvitationLabels.email)}
                    field={"email"}
                    register={register_add}
                    registerOptions={{
                       onChange: () => {
                         setTimeout(() => {
                           trigger_add("recipientName");
                         }, 50);
                       },
                       validate: {
                         empty: (value: any) => {
                           return !(
                               (recipients.length <= 0 ||
                                   !!formValues_add["recipientName"]) &&
                               value === ""
                           );
                         },
                       },
                       pattern: {
                         value: UserUtils.EMAIL_VALIDATION_PATTERN,
                         message: intl.formatMessage(
                             {
                               defaultMessage: "{email} is not valid.",
                               description:
                                   "Field validation error. 'email' = Field label for device client group invitation recipient email (ClientGroupInvitation.email)",
                             },
                             {
                               email: intl.formatMessage(
                                   ClientGroupInvitationLabels.email
                               ),
                             }
                         ),
                       },
                     }}
                    resolveValidity={FormInputUtils.validityResolver({
                      errors: formState_add.errors,
                      touchedFields: {
                        ...formState_add.touchedFields,
                        ...{
                          email: !!formValues_add["email"],
                          recipientName: !!formValues_add["recipientName"],
                        },
                      },
                    })}
                    hasValue={!!formValues_add["email"]}
                >
                  {errors_add &&
                      errors_add.email &&
                      errors_add.email.type &&
                      errors_add.email.type === "empty" && (
                          <Form.Control.Feedback type="invalid">
                            <FontAwesomeIcon
                                icon={IconLibrary.icons.faExclamationCircle}
                                className={"icon"}
                            />
                            <span className={"copy"}>
                          {intl.formatMessage(
                              {
                                defaultMessage: "{email} is required.",
                                description:
                                    "Field validation error. 'email' = Field label for device client group invitation recipient email (ClientGroupInvitation.email)",
                              },
                              {
                                email: intl.formatMessage(
                                    ClientGroupInvitationLabels.email
                                ),
                              }
                          )}
                        </span>
                          </Form.Control.Feedback>
                      )}
                  {errors_add &&
                      errors_add.email &&
                      errors_add.email.type &&
                      errors_add.email.type === "pattern" && (
                          <Form.Control.Feedback type="invalid">
                            <FontAwesomeIcon
                                icon={IconLibrary.icons.faExclamationCircle}
                                className={"icon"}
                            />
                            <span className={"copy"}>{errors_add.email.message}</span>
                          </Form.Control.Feedback>
                      )}
                </FormInput>
              </fieldset>
              <fieldset>
                <legend >
                  <FormattedMessage
                      defaultMessage="Device client details"
                      description="Heading for the device client inputs"
                  />
                </legend>
                <FormInput
                    data-test-create-invitation-modal-client-name
                    label={intl.formatMessage(
                        ClientGroupInvitationLabels.clientName
                    )}
                    field={"clientName"}
                    register={register_add}
                    registerOptions={{
                      onChange: () => {
                        setTimeout(() => {
                          trigger_add("recipientName");
                        }, 50);
                      },
                      validate: {
                        empty: (value: any) => {
                          return !(
                              (recipients.length <= 0 ||
                                  !!formValues_add["clientId"] ||
                                  !!formValues_add["recipientName"] ||
                                  !!formValues_add["email"]
                              ) &&
                              value === ""
                          );
                        },
                      },
                    }}
                    resolveValidity={FormInputUtils.validityResolver({
                      errors: formState_add.errors,
                      touchedFields: {
                        ...formState_add.touchedFields,
                        ...{
                          clientName: !!formValues_add["clientName"],
                          clientId: !!formValues_add["clientId"],
                          email: !!formValues_add["email"],
                          recipientName: !!formValues_add["recipientName"],
                        },
                      },
                    })}
                    hasValue={!!formValues_add["clientName"]}
                >
                  {errors_add &&
                      errors_add.clientName &&
                      errors_add.clientName.type &&
                      errors_add.clientName.type === "empty" && (
                          <Form.Control.Feedback type="invalid">
                            <FontAwesomeIcon
                                icon={IconLibrary.icons.faExclamationCircle}
                                className={"icon"}
                            />
                            <span className={"copy"}>
                          {intl.formatMessage(
                              {
                                defaultMessage: "{clientName} is required.",
                                description:
                                    "Field validation error. 'clientName' = Field label for the name of the device client (DeviceClientInvitationLabels.clientName)",
                              },
                              {
                                clientName: intl.formatMessage(
                                    ClientGroupInvitationLabels.clientName
                                ),
                              }
                          )}
                        </span>
                          </Form.Control.Feedback>
                      )}
                </FormInput>
                <FormInput
                    data-test-create-device-client-group-invitation-modal-client-id
                    label={intl.formatMessage(ClientGroupInvitationLabels.allowClientId)}
                    field={"clientId"}
                    register={register_add}
                    registerOptions={{
                      onChange: () => {
                        setTimeout(() => {
                          trigger_add("clientId");
                        }, 50);
                      },
                    }}
                    hasValue={!!formValues_add.clientId}
                >
                  <Form.Text className={"info-feedback"}>
                    <FontAwesomeIcon icon={IconLibrary.icons.faInfoCircle} className={"icon"} />
                    <FormattedMessage
                        defaultMessage='Leave empty to allow any device client.'
                        description={
                          "field info for client invitation clientId. Limited width available in ui, try to keep as short as the default."
                        }
                    />
                  </Form.Text>
                </FormInput>
              </fieldset>
            </div>
            <div
                className={"button-wrapper" + (editIndex !== -1 ? " collapse" : " collapse show")}
            >
              <Button
                  data-test-add-recipient
                  type={"button"}
                  variant={"primary"}
                  className={"btn custom-base"}
                  disabled={
                      recipients.length >= CREATE_INVITATION_CSV_IMPORT_LIMIT ||
                      !(formIsValid_add && formIsDirty_add) ||
                      !formValues_add.email ||
                      !formValues_add.recipientName ||
                      !formValues_add.clientName
                  }
                  action={() => {
                    setEditIndex(-1);
                    addRecipient({
                      email: formValues_add.email,
                      recipientName: formValues_add.recipientName,
                      clientName: formValues_add.clientName,
                      clientId: formValues_add.clientId,
                    });
                    setTimeout(() => {
                      trigger();
                    }, 50);
                    reset_add(defaultValues_add);
                  }}
                  icon={<FontAwesomeIcon
                      fixedWidth={true}
                      icon={IconLibrary.icons.faPlus}
                  />}
                  tooltip={
                    recipients.length >= CREATE_INVITATION_CSV_IMPORT_LIMIT
                        ? intl.formatMessage({
                          defaultMessage:
                              "Maximum amount of invitation recipients reached.",
                          description:
                              "tooltip for add recipient button when max count has been reached",
                        })
                        : !(formIsValid_add && formIsDirty_add) ||
                        !formValues_add.email ||
                        !formValues_add.recipientName ||
                        !formValues_add.clientName
                            ? intl.formatMessage({
                              defaultMessage:
                                  "Fill in the inputs with valid values to add a new recipient.",
                              description:
                                  "tooltip for add recipient button when the fields are empty",
                            })
                            : intl.formatMessage({
                              defaultMessage: "Add new recipient",
                              description: "tooltip for add recipient button",
                            })
                  }
              />
            </div>
          </Form>
        </div>
        <Form noValidate>
          {isReady && !selectionFixed && (
            <Form.Group
              className={"form-table"}
            >
              <Table<OrganizationGroup>
                maxRows={5}
                compact={true}
                className={
                  selectionTouched && (!selected || selected.length === 0)
                    ? "invalid"
                    : undefined
                }
                header={
                  <Form.Label>
                    {intl.formatMessage({
                      defaultMessage: "Select device client group(s) to connect",
                      description: "field label",
                    })}
                  </Form.Label>
                }
                data-test-select-groups
                search={(clientGroups || []).length > TABLE_SEARCH_THRESHOLD}
                activeSearch={activeSearch}
                onSearch={onSetActiveSearch}
                columnToggle={false}
                reset={false}
                data={clientGroups || []}
                pagination={false}
                identifyingColumn={"id"}
                selection={{
                  multi: true,
                  selectAll: true,
                }}
                onFocus={() => {
                  onSetSelectionTouched(true);
                }}
                onSelectionChanged={onSetSelected}
                selected={selected}
                columns={[
                  {
                    key: "id",
                    label: intl.formatMessage(
                        ClientGroupInvitationLabels.id
                    ),
                    isTechnical: true,
                    hidden: true,
                  },
                  {
                    key: "name",
                    label: intl.formatMessage({
                      defaultMessage: "Device client group",
                      description: "Column heading for the device client groups to select",
                    }),
                    sortable: true,
                    renderer: (props: {
                      cell: any;
                      row: any;
                      rowIndex: Number;
                      rendererData: any;
                    }) => {
                      return (
                        <>
                          {hasAction(groupConf.rowActions, 'show') ? (
                            <NavigateAfterAction
                              href={
                                "/device-client-groups/" + props.row.id + "/" + ModalKeys.show
                              }
                              action={onClose}
                            >
                              {props.cell}
                            </NavigateAfterAction>
                          ) : props.cell}
                        </>
                      );
                    },
                    tipRenderer: (props: {
                      cell: any;
                      row: any;
                      rowIndex: Number;
                      rendererData: any;
                    }) => {
                      return (
                        <>
                          <FormattedMessage
                            defaultMessage="Click to select or deselect"
                            description="tooltip for table name cells"
                          />
                        </>
                      );
                    },
                  },
                ]}
              />
              {selectionTouched && !hasSelection ? (
                <Form.Control.Feedback type="invalid" className={"d-block"}>
                  <FontAwesomeIcon
                    icon={IconLibrary.icons.faExclamationCircle}
                    className={"icon"}
                  />
                  <span className={'copy'}>
                    {intl.formatMessage({
                      defaultMessage: "At least one device client group must be selected.",
                      description: "Field validation error",
                    })}
                  </span>
                </Form.Control.Feedback>
              ) : undefined}
            </Form.Group>
          )}
          <FormInput
            type={"textarea"}
            data-test-create-invitation-modal-invitation-scope-information
            label={intl.formatMessage(
                ClientGroupInvitationLabels.invitationScopeInformation
            )}
            field="invitationScopeInformation"
            register={register}
            registerOptions={{
              required: true,
            }}
            hasValue={!!formValues["invitationScopeInformation"]}
            resolveValidity={FormInputUtils.validityResolver(formState)}
          >
            {errors &&
              errors.invitationScopeInformation &&
              errors.invitationScopeInformation.type &&
              errors.invitationScopeInformation.type === "required" && (
                <Form.Control.Feedback type="invalid">
                  <FontAwesomeIcon
                    icon={IconLibrary.icons.faExclamationCircle}
                    className={"icon"}
                  />
                  {intl.formatMessage(
                    {
                      defaultMessage:
                        "{invitationScopeInformation} is required.",
                      description:
                        "Field validation error. 'message' = Field label for a custom message attached to the device client group invitation (ClientGroupInvitation.invitationScopeInformation)",
                    },
                    {
                      invitationScopeInformation: intl.formatMessage(
                          ClientGroupInvitationLabels.invitationScopeInformation
                      ),
                    }
                  )}
                </Form.Control.Feedback>
              )}
          </FormInput>
        </Form>
      </>
    </Modal>
  );
}

export default CreateDeviceClientInvitationModal;
