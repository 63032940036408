import React, { useContext, useEffect, useId, useMemo } from "react";
import { ClosableModalProps } from "../closable-modal-props";
import { User } from "../../../model/User";
import { FormattedMessage, useIntl } from "react-intl";
import UserUtils from "../../../utils/user";
import { UserValues } from "../../../localization/user";
import Table from "../../table/table-container";
import { Form } from "react-bootstrap";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import { UserLicensedItemAssignmentsValues } from "../../../localization/user-licensed-item-assignments";
import NavigateAfterAction from "../../navigate-after-action";
import { ModalKeys } from "../../pages/licenses";
import LicenseValidityBadge, {
  resolveLicenseValidity,
} from "../../badges/license-validity-badge";
import { LicenseLabels, LicenseValues } from "../../../localization/license";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import { UserLicensedItemAssignments } from "../../../model/entitlement/UserLicensedItemAssignments";
import { LicenseWithCredits } from "../../../model/entitlement/LicenseWithCredits";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ClearErrorAction,
  GetUserAction,
  isAddErrorAction,
} from "../../../actions/actionTypes";
import {
  DownloadLicenseRequestErrors,
  DownloadLicenseRequestLabels,
} from "../../../localization/download-license-request";
import "./download-users-license-modal-view.scss";
import { LicenseValidityType } from "../../badges/license-validity-badge/license-validity-badge-view";
import {IconLibrary, FormInput, FormInputUtils, FeedbackEntry, Feedback, ConfirmModal} from "@10duke/dukeui";
import {hasAction} from "../../../ui-configuration/configuration-tools";

const resolveUserName = (
  user: User | undefined | null,
  intl: { formatMessage: (v: any, v2?: any) => string }
) =>
  UserUtils.resolveDisplayName(
    user,
    intl.formatMessage(UserValues.displayName.undefined)
  );

function resolveLicensedItemName(
  lic: UserLicensedItemAssignments | undefined,
  fallback: string
): string {
  let retVal = lic
    ? lic.licensedItemDisplayName || lic.licensedItemName || fallback
    : fallback;
  return retVal;
}
function isValid(l: LicenseWithCredits) {
  const state = resolveLicenseValidity(l);
  return (
    state === LicenseValidityType.valid ||
    state === LicenseValidityType.expiring
  );
}
export interface DownloadUsersLicenseModalStateProps {
  selected: Array<UserLicensedItemAssignments>;
  onSetSelected: (selection: Array<UserLicensedItemAssignments>) => void;
  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;
  formData: any;
  onSetFormData: (d: any) => void;
  resultBlob: Blob | undefined;
  onSetResultBlob: (b: Blob | undefined) => void;
  failed: any[] | undefined;
  onSetFailed: (f: any[] | undefined) => void;
  showVersion: boolean;
  showAll: boolean;
  onToggleShowAll: () => void;
}
export interface DownloadUsersLicenseModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {
  onShowFeedback: (feedback: FeedbackEntry | FeedbackEntry[]) => void;
}
export interface DownloadUsersLicenseModalInputProps
  extends DownloadUsersLicenseModalVisibilityProps {
  userId?: string;
}
export interface DownloadUsersLicenseModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  user?: User | null;
  onLoadUser?: (id: string) => Promise<GetUserAction>;
  licenses: Array<UserLicensedItemAssignments> | undefined;
  onLoadLicenses: (id: string) => void;
  onDownloadLicense: (d: any) => Promise<any>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface DownloadUsersLicenseModalProps
  extends DownloadUsersLicenseModalInputProps,
    DownloadUsersLicenseModalDataProps,
    DownloadUsersLicenseModalStateProps {}
function DownloadUsersLicenseModal(props: DownloadUsersLicenseModalProps) {
  const {
    onExited,
    show,
    onClose,
    isReady,
    userId,
    user,
    onLoadUser,
    licenses,
    onLoadLicenses,
    activeSearch,
    onSetActiveSearch,
    selected,
    onSetSelected,
    onDownloadLicense,
    formData,
    onSetFormData,
    resultBlob,
    onSetResultBlob,
    failed,
    onSetFailed,
    showVersion,
    showAll,
    onToggleShowAll,
    onClearError,
  } = props;
  const idPrefix = useId();
  const intl = useIntl();
  if (show && user === undefined && (!userId || !onLoadUser)) {
    throw new Error(
      "DownloadUsersLicenseModal: Required props missing. The onLoadUser is required with userId"
    );
  }

  const defaultValues = useMemo(
    () => ({
      ...formData,
    }),
    [formData]
  );
  const { register, handleSubmit, formState, reset, watch } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
  });
  const { errors } = formState;
  const formValues = watch();
  useEffect(() => {
    if (show) {
      reset(defaultValues);
    } else {
      if (showVersion) {
        reset({
          hardwareId: "",
          version: "",
        });
      } else {
        reset({
          hardwareId: "",
        });
      }
    }
  }, [show, showVersion, reset, defaultValues]);
  const { conf } = useContext(UIConfiguration);
  const licenseConf =
    conf.functionality && conf.functionality.licenses
      ? conf.functionality.licenses
      : {};
  const userObjId = user ? user.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!onLoadUser &&
      !!userId &&
      (userObjId === undefined || (!!userObjId && userObjId !== userId))
    ) {
      onLoadUser(userId).then((res) => {
        if (!userObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, onLoadUser, userId, userObjId, onClearError]);
  useEffect(() => {
    if (!!show && !!onLoadLicenses && !!userObjId && !licenses) {
      onLoadLicenses(userObjId);
    }
  }, [show, licenses, onLoadLicenses, userId, userObjId]);

  return (
    <ConfirmModal
      id={"download-users-license-modal"}
      data-test-download-users-license-modal={userObjId || userId}
      onExited={onExited}
      onReloadData={
        !!resultBlob || (!!failed && failed.length > 0)
          ? undefined
          : () => {
              if (onLoadUser && userId) {
                onLoadUser(userId as string).then((res) => {
                  if (!userObjId && isAddErrorAction(res)) {
                    // only clear error if no data exists, leave if previous data is still available and
                    // let the api error notification handler show error
                    onClearError(res.error?.errorId);
                  }
                });
              }
              if (onLoadLicenses && userId) {
                onLoadLicenses(userId as string);
              }
            }
      }
      confirmTitle={
        resultBlob
          ? intl.formatMessage(
              {
                defaultMessage: "{name}: Download license token",
                description:
                  "modal heading for confirmed successful action. 'name' = name of the user",
              },
              {
                name: resolveUserName(user, intl),
              }
            )
          : intl.formatMessage(
              {
                defaultMessage: "{name}: Confirm license token download",
                description:
                  "modal heading for confirming action. 'name' = name of the user",
              },
              {
                name: resolveUserName(user, intl),
              }
            )
      }
      confirmContent={
        <>
          {!resultBlob && !failed && (
            <>
              {(!showVersion || formValues.version === "") && (
                <p data-test-confirm-changes-message>
                  <FormattedMessage
                    defaultMessage="Are you sure you wish to download a license token for {licenses} with the hardware id {hardwareId}?"
                    description="Confirm license download copy. 'licenses' = list of licenses, 'hardwareId' = hardware identifier value"
                    values={{
                      licenses: selected.map((s, i) => (
                        <span
                          key={s.licensedItemDisplayName || s.licensedItemName}
                        >
                          <strong>
                            {s.licensedItemDisplayName || s.licensedItemName}
                          </strong>
                          {i < selected.length - 1 && ", "}
                        </span>
                      )),
                      hardwareId: (
                        <strong className={"hardware-id"}>
                          {formValues.hardwareId}
                        </strong>
                      ),
                    }}
                  />
                </p>
              )}
              {showVersion && formValues.version !== "" && (
                <p data-test-confirm-changes-message>
                  <FormattedMessage
                    defaultMessage="Are you sure you wish to download a license token for {licenses} with the hardware id {hardwareId} and version {version}?"
                    description="Confirm license download copy with version enabled. 'licenses' = list of licenses, 'hardwareId' = hardware identifier value, 'version' = version information"
                    values={{
                      licenses: selected.map((s, i) => (
                        <span
                          key={s.licensedItemDisplayName || s.licensedItemName}
                        >
                          <strong>
                            {s.licensedItemDisplayName || s.licensedItemName}
                          </strong>
                          {i < selected.length - 1 && ", "}
                        </span>
                      )),
                      hardwareId: (
                        <strong className={"hardware-id"}>
                          {formValues.hardwareId}
                        </strong>
                      ),
                      version: (
                        <strong className={"version"}>
                          {formValues.version}
                        </strong>
                      ),
                    }}
                  />
                </p>
              )}
            </>
          )}
          {!!failed && (
            <Feedback show={true} type="danger" asChild={true}>
              <h4>
                <FormattedMessage
                  defaultMessage="Generating license token failed"
                  description="Generate license token failed heading"
                />
              </h4>
              <div data-test-operation-failed-message>
                <ul className={"list-unstyled"}>
                  {failed.map((f: any, i: number) => {
                    return (
                      <li className="err-msg" key={f.name}>
                        <strong>{f.name}:</strong>
                        {!!f.errorCode &&
                          !!(DownloadLicenseRequestErrors as any)[
                            f.errorCode
                          ] && (
                            <span>
                              {intl.formatMessage(
                                (DownloadLicenseRequestErrors as any)[
                                  f.errorCode
                                ]
                              )}
                            </span>
                          )}
                        {!f.errorCode ||
                          (!(DownloadLicenseRequestErrors as any)[
                            f.errorCode
                          ] && (
                            <span>
                              <FormattedMessage
                                defaultMessage="Unknown error."
                                description="Download license failure error fallback message"
                              />
                            </span>
                          ))}
                      </li>
                    );
                  })}
                </ul>
                <p>
                  <FormattedMessage
                    defaultMessage="The operation was aborted before any changes were applied to license consumption."
                    description="Generate license token failed copy suffix. Appended as last paragraph to all download errors."
                  />
                </p>
              </div>
            </Feedback>
          )}
          {!!resultBlob && (
            <Feedback show={true} type="success" asChild={true}>
              <h4>
                <FormattedMessage
                  defaultMessage="Download license token"
                  description="Generate license token success heading"
                />
              </h4>
              {(!showVersion || formValues.version === "") && (
                <p data-test-download-license-message>
                  <FormattedMessage
                    defaultMessage="License token generated successfully for {licenses} with the hardware id {hardwareId}."
                    description="Generate license token success copy 1, 'licenses' = list of failed license names, 'hardwareId' = hardware identifier value"
                    values={{
                      licenses: selected.map((f, i) => (
                        <span
                          key={f.licensedItemDisplayName || f.licensedItemName}
                        >
                          {i > 0 && <>, </>}
                          <strong>
                            {f.licensedItemDisplayName || f.licensedItemName}
                          </strong>
                        </span>
                      )),
                      hardwareId: (
                        <strong className={"hardware-id"}>
                          {formValues.hardwareId}
                        </strong>
                      ),
                    }}
                  />
                </p>
              )}
              {showVersion && formValues.version !== "" && (
                <p data-test-download-license-message>
                  <FormattedMessage
                    defaultMessage="License token generated successfully for {licenses} with the hardware id {hardwareId} and version {version}."
                    description="Generate license token success copy 1 with version enabled, 'licenses' = list of failed license names, 'hardwareId' = hardware identifier value, 'version' = version information"
                    values={{
                      licenses: selected.map((f, i) => (
                        <span
                          key={f.licensedItemDisplayName || f.licensedItemName}
                        >
                          {i > 0 && <>, </>}
                          <strong>
                            {f.licensedItemDisplayName || f.licensedItemName}
                          </strong>
                        </span>
                      )),
                      hardwareId: (
                        <strong className={"hardware-id"}>
                          {formValues.hardwareId}
                        </strong>
                      ),
                      version: (
                        <strong className={"version"}>
                          {formValues.version}
                        </strong>
                      ),
                    }}
                  />
                </p>
              )}
              <p>
                <FormattedMessage
                  defaultMessage="Please download the license token and follow the instructions in the application on how to use it."
                  description="Generate license token success copy 2"
                />
              </p>
              <a
                data-test-download-button
                href={URL.createObjectURL(resultBlob)}
                download={"license.jwt"}
                target="_blank"
                rel={"noreferrer"}
                className={"btn btn-success"}
              >
                {intl.formatMessage({
                  defaultMessage: "Download",
                  description: "button label for download license token file",
                })}
              </a>
            </Feedback>
          )}
        </>
      }
      acceptButton={
        !!resultBlob || !!failed
          ? {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
          : {
              label: intl.formatMessage({
                defaultMessage: "Generate token",
                description: "accept button label",
              }),
            }
      }
      cancelButton={
        !!resultBlob || !!failed
          ? undefined
          : {
              label: intl.formatMessage({
                defaultMessage: "Cancel",
                description: "cancel button label",
              }),
            }
      }
      title={
        user
          ? intl.formatMessage(
              {
                defaultMessage: "{name}: Download license token",
                description: "modal heading. 'name' = name of the user",
              },
              {
                name: resolveUserName(user, intl),
              }
            )
          : intl.formatMessage({
              defaultMessage: "User not found",
              description: "modal heading when user not found",
            })
      }
      show={show}
      onClose={onClose}
      isReady={isReady}
      backdrop={formState.isDirty || selected.length > 0 ? "static" : true}
      skipConfirm={!user}
      primaryButton={
        user && UserUtils.resolveUserStatus(user) !== "suspended"
          ? {
              label: intl.formatMessage({
                defaultMessage: "Continue",
                description: "primary button label",
              }),
              tooltip:
                selected.length === 0
                  ? intl.formatMessage({
                      defaultMessage: "Please select a license first.",
                      description:
                        "primary button disabled due to no license tooltip",
                    })
                  : !formValues.hardwareId || formValues.hardwareId === ""
                  ? intl.formatMessage({
                      defaultMessage: "Please provide a hardware id first.",
                      description:
                        "primary button disabled due to no hardware id tooltip",
                    })
                  : undefined,
              disabled:
                selected.length === 0 ||
                !formValues.hardwareId ||
                formValues.hardwareId === "",
            }
          : {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
      }
      onConfirm={(f: boolean) => {
        return new Promise((resolve) => {
          if (f) {
            handleSubmit((data) => {
              onSetFormData(data);
              resolve(f);
            })();
          } else {
            resolve(f);
          }
        });
      }}
      onPrimaryAction={
        user && UserUtils.resolveUserStatus(user) !== "suspended"
          ? () => {
              if (!!onDownloadLicense) {
                if (!!resultBlob || (!!failed && failed.length > 0)) {
                  onClose();
                } else {
                  onDownloadLicense({
                    hardwareId: formData.hardwareId,
                    version:
                      showVersion && formData.version !== ""
                        ? formData.version
                        : undefined,
                    onBehalfOfId: user.id as string,
                    consumptionMode: "checkout",
                    licenses: selected.map((s) => ({
                      name: s.licensedItemName,
                      licenseId: s.licenseId,
                    })),
                  }).then((tmp) => {
                    const suc = tmp.results.filter(
                      (v: any) =>
                        !isAddErrorAction(v) &&
                        typeof (v as any).result === "string"
                    );
                    const fail = tmp.results.filter(
                      (v: any) =>
                        isAddErrorAction(v) ||
                        !(v as any).result ||
                        (typeof (v as any).result !== "string" &&
                          (!(v as any).data?.license?.name ||
                            (v as any).result[(v as any).data.license.name] !==
                              true))
                    );
                    if (suc.length > 0 && fail.length === 0) {
                      const blob = new Blob(
                        [suc.map((res: any) => res.result).join("\n")],
                        {
                          type: "application/jwt",
                        }
                      );
                      onSetResultBlob(blob);
                    } else {
                      onSetResultBlob(undefined);
                    }
                    if (fail.length > 0) {
                      fail.forEach((f: any) => {
                        f.results?.forEach((fr: any) => {
                          onClearError(fr?.error?.errorId);
                        });
                      });
                      onSetFailed(
                        tmp.results
                          .map((f: any, i: number) => {
                            if (
                              isAddErrorAction(f) ||
                              !(f as any).result ||
                              (typeof (f as any).result !== "string" &&
                                (!(f as any).data?.license?.name ||
                                  (f as any).result[
                                    (f as any).data.license.name
                                  ] !== true))
                            ) {
                              return {
                                name:
                                  selected[i].licensedItemDisplayName ||
                                  selected[i].licensedItemName,
                                licenseId: selected[i].licenseId,
                                errorCode: !!(f as any).data?.license?.name
                                  ? (f as any).result[
                                      (f as any).data.license.name +
                                        "_errorCode"
                                    ]
                                  : "unknownError",
                              };
                            } else {
                              return undefined;
                            }
                          })
                          .filter((f: any) => !!f)
                      );
                    } else {
                      onSetFailed(undefined);
                    }
                  });
                }
              }
            }
          : onClose
      }
      secondaryButton={
        user && UserUtils.resolveUserStatus(user) !== "suspended"
          ? {
              label: intl.formatMessage({
                defaultMessage: "Cancel",
                description: "secondary button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={
        user && UserUtils.resolveUserStatus(user) !== "suspended"
          ? onClose
          : undefined
      }
    >
      <>
        {user && isReady && UserUtils.resolveUserStatus(user) !== "suspended" && (
          <>
            <p>
              <FormattedMessage
                defaultMessage="A hardware id is required to download a license token file, which will enable license usage in eg. offline environments."
                description="Licence download info copy"
                values={{
                  licenses: selected.map((s, i) => (
                    <span key={s.licensedItemDisplayName || s.licensedItemName}>
                      <strong>
                        {s.licensedItemDisplayName || s.licensedItemName}
                      </strong>
                      {i < selected.length - 1 && ", "}
                    </span>
                  )),
                  hardwareId: (
                    <strong className={"hardware-id"}>
                      {formValues.hardwareId}
                    </strong>
                  ),
                }}
              />
            </p>
            <Form noValidate>
              <FormInput
                data-test-download-license-hardware-id
                label={intl.formatMessage(
                  DownloadLicenseRequestLabels.hardwareId
                )}
                field="hardwareId"
                register={register}
                registerOptions={{
                  required: true,
                  maxLength: {
                    value: 2048,
                    message: intl.formatMessage(
                      {
                        defaultMessage:
                          "{hardwareId} must be shorter than {max} characters.",
                        description:
                          "Field validation error. 'max' = maximum number of characters, always larger than 3. 'hardwareId' = Field label for Hardware identifier (DownloadLicenseRequest.hardwareId)",
                      },
                      {
                        hardwareId: intl.formatMessage(
                          DownloadLicenseRequestLabels.hardwareId
                        ),
                        max: 2048,
                      }
                    ),
                  },
                  pattern: {
                    value: /^.*\S+.*$/g,
                    message: intl.formatMessage(
                      {
                        defaultMessage:
                          "{hardwareId} cannot contain only spaces",
                        description:
                          "Field validation error. 'hardwareId' = Field label for Hardware identifier (DownloadLicenseRequest.hardwareId)",
                      },
                      {
                        hardwareId: intl.formatMessage(
                          DownloadLicenseRequestLabels.hardwareId
                        ),
                      }
                    ),
                  },
                }}
                hasValue={!!formValues["hardwareId"]}
                resolveValidity={FormInputUtils.validityResolver(formState)}
              >
                {errors &&
                  errors.hardwareId &&
                  errors.hardwareId.type &&
                  errors.hardwareId.type === "required" && (
                    <Form.Control.Feedback type="invalid">
                      <FontAwesomeIcon
                        icon={IconLibrary.icons.faExclamationCircle}
                        className={"icon"}
                      />
                      <span className={"copy"}>
                        {intl.formatMessage(
                          {
                            defaultMessage: "{hardwareId} is required.",
                            description:
                              "Field validation error. 'hardwareId' = Field label for Hardware identifier (DownloadLicenseRequest.hardwareId)",
                          },
                          {
                            hardwareId: intl.formatMessage(
                              DownloadLicenseRequestLabels.hardwareId
                            ),
                          }
                        )}
                      </span>
                    </Form.Control.Feedback>
                  )}
                {errors &&
                  errors.hardwareId &&
                  errors.hardwareId.type &&
                  (errors.hardwareId.type === "maxLength" ||
                    errors.hardwareId.type === "pattern") && (
                    <Form.Control.Feedback type="invalid">
                      <FontAwesomeIcon
                        icon={IconLibrary.icons.faExclamationCircle}
                        className={"icon"}
                      />
                      <span className={"copy"}>
                        {(errors as any).hardwareId.message}
                      </span>
                    </Form.Control.Feedback>
                  )}
              </FormInput>
              {showVersion && (
                <FormInput
                  data-test-download-license-version
                  label={intl.formatMessage(
                    DownloadLicenseRequestLabels.version
                  )}
                  field="version"
                  register={register}
                  registerOptions={{
                    required: false,
                  }}
                  hasValue={!!formValues["version"]}
                  resolveValidity={FormInputUtils.validityResolver(formState)}
                ></FormInput>
              )}
              <Form.Group className={"form-table"}>
                <Table<UserLicensedItemAssignments>
                  disableLoadingIndicator={!isReady}
                  maxRows={15}
                  compact={true}
                  header={
                    <div>
                      <Form.Label>
                        <FormattedMessage
                          defaultMessage="Select Licenses"
                          description={"field label"}
                        />
                      </Form.Label>
                      <Form.Check
                        type={"switch"}
                        className={"custom-switch-sm"}
                        label={intl.formatMessage({
                          defaultMessage: "Show only valid licenses",
                          description:
                              "Label for filter toggle that controls visibility of invalid licenses in table",
                        })}
                        onChange={() => {
                          onToggleShowAll();
                        }}
                        id={idPrefix + "showAll"}
                        checked={!showAll}
                        value="1"
                      />
                    </div>
                  }
                  data-test-select-licenses
                  search={
                      (licenses || []).length > TABLE_SEARCH_THRESHOLD ||
                      !!activeSearch
                  }
                  activeSearch={activeSearch}
                  onSearch={onSetActiveSearch}
                  columnToggle={false}
                  reset={false}
                  data={licenses ? licenses : isReady ? [] : undefined}
                  pagination={false}
                  identifyingColumn={"licenseId"}
                  selection={{
                    multi: true,
                    selectAll: true,
                    disabledFor: licenses
                      ? licenses
                            .filter(
                                (f) =>
                                    f &&
                                    !isValid(
                                        f.assignments &&
                                        f.assignments.length &&
                                        f.assignments[0] &&
                                        f.assignments[0].license
                                            ? (f.assignments[0].license as any)
                                            : undefined
                                    )
                            )
                            .map((f) => f.licenseId)
                        : [],
                  }}
                  onSelectionChanged={onSetSelected}
                  selected={selected}
                  columns={[
                    {
                      key: "licensedId",
                      label: intl.formatMessage(LicenseLabels.id),
                      isTechnical: true,
                      hidden: true,
                    },
                    {
                      key: "name",
                      isDummy: true,
                      label: intl.formatMessage({
                        defaultMessage: "License",
                        description:
                          "Column heading for the licenses to select",
                      }),
                      sortable: true,
                      renderer: (props: {
                        cell: any;
                        row: any;
                        rowIndex: Number;
                        rendererData: any;
                      }) => {
                        const t = props.rendererData.resolveValue(props.row);
                        return (
                          <>
                            {t && t.length ? (
                              <span className={"link-holder"}>
                                {hasAction(licenseConf.rowActions, 'show') ? (
                                  <NavigateAfterAction
                                    href={
                                      (("/licenses/" +
                                        (props.row.assignments &&
                                        props.row.assignments.length &&
                                        props.row.assignments[0] &&
                                        props.row.assignments[0].license
                                          ? props.row.assignments[0].license.id
                                          : undefined)) as string) +
                                      "/" +
                                      ModalKeys.show
                                    }
                                    action={onClose}
                                  >
                                    {t[0]}
                                  </NavigateAfterAction>
                                ) : (
                                  t[0]
                                )}
                              </span>
                            ) : (
                              false
                            )}
                            {t && t.length > 1 ? (
                              <>
                                <LicenseValidityBadge
                                  validUntil={
                                    props.row.assignments &&
                                    props.row.assignments.length &&
                                    props.row.assignments[0] &&
                                    props.row.assignments[0].license
                                      ? props.row.assignments[0].license
                                          .validUntil
                                      : undefined
                                  }
                                  validFrom={
                                    props.row.assignments &&
                                    props.row.assignments.length &&
                                    props.row.assignments[0] &&
                                    props.row.assignments[0].license
                                      ? props.row.assignments[0].license
                                          .validFrom
                                      : undefined
                                  }
                                  validity={resolveLicenseValidity(
                                    props.row.assignments &&
                                      props.row.assignments.length &&
                                      props.row.assignments[0] &&
                                      props.row.assignments[0].license
                                      ? props.row.assignments[0].license
                                      : undefined
                                  )}
                                />
                              </>
                            ) : (
                              false
                            )}
                          </>
                        );
                      },
                      tipRenderer: (props: {
                        cell: any;
                        row: any;
                        rowIndex: Number;
                        rendererData: any;
                      }) => {
                        const valid = isValid(
                          props.row.assignments &&
                            props.row.assignments.length &&
                            props.row.assignments[0] &&
                            props.row.assignments[0].license
                            ? (props.row.assignments[0].license as any)
                            : undefined
                        );
                        if (!valid) {
                          return (
                            <>
                              {intl.formatMessage({
                                defaultMessage:
                                    "Only valid licenses can be selected.",
                                description: "tooltip for unselectable licenses",
                              })}
                            </>
                          );
                        } else {
                          const t = props.rendererData.resolveValue(props.row);
                          return (
                            <>
                              {t && t.length ? t[0] : false}
                              {t && t.length > 1 ? <>, {t[1]}</> : false}
                            </>
                          );
                        }
                      },
                      rendererData: {
                        resolveValue: (itm: any) => {
                          const validity = resolveLicenseValidity(
                            itm.assignments &&
                              itm.assignments.length &&
                              itm.assignments[0] &&
                              itm.assignments[0].license
                              ? itm.assignments[0].license
                              : undefined
                          );
                          return [
                            resolveLicensedItemName(
                              itm,
                              intl.formatMessage(
                                UserLicensedItemAssignmentsValues
                                  .licensedItemDisplayName.undefined
                              )
                            ),
                            validity
                              ? intl.formatMessage(
                                  (LicenseValues.validityStatus as any)[
                                    validity
                                  ]
                                )
                              : validity,
                          ];
                        },
                      },
                    },
                  ]}
                />
              </Form.Group>
            </Form>
          </>
        )}
        {user && isReady && UserUtils.resolveUserStatus(user) === "suspended" && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p>
              <FormattedMessage
                defaultMessage="License download is not available for suspended users."
                description="message to be shown when the user has been suspended"
              />
            </p>
          </Feedback>
        )}
        {isReady && !user && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p>
              <FormattedMessage
                defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
                description="message to be shown when there is no user to display"
              />
            </p>
          </Feedback>
        )}
      </>
    </ConfirmModal>
  );
}
export default DownloadUsersLicenseModal;
