import * as ActionTypes from "../actions/actionTypes";
import {ClientIdsByEntitlementId} from "../store/EntitlementState";

export default function entitlementClients(
  state: ClientIdsByEntitlementId,
  action: ActionTypes.AppAction
): ClientIdsByEntitlementId | null {
  const currentState = state || ({} as ClientIdsByEntitlementId);
  switch (action.type) {
    case ActionTypes.DELETE_ORG_CLIENT:
      const deleteClientAction = action as (ActionTypes.DeleteOrgClientAction);
      const entIds = Object.keys(currentState);
      const newState: ClientIdsByEntitlementId = {};
      entIds.forEach((id) =>  {
        newState[id] = currentState[id].filter((f) => f !== deleteClientAction.clientId);
      })
      return newState;
    case ActionTypes.LIST_ENT_CONSUMING_CLIENTS:
      const listEntConsumingClientsAction = action as ActionTypes.ListEntConsumingClientsAction
      return { ...currentState,  ...{ [listEntConsumingClientsAction.entId]: listEntConsumingClientsAction.clientIds} };
    case ActionTypes.START_AUTHN:
      return null;
    default:
      return state || null;
  }
}
