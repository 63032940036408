import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form } from "react-bootstrap";

export interface SelectCellProps {
  mode?: string;
  checked?: boolean;
  disabled?: boolean;
  className?: string;
}

export default function SelectCell(props: SelectCellProps) {
  const { mode, checked, disabled, className } = props;
  return (
    <>
      {!disabled && (
        <Form.Check
          data-test-select-control
          className={"select-control" + (className ? " " + className : "")}
          checked={checked}
          readOnly
          type={mode === "radio" ? "radio" : "checkbox"}
          disabled={disabled}
          label={""}
        />
      )}
      {disabled && (
        <FontAwesomeIcon
          data-test-select-indicator={checked ? "checked" : "unchecked"}
          icon={checked ? "check" : "times"}
          className={
            "select-indicator " +
            (className ? className + " " : "") +
            (checked ? "checked" : "unchecked")
          }
          fixedWidth={true}
        />
      )}
    </>
  );
}
