import { withCloseAfterExited } from "@10duke/dukeui";
import {
  default as View,
  RemoveDeviceClientInvitationModalProps
} from "./remove-device-client-invitation-modal-view";

const RemoveDeviceClientInvitationModal = withCloseAfterExited<RemoveDeviceClientInvitationModalProps>(
  View
);
export default RemoveDeviceClientInvitationModal;
