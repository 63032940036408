import * as ActionTypes from "./actionTypes";
import { ActionSender } from "../model/ActionSender";
import { removeUserFromOrganization } from "./orgUsersActions";
import {
  ensureSelectedOrgId,
  buildMultiActionThunk,
  EventOperation,
  EventOperationProvider
} from "./actionHelpers";
import { queryAvailableLicenses, manageLicenseAssignments } from "./entActions";
import { setUserSuspended } from "./userActions";
import {queryAvailableLicensesWithUsage} from "./queryAvailableLicensesWithUsageMultiAction";
import {releaseLicenseLeaseAction} from "./releaseLicenseLeaseAction";

/**
 * Detach user from organization and set related reservations to floating.
 *
 * @param sender Sender of action.
 * @param userId ID of user to be detached.
 * @param orgId Target organization for detachment of user.
 */
export function detachUserFromOrganization(
  sender: ActionSender,
  userId: string,
  orgId?: string,
  removeLease?: boolean
): ActionTypes.AppThunkAction<ActionTypes.MultiAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);

  return buildMultiActionThunk(
    sender,
    new DetachUserFromOrgProvider(sender, userId, orgIdOrDefault, removeLease === true),
    false
  );
}

enum DetachUserState {
  INIT = 0,
  QUERY_AVAILABLE_LICENSES,
  MANAGE_LICENSE_ASSIGMENTS,
  PREPARE_ACTIVE_LEASES,
  MANAGE_ACTIVE_LEASES,
  ACTIVATE_USER,
  REMOVE_FROM_ORGANIZATION,
  DONE
}

class DetachUserFromOrgProvider
  implements EventOperationProvider<ActionTypes.AppAction> {
  private state: DetachUserState;
  private sender: ActionSender;
  private userId: string;
  private orgId: string;
  private licenseManagementOperations: EventOperation<ActionTypes.AppAction>[];
  private leaseManagementOperations: EventOperation<ActionTypes.AppAction>[];
  private removeLease: boolean;

  constructor(sender: ActionSender, userId: string, orgId: string, removeLease: boolean) {
    this.state = DetachUserState.INIT;
    this.sender = sender;
    this.userId = userId;
    this.orgId = orgId;
    this.licenseManagementOperations = [];
    this.leaseManagementOperations = [];
    this.removeLease = removeLease;
  }

  /**
   * Method for providing next thunk/function to be executed in this chain.
   * @param sender
   * @param multiAction
   */
  next(
    sender: ActionSender,
    multiAction: ActionTypes.MultiAction
  ): EventOperation<ActionTypes.AppAction> | null {
    if (this.state === DetachUserState.INIT) {
      this.state = DetachUserState.QUERY_AVAILABLE_LICENSES;
      if (this.removeLease) {
        return {
          thunk: queryAvailableLicensesWithUsage(this.sender, this.userId, this.orgId)
        };
      } else {
        return {
          thunk: queryAvailableLicenses(this.sender, this.userId, this.orgId)
        };
      }
    }

    if (this.state === DetachUserState.QUERY_AVAILABLE_LICENSES) {
      if (this.removeLease) {
        this.licenseManagementOperations = this.buildLicenseManagementOperations(
            (multiAction.results[0] as ActionTypes.MultiAction).results[0] as ActionTypes.QueryAvailableLicensesAction
        );
      } else {
        this.licenseManagementOperations = this.buildLicenseManagementOperations(
            multiAction.results[0] as ActionTypes.QueryAvailableLicensesAction
        );
      }

      this.state = DetachUserState.MANAGE_LICENSE_ASSIGMENTS;
    }

    if (this.state === DetachUserState.MANAGE_LICENSE_ASSIGMENTS) {
      if (this.licenseManagementOperations.length !== 0) {
        return this.licenseManagementOperations.pop() as EventOperation<
          ActionTypes.AppAction
        >;
      } else {
        if (this.removeLease) {
          this.state = DetachUserState.PREPARE_ACTIVE_LEASES;
        } else {
          this.state = DetachUserState.ACTIVATE_USER;
        }
      }
    }

    if (this.state === DetachUserState.PREPARE_ACTIVE_LEASES) {
      this.leaseManagementOperations = this.buildLeaseManagementOperations(
          multiAction.results[0] as ActionTypes.MultiAction
      );

      this.state = DetachUserState.MANAGE_ACTIVE_LEASES;
    }

    if (this.state === DetachUserState.MANAGE_ACTIVE_LEASES) {
      if (this.leaseManagementOperations.length !== 0) {
        return this.leaseManagementOperations.pop() as EventOperation<
            ActionTypes.AppAction
        >;
      } else {
        this.state = DetachUserState.ACTIVATE_USER;
      }
    }

    if (this.state === DetachUserState.ACTIVATE_USER) {
      this.state = DetachUserState.REMOVE_FROM_ORGANIZATION;
      return {
        thunk: setUserSuspended(this.sender, false, this.userId)
      };
    }

    if (this.state === DetachUserState.REMOVE_FROM_ORGANIZATION) {
      this.state = DetachUserState.DONE;
      return {
        thunk: removeUserFromOrganization(this.sender, this.userId, this.orgId)
      };
    }

    return null;
  }

  /**
   * Build required license management operations based on data returned by getAvailableLicenses() action thunk.
   *
   * @param availableLicensesAction
   */
  private buildLicenseManagementOperations(
      availableLicensesAction: ActionTypes.QueryAvailableLicensesAction,
  ): EventOperation<ActionTypes.AppAction>[] {
    const ops: EventOperation<ActionTypes.AppAction>[] = [];
    for (const license of availableLicensesAction.licenses) {
      if (!license.assignments || license.assignments.length === 0) {
        continue;
      }

      // Filter only reserved assigments.
      const assignments = license.assignments.filter(
          ass => ass.type && ass.type === "reserved"
      );
      // skip if no reserved assignments to remove
      if (assignments.length === 0) {
        continue;
      }
      // Mark reserved as floating.
      assignments.forEach((ass) => (ass.type = "floating"));

      // Create thunks to execute.
      ops.push({
        thunk: manageLicenseAssignments(
            this.sender,
            "any",
            license.id as string,
            this.userId,
            assignments,
            this.orgId
        )
      });
    }

    return ops;
  }
  private buildLeaseManagementOperations(
      result: ActionTypes.MultiAction
  ): EventOperation<ActionTypes.AppAction>[] {
    const ops: EventOperation<ActionTypes.AppAction>[] = [];
    for (let i = 1; i < result.results.length; i += 1) {
      if ((result.results[i] as any).licenseUsage?.users?.length) {
        const t = (result.results[i] as any).licenseUsage?.users[0].assignments?.map((a:any) => {
          return a.sessions?.map((s:any) => {
            return s.leases?.map((l:any) => { return { id: l.id, assignment: a };});
          });
        }).flat(3)
            .filter((f:any) => !!f && !!f.id);
        if (!t || !t.length) {
          continue;
        }
        // Create thunks to execute.
        ops.push({
          thunk: releaseLicenseLeaseAction(
              this.sender,
              t
          )
        });
      }
    }
    return ops;
  }
}
