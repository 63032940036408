import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {
  buildActionThunk,
  buildMultiActionThunk,
  ensureSelectedOrgId,
  EventOperation,
  EventOperationProvider,
  forceUndefined
} from "./actionHelpers";
import { OrganizationRole } from "../model/OrganizationRole";
import { listOrganizationUsers } from "./orgsActions";
import { listUsersInOrganizationRole } from "./orgRoleUsersActions";

/**
 * Lists organization roles of an organization.
 * @param sender Component requesting for the action
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function listOrganizationRoles(
  sender: ActionSender,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListOrgRolesAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ListOrgRolesAction, OrganizationRole[]>(
    sender,
    ActionTypes.LIST_ORG_ROLES,
    async () => await idpApi.listOrganizationRoles(orgIdOrDefault),
    (type, roles) => ({
      type,
      roles: forceUndefined(roles),
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Creates a new organization role.
 * @param sender Component requesting for the action
 * @param orgRole OrganizationRole object describing the organization role to create.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function createOrganizationRole(
  sender: ActionSender,
  orgRole: OrganizationRole,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.CreateOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.CreateOrgRoleAction, OrganizationRole>(
    sender,
    ActionTypes.CREATE_ORG_ROLE,
    async () => await idpApi.createOrganizationRole(orgRole, orgIdOrDefault),
    (type, role) => ({
      type,
      role: forceUndefined(role),
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Updates organization role data by replacing existing data with the given data.
 * @param sender Component requesting for the action
 * @param orgRole OrganizationRole object describing the new organization role data.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function replaceOrganizationRole(
  sender: ActionSender,
  orgRole: OrganizationRole,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ReplaceOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ReplaceOrgRoleAction, OrganizationRole>(
    sender,
    ActionTypes.REPLACE_ORG_ROLE,
    async () => await idpApi.replaceOrganizationRole(orgRole, orgIdOrDefault),
    (type, role) => ({
      type,
      role: forceUndefined(role),
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Gets an organization role of an organization by id.
 * @param sender Component requesting for the action
 * @param orgRoleId The organization role id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function getOrganizationRole(
  sender: ActionSender,
  orgRoleId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.GetOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.GetOrgRoleAction, OrganizationRole>(
    sender,
    ActionTypes.GET_ORG_ROLE,
    async () => await idpApi.getOrganizationRole(orgRoleId, orgIdOrDefault),
    (type, role) => ({
      type,
      role: forceUndefined(role),
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Deletes an organization role of an organization by id.
 * @param sender Component requesting for the action
 * @param orgRoleId The organization role id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function deleteOrganizationRole(
  sender: ActionSender,
  orgRoleId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.DeleteOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.DeleteOrgRoleAction, void>(
    sender,
    ActionTypes.DELETE_ORG_ROLE,
    async () => await idpApi.deleteOrganizationRole(orgRoleId, orgIdOrDefault),
    type => ({
      type,
      orgRoleId,
      orgId: orgIdOrDefault
    })
  );
}

export function listOrganizationRolesWithUsers(
  sender: ActionSender,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.MultiAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildMultiActionThunk(
    sender,
    new ListOrganizationRolesWithUsers(sender, orgIdOrDefault),
    false
  );
}
enum ListOrganizationRolesWithUsersState {
  INIT = 0,
  LIST_ROLES,
  HANDLE_ROLES,
  LIST_USERS_IN_ROLES,
  DONE
}
class ListOrganizationRolesWithUsers
  implements EventOperationProvider<ActionTypes.AppAction> {
  private state: ListOrganizationRolesWithUsersState;
  private sender: ActionSender;
  private orgId: string;
  private operations: EventOperation<ActionTypes.AppAction>[];

  constructor(sender: ActionSender, orgId: string) {
    this.state = ListOrganizationRolesWithUsersState.INIT;
    this.sender = sender;
    this.orgId = orgId;
    this.operations = [];
  }

  /**
   * Method for providing next thunk/function to be executed in this chain.
   * @param sender
   * @param multiAction
   */
  next(
    sender: ActionSender,
    multiAction: ActionTypes.MultiAction
  ): EventOperation<ActionTypes.AppAction> | null {
    if (this.state === ListOrganizationRolesWithUsersState.INIT) {
      this.state = ListOrganizationRolesWithUsersState.LIST_ROLES;
      return {
        thunk: listOrganizationUsers(this.sender, this.orgId)
      };
    }
    if (this.state === ListOrganizationRolesWithUsersState.LIST_ROLES) {
      this.state = ListOrganizationRolesWithUsersState.HANDLE_ROLES;
      return {
        thunk: listOrganizationRoles(this.sender, this.orgId)
      };
    }
    if (this.state === ListOrganizationRolesWithUsersState.HANDLE_ROLES) {
      this.operations = this.buildOperations(
        multiAction.results[1] as ActionTypes.ListOrgRolesAction
      );
      this.state = ListOrganizationRolesWithUsersState.LIST_USERS_IN_ROLES;
    }
    if (
      this.state === ListOrganizationRolesWithUsersState.LIST_USERS_IN_ROLES
    ) {
      if (this.operations.length !== 0) {
        return this.operations.pop() as EventOperation<ActionTypes.AppAction>;
      } else {
        this.state = ListOrganizationRolesWithUsersState.DONE;
      }
    }
    return null;
  }

  /**
   * Build required listUsersInOrganizationRole operations based on data returned by
   * listOrganizationRoles() action thunk.
   *
   * @param rolesAction
   */
  private buildOperations(
    rolesAction: ActionTypes.ListOrgRolesAction
  ): EventOperation<ActionTypes.AppAction>[] {
    const ops: EventOperation<ActionTypes.AppAction>[] = [];

    for (const role of rolesAction.roles) {
      ops.push({
        thunk: listUsersInOrganizationRole(
          this.sender,
          role.id as string,
          this.orgId
        )
      });
    }
    return ops;
  }
}
