import * as ActionTypes from "../actions/actionTypes";
import { InProgress } from "../model/InProgress";

export default function inProgress(
  state: InProgress<any>[],
  action: ActionTypes.AppAction
): InProgress<any>[] | null {
  switch (action.type) {
    case ActionTypes.ADD_IN_PROGRESS:
      const addInProgressAction =
        action as ActionTypes.AddInProgressAction<any>;
      return (state || []).concat(addInProgressAction.operation);
    case ActionTypes.CLEAR_IN_PROGRESS:
      const clearInProgressAction = action as ActionTypes.ClearInProgressAction;
      return (state || []).filter(
        (oper) => oper.operationId !== clearInProgressAction.operationId
      );
    case ActionTypes.CLEAR_ALL_IN_PROGRESS_INFOS:
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return [];
    default:
      return state || [];
  }
}
