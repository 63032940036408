import React, { useEffect, useMemo } from "react";
import { ClosableModalProps } from "../closable-modal-props";
import { User } from "../../../model/User";
import { FormattedMessage, useIntl } from "react-intl";
import UserUtils from "../../../utils/user";
import {
  UserLabels,
  UserValues,
  UserWithStatusValues,
} from "../../../localization/user";
import Table from "../../table/table-container";
import { Form } from "react-bootstrap";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import NavigateAfterAction from "../../navigate-after-action";
import { ModalKeys } from "../../pages/users";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ClearErrorAction,
  isAddErrorAction,
  QueryLicenseUsageAction,
} from "../../../actions/actionTypes";
import {
  DownloadLicenseRequestErrors,
  DownloadLicenseRequestLabels,
} from "../../../localization/download-license-request";
import { License } from "../../../model/entitlement/License";
import UserStatusBadge from "../../badges/user-status-badge";
import { LicenseWithCredits } from "../../../model/entitlement/LicenseWithCredits";
import { resolveLicenseValidity } from "../../badges/license-validity-badge";
import { UserLicensedItemAssignmentsValues } from "../../../localization/user-licensed-item-assignments";
import { LicenseUsage } from "../../../model/entitlement/LicenseUsage";
import { LicenseWithOwner } from "../../../model/entitlement/LicenseWithOwner";
import "./download-license-modal-view.scss";
import { LicenseValidityType } from "../../badges/license-validity-badge/license-validity-badge-view";
import {IconLibrary, FormInput, FormInputUtils, FeedbackEntry, Feedback, ConfirmModal} from "@10duke/dukeui";

function isValid(l: License) {
  const state = resolveLicenseValidity(l as LicenseWithCredits);
  return (
    state === LicenseValidityType.valid ||
    state === LicenseValidityType.expiring
  );
}

const resolveUserName = (
  user: User | undefined | null,
  intl: { formatMessage: (v: any, v2?: any) => string }
) =>
  UserUtils.resolveDisplayName(
    user,
    intl.formatMessage(UserValues.displayName.undefined)
  );

function resolveLicensedItemName(
  lic: License | undefined,
  fallback: string
): string {
  let retVal =
    lic && lic.licensedItem
      ? lic.licensedItem.displayName || lic.licensedItem.name || fallback
      : fallback;
  return retVal;
}

export interface DownloadLicenseModalStateProps {
  selected: Array<User>;
  onSetSelected: (selection: Array<User>) => void;
  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;
  formData: any;
  onSetFormData: (d: any) => void;
  resultBlob: Blob | undefined;
  onSetResultBlob: (b: Blob | undefined) => void;
  failed: any[] | undefined;
  onSetFailed: (f: any[] | undefined) => void;
  showVersion: boolean;
}
export interface DownloadLicenseModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {
  onShowFeedback: (feedback: FeedbackEntry | FeedbackEntry[]) => void;
}
export interface DownloadLicenseModalInputProps
  extends DownloadLicenseModalVisibilityProps {
  licenseId?: string;
}
export interface DownloadLicenseModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  license?: (LicenseUsage & LicenseWithOwner) | null;
  onLoadLicense?: (id: string) => Promise<QueryLicenseUsageAction>;
  users: Array<User> | undefined;
  onLoadUsers: () => void;
  onDownloadLicense: (d: any) => Promise<any>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface DownloadLicenseModalProps
  extends DownloadLicenseModalInputProps,
    DownloadLicenseModalDataProps,
    DownloadLicenseModalStateProps {}
function DownloadLicenseModal(props: DownloadLicenseModalProps) {
  const {
    onExited,
    show,
    onClose,
    isReady,
    licenseId,
    license,
    onLoadLicense,
    users,
    onLoadUsers,
    activeSearch,
    onSetActiveSearch,
    selected,
    onSetSelected,
    onDownloadLicense,
    formData,
    onSetFormData,
    resultBlob,
    onSetResultBlob,
    failed,
    onSetFailed,
    showVersion,
    onClearError,
  } = props;
  const intl = useIntl();
  if (show && license === undefined && (!licenseId || !onLoadLicense)) {
    throw new Error(
      "DownloadLicenseModal: Required props missing. The onLoadLicense is required with licenseId"
    );
  }

  const defaultValues = useMemo(
    () => ({
      ...formData,
    }),
    [formData]
  );
  const { register, handleSubmit, formState, reset, watch } = useForm({
    mode: "onBlur",
    defaultValues: defaultValues,
  });
  const { errors } = formState;
  const formValues = watch();
  useEffect(() => {
    if (show) {
      reset(defaultValues);
    } else {
      if (showVersion) {
        reset({
          hardwareId: "",
          version: "",
        });
      } else {
        reset({
          hardwareId: "",
        });
      }
    }
  }, [show, showVersion, reset, defaultValues]);
  const licenseObjId = license ? license.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!onLoadLicense &&
      !!licenseId &&
      (licenseObjId === undefined ||
        (!!licenseObjId && licenseObjId !== licenseId))
    ) {
      onLoadLicense(licenseId).then((res) => {
        if (!licenseObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, onLoadLicense, licenseId, licenseObjId, onClearError]);
  useEffect(() => {
    if (!!show && !!onLoadUsers && !users) {
      onLoadUsers();
    }
  }, [show, users, onLoadUsers]);
  const licenseIsValidWithSeats = license
    ? isValid(license) && license.active
    : false;
  return (
    <ConfirmModal
      id={"download-license-modal"}
      data-test-download-license-modal={licenseObjId || licenseId}
      onExited={onExited}
      onReloadData={
        !!resultBlob || (!!failed && failed.length > 0)
          ? undefined
          : () => {
              if (onLoadLicense && licenseId) {
                onLoadLicense(licenseId as string);
              }
              if (onLoadUsers) {
                onLoadUsers();
              }
            }
      }
      confirmTitle={
        resultBlob
          ? intl.formatMessage(
              {
                defaultMessage: "{name}: Download license token",
                description:
                  "modal heading for confirmed successful action. 'name' = name of the licensed item",
              },
              {
                name: license
                  ? resolveLicensedItemName(
                      license,
                      intl.formatMessage(
                        UserLicensedItemAssignmentsValues
                          .licensedItemDisplayName.undefined
                      )
                    )
                  : intl.formatMessage(
                      UserLicensedItemAssignmentsValues.licensedItemDisplayName
                        .undefined
                    ),
              }
            )
          : intl.formatMessage(
              {
                defaultMessage: "{name}: Confirm license token download",
                description:
                  "modal heading for confirming action. 'name' = name of the licensed item",
              },
              {
                name: license
                  ? resolveLicensedItemName(
                      license,
                      intl.formatMessage(
                        UserLicensedItemAssignmentsValues
                          .licensedItemDisplayName.undefined
                      )
                    )
                  : intl.formatMessage(
                      UserLicensedItemAssignmentsValues.licensedItemDisplayName
                        .undefined
                    ),
              }
            )
      }
      confirmContent={
        <>
          {!resultBlob && !failed && (
            <>
              {(!showVersion || formValues.version === "") && (
                <p data-test-confirm-changes-message>
                  <FormattedMessage
                    defaultMessage="Are you sure you wish to download a license token for {user} with the hardware id {hardwareId}?"
                    description="Confirm license download copy. 'user' = users name, 'hardwareId' = hardware identifier value"
                    values={{
                      user: selected.map((s, i) => (
                        <strong key={s.id as string}>
                          {resolveUserName(s, intl)}
                        </strong>
                      )),
                      hardwareId: (
                        <strong className={"hardware-id"}>
                          {formValues.hardwareId}
                        </strong>
                      ),
                    }}
                  />
                </p>
              )}
              {showVersion && formValues.version !== "" && (
                <p data-test-confirm-changes-message>
                  <FormattedMessage
                    defaultMessage="Are you sure you wish to download a license token for {user} with the hardware id {hardwareId} and version {version}?"
                    description="Confirm license download copy with version enabled. 'user' = users name, 'hardwareId' = hardware identifier value, 'version' = version information"
                    values={{
                      user: selected.map((s, i) => (
                        <strong key={s.id as string}>
                          {resolveUserName(s, intl)}
                        </strong>
                      )),
                      hardwareId: (
                        <strong className={"hardware-id"}>
                          {formValues.hardwareId}
                        </strong>
                      ),
                      version: (
                        <strong className={"version"}>
                          {formValues.version}
                        </strong>
                      ),
                    }}
                  />
                </p>
              )}
            </>
          )}
          {!!failed && (
            <Feedback show={true} type="danger" asChild={true}>
              <h4>
                <FormattedMessage
                  defaultMessage="Generating license token failed"
                  description="Generate license token failed heading"
                />
              </h4>
              <div data-test-operation-failed-message>
                <ul className={"list-unstyled"}>
                  {failed.map((f: any, i: number) => {
                    return (
                      <li className="err-msg" key={f.id as string}>
                        <strong>{resolveUserName(f, intl)}:</strong>
                        {!!f.errorCode &&
                          !!(DownloadLicenseRequestErrors as any)[
                            f.errorCode
                          ] && (
                            <span>
                              {intl.formatMessage(
                                (DownloadLicenseRequestErrors as any)[
                                  f.errorCode
                                ]
                              )}
                            </span>
                          )}
                        {!f.errorCode ||
                          (!(DownloadLicenseRequestErrors as any)[
                            f.errorCode
                          ] && (
                            <span>
                              <FormattedMessage
                                defaultMessage="Unknown error."
                                description="Download license failure error fallback message"
                              />
                            </span>
                          ))}
                      </li>
                    );
                  })}
                </ul>
                <p>
                  <FormattedMessage
                    defaultMessage="The operation was aborted before any changes were applied to license consumption."
                    description="Generate license token failed copy suffix. Appended as last paragraph to all download errors."
                  />
                </p>
              </div>
            </Feedback>
          )}
          {!!resultBlob && (
            <Feedback show={true} type="success" asChild={true}>
              <h4>
                <FormattedMessage
                  defaultMessage="Download license token"
                  description="Generate license token success heading"
                />
              </h4>

              {(!showVersion || formValues.version === "") && (
                <p data-test-download-license-message>
                  <FormattedMessage
                    defaultMessage="License token generated successfully for {user} with the hardware id {hardwareId}."
                    description="Generate license token success copy 1, 'user' = users name, 'hardwareId' = hardware identifier value"
                    values={{
                      user: selected.map((f, i) => (
                        <strong key={f.id as string}>
                          {resolveUserName(f, intl)}
                        </strong>
                      )),
                      hardwareId: (
                        <strong className={"hardware-id"}>
                          {formValues.hardwareId}
                        </strong>
                      ),
                    }}
                  />
                </p>
              )}
              {showVersion && formValues.version !== "" && (
                <p data-test-download-license-message>
                  <FormattedMessage
                    defaultMessage="License token generated successfully for {user} with the hardware id {hardwareId} and version {version}."
                    description="Generate license token success copy 1 with version enabled, 'user' = users name, 'hardwareId' = hardware identifier value, 'version' = version information"
                    values={{
                      user: selected.map((f, i) => (
                        <strong key={f.id as string}>
                          {resolveUserName(f, intl)}
                        </strong>
                      )),
                      hardwareId: (
                        <strong className={"hardware-id"}>
                          {formValues.hardwareId}
                        </strong>
                      ),
                      version: (
                        <strong className={"version"}>
                          {formValues.version}
                        </strong>
                      ),
                    }}
                  />
                </p>
              )}
              <p>
                <FormattedMessage
                  defaultMessage="Please download the license token and follow the instructions in the application on how to use it."
                  description="Generate license token success copy 2"
                />
              </p>
              <a
                data-test-download-button
                href={URL.createObjectURL(resultBlob)}
                download={"license.jwt"}
                target="_blank"
                rel={"noreferrer"}
                className={"btn btn-success"}
              >
                {intl.formatMessage({
                  defaultMessage: "Download",
                  description: "button label for download license token file",
                })}
              </a>
            </Feedback>
          )}
        </>
      }
      acceptButton={
        !!resultBlob || !!failed
          ? {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
          : {
              label: intl.formatMessage({
                defaultMessage: "Generate token",
                description: "accept button label",
              }),
            }
      }
      cancelButton={
        !!resultBlob || !!failed
          ? undefined
          : {
              label: intl.formatMessage({
                defaultMessage: "Cancel",
                description: "cancel button label",
              }),
            }
      }
      title={
        license
          ? intl.formatMessage(
              {
                defaultMessage: "{name}: Download license token",
                description:
                  "modal heading. 'name' = name of the licensed item",
              },
              {
                name: license
                  ? resolveLicensedItemName(
                      license,
                      intl.formatMessage(
                        UserLicensedItemAssignmentsValues
                          .licensedItemDisplayName.undefined
                      )
                    )
                  : intl.formatMessage(
                      UserLicensedItemAssignmentsValues.licensedItemDisplayName
                        .undefined
                    ),
              }
            )
          : intl.formatMessage({
              defaultMessage: "License not found",
              description: "modal heading when license not found",
            })
      }
      show={show}
      onClose={onClose}
      isReady={isReady}
      backdrop={formState.isDirty || selected.length > 0 ? "static" : true}
      skipConfirm={!license}
      primaryButton={
        license && licenseIsValidWithSeats
          ? {
              label: intl.formatMessage({
                defaultMessage: "Continue",
                description: "primary button label",
              }),
              tooltip:
                selected.length === 0
                  ? intl.formatMessage({
                      defaultMessage: "Please select a user first.",
                      description:
                        "primary button disabled due to no license tooltip",
                    })
                  : !formValues.hardwareId || formValues.hardwareId === ""
                  ? intl.formatMessage({
                      defaultMessage: "Please provide a hardware id first.",
                      description:
                        "primary button disabled due to no hardware id tooltip",
                    })
                  : undefined,
              disabled:
                selected.length === 0 ||
                !formValues.hardwareId ||
                formValues.hardwareId === "",
            }
          : {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
      }
      onConfirm={(f: boolean) => {
        return new Promise((resolve) => {
          if (f) {
            handleSubmit((data) => {
              onSetFormData(data);
              resolve(f);
            })();
          } else {
            resolve(f);
          }
        });
      }}
      onPrimaryAction={
        license && licenseIsValidWithSeats
          ? () => {
              if (!!onDownloadLicense) {
                if (!!resultBlob || (!!failed && failed.length > 0)) {
                  onClose();
                } else {
                  onDownloadLicense({
                    hardwareId: formData.hardwareId,
                    version:
                      showVersion && formData.version !== ""
                        ? formData.version
                        : undefined,
                    onBehalfOfId: selected[0].id as string,
                    consumptionMode: "checkout",
                    licenses: [
                      {
                        name: license?.licensedItem?.name,
                        licenseId: license?.id,
                      },
                    ],
                  }).then((tmp) => {
                    const suc = tmp.results.filter(
                      (v: any) =>
                        !isAddErrorAction(v) &&
                        typeof (v as any).result === "string"
                    );
                    const fail = tmp.results.filter(
                      (v: any) =>
                        isAddErrorAction(v) ||
                        !(v as any).result ||
                        (typeof (v as any).result !== "string" &&
                          (!(v as any).data?.license?.name ||
                            (v as any).result[(v as any).data.license.name] !==
                              true))
                    );
                    if (suc.length > 0 && fail.length === 0) {
                      const blob = new Blob(
                        [suc.map((res: any) => res.result).join("\n")],
                        {
                          type: "application/jwt",
                        }
                      );
                      onSetResultBlob(blob);
                    } else {
                      onSetResultBlob(undefined);
                    }
                    if (fail.length > 0) {
                      fail.forEach((f: any) => {
                        f.results?.forEach((fr: any) => {
                          onClearError(fr?.error?.errorId);
                        });
                      });
                      onSetFailed(
                        tmp.results
                          .map((f: any, i: number) => {
                            if (
                              isAddErrorAction(f) ||
                              !(f as any).result ||
                              (typeof (f as any).result !== "string" &&
                                (!(f as any).data?.license?.name ||
                                  (f as any).result[
                                    (f as any).data.license.name
                                  ] !== true))
                            ) {
                              return {
                                ...selected[0],
                                // pick errorCode from result
                                errorCode: !!(f as any).data?.license?.name
                                  ? (f as any).result[
                                      (f as any).data.license.name +
                                        "_errorCode"
                                    ]
                                  : "unknownError",
                                /* result: (f as any).result, */
                              };
                            } else {
                              return undefined;
                            }
                          })
                          .filter((f: any) => !!f)
                      );
                    } else {
                      onSetFailed(undefined);
                    }
                  });
                }
              }
            }
          : onClose
      }
      secondaryButton={
        license
          ? {
              label: intl.formatMessage({
                defaultMessage: "Cancel",
                description: "secondary button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={license ? onClose : undefined}
    >
      <>
        {license && isReady && licenseIsValidWithSeats && (
          <>
            <p>
              <FormattedMessage
                defaultMessage="A hardware id is required to download a license token file, which will enable license usage in e.g. offline environments."
                description="Licence download info copy"
              />
            </p>
            <Form noValidate>
              <FormInput
                data-test-download-license-hardware-id
                label={intl.formatMessage(
                  DownloadLicenseRequestLabels.hardwareId
                )}
                field="hardwareId"
                register={register}
                registerOptions={{
                  required: true,
                  maxLength: {
                    value: 2048,
                    message: intl.formatMessage(
                      {
                        defaultMessage:
                          "{hardwareId} must be shorter than {max} characters.",
                        description:
                          "Field validation error. 'max' = maximum number of characters, always larger than 3. 'hardwareId' = Field label for Hardware identifier (DownloadLicenseRequest.hardwareId)",
                      },
                      {
                        hardwareId: intl.formatMessage(
                          DownloadLicenseRequestLabels.hardwareId
                        ),
                        max: 2048,
                      }
                    ),
                  },
                  pattern: {
                    value: /^.*\S+.*$/g,
                    message: intl.formatMessage(
                      {
                        defaultMessage:
                          "{hardwareId} cannot contain only spaces",
                        description:
                          "Field validation error. 'hardwareId' = Field label for Hardware identifier (DownloadLicenseRequest.hardwareId)",
                      },
                      {
                        hardwareId: intl.formatMessage(
                          DownloadLicenseRequestLabels.hardwareId
                        ),
                      }
                    ),
                  },
                }}
                hasValue={!!formValues["hardwareId"]}
                resolveValidity={FormInputUtils.validityResolver(formState)}
              >
                {errors &&
                  errors.hardwareId &&
                  errors.hardwareId.type &&
                  errors.hardwareId.type === "required" && (
                    <Form.Control.Feedback type="invalid">
                      <FontAwesomeIcon
                        icon={IconLibrary.icons.faExclamationCircle}
                        className={"icon"}
                      />
                      <span className={"copy"}>
                        {intl.formatMessage(
                          {
                            defaultMessage: "{hardwareId} is required.",
                            description:
                              "Field validation error. 'hardwareId' = Field label for Hardware identifier (DownloadLicenseRequest.hardwareId)",
                          },
                          {
                            hardwareId: intl.formatMessage(
                              DownloadLicenseRequestLabels.hardwareId
                            ),
                          }
                        )}
                      </span>
                    </Form.Control.Feedback>
                  )}
                {errors &&
                  errors.hardwareId &&
                  errors.hardwareId.type &&
                  (errors.hardwareId.type === "maxLength" ||
                    errors.hardwareId.type === "pattern") && (
                    <Form.Control.Feedback type="invalid">
                      <FontAwesomeIcon
                        icon={IconLibrary.icons.faExclamationCircle}
                        className={"icon"}
                      />
                      <span className={"copy"}>
                        {(errors as any).hardwareId.message}
                      </span>
                    </Form.Control.Feedback>
                  )}
              </FormInput>

              {showVersion && (
                <FormInput
                  data-test-download-license-version
                  label={intl.formatMessage(
                    DownloadLicenseRequestLabels.version
                  )}
                  field="version"
                  register={register}
                  registerOptions={{
                    required: false,
                  }}
                  hasValue={!!formValues["version"]}
                  resolveValidity={FormInputUtils.validityResolver(formState)}
                ></FormInput>
              )}

              <Form.Group className={"form-table"}>
                <Table<User>
                    disableLoadingIndicator={!isReady}
                    maxRows={15}
                    compact={true}
                    header={
                      <Form.Label>
                        <FormattedMessage
                            defaultMessage="Select user"
                            description={"field label"}
                        />
                      </Form.Label>
                    }
                    data-test-select-user
                    search={users && users.length > TABLE_SEARCH_THRESHOLD}
                    activeSearch={activeSearch}
                    onSearch={onSetActiveSearch}
                    columnToggle={false}
                    reset={false}
                    data={users ? users : isReady ? [] : undefined}
                    pagination={false}
                    identifyingColumn={"id"}
                    selection={{
                      multi: false,
                      selectAll: false,
                      selectColumnType: "radio",
                      disabledFor: users
                          ? users
                              .filter(
                                  (u) =>
                                      UserUtils.resolveUserStatus(u) === "suspended"
                              )
                              .map((u) => u.id)
                          : [],
                    }}
                    onSelectionChanged={onSetSelected}
                    selected={selected}
                    columns={[
                      {
                        key: "id",
                        label: intl.formatMessage(UserLabels.id),
                        isTechnical: true,
                        hidden: true,
                      },
                      {
                        key: "name",
                        isDummy: true,
                        label: intl.formatMessage({
                          defaultMessage: "User",
                          description: "cell heading for the user cell",
                        }),
                        sortable: true,
                        renderer: (props: {
                          cell: any;
                          row: any;
                          rowIndex: Number;
                          rendererData: any;
                        }) => {
                          const t = props.rendererData.resolveValue(props.row);
                          return (
                              <>
                            <span
                                key={"link_" + props.row.id}
                                className={"link-holder"}
                            >
                              <NavigateAfterAction
                                  href={
                                      "/users/" +
                                      props.row.id +
                                      "/" +
                                      ModalKeys.show
                                  }
                                  action={onClose}
                              >
                                <strong>{t[0]}</strong>
                                {t[1] && <small>{" <" + t[1] + ">"}</small>}
                              </NavigateAfterAction>
                            </span>
                                <UserStatusBadge
                                    status={UserUtils.resolveUserStatus(props.row)}
                                />
                              </>
                          );
                        },
                        tipRenderer: (props: {
                          cell: any;
                          row: any;
                          rowIndex: Number;
                          rendererData: any;
                        }) => {
                          const t = props.rendererData.resolveValue(props.row);
                          const status = UserUtils.resolveUserStatus(props.row);
                          if (status === "suspended") {
                            return (
                                <>
                                  <FormattedMessage
                                      defaultMessage="Suspended users cannot be selected for download license."
                                      description="tooltip for suspended users"
                                  />
                                </>
                            );
                          } else {
                            return (
                                <>
                                  {t[0]}, {t[1]}
                                </>
                            );
                          }
                        },
                        rendererData: {
                          resolveValue: (itm: any) => {
                            const status = UserUtils.resolveUserStatus(itm);
                            return [
                              resolveUserName(itm, intl),
                              itm.email,
                              status
                                  ? intl.formatMessage(
                                      (UserWithStatusValues.status as any)[status]
                                  )
                                  : status,
                            ];
                          },
                        },
                      },
                    ]}
                />
              </Form.Group>
            </Form>
          </>
        )}

        {isReady && license && !licenseIsValidWithSeats && (
          <>
            {!license.active && (
              <Feedback type={"danger"} show={true} asChild={true}>
                <p>
                  <FormattedMessage
                    defaultMessage="The license has been deactivated. License download is only allowed for valid licenses."
                    description="message to be shown when the license is not active"
                  />
                </p>
              </Feedback>
            )}
            {!!license.active && !isValid(license) && (
              <Feedback type={"danger"} show={true} asChild={true}>
                <p>
                  <FormattedMessage
                    defaultMessage="The license is invalid. License download is only allowed for valid licenses."
                    description="message to be shown when the license is invalid"
                  />
                </p>
              </Feedback>
            )}
          </>
        )}

        {isReady && !license && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p>
              <FormattedMessage
                defaultMessage="Something went wrong and the license could not be loaded. The license may have been removed or you don't have sufficient access rights."
                description="message to be shown when there is no license to display"
              />
            </p>
          </Feedback>
        )}
      </>
    </ConfirmModal>
  );
}
export default DownloadLicenseModal;
