import { connect } from "react-redux";
import App, { AppProps as _AppProps } from "./app-view";
import { AppState } from "../../store/AppState";
import OrganizationUtils from "../../utils/organization";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../util/typeUtil";
import InProgressUtils from "../../utils/in-progress";
import { ActionSender } from "../../model/ActionSender";

// Own props, i.e. props that this component takes as input
interface AppProps {
  localeInPath?: string;
  externalLogout?: boolean;
}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_AppProps, AppProps>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<_AppProps, AppProps>;

const sender: ActionSender = { key: "index" };

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    organizationId: OrganizationUtils.selectSelectedOrganizationId(state),
    hasOrgsToManage: OrganizationUtils.selectHasOrganizations(state),
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    OrganizationUtils.compareSelectedOrganizationIdStates(next, prev) &&
    OrganizationUtils.compareOrganizationsStates(next, prev) &&
    InProgressUtils.compareInProgressStates(next, prev)
  );
}

export default connect<ReduxStateProps, ReduxDispatchProps, AppProps, AppState>(
  mapStateToProps,
  {},
  null,
  {
    areStatesEqual,
  }
)(App);
