import { Dropdown, Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { IconLibrary, TooltipWrapper } from "@10duke/dukeui";
import "./filter-tools-view.scss";
import { useId } from "react";

//<editor-fold desc="Props">
export interface FilterToolsVisibilityProps {
  show?: boolean;
  onShow: () => void;
  onHide: () => void;
}
export interface TableFilterInternal {
  key: string;
  handler: (active: boolean) => void;
  isActive: boolean|undefined;
  content: string|JSX.Element;
}
export interface FilterToolsProps extends FilterToolsVisibilityProps {
  filters: TableFilterInternal[];
  compact?: boolean;
}
//</editor-fold>

export default function FilterTools(props: FilterToolsProps) {
  //<editor-fold desc="Local variables">
  const { filters, show, onShow, onHide, compact } = props;
  //</editor-fold>

  //<editor-fold desc="Hooks">
  const intl = useIntl();
  const idPrefix = useId();
  //</editor-fold>
  const onToggleHandler = (isOpen: boolean, metadata: any) => {
    if (show) {
      if (metadata.source !== "select") {
        onHide();
      }
    } else {
      onShow();
    }
  };
  const hasActive = !!filters.find((f) => f.isActive);
  return (
    <Dropdown
      className={"btn-group filter-tools" + (compact ? " btn-group-sm" : "")}
      show={show}
      onToggle={onToggleHandler}
    >
      <TooltipWrapper
        tipKey={"toolsToggleFilterTip"}
        tip={intl.formatMessage({
          defaultMessage: "Select filters to control displayed items.",
          description: "Tooltip for the select filters button",
        })}
      >
        <Dropdown.Toggle
          variant={"primary"}
          className={'custom-base'}
          data-test-table-filters-trigger
        >
          <div className={"icon d-block"}>
            {!hasActive && IconLibrary.customIcons.editFilters}
            {hasActive && IconLibrary.customIcons.editFiltersActive}
          </div>
        </Dropdown.Toggle>
      </TooltipWrapper>

      <Dropdown.Menu
        data-test-table-filters
        popperConfig={{
          modifiers: [
            {
              name: "computeStyles",
              options: {
                gpuAcceleration: false, // true by default. Without this random conf, unnecessary scrollbars are added to parents
              },
            },
          ],
        }}
      >
        <Dropdown.Header className={"border-bottom"}>
          <FormattedMessage
            defaultMessage="Toggle filters"
            description={"Heading for filters selection dropdown"}
          />
        </Dropdown.Header>
        {filters &&
          filters.map((c, i) => {
            return (
              <Dropdown.Item
                data-test-table-filter={c.key}
                key={c.key}
                onClick={() => c.handler(!c.isActive)}
              >
                <Form.Check
                  id={idPrefix + c.key}
                  readOnly
                  checked={c.isActive}
                  type={"checkbox"}
                  label={c.content}
                />
              </Dropdown.Item>
            );
          })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
