import { useEffect, useMemo, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import {FieldList, IconLibrary, FormInput,FormInputUtils,FeedbackEntry,Feedback,ApplyInput,Modal} from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import { OrganizationRole } from "../../../model/OrganizationRole";
import cellEditFactory from "react-bootstrap-table2-editor";
import { Form, FormGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  ClearErrorAction,
  GetOrgRoleAction,
  isAddErrorAction,
  ReplaceOrgRoleAction,
  SetOrganizationRolesOfOrgRoleAction,
  SetPermissionsOfOrgRoleAction,
} from "../../../actions/actionTypes";
import RoleTypeBadge from "../../badges/role-type-badge";
import Table from "../../table";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrganizationRoleLabels } from "../../../localization/organization-role";
import { PermissionWithGrantedActions } from "../../../model/PermissionWithGrantedActions";
import { PermissionGrantsForPermission } from "../../../model/PermissionGrantsForPermission";
import "./view-role-modal.scss";
import NavigateAfterAction from "../../navigate-after-action";
import { ModalKeys } from "../../pages/roles";

//<editor-fold desc="Props">

export interface ViewRoleModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title" | "role"> {}
export interface ViewRoleModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {
  onShowFeedback: (feedback: FeedbackEntry | FeedbackEntry[]) => void;
}
export interface ViewRoleModalInputProps extends ViewRoleModalVisibilityProps {
  edit?: boolean;
  allowEdit?: boolean;
  roleId: string | undefined;
}
export interface ViewRoleModalStateProps {
  hasPermissionChanges: boolean;

  selectedPermissions: PermissionWithGrantedActions[];
  onSetSelectedPermissions: (selection: PermissionWithGrantedActions[]) => void;
  activePermissionsSearch?: string;
  onSetActivePermissionsSearch: (s: string) => void;

  hasRoleChanges: boolean;

  selectedRoles: OrganizationRole[];
  onSetSelectedRoles: (selection: OrganizationRole[]) => void;
  activeRoleSearch?: string;
  onSetActiveRoleSearch: (s: string) => void;

  onUpdateActions: (obj: PermissionWithGrantedActions, value: string[]) => void;
}
export interface RoleWithType extends OrganizationRole {
  type: string | undefined;
}
export interface ViewRoleModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  organizationId?: string;
  role: RoleWithType | undefined | null;
  includedRoles?: OrganizationRole[];
  rolePermissions?: PermissionWithGrantedActions[];
  onLoadRole: (roleId: string) => Promise<GetOrgRoleAction>;
  onUpdateRole?: (role: OrganizationRole) => Promise<ReplaceOrgRoleAction>;
  onLoadRolePermissions?: (roleId: string, orgId: string) => void;
  onLoadRoleIncludedRoles?: (roleId: string, orgId: string) => void;
  onSetIncludedRoles?: (
    roles: OrganizationRole[],
    roleId: string,
    orgId: string
  ) => Promise<SetOrganizationRolesOfOrgRoleAction>;
  onSetRolePermissions?: (
    permissionGrantsForPermission: PermissionGrantsForPermission[],
    roleId: string,
    orgId: string
  ) => Promise<SetPermissionsOfOrgRoleAction>;

  permissions?: PermissionWithGrantedActions[];
  onLoadPermissions?: (orgId: string) => void;

  roles?: OrganizationRole[];
  onLoadRoles?: (orgId: string) => void;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface ViewRoleModalProps
  extends ViewRoleModalDOMProps,
    ViewRoleModalInputProps,
    ViewRoleModalStateProps,
    ViewRoleModalDataProps {}
//</editor-fold>

function ViewRoleModal(props: ViewRoleModalProps) {
  //<editor-fold desc="Local variables">
  let {
    role,
    roleId,
    onLoadRole,
    show,
    onClose,
    edit,
    allowEdit,
    onUpdateRole,
    onShowFeedback,
    onSetRolePermissions,
    onSetIncludedRoles,
    rolePermissions,
    onLoadRolePermissions,
    onLoadRoleIncludedRoles,
    onLoadPermissions,
    permissions,
    organizationId,
    hasPermissionChanges,
    selectedPermissions,
    onSetSelectedPermissions,
    activePermissionsSearch,
    onSetActivePermissionsSearch,
    hasRoleChanges,
    selectedRoles,
    onSetSelectedRoles,
    activeRoleSearch,
    onSetActiveRoleSearch,
    isReady,
    onExited,
    includedRoles,
    roles,
    onLoadRoles,
    onUpdateActions,
    onClearError,
  } = props;
  // this is more like a variable than a hook
  const intl = useIntl();
  //</editor-fold>

  //<editor-fold desc="Hooks">
  const history = useHistory();
  const defaultValues = useMemo(
    () => ({
      name: role ? role.name : "",
      type: role ? role.type : "",
      description: role ? role.description : "",
    }),
    [role]
  );
  const { register, handleSubmit, formState, reset, watch } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const { errors } = formState;

  const formValues = watch();

  if (show && includedRoles === undefined && !onLoadRoleIncludedRoles) {
    throw new Error(
      "ViewRoleModal: Required props missing. includedRoles or onLoadRoleIncludedRoles must be defined"
    );
  }
  if (show && rolePermissions === undefined && !onLoadRolePermissions) {
    throw new Error(
      "ViewRoleModal: Required props missing. rolePermissions or onLoadRolePermissions must be defined"
    );
  }
  if (show && permissions === undefined && !onLoadPermissions) {
    throw new Error(
      "ViewRoleModal: Required props missing. permissions or onLoadPermissions must be defined"
    );
  }
  if (show && roles === undefined && !onLoadRoles) {
    throw new Error(
      "ViewRoleModal: Required props missing. roles or onLoadRoles must be defined"
    );
  }
  if (
    show &&
    allowEdit &&
    (!onSetRolePermissions || !onSetIncludedRoles || !onUpdateRole)
  ) {
    throw new Error(
      "ViewRoleModal: Required props missing. onSetRolePermissions, onSetIncludedRoles and onUpdateRole must be defined when allowEdit"
    );
  }
  const roleObjId = role ? role.id : undefined;
  useEffect(() => {
    if (
      show &&
      organizationId &&
      roleObjId &&
      rolePermissions === undefined &&
      onLoadRolePermissions
    ) {
      onLoadRolePermissions(roleObjId as string, organizationId);
    }
  }, [show, organizationId, roleObjId, rolePermissions, onLoadRolePermissions]);
  useEffect(() => {
    if (
      show &&
      organizationId &&
      roleObjId &&
      includedRoles === undefined &&
      onLoadRoleIncludedRoles
    ) {
      onLoadRoleIncludedRoles(roleObjId as string, organizationId);
    }
  }, [show, roleObjId, organizationId, includedRoles, onLoadRoleIncludedRoles]);

  useEffect(() => {
    if (
      show &&
      organizationId &&
      permissions === undefined &&
      onLoadPermissions
    ) {
      onLoadPermissions(organizationId);
    }
  }, [show, organizationId, permissions, onLoadPermissions]);
  useEffect(() => {
    if (show && organizationId && roles === undefined && onLoadRoles) {
      onLoadRoles(organizationId);
    }
  }, [show, organizationId, roles, onLoadRoles]);
  useEffect(() => {
    if (
      show &&
      roleId &&
      (roleObjId === undefined || (roleObjId && roleObjId !== roleId)) &&
      onLoadRole
    ) {
      onLoadRole(roleId).then((res) => {
        if (!roleObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, roleId, roleObjId, onLoadRole, onClearError]);

  useEffect(() => {
    if (defaultValues && edit) {
      reset(defaultValues);
    }
  }, [defaultValues, edit, reset]);
  //</editor-fold>
  return (
    <Modal
      className={"view-role-modal"}
      onExited={onExited}
      onReloadData={() => {
        if (organizationId && (role || roleId) && onLoadRolePermissions) {
          onLoadRolePermissions(
            role ? (role.id as string) : (roleId as string),
            organizationId
          );
        }
        if (organizationId && (role || roleId) && onLoadRoleIncludedRoles) {
          onLoadRoleIncludedRoles(
            role ? (role.id as string) : (roleId as string),
            organizationId
          );
        }
        if (organizationId && onLoadPermissions) {
          onLoadPermissions(organizationId);
        }
        if (organizationId && onLoadRoles) {
          onLoadRoles(organizationId);
        }
        if ((role || roleId) && onLoadRole) {
          onLoadRole(role ? (role.id as string) : (roleId as string)).then(
            (res) => {
              if (!role && isAddErrorAction(res)) {
                // only clear error if no data exists, leave if previous data is still available and
                // let the api error notification handler show error
                onClearError(res.error?.errorId);
              }
            }
          );
        }
      }}
      isReady={isReady}
      data-test-view-role-modal
      title={
        !isReady || role
          ? !edit
            ? intl.formatMessage({
                defaultMessage: "Role details",
                description: "modal heading",
              })
            : intl.formatMessage({
                defaultMessage: "Edit role details",
                description: "modal heading for edit state",
              })
          : intl.formatMessage({
              defaultMessage: "Role not found",
              description: "modal heading when role not found",
            })
      }
      show={show}
      onClose={onClose}
      backdrop={
        formState.isDirty || hasPermissionChanges || hasRoleChanges
          ? "static"
          : true
      }
      primaryButton={
        role && allowEdit
          ? !edit
            ? {
                label: intl.formatMessage({
                  defaultMessage: "Edit",
                  description: "primary button label",
                }),
              }
            : {
                label: intl.formatMessage({
                  defaultMessage: "Apply",
                  description: "primary button label for edit state",
                }),
                disabled:
                  (errors !== undefined &&
                    errors !== null &&
                    Object.keys(errors).length > 0) ||
                  !(
                    formState.isDirty ||
                    hasPermissionChanges ||
                    hasRoleChanges
                  ),
              }
          : {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
      }
      onPrimaryAction={
        role && allowEdit
          ? !edit
            ? () => {
                history.push(
                  "/roles/" +
                    (role ? (role.id as string) : "not_possible") +
                    "/edit"
                );
              }
            : () => {
                handleSubmit((data) => {
                  const ng: any = {
                    ...role,
                    ...data,
                  };
                  delete ng.type;
                  const feedback: FeedbackEntry[] = [];
                  const handleRolesAndPermissions = (nrole: any) => {
                    const promiseWrapper: Promise<any>[] = [];

                    if (onSetRolePermissions && hasPermissionChanges) {
                      promiseWrapper.push(
                        onSetRolePermissions(
                          rolePermissions
                            ? rolePermissions.map((r) => {
                                return {
                                  permissionId: r.id as string,
                                  grantedActions:
                                    r.grantedActions?.allowedActions,
                                } as PermissionGrantsForPermission;
                              })
                            : [],
                          nrole.id as string,
                          organizationId as string
                        )
                      );
                    }
                    if (onSetIncludedRoles && hasRoleChanges) {
                      promiseWrapper.push(
                        onSetIncludedRoles(
                          includedRoles ? includedRoles : [],
                          nrole.id as string,
                          organizationId as string
                        )
                      );
                    }
                    if (promiseWrapper.length) {
                      Promise.all(promiseWrapper).then((results) => {
                        const permRes = hasPermissionChanges
                          ? results[0]
                          : undefined;
                        const roleRes =
                          hasRoleChanges && hasPermissionChanges
                            ? results[1]
                            : hasRoleChanges
                            ? results[0]
                            : undefined;
                        if (permRes) {
                          const rres = permRes;
                          if (!isAddErrorAction(rres)) {
                            feedback.push({
                              id: "edit_organization_role_permissions",
                              msg: intl.formatMessage(
                                {
                                  defaultMessage:
                                    "Included permissions updated for {name}.",
                                  description:
                                    "success notification for updating role permissions. 'name' = name of the role",
                                },
                                {
                                  name: "<strong>" + nrole.name + "</strong>",
                                }
                              ),
                              autoClose: true,
                              type: "success",
                            });
                          } else {
                            onClearError(rres.error?.errorId);
                            feedback.push({
                              id: "edit_organization_role_permissions",
                              msg: intl.formatMessage(
                                {
                                  defaultMessage:
                                    "Updating included permissions for {name} failed.",
                                  description:
                                    "failure notification for updating role permissions. 'name' = name of the role",
                                },
                                {
                                  name: "<strong>" + nrole.name + "</strong>",
                                }
                              ),
                              type: "danger",
                            });
                          }
                        }
                        if (roleRes) {
                          const rres = roleRes;
                          if (!isAddErrorAction(rres)) {
                            feedback.push({
                              id: "edit_organization_role_roles",
                              msg: intl.formatMessage(
                                {
                                  defaultMessage:
                                    "Included roles updated for {name}.",
                                  description:
                                    "success notification for updating role roles. 'name' = name of the role",
                                },
                                {
                                  name: "<strong>" + nrole.name + "</strong>",
                                }
                              ),
                              autoClose: true,
                              type: "success",
                            });
                          } else {
                            onClearError(rres.error?.errorId);
                            feedback.push({
                              id: "edit_organization_role_roles",
                              msg: intl.formatMessage(
                                {
                                  defaultMessage:
                                    "Updating included roles for {name} failed.",
                                  description:
                                    "failure notification for updating role roles. 'name' = name of the role",
                                },
                                {
                                  name: "<strong>" + nrole.name + "</strong>",
                                }
                              ),
                              type: "danger",
                            });
                          }
                        }
                        onShowFeedback(feedback);
                        onClose();
                      });
                    } else {
                      onShowFeedback(feedback);
                      onClose();
                    }
                  };
                  if (
                    onUpdateRole &&
                    onSetRolePermissions &&
                    onSetIncludedRoles
                  ) {
                    if (formState.isDirty) {
                      onUpdateRole(ng as OrganizationRole).then((res) => {
                        if (!isAddErrorAction(res)) {
                          feedback.push({
                            id: "roleEditSuccess",
                            msg: intl.formatMessage(
                              {
                                defaultMessage: "Role {name} updated.",
                                description:
                                  "success notification for updating role. 'name' = name of the role",
                              },
                              {
                                name: "<strong>" + ng.name + "</strong>",
                              }
                            ),
                            autoClose: true,
                            type: "success",
                          });
                          handleRolesAndPermissions(ng);
                        } else {
                          onClearError(res.error?.errorId);
                          onShowFeedback({
                            id:
                              "roleEditFailure_" +
                              (role ? (role.id as string) : "not_possible"),
                            msg: intl.formatMessage(
                              {
                                defaultMessage: "Updating role {name} failed.",
                                description:
                                  "failure notification for updating role. 'name' = name of the role",
                              },
                              {
                                name:
                                  "<strong>" +
                                  (!!role ? role.name : ng.name) +
                                  "</strong>",
                              }
                            ),
                            type: "danger",
                          });
                          onClose();
                        }
                      });
                    } else {
                      handleRolesAndPermissions(ng);
                    }
                  }
                  onClose();
                })();
              }
          : onClose
      }
      secondaryButton={
        role && allowEdit
          ? !edit
            ? {
                label: intl.formatMessage({
                  defaultMessage: "Close",
                  description: "close button label",
                }),
              }
            : {
                label: intl.formatMessage({
                  defaultMessage: "Cancel",
                  description: "secondary button label",
                }),
              }
          : undefined
      }
      onSecondaryAction={
        role && allowEdit
          ? !edit
            ? onClose
            : () => {
                history.push(
                  "/roles/" +
                    (role ? (role.id as string) : "not_possible") +
                    "/show"
                );
              }
          : undefined
      }
    >
      {(!isReady || role) && !edit && (
        <>
          <FieldList
            asOneLiners={true}
            fields={{
              name: {
                label: intl.formatMessage(OrganizationRoleLabels.name),
                value: role ? role.name : "",
              },
              description: {
                label: intl.formatMessage(OrganizationRoleLabels.description),
                value: role ? role.description : "",
              },
              type: {
                label: intl.formatMessage(OrganizationRoleLabels.type),
                value: role ? <RoleTypeBadge type={role.type} /> : "",
              },
              includedRoles: {
                label: intl.formatMessage(
                  OrganizationRoleLabels.organizationRoles
                ),
                itemClass:
                  includedRoles && includedRoles.length > 0
                    ? "list-as-value"
                    : undefined,
                value: (
                  <>
                    {includedRoles && (
                      <ul>
                        {includedRoles.length > 0 &&
                          includedRoles.map((rpm, i) => (
                            <li
                              key={"includedRoles_" + rpm.id}
                              data-test-included-role={rpm.id}
                            >
                              <NavigateAfterAction
                                href={"/roles/" + rpm.id + "/" + ModalKeys.show}
                                action={onClose}
                              >
                                {rpm.name}
                              </NavigateAfterAction>
                            </li>
                          ))}
                      </ul>
                    )}
                    {(!includedRoles || includedRoles.length === 0) && (
                      <span data-test-no-included-roles>
                        <FormattedMessage
                          defaultMessage="No included organization roles"
                          description={"display value for no roles"}
                        />
                      </span>
                    )}
                  </>
                ),
              },
              permissions: {
                label: intl.formatMessage(OrganizationRoleLabels.permissions),
                itemClass:
                  rolePermissions && rolePermissions.length > 0
                    ? "list-as-value"
                    : undefined,
                value: (
                  <>
                    {rolePermissions && (
                      <ul>
                        {rolePermissions?.map((rpm, i) => (
                          <li
                            key={"rolePermissions_" + rpm.id}
                            data-test-included-permission={rpm.id}
                          >
                            <strong>{rpm.name}</strong>
                            {rpm.grantedActions &&
                              rpm.grantedActions.allowedActions &&
                              rpm.grantedActions.allowedActions.length > 0 && (
                                <>
                                  {" "}
                                  <em data-test-permission-actions>
                                    (
                                    <span>
                                      {rpm.grantedActions?.allowedActions.join(
                                        ", "
                                      )}
                                    </span>
                                    )
                                  </em>
                                </>
                              )}
                          </li>
                        ))}
                      </ul>
                    )}
                    {(!rolePermissions || rolePermissions.length === 0) && (
                      <span data-test-no-included-permissions>
                        <FormattedMessage
                          defaultMessage="No included permissions"
                          description={"display value for no permissions"}
                        />
                      </span>
                    )}
                  </>
                ),
              },
            }}
          />
        </>
      )}
      {(isReady || role) && edit && (
        <Form noValidate data-test-edit>
          <FormInput
            data-test-view-role-modal-role-name
            label={intl.formatMessage(OrganizationRoleLabels.name)}
            field="name"
            register={register}
            registerOptions={{
              required: true,
            }}
            hasValue={!!formValues["name"]}
            resolveValidity={FormInputUtils.validityResolver(formState)}
          >
            {errors &&
              errors.name &&
              errors.name.type &&
              errors.name.type === "required" && (
                <Form.Control.Feedback type="invalid">
                  <FontAwesomeIcon
                    icon={IconLibrary.icons.faExclamationCircle}
                    className={"icon"}
                  />
                  <span className={"copy"}>
                    {intl.formatMessage(
                      {
                        defaultMessage: "{name} is required.",
                        description:
                          "Field validation error. 'name' = Field label for organization role name (OrganizationRole.name)",
                      },
                      {
                        name: intl.formatMessage(OrganizationRoleLabels.name),
                      }
                    )}
                  </span>
                </Form.Control.Feedback>
              )}
          </FormInput>
          <FormInput
            data-test-view-role-modal-role-description
            label={intl.formatMessage(OrganizationRoleLabels.description)}
            field="description"
            type={"textarea"}
            register={register}
            registerOptions={{
              required: true,
            }}
            hasValue={!!formValues["description"]}
            resolveValidity={FormInputUtils.validityResolver(formState)}
          >
            {errors &&
              errors.description &&
              errors.description.type &&
              errors.description.type === "required" && (
                <Form.Control.Feedback type="invalid">
                  <FontAwesomeIcon
                    icon={IconLibrary.icons.faExclamationCircle}
                    className={"icon"}
                  />
                  {intl.formatMessage(
                    {
                      defaultMessage: "{description} is required.",
                      description:
                        "Field validation error. 'description' = Field label for organization role description (OrganizationRole.description)",
                    },
                    {
                      description: intl.formatMessage(
                        OrganizationRoleLabels.description
                      ),
                    }
                  )}
                </Form.Control.Feedback>
              )}
          </FormInput>
          <FormGroup>
            <Form.Label>
              {intl.formatMessage(OrganizationRoleLabels.type)}
            </Form.Label>
            <RoleTypeBadge type={role ? role.type : undefined} />
          </FormGroup>
          {roles && (
            <Form.Group>
              <Table<OrganizationRole>
                disableLoadingIndicator={!isReady}
                maxRows={5}
                compact={true}
                data-test-select-roles
                header={
                  <Form.Label>
                    {intl.formatMessage(
                      OrganizationRoleLabels.organizationRoles
                    )}
                  </Form.Label>
                }
                pagination={false}
                data={roles}
                identifyingColumn={"id"}
                search={roles.length > TABLE_SEARCH_THRESHOLD}
                activeSearch={activeRoleSearch}
                onSearch={onSetActiveRoleSearch}
                columnToggle={false}
                reset={false}
                selection={{
                  multi: true,
                  selectAll: true,
                }}
                onSelectionChanged={onSetSelectedRoles}
                selected={selectedRoles}
                columns={[
                  {
                    key: "id",
                    label: intl.formatMessage(OrganizationRoleLabels.id),
                    isTechnical: true,
                    hidden: true,
                  },
                  {
                    key: "name",
                    label: intl.formatMessage({
                      defaultMessage: "Role",
                      description: "Column heading for the roles to select",
                    }),
                    sortable: true,
                    renderer: (props: {
                      cell: any;
                      row: any;
                      rowIndex: Number;
                      rendererData: any;
                    }) => {
                      return <>{props.row.name}</>;
                    },
                  },
                ]}
              />
            </Form.Group>
          )}
          {permissions && (
            <Form.Group>
              <Table<PermissionWithGrantedActions>
                cellEdit={cellEditFactory({
                  mode: "click",
                  beforeSaveCell: (
                    oldValue: string[],
                    newValue: string[],
                    row: PermissionWithGrantedActions,
                    column: string,
                    done: (b: boolean) => void
                  ) => {
                    setTimeout(() => {
                      // Reject the change as the data is not owned by the table and should be immutable
                      done(false);
                      // Apply the change to the state
                      onUpdateActions(row, newValue);
                    }, 0);
                    return { async: true };
                  },
                })}
                disableLoadingIndicator={!isReady}
                maxRows={5}
                compact={true}
                data-test-select-permissions
                header={
                  <Form.Label>
                    {intl.formatMessage(OrganizationRoleLabels.permissions)}
                  </Form.Label>
                }
                pagination={false}
                data={permissions}
                identifyingColumn={"id"}
                search={permissions.length > TABLE_SEARCH_THRESHOLD}
                activeSearch={activePermissionsSearch}
                onSearch={onSetActivePermissionsSearch}
                columnToggle={false}
                reset={false}
                selection={{
                  multi: true,
                  selectAll: false,
                }}
                onSelectionChanged={onSetSelectedPermissions}
                selected={selectedPermissions}
                columns={[
                  {
                    key: "id",
                    label: intl.formatMessage(OrganizationRoleLabels.id),
                    isTechnical: true,
                    hidden: true,
                  },
                  {
                    key: "name",
                    label: intl.formatMessage({
                      defaultMessage: "Permission",
                      description:
                        "column heading for the permissions to select",
                    }),
                    sortable: true,
                    renderer: (props: {
                      cell: any;
                      row: any;
                      rowIndex: Number;
                      rendererData: any;
                    }) => {
                      return <>{props.row.name}</>;
                    },
                  },
                  {
                    key: "grantedActions.allowedActions",
                    label: intl.formatMessage(
                      OrganizationRoleLabels.grantedActions
                    ),
                    editor: (
                      editorProps,
                      value,
                      row,
                      column,
                      rowIndex,
                      columnIndex
                    ) => {
                      return (
                        <ApplyInput
                          size={"sm"}
                          applyLabel={intl.formatMessage({
                            defaultMessage: "Apply",
                            description: "the label of the apply button",
                          })}
                          clearDirtyInputTip={intl.formatMessage({
                            defaultMessage: "Clear unapplied input.",
                            description:
                              "the tooltip of the clear unapplied input icon button",
                          })}
                          clearInputTip={intl.formatMessage({
                            defaultMessage: "Remove actions",
                            description:
                              "the tooltip of the clear input icon button",
                          })}
                          activeValue={value ? value.join(", ") : ""}
                          value={value ? value.join(", ") : ""}
                          onApply={(v) => {
                            editorProps.onUpdate(
                              v
                                .split(/,/)
                                .map((vs) => vs.trim())
                                .filter((vst) => !!vst && vst !== "")
                            );
                          }}
                        />
                      );
                    },
                  },
                ]}
              />
            </Form.Group>
          )}
        </Form>
      )}
      {!role && isReady && (
        <Feedback type={"danger"} show={true} asChild={true}>
          <p>
            <FormattedMessage
              defaultMessage="Something went wrong and the role could not be loaded. The role may have been removed or you don't have sufficient access rights."
              description="message to be shown when there is no role to display"
            />
          </p>
        </Feedback>
      )}
    </Modal>
  );
}

export default ViewRoleModal;
