import { connect } from "react-redux";
import { AppState } from "../../../store/AppState";
import OrganizationUtils from "../../../utils/organization";
import ClientGroupsCard, {
  ClientGroupsCardProps as _ClientGroupsCardProps
} from "./client-groups-card-view";

import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../../util/typeUtil";
import {listOrganizationClientGroups} from "../../../actions";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";

export interface ClientGroupsCardProps
  extends Omit<_ClientGroupsCardProps, "groups" | "isReady"> {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_ClientGroupsCardProps, ClientGroupsCardProps>;

// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ClientGroupsCardProps,
  ClientGroupsCardProps
>;

const sender: ActionSender = { key: "clientGroupsCard" };

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    groups: OrganizationUtils.selectOrganizationClientGroups(state),
    isReady: InProgressUtils.selectNotInProgress(sender.key, state)
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    OrganizationUtils.compareOrganizationClientGroupsStates(next, prev) &&
    InProgressUtils.compareInProgressStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadGroups: addSenderArgument(sender, listOrganizationClientGroups)
};
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ClientGroupsCardProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual
})(ClientGroupsCard);
