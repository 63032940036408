import { useEffect, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {FeedbackEntry, Feedback, ConfirmModal} from "@10duke/dukeui";
import { User } from "../../../model/User";
import UserUtils from "../../../utils/user";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
import {
  AddUsersToOrgGroupAction,
  ClearErrorAction,
  GetOrgGroupAction,
  isAddErrorAction,
  RemoveUsersFromOrgGroupAction,
} from "../../../actions/actionTypes";
import Table from "../../table";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import { ModalKeys } from "../../pages/users";
import UserStatusBadge from "../../badges/user-status-badge";
import { Form } from "react-bootstrap";
import { ClosableModalProps } from "../closable-modal-props";
import NavigateAfterAction from "../../navigate-after-action";
import { UserValues, UserWithStatusValues } from "../../../localization/user";
import { OrganizationGroupLabels } from "../../../localization/organization-group";
import "./manage-group-members-modal-view.scss";

const resolveUserName = (
  user: User | undefined | null,
  intl: { formatMessage: (v: any, v2?: any) => string }
) =>
  UserUtils.resolveDisplayName(
    user,
    intl.formatMessage(UserValues.displayName.undefined)
  );

export interface ManageGroupMembersModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}

export interface ManageGroupMembersModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {
  onShowFeedback: (feedback: FeedbackEntry | FeedbackEntry[]) => void;
}
export interface ManageGroupMembersModalInputProps
  extends ManageGroupMembersModalVisibilityProps {
  groupId?: string;
}
export interface ManageGroupMembersModalStateProps {
  membersToAdd: User[];
  membersToRemove: User[];
  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;
  selected: User[];
  onSetSelected: (selection: User[]) => void;
}
export interface ModalGroupMemberAdminProps {
  adminsLoaded?: boolean;
  onLoadAdmins?: () => void;
}
export interface ManageGroupMembersModalDataProps
  extends ModalGroupMemberAdminProps,
    Pick<ClosableModalProps, "isReady"> {
  actorId: string;
  group?: OrganizationGroup | null;
  onLoadGroup?: (id: string) => Promise<GetOrgGroupAction>;

  users?: User[];
  onLoadUsers?: () => void;

  employeesGroupId?: string;

  groupMembers?: string[];
  onLoadGroupMembers?: (id: string) => void;

  onAddMembers?: (
    grpId: string,
    usrs: string[]
  ) => Promise<AddUsersToOrgGroupAction>;
  onRemoveMembers?: (
    grpId: string,
    usrs: string[]
  ) => Promise<RemoveUsersFromOrgGroupAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface ManageGroupMembersModalProps
  extends ManageGroupMembersModalDOMProps,
    ManageGroupMembersModalStateProps,
    ManageGroupMembersModalDataProps,
    ManageGroupMembersModalInputProps {}
//</editor-fold>

function ManageGroupMembersModal(props: ManageGroupMembersModalProps) {
  //<editor-fold desc="Local variables">
  let {
    activeSearch,
    onSetActiveSearch,
    group,
    isReady,
    groupId,
    actorId,
    onLoadGroup,
    users,
    onLoadUsers,
    onAddMembers,
    onRemoveMembers,
    groupMembers,
    onLoadGroupMembers,
    show,
    onClose,
    onExited,
    onShowFeedback,
    membersToAdd,
    membersToRemove,
    employeesGroupId,
    onSetSelected,
    selected,
    adminsLoaded,
    onLoadAdmins,
    onClearError,
  } = props;
  if (show && (adminsLoaded === undefined || !onLoadAdmins)) {
    // just can't figure out how to require these in the props as they should be, while keeping container, redux and all the type restrictions and linters happy.
    throw new Error(
      "ManageGroupMembersModal: Required props missing. The adminsLoaded and onLoadAdmins are required"
    );
  }
  if (show && !onAddMembers) {
    throw new Error(
      "ManageGroupMembersModal: Required props missing. The onAddMembers is required"
    );
  }
  if (show && !onRemoveMembers) {
    throw new Error(
      "ManageGroupMembersModal: Required props missing. The onRemoveMembers is required"
    );
  }
  if (show && group === undefined && (!groupId || !onLoadGroup)) {
    throw new Error(
      "ManageGroupMembersModal: Required props missing. The onLoadGroup is required with groupId"
    );
  }
  if (show && !users && !onLoadUsers) {
    throw new Error(
      "ManageGroupMembersModal: Required props missing. Either groups or onLoadUSers must be provided"
    );
  }
  if (show && !groupMembers && !onLoadGroupMembers) {
    throw new Error(
      "ManageGroupMembersModal: Required props missing. Either groupMembers or onLoadGroupMembers must be provided"
    );
  }
  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>
  useEffect(() => {
    if (show && !adminsLoaded && onLoadAdmins) {
      onLoadAdmins();
    }
  }, [show, adminsLoaded, onLoadAdmins]);

  const groupObjId = group ? group.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!onLoadGroup &&
      !!groupId &&
      (groupObjId === undefined || (!!groupObjId && groupObjId !== groupId))
    ) {
      onLoadGroup(groupId).then((res) => {
        if (!groupObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, onLoadGroup, groupId, groupObjId, onClearError]);

  useEffect(() => {
    if (show && !users && onLoadUsers) {
      onLoadUsers();
    }
  }, [show, users, onLoadUsers]);

  useEffect(() => {
    if (show && !groupMembers && group && onLoadGroupMembers) {
      onLoadGroupMembers(group.id as string);
    }
  }, [show, group, groupMembers, onLoadGroupMembers]);

  return (
    <ConfirmModal
      id={"manage-group-members-modal"}
      onExited={onExited}
      isReady={isReady}
      onReloadData={() => {
        if (onLoadUsers) {
          onLoadUsers();
        }
        if (groupId && onLoadGroupMembers) {
          onLoadGroupMembers(groupId);
        }
        if (onLoadAdmins) {
          onLoadAdmins();
        }
        if (groupId && onLoadGroup) {
          onLoadGroup(groupId).then((res) => {
            if (!group && isAddErrorAction(res)) {
              // only clear error if no data exists, leave if previous data is still available and
              // let the api error notification handler show error
              onClearError(res.error?.errorId);
            }
          });
        }
      }}
      data-test-manage-group-members-modal={group ? group.id : true}
      title={
        group
          ? intl.formatMessage(
              {
                defaultMessage: "{name}: members",
                description: "modal heading, 'name' = name of the group",
              },
              {
                name: group.name,
              }
            )
          : intl.formatMessage({
              defaultMessage: "Group not found",
              description: "modal heading when group not found",
            })
      }
      confirmTitle={
        group
          ? intl.formatMessage(
              {
                defaultMessage: "{name}: confirm members",
                description:
                  "modal confirm action heading. 'name' = name of the group",
              },
              {
                name: group.name,
              }
            )
          : undefined
      }
      confirmContent={
        <>
          <p data-test-confirm-changes-message>
            <FormattedMessage
              defaultMessage="Are you sure you wish to apply the following changes to the group memberships?"
              description={"Confirm action message"}
            />
          </p>
          {membersToRemove.length > 0 && (
            <>
              {group && employeesGroupId === group.id && (
                <Feedback type={"warning"} show={true} asChild={true}>
                  <p data-test-employees-group-removal-warning>
                    <FormattedMessage
                      defaultMessage="Please note that removing a member from the employees group may restrict their access across connected services and your ability to manage them."
                      description="warning message when removing from employees group"
                    />
                  </p>
                </Feedback>
              )}
              <strong>
                <FormattedMessage
                  defaultMessage="Members to remove"
                  description={"heading for summary of members to remove"}
                />
              </strong>
              <ul>
                {membersToRemove.map((g) => (
                  <li key={"toRemove" + (g.id as string)}>
                    {resolveUserName(g, intl)}
                  </li>
                ))}
              </ul>
            </>
          )}
          {membersToAdd.length > 0 && (
            <>
              <strong>
                <FormattedMessage
                  defaultMessage="Members to add"
                  description={"heading for summary of members to add"}
                />
              </strong>
              <ul>
                {membersToAdd.map((g) => (
                  <li key={"toAdd" + (g.id as string)}>
                    {resolveUserName(g, intl)}
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      }
      acceptButton={{
        label: intl.formatMessage({
          defaultMessage: "Yes",
          description: "confirm action button label",
        }),
      }}
      cancelButton={{
        label: intl.formatMessage({
          defaultMessage: "No",
          description: "cancel confirmation button label",
        }),
      }}
      show={show}
      onClose={onClose}
      skipConfirm={!group}
      backdrop={
        membersToAdd.length > 0 || membersToRemove.length > 0 ? "static" : true
      }
      primaryButton={{
        label: !group
          ? intl.formatMessage({
              defaultMessage: "Close",
              description: "close button label",
            })
          : intl.formatMessage({
              defaultMessage: "Apply",
              description: "primary button label",
            }),
        disabled:
          !!group && membersToAdd.length === 0 && membersToRemove.length === 0,
        tooltip:
          !!group && membersToAdd.length === 0 && membersToRemove.length === 0
            ? intl.formatMessage({
                defaultMessage: "There are no changes to apply.",
                description: "tooltip for disabled primary button",
              })
            : undefined,
      }}
      onPrimaryAction={() => {
        if (show && group) {
          if (onAddMembers && onRemoveMembers) {
            const actions = [];
            if (membersToAdd.length) {
              actions.push(
                onAddMembers(
                  group.id as string,
                  membersToAdd.map((val) => val.id as string)
                ).catch((error) => error)
              );
            }
            if (membersToRemove.length) {
              actions.push(
                onRemoveMembers(
                  group.id as string,
                  membersToRemove.map((val) => val.id as string)
                ).catch((error) => error)
              );
            }
            if (actions.length) {
              // should aways come here
              Promise.all(actions).then(
                (res) => {
                  const feedback: FeedbackEntry[] = [];
                  let addResult;
                  let removeResult;
                  if (membersToAdd.length) {
                    addResult = res[0];
                    if (membersToRemove.length) {
                      removeResult = res[1];
                    }
                  } else if (membersToRemove.length) {
                    removeResult = res[0];
                  } else {
                    throw Error("This should never happen");
                  }
                  if (addResult && addResult.error) {
                    onClearError(addResult.error?.errorId);
                    feedback.push({
                      id: "manageGroupMembers_add",
                      msg: intl.formatMessage(
                        {
                          defaultMessage: "{name}: Adding members failed.",
                          description:
                            "failure notification. 'name' = name of the group",
                        },
                        {
                          name:
                            "<strong>" +
                            (group ? group.name : "impossible") +
                            "</strong>",
                        }
                      ),
                      type: "danger",
                    });
                  } else if (addResult) {
                    feedback.push({
                      id: "manageGroupMembers_add",
                      msg: intl.formatMessage(
                        {
                          defaultMessage:
                            "{name}: {count} {count, plural, one {member} other {members}} added.",
                          description:
                            "Success notification. 'name' = name of he group, 'count' = the amount of members added",
                        },
                        {
                          name:
                            "<strong>" +
                            (group ? group.name : "impossible") +
                            "</strong>",
                          count: membersToAdd.length,
                        }
                      ),
                      autoClose: true,
                      type: "success",
                    });
                  }
                  if (removeResult && removeResult.error) {
                    onClearError(removeResult.error?.errorId);
                    feedback.push({
                      id: "manageGroupMembers_remove",
                      msg: intl.formatMessage(
                        {
                          defaultMessage: "{name}: Removing members failed.",
                          description:
                            "failure notification. 'name' = name of the group",
                        },
                        {
                          name:
                            "<strong>" +
                            (group ? group.name : "impossible") +
                            "</strong>",
                        }
                      ),
                      type: "danger",
                    });
                  } else if (removeResult) {
                    feedback.push({
                      id: "manageGroupMembers_remove",
                      msg: intl.formatMessage(
                        {
                          defaultMessage:
                            "{name}: {count} {count, plural, one {member} other {members}} removed.",
                          description:
                            "Success notification. 'name' = name of the group, 'count' = the amount of members removed",
                        },
                        {
                          name:
                            "<strong>" +
                            (group ? group.name : "impossible") +
                            "</strong>",
                          count: membersToRemove.length,
                        }
                      ),
                      autoClose: true,
                      type: "success",
                    });
                  }
                  if (feedback.length) {
                    onShowFeedback(feedback);
                  }
                },
                (rej) => {
                  throw new Error("Should not happen");
                }
              );
            }
          }
        }
        onClose();
      }}
      secondaryButton={
        group
          ? {
              label: intl.formatMessage({
                defaultMessage: "Cancel",
                description: "secondary button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={onClose}
    >
      {group && (
        <Form.Group className={"form-table"}>
          <Table<User>
            disableLoadingIndicator={!isReady}
            maxRows={15}
            header={
              <Form.Label>
                <FormattedMessage
                  defaultMessage="Select group members"
                  description={"field label"}
                />
              </Form.Label>
            }
            compact={true}
            data-test-select-members
            search={users && users.length > TABLE_SEARCH_THRESHOLD}
            activeSearch={activeSearch}
            onSearch={onSetActiveSearch}
            columnToggle={false}
            reset={false}
            data={users && group ? users : isReady ? [] : undefined}
            pagination={false}
            identifyingColumn={"id"}
            selection={{
              multi: true,
              selectAll: true,
              disabledFor:
                employeesGroupId === (group ? (group.id as string) : false) &&
                groupMembers &&
                groupMembers.includes(actorId as string)
                  ? [actorId]
                  : [],
            }}
            onSelectionChanged={onSetSelected}
            selected={selected}
            columns={[
              {
                key: "id",
                label: intl.formatMessage(OrganizationGroupLabels.id),
                isTechnical: true,
                hidden: true,
              },
              {
                key: "name",
                isDummy: true,
                label: intl.formatMessage({
                  defaultMessage: "Member",
                  description: "column heading for the members to select",
                }),
                sortable: true,
                renderer: (props: {
                  cell: any;
                  row: any;
                  rowIndex: Number;
                  rendererData: any;
                }) => {
                  const t = props.rendererData.resolveValue(props.row);
                  return (
                    <>
                      <span
                        key={"link_" + props.row.id}
                        className={"link-holder"}
                      >
                        <NavigateAfterAction
                          href={"/users/" + props.row.id + "/" + ModalKeys.show}
                          action={onClose}
                        >
                          <strong>{t[0]}</strong>
                          {t[1] && <small>{" <" + t[1] + ">"}</small>}
                        </NavigateAfterAction>
                      </span>
                      <UserStatusBadge
                        status={UserUtils.resolveUserStatus(props.row)}
                      />
                    </>
                  );
                },
                tipRenderer: (props: {
                  cell: any;
                  row: any;
                  rowIndex: Number;
                  rendererData: any;
                }) => (
                  <>
                    {actorId ===
                      (props.row ? (props.row.id as string) : false) &&
                    employeesGroupId ===
                      (group ? (group.id as string) : false) &&
                    groupMembers &&
                    groupMembers.includes(props.row.id as string) ? (
                      intl.formatMessage({
                        defaultMessage:
                          "You're not allowed to remove yourself from the employees group.",
                        description: "tooltip for disabled self removal",
                      })
                    ) : (
                      <FormattedMessage
                        defaultMessage="Click to select or deselect."
                        description="tooltip for toggling member selection"
                      />
                    )}
                  </>
                ),
                rendererData: {
                  resolveValue: (itm: any) => {
                    const status = UserUtils.resolveUserStatus(itm);
                    return [
                      resolveUserName(itm, intl),
                      itm.email,
                      status
                        ? intl.formatMessage(
                            (UserWithStatusValues.status as any)[status]
                          )
                        : status,
                    ];
                  },
                },
              },
            ]}
          />
        </Form.Group>
      )}
      {isReady && !group && (
        <Feedback type={"danger"} show={true} asChild={true}>
          <p>
            <FormattedMessage
              defaultMessage="Something went wrong and the group could not be loaded. The group may have been removed or you don't have sufficient access rights."
              description="message to be shown when there is no group to display"
            />
          </p>
        </Feedback>
      )}
    </ConfirmModal>
  );
}

export default ManageGroupMembersModal;
