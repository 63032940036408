import { withCloseAfterExited } from "@10duke/dukeui";
import {
  default as View,
  ResendDeviceClientInvitationModalProps
} from "./resend-device-client-invitation-modal-view";

const ResendDeviceClientInvitationModal = withCloseAfterExited<ResendDeviceClientInvitationModalProps>(
  View
);
export default ResendDeviceClientInvitationModal;
