import {TooltipWrapper} from "@10duke/dukeui";
import SortCaret from "./sort-caret/sort-caret-view";
import HeaderCell, { HeaderCellProps } from "./header-cell/header-cell-view";
import {
  TableColumn,
  TableSelection,
  TableSort,
  TableSortProps,
  TableStateProps,
} from "./table-view";
import SelectCell from "./select-cell/select-cell-view";
import SelectHeaderCell from "./select-header-cell/select-header-cell-view";

/**
 * Converts sorting props to react-bootstrap-table2 format
 * @param sort
 */
export function createRBT2Sort(
  onSort: (d: any, asc: boolean) => void,
  sort?: { column: string; ascending: boolean }
) {
  return {
    dataField: sort ? sort.column : undefined,
    order: sort ? (sort.ascending ? "asc" : "desc") : undefined,
    sortCaret: (order: "asc" | "desc" | undefined, column: any) => {
      return (
        <SortCaret onSort={onSort} field={column.dataField} order={order} />
      );
    },
  } as {
    dataField: string | undefined;
    order: "asc" | "desc" | undefined;
    sortCaret: (order: "asc" | "desc" | undefined, column: any) => JSX.Element;
  };
}

interface RBT2ColumnProps
  extends Pick<TableStateProps, "onSort" | "moveColumn" | "findColumn">,
    Pick<HeaderCellProps, "allowDrag" | "tableHolderElement">,
    TableSortProps {
  index: number;
  stickyTop?: number;
}
function headerAndCellClasses(col: TableColumn, sort: TableSort | undefined) {
  const retVal = [];
  if (col.className) {
    retVal.push(col.className);
  }
  if (col.sortable === true && sort && sort.column === col.key) {
    retVal.push("active-sort");
  }
  return retVal.length ? retVal.join(" ") : undefined;
}
/**
 * Converts column props to react-bootstrap-table2 format
 * @param col
 * @param props
 */
export function asRBT2Column(col: TableColumn, props: RBT2ColumnProps): any {
  const classes = headerAndCellClasses(col, props.sort);
  const retVal: any = {
    dataField: col.key,
    align: col.align,
    text: col.label,
    sort: col.sortable === true,
    onSort: (field: any, order: any) => {
      props.onSort(field, order === "asc");
    },
    hidden: col.hidden || col.isTechnical,
    editorRenderer: col.editor,
    editable: !!col.editor,
    isDummyField: col.isDummy === true,
    classes: (cell: any, row: any, rowIndex: any, colIndex: any) => classes,
    headerClasses: (column: any, colIndex: any) => classes,
    formatter: (cell: any, row: any, rowIndex: number, rendererData: any) => {
      // values must be converted to strings here.
      const tipRenderer = col.tipRenderer || col.renderer;
      return (
        <TooltipWrapper
          delay={{ show: 900, hide: 100 }}
          tip={
            tipRenderer
              ? tipRenderer({ cell, row, rowIndex, rendererData })
              : "" +
                (cell as string) /* <- does not make much sense but some how the string concat is required, or the tip will fail to render properly*/
          }
          tipKey={rowIndex + "_" + col.key}
        >
          <div className={"duke-cell"}>
            {col.renderer
              ? col.renderer({ cell, row, rowIndex, rendererData })
              : "" + (cell !== undefined ? (cell as string) : "")}
          </div>
        </TooltipWrapper>
      );
    },
    formatExtraData: col.rendererData ? col.rendererData : undefined,
    headerFormatter: (column: any, columnIndex: number, components: any) => {
      return (
        <HeaderCell
          index={props.index}
          tip={col.tooltip !== undefined ? col.tooltip : column.text}
          tipClass={col.tooltipClass}
          column={col}
          moveColumn={props.moveColumn}
          findColumn={props.findColumn}
          onClick={col.onHeaderClick}
          allowDrag={props.allowDrag}
          tableHolderElement={props.tableHolderElement}
        >
          {components && components.sortElement && (
            <div className={"duke-column-tools"}>{components.sortElement}</div>
          )}
          <div
            className={
              "duke-cell" + (col.onHeaderClick ? " has-click-handler" : "")
            }
          >
            {col.header
              ? typeof col.header === "function"
                ? col.header()
                : col.header
              : column.text}
          </div>
        </HeaderCell>
      );
    },
  };
  if (col.sortable && col.rendererData && col.rendererData.resolveValue) {
    retVal.sortValue = (cell: any, row: any) => {
      return col.rendererData.resolveValue(row, col.rendererData);
    };
  }
  if (props.stickyTop !== undefined) {
    retVal.headerStyle = { top: props.stickyTop + "px" };
  }
  return retVal;
}

export function asRTB2Selection<D>(
  selection: TableSelection | undefined,
  selected: D[] | undefined,
  onSelectionChanged: ((selection: D[]) => void) | undefined,
  data: D[] | undefined,
  identifyingColumn: string,
  customSelectParams: {
    tooltips: {
      selectAll: string | undefined;
      deselectAll: string | undefined;
    };
    isSelect: boolean;
    selectedInView: D[];
    selectableInView: D[];
    deselectableInView: D[];
  },
  disableClickToSelect?: boolean
) {
  let retVal;
  if (selection) {
    retVal = {
      mode: selection.selectColumnType
        ? selection.selectColumnType
        : selection.multi
        ? "checkbox"
        : "radio",
      selected: selected
        ? selected.map((itm) => (itm as any)[identifyingColumn])
        : [],
      onSelect: (row: D, isSelect: boolean, rowIndex: number, e: any) => {
        if (selection && onSelectionChanged) {
          let newSel: D[] = [];
          if (selected && selection.multi) {
            newSel = newSel.concat(selected);
          }
          if (isSelect) {
            newSel = newSel.concat(row);
          } else if (selection.multi) {
            newSel = newSel.filter(
              (itm) =>
                (itm as any)[identifyingColumn] !==
                (row as any)[identifyingColumn]
            );
          }
          onSelectionChanged(newSel);
        }
      },
      onSelectAll: (isSel: boolean, r: D[], e: any) => {
        // the table's internal logic does not really work with our infinite scrolling, search and external state
        // so all the arguments are ignored and our own are used.
        // The built in "hideSelectAll" can't be used as it disables all styles for the empty header cell, so the
        // select all is always rendered and triggered on click, but rendered as empty and the click ignored
        let newSelection = [...(selected || [])];
        if (
          !selection.fixed &&
          selection.selectAll !== false &&
          onSelectionChanged
        ) {
          /**
           * Utility for matching the selectable/selected items
           * @param s item to match against
           * @return matcher function to use with Array filter/find/findIndex
           */
          const matchItems = (s: D) => (ds: D) =>
            ((ds as any)[identifyingColumn as string] as string) ===
            ((s as any)[identifyingColumn as string] as string);

          if (customSelectParams.isSelect) {
            const toSelect = (selected || []).concat(
              customSelectParams.selectableInView
            );
            const noDuplicates = toSelect.filter(
              (s, i) => toSelect.findIndex(matchItems(s)) === i
            );
            newSelection = noDuplicates;
          } else {
            newSelection = (selected || []).filter(
              (s) => !customSelectParams.deselectableInView.find(matchItems(s))
            );
          }
          onSelectionChanged(newSelection);
        }
        return newSelection.map(
          (d) => (d as any)[identifyingColumn as string] as string
        );
      },
      hideSelectAll: false,
      hideSelectColumn:
        !selection.selectColumnType && (selection.fixed || !selection.multi),
      clickToSelect:
        disableClickToSelect !== undefined
          ? !disableClickToSelect
          : !selection.fixed,
      clickToEdit: true,
      classes: "table-active",
      nonSelectable: selection.disabledFor,
      nonSelectableClasses: "row-disabled",
      selectionRenderer: SelectCell,
      selectionHeaderRenderer: (p: any) => {
        // Replace the indeterminate and checked with our own logic that takes into account disabled items and
        // infinite scrolling
        if (selection.fixed || !selection.selectAll) {
          return <div className={"duke-header-cell"}> </div>;
        } else {
          const { indeterminate, checked, ...others } = p;
          const partialSelection =
            customSelectParams.selectedInView.length !== data?.length &&
            customSelectParams.selectedInView.length !== 0;
          return (
            <SelectHeaderCell
              tooltips={customSelectParams.tooltips}
              isSelect={customSelectParams.isSelect}
              indeterminate={partialSelection}
              checked={customSelectParams.selectedInView.length > 0}
              {...others}
            />
          );
        }
      },
    };
  }
  return retVal;
}
