import { Entitlement } from "../../../model/entitlement/Entitlement";

export const ORG_1_ENTITLEMENT_1: Entitlement = {
  id: "57797e0e-aa30-480f-b1d1-7a522afd4148",
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T07:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-06-11T07:01:00.000Z",
  validFrom: "2020-06-11T07:00:00.000Z",
  validUntil: "2120-06-11T07:00:00.000Z",
  isDefault: false,
  name: "org1ent1",
  description: "org 1 entitlement 1",
  type: "mock"
};

export const ORG_1_ENTITLEMENT_2: Entitlement = {
  id: "68e53df8-7db7-456a-8a1b-846175904d2e",
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T07:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-06-11T07:01:00.000Z",
  validFrom: "2020-06-11T07:00:00.000Z",
  validUntil: "2120-06-11T07:00:00.000Z",
  name: "org1ent2",
  description: "org 1 entitlement 2",
  isDefault: false,
  type: "mock"
};

export const ORG_1_ENTITLEMENTS: Entitlement[] = [
  ORG_1_ENTITLEMENT_1,
  ORG_1_ENTITLEMENT_2
];

export const ORG_1_GROUP_IDS_BY_ENTITLEMENT_ID = {
  [ORG_1_ENTITLEMENT_1.id as string]: [] as string[],
  [ORG_1_ENTITLEMENT_2.id as string]: [] as string[]
};
