import * as ActionTypes from "../actions/actionTypes";
import { LicenseIdsByOrgId } from "../store/LicenseState";
import { ID_ANY } from "../api/EntApi";

export default function orgLicenseIds(
  state: LicenseIdsByOrgId,
  action: ActionTypes.AppAction
): LicenseIdsByOrgId | null {
  const currentState = state || ({} as LicenseIdsByOrgId);
  switch (action.type) {
    case ActionTypes.LIST_ENT_LICENSES:
    case ActionTypes.LIST_ENT_LICENSES_WITH_USAGE:
      const listEntLicenses = action as ActionTypes.ListEntLicensesAction;
      const stateAfterListEntLicenses = { ...currentState };
      const existingLicenseIds =
        stateAfterListEntLicenses[listEntLicenses.orgId];
      const newLicenseIds = listEntLicenses.licenses.map(
        lic => lic.id as string
      );
      if (
        ID_ANY === listEntLicenses.entId ||
        existingLicenseIds === undefined ||
        existingLicenseIds.length === 0
      ) {
        stateAfterListEntLicenses[listEntLicenses.orgId] = newLicenseIds;
      } else {
        const licenseIdsAfterListEntLicenses = new Set<string>([
          ...existingLicenseIds,
          ...newLicenseIds
        ]);
        stateAfterListEntLicenses[listEntLicenses.orgId] = Array.from(
          licenseIdsAfterListEntLicenses.values()
        );
      }
      return stateAfterListEntLicenses;
    case ActionTypes.QUERY_AVAILABLE_LICENSES:
    case ActionTypes.QUERY_CLIENT_AVAILABLE_LICENSES: {
      const queryAvailableLicenses = action as ActionTypes.QueryAvailableLicensesAction;
      const result = {...currentState};
      queryAvailableLicenses.licenses
          .filter(license => {
            return license.owner && license.owner.objectType === "Organization";
          })
          .forEach(licenseOwnedByOrg => {
            const licenseId = licenseOwnedByOrg.id;
            const orgId = licenseOwnedByOrg.owner?.id;
            if (licenseId && orgId) {
              if (result[orgId]) {
                result[orgId] = [...result[orgId]];
              } else {
                result[orgId] = [];
              }

              if (!result[orgId].includes(licenseId)) {
                result[orgId].push(licenseId);
              }
            }
          });
      return result;
    }
    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

function deleteLicense(
  licenseId: string,
  orgId: string,
  currentState: LicenseIdsByOrgId
): LicenseIdsByOrgId {
  const beforeDelete = currentState[orgId] || [];
  const afterDelete = beforeDelete.filter(
    licenseIdFromList => licenseIdFromList !== licenseId
  );
  return {
    ...currentState,
    [orgId]: afterDelete
  };
}

function handleErrorAction(
  currentState: LicenseIdsByOrgId,
  action: ActionTypes.AppAction
): LicenseIdsByOrgId {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  const type = errorAction.error.action.type;

  if (
    type === ActionTypes.QUERY_LICENSE_USAGE ||
    type === ActionTypes.GET_USER_LICENSE_ASSIGNMENTS
  ) {
    if (errorAction.error.apiError.error === "404") {
      const typedError = action as ActionTypes.AddErrorAction<
        ActionTypes.QueryLicenseUsageAction
      >;
      finalState = deleteLicense(
        typedError.error.action?.licenseId as string,
        typedError.error.action?.orgId as string,
        currentState
      );
    }
  }

  return finalState;
}
