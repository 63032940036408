import * as ActionTypes from "../actions/actionTypes";
import { UserOrgRoleIdsByUserId } from "../store/UserState";
import {
  removeFromStateById,
  removeUsersFromState,
} from "../util/ReducerUtils";

export default function userOrgRoleIds(
  state: UserOrgRoleIdsByUserId,
  action: ActionTypes.AppAction
): UserOrgRoleIdsByUserId | null {
  const currentState = state || ({} as UserOrgRoleIdsByUserId);
  switch (action.type) {
    case ActionTypes.LIST_ORG_ROLES_OF_USER:
      const listOrgRolesOfUser = action as ActionTypes.ListOrgRolesOfUserAction;
      const orgRoleIds = listOrgRolesOfUser.roles.map(
        (role) => role.id as string
      );
      return { ...currentState, [listOrgRolesOfUser.userId]: orgRoleIds };
    case ActionTypes.ADD_ORG_ROLES_FOR_USER: {
      const addOrgRolesForUser = action as ActionTypes.AddOrgRolesForUserAction;
      const beforeAddRoles = (
        currentState[addOrgRolesForUser.userId] || []
      ).filter((roleId) => !addOrgRolesForUser.orgRoleIds.includes(roleId));
      const afterAddRoles = [
        ...beforeAddRoles,
        ...addOrgRolesForUser.orgRoleIds,
      ];
      return { ...currentState, [addOrgRolesForUser.userId]: afterAddRoles };
    }
    /*
    case ActionTypes.SET_ORG_ROLES_OF_USER: {
      const setOrgRolesForUser = action as ActionTypes.SetOrgRolesOfUserAction;
      return {
        ...currentState,
        [setOrgRolesForUser.userId]: [...setOrgRolesForUser.orgRoleIds]
      };
    }
     */
    case ActionTypes.IMPORT_ORGANIZATION_USERS: {
      const importOrganizationUsersAction =
        action as ActionTypes.ImportOrganizationUsersAction;
      const nextState = {
        ...currentState,
      };

      (importOrganizationUsersAction.users || []).forEach((user) => {
        nextState[user.id as string] = [...(user.organizationRoleIds || [])];
      });

      return nextState;
    }
    case ActionTypes.REMOVE_ORG_ROLES_OF_USER: {
      const removeOrgRolesOfUser =
        action as ActionTypes.RemoveOrgRolesOfUserAction;
      const afterRemove = (
        currentState[removeOrgRolesOfUser.userId] || []
      ).filter((roleId) => !removeOrgRolesOfUser.orgRoleIds.includes(roleId));
      return { ...currentState, [removeOrgRolesOfUser.userId]: afterRemove };
    }
    case ActionTypes.ADD_USERS_TO_ORG_ROLE:
      const addUsersToOrgRole = action as ActionTypes.AddUsersToOrgRoleAction;
      let afterAddUsersToOrgRole = currentState;
      for (const userIdToAdd of addUsersToOrgRole.userIds) {
        const beforeAddUsers = afterAddUsersToOrgRole[userIdToAdd] || [];
        const afterAddUsers = [...beforeAddUsers, addUsersToOrgRole.orgRoleId];
        afterAddUsersToOrgRole = {
          ...afterAddUsersToOrgRole,
          [userIdToAdd]: afterAddUsers,
        };
      }
      return afterAddUsersToOrgRole;
    case ActionTypes.REMOVE_USERS_FROM_ORG_ROLE:
      const removeUsersFromOrgRole =
        action as ActionTypes.RemoveUsersFromOrgRoleAction;
      let afterRemoveUsersFromOrgRole = currentState;
      for (const userIdToRemove of removeUsersFromOrgRole.userIds) {
        const beforeRemoveUsers =
          afterRemoveUsersFromOrgRole[userIdToRemove] || [];
        const afterRemoveUsers = beforeRemoveUsers.filter(
          (orgRoleId) => orgRoleId !== removeUsersFromOrgRole.orgRoleId
        );
        afterRemoveUsersFromOrgRole = {
          ...afterRemoveUsersFromOrgRole,
          [userIdToRemove]: afterRemoveUsers,
        };
      }
      return afterRemoveUsersFromOrgRole;
    case ActionTypes.ADD_ORG_ROLE_FOR_USER:
      const addOrgRoleForUser = action as ActionTypes.AddOrgRoleForUserAction;
      const beforeAddRole = currentState[addOrgRoleForUser.userId] || [];
      const afterAddRole = [...beforeAddRole, addOrgRoleForUser.orgRoleId];
      return { ...currentState, [addOrgRoleForUser.userId]: afterAddRole };
    /*
    case ActionTypes.REMOVE_ORG_ROLE_OF_USER:
      const removeOrgRoleOfUser = action as ActionTypes.RemoveOrgRoleOfUserAction;
      const beforeRemoveRole = currentState[removeOrgRoleOfUser.userId] || [];
      const afterRemoveRole = beforeRemoveRole.filter(
        orgRoleId => removeOrgRoleOfUser.orgRoleId !== orgRoleId
      );
      return {
        ...currentState,
        [removeOrgRoleOfUser.userId]: afterRemoveRole
      };
       */
    case ActionTypes.DELETE_USER:
    case ActionTypes.REMOVE_USER_FROM_ORG:
      return removeUsersFromState<UserOrgRoleIdsByUserId>(action, currentState);

    case ActionTypes.DELETE_ORG_ROLE: {
      const deleteOrgRole = action as ActionTypes.DeleteOrgRoleAction;
      const stateAfterModification: UserOrgRoleIdsByUserId | null = currentState
        ? {}
        : null;
      if (stateAfterModification) {
        for (const key in currentState) {
          let usersRoleIds = currentState[key] || [];
          usersRoleIds = usersRoleIds.filter(
            (roleId) => roleId !== deleteOrgRole.orgRoleId
          );
          stateAfterModification[key] = usersRoleIds;
        }
      }
      return stateAfterModification;
    }
    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

function handleErrorAction(
  currentState: UserOrgRoleIdsByUserId,
  action: ActionTypes.AppAction
): UserOrgRoleIdsByUserId {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  if (
    errorAction.error.action.type === ActionTypes.GET_USER &&
    errorAction.error.apiError.error === "404"
  ) {
    const typedError =
      action as ActionTypes.AddErrorAction<ActionTypes.GetUserAction>;
    finalState = removeFromStateById<UserOrgRoleIdsByUserId>(
      [typedError.error.action?.userId as string],
      currentState
    );
  }

  return finalState;
}
