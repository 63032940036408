import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {
  buildActionThunk,
  ensureSelectedOrgId,
  forceUndefined,
} from "./actionHelpers";
import { OrganizationRole } from "../model/OrganizationRole";

/**
 * Gets all organization roles given for an organization role.
 * @param sender Component requesting for the action
 * @param orgRoleId The organization role id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function listOrganizationRolesInOrganizationRole(
  sender: ActionSender,
  orgRoleId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListOrganizationRolesOfOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.ListOrganizationRolesOfOrgRoleAction,
    OrganizationRole[]
  >(
    sender,
    ActionTypes.LIST_ORG_ROLES_OF_ORG_ROLE,
    async () =>
      await idpApi.listOrganizationRolesInOrganizationRole(
        orgIdOrDefault,
        orgRoleId
      ),
    (type, roles) => ({
      type,
      orgRoleId,
      roles: forceUndefined(roles),
      orgId: orgIdOrDefault,
    })
  );
}
/**
 * Replaces all current organization roles of an organization role with the given organization roles.
 * @param sender Component requesting for the action
 * @param roles Array of OrganizationRoles describing the organization roles
 *   to set.
 * @param orgRoleId The organization role id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function setOrganizationRolesOfOrganizationRole(
  sender: ActionSender,
  roles: OrganizationRole[],
  orgRoleId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.SetOrganizationRolesOfOrgRoleAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.SetOrganizationRolesOfOrgRoleAction,
    void
  >(
    sender,
    ActionTypes.SET_ORG_ROLES_OF_ORG_ROLE,
    async () =>
      await idpApi.setOrganizationRolesInOrganizationRole(
        orgIdOrDefault,
        orgRoleId,
        roles.map((r) => r.id as string)
      ),
    (type) => ({
      type,
      roles,
      orgRoleId,
      orgId: orgIdOrDefault,
    })
  );
}
