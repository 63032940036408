import { useEffect, useMemo, HTMLAttributes } from "react";
import { useIntl } from "react-intl";
import { ClosableModalProps } from "../closable-modal-props";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { OrganizationRole } from "../../../model/OrganizationRole";
import {
  AddErrorAction,
  ClearErrorAction,
  CreateOrgRoleAction,
  isAddErrorAction,
  SetOrganizationRolesOfOrgRoleAction,
  SetPermissionsOfOrgRoleAction,
} from "../../../actions/actionTypes";
import Table from "../../table/table-container";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrganizationRoleLabels } from "../../../localization/organization-role";
import { PermissionGrantsForPermission } from "../../../model/PermissionGrantsForPermission";
import { PermissionWithGrantedActions } from "../../../model/PermissionWithGrantedActions";
import cellEditFactory from "react-bootstrap-table2-editor";
import {IconLibrary,FormInput,FormInputUtils,FeedbackEntry,ApplyInput, Modal} from "@10duke/dukeui";

//<editor-fold desc="Utils">

//</editor-fold>

//<editor-fold desc="Props">

export interface CreateOrganizationRoleModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}

export interface CreateOrganizationRoleModalStateProps {
  rolePermissions: PermissionWithGrantedActions[];
  activePermissionsSearch?: string;
  onSetActivePermissionsSearch: (s: string) => void;
  selectedPermissions: PermissionWithGrantedActions[];
  onSetSelectedPermissions: (selection: PermissionWithGrantedActions[]) => void;

  includedRoles: OrganizationRole[];
  selectedRoles: OrganizationRole[];
  onSetSelectedRoles: (selection: OrganizationRole[]) => void;
  activeRoleSearch?: string;
  onSetActiveRoleSearch: (s: string) => void;
  onUpdateActions: (obj: PermissionWithGrantedActions, value: string[]) => void;
}
export interface CreateOrganizationRoleModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {
  onShowFeedback: (feedback: FeedbackEntry | FeedbackEntry[]) => void;
}
export interface CreateOrganizationRoleModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  onCreateOrganizationRole: (
    role: OrganizationRole
  ) => Promise<CreateOrgRoleAction>;
  roles?: OrganizationRole[];
  onLoadRoles?: (orgId: string) => void;
  permissions?: PermissionWithGrantedActions[];
  onLoadPermissions?: (id: string) => void;
  onSetRolePermissions: (
    permissionGrants: PermissionGrantsForPermission[],
    roleId: string,
    orgId: string
  ) => Promise<SetPermissionsOfOrgRoleAction>;
  organizationId?: string;
  onSetIncludedRoles: (
    roles: OrganizationRole[],
    roleId: string,
    orgId: string
  ) => Promise<SetOrganizationRolesOfOrgRoleAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}

export interface CreateOrganizationRoleModalProps // CreateOrganizationGroupModalDOMProps,
  extends CreateOrganizationRoleModalVisibilityProps,
    CreateOrganizationRoleModalStateProps,
    CreateOrganizationRoleModalDataProps {}
//</editor-fold>

function CreateOrganizationRoleModal(props: CreateOrganizationRoleModalProps) {
  //<editor-fold desc="Local variables">
  let {
    onCreateOrganizationRole,
    show,
    onClose,
    onShowFeedback,
    permissions,
    onLoadPermissions,
    organizationId,
    rolePermissions,
    onSetRolePermissions,
    onSetActivePermissionsSearch,
    activePermissionsSearch,
    selectedPermissions,
    onSetSelectedPermissions,
    isReady,
    onExited,
    onLoadRoles,
    roles,
    includedRoles,
    onSetIncludedRoles,
    selectedRoles,
    onSetSelectedRoles,
    activeRoleSearch,
    onSetActiveRoleSearch,
    onUpdateActions,
    onClearError,
  } = props;
  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>
  if (show && permissions === undefined && !onLoadPermissions) {
    throw new Error(
      "CreateOrganizationRoleModal: Required props missing. Either permissions or onLoadPermissions must be provided"
    );
  }
  if (show && roles === undefined && !onLoadRoles) {
    throw new Error(
      "CreateOrganizationRoleModal: Required props missing. Either roles or onLoadRoles must be provided"
    );
  }
  //<editor-fold desc="Hooks">
  const defaultValues = useMemo(
    () => ({
      name: "",
      description: "",
    }),
    []
  );
  const { register, handleSubmit, formState, watch, reset } = useForm({
    mode: "onTouched",
    defaultValues: defaultValues,
  });

  const { errors } = formState;

  const formValues = watch();
  const formIsDirty = formState.isDirty;
  const formIsValid = formState.isValid;
  useEffect(() => {
    reset(defaultValues);
  }, [show, defaultValues, reset]);
  useEffect(() => {
    if (
      show &&
      organizationId &&
      onLoadPermissions &&
      permissions === undefined
    ) {
      onLoadPermissions(organizationId);
    }
  }, [organizationId, permissions, onLoadPermissions, show]);
  useEffect(() => {
    if (show && organizationId && onLoadRoles && roles === undefined) {
      onLoadRoles(organizationId);
    }
  }, [organizationId, roles, onLoadRoles, show]);
  //</editor-fold>

  const onSubmitOrganizationRole = (d: any) => {
    let data: any = { ...d };
    return onCreateOrganizationRole(data as OrganizationRole);
  };
  return (
    <Modal
      onExited={onExited}
      isReady={isReady}
      onReloadData={() => {
        if (onLoadPermissions && organizationId) {
          onLoadPermissions(organizationId);
        }
        if (onLoadRoles && organizationId) {
          onLoadRoles(organizationId);
        }
      }}
      data-test-create-organization-role-modal
      title={intl.formatMessage({
        defaultMessage: "Create Role",
        description: "modal heading",
      })}
      show={show}
      backdrop={
        formState.isDirty || rolePermissions.length || includedRoles.length
          ? "static"
          : true
      }
      onClose={onClose}
      primaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Create",
          description: "primary button label",
        }),
        disabled:
          (errors !== undefined &&
            errors !== null &&
            Object.keys(errors).length > 0) ||
          !formIsDirty ||
          !formIsValid,
        tooltip:
          errors !== undefined &&
          errors !== null &&
          Object.keys(errors).length > 0
            ? intl.formatMessage({
                defaultMessage: "Please correct errors before creating role.",
                description:
                  "tooltip for disabled primary button when there are validation errors",
              })
            : !formState.isDirty
            ? intl.formatMessage({
                defaultMessage: "Please fill in the fields first.",
                description:
                  "tooltip for disabled primary button when there is nothing to create",
              })
            : "",
      }}
      onPrimaryAction={() => {
        handleSubmit((data: any) => {
          onSubmitOrganizationRole(data).then((res) => {
            if (!isAddErrorAction(res)) {
              const feedback: FeedbackEntry[] = [
                {
                  id: "create_organization_role",
                  msg: intl.formatMessage(
                    {
                      defaultMessage: "Organization role {name} created.",
                      description:
                        "success notification for create role. 'name' = name of the created role",
                    },
                    {
                      name: "<strong>" + res.role.name + "</strong>",
                    }
                  ),
                  autoClose: true,
                  type: "success",
                },
              ];
              const promiseWrapper: Promise<any>[] = [];
              if (!!rolePermissions && rolePermissions.length > 0) {
                promiseWrapper.push(
                  onSetRolePermissions(
                    rolePermissions.map((r) => {
                      return {
                        permissionId: r.id as string,
                        grantedActions: r.grantedActions?.allowedActions,
                      } as PermissionGrantsForPermission;
                    }),
                    res.role.id as string,
                    organizationId as string
                  )
                );
              }

              if (!!includedRoles && includedRoles.length > 0) {
                promiseWrapper.push(
                  onSetIncludedRoles(
                    includedRoles,
                    res.role.id as string,
                    organizationId as string
                  )
                );
              }
              if (promiseWrapper.length) {
                Promise.all(promiseWrapper).then((results) => {
                  const permRes =
                    !!rolePermissions && rolePermissions.length > 0
                      ? results[0]
                      : undefined;
                  const roleRes =
                    !!includedRoles &&
                    includedRoles.length > 0 &&
                    !!rolePermissions &&
                    rolePermissions.length > 0
                      ? results[1]
                      : !!includedRoles && includedRoles.length > 0
                      ? results[0]
                      : undefined;

                  if (permRes) {
                    const rres = permRes;
                    if (!isAddErrorAction(rres)) {
                      feedback.push({
                        id: "create_organization_role_permissions",
                        msg: intl.formatMessage(
                          {
                            defaultMessage:
                              "Included permissions updated for {name}.",
                            description:
                              "success notification for adding permissions to created role. 'name' = name of the created role",
                          },
                          {
                            name: "<strong>" + res.role.name + "</strong>",
                          }
                        ),
                        autoClose: true,
                        type: "success",
                      });
                    } else {
                      onClearError(
                        (res as any as AddErrorAction<any>).error?.errorId
                      );
                      feedback.push({
                        id:
                          "create_organization_role_permissions" +
                          (res.role ? (res.role.id as string) : "not_possible"),
                        msg: intl.formatMessage(
                          {
                            defaultMessage:
                              "Updating included permissions for {name} failed.",
                            description:
                              "failure notification for creating role. 'name' = name of the failed role",
                          },
                          {
                            name: "<strong>" + res.role.name + "</strong>",
                          }
                        ),
                        type: "danger",
                      });
                    }
                  }
                  if (roleRes) {
                    const rres = roleRes;
                    if (!isAddErrorAction(rres)) {
                      feedback.push({
                        id: "create_organization_role_roles",
                        msg: intl.formatMessage(
                          {
                            defaultMessage:
                              "Included roles updated for {name}.",
                            description:
                              "success notification for adding roles to created role. 'name' = name of the created role",
                          },
                          {
                            name: "<strong>" + res.role.name + "</strong>",
                          }
                        ),
                        autoClose: true,
                        type: "success",
                      });
                    } else {
                      onClearError(
                        (res as any as AddErrorAction<any>).error?.errorId
                      );
                      feedback.push({
                        id:
                          "create_organization_role_roles" +
                          (res.role ? (res.role.id as string) : "not_possible"),
                        msg: intl.formatMessage(
                          {
                            defaultMessage:
                              "updating included roles for {name} failed.",
                            description:
                              "failure notification for adding roles to role. 'name' = name of the failed role",
                          },
                          {
                            name: "<strong>" + res.role.name + "</strong>",
                          }
                        ),
                        type: "danger",
                      });
                    }
                  }

                  onShowFeedback(feedback);
                  onClose();
                });
              } else {
                onShowFeedback(feedback);
                onClose();
              }
            } else {
              onClearError(res.error?.errorId);
              onShowFeedback({
                id: "create_organization_role",
                msg: intl.formatMessage({
                  defaultMessage: "Creating organization role failed.",
                  description:
                    "failure notification for unspecified error when creating role.",
                }),
                type: "danger",
              });
              onClose();
            }
          });
        })();
      }}
      secondaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Cancel",
          description: "secondary button label",
        }),
      }}
      onSecondaryAction={onClose}
    >
      <>
        <Form noValidate>
          <FormInput
            data-test-create-organization-role-modal-name
            label={intl.formatMessage(OrganizationRoleLabels.name)}
            field="name"
            register={register}
            registerOptions={{
              required: true,
            }}
            hasValue={!!formValues["name"]}
            resolveValidity={FormInputUtils.validityResolver(formState)}
          >
            {errors &&
              errors.name &&
              errors.name.type &&
              errors.name.type === "required" && (
                <Form.Control.Feedback type="invalid">
                  <FontAwesomeIcon
                    icon={IconLibrary.icons.faExclamationCircle}
                    className={"icon"}
                  />
                  <span className={"copy"}>
                    {intl.formatMessage(
                      {
                        defaultMessage: "{name} is required.",
                        description:
                          "Field validation error. 'name' = Field label for organization role name (OrganizationRole.name)",
                      },
                      {
                        name: intl.formatMessage(OrganizationRoleLabels.name),
                      }
                    )}
                  </span>
                </Form.Control.Feedback>
              )}
          </FormInput>
          <FormInput
            type={"textarea"}
            data-test-create-organization-role-modal-description
            label={intl.formatMessage(OrganizationRoleLabels.description)}
            field="description"
            register={register}
            registerOptions={{
              required: true,
            }}
            hasValue={!!formValues["description"]}
            resolveValidity={FormInputUtils.validityResolver(formState)}
          >
            {errors &&
              errors.description &&
              errors.description.type &&
              errors.description.type === "required" && (
                <Form.Control.Feedback type="invalid">
                  <FontAwesomeIcon
                    icon={IconLibrary.icons.faExclamationCircle}
                    className={"icon"}
                  />
                  <span className={"copy"}>
                    {intl.formatMessage(
                      {
                        defaultMessage: "{description} is required.",
                        description:
                          "Field validation error. 'description' = Field label for organization role description (OrganizationRole.description)",
                      },
                      {
                        description: intl.formatMessage(
                          OrganizationRoleLabels.description
                        ),
                      }
                    )}
                  </span>
                </Form.Control.Feedback>
              )}
          </FormInput>
          {roles && (
            <Form.Group>
              <Table<OrganizationRole>
                disableLoadingIndicator={!isReady}
                data-test-select-roles
                maxRows={5}
                compact={true}
                header={
                  <Form.Label>
                    {intl.formatMessage(
                      OrganizationRoleLabels.organizationRoles
                    )}
                  </Form.Label>
                }
                search={roles.length > TABLE_SEARCH_THRESHOLD}
                activeSearch={activeRoleSearch}
                onSearch={onSetActiveRoleSearch}
                columnToggle={false}
                reset={false}
                data={roles}
                pagination={false}
                identifyingColumn={"id"}
                selection={{
                  multi: true,
                  selectAll: true,
                }}
                onSelectionChanged={onSetSelectedRoles}
                selected={selectedRoles}
                columns={[
                  {
                    key: "id",
                    label: intl.formatMessage(OrganizationRoleLabels.id),
                    isTechnical: true,
                    hidden: true,
                  },
                  {
                    key: "name",
                    label: intl.formatMessage({
                      defaultMessage: "Role",
                      description: "column heading for the roles to select",
                    }),
                    sortable: true,
                  },
                ]}
              />
            </Form.Group>
          )}
          {permissions && (
            <Form.Group>
              <Table<PermissionWithGrantedActions>
                data-test-select-permissions
                disableLoadingIndicator={!isReady}
                cellEdit={cellEditFactory({
                  mode: "click",
                  beforeSaveCell: (
                    oldValue: string[],
                    newValue: string[],
                    row: PermissionWithGrantedActions,
                    column: string,
                    done: (b: boolean) => void
                  ) => {
                    setTimeout(() => {
                      done(false);
                      onUpdateActions(row, newValue);
                    }, 0);
                    return { async: true };
                  },
                })}
                maxRows={5}
                compact={true}
                header={
                  <Form.Label>
                    {intl.formatMessage(OrganizationRoleLabels.permissions)}
                  </Form.Label>
                }
                search={permissions.length > TABLE_SEARCH_THRESHOLD}
                activeSearch={activePermissionsSearch}
                onSearch={onSetActivePermissionsSearch}
                columnToggle={false}
                reset={false}
                data={permissions}
                pagination={false}
                identifyingColumn={"id"}
                selection={{
                  multi: true,
                  selectAll: false,
                }}
                onSelectionChanged={onSetSelectedPermissions}
                selected={selectedPermissions}
                columns={[
                  {
                    key: "id",
                    label: intl.formatMessage(OrganizationRoleLabels.id),
                    isTechnical: true,
                    hidden: true,
                  },
                  {
                    key: "name",
                    label: intl.formatMessage({
                      defaultMessage: "Permission",
                      description:
                        "column heading for the permissions to select",
                    }),
                    sortable: true,
                  },
                  {
                    key: "grantedActions.allowedActions",
                    label: intl.formatMessage(
                      OrganizationRoleLabels.grantedActions
                    ),
                    editor: (
                      editorProps,
                      value,
                      row,
                      column,
                      rowIndex,
                      columnIndex
                    ) => {
                      return (
                        <ApplyInput
                          size={"sm"}
                          applyLabel={intl.formatMessage({
                            defaultMessage: "Apply",
                            description: "the label of the apply button",
                          })}
                          clearDirtyInputTip={intl.formatMessage({
                            defaultMessage: "Clear unapplied input.",
                            description:
                              "the tooltip of the clear unapplied input icon button",
                          })}
                          clearInputTip={intl.formatMessage({
                            defaultMessage: "Remove actions",
                            description:
                              "the tooltip of the clear input icon button",
                          })}
                          activeValue={value ? value.join(", ") : ""}
                          value={value ? value.join(", ") : ""}
                          onApply={(v) => {
                            editorProps.onUpdate(
                              v
                                .split(/,/)
                                .map((vs) => vs.trim())
                                .filter((vst) => !!vst && vst !== "")
                            );
                          }}
                        />
                      );
                    },
                  },
                ]}
              />
            </Form.Group>
          )}
        </Form>
      </>
    </Modal>
  );
}

export default CreateOrganizationRoleModal;
