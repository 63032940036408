import { connect } from "react-redux";
import { AppState } from "../../../store/AppState";
import OrganizationUtils from "../../../utils/organization";
import RolesCard, {
  RolesCardProps as _RolesCardProps,
} from "./roles-card-view";

import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import { listOrganizationRoles } from "../../../actions";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";

export interface RolesCardProps
  extends Omit<_RolesCardProps, "roles" | "isReady"> {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_RolesCardProps, RolesCardProps>;

// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _RolesCardProps,
  RolesCardProps
>;

const sender: ActionSender = { key: "rolesCard" };

function mapStateToProps(state: AppState): ReduxStateProps {
  const orgAdminRoleId = OrganizationUtils.selectOrgAdminRoleId(state);
  let roles: any[] | undefined =
    OrganizationUtils.selectOrganizationRoles(state);
  if (roles) {
    roles = roles.map((rl) => {
      const role = { ...rl };
      role.type = orgAdminRoleId === role.id ? "orgadmin" : "role";
      return role;
    });
  }
  return {
    roles,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    OrganizationUtils.compareOrganizationRolesStates(next, prev) &&
    InProgressUtils.compareInProgressStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadRoles: addSenderArgument(sender, listOrganizationRoles),
};
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  RolesCardProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(RolesCard);
