import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  HTMLAttributes,
  SyntheticEvent,
  useId,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {IconLibrary, TooltipWrapper, FormInput, FormInputUtils, FeedbackEntry, Feedback, ProgressIndicator, ConfirmModal, Button} from "@10duke/dukeui";
import { Dropdown, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm } from "react-hook-form";
import { TableColumn } from "../../table";
import Table from "../../table/table-container";
import "./import-csv-modal-view.scss";
import { createPortal } from "react-dom";
import { kebabCase } from "change-case";
import DropdownToggleWrapper from "../../dropdown-toggle-wrapper";
import {
  ALL_USER_FIELDS,
  CSVData,
  REQUIRED_USER_FIELDS,
  USER_DATA_MAPPINGS,
} from "./import-csv-utils";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import { ModalKeys } from "../../pages/groups";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import { UserForCreate } from "../../../model/User";
import {
  ClearErrorAction,
  ImportOrganizationUsersAction,
  isAddErrorAction,
} from "../../../actions/actionTypes";
import cloneDeep from "lodash/cloneDeep";
import { ClosableModalProps } from "../closable-modal-props";
import NavigateAfterAction from "../../navigate-after-action";
import isEmail from "../../../util/isEmail";
import { UserLabels } from "../../../localization/user";
import { OrganizationGroupInvitationLabels } from "../../../localization/organization-group-invitation";
import {hasAction} from "../../../ui-configuration/configuration-tools";

//<editor-fold desc="Props">

export interface ImportCSVModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}

export interface ImportCSVModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  groups: OrganizationGroup[] | undefined;
  onImportUsers: (
    users: UserForCreate[]
  ) => Promise<ImportOrganizationUsersAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}

export interface ImportCSVModalStateProps {
  hasFile: boolean;
  setHasFile: (b: boolean) => void;

  csvFile?: File;
  setCsvFile: (f: File | undefined) => void;

  csvData?: Array<CSVData> | null;
  setCsvData: (d: CSVData[] | undefined | null) => void;

  columns?: TableColumn[];
  setColumns: (c: TableColumn[] | undefined) => void;

  columnLabels: { [key: string]: string };

  dataMapping?: { [key: string]: string | null } | null;
  setDataMapping: (m: { [key: string]: string | null } | null) => void;

  importData: { [key: string]: any }[];
  setImportData: (d: { [key: string]: any }[]) => void;

  importColumns: TableColumn[];
  setImportColumns: (c: TableColumn[]) => void;

  duplicatesFound: number;

  invalidEmailsFound: number;
  setInvalidEmailsFound: (n: number) => void;

  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;

  onSetSelected: (selection: OrganizationGroup[]) => void;
  selected?: OrganizationGroup[];

  importFailed: boolean;
  onSetImportFailed: (b: boolean) => void;

  importErrors: any[] | undefined;
  onSetImportErrors: (n: any[] | undefined) => void;
}

export interface ImportCSVModalProps
  extends ImportCSVModalDOMProps,
    ImportCSVModalStateProps,
    ImportCSVModalDataProps,
    Pick<
      ClosableModalProps,
      "show" | "onClose" | "onExited" | "onReloadData"
    > {
  onShowFeedback: (feedback: FeedbackEntry) => void;
}
//</editor-fold>

function ImportCSVModal(props: ImportCSVModalProps) {
  //<editor-fold desc="Local variables">
  let {
    show,
    onClose,
    isReady,
    hasFile,
    setHasFile,
    csvFile,
    setCsvFile,
    csvData,
    setCsvData,
    columns,
    setColumns,
    columnLabels,
    dataMapping,
    setDataMapping,
    importData,
    setImportData,
    importColumns,
    setImportColumns,
    duplicatesFound,
    activeSearch,
    onSetActiveSearch,
    onSetSelected,
    selected,
    groups,
    onImportUsers,
    onShowFeedback,
    onReloadData,
    onExited,
    importErrors,
    onSetImportErrors,
    importFailed,
    onSetImportFailed,
    invalidEmailsFound,
    onClearError,
  } = props;
  // this is more like a variable than a hook
  const intl = useIntl();

  const { conf } = useContext(UIConfiguration);
  const idPrefix = useId();
  const usersConf =
    conf.functionality && conf.functionality.users
      ? conf.functionality.users
      : {};
  const groupConf =
    conf.functionality && conf.functionality.groups
      ? conf.functionality.groups
      : {};

  const defaultValues_file = useMemo(
    () =>
      ({
        csv: undefined,
      } as { csv: string | undefined }),
    []
  );
  const {
    register: register_file,
    watch: watch_file,
    formState: formState_file,

    // trigger: trigger_file
    reset: reset_file,
  } = useForm({
    mode: "onTouched",
    defaultValues: defaultValues_file,
  });

  const {
    // handleSubmit: handleSubmit_file,
    errors: errors_file,
  } = formState_file;

  const formValues_file = watch_file();
  useEffect(() => {
    setHasFile(
      formValues_file.csv &&
        (formValues_file.csv as any as Array<any>).length > 0
        ? true
        : false
    );
  }, [formValues_file, setHasFile]);

  const [isConfirm, onSetIsConfirm] = useState(false);
  const restart = useCallback(() => {
    setHasFile(false);
    reset_file(defaultValues_file);
    setDataMapping(null);
    setCsvFile(undefined);
    setCsvData(undefined);
    setColumns(undefined);
    setImportData([]);
    setImportColumns([]);
    onSetSelected([]);
    onSetImportErrors(undefined);
    onSetImportFailed(false);
    onSetIsConfirm(false);
  }, [
    setHasFile,
    reset_file,
    defaultValues_file,
    setDataMapping,
    setCsvFile,
    setCsvData,
    setColumns,
    setImportData,
    setImportColumns,
    onSetSelected,
    onSetImportErrors,
    onSetImportFailed,
    onSetIsConfirm,
  ]);
  useEffect(() => {
    if (!show) {
      restart();
    }
  }, [show, restart]);
  useEffect(() => {
    if (isConfirm) {
      if (csvData && dataMapping) {
        const newData = [];
        const mappedKeys = Object.keys(dataMapping);
        const mappedValues = Object.values(dataMapping);

        const fields = ALL_USER_FIELDS.filter(
          (v) => usersConf.columns && usersConf.columns.find((c) => c.key === v)
        );
        const iColumns: TableColumn[] = fields.map(
          (v, i) =>
            ({
              key: v,
              label: (UserLabels as any)[v]
                ? intl.formatMessage((UserLabels as any)[v])
                : v,
              hidden: false,
              tipRenderer: (props) => {
                if (
                  props.rendererData &&
                  props.rendererData.errors &&
                  props.rendererData.errors[props.rowIndex as number]
                ) {
                  if (
                    props.rendererData.errors[props.rowIndex as number]
                      .error === "resource_already_exists"
                  ) {
                    return (
                      <FormattedMessage
                        defaultMessage="You're not authorized to import this user."
                        description="tooltip for unauthorized import item"
                      />
                    );
                  } else {
                    return (
                      <FormattedMessage
                        defaultMessage="This user could not be imported."
                        description="tooltip for unspecified failing import item"
                      />
                    );
                  }
                } else {
                  return props.cell;
                }
              },
              rendererData: { errors: importErrors },
            } as TableColumn)
        );
        for (let i = 0; i < csvData.length; i += 1) {
          const t: any = {};
          for (let j = 0; j < fields.length; j += 1) {
            const ind = mappedValues.indexOf(fields[j]);
            if (ind >= 0) {
              t[fields[j]] = csvData[i][mappedKeys[ind]];
            }
          }
          newData.push(t);
        }
        for (let j = 0; j < fields.length; j += 1) {
          const col = iColumns.find((v) => v.key === fields[j]);
          if (col && newData.filter((v) => !!v[fields[j]]).length <= 0) {
            col.hidden = true;
          } else if (col) {
            col.hidden = false;
          }
        }
        setImportColumns(iColumns);
        setImportData(newData);
      }
    } else {
      onSetImportFailed(false);
      onSetImportErrors(undefined);
    }
  }, [
    csvData,
    dataMapping,
    setImportColumns,
    setImportData,
    isConfirm,
    importErrors,
    onSetImportFailed,
    onSetImportErrors,
    intl,
    usersConf.columns,
  ]);

  useEffect(() => {
    if (csvData && csvData.length) {
      if (dataMapping === null) {
        const colKeys = Object.keys(csvData[0])
          .map((v) => ({
            original: columnLabels[v],
            safe: v,
            compare: kebabCase(v),
          }))
          .filter(
            (itm: any) =>
              csvData && csvData.findIndex((v) => v[itm.safe] !== "") >= 0
          );
        const userFields = ALL_USER_FIELDS.filter(
          (v) => usersConf.columns && usersConf.columns.find((c) => c.key === v)
        ).map((v) => ({
          original: v,
          compare: kebabCase(v),
        }));
        const tmp: any = {};
        // Map columns automatically
        for (let i = 0; i < colKeys.length; i += 1) {
          const uf = userFields.find((v) => v.compare === colKeys[i].compare);
          if (uf) {
            // exact match
            tmp[colKeys[i].safe] = uf.original;
          } else {
            // look for matches in common mapping collections
            const maps = Object.keys(USER_DATA_MAPPINGS);
            for (let j = 0; j < maps.length; j += 1) {
              const tmapped =
                USER_DATA_MAPPINGS[maps[j]][colKeys[i].original] ||
                USER_DATA_MAPPINGS[maps[j]][colKeys[i].compare];
              if (
                tmapped &&
                userFields.find((v) => v.original === tmapped) &&
                Object.values(tmp).indexOf(tmapped) < 0
              ) {
                tmp[colKeys[i].safe] = tmapped;
                break;
              }
            }
          }
        }
        setDataMapping(tmp);
      }
      const colKeys = Object.keys(csvData[0]);
      // filter out cols without any values
      const c: TableColumn[] = colKeys
        .filter(
          (key) => csvData && csvData.findIndex((v) => v[key] !== "") >= 0
        )
        .map((v, v_ind) => {
          const mapping = dataMapping ? dataMapping[v] : null;
          const tip = intl.formatMessage(
            {
              defaultMessage: "{csvColumnLabel} = {dataColumnLabel}",
              description:
                "Mapped data column heading. 'csvColumnLabel' = column label in uploaded file, 'dataColumnLabel' = column label in the actual data model",
            },
            {
              csvColumnLabel: columnLabels[v] || v,
              dataColumnLabel: !mapping
                ? intl.formatMessage({
                    defaultMessage: "Ignored",
                    description:
                      "Label to show in place of mapped data column heading  when the csv column is to be ignored",
                  })
                : intl.formatMessage((UserLabels as any)[mapping]),
            }
          );
          return {
            key: v,
            label: columnLabels[v] || v,
            isTechnical: v === "tmp_id",
            hidden: v === "tmp_id",
            rendererData: { data: csvData },
            renderer: (props) => {
              if (mapping === "email" && !isEmail(props.cell)) {
                return (
                  <div className={"email-cell"}>
                    <span className={"value"}>{props.cell}</span>
                    <Button
                      data-test-remove-invalid-button
                      variant={"danger"}
                      size={"sm"}
                      action={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (props.rendererData && props.rendererData.data) {
                          setCsvData(
                            (props.rendererData.data as CSVData[]).filter(
                              (v, i) => i !== props.rowIndex
                            )
                          );
                        }
                      }}
                    >
                      <FontAwesomeIcon icon={IconLibrary.icons.faTrashAlt} />
                    </Button>
                  </div>
                );
              } else if (duplicatesFound > 0 && mapping === "email") {
                if (
                  props.rendererData &&
                  props.rendererData.data &&
                  (props.rendererData.data as CSVData[]).filter(
                    (ent) => ent[v] === props.cell
                  ).length > 1
                ) {
                  return (
                    <div className={"email-cell"}>
                      <span className={"value"}>{props.cell}</span>
                      <Button
                        data-test-remove-duplicate-button
                        size={"sm"}
                        variant={"danger"}
                        action={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          if (props.rendererData && props.rendererData.data) {
                            setCsvData(
                              (props.rendererData.data as CSVData[]).filter(
                                (v, i) => i !== props.rowIndex
                              )
                            );
                          }
                        }}
                        icon={<FontAwesomeIcon icon={IconLibrary.icons.faTrashAlt} />}
                      />
                    </div>
                  );
                } else {
                  return props.cell;
                }
              } else {
                return props.cell;
              }
            },
            tipRenderer: (props) => {
              let retVal = props.cell;
              if (mapping === "email" && !isEmail(props.cell)) {
                retVal = (
                  <>
                    {props.cell} <br />
                    {intl.formatMessage({
                      defaultMessage:
                        "Click the button to remove this invalid item from the list of users to import.",
                      description: "tooltip content for invalid item cell",
                    })}
                  </>
                );
              } else if (duplicatesFound > 0 && mapping === "email") {
                if (
                  props.rendererData &&
                  props.rendererData.data &&
                  (props.rendererData.data as CSVData[]).filter(
                    (ent) => ent[v] === props.cell
                  ).length > 1
                ) {
                  retVal = (
                    <>
                      {props.cell} <br />
                      {intl.formatMessage({
                        defaultMessage:
                          "Click the button to remove this duplicated item from the list of users to import.",
                        description: "tooltip content for duplicated item cell",
                      })}
                    </>
                  );
                }
              }
              return retVal;
            },
            className:
              (mapping ? "mapped" : "not-mapped") +
              ((duplicatesFound > 0 || invalidEmailsFound > 0) &&
              mapping === "email"
                ? " error"
                : ""),
            tooltip:
              (duplicatesFound > 0 || invalidEmailsFound > 0) &&
              mapping === "email" ? (
                <>
                  {tip}
                  <strong key={"err"} className={"d-block"}>
                    <FormattedMessage
                      defaultMessage="Emails have to be valid and unique."
                      description="Tooltip content for email column when unique email constraint is not met or emails are invalid"
                    />
                  </strong>
                </>
              ) : (
                tip
              ),
            header: () => (
              <Dropdown
                id={"d_" + v}
                onSelect={(
                  eventKey: string | null,
                  e: SyntheticEvent<unknown>
                ) => {
                  if (eventKey) {
                    if (
                      dataMapping &&
                      (eventKey as string) === dataMapping[v]
                    ) {
                      setDataMapping({ ...dataMapping, [v]: null });
                    } else if (dataMapping) {
                      const t = Object.values(dataMapping).indexOf(eventKey);
                      if (t >= 0) {
                        // remove previous mapping
                        setDataMapping({
                          ...dataMapping,
                          [Object.keys(dataMapping)[t]]: null,
                          [v]: eventKey as string,
                        });
                      } else {
                        setDataMapping({
                          ...dataMapping,
                          [v]: eventKey as string,
                        });
                      }
                    } else {
                      setDataMapping({
                        [v]: eventKey as string,
                      });
                    }
                  }
                }}
              >
                <Dropdown.Toggle
                  as={DropdownToggleWrapper}
                  id={idPrefix + "dt_" + v}
                  data-test-mapping-tool-trigger={v}
                >
                  <span className={"original"}>{columnLabels[v] || v}</span>
                  <FontAwesomeIcon icon={IconLibrary.icons.faEquals} className="indicator" />
                  <span className={"mapping"}>
                    {!mapping &&
                      intl.formatMessage({
                        defaultMessage: "Ignored",
                        description:
                          "Label to show in place of mapped data column heading  when the csv column is to be ignored",
                      })}
                    {mapping && (
                      <>{intl.formatMessage((UserLabels as any)[mapping])}</>
                    )}
                  </span>
                </Dropdown.Toggle>
                {createPortal(
                  <Dropdown.Menu
                    className={"from-modal"}
                    data-test-mapping-tool-items
                    popperConfig={{
                      modifiers: [
                        {
                          name: "computeStyles",
                          options: {
                            gpuAcceleration: false, // true by default. Without this random conf, unnecessary scrollbars are added to parents
                          },
                        },
                      ],
                    }}
                  >
                    <Dropdown.Header>
                      <FormattedMessage
                        defaultMessage="Select target data column"
                        description="column mapping dropdown heading"
                      />
                    </Dropdown.Header>
                    {ALL_USER_FIELDS.filter(
                      (v) =>
                        usersConf.columns &&
                        usersConf.columns.find((c) => c.key === v)
                    ).map((f) => (
                      <Dropdown.Item
                        key={f}
                        eventKey={f}
                        className={
                          dataMapping &&
                          Object.values(dataMapping).indexOf(f) >= 0
                            ? "mapped"
                            : undefined
                        }
                        active={mapping === f}
                      >
                        {intl.formatMessage((UserLabels as any)[f])}{" "}
                        <em>
                          (
                          {dataMapping &&
                          Object.values(dataMapping).indexOf(f) >= 0 ? (
                            columnLabels[
                              Object.keys(dataMapping)[
                                Object.values(dataMapping).indexOf(f)
                              ]
                            ]
                          ) : (
                            <FormattedMessage
                              defaultMessage="Not mapped"
                              description="Dropdown item column label fallback for unmapped columns"
                            />
                          )}
                        </em>
                        )
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>,
                  document.querySelector("body") as HTMLBodyElement
                )}
              </Dropdown>
            ),
          };
        });
      setColumns(c);
    }
  }, [
    csvData,
    setCsvData,
    dataMapping,
    duplicatesFound,
    invalidEmailsFound,
    columnLabels,
    setColumns,
    intl,
    setDataMapping,
    usersConf.columns,
    idPrefix,
  ]);
  //</editor-fold>
  return (
    <ConfirmModal
      onExited={onExited}
      onReloadData={onReloadData}
      className="import-csv-modal"
      size={"lg"}
      isReady={isReady}
      data-test-import-csv-modal={true}
      title={intl.formatMessage({
        defaultMessage: "Import users",
        description: "Modal heading",
      })}
      show={show}
      onClose={onClose}
      backdrop={csvFile ? "static" : true}
      primaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Continue",
          description: "primary button label",
        }),
        tooltip:
          duplicatesFound > 0 ||
          invalidEmailsFound > 0 ||
          REQUIRED_USER_FIELDS.filter(
            (v) => !dataMapping || Object.values(dataMapping).indexOf(v) < 0
          ).length > 0
            ? duplicatesFound > 0 || invalidEmailsFound > 0
              ? intl.formatMessage({
                  defaultMessage: "Email constraints not met",
                  description:
                    "Tooltip for disabled primary button when there are duplicated or invalid emails",
                })
              : intl.formatMessage({
                  defaultMessage: "Please map all required columns",
                  description:
                    "Tooltip for disabled primary button when there are unmapped required columns",
                })
            : undefined,
        disabled:
          !csvData ||
          csvData.length === 0 ||
          duplicatesFound > 0 ||
          invalidEmailsFound > 0 ||
          REQUIRED_USER_FIELDS.filter(
            (v) => !dataMapping || Object.values(dataMapping).indexOf(v) < 0
          ).length > 0,
      }}
      onPrimaryAction={() => {
        onSetImportFailed(false);
        onSetImportErrors(undefined);
        if (importData && importData.length) {
          let users: UserForCreate[] = cloneDeep(importData) as UserForCreate[];
          if (selected && selected.length) {
            const selIds: string[] = selected.map((v) => v.id as string);
            users = users
              .map((v) => {
                v.groupIds = (v.groupIds || []).concat(selIds);
                return v;
              })
              .filter(
                (v, ind, arr) =>
                  arr.findIndex((av) => av.email === v.email) === ind
              );
          }
          onImportUsers(users).then((res) => {
            if (!isAddErrorAction(res)) {
              onShowFeedback({
                id: "import",
                msg: intl.formatMessage({
                  defaultMessage: "Users imported.",
                  description: "Success notification",
                }),
                autoClose: true,
                type: "success",
              });
              onClose();
            } else {
              onClearError(res.error?.errorId);
              onSetImportFailed(true);
              if (
                res &&
                res.error &&
                res.error.apiError &&
                res.error.apiError.errors
              ) {
                onSetImportErrors([].concat(res.error.apiError.errors));
              }
            }
          });
        } else {
          onClose();
        }
      }}
      secondaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Close",
          description: "secondary button label",
        }),
      }}
      onSecondaryAction={onClose}
      confirmTitle={intl.formatMessage({
        defaultMessage: "Confirm user import",
        description: "Modal heading for confirming action",
      })}
      onConfirm={(flag) => {
        onSetIsConfirm(flag);
        return new Promise((resolve) => {
          resolve(flag);
        });
      }}
      confirmContent={
        <>
          {!importFailed ? (
            <p>
              <FormattedMessage
                defaultMessage="Please review and confirm the User data to import."
                description="Confirm action message"
              />
            </p>
          ) : (
            <>
              <Feedback show={true} type="danger" asChild={true}>
                <h4>
                  <FormattedMessage
                    defaultMessage="Importing users failed"
                    description="Import failed heading"
                  />
                </h4>
                {importErrors &&
                csvData &&
                importErrors.filter((v) => !!v).length === csvData.length ? (
                  <>
                    <p>
                      <FormattedMessage
                        defaultMessage="Users could not be imported and the process was aborted. Hover over the highlighted rows below for error details."
                        description="Import failed message"
                      />
                    </p>
                  </>
                ) : undefined}
                {importErrors &&
                importErrors.filter((v) => !!v).length > 0 &&
                csvData &&
                importErrors.filter((v) => !!v).length < csvData.length ? (
                  <>
                    <p>
                      <FormattedMessage
                        defaultMessage="Some of the users could not be imported and the process was aborted. Hover over the highlighted rows below for error details."
                        description="Import partially failed message 1"
                      />
                    </p>
                    <p>
                      <FormattedMessage
                        defaultMessage="If you wish you may continue the import without these users."
                        description="Import partially failed message 2"
                      />
                    </p>
                    <Button
                      data-test-remove-failing-users-button
                      action={() => {
                        setCsvData(
                          csvData
                            ? csvData.filter(
                                (v, i) => !importErrors || !importErrors[i]
                              )
                            : []
                        );
                        onSetImportErrors(undefined);
                        onSetImportFailed(false);
                      }}
                      variant={"danger"}
                      className={"btn remove-users-btn"}
                    >
                      <FormattedMessage
                        defaultMessage="Remove highlighted users"
                        description="Remove all failed items button label"
                      />
                    </Button>
                  </>
                ) : undefined}
              </Feedback>
            </>
          )}
          {importColumns && importColumns.length ? (
            <Table<any>
              sort={{ column: "email", ascending: true }}
              data-test-confirm-import-table={true}
              columns={importColumns}
              horizontalScroll={true}
              maxRows={6}
              data={importData || []}
              identifyingColumn={"email"}
              pagination={false}
              rowClasses={(row, rowIndex) =>
                importErrors && importErrors[rowIndex] ? "error" : undefined
              }
            ></Table>
          ) : undefined}

          {usersConf.importShowGroupSelection && (
            <Form.Group className={"form-table"}>
              <Table<OrganizationGroup>
                data-test-assign-groups-table={true}
                maxRows={5}
                compact={true}
                header={
                  <Form.Label>
                    {intl.formatMessage({
                      defaultMessage:
                        "Add group membership(s) for all imported users",
                      description: "field label",
                    })}
                  </Form.Label>
                }
                data-test-select-groups
                search={(groups || []).length > TABLE_SEARCH_THRESHOLD}
                activeSearch={activeSearch}
                onSearch={onSetActiveSearch}
                columnToggle={false}
                reset={false}
                data={groups || []}
                pagination={false}
                identifyingColumn={"id"}
                selection={{
                  multi: true,
                  selectAll: true,
                }}
                onSelectionChanged={onSetSelected}
                selected={selected}
                columns={[
                  {
                    key: "id",
                    label: intl.formatMessage(
                      OrganizationGroupInvitationLabels.id
                    ),
                    isTechnical: true,
                    hidden: true,
                  },
                  {
                    key: "name",
                    label: intl.formatMessage({
                      defaultMessage: "Group",
                      description: "column heading for the groups to select",
                    }),
                    sortable: true,
                    renderer: (props: {
                      cell: any;
                      row: any;
                      rowIndex: Number;
                      rendererData: any;
                    }) => {
                      return (
                        <>
                          {hasAction(groupConf.rowActions, 'show') ? (
                            <NavigateAfterAction
                              href={
                                "/groups/" + props.row.id + "/" + ModalKeys.show
                              }
                              action={onClose}
                            >
                              {props.cell}
                            </NavigateAfterAction>
                          ) : props.cell}
                        </>
                      );
                    },
                    tipRenderer: (props: {
                      cell: any;
                      row: any;
                      rowIndex: Number;
                      rendererData: any;
                    }) => {
                      return (
                        <>
                          <FormattedMessage
                            defaultMessage="Click to select or deselect."
                            description={"tooltip for name column cells"}
                          />
                        </>
                      );
                    },
                  },
                ]}
              />
            </Form.Group>
          )}
        </>
      }
      acceptButton={{
        label: intl.formatMessage({
          defaultMessage: "Import",
          description: "Confirm button label",
        }),
        disabled:
          (usersConf.importShowGroupSelection &&
            (!selected || selected.length === 0)) ||
          importFailed
            ? true
            : undefined,
        tooltip:
          usersConf.importShowGroupSelection &&
          (!selected || selected.length === 0)
            ? intl.formatMessage({
                defaultMessage: "Please select at least 1 group.",
                description:
                  "Tooltip for disabled confirm button when there are no groups selected",
              })
            : undefined,
      }}
      cancelButton={{
        label: intl.formatMessage({
          defaultMessage: "Cancel",
          description: "cancel confirmation button label",
        }),
      }}
    >
      {csvFile &&
        csvData &&
        csvData.length > 0 &&
        csvData[0] &&
        Object.keys(csvData[0]).length >= REQUIRED_USER_FIELDS.length + 1 && (
          <>
            <Feedback
              show={true}
              type={
                duplicatesFound > 0 || invalidEmailsFound > 0
                  ? "danger"
                  : "success"
              }
              asChild={true}
            >
              <h2 className="h4">
                <FormattedMessage
                  defaultMessage="Importing {file}"
                  description={
                    "heading for file import details. 'file' = name of the file"
                  }
                  values={{
                    file: (
                      <em className={"file-name"}>
                        {csvFile ? (csvFile as any).name : ""}
                      </em>
                    ),
                  }}
                />
              </h2>
              {(duplicatesFound > 0 || invalidEmailsFound > 0) && (
                <>
                  <div className="import-issues">
                    <ul>
                      {invalidEmailsFound > 0 && (
                        <li>
                          <div className={"import-issue"}>
                            <span className={"value"}>
                              <FormattedMessage
                                defaultMessage="{count} invalid or missing emails"
                                description={
                                  "summary item for invalid emails. 'count' = number of invalid emails"
                                }
                                values={{
                                  count: <em>{invalidEmailsFound}</em>,
                                }}
                              />
                            </span>
                            <Button
                              data-test-remove-all-invalid-button
                              type={"button"}
                              variant={"danger"}
                              size={"sm"}
                              action={() => {
                                if (dataMapping) {
                                  const values = Object.values(dataMapping);
                                  const keys = Object.keys(dataMapping);
                                  const ind = values.indexOf("email");
                                  if (ind >= 0 && keys[ind]) {
                                    const emailField: string = keys[
                                      ind
                                    ] as string;
                                    setCsvData(
                                      csvData
                                        ? csvData.filter((v, i) =>
                                            isEmail(v[emailField])
                                          )
                                        : csvData
                                    );
                                  }
                                }
                              }}
                            >
                              <FormattedMessage
                                defaultMessage="Remove all invalid"
                                description={
                                  "Remove all invalid email items button label"
                                }
                              />
                            </Button>
                          </div>
                        </li>
                      )}
                      {duplicatesFound > 0 && (
                        <li>
                          <div className={"import-issue"}>
                            <span className={"value"}>
                              <FormattedMessage
                                defaultMessage="{count} duplicated emails"
                                description={
                                  "summary item for duplicated emails. 'count' = number of duplicated emails"
                                }
                                values={{
                                  count: <em>{duplicatesFound}</em>,
                                }}
                              />
                            </span>
                            <Button
                              data-test-remove-all-duplicates-button
                              type={"button"}
                              variant={"danger"}
                              size={"sm"}
                              action={() => {
                                if (dataMapping) {
                                  const values = Object.values(dataMapping);
                                  const keys = Object.keys(dataMapping);
                                  const ind = values.indexOf("email");
                                  if (ind >= 0 && keys[ind]) {
                                    const emailField: string = keys[
                                      ind
                                    ] as string;
                                    setCsvData(
                                      csvData
                                        ? csvData.filter((v, i) =>
                                            csvData
                                              ? csvData.findIndex(
                                                  (d) =>
                                                    d[emailField] ===
                                                    v[emailField]
                                                ) === i
                                              : true
                                          )
                                        : csvData
                                    );
                                  }
                                }
                              }}
                            >
                              <FormattedMessage
                                defaultMessage="Remove all duplicates"
                                description={
                                  "Button label for removing items with duplicate email addresses"
                                }
                              />
                            </Button>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                  <hr />
                </>
              )}
              <div className="file-details">
                <ul>
                  <li>
                    <FormattedMessage
                      defaultMessage="{count} columns"
                      description={
                        "summary item for file columns. 'count' = number of columns"
                      }
                      values={{
                        count: (
                          <em>
                            {csvData &&
                            csvData[0] &&
                            Object.keys(csvData[0]).length > 1
                              ? Object.keys(csvData[0]).length - 1
                              : "0"}
                          </em>
                        ),
                      }}
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      defaultMessage="{count} data entries"
                      description={
                        "summary item for file entries. 'count' = number of entries"
                      }
                      values={{
                        count: <em>{csvData && csvData.length}</em>,
                      }}
                    />
                  </li>
                </ul>
                <Button
                  type={"button"}
                  variant={"secondary"}
                  action={restart}
                >
                  <FormattedMessage
                    defaultMessage="Choose another file"
                    description={"Restart file import button label"}
                  />
                </Button>
              </div>
            </Feedback>
          </>
        )}
      {csvFile &&
        (csvData === null ||
          (csvData !== undefined &&
            (csvData.length <= 0 ||
              !csvData[0] ||
              Object.keys(csvData[0]).length <
                REQUIRED_USER_FIELDS.length + 1))) && (
          <>
            <Feedback show={true} type="danger" asChild={true}>
              <h2 className={"h4"}>
                <FormattedMessage
                  defaultMessage="Importing {file}"
                  description={
                    "heading for file import details. 'file' = name of the file"
                  }
                  values={{
                    file: (
                      <em className={"file-name"}>
                        {csvFile ? (csvFile as any).name : ""}
                      </em>
                    ),
                  }}
                />
              </h2>
              <div className="import-issues">
                <ul>
                  {csvData &&
                  csvData[0] &&
                  Object.keys(csvData[0]).length <
                    REQUIRED_USER_FIELDS.length + 1 ? (
                    <li data-test-file-no-columns>
                      <div className={"import-issue"}>
                        <span className={"value"}>
                          <FormattedMessage
                            defaultMessage="A minimum of {required} columns are required, only {count} detected."
                            description={
                              "Error message for minimum column requirement. 'required' = minimum requirement count, 'count' = detected count"
                            }
                            values={{
                              required: (
                                <strong>{REQUIRED_USER_FIELDS.length}</strong>
                              ),
                              count: (
                                <strong>
                                  {Object.keys(
                                    csvData && csvData[0] ? csvData[0] : {}
                                  ).length - 1}
                                </strong>
                              ),
                            }}
                          />
                        </span>
                      </div>
                    </li>
                  ) : undefined}
                  {csvData && csvData.length <= 0 ? (
                    <li data-test-file-no-items>
                      <div className={"import-issue"}>
                        <span className={"value"}>
                          <FormattedMessage
                            defaultMessage="The CSV does not contain data entries to import."
                            description={
                              "Error message for no data entries in file"
                            }
                          />
                        </span>
                      </div>
                    </li>
                  ) : undefined}
                  {!csvData && (
                    <li data-test-file-invalid-csv>
                      <div className={"import-issue"}>
                        <span className={"value"}>
                          <FormattedMessage
                            defaultMessage="Not a valid CSV file"
                            description={"Error message for invalid file"}
                          />
                        </span>
                      </div>
                    </li>
                  )}
                </ul>
                <Button
                  type={"button"}
                  variant={"danger"}
                  action={restart}
                >
                  <FormattedMessage
                    defaultMessage="Choose another file"
                    description={"Restart file import button label"}
                  />
                </Button>
              </div>
            </Feedback>
          </>
        )}
      {(!csvFile || csvData === undefined) && (
        <Form noValidate>
          <Feedback show={true} type="info" asChild={true}>
            <p>
              <FormattedMessage
                defaultMessage="Users can be imported by uploading user data in CSV format. The CSV file should contain column headers on the first row to allow mapping data."
                description={"CSV upload info 1"}
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage="The CSV must contain values for at least the following required data fields {fields}."
                description={
                  "CSV upload info 2. 'fields' = list of required fields"
                }
                values={{
                  fields: REQUIRED_USER_FIELDS.map((v, i) => (
                    <span key={v}>
                      <strong key={v}>
                        {intl.formatMessage((UserLabels as any)[v])}
                      </strong>
                      {i < REQUIRED_USER_FIELDS.length - 1 ? ", " : ""}
                    </span>
                  )),
                }}
              />
            </p>
          </Feedback>
          <FormInput
            data-test-upload-csv
            type={"file"}
            label={
              <FormattedMessage
                defaultMessage="Import CSV"
                description={"Field label"}
              />
            }
            field="csv"
            register={register_file}
            registerOptions={{
              required: true,
              onChange: (e: any) => {
                if (e && e.target && e.target.files && e.target.files[0]) {
                  setCsvFile(e.target.files[0]);
                } else {
                  setCsvFile(undefined);
                }
                setDataMapping(null);
                setCsvData(undefined);
              },
            }}
            hasValue={hasFile}
            resolveValidity={FormInputUtils.validityResolver(formState_file)}
          >
            {errors_file &&
              errors_file.csv &&
              errors_file.csv.type &&
              errors_file.csv.type === "required" && (
                <Form.Control.Feedback type="invalid">
                  <FontAwesomeIcon
                    icon={IconLibrary.icons.faExclamationCircle}
                    className={"icon"}
                  />
                  <span className={"copy"}>
                    <FormattedMessage
                      defaultMessage="A valid csv is required."
                      description={"Field validation error"}
                    />
                  </span>
                </Form.Control.Feedback>
              )}
          </FormInput>
        </Form>
      )}

      <ProgressIndicator
          show={!columns &&
              !!csvFile &&
              !!csvData &&
              csvData.length > 0 &&
              !!csvData[0] &&
              Object.keys(csvData[0]).length >= REQUIRED_USER_FIELDS.length + 1}
          variant={'lg'}
          backdrop={true}
      />

      {columns &&
        columns.length &&
        csvFile &&
        csvData &&
        csvData.length > 0 &&
        csvData[0] &&
        Object.keys(csvData[0]).length >= REQUIRED_USER_FIELDS.length + 1 && (
          <Form noValidate>
            <h2>
              <FormattedMessage
                defaultMessage="Map CSV columns"
                description={"Heading for map columns section"}
              />
            </h2>
            <p>
              <FormattedMessage
                defaultMessage="The CSV column names must be mapped to the data fields of the User object.
                We've analysed the CSV and mapped all recognised columns. Please check that the automatic mapping is
                correct and map any additional columns you wish to import."
                description={"Map CSV columns message"}
              />
            </p>
            <p>
              <FormattedMessage
                defaultMessage="Minimum requirements: {fields}."
                description={
                  "minimum column mapping requirements message. 'fields' = list of columns with mapping status"
                }
                values={{
                  fields: REQUIRED_USER_FIELDS.map((v, i) => {
                    const mapInd = dataMapping
                      ? Object.values(dataMapping).indexOf(v)
                      : -1;
                    const isMapped = mapInd >= 0;
                    return (
                      <span key={v}>
                        <TooltipWrapper
                          tipKey={v + "-mapping-tip"}
                          tip={
                            isMapped && dataMapping ? (
                              <FormattedMessage
                                defaultMessage="Mapped to CSV field: {field}."
                                description={"Tooltip for mapped column"}
                                values={{
                                  field:
                                    columnLabels[
                                      Object.keys(dataMapping)[mapInd]
                                    ] || Object.keys(dataMapping)[mapInd],
                                }}
                              />
                            ) : (
                              <FormattedMessage
                                defaultMessage="Not mapped"
                                description={"Tooltip for unmapped column"}
                              />
                            )
                          }
                          delay={{ show: 900, hide: 100 }}
                          placement={"top"}
                        >
                          <strong
                            className={
                              "required-mapping" + (isMapped ? " mapped" : "")
                            }
                          >
                            {isMapped ? (
                              <FontAwesomeIcon
                                icon={IconLibrary.icons.faCheck}
                                fixedWidth={true}
                                className="icon"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={IconLibrary.icons.faTimes}
                                fixedWidth={true}
                                className="icon"
                              />
                            )}
                            {intl.formatMessage((UserLabels as any)[v])}
                          </strong>
                        </TooltipWrapper>
                        {i < REQUIRED_USER_FIELDS.length - 1 ? ", " : ""}
                      </span>
                    );
                  }),
                }}
              />
            </p>
            <Form.Group className={"form-table"}>
              <Table<any>
                sort={{ column: "email", ascending: true }}
                data-test-map-columns-table={true}
                columns={columns ? columns : []}
                horizontalScroll={true}
                maxRows={6}
                data={csvData || []}
                className={
                  duplicatesFound > 0 || invalidEmailsFound > 0
                    ? "has-errors"
                    : undefined
                }
                identifyingColumn={"tmp_id"}
                rowClasses={(row, rowIndex: number) => {
                  let retVal: string | undefined = undefined;
                  if (dataMapping) {
                    const keys = Object.keys(dataMapping);
                    const values = Object.values(dataMapping);
                    const emailInd = values.findIndex((v) => v === "email");
                    const mapped = keys[emailInd];
                    if (mapped) {
                      if (
                        csvData &&
                        csvData.filter((ent) => ent[mapped] === row[mapped])
                          .length > 1
                      ) {
                        retVal = "error-row";
                      } else if (!isEmail(row[mapped])) {
                        retVal = "error-row";
                      }
                    }
                  }
                  return retVal;
                }}
                pagination={false}
              ></Table>
              {(duplicatesFound > 0 || invalidEmailsFound > 0) && (
                <>
                  <Form.Control.Feedback
                    type="invalid"
                    className={"d-block"}
                    data-test-unique-email-constraint-notification
                  >
                    <FontAwesomeIcon
                      icon={IconLibrary.icons.faExclamationCircle}
                      className={"icon"}
                    />
                    <span className={"copy"}>
                      <FormattedMessage
                        defaultMessage="Email constraints not met."
                        description={"Field validation error"}
                      />
                    </span>
                  </Form.Control.Feedback>
                </>
              )}
            </Form.Group>
          </Form>
        )}
    </ConfirmModal>
  );
}

export default ImportCSVModal;
