import { useEffect, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {FeedbackEntry, Feedback, ConfirmModal} from "@10duke/dukeui";
import {
  AddClientsToClientGroupAction,
  ClearErrorAction, GetOrgClientGroupAction,
  isAddErrorAction, RemoveClientsFromClientGroupAction,
} from "../../../actions/actionTypes";
import Table from "../../table";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import { ModalKeys } from "../../pages/device-clients";
import { Form } from "react-bootstrap";
import { ClosableModalProps } from "../closable-modal-props";
import NavigateAfterAction from "../../navigate-after-action";
import {ClientGroup} from "../../../model/ClientGroup";
import {Client} from "../../../model/Client";
import {ClientGroupLabels} from "../../../localization/client-group";
import "./manage-device-client-group-device-client-modal-view.scss";


export interface ManageDeviceClientGroupDeviceClientsModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}

export interface ManageDeviceClientGroupDeviceClientsModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {
  onShowFeedback: (feedback: FeedbackEntry | FeedbackEntry[]) => void;
}
export interface ManageDeviceClientGroupDeviceClientsModalInputProps
  extends ManageDeviceClientGroupDeviceClientsModalVisibilityProps {
  groupId?: string;
}
export interface ManageDeviceClientGroupDeviceClientsModalStateProps {
  membersToAdd: Client[];
  membersToRemove: Client[];
  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;
  selected: Client[];
  onSetSelected: (selection: Client[]) => void;
}
export interface ManageDeviceClientGroupDeviceClientsModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  group?: ClientGroup | null;
  onLoadGroup?: (id: string) => Promise<GetOrgClientGroupAction>;

  clients?: Client[];
  onLoadClients?: () => void;

  groupMembers?: string[];
  onLoadGroupMembers?: (id: string) => void;

  onAddClients?: (
    grpId: string,
    clientIds: string[]
  ) => Promise<AddClientsToClientGroupAction>;
  onRemoveClients?: (
    grpId: string,
    clientIds: string[]
  ) => Promise<RemoveClientsFromClientGroupAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface ManageDeviceClientGroupDeviceClientsModalProps
  extends ManageDeviceClientGroupDeviceClientsModalDOMProps,
    ManageDeviceClientGroupDeviceClientsModalStateProps,
    ManageDeviceClientGroupDeviceClientsModalDataProps,
    ManageDeviceClientGroupDeviceClientsModalInputProps {}
//</editor-fold>

function ManageDeviceClientGroupDeviceClientsModal(props: ManageDeviceClientGroupDeviceClientsModalProps) {
  //<editor-fold desc="Local variables">
  let {
    activeSearch,
    onSetActiveSearch,
    group,
    isReady,
    groupId,
    onLoadGroup,
    clients,
    onLoadClients,
    onAddClients,
    onRemoveClients,
    groupMembers,
    onLoadGroupMembers,
    show,
    onClose,
    onExited,
    onShowFeedback,
    membersToAdd,
    membersToRemove,
    onSetSelected,
    selected,
    onClearError,
  } = props;
  if (show && !onAddClients) {
    throw new Error(
      "ManageDeviceClientGroupDeviceClientsModal: Required props missing. The onAddMembers is required"
    );
  }
  if (show && !onRemoveClients) {
    throw new Error(
      "ManageDeviceClientGroupDeviceClientsModal: Required props missing. The onRemoveMembers is required"
    );
  }
  if (show && group === undefined && (!groupId || !onLoadGroup)) {
    throw new Error(
      "ManageDeviceClientGroupDeviceClientsModal: Required props missing. The onLoadGroup is required with groupId"
    );
  }
  if (show && !clients && !onLoadClients) {
    throw new Error(
      "ManageDeviceClientGroupDeviceClientsModal: Required props missing. Either clients or onLoadClients must be provided"
    );
  }
  if (show && !groupMembers && !onLoadGroupMembers) {
    throw new Error(
      "ManageDeviceClientGroupDeviceClientsModal: Required props missing. Either groupMembers or onLoadGroupMembers must be provided"
    );
  }
  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>

  const groupObjId = group ? group.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!onLoadGroup &&
      !!groupId &&
      (groupObjId === undefined || (!!groupObjId && groupObjId !== groupId))
    ) {
      onLoadGroup(groupId).then((res) => {
        if (!groupObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, onLoadGroup, groupId, groupObjId, onClearError]);

  useEffect(() => {
    if (show && !clients && onLoadClients) {
      onLoadClients();
    }
  }, [show, clients, onLoadClients]);

  useEffect(() => {
    if (show && !groupMembers && group && onLoadGroupMembers) {
      onLoadGroupMembers(group.id as string);
    }
  }, [show, group, groupMembers, onLoadGroupMembers]);

  return (
    <ConfirmModal
      id="manage-device-client-group-device-clients-modal"
      onExited={onExited}
      isReady={isReady}
      onReloadData={() => {
        if (onLoadClients) {
          onLoadClients();
        }
        if (groupId && onLoadGroupMembers) {
          onLoadGroupMembers(groupId);
        }
        if (groupId && onLoadGroup) {
          onLoadGroup(groupId).then((res) => {
            if (!group && isAddErrorAction(res)) {
              // only clear error if no data exists, leave if previous data is still available and
              // let the api error notification handler show error
              onClearError(res.error?.errorId);
            }
          });
        }
      }}
      data-test-manage-client-group-clients-modal={group ? group.id : true}
      title={
        group
          ? intl.formatMessage(
              {
                defaultMessage: "{name}: connect device clients",
                description: "modal heading, 'name' = name of the device client group",
              },
              {
                name: group.name,
              }
            )
          : intl.formatMessage({
              defaultMessage: "Device client group not found",
              description: "modal heading when device client group not found",
            })
      }
      confirmTitle={
        group
          ? intl.formatMessage(
              {
                defaultMessage: "{name}: confirm connected device clients",
                description:
                  "modal confirm action heading. 'name' = name of the device client group",
              },
              {
                name: group.name,
              }
            )
          : undefined
      }
      confirmContent={
        <>
          <p data-test-confirm-changes-message>
            <FormattedMessage
              defaultMessage="Are you sure you wish to apply the following changes to the connected clients?"
              description={"Confirm action message"}
            />
          </p>
          {membersToRemove.length > 0 && (
            <>
              <strong>
                <FormattedMessage
                  defaultMessage="Device clients to remove"
                  description={"heading for summary of devices to remove"}
                />
              </strong>
              <ul>
                {membersToRemove.map((g) => (
                  <li key={"toRemove" + (g.id as string)}>
                    {g.name}
                  </li>
                ))}
              </ul>
            </>
          )}
          {membersToAdd.length > 0 && (
            <>
              <strong>
                <FormattedMessage
                  defaultMessage="Device clients to connect"
                  description={"heading for summary of devices to add"}
                />
              </strong>
              <ul>
                {membersToAdd.map((g) => (
                  <li key={"toAdd" + (g.id as string)}>
                    {g.name}
                  </li>
                ))}
              </ul>
            </>
          )}
        </>
      }
      acceptButton={{
        label: intl.formatMessage({
          defaultMessage: "Yes",
          description: "confirm action button label",
        }),
      }}
      cancelButton={{
        label: intl.formatMessage({
          defaultMessage: "No",
          description: "cancel confirmation button label",
        }),
      }}
      show={show}
      onClose={onClose}
      skipConfirm={!group}
      backdrop={
        membersToAdd.length > 0 || membersToRemove.length > 0 ? "static" : true
      }
      primaryButton={{
        label: !group
          ? intl.formatMessage({
              defaultMessage: "Close",
              description: "close button label",
            })
          : intl.formatMessage({
              defaultMessage: "Apply",
              description: "primary button label",
            }),
        disabled:
          !!group && membersToAdd.length === 0 && membersToRemove.length === 0,
        tooltip:
          !!group && membersToAdd.length === 0 && membersToRemove.length === 0
            ? intl.formatMessage({
                defaultMessage: "There are no changes to apply.",
                description: "tooltip for disabled primary button",
              })
            : undefined,
      }}
      onPrimaryAction={() => {
        if (show && group) {
          if (onAddClients && onRemoveClients) {
            const actions = [];
            if (membersToAdd.length) {
              actions.push(
                onAddClients(
                  group.id as string,
                  membersToAdd.map((val) => val.id as string)
                ).catch((error) => error)
              );
            }
            if (membersToRemove.length) {
              actions.push(
                onRemoveClients(
                  group.id as string,
                  membersToRemove.map((val) => val.id as string)
                ).catch((error) => error)
              );
            }
            if (actions.length) {
              // should aways come here
              Promise.all(actions).then(
                (res) => {
                  const feedback: FeedbackEntry[] = [];
                  let addResult;
                  let removeResult;
                  if (membersToAdd.length) {
                    addResult = res[0];
                    if (membersToRemove.length) {
                      removeResult = res[1];
                    }
                  } else if (membersToRemove.length) {
                    removeResult = res[0];
                  } else {
                    throw Error("This should never happen");
                  }
                  if (addResult && addResult.error) {
                    onClearError(addResult.error?.errorId);
                    feedback.push({
                      id: "manageDeviceClientGroupDeviceClientsModal_add",
                      msg: intl.formatMessage(
                        {
                          defaultMessage: "{name}: Connecting device clients failed.",
                          description:
                            "failure notification. 'name' = name of the device client group",
                        },
                        {
                          name:
                            "<strong>" +
                            (group ? group.name : "impossible") +
                            "</strong>",
                        }
                      ),
                      type: "danger",
                    });
                  } else if (addResult) {
                    feedback.push({
                      id: "manageDeviceClientGroupDeviceClientsModal_add",
                      msg: intl.formatMessage(
                        {
                          defaultMessage:
                            "{name}: {count} {count, plural, one {device client} other {device clients}} added.",
                          description:
                            "Success notification. 'name' = name of the device client group, 'count' = the amount of device clients added",
                        },
                        {
                          name:
                            "<strong>" +
                            (group ? group.name : "impossible") +
                            "</strong>",
                          count: membersToAdd.length,
                        }
                      ),
                      autoClose: true,
                      type: "success",
                    });
                  }
                  if (removeResult && removeResult.error) {
                    onClearError(removeResult.error?.errorId);
                    feedback.push({
                      id: "manageDeviceClientGroupDeviceClients_remove",
                      msg: intl.formatMessage(
                        {
                          defaultMessage: "{name}: Removing device clients failed.",
                          description:
                            "failure notification. 'name' = name of the device client group",
                        },
                        {
                          name:
                            "<strong>" +
                            (group ? group.name : "impossible") +
                            "</strong>",
                        }
                      ),
                      type: "danger",
                    });
                  } else if (removeResult) {
                    feedback.push({
                      id: "manageDeviceClientGroupDeviceClients_remove",
                      msg: intl.formatMessage(
                        {
                          defaultMessage:
                            "{name}: {count} {count, plural, one {device client} other {device clients}} removed.",
                          description:
                            "Success notification. 'name' = name of the device client group, 'count' = the amount of device clientss removed",
                        },
                        {
                          name:
                            "<strong>" +
                            (group ? group.name : "impossible") +
                            "</strong>",
                          count: membersToRemove.length,
                        }
                      ),
                      autoClose: true,
                      type: "success",
                    });
                  }
                  if (feedback.length) {
                    onShowFeedback(feedback);
                  }
                },
                (rej) => {
                  throw new Error("Should not happen");
                }
              );
            }
          }
        }
        onClose();
      }}
      secondaryButton={
        group
          ? {
              label: intl.formatMessage({
                defaultMessage: "Cancel",
                description: "secondary button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={onClose}
    >
      {group && (
        <>
          <Table<Client>
            disableLoadingIndicator={!isReady}
            maxRows={15}
            header={
              <Form.Label>
                <FormattedMessage
                  defaultMessage="Select device clients"
                  description={"field label"}
                />
              </Form.Label>
            }
            compact={true}
            data-test-select-members
            search={clients && clients.length > TABLE_SEARCH_THRESHOLD}
            activeSearch={activeSearch}
            onSearch={onSetActiveSearch}
            columnToggle={false}
            reset={false}
            data={clients ? clients : isReady ? [] : undefined}
            pagination={false}
            identifyingColumn={"id"}
            selection={{
              multi: true,
              selectAll: true,
            }}
            onSelectionChanged={onSetSelected}
            selected={selected}
            columns={[
              {
                key: "id",
                label: intl.formatMessage(ClientGroupLabels.id),
                isTechnical: true,
                hidden: true,
              },
              {
                key: "name",
                isDummy: true,
                label: intl.formatMessage({
                  defaultMessage: "Device client",
                  description: "column heading for the members to select",
                }),
                sortable: true,
                renderer: (props: {
                  cell: any;
                  row: any;
                  rowIndex: Number;
                  rendererData: any;
                }) => {
                  const t = props.rendererData.resolveValue(props.row);
                  return (
                    <>
                      <span key={"link_" + props.row.id}
                            className={"link-holder"}
                      >
                        <NavigateAfterAction
                          href={"/device-clients/" + props.row.id + "/" + ModalKeys.show}
                          action={onClose}
                        >
                          <strong>{t[0]}</strong>
                          {t[1] && <small>{" <" + t[1] + ">"}</small>}
                        </NavigateAfterAction>
                      </span>
                    </>
                  );
                },
                tipRenderer: (props: {
                  cell: any;
                  row: any;
                  rowIndex: Number;
                  rendererData: any;
                }) => (
                  <FormattedMessage
                    defaultMessage="Click to select or deselect."
                    description="tooltip for toggling device client selection"
                  />
                ),
                rendererData: {
                  resolveValue: (itm: any) => {
                    return [
                      itm.name,
                      itm.clientId,
                    ];
                  },
                },
              },
            ]}
          />
        </>
      )}
      {isReady && !group && (
        <Feedback type={"danger"} show={true} asChild={true}>
          <p>
            <FormattedMessage
              defaultMessage="Something went wrong and the device client group could not be loaded. The device client group may have been removed or you don't have sufficient access rights."
              description="message to be shown when there is no device client group to display"
            />
          </p>
        </Feedback>
      )}
    </ConfirmModal>
  );
}

export default ManageDeviceClientGroupDeviceClientsModal;
