import { useEffect, useRef, useState } from "react";
import View, {
  CreateOrganizationRoleModalProps as _CreateOrganizationRoleModalProps,
  CreateOrganizationRoleModalStateProps,
} from "./create-organization-role-modal-view";
import _ from "lodash";
import { withCloseAfterExited } from "@10duke/dukeui";
import { OrganizationRole } from "../../../model/OrganizationRole";
import { PermissionWithGrantedActions } from "../../../model/PermissionWithGrantedActions";

export type CreateOrganizationRoleModalProps = Omit<
  _CreateOrganizationRoleModalProps,
  keyof CreateOrganizationRoleModalStateProps
>;

const ViewWithCloseAfterExited =
  withCloseAfterExited<_CreateOrganizationRoleModalProps>(View);

export default function CreateOrganizationRoleModal(
  props: CreateOrganizationRoleModalProps
) {
  const { show, permissions, roles, ...other } = props;
  const permissionsRef = useRef(permissions);
  if (!_.isEqual(permissionsRef.current, permissions)) {
    permissionsRef.current = permissions;
  }
  const permissionsRefCurrent = permissionsRef.current;
  const [rolePermissions, setRolePermissions] = useState<
    PermissionWithGrantedActions[]
  >([]);
  const [permissionsWithActions, setPermissionsWithActions] = useState<
    PermissionWithGrantedActions[] | undefined
  >(permissions);
  const [activePermissionsSearch, onSetActivePermissionsSearch] = useState("");

  useEffect(() => {
    if (permissionsRefCurrent) {
      setPermissionsWithActions(permissionsRefCurrent);
    }
  }, [permissionsRefCurrent, setPermissionsWithActions]);
  useEffect(() => {
    if (!show) {
      setRolePermissions([]);
      onSetActivePermissionsSearch("");
    } else {
    }
  }, [show, permissionsRefCurrent]);
  const onSetPermissions = (ents: PermissionWithGrantedActions[]) => {
    let newEdited: PermissionWithGrantedActions[] = ents;
    setRolePermissions(newEdited);
  };

  const rolesRef = useRef(roles);
  if (!_.isEqual(rolesRef.current, roles)) {
    rolesRef.current = roles;
  }
  const rolesRefCurrent = rolesRef.current;
  const [includedRoles, setIncudedRoles] = useState<OrganizationRole[]>([]);
  const [activeRoleSearch, onSetActiveRoleSearch] = useState("");
  useEffect(() => {
    if (!show) {
      setIncudedRoles([]);
      onSetActiveRoleSearch("");
    }
  }, [show, rolesRefCurrent]);

  useEffect(() => {
    if (!show) {
      setPermissionsWithActions(permissionsRefCurrent);
    }
  }, [show, permissionsRefCurrent, setPermissionsWithActions]);

  const onSetRoles = (ent: OrganizationRole[]) => {
    setIncudedRoles(ent);
  };
  const onUpdateActions = (
    obj: PermissionWithGrantedActions,
    value: string[]
  ) => {
    const newPerm = permissionsWithActions?.map((p) => {
      if (p.id === obj.id) {
        return {
          ...p,
          grantedActions: {
            allowedActions: value,
          },
        };
      } else {
        return p;
      }
    });
    setPermissionsWithActions(newPerm);
    onSetPermissions(
      rolePermissions
        ? rolePermissions.map(
            (p) =>
              newPerm?.find(
                (f) => f.id === p.id
              ) as PermissionWithGrantedActions
          )
        : []
    );
  };
  return (
    <ViewWithCloseAfterExited
      {...other}
      show={show}
      permissions={permissionsWithActions}
      onSetActivePermissionsSearch={onSetActivePermissionsSearch}
      activePermissionsSearch={activePermissionsSearch}
      rolePermissions={rolePermissions}
      onSetSelectedPermissions={onSetPermissions}
      selectedPermissions={rolePermissions}
      roles={roles}
      onSetActiveRoleSearch={onSetActiveRoleSearch}
      activeRoleSearch={activeRoleSearch}
      includedRoles={includedRoles}
      onSetSelectedRoles={onSetRoles}
      selectedRoles={includedRoles || []}
      onUpdateActions={onUpdateActions}
    />
  );
}
