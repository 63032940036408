import { ReactNode, HTMLAttributes, MouseEvent, useId } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useIntl, FormattedMessage } from "react-intl";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import "./table-view.scss";
import { ButtonGroup, Dropdown } from "react-bootstrap";
import { IconLibrary, ApplyInput, ProgressIndicator, useAppLayoutContext, Button } from "@10duke/dukeui";
import ColumnTools from "./column-tools";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderCell from "./header-cell";
import { HeaderCellProps } from "./header-cell/header-cell-view";
import { createPortal } from "react-dom";
import DropdownToggleWrapper from "../dropdown-toggle-wrapper";
import { asRBT2Column, asRTB2Selection } from "./table-utils";
import NoDataIndicator from "./no-data-indicator";
import FilterTools from "./filter-tools";
import { TableFilterInternal } from "./filter-tools/filter-tools-view";
export const TABLE_VIEW_SIZE = 100;
export const TABLE_SEARCH_THRESHOLD = 5;

//<editor-fold desc="Props">
export interface TableColumn {
  /**
   * The data field this column refers to
   */
  key: string;
  className?: string;
  /**
   * Label for the column
   */
  label: string;
  /**
   * Override default header cell label
   */
  header?: (() => string | ReactNode) | string | ReactNode;

  /**
   * Tip for the column header
   */
  tooltip?: JSX.Element | string | null;

  /**
   * Tip class for the column header
   */
  tooltipClass?: string;
  /**
   * Click hander for header
   */
  onHeaderClick?: (e: MouseEvent) => void;
  /**
   * Marks the column as technical. Technical columns are never to be shown to the user, but the data may still be required.
   */
  isTechnical?: boolean;
  align?: "left" | "center" | "right";
  /**
   * Toggles the visibility of the column. Eg. id is commonly a required identification column, but there's little point in visualising the uuid in the table
   */
  hidden?: boolean;

  /**
   *
   */
  editor?: (
    editorProps: any,
    value: any,
    row: any,
    column: string,
    rowIndex: number,
    columnIndex: number
  ) => ReactNode;
  /**
   * Toggles the availability of sort functionality
   */
  sortable?: boolean;
  /**
   * Toggles the availability of drag functionality per column. Dragging a column is enabled by default, but requires a table prop to allow column ordering
   */
  disableDragging?: boolean;
  /**
   * Marks the column as dummy, ie. has no data field
   */
  isDummy?: boolean;
  /**
   * Custom renderer for the cell
   * @param props
   *  props.cell Either the data for the cell, or some metadata describing the cell. Undocumented in the underlying component. Needs to be clarified once figured out.
   *  props.row The whole data entry that makes up the row.
   *  props.rowIndex Index of the row within the table.
   *  props.rendererData Additional data that can be passed into the renderer by populating the rendererData field for the column definition.
   */
  renderer?: (props: {
    cell: any;
    row: any;
    rowIndex: Number;
    rendererData: any;
  }) => ReactNode;
  /**
   * Custom renderer for the cell tooltip
   * @param props
   *  props.cell Either the data for the cell, or some metadata describing the cell. Undocumented in the underlying component. Needs to be clarified once figured out.
   *  props.row The whole data entry that makes up the row.
   *  props.rowIndex Index of the row within the table.
   *  props.rendererData Additional data that can be passed into the renderer by populating the rendererData field for the column definition.
   */
  tipRenderer?: (props: {
    cell: any;
    row: any;
    rowIndex: Number;
    rendererData: any;
  }) => ReactNode;
  /**
   * Custom data passed to the renderer function.
   * The return value of a member resolveValue: (row:any) => any is used for searching, if available and column is marked as isDummy: true
   */
  rendererData?: any;
}

interface TableDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "children"> {}
export interface TableStateProps
  extends Pick<HeaderCellProps, "moveColumn" | "findColumn"> {
  onSort: (column: string, ascending: boolean) => void;
  onToggleColumn?: (key: string, visible: boolean) => void;
  loadFailed?: boolean;

  visibleItemCount: number;
  setVisibleItemCount: (n: ((prev: number) => number) | number) => void;

  loadingMore: boolean;
  // The joys of typescript with npm. There is a type defined for this,
  // but that type is not exported so it can't be used here.
  // Yet a type must always be set, so there's no other option than to use any.
  loadMoreSensor: (node: any) => void;
  loadLessSensor: (node: any) => void;

  rtb2Sort: {
    dataField: string | undefined;
    order: "asc" | "desc" | undefined;
    sortCaret: (order: "asc" | "desc", column: any) => JSX.Element;
  };

  tableHolderElement: any;
  setTableHolderElement: (node: any) => void;
  filters?: TableFilterInternal[];
}
export interface TableSort {
  /**
   * data entry member
   */
  column: string;
  /**
   * toggles the sort order
   */
  ascending: boolean;
}
export interface TableSortProps {
  /**
   * Active sort for the table.
   */
  sort?: TableSort;
}
export interface TableSelection {
  /**
   * toggles the selection controls
   */
  fixed?: boolean;
  /**
   * toggles multi vs single select
   */
  multi?: boolean;
  /**
   * Toggles select all button visibility
   */
  selectAll?: boolean;
  /**
   *
   */
  selectColumnType?: "checkbox" | "radio";
  /**
   *
   */
  disabledFor?: any[];
}
export interface TableProps<D>
  extends TableStateProps,
    TableSortProps,
    TableDOMProps {
  maxRows?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 15 | 20 | 25;
  disableLoadingIndicator?: boolean;
  compact?: boolean;
  horizontalScroll?: boolean;
  /**
   * Toggles the availability if visual search tools in the table header
   */
  search?: boolean;
  /**
   * Allows for the visible column selection to be controlled
   */
  columnToggle?: boolean;
  /**
   * Toggles the reset button visibility
   */
  reset?: boolean;
  /**
   * reset handler
   */
  onReset?: () => void;
  /**
   * Toggles the reload button visibility
   */
  reload?: boolean;
  /**
   * Tools to be included as dropdown menu items within a tools column.
   * @param props
   *   props.rowEntry The data entry for the row in question.
   * @returns JSX fragment containing the dropdown menuitems
   *
   */
  rowTools?: (props: { rowEntry: D }) => ReactNode;
  /**
   * Row class resolver
   * @param row
   * @param rowIndex
   */
  rowClasses?: (row: D, rowIndex: number) => string | undefined;
  /**
   * toggles the pagination buttons
   */
  pagination: boolean;
  /**
   * Optional header element to include with the table
   */
  header?: ReactNode;
  /**
   * Defines the selection model and state. This table is stateless so it's the callers responsibility to handle the selection state.
   */
  selection?: TableSelection;
  /**
   * Sets selection state.
   */
  selected?: D[];
  /**
   * Select callback.
   * @param selection
   */
  onSelectionChanged?: (selection: D[]) => void;
  /**
   * Handler for data reload
   */
  onLoadData?: () => void;
  /**
   * Data entries for the table
   */
  data: D[] | undefined;

  /**
   * Name of the data entry member that is used as row identification. Usually 'id'
   */
  identifyingColumn: string;
  /**
   * Defines the columns for the table
   */
  columns: Array<TableColumn>;

  /**
   * Optional callback for search changes. Can be used as external state handling for the search with the activeSearch.
   * @param s
   */
  onSearch?: (s: string) => void;
  /**
   * Optional initial search value or external search state.
   */
  activeSearch?: string;
  cellEdit?: any;
}

//</editor-fold>

//<editor-fold desc="Utils">

//</editor-fold>

//<editor-fold desc="Messages">
//</editor-fold>
function Table<D>(props: TableProps<D>) {
  //<editor-fold desc="Local variables">
  let retVal;
  const intl = useIntl();
  const paginationTexts = {
    firstPageText: intl.formatMessage({
      defaultMessage: "First",
      description: "pagination, the text of first page button",
    }),
    prePageText: intl.formatMessage({
      defaultMessage: "Prev",
      description: "pagination, the text of previous page button",
    }),
    nextPageText: intl.formatMessage({
      defaultMessage: "Next",
      description: "pagination, the text of next page button",
    }),
    lastPageText: intl.formatMessage({
      defaultMessage: "Last",
      description: "pagination, the text of last page button",
    }),
    nextPageTitle: intl.formatMessage({
      defaultMessage: "Go to next",
      description: "pagination, the tooltip of next page button",
    }),
    prePageTitle: intl.formatMessage({
      defaultMessage: "Go to previous",
      description: "pagination, the tooltip of previous page button",
    }),
    firstPageTitle: intl.formatMessage({
      defaultMessage: "Go to first",
      description: "pagination, the tooltip of first page button",
    }),
    lastPageTitle: intl.formatMessage({
      defaultMessage: "Go to last",
      description: "pagination, the tooltip of last page button",
    }),
  };
  const {
    maxRows,
    compact,
    horizontalScroll,
    className,
    columns,
    data,
    identifyingColumn,
    sort,
    onSort,
    pagination,
    selection,
    rowTools,
    header,
    columnToggle,
    onToggleColumn,
    reload,
    onLoadData,
    reset,
    onReset,
    search,
    activeSearch,
    onSearch,
    onSelectionChanged,
    selected,
    rowClasses,
    disableLoadingIndicator,
    moveColumn,
    findColumn,
    loadFailed,
    visibleItemCount,
    setVisibleItemCount,
    loadingMore,
    loadMoreSensor,
    loadLessSensor,
    rtb2Sort,
    tableHolderElement,
    setTableHolderElement,
    cellEdit,
    filters,
    ...other
  } = props;
  const idPrefix = useId();
  const infiniteScroll = true;
  if (selection && !selection.fixed && !onSelectionChanged) {
    throw new Error(
      "Invalid Table props (view): onSelectionChanged is required if the selection is not fixed"
    );
  }
  if (columnToggle && !onToggleColumn) {
    throw new Error(
      "Invalid Table props (view): toggleColumn is required if columnToggle is allowed"
    );
  }
  if (reload && !onLoadData) {
    throw new Error(
      "Invalid Table props (view): onLoadData is required if reload is allowed"
    );
  }
  if (search && !onSearch) {
    throw new Error(
      "Invalid Table props (view): onSearch is required if search is allowed"
    );
  }
  let resetTool = reset;
  if (resetTool === null || resetTool === undefined) {
    resetTool =
      columnToggle ||
      search ||
      columns.findIndex((v) => v.sortable === true) >= 0;
  }
  const { headerHeight } = useAppLayoutContext();
  /**
   * convert our props to react-bootstrap-table2 format
   */
  // items in view that are not disabled or are already selected
  const selectableInView =
    data && data.length
      ? data.filter((d) => {
          const isDisabled = !!selection?.disabledFor?.find(
            (df) => df === ((d as any)[identifyingColumn] as string)
          );
          if (isDisabled) {
            // this item is disabled, but already selected
            return !!selected?.find(
              (s) =>
                (s as any)[identifyingColumn as string] ===
                (d as any)[identifyingColumn]
            );
          } else {
            return true;
          }
        })
      : [];
  const selectedInView =
    selected && selected.length
      ? selected.filter((s) => {
          const itemIsVisible = !!data?.find(
            (d) =>
              (s as any)[identifyingColumn as string] ===
              (d as any)[identifyingColumn as string]
          );
          return itemIsVisible;
        })
      : [];
  // items in view that are selected and not disabled
  const deselectableInView =
    selectedInView && selectedInView.length
      ? selectedInView.filter((s) => {
          const itemIsDisabled = !!selection?.disabledFor?.find(
            (df) => df === ((s as any)[identifyingColumn] as string)
          );
          return !itemIsDisabled;
        })
      : [];
  const selectRow = asRTB2Selection<D>(
    selection,
    selected,
    onSelectionChanged,
    data,
    identifyingColumn,
    {
      tooltips: {
        selectAll: selectableInView.length
          ? intl.formatMessage(
              {
                defaultMessage: "Select {count} in view",
                description:
                  "tooltip for the select column heading when the action is select. count = number of items to select",
              },
              {
                count: selectableInView.length,
              }
            )
          : undefined,
        deselectAll: deselectableInView.length
          ? intl.formatMessage(
              {
                defaultMessage: "Deselect {count} in view",
                description:
                  "tooltip for the select column heading when the action is deselect.  count = number of items to deselect",
              },
              {
                count: deselectableInView.length,
              }
            )
          : undefined,
      },
      isSelect: deselectableInView.length === 0,
      selectedInView: selectedInView,
      selectableInView: selectableInView,
      deselectableInView: deselectableInView,
    },
    !!cellEdit
  );
  const cols = columns.map((val: TableColumn, ind: number) => {
    return asRBT2Column(val, {
      sort: sort,
      onSort: onSort,
      moveColumn: moveColumn,
      findColumn: findColumn,
      index: ind,
      allowDrag: columns.filter((v) => !v.hidden).length > 1,
      tableHolderElement: tableHolderElement,
      stickyTop: headerHeight,
    });
  });
  if (rowTools) {
    // add tools column as last column
    const col: TableColumn = {
      key: "rowTools",
      label: intl.formatMessage({
        defaultMessage: "Tools",
        description: "tooltip for the row tools heading",
      }),
      disableDragging: true,
    };
    cols.push({
      dataField: col.key,
      headerClasses: "row-tools-cell",
      text: col.label,
      isDummyField: true,
      headerStyle:
        headerHeight !== undefined
          ? {
              top: headerHeight + "px",
            }
          : undefined,
      headerFormatter: (column: any, columnIndex: number, components: any) => {
        return (
          <HeaderCell
            index={cols.length - 1}
            tip={col.label}
            column={col}
            moveColumn={props.moveColumn}
            findColumn={props.findColumn}
            allowDrag={false}
            tableHolderElement={tableHolderElement}
          >
            <div className={"duke-tool-cell"}>
              <div className={"icon d-block"}>
                {IconLibrary.customIcons.rowTools}
              </div>
            </div>
          </HeaderCell>
        );
      },
      formatter: (cell: any, row: any, rowIndex: number, rendererData: any) => {
        return (
          <Dropdown>
            <Dropdown.Toggle
              as={DropdownToggleWrapper}
              id={idPrefix + "rowTools_" + rowIndex}
              data-test-row-tool-trigger={row.id}
            >
              <FontAwesomeIcon icon={IconLibrary.icons.faEllipsisH} />
            </Dropdown.Toggle>
            {createPortal(
              <Dropdown.Menu
                data-test-row-tool-items
                popperConfig={{
                  modifiers: [
                    {
                      name: "computeStyles",
                      options: {
                        gpuAcceleration: false, // true by default. Without this random conf, unnecessary scrollbars are added to parents
                      },
                    },
                  ],
                }}
              >
                {rowTools ? rowTools({ rowEntry: row }) : null}
              </Dropdown.Menu>,
              document.querySelector("body") as HTMLBodyElement
            )}
          </Dropdown>
        );
      },
    });
  }
  if (selectRow) {
    (selectRow as any).headerColumnStyle =
      headerHeight !== undefined
        ? {
            top: headerHeight + "px",
          }
        : undefined;
  }
  const includeTools = columnToggle || filters || reload || search;
  const includeHeader = header || includeTools;
  //</editor-fold>

  //<editor-fold desc="Partials">
  const noDataIndicator = () => {
    return (
      <NoDataIndicator
        data={data}
        disableLoadingIndicator={disableLoadingIndicator}
        loadFailed={loadFailed}
        search={search}
        activeSearch={activeSearch}
        onSearch={onSearch}
        filters={filters}
      />
    );
  };
  //</editor-fold>
  const datalength = data ? data.length : 0;

  retVal = (
    <DndProvider backend={HTML5Backend}>
      <div
        className={
          (className ? className : "") +
          " duke-table" +
          (horizontalScroll ? " horizontal-scrolling" : "") +
          (compact ? " duke-table-compact" : "") +
          (maxRows ? " max-rows-" + maxRows : "")
        }
        {...other}
      >
        {includeHeader && (
          <header className={"duke-table-header"}>
            {header}
            {includeTools && (
              <div className={"duke-table-header-tools"}>
                {search && onSearch && (
                  <ApplyInput
                    size={compact ? "sm" : undefined}
                    data-test-table-search
                    applyLabel={intl.formatMessage({
                      defaultMessage: "Search",
                      description: "the label of the search button",
                    })}
                    clearDirtyInputTip={intl.formatMessage({
                      defaultMessage: "Clear unapplied search input.",
                      description:
                        "the tooltip of the clear unapplied input icon button",
                    })}
                    clearInputTip={intl.formatMessage({
                      defaultMessage: "Clear search",
                      description:
                        "the tooltip of the clear search icon button",
                    })}
                    activeValue={activeSearch}
                    value={activeSearch}
                    onApply={onSearch}
                  />
                )}
                {(columnToggle || filters || reload || resetTool) && (
                  <ButtonGroup size={compact ? "sm" : undefined}>
                    {filters && <FilterTools filters={filters} compact={compact} />}
                    {columnToggle && (
                      <ColumnTools
                        columns={columns}
                        onToggleColumn={onToggleColumn}
                      />
                    )}
                    {resetTool && onReset && (
                      <Button
                        data-test-table-reset-trigger
                        variant={"primary"}
                        className={"btn custom-base"}
                        type={"button"}
                        action={onReset}
                        tooltip={intl.formatMessage({
                              defaultMessage: "Reset defaults",
                              description: "the tooltip of reset button",
                            })
                        }
                        icon={IconLibrary.customIcons.resetSettings}
                      />
                    )}
                    {reload && onLoadData && (
                      <Button
                        data-test-table-reload-trigger
                        variant={"primary"}
                        className={"btn custom-base"}
                        type={"button"}
                        action={onLoadData}
                        tooltip={intl.formatMessage({
                          defaultMessage: "Reload data",
                          description: "the tooltip of reload button",
                        })}
                        icon={IconLibrary.customIcons.reload}
                      />
                    )}
                  </ButtonGroup>
                )}
              </div>
            )}
          </header>
        )}
        <PaginationProvider
          pagination={paginationFactory({
            ...paginationTexts,
            custom: true,
            totalSize: datalength,
            sizePerPage: infiniteScroll ? visibleItemCount : datalength,
          })}
        >
          {(pp: any) => (
            <>
              <div className={"duke-table-holder"} ref={setTableHolderElement}>
                <div className={"react-bootstrap-table-wrapper"}>
                  {infiniteScroll && datalength > TABLE_VIEW_SIZE && (
                    <div
                      data-test-infinite-scroll-less-sensor
                      ref={loadLessSensor}
                      className={"infinite-scroll-sensor rendering-less"}
                    ></div>
                  )}
                  <BootstrapTable
                    condensed={compact}
                    hover={!!selection}
                    striped
                    className={className}
                    bootstrap4
                    noDataIndication={noDataIndicator}
                    data={data || ([] as D[])}
                    columns={cols}
                    keyField={identifyingColumn}
                    sort={rtb2Sort}
                    selectRow={selectRow}
                    rowClasses={rowClasses}
                    cellEdit={cellEdit}
                    {...pp.paginationTableProps}
                  />
                  {infiniteScroll && datalength > visibleItemCount && (
                    <>
                      <div
                        data-test-infinite-scroll-more-sensor
                        ref={loadMoreSensor}
                        className={"infinite-scroll-sensor rendering-more"}
                      >
                        <ProgressIndicator
                            show={true}
                            backdrop={false}
                        />
                        <Button
                          variant={"link"}
                          className={"btn w-100"}
                          type={"button"}
                          action={() => {
                            setVisibleItemCount(
                              (prev) => prev + TABLE_VIEW_SIZE
                            );
                          }}
                        >
                          <FormattedMessage
                            defaultMessage="Show more"
                            description={
                              "Show more button label. Shown when scrolling to end of view port"
                            }
                          />
                        </Button>
                      </div>
                    </>
                  )}
                  {infiniteScroll &&
                    datalength <= visibleItemCount &&
                    datalength > TABLE_VIEW_SIZE && (
                      <div
                        data-test-infinite-scroll-end
                        className={"infinite-scroll-sensor end-reached"}
                      >
                        <FormattedMessage
                          defaultMessage="No more results to show."
                          description={
                            "message shown when the table has been scrolled to end."
                          }
                        />
                      </div>
                    )}
                </div>
              </div>
            </>
          )}
        </PaginationProvider>
        {selection &&
        selectRow &&
        !selectRow.hideSelectColumn &&
        selected &&
        selected.length ? (
          <footer className={"duke-table-footer"}>
            <FormattedMessage
              defaultMessage="Selected: {count}"
              description={
                "Shows the count of currently selected items. count = the number of items, always greater than 0"
              }
              values={{
                count: selected.length,
              }}
            />
          </footer>
        ) : undefined}
      </div>
    </DndProvider>
  );

  return retVal;
}
export default Table;
