import * as ActionTypes from "../actions/actionTypes";
import {ClientsById} from "../store/ClientState";

export default function clients(
  state: ClientsById,
  action: ActionTypes.AppAction
): ClientsById | null {
  const currentState = state || ({} as ClientsById);
  switch (action.type) {
    case ActionTypes.LIST_ORG_CLIENTS:
      const listOrgClients = action as ActionTypes.ListOrgClientsAction;
      const newOrgClientsById = listOrgClients.clients.reduce<ClientsById>(
        (map, client) => {
          map[client.id as string] = client;
          return map;
        },
        {}
      );
      return { ...currentState, ...newOrgClientsById };
    case ActionTypes.GET_ORG_CLIENT:
      const getOrgClient = action as ActionTypes.GetOrgClientAction;
      return {
        ...currentState,
        [getOrgClient.client.id as string]: getOrgClient.client
      };
    case ActionTypes.REPLACE_CLIENT:
      const replaceOrgClient = action as ActionTypes.GetOrgClientAction;
      return {
        ...currentState,
        [replaceOrgClient.client.id as string]: replaceOrgClient.client
      };
    case ActionTypes.DELETE_ORG_CLIENT:
      const deleteClient = action as ActionTypes.DeleteOrgClientAction;
      const { [deleteClient.clientId]: _, ...remaining } = currentState;
      return remaining;
    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}


function handleErrorAction(
  currentState: ClientsById,
  action: ActionTypes.AppAction
): ClientsById {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  if (
    errorAction.error.action.type === ActionTypes.GET_ORG_CLIENT &&
    errorAction.error.apiError.error === "404"
  ) {
    const typedError = action as ActionTypes.AddErrorAction<
      ActionTypes.GetOrgClientAction
    >;
    const { [typedError.error.action?.clientId as string]: _, ...remaining } = currentState;
    finalState = remaining;
  }

  return finalState;
}
