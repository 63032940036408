import { connect } from "react-redux";
import Users, {
  UsersDOMProps,
  UsersProps as _UsersProps,
  UsersModalVisibilityProps,
} from "./users-container";
import { AppState } from "../../../store/AppState";
import OrganizationUtils from "../../../utils/organization";
import {
  listOrganizationGroups,
  listOrganizationRolesWithUsers,
} from "../../../actions";

import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import UserUtils, { UserWithStatus } from "../../../utils/user";
import { ActionSender } from "../../../model/ActionSender";
import AuthenticationUtils from "../../../utils/authentication";
import { addSenderArgument } from "../../../actions/actionHelpers";

export { ModalKeys } from "./users-container";

export interface UsersProps extends UsersDOMProps, UsersModalVisibilityProps {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_UsersProps, UsersProps>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<_UsersProps, UsersProps>;

const sender: ActionSender = { key: "users" };

function mapStateToProps(state: AppState): ReduxStateProps {
  const users = OrganizationUtils.selectOrganizationUsers(state);
  const orgAdmins = OrganizationUtils.selectOrganizationAdminIds(state, false);

  let usersWithExtra: UserWithStatus[] | undefined = undefined;
  if (users && orgAdmins) {
    usersWithExtra = UserUtils.usersWithStatus(
      users.map((usr) => {
        const user = !!usr ? { ...usr } : usr;
        (user as any).isOrgAdmin = orgAdmins.indexOf(user.id as string) !== -1;
        return user;
      })
    );
  }

  return {
    users: usersWithExtra,
    groups: OrganizationUtils.selectOrganizationGroups(state),
    userName: AuthenticationUtils.selectAuthenticationUserDisplayName(state),
    userId: AuthenticationUtils.selectAuthenticationUserId(state),
    orgAdminRoleId: OrganizationUtils.selectOrgAdminRoleId(state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    AuthenticationUtils.compareAuthenticationUserDisplayNameStates(
      next,
      prev
    ) &&
    AuthenticationUtils.compareAuthenticationUserIdStates(next, prev) &&
    OrganizationUtils.compareOrganizationGroupsStates(next, prev) &&
    OrganizationUtils.compareOrganizationUsersStates(next, prev) &&
    OrganizationUtils.compareOrganizationRolesStates(next, prev) &&
    OrganizationUtils.compareOrganizationRoleUserIdsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadUsers: addSenderArgument(sender, listOrganizationRolesWithUsers),
  onLoadGroups: addSenderArgument(sender, listOrganizationGroups),
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  UsersProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(Users);
