import { Entitlement } from "../../../model/entitlement/Entitlement";

export const ORG_2_ENTITLEMENT_1: Entitlement = {
  id: "184e6448-ea3b-4787-bc8d-5d756200fe08",
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T07:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-06-11T07:01:00.000Z",
  validFrom: "2020-06-11T07:00:00.000Z",
  validUntil: "2120-06-11T07:00:00.000Z",
  isDefault: false,
  name: "org2ent1",
  description: "org 2 entitlement 1",
  type: "mock"
};

export const ORG_2_ENTITLEMENTS: Entitlement[] = [ORG_2_ENTITLEMENT_1];

export const ORG_2_GROUP_IDS_BY_ENTITLEMENT_ID = {
  [ORG_2_ENTITLEMENT_1.id as string]: [] as string[]
};
