import { withCloseAfterExited } from "@10duke/dukeui";
import {
  default as View,
  RevokeDeviceClientInvitationModalProps
} from "./revoke-device-client-invitation-modal-view";

const RevokeDeviceClientInvitationModal = withCloseAfterExited<RevokeDeviceClientInvitationModalProps>(
  View
);
export default RevokeDeviceClientInvitationModal;
