import { useEffect, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {FeedbackEntry, Feedback, Modal} from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import { User } from "../../../model/User";
import {
  ClearErrorAction,
  GetUserAction,
  isAddErrorAction,
  SetUserSuspendedAction,
} from "../../../actions/actionTypes";
import UserUtils, { UserWithStatus } from "../../../utils/user";
import { UserValues } from "../../../localization/user";

//<editor-fold desc="Props">

const resolveUserName = (
  user: User | undefined | null,
  intl: { formatMessage: (v: any, v2?: any) => string }
) =>
  UserUtils.resolveDisplayName(
    user,
    intl.formatMessage(UserValues.displayName.undefined)
  );
export interface SuspendUserModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface SuspendUserModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  user: UserWithStatus | undefined | null;
  actorId: string;
  onSuspendUser: (id: string) => Promise<SetUserSuspendedAction>;
  onLoadUser: (id: string) => Promise<GetUserAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface SuspendUserModalProps
  extends SuspendUserModalDOMProps,
    SuspendUserModalDataProps,
    Omit<ClosableModalProps, "isReady"> {
  onShowFeedback: (feedback: FeedbackEntry) => void;
  userId?: string;
}
//</editor-fold>

function SuspendUserModal(props: SuspendUserModalProps) {
  //<editor-fold desc="Local variables">
  let {
    userId,
    user,
    actorId,
    show,
    onClose,
    onLoadUser,
    onSuspendUser,
    onShowFeedback,
    isReady,
    onExited,
    onClearError,
  } = props;

  const userObjId = user ? user.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!userId &&
      (userObjId === undefined || (!!userObjId && userObjId !== userId)) &&
      !!onLoadUser
    ) {
      onLoadUser(userId).then((res) => {
        if (!userObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, userId, userObjId, onLoadUser, onClearError]);
  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>

  return (
    <Modal
      onReloadData={() => {
        if ((user || userId) && onLoadUser) {
          onLoadUser(user ? (user.id as string) : (userId as string)).then(
            (res) => {
              if (!user && isAddErrorAction(res)) {
                // only clear error if no data exists, leave if previous data is still available and
                // let the api error notification handler show error
                onClearError(res.error?.errorId);
              }
            }
          );
        }
      }}
      onExited={onExited}
      isReady={isReady}
      data-test-suspend-user-modal={user ? (user.id as string) : true}
      title={
        user || !isReady
          ? intl.formatMessage(
              {
                defaultMessage: "Suspend {name}",
                description: "modal heading. 'name' = name of the user",
              },
              {
                name: resolveUserName(user, intl),
              }
            )
          : intl.formatMessage({
              defaultMessage: "User not found",
              description: "modal heading when user not found",
            })
      }
      show={show}
      onClose={onClose}
      primaryButton={
        user && user.status !== "suspended" && user.id !== actorId
          ? {
              label: intl.formatMessage({
                defaultMessage: "Suspend",
                description: "primary button label",
              }),
            }
          : {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
      }
      onPrimaryAction={() => {
        if (
          show &&
          user &&
          user.status !== "suspended" &&
          user.id !== actorId
        ) {
          onSuspendUser(user.id as string).then((res) => {
            if (!isAddErrorAction(res)) {
              onShowFeedback({
                id: "suspend_" + (user ? (user.id as string) : ""),
                msg: intl.formatMessage(
                  {
                    defaultMessage: "{name} has been suspended.",
                    description:
                      "Success notification. 'name' = name of the user",
                  },
                  {
                    name:
                      "<strong>" + resolveUserName(user, intl) + "</strong>",
                  }
                ),
                autoClose: true,
                type: "success",
              });
            } else {
              onClearError(res.error?.errorId);
              onShowFeedback({
                id: "suspend_" + (user ? (user.id as string) : ""),
                msg: intl.formatMessage(
                  {
                    defaultMessage: "Suspending {name} failed: {msg}",
                    description:
                      "failure notification 'name' = name of the user, 'msg' = error message",
                  },
                  {
                    name:
                      "<strong>" + resolveUserName(user, intl) + "</strong>",
                    msg: res.error.apiError.error_description,
                  }
                ),
                type: "danger",
              });
            }
          });
        }
        onClose();
      }}
      secondaryButton={
        user && user.status !== "suspended" && user.id !== actorId
          ? {
              label: intl.formatMessage({
                defaultMessage: "Cancel",
                description: "secondary button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={
        user && user.id !== actorId && user.status !== "suspended"
          ? onClose
          : undefined
      }
    >
      <>
        {show && user && (
          <>
            {user.id === actorId && (
              <Feedback type={"danger"} show={true} asChild={true}>
                <p>
                  <FormattedMessage
                    defaultMessage="{name}, you're not allowed to suspend yourself."
                    description="message for disabled self removal. 'name' = name of the user"
                    values={{
                      name: <strong>{resolveUserName(user, intl)}</strong>,
                    }}
                  />
                </p>
              </Feedback>
            )}
            {user.status !== "suspended" && user.id !== actorId && (
              <p>
                <FormattedMessage
                  defaultMessage="Are you sure you wish to suspend {name}?"
                  description="confirm action message. 'name' = name of the user"
                  values={{
                    name: <strong>{resolveUserName(user, intl)}</strong>,
                  }}
                />
              </p>
            )}
            {user.status === "suspended" && user.id !== actorId && (
              <Feedback type={"danger"} show={true} asChild={true}>
                <p>
                  <FormattedMessage
                    defaultMessage="{name} is already suspended."
                    description="message to be shown when the user is already suspended"
                    values={{
                      name: <strong>{resolveUserName(user, intl)}</strong>,
                    }}
                  />
                </p>
              </Feedback>
            )}
          </>
        )}
        {show && !user && isReady && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p>
              <FormattedMessage
                defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
                description="message to be shown when there is no user to display"
              />
            </p>
          </Feedback>
        )}
      </>
    </Modal>
  );
}

export default SuspendUserModal;
