import { HttpError, ok } from "oazapfts";
import { ApiResponse, WithHeaders } from "oazapfts/lib/runtime";
import { InternalApiError, ApiError } from "../../model/ApiError";
import {
  MembershipRemovingResult,
  OrganizationRemovingResult,
} from "../IdpApi";

const SUCCESS_CODES = [200, 201, 202, 204] as const;
async function okWithFullResponse(
  promise: Promise<WithHeaders<ApiResponse>>
): Promise<WithHeaders<ApiResponse>> {
  const res = await promise;
  if (SUCCESS_CODES.some((s) => s === res.status)) {
    return res;
  }
  throw new HttpError(res.status, res.data, res.headers);
}
/**
 * Awaits for and handles result of an API call.
 *
 * This method handles exceptions thrown by the API call and tries to parse error response data.
 *
 * @param method Api method to call.
 */
export async function handleApiCallWithFullResponse<T>(
  resultPromise: Promise<WithHeaders<ApiResponse>>
): Promise<{ result: T; headers: Headers; status: number }> {
  try {
    const t = await okWithFullResponse(resultPromise);
    return { result: t.data, headers: t.headers, status: t.status };
  } catch (error) {
    throw getApiError(error as HttpError);
  }
}
export async function handleApiCall<T>(
  resultPromise: Promise<WithHeaders<ApiResponse>>
): Promise<T> {
  try {
    return await ok(resultPromise);
  } catch (error) {
    throw getApiError(error as HttpError);
  }
}
function getApiError(error: HttpError): ApiError {
  if (error.data) {
    let apiErrorWithResponseData = undefined;
    if (typeof error.data === "string") {
      apiErrorWithResponseData = JSON.parse(error.data) as ApiError;
    } else {
      apiErrorWithResponseData = error.data as ApiError;
    }

    return apiErrorWithResponseData;
  }

  const apiError = new InternalApiError(error.status.toString(), error.message);

  return apiError;
}

/**
 * Resolves ids from tenduke api response header
 * @param headerValue
 */
function resolveIDs(
  headerValue: string | null | undefined
): string[] | undefined {
  if (headerValue) {
    // convert to comma separated list
    const tmp = headerValue.replaceAll(/\[|\]|\s/gm, "");
    return tmp !== "" ? tmp.split(",") : [];
  } else {
    return undefined;
  }
}
export function createMembershipRemovingResult(
  response: WithHeaders<ApiResponse> | undefined
): MembershipRemovingResult {
  const tmp = resolveIDs(
    response?.headers?.get("tenduke-no-memberships-user-ids")
  );
  if (tmp) {
    return {
      noMembershipUserIds: tmp,
    };
  } else {
    return {};
  }
}
export function createOrganizationRemovingResult(
  response: WithHeaders<ApiResponse> | undefined
): OrganizationRemovingResult {
  const tmp = resolveIDs(
    response?.headers?.get("tenduke-no-memberships-org-ids")
  );
  if (tmp) {
    return {
      noMembershipOrgIds: tmp,
    };
  } else {
    return {};
  }
}
