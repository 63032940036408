import { withCloseAfterExited } from "@10duke/dukeui";
import View, {
  DownloadLicenseModalProps as _DownloadLicenseModalProps,
  DownloadLicenseModalStateProps,
} from "./download-license-modal-view";
import { useContext, useEffect, useState } from "react";
import { User } from "../../../model/User";
import UIConfiguration from "../../../ui-configuration/configuration-provider";

const ViewWithCloseAfterExited =
  withCloseAfterExited<_DownloadLicenseModalProps>(View);

export type DownloadLicenseModalProps = Omit<
  _DownloadLicenseModalProps,
  keyof DownloadLicenseModalStateProps
>;
export default function DownloadLicenseModal(props: DownloadLicenseModalProps) {
  const { show, ...other } = props;

  const { conf } = useContext(UIConfiguration);
  const showVersion =
    conf.functionality &&
    conf.functionality.enableDownloadLicenseVersion !== null &&
    conf.functionality.enableDownloadLicenseVersion !== undefined
      ? conf.functionality.enableDownloadLicenseVersion
      : false;
  const [formData, onSetFormData] = useState(
    showVersion ? { hardwareId: "", version: "" } : { hardwareId: "" }
  );

  const [failed, onSetFailed] = useState<any[] | undefined>(undefined);
  const [activeSearch, onSetActiveSearch] = useState("");
  const [resultBlob, onSetResultBlob] = useState<Blob | undefined>(undefined);
  const [selected, onSetSelected] = useState<Array<User>>([]);
  useEffect(() => {
    if (!show) {
      onSetActiveSearch("");
      onSetFailed(undefined);
      if (showVersion) {
        onSetFormData({ hardwareId: "", version: "" });
      } else {
        onSetFormData({ hardwareId: "" });
      }
      onSetResultBlob(undefined);
      onSetSelected([]);
    }
  }, [
    show,
    showVersion,
    onSetActiveSearch,
    onSetFailed,
    onSetFormData,
    onSetResultBlob,
    onSetSelected,
  ]);

  return (
    <ViewWithCloseAfterExited
      {...other}
      showVersion={showVersion}
      formData={formData}
      onSetFormData={onSetFormData}
      show={show}
      activeSearch={activeSearch}
      onSetActiveSearch={onSetActiveSearch}
      selected={selected}
      onSetSelected={onSetSelected}
      resultBlob={resultBlob}
      onSetResultBlob={onSetResultBlob}
      failed={failed}
      onSetFailed={onSetFailed}
    />
  );
}
