import { AppState } from "./AppState";
import _debug from "debug";
import appStorageFactory from "application-storage/dist";
const debug = _debug("localStorage");

const appStateStorage = appStorageFactory("AppState", "local");
const sessionStateStorage = appStorageFactory("SessionState", "session");

export function loadState(): AppState | undefined {
  const app = appStateStorage.getValue();
  const sess = sessionStateStorage.getValue();
  if (!app && !sess) {
    return undefined;
  } else if (app && !sess) {
    return app;
  } else if (sess && !app) {
    return sess;
  } else {
    return { ...app, ...sess };
  }
}

export function saveState(state: AppState): void {
  try {
    const { pendingAuthentication, ...other } = state;
    appStateStorage.setValue(other);
    if (!!pendingAuthentication) {
      sessionStateStorage.setValue({ pendingAuthentication });
    } else {
      sessionStateStorage.removeValue();
    }
  } catch (err) {
    if (debug.enabled) {
      debug(`save state failed: ${err}`);
    }
  }
}
