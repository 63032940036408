import { connect } from "react-redux";
import OrganizationUtils from "../../../utils/organization";
import {
  RemoveUserModalDataProps
} from "./remove-user-modal-view";
import {RemoveUserModalProps as _RemoveUserModalProps} from "./remove-user-modal-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import { clearError, getUser } from "../../../actions";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";
import RemoveUserModal from "./remove-user-modal-container";
import InProgressUtils from "../../../utils/in-progress";
import AuthenticationUtils from "../../../utils/authentication";
import { detachUserFromOrganization } from "../../../actions/detachUserFromOrgMultiAction";

export interface RemoveUserModalProps
  extends Omit<_RemoveUserModalProps, keyof RemoveUserModalDataProps> {
  // for some reason in this case the omit does not remove all keys with keyof  RemoveGroupModalDataProps like it does
  // elsewhere. So far I have not been able to figure out a way to inspect/debug what is actually there to be removed.
}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _RemoveUserModalProps,
  RemoveUserModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _RemoveUserModalProps,
  RemoveUserModalProps
>;

const sender: ActionSender = { key: "remove-user" };

function mapStateToProps(
  state: AppState,
  ownProps: RemoveUserModalProps
): ReduxStateProps {
  return {
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    actorId: AuthenticationUtils.selectAuthenticationUserId(state) as string,
    user: ownProps.userId
      ? OrganizationUtils.selectOrganizationUser(ownProps.userId, state)
      : undefined,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    AuthenticationUtils.compareAuthenticationUserIdStates(next, prev) &&
    OrganizationUtils.compareOrganizationUsersStates(next, prev)
  );
}
const dispatchActions: ReduxDispatchProps = {
  onRemoveUser: addSenderArgument(sender, detachUserFromOrganization),
  onLoadUser: addSenderArgument(sender, getUser),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  RemoveUserModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(RemoveUserModal);
