import * as ActionTypes from "../actions/actionTypes";
import {UserIdsByEntitlementId} from "../store/EntitlementState";

export default function entitlementUsers(
  state: UserIdsByEntitlementId,
  action: ActionTypes.AppAction
): UserIdsByEntitlementId | null {
  const currentState = state || ({} as UserIdsByEntitlementId);
  switch (action.type) {
    case ActionTypes.REMOVE_USER_FROM_ORG:
    case ActionTypes.DELETE_USER:
      const removeUserAction = action as (ActionTypes.RemoveUserFromOrgAction | ActionTypes.DeleteUserAction);
      const entIds = Object.keys(currentState);
      const newState: UserIdsByEntitlementId = {};
      entIds.forEach((id) =>  {
        newState[id] = currentState[id].filter((f) => f !== removeUserAction.userId);
      })
      return newState;
    case ActionTypes.LIST_ENT_CONSUMING_USERS:
      const listEntConsumingUsersAction = action as ActionTypes.ListEntConsumingUsersAction
      return { ...currentState,  ...{ [listEntConsumingUsersAction.entId]: listEntConsumingUsersAction.userIds} };
    case ActionTypes.START_AUTHN:
      return null;
    default:
      return state || null;
  }
}
