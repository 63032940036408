import * as ActionTypes from "../actions/actionTypes";
import { EntitlementIdsByOrgId } from "../store/EntitlementState";

export default function orgEntitlementIds(
  state: EntitlementIdsByOrgId,
  action: ActionTypes.AppAction
): EntitlementIdsByOrgId | null {
  const currentState = state || ({} as EntitlementIdsByOrgId);
  switch (action.type) {
    case ActionTypes.LIST_ORG_ENTITLEMENTS:
      const listEntitlements = action as ActionTypes.ListEntitlementsAction;
      const newOrgEntitlementIds = listEntitlements.entitlements.map(
        entitlement => entitlement.id as string
      );
      return {
        ...currentState,
        [listEntitlements.orgId]: newOrgEntitlementIds
      };
    case ActionTypes.GET_ORG_ENTITLEMENT:
      const actionResult = action as ActionTypes.GetOrgEntitlementAction;
      const orgsEntIds = [...(currentState[actionResult.orgId] || [])];
      if (!orgsEntIds.includes(actionResult.entitlement.id as string)) {
        orgsEntIds.push(actionResult.entitlement.id as string);
      }
      return { ...currentState, [actionResult.orgId]: orgsEntIds };
    case ActionTypes.LIST_ENTITLEMENTS_CONSUMED_BY_ORG_GROUP: {
      const actionResult = action as ActionTypes.ListEntitlementsConsumedByOrgGroup;
      const orgsExistingEntitlementIds = currentState[actionResult.orgId] || [];
      const orgsNewEntitlementIds = actionResult.entitlements
        .map(ent => ent.id as string)
        .filter(entId => !orgsExistingEntitlementIds.includes(entId));
      return {
        ...currentState,
        [actionResult.orgId]: [
          ...orgsExistingEntitlementIds,
          ...orgsNewEntitlementIds
        ]
      };
    }
    case ActionTypes.START_AUTHN:
      return null;
    default:
      return state || null;
  }
}
