import { withCloseAfterExited } from "@10duke/dukeui";
import {
  default as View,
  ResendInvitationModalProps
} from "./resend-invitation-modal-view";

const ResendInvitationModal = withCloseAfterExited<ResendInvitationModalProps>(
  View
);
export default ResendInvitationModal;
