import * as ActionTypes from "../actions/actionTypes";
import { AppError } from "../model/AppError";
import _debug from "debug";
const debug = _debug("Reducer:errors");

export default function errors(
  state: AppError<any>[],
  action: ActionTypes.AppAction
): AppError<any>[] | null {
  switch (action.type) {
    case ActionTypes.ADD_ERROR:
      const addErrorAction = action as ActionTypes.AddErrorAction<any>;
      if (debug.enabled) {
        debug(JSON.stringify(addErrorAction.error));
      }
      return (state || []).concat(addErrorAction.error);
    case ActionTypes.CLEAR_ERROR:
      const clearErrorAction = action as ActionTypes.ClearErrorAction;
      return (state || []).filter(
        (err) => err.errorId !== clearErrorAction.errorId
      );
    case ActionTypes.CLEAR_ALL_ERRORS:
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return [];
    default:
      return state || [];
  }
}
