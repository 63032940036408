import { defineMessages } from "react-intl";
import { BaseObjLabels } from "../base-obj";
import { ValidityFieldsLabels } from "../validity-fields";

/**
 * Localized labels for OrganizationGroupInvitation fields
 */
export const OrganizationGroupInvitationLabels = {
  ...BaseObjLabels,
  // Add AuthorEditorFields if needed
  ...ValidityFieldsLabels,
  ...defineMessages({
    // organizationRoleIds
    groupIds: {
      defaultMessage: "user groups",
      description:
        "Field label for targeted organization user groups (OrganizationGroupInvitation.groupIds)",
    },
    // organizationId
    // clientRoleIds
    email: {
      defaultMessage: "recipient email",
      description:
        "Field label for invitation recipient email (OrganizationGroupInvitation.email)",
    },
    invitationState: {
      defaultMessage: "state",
      description:
        "Field label for the state of the invitation (OrganizationGroupInvitation.invitationState)",
    },
    // clientData
    // memberWelcomeUrl
    // invitationCompletedUrl
    // claimedAt
    // declinedAt
    // revokedAt
    recipientName: {
      defaultMessage: "recipient name",
      description:
        "Field label for the name of the recipient (OrganizationGroupInvitation.recipientName)",
    },
    inviterName: {
      defaultMessage: "inviter name",
      description:
        "Field label for the name of the inviter (OrganizationGroupInvitation.inviterName)",
    },
    // recipientCountryCode
    // recipientLanguageCode
    invitationScopeInformation: {
      defaultMessage: "message",
      description:
        "Field label for a custom message attached to the invitation (OrganizationGroupInvitation.invitationScopeInformation)",
    },
    isOrgAdmin: {
      defaultMessage: "organization admin",
      description:
        "Field label for a field indicating if the invitation includes an orgadmin status for the recipient (OrganizationGroupInvitation.isOrgAdmin)",
    },
  }),
};

/**
 * Localized values for OrganizationGroupInvitation fields
 */
export const OrganizationGroupInvitationValues = {
  // no extension needed for now
  isOrgAdmin: defineMessages({
    true: {
      defaultMessage: "Yes",
      description:
        "Label for the invitation including an orgadmin status for the recipient (OrganizationGroupInvitation.isOrgAdmin 'true')",
    },
    false: {
      defaultMessage: "No",
      description:
        "Label for the invitation not including an orgadmin status for the recipient (OrganizationGroupInvitation.isOrgAdmin 'false')",
    },
  }),
  invitationState: defineMessages({
    deliveryRequested: {
      defaultMessage: "pending",
      description:
        "Label for invitation state when the invitation has been sent and its waiting for a response from the recipient (OrganizationGroupInvitation.invitationState 'deliveryRequested')",
    },
    updated: {
      defaultMessage: "updated",
      description:
        "Label for invitation state when the invitation has been updated (OrganizationGroupInvitation.invitationState 'updated')",
    },
    created: {
      defaultMessage: "unsent",
      description:
        "Label for invitation state when the invitation has been created (OrganizationGroupInvitation.invitationState 'created')",
    },
    revoked: {
      defaultMessage: "revoked",
      description:
        "Label for invitation state when the invitation has been revoked (OrganizationGroupInvitation.invitationState 'revoked')",
    },
    accepted: {
      defaultMessage: "accepted",
      description:
        "Label for invitation state when the invitation has been accepted (OrganizationGroupInvitation.invitationState 'accepted')",
    },
    declined: {
      defaultMessage: "declined",
      description:
          "Label for invitation state when the invitation has been declined (OrganizationGroupInvitation.invitationState 'declined')",
    },
    expired: {
      defaultMessage: "expired",
      description:
          "Label for invitation state when the invitation has expired (OrganizationGroupInvitation.invitationState 'expired')",
    },
  }),
};
