import { ActionSender } from "../model/ActionSender";
import * as ActionTypes from "./actionTypes";
import {
  buildActionThunk,
  ensureSelectedOrgId,
  forceUndefined,
} from "./actionHelpers";
import { Permission } from "../model/Permission";
import { idpApi } from "../api";

export function listPermissions(
  sender: ActionSender,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListPermissions> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ListPermissions, Permission[]>(
    sender,
    ActionTypes.LIST_PERMISSIONS,
    async () => await idpApi.listPermissions(),
    (type, permissions) => ({
      type,
      permissions: forceUndefined(permissions),
      orgId: orgIdOrDefault,
    })
  );
}
