import { default as View } from "./localize-view";
import { useCallback, useContext, useEffect, useState } from "react";
import { OrgadminLocalizationLocale } from "../../ui-configuration/orgadmin-configuration";
import UIConfiguration from "../../ui-configuration/configuration-provider";
import {
  LocaleManager,
  ResolvedFrom,
  ResolvedLocaleData,
} from "../../utils/locale";

const ALIAS_SEPARATOR = "|";
/**
 * Intenal utility for resolving localisation resource bundle urls
 */
function resolveBundleUrl(locale: string, translationsUrl: string) {
  let retVal;
  if (translationsUrl === "") {
    retVal = process.env.PUBLIC_URL + "/translations/" + locale + ".json";
  } else {
    retVal = translationsUrl + locale + ".json";
  }
  return retVal;
}

function resolveDukeUIBundleUrl(locale: string, translationsUrl: string) {
  let retVal;
  if (translationsUrl === "") {
    retVal = process.env.PUBLIC_URL + "/translations/duke-ui/" + locale + ".json";
  } else {
    retVal = translationsUrl + 'duke-ui/' + locale + ".json";
  }
  return retVal;
}
export default function Localize(props: any) {
  // <editor-fold desc="configuration">
  const { conf } = useContext(UIConfiguration);
  const translationsUrl = conf.functionality?.localization
    ?.translationsUrl as string;
  // Convert locales to a string to reduce useEffects hits without use of deep equal comparison. No need to be in state
  // as this will not change.
  const allowedLocalesAsString: string = ([] as OrgadminLocalizationLocale[])
    .concat(conf?.functionality?.localization?.locales || [])
    .concat({
      value: conf.functionality?.localization?.default as string,
      label: "default",
    })
    // include alias with custom separator
    .map(
      (l) =>
        l.value +
        (l.alias ? ALIAS_SEPARATOR + l.alias.join(ALIAS_SEPARATOR) : "")
    )
    .join(",");
  // </editor-fold>

  // <editor-fold desc="state">

  const [resolvedLocaleData, setResolvedLocaleData] = useState(
    LocaleManager.getResolved()
  );

  const [defaultNotAvailable, setDefaultNotAvailable] = useState<
    string | undefined
  >(undefined);

  const [defaultMessages, setDefaultMessages] = useState<
    { [key: string]: string } | undefined | null
  >(undefined);

  const [notAvailable, setNotAvailable] = useState<string | undefined>(
    undefined
  );

  const [messages, setMessages] = useState<
    { [key: string]: string } | undefined | null
  >(undefined);

  const [notAvailableDismissed, setNotAvailableDismissed] =
    useState<boolean>(false);

  const [restoreStoredDismissed, setRestoreStoredDismissed] =
    useState<boolean>(false);

  const [pathAndStoredMatch, setPathAndStoredMatch] = useState(
    resolvedLocaleData?.locale?.value === resolvedLocaleData?.stored ||
      !!allowedLocalesAsString.split(",").find((al) => {
        const t = al.split(ALIAS_SEPARATOR);
        return (
          !!t.find((f) => f === resolvedLocaleData?.path) &&
          !!t.find((f) => f === resolvedLocaleData?.stored)
        );
      })
  );

  const [restoreStoredNotification, setRestoreStoredNotification] = useState(
    defaultMessages &&
      !notAvailable &&
      !restoreStoredDismissed &&
      resolvedLocaleData?.stored &&
      resolvedLocaleData?.path &&
      // resolvedLocaleData.stored !== resolvedLocaleData.path &&
      resolvedLocaleData?.resolvedFrom !== ResolvedFrom.store &&
      !pathAndStoredMatch
  );

  //</editor-fold>

  // <editor-fold desc="callbacks">

  const setupLanguageMessages = useCallback(
    (loc: string) => {
      const allowedArray = allowedLocalesAsString.split(",");
      const localeToSet = loc;
      if (
        !allowedArray.find(
          (f) => !!f.split(ALIAS_SEPARATOR).find((l) => l === localeToSet)
        )
      ) {
        // requested is not allowed, skip loading and reject as not available
        setMessages(defaultMessages);
        setNotAvailable(localeToSet);
      } else {
        // Note "locale missing" is shown even when the first term matches if the 2 part locale in question is
        // from configured. ie. What is configured, must have an exact match.
        if (localeToSet && localeToSet !== resolvedLocaleData?.defaultLocale) {
          // only attempt if the locale is listed as allowed
          fetch(resolveBundleUrl(localeToSet, translationsUrl))
            .then((response) => {
              return new Promise(resolve => {
                response.json().then((orgadminMessages) => {
                  fetch(resolveDukeUIBundleUrl(localeToSet, translationsUrl))
                      .then((response) => {
                        if (response.ok) {
                          return response.json();
                        } else {
                          // duke-ui resource file not found or invalid, as duke-ui resources can be provided with the
                          // orgadmin assets, allow to continue with empty object.
                          return {};
                        }
                      })
                      .then((dukeuiMessages) => {
                        const result = {
                          ...dukeuiMessages,
                          ...orgadminMessages
                        };
                        resolve(result);
                      })
                  ;
                });
              });
            })
            .then((response) => {
              // populate defaults from configured default for prod builds,
              // when not populated, "missing translation" error is logged to console in non prod builds
              // (unfortunately this does not seem to work for the configured default)
              // this way prod build will default to configured default messages, instead of the in code values
              const tmp = !!response
                ? Object.assign(
                    {},
                    process.env.NODE_ENV === "production"
                      ? defaultMessages
                      : {},
                    response
                  )
                : process.env.NODE_ENV === "production"
                ? defaultMessages
                : {};
              setMessages(tmp);
              setNotAvailable(undefined);
            })
            .catch((err) => {
              // resource not found, use defaults
              setMessages(defaultMessages);
              setNotAvailable(localeToSet);
            });
        } else if (localeToSet === resolvedLocaleData?.defaultLocale) {
          // skip reload of default
          setMessages(defaultMessages);
          setNotAvailable(undefined);
        } else {
          setMessages(defaultMessages);
          setNotAvailable(localeToSet);
        }
      }
      setNotAvailableDismissed(false);
    },
    [
      allowedLocalesAsString,
      translationsUrl,
      setMessages,
      setNotAvailable,
      defaultMessages,
      resolvedLocaleData?.defaultLocale,
      setNotAvailableDismissed,
    ]
  );

  // </editor-fold>

  // <editor-fold desc="effects">

  useEffect(() => {
    setPathAndStoredMatch(
      resolvedLocaleData?.locale?.value === resolvedLocaleData?.stored ||
        !!allowedLocalesAsString.split(",").find((al) => {
          const t = al.split(ALIAS_SEPARATOR);
          return (
            !!t.find((f) => f === resolvedLocaleData?.path) &&
            !!t.find((f) => f === resolvedLocaleData?.stored)
          );
        })
    );
  }, [
    resolvedLocaleData?.locale?.value,
    allowedLocalesAsString,
    resolvedLocaleData?.path,
    resolvedLocaleData?.stored,
  ]);
  // Fetch and apply configured default locale assets
  useEffect(() => {
    fetch(
      resolveBundleUrl(
        resolvedLocaleData?.defaultLocale as string,
        translationsUrl
      )
    )
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        setDefaultMessages(response);
        setDefaultNotAvailable(undefined);
      })
      .catch((err) => {
        setDefaultMessages(null);
        setDefaultNotAvailable(resolvedLocaleData?.defaultLocale as string);
      });
  }, [
    translationsUrl,
    resolvedLocaleData?.defaultLocale,
    setDefaultMessages,
    setDefaultNotAvailable,
  ]);

  // add change language listener
  useEffect(() => {
    const l = (ld: ResolvedLocaleData) => {
      setResolvedLocaleData(ld);
    };
    LocaleManager.addChangeLanguageListener(l);
    return () => {
      LocaleManager.removeChangeLanguageListener(l);
    };
  }, []);

  // apply selected locale
  useEffect(() => {
    if (defaultMessages && Object.keys(defaultMessages).length > 0) {
      if (
        resolvedLocaleData?.path &&
        resolvedLocaleData?.resolvedFrom !== ResolvedFrom.path &&
        resolvedLocaleData?.path !== resolvedLocaleData?.stored
      ) {
        // path locale is defined, but not the source so it must have been rejected, attempt to use as requested to
        // generate error output
        setupLanguageMessages(resolvedLocaleData?.path);
      } else if (
        (!resolvedLocaleData?.path ||
          resolvedLocaleData?.path === resolvedLocaleData?.stored) &&
        resolvedLocaleData?.stored &&
        resolvedLocaleData?.resolvedFrom !== ResolvedFrom.store
      ) {
        // stored locale is defined, but not the source so it must have been rejected, attempt to use as requested to
        // generate error output
        setupLanguageMessages(resolvedLocaleData?.stored);
      } else {
        // rejected detected locales are not considered as errors, so there is no need to show any errors and we can
        // just use the resolved
        setupLanguageMessages(resolvedLocaleData?.locale?.value as string);
      }
    }
  }, [
    setupLanguageMessages,
    resolvedLocaleData?.locale,
    resolvedLocaleData?.path,
    resolvedLocaleData?.stored,
    resolvedLocaleData?.resolvedFrom,
    resolvedLocaleData?.defaultLocale,
    defaultMessages,
  ]);

  useEffect(() => {
    // The restore notification is briefly shown initially before everything has been initialized properly.
    // postponing the display of said notification unless the trigger was a dismissal.
    const timeout = !restoreStoredDismissed ? 100 : 0;
    let timeoutId: NodeJS.Timeout | undefined = setTimeout(
      () =>
        setRestoreStoredNotification(
          defaultMessages &&
            !notAvailable &&
            !restoreStoredDismissed &&
            resolvedLocaleData?.stored &&
            resolvedLocaleData?.path &&
            resolvedLocaleData?.stored !== resolvedLocaleData?.path &&
            resolvedLocaleData?.resolvedFrom !== ResolvedFrom.store &&
            !pathAndStoredMatch
        ),
      timeout
    );
    return () => {
      if (timeoutId) {
        try {
          clearTimeout(timeoutId);
          timeoutId = undefined;
        } catch (e) {}
      }
    };
  }, [
    defaultMessages,
    notAvailable,
    restoreStoredDismissed,
    resolvedLocaleData?.stored,
    resolvedLocaleData?.path,
    resolvedLocaleData?.resolvedFrom,
    pathAndStoredMatch,
  ]);

  // </editor-fold>
  return (
    <View
      resolvedLocaleData={resolvedLocaleData}
      changeLanguage={LocaleManager.changeLanguage}
      defaultNotAvailable={defaultNotAvailable}
      defaultMessagesReady={!!defaultMessages}
      notAvailable={notAvailable}
      messages={messages}
      notAvailableDismissed={notAvailableDismissed}
      setNotAvailableDismissed={setNotAvailableDismissed}
      restoreStoredNotification={restoreStoredNotification}
      dismissRestoreStoredNotification={() => setRestoreStoredDismissed(true)}
      {...props}
    />
  );
}
