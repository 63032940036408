import { useEffect, useRef, useState } from "react";
import View, {
  CREATE_INVITATION_CSV_IMPORT_LIMIT,
  CreateInvitationModalProps as _CreateInvitationModalProps,
  CreateInvitationModalStateProps,
  InvitationRecipient,
} from "./create-invitation-modal-view";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
import _ from "lodash";
import isEmail from "../../../util/isEmail";
import { withCloseAfterExited } from "@10duke/dukeui";
import { parse } from "csv-parse/browser/esm";

export type CreateInvitationModalProps = Omit<
  _CreateInvitationModalProps,
  keyof CreateInvitationModalStateProps
>;

const ViewWithCloseAfterExited =
  withCloseAfterExited<_CreateInvitationModalProps>(View);

export default function CreateInvitationModal(
  props: CreateInvitationModalProps
) {
  const { show, selected, groups, ...other } = props;

  const inputRef = useRef<HTMLInputElement>(null);
  const groupsRef = useRef(groups);
  if (!_.isEqual(groupsRef.current, groups)) {
    groupsRef.current = groups;
  }
  const groupsRefCurrent = groupsRef.current;
  const selectedRef = useRef(selected);
  if (!_.isEqual(selectedRef.current, selected)) {
    selectedRef.current = selected;
  }
  const selectedRefCurrent = selectedRef.current;

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showAllDuplicated, setShowAllDuplicated] = useState<boolean>(false);
  const [showAllMissingData, setShowAllMissingData] = useState<boolean>(false);
  const [showRecipients, setShowRecipients] = useState<boolean>(false);

  const [importIssue, setImportIssue] = useState<any | undefined>(undefined);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [editMissingDataIndex, setEditMissingDataIndex] = useState<number>(-1);
  const [editDuplicatedDataIndex, setEditDuplicatedDataIndex] =
    useState<number>(-1);
  const [recipients, setRecipients] = useState<InvitationRecipient[]>([]);
  const [missingDataRecipients, setMissingDataRecipients] = useState<
    InvitationRecipient[]
  >([]);
  const [duplicatedDataRecipients, setDuplicatedDataRecipients] = useState<
    InvitationRecipient[]
  >([]);
  const addRecipient = (r: InvitationRecipient | InvitationRecipient[]) => {
    let timer: any;
    setRecipients((cur) => {
      setDisableListButton(cur.length);
      timer = setTimeout(() => {
        setDisableListButton(-1);
      }, 500);
      return cur.concat(r);
    });
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  };
  const updateRecipient = (ind: number, r: InvitationRecipient) => {
    setRecipients((cur) => {
      const t: InvitationRecipient[] = ([] as InvitationRecipient[]).concat(
        cur
      );
      t.splice(ind, 1, r);
      return t;
    });
    setEditIndex(-1);
  };

  const removeRecipient = (ind: number) => {
    setRecipients((cur) => {
      const t: InvitationRecipient[] = ([] as InvitationRecipient[]).concat(
        cur
      );
      t.splice(ind, 1);
      return t;
    });
  };
  const [disableListButton, setDisableListButton] = useState<number>(-1);
  const [disableMissingDataListButton, setDisableMissingDataListButton] =
    useState<number>(-1);
  const [disableDuplicatedListButton, setDisableDuplicatedListButton] =
    useState<number>(-1);
  useEffect(() => {
    let timer: any;
    if (editIndex !== -1) {
      setDisableListButton(editIndex);
    }
    if (editIndex === -1) {
      setDisableListButton((cur) => {
        if (cur !== -1) {
          timer = setTimeout(() => {
            setDisableListButton(-1);
          }, 500);
          return cur;
        }
        return -1;
      });
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [editIndex]);
  useEffect(() => {
    let timer: any;
    if (editDuplicatedDataIndex !== -1) {
      setDisableDuplicatedListButton(editDuplicatedDataIndex);
    }
    if (editDuplicatedDataIndex === -1) {
      setDisableDuplicatedListButton((cur) => {
        if (cur !== -1) {
          timer = setTimeout(() => {
            setDisableDuplicatedListButton(-1);
          }, 500);
          return cur;
        }
        return -1;
      });
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [editDuplicatedDataIndex]);
  useEffect(() => {
    let timer: any;
    if (editMissingDataIndex !== -1) {
      setDisableMissingDataListButton(editMissingDataIndex);
    }
    if (editMissingDataIndex === -1) {
      setDisableMissingDataListButton((cur) => {
        if (cur !== -1) {
          timer = setTimeout(() => {
            setDisableMissingDataListButton(-1);
          }, 500);
          return cur;
        }
        return -1;
      });
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [editMissingDataIndex]);
  const [activeSearch, onSetActiveSearch] = useState("");
  const [selectionTouched, onSetSelectionTouched] = useState(false);

  const [sel, onSetSelected] = useState<OrganizationGroup[]>(selected || []);
  const [dirtySelection, onSetDirtySelection] = useState<boolean>(false);
  const [selectionFixed, onSetSelectionFixed] = useState<boolean>(!!selected);
  useEffect(() => {
    if (!show) {
      setShowAllDuplicated(false);
      setShowAllMissingData(false);

      setSubmitting(false);
      setImportIssue(undefined);

      setEditIndex(-1);
      setRecipients([]);

      setEditMissingDataIndex(-1);
      setMissingDataRecipients([]);

      setEditDuplicatedDataIndex(-1);
      setDuplicatedDataRecipients([]);

      onSetActiveSearch("");
      onSetSelectionTouched(false);
      onSetSelected([]);
      onSetSelectionFixed(false);
      onSetDirtySelection(false);
    } else {
      onSetSelected((s) => {
        if (selectedRefCurrent) {
          // filter out potentially removed items
          const t = selectedRefCurrent.filter((v) =>
            groupsRefCurrent
              ? groupsRefCurrent.findIndex((g) => g.id === v.id) >= 0
              : false
          );
          if (t.length <= 0) {
            onSetDirtySelection(false);
            onSetSelectionFixed(false);
          } else {
            onSetSelectionFixed(true);
          }
          return t;
        } else {
          onSetSelectionFixed(false);
          onSetSelectionFixed(false);
          return s.filter((v) =>
            groupsRefCurrent
              ? groupsRefCurrent.findIndex((g) => g.id === v.id) >= 0
              : false
          );
        }
      });
    }
  }, [show, selectedRefCurrent, groupsRefCurrent]);
  const setSelection = (s: OrganizationGroup[]) => {
    const initialSel = selected ? selected.map((v) => v.id).sort() : [];
    const newSel = s.map((v) => v.id).sort();
    if (initialSel.length !== newSel.length) {
      onSetDirtySelection(true);
    } else {
      let notEqual = false;
      for (let i = 0; i < newSel.length; i += 1) {
        if (newSel[i] !== initialSel[i]) {
          notEqual = true;
          break;
        }
      }
      if (notEqual) {
        onSetDirtySelection(true);
      } else {
        onSetDirtySelection(false);
      }
    }
    onSetSelectionTouched(true);
    onSetSelected(s);
  };
  const onFileChanged = () => {
    return new Promise<any>((resolve, reject) => {
      if (inputRef.current?.files) {
        try {
          const reader = new FileReader();
          reader.onload = async (e) => {
            if (e && e.target && e.target.result) {
              // parse to data
              parse(
                typeof e.target.result !== "string"
                  ? new Buffer(e.target.result)
                  : e.target.result,
                {
                  bom: true,
                  delimiter: [",", ";"],
                  skip_empty_lines: true,
                  trim: true,
                },
                (err: any, output: any[]) => {
                  if (!err && output) {
                    if (
                      output.length <=
                      CREATE_INVITATION_CSV_IMPORT_LIMIT + 1
                    ) {
                      // valid data
                      let emailIndex = -1;
                      let nameIndex = -1;
                      let firstRowIsColumns = false;
                      // resolve email index by detecting values, first column with email as value is it.
                      for (let row = 0; row < output.length; row += 1) {
                        for (let i = 0; i < output[row].length; i += 1) {
                          if (isEmail(output[row][i])) {
                            if (row > 0) {
                              firstRowIsColumns = true;
                            }
                            emailIndex = i;
                            // break outer loop
                            row = output.length;
                            break;
                          }
                        }
                      }
                      if (
                        !firstRowIsColumns &&
                        output.length === CREATE_INVITATION_CSV_IMPORT_LIMIT + 1
                      ) {
                        reject({
                          tooManyItems: output.length,
                        });
                      } else {
                        if (emailIndex >= 0) {
                          // valid email column found
                          if (output[0].length === 2) {
                            // only 2 columns, the other must be name
                            if (emailIndex === 0) {
                              nameIndex = 1;
                            } else {
                              nameIndex = 0;
                            }
                          } else {
                            if (firstRowIsColumns) {
                              // named columns, ie. first row does not contain email value
                              const nameInd = output[0].findIndex(
                                (c: string) => c.toLowerCase() === "name"
                              );
                              if (nameInd >= 0) {
                                // exactly named found
                                nameIndex = nameInd;
                              }
                            }
                          }
                          if (
                            nameIndex >= 0 &&
                            emailIndex >= 0 &&
                            nameIndex !== emailIndex
                          ) {
                            // valid indices found, create array of items to add
                            const toAdd = output
                              .map((r: string[], ind: number) => {
                                if (!firstRowIsColumns || ind > 0) {
                                  return {
                                    email: r[emailIndex],
                                    recipientName: r[nameIndex],
                                  };
                                } else {
                                  return undefined;
                                }
                              })
                              .filter((v: any) => !!v);
                            resolve(toAdd);
                          } else {
                            // valid indices not found, reject with sufficient data
                            reject({
                              firstRowIsColumns: firstRowIsColumns,
                              emailIndex: emailIndex,
                              nameIndex: nameIndex,
                              output: output,
                            });
                          }
                        } else {
                          // valid indices not found, reject with sufficient data
                          reject({
                            firstRowIsColumns: firstRowIsColumns,
                            emailIndex: emailIndex,
                            nameIndex: nameIndex,
                            output: output,
                          });
                        }
                      }
                    } else {
                      let c = output.length;
                      // remove 1 if first row is columns
                      if (output[0].findIndex((v: any) => isEmail(v)) < 0) {
                        c -= 1;
                      }
                      reject({
                        tooManyItems: c,
                      });
                    }
                  } else {
                    reject(err);
                    // The error object contains additional information about what wen wrong, but displaying it in
                    // detail requires too much effort for now.
                  }
                }
              );
            }
          };
          reader.readAsText(inputRef.current?.files[0] as any as Blob);
        } catch (e) {
          reject(e);
        }
      }
    });
  };
  return (
    <ViewWithCloseAfterExited
      {...other}
      missingDataRecipients={missingDataRecipients}
      setMissingDataRecipients={setMissingDataRecipients}
      duplicatedDataRecipients={duplicatedDataRecipients}
      setDuplicatedDataRecipients={setDuplicatedDataRecipients}
      disableListButton={disableListButton}
      disableDuplicatedListButton={disableDuplicatedListButton}
      disableMissingDataListButton={disableMissingDataListButton}
      recipients={recipients}
      updateRecipient={updateRecipient}
      addRecipient={addRecipient}
      removeRecipient={removeRecipient}
      editIndex={editIndex}
      setEditIndex={setEditIndex}
      editMissingDataIndex={editMissingDataIndex}
      setEditMissingDataIndex={setEditMissingDataIndex}
      editDuplicatedDataIndex={editDuplicatedDataIndex}
      setEditDuplicatedDataIndex={setEditDuplicatedDataIndex}
      setImportIssue={setImportIssue}
      importIssue={importIssue}
      fileInputRef={inputRef}
      onFileChanged={onFileChanged}
      show={show}
      groups={groups}
      onSetActiveSearch={onSetActiveSearch}
      activeSearch={activeSearch}
      onSetSelected={setSelection}
      selected={sel}
      selectionTouched={selectionTouched}
      selectionFixed={selectionFixed}
      onSetSelectionTouched={onSetSelectionTouched}
      submitting={submitting}
      setSubmitting={setSubmitting}
      showAllDuplicated={showAllDuplicated}
      setShowAllDuplicated={setShowAllDuplicated}
      showAllMissingData={showAllMissingData}
      setShowAllMissingData={setShowAllMissingData}
      showRecipients={showRecipients}
      setShowRecipients={setShowRecipients}
      dirtySelection={dirtySelection}
    />
  );
}
