import IdpApiWrapper from "./IdpApiWrapper";
import { User } from "../../model/User";
import { getSelectedOrgId, getAuthentication } from "../../store";
import { Organization } from "../../model/Organization";
import { OrganizationGroup } from "../../model/OrganizationGroup";
import { OrganizationRole } from "../../model/OrganizationRole";
import { OrganizationGroupInvitation } from "../../model/OrganizationGroupInvitation";
import {
  DeleteOrganizationGroupResult, RemoveClientGroupsOfClientResult,
  RemoveOrgGroupsOfUserResult,
  RemoveUsersFromOrgGroupResult,
  SetOrgGroupsOfUserResult,
  SetUsersInOrgGroupResult,
} from "../IdpApi";
import {Client} from "../../model/Client";
import {ClientGroup} from "../../model/ClientGroup";
import {ClientGroupInvitation} from "../../model/ClientGroupInvitation";

/**
 * Wraps the used IpdApi and provides defaults argument values.
 */
export default class IdpApiWithDefaults extends IdpApiWrapper {
  public getAppOrganizations(userId?: string): Promise<Organization[]> {
    return super.getAppOrganizations(this.userIdOrDefault(userId));
  }

  public getOrganization(orgId?: string): Promise<Organization> {
    return super.getOrganization(this.orgIdOrDefault(orgId));
  }

  public setMfaRequired(required: boolean, orgId?: string): Promise<void> {
    return super.setMfaRequired(required, this.orgIdOrDefault(orgId));
  }

  public listAllOrganizationUsers(orgId?: string): Promise<User[]> {
    return super.listAllOrganizationUsers(this.orgIdOrDefault(orgId));
  }

  public listOrganizationGroups(orgId?: string): Promise<OrganizationGroup[]> {
    return super.listOrganizationGroups(this.orgIdOrDefault(orgId));
  }

  public listOrganizationGroupsOfUser(
      userId: string,
      organizationId?: string
  ): Promise<OrganizationGroup[]> {
    return super.listOrganizationGroupsOfUser(userId, this.orgIdOrDefault(organizationId));
  }
  public listClientGroupsOfClient(
      clientId: string,
      organizationId?: string
  ): Promise<ClientGroup[]> {
    return super.listClientGroupsOfClient(clientId, this.orgIdOrDefault(organizationId));
  }
  listOrganizationClients(orgId?: string): Promise<Client[]> {
    return super.listOrganizationClients(this.orgIdOrDefault(orgId));
  }
  getOrganizationClientGroup(
      orgClientGroupId: string,
      orgId?: string
  ): Promise<OrganizationGroup> {
    return super.getOrganizationClientGroup(orgClientGroupId, orgId);
  }
  listOrganizationClientGroups(orgId?: string): Promise<ClientGroup[]> {
    return super.listOrganizationClientGroups(this.orgIdOrDefault(orgId));
  }
  sendOrganizationClientGroupInvitation(
      invitationId: string,
      orgId?:string
  ): Promise<ClientGroupInvitation> {
    return super.sendOrganizationClientGroupInvitation(invitationId, this.orgIdOrDefault(orgId) as string);
  }
  revokeOrganizationClientGroupInvitation(
      invitationId: string,
      orgId?:string
  ): Promise<ClientGroupInvitation> {
    return super.revokeOrganizationClientGroupInvitation(invitationId, this.orgIdOrDefault(orgId) as string);
  }
  getOrganizationClientGroupInvitation(
      invitationId: string,
      orgId?: string
  ): Promise<ClientGroupInvitation> {
    return super.getOrganizationClientGroupInvitation(invitationId, this.orgIdOrDefault(orgId));
  }
  deleteOrganizationClientGroupInvitation(invitationId: string, orgId?:string): Promise<void> {
    return super.deleteOrganizationClientGroupInvitation(invitationId, this.orgIdOrDefault(orgId));
  }
  createOrganizationClientGroup(
      orgId: string,
      clientGroup: ClientGroup): Promise<ClientGroup> {
    return super.createOrganizationClientGroup(orgId, clientGroup);
  }
  public createOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return super.createOrganizationGroup(orgGroup, this.orgIdOrDefault(orgId));
  }

  public replaceOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return super.replaceOrganizationGroup(orgGroup, this.orgIdOrDefault(orgId));
  }

  public getOrganizationGroup(
    orgGroupId: string,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return super.getOrganizationGroup(orgGroupId, this.orgIdOrDefault(orgId));
  }

  public replaceOrganizationClientGroup(organizationId: string, clientGroup: ClientGroup): Promise<ClientGroup> {
    return super.replaceOrganizationClientGroup(organizationId, clientGroup);
  }

  public deleteOrganizationGroup(
    orgGroupId: string,
    orgId?: string,
    opts?: {
      cleanUpLicenseReservations?: boolean;
      returnNoMembershipsUserIds?: boolean;
    }
  ): Promise<DeleteOrganizationGroupResult> {
    return super.deleteOrganizationGroup(
      orgGroupId,
      this.orgIdOrDefault(orgId),
      opts || {
        cleanUpLicenseReservations: true,
        returnNoMembershipsUserIds: true,
      }
    );
  }

  public listOrganizationRoles(orgId?: string): Promise<OrganizationRole[]> {
    return super.listOrganizationRoles(this.orgIdOrDefault(orgId));
  }

  public createOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    return super.createOrganizationRole(orgRole, this.orgIdOrDefault(orgId));
  }

  public replaceOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    return super.replaceOrganizationRole(orgRole, this.orgIdOrDefault(orgId));
  }

  public getOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<OrganizationRole> {
    return super.getOrganizationRole(orgRoleId, this.orgIdOrDefault(orgId));
  }

  public deleteOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<void> {
    return super.deleteOrganizationRole(orgRoleId, this.orgIdOrDefault(orgId));
  }

  public listUsersInOrganizationGroup(
    groupId: string,
    orgId?: string
  ): Promise<User[]> {
    return super.listUsersInOrganizationGroup(
      groupId,
      this.orgIdOrDefault(orgId)
    );
  }

  public addUsersToOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return super.addUsersToOrganizationGroup(
      groupId,
      userIds,
      this.orgIdOrDefault(orgId)
    );
  }

  public setUsersInOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string,
    opts?: {
      cleanUpLicenseReservations?: boolean;
      returnNoMembershipsUserIds?: boolean;
    }
  ): Promise<SetUsersInOrgGroupResult> {
    return super.setUsersInOrganizationGroup(
      groupId,
      userIds,
      this.orgIdOrDefault(orgId),
      opts || {
        cleanUpLicenseReservations: true,
        returnNoMembershipsUserIds: true,
      }
    );
  }

  public removeUsersFromOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string,
    opts?: {
      cleanUpLicenseReservations?: boolean;
      returnNoMembershipsUserIds?: boolean;
    }
  ): Promise<RemoveUsersFromOrgGroupResult> {
    return super.removeUsersFromOrganizationGroup(
      groupId,
      userIds,
      this.orgIdOrDefault(orgId),
      opts || {
        cleanUpLicenseReservations: true,
        returnNoMembershipsUserIds: true,
      }
    );
  }

  public listOrganizationsOrganizationGroupInvitations(
    orgId?: string
  ): Promise<OrganizationGroupInvitation[]> {
    return super.listOrganizationsOrganizationGroupInvitations(
      this.orgIdOrDefault(orgId)
    );
  }

  public getOrganizationsOrganizationGroupInvitation(
    invitationId: string,
    orgId?: string
  ): Promise<OrganizationGroupInvitation> {
    return super.getOrganizationsOrganizationGroupInvitation(
      invitationId,
      this.orgIdOrDefault(orgId)
    );
  }

  public listUsersInOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<User[]> {
    return super.listUsersInOrganizationRole(
      orgRoleId,
      this.orgIdOrDefault(orgId)
    );
  }

  public addUsersToOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return super.addUsersToOrganizationRole(
      orgRoleId,
      userIds,
      this.orgIdOrDefault(orgId)
    );
  }

  public getUserInOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<User> {
    return super.getUserInOrganizationRole(
      orgRoleId,
      userId,
      this.orgIdOrDefault(orgId)
    );
  }

  public addUserToOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    return super.addUserToOrganizationRole(
      orgRoleId,
      userId,
      this.orgIdOrDefault(orgId)
    );
  }

  public removeUsersFromOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return super.removeUsersFromOrganizationRole(
      orgRoleId,
      userIds,
      this.orgIdOrDefault(orgId)
    );
  }

  public removeUserFromOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    return super.removeUserFromOrganizationRole(
      orgRoleId,
      userId,
      this.orgIdOrDefault(orgId)
    );
  }

  public setUsersInOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return super.setUsersInOrganizationRole(
      orgRoleId,
      userIds,
      this.orgIdOrDefault(orgId)
    );
  }

  public addOrganizationGroupsForUser(
    groupIds: string[],
    userId: string
  ): Promise<void> {
    return this.getWrapped().addOrganizationGroupsForUser(groupIds, userId);
  }

  async addClientGroupsForClient(
      groupIds: string[],
      clientId: string
  ): Promise<void> {
    return this.getWrapped().addClientGroupsForClient(groupIds, clientId);
  }
  public setOrganizationGroupsOfUser(
    groupIds: string[],
    userId: string
  ): Promise<SetOrgGroupsOfUserResult> {
    return this.getWrapped().setOrganizationGroupsOfUser(groupIds, userId);
  }

  public removeOrganizationGroupsOfUser(
    groupIds: string[],
    userId: string
  ): Promise<RemoveOrgGroupsOfUserResult> {
    return this.getWrapped().removeOrganizationGroupsOfUser(groupIds, userId);
  }

  public removeClientGroupsOfClient(
      groupIds: string[],
      clientId: string
  ): Promise<RemoveClientGroupsOfClientResult> {
    return this.getWrapped().removeClientGroupsOfClient(groupIds, clientId);
  }
  public addUserToOrganizationGroup(
    groupId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().addUserToOrganizationGroup(
      groupId,
      userId,
      this.orgIdOrDefault(orgId)
    );
  }

  public removeUserFromOrganizationGroup(
    groupId: string,
    userId: string,
    orgId?: string,
    opts?: {
      returnNoMembershipsUserIds: boolean;
      cleanUpLicenseReservations: boolean;
    }
  ): Promise<void> {
    return this.getWrapped().removeUserFromOrganizationGroup(
      groupId,
      userId,
      this.orgIdOrDefault(orgId),
      opts || {
        cleanUpLicenseReservations: true,
        returnNoMembershipsUserIds: true,
      }
    );
  }

  private orgIdOrDefault(orgId?: string): string | undefined {
    return orgId ? orgId : getSelectedOrgId();
  }

  private userIdOrDefault(userId?: string): string | undefined {
    if (userId) {
      return userId;
    }

    const authentication = getAuthentication();

    if (!authentication) {
      throw new Error(
        "User id not provided and authentication object not available"
      );
    }

    return authentication.userId;
  }
  /**
   * List client group invitations
   */
  listOrganizationClientGroupInvitations(organizationId: string): Promise<ClientGroupInvitation[]> {
    return super.listOrganizationClientGroupInvitations(organizationId);
  }
  /**
   * Create and send a client group invitation
   */
  createAndSendOrganizationClientGroupInvitation(organizationId: string, clientGroupInvitation: ClientGroupInvitation): Promise<ClientGroupInvitation> {
    return super.createAndSendOrganizationClientGroupInvitation(organizationId, clientGroupInvitation);
  };

  public async listClientsInOrganizationClientGroup(organizationId: string, groupId: string): Promise<Client[]> {
    return super.listClientsInOrganizationClientGroup(organizationId, groupId);
  }

  addClientsToOrganizationClientGroup(organizationId: string, groupId: string, body: string[]): Promise<void> {
    return super.addClientsToOrganizationClientGroup(organizationId, groupId, body);
  }
  removeClientsFromOrganizationClientGroup(organizationId: string, groupId: string, body: string[]): Promise<void> {
    return super.removeClientsFromOrganizationClientGroup(organizationId, groupId, body);
  }

  getClient(clientId: string): Promise<Client> {
    return super.getClient(clientId);
  }
  deleteOrganizationClient(organizationId: string, clientId: string): Promise<void>  {
    return super.deleteOrganizationClient(organizationId, clientId);
  }
  replaceOrganizationClient(organizationId: string, client: Client): Promise<Client> {
    return super.replaceOrganizationClient(organizationId, client);
  }
}
