import { HTMLAttributes } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./profile-image.scss";
import {IconLibrary} from "@10duke/dukeui";

//<editor-fold desc="Props">
interface ProfileImageDOMProps
  extends Omit<HTMLAttributes<HTMLSpanElement>, "children"> {}

interface ProfileImageProps extends ProfileImageDOMProps {}
//</editor-fold>

export default function ProfileImage(props: ProfileImageProps) {
  //<editor-fold desc="Local variables">
  let retVal;
  let { className, ...other } = props;

  //</editor-fold>

  retVal = (
    <span
      className={"profile-image" + (className ? " " + className : "")}
      {...other}
    >
      <FontAwesomeIcon icon={IconLibrary.icons.faUserCircle} className={"fa fa-fw"} />
    </span>
  );

  return retVal;
}
