import {
  ResendInvitationModalProps as _ResendInvitationModalProps,
  ResendInvitationModalDataProps,
} from "./resend-invitation-modal-view";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import { ActionSender } from "../../../model/ActionSender";
import { AppState } from "../../../store/AppState";
import InProgressUtils from "../../../utils/in-progress";
import OrganizationUtils from "../../../utils/organization";
import { addSenderArgument } from "../../../actions/actionHelpers";
import {
  clearError,
  getOrganizationsOrganizationGroupInvitation,
  sendOrganizationGroupInvitation,
} from "../../../actions";
import { connect } from "react-redux";
import ResendInvitationModal from "./resend-invitation-modal-container";

export interface ResendInvitationModalProps
  extends Omit<
    _ResendInvitationModalProps,
    keyof ResendInvitationModalDataProps
  > {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ResendInvitationModalProps,
  ResendInvitationModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ResendInvitationModalProps,
  ResendInvitationModalProps
>;
const sender: ActionSender = { key: "resend-invitation" };

function mapStateToProps(
  state: AppState,
  ownProps: ResendInvitationModalProps
): ReduxStateProps {
  return {
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    invitation: ownProps.invitationId
      ? OrganizationUtils.selectOrganizationInvitation(
          ownProps.invitationId,
          state
        )
      : undefined,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationInvitationsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onResendInvitation: addSenderArgument(
    sender,
    sendOrganizationGroupInvitation
  ),
  onLoadInvitation: addSenderArgument(
    sender,
    getOrganizationsOrganizationGroupInvitation
  ),
  onClearError: clearError,
};
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ResendInvitationModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ResendInvitationModal);
