import * as ActionTypes from "../actions/actionTypes";
import { OrgRoleUserIdsByOrgRoleId } from "../store/OrganizationRoleState";
import {
  removeFromStateListsById,
  removeUsersFromStateLists,
} from "../util/ReducerUtils";

export default function orgRoleUserIds(
  state: OrgRoleUserIdsByOrgRoleId,
  action: ActionTypes.AppAction
): OrgRoleUserIdsByOrgRoleId | null {
  const currentState = state || ({} as OrgRoleUserIdsByOrgRoleId);
  switch (action.type) {
    case ActionTypes.LIST_USERS_IN_ORG_ROLE:
      const listUsersInOrgRole = action as ActionTypes.ListUsersInOrgRoleAction;
      const userIds = listUsersInOrgRole.users.map((user) => user.id as string);
      return { ...currentState, [listUsersInOrgRole.orgRoleId]: userIds };

    case ActionTypes.DELETE_USER:
    case ActionTypes.REMOVE_USER_FROM_ORG:
      return removeUsersFromStateLists<OrgRoleUserIdsByOrgRoleId>(
        action,
        currentState
      );

    case ActionTypes.ADD_USERS_TO_ORG_ROLE: {
      const addUsersToOrgRole = action as ActionTypes.AddUsersToOrgRoleAction;
      return addUsersToOrgRoleInteral(
        currentState,
        addUsersToOrgRole.orgRoleId,
        addUsersToOrgRole.userIds
      );
    }
    case ActionTypes.REMOVE_USERS_FROM_ORG_ROLE: {
      const removeUsersFromOrgRole =
        action as ActionTypes.RemoveUsersFromOrgRoleAction;
      return removeUsersFromOrgRoleInternal(
        currentState,
        removeUsersFromOrgRole.orgRoleId,
        removeUsersFromOrgRole.userIds
      );
    }
    case ActionTypes.ADD_USER_TO_ORG_ROLE: {
      const addUserToOrgRole = action as ActionTypes.AddUserToOrgRoleAction;
      return addUsersToOrgRoleInteral(
        currentState,
        addUserToOrgRole.orgRoleId,
        [addUserToOrgRole.userId]
      );
    }
    case ActionTypes.REMOVE_USER_FROM_ORG_ROLE: {
      const removeUserFromOrgRole =
        action as ActionTypes.RemoveUserFromOrgRoleAction;
      return removeUsersFromOrgRoleInternal(
        currentState,
        removeUserFromOrgRole.orgRoleId,
        [removeUserFromOrgRole.userId]
      );
    }
    case ActionTypes.ADD_ORG_ROLES_FOR_USER: {
      const newState = { ...currentState };
      const addOrgRolesForUser = action as ActionTypes.AddOrgRolesForUserAction;
      addOrgRolesForUser.orgRoleIds.forEach((orgRoleId) => {
        const usersOfRole = currentState[orgRoleId] || [];
        if (!usersOfRole.includes(addOrgRolesForUser.userId)) {
          newState[orgRoleId] = [...usersOfRole, addOrgRolesForUser.userId];
        }
      });
      return newState;
    }
    case ActionTypes.REMOVE_ORG_ROLES_OF_USER: {
      const newState = { ...currentState };
      const removeOrgRolesOfUser =
        action as ActionTypes.RemoveOrgRolesOfUserAction;
      removeOrgRolesOfUser.orgRoleIds.forEach((orgRoleId) => {
        const usersOfRole = (currentState[orgRoleId] || []).filter(
          (userId) => userId !== removeOrgRolesOfUser.userId
        );
        newState[orgRoleId] = usersOfRole;
      });
      return newState;
    }
    case ActionTypes.DELETE_ORG_ROLE: {
      const deleteOrgRole = action as ActionTypes.DeleteOrgRoleAction;
      const { [deleteOrgRole.orgRoleId]: _, ...remaining } = currentState;
      return remaining;
    }
    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }

  function addUsersToOrgRoleInteral(
    currentState: OrgRoleUserIdsByOrgRoleId,
    roleId: string,
    userIds: string[]
  ): OrgRoleUserIdsByOrgRoleId {
    const updatedUsersOfRole: string[] = currentState[roleId]
      ? [...currentState[roleId]]
      : [];

    for (const userId of userIds) {
      if (!updatedUsersOfRole.includes(userId)) {
        updatedUsersOfRole.push(userId);
      }
    }
    return { ...currentState, [roleId]: updatedUsersOfRole };
  }

  function removeUsersFromOrgRoleInternal(
    currentState: OrgRoleUserIdsByOrgRoleId,
    roleId: string,
    userIds: string[]
  ): OrgRoleUserIdsByOrgRoleId {
    const updatedUsersOfRole = currentState[roleId]
      ? currentState[roleId].filter((userId) => !userIds.includes(userId))
      : [];
    return { ...currentState, [roleId]: updatedUsersOfRole };
  }
}

function handleErrorAction(
  currentState: OrgRoleUserIdsByOrgRoleId,
  action: ActionTypes.AppAction
): OrgRoleUserIdsByOrgRoleId {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  if (
    errorAction.error.action.type === ActionTypes.GET_USER &&
    errorAction.error.apiError.error === "404"
  ) {
    const typedError =
      action as ActionTypes.AddErrorAction<ActionTypes.GetUserAction>;
    finalState = removeFromStateListsById(
      [typedError.error.action?.userId as string],
      currentState
    );
  }

  return finalState;
}
