import { connect } from "react-redux";
import ViewDeviceClientGroupModal, {
  ViewDeviceClientGroupModalProps as _ViewDeviceClientGroupModalProps,
} from "./view-device-client-group-modal-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import {
  clearError,
  getOrganizationClientGroup,
  replaceOrganizationClientGroup,
} from "../../../actions";
import {
  listEntitlementsConsumedByOrgClientGroup,
  listOrganizationEntitlements,
  setEntitlementsConsumedByCliGroup,
} from "../../../actions/entActions";
import OrganizationUtils from "../../../utils/organization";
import {
  ViewDeviceClientGroupModalDataProps,
} from "./view-device-client-group-modal-view";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import EntitlementUtils from "../../../utils/entitlement";
import { addSenderArgument } from "../../../actions/actionHelpers";

export type ViewDeviceClientGroupModalProps = Omit<
  _ViewDeviceClientGroupModalProps,
  keyof ViewDeviceClientGroupModalDataProps
>;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ViewDeviceClientGroupModalProps,
  ViewDeviceClientGroupModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ViewDeviceClientGroupModalProps,
  ViewDeviceClientGroupModalProps
>;

const sender: ActionSender = { key: "view-device-client-group-modal" };
/**
 * @param state
 * @param ownProps
 */
function mapStateToProps(
  state: AppState,
  ownProps: ViewDeviceClientGroupModalProps
): ReduxStateProps {
  return {
    clientGroup: ownProps.clientGroupId
      ? OrganizationUtils.selectOrganizationClientGroup(ownProps.clientGroupId, state)
      : undefined,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    entitlements: OrganizationUtils.selectOrganizationEntitlements(state),
    organizationId: OrganizationUtils.selectSelectedOrganizationId(state),
    groupEntitlements: ownProps.clientGroupId
      ? EntitlementUtils.selectClientGroupEntitlements(ownProps.clientGroupId, state)
      : undefined,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientGroupsStates(next, prev) &&
    EntitlementUtils.compareClientGroupEntitlementsStates(next, prev) &&
    OrganizationUtils.compareOrganizationEntitlementsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onUpdateClientGroup: addSenderArgument(sender, replaceOrganizationClientGroup),
  onLoadClientGroupEntitlements: addSenderArgument(
    sender,
    listEntitlementsConsumedByOrgClientGroup
  ),
  onLoadEntitlements: addSenderArgument(sender, listOrganizationEntitlements),
  onLoadClientGroup: addSenderArgument(sender, getOrganizationClientGroup),
  onSetClientGroupEntitlements: addSenderArgument(
    sender,
    setEntitlementsConsumedByCliGroup
  ),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ViewDeviceClientGroupModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ViewDeviceClientGroupModal);
