import { useEffect, useMemo, HTMLAttributes } from "react";
import { useIntl } from "react-intl";
import { ClosableModalProps } from "../closable-modal-props";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
import {
  AddErrorAction,
  ClearErrorAction,
  CreateOrgGroupAction,
  isAddErrorAction,
  SetEntitlementsConsumedByOrgGroup,
} from "../../../actions/actionTypes";
import { Entitlement } from "../../../model/entitlement/Entitlement";
import Table from "../../table/table-container";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrganizationGroupLabels } from "../../../localization/organization-group";
import { IconLibrary, FormInput, FormInputUtils, FeedbackEntry, Modal } from "@10duke/dukeui";

//<editor-fold desc="Utils">

//</editor-fold>

//<editor-fold desc="Props">

export interface CreateOrganizationGroupModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}

export interface CreateOrganizationGroupModalStateProps {
  groupEntitlements: string[];
  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;
  selected: Entitlement[];
  onSetSelected: (selection: Entitlement[]) => void;
}
export interface CreateOrganizationGroupModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {
  onShowFeedback: (feedback: FeedbackEntry | FeedbackEntry[]) => void;
}
export interface CreateOrganizationGroupModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  onCreateOrganizationGroup: (
    group: OrganizationGroup
  ) => Promise<CreateOrgGroupAction>;
  entitlements?: Entitlement[];
  onLoadEntitlements?: (id: string) => void;
  onSetGroupEntitlements: (
    orgId: string,
    groupId: string,
    entitlementIds: string[]
  ) => Promise<SetEntitlementsConsumedByOrgGroup>;
  organizationId?: string;
  onClearError: (errorId: string) => ClearErrorAction;
}

export interface CreateOrganizationGroupModalProps // CreateOrganizationGroupModalDOMProps,
  extends CreateOrganizationGroupModalVisibilityProps,
    CreateOrganizationGroupModalStateProps,
    CreateOrganizationGroupModalDataProps {}
//</editor-fold>

function CreateOrganizationGroupModal(
  props: CreateOrganizationGroupModalProps
) {
  //<editor-fold desc="Local variables">
  let {
    onCreateOrganizationGroup,
    show,
    onClose,
    onShowFeedback,
    entitlements,
    onLoadEntitlements,
    organizationId,
    groupEntitlements,
    onSetGroupEntitlements,
    onSetActiveSearch,
    activeSearch,
    selected,
    onSetSelected,
    isReady,
    onExited,
    onClearError,
  } = props;
  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>
  if (show && entitlements === undefined && !onLoadEntitlements) {
    throw new Error(
      "CreateOrganizationGroupModal: Required props missing. Either entitlements or onLoadEntitlements must be provided"
    );
  }
  //<editor-fold desc="Hooks">
  const defaultValues = useMemo(
    () => ({
      name: "",
      type: "",
      description: "",
    }),
    []
  );
  const { register, handleSubmit, formState, watch, reset } = useForm({
    mode: "onTouched",
    defaultValues: defaultValues,
  });

  const { errors } = formState;

  const formValues = watch();
  const formIsDirty = formState.isDirty;
  const formIsValid = formState.isValid;
  useEffect(() => {
    reset(defaultValues);
  }, [show, defaultValues, reset]);
  useEffect(() => {
    if (
      show &&
      organizationId &&
      onLoadEntitlements &&
      entitlements === undefined
    ) {
      onLoadEntitlements(organizationId);
    }
  }, [organizationId, entitlements, onLoadEntitlements, show]);
  //</editor-fold>

  const onSubmitOrganizationGroup = (d: any) => {
    let data: any = { ...d };
    delete data.entitlements;
    return onCreateOrganizationGroup(data as OrganizationGroup);
  };
  return (
    <Modal
      onExited={onExited}
      isReady={isReady}
      onReloadData={() => {
        if (onLoadEntitlements && organizationId) {
          onLoadEntitlements(organizationId);
        }
      }}
      data-test-create-organization-group-modal
      title={intl.formatMessage({
        defaultMessage: "Create user group",
        description: "modal heading",
      })}
      show={show}
      backdrop={formState.isDirty || groupEntitlements.length ? "static" : true}
      onClose={onClose}
      primaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Create",
          description: "primary button label",
        }),
        disabled:
          (errors !== undefined &&
            errors !== null &&
            Object.keys(errors).length > 0) ||
          !formIsDirty ||
          !formIsValid,
        tooltip:
          errors !== undefined &&
          errors !== null &&
          Object.keys(errors).length > 0
            ? intl.formatMessage({
                defaultMessage: "Please correct errors before creating group.",
                description:
                  "tooltip for disabled primary button when there are validation errors",
              })
            : !formState.isDirty
            ? intl.formatMessage({
                defaultMessage: "Please fill in the fields first.",
                description:
                  "tooltip for disabled primary button when there is nothing to create",
              })
            : "",
      }}
      onPrimaryAction={() => {
        handleSubmit((data: any) => {
          onSubmitOrganizationGroup(data).then((res) => {
            if (!isAddErrorAction(res)) {
              const feedback: FeedbackEntry[] = [
                {
                  id: "create_organization_group",
                  msg: intl.formatMessage(
                    {
                      defaultMessage: "Organization user group {name} created.",
                      description:
                        "success notification for create group. 'name' = name of the created group",
                    },
                    {
                      name: "<strong>" + res.group.name + "</strong>",
                    }
                  ),
                  autoClose: true,
                  type: "success",
                },
              ];
              if (!!groupEntitlements && groupEntitlements.length > 0) {
                onSetGroupEntitlements(
                  organizationId as string,
                  res.group.id as string,
                  groupEntitlements
                ).then((rres) => {
                  if (!isAddErrorAction(rres)) {
                    feedback.push({
                      id: "create_organization_group_entitlements",
                      msg: intl.formatMessage(
                        {
                          defaultMessage: "Entitlements added for {name}.",
                          description:
                            "success notification for adding entitlements to created group. 'name' = name of the created group",
                        },
                        {
                          name: "<strong>" + res.group.name + "</strong>",
                        }
                      ),
                      autoClose: true,
                      type: "success",
                    });
                  } else {
                    onClearError(
                      (res as any as AddErrorAction<any>).error?.errorId
                    );
                    feedback.push({
                      id:
                        "create_organization_group_entitlements" +
                        (res.group ? (res.group.id as string) : "not_possible"),
                      msg: intl.formatMessage(
                        {
                          defaultMessage:
                            "Adding entitlements for {name} failed.",
                          description:
                            "failure notification for creating group. 'name' = name of the failed group",
                        },
                        {
                          name: "<strong>" + res.group.name + "</strong>",
                        }
                      ),
                      type: "danger",
                    });
                  }
                  onShowFeedback(feedback);
                  onClose();
                });
              } else {
                onShowFeedback(feedback);
                onClose();
              }
            } else {
              onClearError(res.error?.errorId);
              onShowFeedback({
                id: "create_organization_group",
                msg: intl.formatMessage({
                  defaultMessage: "Creating organization group failed.",
                  description:
                    "failure notification for unspecified error when creating group.",
                }),
                type: "danger",
              });
              onClose();
            }
          });
        })();
      }}
      secondaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Cancel",
          description: "secondary button label",
        }),
      }}
      onSecondaryAction={onClose}
    >
      <>
        <Form noValidate>
          <FormInput
            data-test-create-organization-group-modal-name
            label={intl.formatMessage(OrganizationGroupLabels.name)}
            field="name"
            register={register}
            registerOptions={{
              required: true,
            }}
            hasValue={!!formValues["name"]}
            resolveValidity={FormInputUtils.validityResolver(formState)}
          >
            {errors &&
              errors.name &&
              errors.name.type &&
              errors.name.type === "required" && (
                <Form.Control.Feedback type="invalid">
                  <FontAwesomeIcon
                    icon={IconLibrary.icons.faExclamationCircle}
                    className={"icon"}
                  />
                  <span className={"copy"}>
                    {intl.formatMessage(
                      {
                        defaultMessage: "{name} is required.",
                        description:
                          "Field validation error. 'name' = Field label for organization group name (OrganizationGroup.name)",
                      },
                      {
                        name: intl.formatMessage(OrganizationGroupLabels.name),
                      }
                    )}
                  </span>
                </Form.Control.Feedback>
              )}
          </FormInput>
          <FormInput
            data-test-create-organization-group-modal-type
            label={intl.formatMessage(OrganizationGroupLabels.type)}
            field="type"
            register={register}
            registerOptions={{
              required: true,
              pattern: {
                value: /^(?!employees$)/g,
                message: intl.formatMessage(
                  {
                    defaultMessage:
                      'Your organization can have only one group with {type} "employees". Please enter a different {type}.',
                    description:
                      "Field validation error. 'type' = Field label for organization group type (OrganizationGroup.type)",
                  },
                  {
                    type: intl.formatMessage(OrganizationGroupLabels.type),
                  }
                ),
              },
            }}
            hasValue={!!formValues["type"]}
            resolveValidity={FormInputUtils.validityResolver(formState)}
          >
            {errors &&
              errors.type &&
              errors.type.type &&
              errors.type.type === "required" && (
                <Form.Control.Feedback type="invalid">
                  <FontAwesomeIcon
                    icon={IconLibrary.icons.faExclamationCircle}
                    className={"icon"}
                  />
                  <span className={"copy"}>
                    {intl.formatMessage(
                      {
                        defaultMessage: "{type} is required.",
                        description:
                          "Field validation error. 'type' = Field label for organization group type (OrganizationGroup.type)",
                      },
                      {
                        type: intl.formatMessage(OrganizationGroupLabels.type),
                      }
                    )}
                  </span>
                </Form.Control.Feedback>
              )}
            {errors &&
              errors.type &&
              errors.type.type &&
              errors.type.type === "pattern" && (
                <Form.Control.Feedback type="invalid">
                  <FontAwesomeIcon
                    icon={IconLibrary.icons.faExclamationCircle}
                    className={"icon"}
                  />
                  <span className={"copy"}>{(errors as any).type.message}</span>
                </Form.Control.Feedback>
              )}
          </FormInput>
          <FormInput
            type={"textarea"}
            data-test-create-organization-group-modal-description
            label={intl.formatMessage(OrganizationGroupLabels.description)}
            field="description"
            register={register}
            registerOptions={{
              required: false,
            }}
            hasValue={!!formValues["description"]}
            resolveValidity={FormInputUtils.validityResolver(formState)}
          >
          </FormInput>

          {entitlements && (
            <Form.Group className={"form-table"}>
              <Table<Entitlement>
                disableLoadingIndicator={!isReady}
                maxRows={5}
                compact={true}
                data-test-select-license-groups
                header={
                  <Form.Label>
                    {intl.formatMessage(OrganizationGroupLabels.entitlements)}
                  </Form.Label>
                }
                search={entitlements.length > TABLE_SEARCH_THRESHOLD}
                activeSearch={activeSearch}
                onSearch={onSetActiveSearch}
                columnToggle={false}
                reset={false}
                data={entitlements}
                pagination={false}
                identifyingColumn={"id"}
                selection={{
                  multi: true,
                  selectAll: true,
                }}
                onSelectionChanged={onSetSelected}
                selected={selected}
                columns={[
                  {
                    key: "id",
                    label: intl.formatMessage(OrganizationGroupLabels.id),
                    isTechnical: true,
                    hidden: true,
                  },
                  {
                    key: "name",
                    label: intl.formatMessage({
                      defaultMessage: "Entitlements",
                      description:
                        "column heading for the entitlements to select",
                    }),
                    sortable: true,
                  },
                ]}
              />
            </Form.Group>
          )}
        </Form>
      </>
    </Modal>
  );
}

export default CreateOrganizationGroupModal;
