import {
  AppAction,
  DeleteUserAction,
  GetUserAction,
  RemoveMembershipResult,
  RemoveOrganizationResult,
  RemoveUserFromOrgAction,
} from "../actions/actionTypes";

/**
 * Removes all members matching the ids from state object.
 * @param ids The ids to remove
 * @param state Current state
 */
export function removeFromStateById<T>(ids: string[] | undefined, state: T): T {
  const updated: T = {
    ...state,
  };
  if (ids) {
    for (let i = 0; i < ids.length; i += 1) {
      delete updated[ids[i] as keyof T];
    }
  }
  return updated;
}

/**
 * Removes all matching ids from all member lists in the state object.
 * @param ids The ids to remove
 * @param state Current state
 */
export function removeFromStateListsById<T extends {}>(
  ids: string[] | undefined,
  state: T
): T {
  const updated: T = {
    ...state,
  };
  if (ids) {
    const lists = Object.keys(updated) as Array<keyof T>;
    for (let i = 0; i < lists.length; i += 1) {
      updated[lists[i] as keyof T] = (
        (updated[lists[i] as keyof T] || []) as string[]
      ).filter((f) => !ids.find((s) => s === f)) as any;
    }
  }
  return updated;
}
// type guard functions, these seem to be required to allow typscript to do some sort of type comparisons.
function isRemoveMembershipResult(
  toBeDetermined: RemoveMembershipResult | AppAction
): toBeDetermined is RemoveMembershipResult {
  if ((toBeDetermined as RemoveMembershipResult).membershipRemovingResult) {
    return true;
  }
  return false;
}
function isDeleteUserAction(
  toBeDetermined: DeleteUserAction | AppAction
): toBeDetermined is DeleteUserAction {
  if ((toBeDetermined as DeleteUserAction).userId) {
    return true;
  }
  return false;
}
function isGetUserAction(
  toBeDetermined: GetUserAction | AppAction
): toBeDetermined is GetUserAction {
  if ((toBeDetermined as GetUserAction).userId) {
    return true;
  }
  return false;
}

function isRemoveOrganizationResult(
  toBeDetermined: AppAction
): toBeDetermined is RemoveOrganizationResult {
  if ((toBeDetermined as RemoveOrganizationResult).organizationRemovingResult) {
    return true;
  }
  return false;
}
function isRemoveUserFromOrgAction(
  toBeDetermined: AppAction
): toBeDetermined is RemoveUserFromOrgAction {
  if ((toBeDetermined as RemoveUserFromOrgAction).userId) {
    return true;
  }
  return false;
}

/**
 * Uses the action to resolve user ids to remove
 * @param action
 */
function resolveUserIdsToRemove(action: AppAction): string[] | undefined {
  let ids;
  if (isRemoveMembershipResult(action)) {
    ids = action.membershipRemovingResult?.noMembershipUserIds;
  } else if (isRemoveOrganizationResult(action)) {
    ids = action.organizationRemovingResult?.noMembershipOrgIds?.find(
      (f) => action.orgId === f
    )
      ? [action.userId]
      : undefined;
  } else if (
    isDeleteUserAction(action) ||
    isGetUserAction(action) ||
    isRemoveUserFromOrgAction(action)
  ) {
    ids = [action.userId];
  } else {
    throw new Error("Action not supported");
  }
  return ids;
}

/**
 * Removes all matching `"id": {...}` members from the state object based on ids provided by the action.
 * @param action
 * @param state
 */
export function removeUsersFromState<T>(action: AppAction, state: T): T {
  return removeFromStateById<T>(resolveUserIdsToRemove(action), state);
}

/**
 * Removes all matching ids from the list members of the state object based on ids provided by the action.
 * @param action
 * @param state
 */
export function removeUsersFromStateLists<T extends {}>(
  action: AppAction,
  state: T
): T {
  return removeFromStateListsById<T>(resolveUserIdsToRemove(action), state);
}
