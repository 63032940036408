import { AppState } from "../store/AppState";
import { LicenseUsage } from "../model/entitlement/LicenseUsage";
import { LicenseWithOwner } from "../model/entitlement/LicenseWithOwner";
import { LicenseUserWithAssignmentsAndSessions } from "../model/entitlement/LicenseUserWithAssignmentsAndSessions";
import { LicenseAssignmentsById } from "../store/LicenseState";
import { UserWithLicenseUsage } from "../model/User";
import OrganizationUtils from "./organization";
import { isValid } from "../util/objectUtil";
import { UserLicensedItemAssignments } from "../model/entitlement/UserLicensedItemAssignments";
import { LicensedItem } from "../model/entitlement/LicensedItem";
import { LicenseAndAssignments } from "../model/entitlement/LicenseAndAssignments";
import { consumesLicense } from "../util/licenseUtil";
import {ClientWithLicenseUsage} from "../model/Client";
import EntitlementUtils from "./entitlement";

/**
 * Checks if the given license user has at least one valid reservation.
 * @param licenseUser The license user
 * @returns true if at least one reservation found, false otherwise
 */
function hasValidReservation(
  licenseUser: LicenseUserWithAssignmentsAndSessions
): boolean {
  return !!licenseUser.assignments
    ? !!licenseUser.assignments.find(
        (assignment) => assignment.type === "reserved" && isValid(assignment)
      )
    : false;
}
/**
 * Checks if the given license user has at least one valid denial.
 * @param licenseUser The license user
 * @returns true if at least one denial found, false otherwise
 */
function hasValidDenial(
  licenseUser: LicenseUserWithAssignmentsAndSessions
): boolean {
  return !!licenseUser.assignments
    ? !!licenseUser.assignments.find(
        (assignment) => assignment.type === "denied" && isValid(assignment)
      )
    : false;
}

function selectLicense(
  id: string,
  state: AppState
): (LicenseUsage & LicenseWithOwner) | undefined {
  return !!id && !!state.licenses ? state.licenses[id] : undefined;
}
function selectLicenseWithUsageAndAssignments(
  id: string,
  state: AppState
): (LicenseUsage & LicenseWithOwner) | undefined {
  const l = selectLicense(id, state);
  if (
    !l ||
    !state.licenseUsage ||
    !state.licenseUsage[l.id as string] ||
    !state.licenseAssignments
  ) {
    return l;
  }
  const licenseUsers = state.licenseUsage[l.id as string].users.map(
    (userData) => {
      let user: LicenseUserWithAssignmentsAndSessions | undefined = undefined;
      const existingLicUsageIndex = l.users
        ? l.users.findIndex((licUser) => licUser.id === userData.userId)
        : -1;
      const existingLicUsage =
        existingLicUsageIndex === -1
          ? undefined
          : (l.users as LicenseUserWithAssignmentsAndSessions[])[
              existingLicUsageIndex
            ];
      if (existingLicUsage) {
        user = existingLicUsage;
      }
      if (!user && state.users) {
        user = state.users[userData.userId];
      }
      if (!user) {
        user = {
          id: userData.userId,
        };
      }

      const assignments = userData.assignmentIds.map(
        (assId) => (state.licenseAssignments as LicenseAssignmentsById)[assId]
      )
      // remove undefined assignments that were not found from license assignments, they're likely a leftover from
      // license release that does not have sufficient data to clear them.
      .filter((f) => !!f);
      return { ...user, assignments };
    }
  );

  return { ...l, users: licenseUsers };
}
function selectOrganizationLicenseEntitlementUsersWithLicenseReservation(
  license: LicenseUsage,
  state: AppState
): UserWithLicenseUsage[] | undefined {
  const orgId = OrganizationUtils.selectSelectedOrganizationId(state);
  let orgUsers = orgId && license && license.entitlementId ?
      EntitlementUtils.selectOrgEntitlementUsers(orgId, license.entitlementId, state) as UserWithLicenseUsage[] :
      undefined
  ;
  if (
    !license ||
    !orgId ||
    !orgUsers ||
    !state.orgUserIds ||
    !state.orgUserIds[orgId] ||
    !state.users
  ) {
    return;
  }
  const licenseUsers = license.users ? license.users : [];
  return orgUsers.map((uo) => {
    const u = { ...uo };
    const usrId = u.id;
    const t = licenseUsers.find(
      (lu) => lu.id === usrId
    ) as UserWithLicenseUsage;
    if (t) {
      u.assignments = t.assignments;
      u.hasReservation = hasValidReservation(u);
      u.hasDenial = hasValidDenial(u);
    } else {
      u.hasReservation = false;
      u.hasDenial = false;
    }
    return u;
  });
}
function selectOrganizationLicenseEntitlementClientsWithLicenseReservation(
    license: LicenseUsage,
    state: AppState
): ClientWithLicenseUsage[] | undefined {
  const orgId = OrganizationUtils.selectSelectedOrganizationId(state);
  let orgClients = orgId && license && license.entitlementId ?
      EntitlementUtils.selectOrgEntitlementClients(orgId, license.entitlementId, state) as ClientWithLicenseUsage[] :
      undefined
  ;
  if (
      !license ||
      !orgId ||
      !orgClients ||
      !state.orgClientIds ||
      !state.orgClientIds[orgId] ||
      !state.orgClients
  ) {
    return;
  }
  // I'm a bit lost with these types, sometimes clients are users, but usually not. I think here they should be.
  const licenseUsers = (license.users ? license.users : []) as any as ClientWithLicenseUsage[];
  return orgClients.map((uo) => {
    const c = { ...uo };
    const clntId = c.id;
    const t = licenseUsers.find(
        (lu) => lu.id === clntId
    ) as ClientWithLicenseUsage;
    if (t) {
      c.assignments = t.assignments;
      c.hasReservation = hasValidReservation(c);
      c.hasDenial = hasValidDenial(c);
    } else {
      c.hasReservation = false;
      c.hasDenial = false;
    }
    return c;
  });
}
function compareLicensesStates(next: AppState, prev: AppState): boolean {
  return next.licenses === prev.licenses;
}
function compareLicenseAssignmentsStates(
  next: AppState,
  prev: AppState
): boolean {
  return next.licenseAssignments === prev.licenseAssignments;
}
function compareLicenseUsageAndAssignmentsStates(
  next: AppState,
  prev: AppState
): boolean {
  return (
    next.licenseUsage === prev.licenseUsage &&
    compareLicenseAssignmentsStates(next, prev)
  );
}
function selectClientLicensedItemAssignments(
    clientId: string,
    state: AppState
): UserLicensedItemAssignments[] | undefined {
  const orgId = OrganizationUtils.selectSelectedOrganizationId(state);
  if (
      !orgId ||
      !state.clientAvailableLicenses ||
      !state.clientAvailableLicenses[clientId] ||
      !state.licenses ||
      !state.licenseAssignments
  ) {
    return undefined;
  }

  if (orgId && (!state.orgLicenseIds || !state.orgLicenseIds[orgId])) {
    // store is not properly populated.
    return undefined;
  }

  if (!state.clientAvailableLicenses[clientId].length) {
    return [];
  }

  const retValue: UserLicensedItemAssignments[] = [];
  const licensesAvailableToUser = state.clientAvailableLicenses[clientId];
  for (const licenseAvailableToUser of licensesAvailableToUser) {
    // fetch license from store.
    const license = state.licenses[licenseAvailableToUser.licenseId];
    if (!license.licensedItem) {
      console.error(
          "Incomplete license data for license %s, licensedItem missing",
          license.id
      );
      continue;
    }

    if (
        orgId &&
        state.orgLicenseIds &&
        !state.orgLicenseIds[orgId].includes(license.id as string)
    ) {
      // License does not belong to specified organization.
      continue;
    }

    const licensedItem = license.licensedItem as LicensedItem;

    // check if UserLicensedItemAssignments already exists for this licensed item...
    let userLicensedItemAssignment = retValue.find(
        (existingResultObj) =>
            existingResultObj.licensedItemId === licensedItem.id &&
            existingResultObj.licenseId === license.id
    );

    // ...if not then create new and add it to returned object.
    if (!userLicensedItemAssignment) {
      userLicensedItemAssignment = {} as UserLicensedItemAssignments;
      userLicensedItemAssignment.licenseId = license.id as string;
      userLicensedItemAssignment.licensedItemId = licensedItem.id as string;
      userLicensedItemAssignment.licensedItemName = licensedItem.name as string;
      userLicensedItemAssignment.licensedItemDisplayName =
          licensedItem.displayName;
      userLicensedItemAssignment.userId = clientId;
      userLicensedItemAssignment.isConsuming = false;
      retValue.push(userLicensedItemAssignment);
    }

    // Fetch assigments from store for this license.
    const assignments = licenseAvailableToUser.assignmentIds.map(
        (assignmentId) =>
            (state.licenseAssignments as LicenseAssignmentsById)[assignmentId]
    );

    // Construct LicenseAndAssignments structure from license and assignments.
    const licenseAndAssignments: LicenseAndAssignments = {
      license,
      assignments,
    };

    // Add created LicenseAndAssignments to results.
    userLicensedItemAssignment.assignments =
        userLicensedItemAssignment.assignments || ([] as LicenseAndAssignments[]);
    userLicensedItemAssignment.assignments.push(licenseAndAssignments);

    // Check if user is consuming license.
    userLicensedItemAssignment.isConsuming = consumesLicense(
        clientId,
        license.id as string,
        state
    );
  }

  return retValue;
}
function selectUserLicensedItemAssignments(
  userId: string,
  state: AppState
): UserLicensedItemAssignments[] | undefined {
  const orgId = OrganizationUtils.selectSelectedOrganizationId(state);
  if (
    !orgId ||
    !state.userAvailableLicenses ||
    !state.userAvailableLicenses[userId] ||
    !state.licenses ||
    !state.licenseAssignments
  ) {
    return undefined;
  }

  if (orgId && (!state.orgLicenseIds || !state.orgLicenseIds[orgId])) {
    // store is not properly populated.
    return undefined;
  }

  if (!state.userAvailableLicenses[userId].length) {
    return [];
  }

  const retValue: UserLicensedItemAssignments[] = [];
  const licensesAvailableToUser = state.userAvailableLicenses[userId];
  for (const licenseAvailableToUser of licensesAvailableToUser) {
    // fetch license from store.
    const license = state.licenses[licenseAvailableToUser.licenseId];
    if (!license.licensedItem) {
      console.error(
        "Incomplete license data for license %s, licensedItem missing",
        license.id
      );
      continue;
    }

    if (
      orgId &&
      state.orgLicenseIds &&
      !state.orgLicenseIds[orgId].includes(license.id as string)
    ) {
      // License does not belong to specified organization.
      continue;
    }

    const licensedItem = license.licensedItem as LicensedItem;

    // check if UserLicensedItemAssignments already exists for this licensed item...
    let userLicensedItemAssignment = retValue.find(
      (existingResultObj) =>
        existingResultObj.licensedItemId === licensedItem.id &&
        existingResultObj.licenseId === license.id
    );

    // ...if not then create new and add it to returned object.
    if (!userLicensedItemAssignment) {
      userLicensedItemAssignment = {} as UserLicensedItemAssignments;
      userLicensedItemAssignment.licenseId = license.id as string;
      userLicensedItemAssignment.licensedItemId = licensedItem.id as string;
      userLicensedItemAssignment.licensedItemName = licensedItem.name as string;
      userLicensedItemAssignment.licensedItemDisplayName =
        licensedItem.displayName;
      userLicensedItemAssignment.userId = userId;
      userLicensedItemAssignment.isConsuming = false;
      retValue.push(userLicensedItemAssignment);
    }

    // Fetch assigments from store for this license.
    const assignments = licenseAvailableToUser.assignmentIds.map(
      (assignmentId) =>
        (state.licenseAssignments as LicenseAssignmentsById)[assignmentId]
    );

    // Construct LicenseAndAssignments structure from license and assignments.
    const licenseAndAssignments: LicenseAndAssignments = {
      license,
      assignments,
    };

    // Add created LicenseAndAssignments to results.
    userLicensedItemAssignment.assignments =
      userLicensedItemAssignment.assignments || ([] as LicenseAndAssignments[]);
    userLicensedItemAssignment.assignments.push(licenseAndAssignments);

    // Check if user is consuming license.
    userLicensedItemAssignment.isConsuming = consumesLicense(
      userId,
      license.id as string,
      state
    );
  }

  return retValue;
}
const LicenseUtils = {
  selectLicense,
  selectLicenseWithUsageAndAssignments,
  selectOrganizationLicenseEntitlementUsersWithLicenseReservation,
  selectOrganizationLicenseEntitlementClientsWithLicenseReservation,
  compareLicensesStates,
  compareLicenseUsageAndAssignmentsStates,
  selectUserLicensedItemAssignments,
  compareLicenseAssignmentsStates,
  selectClientLicensedItemAssignments,
};
export default LicenseUtils;
