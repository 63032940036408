import { connect } from "react-redux";
import ManageDeviceClientsDeviceClientGroupsModal, {
  ManageDeviceClientsDeviceClientGroupsModalProps as _ManageDeviceClientsDeviceClientGroupsModalProps,
} from "./manage-device-clients-device-client-groups-modal-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import OrganizationUtils from "../../../utils/organization";
import {
  addClientGroupsForClient,
  clearError, getClient,
  listClientGroupsOfClient, listOrganizationClientGroups,
  removeClientGroupsOfClient,
} from "../../../actions";
import {
  ManageDeviceClientsDeviceClientGroupsModalDataProps,
} from "./manage-device-clients-device-client-groups-modal-view";
import InProgressUtils from "../../../utils/in-progress";
import { addSenderArgument } from "../../../actions/actionHelpers";

export type ManageDeviceClientsDeviceClientGroupsModalProps = Omit<
  _ManageDeviceClientsDeviceClientGroupsModalProps,
  keyof ManageDeviceClientsDeviceClientGroupsModalDataProps
>;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ManageDeviceClientsDeviceClientGroupsModalProps,
  ManageDeviceClientsDeviceClientGroupsModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ManageDeviceClientsDeviceClientGroupsModalProps,
  ManageDeviceClientsDeviceClientGroupsModalProps
>;

const sender = { key: "manage-device-clients-device-client-groups" };

function mapStateToProps(
  state: AppState,
  ownProps: ManageDeviceClientsDeviceClientGroupsModalProps
): ReduxStateProps {
  const clientGroups = OrganizationUtils.selectOrganizationClientGroups(state);
  return {
    clientGroups,
    client: ownProps.clientId
      ? OrganizationUtils.selectOrganizationClient(ownProps.clientId, state)
      : undefined,
    clientsClientGroups: ownProps.clientId
      ? OrganizationUtils.selectClientClientGroupIds(ownProps.clientId, state)
      : undefined,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientGroupsStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientsStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientsClientGroupIdStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onClearError: clearError,
  onLoadClient: addSenderArgument(sender, getClient),
  onLoadClientGroups: addSenderArgument(sender, listOrganizationClientGroups),
  onLoadClientsClientGroups: addSenderArgument(sender, listClientGroupsOfClient),
  onAddGroups: addSenderArgument(sender, addClientGroupsForClient),
  onRemoveGroups: addSenderArgument(sender, removeClientGroupsOfClient),
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ManageDeviceClientsDeviceClientGroupsModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ManageDeviceClientsDeviceClientGroupsModal);
