import { useContext, useEffect } from "react";
import {
    TooltipWrapper,
    FeedbackContainerProps,
    ShowFeedback,
    Page,
    PageDOMProps,
    Button,
    IconLibrary
} from "@10duke/dukeui";
import { useIntl } from "react-intl";
import Table, { TableColumn } from "../../table";
import { Dropdown } from "react-bootstrap";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
import ViewGroupModal from "../../modals/view-group-modal";
import CreateOrganizationGroupModal from "../../modals/create-organization-group-modal";
import RemoveGroupModal from "../../modals/remove-group-modal";
import CreateInvitationModal from "../../modals/create-invitation-modal";
import ManageGroupMembersModal from "../../modals/manage-group-members-modal";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import GroupTypeBadge from "../../badges/group-type-badge";
import ViewEntitlementModal from "../../modals/view-entitlement-modal";
import { OrganizationGroupLabels } from "../../../localization/organization-group";
import {hasAction, resolveColumns} from "../../../ui-configuration/configuration-tools";
import HeaderActions from "../../table/header-actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export enum ModalKeys {
  show = "show",
  showEntitlement = "show-entitlement",
  edit = "edit",
  remove = "remove",
  members = "members",
  create = "create",
  invite = "invite",
}
//<editor-fold desc="Props">

export interface GroupsModalVisibilityProps {
  showModal?: { key: ModalKeys; groupId?: string; secondaryId?: string };
  onShowModal: (key: ModalKeys, groupId?: string, secondaryId?: string) => void;
  onHideModal: (callback?: () => void) => void;
}
export interface GroupsDOMProps extends PageDOMProps {}

export interface GroupsStateProps extends FeedbackContainerProps {
  selected: OrganizationGroup[];
  onSetSelected: (selection: OrganizationGroup[]) => void;
}
export interface GroupsProps
  extends GroupsStateProps,
    GroupsDOMProps,
    GroupsModalVisibilityProps {
  userName?: string;
  userId?: string;
  employeesGroupId?: string;
  groups?: OrganizationGroup[];
  onLoadGroups: () => Promise<any>;
}
//</editor-fold>

//<editor-fold desc="Messages">
//</editor-fold>

//<editor-fold desc="Utils">

const resolveRowClasses = (row: OrganizationGroup, rowIndex: number) => {
  return row.type;
};

const resolveGroup = (
  groupId: string | undefined,
  groups: OrganizationGroup[] | undefined
) => {
  let retVal: OrganizationGroup | undefined | null;
  if (groupId && groups) {
    retVal = groups.find((val) => val.id === groupId);
    if (!retVal) {
      retVal = null;
    }
  }
  return retVal;
};

//</editor-fold>

function Groups(props: GroupsProps) {
  //<editor-fold desc="Local variables">
  const {
    groups,
    onLoadGroups,
    selected,
    onSetSelected,
    employeesGroupId,
    onShowModal,
    onHideModal,
    showModal,
    onShowFeedback,
    onHideFeedback,
    feedback,
    userName,
    userId,
    ...others
  } = props;

  const modalGroup: OrganizationGroup | undefined | null = showModal
    ? resolveGroup(showModal.groupId, groups)
    : undefined;

  // this is more like a variable than a hook
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: "Manage user groups",
    description: "heading for the Manage user groups page",
  });
  const description = intl.formatMessage({
    defaultMessage: "Organization user group management",
    description: "window meta description for the Manage user groups screen",
  });

  const { conf } = useContext(UIConfiguration);
  const groupConf =
    conf.functionality && conf.functionality.groups
      ? conf.functionality.groups
      : {};

  let columns: TableColumn[] = [
    {
      key: "id",
      label: intl.formatMessage(OrganizationGroupLabels.id),
      isTechnical: true,
      hidden: true,
    },
    {
      key: "type",
      sortable: true,
      label: intl.formatMessage(OrganizationGroupLabels.type),
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return <GroupTypeBadge type={props.cell} />;
      },
      tipRenderer: () => false,
    },
    {
      key: "name",
      sortable: true,
      label: intl.formatMessage(OrganizationGroupLabels.name),
    },
    {
      key: "description",
      sortable: true,
      label: intl.formatMessage(OrganizationGroupLabels.description),
    },
  ];
  if (groupConf.columns && groupConf.columns.length > 0) {
    columns = resolveColumns(columns, groupConf.columns);
  }
  //</editor-fold>

  //<editor-fold desc="Hooks">

  // resets selection when users are undefined, and clears out selected items that are not part of updated data
  useEffect(() => {
    if (selected.length) {
      if (groups === undefined) {
        onSetSelected([]);
      } else {
        const ids = groups.map((grp: OrganizationGroup) => grp.id);
        const newS = selected.filter((itm: any) => {
          return ids.indexOf(itm.id) >= 0;
        });
        if (newS.length !== selected.length) {
          onSetSelected(newS);
        }
      }
    }
  }, [groups, selected, onSetSelected]);
  //</editor-fold>
  return (
    <Page
      header={<h1>{title}</h1>}
      data-test-groups-page
      id={"groups-page"}
      meta={{
        title,
        description,
      }}
      {...others}
    >
      <ShowFeedback
        idPrefix={"groups_"}
        onHideFeedback={onHideFeedback}
        feedback={feedback}
      />
      <Table<OrganizationGroup>
        data-test-groups-table
        persistStateKey={"groups-table"}
        allowColumnSort={true}
        selection={{
          multi: false,
          selectAll: false,
        }}
        header={<HeaderActions actions={groupConf.actions} actionRenderer={(action) => {
            let retVal = undefined;
            if (action.key === 'create') {
                retVal = (
                    <Button
                        key={action.key}
                        data-test-create-organization-group-trigger
                        action={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onShowModal(ModalKeys.create);
                        }}
                        variant={"primary"}
                        className={"btn custom-base"}
                    >
                        {intl.formatMessage({
                            defaultMessage: "Create",
                            description: "create group button label",
                        })}
                    </Button>
                );
            } else if (!!action.url) {
                retVal = (
                    <Button
                        key={action.key}
                        data-test-custom-action={action.key}
                        action={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.open(action.url, '_blank');
                        }}
                        variant={"primary"}
                        className={"btn custom-base"}
                    >
                        {intl.formatMessage({
                            defaultMessage: "{action, select, other {{action}}}",
                            description: "Custom header action label. Prints action key by default. Inject `actionKey {Label}` after the `select,` and before the `other` to provide translation for custom action. Only use space as separator with multiple actions, no comma.",
                        }, {
                            action: action.key
                        })}
                    </Button>
                );
            }
            return retVal;
        }} />}
        search={true}
        rowClasses={resolveRowClasses}
        selected={selected}
        onSelectionChanged={onSetSelected}
        columns={columns}
        columnToggle
        reload={true}
        onLoadData={onLoadGroups}
        data={groups ? groups : undefined}
        identifyingColumn={"id"}
        pagination={false}
        rowTools={(props: { rowEntry: OrganizationGroup }) => (
                groupConf.rowActions?.map((m) => {
                    let retVal = undefined;
                    if (!m.disabled) {
                      if (m.key === 'show') {
                        retVal =  (
                            <Dropdown.Item
                                data-test-row-tool-group-details={props.rowEntry.id}
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onShowModal(ModalKeys.show, props.rowEntry.id);
                                }}
                            >
                              {intl.formatMessage({
                                defaultMessage: "View details",
                                description: "Label for the tool",
                              })}
                            </Dropdown.Item>
                        );
                      } else if (m.key === 'members') {
                        retVal = (
                            <Dropdown.Item
                                data-test-row-tool-group-members={props.rowEntry.id}
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onShowModal(ModalKeys.members, props.rowEntry.id);
                                }}
                            >
                              {intl.formatMessage({
                                defaultMessage: "Members",
                                description: "Label for the tool",
                              })}
                            </Dropdown.Item>
                        );
                      } else if (m.key === 'invite') {
                          retVal = (
                            <Dropdown.Item
                                data-test-row-tool-invite-to-group={props.rowEntry.id}
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onShowModal(ModalKeys.invite, props.rowEntry.id);
                                }}
                            >
                              {intl.formatMessage({
                                defaultMessage: "Send invite",
                                description: "Label for the tool",
                              })}
                            </Dropdown.Item>
                        );
                      } else if (m.key === 'remove') {
                        if (props.rowEntry.id === employeesGroupId) {
                            retVal = (
                              <TooltipWrapper
                                  tip={intl.formatMessage({
                                    defaultMessage:
                                        'The "employees" type group cannot be removed.',
                                    description:
                                        "tooltip for disabled employees group removal",
                                  })}
                                  tipKey={"removeBlockedTip"}
                                  placement={"auto"}
                                  wrapDisabled={true}
                                  className={"d-block"}
                              >
                                <Dropdown.Item
                                    data-test-row-tool-remove-group={props.rowEntry.id}
                                    onClick={(e: any) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      onShowModal(ModalKeys.remove, props.rowEntry.id);
                                    }}
                                    disabled={true}
                                >
                                  {intl.formatMessage({
                                    defaultMessage: "Remove",
                                    description: "Label for the tool",
                                  })}
                                </Dropdown.Item>
                              </TooltipWrapper>
                          );
                        } else {
                            retVal = (
                              <Dropdown.Item
                                  data-test-row-tool-remove-group={props.rowEntry.id}
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onShowModal(ModalKeys.remove, props.rowEntry.id);
                                  }}
                              >
                                {intl.formatMessage({
                                  defaultMessage: "Remove",
                                  description: "Label for the tool",
                                })}
                              </Dropdown.Item>
                          );
                        }
                      } else if (!!m.url) {
                          retVal = (
                              <Dropdown.Item
                                  data-test-row-tool-custom={m.key}
                                  onClick={(e: any) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      window.open(m.url, '_blank');
                                  }}
                                  className={'custom-action'}
                              >
                                  <span>{intl.formatMessage({
                                      defaultMessage: "{action, select, other {{action}}}",
                                      description: "Custom row action label. Prints action key by default. Inject `actionKey {Label}` after the `select,` and before the `other` to provide translation for custom action. Only use space as separator with multiple actions, no comma.",
                                  }, {
                                      action: m.key
                                  })}</span>
                                  <FontAwesomeIcon icon={IconLibrary.icons.faExternalLinkAlt} className={'icon'} fixedWidth={true} />
                              </Dropdown.Item>
                          );
                      }
                    }
                    return retVal;
                })
        )}
      />
      {hasAction(groupConf.rowActions, 'show') && (
        <ViewGroupModal
          show={
            showModal && showModal.groupId
              ? showModal.key === ModalKeys.show ||
                (groupConf.allowEdit && showModal.key === ModalKeys.edit)
                ? true
                : false
              : false
          }
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
          edit={showModal ? showModal.key === ModalKeys.edit : false}
          allowEdit={groupConf.allowEdit ? true : false}
          groupId={showModal ? showModal.groupId : undefined}
        />
      )}
      {hasAction(groupConf.rowActions, 'remove') && (
        <RemoveGroupModal
          show={
            showModal && showModal.groupId
              ? showModal.key === ModalKeys.remove
              : false
          }
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
          groupId={showModal ? showModal.groupId : undefined}
        />
      )}
      {hasAction(groupConf.actions, 'create') && (
        <CreateOrganizationGroupModal
          show={showModal ? showModal.key === ModalKeys.create : false}
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
        />
      )}
      {hasAction(groupConf.rowActions, 'invite') && (
        <CreateInvitationModal
          selected={showModal && modalGroup ? [modalGroup] : undefined}
          show={showModal ? showModal.key === ModalKeys.invite : false}
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
        />
      )}
      {groupConf.showEntitlement && (
        <ViewEntitlementModal
          show={showModal ? showModal.key === ModalKeys.showEntitlement : false}
          onClose={onHideModal}
          entitlementId={showModal ? showModal.secondaryId : undefined}
        />
      )}
      {hasAction(groupConf.rowActions, 'members') && (
        <ManageGroupMembersModal
          show={
            showModal
              ? showModal.key === ModalKeys.members && modalGroup !== undefined
              : false
          }
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
          groupId={showModal ? showModal.groupId : undefined}
        />
      )}
    </Page>
  );
}
export default Groups;
