import { useEffect, useRef, useState } from "react";
import View, {
  ManageDeviceClientsDeviceClientGroupsModalProps as _ManageDeviceClientsDeviceClientGroupsModalProps,
  ManageDeviceClientsDeviceClientGroupsModalStateProps,
} from "./manage-device-clients-device-client-groups-modal-view";
import _ from "lodash";
import { withCloseAfterExited } from "@10duke/dukeui";
import {ClientGroup} from "../../../model/ClientGroup";

const isArrayEqual = (x: Array<any>, y: Array<any>) => {
  return _(x).xorWith(y, _.isEqual).isEmpty();
};
const ViewWithCloseAfterExited =
  withCloseAfterExited<_ManageDeviceClientsDeviceClientGroupsModalProps>(View);
export type ManageDeviceClientsDeviceClientGroupsModalProps = Omit<
  _ManageDeviceClientsDeviceClientGroupsModalProps,
  keyof ManageDeviceClientsDeviceClientGroupsModalStateProps
>;

export default function ManageDeviceClientsDeviceClientGroupsModal(
  props: ManageDeviceClientsDeviceClientGroupsModalProps
) {
  const { show, clientsClientGroups, clientGroups, ...other } = props;

  const groupsRef = useRef(clientGroups);
  if (!_.isEqual(groupsRef.current, clientGroups)) {
    groupsRef.current = clientGroups;
  }
  const groupsRefCurrent = groupsRef.current;

  const clientsClientGroupsRef = useRef(clientsClientGroups);
  if (!_.isEqual(clientsClientGroupsRef.current, clientsClientGroups)) {
    clientsClientGroupsRef.current = clientsClientGroups;
  }
  const clientsClientGroupsRefCurrent = clientsClientGroupsRef.current;

  const [activeSearch, onSetActiveSearch] = useState("");
  const [editedGroups, setEditedGroups] = useState<string[] | undefined>(
    clientsClientGroups
  );
  const [groupsToRemove, setGroupsToRemove] = useState<ClientGroup[]>([]);
  const [groupsToAdd, setGroupsToAdd] = useState<ClientGroup[]>([]);

  useEffect(() => {
    setEditedGroups((cur) => {
      if (
        cur &&
        clientsClientGroupsRefCurrent &&
        isArrayEqual(cur, clientsClientGroupsRefCurrent)
      ) {
        setGroupsToRemove((v) =>
          v.filter((r) =>
            groupsRefCurrent
              ? groupsRefCurrent.findIndex((g) => r.id === g.id) >= 0
              : false
          )
        );
        setGroupsToAdd((v) =>
          v.filter((r) =>
            groupsRefCurrent
              ? groupsRefCurrent.findIndex((g) => r.id === g.id) >= 0
              : false
          )
        );
        return cur.filter((v) =>
          groupsRefCurrent
            ? groupsRefCurrent.findIndex((g) => g.id === v) >= 0
            : false
        );
      } else {
        setGroupsToRemove([]);
        setGroupsToAdd([]);
        return clientsClientGroupsRefCurrent
          ? _.cloneDeep(clientsClientGroupsRefCurrent)
          : clientsClientGroupsRefCurrent;
      }
    });
  }, [clientsClientGroupsRefCurrent, groupsRefCurrent, setEditedGroups]);

  useEffect(() => {
    if (!show) {
      setEditedGroups(undefined);
      setGroupsToRemove([]);
      setGroupsToAdd([]);
      onSetActiveSearch("");
    }
  }, [show, setEditedGroups]);

  const onSetGroups = (sgroups: ClientGroup[]) => {
    let newEdited: string[] | undefined = sgroups.map((u) => u.id as string);
    if (newEdited) {
      setGroupsToRemove(
        (clientsClientGroups && newEdited
          ? clientsClientGroups.filter((x) =>
              newEdited ? !newEdited.includes(x) : false
            )
          : []
        )
          .map((id) => {
            return clientGroups ? clientGroups.find((val) => val.id === id) : undefined;
          })
          .filter((val) => val !== undefined) as ClientGroup[]
      );
      setGroupsToAdd(
        (newEdited
          ? newEdited.filter((x) =>
                    clientsClientGroups ? !clientsClientGroups.includes(x) : false
            )
          : []
        )
          .map((id) => {
            return clientGroups ? clientGroups.find((val) => val.id === id) : undefined;
          })
          .filter((val) => val !== undefined) as ClientGroup[]
      );
    } else {
      console.error("this should never happen");
    }
    setEditedGroups(newEdited);
  };
  return (
    <ViewWithCloseAfterExited
      {...other}
      show={show}
      clientsClientGroups={clientsClientGroups}
      clientGroups={clientGroups}
      groupsToRemove={groupsToRemove}
      groupsToAdd={groupsToAdd}
      onSetActiveSearch={onSetActiveSearch}
      activeSearch={activeSearch}
      onSetSelected={onSetGroups}
      selected={
        editedGroups && clientGroups
          ? (editedGroups
              .map((g) => clientGroups.find((v) => v.id === g))
              .filter((v) => !!v) as ClientGroup[])
          : []
      }
    />
  );
}
