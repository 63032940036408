import { useContext, useEffect, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FieldList,Feedback,Modal } from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
import { Entitlement } from "../../../model/entitlement/Entitlement";
import { License } from "../../../model/entitlement/License";
import { ModalKeys as GroupModalKeys } from "../../pages/groups";
import { ModalKeys as ClientGroupModalKeys } from "../../pages/device-client-groups";
import { ModalKeys as LicenseModalKeys } from "../../pages/licenses";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import LicenseUtils from "../../../utils/licensed-item";
import NavigateAfterAction from "../../navigate-after-action";
import {
  EntitlementLabels,
  EntitlementValues,
} from "../../../localization/entitlement";
import { OrganizationGroupValues } from "../../../localization/organization-group";
import { LicenseValues } from "../../../localization/license";
import {
  ClearErrorAction,
  GetOrgEntitlementAction,
  isAddErrorAction,
} from "../../../actions/actionTypes";
import {ClientGroup} from "../../../model/ClientGroup";
import {ClientGroupValues} from "../../../localization/client-group/client-group";
import {hasAction} from "../../../ui-configuration/configuration-tools";

//<editor-fold desc="Props">

export interface ViewEntitlementModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface ViewEntitlementModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {}
export interface ViewEntitlementModalInputProps
  extends ViewEntitlementModalVisibilityProps {
  entitlementId: string | undefined;
}
export interface ViewEntitlementModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  entitlement: Entitlement | undefined | null;
  onLoadEntitlement: (
      entitlementId: string
  ) => Promise<GetOrgEntitlementAction>;
  entitlementGroups: OrganizationGroup[] | undefined | null;
  onLoadEntitlementGroups: (entitlementId: string) => void;
  entitlementClientGroups: ClientGroup[] | undefined | null;
  onLoadEntitlementClientGroups: (entitlementId: string) => void;
  entitlementLicenses: License[] | undefined | null;
  onLoadEntitlementLicenses: (entitlementId: string) => void;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface ViewEntitlementModalProps
  extends ViewEntitlementModalDOMProps,
    ViewEntitlementModalInputProps,
    ViewEntitlementModalDataProps {}
//</editor-fold>

function ViewEntitlementModal(props: ViewEntitlementModalProps) {
  //<editor-fold desc="Local variables">
  let {
    entitlementLicenses,
    onLoadEntitlementLicenses,
    entitlementGroups,
    onLoadEntitlementGroups,
    entitlement,
    entitlementId,
    onLoadEntitlement,
    show,
    onClose,
    isReady,
    onExited,
    onClearError,
    onLoadEntitlementClientGroups,
      entitlementClientGroups,
  } = props;

  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>
  //<editor-fold desc="Hooks">
  useEffect(() => {
    if (
      show &&
      entitlementId &&
      (entitlement === undefined ||
        (entitlement && entitlement.id !== entitlementId)) &&
      onLoadEntitlement
    ) {
      onLoadEntitlement(entitlementId).then((res) => {
        if (!entitlement && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, entitlementId, entitlement, onLoadEntitlement, onClearError]);
  useEffect(() => {
    if (
      show &&
      entitlementId &&
      entitlementGroups === undefined &&
      onLoadEntitlementGroups
    ) {
      onLoadEntitlementGroups(entitlementId);
    }
  }, [show, entitlementId, entitlementGroups, onLoadEntitlementGroups]);
  useEffect(() => {
    if (
      show &&
      entitlementId &&
      entitlementLicenses === undefined &&
      onLoadEntitlementLicenses
    ) {
      onLoadEntitlementLicenses(entitlementId);
    }
  }, [show, entitlementId, entitlementLicenses, onLoadEntitlementLicenses]);

  const { conf } = useContext(UIConfiguration);
  const groupConf =
    conf.functionality && conf.functionality.groups
      ? conf.functionality.groups
      : {};
  const licenseConf =
      conf.functionality && conf.functionality.licenses
          ? conf.functionality.licenses
          : {};
  const deviceClientGroupConf =
      conf.functionality && conf.functionality["device-client-groups"]
          ? conf.functionality["device-client-groups"]
          : {};

  const showClientGroups =
      conf.functionality && conf.functionality.sections
          ? conf.functionality.sections.indexOf("device-client-groups") >= 0
          : false;

  useEffect(() => {
    if (
        show &&
        showClientGroups &&
        entitlementId &&
        entitlementClientGroups === undefined &&
        onLoadEntitlementClientGroups
    ) {
      onLoadEntitlementClientGroups(entitlementId);
    }
  }, [show, showClientGroups, entitlementId, entitlementClientGroups, onLoadEntitlementClientGroups]);

  // Another typscript quirk here, have to use any as otherwise "TS2322: Type 'Element' is not assignable to type 'string | Element'."
  const fields: {[key:string]: { label:string, value: string|any }} = {
    name: {
      label: intl.formatMessage(EntitlementLabels.name),
      value: entitlement ? entitlement.name as string : "",
    },
    groups: {
      label: intl.formatMessage(EntitlementLabels.groups),
      value: (
          <>
            {entitlementGroups && entitlementGroups.length > 0
                ? entitlementGroups.map((g, i) => {
                  return (
                      <span key={"grp" + g.id}>
                            {hasAction(groupConf.rowActions, 'show') ? (
                                <NavigateAfterAction
                                    href={
                                        "/groups/" + g.id + "/" + GroupModalKeys.show
                                    }
                                    action={onClose}
                                >
                                  {g.name
                                      ? g.name
                                      : intl.formatMessage(
                                          OrganizationGroupValues.name.undefined
                                      )}
                                </NavigateAfterAction>
                            ) : (g.name
                                ? g.name
                                : intl.formatMessage(
                                    OrganizationGroupValues.name.undefined
                                ))}
                        {entitlementGroups &&
                        i < entitlementGroups.length - 1
                            ? ", "
                            : undefined}
                          </span>
                  );
                })
                : intl.formatMessage(EntitlementValues.groups.undefined)}
          </>
      ),
    },
  };

  if (showClientGroups) {
    fields.clientGroups = {
      label: intl.formatMessage(EntitlementLabels.clientGroups),
      value: (
          <>
            {entitlementClientGroups && entitlementClientGroups.length > 0
                ? entitlementClientGroups.map((g, i) => {
                  return (
                      <span key={"grp" + g.id}>
                            {hasAction(deviceClientGroupConf.rowActions, 'show') ? (
                                <NavigateAfterAction
                                    href={
                                        "/device-client-groups/" + g.id + "/" + ClientGroupModalKeys.show
                                    }
                                    action={onClose}
                                >
                                  {g.name
                                      ? g.name
                                      : intl.formatMessage(
                                          ClientGroupValues.name.undefined
                                      )}
                                </NavigateAfterAction>
                            ) : (g.name
                                ? g.name
                                : intl.formatMessage(
                                    ClientGroupValues.name.undefined
                                ))}
                        {entitlementClientGroups &&
                        i < entitlementClientGroups.length - 1
                            ? ", "
                            : undefined}
                          </span>
                  );
                })
                : intl.formatMessage(EntitlementValues.clientGroups.undefined)}
          </>
      ),
    };
  }
  fields.licenses = {
    label: intl.formatMessage(EntitlementLabels.licenses),
    value: (
        <>
          {entitlementLicenses && entitlementLicenses.length > 0
              ? entitlementLicenses.map((l, i) => {
                return (
                    <span key={"lic" + l.id}>
                          {hasAction(licenseConf.rowActions, 'show') ? (
                              <NavigateAfterAction
                                  href={
                                      "/licenses/" +
                                      l.id +
                                      "/" +
                                      LicenseModalKeys.show
                                  }
                                  action={onClose}
                              >
                                {l.licensedItem
                                    ? LicenseUtils.resolveDisplayName(
                                        l.licensedItem,
                                        intl.formatMessage(
                                            LicenseValues.licensedItem.displayName
                                                .undefined
                                        )
                                    )
                                    : intl.formatMessage(
                                        LicenseValues.licensedItem.displayName
                                            .undefined
                                    )}
                              </NavigateAfterAction>
                          ) : (
                          <>
                            {l.licensedItem
                                ? LicenseUtils.resolveDisplayName(
                                    l.licensedItem,
                                    intl.formatMessage(
                                        LicenseValues.licensedItem.displayName
                                            .undefined
                                    )
                                )
                                : intl.formatMessage(
                                    LicenseValues.licensedItem.displayName
                                        .undefined
                                )}
                          </>
                      )}
                      {entitlementLicenses &&
                      i < entitlementLicenses.length - 1
                          ? ", "
                          : undefined}
                        </span>
                );
              })
              : intl.formatMessage(EntitlementValues.licenses.undefined)}
        </>
    ),
  };

  //</editor-fold>
  return (
    <Modal
      onExited={onExited}
      onReloadData={() => {
        if ((entitlement || entitlementId) && onLoadEntitlement) {
          onLoadEntitlement(
            entitlement ? (entitlement.id as string) : (entitlementId as string)
          ).then((res) => {
            if (!entitlement && isAddErrorAction(res)) {
              // only clear error if no data exists, leave if previous data is still available and
              // let the api error notification handler show error
              onClearError(res.error?.errorId);
            }
          });
        }
        if ((entitlement || entitlementId) && onLoadEntitlementGroups) {
          onLoadEntitlementGroups(
              entitlement ? (entitlement.id as string) : (entitlementId as string)
          );
        }
        if (showClientGroups && (entitlement || entitlementId) && onLoadEntitlementClientGroups) {
          onLoadEntitlementClientGroups(
              entitlement ? (entitlement.id as string) : (entitlementId as string)
          );
        }
        if ((entitlement || entitlementId) && onLoadEntitlementLicenses) {
          onLoadEntitlementLicenses(
            entitlement ? (entitlement.id as string) : (entitlementId as string)
          );
        }
      }}
      isReady={isReady}
      data-test-view-entitlement-modal
      title={
        !isReady || entitlement
          ? intl.formatMessage({
              defaultMessage: "Entitlement details",
              description: "modal heading",
            })
          : intl.formatMessage({
              defaultMessage: "Entitlement not found",
              description: "modal heading when entitlement was not found",
            })
      }
      show={show}
      onClose={onClose}
      primaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Close",
          description: "primary button label",
        }),
      }}
      onPrimaryAction={onClose}
    >
      {!isReady || entitlement ? (
        <FieldList
          asOneLiners={true}
          fields={fields}
        />
      ) : undefined}
      {isReady && !entitlement && (
        <Feedback type={"danger"} show={true} asChild={true}>
          <p>
            <FormattedMessage
              defaultMessage="Something went wrong and the entitlement could not be loaded. The license group may have been removed or you don't have sufficient access rights."
              description="message to be shown when there is no entitlement to display"
            />
          </p>
        </Feedback>
      )}
    </Modal>
  );
}

export default ViewEntitlementModal;
