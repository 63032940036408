import { connect } from "react-redux";
import { AppState } from "../../store/AppState";
import SessionExpirationWatcher, {
  SessionExpirationWatcherProps as _SessionExpirationWatcherProps,
} from "./session-expiration-watcher-view";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../util/typeUtil";
import AuthenticationUtils from "../../utils/authentication";

// Own props, i.e. props that this component takes as input
interface SessionExpirationWatcherProps {
  externalLogout?: boolean;
}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _SessionExpirationWatcherProps,
  SessionExpirationWatcherProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _SessionExpirationWatcherProps,
  SessionExpirationWatcherProps
>;

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    authenticationIsValidUntil:
      AuthenticationUtils.selectAuthenticationIsValidUntil(state) || 0,
  };
}
function areStatesEqual(next: AppState, prev: AppState): boolean {
  return AuthenticationUtils.compareAuthenticationIsValidUntilStates(
    next,
    prev
  );
}
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  SessionExpirationWatcherProps,
  AppState
>(mapStateToProps, {}, null, {
  areStatesEqual,
})(SessionExpirationWatcher);
