import * as ActionTypes from "../actions/actionTypes";
import {ClientInvitationIdsByOrgId} from "../store/ClientInvitationState";
export default function orgOrgClientInvitationIds(
  state: ClientInvitationIdsByOrgId,
  action: ActionTypes.AppAction
): ClientInvitationIdsByOrgId | null {
  const currentState = state || ({} as ClientInvitationIdsByOrgId);
  switch (action.type) {
    case ActionTypes.CREATE_AND_SEND_ORG_CLIENT_GROUP_INVITATION: {
      const sendOrgInvitation = action as ActionTypes.CreateAndSendOrgClientGroupInvitationAction;
      const beforeCreate = currentState[sendOrgInvitation.orgId] || [];
      const afterCreate = [
        ...beforeCreate,
        sendOrgInvitation.invitation.id as string
      ];
      return {
        ...currentState,
        [sendOrgInvitation.orgId]: afterCreate
      };
    }
    case ActionTypes.LIST_ORG_CLIENT_GROUP_INVITATIONS: {
      const listOrgInvitations = action as ActionTypes.ListOrgClientGroupInvitationsAction;
      const beforeCreate = currentState[listOrgInvitations.orgId] || [];
      const afterCreate = [
        ...beforeCreate
      ].concat(
          ...listOrgInvitations.invitations.map((inv) => inv.id as string)
      ).filter((f, i, a) => a.indexOf(f) === i);
      return {
        ...currentState,
        [listOrgInvitations.orgId]: afterCreate
      };
    }

    case ActionTypes.GET_ORG_CLIENT_GROUP_INVITATION:
      const getOrgInvitation = action as ActionTypes.GetOrgClientGroupInvitationAction;
      const beforeCreate = currentState[getOrgInvitation.orgId] || [];
      const afterCreate = [
        ...beforeCreate
      ].concat(getOrgInvitation.invitation.id as string).filter((f, i, a) => a.indexOf(f) === i);
      return {
        ...currentState,
        [getOrgInvitation.orgId]: afterCreate
      };

    case ActionTypes.DELETE_ORG_CLIENT_GROUP_INVITATION:
      const delOrgInvitation = action as ActionTypes.DeleteOrgClientGroupInvitationAction;
      return {
        ...currentState,
        [delOrgInvitation.orgId]: (
            currentState[delOrgInvitation.orgId] || []
        ).filter(invitationId => invitationId !== delOrgInvitation.invitationId)
      };

    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
