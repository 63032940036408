import {OrgadminActionConfiguration} from "../../../ui-configuration/orgadmin-configuration";
import {ReactNode, useMemo} from "react";
import {ButtonGroup, ButtonToolbar} from "react-bootstrap";

export interface HeaderActionsProps {
  actions: OrgadminActionConfiguration[]|undefined,
  actionRenderer: (adtion: OrgadminActionConfiguration) => ReactNode,
}
export default function HeaderActions(props: HeaderActionsProps) {
  const {
    actions,
    actionRenderer,
  } = props;
  const headerActions = useMemo(() => {
    return {
      own: actions?.filter((acc) => !acc.disabled && !acc.url),
      custom: actions?.filter((acc) => !acc.disabled && !!acc.url),
    };
  }, [actions])
 return <ButtonToolbar>
   {headerActions.own?.length ? <ButtonGroup>{headerActions.own?.map(actionRenderer)}</ButtonGroup> : undefined}
   {headerActions.custom?.length ? <ButtonGroup>{headerActions.custom?.map(actionRenderer)}</ButtonGroup> : undefined}
 </ButtonToolbar>
}
