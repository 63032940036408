import { useIntl, FormattedMessage } from "react-intl";
import { Screen } from "@10duke/dukeui";
import { getAppBase } from "../../../util/env";
import logo from "../../logo";
function LogoutScreen() {
  const intl = useIntl();
  return (
      <Screen
          id={"logout-screen"}
          data-test-logout-screen
          meta={{
              title: intl.formatMessage({
                  defaultMessage: "Logout completed",
                  description: "Heading for the logout screen",
              }),
              description: intl.formatMessage({
                  defaultMessage: "Logout completed successfully",
                  description: "window meta description for the logout screen",
              }),
          }}
          header={<img src={logo} alt={""} title={""} className={'logo'}/>}
          footer={
              <a
                  className={"btn btn-lg w-100 btn-primary"}
                  href={window.location.href.split(getAppBase())[0] + getAppBase()}
              >
                  <FormattedMessage
                      defaultMessage="Continue"
                      description={"continue button label"}
                  />
              </a>
          }
      >
          <h1>
              <FormattedMessage
                  defaultMessage="Logout completed"
                  description="Heading for the logout screen"
              />
          </h1>
          <p>
              <FormattedMessage
                  defaultMessage="You've been logged out successfully. In case you're using a public or shared computer, you may want to clear your browser history and cache before closing the browser."
                  description={"logout info message"}
              />
          </p>
      </Screen>
  );
}

export default LogoutScreen;
