import { useEffect, useRef, useState } from "react";
import View, {
  ManageDeviceClientGroupDeviceClientsModalProps as _ManageDeviceClientGroupDeviceClientsModalProps,
  ManageDeviceClientGroupDeviceClientsModalStateProps,
} from "./manage-device-client-group-device-clients-modal-view";
import _ from "lodash";
import { withCloseAfterExited } from "@10duke/dukeui";
import {Client} from "../../../model/Client";
const isArrayEqual = (x: Array<any>, y: Array<any>) => {
  const t = _(x).xorWith(y, _.isEqual).isEmpty();
  return t;
};

export type ManageDeviceClientGroupDeviceClientsModalProps = Omit<
  _ManageDeviceClientGroupDeviceClientsModalProps,
  keyof ManageDeviceClientGroupDeviceClientsModalStateProps
>;
const ViewWithCloseAfterExited =
  withCloseAfterExited<_ManageDeviceClientGroupDeviceClientsModalProps>(View);
export default function ManageDeviceClientGroupDeviceClientsModal(
  props: ManageDeviceClientGroupDeviceClientsModalProps
) {
  const { show, groupMembers, clients, ...other } = props;

  const groupMembersRef = useRef(groupMembers);
  if (!_.isEqual(groupMembersRef.current, groupMembers)) {
    groupMembersRef.current = groupMembers;
  }
  const groupMembersRefCurrent = groupMembersRef.current;

  const clientsRef = useRef(clients);
  if (!_.isEqual(clientsRef.current, clients)) {
    clientsRef.current = clients;
  }
  const clientsRefCurrent = clientsRef.current;

  const [activeSearch, onSetActiveSearch] = useState("");
  const [editedMembers, setEditedMembers] = useState<string[] | undefined>(
    groupMembers
  );
  const [membersToRemove, setMembersToRemove] = useState<Client[]>([]);
  const [membersToAdd, setMembersToAdd] = useState<Client[]>([]);
  useEffect(() => {
    setEditedMembers((cur) => {
      if (
        cur &&
        groupMembersRefCurrent &&
        isArrayEqual(cur, groupMembersRefCurrent)
      ) {
        // filter out items possibly removed from users
        setMembersToRemove((v) =>
          v.filter((u) =>
              clientsRefCurrent
              ? clientsRefCurrent.findIndex((ui) => u.id === ui.id) >= 0
              : false
          )
        );
        setMembersToAdd((v) =>
          v.filter((u) =>
              clientsRefCurrent
              ? clientsRefCurrent.findIndex((ui) => u.id === ui.id) >= 0
              : false
          )
        );
        return cur.filter((v) =>
            clientsRefCurrent
            ? clientsRefCurrent.findIndex((u) => u.id === v) >= 0
            : false
        );
      } else {
        // incomimg memberships changed, so reset.
        setMembersToRemove([]);
        setMembersToAdd([]);
        return groupMembersRefCurrent
          ? _.cloneDeep(groupMembersRefCurrent)
          : groupMembersRefCurrent;
      }
    });
  }, [
    groupMembersRefCurrent,
    clientsRefCurrent,
    setEditedMembers,
    setMembersToRemove,
    setMembersToAdd,
    onSetActiveSearch,
  ]);

  useEffect(() => {
    if (!show) {
      setEditedMembers(undefined);
      setMembersToRemove([]);
      setMembersToAdd([]);
      onSetActiveSearch("");
    }
  }, [show, setEditedMembers]);

  const onSetMembers = (members: Client[]) => {
    let newEdited: string[] | undefined = members.map((u) => u.id as string);
    if (newEdited) {
      const nm = (
        groupMembers && newEdited
          ? groupMembers.filter((x) =>
              newEdited ? !newEdited.includes(x) : false
            )
          : []
      )
        .map((id) => {
          return clients ? clients.find((val) => val.id === id) : undefined;
        })
        .filter((val) => val !== undefined) as Client[];
      setMembersToRemove(nm);
      const nn = (
        newEdited
          ? newEdited.filter((x) =>
              groupMembers ? !groupMembers.includes(x) : false
            )
          : []
      )
        .map((id) => {
          return clients ? clients.find((val) => val.id === id) : undefined;
        })
        .filter((val) => val !== undefined) as Client[];
      setMembersToAdd(nn);
    } else {
      console.error("this should never happen");
    }
    setEditedMembers(newEdited);
  };

  return (
    <ViewWithCloseAfterExited
      {...other}
      show={show}
      groupMembers={editedMembers}
      clients={clients}
      onSetActiveSearch={onSetActiveSearch}
      activeSearch={activeSearch}
      membersToRemove={membersToRemove}
      membersToAdd={membersToAdd}
      selected={
        editedMembers && clients
          ? (editedMembers
              .map((m) => clients.find((u) => u.id === m))
              .filter((u) => !!u) as Client[])
          : []
      }
      onSetSelected={onSetMembers}
    />
  );
}
