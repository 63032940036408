import UsersPage, {
  ModalKeys as _ModalKeys,
} from "../../components/pages/users";
import { useHistory, useLocation, useParams } from "react-router-dom";

export { _ModalKeys as ModalKeys };

function Users() {
  const history = useHistory();
  const location = useLocation();
  let { modal, userId } = useParams<any>();
  const onShowModal = (key: _ModalKeys, usrId?: string) => {
    let base: string | undefined;
    if (modal) {
      if (userId) {
        base = location.pathname.replace(`/${userId}/${modal}`, "");
      } else {
        base = location.pathname.replace(`/${modal}`, "");
      }
    } else {
      base = location.pathname;
    }
    if (base && base.endsWith("/")) {
      base = base.substr(0, base.length - 1);
    }
    if (usrId) {
      history.push(`${base}/${usrId}/${key}`);
    } else {
      history.push(`${base}/${key}`);
    }
  };
  const onHideModal = (callback?: () => void) => {
    if (userId) {
      history.push(location.pathname.replace(`/${userId}/${modal}`, ""));
    } else {
      history.push(location.pathname.replace(`/${modal}`, ""));
    }

    if (callback && typeof callback === "function") {
      callback();
    }
  };
  return (
    <UsersPage
      showModal={
        modal ? { key: modal as _ModalKeys, userId: userId } : undefined
      }
      onShowModal={onShowModal}
      onHideModal={onHideModal}
    />
  );
}
export default Users;
