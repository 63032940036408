import { useState } from "react";
import { FeedbackContainerProps,withFeedback } from "@10duke/dukeui";
import GroupsView, {
  GroupsProps as _GroupsProps,
  GroupsStateProps,
  GroupsDOMProps as _GroupsDOMProps,
  GroupsModalVisibilityProps as _GroupsModalVisibilityProps,
} from "./groups-view";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
export { ModalKeys } from "./groups-view";

export type GroupsDOMProps = _GroupsDOMProps;
export type GroupsModalVisibilityProps = _GroupsModalVisibilityProps;

export interface GroupsProps
  extends Omit<_GroupsProps, keyof GroupsStateProps> {}

function Groups(props: GroupsProps & FeedbackContainerProps) {
  const [selected, setSelected] = useState([] as OrganizationGroup[]);
  const viewProps: _GroupsProps = {
    selected: selected,
    onSetSelected: setSelected,
    ...props,
  };
  return <GroupsView {...viewProps} />;
}
export default withFeedback<GroupsProps & FeedbackContainerProps>(Groups);
