import {SessionExpirationWatcher as Watcher} from "@10duke/dukeui";
import {getClientId, getUserUIBase} from "../../api/ApiImplementation";
import {useIntl} from "react-intl";
import {asDukeLocale} from "../localize/localize-view";
import {useCallback} from "react";

export interface SessionExpirationWatcherProps {
    /**
     * Time in millis that the current authentication will remain valid
     */
    authenticationIsValidUntil: number;
    externalLogout?: boolean;
}
export default function SessionExpirationWatcher(props: SessionExpirationWatcherProps) {
    const {
        authenticationIsValidUntil,
        externalLogout,
    } = props;

    const intl = useIntl();
    /* eslint-disable no-template-curly-in-string */
    /* tslint:disable:no-template-curly-in-string */
    const idp_base = getUserUIBase().replace(
        "${locale}",
        intl.locale !== intl.defaultLocale ? "/" + asDukeLocale(intl.locale) : ""
    );
    const clientId = getClientId();
    const onRefresh = useCallback(() => window.location.reload(),[]);
    const onLogout = useCallback(() => {
        window.location.href =
            idp_base +
            "/oauth20/signout?client_id=" +
            encodeURIComponent(clientId);
    },[clientId, idp_base]);
    return <Watcher
        externalLogout={externalLogout}
        authenticationIsValidUntil={authenticationIsValidUntil}
        onRefresh={onRefresh}
        onLogout={onLogout}
    />;
}
