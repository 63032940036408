import { useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import Table, { TableColumn } from "../../table";
import { Dropdown } from "react-bootstrap";
import ViewDeviceGroupModal from "../../modals/view-device-client-group-modal";
import CreateDeviceGroupModal from "../../modals/create-device-client-group-modal";
import RemoveDeviceGroupModal from "../../modals/remove-device-client-group-modal";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import ViewEntitlementModal from "../../modals/view-entitlement-modal";
import {hasAction, resolveColumns} from "../../../ui-configuration/configuration-tools";
import ManageDeviceClientGroupDeviceClientsModal from "../../modals/manage-device-client-group-device-clients-modal";
import CreateDeviceInvitationModal from "../../modals/create-device-client-invitation-modal";
import {ClientGroup} from "../../../model/ClientGroup";
import {ClientGroupLabels} from "../../../localization/client-group";
import HeaderActions from "../../table/header-actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FeedbackContainerProps, ShowFeedback, Page, PageDOMProps, Button, IconLibrary} from "@10duke/dukeui";

export enum ModalKeys {
  show = "show",
  showEntitlement = "showEntitlement",
  edit = "edit",
  remove = "remove",
  deviceClients = "deviceClients",
  create = "create",
  invite = "invite",
}
//<editor-fold desc="Props">

export interface DeviceClientGroupsModalVisibilityProps {
  showModal?: { key: ModalKeys; clientGroupId?: string; secondaryId?: string };
  onShowModal: (key: ModalKeys, clientGroupId?: string, secondaryId?: string) => void;
  onHideModal: (callback?: () => void) => void;
}
export interface DeviceClientGroupsDOMProps extends PageDOMProps {}

export interface DeviceClientGroupsStateProps extends FeedbackContainerProps {
  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;
  selected: ClientGroup[];
  onSetSelected: (selection: ClientGroup[]) => void;
}
export interface DeviceClientGroupsProps
  extends DeviceClientGroupsStateProps,
      DeviceClientGroupsDOMProps,
      DeviceClientGroupsModalVisibilityProps {
  userName?: string;
  userId?: string;
  clientGroups?: ClientGroup[];
  onLoadClientGroups: () => Promise<any>;
}
//</editor-fold>

//<editor-fold desc="Messages">
//</editor-fold>

//<editor-fold desc="Utils">

const resolveRowClasses = (row: ClientGroup, rowIndex: number) => {
  /*
  const t: string[] = [];
  return t.length ? t.join(" ") : undefined;
   */
  return undefined;
};

const resolveClientGroup = (
  clientGroupId: string | undefined,
  clientGroups: ClientGroup[] | undefined
) => {
  let retVal: ClientGroup | undefined | null;
  if (clientGroupId && clientGroups) {
    retVal = clientGroups.find((val) => val.id === clientGroupId);
    if (!retVal) {
      retVal = null;
    }
  }
  return retVal;
};

//</editor-fold>

function DeviceClientGroups(props: DeviceClientGroupsProps) {
  //<editor-fold desc="Local variables">
  const {
    clientGroups,
    onLoadClientGroups,
    selected,
    onSetSelected,
    onShowModal,
    onHideModal,
    showModal,
    activeSearch,
    onSetActiveSearch,
    onShowFeedback,
    onHideFeedback,
    feedback,
    userName,
    userId,
    ...others
  } = props;

  const modalGroup: ClientGroup | undefined | null = showModal
    ? resolveClientGroup(showModal.clientGroupId, clientGroups)
    : undefined;

  // this is more like a variable than a hook
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: "Manage device client groups",
    description: "heading for the Manage device client groups page",
  });
  const description = intl.formatMessage({
    defaultMessage: "Device client group management",
    description: "window meta description for the Manage device client groups screen",
  });

  const { conf } = useContext(UIConfiguration);
  const groupConf =
    conf.functionality && conf.functionality["device-client-groups"]
      ? conf.functionality["device-client-groups"]
      : {};

  let columns: TableColumn[] = [
    {
      key: "id",
      label: intl.formatMessage(ClientGroupLabels.id),
      isTechnical: true,
      hidden: true,
    },
    {
      key: "name",
      sortable: true,
      label: intl.formatMessage(ClientGroupLabels.name),
    },
    {
      key: "description",
      sortable: true,
      label: intl.formatMessage(ClientGroupLabels.description),
    },
  ];
  if (groupConf.columns && groupConf.columns.length > 0) {
    columns = resolveColumns(columns, groupConf.columns);
  }
  //</editor-fold>

  //<editor-fold desc="Hooks">

  // resets selection when users are undefined, and clears out selected items that are not part of updated data
  useEffect(() => {
    if (selected.length) {
      if (clientGroups === undefined) {
        onSetSelected([]);
      } else {
        const ids = clientGroups.map((grp: ClientGroup) => grp.id);
        const newS = selected.filter((itm: any) => {
          return ids.indexOf(itm.id) >= 0;
        });
        if (newS.length !== selected.length) {
          onSetSelected(newS);
        }
      }
    }
  }, [clientGroups, selected, onSetSelected]);
  //</editor-fold>
  return (
    <Page
      header={<h1>{title}</h1>}
      data-test-device-groups-page
      id={"device-client-groups-page"}
      meta={{
        title,
        description,
      }}
      {...others}
    >
      <ShowFeedback
        idPrefix={"device-client-groups_"}
        onHideFeedback={onHideFeedback}
        feedback={feedback}
      />
      <Table<ClientGroup>
        data-test-groups-table
        persistStateKey={"device-client-groups-table"}
        allowColumnSort={true}
        selection={{
          multi: false,
          selectAll: false,
        }}
        header={<HeaderActions actions={groupConf.actions} actionRenderer={(action) => {
            let retVal = undefined;
            if (action.key === 'create') {
                retVal = (
                    <Button
                        key={action.key}
                        data-test-create-device-client-group-trigger
                        action={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onShowModal(ModalKeys.create);
                        }}
                        variant={"primary"}
                        className={"btn custom-base"}
                    >
                        {intl.formatMessage({
                            defaultMessage: "Create",
                            description: "create group button label",
                        })}
                    </Button>
                );
            } else if (!!action.url) {
                retVal = (
                    <Button
                        key={action.key}
                        data-test-custom-action={action.key}
                        action={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            window.open(action.url, '_blank');
                        }}
                        className={"btn custom-base"}
                    >
                        {intl.formatMessage({
                            defaultMessage: "{action, select, other {{action}}}",
                            description: "Custom header action label. Prints action key by default. Inject `actionKey {Label}` after the `select,` and before the `other` to provide translation for custom action. Only use space as separator with multiple actions, no comma.",
                        }, {
                            action: action.key
                        })}
                    </Button>
                );
            }
            return retVal;
        }} />}
        search={true}
        activeSearch={activeSearch}
        onSearch={onSetActiveSearch}
        rowClasses={resolveRowClasses}
        selected={selected}
        onSelectionChanged={onSetSelected}
        columns={columns}
        columnToggle
        reload={true}
        onLoadData={onLoadClientGroups}
        data={clientGroups ? clientGroups : undefined}
        identifyingColumn={"id"}
        pagination={false}
        rowTools={(props: { rowEntry: ClientGroup }) => (
                groupConf.rowActions?.map((m) => {
                    let retVal = undefined;
                    if (!m.disabled) {
                        if (m.key === 'show') {
                            retVal = (
                                <Dropdown.Item
                                    data-test-row-tool-group-details={props.rowEntry.id}
                                    onClick={(e: any) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        onShowModal(ModalKeys.show, props.rowEntry.id);
                                    }}
                                >
                                    {intl.formatMessage({
                                        defaultMessage: "View details",
                                        description: "Label for the tool",
                                    })}
                                </Dropdown.Item>
                            );
                        } else if (m.key === 'deviceClients') {
                            retVal = (
                              <Dropdown.Item
                                  data-test-row-tool-group-device-clients={props.rowEntry.id}
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onShowModal(ModalKeys.deviceClients, props.rowEntry.id);
                                  }}
                              >
                                {intl.formatMessage({
                                  defaultMessage: "Clients",
                                  description: "Label for the tool",
                                })}
                              </Dropdown.Item>
                            );
                        } else if (m.key === 'remove') {
                            retVal = (
                              <Dropdown.Item
                                  data-test-row-tool-remove-group={props.rowEntry.id}
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onShowModal(ModalKeys.remove, props.rowEntry.id);
                                  }}
                              >
                                {intl.formatMessage({
                                  defaultMessage: "Remove",
                                  description: "Label for the tool",
                                })}
                              </Dropdown.Item>
                            );
                        } else if (m.key === 'invite') {
                          retVal = (
                              <Dropdown.Item
                                  data-test-row-tool-invite-to-group={props.rowEntry.id}
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    onShowModal(ModalKeys.invite, props.rowEntry.id);
                                  }}
                              >
                                {intl.formatMessage({
                                  defaultMessage: "Send invite",
                                  description: "Label for the tool",
                                })}
                              </Dropdown.Item>
                          );
                        } else if (!!m.url) {
                          retVal = (
                              <Dropdown.Item
                                  data-test-row-tool-custom={m.key}
                                  onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    window.open(m.url, '_blank');
                                  }}
                                  className={'custom-action'}
                              >
                                  <span>{intl.formatMessage({
                                      defaultMessage: "{action, select, other {{action}}}",
                                      description: "Custom row action label. Prints action key by default. Inject `actionKey {Label}` after the `select,` and before the `other` to provide translation for custom action. Only use space as separator with multiple actions, no comma.",
                                  }, {
                                      action: m.key
                                  })}</span>
                                  <FontAwesomeIcon icon={IconLibrary.icons.faExternalLinkAlt} className={'icon'} fixedWidth={true} />
                              </Dropdown.Item>
                          );
                        }
                    }
                    return retVal;
                })
        )}
      />
      {hasAction(groupConf.rowActions, 'show') && (
        <ViewDeviceGroupModal
          show={
            showModal && showModal.clientGroupId
              ? (hasAction(groupConf.rowActions, 'show') && showModal.key === ModalKeys.show) ||
                (groupConf.allowEdit && showModal.key === ModalKeys.edit)
                ? true
                : false
              : false
          }
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
          edit={showModal ? showModal.key === ModalKeys.edit : false}
          allowEdit={groupConf.allowEdit ? true : false}
          clientGroupId={showModal ? showModal.clientGroupId : undefined}
        />
      )}
      {hasAction(groupConf.rowActions, 'remove') && (
        <RemoveDeviceGroupModal
          show={
            showModal && showModal.clientGroupId
              ? showModal.key === ModalKeys.remove
              : false
          }
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
          clientGroupId={showModal ? showModal.clientGroupId : undefined}
        />
      )}
      {hasAction(groupConf.actions, 'create') && (
        <CreateDeviceGroupModal
          show={showModal ? showModal.key === ModalKeys.create : false}
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
        />
      )}
      {hasAction(groupConf.rowActions, 'invite') && (
        <CreateDeviceInvitationModal
          selected={showModal && modalGroup ? [modalGroup] : undefined}
          show={showModal ? showModal.key === ModalKeys.invite : false}
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
        />
      )}
      {groupConf.showEntitlement && (
        <ViewEntitlementModal
          show={showModal ? showModal.key === ModalKeys.showEntitlement : false}
          onClose={onHideModal}
          entitlementId={showModal ? showModal.secondaryId : undefined}
        />
      )}
      {hasAction(groupConf.rowActions, 'deviceClients') && (
        <ManageDeviceClientGroupDeviceClientsModal
          show={
            showModal
              ? showModal.key === ModalKeys.deviceClients && modalGroup !== undefined
              : false
          }
          onShowFeedback={onShowFeedback}
          onClose={onHideModal}
          groupId={showModal ? showModal.clientGroupId : undefined}
        />
      )}
    </Page>
  );
}
export default DeviceClientGroups;
