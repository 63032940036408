import {
  useCallback,
  useContext,
} from "react";
import { Dropdown, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {IconLibrary, TooltipWrapper} from "@10duke/dukeui";
import ProfileImage from "../profile-image";
import { FormattedMessage, useIntl } from "react-intl";
import { Organization } from "../../model/Organization";
import { getClientId, getUserUIBase } from "../../api/ApiImplementation";
import UIConfiguration from "../../ui-configuration/configuration-provider";
import LanguageContext from "../../language-context";
import { UserValues } from "../../localization/user";
import { asDukeLocale, asIntlLocale } from "../localize/localize-view";
import { getAppBase } from "../../util/env";
import { ResolvedFrom } from "../../utils/locale";
import "./app-menus.scss"

//<editor-fold desc="Props">
export interface AppMenusUser {
  id: string;
  name?: string;
  email?: string;
}

export interface AppMenusInputProps {
  dropdownVariant: string;
}

export interface AppMenusProps extends AppMenusInputProps {
  user?: AppMenusUser;
  selectedOrganization?: Organization;
  organizations?: Organization[];
  onSelectOrganization?: (orgId: string) => void;
  disableOrganizationSelect?: boolean;
}
//</editor-fold>

function AppMenus(props: AppMenusProps) {
  //<editor-fold desc="Local variables">
  const {
    user,
    selectedOrganization,
    onSelectOrganization,
    organizations,
    disableOrganizationSelect,
    dropdownVariant,
  } = props;

  const intl = useIntl();
  /* eslint-disable no-template-curly-in-string */
  /* tslint:disable:no-template-curly-in-string */
  const idp_base = getUserUIBase().replace(
    "${locale}",
    intl.locale !== intl.defaultLocale ? "/" + asDukeLocale(intl.locale) : ""
  );
  /* tslint:enable:no-template-curly-in-string */
  /* eslint-enable no-template-curly-in-string */
  const organizationDropdownItemClickHandler = useCallback(
    (eventKey: any) => {
      if (onSelectOrganization) {
        onSelectOrganization(eventKey);
      }
    },
    [onSelectOrganization]
  );

  const { conf } = useContext(UIConfiguration);
  // could and possible should be wrapped in a memo, but unfortunately the key?.key2? syntax does not work with keys
  // that need to be wrapped in ["key3"], so resolving the dependencies would likely consume more than the memo can
  // save.
  const localeConf =
    conf.functionality && conf.functionality.localization
      ? conf.functionality.localization
      : {};
  const headerConf =
      conf.functionality && conf.functionality.header
          ? conf.functionality.header
          : {};
  //</editor-fold>

  //<editor-fold desc="Partials">

  const langDropdown =
    headerConf["show-locale-switcher"] &&
    localeConf.locales &&
    localeConf.locales.length > 0 ? (
      <LanguageContext.Consumer>
        {(context) => {
          let currentLocale = context.locale;
          const languageNames = new Intl.DisplayNames(
            [asIntlLocale(currentLocale?.value as string)],
            { type: "language" }
          );
          return (
          <Nav id={"language-nav"}>
            <NavDropdown
              data-test-select-language-tool
              data-bs-theme={dropdownVariant}
              title={
                <span data-test-selected-language>
                      {currentLocale && currentLocale.label}
                     {(!currentLocale || !currentLocale.label) &&
                    languageNames.of(intl.locale)}
                </span>
              }
              id="header-nav-language-dropdown"
              onSelect={context.changeLanguage}
            >
              <Dropdown.Header>
                <FormattedMessage
                  defaultMessage="Select language"
                  description="Change language dropdown menu heading"
                />
              </Dropdown.Header>
              {localeConf?.locales?.map((l) => {

                  const active =
                      l.value === (context.locale.value || intl.locale);
                  return (
                      <TooltipWrapper
                          tipKey={"languageSelectTip_" + l.value}
                          key={l.value}
                          tip={
                            context.resolvedFrom === ResolvedFrom.store &&
                            active ? (
                                <FormattedMessage
                                    defaultMessage="Stored user preference"
                                    description="active dropdown language tooltip for a language that is stored as user preference"
                                />
                            ) : context.resolvedFrom === ResolvedFrom.detected &&
                            active ? (
                                <FormattedMessage
                                    defaultMessage="Detected browser setting"
                                    description="active dropdown language tooltip for a language that detected automatically"
                                />
                            ) : undefined
                          }
                      >
                        <NavDropdown.Item
                            key={l.value}
                            data-test-select-language={l.value}
                            active={active}
                            eventKey={l.value}
                            className={
                              "align-items-center d-flex flex-row justify-content-between dropdown-item"
                            }
                        >
                          <span>{l.label}</span>
                          {active &&
                              context.resolvedFrom === ResolvedFrom.store && (
                                  <FontAwesomeIcon
                                      icon={IconLibrary.icons.faUserCheck}
                                      fixedWidth={true}
                                      className={""}
                                  ></FontAwesomeIcon>
                              )}
                          {active &&
                              context.resolvedFrom === ResolvedFrom.detected && (
                                  <FontAwesomeIcon
                                      icon={IconLibrary.icons.faGlobe}
                                      fixedWidth={true}
                                      className={""}
                                  ></FontAwesomeIcon>
                              )}
                        </NavDropdown.Item>
                      </TooltipWrapper>
                  );

                })}
            </NavDropdown>
          </Nav>
        );
        }}
      </LanguageContext.Consumer>
    ) : undefined;
  const orgDropdown =
    organizations && organizations.length > 1 ? (
      <>
        <Nav id={"organization-nav"}>
          <TooltipWrapper
            tipKey={"organizationSelectDisabledTip"}
            tip={
              disableOrganizationSelect ? (
                <FormattedMessage
                  defaultMessage="Switching organization is disabled until all pending actions are completed."
                  description="tooltip to be shown when the change organization tool is disabled due to ongoing api call"
                />
              ) : undefined
            }
          >
            <NavDropdown
              data-bs-theme={dropdownVariant}
              id="header-nav-organization-dropdown"
              disabled={disableOrganizationSelect}
              onSelect={organizationDropdownItemClickHandler}
              title={
                <span data-test-select-organization-tool>
                  {selectedOrganization ? (
                    selectedOrganization.name
                  ) : (
                    <FormattedMessage
                      defaultMessage="Select organization"
                      description="Select organization dropdown default option label"
                    />
                  )}
                </span>
              }
            >
              <Dropdown.Header>
                <FormattedMessage
                  defaultMessage="Select active organization"
                  description="Select organization dropdown menu heading"
                />
              </Dropdown.Header>
              {organizations &&
                organizations.length &&
                organizations.map((itm) => {
                  return (
                    <NavDropdown.Item
                      key={itm.id}
                      data-test-select-organization={itm.id}
                      eventKey={itm.id}
                      active={
                        selectedOrganization &&
                        selectedOrganization.id === itm.id
                      }
                    >
                      <span>{itm.name}</span>
                    </NavDropdown.Item>
                  );
                })}
            </NavDropdown>
          </TooltipWrapper>
        </Nav>
      </>
    ) : null;

  const userDropdown = (
    <>
      <Nav id={"user-nav"} >
        {user && (
          <NavDropdown
            data-bs-theme={dropdownVariant}
            data-test-user-menu={user.id as string}
            title={<ProfileImage />}
            id="header-nav-profile-dropdown"
          >
            <NavDropdown.Header>
              <ProfileImage className={"icon"} />
              <span>
                {user.name
                  ? user.name
                  : intl.formatMessage(UserValues.displayName.undefined)}
                {user.email && (
                  <>
                    <br />
                    <em>{user.email}</em>
                  </>
                )}
              </span>
            </NavDropdown.Header>
            {headerConf["user-menu"]?.map((m, i) => {
              let retVal = undefined;
              if (m.key === 'my-profile' && !m.disabled) {
                retVal = (
                    <NavDropdown.Item href={idp_base} target={"_blank"} active={false}>
                        <FontAwesomeIcon icon={IconLibrary.icons.faUserCog} className="icon fa-fw" />
                      <span>
                        <FormattedMessage
                            defaultMessage="My Profile"
                            description={"Profile menu item label for the profile page"}
                        />
                      </span>
                    </NavDropdown.Item>
                );
              } else if (!m.disabled && !!m.url) {
                retVal = (
                    <NavDropdown.Item href={m.url} target={"_blank"} active={false}>
                      {!!m.icon && <span className={'custom-icon-wrapper icon'}><img src={m.icon} alt={""} title={""} /></span>}
                      {!m.icon && <FontAwesomeIcon icon={IconLibrary.icons.faExternalLinkAlt} className="icon fa-fw" />}
                      <span>
                        {intl.formatMessage({
                          defaultMessage: "{action, select, other {{action}}}",
                          description: "Custom profile menu action label. Prints action key by default. Inject `actionKey {Label}` after the `select,` and before the `other` to provide translation for custom action. Only use space as separator with multiple actions, no comma.",
                        }, {
                          action: m.key
                        })}
                      </span>
                    </NavDropdown.Item>
                );
              }
              return retVal;
            })}
            <NavDropdown.Divider />
            <NavDropdown.Item
              href={
                idp_base +
                "/oauth20/signout?client_id=" +
                encodeURIComponent(getClientId()) +
                "&RelayState=" +
                encodeURIComponent(
                  "url=" +
                    window.location.href.split(getAppBase())[0] +
                    getAppBase() +
                    "/logout"
                )
              }
            >
              <FontAwesomeIcon icon={IconLibrary.icons.faPowerOff} className="icon fa-fw" />
              <span>
                <FormattedMessage
                  defaultMessage="Logout"
                  description={"Profile menu item label for logging out"}
                />
              </span>
            </NavDropdown.Item>
          </NavDropdown>
        )}
        {!user && (
          <Navbar.Text>
            <FormattedMessage
              defaultMessage="Guest"
              description="Label to be shown in place of Profile menu when there is no session"
            />
          </Navbar.Text>
        )}
      </Nav>
    </>
  );
  //</editor-fold>

  return (
      <>
            {orgDropdown}
            {langDropdown}
            {userDropdown}
      </>
  );
}

export default AppMenus;
