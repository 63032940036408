import { useEffect, useRef, useState } from "react";
import View, {
  ManageUsersRolesModalProps as _ManageUsersRolesModalProps,
  ManageUsersRolesModalStateProps,
} from "./manage-users-roles-modal-view";
import { OrganizationRole } from "../../../model/OrganizationRole";
import _ from "lodash";
import { withCloseAfterExited } from "@10duke/dukeui";

const isArrayEqual = (x: Array<any>, y: Array<any>) => {
  return _(x).xorWith(y, _.isEqual).isEmpty();
};
const ViewWithCloseAfterExited =
  withCloseAfterExited<_ManageUsersRolesModalProps>(View);
export type ManageUsersRolesModalProps = Omit<
  _ManageUsersRolesModalProps,
  keyof ManageUsersRolesModalStateProps
>;

export default function ManageUsersRolesModal(
  props: ManageUsersRolesModalProps
) {
  const { show, usersRoles, roles, ...other } = props;

  const rolesRef = useRef(roles);
  if (!_.isEqual(rolesRef.current, roles)) {
    rolesRef.current = roles;
  }
  const rolesRefCurrent = rolesRef.current;

  const usersRolesRef = useRef(usersRoles);
  if (!_.isEqual(usersRolesRef.current, usersRoles)) {
    usersRolesRef.current = usersRoles;
  }
  const usersRolesRefCurrent = usersRolesRef.current;

  const [activeSearch, onSetActiveSearch] = useState("");
  const [editedRoles, setEditedRoles] = useState<string[] | undefined>(
    usersRoles
  );
  const [rolesToRemove, setRolesToRemove] = useState<OrganizationRole[]>([]);
  const [rolesToAdd, setRolesToAdd] = useState<OrganizationRole[]>([]);

  useEffect(() => {
    setEditedRoles((cur) => {
      if (
        cur &&
        usersRolesRefCurrent &&
        isArrayEqual(cur, usersRolesRefCurrent)
      ) {
        setRolesToRemove((v) =>
          v.filter((r) =>
            rolesRefCurrent
              ? rolesRefCurrent.findIndex((g) => r.id === g.id) >= 0
              : false
          )
        );
        setRolesToAdd((v) =>
          v.filter((r) =>
            rolesRefCurrent
              ? rolesRefCurrent.findIndex((g) => r.id === g.id) >= 0
              : false
          )
        );
        return cur.filter((v) =>
          rolesRefCurrent
            ? rolesRefCurrent.findIndex((r) => r.id === v) >= 0
            : false
        );
      } else {
        setRolesToRemove([]);
        setRolesToAdd([]);
        return usersRolesRefCurrent
          ? _.cloneDeep(usersRolesRefCurrent)
          : usersRolesRefCurrent;
      }
    });
  }, [usersRolesRefCurrent, rolesRefCurrent, setEditedRoles]);

  useEffect(() => {
    if (!show) {
      setEditedRoles(undefined);
      setRolesToRemove([]);
      setRolesToAdd([]);
      onSetActiveSearch("");
    }
  }, [show, setEditedRoles]);

  const onSetGroups = (sroles: OrganizationRole[]) => {
    let newEdited: string[] | undefined = sroles.map((u) => u.id as string);
    if (newEdited) {
      setRolesToRemove(
        (usersRoles && newEdited
          ? usersRoles.filter((x) =>
              newEdited ? !newEdited.includes(x) : false
            )
          : []
        )
          .map((id) => {
            return roles ? roles.find((val) => val.id === id) : undefined;
          })
          .filter((val) => val !== undefined) as OrganizationRole[]
      );
      setRolesToAdd(
        (newEdited
          ? newEdited.filter((x) =>
              usersRoles ? !usersRoles.includes(x) : false
            )
          : []
        )
          .map((id) => {
            return roles ? roles.find((val) => val.id === id) : undefined;
          })
          .filter((val) => val !== undefined) as OrganizationRole[]
      );
    } else {
      console.error("this should never happen");
    }
    setEditedRoles(newEdited);
  };
  return (
    <ViewWithCloseAfterExited
      {...other}
      show={show}
      usersRoles={editedRoles}
      roles={roles}
      rolesToRemove={rolesToRemove}
      rolesToAdd={rolesToAdd}
      onSetActiveSearch={onSetActiveSearch}
      activeSearch={activeSearch}
      onSetSelected={onSetGroups}
      selected={
        editedRoles && roles
          ? (editedRoles
              .map((g) => roles.find((v) => v.id === g))
              .filter((v) => !!v) as OrganizationRole[])
          : []
      }
    />
  );
}
