/**
 * 10Duke Identity Management API
 * 2.0.0
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
    baseUrl: "https://example/api/idp/v1",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {
    server1: "https://example/api/idp/v1"
};
export type GrantedPermissionActions = {
    /** The granted actions for the permission. */
    allowedActions: string[];
};
export type PermissionWithGrantedActions = {
    /** The name of the permission (the protected resource). The name must be unique in the system. */
    name: string;
    /** The description of the permission. */
    description?: string;
    /** The unique ID of the permission. If an ID is not specified when the permission is created, the system generates an ID. */
    id: string;
    grantedActions?: GrantedPermissionActions;
};
export type PermissionWithGrantedActionsRead = {
    /** The name of the permission (the protected resource). The name must be unique in the system. */
    name: string;
    /** The description of the permission. */
    description?: string;
    /** The date and time (ISO 8601) when the permission was created. */
    created?: string;
    /** The date and time (ISO 8601) when the permission was last modified. */
    modified?: string;
    /** The ID of the user who created the permission. */
    authorId?: string;
    /** The ID of the user who last modified the permission. */
    editorId?: string;
    /** The unique ID of the permission. If an ID is not specified when the permission is created, the system generates an ID. */
    id: string;
    grantedActions?: GrantedPermissionActions;
};
export type PermissionGrantsForPermission = {
    /** The ID of the permission. */
    permissionId?: string;
    /** The actions granted to the resource protected by the permission. */
    grantedActions?: string[];
    /** The unique ID of the permission assignment to the role. If an ID is not specified when the permission is assigned to a role, the system generates an ID. Operations that add or replace permissions in a role typically let the system generate the ID. */
    id: string;
};
export type PermissionGrantsForPermissionRead = {
    /** The ID of the permission. */
    permissionId?: string;
    /** The actions granted to the resource protected by the permission. */
    grantedActions?: string[];
    /** The date and time (ISO 8601) when the permission was assigned to the role. */
    created?: string;
    /** The date and time (ISO 8601) when the permission assignment was last marked as modified. */
    modified?: string;
    /** The ID of the user who assigned the permission to the role. */
    authorId?: string;
    /** The ID of the user who last modified the permission assignment. */
    editorId?: string;
    /** The unique ID of the permission assignment to the role. If an ID is not specified when the permission is assigned to a role, the system generates an ID. Operations that add or replace permissions in a role typically let the system generate the ID. */
    id: string;
};
export type ClientPermission = {
    /** The name of the client permission (the protected resource). The name must be unique in the system. */
    name: string;
    /** The description of the client permission. */
    description?: string;
    /** The unique ID of the client permission. If an ID is not specified when the client permission is created, the system generates an ID. */
    id: string;
};
export type ClientPermissionRead = {
    /** The name of the client permission (the protected resource). The name must be unique in the system. */
    name: string;
    /** The description of the client permission. */
    description?: string;
    /** The date and time (ISO 8601) when the client permission was created. */
    created?: string;
    /** The date and time (ISO 8601) when the client permission was last modified. */
    modified?: string;
    /** The ID of the user who created the client permission. */
    authorId?: string;
    /** The ID of the user who last modified the client permission. */
    editorId?: string;
    /** The unique ID of the client permission. If an ID is not specified when the client permission is created, the system generates an ID. */
    id: string;
};
export type ClientRole = {
    /** The ID of the client application that the client role applies to, or "any" if it applies to any client application. */
    clientIdentifier: string;
    /** The name of the client role. The name must be unique in the system. */
    name: string;
    /** The description of the client role. */
    description?: string;
    /** The designator of the client role. */
    designator?: string;
    /** The unique ID of the client role. If an ID is not specified when the client role is created, the system generates an ID. */
    id: string;
};
export type ClientRoleRead = {
    /** The ID of the client application that the client role applies to, or "any" if it applies to any client application. */
    clientIdentifier: string;
    /** The name of the client role. The name must be unique in the system. */
    name: string;
    /** The description of the client role. */
    description?: string;
    /** The designator of the client role. */
    designator?: string;
    /** The date and time (ISO 8601) when the client role was created. */
    created?: string;
    /** The date and time (ISO 8601) when the client role was last modified. */
    modified?: string;
    /** The ID of the user who created the client role. */
    authorId?: string;
    /** The ID of the user who last modified the client role. */
    editorId?: string;
    /** The unique ID of the client role. If an ID is not specified when the client role is created, the system generates an ID. */
    id: string;
};
export type InvitationBase = {
    /** A description of what the recipient is invited to, intended for the invitation message from the inviter to the recipient. */
    invitationScopeInformation?: string;
    /** The ISO 639-1 language code for the message locale. */
    recipientLanguageCode?: string;
    /** The URL to the welcome page, formatted into the invitation message. If not specified, the configured default URL is used. The URL must contain the format specifier "${key}" where 10Duke Enterprise inserts an invitation token to authorize access to the invitation. In most cases, the URL should also contain "${email}" that is expanded with the invitation recipient's email. "${base_url}" can be included to refer to the service itself. If you provide the invitationCompletedUrl field for the invitation, "${completedUrl}" can be included for providing it in this URL, usually as the value of the "next" parameter. */
    memberWelcomeUrl?: string;
    /** The URL where the user is redirected after completing the invitation process. If not specified, the user is redirected to the 10Duke Enterprise user profile page. An external URL must be whitelisted in 10Duke Enterprise. */
    invitationCompletedUrl?: string;
    /** The ISO 3166-1 alpha-2 country code for the message locale (not related to the recipient's geographical location). */
    recipientCountryCode?: string;
    /** Any additional data attached to the invitation by the client, for example, in JSON or XML format understood by the client. */
    clientData?: string;
    /** The recipient's name shown in the invitation. */
    recipientName: string;
    /** The inviter's name shown in the invitation. */
    inviterName: string;
    /** The email address of the recipient. */
    email: string;
    /** The start date and time (ISO 8601) from which the invitation is valid. If not set or set to null, the invitation is not considered valid. */
    validFrom: string;
    /** The end date and time (ISO 8601) after which the invitation expires. If not set or set to null, the invitation is considered valid indefinitely. */
    validUntil?: string;
    /** The unique ID of the invitation. If an ID is not specified when the invitation is created, the system generates an ID. */
    id?: string;
};
export type InvitationBaseRead = {
    /** A description of what the recipient is invited to, intended for the invitation message from the inviter to the recipient. */
    invitationScopeInformation?: string;
    /** The ISO 639-1 language code for the message locale. */
    recipientLanguageCode?: string;
    /** The URL to the welcome page, formatted into the invitation message. If not specified, the configured default URL is used. The URL must contain the format specifier "${key}" where 10Duke Enterprise inserts an invitation token to authorize access to the invitation. In most cases, the URL should also contain "${email}" that is expanded with the invitation recipient's email. "${base_url}" can be included to refer to the service itself. If you provide the invitationCompletedUrl field for the invitation, "${completedUrl}" can be included for providing it in this URL, usually as the value of the "next" parameter. */
    memberWelcomeUrl?: string;
    /** The URL where the user is redirected after completing the invitation process. If not specified, the user is redirected to the 10Duke Enterprise user profile page. An external URL must be whitelisted in 10Duke Enterprise. */
    invitationCompletedUrl?: string;
    /** The ISO 3166-1 alpha-2 country code for the message locale (not related to the recipient's geographical location). */
    recipientCountryCode?: string;
    /** The date and time (ISO 8601) when the invitation was declined. */
    declinedAt?: string;
    /** Any additional data attached to the invitation by the client, for example, in JSON or XML format understood by the client. */
    clientData?: string;
    /** The date and time (ISO 8601) when the invitation was revoked. */
    revokedAt?: string;
    /** The date and time (ISO 8601) when the invitation was accepted. */
    claimedAt?: string;
    /** The recipient's name shown in the invitation. */
    recipientName: string;
    /** The inviter's name shown in the invitation. */
    inviterName: string;
    /** The status of the invitation. "created" = Invitation created but not sent and no token created. "deliveryRequested" = Invitation sent. "accepted" = Invitation accepted. "declined" = Invitation declined. "revoked" = Invitation revoked. "updated" = Reserved for future use. */
    invitationState?: "accepted" | "created" | "updated" | "declined" | "deliveryRequested" | "revoked";
    /** The email address of the recipient. */
    email: string;
    /** The start date and time (ISO 8601) from which the invitation is valid. If not set or set to null, the invitation is not considered valid. */
    validFrom: string;
    /** The end date and time (ISO 8601) after which the invitation expires. If not set or set to null, the invitation is considered valid indefinitely. */
    validUntil?: string;
    /** The unique ID of the invitation. If an ID is not specified when the invitation is created, the system generates an ID. */
    id?: string;
    /** The date and time (ISO 8601) when the invitation was created. */
    created?: string;
    /** The date and time (ISO 8601) when the invitation was last modified. */
    modified?: string;
    /** The ID of the user who created the invitation. */
    authorId?: string;
    /** The ID of the user who last modified the invitation. */
    editorId?: string;
};
export type OrganizationGroupInvitation = InvitationBase & {
    /** The ID of the organization that the user is added to when they accept the invitation. */
    organizationId: string;
    /** The IDs of the client roles assigned to the user when they accept the invitation. */
    clientRoleIds?: string[];
    /** The IDs of the user groups that the user is added to when they accept the invitation. */
    groupIds: string[];
    /** The IDs of the organization roles assigned to the user when they accept the invitation. */
    organizationRoleIds?: string[];
};
export type OrganizationGroupInvitationRead = InvitationBaseRead & {
    /** The ID of the organization that the user is added to when they accept the invitation. */
    organizationId: string;
    /** The IDs of the client roles assigned to the user when they accept the invitation. */
    clientRoleIds?: string[];
    /** The IDs of the user groups that the user is added to when they accept the invitation. */
    groupIds: string[];
    /** The IDs of the organization roles assigned to the user when they accept the invitation. */
    organizationRoleIds?: string[];
};
export type InvitationTokenResponse = {
    /** The email address of the recipient. */
    email: string;
    /** The invitation token. */
    token: string;
};
export type Organization = {
    /** The name of the organization. The name must be unique in the system. */
    name: string;
    /** The type of the organization. The type is only metadata for administrative purposes and is not used by the system. Predefined reserved values: "company", "enterprise", "project". */
    "type"?: string;
    /** The description of the organization. */
    description?: string;
    /** The unique ID of the organization. Not required when creating an organization: if not specified, the system generates an ID. */
    id: string;
};
export type OrganizationRead = {
    /** The name of the organization. The name must be unique in the system. */
    name: string;
    /** The type of the organization. The type is only metadata for administrative purposes and is not used by the system. Predefined reserved values: "company", "enterprise", "project". */
    "type"?: string;
    /** The description of the organization. */
    description?: string;
    /** The date and time (ISO 8601) when the organization was created. */
    created?: string;
    /** The date and time (ISO 8601) when the organization was last modified. */
    modified?: string;
    /** The ID of the user who created the organization. */
    authorId?: string;
    /** The ID of the user who last modified the organization. */
    editorId?: string;
    /** The unique ID of the organization. Not required when creating an organization: if not specified, the system generates an ID. */
    id: string;
};
export type Address = {
    /** The country name. */
    country?: string;
    /** The region. */
    region?: string;
    /** The postal code. */
    postalCode?: string;
    /** The full mailing address, formatted for display. */
    formatted?: string;
    /** The city or locality. */
    locality?: string;
    /** The full street address. */
    streetAddress?: string;
    /** The unique ID of the address. If an ID is not specified when the address is created, the system generates an ID. */
    id: string;
};
export type AddressRead = {
    /** The country name. */
    country?: string;
    /** The region. */
    region?: string;
    /** The postal code. */
    postalCode?: string;
    /** The full mailing address, formatted for display. */
    formatted?: string;
    /** The city or locality. */
    locality?: string;
    /** The full street address. */
    streetAddress?: string;
    /** The date and time (ISO 8601) when the address was created. */
    created?: string;
    /** The date and time (ISO 8601) when the address was last modified. */
    modified?: string;
    /** The ID of the user who created the address. */
    authorId?: string;
    /** The ID of the user who last modified the address. */
    editorId?: string;
    /** The unique ID of the address. If an ID is not specified when the address is created, the system generates an ID. */
    id: string;
};
export type User = {
    address?: Address;
    /** The user's full name as it should be displayed, possibly including a title and suffixes. */
    displayName?: string;
    /** The user's primary contact email address. */
    email: string;
    /** The user's last name. */
    lastName: string;
    /** The user's first name. */
    firstName: string;
    /** The user's phone number. */
    phoneNumber?: string;
    /** The user's nickname. */
    nickname?: string;
    /** The user's professional title or status that describes their professional credentials (such as "Dr.") or their position in their organization (such as "CEO"). */
    professionalTitle?: string;
    /** The unique ID of the user. If an ID is not specified when the user is created, the system generates an ID. */
    id: string;
    /** The end date and time (ISO 8601) after which the user's account is no longer valid. */
    validUntil?: string;
    /** The start date and time (ISO 8601) from which the user's account is valid. */
    validFrom?: string;
};
export type UserRead = {
    address?: AddressRead;
    /** The user's full name as it should be displayed, possibly including a title and suffixes. */
    displayName?: string;
    /** The user's primary contact email address. */
    email: string;
    /** The user's last name. */
    lastName: string;
    /** The user's first name. */
    firstName: string;
    /** The user's phone number. */
    phoneNumber?: string;
    /** The date and time (ISO 8601) of the user's last login. */
    lastSignOn?: string;
    /** The user's nickname. */
    nickname?: string;
    /** The URL where the user's profile picture is located. */
    picture?: string;
    /** The user's professional title or status that describes their professional credentials (such as "Dr.") or their position in their organization (such as "CEO"). */
    professionalTitle?: string;
    /** The date and time (ISO 8601) when the user was created. */
    created?: string;
    /** The date and time (ISO 8601) when the user was last modified. */
    modified?: string;
    /** The ID of the user who created this user. */
    authorId?: string;
    /** The ID of the user who last modified this user. */
    editorId?: string;
    /** The unique ID of the user. If an ID is not specified when the user is created, the system generates an ID. */
    id: string;
    /** The end date and time (ISO 8601) after which the user's account is no longer valid. */
    validUntil?: string;
    /** The start date and time (ISO 8601) from which the user's account is valid. */
    validFrom?: string;
};
export type Entitlement = {
    /** The name of the entitlement. */
    name: string;
    /** The type of the entitlement. The type is only metadata for administrative purposes and is not used by the system. */
    "type"?: string;
    /** The description of the entitlement. */
    description?: string;
    /** Indicates whether this is the organization's default entitlement, which is used when licenses are created or consumed and the request doesn't specify an entitlement. */
    isDefault?: boolean;
    ownerUser?: User;
    ownerOrganization?: Organization;
    /** The unique ID of the entitlement. If an ID is not specified when the entitlement is created, the system generates an ID. */
    id: string;
};
export type EntitlementRead = {
    /** The name of the entitlement. */
    name: string;
    /** The type of the entitlement. The type is only metadata for administrative purposes and is not used by the system. */
    "type"?: string;
    /** The description of the entitlement. */
    description?: string;
    /** Indicates whether this is the organization's default entitlement, which is used when licenses are created or consumed and the request doesn't specify an entitlement. */
    isDefault?: boolean;
    ownerUser?: UserRead;
    ownerOrganization?: OrganizationRead;
    /** The date and time (ISO 8601) when the entitlement was created. */
    created?: string;
    /** The date and time (ISO 8601) when the entitlement was last modified. */
    modified?: string;
    /** The ID of the user who created the entitlement. */
    authorId?: string;
    /** The ID of the user who last modified the entitlement. */
    editorId?: string;
    /** The unique ID of the entitlement. If an ID is not specified when the entitlement is created, the system generates an ID. */
    id: string;
};
export type EntitlementConsumerGroup = {
    /** The unique ID of the association between a user group and an organization's entitlement. */
    id: string;
    /** The ID of the entitlement. */
    entitlementId?: string;
    /** The ID of the user group authorized to access the entitlement. */
    groupId?: string;
};
export type OrganizationGroupBase = {
    /** The name of the group. */
    name: string;
    /** The type of the group. */
    "type"?: string;
    /** The description of the group. */
    description?: string;
    /** The ID of the organization that the group belongs to. This field is read-only. */
    organizationId?: string;
    /** The unique ID of the group. If an ID is not specified when the group is created, the system generates an ID. */
    id: string;
};
export type OrganizationGroupBaseRead = {
    /** The name of the group. */
    name: string;
    /** The type of the group. */
    "type"?: string;
    /** The description of the group. */
    description?: string;
    /** The ID of the organization that the group belongs to. This field is read-only. */
    organizationId?: string;
    /** The date and time (ISO 8601) when the group was created. */
    created?: string;
    /** The date and time (ISO 8601) when the group was last modified. */
    modified?: string;
    /** The ID of the user who created the group. */
    authorId?: string;
    /** The ID of the user who last modified the group. */
    editorId?: string;
    /** The unique ID of the group. If an ID is not specified when the group is created, the system generates an ID. */
    id: string;
};
export type OrganizationGroup = OrganizationGroupBase;
export type OrganizationGroupRead = OrganizationGroupBaseRead;
export type OrganizationRole = {
    /** The ID of the organization that the organization role applies to. This field is read-only. */
    organizationId?: string;
    /** The name of the organization role. */
    name: string;
    /** The description of the organization role. */
    description?: string;
    /** The designator of the organization role. */
    designator?: string;
    /** The unique ID of the organization role. If an ID is not specified when the organization role is created, the system generates an ID. */
    id: string;
};
export type OrganizationRoleRead = {
    /** The ID of the organization that the organization role applies to. This field is read-only. */
    organizationId?: string;
    /** The name of the organization role. */
    name: string;
    /** The description of the organization role. */
    description?: string;
    /** The designator of the organization role. */
    designator?: string;
    /** The date and time (ISO 8601) when the organization role was created. */
    created?: string;
    /** The date and time (ISO 8601) when the organization role was last modified. */
    modified?: string;
    /** The ID of the user who created the organization role. */
    authorId?: string;
    /** The ID of the user who last modified the organization role. */
    editorId?: string;
    /** The unique ID of the organization role. If an ID is not specified when the organization role is created, the system generates an ID. */
    id: string;
};
export type CreatedOrganizationWithEntitlement = {
    /** The entitlements created for the organization. */
    entitlements?: Entitlement[];
    /** The license consumption authorization granted with this request, allowing users in the user group to consume licenses in the entitlement. */
    entitlementConsumerGroups?: EntitlementConsumerGroup[];
    /** The user groups created for the organization. */
    groups?: OrganizationGroup[];
    /** The ID of the user who was granted organization administrator access. */
    initialAdminId?: string;
    /** The organization roles created for the organization. */
    organizationRoles?: OrganizationRole[];
    /** The name of the organization. */
    name: string;
    /** The type of the organization. The type is only metadata for administrative purposes and is not used by the system. Predefined reserved values: "company", "enterprise", "project". */
    "type"?: string;
    /** The description of the organization. */
    description?: string;
    /** The unique ID of the organization. Not required when creating an organization: if not specified, the system generates an ID. */
    id: string;
};
export type CreatedOrganizationWithEntitlementRead = {
    /** The entitlements created for the organization. */
    entitlements?: EntitlementRead[];
    /** The license consumption authorization granted with this request, allowing users in the user group to consume licenses in the entitlement. */
    entitlementConsumerGroups?: EntitlementConsumerGroup[];
    /** The user groups created for the organization. */
    groups?: OrganizationGroupRead[];
    /** The ID of the user who was granted organization administrator access. */
    initialAdminId?: string;
    /** The organization roles created for the organization. */
    organizationRoles?: OrganizationRoleRead[];
    /** The name of the organization. */
    name: string;
    /** The type of the organization. The type is only metadata for administrative purposes and is not used by the system. Predefined reserved values: "company", "enterprise", "project". */
    "type"?: string;
    /** The description of the organization. */
    description?: string;
    /** The date and time (ISO 8601) when the organization was created. */
    created?: string;
    /** The date and time (ISO 8601) when the organization was last modified. */
    modified?: string;
    /** The ID of the user who created the organization. */
    authorId?: string;
    /** The ID of the user who last modified the organization. */
    editorId?: string;
    /** The unique ID of the organization. Not required when creating an organization: if not specified, the system generates an ID. */
    id: string;
};
export type Property = {
    /** The key of the custom property. */
    key: string;
    /** The value of the custom property. */
    value?: string;
};
export type ExternalIdentity = {
    /** The ID of the external system's configuration in 10Duke Enterprise. */
    providerId: string;
    /** The user's ID in the external system. */
    userId: string;
    /** The unique ID of the association between a user in 10Duke Enterprise and a user in an external system. If an ID is not specified when the association is created, the system generates an ID. */
    id: string;
};
export type UserForCreate = {
    /** The IDs of the user groups that the user is added to. */
    groupIds?: string[];
    /** References to the user identity in external identity providers. */
    externalIdentities?: ExternalIdentity[];
    /** The IDs of the organization roles added for the user. */
    organizationRoleIds?: string[];
    address?: Address;
    /** The user's full name as it should be displayed, possibly including a title and suffixes. */
    displayName?: string;
    /** The user's primary contact email address. */
    email: string;
    /** The user's last name. */
    lastName: string;
    /** The user's first name. */
    firstName: string;
    /** The user's phone number. */
    phoneNumber?: string;
    /** The user's nickname. */
    nickname?: string;
    /** The user's professional title or status that describes their professional credentials (such as "Dr.") or their position in their organization (such as "CEO"). */
    professionalTitle?: string;
    /** The unique ID of the user. If an ID is not specified when the user is created, the system generates an ID. */
    id: string;
    /** The end date and time (ISO 8601) after which the user's account is no longer valid. */
    validUntil?: string;
    /** The start date and time (ISO 8601) from which the user's account is valid. */
    validFrom?: string;
};
export type UserForCreateRead = {
    /** The IDs of the user groups that the user is added to. */
    groupIds?: string[];
    /** References to the user identity in external identity providers. */
    externalIdentities?: ExternalIdentity[];
    /** The IDs of the organization roles added for the user. */
    organizationRoleIds?: string[];
    address?: AddressRead;
    /** The user's full name as it should be displayed, possibly including a title and suffixes. */
    displayName?: string;
    /** The user's primary contact email address. */
    email: string;
    /** The user's last name. */
    lastName: string;
    /** The user's first name. */
    firstName: string;
    /** The user's phone number. */
    phoneNumber?: string;
    /** The date and time (ISO 8601) of the user's last login. */
    lastSignOn?: string;
    /** The user's nickname. */
    nickname?: string;
    /** The URL where the user's profile picture is located. */
    picture?: string;
    /** The user's professional title or status that describes their professional credentials (such as "Dr.") or their position in their organization (such as "CEO"). */
    professionalTitle?: string;
    /** The date and time (ISO 8601) when the user was created. */
    created?: string;
    /** The date and time (ISO 8601) when the user was last modified. */
    modified?: string;
    /** The ID of the user who created this user. */
    authorId?: string;
    /** The ID of the user who last modified this user. */
    editorId?: string;
    /** The unique ID of the user. If an ID is not specified when the user is created, the system generates an ID. */
    id: string;
    /** The end date and time (ISO 8601) after which the user's account is no longer valid. */
    validUntil?: string;
    /** The start date and time (ISO 8601) from which the user's account is valid. */
    validFrom?: string;
};
export type Permission = {
    /** The name of the internal permission (the protected resource). The name must be unique in the system. */
    name: string;
    /** The description of the internal permission. */
    description?: string;
    /** The unique ID of the internal permission. If an ID is not specified when the internal permission is created, the system generates an ID. */
    id: string;
};
export type PermissionRead = {
    /** The name of the internal permission (the protected resource). The name must be unique in the system. */
    name: string;
    /** The description of the internal permission. */
    description?: string;
    /** The date and time (ISO 8601) when the internal permission was created. */
    created?: string;
    /** The date and time (ISO 8601) when the internal permission was last modified. */
    modified?: string;
    /** The ID of the user who created the internal permission. */
    authorId?: string;
    /** The ID of the user who last modified the internal permission. */
    editorId?: string;
    /** The unique ID of the internal permission. If an ID is not specified when the internal permission is created, the system generates an ID. */
    id: string;
};
export type InternalRole = {
    /** The name of the internal role. The name must be unique in the system. */
    name: string;
    /** The description of the internal role. */
    description?: string;
    /** The designator of the internal role. */
    designator?: string;
    /** The unique ID of the internal role. If an ID is not specified when the internal role is created, the system generates an ID. */
    id: string;
};
export type InternalRoleRead = {
    /** The name of the internal role. The name must be unique in the system. */
    name: string;
    /** The description of the internal role. */
    description?: string;
    /** The designator of the internal role. */
    designator?: string;
    /** The date and time (ISO 8601) when the internal role was created. */
    created?: string;
    /** The date and time (ISO 8601) when the internal role was last modified. */
    modified?: string;
    /** The ID of the user who created the internal role. */
    authorId?: string;
    /** The ID of the user who last modified the internal role. */
    editorId?: string;
    /** The unique ID of the internal role. If an ID is not specified when the internal role is created, the system generates an ID. */
    id: string;
};
export type UserInvitation = InvitationBase & {
    /** The IDs of the internal roles assigned to the user when they accept the invitation. */
    internalRoles?: string[];
};
export type UserInvitationRead = InvitationBaseRead & {
    /** The IDs of the internal roles assigned to the user when they accept the invitation. */
    internalRoles?: string[];
};
export type RecoveryEmail = {
    /** The recovery email address. */
    value?: string;
    /** The date and time (ISO 8601) when the recovery email address was validated by the user. */
    validatedAt?: string;
    /** The unique ID of the recovery email address. If an ID is not specified when the recovery email address is created, the system generates an ID. */
    id: string;
    /** The ISO 3166-1 alpha-2 country code for the locale of the message used for validating the recovery email address (not related to the recipient's geographical location). */
    recipientCountryCode?: string;
    /** The ISO 639-1 language code for the locale of the message used for validating the recovery email address. */
    recipientLanguageCode?: string;
};
export type RecoveryEmailRead = {
    /** The recovery email address. */
    value?: string;
    /** The date and time (ISO 8601) when the recovery email address was validated by the user. */
    validatedAt?: string;
    /** The date and time (ISO 8601) when the recovery email address was created. */
    created?: string;
    /** The date and time (ISO 8601) when the recovery email address was last modified. */
    modified?: string;
    /** The ID of the user who created the recovery email address. */
    authorId?: string;
    /** The ID of the user who last modified the recovery email address. */
    editorId?: string;
    /** The unique ID of the recovery email address. If an ID is not specified when the recovery email address is created, the system generates an ID. */
    id: string;
    /** The ISO 3166-1 alpha-2 country code for the locale of the message used for validating the recovery email address (not related to the recipient's geographical location). */
    recipientCountryCode?: string;
    /** The ISO 639-1 language code for the locale of the message used for validating the recovery email address. */
    recipientLanguageCode?: string;
};
export type OrganizationGroupOfUser = OrganizationGroup & {
    /** The end date and time (ISO 8601) after which the user's membership in the user group is no longer valid. This is by default set to null, which means the membership is valid indefinitely. */
    validUntil?: string;
    /** The start date and time (ISO 8601) from which the user's membership in the user group is valid. This is by default set to the time when the membership is created. */
    validFrom?: string;
};
export type OrganizationGroupOfUserRead = OrganizationGroupRead & {
    /** The end date and time (ISO 8601) after which the user's membership in the user group is no longer valid. This is by default set to null, which means the membership is valid indefinitely. */
    validUntil?: string;
    /** The start date and time (ISO 8601) from which the user's membership in the user group is valid. This is by default set to the time when the membership is created. */
    validFrom?: string;
};
export type ValidationTokenResponse = {
    /** The recovery email address. */
    email: string;
    /** The validation token. */
    token: string;
};
export type OtpCredential = {
    /** The TOTP credentials generated into a QR code as a Base64-encoded PNG image. */
    qrCode?: string;
    /** The TOTP credentials as a URI. */
    keyUri?: string;
    /** The Base32-encoded secret key of the TOTP credentials. */
    secret?: string;
    /** Indicates if the user has verified their TOTP credentials to activate 2FA. */
    verified?: boolean;
    /** The unique ID of the TOTP credentials. Not required when creating TOTP credentials: if not specified, the system generates an ID. */
    id: string;
};
export type OtpCredentialRead = {
    /** The TOTP credentials generated into a QR code as a Base64-encoded PNG image. */
    qrCode?: string;
    /** The TOTP credentials as a URI. */
    keyUri?: string;
    /** The Base32-encoded secret key of the TOTP credentials. */
    secret?: string;
    /** Indicates if the user has verified their TOTP credentials to activate 2FA. */
    verified?: boolean;
    /** The date and time (ISO 8601) when the TOTP credentials were created. */
    created?: string;
    /** The date and time (ISO 8601) when the TOTP credentials were last modified. */
    modified?: string;
    /** The ID of the user who created the TOTP credentials. */
    authorId?: string;
    /** The ID of the user who last modified the TOTP credentials. */
    editorId?: string;
    /** The unique ID of the TOTP credentials. Not required when creating TOTP credentials: if not specified, the system generates an ID. */
    id: string;
};
export type ClientBaseBase = {
    /** The unique ID of the client. If an ID is not specified when the client is created, the system generates an ID. */
    id?: string;
    /** The name of the client. */
    name: string;
    /** The protocol used by the client. The value is either "OAuth20Client" or "Saml20Client". */
    "type": string;
};
export type ClientBaseBaseRead = {
    /** The unique ID of the client. If an ID is not specified when the client is created, the system generates an ID. */
    id?: string;
    /** The name of the client. */
    name: string;
    /** The protocol used by the client. The value is either "OAuth20Client" or "Saml20Client". */
    "type": string;
    /** The date and time (ISO 8601) when the client was created. */
    created?: string;
    /** The date and time (ISO 8601) when the client was last modified. */
    modified?: string;
    /** The ID of the user who created the client. */
    authorId?: string;
    /** The ID of the user who last modified the client. */
    editorId?: string;
};
export type OAuth20Client = {
    "type": "OAuth20Client";
} & ClientBaseBase & {
    /** The client ID (OAuth "client_id") used for authenticating the client. */
    clientId: string;
    /** The OIDC/OAuth flow used by the client. */
    flow: "None" | "AuthzCode" | "AuthzCodeWithPkce" | "AuthzCodeAny" | "ResourceOwnerPassword" | "ClientCredentials" | "Implicit" | "Device" | "JwtBearer" | "Any";
    /** The login callback URLs (OAuth "redirect_uri") where the client receives responses from 10Duke Enterprise. This is often also the URL to which users are redirected when their authentication is complete. Required by the AuthzCode, AuthzCodeWithPkce, and Implicit flows, and ignored with other flows. */
    loginCallbackUris?: string[];
    /** The logout callback URI if single logout (SLO) is used with the client. Can be used with the AuthzCode, AuthzCodeWithPkce, and Implicit flows, ignored with other flows. You can currently define only one URI. Leave this empty if the client doesn't use or support SLO. */
    logoutCallbackUris?: string[];
    /** Defines if the OAuth access token and refresh token validity is attached to the end user's browser session with this client. Can be used with the AuthzCode, AuthzCodeWithPkce, Device, and Implicit flows, ignored with other flows. "Attached" = Tokens are invalidated when the browser session ends. "Detached" = Tokens are independent of the browser session. "DetachedIfRememberSession" = Detached mode is used if the user selects the Remember me option when logging in, otherwise attached mode is used. */
    sessionAttachment?: "Attached" | "Detached" | "DetachedIfRememberSession";
    /** Defines if OAuth refresh tokens are granted to the client. If no value is specified, the OAuth flow's default setting is used. */
    grantRefreshToken?: boolean;
};
export type OAuth20ClientRead = {
    "type": "OAuth20Client";
} & ClientBaseBaseRead & {
    /** The client ID (OAuth "client_id") used for authenticating the client. */
    clientId: string;
    /** The OIDC/OAuth flow used by the client. */
    flow: "None" | "AuthzCode" | "AuthzCodeWithPkce" | "AuthzCodeAny" | "ResourceOwnerPassword" | "ClientCredentials" | "Implicit" | "Device" | "JwtBearer" | "Any";
    /** The login callback URLs (OAuth "redirect_uri") where the client receives responses from 10Duke Enterprise. This is often also the URL to which users are redirected when their authentication is complete. Required by the AuthzCode, AuthzCodeWithPkce, and Implicit flows, and ignored with other flows. */
    loginCallbackUris?: string[];
    /** The logout callback URI if single logout (SLO) is used with the client. Can be used with the AuthzCode, AuthzCodeWithPkce, and Implicit flows, ignored with other flows. You can currently define only one URI. Leave this empty if the client doesn't use or support SLO. */
    logoutCallbackUris?: string[];
    /** Defines if the OAuth access token and refresh token validity is attached to the end user's browser session with this client. Can be used with the AuthzCode, AuthzCodeWithPkce, Device, and Implicit flows, ignored with other flows. "Attached" = Tokens are invalidated when the browser session ends. "Detached" = Tokens are independent of the browser session. "DetachedIfRememberSession" = Detached mode is used if the user selects the Remember me option when logging in, otherwise attached mode is used. */
    sessionAttachment?: "Attached" | "Detached" | "DetachedIfRememberSession";
    /** Defines if OAuth refresh tokens are granted to the client. If no value is specified, the OAuth flow's default setting is used. */
    grantRefreshToken?: boolean;
};
export type Saml20Client = {
    "type": "Saml20Client";
} & ClientBaseBase & {
    /** The SAML entity ID (NameID) of the client (SAML Service Provider). */
    entityId: string;
    /** The SAML NameID format used in authentication responses. */
    nameIdFormat: "unspecified" | "emailAddress" | "persistend";
    /** The URL where the client (SAML Service Provider) receives login responses. This is usually the Assertion Consumer Service (ACS) from the client's SAML metadata. */
    acsUrl: string[];
    /** The logout callback URI if single logout (SLO) is used with the client (SAML Service Provider). This can be the Assertion Consumer Service (ACS) from the client's SAML metadata. You can currently define only one URI. Leave this empty if the client doesn't use or support SLO. */
    sloUrl?: string[];
    /** The algorithm to use for signing SAML response assertions. */
    sigAlg?: string;
};
export type Saml20ClientRead = {
    "type": "Saml20Client";
} & ClientBaseBaseRead & {
    /** The SAML entity ID (NameID) of the client (SAML Service Provider). */
    entityId: string;
    /** The SAML NameID format used in authentication responses. */
    nameIdFormat: "unspecified" | "emailAddress" | "persistend";
    /** The URL where the client (SAML Service Provider) receives login responses. This is usually the Assertion Consumer Service (ACS) from the client's SAML metadata. */
    acsUrl: string[];
    /** The logout callback URI if single logout (SLO) is used with the client (SAML Service Provider). This can be the Assertion Consumer Service (ACS) from the client's SAML metadata. You can currently define only one URI. Leave this empty if the client doesn't use or support SLO. */
    sloUrl?: string[];
    /** The algorithm to use for signing SAML response assertions. */
    sigAlg?: string;
};
export type Client = ({
    "type": "OAuth20Client";
} & OAuth20Client) | ({
    "type": "Saml20Client";
} & Saml20Client);
export type ClientRead = ({
    "type": "OAuth20Client";
} & OAuth20ClientRead) | ({
    "type": "Saml20Client";
} & Saml20ClientRead);
export type SetClientSecretRequest = {
    /** The client secret for the client. */
    clientSecret?: string;
    /** Define whether 10Duke Enterprise generates a new client secret for the client. This is ignored if you define a client secret in "clientSecret". */
    generate?: boolean;
};
export type SetClientSecretResponse = {
    /** The client secret of the client. */
    clientSecret?: string;
};
export type ClientGroup = OrganizationGroupBase;
export type ClientGroupRead = OrganizationGroupBaseRead;
export type ClientGroupOfClient = ClientGroup & {
    /** The end date and time (ISO 8601) after which the client's membership in the client group is no longer valid. This is by default set to null, which means the membership is valid indefinitely. */
    validUntil?: string;
    /** The start date and time (ISO 8601) from which the client's membership in the client group is valid. This is by default set to the time when the membership is created. */
    validFrom?: string;
};
export type ClientGroupOfClientRead = ClientGroupRead & {
    /** The end date and time (ISO 8601) after which the client's membership in the client group is no longer valid. This is by default set to null, which means the membership is valid indefinitely. */
    validUntil?: string;
    /** The start date and time (ISO 8601) from which the client's membership in the client group is valid. This is by default set to the time when the membership is created. */
    validFrom?: string;
};
export type ClientGroupInvitation = InvitationBase & {
    /** The ID of the organization that the client is added to when the invitation is accepted. */
    organizationId: string;
    /** The IDs of the client groups that the client is added to when the invitation is accepted. */
    groupIds?: string[];
    /** The client allowed to accept or decline the invitation. Specify the unique ID of a client object to only allow the specified client, "*" to allow any existing client in the system, or leave undefined or null to allow any existing client in the system or the new client that is created when the invitation is accepted. */
    allowClientId?: string;
    /** The client name that is used if accepting the invitation creates a new client and the client doesn't specify a name. */
    clientName: string;
};
export type ClientGroupInvitationRead = InvitationBaseRead & {
    /** The ID of the organization that the client is added to when the invitation is accepted. */
    organizationId: string;
    /** The IDs of the client groups that the client is added to when the invitation is accepted. */
    groupIds?: string[];
    /** The client allowed to accept or decline the invitation. Specify the unique ID of a client object to only allow the specified client, "*" to allow any existing client in the system, or leave undefined or null to allow any existing client in the system or the new client that is created when the invitation is accepted. */
    allowClientId?: string;
    /** The client name that is used if accepting the invitation creates a new client and the client doesn't specify a name. */
    clientName: string;
};
export type AcceptOrDeclineInvitationRequestBase = {
    /** The invitation token received by the invitation recipient, authorizing them to access this invitation. */
    token: string;
};
export type AcceptClientGroupInvitationRequest = AcceptOrDeclineInvitationRequestBase & {
    /** The name of the client. Required if the invitation creates a new client and the invitation doesn't specify a client name. */
    clientName?: string;
    /** Define whether the request should attempt to create a new client. If the request is authorized (it has an access token), no new client is created. */
    createClient?: boolean;
};
export type AcceptClientGroupInvitationResponse = {
    /** The client details, if accepting the client group invitation created a new client. */
    createdClient?: OAuth20Client & {
        /** The client secret set to the client */
        clientSecret?: string;
    };
};
export type AcceptClientGroupInvitationResponseRead = {
    /** The client details, if accepting the client group invitation created a new client. */
    createdClient?: OAuth20ClientRead & {
        /** The client secret set to the client */
        clientSecret?: string;
    };
};
export type DeclineClientGroupInvitationRequest = AcceptOrDeclineInvitationRequestBase;
export type DeclineClientGroupInvitationResponse = object;
/**
 * List a client role's client permissions
 */
export function listClientPermissionsOfClientRole(roleId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PermissionWithGrantedActionsRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/client-roles/${encodeURIComponent(roleId)}/permissions`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace a client role's client permissions
 */
export function setClientPermissionsOfClientRole(roleId: string, body?: PermissionGrantsForPermission[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/client-roles/${encodeURIComponent(roleId)}/permissions`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Add client permissions to a client role
 */
export function addClientPermissionsForClientRole(roleId: string, body?: PermissionGrantsForPermission[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/client-roles/${encodeURIComponent(roleId)}/permissions`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Remove client permissions from a client role
 */
export function removeClientPermissionsOfClientRole(roleId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/client-roles/${encodeURIComponent(roleId)}/permissions`, oazapfts.json({
        ...opts,
        method: "DELETE",
        body
    }));
}
/**
 * Get a client role's client permission
 */
export function getClientPermissionOfClientRole(roleId: string, permissionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PermissionWithGrantedActionsRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/client-roles/${encodeURIComponent(roleId)}/permissions/${encodeURIComponent(permissionId)}`, {
        ...opts
    });
}
/**
 * Remove a client permission from a client role
 */
export function removeClientPermissionOfClientRole(roleId: string, permissionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/client-roles/${encodeURIComponent(roleId)}/permissions/${encodeURIComponent(permissionId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List client permissions
 */
export function listClientPermissions(clientId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientPermissionRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/clients/${encodeURIComponent(clientId)}/client-permissions`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace a client permission's details
 */
export function replaceClientPermission(clientId: string, clientPermission: ClientPermission, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientPermissionRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/clients/${encodeURIComponent(clientId)}/client-permissions`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: clientPermission
    }));
}
/**
 * Create a client permission
 */
export function createClientPermission(clientId: string, clientPermission: ClientPermission, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: ClientPermissionRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/clients/${encodeURIComponent(clientId)}/client-permissions`, oazapfts.json({
        ...opts,
        method: "POST",
        body: clientPermission
    }));
}
/**
 * Get a client permission
 */
export function getClientPermission(clientId: string, clientPermissionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientPermissionRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/clients/${encodeURIComponent(clientId)}/client-permissions/${encodeURIComponent(clientPermissionId)}`, {
        ...opts
    });
}
/**
 * Delete a client permission
 */
export function deleteClientPermission(clientId: string, clientPermissionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/clients/${encodeURIComponent(clientId)}/client-permissions/${encodeURIComponent(clientPermissionId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List client roles
 */
export function listClientRoles(clientId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientRoleRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/clients/${encodeURIComponent(clientId)}/client-roles`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace a client role's details
 */
export function replaceClientRole(clientId: string, clientRole: ClientRole, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientRoleRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/clients/${encodeURIComponent(clientId)}/client-roles`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: clientRole
    }));
}
/**
 * Create a client role
 */
export function createClientRole(clientId: string, clientRole: ClientRole, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: ClientRoleRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/clients/${encodeURIComponent(clientId)}/client-roles`, oazapfts.json({
        ...opts,
        method: "POST",
        body: clientRole
    }));
}
/**
 * Get a client role
 */
export function getClientRole(clientId: string, clientRoleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientRoleRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/clients/${encodeURIComponent(clientId)}/client-roles/${encodeURIComponent(clientRoleId)}`, {
        ...opts
    });
}
/**
 * Delete a client role
 */
export function deleteClientRole(clientId: string, clientRoleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/clients/${encodeURIComponent(clientId)}/client-roles/${encodeURIComponent(clientRoleId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List organization invitations
 */
export function listOrganizationGroupInvitations({ offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupInvitationRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/organization-group-invitations", {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Create an organization invitation
 */
export function createOrganizationGroupInvitation(organizationGroupInvitation: OrganizationGroupInvitation, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: OrganizationGroupInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/organization-group-invitations", oazapfts.json({
        ...opts,
        method: "POST",
        body: organizationGroupInvitation
    }));
}
/**
 * Accept an organization invitation
 */
export function acceptOrganizationGroupInvitation(body: {
    /** The invitation token received by the invitation recipient, authorizing them to access this invitation. */
    token: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/organization-group-invitations/accept", oazapfts.form({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * List accepted organization invitations
 */
export function listAcceptedOrganizationGroupInvitations({ offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupInvitationRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/organization-group-invitations/accepted", {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Decline an organization invitation
 */
export function declineOrganizationGroupInvitation(body: {
    /** The invitation token received by the invitation recipient, authorizing them to access this invitation. */
    token: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/organization-group-invitations/decline", oazapfts.form({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * List declined organization invitations
 */
export function listDeclinedOrganizationGroupInvitations({ offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupInvitationRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/organization-group-invitations/declined", {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * List open organization invitations
 */
export function listOpenOrganizationGroupInvitations({ offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupInvitationRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/organization-group-invitations/open", {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * List revoked organization invitations
 */
export function listRevokedOrganizationGroupInvitations({ offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupInvitationRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/organization-group-invitations/revoked", {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Create and send an organization invitation
 */
export function createAndSendOrganizationGroupInvitation(organizationGroupInvitation: OrganizationGroupInvitation, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: OrganizationGroupInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/organization-group-invitations/send", oazapfts.json({
        ...opts,
        method: "POST",
        body: organizationGroupInvitation
    }));
}
/**
 * Get an organization invitation
 */
export function getOrganizationGroupInvitation(invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupInvitationRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organization-group-invitations/${encodeURIComponent(invitationId)}`, {
        ...opts
    });
}
/**
 * Delete an organization invitation
 */
export function deleteOrganizationGroupInvitation(invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organization-group-invitations/${encodeURIComponent(invitationId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Create a token for an organization invitation
 */
export function createOrganizationGroupInvitationToken(invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: InvitationTokenResponse;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organization-group-invitations/${encodeURIComponent(invitationId)}/create-token`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Revoke an organization invitation
 */
export function revokeOrganizationGroupInvitation(invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organization-group-invitations/${encodeURIComponent(invitationId)}/revoke`, {
        ...opts,
        method: "PUT"
    });
}
/**
 * Send an organization invitation
 */
export function sendOrganizationGroupInvitation(invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organization-group-invitations/${encodeURIComponent(invitationId)}/send`, {
        ...opts,
        method: "PUT"
    });
}
/**
 * List an organization role's permissions
 */
export function listPermissionsOfOrganizationRole(roleId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PermissionWithGrantedActionsRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organization-roles/${encodeURIComponent(roleId)}/permissions`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace an organization role's permissions
 */
export function setPermissionsOfOrganizationRole(roleId: string, body?: PermissionGrantsForPermission[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organization-roles/${encodeURIComponent(roleId)}/permissions`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Add permissions to an organization role
 */
export function addPermissionsForOrganizationRole(roleId: string, body?: PermissionGrantsForPermission[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organization-roles/${encodeURIComponent(roleId)}/permissions`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Remove permissions from an organization role
 */
export function removePermissionsOfOrganizationRole(roleId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organization-roles/${encodeURIComponent(roleId)}/permissions`, oazapfts.json({
        ...opts,
        method: "DELETE",
        body
    }));
}
/**
 * Get an organization role's permission
 */
export function getPermissionOfOrganizationRole(roleId: string, permissionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PermissionWithGrantedActionsRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organization-roles/${encodeURIComponent(roleId)}/permissions/${encodeURIComponent(permissionId)}`, {
        ...opts
    });
}
/**
 * Remove a permission from an organization role
 */
export function removePermissionOfOrganizationRole(roleId: string, permissionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organization-roles/${encodeURIComponent(roleId)}/permissions/${encodeURIComponent(permissionId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List organizations
 */
export function listOrganizations({ offset, limit, order, asc, filter }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
    filter?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations${QS.query(QS.explode({
        filter
    }))}`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace an organization's details
 */
export function replaceOrganization(organization: Organization, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/organizations", oazapfts.json({
        ...opts,
        method: "PUT",
        body: organization
    }));
}
/**
 * Create an organization
 */
export function createOrganization(organization: Organization, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: OrganizationRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/organizations", oazapfts.json({
        ...opts,
        method: "POST",
        body: organization
    }));
}
/**
 * Set up an organization
 */
export function setupOrganization(organization: Organization, { initialAdminId, createDefaultEntitlement }: {
    initialAdminId?: string;
    createDefaultEntitlement?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: CreatedOrganizationWithEntitlementRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/organization-setup${QS.query(QS.explode({
        initialAdminId,
        createDefaultEntitlement
    }))}`, oazapfts.json({
        ...opts,
        method: "POST",
        body: organization
    }));
}
/**
 * Get an organization
 */
export function getOrganization(organizationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}`, {
        ...opts
    });
}
/**
 * Delete an organization
 */
export function deleteOrganization(organizationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List an organization's invitations
 */
export function listOrganizationsOrganizationGroupInvitations(organizationId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupInvitationRead[];
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/group-invitations`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Get an organization invitation
 */
export function getOrganizationsOrganizationGroupInvitation(organizationId: string, invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/group-invitations/${encodeURIComponent(invitationId)}`, {
        ...opts
    });
}
/**
 * List an organization's user groups
 */
export function listOrganizationGroups(organizationId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/groups`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace a user group's details
 */
export function replaceOrganizationGroup(organizationId: string, organizationGroup: OrganizationGroup, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/groups`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: organizationGroup
    }));
}
/**
 * Create a user group
 */
export function createOrganizationGroup(organizationId: string, organizationGroup: OrganizationGroup, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: OrganizationGroupRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/groups`, oazapfts.json({
        ...opts,
        method: "POST",
        body: organizationGroup
    }));
}
/**
 * Get a user group
 */
export function getOrganizationGroup(organizationId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/groups/${encodeURIComponent(groupId)}`, {
        ...opts
    });
}
/**
 * Delete a user group
 */
export function deleteOrganizationGroup(organizationId: string, groupId: string, { cleanUpLicenseReservations, returnNoMembershipsUserIds }: {
    cleanUpLicenseReservations?: boolean;
    returnNoMembershipsUserIds?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/groups/${encodeURIComponent(groupId)}${QS.query(QS.explode({
        cleanUpLicenseReservations,
        returnNoMembershipsUserIds
    }))}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List a user group's users
 */
export function listUsersInOrganizationGroup(organizationId: string, groupId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/groups/${encodeURIComponent(groupId)}/users`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace a user group's users
 */
export function setUsersInOrganizationGroup(organizationId: string, groupId: string, body: string[], { cleanUpLicenseReservations, returnNoMembershipsUserIds }: {
    cleanUpLicenseReservations?: boolean;
    returnNoMembershipsUserIds?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/groups/${encodeURIComponent(groupId)}/users${QS.query(QS.explode({
        cleanUpLicenseReservations,
        returnNoMembershipsUserIds
    }))}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Add users to a user group
 */
export function addUsersToOrganizationGroup(organizationId: string, groupId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/groups/${encodeURIComponent(groupId)}/users`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Remove users from a user group
 */
export function removeUsersFromOrganizationGroup(organizationId: string, groupId: string, body: string[], { cleanUpLicenseReservations, returnNoMembershipsUserIds }: {
    cleanUpLicenseReservations?: boolean;
    returnNoMembershipsUserIds?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/groups/${encodeURIComponent(groupId)}/users${QS.query(QS.explode({
        cleanUpLicenseReservations,
        returnNoMembershipsUserIds
    }))}`, oazapfts.json({
        ...opts,
        method: "DELETE",
        body
    }));
}
/**
 * Get a user group's user
 */
export function getUserInOrganizationGroup(organizationId: string, groupId: string, userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/groups/${encodeURIComponent(groupId)}/users/${encodeURIComponent(userId)}`, {
        ...opts
    });
}
/**
 * Add a user to a user group
 */
export function addUserToOrganizationGroup(organizationId: string, groupId: string, userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/groups/${encodeURIComponent(groupId)}/users/${encodeURIComponent(userId)}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Remove a user from a user group
 */
export function removeUserFromOrganizationGroup(organizationId: string, groupId: string, userId: string, { cleanUpLicenseReservations, returnNoMembershipsUserIds }: {
    cleanUpLicenseReservations?: boolean;
    returnNoMembershipsUserIds?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/groups/${encodeURIComponent(groupId)}/users/${encodeURIComponent(userId)}${QS.query(QS.explode({
        cleanUpLicenseReservations,
        returnNoMembershipsUserIds
    }))}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List organization roles
 */
export function listOrganizationRoles(organizationId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationRoleRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/organization-roles`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace an organization role's details
 */
export function replaceOrganizationRole(organizationId: string, organizationRole: OrganizationRole, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationRoleRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/organization-roles`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: organizationRole
    }));
}
/**
 * Create an organization role
 */
export function createOrganizationRole(organizationId: string, organizationRole: OrganizationRole, { impliedOrganizationRoleId }: {
    impliedOrganizationRoleId?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: OrganizationRoleRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/organization-roles${QS.query(QS.explode({
        impliedOrganizationRoleId
    }))}`, oazapfts.json({
        ...opts,
        method: "POST",
        body: organizationRole
    }));
}
/**
 * List an organization role's inherited client roles
 */
export function listClientRolesInOrganizationRole(organizationId: string, organizationRoleId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientRoleRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(organizationRoleId)}/client-roles`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace an organization role's inherited client roles
 */
export function setClientRolesInOrganizationRole(organizationId: string, organizationRoleId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(organizationRoleId)}/client-roles`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Add inherited client roles for an organization role
 */
export function addClientRolesToOrganizationRole(organizationId: string, organizationRoleId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(organizationRoleId)}/client-roles`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Remove inherited client roles from an organization role
 */
export function removeClientRolesFromOrganizationRole(organizationId: string, organizationRoleId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(organizationRoleId)}/client-roles`, oazapfts.json({
        ...opts,
        method: "DELETE",
        body
    }));
}
/**
 * Get an organization role's inherited client role
 */
export function getClientRoleInOrganizationRole(organizationId: string, organizationRoleId: string, roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientRoleRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(organizationRoleId)}/client-roles/${encodeURIComponent(roleId)}`, {
        ...opts
    });
}
/**
 * Add an inherited client role for an organization role
 */
export function addClientRoleToOrganizationRole(organizationId: string, organizationRoleId: string, roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(organizationRoleId)}/client-roles/${encodeURIComponent(roleId)}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Remove an inherited client role from an organization role
 */
export function removeClientRoleFromOrganizationRole(organizationId: string, organizationRoleId: string, roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(organizationRoleId)}/client-roles/${encodeURIComponent(roleId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List an organization role's inherited organization roles
 */
export function listOrganizationRolesInOrganizationRole(organizationId: string, parentRoleId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationRoleRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(parentRoleId)}/organization-roles`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace an organization role's inherited organization roles
 */
export function setOrganizationRolesInOrganizationRole(organizationId: string, parentRoleId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(parentRoleId)}/organization-roles`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Add inherited organization roles for an organization role
 */
export function addOrganizationRolesToOrganizationRole(organizationId: string, parentRoleId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(parentRoleId)}/organization-roles`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Remove inherited organization roles from an organization role
 */
export function removeOrganizationRolesFromOrganizationRole(organizationId: string, parentRoleId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(parentRoleId)}/organization-roles`, oazapfts.json({
        ...opts,
        method: "DELETE",
        body
    }));
}
/**
 * Get an organization role's inherited organization role
 */
export function getOrganizationRoleInOrganizationRole(organizationId: string, parentRoleId: string, roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationRoleRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(parentRoleId)}/organization-roles/${encodeURIComponent(roleId)}`, {
        ...opts
    });
}
/**
 * Add an inherited organization role for an organization role
 */
export function addOrganizationRoleToOrganizationRole(organizationId: string, parentRoleId: string, roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(parentRoleId)}/organization-roles/${encodeURIComponent(roleId)}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Remove an inherited organization role from an organization role
 */
export function removeOrganizationRoleFromOrganizationRole(organizationId: string, parentRoleId: string, roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(parentRoleId)}/organization-roles/${encodeURIComponent(roleId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get an organization role
 */
export function getOrganizationRole(organizationId: string, roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationRoleRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(roleId)}`, {
        ...opts
    });
}
/**
 * Delete an organization role
 */
export function deleteOrganizationRole(organizationId: string, roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(roleId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List users who have an organization role
 */
export function listUsersInOrganizationRole(organizationId: string, roleId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(roleId)}/users`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace which users have an organization role
 */
export function setUsersInOrganizationRole(organizationId: string, roleId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(roleId)}/users`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Add an organization role to users
 */
export function addUsersToOrganizationRole(organizationId: string, roleId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(roleId)}/users`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Remove an organization role from users
 */
export function removeUsersFromOrganizationRole(organizationId: string, roleId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(roleId)}/users`, oazapfts.json({
        ...opts,
        method: "DELETE",
        body
    }));
}
/**
 * Get a user who has an organization role
 */
export function getUserInOrganizationRole(organizationId: string, roleId: string, userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(roleId)}/users/${encodeURIComponent(userId)}`, {
        ...opts
    });
}
/**
 * Add an organization role to a user
 */
export function addUserToOrganizationRole(organizationId: string, roleId: string, userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(roleId)}/users/${encodeURIComponent(userId)}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Remove an organization role from a user
 */
export function removeUserFromOrganizationRole(organizationId: string, roleId: string, userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/organization-roles/${encodeURIComponent(roleId)}/users/${encodeURIComponent(userId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List an organization's properties
 */
export function listOrganizationProperties(organizationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Property[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/properties`, {
        ...opts
    });
}
/**
 * Replace an organization's properties
 */
export function replaceOrganizationProperties(organizationId: string, body: Property[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Property[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/properties`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * List an organization's users
 */
export function listOrganizationUsers(organizationId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/users`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Import users to an organization
 */
export function importOrganizationUsers(organizationId: string, body: UserForCreate[], { allowUpdateExisting, errorHandlingMode }: {
    allowUpdateExisting?: boolean;
    errorHandlingMode?: "failImmediately" | "failWithErrors" | "skipErrors";
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserForCreateRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/users/import${QS.query(QS.explode({
        allowUpdateExisting,
        errorHandlingMode
    }))}`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Get an organization's user
 */
export function getOrganizationUser(organizationId: string, userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/users/${encodeURIComponent(userId)}`, {
        ...opts
    });
}
/**
 * Remove a user from an organization
 */
export function removeUserFromOrganization(organizationId: string, userId: string, { deleteInvitations }: {
    deleteInvitations?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/users/${encodeURIComponent(userId)}${QS.query(QS.explode({
        deleteInvitations
    }))}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List internal permissions
 */
export function listPermissions({ offset, limit, order, asc, domain }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
    domain?: "organization";
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PermissionRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/permissions${QS.query(QS.explode({
        domain
    }))}`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace an internal permission's details
 */
export function replacePermission(permission: Permission, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PermissionRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/permissions", oazapfts.json({
        ...opts,
        method: "PUT",
        body: permission
    }));
}
/**
 * Create an internal permission
 */
export function createPermission(permission: Permission, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: PermissionRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/permissions", oazapfts.json({
        ...opts,
        method: "POST",
        body: permission
    }));
}
/**
 * Get an internal permission
 */
export function getPermission(permissionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PermissionRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/permissions/${encodeURIComponent(permissionId)}`, {
        ...opts
    });
}
/**
 * Delete an internal permission
 */
export function deletePermission(permissionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/permissions/${encodeURIComponent(permissionId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List internal roles
 */
export function listRoles({ offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: InternalRoleRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/roles", {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace an internal role's details
 */
export function replaceRole(internalRole: InternalRole, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: InternalRoleRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/roles", oazapfts.json({
        ...opts,
        method: "PUT",
        body: internalRole
    }));
}
/**
 * Create an internal role
 */
export function createRole(internalRole: InternalRole, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: InternalRoleRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/roles", oazapfts.json({
        ...opts,
        method: "POST",
        body: internalRole
    }));
}
/**
 * Get an internal role
 */
export function getRole(roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: InternalRoleRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/roles/${encodeURIComponent(roleId)}`, {
        ...opts
    });
}
/**
 * Delete an internal role
 */
export function deleteRole(roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/roles/${encodeURIComponent(roleId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List an internal role's permissions
 */
export function listPermissionsOfRole(roleId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PermissionWithGrantedActionsRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/roles/${encodeURIComponent(roleId)}/permissions`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace an internal role's permissions
 */
export function setPermissionsOfRole(roleId: string, body?: PermissionGrantsForPermission[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/roles/${encodeURIComponent(roleId)}/permissions`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Add permissions to an internal role
 */
export function addPermissionsForRole(roleId: string, body?: PermissionGrantsForPermission[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/roles/${encodeURIComponent(roleId)}/permissions`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Remove permissions from an internal role
 */
export function removePermissionsOfRole(roleId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/roles/${encodeURIComponent(roleId)}/permissions`, oazapfts.json({
        ...opts,
        method: "DELETE",
        body
    }));
}
/**
 * Get an internal role's permission
 */
export function getPermissionOfRole(roleId: string, permissionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: PermissionWithGrantedActionsRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/roles/${encodeURIComponent(roleId)}/permissions/${encodeURIComponent(permissionId)}`, {
        ...opts
    });
}
/**
 * Remove a permission from an internal role
 */
export function removePermissionOfRole(roleId: string, permissionId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/roles/${encodeURIComponent(roleId)}/permissions/${encodeURIComponent(permissionId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List invitations
 */
export function listUserInvitations({ offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserInvitationRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/user-invitations", {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Create an invitation
 */
export function createUserInvitation(userInvitation: UserInvitation, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: UserInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/user-invitations", oazapfts.json({
        ...opts,
        method: "POST",
        body: userInvitation
    }));
}
/**
 * Accept an invitation
 */
export function acceptUserInvitation(body: {
    /** The invitation token received by the invitation recipient, authorizing them to access this invitation. */
    token: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/user-invitations/accept", oazapfts.form({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * List accepted invitations
 */
export function listAcceptedUserInvitations({ offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserInvitationRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/user-invitations/accepted", {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Decline an invitation
 */
export function declineUserInvitation(body: {
    /** The invitation token received by the invitation recipient, authorizing them to access this invitation. */
    token: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/user-invitations/decline", oazapfts.form({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * List declined invitations
 */
export function listDeclinedUserInvitations({ offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserInvitationRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/user-invitations/declined", {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * List open invitations
 */
export function listOpenUserInvitations({ offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserInvitationRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/user-invitations/open", {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * List revoked invitations
 */
export function listRevokedUserInvitations({ offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserInvitationRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/user-invitations/revoked", {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Get an invitation
 */
export function getUserInvitation(invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserInvitationRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/user-invitations/${encodeURIComponent(invitationId)}`, {
        ...opts
    });
}
/**
 * Delete an invitation
 */
export function deleteUserInvitation(invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/user-invitations/${encodeURIComponent(invitationId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Create an invitation token
 */
export function createUserInvitationToken(invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: InvitationTokenResponse;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/user-invitations/${encodeURIComponent(invitationId)}/create-token`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Revoke an invitation
 */
export function revokeUserInvitation(invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/user-invitations/${encodeURIComponent(invitationId)}/revoke`, {
        ...opts,
        method: "PUT"
    });
}
/**
 * Send an invitation
 */
export function sendUserInvitation(invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/user-invitations/${encodeURIComponent(invitationId)}/send`, {
        ...opts,
        method: "PUT"
    });
}
/**
 * List users
 */
export function listUsers({ offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/users", {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace a user's details
 */
export function replaceUser(user: User, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/users", oazapfts.json({
        ...opts,
        method: "PUT",
        body: user
    }));
}
/**
 * Create a user
 */
export function createUser(userForCreate: UserForCreate, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: UserForCreateRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/users", oazapfts.json({
        ...opts,
        method: "POST",
        body: userForCreate
    }));
}
/**
 * Import users
 */
export function importUsers(body: UserForCreate[], { allowUpdateExisting, errorHandlingMode }: {
    allowUpdateExisting?: boolean;
    errorHandlingMode?: "failImmediately" | "failWithErrors" | "skipErrors";
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserForCreateRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/import${QS.query(QS.explode({
        allowUpdateExisting,
        errorHandlingMode
    }))}`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Mark a user's recovery email as valid
 */
export function validateUserRecoveryEmail(body: {
    /** The recovery email address. */
    email: string;
    /** The validation token. */
    token: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: RecoveryEmailRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/users/recovery-emails/validate", oazapfts.form({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Get a user
 */
export function getUser(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}`, {
        ...opts
    });
}
/**
 * Delete a user
 */
export function deleteUser(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List a user's organizations through roles
 */
export function listAuthorizedOrganizationsOfUser(userId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/authorized-organizations`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Change a user's login email
 */
export function changeEmail(userId: string, body: {
    /** The password of the user. */
    password?: string;
    /** The login email address of the user. */
    email: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/changeEmail`, oazapfts.form({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * List a user's organization invitations
 */
export function listUsersOrganizationGroupInvitations(userId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupInvitationRead[];
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/organization-group-invitations`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Get a user's organization invitation
 */
export function getUsersOrganizationGroupInvitation(userId: string, invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/organization-group-invitations/${encodeURIComponent(invitationId)}`, {
        ...opts
    });
}
/**
 * List a user's user groups
 */
export function listOrganizationGroupsOfUser(userId: string, { offset, limit, order, asc, organizationId }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
    organizationId?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupOfUserRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/organization-groups${QS.query(QS.explode({
        organizationId
    }))}`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace a user's user groups
 */
export function setOrganizationGroupsOfUser(userId: string, body: string[], { cleanUpLicenseReservations, returnNoMembershipsOrgIds }: {
    cleanUpLicenseReservations?: boolean;
    returnNoMembershipsOrgIds?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/organization-groups${QS.query(QS.explode({
        cleanUpLicenseReservations,
        returnNoMembershipsOrgIds
    }))}`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Add a user to user groups
 */
export function addOrganizationGroupsForUser(userId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/organization-groups`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Remove a user from user groups
 */
export function removeOrganizationGroupsOfUser(userId: string, body: string[], { cleanUpLicenseReservations, returnNoMembershipsOrgIds }: {
    cleanUpLicenseReservations?: boolean;
    returnNoMembershipsOrgIds?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/organization-groups${QS.query(QS.explode({
        cleanUpLicenseReservations,
        returnNoMembershipsOrgIds
    }))}`, oazapfts.json({
        ...opts,
        method: "DELETE",
        body
    }));
}
/**
 * Get a user's user group
 */
export function getOrganizationGroupOfUser(userId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationGroupOfUserRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/organization-groups/${encodeURIComponent(groupId)}`, {
        ...opts
    });
}
/**
 * Add a user to a user group
 */
export function addOrganizationGroupForUser(userId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/organization-groups/${encodeURIComponent(groupId)}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Remove a user from a user group
 */
export function removeOrganizationGroupOfUser(userId: string, groupId: string, { cleanUpLicenseReservations, returnNoMembershipsOrgIds }: {
    cleanUpLicenseReservations?: boolean;
    returnNoMembershipsOrgIds?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/organization-groups/${encodeURIComponent(groupId)}${QS.query(QS.explode({
        cleanUpLicenseReservations,
        returnNoMembershipsOrgIds
    }))}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List a user's organization roles
 */
export function listOrganizationRolesOfUser(userId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationRoleRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/organization-roles`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace a user's organization roles
 */
export function setOrganizationRolesOfUser(userId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/organization-roles`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Add organization roles to a user
 */
export function addOrganizationRolesForUser(userId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/organization-roles`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Remove organization roles from a user
 */
export function removeOrganizationRolesOfUser(userId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/organization-roles`, oazapfts.json({
        ...opts,
        method: "DELETE",
        body
    }));
}
/**
 * Get a user's organization role
 */
export function getOrganizationRoleOfUser(userId: string, roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OrganizationRoleRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/organization-roles/${encodeURIComponent(roleId)}`, {
        ...opts
    });
}
/**
 * Add an organization role to a user
 */
export function addOrganizationRoleForUser(userId: string, roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/organization-roles/${encodeURIComponent(roleId)}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Remove an organization role from a user
 */
export function removeOrganizationRoleOfUser(userId: string, roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/organization-roles/${encodeURIComponent(roleId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List a user's properties
 */
export function listUserProperties(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Property[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/properties`, {
        ...opts
    });
}
/**
 * Replace a user's properties
 */
export function replaceUserProperties(userId: string, body: Property[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: Property[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/properties`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * List a user's recovery emails
 */
export function listUserRecoveryEmails(userId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: RecoveryEmailRead[];
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/recovery-emails`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace a user's recovery email data
 */
export function replaceUserRecoveryEmail(userId: string, recoveryEmail: RecoveryEmail, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: RecoveryEmailRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/recovery-emails`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: recoveryEmail
    }));
}
/**
 * Create a recovery email for a user
 */
export function createUserRecoveryEmail(userId: string, recoveryEmail: RecoveryEmail, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: RecoveryEmailRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/recovery-emails`, oazapfts.json({
        ...opts,
        method: "POST",
        body: recoveryEmail
    }));
}
/**
 * Get a user's recovery email
 */
export function getUserRecoveryEmail(userId: string, emailId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: RecoveryEmailRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/recovery-emails/${encodeURIComponent(emailId)}`, {
        ...opts
    });
}
/**
 * Delete a user's recovery email
 */
export function deleteUserRecoveryEmail(userId: string, emailId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/recovery-emails/${encodeURIComponent(emailId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Create a recovery email validation token
 */
export function createUserRecoveryEmailValidationToken(userId: string, emailId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ValidationTokenResponse;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/recovery-emails/${encodeURIComponent(emailId)}/create-token`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Create a recovery email validation token and send a verification email
 */
export function sendUserRecoveryEmailValidation(userId: string, emailId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: RecoveryEmailRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/recovery-emails/${encodeURIComponent(emailId)}/send-validation`, {
        ...opts,
        method: "PUT"
    });
}
/**
 * List a user's internal roles
 */
export function listRolesOfUser(userId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: InternalRoleRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/roles`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace a user's internal roles
 */
export function setRolesOfUser(userId: string, body?: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/roles`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Add internal roles to a user
 */
export function addRolesForUser(userId: string, body?: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/roles`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Remove internal roles from a user
 */
export function removeRolesOfUser(userId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/roles`, oazapfts.json({
        ...opts,
        method: "DELETE",
        body
    }));
}
/**
 * Get a user's internal role
 */
export function getRoleOfUser(userId: string, roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: InternalRoleRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/roles/${encodeURIComponent(roleId)}`, {
        ...opts
    });
}
/**
 * Add an internal role to a user
 */
export function addRoleForUser(userId: string, roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/roles/${encodeURIComponent(roleId)}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Remove an internal role from a user
 */
export function removeRoleOfUser(userId: string, roleId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/roles/${encodeURIComponent(roleId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Get a user's TOTP credentials
 */
export function getOtpCredential(userId: string, { expand }: {
    expand?: "qrCode"[];
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: OtpCredentialRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/security/totp${QS.query(QS.explode({
        expand
    }))}`, {
        ...opts
    });
}
/**
 * Create TOTP credentials for a user
 */
export function createOtpCredential(userId: string, { expand }: {
    expand?: "qrCode"[];
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: OtpCredentialRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/security/totp${QS.query(QS.explode({
        expand
    }))}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Delete a user's TOTP credentials
 */
export function deleteOtpCredential(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/security/totp`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Check if a user has TOTP credentials
 */
export function userHasOtpCredential(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/security/totp`, {
        ...opts,
        method: "HEAD"
    });
}
/**
 * Get a user's TOTP credentials as a QR code
 */
export function getMfaImage(userId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/security/totp/qr`, {
        ...opts
    });
}
/**
 * Verify a user's TOTP credentials
 */
export function verifyOtpCredential(userId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/security/totp/verify`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Activate or suspend a user
 */
export function setUserActive(userId: string, status: boolean, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: UserRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/users/${encodeURIComponent(userId)}/setActive/${encodeURIComponent(status)}`, {
        ...opts,
        method: "PUT"
    });
}
/**
 * Set a user's password
 */
export function setPassword(userId: string, body: {
    /** The password */
    password: string;
    /** Specify if this password is validated according to the validation criteria configured in 10Duke Enterprise. */
    validate?: boolean;
    /** Specify if this password replaces the user's existing password if they have one. */
    resetExisting?: boolean;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/setPassword`, oazapfts.form({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Mark a user's contact email as valid
 */
export function validateEmail(userId: string, body: {
    /** The contact email address. */
    email: string;
}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/users/${encodeURIComponent(userId)}/validateEmail`, oazapfts.form({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * List clients
 */
export function listClients({ offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/clients", {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace a client's details
 */
export function replaceClient(client: Client, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/clients", oazapfts.json({
        ...opts,
        method: "PUT",
        body: client
    }));
}
/**
 * Create a client
 */
export function createClient(client: Client, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: ClientRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/clients", oazapfts.json({
        ...opts,
        method: "POST",
        body: client
    }));
}
/**
 * Get a client
 */
export function getClient(clientId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/clients/${encodeURIComponent(clientId)}`, {
        ...opts
    });
}
/**
 * Delete a client
 */
export function deleteClient(clientId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/clients/${encodeURIComponent(clientId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Set the client secret for a client
 */
export function setClientSecret(clientId: string, setClientSecretRequest?: SetClientSecretRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SetClientSecretResponse;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/clients/${encodeURIComponent(clientId)}/set-secret`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: setClientSecretRequest
    }));
}
/**
 * List a client's client groups
 */
export function listClientGroupsOfClient(clientId: string, { offset, limit, order, asc, organizationId }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
    organizationId?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientGroupOfClientRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/clients/${encodeURIComponent(clientId)}/client-groups${QS.query(QS.explode({
        organizationId
    }))}`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace a client's client groups
 */
export function setClientGroupsOfClient(clientId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/clients/${encodeURIComponent(clientId)}/client-groups`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Add a client to client groups
 */
export function addClientGroupsForClient(clientId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/clients/${encodeURIComponent(clientId)}/client-groups`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Remove a client from client groups
 */
export function removeClientGroupsOfClient(clientId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/clients/${encodeURIComponent(clientId)}/client-groups`, oazapfts.json({
        ...opts,
        method: "DELETE",
        body
    }));
}
/**
 * Get a client's client group
 */
export function getClientGroupOfClient(clientId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientGroupOfClientRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/clients/${encodeURIComponent(clientId)}/client-groups/${encodeURIComponent(groupId)}`, {
        ...opts
    });
}
/**
 * Add a client to a client group
 */
export function addClientGroupForClient(clientId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/clients/${encodeURIComponent(clientId)}/client-groups/${encodeURIComponent(groupId)}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Remove a client from a client group
 */
export function removeClientGroupOfClient(clientId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/clients/${encodeURIComponent(clientId)}/client-groups/${encodeURIComponent(groupId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List an organization's clients
 */
export function listOrganizationClients(organizationId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/clients`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace an organization client's details
 */
export function replaceOrganizationClient(organizationId: string, client: Client, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/clients`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: client
    }));
}
/**
 * Create an organization client for an organization
 */
export function createOrganizationClient(organizationId: string, client: Client, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: ClientRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/clients`, oazapfts.json({
        ...opts,
        method: "POST",
        body: client
    }));
}
/**
 * Get a client of an organization
 */
export function getOrganizationClient(organizationId: string, clientId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/clients/${encodeURIComponent(clientId)}`, {
        ...opts
    });
}
/**
 * Delete a client of an organization
 */
export function deleteOrganizationClient(organizationId: string, clientId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/clients/${encodeURIComponent(clientId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Set the client secret for a client of an organization
 */
export function setOrganizationClientSecret(organizationId: string, clientId: string, setClientSecretRequest?: SetClientSecretRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SetClientSecretResponse;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/clients/${encodeURIComponent(clientId)}/set-secret`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: setClientSecretRequest
    }));
}
/**
 * List an organization's client groups
 */
export function listOrganizationClientGroups(organizationId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientGroupRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/client-groups`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace a client group's details
 */
export function replaceOrganizationClientGroup(organizationId: string, clientGroup: ClientGroup, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientGroupRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/client-groups`, oazapfts.json({
        ...opts,
        method: "PUT",
        body: clientGroup
    }));
}
/**
 * Create a client group
 */
export function createOrganizationClientGroup(organizationId: string, clientGroup: ClientGroup, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: ClientGroupRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/client-groups`, oazapfts.json({
        ...opts,
        method: "POST",
        body: clientGroup
    }));
}
/**
 * Get a client group
 */
export function getOrganizationClientGroup(organizationId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientGroupRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/client-groups/${encodeURIComponent(groupId)}`, {
        ...opts
    });
}
/**
 * Delete a client group
 */
export function deleteOrganizationClientGroup(organizationId: string, groupId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/client-groups/${encodeURIComponent(groupId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List a client group's clients
 */
export function listClientsInOrganizationClientGroup(organizationId: string, groupId: string, { offset, limit, order, asc }: {
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/client-groups/${encodeURIComponent(groupId)}/clients`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Replace a client group's clients
 */
export function setClientsInOrganizationClientGroup(organizationId: string, groupId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/client-groups/${encodeURIComponent(groupId)}/clients`, oazapfts.json({
        ...opts,
        method: "PUT",
        body
    }));
}
/**
 * Add clients to a client group
 */
export function addClientsToOrganizationClientGroup(organizationId: string, groupId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/client-groups/${encodeURIComponent(groupId)}/clients`, oazapfts.json({
        ...opts,
        method: "POST",
        body
    }));
}
/**
 * Remove clients from a client group
 */
export function removeClientsFromOrganizationClientGroup(organizationId: string, groupId: string, body: string[], opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/client-groups/${encodeURIComponent(groupId)}/clients`, oazapfts.json({
        ...opts,
        method: "DELETE",
        body
    }));
}
/**
 * Get a client group's client
 */
export function getClientInOrganizationClientGroup(organizationId: string, groupId: string, clientId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/client-groups/${encodeURIComponent(groupId)}/clients/${encodeURIComponent(clientId)}`, {
        ...opts
    });
}
/**
 * Add a client to a client group
 */
export function addClientToOrganizationClientGroup(organizationId: string, groupId: string, clientId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/client-groups/${encodeURIComponent(groupId)}/clients/${encodeURIComponent(clientId)}`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Remove a client from a client group
 */
export function removeClientFromOrganizationClientGroup(organizationId: string, groupId: string, clientId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/client-groups/${encodeURIComponent(groupId)}/clients/${encodeURIComponent(clientId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * List client group invitations
 */
export function listOrganizationClientGroupInvitations(organizationId: string, { invitationState, offset, limit, order, asc }: {
    invitationState?: "accepted" | "created" | "updated" | "declined" | "deliveryRequested" | "revoked";
    offset?: number;
    limit?: number;
    order?: string;
    asc?: boolean;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientGroupInvitationRead[];
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/client-group-invitations${QS.query(QS.explode({
        invitationState
    }))}`, {
        ...opts,
        headers: oazapfts.mergeHeaders(opts?.headers, {
            offset: offset !== undefined ? String(offset) : undefined,
            limit: limit !== undefined ? String(limit) : undefined,
            order,
            asc: asc !== undefined ? String(asc) : undefined
        })
    });
}
/**
 * Create a client group invitation
 */
export function createOrganizationClientGroupInvitation(organizationId: string, clientGroupInvitation: ClientGroupInvitation, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: ClientGroupInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/client-group-invitations`, oazapfts.json({
        ...opts,
        method: "POST",
        body: clientGroupInvitation
    }));
}
/**
 * Accept a client group invitation
 */
export function acceptClientGroupInvitation(acceptClientGroupInvitationRequest: AcceptClientGroupInvitationRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: AcceptClientGroupInvitationResponseRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/client-group-invitations/accept", oazapfts.json({
        ...opts,
        method: "PUT",
        body: acceptClientGroupInvitationRequest
    }));
}
/**
 * Decline a client group invitation
 */
export function declineClientGroupInvitation(declineClientGroupInvitationRequest: DeclineClientGroupInvitationRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: DeclineClientGroupInvitationResponse;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>("/client-group-invitations/decline", oazapfts.json({
        ...opts,
        method: "PUT",
        body: declineClientGroupInvitationRequest
    }));
}
/**
 * Create and send a client group invitation
 */
export function createAndSendOrganizationClientGroupInvitation(organizationId: string, clientGroupInvitation: ClientGroupInvitation, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: ClientGroupInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/client-group-invitations/send`, oazapfts.json({
        ...opts,
        method: "POST",
        body: clientGroupInvitation
    }));
}
/**
 * Get a client group invitation
 */
export function getOrganizationClientGroupInvitation(organizationId: string, invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientGroupInvitationRead;
    } | {
        status: 400;
        data: string;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 404;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/client-group-invitations/${encodeURIComponent(invitationId)}`, {
        ...opts
    });
}
/**
 * Delete a client group invitation
 */
export function deleteOrganizationClientGroupInvitation(organizationId: string, invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/organizations/${encodeURIComponent(organizationId)}/client-group-invitations/${encodeURIComponent(invitationId)}`, {
        ...opts,
        method: "DELETE"
    });
}
/**
 * Create a token for a client group invitation
 */
export function createOrganizationClientGroupInvitationToken(organizationId: string, invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: InvitationTokenResponse;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/client-group-invitations/${encodeURIComponent(invitationId)}/create-token`, {
        ...opts,
        method: "POST"
    });
}
/**
 * Revoke a client group invitation
 */
export function revokeOrganizationClientGroupInvitation(organizationId: string, invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientGroupInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/client-group-invitations/${encodeURIComponent(invitationId)}/revoke`, {
        ...opts,
        method: "PUT"
    });
}
/**
 * Send a client group invitation
 */
export function sendOrganizationClientGroupInvitation(organizationId: string, invitationId: string, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ClientGroupInvitationRead;
    } | {
        status: 401;
        data: string;
    } | {
        status: 403;
        data: string;
    } | {
        status: 500;
        data: string;
    }>(`/organizations/${encodeURIComponent(organizationId)}/client-group-invitations/${encodeURIComponent(invitationId)}/send`, {
        ...opts,
        method: "PUT"
    });
}
