import { useContext, useEffect } from "react";
import {
  Button,
  TooltipWrapper,
  FeedbackContainerProps,
  ShowFeedback,
  Page,
  PageDOMProps,
  IconLibrary
} from "@10duke/dukeui";
import { useIntl } from "react-intl";
import Table, {TableColumn} from "../../table";
import { LicenseWithCredits } from "../../../model/entitlement/LicenseWithCredits";
import LicenseUtils from "../../../utils/licensed-item";
import { Link } from "react-router-dom";
import {
  resolveFreeSeatsForLicense,
  resolveFreeUseCountForLicense,
  resolveFreeUseTimeForLicense,
} from "../../../util/licenseUtil";
import { Dropdown } from "react-bootstrap";
import LicenseUsageModal from "../../modals/license-usage-modal";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import LicenseValidityBadge, {
  resolveLicenseValidity,
} from "../../badges/license-validity-badge";
import ViewLicenseModal from "../../modals/view-license-modal";
import ViewEntitlementModal from "../../modals/view-entitlement-modal";
import { LicenseLabels, LicenseValues } from "../../../localization/license";
import DownloadLicenseModal from "../../modals/download-license-modal";
import { hasAction, resolveColumns, resolveFilters} from "../../../ui-configuration/configuration-tools";
import { isValid } from "../../../util/objectUtil";
import {TableFilter} from "../../table/table-container";
import {LicenseValidityType} from "../../badges/license-validity-badge/license-validity-badge-view";
import {HideLabelTableFilterView, TableFilterViewProps} from "../../table/table-filter/table-filter-view";
import "./licenses-view.scss";
import HeaderActions from "../../table/header-actions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export enum ModalKeys {
  show = "show",
  showEntitlement = "show-entitlement",
  usage = "usage",
  downloadLicense = "download-license",
}

//<editor-fold desc="Props">
export interface LicenseWithCreditsAndEntitlementName
  extends LicenseWithCredits {
  entitlementName: string | undefined;
  creditTypes: string[];
}
export interface LicensesDOMProps extends PageDOMProps {}
export interface LicensesStateProps extends FeedbackContainerProps {
  selected: LicenseWithCreditsAndEntitlementName[];
  onSetSelected: (selection: LicenseWithCreditsAndEntitlementName[]) => void;
}
export interface LicensesModalVisibilityProps {
  showModal?: { key: ModalKeys; licenseId?: string; secondaryId?: string };
  onShowModal: (key: ModalKeys, licenseId?: string) => void;
  onHideModal: (callback?: () => void) => void;
}
export interface LicensesProps
  extends LicensesStateProps,
    LicensesDOMProps,
    LicensesModalVisibilityProps {
  licenses?: LicenseWithCreditsAndEntitlementName[];
  onLoadLicenses: () => Promise<any>;
}
//</editor-fold>

/**
 * Internal utility for creating a license status filter
 * @param status
 * @param initial
 * @param intl
 */
function createStatusFilter(status: "valid"|"invalid"|"expiring"|"scheduled"|"deactivated",
                            initial: boolean): TableFilter {
  return {
    key: "hide" + status.replace(/^\w/, (c) => c.toUpperCase()),
    initial: initial,
    view: (props: TableFilterViewProps) => <HideLabelTableFilterView
        label={LicenseValues.validityStatus[status]}
        {...props}
    />,
    apply: (itm: LicenseWithCreditsAndEntitlementName) => {
      const validity = itm ? resolveLicenseValidity(itm) : undefined;
      return validity !== status;
    },
  };
}

function Licenses(props: LicensesProps) {
  //<editor-fold desc="Local variables">
  const {
    licenses,
    onLoadLicenses,
    onShowModal,
    onHideModal,
    showModal,
    selected,
    onSetSelected,
    onShowFeedback,
    onHideFeedback,
    feedback,
    ...other
  } = props;
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: "Manage Licenses",
    description: "Heading for the Licenses page",
  });
  const description = intl.formatMessage({
    defaultMessage: "Organization license management",
    description: "window meta description for the Licenses page",
  });
  let hideSeats = true;
  let hideCount = true;
  let hideTime = true;
  if (licenses) {
    hideSeats =
      licenses.filter((lic) => lic.creditTypes.indexOf("seat") >= 0).length ===
      0;
    hideCount =
      licenses.filter((lic) => lic.creditTypes.indexOf("count") >= 0).length ===
      0;
    hideTime =
      licenses.filter((lic) => lic.creditTypes.indexOf("time") >= 0).length ===
      0;
  }

  const { conf } = useContext(UIConfiguration);
  const licensesConf =
    conf.functionality && conf.functionality.licenses
      ? conf.functionality.licenses
      : {};
  let columns: TableColumn[] = [
    {
      key: "id",
      label: intl.formatMessage(LicenseLabels.id),
      hidden: true,
      isTechnical: true,
    },
    {
      key: "validity",
      isDummy: true,
      label: intl.formatMessage(LicenseLabels.validityStatus),
      sortable: true,
      rendererData: {
        resolveValue: (r: any) => {
          const res = resolveLicenseValidity(r);
          if (res) {
            return intl.formatMessage(LicenseValues.validityStatus[res]);
          } else {
            return res;
          }
        },
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return (
          <LicenseValidityBadge
            validFrom={props.row.validFrom}
            validUntil={props.row.validUntil}
            validity={resolveLicenseValidity(props.row)}
          />
        );
      },
      tipRenderer: () => false,
    },
    {
      key: "validFrom",
      label: intl.formatMessage(LicenseLabels.validFrom),
      sortable: true,
      hidden: true,
      rendererData: {
        resolveValue: (itm: any) => {
          let retVal;
          const d = itm.validFrom;
          if (d === "now()") {
            retVal = intl.formatDate(new Date());
          } else if (d) {
            retVal = intl.formatDate(new Date(d));
          }
          return retVal;
        },
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return <>{props.rendererData.resolveValue(props.row)}</>;
      },
    },

    {
      key: "validUntil",
      label: intl.formatMessage(LicenseLabels.validUntil),
      sortable: true,
      hidden: true,
      rendererData: {
        resolveValue: (itm: any) => {
          let retVal;
          const d = itm.validUntil;
          if (d === "now()") {
            retVal = intl.formatDate(new Date());
          } else if (d) {
            retVal = intl.formatDate(new Date(d));
          }
          return retVal;
        },
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return <>{props.rendererData.resolveValue(props.row)}</>;
      },
    },
    {
      key: "licensedItem.displayName",
      label: intl.formatMessage(LicenseLabels.licensedItem.displayName),
      sortable: true,
      rendererData: {
        resolveValue: (itm: any) => {
          return LicenseUtils.resolveDisplayName(
            itm.licensedItem,
            intl.formatMessage(LicenseValues.licensedItem.displayName.undefined)
          );
        },
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return <>{props.rendererData.resolveValue(props.row)}</>;
      },
    },
    {
      key: "entitlementName",
      label: intl.formatMessage(LicenseLabels.entitlementName),
      sortable: true,
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return (
          <>
            {licensesConf.showEntitlement && (
              <Link
                to={
                  "/licenses/" +
                  ModalKeys.showEntitlement +
                  "/" +
                  props.row.entitlementId
                }
              >
                {props.cell}
              </Link>
            )}
            {!licensesConf.showEntitlement && props.cell}
          </>
        );
      },
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return <>{props.cell}</>;
      },
    },
    {
      key: "type",
      isDummy: true,
      label: intl.formatMessage(LicenseLabels.effectiveLicenseModel.modelType),
      sortable: true,
      hidden: true,
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return <>{props.rendererData.resolveValue(props.row)}</>;
      },
      rendererData: {
        resolveValue: (itm: any) => {
          return itm &&
            itm.effectiveLicenseModel &&
              itm.effectiveLicenseModel.assignments &&
              itm.effectiveLicenseModel.assignments.reservations &&
              itm.effectiveLicenseModel.assignments.reservations === 'advance'
            ? intl.formatMessage(
                LicenseValues.effectiveLicenseModel.modelType.requireReservation
              )
            : intl.formatMessage(
                LicenseValues.effectiveLicenseModel.modelType
                  .optionalReservation
              );
        },
      },
    },
    {
      key: "creditTypes",
      label: intl.formatMessage(LicenseLabels.creditTypes),
      sortable: true,
    },
    {
      key: "seatsReserved",
      label: intl.formatMessage(LicenseLabels.seatsReserved),
      sortable: true,
    },

    {
      key: "seatsTotal",
      label: intl.formatMessage(LicenseLabels.seatsTotal),
      sortable: true,
      hidden: hideSeats,
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const tmp = props.row.seatsTotal;
        return <>{tmp === undefined ? intl.formatMessage(LicenseValues.seatsTotal.undefined) : tmp}</>;
      },
    },
    {
      key: "seatsTaken",
      label: intl.formatMessage(LicenseLabels.seatsTaken),
      sortable: true,
      hidden: hideSeats,
    },
    {
      key: "seatsAvailable",
      isDummy: true,
      label: intl.formatMessage(LicenseLabels.seatsAvailable),
      sortable: true,
      hidden: hideSeats,
      rendererData: {
        resolveValue: resolveFreeSeatsForLicense,
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const tmp = props.rendererData.resolveValue(props.row);
        return <>{tmp === undefined ? intl.formatMessage(LicenseValues.seatsAvailable.undefined) : tmp}</>;
      },
    },
    {
      key: "useCountCredits[0].useCount",
      label: intl.formatMessage(
        LicenseLabels.aggregatedUseCountCredits.useCount
      ),
      hidden: hideCount,
      sortable: true,
    },
    {
      key: "useCountCredits[0].countUsed",
      label: intl.formatMessage(
        LicenseLabels.aggregatedUseCountCredits.countUsed
      ),
      hidden: hideCount,
      sortable: true,
    },
    {
      key: "useCountAvailable",
      isDummy: true,
      label: intl.formatMessage(
        LicenseLabels.aggregatedUseCountCredits.useCountAvailable
      ),
      sortable: true,
      hidden: hideCount,
      rendererData: {
        resolveValue: resolveFreeUseCountForLicense,
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return <>{props.rendererData.resolveValue(props.row)}</>;
      },
    },

    {
      key: "useTimeCredits[0].useTime",
      label: intl.formatMessage(LicenseLabels.aggregatedUseTimeCredits.useTime),
      hidden: hideTime,
      sortable: true,
    },
    {
      key: "useTimeCredits[0].timeUsed",
      label: intl.formatMessage(
        LicenseLabels.aggregatedUseTimeCredits.timeUsed
      ),
      hidden: hideTime,
      sortable: true,
    },
    {
      key: "useTimeAvailable",
      isDummy: true,
      label: intl.formatMessage(
        LicenseLabels.aggregatedUseTimeCredits.useTimeAvailable
      ),
      hidden: hideTime,
      sortable: true,
      rendererData: {
        resolveValue: resolveFreeUseTimeForLicense,
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return <>{props.rendererData.resolveValue(props.row)}</>;
      },
    },
    {
      key: "resolvedAllowedVersions",
      isDummy: true,
      label: intl.formatMessage(LicenseLabels.resolvedAllowedVersions),
      sortable: true,
      rendererData: {
        resolveValue: (l: any) => {
          let version;
          if (l.seatCountCredits && l.seatCountCredits.length) {
            version =
              l.seatCountCredits[0].allowedVersionsDescription ||
              l.seatCountCredits[0].allowedVersions;
          }
          if (!version && l.useCountCredits && l.useCountCredits.length) {
            version =
              l.useCountCredits[0].allowedVersionsDescription ||
              l.useCountCredits[0].allowedVersions;
          }
          if (!version && l.useTimeCredits && l.useTimeCredits.length) {
            version =
              l.useTimeCredits[0].allowedVersionsDescription ||
              l.useTimeCredits[0].allowedVersions;
          }
          return version || "";
        },
      },
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return <>{props.rendererData.resolveValue(props.row)}</>;
      },
    },
  ];

  if (licensesConf.columns && licensesConf.columns.length > 0) {
    columns = resolveColumns(columns, licensesConf.columns);
  }
  //</editor-fold>
  // resets selection when users are undefined, and clears out selected items that are not part of updated data
  useEffect(() => {
    if (selected.length) {
      if (licenses === undefined) {
        onSetSelected([]);
      } else {
        const ids = licenses.map((lic) => lic.id);
        const newS = selected.filter((itm: any) => {
          return ids.indexOf(itm.id) >= 0;
        });
        if (newS.length !== selected.length) {
          onSetSelected(newS);
        }
      }
    }
  }, [licenses, selected, onSetSelected]);

  const handleClose = (callback?: () => void) => {
    onSetSelected([]);
    onHideModal(callback);
  };

  const filters: TableFilter[] = resolveFilters([
      {
        key: "showOnlyValid",
        initial: true,
        view: (props: TableFilterViewProps) => <>{intl.formatMessage({
          defaultMessage: "Show only valid licenses",
          description:
              "Label for filter toggle that controls visibility of not valid licenses in table",
        })}</>,
        apply: (itm: LicenseWithCreditsAndEntitlementName) => {
          const validity = itm ? resolveLicenseValidity(itm) : undefined;
          return (
              validity === LicenseValidityType.valid ||
              validity === LicenseValidityType.expiring
          );
        },
      },
    createStatusFilter('valid', false),
    createStatusFilter('invalid', true),
    createStatusFilter('expiring', false),
    createStatusFilter('scheduled', true),
    createStatusFilter('deactivated', true),
    ], licensesConf?.filters);
  return (
    <Page
      data-test-licenses-page
      header={<h1>{title}</h1>}
      id={"licenses-page"}
      meta={{
        title,
        description,
      }}
      {...other}
    >
      <ShowFeedback
        idPrefix={"licenses_"}
        onHideFeedback={onHideFeedback}
        feedback={feedback}
      />
      <Table<LicenseWithCreditsAndEntitlementName>
        data-test-license-table
        persistStateKey={"licenses-table"}
        allowColumnSort={true}
        selection={{
          multi: false,
          selectAll: false,
        }}
        columnToggle
        selected={selected}
        onSelectionChanged={onSetSelected}
        reload={true}
        onLoadData={onLoadLicenses}
        search={true}
        columns={columns}
        data={
          licenses
            ? (licenses as LicenseWithCreditsAndEntitlementName[])
            : undefined
        }
        identifyingColumn={"id"}
        filters={filters}
        pagination={false}
        rowClasses={(r) => {
          return resolveLicenseValidity(r);
        }}
        header={<HeaderActions actions={licensesConf.actions} actionRenderer={(action) => {
          let retVal = undefined;
          if (!!action.url) {
            retVal = (
                <Button
                    key={action.key}
                    data-test-custom-action={action.key}
                    action={(e: any) => {
                      e.preventDefault();
                      e.stopPropagation();
                      window.open(action.url, '_blank');
                    }}
                    variant={"primary"}
                    className={"btn custom-base"}
                >
                  {intl.formatMessage({
                    defaultMessage: "{action, select, other {{action}}}",
                    description: "Custom header action label. Prints action key by default. Inject `actionKey {Label}` after the `select,` and before the `other` to provide translation for custom action. Only use space as separator with multiple actions, no comma.",
                  }, {
                    action: action.key
                  })}
                </Button>
            );
          }
          return retVal;
        }} />}
        rowTools={(props: { rowEntry: LicenseWithCreditsAndEntitlementName }) => (
                licensesConf.rowActions?.map((m) => {
                  let retVal = undefined;
                  if (!m.disabled) {
                    if (m.key === 'show') {
                      retVal = (
                          <Dropdown.Item
                              data-test-row-tool-details={props.rowEntry.id}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShowModal(ModalKeys.show, props.rowEntry.id);
                              }}
                          >
                            {intl.formatMessage({
                              defaultMessage: "License details",
                              description: "label for the tool",
                            })}
                          </Dropdown.Item>
                      );
                    } else if (m.key === 'usage') {
                      retVal = (
                          <Dropdown.Item
                              data-test-row-tool-usage={props.rowEntry.id}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShowModal(ModalKeys.usage, props.rowEntry.id);
                              }}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Usage",
                              description: "label for the tool",
                            })}
                          </Dropdown.Item>
                      );
                    } else if (m.key === 'downloadLicense') {
                      if (props.rowEntry.active &&
                          isValid(props.rowEntry)) {
                        retVal = (<Dropdown.Item
                            data-test-row-tool-download={props.rowEntry.id}
                            onClick={(e: any) => {
                              e.preventDefault();
                              e.stopPropagation();
                              onShowModal(
                                  ModalKeys.downloadLicense,
                                  props.rowEntry.id
                              );
                            }}
                        >
                          {intl.formatMessage({
                            defaultMessage: "Download license",
                            description: "label for the tool",
                          })}
                        </Dropdown.Item>);
                      } else {
                        retVal = (<TooltipWrapper
                            tip={intl.formatMessage({
                              defaultMessage:
                                  "Only valid licenses can be downloaded.",
                              description:
                                  "tooltip for disabled download license tool",
                            })}
                            tipKey={"unassignBlockedTip"}
                            placement={"auto"}
                            wrapDisabled={true}
                            className={"d-block"}
                        >
                          <Dropdown.Item
                              data-test-row-tool-download={props.rowEntry.id}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onShowModal(
                                    ModalKeys.downloadLicense,
                                    props.rowEntry.id
                                );
                              }}
                              disabled={true}
                          >
                            {intl.formatMessage({
                              defaultMessage: "Download license",
                              description: "label for the tool",
                            })}
                          </Dropdown.Item>
                        </TooltipWrapper>);
                      }
                    } else if (!!m.url) {
                      retVal = (
                          <Dropdown.Item
                              data-test-row-tool-custom={m.key}
                              onClick={(e: any) => {
                                e.preventDefault();
                                e.stopPropagation();
                                window.open(m.url, '_blank');
                              }}
                              className={'custom-action'}
                          >
                            <span>{intl.formatMessage({
                              defaultMessage: "{action, select, other {{action}}}",
                              description: "Custom row action label. Prints action key by default. Inject `actionKey {Label}` after the `select,` and before the `other` to provide translation for custom action. Only use space as separator with multiple actions, no comma.",
                            }, {
                              action: m.key
                            })}</span>
                            <FontAwesomeIcon icon={IconLibrary.icons.faExternalLinkAlt} className={'icon'} fixedWidth={true} />
                          </Dropdown.Item>
                      );
                    }
                  }
                  return retVal;
                })
        )}
      />
      {hasAction(licensesConf.rowActions, 'show') && (
        <ViewLicenseModal
          show={showModal ? showModal.key === ModalKeys.show : false}
          onClose={handleClose}
          licenseId={showModal?.licenseId}
        />
      )}
      {licensesConf.showEntitlement && (
        <ViewEntitlementModal
          show={showModal ? showModal.key === ModalKeys.showEntitlement : false}
          onClose={handleClose}
          entitlementId={showModal ? showModal.secondaryId : undefined}
        />
      )}
      {hasAction(licensesConf.rowActions, 'usage') && (
        <LicenseUsageModal
          show={showModal ? showModal.key === ModalKeys.usage : false}
          onClose={handleClose}
          onShowFeedback={onShowFeedback}
          licenseId={showModal ? (showModal.licenseId as string) : undefined}
          enableUsers={conf.functionality?.licenses?.usageEnableUsers !== false}
          enableClients={conf.functionality?.licenses?.usageEnableClients !== false}
        />
      )}
      {hasAction(licensesConf.rowActions, 'downloadLicense') && (
        <DownloadLicenseModal
          show={showModal ? showModal.key === ModalKeys.downloadLicense : false}
          onClose={handleClose}
          onShowFeedback={onShowFeedback}
          licenseId={showModal ? (showModal.licenseId as string) : undefined}
        />
      )}
    </Page>
  );
}
export default Licenses;
