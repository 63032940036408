import {
  RemoveDeviceClientInvitationModalProps as _RemoveDeviceClientInvitationModalProps,
  RemoveDeviceClientInvitationModalDataProps,
} from "./remove-device-client-invitation-modal-view";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import { ActionSender } from "../../../model/ActionSender";
import { AppState } from "../../../store/AppState";
import InProgressUtils from "../../../utils/in-progress";
import OrganizationUtils from "../../../utils/organization";
import { addSenderArgument } from "../../../actions/actionHelpers";
import {
  clearError, deleteOrgClientGroupInvitation, getOrganizationClientGroupInvitation,
} from "../../../actions";
import { connect } from "react-redux";
import RemoveDeviceClientInvitationModal from "./remove-device-client-invitation-modal-container";

export interface RemoveDeviceClientInvitationModalProps
  extends Omit<
    _RemoveDeviceClientInvitationModalProps,
    keyof RemoveDeviceClientInvitationModalDataProps
  > {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _RemoveDeviceClientInvitationModalProps,
  RemoveDeviceClientInvitationModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _RemoveDeviceClientInvitationModalProps,
  RemoveDeviceClientInvitationModalProps
>;
const sender: ActionSender = { key: "remove-client-invitation" };

function mapStateToProps(
  state: AppState,
  ownProps: RemoveDeviceClientInvitationModalProps
): ReduxStateProps {
  return {
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    invitation: ownProps.invitationId
      ? OrganizationUtils.selectOrganizationClientInvitation(
          ownProps.invitationId,
          state
        )
      : undefined,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientInvitationsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onRemoveInvitation: addSenderArgument(
    sender,
      deleteOrgClientGroupInvitation
  ),
  onLoadInvitation: addSenderArgument(
    sender,
    getOrganizationClientGroupInvitation
  ),
  onClearError: clearError,
};
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  RemoveDeviceClientInvitationModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(RemoveDeviceClientInvitationModal);
