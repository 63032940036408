import {
  ViewInvitationModalProps as _ViewInvitationModalProps,
  ViewInvitationModalDataProps,
} from "./view-invitation-modal-view";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import { ActionSender } from "../../../model/ActionSender";
import { AppState } from "../../../store/AppState";
import InProgressUtils from "../../../utils/in-progress";
import OrganizationUtils from "../../../utils/organization";
import { addSenderArgument } from "../../../actions/actionHelpers";
import {
  clearError,
  getOrganizationsOrganizationGroupInvitation,
  listOrganizationRoles,
} from "../../../actions";
import { connect } from "react-redux";
import ViewInvitationModal from "./view-invitation-modal-container";
import {populateExpiredStatus} from "../../../utils/invitation";

export interface ViewInvitationModalProps
  extends Omit<_ViewInvitationModalProps, keyof ViewInvitationModalDataProps> {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ViewInvitationModalProps,
  ViewInvitationModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ViewInvitationModalProps,
  ViewInvitationModalProps
>;
const sender: ActionSender = { key: "view-invitation" };

function mapStateToProps(
  state: AppState,
  ownProps: ViewInvitationModalProps
): ReduxStateProps {
  return {
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    invitation: ownProps.invitationId
      ? populateExpiredStatus(OrganizationUtils.selectOrganizationInvitation(
          ownProps.invitationId,
          state
        ))
      : undefined,
    orgAdminRoleId: OrganizationUtils.selectOrgAdminRoleId(state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationInvitationsStates(next, prev) &&
    OrganizationUtils.compareOrganizationRolesStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadInvitation: addSenderArgument(
    sender,
    getOrganizationsOrganizationGroupInvitation
  ),
  onLoadOrganizationRoles: addSenderArgument(sender, listOrganizationRoles),
  onClearError: clearError,
};
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ViewInvitationModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ViewInvitationModal);
