import { defineMessages } from "react-intl";
import { BaseObjLabels } from "../base-obj";

/**
 * Localized labels for "UI Model" LicensedItem fields
 */
export const LicensedItemLabels = {
  ...BaseObjLabels,
  // Add AuthorEditorFields if needed
  ...defineMessages({
    displayName: {
      defaultMessage: "name",
      description:
        "Field label for the display name of a licensed item (LicensedItem.displayName)"
    }

    /*
    name?: string;
    description?: string;
    type?: string;
    aggregatedItems?: LicensedItem[];
    */
  })
};

/**
 * Localized values for LicensedItem fields
 */
export const LicensedItemValues = {
  // no extension needed for now
  displayName: defineMessages({
    undefined: {
      defaultMessage: "Unknown",
      description:
        "fallback value for missing display name of a licenses item (LicensedItem.displayName)"
    }
  })
};
