import { connect } from "react-redux";
import { AppState } from "../../../store/AppState";
import OrganizationUtils from "../../../utils/organization";
import InvitationsCard, {
  InvitationsCardProps as _InvitationsCardProps
} from "./invitations-card-container";

import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../../util/typeUtil";
import {listOrganizationsOrganizationGroupInvitations, listOrgClientGroupInvitations} from "../../../actions";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";
import {populateExpiredStatus} from "../../../utils/invitation";
import {UIInvitation} from "../../pages/invitations/invitations-view";
import {populateDeviceClientInvitationExpiredStatus} from "../../../utils/deviceClientGroupInvitation";

export interface InvitationsCardProps
  extends Pick<_InvitationsCardProps, "moreAction"|"enableClients"|"enableUsers"> {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _InvitationsCardProps,
  InvitationsCardProps
>;

// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _InvitationsCardProps,
  InvitationsCardProps
>;

const sender: ActionSender = { key: "invitationsCard" };

function mapStateToProps(state: AppState, ownProps: InvitationsCardProps): ReduxStateProps {

  let invitations = ownProps.enableUsers ? OrganizationUtils.selectOrganizationInvitations(state)?.map((m) => {
    return {
      ...populateExpiredStatus(m),
      invitationType: "user",
    } as UIInvitation;
  }) : undefined;
  if (ownProps.enableClients) {
    const cInv = OrganizationUtils.selectOrganizationClientInvitations(state)?.map((m) => {
      return {
        ...populateDeviceClientInvitationExpiredStatus(m),
        invitationType: "client",
      } as UIInvitation;
    });
    if (((!!invitations || !ownProps.enableUsers)) && !!cInv) {
      invitations = (invitations || []).concat(...cInv);
    } else if (!invitations || !cInv) {
      // loading is with a single trigger, so both must be returned before we can proceed to ensure all data is loaded
      invitations = undefined;
    }
  }
  if (invitations && invitations.length) {
    // match the default sort order after combining the device & user results
    invitations = invitations
        .sort((a, b) => {
          if (a.email < b.email) {
            return -1;
          }
          if (a.email > b.email) {
            return 1;
          }
          return 0;
        })
    ;
  }
  const pendingCount = !!invitations
    ? invitations.filter((i) => {
        return i.invitationState === "deliveryRequested";
      }).length
    : 0;
  const unsentCount = !!invitations
    ? invitations.filter((i) => {
        return i.invitationState === "created";
      }).length
    : 0;
  const declinedCount = !!invitations
    ? invitations.filter((i) => {
        return i.invitationState === "declined";
      }).length
    : 0;
  const revokedCount = !!invitations
    ? invitations.filter((i) => {
        return i.invitationState === "revoked";
      }).length
    : 0;
  const acceptedCount = !!invitations
    ? invitations.filter((i) => {
        return i.invitationState === "accepted";
      }).length
    : 0;
  const updatedCount = !!invitations
      ? invitations.filter((i) => {
        return i.invitationState === "updated";
      }).length
      : 0;
  const expiredCount = !!invitations
      ? invitations.filter((i) => {
        return i.invitationState === "expired";
      }).length
      : 0;
  return {
    pendingCount,
    unsentCount,
    declinedCount,
    revokedCount,
    acceptedCount,
    updatedCount,
    expiredCount,
    invitations: invitations,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state)
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationInvitationsStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientInvitationsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadInvitations: addSenderArgument(
    sender,
    listOrganizationsOrganizationGroupInvitations
  ),
  onLoadDeviceClientGroupInvitations: addSenderArgument(
      sender,
      listOrgClientGroupInvitations
  ),
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  InvitationsCardProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual
})(InvitationsCard);
