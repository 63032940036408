import LicensesPage, { ModalKeys } from "../../components/pages/licenses";
import { useHistory, useLocation, useParams } from "react-router-dom";
export { ModalKeys } from "../../components/pages/licenses";

export default function Licenses() {
  const history = useHistory();
  const location = useLocation();
  let { modal, licenseId, secondaryId } = useParams<any>();
  const resolveBase = () => {
    let base: string = location.pathname;
    if (modal) {
      base = base.replace(`/${modal}`, "");
    }
    if (licenseId) {
      base = base.replace(`/${licenseId}`, "");
    }
    if (secondaryId) {
      base = base.replace(`/${secondaryId}`, "");
    }

    if (base.endsWith("/")) {
      base = base.substr(0, base.length - 1);
    }
    return base;
  };
  const onShowModal = (key: ModalKeys, licId?: string, secId?: string) => {
    let path: string[] = [resolveBase()];

    if (licId) {
      path.push(licId);
    }
    path.push(key);
    if (secId) {
      path.push(secId);
    }
    history.push(path.join("/"));
  };
  const onHideModal = (callback?: () => void) => {
    history.push(resolveBase());

    if (callback && typeof callback === "function") {
      callback();
    }
  };
  return (
    <LicensesPage
      showModal={
        modal
          ? {
              key: modal as ModalKeys,
              licenseId: licenseId,
              secondaryId: secondaryId,
            }
          : undefined
      }
      onShowModal={onShowModal}
      onHideModal={onHideModal}
    />
  );
}
