import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {
  buildActionThunk,
  ensureSelectedOrgId,
  forceUndefined
} from "./actionHelpers";
import {ClientGroupInvitation} from "../model/ClientGroupInvitation";

/**
 * Revokes an invitation, which means that a previously delivered email and the
 * link in it can no longer be used.
 * @param sender Component requesting for the action
 * @param invitationId The invitation id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function revokeOrgClientGroupInvitation(
    sender: ActionSender,
    invitationId: string,
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.RevokeOrgClientGroupInvitationAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
      ActionTypes.RevokeOrgClientGroupInvitationAction,
      ClientGroupInvitation
  >(
      sender,
      ActionTypes.REVOKE_ORG_CLIENT_GROUP_INVITATION,
      async () => await idpApi.revokeOrganizationClientGroupInvitation(orgIdOrDefault, invitationId),
      (type, invitation) => ({
        type,
        invitation: forceUndefined(invitation),
        orgId: orgIdOrDefault
      })
  );
}

export function deleteOrgClientGroupInvitation(
    sender: ActionSender,
    invitationId: string,
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.DeleteOrgClientGroupInvitationAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.DeleteOrgClientGroupInvitationAction, void>(
      sender,
      ActionTypes.DELETE_ORG_CLIENT_GROUP_INVITATION,
      async () => await idpApi.deleteOrganizationClientGroupInvitation(invitationId, orgIdOrDefault),
      type => ({
        type,
        invitationId: invitationId,
        orgId: orgIdOrDefault
      })
  );
}

/**
 * Lists all organization client group invitations of an organization.
 * @param sender Component requesting for the action
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function listOrgClientGroupInvitations(
  sender: ActionSender,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListOrgClientGroupInvitationsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.ListOrgClientGroupInvitationsAction,
    ClientGroupInvitation[]
  >(
    sender,
    ActionTypes.LIST_ORG_CLIENT_GROUP_INVITATIONS,
    async () =>
      await idpApi.listOrganizationClientGroupInvitations(
        orgIdOrDefault
      ),
    (type, invitations) => ({
      type,
      invitations: forceUndefined(invitations),
      orgId: orgIdOrDefault
    })
  );
}
//

export function createAndSendOrgClientGroupInvitation(
    sender: ActionSender,
    invitation: ClientGroupInvitation,
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.CreateAndSendOrgClientGroupInvitationAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
      ActionTypes.CreateAndSendOrgClientGroupInvitationAction,
      ClientGroupInvitation
  >(
      sender,
      ActionTypes.CREATE_AND_SEND_ORG_CLIENT_GROUP_INVITATION,
      async () =>
          await idpApi.createAndSendOrganizationClientGroupInvitation(
              orgIdOrDefault,
              invitation
          ),
      (type, inv) => ({
        type,
        invitation: forceUndefined(inv),
        orgId: orgIdOrDefault
      })
  );
}

/**
 * Sends the invitation by email to the recipient so the recipient can can
 * accept or decline the invitation.
 * @param sender Component requesting for the action
 * @param invitationId The invitation id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function sendOrgClientGroupInvitation(
    sender: ActionSender,
    invitationId: string,
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.SendOrgClientGroupInvitationAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
      ActionTypes.SendOrgClientGroupInvitationAction,
      ClientGroupInvitation
  >(
      sender,
      ActionTypes.SEND_ORG_CLIENT_GROUP_INVITATION,
      async () => await idpApi.sendOrganizationClientGroupInvitation(invitationId, ensureSelectedOrgId(orgId)),
      (type, invitation) => ({
        type,
        invitation: forceUndefined(invitation),
        orgId: orgIdOrDefault
      })
  );
}

export function getOrganizationClientGroupInvitation(
    sender: ActionSender,
    invitationId: string,
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.GetOrgClientGroupInvitationAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
      ActionTypes.GetOrgClientGroupInvitationAction,
      ClientGroupInvitation
  >(
      sender,
      ActionTypes.GET_ORG_CLIENT_GROUP_INVITATION,
      async () =>
          await idpApi.getOrganizationClientGroupInvitation(
              orgIdOrDefault,
              invitationId
          ),
      (type, invitation) => ({
        type,
        invitation: forceUndefined(invitation),
        orgId: orgIdOrDefault
      })
  );
}
