import { useIntl } from "react-intl";
import { StatusBadge } from "@10duke/dukeui";
import "./group-type-badge-view.scss";

//<editor-fold desc="Props">
export interface GroupTypeBadgeProps {
  type: "employees" | "licenseConsumers" | string | undefined;
}
//</editor-fold>

function GroupTypeBadge(props: GroupTypeBadgeProps) {
  //<editor-fold desc="Local variables">
  const intl = useIntl();
  let { type } = props;
  const d: any = {};
  d.label = type || "";
  if (type === "employees") {
    d.tip = intl.formatMessage(
      {
        defaultMessage:
          "{value}: The employees group is where organization members belong.",
        description: "tooltip content for group type employees. 'value' = type",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
      }
    );
    d.badgeStyle = "group employees";
  } else if (type === "licenseConsumers") {
    d.tip = intl.formatMessage(
      {
        defaultMessage: "{value}: A custom group type.",
        description:
          "tooltip content for group type licenseConsumers. 'value': type",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
      }
    );
    d.badgeStyle = "group licenseConsumers";
  } else {
    d.tip = intl.formatMessage(
      {
        defaultMessage: "{value}: A custom group type.",
        description:
          "Tooltip content for custom group types. Custom groups can be used freely for organizing users into groups. 'value' = type",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
      }
    );
    d.badgeStyle = "group other";
  }
  return <StatusBadge item={d} />;
}
export default GroupTypeBadge;
