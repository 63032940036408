import { useEffect, HTMLAttributes, useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {FeedbackEntry, Feedback, ConfirmModal} from "@10duke/dukeui";
import { User } from "../../../model/User";
import UserUtils from "../../../utils/user";
import { OrganizationRole } from "../../../model/OrganizationRole";
import {
  AddOrgRolesForUserAction,
  ClearErrorAction,
  GetUserAction,
  isAddErrorAction,
  RemoveOrgRolesOfUserAction,
} from "../../../actions/actionTypes";
import Table from "../../table/table-container";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import { ModalKeys } from "../../pages/roles";
import RoleTypeBadge from "../../badges/role-type-badge";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import { Form } from "react-bootstrap";
import { ClosableModalProps } from "../closable-modal-props";
import NavigateAfterAction from "../../navigate-after-action";
import { UserValues } from "../../../localization/user";
import { OrganizationRoleLabels } from "../../../localization/organization-role";
import {hasAction} from "../../../ui-configuration/configuration-tools";

//<editor-fold desc="Props">

const resolveUserName = (
  user: User | undefined | null,
  intl: { formatMessage: (v: any, v2?: any) => string }
) =>
  UserUtils.resolveDisplayName(
    user,
    intl.formatMessage(UserValues.displayName.undefined)
  );

export interface ManageUsersRolesModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}

export interface ManageUsersRolesModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {
  onShowFeedback: (feedback: FeedbackEntry | FeedbackEntry[]) => void;
}
export interface ManageUsersRolesModalInputProps
  extends ManageUsersRolesModalVisibilityProps {
  userId?: string;
}
export interface ManageUsersRolesModalStateProps {
  selected: OrganizationRole[];
  onSetSelected: (selection: OrganizationRole[]) => void;
  rolesToAdd: OrganizationRole[];
  rolesToRemove: OrganizationRole[];
  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;
}
export interface ManageUsersRolesModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  actorId: string;
  user?: User | null;
  onLoadUser?: (id: string) => Promise<GetUserAction>;

  roles?: OrganizationRole[];
  onLoadRoles?: () => void;

  orgAdminRoleId?: string;

  usersRoles?: string[];
  onLoadUsersRoles?: (id: string) => void;

  onAddRoles?: (
    roles: string[],
    usrId: string
  ) => Promise<AddOrgRolesForUserAction>;
  onRemoveRoles?: (
    roles: string[],
    usrId: string
  ) => Promise<RemoveOrgRolesOfUserAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface ManageUsersRolesModalProps
  extends ManageUsersRolesModalDOMProps,
    ManageUsersRolesModalInputProps,
    ManageUsersRolesModalStateProps,
    ManageUsersRolesModalDataProps {}
//</editor-fold>

function ManageUsersRolesModal(props: ManageUsersRolesModalProps) {
  //<editor-fold desc="Local variables">
  let {
    user,
    userId,
    actorId,
    onLoadUser,
    roles,
    onLoadRoles,
    onAddRoles,
    onRemoveRoles,
    usersRoles,
    onLoadUsersRoles,
    show,
    onClose,
    onShowFeedback,
    rolesToAdd,
    rolesToRemove,
    orgAdminRoleId,
    onSetSelected,
    selected,
    activeSearch,
    onSetActiveSearch,
    isReady,
    onExited,
    onClearError,
  } = props;
  if (show && !onAddRoles) {
    throw new Error(
      "ManageUsersRolesModal: Required props missing. The onAddRoles is required"
    );
  }
  if (show && !onRemoveRoles) {
    throw new Error(
      "ManageUsersRolesModal: Required props missing. The onRemoveRoles is required"
    );
  }
  if (show && user === undefined && (!userId || !onLoadUser)) {
    throw new Error(
      "ManageUsersRolesModal: Required props missing. The onLoadUser is required with userId"
    );
  }
  if (show && !roles && !onLoadRoles) {
    throw new Error(
      "ManageUsersRolesModal: Required props missing. Either roles or onLoadRoles must be provided"
    );
  }
  if (show && !usersRoles && !onLoadUsersRoles) {
    throw new Error(
      "ManageUsersRolesModal: Required props missing. Either usersRoles or onLoadUsersRoles must be provided"
    );
  }
  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>

  const userObjId = user ? user.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!onLoadUser &&
      !!userId &&
      (userObjId === undefined || (!!userObjId && userObjId !== userId))
    ) {
      onLoadUser(userId).then((res) => {
        if (!userObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, onLoadUser, userId, userObjId, onClearError]);

  useEffect(() => {
    if (!!show && !roles && !!onLoadRoles) {
      onLoadRoles();
    }
  }, [show, roles, onLoadRoles]);

  useEffect(() => {
    if (!!show && !!userObjId && !!onLoadUsersRoles) {
      onLoadUsersRoles(userObjId as string);
    }
  }, [show, userObjId, onLoadUsersRoles]);

  const { conf } = useContext(UIConfiguration);
  const roleConf =
    conf.functionality && conf.functionality.roles
      ? conf.functionality.roles
      : {};
  return (
    <ConfirmModal
      onExited={onExited}
      onReloadData={() => {
        if (onLoadRoles) {
          onLoadRoles();
        }
        if (userId && onLoadUsersRoles) {
          onLoadUsersRoles(userId);
        }
        if (userId && onLoadUser) {
          onLoadUser(userId).then((res) => {
            if (!user && isAddErrorAction(res)) {
              // only clear error if no data exists, leave if previous data is still available and
              // let the api error notification handler show error
              onClearError(res.error?.errorId);
            }
          });
        }
      }}
      isReady={isReady}
      data-test-manage-users-roles-modal={user ? user.id : true}
      title={
        user
          ? intl.formatMessage(
              {
                defaultMessage: "{name}: roles",
                description: "modal heading. 'name' = name of the user",
              },
              {
                name: resolveUserName(user, intl),
              }
            )
          : intl.formatMessage({
              defaultMessage: "User not found",
              description: "modal heading when user not found",
            })
      }
      confirmTitle={intl.formatMessage(
        {
          defaultMessage: "{name}: confirm roles",
          description:
            "modal heading for confirming action. 'name' = name of the user",
        },
        {
          name: resolveUserName(user, intl),
        }
      )}
      confirmContent={
        <>
          <p data-test-confirm-changes-message>
            <FormattedMessage
              defaultMessage="Are you sure you wish to apply the following changes to the role memberships?"
              description={"Confirm action message"}
            />
          </p>
          {rolesToRemove.length > 0 && (
            <>
              {orgAdminRoleId &&
                rolesToRemove.findIndex((val) => val.id === orgAdminRoleId) >=
                  0 && (
                  <Feedback type={"warning"} show={true} asChild={true}>
                    <p data-test-orgadmin-role-removal-warning>
                      <FormattedMessage
                        defaultMessage="Please note that removing the admin role from a user will restrict their access across connected services."
                        description="warning to be shown when removing the admin role"
                      />
                    </p>
                  </Feedback>
                )}
              <strong>
                <FormattedMessage
                  defaultMessage="Roles to remove"
                  description={"heading for summary of roles to remove"}
                />
              </strong>
              <ul>
                {rolesToRemove.map((r) => (
                  <li key={"toRemove" + (r.id as string)}>{r.name}</li>
                ))}
              </ul>
            </>
          )}
          {rolesToAdd.length > 0 && (
            <>
              <strong>
                <FormattedMessage
                  defaultMessage="Roles to add"
                  description={"heading for summary of roles to add"}
                />
              </strong>
              <ul>
                {rolesToAdd.map((r) => (
                  <li key={"toAdd" + (r.id as string)}>{r.name}</li>
                ))}
              </ul>
            </>
          )}
        </>
      }
      acceptButton={{
        label: intl.formatMessage({
          defaultMessage: "Yes",
          description: "confirm action button label",
        }),
      }}
      cancelButton={{
        label: intl.formatMessage({
          defaultMessage: "No",
          description: "cancel confirmation button label",
        }),
      }}
      show={show}
      onClose={onClose}
      skipConfirm={!user}
      backdrop={
        rolesToAdd.length > 0 || rolesToRemove.length > 0 ? "static" : true
      }
      primaryButton={{
        label: !user
          ? intl.formatMessage({
              defaultMessage: "Close",
              description: "close button label",
            })
          : intl.formatMessage({
              defaultMessage: "Apply",
              description: "primary button label",
            }),
        disabled:
          !!user && rolesToAdd.length === 0 && rolesToRemove.length === 0,
        tooltip:
          !!user && rolesToAdd.length === 0 && rolesToRemove.length === 0
            ? intl.formatMessage({
                defaultMessage: "There no changes to apply.",
                description: "tooltip for disabled primary button",
              })
            : undefined,
      }}
      onPrimaryAction={() => {
        if (show && user) {
          if (onAddRoles && onRemoveRoles) {
            const actions = [];
            if (rolesToAdd.length) {
              actions.push(
                onAddRoles(
                  rolesToAdd.map((val) => val.id as string),
                  user.id as string
                ).catch((error) => error)
              );
            }
            if (rolesToRemove.length) {
              actions.push(
                onRemoveRoles(
                  rolesToRemove.map((val) => val.id as string),
                  user.id as string
                ).catch((error) => error)
              );
            }
            if (actions.length) {
              // should aways come here
              Promise.all(actions).then(
                (res) => {
                  const feedback: FeedbackEntry[] = [];
                  let addResult;
                  let removeResult;
                  if (rolesToAdd.length) {
                    addResult = res[0];
                    if (rolesToRemove.length) {
                      removeResult = res[1];
                    }
                  } else if (rolesToRemove.length) {
                    removeResult = res[0];
                  } else {
                    throw Error("This should never happen");
                  }
                  if (addResult && addResult.error) {
                    onClearError(addResult.error?.errorId);
                    feedback.push({
                      id:
                        "manageUsersRoles_add_" +
                        (user ? (user.id as string) : ""),
                      msg: intl.formatMessage(
                        {
                          defaultMessage: "{name}: Adding roles failed.",
                          description:
                            "Failure notification for adding roles. 'name' = name of the user",
                        },
                        {
                          name:
                            "<strong>" +
                            resolveUserName(user, intl) +
                            "</strong>",
                        }
                      ),
                      type: "danger",
                    });
                  } else if (addResult) {
                    feedback.push({
                      id:
                        "manageUsersRoles_add_" +
                        (user ? (user.id as string) : ""),
                      msg: intl.formatMessage(
                        {
                          defaultMessage:
                            "{name}: {count} {count, plural, one {role} other {roles}} added.",
                          description:
                            "Success notification for adding roles. 'name' = name of the user, 'count' = amount of roles added",
                        },
                        {
                          name:
                            "<strong>" +
                            resolveUserName(user, intl) +
                            "</strong>",
                          count: rolesToAdd.length,
                        }
                      ),
                      autoClose: true,
                      type: "success",
                    });
                  }
                  if (removeResult && removeResult.error) {
                    onClearError(removeResult.error?.errorId);
                    feedback.push({
                      id:
                        "manageUsersRoles_remove_" +
                        (user ? (user.id as string) : ""),
                      msg: intl.formatMessage(
                        {
                          defaultMessage: "{name}: Removing roles failed.",
                          description:
                            "Failure notification for removing roles. 'name' = name of the user",
                        },
                        {
                          name:
                            "<strong>" +
                            resolveUserName(user, intl) +
                            "</strong>",
                        }
                      ),
                      type: "danger",
                    });
                  } else if (removeResult) {
                    feedback.push({
                      id:
                        "manageUsersRoles_remove_" +
                        (user ? (user.id as string) : ""),
                      msg: intl.formatMessage(
                        {
                          defaultMessage:
                            "{name}: {count} {count, plural, one {role} other {roles}} removed.",
                          description:
                            "Success notification for adding roles. 'name' = name of the user, 'count' = amount of roles removed.",
                        },
                        {
                          name:
                            "<strong>" +
                            resolveUserName(user, intl) +
                            "</strong>",
                          count: rolesToRemove.length,
                        }
                      ),
                      autoClose: true,
                      type: "success",
                    });
                  }
                  if (feedback.length) {
                    onShowFeedback(feedback);
                  }
                },
                (rej) => {
                  throw new Error("Should not happen");
                }
              );
            }
          }
        }
        onClose();
      }}
      secondaryButton={
        !!user
          ? {
              label: intl.formatMessage({
                defaultMessage: "Cancel",
                description: "secondary button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={onClose}
    >
      {user && (
        <>
          <Table<OrganizationRole>
            disableLoadingIndicator={!isReady}
            maxRows={15}
            compact={true}
            header={
              <Form.Label>
                <FormattedMessage
                  defaultMessage="Select roles"
                  description={"field label"}
                />
              </Form.Label>
            }
            data-test-select-role
            search={roles && roles.length > TABLE_SEARCH_THRESHOLD}
            activeSearch={activeSearch}
            onSearch={onSetActiveSearch}
            columnToggle={false}
            reset={false}
            data={roles ? roles : isReady ? [] : undefined}
            pagination={false}
            identifyingColumn={"id"}
            selection={{
              multi: true,
              selectAll: true,
              disabledFor:
                actorId === (user ? (user.id as string) : false) &&
                orgAdminRoleId &&
                usersRoles &&
                usersRoles.includes(orgAdminRoleId)
                  ? [orgAdminRoleId]
                  : [],
            }}
            onSelectionChanged={onSetSelected}
            selected={selected}
            columns={[
              {
                key: "id",
                label: intl.formatMessage(OrganizationRoleLabels.id),
                isTechnical: true,
                hidden: true,
              },
              {
                key: "name",
                label: intl.formatMessage({
                  defaultMessage: "Role",
                  description: "column heading for the roles to select",
                }),
                sortable: true,
                renderer: (props: {
                  cell: any;
                  row: any;
                  rowIndex: Number;
                  rendererData: any;
                }) => {
                  return (
                    <>
                      <span
                        key={"link" + props.row.id}
                        className={"link-holder"}
                        data-test-role={props.row.id}
                      >
                        {hasAction(roleConf.rowActions, 'show') ? (
                          <NavigateAfterAction
                            href={
                              "/roles/" + props.row.id + "/" + ModalKeys.show
                            }
                            action={onClose}
                          >
                            {props.cell}
                          </NavigateAfterAction>
                        ) : props.cell}
                      </span>
                      <RoleTypeBadge type={props.row.type} />
                    </>
                  );
                },
                tipRenderer: (props: {
                  cell: any;
                  row: any;
                  rowIndex: Number;
                  rendererData: any;
                }) => {
                  return (
                    <>
                      {actorId === (user ? (user.id as string) : false) &&
                      orgAdminRoleId ===
                        (props.row.id ? (props.row.id as string) : false) &&
                      usersRoles &&
                      usersRoles.includes(props.row.id as string) ? (
                        intl.formatMessage({
                          defaultMessage:
                            "You're not allowed to remove yourself from the admin role.",
                          description: "tooltip for disabled self removal",
                        })
                      ) : (
                        <FormattedMessage
                          defaultMessage="Click to select or deselect."
                          description="tooltip for toggling role selection"
                        />
                      )}
                    </>
                  );
                },
              },
            ]}
          />
        </>
      )}
      {isReady && !user && (
        <Feedback type={"danger"} show={true} asChild={true}>
          <p>
            <FormattedMessage
              defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
              description="message to be shown when there is no user to display"
            />
          </p>
        </Feedback>
      )}
    </ConfirmModal>
  );
}

export default ManageUsersRolesModal;
