import { User } from "../../../model/User";
import { ORG_1_USERS } from "./org1Members";
import { ORG_2_USERS } from "./org2Members";
import { ORGLESS_USERS } from "./orglessUsers";
import { APP_ORGS } from "./appOrganizations";

export const ALL_USERS: User[][] = [ORG_1_USERS, ORG_2_USERS, ORGLESS_USERS];

export const ALL_USERS_BY_ORG_ID = {
  [APP_ORGS[0].id as string]: ORG_1_USERS,
  [APP_ORGS[1].id as string]: ORG_2_USERS
};
