import { connect } from "react-redux";
import OrganizationUtils from "../../../utils/organization";
import Invitations, {
  InvitationsDOMProps,
  InvitationsProps as _InvitationsProps,
  InvitationsModalVisibilityProps
} from "./invitations-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../../util/typeUtil";
import {
  listOrganizationGroups,
  listOrganizationRoles,
  listOrganizationsOrganizationGroupInvitations,
  listOrgClientGroupInvitations, listOrganizationClientGroups
} from "../../../actions";
import { ActionSender } from "../../../model/ActionSender";
import AuthenticationUtils from "../../../utils/authentication";
import { addSenderArgument } from "../../../actions/actionHelpers";
import {UIInvitation} from "./invitations-view";
import {populateExpiredStatus} from "../../../utils/invitation";
import {populateDeviceClientInvitationExpiredStatus} from "../../../utils/deviceClientGroupInvitation";

export { ModalKeys } from "./invitations-container";


export interface InvitationsProps
  extends InvitationsDOMProps,
    InvitationsModalVisibilityProps {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_InvitationsProps, InvitationsProps>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _InvitationsProps,
  InvitationsProps
>;

const sender: ActionSender = { key: "invitations" };

function mapStateToProps(state: AppState, ownProps: InvitationsProps): ReduxStateProps {
  let invitations = ownProps.enableUsers ? OrganizationUtils.selectOrganizationInvitations(state)?.map((m) => {
    return {
      ...populateExpiredStatus(m),
      invitationType: "user",
    } as UIInvitation;
  }) : undefined;
  if (ownProps.enableClients) {
    const cInv = OrganizationUtils.selectOrganizationClientInvitations(state)?.map((m) => {
      return {
        ...populateDeviceClientInvitationExpiredStatus(m),
        invitationType: "client",
      } as UIInvitation;
    });
    if (((!!invitations || !ownProps.enableUsers)) && !!cInv) {
      invitations = (invitations || []).concat(...cInv);
    } else if (!invitations || !cInv) {
      // loading is with a single trigger, so both must be returned before we can proceed to ensure all data is loaded
      invitations = undefined;
    }
  }
  if (invitations && invitations.length) {
    // match the default sort order after combining the device & user results
    invitations = invitations
        .sort((a, b) => {
          if (a.email < b.email) {
            return -1;
          }
          if (a.email > b.email) {
            return 1;
          }
          return 0;
        })
    ;
  }
  return {
    invitations: invitations,
    groups: OrganizationUtils.selectOrganizationGroups(state),
    clientGroups: ownProps.enableClients ? OrganizationUtils.selectOrganizationClientGroups(state) : [],
    userName: AuthenticationUtils.selectAuthenticationUserDisplayName(state),
    userId: AuthenticationUtils.selectAuthenticationUserId(state),
    orgAdminRoleId: OrganizationUtils.selectOrgAdminRoleId(state)
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    AuthenticationUtils.compareAuthenticationUserDisplayNameStates(
      next,
      prev
    ) &&
    AuthenticationUtils.compareAuthenticationUserIdStates(next, prev) &&
    OrganizationUtils.compareOrganizationRolesStates(next, prev) &&
    OrganizationUtils.compareOrganizationGroupsStates(next, prev) &&
    OrganizationUtils.compareOrganizationInvitationsStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientInvitationsStates(next, prev)
  );
}
const dispatchActions: ReduxDispatchProps = {
  onLoadOrganizationRoles: addSenderArgument(sender, listOrganizationRoles),
  onLoadInvitations: addSenderArgument(
      sender,
      listOrganizationsOrganizationGroupInvitations
  ),
  onLoadDeviceClientGroupInvitations: addSenderArgument(
      sender,
      listOrgClientGroupInvitations
  ),
  onLoadGroups: addSenderArgument(sender, listOrganizationGroups),
  onLoadClientGroups: addSenderArgument(sender, listOrganizationClientGroups)
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  InvitationsProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual
})(Invitations);
