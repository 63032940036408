import { connect } from "react-redux";
import SelectOrganization, {
  SelectOrganizationProps as _SelectOrganizationProps,
  SelectOrganizationDOMProps
} from "./select-organization-view";
import { AppState } from "../../../store/AppState";
import { setOrg } from "../../../actions";
import OrganizationUtils from "../../../utils/organization";
import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../../util/typeUtil";
import InProgressUtils from "../../../utils/in-progress";

export interface SelectOrganizationInputProps {}
export interface SelectOrganizationDispatchProps {}
// Own props
interface SelectOrganizationProps extends SelectOrganizationDOMProps {}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _SelectOrganizationProps,
  SelectOrganizationProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _SelectOrganizationProps,
  SelectOrganizationProps
>;

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    selectedOrganization: OrganizationUtils.selectSelectedOrganization(state),
    organizations: OrganizationUtils.selectAppOrganizations(state) || [],
    disableOrganizationSelect: InProgressUtils.selectInProgressLength(state) > 0
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareSelectedOrganizationIdStates(next, prev) &&
    OrganizationUtils.compareAppOrganizationsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onSelectOrganization: setOrg
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  SelectOrganizationProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual
})(SelectOrganization);
