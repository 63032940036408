import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Collapse } from "react-bootstrap";
import idpInfo from "../../../gen/api/idp/info.json";
import entInfo from "../../../gen/api/entitlement/info.json";
import "./api-version-error-screen-view.scss";
import {IconLibrary, Button, Screen} from "@10duke/dukeui";
import logo from "../../logo";
//<editor-fold desc="Messages">
//</editor-fold>

//<editor-fold desc="Props">
export interface APIVersionErrorScreenProps {
  /**
   * Detected invalid API information, or null for valid APIs
   * [0] = Idp api
   * [1] = Ent api
   */
  apis?: ({ version: string; [key: string]: any } | null)[];
  showError: boolean;
  onShowError: (b: boolean) => void;
}

//</editor-fold>
const IncludeInJSONOutput = ["title", "version"];
export default function APIVersionErrorScreen(
  props: APIVersionErrorScreenProps
) {
  const { apis, showError, onShowError } = props;
  const intl = useIntl();
  return (
      <Screen
          id={"api-version-error-screen"}
          data-test-api-version-error-screen
          meta={{
              title: intl.formatMessage({
                  defaultMessage: "Detected API version not supported",
                  description: "heading for the screen",
              }),
              description: intl.formatMessage({
                  defaultMessage:
                      "The detected API version is not supported by this client",
                  description: "window meta description for the screen",
              }),
          }}
          header={<img src={logo} alt={""} title={""} className={'logo'}/>}
      >

          <FontAwesomeIcon
              icon={IconLibrary.icons.faExclamationTriangle}
              className={"icon"}
              size="4x"
          />
          <h1>
              <FormattedMessage
                  defaultMessage="Oh no!"
                  description={"API version error screen title"}
              />
          </h1>
          <p>
              <FormattedMessage
                  defaultMessage="The detected API version is not supported. Please contact administrator."
                  description={
                      "API version error screen message. The administrator here is not the same as the 'admin' used elsewhere, different translation is preferred. This refers to a person responsible for deploying and managing this application."
                  }
              />
          </p>
          {apis && apis.length && (
              <div className={"details"}>
                  <Button
                      className={"btn btn-block custom-base" + (showError ? " active" : "")}
                      data-test-error-details-trigger
                      action={(e: any) => {
                          onShowError(!showError);
                      }}
                  >
                      <FormattedMessage
                          defaultMessage="{isVisible, select, true {Hide} false {Show} other {Toggle} } technical error details"
                          description={
                              "Toggle error details button label. 'isVisible' = state of target"
                          }
                          values={{isVisible: showError}}
                      />
                  </Button>
                  <Collapse in={showError} data-test-error-details>
                      <div className={"collapsible-target"}>
                          {apis.map((err, ind) => {
                              if (err !== null) {
                                  return (
                                      <div className={"api"} key={"api_" + ind}>
                                          <h2 className={"h5"}>
                                              <FormattedMessage
                                                  defaultMessage="Detected"
                                                  description={"Heading for detected api info"}
                                              />
                                          </h2>
                                          <pre>
                        <code>
                          {JSON.stringify(err, IncludeInJSONOutput, 2)}
                        </code>
                      </pre>
                                          <h2 className={"h5"}>
                                              <FormattedMessage
                                                  defaultMessage="Required"
                                                  description={"Heading for required api info"}
                                              />
                                          </h2>
                                          <pre>
                        <code>
                          {JSON.stringify(
                              ind === 0 ? idpInfo : entInfo,
                              IncludeInJSONOutput,
                              2
                          )}
                        </code>
                      </pre>
                                      </div>
                                  );
                              } else {
                                  return undefined;
                              }
                          })}
                      </div>
                  </Collapse>
              </div>
          )}
      </Screen>
  );
}
