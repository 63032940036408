import { useState } from "react";
import { FeedbackContainerProps,withFeedback } from "@10duke/dukeui";
import UsersView, {
  UsersDOMProps as _UsersDOMProps,
  UsersModalVisibilityProps as _UsersModalVisibilityProps, UsersDataProps,
} from "./users-view";
import { UserWithStatus } from "../../../utils/user";
export { ModalKeys } from "./users-view";

export type UsersDOMProps = _UsersDOMProps;
export type UsersModalVisibilityProps = _UsersModalVisibilityProps;

export interface UsersProps extends UsersDataProps,
    UsersDOMProps,
    UsersModalVisibilityProps {};

function Users(props: UsersProps & FeedbackContainerProps) {
  const [selected, setSelected] = useState([] as UserWithStatus[]);
  return <UsersView
      selected={selected}
      onSetSelected={setSelected}
      {...props} />;
}
export default withFeedback<UsersProps & FeedbackContainerProps>(Users);
