import * as ActionTypes from "../actions/actionTypes";
import {OrgClientGroupClientIdsByOrgClientGroupId} from "../store/ClientGroupState";

export default function orgClientGroupClientIds(
  state: OrgClientGroupClientIdsByOrgClientGroupId,
  action: ActionTypes.AppAction
): OrgClientGroupClientIdsByOrgClientGroupId | null {
  const currentState = state || ({} as OrgClientGroupClientIdsByOrgClientGroupId);

  switch (action.type) {
    case ActionTypes.LIST_ORG_CLIENTS_IN_ORG_CLIENT_GROUP: {
      const listClientsInClientGroup =
          action as ActionTypes.ListOrgClientsInOrgClientGroupAction;
      const clientIds = listClientsInClientGroup.clients.map(
          (client) => client.id as string
      );
      return { ...currentState, [listClientsInClientGroup.groupId]: clientIds };
    }
    case ActionTypes.ADD_CLIENT_GROUPS_FOR_CLIENT: {
      const addClientGroupsForClient =
          action as ActionTypes.AddClientGroupsForClientAction;
      return addClients(currentState, addClientGroupsForClient.clientGroupIds, [addClientGroupsForClient.clientId]);
    }
    case ActionTypes.ADD_CLIENTS_TO_CLIENT_GROUP: {
      const addClientsToClientGroup = action as ActionTypes.AddClientsToClientGroupAction;
      return addClients(currentState, [addClientsToClientGroup.clientGroupId], addClientsToClientGroup.clientIds);
    }
    case ActionTypes.REMOVE_CLIENTS_FROM_CLIENT_GROUP: {
      const removeClientsFromClientGroup = action as ActionTypes.RemoveClientsFromClientGroupAction;
      return removeClients(currentState, [removeClientsFromClientGroup.clientGroupId], removeClientsFromClientGroup.clientIds);
    }
    case ActionTypes.REMOVE_CLIENT_GROUPS_OF_CLIENT: {
      const removeClientGroupsOfClient =
          action as ActionTypes.RemoveClientGroupsOfClientAction;
      return removeClients(currentState, removeClientGroupsOfClient.clientGroupIds, [removeClientGroupsOfClient.clientId]);
    }
    case ActionTypes.DELETE_ORG_CLIENT:
      const deleteClient = action as ActionTypes.DeleteOrgClientAction;

      const groups = Object.keys(currentState);
      const newState: OrgClientGroupClientIdsByOrgClientGroupId = {};
      for (let i = 0; i < groups.length; i += 1) {
        newState[groups[i]] = currentState[groups[i]]?.filter((f) => f !== deleteClient.clientId)
      }
      return newState;
    case ActionTypes.START_AUTHN:
      return null;
    default:
      return state || null;
  }
}
function addClients(currentState: OrgClientGroupClientIdsByOrgClientGroupId, clientGroupIds: string [], clientIds: string[]): OrgClientGroupClientIdsByOrgClientGroupId {
  const afterAdd: OrgClientGroupClientIdsByOrgClientGroupId = {};
  clientGroupIds.forEach((cgid) => {
    afterAdd[cgid] = (currentState[cgid] || []).concat(clientIds).filter(
        (clientId, i, arr) => arr.indexOf(clientId) === i
    );
  });
  return {
    ...currentState,
    ...afterAdd,
  };
}
function removeClients(currentState: OrgClientGroupClientIdsByOrgClientGroupId,  clientGroupIds: string [], clientIds: string[]): OrgClientGroupClientIdsByOrgClientGroupId {
  const afterRemove: OrgClientGroupClientIdsByOrgClientGroupId = {};
  clientGroupIds.forEach((cgid) => {
    afterRemove[cgid] = (currentState[cgid] || []).filter(
        (clientId) => !clientIds.includes(clientId)
    );
  });
  return {
    ...currentState,
    ...afterRemove,
  };
}
