import { defineMessages } from "react-intl";
import { UserLabels, UserValues } from "./user";

/**
 * Localized labels for UserWithStatus fields
 */
export const UserWithStatusLabels = {
  ...UserLabels,
  ...defineMessages({
    status: {
      defaultMessage: "status",
      description: "Field label for user's status (UserWithStatus.status)"
    }
  })
};

/**
 * Localized values for UserWithStatus fields
 */
export const UserWithStatusValues = {
  // no extension needed for now
  ...UserValues,
  status: defineMessages({
    suspended: {
      defaultMessage: "suspended",
      description:
        "status value for suspended users (UserWithStatus.status 'suspended')"
    },
    admin: {
      defaultMessage: "admin",
      description:
        "status value for admin users (UserWithStatus.status 'admin')"
    },
    inactive: {
      defaultMessage: "inactive",
      description:
        "status value for inactive users (UserWithStatus.status 'inactive')"
    },
    member: {
      defaultMessage: "active",
      description:
        "status value for active users (UserWithStatus.status 'member')"
    }
  })
};
