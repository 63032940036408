import { APP_ORG_1, APP_ORG_2 } from "./appOrganizations";
import { ORG_1_ENTITLEMENTS } from "./org1Entitlements";
import { ORG_2_ENTITLEMENTS } from "./org2Entitlements";
import { ORG_1_GROUP_IDS_BY_ENTITLEMENT_ID } from "./org1Entitlements";
import { ORG_2_GROUP_IDS_BY_ENTITLEMENT_ID } from "./org2Entitlements";

export const ORG_ENTITLEMENTS = {
  [APP_ORG_1.id as string]: ORG_1_ENTITLEMENTS,
  [APP_ORG_2.id as string]: ORG_2_ENTITLEMENTS
};

export const ORG_GROUP_IDS_BY_ORGANIZATION_ID_AND_BY_ENTITLEMENT_ID = {
  [APP_ORG_1.id as string]: ORG_1_GROUP_IDS_BY_ENTITLEMENT_ID,
  [APP_ORG_2.id as string]: ORG_2_GROUP_IDS_BY_ENTITLEMENT_ID
};
