import * as ActionTypes from "../actions/actionTypes";
import {ClientGroupsById} from "../store/ClientGroupState";

export default function clientGroups(
  state: ClientGroupsById,
  action: ActionTypes.AppAction
): ClientGroupsById | null {
  const currentState = state || ({} as ClientGroupsById);
  switch (action.type) {
    case ActionTypes.LIST_ORG_CLIENT_GROUPS:
      const listOrgClientGroups = action as ActionTypes.ListOrgClientGroupsAction;
      const newOrgClientGroupsById = listOrgClientGroups.clientGroups.reduce<ClientGroupsById>(
        (map, clientGroup) => {
          map[clientGroup.id as string] = clientGroup;
          return map;
        },
        {}
      );
      return { ...currentState, ...newOrgClientGroupsById };
    case ActionTypes.CREATE_ORG_CLIENT_GROUP:
      const createOrgGroup = action as ActionTypes.CreateOrgClientGroupAction;
      return {
        ...currentState,
        [createOrgGroup.clientGroup.id as string]: createOrgGroup.clientGroup
      };
    case ActionTypes.REPLACE_ORG_CLIENT_GROUP:
      const replaceOrgClientGroup = action as ActionTypes.ReplaceOrgClientGroupAction;
      return {
        ...currentState,
        [replaceOrgClientGroup.group.id as string]: replaceOrgClientGroup.group
      };
    case ActionTypes.GET_ORG_CLIENT_GROUP:
      const getOrgClientGroup = action as ActionTypes.GetOrgClientGroupAction;
      return {
        ...currentState,
        [getOrgClientGroup.group.id as string]: getOrgClientGroup.group
      };
    case ActionTypes.DELETE_ORG_CLIENT_GROUP:
      const delOrgGroup = action as ActionTypes.DeleteOrgClientGroupAction;
      return removeClientGroup(delOrgGroup.orgClientGroupId, currentState);
    case ActionTypes.LIST_CLIENT_GROUPS_OF_CLIENT:
      const listClientsClientGroups = action as ActionTypes.ListClientGroupsOfClientAction;
      const newClientClientGroupsById = listClientsClientGroups.groups.reduce<
          ClientGroupsById
      >((map, group) => {
        map[group.id as string] = group;
        return map;
      }, {});
      return { ...currentState, ...newClientClientGroupsById };
    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

function removeClientGroup(
    clientGroupId: string,
    currentState: ClientGroupsById
): ClientGroupsById {
  const { [clientGroupId]: _, ...remaining } = currentState;
  return remaining;
}

function handleErrorAction(
  currentState: ClientGroupsById,
  action: ActionTypes.AppAction
): ClientGroupsById {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  if (
    errorAction.error.action.type === ActionTypes.GET_ORG_CLIENT_GROUP &&
    errorAction.error.apiError.error === "404"
  ) {
    const typedError = action as ActionTypes.AddErrorAction<
      ActionTypes.GetOrgClientGroupAction
    >;
    finalState = removeClientGroup(
      typedError.error.action?.orgClientGroupId as string,
      currentState
    );
  }

  return finalState;
}
