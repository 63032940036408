import {
  DeleteClientGroupResult,
  DeleteOrganizationGroupResult,
  IdpApi, RemoveClientGroupsOfClientResult,
  RemoveOrgGroupOfUserResult,
  RemoveOrgGroupsOfUserResult,
  RemoveUsersFromOrgGroupResult,
  SetOrgGroupsOfUserResult,
  SetUsersInOrgGroupResult,
} from "../IdpApi";
import { Organization } from "../../model/Organization";
import { User, UserForCreate } from "../../model/User";
import { OrganizationGroup } from "../../model/OrganizationGroup";
import { OrganizationRole } from "../../model/OrganizationRole";
import { OrganizationGroupInvitation } from "../../model/OrganizationGroupInvitation";
import { InternalPermissionWithGrantedActions } from "../../model/InternalPermissionWithGrantedActions";
import { PermissionGrantsForPermission } from "../../model/PermissionGrantsForPermission";
import { InternalPermission } from "../../model/InternalPermission";
import {Client} from "../../model/Client";
import {ClientGroup} from "../../model/ClientGroup";
import {ClientGroupInvitation} from "../../model/ClientGroupInvitation";
/**
 * Wraps another IdpApi implementation, just calling through to methods
 * of the wrapped IpdApi. This enables selectively overriding methods
 * to change / enhance behavior of the API.
 */
export default abstract class IdpApiWrapper implements IdpApi {
  private wrapped: IdpApi;

  constructor(wrapped: IdpApi) {
    this.wrapped = wrapped;
  }

  initialize(initProvider: (name: string) => string): void {
    this.getWrapped().initialize(initProvider);
  }

  protected getWrapped(): IdpApi {
    return this.wrapped;
  }

  getApiInfo(): Promise<{ version: string; [key: string]: any }> {
    return this.getWrapped().getApiInfo();
  }
  public getAppOrganizations(userId?: string): Promise<Organization[]> {
    return this.getWrapped().getAppOrganizations(userId);
  }

  public getOrganization(orgId?: string): Promise<Organization> {
    return this.getWrapped().getOrganization(orgId);
  }

  public replaceOrganization(org: Organization): Promise<Organization> {
    return this.getWrapped().replaceOrganization(org);
  }

  public setMfaRequired(required: boolean, orgId?: string): Promise<void> {
    return this.getWrapped().setMfaRequired(required, orgId);
  }

  public listAllOrganizationUsers(orgId?: string): Promise<User[]> {
    return this.getWrapped().listAllOrganizationUsers(orgId);
  }

  public listOrganizationGroups(orgId?: string): Promise<OrganizationGroup[]> {
    return this.getWrapped().listOrganizationGroups(orgId);
  }

  listOrganizationClients(orgId?: string): Promise<Client[]> {
    return this.getWrapped().listOrganizationClients(orgId);
  }
  listOrganizationClientGroups(orgId?: string): Promise<ClientGroup[]> {
    return this.getWrapped().listOrganizationClientGroups(orgId);
  }
  getOrganizationClientGroup(
      orgClientGroupId: string,
      orgId?: string
  ): Promise<OrganizationGroup> {
    return this.getWrapped().getOrganizationClientGroup(orgClientGroupId, orgId);
  }
  replaceOrganizationClientGroup(organizationId: string, clientGroup: ClientGroup): Promise<ClientGroup> {
    return this.getWrapped().replaceOrganizationClientGroup(organizationId, clientGroup);
  }
  deleteOrganizationClientGroup(
      clientGroupId: string,
      orgId?: string
  ): Promise<DeleteClientGroupResult> {
    return this.getWrapped().deleteOrganizationClientGroup(clientGroupId, orgId);
  }
  getOrganizationClientGroupInvitation(
      invitationId: string,
      orgId?: string
  ): Promise<ClientGroupInvitation> {
    return this.getWrapped().getOrganizationClientGroupInvitation(invitationId, orgId);
  }
  public async deleteOrganizationClientGroupInvitation(invitationId: string, orgId?:string): Promise<void> {
    return this.getWrapped().deleteOrganizationClientGroupInvitation(invitationId, orgId);
  }

  revokeOrganizationClientGroupInvitation(
      invitationId: string,
      orgId?:string
  ): Promise<ClientGroupInvitation> {
    return this.getWrapped().revokeOrganizationClientGroupInvitation(invitationId, orgId);
  }
  sendOrganizationClientGroupInvitation(
      invitationId: string,
      orgId:string
  ): Promise<ClientGroupInvitation> {
    return this.getWrapped().sendOrganizationClientGroupInvitation(invitationId, orgId);
  }
  createOrganizationClientGroup(
      orgId: string,
      clientGroup: ClientGroup): Promise<ClientGroup> {
    return this.getWrapped().createOrganizationClientGroup(orgId, clientGroup);
  }
  public createOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return this.getWrapped().createOrganizationGroup(orgGroup, orgId);
  }

  public replaceOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return this.getWrapped().replaceOrganizationGroup(orgGroup, orgId);
  }

  public getOrganizationGroup(
    orgGroupId: string,
    orgId?: string
  ): Promise<OrganizationGroup> {
    return this.getWrapped().getOrganizationGroup(orgGroupId, orgId);
  }

  public deleteOrganizationGroup(
    orgGroupId: string,
    orgId?: string,
    opts?: {
      cleanUpLicenseReservations?: boolean;
      returnNoMembershipsUserIds?: boolean;
    }
  ): Promise<DeleteOrganizationGroupResult> {
    return this.getWrapped().deleteOrganizationGroup(orgGroupId, orgId, opts);
  }

  public listOrganizationRoles(orgId?: string): Promise<OrganizationRole[]> {
    return this.getWrapped().listOrganizationRoles(orgId);
  }

  addClientsToOrganizationClientGroup(organizationId: string, groupId: string, body: string[]): Promise<void> {
    return this.getWrapped().addClientsToOrganizationClientGroup(organizationId, groupId, body);
  }
  removeClientsFromOrganizationClientGroup(organizationId: string, groupId: string, body: string[]): Promise<void> {
    return this.getWrapped().removeClientsFromOrganizationClientGroup(organizationId, groupId, body);
  }

  public async listClientsInOrganizationClientGroup(organizationId: string, groupId: string): Promise<Client[]> {
    return this.getWrapped().listClientsInOrganizationClientGroup(organizationId, groupId);
  }
  public listOrganizationRolesInOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<OrganizationRole[]> {
    return this.getWrapped().listOrganizationRolesInOrganizationRole(
      orgId as string,
      orgRoleId
    );
  }

  public setOrganizationRolesInOrganizationRole(
    orgId: string,
    orgRoleId: string,
    roles: string[]
  ): Promise<void> {
    return this.getWrapped().setOrganizationRolesInOrganizationRole(
      orgId as string,
      orgRoleId,
      roles
    );
  }

  public createOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    return this.getWrapped().createOrganizationRole(orgRole, orgId);
  }

  public replaceOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    return this.getWrapped().replaceOrganizationRole(orgRole, orgId);
  }

  public getOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<OrganizationRole> {
    return this.getWrapped().getOrganizationRole(orgRoleId, orgId);
  }

  public deleteOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().deleteOrganizationRole(orgRoleId, orgId);
  }

  public getUser(userId: string): Promise<User> {
    return this.getWrapped().getUser(userId);
  }

  public deleteUser(userId: string): Promise<void> {
    return this.getWrapped().deleteUser(userId);
  }

  public replaceUser(user: User): Promise<User> {
    return this.getWrapped().replaceUser(user);
  }

  public setUserSuspended(suspended: boolean, userId: string): Promise<User> {
    return this.getWrapped().setUserSuspended(suspended, userId);
  }

  public deleteOtpCredential(userId: string): Promise<void> {
    return this.getWrapped().deleteOtpCredential(userId);
  }

  public listOrganizationGroupsOfUser(
      userId: string,
      organizationId?: string
  ): Promise<OrganizationGroup[]> {
    return this.getWrapped().listOrganizationGroupsOfUser(userId, organizationId);
  }

  public listClientGroupsOfClient(
      clientId: string,
      organizationId?: string
  ): Promise<ClientGroup[]> {
    return this.getWrapped().listClientGroupsOfClient(clientId, organizationId);
  }

  public listOrganizationRolesOfUser(
    userId: string
  ): Promise<OrganizationRole[]> {
    return this.getWrapped().listOrganizationRolesOfUser(userId);
  }

  public addOrganizationGroupForUser(
    groupId: string,
    userId: string
  ): Promise<void> {
    return this.getWrapped().addOrganizationGroupForUser(groupId, userId);
  }

  public addOrganizationRoleForUser(
    roleId: string,
    userId: string
  ): Promise<void> {
    return this.getWrapped().addOrganizationRoleForUser(roleId, userId);
  }

  public removeOrganizationGroupOfUser(
    groupId: string,
    userId: string
  ): Promise<RemoveOrgGroupOfUserResult> {
    return this.getWrapped().removeOrganizationGroupOfUser(groupId, userId);
  }

  public listUsersInOrganizationGroup(
    groupId: string,
    orgId?: string
  ): Promise<User[]> {
    return this.getWrapped().listUsersInOrganizationGroup(groupId, orgId);
  }

  public addUsersToOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().addUsersToOrganizationGroup(
      groupId,
      userIds,
      orgId
    );
  }

  public setUsersInOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string,
    opts?: {
      cleanUpLicenseReservations?: boolean;
      returnNoMembershipsUserIds?: boolean;
    }
  ): Promise<SetUsersInOrgGroupResult> {
    return this.getWrapped().setUsersInOrganizationGroup(
      groupId,
      userIds,
      orgId,
      opts
    );
  }

  public removeUsersFromOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string,
    opts?: {
      cleanUpLicenseReservations?: boolean;
      returnNoMembershipsUserIds?: boolean;
    }
  ): Promise<RemoveUsersFromOrgGroupResult> {
    return this.getWrapped().removeUsersFromOrganizationGroup(
      groupId,
      userIds,
      orgId,
      opts
    );
  }

  public listOrganizationsOrganizationGroupInvitations(
    orgId?: string
  ): Promise<OrganizationGroupInvitation[]> {
    return this.getWrapped().listOrganizationsOrganizationGroupInvitations(
      orgId
    );
  }

  public getOrganizationsOrganizationGroupInvitation(
    invitationId: string,
    orgId?: string
  ): Promise<OrganizationGroupInvitation> {
    return this.getWrapped().getOrganizationsOrganizationGroupInvitation(
      invitationId,
      orgId
    );
  }

  public createOrganizationGroupInvitation(
    invitation: OrganizationGroupInvitation
  ): Promise<OrganizationGroupInvitation> {
    return this.getWrapped().createOrganizationGroupInvitation(invitation);
  }

  public sendOrganizationGroupInvitation(
    invitationId: string
  ): Promise<OrganizationGroupInvitation> {
    return this.getWrapped().sendOrganizationGroupInvitation(invitationId);
  }

  public revokeOrganizationGroupInvitation(
    invitationId: string
  ): Promise<OrganizationGroupInvitation> {
    return this.getWrapped().revokeOrganizationGroupInvitation(invitationId);
  }

  public deleteOrganizationGroupInvitation(
    invitationId: string
  ): Promise<void> {
    return this.getWrapped().deleteOrganizationGroupInvitation(invitationId);
  }

  public listPermissionsOfOrganizationRole(
    orgRoleId: string
  ): Promise<InternalPermissionWithGrantedActions[]> {
    return this.getWrapped().listPermissionsOfOrganizationRole(orgRoleId);
  }

  public listPermissions(): Promise<InternalPermission[]> {
    return this.getWrapped().listPermissions();
  }

  public addPermissionsForOrganizationRole(
    grants: PermissionGrantsForPermission[],
    orgRoleId: string
  ): Promise<void> {
    return this.getWrapped().addPermissionsForOrganizationRole(
      grants,
      orgRoleId
    );
  }

  public setPermissionsOfOrganizationRole(
    grants: PermissionGrantsForPermission[],
    orgRoleId: string
  ): Promise<void> {
    return this.getWrapped().setPermissionsOfOrganizationRole(
      grants,
      orgRoleId
    );
  }

  public removePermissionsOfOrganizationRole(
    permissionIds: string[],
    orgRoleId: string
  ): Promise<void> {
    return this.getWrapped().removePermissionsOfOrganizationRole(
      permissionIds,
      orgRoleId
    );
  }

  public removePermissionOfOrganizationRole(
    permissionId: string,
    orgRoleId: string
  ): Promise<void> {
    return this.getWrapped().removePermissionOfOrganizationRole(
      permissionId,
      orgRoleId
    );
  }

  public listUsersInOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<User[]> {
    return this.getWrapped().listUsersInOrganizationRole(orgRoleId, orgId);
  }

  public addUsersToOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().addUsersToOrganizationRole(
      orgRoleId,
      userIds,
      orgId
    );
  }

  public getUserInOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<User> {
    return this.getWrapped().getUserInOrganizationRole(
      orgRoleId,
      userId,
      orgId
    );
  }

  public addUserToOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().addUserToOrganizationRole(
      orgRoleId,
      userId,
      orgId
    );
  }

  public removeUsersFromOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().removeUsersFromOrganizationRole(
      orgRoleId,
      userIds,
      orgId
    );
  }

  public removeUserFromOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().removeUserFromOrganizationRole(
      orgRoleId,
      userId,
      orgId
    );
  }

  public setUsersInOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().setUsersInOrganizationRole(
      orgRoleId,
      userIds,
      orgId
    );
  }

  public addOrganizationGroupsForUser(
    groupIds: string[],
    userId: string
  ): Promise<void> {
    return this.getWrapped().addOrganizationGroupsForUser(groupIds, userId);
  }

  async addClientGroupsForClient(
      groupIds: string[],
      clientId: string
  ): Promise<void> {
    return this.getWrapped().addClientGroupsForClient(groupIds, clientId);
  }
  public addOrganizationRolesForUser(
    roleIds: string[],
    userId: string
  ): Promise<void> {
    return this.getWrapped().addOrganizationRolesForUser(roleIds, userId);
  }

  public setOrganizationGroupsOfUser(
    groupIds: string[],
    userId: string
  ): Promise<SetOrgGroupsOfUserResult> {
    return this.getWrapped().setOrganizationGroupsOfUser(groupIds, userId);
  }

  public removeOrganizationGroupsOfUser(
    groupIds: string[],
    userId: string
  ): Promise<RemoveOrgGroupsOfUserResult> {
    return this.getWrapped().removeOrganizationGroupsOfUser(groupIds, userId);
  }

  public removeClientGroupsOfClient(
      groupIds: string[],
      clientId: string
  ): Promise<RemoveClientGroupsOfClientResult> {
    return this.getWrapped().removeClientGroupsOfClient(groupIds, clientId);
  }
  public removeOrganizationRolesOfUser(
    roleIds: string[],
    userId: string
  ): Promise<void> {
    return this.getWrapped().removeOrganizationRolesOfUser(roleIds, userId);
  }

  public addUserToOrganizationGroup(
    groupId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    return this.getWrapped().addUserToOrganizationGroup(groupId, userId, orgId);
  }

  public removeUserFromOrganizationGroup(
    groupId: string,
    userId: string,
    orgId?: string,
    opts?: {
      returnNoMembershipsUserIds: boolean;
      cleanUpLicenseReservations: boolean;
    }
  ): Promise<void> {
    return this.getWrapped().removeUserFromOrganizationGroup(
      groupId,
      userId,
      orgId,
      opts || {
        cleanUpLicenseReservations: true,
        returnNoMembershipsUserIds: true,
      }
    );
  }

  importUsers(users: User[]): Promise<User[]> {
    return this.getWrapped().importUsers(users);
  }

  importOrganizationUsers(
    users: UserForCreate[],
    orgId?: string
  ): Promise<User[]> {
    return this.getWrapped().importOrganizationUsers(users, orgId);
  }
  /**
   * List client group invitations
   */
  listOrganizationClientGroupInvitations(organizationId: string): Promise<ClientGroupInvitation[]> {
    return this.getWrapped().listOrganizationClientGroupInvitations(organizationId);
  }

  /**
   * Create and send a client group invitation
   */
  createAndSendOrganizationClientGroupInvitation(organizationId: string, clientGroupInvitation: ClientGroupInvitation): Promise<ClientGroupInvitation> {
    return this.getWrapped().createAndSendOrganizationClientGroupInvitation(organizationId, clientGroupInvitation);
  };

  getClient(clientId: string): Promise<Client> {
    return this.getWrapped().getClient(clientId);
  }
  deleteOrganizationClient(organizationId: string, clientId: string): Promise<void>  {
    return this.getWrapped().deleteOrganizationClient(organizationId, clientId);
  }
  replaceOrganizationClient(organizationId: string, client: Client): Promise<Client> {
    return this.getWrapped().replaceOrganizationClient(organizationId, client);
  }
}
