import { useContext, useEffect, useId } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {IconLibrary, TooltipWrapper, FeedbackEntry, Feedback, ShowFeedback, ConfirmModal, Button} from "@10duke/dukeui";
import { UserLicensedItemAssignments } from "../../../model/entitlement/UserLicensedItemAssignments";
import { resolveFreeSeatsForLicenseAndAssignmentsArray } from "../../../util/licenseUtil";
import { User } from "../../../model/User";
import UserUtils from "../../../utils/user";
import { ClosableModalProps } from "../closable-modal-props";
import * as ActionTypes from "../../../actions/actionTypes";
import LicenseUtils from "../../../utils/licensed-item";
import Table, { TableColumn } from "../../table";
import LicenseValidityBadge, {
  resolveLicenseValidity,
} from "../../badges/license-validity-badge";
import { ModalKeys } from "../../pages/licenses";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import NavigateAfterAction from "../../navigate-after-action";
import {
  ClearErrorAction,
  GetUserAction,
  isAddErrorAction,
  MANAGE_USERS_LICENSE_ASSIGNMENTS, ReleaseLicenseLeaseAction,
} from "../../../actions/actionTypes";
import { LicenseValues } from "../../../localization/license";
import {
  UserLicensedItemAssignmentsLabels,
  UserLicensedItemAssignmentsValues,
} from "../../../localization/user-licensed-item-assignments";
import { LicenseUsageByUsersValues } from "../../../localization/license-usage-by-users";
import { LicenseConsumptionLabels } from "../../../localization/license-consumption";
import { Form } from "react-bootstrap";
import "./user-licenses-modal-view.scss";
import {LicenseAssignmentWithSessions} from "../../../model/entitlement/LicenseAssignmentWithSessions";
import {hasAction} from "../../../ui-configuration/configuration-tools";

function resolveLicensedItemNameFromAssignments(
  licenses: UserLicensedItemAssignments[] | undefined,
  licenseId: string,
  fallback: string
): string {
  let retVal = fallback;
  if (licenses !== undefined && licenseId) {
    const t = licenses
      ? licenses.find(
          (val) =>
            val &&
            val.assignments &&
            val.assignments.length &&
            val.assignments[0] &&
            val.assignments[0].license &&
            val.assignments[0].license.id === licenseId
        )
      : undefined;
    retVal = resolveLicensedItemName(t, fallback);
  }
  return retVal;
}

function resolveLicensedItemName(
  lic: UserLicensedItemAssignments | undefined,
  fallback: string
): string {
  let retVal = lic
    ? lic.licensedItemDisplayName || lic.licensedItemName || fallback
    : fallback;
  return retVal;
}
export interface UserLicensesModalStateProps {
  licensesChanged?: boolean;
  onToggleReservation: (licenseId: string) => void;
  onToggleDenial: (licenseId: string) => void;
  feedback?: FeedbackEntry[];
  onShowFeedback: (feedback: FeedbackEntry) => void;
  onHideFeedback: (id: string) => void;
  onSetActiveSearch: (s: string) => void;
  activeSearch?: string;
  showAll: boolean;
  onToggleShowAll: () => void;
  onAddLeaseToBeReleased: (release: {id:string, assignment: LicenseAssignmentWithSessions}) => void;
  onRemoveLeaseToBeReleased: (release: {id:string, assignment: LicenseAssignmentWithSessions}) => void;
  releaseLeases: {id:string, assignment: LicenseAssignmentWithSessions}[];
}
export interface UserLicensesModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {}
export interface UserLicensesModalDataProps {
  userId?: string;
}

export interface UserLicensesModalProps
  extends UserLicensesModalStateProps,
    UserLicensesModalVisibilityProps,
    UserLicensesModalDataProps,
    Pick<ClosableModalProps, "isReady"> {
  user?: User;
  onLoadUser: (userId: string) => Promise<GetUserAction>;
  licenses?: UserLicensedItemAssignments[];
  onLoadLicenses: (userId: string) => void;
  onApply: (
    lics: UserLicensedItemAssignments[] | undefined,
    userId: string
  ) => Promise<ActionTypes.MultiAction|string>;
  onClearError: (errorId: string) => ClearErrorAction;
  onReleaseLicenseLease: (release: { id: string, assignment: LicenseAssignmentWithSessions }[]) => Promise<ReleaseLicenseLeaseAction>;
}

export default function UserLicensesModalView(props: UserLicensesModalProps) {
  const {
    userId,
    user,
    onLoadUser,
    show,
    onClose,
    licenses,
    licensesChanged,
    onApply,
    onLoadLicenses,
    onToggleReservation,
    onToggleDenial,
    feedback,
    onShowFeedback,
    onHideFeedback,
    onSetActiveSearch,
    activeSearch,
    isReady,
    onExited,
    showAll,
    onToggleShowAll,
    onClearError,
    onReleaseLicenseLease,
    onAddLeaseToBeReleased,
    onRemoveLeaseToBeReleased,
    releaseLeases,
    ...other
  } = props;
  const idPrefix = useId();
  const { conf } = useContext(UIConfiguration);
  const licenseConf =
    conf.functionality && conf.functionality.licenses
      ? conf.functionality.licenses
      : {};
  const intl = useIntl();

  const columns: TableColumn[] = [
    {
      key: "licenseId",
      label: intl.formatMessage(UserLicensedItemAssignmentsLabels.licenseId),
      isTechnical: true,
      hidden: true,
    },
    {
      key: "name",
      isDummy: true,
      label: intl.formatMessage(
        UserLicensedItemAssignmentsLabels.licensedItemDisplayName
      ),
      sortable: true,
      className: "name-column",
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const t = props.rendererData.resolveValue(props.row);
        return (
          <>
            {t && t.length ? (
              <span className={"link-holder"}>
                {hasAction(licenseConf.rowActions, 'show') ? (
                  <NavigateAfterAction
                    href={
                      (("/licenses/" +
                        (props.row.assignments &&
                        props.row.assignments.length &&
                        props.row.assignments[0] &&
                        props.row.assignments[0].license
                          ? props.row.assignments[0].license.id
                          : undefined)) as string) +
                      "/" +
                      ModalKeys.show
                    }
                    action={onClose}
                  >
                    {t[0]}
                  </NavigateAfterAction>
                ) : (
                  t[0]
                )}
              </span>
            ) : (
              false
            )}
            {t && t.length > 1 ? (
              <>
                <LicenseValidityBadge
                  validUntil={
                    props.row.assignments &&
                    props.row.assignments.length &&
                    props.row.assignments[0] &&
                    props.row.assignments[0].license
                      ? props.row.assignments[0].license.validUntil
                      : undefined
                  }
                  validFrom={
                    props.row.assignments &&
                    props.row.assignments.length &&
                    props.row.assignments[0] &&
                    props.row.assignments[0].license
                      ? props.row.assignments[0].license.validFrom
                      : undefined
                  }
                  validity={resolveLicenseValidity(
                    props.row.assignments &&
                      props.row.assignments.length &&
                      props.row.assignments[0] &&
                      props.row.assignments[0].license
                      ? props.row.assignments[0].license
                      : undefined
                  )}
                />
              </>
            ) : (
              false
            )}
          </>
        );
      },
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const t = props.rendererData.resolveValue(props.row);
        return (
          <>
            {t && t.length ? t[0] : false}
            {t && t.length > 1 ? <>, {t[1]}</> : false}
          </>
        );
      },
      rendererData: {
        resolveValue: (itm: any) => {
          const validity = resolveLicenseValidity(
            itm.assignments &&
              itm.assignments.length &&
              itm.assignments[0] &&
              itm.assignments[0].license
              ? itm.assignments[0].license
              : undefined
          );
          return [
            resolveLicensedItemName(
              itm,
              intl.formatMessage(
                UserLicensedItemAssignmentsValues.licensedItemDisplayName
                  .undefined
              )
            ),
            validity
              ? intl.formatMessage(
                  (LicenseValues.validityStatus as any)[validity]
                )
              : validity,
          ];
        },
      },
    },
    {
      key: "hasReservation",
      isDummy: true,
      label: intl.formatMessage(
        UserLicensedItemAssignmentsLabels.hasReservation
      ),
      tooltip: intl.formatMessage({
        defaultMessage:
          "Reserving a license will guarantee the user will always have access to a license. Reserved licenses are not available to other users.",
        description: "Cell heading tooltip",
      }),
      align: "center",
      sortable: true,
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const freeSeats = resolveFreeSeatsForLicenseAndAssignmentsArray(
          props.row.assignments
        );
        const value = props.rendererData.resolveValue(props.row);
        return (
          <div
            className={
              !value &&
              ((freeSeats !== undefined && freeSeats <= 0) ||
                (props.row.assignments &&
                  props.row.assignments.length &&
                  props.row.assignments[0].license &&
                  props.row.assignments[0].license.active === false))
                ? "tooltip-disabled-wrapper d-block"
                : "d-block"
            }
          >
            <Button
              data-test-reserve={!value ? true : null}
              data-test-release={value ? true : null}
              variant={(value ? "success" : "outline-secondary")}
              className={"btn w-100"}
              disabled={
                !value &&
                ((freeSeats !== undefined && freeSeats <= 0) ||
                  (props.row.assignments &&
                    props.row.assignments.length &&
                    props.row.assignments[0].license &&
                    props.row.assignments[0].license.active === false))
              }
              action={() => {
                onToggleReservation(props.row.licenseId);
              }}
              icon={value && <FontAwesomeIcon icon={IconLibrary.icons.faCheck} />}
            >
                {value
                  ? intl.formatMessage({
                      defaultMessage: "Release",
                      description:
                        "Button label for toggling license seat reservation",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Reserve",
                      description:
                        "Button label for toggling license seat reservation",
                    })}
            </Button>
          </div>
        );
      },
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const value = props.rendererData.resolveValue(props.row);
        return props.row.assignments &&
          props.row.assignments.length &&
          props.row.assignments[0].license &&
          props.row.assignments[0].license.active === false ? (
          value ? (
            <>
              <FormattedMessage
                defaultMessage="Releasing a license is not available as the license has been deactivated."
                description={"tooltip for disabled license release"}
              />
            </>
          ) : (
            <>
              <FormattedMessage
                defaultMessage="Reserving a license is not available as the license has been deactivated."
                description={"tooltip for disabled license reservation"}
              />
            </>
          )
        ) : undefined;
      },
      rendererData: {
        resolveValue: (itm: any) => {
          const firstLevelAssignment = itm.assignments[0];
          const secondLevelAssignment =
            firstLevelAssignment.assignments &&
            firstLevelAssignment.assignments.length
              ? firstLevelAssignment.assignments[0]
              : undefined;

          return !!(
            secondLevelAssignment && secondLevelAssignment.type === "reserved"
          );
        },
      },
    },
    {
      key: "hasDenial",
      isDummy: true,
      label: intl.formatMessage(UserLicensedItemAssignmentsLabels.hasDenial),
      tooltip: intl.formatMessage({
        defaultMessage: "Blocked users are not able to consume a license.",
        description: "Cell heading tooltip",
      }),
      sortable: true,
      align: "center",
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const value = props.rendererData.resolveValue(props.row);
        return (
          <div
            className={
              props.row.assignments &&
              props.row.assignments.length &&
              props.row.assignments[0].license &&
              props.row.assignments[0].license.active === false
                ? "tooltip-disabled-wrapper d-block"
                : "d-block"
            }
          >
            <Button
              data-test-deny={!value ? true : null}
              data-test-allow={value ? true : null}
              size={"sm"}
              variant={value ? "danger" : "outline-secondary"}
              className={"btn w-100"}
              action={() => {
                onToggleDenial(props.row.licenseId);
              }}
              disabled={
                props.row.assignments &&
                props.row.assignments.length &&
                props.row.assignments[0].license &&
                props.row.assignments[0].license.active === false
              }
              icon={value && <FontAwesomeIcon icon={IconLibrary.icons.faBan} />}
            >
              {value
                  ? intl.formatMessage({
                      defaultMessage: "Release",
                      description:
                        "Button label for toggling license seat denial",
                    })
                  : intl.formatMessage({
                      defaultMessage: "Block",
                      description:
                        "Button label for toggling license seat denial",
                    })}
            </Button>
          </div>
        );
      },
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const value = props.rendererData.resolveValue(props.row);
        return props.row.assignments &&
          props.row.assignments.length &&
          props.row.assignments[0].license &&
          props.row.assignments[0].license.active === false ? (
          value ? (
            <>
              <FormattedMessage
                defaultMessage="Releasing a blocked license is not available as the license has been deactivated."
                description={"tooltip for disabled license denial release"}
              />
            </>
          ) : (
            <>
              <FormattedMessage
                defaultMessage="Blocking a license is not available as the license has been deactivated."
                description={"tooltip for disabled license denial"}
              />
            </>
          )
        ) : undefined;
      },
      rendererData: {
        resolveValue: (itm: any) => {
          const firstLevelAssignment = itm.assignments[0];
          const secondLevelAssignment =
            firstLevelAssignment.assignments &&
            firstLevelAssignment.assignments.length
              ? firstLevelAssignment.assignments[0]
              : undefined;

          return !!(
            secondLevelAssignment && secondLevelAssignment.type === "denied"
          );
        },
      },
    },
    {
      key: "isConsuming",
      sortable: true,
      isDummy: true,
      label: intl.formatMessage(UserLicensedItemAssignmentsLabels.isConsuming),
      renderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        const t = props.rendererData.resolveValue(props.row);
        return (
          <>
            {t.length ? (
              t.map((m: any) => {
                let from = m.from;
                if (from === "now()") {
                  from = intl.formatDate(new Date(), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                } else if (from) {
                  from = intl.formatDate(new Date(from), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                }
                let to = m.to;
                if (to === "now()") {
                  to = intl.formatDate(new Date(), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                } else if (to) {
                  to = intl.formatDate(new Date(to), {
                    dateStyle: "short",
                    timeStyle: "medium",
                  });
                }
                const isToBeRemoved = releaseLeases.find((f) => f.id === m.leaseId)
                return (
                  <div className={"usage-details" + (isToBeRemoved ? " to-be-removed" : "")}>
                    {conf.functionality?.enableReleaseLicenseLease && <Button
                          variant={"unstyled"}
                          className={"release"}
                          type={"button"}
                          tooltip={isToBeRemoved ?
                              <FormattedMessage
                                  defaultMessage="Cancel license lease removal"
                                  description={"tooltip for cancel remove license lease button"}
                              />
                              :
                              <FormattedMessage
                                  defaultMessage="Remove license lease"
                                  description={"tooltip for remove license lease button"}
                              />
                          }
                          action={() => {
                            if (isToBeRemoved) {
                              onRemoveLeaseToBeReleased({ id: m.leaseId, assignment: m.assignment });
                            } else {
                              onAddLeaseToBeReleased({ id: m.leaseId, assignment: m.assignment });
                            }
                          }}
                          icon={isToBeRemoved ? <FontAwesomeIcon icon={IconLibrary.icons.faBan} fixedWidth={true} /> : <FontAwesomeIcon icon={IconLibrary.icons.faTrashAlt} fixedWidth={true} />}
                      />}
                    <TooltipWrapper
                        tipKey={"details_" + m.leaseId}
                        placement={"auto-start"}
                        tip={
                          (
                              <div className="usage-details">

                                {m.id ? (
                                    <div>
                                      <strong className="d-inline-block">
                                        {intl.formatMessage(
                                            LicenseConsumptionLabels.hardwareId
                                        )}
                                      </strong>
                                      <span>{m.id}</span>
                                    </div>
                                ) : undefined}
                                {m.name ? (
                                    <div>
                                      <strong className="d-inline-block">
                                        {intl.formatMessage(
                                            LicenseConsumptionLabels.hardwareName
                                        )}
                                      </strong>
                                      <span className={"value"}>{m.name}</span>
                                    </div>
                                ) : undefined}
                                <div>
                                  <FormattedMessage
                                      defaultMessage="The license lease is valid from {from} until {to}"
                                      description={
                                        "Validity information for the license lease. from = formatted date with time for start of validity, to = formatted date with time for end of validity"
                                      }
                                      values={{
                                        from: from,
                                        to: to,
                                      }}
                                  />
                                </div>
                              </div>
                          )
                        }

                    >
                    <div className={"usage-details-content"}>
                      {m.id ? (
                      <div>
                        <strong className="d-inline-block">
                          {intl.formatMessage(
                            LicenseConsumptionLabels.hardwareId
                          )}
                        </strong>
                        <span>{m.id}</span>
                      </div>
                      ) : undefined}
                      {m.name ? (
                        <div>
                          <strong className="d-inline-block">
                            {intl.formatMessage(
                              LicenseConsumptionLabels.hardwareName
                            )}
                          </strong>
                          <span>{m.name}</span>
                        </div>
                      ) : undefined}
                      <div>
                        <strong className="d-inline-block">
                          {intl.formatMessage(LicenseConsumptionLabels.from)}
                        </strong>
                        <span>{from}</span>
                      </div>
                      <div>
                        <strong className="d-inline-block">
                          {intl.formatMessage(LicenseConsumptionLabels.until)}
                        </strong>
                        <span>{to}</span>
                      </div>
                    </div>
                    </TooltipWrapper>
                  </div>
                );
              })
            ) : (
                <TooltipWrapper
                    tipKey={"not-used"}
                    placement={"auto-start"}
                    tip={<>
                      {intl.formatMessage(
                        LicenseUsageByUsersValues.isConsuming.false
                      )}
                    </>}
                >
                  <div className="usage-details no-usage">
                    <FontAwesomeIcon
                        icon={IconLibrary.icons.faTimes}
                        className={"icon d-inline-block"}
                    />
                  </div>
                </TooltipWrapper>
            )}
            {conf.functionality?.enableReleaseLicenseLease && t.length > 1 && t.length !== releaseLeases.length ?
                <TooltipWrapper
                    tipKey={"release-all"}
                    placement={"bottom"}
                >
                  <Button
                      className={"release-all btn w-100"}
                      variant={"outline-danger"}
                      size={"sm"}
                      type={"button"}
                      action={() => {
                        onAddLeaseToBeReleased(t.map((m: any) => {
                          return {id: m.leaseId, assignment: m.assignment};
                        }).filter((f: string) => !!f));
                      }}
                      tooltip={
                        <FormattedMessage
                            defaultMessage="Remove all license leases"
                            description={"tooltip for remove license lease button"}
                        />
                      }
                  >
                    <FormattedMessage
                        defaultMessage="Remove all"
                        description={"label for remove all license leases button"}
                    />
                  </Button>
                </TooltipWrapper> : undefined }
          </>
        );
      },
      tipRenderer: (props: {
        cell: any;
        row: any;
        rowIndex: Number;
        rendererData: any;
      }) => {
        return undefined;
      },
      rendererData: {
        resolveValue: (itm: UserLicensedItemAssignments) => {
          // Excessive ? operator use is needed as it seems that the state can change while this is running
          return itm.assignments?.map((a2) => {
            return a2.assignments
              ?.map((a) => {
                return a?.sessions?.map((s) => {
                  const t = s?.anchors
                      ? s?.anchors.map((anchor, ind) => {
                        const leaseId = s?.leases && s?.leases.length ? (s?.leases[ind]?.id) : undefined;
                        return { name: anchor?.externalName, id: anchor?.externalId, leaseId, assignment: a };
                      })
                      : [{}];
                  return t.map((m: any, i: number) => {
                    return { from: s?.validFrom, to: s?.validUntil, ...m };
                  });
                });
              });
            })
            .flat(3)
            .filter((f: any) => !!f) || []
          ;
        },
      },
    },
  ];
  useEffect(() => {
    if (
      show &&
      userId &&
      (user === undefined || userId !== (user.id as string))
    ) {
      onLoadUser(userId).then((res) => {
        if (!user && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, user, userId, onLoadUser, onClearError]);
  useEffect(() => {
    if (show && user && licenses === undefined) {
      onLoadLicenses(user.id as string);
    }
  }, [show, user, licenses, onLoadLicenses]);
  const resourceUser = user
    ? UserUtils.resolveDisplayName(
        user,
        intl.formatMessage({
          defaultMessage: "User",
          description:
            "user name to use when real user name cannot be resolved",
        })
      )
    : intl.formatMessage({
        defaultMessage: "User",
        description: "user name to use when real user name cannot be resolved",
      });
  return (
    <>
      <ShowFeedback
        idPrefix={"user_licenses_modals_"}
        onHideFeedback={onHideFeedback}
        feedback={feedback}
      />
      <ConfirmModal
        id={"user-licenses-modal"}
        onExited={onExited}
        onReloadData={() => {
          if ((userId || user) && onLoadUser) {
            onLoadUser(user ? (user.id as string) : (userId as string)).then(
              (res) => {
                if (!user && isAddErrorAction(res)) {
                  // only clear error if no data exists, leave if previous data is still available and
                  // let the api error notification handler show error
                  onClearError(res.error?.errorId);
                }
              }
            );
          }
          if ((userId || user) && onLoadLicenses) {
            onLoadLicenses(user ? (user.id as string) : (userId as string));
          }
        }}
        isReady={isReady}
        data-test-user-licenses-modal
        {...other}
        title={
          isReady && !user
            ? intl.formatMessage({
                defaultMessage: "User not found",
                description: "modal heading when user is not found",
              })
            : intl.formatMessage(
                {
                  defaultMessage: "{name}: licenses",
                  description: "modal heading. 'name' = name of the user",
                },
                {
                  name: resourceUser,
                }
              )
        }
        show={show}
        onClose={onClose}
        backdrop={licensesChanged || releaseLeases.length > 0 ? "static" : true}
        primaryButton={
          !isReady
            ? {
                label: intl.formatMessage({
                  defaultMessage: "Save",
                  description: "primary button label",
                }),
              }
            : !user
            ? {
                label: intl.formatMessage({
                  defaultMessage: "Close",
                  description: "close button label",
                }),
              }
            : !licenses || licenses.length === 0
            ? {
                label: intl.formatMessage({
                  defaultMessage: "Close",
                  description: "close button label",
                }),
              }
            : {
                label: intl.formatMessage({
                  defaultMessage: "Save",
                  description: "primary button label",
                }),
                disabled: !licensesChanged && releaseLeases.length === 0,
                tooltip: !licensesChanged && releaseLeases.length === 0
                  ? intl.formatMessage({
                      defaultMessage: "No changes to save.",
                      description: "primary button disabled tooltip",
                    })
                  : "",
              }
        }
        onPrimaryAction={
          !isReady
            ? () => {
                if (onClose) {
                  onClose();
                }
              }
            : !user
            ? () => {
                if (onClose) {
                  onClose();
                }
              }
            : !licenses || licenses.length === 0
            ? () => {
                if (onClose) {
                  onClose();
                }
              }
            : () => {
                const releaseHandler = (releaseResult: any|undefined) => {
                  const reservationHandler = (result: any) => {
                    const invalidResults: any[] = [];
                    let validresults: any[] = [];
                    if (result !== 'skipped' && typeof result !== "string") {
                      validresults = result.results?.filter((res: any) => {
                        if (res.type === MANAGE_USERS_LICENSE_ASSIGNMENTS) {
                          if (res && res.errors && res.errors[userId as string]) {
                            invalidResults.push(res);
                            return false;
                          } else {
                            return true;
                          }
                        } else {
                          return false;
                        }
                      });
                    }
                    if (releaseResult && releaseResult.result) {
                      const resultArray = releaseResult.result.length !== undefined ? releaseResult.result : [releaseResult.result];
                      const invalidReleaseResults: any[] = [];
                      const validReleaseResults = releaseLeases.map((rl) => {
                        if (resultArray.find((rrr: any) => rrr[rl.id])) {
                          return rl;
                        } else {
                          invalidReleaseResults.push(rl);
                          return undefined;
                        }
                      }).filter((f) => !!f);
                      if (validReleaseResults && validReleaseResults.length) {
                        // success
                        onShowFeedback({
                          id: "releaseLease_success_" + userId,
                          msg: intl.formatMessage(
                              {
                                defaultMessage:
                                    "{name}'s {leaseCount, plural, =1 {license lease} other {license leases} } removed for: {licenses}.",
                                description:
                                    "Success notification for removing license lease. 'leaseCount' = Amount of leases for choosing plural/singular term for 'license lease' in translation, 'name' = name of the user, 'licenses' = a list of targeted licenses",
                              },
                              {
                                leaseCount: validReleaseResults.length,
                                name: "<strong>" + resourceUser + "</strong>",
                                licenses: validReleaseResults.map(
                                        (r: any) =>
                                            "<strong>" +
                                            resolveLicensedItemName(licenses.find((lic) => {
                                              return lic.assignments.find((ass) => ass.assignments?.find((ass2) => ass2.id === r.assignment.id));
                                            }), intl.formatMessage(
                                                LicenseValues.licensedItem.displayName
                                                    .undefined
                                            )) +
                                            "</strong>"
                                    ).filter((f, i, arr) => arr.indexOf(f) === i).join(", "),
                              }
                          ),
                          autoClose: true,
                          type: "success",
                        });
                      }
                      if (invalidReleaseResults.length) {
                        // failure
                        onShowFeedback({
                          id: "releaseLease_failed_" + userId,
                          msg: intl.formatMessage(
                              {
                                defaultMessage:
                                    "Removing {name}'s {leaseCount, plural, =1 {license lease} other {license leases} } failed for: {licenses}.",
                                description:
                                    "Failure notification for removing license lease. leaseCount' = Amount of leases for choosing plural/singular term for 'license lease' in translation, 'name' = name of the user, 'licenses' = a list of targeted licenses",
                              },
                              {
                                leaseCount: invalidReleaseResults.length,
                                name: "<strong>" + resourceUser + "</strong>",
                                licenses: invalidReleaseResults
                                    .map(
                                        (r: any) =>
                                            "<strong>" +
                                            resolveLicensedItemName(licenses.find((lic) => {
                                              return lic.assignments.find((ass) => ass.assignments?.find((ass2) => ass2.id === r.assignment.id));
                                            }), intl.formatMessage(
                                                LicenseValues.licensedItem.displayName
                                                    .undefined
                                            )) +
                                            "</strong>"
                                    )
                                    .join(", "),
                              }
                          ),
                          type: "danger",
                        });
                      }
                    }
                    if (validresults.length) {
                      // some success, show combined feedback
                      onShowFeedback({
                        id: "manageAssignments_success_" + userId,
                        msg: intl.formatMessage(
                            {
                              defaultMessage:
                                  "{name}'s licenses updated for: {licenses}.",
                              description:
                                  "Success notification for updating license. 'name' = name of the user, 'licenses' = a list of targeted licenses",
                            },
                            {
                              name: "<strong>" + resourceUser + "</strong>",
                              licenses: validresults
                                  .filter((r: any) => !!r)
                                  .map(
                                      (r: any) =>
                                          "<strong>" +
                                          LicenseUtils.resolveDisplayName(
                                              r.licenseUsage.licensedItem,
                                              intl.formatMessage(
                                                  LicenseValues.licensedItem.displayName
                                                      .undefined
                                              )
                                          ) +
                                          "</strong>"
                                  )
                                  .join(", "),
                            }
                        ),
                        autoClose: true,
                        type: "success",
                      });
                    }
                    if (invalidResults.length) {
                      // some fail, show combined feedback
                      onShowFeedback({
                        id: "manageAssignments_failure_" + userId,
                        msg: intl.formatMessage(
                            {
                              defaultMessage:
                                  "Updating {name}'s licenses failed for: {licenses}.",
                              description:
                                  "Failure notification for updating license. 'name' = name of the user, 'licenses' =  a list of targeted licenses",
                            },
                            {
                              name: "<strong>" + resourceUser + "</strong>",
                              licenses: invalidResults
                                  .map(
                                      (r) =>
                                          resolveLicensedItemNameFromAssignments(
                                              licenses,
                                              r.licenseId,
                                              intl.formatMessage(
                                                  LicenseValues.licensedItem.displayName
                                                      .undefined
                                              )
                                          ) +
                                          " (" +
                                          r.errors[userId as string].message +
                                          ")"
                                  )
                                  .join(", "),
                            }
                        ),
                        type: "danger",
                      });
                    }
                  };
                  if (licensesChanged) {
                    onApply(licenses, user.id as string).then(reservationHandler);
                  } else {
                    reservationHandler('skipped');
                  }
                };
                if (releaseLeases.length && conf.functionality?.enableReleaseLicenseLease) {
                  onReleaseLicenseLease(releaseLeases).then(releaseHandler);
                } else {
                  releaseHandler(undefined);
                }
                if (onClose) {
                  onClose();
                }
              }
        }
        secondaryButton={
          isReady && user && licenses && licenses.length > 0
            ? {
                label: intl.formatMessage({
                  defaultMessage: "Cancel",
                  description: "secondary button label",
                }),
              }
            : undefined
        }
        onSecondaryAction={onClose}
        skipConfirm={!isReady || !user || !licenses || licenses.length <= 0}
        size={
          isReady && user && licenses && licenses.length > 0 ? "lg" : undefined
        }
        confirmTitle={intl.formatMessage({
          defaultMessage: "Edit Licenses",
          description: "modal heading for confirming action",
        })}
        confirmContent={
          <p>
            <FormattedMessage
              defaultMessage="Are you sure you want to make these changes to {name}'s licenses?"
              description="confirm action message. 'name' = targeted users name"
              values={{
                name: <strong>{resourceUser}</strong>,
              }}
            />
          </p>
        }
        acceptButton={{
          label: intl.formatMessage({
            defaultMessage: "Yes",
            description: "confirm action button label",
          }),
        }}
        cancelButton={{
          label: intl.formatMessage({
            defaultMessage: "No",
            description: "cancel confirmation button label",
          }),
        }}
      >
        {!isReady && !user && (!licenses || licenses.length <= 0) && (
          <Table<UserLicensedItemAssignments>
            disableLoadingIndicator={!isReady}
            maxRows={15}
            compact={true}
            reset={false}
            pagination={false}
            data={undefined}
            identifyingColumn={"licenseId"}
            columns={columns}
            search={(licenses || []).length > TABLE_SEARCH_THRESHOLD}
            onSearch={onSetActiveSearch}
            activeSearch={activeSearch}
          />
        )}
        {isReady && !user && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p>
              <FormattedMessage
                defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
                description="message to be shown when there is no user to display"
              />
            </p>
          </Feedback>
        )}
        {user && isReady && (!licenses || licenses.length === 0) ? (
          <p>
            <FormattedMessage
              defaultMessage="There are no licenses available for assignment."
              description="message to be shown when there are no licenses"
            />
          </p>
        ) : undefined}
        {user && licenses && licenses.length > 0 ? (
          <Table<UserLicensedItemAssignments>
            disableLoadingIndicator={!isReady}
            maxRows={15}
            compact={true}
            reset={false}
            pagination={false}
            data={licenses || []}
            identifyingColumn={"licenseId"}
            columns={columns}
            search={
              (licenses || []).length > TABLE_SEARCH_THRESHOLD || !!activeSearch
            }
            onSearch={onSetActiveSearch}
            activeSearch={activeSearch}
            header={
              <div>
                {
                  <Form.Check
                    type={"switch"}
                    className={"custom-switch-sm"}
                    label={intl.formatMessage({
                      defaultMessage: "Show only valid licenses",
                      description:
                        "Label for filter toggle that controls visibility of invalid licenses in table",
                    })}
                    onChange={() => {
                      onToggleShowAll();
                    }}
                    id={idPrefix + "showAll"}
                    checked={!showAll}
                    value="1"
                  />
                }
              </div>
            }
          />
        ) : undefined}
      </ConfirmModal>
    </>
  );
}
