import {ActionSender} from "../model/ActionSender";
import * as ActionTypes from "./actionTypes";
import {buildActionThunk} from "./actionHelpers";
import {authzApi} from "../api";
import {LicenseAssignmentWithSessions} from "../model/entitlement/LicenseAssignmentWithSessions";

export function releaseLicenseLeaseAction(
    sender: ActionSender,
    release: {
      id: string,
      assignment: LicenseAssignmentWithSessions
    }[],
): ActionTypes.AppThunkAction<ActionTypes.ReleaseLicenseLeaseAction> {

  return buildActionThunk<ActionTypes.ReleaseLicenseLeaseAction, any>(
      sender,
      ActionTypes.RELEASE_LICENSE_LEASE,
      async () => await releaseLicenseLeaseInternal(release.map((r) => r.id)),
      (type, result) => ({
        type,
        release: release?.map((r) => {
          const {
            assignment,
            id: leaseId,
            ...rest
          } = r;
          let assignmentId = undefined;
          if (assignment.type !==  "reserved" && assignment.type !== "denied") {
            const sessions = assignment.sessions?.map((s) => {
              // remove matching lease
              const leases = s.leases?.filter((l) => l.id !== r.id);
              // if more leases left, return session with updated leases, else remove session
              if (leases && leases.length) {
                return {
                  ...s,
                  leases
                };
              } else {
                return undefined;
              }
            }).filter((filt) => !!filt);
            if (!sessions || sessions.length <= 0) {
              assignmentId = assignment.id;
            }

          }
          return {
            ...rest,
            leaseId,
            removeAssignmentId: assignmentId
          };
        }),
        result
      })
  );

}

async function releaseLicenseLeaseInternal(
    ids: string[]
): Promise<any> {
  const result = await authzApi.releaseLicenseConsumptionById(ids);
  return result;
}
