import * as ActionTypes from "../actions/actionTypes";
import {
  LicenseAssignmentsById,
  LicenseUsageDataByLicenseId,
} from "../store/LicenseState";
import { LicenseAssignmentWithSessions } from "../model/entitlement/LicenseAssignmentWithSessions";
import { updateAssignment } from "../util/licenseUtil";
import { LicenseUsage } from "../model/entitlement/LicenseUsage";
import {
  ReleaseLeaseResult,
  ReleaseLicenseLeaseAction,
  RemoveMembershipResult,
  RemoveOrganizationResult,
} from "../actions/actionTypes";

export default function licenseAssignments(
  state: LicenseAssignmentsById,
  action: ActionTypes.AppAction,
  usageState: LicenseUsageDataByLicenseId
): LicenseAssignmentsById | null {
  const currentState = state || ({} as LicenseAssignmentsById);
  switch (action.type) {
    case ActionTypes.QUERY_LICENSE_USAGE: {
      const queryLicenseUsage = action as ActionTypes.QueryLicenseUsageAction;
      const usage = queryLicenseUsage.licenseUsage;
      return updateLicenseUsageToState(currentState, usage);
    }

    case ActionTypes.LIST_ENT_LICENSES_WITH_USAGE: {
      const listEntLicensesWithUsage =
        action as ActionTypes.ListEntLicensesWithUsageAction;
      if (
        listEntLicensesWithUsage.licenses &&
        listEntLicensesWithUsage.licenses.length
      ) {
        let stateAfterListEntLicensesWithUsage = { ...currentState };
        for (const lic of listEntLicensesWithUsage.licenses) {
          // Licenses returned by this action are always of type LicenseUsage
          const licUsage = lic as LicenseUsage;
          stateAfterListEntLicensesWithUsage = updateLicenseUsageToState(
            stateAfterListEntLicensesWithUsage,
            licUsage
          );
        }
        return stateAfterListEntLicensesWithUsage;
      }
      return currentState;
    }
    case ActionTypes.GET_USER_LICENSE_ASSIGNMENTS:
    case ActionTypes.MANAGE_USER_LICENSE_ASSIGNMENTS:
    case ActionTypes.MANAGE_USERS_LICENSE_ASSIGNMENTS:
    case ActionTypes.QUERY_AVAILABLE_LICENSES:
    case ActionTypes.QUERY_CLIENT_AVAILABLE_LICENSES:
    case ActionTypes.MANAGE_CLIENTS_LICENSE_ASSIGNMENTS: {
      const licenseAssignmentsAction =
        action as ActionTypes.LicenseAssignmentAction;
      let stateAfterAssignmentAction = { ...currentState };
      for (const userAssignment of licenseAssignmentsAction.licenseAssignments) {
        const userAssignmentId = userAssignment.id as string;
        stateAfterAssignmentAction[userAssignmentId] = updateAssignment(
          userAssignment,
          stateAfterAssignmentAction[userAssignmentId]
        );
      }

      if (action.type === ActionTypes.MANAGE_USER_LICENSE_ASSIGNMENTS) {
        stateAfterAssignmentAction = updateLicenseUsageToState(
          stateAfterAssignmentAction,
          (action as ActionTypes.ManageUserLicenseAssignmentsAction)
            .licenseUsage
        );
      } else if (action.type === ActionTypes.MANAGE_USERS_LICENSE_ASSIGNMENTS) {
        stateAfterAssignmentAction = updateLicenseUsageToState(
          stateAfterAssignmentAction,
          (action as ActionTypes.ManageUsersLicenseAssignmentsAction)
            .licenseUsage
        );
      } else if (action.type === ActionTypes.MANAGE_CLIENTS_LICENSE_ASSIGNMENTS) {
        stateAfterAssignmentAction = updateLicenseUsageToState(
            stateAfterAssignmentAction,
            (action as ActionTypes.ManageUsersLicenseAssignmentsAction)
                .licenseUsage
        );
      }

      return stateAfterAssignmentAction;
    }

    case ActionTypes.SET_ORG_GROUPS_OF_USER:
    case ActionTypes.REMOVE_ORG_GROUPS_OF_USER:
    case ActionTypes.REMOVE_ORG_GROUP_OF_USER:
      const removeOrgAction = action as RemoveOrganizationResult;
      return releaseReservations(
        removeOrgAction.organizationRemovingResult?.noMembershipOrgIds?.find(
          (f) => removeOrgAction.orgId === f
        )
          ? [removeOrgAction.userId]
          : undefined,
        currentState,
        usageState
      );
    case ActionTypes.DELETE_ORG_GROUP:
    case ActionTypes.SET_USERS_IN_ORG_GROUP:
    case ActionTypes.REMOVE_USERS_FROM_ORG_GROUP:
      const removeMembershipAction = action as RemoveMembershipResult;
      return releaseReservations(
        removeMembershipAction.membershipRemovingResult?.noMembershipUserIds,
        currentState,
        usageState
      );
    case ActionTypes.RELEASE_LICENSE_LEASE:
      const releaseLicenseLeaseAction = action as ReleaseLicenseLeaseAction;
      return releaseLicenseLease(releaseLicenseLeaseAction.release, releaseLicenseLeaseAction.result, currentState);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
function releaseLicenseLease(
    release: { leaseId: string, removeAssignmentId?: string}[],
    res: ReleaseLeaseResult|ReleaseLeaseResult[],
    currentState: LicenseAssignmentsById) {
  let result = { ...currentState };
  release.forEach((r) => {
    if (r.leaseId && res && ((res as ReleaseLeaseResult)[r.leaseId] || (res.length && (res as ReleaseLeaseResult[]).find((f: any) => f[r.leaseId])))) {
      // success
      const assignmentIds = Object.keys(result);
      assignmentIds.forEach((lid) => {
        if (r.removeAssignmentId && r.removeAssignmentId === lid) {
          // remove assignment
          delete result[lid];
        } else {
          result[lid].sessions = result[lid].sessions?.filter((s) => {
            if (s.leases?.find((l) => l.id === r.leaseId)) {
              // session has a matching lease
              // remove matching leases
              s.leases = s.leases?.filter((l) => l.id !== r.leaseId);
              if (!s.leases || s.leases.length === 0) {
                // no more leases left, remove session
                return false;
              } else {
                return true;
              }
            } else {
              return true
            }
          });
        }
      });
    }
  });
  return result;
}
function releaseReservations(
  userIds: string[] | undefined,
  currentState: LicenseAssignmentsById,
  usageState: LicenseUsageDataByLicenseId
): LicenseAssignmentsById {
  let result = { ...currentState };
  // Ony attempt to release reservations if all relevant state exists
  if (userIds && usageState && Object.keys(result).length) {
    const lics = Object.keys(usageState);
    for (let i = 0; i < lics.length; i += 1) {
      if (usageState[lics[i]].users) {
        usageState[lics[i]].users?.forEach((u) => {
          if (userIds.find((f) => f === u.userId)) {
            u.assignmentIds.forEach((a) => {
              if (
                result[a]?.type === "denied" ||
                result[a]?.type === "reserved"
              ) {
                result[a] = {
                  ...result[a],
                  type: undefined,
                };
              }
            });
          }
        });
      }
    }
  }
  return result;
}
function updateLicenseUsageToState(
  state: LicenseAssignmentsById,
  usage: LicenseUsage
): LicenseAssignmentsById {
  if (usage.users && usage.users.length !== 0) {
    const usageAssignments = usage.users
      .filter(
        (licUser) => licUser.assignments && licUser.assignments.length !== 0
      )
      .flatMap(
        (licUser) => licUser.assignments as LicenseAssignmentWithSessions[]
      );
    const stateAfterQuery = { ...state };
    for (const usageAssignment of usageAssignments) {
      const usageAssignmentId = usageAssignment.id as string;
      stateAfterQuery[usageAssignmentId] = updateAssignment(
        usageAssignment,
        stateAfterQuery[usageAssignmentId]
      );
    }
    return stateAfterQuery;
  }
  return state;
}
