import MockBase from "./MockBase";
import { DownloadLicenseRequest } from "../../model/DownloadLicenseRequest";
import { AuthzApi } from "../AuthzApi";

class MockAuthz extends MockBase implements AuthzApi {
  public async consumeLicenseForTokenDownload(
    data: DownloadLicenseRequest
  ): Promise<any> {
    if (data.hardwareId === "fail") {
      throw this.build404();
    }
    return data.license.name + "_thisIsAsGoodAPlaceholderAsAnyForTheToken";
  }

  /**
   *
   */
  releaseLicenseConsumptionById(ids: string[]): Promise<any> {
    throw this.build404();
  }
}
export default MockAuthz;
