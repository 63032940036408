import { ReactNode, forwardRef, Ref, HTMLAttributes } from "react";
import {Button} from "@10duke/dukeui";

export interface DropdownToggleWrapperProps
  extends Pick<HTMLAttributes<HTMLButtonElement>, "children"> {
  /**
   * This is actually required, but can't be marked as required due to usage via JSX "as" attribute
   * @param e
   */
  onClick?: (e: any) => void;
  children?: ReactNode;
}

const DropdownToggleWrapper = forwardRef(
  (props: DropdownToggleWrapperProps, ref: Ref<HTMLButtonElement>) => {
    const { onClick, children, ...other } = props;
    if (!props.onClick) {
      throw new Error(
        "DropdownToggleWrapper: Invalid props, onClick is required"
      );
    }
    return (
      <Button
        {...other}
          variant={"unstyled"}
        className={"btn text-center w-100"}
        ref={ref}
        action={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (props.onClick) {
            props.onClick(e);
          }
        }}
      >
        {children}
      </Button>
    );
  }
);

export default DropdownToggleWrapper;
