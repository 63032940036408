import { ValidityFields } from "../model/ValidityFields";
import { AuthorEditorFields } from "../model/AuthorEditorFields";

/**
 * Determines object equality recursively.
 * @param a The first object to compare.
 * @param b The second object to compare.
 */
export function objectsAreEqual(a?: any, b?: any): boolean {
  if (a === undefined) return b === undefined;
  if (b === undefined) return false;
  if (a === null) return b === null;
  if (b === null) return false;

  let aPropCount = 0;
  for (let prop in a) {
    if (a.hasOwnProperty(prop)) {
      aPropCount++;
      if (b.hasOwnProperty(prop)) {
        if (typeof a[prop] === "object") {
          if (!objectsAreEqual(a[prop], b[prop])) return false;
        } else {
          if (a[prop] !== b[prop]) return false;
        }
      } else {
        return false;
      }
    }
  }

  const bPropCount = Object.keys(b).filter((key) =>
    b.hasOwnProperty(key)
  ).length;
  return aPropCount === bPropCount;
}

/**
 * Gets validUntil member as number from obj
 * @param obj The object with validity.
 * @returns Value of validUntil field of the given object
 */
export function getValidUntil(obj: ValidityFields): number | undefined {
  const assignmentValidUntil = obj.validUntil
    ? new Date(obj.validUntil).getTime()
    : undefined;
  return assignmentValidUntil;
}

/**
 * Checks if the given object is valid.
 * @param obj The object with validity.
 * @returns true if the object is currently valid, false otherwise
 */
export function isValid(obj: ValidityFields): boolean {
  const now = new Date().getTime();

  // check valid from.
  let validFrom;
  if (obj.validFrom === "now()") {
    validFrom = now;
  } else if (obj.validFrom) {
    validFrom = new Date(obj.validFrom).getTime();
  }
  if (!validFrom || validFrom > now) {
    return false;
  }

  let validUntil;
  if (obj.validUntil === "now()") {
    validUntil = now;
  } else if (obj.validUntil) {
    validUntil = new Date(obj.validUntil).getTime();
  }
  if (validUntil && validUntil <= now) {
    return false;
  }

  // all good.
  return true;
}
/**
 * Checks if the given object will become valid in the future
 * @param obj The object with validity.
 * @returns true if the object will become valid
 */
export function isScheduled(
  validObj: ValidityFields,
  deltaMs?: number
): boolean {
  const now = new Date().getTime();
  let validFrom;
  if (validObj.validFrom === "now()") {
    validFrom = now;
  } else if (validObj.validFrom) {
    validFrom = new Date(validObj.validFrom).getTime();
  } else {
    return false;
  }
  if (validFrom && validFrom > now) {
    return true;
  }
  return false;
}
/**
 * Checks if the given object is about to expire.
 * @param obj The object with validity.
 * @returns true if the object is currently valid, false otherwise
 */
export function isAboutToExpire(
  validObj: ValidityFields,
  deltaMs?: number
): boolean {
  const now = new Date().getTime();
  const delta = deltaMs || 3600000 * 24 * 7; //given or seven days
  let validUntil;
  if (validObj.validUntil === "now()") {
    validUntil = now;
  } else if (validObj.validUntil) {
    validUntil = new Date(validObj.validUntil).getTime();
  } else {
    return false;
  }
  if (validUntil && validUntil - delta <= now) {
    return true;
  }
  return false;
}
export function sortToLatestByCreatedOrUpdated(
  a: AuthorEditorFields,
  b: AuthorEditorFields
): number {
  let av = a.modified || a.created;
  let bv = b.modified || b.created;
  if ((!av && !bv) || av === bv) {
    return 0;
  } else if ((!av && bv) || (bv && av && av < bv)) {
    return 1;
  } else if ((av && !bv) || (bv && av && av > bv)) {
    return -1;
  } else {
    // should never happen
    return 0;
  }
}
