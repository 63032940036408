import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./not-found-view.scss";
import { IconLibrary, Page, PageDOMProps } from "@10duke/dukeui";
export interface NotFoundDOMProps extends PageDOMProps {}
export interface NotFoundProps extends NotFoundDOMProps {}
function NotFound(props: NotFoundProps) {
  const intl = useIntl();
  const title = intl.formatMessage({
    defaultMessage: "Page not found",
    description: "Heading for the Not found page",
  });
  const description = intl.formatMessage({
    defaultMessage: "The page you were trying to reach could not be found.",
    description: "page not found message 1",
  });
  return (
    <Page
      id={"not-found"}
      data-test-not-found-page
      header={
        <h1>
          <FontAwesomeIcon icon={IconLibrary.icons.faExclamationTriangle} className="icon" />{" "}
          {title}
        </h1>
      }
      meta={{
        title,
        description,
      }}
      {...props}
    >
      <p>{description}</p>
      <p>
        <FormattedMessage
          defaultMessage="This could be because the page may have been moved or you may not be authorized to view it."
          description={"page not found message 2"}
        />
      </p>
      <p>
        <FormattedMessage
          defaultMessage="If you entered a web address manually, please verify that the URL is entered correctly."
          description={"page not found message 3"}
        />
      </p>
    </Page>
  );
}
export default NotFound;
