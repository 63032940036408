import { useEffect, useMemo, HTMLAttributes } from "react";
import { useIntl } from "react-intl";
import { ClosableModalProps } from "../closable-modal-props";
import { Organization } from "../../../model/Organization";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ClearErrorAction,
  isAddErrorAction,
} from "../../../actions/actionTypes";
import { OrganizationLabels } from "../../../localization/organization";
import {IconLibrary,FormInput,FormInputUtils,FeedbackEntry, Modal} from "@10duke/dukeui";

//<editor-fold desc="Props">

export interface EditOrganizationModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}

export interface EditOrganizationModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  organization?: Organization;
  onUpdateOrganization: (org: any) => Promise<any>;
  onLoadOrganization?: () => void;
  isReady: boolean;
  onClearError: (errorId: string) => ClearErrorAction;
}

export interface EditOrganizationModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {
  onShowFeedback: (feedback: FeedbackEntry | FeedbackEntry[]) => void;
}
export interface EditOrganizationModalInputProps
  extends EditOrganizationModalVisibilityProps {}
export interface EditOrganizationModalProps
  extends EditOrganizationModalDOMProps,
    EditOrganizationModalInputProps,
    EditOrganizationModalDataProps {}
//</editor-fold>

function EditOrganizationModal(props: EditOrganizationModalProps) {
  //<editor-fold desc="Local variables">
  let {
    organization,
    onUpdateOrganization,
    onLoadOrganization,
    show,
    onClose,
    onShowFeedback,
    isReady,
    onExited,
    onClearError,
  } = props;

  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>
  const defaultValues = useMemo(
    () => ({
      name: organization ? organization.name : "",
      description: organization ? organization.description : "",
    }),
    [organization]
  );
  const { register, watch, handleSubmit, formState, reset } = useForm({
    mode: "onTouched",
    defaultValues: defaultValues,
  });

  const { errors } = formState;

  const formValues = watch();
  useEffect(() => {
    if (show) {
      reset(defaultValues);
    } else {
      reset({
        name: "",
        description: "",
      });
    }
  }, [show, reset, defaultValues]);
  const onSubmit = (data: any) => {
    return onUpdateOrganization({ ...organization, ...data });
  };

  return (
    <Modal
      onExited={onExited}
      isReady={isReady}
      onReloadData={() => {
        if (onLoadOrganization) {
          onLoadOrganization();
        }
      }}
      backdrop={formState.isDirty ? "static" : true}
      data-test-edit-organization-modal
      title={intl.formatMessage(
        {
          defaultMessage: "{name}: edit details",
          description: "modal heading. 'name' = name of the organization",
        },
        {
          name: organization ? organization.name : "not possible",
        }
      )}
      show={show}
      primaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Save",
          description: "primary button label",
        }),
        disabled:
          (errors !== undefined &&
            errors !== null &&
            Object.keys(errors).length > 0) ||
          !formState.isDirty,
        tooltip:
          errors !== undefined &&
          errors !== null &&
          Object.keys(errors).length > 0
            ? intl.formatMessage({
                defaultMessage: "Please correct errors before saving.",
                description:
                  "tooltip for the disabled primary button, when there are errors",
              })
            : !formState.isDirty
            ? intl.formatMessage({
                defaultMessage: "There are no changes to save.",
                description:
                  "tooltip for the disabled primary button, when there are no changes",
              })
            : "",
      }}
      onPrimaryAction={() => {
        handleSubmit((data: any) => {
          onSubmit({ ...organization, ...data }).then((r: any) => {
            if (!isAddErrorAction(r)) {
              const d = r.organization;
              onShowFeedback({
                id: "edit_organization",
                msg: intl.formatMessage(
                  {
                    defaultMessage: "{name} updated.",
                    description:
                      "Success notification, 'name' = name of the target organization",
                  },
                  {
                    name: "<strong>" + d.name + "</strong>",
                  }
                ),
                autoClose: true,
                type: "success",
              });
            } else {
              onClearError(r.error?.errorId);
              onShowFeedback({
                id: "edit_organization",
                msg: intl.formatMessage(
                  {
                    defaultMessage: "Updating {name} failed.",
                    description:
                      "failure notification, 'name' = name of the target organization",
                  },
                  {
                    name:
                      "<strong>" +
                      (organization ? organization.name : "not possible") +
                      "</strong>",
                  }
                ),
                autoClose: true,
                type: "danger",
              });
            }
            onClose();
          });
        })();
      }}
      secondaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Cancel",
          description: "secondary button label",
        }),
      }}
      onSecondaryAction={onClose}
      onClose={onClose}
    >
      <Form noValidate>
        <FormInput
          label={intl.formatMessage(OrganizationLabels.name)}
          field="name"
          resolveValidity={FormInputUtils.validityResolver(formState)}
          register={register}
          registerOptions={{ required: true }}
          hasValue={!!formValues["name"]}
        >
          {errors &&
            errors.name &&
            errors.name.type &&
            errors.name.type === "required" && (
              <Form.Control.Feedback type="invalid">
                <FontAwesomeIcon
                  icon={IconLibrary.icons.faExclamationCircle}
                  className={"icon"}
                />
                <span className={"copy"}>
                  {intl.formatMessage(
                    {
                      defaultMessage: "{name} is required.",
                      description:
                        "Field validation error. 'name' = Field label for organizations name (Organization.name)",
                    },
                    {
                      name: intl.formatMessage(OrganizationLabels.name),
                    }
                  )}
                </span>
              </Form.Control.Feedback>
            )}
        </FormInput>

        <FormInput
          type="textarea"
          label={intl.formatMessage(OrganizationLabels.description)}
          field="description"
          resolveValidity={FormInputUtils.validityResolver(formState)}
          register={register}
          registerOptions={{ required: false }}
          hasValue={!!formValues["description"]}
        />
      </Form>
    </Modal>
  );
}

export default EditOrganizationModal;
