import {
  ResendDeviceClientInvitationModalProps as _ResendDeviceClientInvitationModalProps,
  ResendDeviceClientInvitationModalDataProps
} from "./resend-device-client-invitation-modal-view";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import { ActionSender } from "../../../model/ActionSender";
import { AppState } from "../../../store/AppState";
import InProgressUtils from "../../../utils/in-progress";
import OrganizationUtils from "../../../utils/organization";
import { addSenderArgument } from "../../../actions/actionHelpers";
import {
  clearError, getOrganizationClientGroupInvitation, sendOrgClientGroupInvitation,
} from "../../../actions";
import { connect } from "react-redux";
import ResendDeviceClientInvitationModal from "./resend-device-client-invitation-modal-container";

export interface ResendDeviceClientInvitationModalProps
  extends Omit<
    _ResendDeviceClientInvitationModalProps,
    keyof ResendDeviceClientInvitationModalDataProps
  > {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ResendDeviceClientInvitationModalProps,
  ResendDeviceClientInvitationModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ResendDeviceClientInvitationModalProps,
  ResendDeviceClientInvitationModalProps
>;
const sender: ActionSender = { key: "resend-device-client-invitation" };

function mapStateToProps(
  state: AppState,
  ownProps: ResendDeviceClientInvitationModalProps
): ReduxStateProps {
  return {
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    invitation: ownProps.invitationId
      ? OrganizationUtils.selectOrganizationClientInvitation(
          ownProps.invitationId,
          state
        )
      : undefined,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientInvitationsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onResendInvitation: addSenderArgument(
    sender,
    sendOrgClientGroupInvitation
  ),
  onLoadInvitation: addSenderArgument(
    sender,
    getOrganizationClientGroupInvitation
  ),
  onClearError: clearError,
};
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ResendDeviceClientInvitationModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ResendDeviceClientInvitationModal);
