import DEFAULT_UI_CONFIGURATION_json from "./DEFAULT_UI_CONFIGURATION.json";
/**
 * Describes a locale description item to use with locale switcher
 */
export interface OrgadminLocalizationLocale {
  /**
   * Name of the locale/language in that language, eg. 'English', 'Suomi'
   */
  label: string;
  /**
   * The locale string, eg. 'en', 'fi'. If region is included, separate with '-' and use lowercase.
   */
  value: string;

  /**
   * List of alternative locale codes for matching requested locale with configured ones. For example full 2 part
   * locale string to allow exact matching when configured value has just 1 part and 2 parts are used when requesting
   * translations.  eg. value: 'pt' => alias: 'pt-pt'. Also allows 1 part aliases to enable e.g. supporting the
   * unorthodox norwegian with codes 'no', 'no-no', 'nb', 'nb-no' all meaning the exact same thing.
   * Separate with '-' and use lowercase.
   */
  alias?: string[];
}

/**
 * Describes the configuration for the localisation
 */
export interface OrgadminLocalizationConfiguration {
  /**
   * Defines the locales to include.
   * Please note that there is no guarantee that resources for those locales exist. It is the responsibility
   * of the developer to add matching resource files separately, this only controls what is potentially available.
   * If missing, default set is used. Empty array is considered as undefined and ignored.
   */
  locales?: OrgadminLocalizationLocale[];
  /**
   * default locale to use
   */
  default?: string;
  /**
   * location of translated assets
   */
  translationsUrl?: string;
  /**
   * Enables automatic detection of preferred locales with navigator.languages
   */
  autoDetect?: boolean;
}

/**
 * Describes the conf for the column section
 */
export interface OrgadminColumnConfiguration {
  /**
   * Column identifier
   */
  key: string;
  /**
   * Marks the column as initially visible/hidden.
   * Leaving this value out of conf, will use UI default behaviour. The UI default may depend on data and be dynamic.
   */
  hidden?: boolean;
  /**
   * Marks the column as disabled, effectively removing it from view.
   */
  disabled?: boolean;
}

/**
 * Describes the conf for the filter section
 */
export interface OrgadminFilterConfiguration {
  /**
   * filter identifier
   */
  key: string;
  /**
   * Marks the filter as initially on/off.
   * Leaving this value out of conf, will use UI default behaviour. The UI default may depend on data and be dynamic.
   */
  active?: boolean;
  /**
   * Marks the filter as disabled, effectively removing it from view.
   */
  disabled?: boolean;
}
export interface OrgadminActionConfiguration {
  /**
   * Action identifier
   */
  key: string;
  /**
   * Marks the action as disabled, effectively removing it from view.
   */
  disabled?: boolean;
  /**
   * URL for custom actions that are just links to open in a new tab.
   */
  url?: string;
}
/**
 * Describes the conf for the section
 */
export interface OrgadminSectionConfiguration {
  /**
   * Defines the order and available set of actions for the section to be displayed as buttons above the table
   */
  actions?: OrgadminActionConfiguration[];
  /**
   * Defines the order and available set of actions for the table rows to be displayed as dropdown
   */
  rowActions?: OrgadminActionConfiguration[];
  /**
   * Defines the order and available set of columns for the data table.
   * If UI includes columns that are not on this list, they are automatically appended at runtime.
   * Columns that do not exist in UI are ignored.
   */
  columns?: OrgadminColumnConfiguration[];

  /**
   * Defines the order and available set of filters for the data table.
   * If UI includes filters that are not on this list, they are automatically appended at runtime.
   * Filters that do not exist in UI are ignored.
   */
  filters?: OrgadminFilterConfiguration[];

  [key: string]:
    | string
    | boolean
    | number
    | OrgadminActionConfiguration[]
    | OrgadminColumnConfiguration[]
    | OrgadminFilterConfiguration[]
    | undefined;
}

export interface OrgadminMenuItemConfiguration extends OrgadminActionConfiguration {
  // data uri for icon image
  icon?: string;
}
export interface OrgadminHeaderConfiguration {
  /**
   * Toggles the availability of the locale dropdown in header
   */
  "show-locale-switcher"?: boolean;
  /**
   * Defines the menu items for the user-menu. Supports special fully localised internal menu item for My Profile,
   * with `{ key: "my-profile" }`. Unrecognised keys will be considered as custom menu items that will be displayed if
   * they also define an url.
   */
  "user-menu"?: OrgadminMenuItemConfiguration[],
}
/**
 * Describes the topics that can be configured
 */
export interface OrgadminFunctionalityConfiguration {
  header?: OrgadminHeaderConfiguration;
  /**
   * Configuration for the primary sections
   */
  sections?: Array<"groups" | "roles" | "invitations" | "users" | "licenses" | "device-client-groups" | "device-clients">;
  dashboard?: OrgadminSectionConfiguration;
  groups?: OrgadminSectionConfiguration;
  roles?: OrgadminSectionConfiguration;
  invitations?: OrgadminSectionConfiguration;
  users?: OrgadminSectionConfiguration;
  licenses?: OrgadminSectionConfiguration;
  "device-client-groups"?: OrgadminSectionConfiguration;
  "device-clients"?: OrgadminSectionConfiguration;
  /**
   * Configuration for the Localization
   * If missing, defaults are used.
   */
  localization?: OrgadminLocalizationConfiguration;
  /**
   * Enables ui input for license version in both variants of dl license dialog
   */
  enableDownloadLicenseVersion?: boolean;
  /**
   * Enables ui controls for releasing license leases
   */
  enableReleaseLicenseLease?: boolean;
}

export interface OrgadminSecurityConfiguration {
  /**
   * Maximum allowed leeway / clock skew when authenticating and checking iat (issued at)
   * timestamp of OpenID Connect ID token. The value is in seconds.
   */
  idTokenIatLeeway?: number;
}
export interface IdentityApiConfiguration {
  /**
   * Adds selected organization id as header for relevant API calls
   */
  includeOrganizationIdHeader?: boolean;
}

/**
 * Configuration for the SsoUIDefault Webapp
 */
export interface OrgadminConfiguration {
  /**
   * The version of the app
   */
  version?: string;
  /**
   * Name of the application/service. To be used when ever the service is being identified as text. If left out, falls back to the
   * `service-name` in `branding.json` or finally the `service-name` defined in default conf.
   */
  "service-name"?: string;

  /**
   * Wraps the actual configurable topics
   * If missing, defaults are used.
   */
  functionality?: OrgadminFunctionalityConfiguration;

  /**
   * Security related configurations.
   * If missing, defaults are used.
   */
  security?: OrgadminSecurityConfiguration;

  /**
   * Identity API configurations.
   * If missing, defaults are used.
   */
  "identity-api"?: IdentityApiConfiguration;
}

/**
 * Intentionally defined in a separate JSON for easy copy-paste base for configs and to enable use in e.g. build tools
 */
export const DEFAULT_UI_CONFIGURATION: OrgadminConfiguration =
  DEFAULT_UI_CONFIGURATION_json as OrgadminConfiguration;
