import { defineMessages } from "react-intl";

export const UserLicenseAssignmentRequestErrors = {
  ...defineMessages({
    licenseQuotaExceeded: {
      defaultMessage: "There are no available licenses.",
      description:
        "Error message for failed license reservation due to not having any available licenses",
    },
    licenseExpired: {
      defaultMessage: "The license has expired.",
      description:
        "Error message for failed license reservation due to expired license",
    },
    licenseNotActive: {
      defaultMessage: "The license is not active.",
      description:
        "Error message for failed license reservation due to license not being active",
    },
    reassigningNotAllowed: {
      defaultMessage:
        "Releasing the seat is not permitted because the license model doesn't allow seat re-assignment.",
      description:
        "Error message for failed license assignment due to re-assignment being disabled",
    },
  }),
};
