import { connect } from "react-redux";
import OrganizationUtils from "../../../utils/organization";
import {
  RemoveGroupModalDataProps,
  RemoveGroupModalProps as _RemoveGroupModalProps,
} from "./remove-group-modal-view";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import {
  clearError,
  deleteOrganizationGroup,
  getOrganizationGroup,
  listOrganizationGroups,
} from "../../../actions";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";
import RemoveGroupModal from "./remove-group-modal-container";
import InProgressUtils from "../../../utils/in-progress";

export interface RemoveGroupModalProps
  extends Omit<_RemoveGroupModalProps, keyof RemoveGroupModalDataProps> {
  // for some reason in this case the omit does not remove all keys with keyof  RemoveGroupModalDataProps like it does
  // elsewhere. So far I have not been able to figure out a way to inspect/debug what is actually there to be removed.
}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _RemoveGroupModalProps,
  RemoveGroupModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _RemoveGroupModalProps,
  RemoveGroupModalProps
>;

const sender: ActionSender = { key: "remove-group" };

function mapStateToProps(
  state: AppState,
  ownProps: RemoveGroupModalProps
): ReduxStateProps {
  return {
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    group: ownProps.groupId
      ? OrganizationUtils.selectOrganizationGroup(ownProps.groupId, state)
      : undefined,
    employeesGroupId:
      OrganizationUtils.selectOrganizationEmployeesGroupId(state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationGroupsStates(next, prev)
  );
}
const dispatchActions: ReduxDispatchProps = {
  onRemoveGroup: addSenderArgument(sender, deleteOrganizationGroup),
  onLoadGroup: addSenderArgument(sender, getOrganizationGroup),
  onLoadGroups: addSenderArgument(sender, listOrganizationGroups),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  RemoveGroupModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(RemoveGroupModal);
