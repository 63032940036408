import * as ActionTypes from "./actionTypes";
import { ActionSender } from "../model/ActionSender";
import {
  ensureSelectedOrgId,
  buildMultiActionThunk,
  EventOperation,
  EventOperationProvider
} from "./actionHelpers";
import {queryClientAvailableLicenses, queryLicenseUsage} from "./entActions";

/**
 * Query available licenses for client followed by query license usage for each available license.
 *
 * @param sender Sender of action.
 * @param clientId ID of client
 * @param orgId Target organization to limit results.
 */
export function queryClientAvailableLicensesWithUsage(
  sender: ActionSender,
  clientId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.MultiAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);

  return buildMultiActionThunk(
    sender,
    new MultiActionProvider(sender, clientId, orgIdOrDefault),
    false
  );
}

enum StateOfMultiAction {
  INIT = 0,
  QUERY_CLIENT_AVAILABLE_LICENSES,
  QUERY_CLIENT_LICENSE_USAGES,
  DONE
}

class MultiActionProvider
  implements EventOperationProvider<ActionTypes.AppAction> {
  private state: StateOfMultiAction;
  private sender: ActionSender;
  private clientId: string;
  private orgId: string;
  private queryLicenseUsageOperations: EventOperation<ActionTypes.AppAction>[];

  constructor(sender: ActionSender, clientId: string, orgId: string) {
    this.state = StateOfMultiAction.INIT;
    this.sender = sender;
    this.clientId = clientId;
    this.orgId = orgId;
    this.queryLicenseUsageOperations = [];
  }

  /**
   * Method for providing next thunk/function to be executed in this chain.
   * @param sender
   * @param multiAction
   */
  next(
    sender: ActionSender,
    multiAction: ActionTypes.MultiAction
  ): EventOperation<ActionTypes.AppAction> | null {
    if (this.state === StateOfMultiAction.INIT) {
      this.state = StateOfMultiAction.QUERY_CLIENT_AVAILABLE_LICENSES;
      return {
        thunk: queryClientAvailableLicenses(this.sender, this.clientId, this.orgId)
      };
    }

    if (this.state === StateOfMultiAction.QUERY_CLIENT_AVAILABLE_LICENSES) {
      this.queryLicenseUsageOperations = this.buildQueryLicenseUsageOperations(
        multiAction.results[0] as ActionTypes.QueryClientAvailableLicensesAction
      );

      this.state = StateOfMultiAction.QUERY_CLIENT_LICENSE_USAGES;
    }

    if (this.state === StateOfMultiAction.QUERY_CLIENT_LICENSE_USAGES) {
      if (this.queryLicenseUsageOperations.length !== 0) {
        return this.queryLicenseUsageOperations.pop() as EventOperation<
          ActionTypes.AppAction
        >;
      } else {
        this.state = StateOfMultiAction.DONE;
      }
    }

    return null;
  }

  /**
   * Build required operations based on data returned by getAvailableLicenses() action thunk.
   *
   * @param availableLicensesAction
   */
  private buildQueryLicenseUsageOperations(
    availableLicensesAction: ActionTypes.QueryClientAvailableLicensesAction
  ): EventOperation<ActionTypes.AppAction>[] {
    const ops: EventOperation<ActionTypes.AppAction>[] = [];

    for (const license of availableLicensesAction.licenses) {
      // Create thunks to execute.
      ops.push({
        thunk: queryLicenseUsage(
            this.sender,
            "any",
            license.id as string,
            this.orgId
        )
      });
    }

    return ops;
  }
}
