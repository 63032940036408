import { connect } from "react-redux";
import ImportCSVModal, {
  ImportCSVModalProps as _ImportCSVModalProps,
} from "./import-csv-modal-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import OrganizationUtils from "../../../utils/organization";
import {
  clearError,
  importOrganizationUsers,
  listOrganizationGroups,
} from "../../../actions";
import { ImportCSVModalDataProps } from "./import-csv-modal-container";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";

export type ImportCSVModalProps = Omit<
  _ImportCSVModalProps,
  keyof ImportCSVModalDataProps
>;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ImportCSVModalProps,
  ImportCSVModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ImportCSVModalProps,
  ImportCSVModalProps
>;

const sender: ActionSender = { key: "import-users" };

function mapStateToProps(
  state: AppState,
  ownProps: ImportCSVModalProps
): ReduxStateProps {
  return {
    groups: OrganizationUtils.selectOrganizationGroups(state),
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationGroupsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadGroups: addSenderArgument(sender, listOrganizationGroups),
  onImportUsers: addSenderArgument(sender, importOrganizationUsers),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ImportCSVModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ImportCSVModal);
