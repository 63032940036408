import { connect } from "react-redux";
import CreateDeviceClientGroupModal, {
  CreateDeviceClientGroupModalProps as _CreateDeviceClientGroupModalProps,
} from "./create-device-client-group-modal-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import OrganizationUtils from "../../../utils/organization";
import {
  listOrganizationEntitlements,
  setEntitlementsConsumedByCliGroup,
} from "../../../actions/entActions";
import {
  CreateDeviceClientGroupModalDataProps,
} from "./create-device-client-group-modal-view";
import {clearError, createOrganizationClientGroup} from "../../../actions";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";

export type CreateDeviceClientGroupModalProps = Omit<
  _CreateDeviceClientGroupModalProps,
  keyof CreateDeviceClientGroupModalDataProps
>;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _CreateDeviceClientGroupModalProps,
  CreateDeviceClientGroupModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _CreateDeviceClientGroupModalProps,
  CreateDeviceClientGroupModalProps
>;

const sender: ActionSender = { key: "create-device-client-group" };

function mapStateToProps(
  state: AppState
): ReduxStateProps {
  return {
    organizationId: OrganizationUtils.selectSelectedOrganizationId(state),
    entitlements: OrganizationUtils.selectOrganizationEntitlements(state),
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    OrganizationUtils.compareSelectedOrganizationIdStates(next, prev) &&
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationEntitlementsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadEntitlements: addSenderArgument(sender, listOrganizationEntitlements),
  onSetClientGroupEntitlements: addSenderArgument(
      sender,
      setEntitlementsConsumedByCliGroup
  ),
  onCreateDeviceClientGroup: addSenderArgument(sender, createOrganizationClientGroup),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  CreateDeviceClientGroupModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(CreateDeviceClientGroupModal);
