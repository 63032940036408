import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {
  buildActionThunk,
  ensureSelectedOrgId,
  forceUndefined
} from "./actionHelpers";
import { UserForCreate } from "../model/User";

/**
 * Removes a user from an organization. This removes the user from all groups.
 * Role memberships are not affected.
 * @param sender Component requesting for the action
 * @param userId The user id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function removeUserFromOrganization(
  sender: ActionSender,
  userId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.RemoveUserFromOrgAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.RemoveUserFromOrgAction,
    UserRemovalResult
  >(
    sender,
    ActionTypes.REMOVE_USER_FROM_ORG,
    async () =>
      await removeUserFromOrganizationInternal(userId, orgIdOrDefault),
    (type, result) => ({
      type,
      orgId: orgIdOrDefault,
      userId,
      orgGroupIds: forceUndefined(result?.orgGroupIds),
      orgRoleIds: forceUndefined(result?.orgRoleIds)
    })
  );
}

interface UserRemovalResult {
  orgGroupIds: string[];
  orgRoleIds: string[];
}

async function removeUserFromOrganizationInternal(
  userId: string,
  orgId: string
): Promise<UserRemovalResult> {
  const orgGroupIdsOfUser = await removeUserFromOrganizationGroupsInternal(
    userId,
    orgId
  );

  const orgRolesIdsOfUser = await removeUserFromOrganizationRolesInternal(
    userId,
    orgId
  );

  return { orgGroupIds: orgGroupIdsOfUser, orgRoleIds: orgRolesIdsOfUser };
}

/**
 * Remove user from all groups of organization.
 * @param userId
 * @param orgId
 */
function removeUserFromOrganizationGroupsInternal(
  userId: string,
  orgId: string
): Promise<string[]> {
  return new Promise<string[]>((resolve, reject) => {
    Promise.all([
      idpApi.listOrganizationGroups(orgId),
      idpApi.listOrganizationGroupsOfUser(userId, orgId)
    ])
      .then(results => {
        const groupsOfOrg = results[0];
        const groupsOfUser = results[1];
        const orgGroupIds = groupsOfOrg.map(group => group.id as string);
        const orgGroupIdsOfUser = groupsOfUser
          .filter(group => orgGroupIds.indexOf(group.id as string) !== -1)
          .map(group => group.id as string);
        const removeRequests = orgGroupIdsOfUser.map(orgGroupId =>
          idpApi.removeOrganizationGroupOfUser(orgGroupId, userId)
        );
        Promise.all(removeRequests)
          .then(removeResults => {
            resolve(orgGroupIdsOfUser);
          })
          .catch((reason: any) => {
            reject(reason);
          });
      })
      .catch((reason: any) => {
        reject(reason);
      });
  });
}

/**
 * Remove user from all groups of organization.
 * @param userId
 * @param orgId
 */
async function removeUserFromOrganizationRolesInternal(
  userId: string,
  orgId: string
) {
  const orgRolesOfUser: string[] = [];

  const orgRoles = await idpApi.listOrganizationRoles(orgId);
  orgRoles.forEach(async orgRole => {
    const roleMembers = await idpApi.listUsersInOrganizationRole(
      orgRole.id as string,
      orgId
    );
    const roleMemberIds = roleMembers.map(role => role.id as string);
    if (roleMemberIds.includes(userId)) {
      await idpApi.removeUserFromOrganizationRole(
        orgRole.id as string,
        userId,
        orgId
      );
      orgRolesOfUser.push(orgRole.id as string);
    }
  });

  return orgRolesOfUser;
}

/**
 * Import users to organization.
 * If any given UserForCreate object has groupIds undefined then that
 * user will be added to employees group by default. If groupIds is empty
 * array, then user will not be added to any group.
 *
 * @param sender Component requesting for the action
 * @param usersForCreate Users to be imported.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function importOrganizationUsers(
  sender: ActionSender,
  usersForCreate: UserForCreate[],
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ImportOrganizationUsersAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.ImportOrganizationUsersAction,
    UserForCreate[]
  >(
    sender,
    ActionTypes.IMPORT_ORGANIZATION_USERS,
    async () =>
      await idpApi.importOrganizationUsers(usersForCreate, orgIdOrDefault),
    (type, users) => ({
      type,
      users: forceUndefined(users),
      orgId: orgIdOrDefault
    })
  );
}
