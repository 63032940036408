import { defineMessages } from "react-intl";

/**
 * Localized labels for "UI Model" AggregatedUseCountCredit fields
 */
export const AggregatedUseCountCreditLabels = {
  // Add LicenseCreditBase if needed
  ...defineMessages({
    useCount: {
      defaultMessage: "use count: total",
      description:
        "Field label for total sum of use count credits (AggregatedUseCountCredit.useCount)"
    },
    countUsed: {
      defaultMessage: "use count: consumed",
      description:
        "Field label for sum of consumed use count credits (AggregatedUseCountCredit.countUsed)"
    },
    useCountAvailable: {
      defaultMessage: "use count: available",
      description:
        "Field label for sum of available use count credits (AggregatedUseCountCredit.useCountAvailable)"
    }
  })
};
