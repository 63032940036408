import { useState } from "react";
import FilterToolsView, {
  FilterToolsProps as _FilterToolsProps,
  FilterToolsVisibilityProps,
} from "./filter-tools-view";

type FilterToolsProps = Omit<
  _FilterToolsProps,
  keyof FilterToolsVisibilityProps
>;

export default function FilterTools(props: FilterToolsProps) {
  const [showFilterTool, setShowFilterTool] = useState(false);

  return (
    <FilterToolsView
      {...props}
      show={showFilterTool}
      onHide={() => {
        setShowFilterTool(false);
      }}
      onShow={() => {
        setShowFilterTool(true);
      }}
    />
  );
}
