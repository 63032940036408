import { OrganizationGroup } from "../../../model/OrganizationGroup";
import { ORG_1_USERS } from "./org1Members";

/*
 * Organization groups of mock org 1, "Acme Inc".
 */

export const ORG_1_GROUP_EMPLOYEES: OrganizationGroup = {
  id: "29c5ca8c-c0d5-44cc-994b-fc6230dfac80",
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-04-15T08:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-04-15T09:00:00.000Z",
  name: "Employees of Acme Inc",
  type: "employees",
  description: "Group containing all employees of Acme Inc"
};

const INITIAL_ORG_1_GROUP_EMPLOYEES_MEMBER_IDS: string[] = ORG_1_USERS.map(
  user => user.id as string
);

export const ORG_1_GROUP_LICENSE_CONSUMERS: OrganizationGroup = {
  id: "ed01903f-59c5-46ed-a516-763238272ce3",
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-04-16T08:00:00.000Z",
  name: "License consumers",
  type: "licenseConsumers",
  description: "Users entitled to consume some licenses"
};

export const ORG_1_GROUP_SECONDARY_LICENSE_CONSUMERS: OrganizationGroup = {
  id: "582c1ff2-8036-41a3-b5fc-b196489bcf30",
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-04-16T08:00:00.000Z",
  name: "Secondary license consumers",
  type: "licenseConsumers",
  description: "Users entitled to consume some secondary licenses"
};

const INITIAL_ORG_1_GROUP_LICENSE_CONSUMERS_MEMBER_IDS: string[] = [
  ORG_1_USERS[0].id as string,
  ORG_1_USERS[1].id as string
];

export const ORG_1_GROUPS: OrganizationGroup[] = [
  ORG_1_GROUP_EMPLOYEES,
  ORG_1_GROUP_LICENSE_CONSUMERS,
  ORG_1_GROUP_SECONDARY_LICENSE_CONSUMERS
];

export const ORG_1_GROUP_MEMBER_IDS_BY_GROUP_ID = {
  [ORG_1_GROUP_EMPLOYEES.id as string]: INITIAL_ORG_1_GROUP_EMPLOYEES_MEMBER_IDS,
  [ORG_1_GROUP_LICENSE_CONSUMERS.id as string]: INITIAL_ORG_1_GROUP_LICENSE_CONSUMERS_MEMBER_IDS,
  [ORG_1_GROUP_SECONDARY_LICENSE_CONSUMERS.id as string]: []
};
