import { useEffect, useRef, useState } from "react";
import View, {
  ManageUsersGroupsModalProps as _ManageUsersGroupsModalProps,
  ManageUsersGroupsModalStateProps,
} from "./manage-users-groups-modal-view";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
import _ from "lodash";
import { withCloseAfterExited } from "@10duke/dukeui";

const isArrayEqual = (x: Array<any>, y: Array<any>) => {
  return _(x).xorWith(y, _.isEqual).isEmpty();
};
const ViewWithCloseAfterExited =
  withCloseAfterExited<_ManageUsersGroupsModalProps>(View);
export type ManageUsersGroupsModalProps = Omit<
  _ManageUsersGroupsModalProps,
  keyof ManageUsersGroupsModalStateProps
>;

export default function ManageUsersGroupsModal(
  props: ManageUsersGroupsModalProps
) {
  const { show, usersGroups, groups, ...other } = props;

  const groupsRef = useRef(groups);
  if (!_.isEqual(groupsRef.current, groups)) {
    groupsRef.current = groups;
  }
  const groupsRefCurrent = groupsRef.current;

  const usersGroupsRef = useRef(usersGroups);
  if (!_.isEqual(usersGroupsRef.current, usersGroups)) {
    usersGroupsRef.current = usersGroups;
  }
  const usersGroupsRefCurrent = usersGroupsRef.current;

  const [activeSearch, onSetActiveSearch] = useState("");
  const [editedGroups, setEditedGroups] = useState<string[] | undefined>(
    usersGroups
  );
  const [groupsToRemove, setGroupsToRemove] = useState<OrganizationGroup[]>([]);
  const [groupsToAdd, setGroupsToAdd] = useState<OrganizationGroup[]>([]);

  useEffect(() => {
    setEditedGroups((cur) => {
      if (
        cur &&
        usersGroupsRefCurrent &&
        isArrayEqual(cur, usersGroupsRefCurrent)
      ) {
        setGroupsToRemove((v) =>
          v.filter((r) =>
            groupsRefCurrent
              ? groupsRefCurrent.findIndex((g) => r.id === g.id) >= 0
              : false
          )
        );
        setGroupsToAdd((v) =>
          v.filter((r) =>
            groupsRefCurrent
              ? groupsRefCurrent.findIndex((g) => r.id === g.id) >= 0
              : false
          )
        );
        return cur.filter((v) =>
          groupsRefCurrent
            ? groupsRefCurrent.findIndex((g) => g.id === v) >= 0
            : false
        );
      } else {
        setGroupsToRemove([]);
        setGroupsToAdd([]);
        return usersGroupsRefCurrent
          ? _.cloneDeep(usersGroupsRefCurrent)
          : usersGroupsRefCurrent;
      }
    });
  }, [usersGroupsRefCurrent, groupsRefCurrent, setEditedGroups]);

  useEffect(() => {
    if (!show) {
      setEditedGroups(undefined);
      setGroupsToRemove([]);
      setGroupsToAdd([]);
      onSetActiveSearch("");
    }
  }, [show, setEditedGroups]);

  const onSetGroups = (sgroups: OrganizationGroup[]) => {
    let newEdited: string[] | undefined = sgroups.map((u) => u.id as string);
    if (newEdited) {
      setGroupsToRemove(
        (usersGroups && newEdited
          ? usersGroups.filter((x) =>
              newEdited ? !newEdited.includes(x) : false
            )
          : []
        )
          .map((id) => {
            return groups ? groups.find((val) => val.id === id) : undefined;
          })
          .filter((val) => val !== undefined) as OrganizationGroup[]
      );
      setGroupsToAdd(
        (newEdited
          ? newEdited.filter((x) =>
              usersGroups ? !usersGroups.includes(x) : false
            )
          : []
        )
          .map((id) => {
            return groups ? groups.find((val) => val.id === id) : undefined;
          })
          .filter((val) => val !== undefined) as OrganizationGroup[]
      );
    } else {
      console.error("this should never happen");
    }
    setEditedGroups(newEdited);
  };
  return (
    <ViewWithCloseAfterExited
      {...other}
      show={show}
      usersGroups={editedGroups}
      groups={groups}
      groupsToRemove={groupsToRemove}
      groupsToAdd={groupsToAdd}
      onSetActiveSearch={onSetActiveSearch}
      activeSearch={activeSearch}
      onSetSelected={onSetGroups}
      selected={
        editedGroups && groups
          ? (editedGroups
              .map((g) => groups.find((v) => v.id === g))
              .filter((v) => !!v) as OrganizationGroup[])
          : []
      }
    />
  );
}
