import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {
  buildActionThunk,
  ensureSelectedOrgId,
  forceUndefined,
} from "./actionHelpers";
import {ClientGroup} from "../model/ClientGroup";
import {DeleteClientGroupResult} from "../api/IdpApi";

export function listOrganizationClientGroups(
    sender: ActionSender,
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListOrgClientGroupsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ListOrgClientGroupsAction, ClientGroup[]>(
      sender,
      ActionTypes.LIST_ORG_CLIENT_GROUPS,
      async () => await idpApi.listOrganizationClientGroups(orgIdOrDefault),
      (type, groups) => ({
        type,
        clientGroups: forceUndefined(groups),
        orgId: orgIdOrDefault,
      })
  );
}

export function replaceOrganizationClientGroup(
    sender: ActionSender,
    orgClientGroup: ClientGroup,
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ReplaceOrgClientGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ReplaceOrgClientGroupAction, ClientGroup>(
      sender,
      ActionTypes.REPLACE_ORG_CLIENT_GROUP,
      async () => await idpApi.replaceOrganizationClientGroup(orgIdOrDefault,orgClientGroup),
      (type, group) => ({
        type,
        group: forceUndefined(orgClientGroup),
        orgId: orgIdOrDefault,
      })
  );
}

export function createOrganizationClientGroup(
    sender: ActionSender,
    clientGroup: ClientGroup,
    orgId?: string,
): ActionTypes.AppThunkAction<ActionTypes.CreateOrgClientGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.CreateOrgClientGroupAction, ClientGroup>(
      sender,
      ActionTypes.CREATE_ORG_CLIENT_GROUP,
      async () => await idpApi.createOrganizationClientGroup(orgIdOrDefault, clientGroup),
      (type, clientGroup) => ({
        type,
        clientGroup: forceUndefined(clientGroup),
        orgId: orgIdOrDefault,
      })
  );
}

export function getOrganizationClientGroup(
    sender: ActionSender,
    orgClientGroupId: string,
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.GetOrgClientGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.GetOrgClientGroupAction, ClientGroup>(
      sender,
      ActionTypes.GET_ORG_CLIENT_GROUP,
      async () => await idpApi.getOrganizationClientGroup(orgClientGroupId, orgIdOrDefault),
      (type, group) => ({
        type,
        group: forceUndefined(group),
        orgId: orgIdOrDefault,
        orgClientGroupId: orgClientGroupId,
      })
  );
}
export function deleteOrganizationClientGroup(
    sender: ActionSender,
    orgClientGroupId: string,
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.DeleteOrgClientGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
      ActionTypes.DeleteOrgClientGroupAction,
      DeleteClientGroupResult
  >(
      sender,
      ActionTypes.DELETE_ORG_CLIENT_GROUP,
      async () =>
          await idpApi.deleteOrganizationClientGroup(orgClientGroupId, orgIdOrDefault),
      (type, data) => ({
        type,
        orgClientGroupId,
        orgId: orgIdOrDefault
      })
  );
}

