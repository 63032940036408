import * as ActionTypes from "../actions/actionTypes";
import {OrgClientGroupIdsByOrgId} from "../store/ClientGroupState";

export default function orgClientGroupIdsReducer(
  state: OrgClientGroupIdsByOrgId,
  action: ActionTypes.AppAction
): OrgClientGroupIdsByOrgId | null {
  const currentState = state || ({} as OrgClientGroupIdsByOrgId);
  switch (action.type) {
    case ActionTypes.LIST_ORG_CLIENT_GROUPS:
      const listOrgClientGroups = action as ActionTypes.ListOrgClientGroupsAction;
      const newOrgClientGroupIds = listOrgClientGroups.clientGroups.map(
        clientGroup => clientGroup.id as string
      );
      return { ...currentState, [listOrgClientGroups.orgId]: newOrgClientGroupIds };
    case ActionTypes.CREATE_ORG_CLIENT_GROUP:
      const createOrgGroup = action as ActionTypes.CreateOrgClientGroupAction;
      const beforeCreate = currentState[createOrgGroup.orgId] || [];
      const afterCreate = [...beforeCreate, createOrgGroup.clientGroup.id as string];
      return {
        ...currentState,
        [createOrgGroup.orgId]: afterCreate
      };
    case ActionTypes.DELETE_ORG_CLIENT_GROUP:
      const delOrgClientGroup = action as ActionTypes.DeleteOrgClientGroupAction;
      return deleteOrgClientGroup(
          delOrgClientGroup.orgId,
          delOrgClientGroup.orgClientGroupId,
          currentState
      );
    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

function deleteOrgClientGroup(
  orgId: string,
  orgClientGroupId: string,
  currentState: OrgClientGroupIdsByOrgId
): OrgClientGroupIdsByOrgId {
  const beforeDelete = currentState[orgId] || [];
  const afterDelete = beforeDelete.filter(groupId => groupId !== orgClientGroupId);
  return {
    ...currentState,
    [orgId]: afterDelete
  };
}

function handleErrorAction(
  currentState: OrgClientGroupIdsByOrgId,
  action: ActionTypes.AppAction
): OrgClientGroupIdsByOrgId {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  if (
    errorAction.error.apiError.error === "404" &&
    errorAction.error.action.type === ActionTypes.GET_ORG_CLIENT_GROUP
  ) {
    const typedError = action as ActionTypes.AddErrorAction<
      ActionTypes.GetOrgClientGroupAction
    >;
    finalState = deleteOrgClientGroup(
      typedError.error.action?.orgId as string,
      typedError.error.action?.orgClientGroupId as string,
      currentState
    );
  }


  return finalState;
}
