import ViewLicenseModal, {
  ViewLicenseModalProps as _ViewLicenseModalProps,
  ViewLicenseModalDataProps,
} from "./view-license-modal-view";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import { ActionSender } from "../../../model/ActionSender";
import { AppState } from "../../../store/AppState";
import InProgressUtils from "../../../utils/in-progress";
import { connect } from "react-redux";
import LicenseUtils from "../../../utils/license";
import {
  listOrganizationEntitlements,
  queryLicenseUsage,
} from "../../../actions/entActions";
import { LicenseWithCreditsAndEntitlementName } from "../../pages/licenses/licenses-view";
import { Entitlement } from "../../../model/entitlement/Entitlement";
import OrganizationUtils from "../../../utils/organization";
import { addSenderArgument } from "../../../actions/actionHelpers";
import {
  aggregateValidSeatCountCreditDataForLicense,
  aggregateValidUseCountCreditDataForLicense,
  aggregateValidUseTimeCreditDataForLicense,
} from "../../../util/licenseUtil";
import { LicenseWithCredits } from "../../../model/entitlement/LicenseWithCredits";
import { clearError } from "../../../actions";

export interface ViewLicenseModalProps
  extends Omit<_ViewLicenseModalProps, keyof ViewLicenseModalDataProps> {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ViewLicenseModalProps,
  ViewLicenseModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ViewLicenseModalProps,
  ViewLicenseModalProps
>;
const sender: ActionSender = { key: "view-license" };

function mapStateToProps(
  state: AppState,
  ownProps: ViewLicenseModalProps
): ReduxStateProps {
  let l = ownProps.licenseId
    ? (LicenseUtils.selectLicenseWithUsageAndAssignments(
        ownProps.licenseId,
        state
      ) as LicenseWithCreditsAndEntitlementName)
    : undefined;

  const entitlements: Entitlement[] | undefined =
    OrganizationUtils.selectOrganizationEntitlements(state);
  if (!!l) {
    l = aggregateValidUseTimeCreditDataForLicense(
      aggregateValidUseCountCreditDataForLicense(
        aggregateValidSeatCountCreditDataForLicense(l as LicenseWithCredits)
      )
    ) as LicenseWithCreditsAndEntitlementName;
    if (entitlements && l.entitlementId) {
      // useless sanity check must be included for the license to avoid incorrect error output here.
      const ent = entitlements.find((v) => !!l && v.id === l.entitlementId);
      (l as LicenseWithCreditsAndEntitlementName).entitlementName = ent
        ? ent.name
        : undefined;
    } else {
      (l as LicenseWithCreditsAndEntitlementName).entitlementName = undefined;
    }
    const creditTypes = [];
    if (l.effectiveLicenseModel) {
      if (l.effectiveLicenseModel.credits?.type === 'seatCount') {
        creditTypes.push("seat");
      }
      if (l.effectiveLicenseModel.credits?.type === 'useCount') {
        creditTypes.push("count");
      }
      if (l.effectiveLicenseModel.credits?.type === 'useTime') {
        creditTypes.push("time");
      }
    }
    if (creditTypes.length === 0) {
      creditTypes.push("seat");
    }
    (l as LicenseWithCreditsAndEntitlementName).creditTypes = creditTypes;
  }

  return {
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    license: l,
    entitlementsLoaded: entitlements !== undefined,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationEntitlementsStates(next, prev) &&
    LicenseUtils.compareLicensesStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadLicense: (licenseId: string) => {
    return queryLicenseUsage(sender, "any", licenseId);
  },
  onLoadEntitlements: addSenderArgument(sender, listOrganizationEntitlements),
  onClearError: clearError,
};
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ViewLicenseModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ViewLicenseModal);
