import {useCallback, useState} from "react";
import {FeedbackContainerProps,withFeedback} from "@10duke/dukeui";
import InvitationsView, {
  InvitationsProps as _InvitationsProps,
  InvitationsStateProps,
  InvitationsDOMProps as _InvitationsDOMProps,
  InvitationsModalVisibilityProps as _InvitationsModalVisibilityProps, UIInvitation,
} from "./invitations-view";
export { ModalKeys } from "./invitations-view";

export type InvitationsDOMProps = _InvitationsDOMProps;
export type InvitationsModalVisibilityProps = _InvitationsModalVisibilityProps;

export interface InvitationsProps
  extends Omit<_InvitationsProps, keyof InvitationsStateProps> {
  onLoadDeviceClientGroupInvitations: () => Promise<any>;
}

function Invitations(props: InvitationsProps & FeedbackContainerProps) {
  const {
    onLoadInvitations,
    onLoadDeviceClientGroupInvitations,
    ...other
  } = props;
  const [selected, setSelected] = useState([] as UIInvitation[]);
  const onLoadInvs = useCallback(() => {
    const promises = [];
    if (other.enableUsers) {
      promises.push(onLoadInvitations())
    }
    if (other.enableClients) {
      promises.push(onLoadDeviceClientGroupInvitations())
    }
    return Promise.all(promises);
  }, [other.enableClients,other.enableUsers, onLoadInvitations, onLoadDeviceClientGroupInvitations]);
  const viewProps: _InvitationsProps = {
    selected: selected,
    onSetSelected: setSelected,
    onLoadInvitations: onLoadInvs,
    ...other
  };
  return <InvitationsView {...viewProps} />;
}
export default withFeedback<InvitationsProps & FeedbackContainerProps>(
  Invitations
);
