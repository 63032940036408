import { connect } from "react-redux";
import OrganizationUtils from "../../../utils/organization";
import DeviceClientGroups, {
  DeviceClientGroupsDOMProps,
  DeviceClientGroupsProps as _DeviceClientGroupsProps,
  DeviceClientGroupsModalVisibilityProps
} from "./device-client-groups-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../../util/typeUtil";
import {listOrganizationClientGroups} from "../../../actions";
import { ActionSender } from "../../../model/ActionSender";
import AuthenticationUtils from "../../../utils/authentication";
import { addSenderArgument } from "../../../actions/actionHelpers";

export { ModalKeys } from "./device-client-groups-container";

export interface DeviceClientGroupsProps
  extends DeviceClientGroupsDOMProps,
      DeviceClientGroupsModalVisibilityProps {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_DeviceClientGroupsProps, DeviceClientGroupsProps>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<_DeviceClientGroupsProps, DeviceClientGroupsProps>;

const sender: ActionSender = { key: "device-client-groups" };

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    userName: AuthenticationUtils.selectAuthenticationUserDisplayName(state),
    userId: AuthenticationUtils.selectAuthenticationUserId(state),
    clientGroups: OrganizationUtils.selectOrganizationClientGroups(state)
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    AuthenticationUtils.compareAuthenticationUserDisplayNameStates(
      next,
      prev
    ) &&
    AuthenticationUtils.compareAuthenticationUserIdStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientGroupsStates(next, prev)
  );
}
const dispatchActions: ReduxDispatchProps = {
  onLoadClientGroups: addSenderArgument(sender, listOrganizationClientGroups)
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  DeviceClientGroupsProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual
})(DeviceClientGroups);
