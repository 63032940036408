import { defineMessages } from "react-intl";

/**
 * Localized labels for AuthorEditorFields fields
 */
export const DownloadLicenseRequestLabels = {
  ...defineMessages({
    hardwareId: {
      defaultMessage: "Hardware id",
      description:
        "Field label for Hardware identifier (DownloadLicenseRequest.hardwareId)",
    },
    version: {
      defaultMessage: "Version",
      description: "Field label for version (DownloadLicenseRequest.version)",
    },
  }),
};

export const DownloadLicenseRequestErrors = {
  ...defineMessages({
    licenseAssignmentMissing: {
      defaultMessage:
        "The license requires the user to have a license reservation before a license can be downloaded.",
      description:
        "Error message for failed license download due to missing required reservation",
    },
    licenseAssignmentDenied: {
      defaultMessage:
        "The license requires the user to have a license reservation before a license can be downloaded and/or the user has been blocked from using the license.",
      description:
        "Error message for failed license download due to missing required reservation or when the user has been blocked from using the license",
    },
    noLicenseFound: {
      defaultMessage:
        "The user requesting the license is not authorised to consume the license.",
      description:
        "Error message for failed license download due to missing license",
    },
    notAuthorized: {
      defaultMessage:
        "The user requesting the license is not authorised to consume the license.",
      description:
        "Error message for failed license download due to user not being authorized to consume the license",
    },
    consumptionLimitExceed: {
      defaultMessage:
        "The number of license consumptions allowed for this user has been reached.",
      description:
        "Error message for failed license download due to maximum number of license consumptions being reached",
    },
    maxConcurrentSessionsExceed: {
      defaultMessage:
        "The number of concurrent license consumptions allowed has been reached.",
      description:
        "Error message for failed license download due to maximum number of concurrent license consumptions being reached",
    },
    licenseExpired: {
      defaultMessage: "The license has expired.",
      description:
        "Error message for failed license download due to expired license",
    },
    licenseNotActive: {
      defaultMessage: "The license is not active.",
      description:
        "Error message for failed license download due to license not being active",
    },
    licenseQuotaExceeded: {
      defaultMessage: "There are no available licenses.",
      description:
        "Error message for failed license download due to not having any available licenses",
    },
    unallowedClientVersion: {
      defaultMessage:
        "The license cannot be downloaded because it requires version information.",
      description:
        "Error message for failed license download due to missing required version information",
    },
  }),
};
