import { connect } from "react-redux";
import { AppState } from "../../../store/AppState";
import OrganizationUtils from "../../../utils/organization";
import UsersCard, {
  UsersCardProps as _UsersCardProps,
} from "./users-card-view";

import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import {
  listOrganizationRolesWithUsers,
  listOrganizationUsers,
} from "../../../actions";
import { User } from "../../../model/User";
import UserUtils from "../../../utils/user";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";

export interface UsersCardProps extends Pick<_UsersCardProps, "moreAction"> {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_UsersCardProps, UsersCardProps>;

// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _UsersCardProps,
  UsersCardProps
>;

const sender: ActionSender = { key: "usersCard" };

function mapStateToProps(state: AppState): ReduxStateProps {
  let users = OrganizationUtils.selectOrganizationUsers(state);
  const orgAdmins = OrganizationUtils.selectOrganizationAdminIds(state, false);

  const usersWithOtherType = !!users
    ? UserUtils.usersWithStatus(
        users.map((usr) => {
          const u = !!usr ? { ...usr } : usr;
          (u as any).isOrgAdmin = orgAdmins
            ? orgAdmins.indexOf(u.id as string) !== -1
            : false;
          return u;
        })
      )
    : users;

  const cur = new Date().getTime();
  const inactiveCount = !!users
    ? users.filter((u: User) => {
        return u.lastSignOn
          ? cur - new Date(u.lastSignOn).getTime() > 30 * 24 * 60 * 60 * 1000
          : true;
      }).length
    : 0;
  const suspendedCount = !!users
    ? users.filter((u: User) => {
        let validUntil;
        if (
          u.validUntil !== null &&
          u.validUntil !== undefined &&
          u.validUntil !== ""
        ) {
          validUntil = new Date(u.validUntil);
        }
        if (validUntil && cur > validUntil.getTime()) {
          return true;
        } else {
          return false;
        }
      }).length
    : 0;
  return {
    users: usersWithOtherType,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    adminCount: OrganizationUtils.resolveOrgAdminCountFromState(state),
    inactiveCount,
    suspendedCount,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationUsersStates(next, prev) &&
    OrganizationUtils.compareOrganizationRolesStates(next, prev) &&
    OrganizationUtils.compareOrganizationRoleUserIdsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadUsers: addSenderArgument(sender, listOrganizationUsers),
  onLoadAdminCount: addSenderArgument(sender, listOrganizationRolesWithUsers),
};
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  UsersCardProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(UsersCard);
