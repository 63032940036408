import {
  DeleteClientGroupResult,
  DeleteOrganizationGroupResult,
  IdpApi, RemoveClientGroupsOfClientResult,
  RemoveOrgGroupOfUserResult,
  RemoveOrgGroupsOfUserResult,
  RemoveUsersFromOrgGroupResult,
  SetOrgGroupsOfUserResult,
  SetUsersInOrgGroupResult,
} from "../IdpApi";
import { Organization } from "../../model/Organization";
import { User, UserForCreate } from "../../model/User";
import { OrganizationGroup } from "../../model/OrganizationGroup";
import { OrganizationRole } from "../../model/OrganizationRole";
import { OrganizationGroupInvitation } from "../../model/OrganizationGroupInvitation";
import { Permission } from "../../model/Permission";
import { InternalPermissionWithGrantedActions } from "../../model/InternalPermissionWithGrantedActions";
import { PermissionGrantsForPermission } from "../../model/PermissionGrantsForPermission";
import { MOCK_DB } from "./mockData";
import MockBase from "./MockBase";
import { ALL_GROUPS } from "./mockData/allGroups";
import { sleep } from "../../util/sleep";
import {
  getEnvParam,
  REACT_APP_MOCK_DATA_DELAY_MAX,
  REACT_APP_MOCK_DATA_DELAY_MIN,
} from "../../util/env";
import UserUtils from "../../utils/user";
import { generateRandomUuid } from "@10duke/dukeui";
import apiInfo from "../../gen/api/idp/info.json";
import { InternalPermission } from "../../model/InternalPermission";
import {Client} from "../../model/Client";
import {ClientGroup} from "../../model/ClientGroup";
import {ClientGroupInvitation} from "../../model/ClientGroupInvitation";
const delayMin: number = parseInt(
  getEnvParam(REACT_APP_MOCK_DATA_DELAY_MIN, "0")
);
const delayMax: number = parseInt(
  getEnvParam(REACT_APP_MOCK_DATA_DELAY_MAX, "0")
);
async function randomDelay() {
  const t = Math.random() * (delayMax - delayMin) + delayMin;
  if (t) {
    await sleep(t);
  }
  return;
}

class MockIdp extends MockBase implements IdpApi {
  getApiInfo(): Promise<{ version: string; [key: string]: any }> {
    return new Promise((resolve) => {
      resolve(apiInfo);
    });
  }
  public async getAppOrganizations(): Promise<Organization[]> {
    await randomDelay();
    return MOCK_DB.APP_ORGS || [];
  }

  public async getOrganization(orgId?: string): Promise<Organization> {
    await randomDelay();
    return this.emptyTo404(
      MOCK_DB.APP_ORGS.filter((org) => org.id === orgId)
    )[0];
  }
  public async revokeOrganizationClientGroupInvitation(
      invitationId: string,
      orgId?:string
  ): Promise<ClientGroupInvitation> {
    throw new Error("Not implemented by the mock API");
  }
 public async sendOrganizationClientGroupInvitation(
     invitationId: string,
      orgId:string
  ): Promise<ClientGroupInvitation> {
   throw new Error("Not implemented by the mock API");
  }
  /**
   * Delete a client group invitation
   */
  public async deleteOrganizationClientGroupInvitation(invitationId: string, orgId?:string): Promise<void> {
    throw new Error("Not implemented by the mock API");
  }
  public async listClientsInOrganizationClientGroup(organizationId: string, groupId: string): Promise<Client[]> {
    throw new Error("Not implemented by the mock API");
  }
  public async addClientsToOrganizationClientGroup(organizationId: string, groupId: string, body: string[]): Promise<void> {
    throw new Error("Not implemented by the mock API");
  }
  public async removeClientsFromOrganizationClientGroup(organizationId: string, groupId: string, body: string[]): Promise<void> {
    throw new Error("Not implemented by the mock API");
  }
  public async getOrganizationClientGroupInvitation(
      invitationId: string,
      orgId?: string
  ): Promise<ClientGroupInvitation> {
    throw new Error("Not implemented by the mock API");
  }
  public async replaceOrganization(org: Organization): Promise<Organization> {
    const updatedOrg = { ...org };
    const index = this.minusOneTo404(
      MOCK_DB.APP_ORGS.findIndex(
        (existingOrg) => existingOrg.id === updatedOrg.id
      )
    );
    MOCK_DB.APP_ORGS[index] = updatedOrg;
    return updatedOrg;
  }

  public setMfaRequired(required: boolean, orgId?: string): Promise<void> {
    throw new Error("Not implemented by the mock API");
  }

  deleteOrganizationClient(organizationId: string, clientId: string): Promise<void>  {
    throw new Error("Not implemented by the mock API");
  }
  replaceOrganizationClient(organizationId: string, client: Client): Promise<Client> {
    throw new Error("Not implemented by the mock API");
  }
  getClient(clientId: string): Promise<Client> {
    throw new Error("Not implemented by the mock API");
  }
  public async listAllOrganizationUsers(orgId?: string): Promise<User[]> {
    await randomDelay();
    if (orgId) {
      return MOCK_DB.ALL_USERS_BY_ORG_ID[orgId];
    }
    throw this.build404();
  }

  public async listOrganizationGroups(
      orgId?: string
  ): Promise<OrganizationGroup[]> {
    await randomDelay();
    return this.requireOrgGroups(orgId);
  }
  public async listOrganizationClients(
      orgId?: string
  ): Promise<Client[]> {
    throw new Error("Not implemented by the mock API");
  }
  public async listOrganizationClientGroups(
      orgId?: string
  ): Promise<Client[]> {
    throw new Error("Not implemented by the mock API");
  }

  public async createOrganizationClientGroup(
      orgId: string,
      clientGroup: ClientGroup): Promise<ClientGroup> {
    throw new Error("Not implemented by the mock API");
  }

  public async createOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    await randomDelay();
    const groups = this.requireOrgGroups(orgId);
    const orgGroupToCreate = { ...orgGroup };
    if (orgGroupToCreate.id) {
      this.notMinusOneTo409(
        groups.findIndex((group) => group.id === orgGroupToCreate.id)
      );
    } else {
      orgGroupToCreate.id = this.randomUuid();
    }
    groups.push(orgGroupToCreate);
    return orgGroupToCreate;
  }

  public async replaceOrganizationGroup(
    orgGroup: OrganizationGroup,
    orgId?: string
  ): Promise<OrganizationGroup> {
    await randomDelay();
    const { groups, index } = this.getOrganizationGroupInternal(
      orgGroup.id as string,
      orgId
    );
    const updatedGroup = { ...orgGroup };
    groups[index] = updatedGroup;
    return updatedGroup;
  }

  public async getOrganizationGroup(
    orgGroupId: string,
    orgId?: string
  ): Promise<OrganizationGroup> {
    await randomDelay();
    const { groups, index } = this.getOrganizationGroupInternal(
      orgGroupId,
      orgId
    );
    return groups[index];
  }
  public async getOrganizationClientGroup(
      orgclientGroupId: string,
      orgId?: string
  ): Promise<ClientGroup> {
    throw new Error("Not implemented by the mock API");
  }
  public async replaceOrganizationClientGroup(organizationId: string, clientGroup: ClientGroup): Promise<ClientGroup> {
    throw new Error("Not implemented by the mock API");
  }
  public async deleteOrganizationClientGroup(
      clientGroupId: string,
      orgId?: string
  ): Promise<DeleteClientGroupResult> {
    throw new Error("Not implemented by the mock API");
  }
  public async deleteOrganizationGroup(
    orgGroupId: string,
    orgId?: string
  ): Promise<DeleteOrganizationGroupResult> {
    await randomDelay();
    const { groups, index } = this.getOrganizationGroupInternal(
      orgGroupId,
      orgId
    );
    groups.splice(index, 1);
    // fix if removed members are needed
    return {} as DeleteOrganizationGroupResult;
  }

  public async listOrganizationRoles(
    orgId?: string
  ): Promise<OrganizationRole[]> {
    await randomDelay();
    return this.requireOrgRoles(orgId);
  }

  public async createOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    await randomDelay();
    const roles = this.requireOrgRoles(orgId);
    const orgRoleToCreate = { ...orgRole };
    if (orgRoleToCreate.id) {
      this.notMinusOneTo409(
        roles.findIndex((role) => role.id === orgRoleToCreate.id)
      );
    } else {
      orgRoleToCreate.id = this.randomUuid();
    }
    roles.push(orgRoleToCreate);
    return orgRoleToCreate;
  }

  public async replaceOrganizationRole(
    orgRole: OrganizationRole,
    orgId?: string
  ): Promise<OrganizationRole> {
    await randomDelay();
    const { roles, index } = this.getOrganizationRoleInternal(
      orgRole.id as string,
      orgId
    );
    const updatedRole = { ...orgRole };
    roles[index] = updatedRole;
    return updatedRole;
  }

  public async getOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<OrganizationRole> {
    await randomDelay();
    const { roles, index } = this.getOrganizationRoleInternal(orgRoleId, orgId);
    return roles[index];
  }

  public async deleteOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<void> {
    await randomDelay();
    const { roles, index } = this.getOrganizationRoleInternal(orgRoleId, orgId);
    roles.splice(index, 1);
  }

  public async getUser(userId: string): Promise<User> {
    await randomDelay();
    const { users, index } = this.getUserInternal(userId);
    return users[index];
  }

  public async deleteUser(userId: string): Promise<void> {
    await randomDelay();
    const { users, index } = this.getUserInternal(userId);
    if (users[index] && users[index].lastName === "McPhailure") {
      throw this.buildTestError();
    }
    users.splice(index, 1);
  }

  public async replaceUser(user: User): Promise<User> {
    await randomDelay();
    const { users, index } = this.getUserInternal(user.id as string);
    if (user && user.lastName === "McPhailure") {
      throw this.buildTestError();
    }
    const updatedUser = { ...user };
    users[index] = updatedUser;
    return updatedUser;
  }

  public async setUserSuspended(
    suspended: boolean,
    userId: string
  ): Promise<User> {
    await randomDelay();
    const user = await this.getUser(userId);
    if (user && user.lastName === "McPhailure") {
      throw this.buildTestError();
    }
    const updatedUser = { ...user };
    if (suspended) {
      updatedUser.validUntil = new Date().toISOString();
    } else {
      updatedUser.validUntil = undefined;
    }
    return await this.replaceUser(updatedUser);
  }

  public async deleteOtpCredential(userId: string): Promise<void> {
    throw new Error("Not implemented by the mock API");
  }

  public async listOrganizationGroupsOfUser(
    userId: string,
    organizationId?: string
  ): Promise<OrganizationGroup[]> {
    await randomDelay();
    await this.getUser(userId);
    const userGroupIds = this.getOrganizationGroupIdsOfUser(userId);
    const retValue: OrganizationGroup[] = [];
    for (const groupId of userGroupIds) {
      const { groupsOfOrg, index } =
        this.getOrganizationGroupByIdInternal(groupId);
      retValue.push(groupsOfOrg[index]);
    }

    return retValue;
  }

  listClientGroupsOfClient(clientId: string, organizationId?: string): Promise<ClientGroup[]> {
    throw new Error("Not implemented by the mock API");
  }

  public async listOrganizationRolesOfUser(
    userId: string
  ): Promise<OrganizationRole[]> {
    await randomDelay();
    await this.getUser(userId);
    const userRoleIds = this.getOrganizationRoleIdsOfUser(userId);
    const retValue: OrganizationRole[] = [];
    for (const roleId of userRoleIds) {
      const { rolesOfOrg, index } =
        this.getOrganizationRoleByIdInternal(roleId);
      retValue.push(rolesOfOrg[index]);
    }

    return retValue;
  }

  public async addOrganizationGroupForUser(
    groupId: string,
    userId: string
  ): Promise<void> {
    await randomDelay();
    await this.getUser(userId);
    const { groupsOfOrg, index } =
      this.getOrganizationGroupByIdInternal(groupId);
    const orgGroup = groupsOfOrg[index];
    const orgGroupMembers =
      MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroup.id as string];
    if (orgGroupMembers.indexOf(userId) !== -1) {
      throw this.build409();
    }
    orgGroupMembers.push(userId);
  }

  public async addOrganizationRoleForUser(
    roleId: string,
    userId: string
  ): Promise<void> {
    await randomDelay();
    await this.getUser(userId);
    const { rolesOfOrg, index } = this.getOrganizationRoleByIdInternal(roleId);
    const orgRole = rolesOfOrg[index];
    const orgRoleMembers =
      MOCK_DB.ALL_ROLE_MEMBER_IDS_BY_ROLE_ID[orgRole.id as string];
    if (orgRoleMembers.indexOf(userId) !== -1) {
      throw this.build409();
    }
    orgRoleMembers.push(userId);
  }

  public async removeOrganizationGroupOfUser(
    groupId: string,
    userId: string
  ): Promise<RemoveOrgGroupOfUserResult> {
    await randomDelay();
    const { users, index: indexOfUser } = this.getUserInternal(userId);
    const user = users[indexOfUser];
    if (user && user.lastName === "McPhailure") {
      throw this.buildTestError();
    }

    await this.getUser(userId);
    const { groupsOfOrg, index } =
      this.getOrganizationGroupByIdInternal(groupId);
    const orgGroup = groupsOfOrg[index];
    const orgGroupMembers =
      MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroup.id as string];
    const memberIndex = orgGroupMembers.indexOf(userId);
    if (memberIndex === -1) {
      throw this.build404();
    }
    orgGroupMembers.splice(memberIndex, 1);
    // fix if removed orgs needed
    return {};
  }

  public async listUsersInOrganizationGroup(
    groupId: string,
    orgId?: string
  ): Promise<User[]> {
    await randomDelay();
    const { groupsOfOrg, index } =
      this.getOrganizationGroupByIdInternal(groupId);
    const orgGroup = groupsOfOrg[index];
    const orgGroupMembers =
      MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroup.id as string];
    return orgGroupMembers.map((userId) => {
      const { users, index } = this.getUserInternal(userId);
      return users[index];
    });
  }

  public async addUsersToOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    await randomDelay();
    const { groupsOfOrg, index } =
      this.getOrganizationGroupByIdInternal(groupId);
    const orgGroup = groupsOfOrg[index];
    const orgGroupMembers =
      MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroup.id as string];
    for (const userId of userIds) {
      if (orgGroupMembers.indexOf(userId) !== -1) {
        throw this.build409();
      }
    }

    MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroup.id as string] = [
      ...orgGroupMembers,
      ...userIds,
    ];
  }

  public async setUsersInOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<SetUsersInOrgGroupResult> {
    await randomDelay();
    const { groupsOfOrg, index } =
      this.getOrganizationGroupByIdInternal(groupId);
    const orgGroup = groupsOfOrg[index];
    MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroup.id as string] = [
      ...userIds,
    ];
    // update implementation if removed users are needed
    return {};
  }

  public async removeUsersFromOrganizationGroup(
    groupId: string,
    userIds: string[],
    orgId?: string
  ): Promise<RemoveUsersFromOrgGroupResult> {
    await randomDelay();
    const { groupsOfOrg, index } =
      this.getOrganizationGroupByIdInternal(groupId);
    const orgGroup = groupsOfOrg[index];
    const orgGroupMembers =
      MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroup.id as string];
    for (const userId of userIds) {
      if (orgGroupMembers.indexOf(userId) === -1) {
        throw this.build404();
      }
    }

    MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroup.id as string] =
      orgGroupMembers.filter((memberId) => userIds.indexOf(memberId) === -1);
    // fix if removed members are needed
    return {};
  }

  public async listOrganizationsOrganizationGroupInvitations(
    orgId?: string
  ): Promise<OrganizationGroupInvitation[]> {
    await randomDelay();
    if (orgId) {
      return MOCK_DB.ALL_ORG_GROUP_INVITATIONS_BY_ORG_ID[orgId];
    }
    throw this.build404();
  }

  public async getOrganizationsOrganizationGroupInvitation(
    invitationId: string,
    orgId?: string
  ): Promise<OrganizationGroupInvitation> {
    await randomDelay();
    const { invitations, index } = this.getOrganizationGroupInvitationInternal(
      invitationId,
      orgId
    );
    return invitations[index];
  }

  public async createOrganizationGroupInvitation(
    invitation: OrganizationGroupInvitation
  ): Promise<OrganizationGroupInvitation> {
    await randomDelay();

    if (
      invitation &&
      invitation.email &&
      invitation.email.toLowerCase().indexOf("McPhailure".toLowerCase()) >= 0
    ) {
      throw this.buildTestError();
    } else {
      const invitations = this.requireOrgInvitations(invitation.organizationId);
      const orgInvitationToCreate = { ...invitation };
      if (orgInvitationToCreate.id) {
        this.notMinusOneTo409(
          invitations.findIndex((inv) => inv.id === orgInvitationToCreate.id)
        );
      } else {
        orgInvitationToCreate.id = this.randomUuid();
      }
      orgInvitationToCreate.invitationState = "created";
      invitations.push(orgInvitationToCreate);
      return orgInvitationToCreate;
    }
  }

  public async sendOrganizationGroupInvitation(
    invitationId: string
  ): Promise<OrganizationGroupInvitation> {
    await randomDelay();
    const invitation = MOCK_DB.ALL_INVITATIONS.flat(1).find(
      (val) => val.id === invitationId
    );
    if (invitation) {
      const { invitations, index } =
        this.getOrganizationGroupInvitationInternal(
          invitation.id as string,
          invitation.organizationId
        );
      const updatedInvitation = { ...invitations[index] };
      updatedInvitation.invitationState = "deliveryRequested";
      invitations[index] = updatedInvitation;
      return updatedInvitation;
    } else {
      throw this.build404();
    }
  }

  public async revokeOrganizationGroupInvitation(
    invitationId: string
  ): Promise<OrganizationGroupInvitation> {
    await randomDelay();
    const invitation = MOCK_DB.ALL_INVITATIONS.flat(1).find(
      (val) => val.id === invitationId
    );
    if (invitation) {
      const { invitations, index } =
        this.getOrganizationGroupInvitationInternal(
          invitation.id as string,
          invitation.organizationId
        );
      const updatedInvitation = { ...invitations[index] };
      updatedInvitation.invitationState = "revoked";
      updatedInvitation.revokedAt = "" + new Date().getTime();
      invitations[index] = updatedInvitation;
      return updatedInvitation;
    } else {
      throw this.build404();
    }
  }

  public async deleteOrganizationGroupInvitation(
    invitationId: string
  ): Promise<void> {
    await randomDelay();
    const invitation = MOCK_DB.ALL_INVITATIONS.flat(1).find(
      (val) => val.id === invitationId
    );
    if (invitation) {
      const { invitations, index } =
        this.getOrganizationGroupInvitationInternal(
          invitation.id as string,
          invitation.organizationId
        );
      invitations.splice(index, 1);
    } else {
      throw this.build404();
    }
  }
  public async listOrganizationRolesInOrganizationRole(
    orgId: string,
    orgRoleId: string
  ): Promise<OrganizationRole[]> {
    await randomDelay();
    // no idea what t do here
    return [];
  }
  public async setOrganizationRolesInOrganizationRole(
    orgId: string,
    orgRoleId: string,
    roles: string[]
  ): Promise<void> {
    await randomDelay();
    // no idea what t do here
    return;
  }

  public async listPermissionsOfOrganizationRole(
    orgRoleId: string
  ): Promise<InternalPermissionWithGrantedActions[]> {
    await randomDelay();
    const { rolesOfOrg, index } =
      this.getOrganizationRoleByIdInternal(orgRoleId);
    const role = rolesOfOrg[index];
    const rolePermissions =
      MOCK_DB.ALL_ROLE_PERMISSION_IDS_BY_ROLE_ID[role.id as string];
    const retValue: InternalPermissionWithGrantedActions[] = [];
    for (const rolePermission of rolePermissions) {
      const permission = this.getPermissionById(rolePermission.id);
      retValue.push({
        ...permission,
        grantedActions: { allowedActions: rolePermission.actions },
      });
    }

    return retValue;
  }
  public async listPermissions(): Promise<InternalPermission[]> {
    await randomDelay();
    // no idea what to fake here
    const retValue: InternalPermission[] = [];

    return retValue;
  }

  public async addPermissionsForOrganizationRole(
    grants: PermissionGrantsForPermission[],
    orgRoleId: string
  ): Promise<void> {
    await randomDelay();
    const { rolesOfOrg, index } =
      this.getOrganizationRoleByIdInternal(orgRoleId);
    const role = rolesOfOrg[index];
    const rolePermissions =
      MOCK_DB.ALL_ROLE_PERMISSION_IDS_BY_ROLE_ID[role.id as string];
    const permissionsToAdd: { id: string; actions: string[] }[] = [];
    for (const grant of grants) {
      if (
        rolePermissions.findIndex(
          (rolePermission) => rolePermission.id === grant.id
        ) !== -1
      ) {
        throw this.build409();
      }
      permissionsToAdd.push({
        id: grant.id as string,
        actions: grant.grantedActions as string[],
      });
    }

    MOCK_DB.ALL_ROLE_PERMISSION_IDS_BY_ROLE_ID[role.id as string] = [
      ...rolePermissions,
      ...permissionsToAdd,
    ];
  }

  public async setPermissionsOfOrganizationRole(
    grants: PermissionGrantsForPermission[],
    orgRoleId: string
  ): Promise<void> {
    await randomDelay();
    const { rolesOfOrg, index } =
      this.getOrganizationRoleByIdInternal(orgRoleId);
    const role = rolesOfOrg[index];
    const permissionsToSet: { id: string; actions: string[] }[] = [];
    for (const grant of grants) {
      permissionsToSet.push({
        id: grant.id as string,
        actions: grant.grantedActions as string[],
      });
    }

    MOCK_DB.ALL_ROLE_PERMISSION_IDS_BY_ROLE_ID[role.id as string] =
      permissionsToSet;
  }

  public async removePermissionsOfOrganizationRole(
    permissionIds: string[],
    orgRoleId: string
  ): Promise<void> {
    await randomDelay();
    const { rolesOfOrg, index } =
      this.getOrganizationRoleByIdInternal(orgRoleId);
    const role = rolesOfOrg[index];
    const rolePermissions =
      MOCK_DB.ALL_ROLE_PERMISSION_IDS_BY_ROLE_ID[role.id as string];
    for (const permissionId of permissionIds) {
      if (
        rolePermissions.findIndex(
          (rolePermission) => rolePermission.id === permissionId
        ) === -1
      ) {
        throw this.build404();
      }
    }

    MOCK_DB.ALL_ROLE_PERMISSION_IDS_BY_ROLE_ID[role.id as string] =
      rolePermissions.filter(
        (rolePermission) => permissionIds.indexOf(rolePermission.id) === -1
      );
  }

  public async removePermissionOfOrganizationRole(
    permissionId: string,
    orgRoleId: string
  ): Promise<void> {
    await randomDelay();
    return await this.removePermissionsOfOrganizationRole(
      [permissionId],
      orgRoleId
    );
  }

  public async listUsersInOrganizationRole(
    orgRoleId: string,
    orgId?: string
  ): Promise<User[]> {
    await randomDelay();
    const role = this.getSingleOrganizationRoleByIdInternal(orgRoleId);
    const orgRoleMembers =
      MOCK_DB.ALL_ROLE_MEMBER_IDS_BY_ROLE_ID[role.id as string];
    return orgRoleMembers.map((userId) => {
      const { users, index } = this.getUserInternal(userId);
      return users[index];
    });
  }

  public async addUsersToOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    await randomDelay();
    userIds.forEach((userId) =>
      this.addUserToOrganizationRole(orgRoleId, userId, orgId)
    );
  }

  public getUserInOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<User> {
    throw new Error("Not implemented by the mock API");
  }

  public async addUserToOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    await randomDelay();
    const role = this.getSingleOrganizationRoleByIdInternal(orgRoleId);
    const orgRoleMembers =
      MOCK_DB.ALL_ROLE_MEMBER_IDS_BY_ROLE_ID[role.id as string];
    const userIndex = orgRoleMembers.indexOf(userId);
    if (userIndex === -1) {
      orgRoleMembers.push(userId);
    }
  }

  public async removeUsersFromOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    await randomDelay();
    userIds.forEach((userId) =>
      this.removeUserFromOrganizationRole(orgRoleId, userId, orgId)
    );
  }

  public async removeUserFromOrganizationRole(
    orgRoleId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    await randomDelay();
    const role = this.getSingleOrganizationRoleByIdInternal(orgRoleId);
    const orgRoleMembers =
      MOCK_DB.ALL_ROLE_MEMBER_IDS_BY_ROLE_ID[role.id as string];
    const userIndex = orgRoleMembers.indexOf(userId);
    if (userIndex !== -1) {
      orgRoleMembers.splice(userIndex, 1);
    }
  }

  public async setUsersInOrganizationRole(
    orgRoleId: string,
    userIds: string[],
    orgId?: string
  ): Promise<void> {
    await randomDelay();
    const role = this.getSingleOrganizationRoleByIdInternal(orgRoleId);
    MOCK_DB.ALL_ROLE_MEMBER_IDS_BY_ROLE_ID[role.id as string] = [...userIds];
  }

  private getPermissionById(permissionId: string): Permission {
    const index = MOCK_DB.INTERNAL_PERMISSIONS.findIndex(
      (permission) => permission.id === permissionId
    );
    if (index === -1) {
      throw this.build404();
    }

    return MOCK_DB.INTERNAL_PERMISSIONS[index];
  }

  public async addOrganizationGroupsForUser(
    groupIds: string[],
    userId: string
  ): Promise<void> {
    await randomDelay();
    const { users, index } = this.getUserInternal(userId);
    if (users[index] && users[index].lastName === "McPhailure") {
      throw this.buildTestError();
    }
    groupIds.forEach((groupId) => {
      let orgGroupMembers =
        MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[groupId] || [];

      if (!orgGroupMembers.includes(userId)) {
        orgGroupMembers.push(userId);
      }

      MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[groupId] = orgGroupMembers;
    });
  }

  async addClientGroupsForClient(
      groupIds: string[],
      clientId: string
  ): Promise<void> {
    throw new Error("Not implemented by the mock API");
  }

  public async addOrganizationRolesForUser(
    roleIds: string[],
    userId: string
  ): Promise<void> {
    await randomDelay();
    const { users, index } = this.getUserInternal(userId);
    if (users[index] && users[index].lastName === "McPhailure") {
      throw this.buildTestError();
    }
    roleIds.forEach((roleId) => {
      let orgRoleMembers = MOCK_DB.ALL_ROLE_MEMBER_IDS_BY_ROLE_ID[roleId] || [];

      if (!orgRoleMembers.includes(userId)) {
        orgRoleMembers.push(userId);
      }

      MOCK_DB.ALL_ROLE_MEMBER_IDS_BY_ROLE_ID[roleId] = orgRoleMembers;
    });
  }

  public async setOrganizationGroupsOfUser(
    groupIds: string[],
    userId: string
  ): Promise<SetOrgGroupsOfUserResult> {
    await randomDelay();
    ALL_GROUPS.forEach((group) => {
      let orgGroupMembers =
        MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[group.id as string] || [];

      if (orgGroupMembers.includes(userId)) {
        orgGroupMembers.splice(orgGroupMembers.indexOf(userId), 1);
      }

      MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[group.id as string] =
        orgGroupMembers;
    });

    groupIds.forEach((groupId) =>
      MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[groupId].push(userId)
    );
    // fix if removed orgs are needed
    return {};
  }

  public async removeOrganizationGroupsOfUser(
    groupIds: string[],
    userId: string
  ): Promise<RemoveOrgGroupsOfUserResult> {
    await randomDelay();
    const { users, index } = this.getUserInternal(userId);
    if (users[index] && users[index].lastName === "McPhailure") {
      throw this.buildTestError();
    }

    groupIds.forEach((groupId) => {
      let orgGroupMembers =
        MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[groupId] || [];

      if (orgGroupMembers.includes(userId)) {
        orgGroupMembers.splice(orgGroupMembers.indexOf(userId), 1);
      }

      MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[groupId] = orgGroupMembers;
    });
    // fix if removed orgs are needed
    return {};
  }
  public async removeClientGroupsOfClient(
      groupIds: string[],
      clientId: string
  ): Promise<RemoveClientGroupsOfClientResult> {
    throw new Error("Method not implemented.");
  }
  public async removeOrganizationRolesOfUser(
    roleIds: string[],
    userId: string
  ): Promise<void> {
    await randomDelay();
    const { users, index } = this.getUserInternal(userId);
    if (users[index] && users[index].lastName === "McPhailure") {
      throw this.buildTestError();
    }

    roleIds.forEach((roleId) => {
      let orgRoleMembers = MOCK_DB.ALL_ROLE_MEMBER_IDS_BY_ROLE_ID[roleId] || [];

      if (orgRoleMembers.includes(userId)) {
        orgRoleMembers.splice(orgRoleMembers.indexOf(userId), 1);
      }

      MOCK_DB.ALL_ROLE_MEMBER_IDS_BY_ROLE_ID[roleId] = orgRoleMembers;
    });
  }

  public addUserToOrganizationGroup(
    groupId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    throw new Error("Method not implemented.");
  }

  public removeUserFromOrganizationGroup(
    groupId: string,
    userId: string,
    orgId?: string
  ): Promise<void> {
    throw new Error("Method not implemented.");
  }

  private getOrganizationRoleByIdInternal(orgRoleId: string): {
    orgId: string;
    rolesOfOrg: OrganizationRole[];
    index: number;
  } {
    let index = -1;
    let roles: OrganizationRole[] | undefined = undefined;
    let orgId: string | undefined = undefined;
    for (const currentOrgId in MOCK_DB.ALL_ROLES_BY_ORG_ID) {
      const rolesOfOrg = MOCK_DB.ALL_ROLES_BY_ORG_ID[currentOrgId];
      index = rolesOfOrg.findIndex(
        (role) => role.id === orgRoleId || role.designator === orgRoleId
      );
      if (index !== -1) {
        roles = rolesOfOrg;
        orgId = currentOrgId;
        break;
      }
    }

    if (index === -1) {
      throw this.build404();
    }

    return {
      orgId: orgId as string,
      rolesOfOrg: roles as OrganizationRole[],
      index,
    };
  }

  private getSingleOrganizationRoleByIdInternal(
    orgRoleId: string
  ): OrganizationRole {
    for (const currentOrgId in MOCK_DB.ALL_ROLES_BY_ORG_ID) {
      const index = MOCK_DB.ALL_ROLES_BY_ORG_ID[currentOrgId].findIndex(
        (role) => role.id === orgRoleId || role.designator === orgRoleId
      );

      if (index !== -1) {
        return MOCK_DB.ALL_ROLES_BY_ORG_ID[currentOrgId][index];
      }
    }

    throw this.build404();
  }

  private getOrganizationGroupByIdInternal(orgGroupId: string): {
    orgId: string;
    groupsOfOrg: OrganizationGroup[];
    index: number;
  } {
    let index = -1;
    let groups: OrganizationGroup[] | undefined = undefined;
    let orgId: string | undefined = undefined;
    for (const currentOrgId in MOCK_DB.ALL_GROUPS_BY_ORG_ID) {
      const groupsOfOrg = MOCK_DB.ALL_GROUPS_BY_ORG_ID[currentOrgId];
      index = groupsOfOrg.findIndex((group) => group.id === orgGroupId);
      if (index !== -1) {
        groups = groupsOfOrg;
        orgId = currentOrgId;
        break;
      }
    }

    if (index === -1) {
      throw this.build404();
    }

    return {
      orgId: orgId as string,
      groupsOfOrg: groups as OrganizationGroup[],
      index,
    };
  }

  private getOrganizationGroupIdsOfUser(userId: string): string[] {
    const retValue: string[] = [];
    for (const groupId in MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID) {
      const memberIds = MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[groupId];
      if (memberIds.indexOf(userId) !== -1) {
        retValue.push(groupId);
      }
    }
    return retValue;
  }
  private getOrganizationRoleIdsOfUser(userId: string): string[] {
    const retValue: string[] = [];
    for (const roleId in MOCK_DB.ALL_ROLE_MEMBER_IDS_BY_ROLE_ID) {
      const memberIds = MOCK_DB.ALL_ROLE_MEMBER_IDS_BY_ROLE_ID[roleId];
      if (memberIds.indexOf(userId) !== -1) {
        retValue.push(roleId);
      }
    }
    return retValue;
  }

  private requireOrgGroups(orgId?: string): OrganizationGroup[] {
    const groups = this.selectOrgGroups(orgId);
    if (groups) {
      return groups;
    }
    throw this.build404();
  }

  private requireOrgInvitations(orgId?: string): OrganizationGroupInvitation[] {
    const invitations = this.selectOrgInvitations(orgId);
    if (invitations) {
      return invitations;
    }
    throw this.build404();
  }

  private selectOrgGroups(orgId?: string): OrganizationGroup[] | undefined {
    if (orgId) {
      return MOCK_DB.ALL_GROUPS_BY_ORG_ID[orgId];
    }
  }

  private selectOrgInvitations(
    orgId?: string
  ): OrganizationGroupInvitation[] | undefined {
    if (orgId) {
      return MOCK_DB.ALL_ORG_GROUP_INVITATIONS_BY_ORG_ID[orgId];
    }
  }

  private getOrganizationGroupInternal(
    orgGroupId: string,
    orgId?: string
  ): { groups: OrganizationGroup[]; index: number } {
    const groups = this.requireOrgGroups(orgId);
    const index = this.minusOneTo404(
      groups.findIndex((existingOrg) => existingOrg.id === orgGroupId)
    );
    return { groups, index };
  }

  private getOrganizationGroupInvitationInternal(
    orgInvitationId: string,
    orgId?: string
  ): { invitations: OrganizationGroupInvitation[]; index: number } {
    const invitations = this.requireOrgInvitations(orgId);
    const index = this.minusOneTo404(
      invitations.findIndex((existingInv) => existingInv.id === orgInvitationId)
    );
    return { invitations, index };
  }

  private requireOrgRoles(orgId?: string): OrganizationRole[] {
    const roles = this.selectOrgRoles(orgId);
    if (roles) {
      return roles;
    }
    throw this.build404();
  }

  private selectOrgRoles(orgId?: string): OrganizationRole[] | undefined {
    if (orgId) {
      return MOCK_DB.ALL_ROLES_BY_ORG_ID[orgId];
    }
  }

  private getOrganizationRoleInternal(
    orgRoleId: string,
    orgId?: string
  ): { roles: OrganizationRole[]; index: number } {
    const roles = this.requireOrgRoles(orgId);
    const index = this.minusOneTo404(
      roles.findIndex((existingRole) => existingRole.id === orgRoleId)
    );
    return { roles, index };
  }

  public async importUsers(users: User[]): Promise<User[]> {
    await randomDelay();
    users.forEach((user) => {
      user.id = generateRandomUuid();
      MOCK_DB.ORGLESS_USERS.push(user);
    });
    return users;
  }

  public async importOrganizationUsers(
    users: UserForCreate[],
    orgId?: string
  ): Promise<User[] | any> {
    await randomDelay();
    const errors = users.map((user) => {
      if (user.email.indexOf("mcphailure") >= 0) {
        return {
          error: "resource_already_exists",
          error_description:
            "Value " +
            user.email +
            ' on field "lower(_value::text)" violates unique constraint on relation "EmailAddress".',
        };
      } else {
        //
        return null;
      }
    });
    if (errors.filter((v) => v === null).length === users.length) {
      users.forEach((user) => {
        this.importOrganizationUser(user, orgId as string);
      });
      return users;
    } else {
      const er = {
        error: "invalid_user_data",
        error_description: "1 error(s) occurred",
        errors: errors,
      } as any as Error;
      throw er;
    }
  }

  private importOrganizationUser(user: UserForCreate, orgId: string) {
    // check that org exists.
    this.getOrganization(orgId);

    user.id = generateRandomUuid();

    // resolve employees group. can be undefined.
    const employeesGroup = this.resolveEmployeesGroup(
      MOCK_DB.ALL_GROUPS_BY_ORG_ID[orgId as string]
    );

    // add to ALL_USERS_BY_ORG_ID
    if (!MOCK_DB.ALL_USERS_BY_ORG_ID[orgId as string]) {
      MOCK_DB.ALL_USERS_BY_ORG_ID[orgId as string] = [];
    }
    const plainUser = UserUtils.transformToUser(user);
    MOCK_DB.ALL_USERS_BY_ORG_ID[orgId as string].push(plainUser);

    // add to ALL_GROUP_MEMBER_IDS_BY_GROUP_ID
    if (user.groupIds) {
      user.groupIds.forEach((groupId) =>
        this.addUserIdToAllMemberIdsByGroupId(user.id as string, groupId)
      );
    } else {
      if (!employeesGroup) {
        throw this.build404();
      }
      this.addUserIdToAllMemberIdsByGroupId(
        user.id as string,
        employeesGroup.id as string
      );
    }
  }

  private addUserIdToAllMemberIdsByGroupId(userId: string, orgGroupId: string) {
    if (!MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroupId]) {
      MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroupId] = [];
    }
    MOCK_DB.ALL_GROUP_MEMBER_IDS_BY_GROUP_ID[orgGroupId].push(userId);
  }

  private resolveEmployeesGroup(
    groups: OrganizationGroup[]
  ): OrganizationGroup | undefined {
    if (!groups) {
      return undefined;
    }

    return groups.find(
      (orgGroup) =>
        orgGroup.type === "employees" || orgGroup.type === "EMPLOYEES"
    );
  }
  /**
   * List client group invitations
   */
  listOrganizationClientGroupInvitations(organizationId: string): Promise<ClientGroupInvitation[]> {
    throw new Error("Not implemented by the mock API");
  }
  /**
   * Create and send a client group invitation
   */
  createAndSendOrganizationClientGroupInvitation(organizationId: string, clientGroupInvitation: ClientGroupInvitation): Promise<ClientGroupInvitation> {
    throw new Error("Not implemented by the mock API");
  };
}

export default MockIdp;
