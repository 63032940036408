import * as ActionTypes from "./actionTypes";
import { entApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {
  buildActionThunk,
  buildMultiActionThunk,
  ensureSelectedOrgId,
  EventOperation,
  EventOperationProvider,
  forceUndefined,
} from "./actionHelpers";
import { LicenseFieldOpts } from "../model/entitlement/LicenseFieldOpts";
import { LicenseWithCredits } from "../model/entitlement/LicenseWithCredits";
import { LicenseUsage } from "../model/entitlement/LicenseUsage";
import { LicenseAssignment } from "../model/entitlement/LicenseAssignment";
import { LicenseWithOwnerAndSingleUserAssignments } from "../model/entitlement/LicenseWithOwnerAndSingleUserAssignments";
import { QueryAvailableLicensesOpts } from "../model/entitlement/QueryAvailableLicensesOpts";
import { updateLicense } from "../util/licenseUtil";
import { Entitlement } from "../model/entitlement/Entitlement";
import { OrganizationGroup } from "../model/OrganizationGroup";
import {ClientGroup} from "../model/ClientGroup";

/**
 * Lists licenses of an entitlement.
 * @param sender Component requesting for the action
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseFieldOpts Options for including additional fields in the response.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function listEntitlementLicenses(
  sender: ActionSender,
  entId: string,
  licenseFieldOpts?: LicenseFieldOpts,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListEntLicensesAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.ListEntLicensesAction,
    LicenseWithCredits[]
  >(
    sender,
    ActionTypes.LIST_ENT_LICENSES,
    async () =>
      await entApi.listEntitlementLicenses(
        orgIdOrDefault,
        entId,
        licenseFieldOpts
      ),
    (type, licenses) => ({
      type,
      entId,
      orgId: orgIdOrDefault,
      licenseFieldOpts,
      licenses: forceUndefined(licenses),
    })
  );
}


export function listEntitlementConsumingUsers(
    sender: ActionSender,
    entId: string,
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListEntConsumingUsersAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
      ActionTypes.ListEntConsumingUsersAction,
      string[]
  >(
      sender,
      ActionTypes.LIST_ENT_CONSUMING_USERS,
      async () =>
          await entApi.listEntitlementConsumingUsers(
              orgIdOrDefault,
              entId
          ),
      (type, userIds) => ({
        type,
        entId,
        orgId: orgIdOrDefault,
        userIds: forceUndefined(userIds),
      })
  );
}

export function listEntitlementConsumingClients(
    sender: ActionSender,
    entId: string,
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListEntConsumingClientsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
      ActionTypes.ListEntConsumingClientsAction,
      string[]
  >(
      sender,
      ActionTypes.LIST_ENT_CONSUMING_CLIENTS,
      async () =>
          await entApi.listEntitlementConsumingClients(
              orgIdOrDefault,
              entId
          ),
      (type, clientIds) => ({
        type,
        entId,
        orgId: orgIdOrDefault,
        clientIds: forceUndefined(clientIds),
      })
  );
}

/**
 * Gets current usage of a license.
 * @param sender Component requesting for the action
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseId Id of the license.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function queryLicenseUsage(
  sender: ActionSender,
  entId: string,
  licenseId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.QueryLicenseUsageAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.QueryLicenseUsageAction, LicenseUsage>(
    sender,
    ActionTypes.QUERY_LICENSE_USAGE,
    async () =>
      await entApi.queryLicenseUsage(orgIdOrDefault, entId, licenseId),
    (type, licenseUsage) => ({
      type,
      entId,
      orgId: orgIdOrDefault,
      licenseId,
      licenseUsage: forceUndefined(licenseUsage),
    })
  );
}

/**
 * Lists licenses of an entitlement with license usage information.
 * @param sender Component requesting for the action
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseFieldOpts Options for including additional fields in the response.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function listEntitlementLicensesWithUsage(
  sender: ActionSender,
  entId: string,
  licenseFieldOpts?: LicenseFieldOpts,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListEntLicensesWithUsageAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.ListEntLicensesWithUsageAction,
    LicenseUsage[]
  >(
    sender,
    ActionTypes.LIST_ENT_LICENSES_WITH_USAGE,
    async () =>
      await listEntitlementLicensesWithUsageInternal(
        orgIdOrDefault,
        entId,
        licenseFieldOpts
      ),
    (type, licenses) => ({
      type,
      entId,
      orgId: orgIdOrDefault,
      licenseFieldOpts,
      licenses: forceUndefined(licenses),
    })
  );
}

/**
 * Lists licenses of an entitlement with license usage information.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseFieldOpts Options for including additional fields in the response.
 */
async function listEntitlementLicensesWithUsageInternal(
  orgId: string,
  entId: string,
  licenseFieldOpts?: LicenseFieldOpts
): Promise<LicenseUsage[]> {
  const entLicenses = await entApi.listEntitlementLicenses(
    orgId,
    entId,
    licenseFieldOpts
  );
  const licenseUsages = await Promise.all(
    entLicenses.map((entLicense) =>
      entApi.queryLicenseUsage(orgId, entId, entLicense.id as string)
    )
  );
  const licenseUsagesByLicenseId = licenseUsages.reduce<{
    [licenseId: string]: LicenseUsage;
  }>((map, licUsage) => {
    map[licUsage.id as string] = licUsage;
    return map;
  }, {});
  return entLicenses.map((entLicense) =>
    updateLicense<LicenseUsage>(licenseUsagesByLicenseId[entLicense.id as string], entLicense)
  );
}

/**
 * Gets user's assignments to the license.
 * @param sender Component requesting for the action
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseId Id of the license.
 * @param userId Id of the user.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function getLicenseAssignments(
  sender: ActionSender,
  entId: string,
  licenseId: string,
  userId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.GetUserLicenseAssignmentsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.GetUserLicenseAssignmentsAction,
    LicenseAssignment[]
  >(
    sender,
    ActionTypes.GET_USER_LICENSE_ASSIGNMENTS,
    async () =>
      await entApi.getLicenseAssignments(
        orgIdOrDefault,
        entId,
        licenseId,
        userId
      ),
    (type, licenseAssignments) => ({
      type,
      entId,
      orgId: orgIdOrDefault,
      licenseId,
      userId,
      licenseAssignments: forceUndefined(licenseAssignments),
    })
  );
}

/**
 * Manages user's license assigments.
 * @param sender Component requesting for the action
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseId Id of the license.
 * @param userId Id of the user.
 * @param newLicenseAssignments New license assignments to set for the user to the license.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function manageLicenseAssignments(
  sender: ActionSender,
  entId: string,
  licenseId: string,
  userId: string,
  newLicenseAssignments: LicenseAssignment[],
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ManageUserLicenseAssignmentsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.ManageUserLicenseAssignmentsAction,
    {
      userAssignments: { [userId: string]: LicenseAssignment[] };
      licenseUsage: LicenseUsage;
      errors: { [userId: string]: any };
    }
  >(
    sender,
    ActionTypes.MANAGE_USER_LICENSE_ASSIGNMENTS,
    async () =>
      await manageUsersLicenseAssignmentsInternal(
        orgIdOrDefault,
        entId,
        licenseId,
        { [userId]: newLicenseAssignments }
      ),
    (type, manageResult) => ({
      type,
      entId,
      orgId: orgIdOrDefault,
      licenseId,
      userId,
      licenseAssignments: manageResult
        ? Object.values(manageResult.userAssignments).flatMap(
            (assignments) => assignments
          )
        : forceUndefined<LicenseAssignment[]>(),
      licenseUsage: forceUndefined(manageResult?.licenseUsage),
      errors: forceUndefined(manageResult?.errors),
    })
  );
}

/**
 * Manages license assigments for several users.
 * @param sender Component requesting for the action
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseId Id of the license.
 * @param userAssignments New license assignments to set by user id.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function manageUsersLicenseAssignments(
  sender: ActionSender,
  entId: string,
  licenseId: string,
  userAssignments: { [userId: string]: LicenseAssignment[] },
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ManageUsersLicenseAssignmentsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.ManageUsersLicenseAssignmentsAction,
    {
      userAssignments: { [userId: string]: LicenseAssignment[] };
      licenseUsage: LicenseUsage;
      errors: { [userId: string]: any };
    }
  >(
    sender,
    ActionTypes.MANAGE_USERS_LICENSE_ASSIGNMENTS,
    async () =>
      await manageUsersLicenseAssignmentsInternal(
        orgIdOrDefault,
        entId,
        licenseId,
        userAssignments
      ),
    (type, manageResult) => ({
      type,
      entId,
      orgId: orgIdOrDefault,
      licenseId,
      userAssignments: forceUndefined(manageResult?.userAssignments),
      licenseAssignments: manageResult
        ? Object.values(manageResult.userAssignments).flatMap(
            (assignments) => assignments
          )
        : forceUndefined<LicenseAssignment[]>(),
      licenseUsage: forceUndefined(manageResult?.licenseUsage),
      errors: forceUndefined(manageResult?.errors),
    })
  );
}

/**
 * Manages license assigments for several users.
 * @param orgId The organization id.
 * @param entId Id of the entitlement, or 'any' in cases where multiple entitlements can be accepted.
 * @param licenseId Id of the license.
 * @param userAssignments New license assignments to set by user id.
 * @returns New license assignments by user id and new license usage of the license.
 */
async function manageUsersLicenseAssignmentsInternal(
  orgId: string,
  entId: string,
  licenseId: string,
  userAssignments: { [userId: string]: LicenseAssignment[] }
): Promise<{
  userAssignments: { [userId: string]: LicenseAssignment[] };
  licenseUsage: LicenseUsage;
  errors: { [userId: string]: any };
}> {
  const userIds = Object.keys(userAssignments);
  // Separate reservations to handle releases first.
  const reservationUserIds: string[] = [];
  const releaseAndDenialUserIds = userIds.filter((f) => {
    if (userAssignments[f][0].type === "reserved") {
      reservationUserIds.push(f);
      return false;
    }
    // same as "floating" || "denied", but guarantees that every id ends up in a list
    return true;
  });
  const handler = (userId: string) =>
    entApi.manageLicenseAssignments(
      orgId,
      entId,
      licenseId,
      userId,
      userAssignments[userId].map((assignment) => {
        // Remove sessions property that is passed here to prevent
        // rest service failing with unsupported member "sessions".
        // "sessions" is member of LicenseAssignmentWithSessions but not LicenseAssignment.
        const { sessions: _, ...other } = assignment as any;
        return other;
      })
    );
  // These need to be run separately, to ensure that backend has had time to update DB with released licenses before
  // we try to reserve
  const manageReleaseAndDenialResults = await Promise.allSettled(
    releaseAndDenialUserIds.map(handler)
  );
  const manageReservationResults = await Promise.allSettled(
    reservationUserIds.map(handler)
  );
  // combine the results for easier handling.
  const results = manageReleaseAndDenialResults.concat(
    manageReservationResults
  );
  const resultIds = releaseAndDenialUserIds.concat(reservationUserIds);

  const licenseUsage = await entApi.queryLicenseUsage(orgId, entId, licenseId);
  const retValue: {
    userAssignments: { [userId: string]: LicenseAssignment[] };
    licenseUsage: LicenseUsage;
    errors: { [userId: string]: any };
  } = { userAssignments: {}, licenseUsage, errors: {} };
  for (let i = 0; i < resultIds.length; i++) {
    if (results[i].status === "fulfilled") {
      retValue.userAssignments[resultIds[i]] = (
        results[i] as PromiseFulfilledResult<LicenseAssignment[]>
      ).value;
    } else {
      retValue.errors[resultIds[i]] = (
        results[i] as PromiseRejectedResult
      ).reason;
    }
  }
  return retValue;
}



export function manageClientsLicenseAssignments(
    sender: ActionSender,
    entId: string,
    licenseId: string,
    clientAssignments: { [clientId: string]: LicenseAssignment[] },
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ManageClientsLicenseAssignmentsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
      ActionTypes.ManageClientsLicenseAssignmentsAction,
      {
        clientAssignments: { [clientId: string]: LicenseAssignment[] };
        licenseUsage: LicenseUsage;
        errors: { [userId: string]: any };
      }
  >(
      sender,
      ActionTypes.MANAGE_CLIENTS_LICENSE_ASSIGNMENTS,
      async () =>
          await manageClientsLicenseAssignmentsInternal(
              orgIdOrDefault,
              entId,
              licenseId,
              clientAssignments
          ),
      (type, manageResult) => ({
        type,
        entId,
        orgId: orgIdOrDefault,
        licenseId,
        clientAssignments: forceUndefined(manageResult?.clientAssignments),
        licenseAssignments: manageResult
            ? Object.values(manageResult.clientAssignments).flatMap(
                (assignments) => assignments
            )
            : forceUndefined<LicenseAssignment[]>(),
        licenseUsage: forceUndefined(manageResult?.licenseUsage),
        errors: forceUndefined(manageResult?.errors),
      })
  );
}

async function manageClientsLicenseAssignmentsInternal(
    orgId: string,
    entId: string,
    licenseId: string,
    clientAssignments: { [clientId: string]: LicenseAssignment[] }
): Promise<{
  clientAssignments: { [clientId: string]: LicenseAssignment[] };
  licenseUsage: LicenseUsage;
  errors: { [userId: string]: any };
}> {
  const clientIds = Object.keys(clientAssignments);
  // Separate reservations to handle releases first.
  const reservationClientIds: string[] = [];
  const releaseAndDenialClientIds = clientIds.filter((f) => {
    if (clientAssignments[f][0].type === "reserved") {
      reservationClientIds.push(f);
      return false;
    }
    // same as "floating" || "denied", but guarantees that every id ends up in a list
    return true;
  });
  const handler = (clientId: string) =>
      entApi.manageClientLicenseAssignments(
          orgId,
          entId,
          licenseId,
          clientId,
          clientAssignments[clientId].map((assignment) => {
            // Remove sessions property that is passed here to prevent
            // rest service failing with unsupported member "sessions".
            // "sessions" is member of LicenseAssignmentWithSessions but not LicenseAssignment.
            const { sessions: _, ...other } = assignment as any;
            return other;
          })
      );
  // These need to be run separately, to ensure that backend has had time to update DB with released licenses before
  // we try to reserve
  const manageReleaseAndDenialResults = await Promise.allSettled(
      releaseAndDenialClientIds.map(handler)
  );
  const manageReservationResults = await Promise.allSettled(
      reservationClientIds.map(handler)
  );
  // combine the results for easier handling.
  const results = manageReleaseAndDenialResults.concat(
      manageReservationResults
  );
  const resultIds = releaseAndDenialClientIds.concat(reservationClientIds);

  const licenseUsage = await entApi.queryLicenseUsage(orgId, entId, licenseId);
  const retValue: {
    clientAssignments: { [userId: string]: LicenseAssignment[] };
    licenseUsage: LicenseUsage;
    errors: { [userId: string]: any };
  } = { clientAssignments: {}, licenseUsage, errors: {} };
  for (let i = 0; i < resultIds.length; i++) {
    if (results[i].status === "fulfilled") {
      retValue.clientAssignments[resultIds[i]] = (
          results[i] as PromiseFulfilledResult<LicenseAssignment[]>
      ).value;
    } else {
      retValue.errors[resultIds[i]] = (
          results[i] as PromiseRejectedResult
      ).reason;
    }
  }
  return retValue;
}





/**
 * Queries information of licenses available to a client.
 * @param sender Component requesting for the action
 * @param orgId Id of the organization.
 * @param clientId Id of the client.
 * @param queryAvailableLicensesOpts Options for the operation.
 */
export function queryClientAvailableLicenses(
    sender: ActionSender,
    clientId: string,
    orgId?: string,
    queryAvailableLicensesOpts?: QueryAvailableLicensesOpts
): ActionTypes.AppThunkAction<ActionTypes.QueryClientAvailableLicensesAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);

  return buildActionThunk<
      ActionTypes.QueryClientAvailableLicensesAction,
      LicenseWithOwnerAndSingleUserAssignments[]
  >(
      sender,
      ActionTypes.QUERY_CLIENT_AVAILABLE_LICENSES,
      async () =>
          await entApi.queryClientAvailableLicenses(clientId, {
            ...queryAvailableLicensesOpts,
            ownerOrganizationId: orgIdOrDefault,
          }),
      (type, availableLicenses) => ({
        type,
        clientId,
        queryAvailableLicensesOpts,
        licenses: forceUndefined(availableLicenses),
        licenseAssignments: availableLicenses
            ? availableLicenses
                .filter((lic) => lic.assignments && lic.assignments.length !== 0)
                .flatMap((lic) => lic.assignments as LicenseAssignment[])
            : forceUndefined<LicenseAssignment[]>(),
      })
  );
}
/**
 * Queries information of licenses available to a user.
 * @param sender Component requesting for the action
 * @param orgId Id of the organization.
 * @param userId Id of the user.
 * @param queryAvailableLicensesOpts Options for the operation.
 */
export function queryAvailableLicenses(
  sender: ActionSender,
  userId: string,
  orgId?: string,
  queryAvailableLicensesOpts?: QueryAvailableLicensesOpts
): ActionTypes.AppThunkAction<ActionTypes.QueryAvailableLicensesAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);

  return buildActionThunk<
    ActionTypes.QueryAvailableLicensesAction,
    LicenseWithOwnerAndSingleUserAssignments[]
  >(
    sender,
    ActionTypes.QUERY_AVAILABLE_LICENSES,
    async () =>
      await entApi.queryAvailableLicenses(userId, {
        ...queryAvailableLicensesOpts,
        ownerOrganizationId: orgIdOrDefault,
      }),
    (type, availableLicenses) => ({
      type,
      userId,
      queryAvailableLicensesOpts,
      licenses: forceUndefined(availableLicenses),
      licenseAssignments: availableLicenses
        ? availableLicenses
            .filter((lic) => lic.assignments && lic.assignments.length !== 0)
            .flatMap((lic) => lic.assignments as LicenseAssignment[])
        : forceUndefined<LicenseAssignment[]>(),
    })
  );
}

/**
 * List license consuming organization groups for specified entitlement.
 * @param sender Component requesting for the action
 * @param orgId The organization id
 * @param entId The entitlement id.
 */
export function listLicenseConsumingOrgGroups(
  sender: ActionSender,
  orgId: string | undefined,
  entId: string
): ActionTypes.AppThunkAction<ActionTypes.ListLicenseConsumingOrgGroupsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.ListLicenseConsumingOrgGroupsAction,
    OrganizationGroup[]
  >(
    sender,
    ActionTypes.LIST_LICENSE_CONSUMING_ORG_GROUPS,
    async () =>
      await entApi.listLicenseConsumingOrgGroups(orgIdOrDefault, entId),
    (type, groups) => ({
      type,
      orgId: orgIdOrDefault,
      entId,
      groups: forceUndefined(groups),
    })
  );
}

/**
 * List license consuming device client groups for specified entitlement.
 * @param sender Component requesting for the action
 * @param orgId The organization id
 * @param entId The entitlement id.
 */
export function listLicenseConsumingCliGroups(
    sender: ActionSender,
    orgId: string | undefined,
    entId: string
): ActionTypes.AppThunkAction<ActionTypes.ListLicenseConsumingOrgClientGroupsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
      ActionTypes.ListLicenseConsumingOrgClientGroupsAction,
      ClientGroup[]
  >(
      sender,
      ActionTypes.LIST_LICENSE_CONSUMING_ORG_CLIENT_GROUPS,
      async () =>
          await entApi.listLicenseConsumingCliGroups(orgIdOrDefault, entId),
      (type, groups) => ({
        type,
        orgId: orgIdOrDefault,
        entId,
        groups: forceUndefined(groups),
      })
  );
}

/**
 * Add license consuming organization group for entitlement.
 * @param sender Component requesting for the action
 * @param orgId The organization id
 * @param entId The entitlement id
 * @param groupId The group id
 */
export function addLicenseConsumingOrgGroup(
  sender: ActionSender,
  orgId: string,
  entId: string,
  groupId: string
): ActionTypes.AppThunkAction<ActionTypes.AddLicenseConsumingOrgGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.AddLicenseConsumingOrgGroupAction, void>(
    sender,
    ActionTypes.ADD_LICENSE_CONSUMING_ORG_GROUP,
    async () => await entApi.addLicenseConsumingOrgGroup(orgId, entId, groupId),
    (type) => ({
      type,
      orgId: orgIdOrDefault,
      entId,
      groupId,
    })
  );
}

/**
 * Remove license consuming organization group from entitlement.
 * @param sender Component requesting for the action
 * @param orgId The organization id
 * @param entId The entitlement id
 * @param groupId The group id
 */
export function removeLicenseConsumingOrgGroup(
  sender: ActionSender,
  orgId: string,
  entId: string,
  groupId: string
): ActionTypes.AppThunkAction<ActionTypes.RemoveLicenseConsumingOrgGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.RemoveLicenseConsumingOrgGroupAction,
    void
  >(
    sender,
    ActionTypes.REMOVE_LICENSE_CONSUMING_ORG_GROUP,
    async () =>
      await entApi.removeLicenseConsumingOrgGroup(orgId, entId, groupId),
    (type) => ({
      type,
      orgId: orgIdOrDefault,
      entId,
      groupId,
    })
  );
}

/**
 * List entitlements belonging to specified organization.
 * @param sender Component requesting for the action
 * @param orgId The organization
 */
export function listOrganizationEntitlements(
  sender: ActionSender,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListEntitlementsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ListEntitlementsAction, Entitlement[]>(
    sender,
    ActionTypes.LIST_ORG_ENTITLEMENTS,
    async () => await entApi.listOrganizationEntitlements(orgIdOrDefault),
    (type, entitlements) => ({
      type,
      orgId: orgIdOrDefault,
      entitlements: forceUndefined(entitlements),
    })
  );
}

/**
 * Get entitlement belonging to specified organization.
 * @param sender Component requesting for the action
 * @param orgId The organization
 * @param entitlementId The id of the queried entitlement
 */
export function getOrganizationEntitlement(
  sender: ActionSender,
  entitlementId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.GetOrgEntitlementAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.GetOrgEntitlementAction, Entitlement>(
    sender,
    ActionTypes.GET_ORG_ENTITLEMENT,
    async () =>
      await entApi.getOrganizationEntitlement(orgIdOrDefault, entitlementId),
    (type, entitlement) => ({
      type,
      orgId: orgIdOrDefault,
      entitlement: forceUndefined(entitlement),
    })
  );
}

/**
 * List entitlements consumed by organization group.
 * @param sender Component requesting for the action
 * @param orgId The organization
 * @param groupId The group
 */
export function listEntitlementsConsumedByOrgGroup(
  sender: ActionSender,
  orgId: string,
  groupId: string
): ActionTypes.AppThunkAction<ActionTypes.ListEntitlementsConsumedByOrgGroup> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.ListEntitlementsConsumedByOrgGroup,
    Entitlement[]
  >(
    sender,
    ActionTypes.LIST_ENTITLEMENTS_CONSUMED_BY_ORG_GROUP,
    async () =>
      await entApi.listEntitlementsConsumedByOrgGroup(orgIdOrDefault, groupId),
    (type, entitlements) => ({
      type,
      orgId: orgIdOrDefault,
      groupId,
      entitlements: forceUndefined(entitlements),
    })
  );
}

export function listEntitlementsConsumedByOrgClientGroup(
    sender: ActionSender,
    orgId: string,
    clientGroupId: string
): ActionTypes.AppThunkAction<ActionTypes.ListEntitlementsConsumedByOrgClientGroup> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
      ActionTypes.ListEntitlementsConsumedByOrgClientGroup,
      Entitlement[]
  >(
      sender,
      ActionTypes.LIST_ENTITLEMENTS_CONSUMED_BY_ORG_CLIENT_GROUP,
      async () =>
          await entApi.listEntitlementsConsumedByCliGroup(orgIdOrDefault, clientGroupId),
      (type, entitlements) => ({
        type,
        orgId: orgIdOrDefault,
        clientGroupId,
        entitlements: forceUndefined(entitlements),
      })
  );
}
/**
 * Set entitlements consumed by organization group.
 * @param sender Component requesting for the action
 * @param orgId The organization
 * @param entitlementIds Entitlement ids
 * @param groupId The group
 */
export function setEntitlementsConsumedByOrgGroup(
  sender: ActionSender,
  orgId: string,
  groupId: string,
  entitlementIds: string[]
): ActionTypes.AppThunkAction<ActionTypes.SetEntitlementsConsumedByOrgGroup> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.SetEntitlementsConsumedByOrgGroup, void>(
    sender,
    ActionTypes.SET_ENTITLEMENTS_CONSUMED_BY_ORG_GROUP,
    async () =>
      await entApi.setEntitlementsConsumedByOrgGroup(
        orgIdOrDefault,
        groupId,
        entitlementIds
      ),
    (type) => ({
      type,
      orgId: orgIdOrDefault,
      groupId,
      entitlementIds,
    })
  );
}
export function setEntitlementsConsumedByCliGroup(
    sender: ActionSender,
    orgId: string,
    clientGroupId: string,
    entitlementIds: string[]
): ActionTypes.AppThunkAction<ActionTypes.SetEntitlementsConsumedByOrgClientGroup> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.SetEntitlementsConsumedByOrgClientGroup, void>(
      sender,
      ActionTypes.SET_ENTITLEMENTS_CONSUMED_BY_ORG_CLIENT_GROUP,
      async () =>
          await entApi.setEntitlementsConsumedByCliGroup(
              orgIdOrDefault,
              clientGroupId,
              entitlementIds
          ),
      (type) => ({
        type,
        orgId: orgIdOrDefault,
        clientGroupId,
        entitlementIds,
      })
  );
}

export function listOrganizationEntitlementsAndEntitlementLicensesWithUsage(
  sender: ActionSender,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.MultiAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);

  return buildMultiActionThunk(
    sender,
    new ListOrganizationEntitlementsAndEntitlementLicensesWithUsageProvider(
      sender,
      "any",
      orgIdOrDefault
    ),
    true
  );
}

export function queryClientLicenseUsage(
    sender: ActionSender,
    clientId: string, { includeEffectiveModel, includeAggregatedLicensedItems }: {
  includeEffectiveModel?: boolean;
  includeAggregatedLicensedItems?: boolean;
}): ActionTypes.AppThunkAction<ActionTypes.QueryClientLicenseUsageAction> {
  return buildActionThunk<ActionTypes.QueryClientLicenseUsageAction, LicenseWithOwnerAndSingleUserAssignments[]>(
      sender,
      ActionTypes.QUERY_CLIENT_LICENSE_USAGE,
      async () =>
          await entApi.queryClientLicenseUsage(clientId, { includeEffectiveModel, includeAggregatedLicensedItems }),
      (type, licenseUsage) => ({
        type,
        clientId,
        licenseUsage: forceUndefined(licenseUsage),
      })
  );
}



enum ListOrganizationEntitlementsAndEntitlementLicensesWithUsageState {
  INIT = 0,
  LIST_ORGANIZATION_ENTITLEMENTS,
  DONE,
}
class ListOrganizationEntitlementsAndEntitlementLicensesWithUsageProvider
  implements EventOperationProvider<ActionTypes.AppAction>
{
  private state: ListOrganizationEntitlementsAndEntitlementLicensesWithUsageState;
  private sender: ActionSender;
  private entId: string;
  private orgId: string;
  private operations: EventOperation<ActionTypes.AppAction>[];

  constructor(sender: ActionSender, entId: string, orgId: string) {
    this.state =
      ListOrganizationEntitlementsAndEntitlementLicensesWithUsageState.INIT;
    this.sender = sender;
    this.entId = entId;
    this.orgId = orgId;
    this.operations = [];
  }

  /**
   * Method for providing next thunk/function to be executed in this chain.
   * @param sender
   * @param multiAction
   */
  next(
    sender: ActionSender,
    multiAction: ActionTypes.MultiAction
  ): EventOperation<ActionTypes.AppAction> | null {
    if (
      this.state ===
      ListOrganizationEntitlementsAndEntitlementLicensesWithUsageState.INIT
    ) {
      this.state =
        ListOrganizationEntitlementsAndEntitlementLicensesWithUsageState.LIST_ORGANIZATION_ENTITLEMENTS;
      return {
        thunk: listEntitlementLicensesWithUsage(this.sender, this.entId, { includeEffectiveModel: true }),
      };
    }
    if (
      this.state ===
      ListOrganizationEntitlementsAndEntitlementLicensesWithUsageState.LIST_ORGANIZATION_ENTITLEMENTS
    ) {
      this.state =
        ListOrganizationEntitlementsAndEntitlementLicensesWithUsageState.DONE;
      return {
        thunk: listOrganizationEntitlements(this.sender, this.orgId),
      };
    }
    return null;
  }
}


export function manageUsersLicenseAssignmentsAndQueryAvailableLicenses(
  sender: ActionSender,
  licenseIds: string[],
  userAssignmentsForLicenses: { [userId: string]: LicenseAssignment[] }[],
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.MultiAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);

  return buildMultiActionThunk(
    sender,
    new ManageUsersLicenseAssignmentsAndQueryAvailableLicensesProvider(
      sender,
      licenseIds,
      userAssignmentsForLicenses,
      orgIdOrDefault
    ),
    false
  );
}

export function manageClientsLicenseAssignmentsAndQueryAvailableLicenses(
    sender: ActionSender,
    licenseIds: string[],
    clientAssignmentsForLicenses: { [clientId: string]: LicenseAssignment[] }[],
    orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.MultiAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);

  return buildMultiActionThunk(
      sender,
      new ManageClientsLicenseAssignmentsAndQueryAvailableLicensesProvider(
          sender,
          licenseIds,
          clientAssignmentsForLicenses,
          orgIdOrDefault
      ),
      false
  );
}



enum ManageUsersLicenseAssignmentsAndQueryAvailableLicensesState {
  INIT = 0,
  MANAGE_LICENSE_ASSIGMENTS,
  QUERY_AVAILABLE,
  HANDLE_RESULT,
  DONE,
}

class ManageUsersLicenseAssignmentsAndQueryAvailableLicensesProvider
  implements EventOperationProvider<ActionTypes.AppAction>
{
  private state: ManageUsersLicenseAssignmentsAndQueryAvailableLicensesState;
  private sender: ActionSender;
  private licenseIds: string[];
  private orgId: string;
  private userAssignments: { [userId: string]: LicenseAssignment[] }[];
  private manageLicenseOperations: EventOperation<ActionTypes.AppAction>[];
  private queryAvailableOperations: EventOperation<ActionTypes.AppAction>[];

  constructor(
    sender: ActionSender,
    licenseIds: string[],
    userAssignments: { [userId: string]: LicenseAssignment[] }[],
    orgId: string
  ) {
    this.state =
      ManageUsersLicenseAssignmentsAndQueryAvailableLicensesState.INIT;
    this.sender = sender;
    this.licenseIds = licenseIds;
    this.orgId = orgId;
    this.userAssignments = userAssignments;
    this.manageLicenseOperations = [];
    this.queryAvailableOperations = [];
  }

  /**
   * Method for providing next thunk/function to be executed in this chain.
   * @param sender
   * @param multiAction
   */
  next(
    sender: ActionSender,
    multiAction: ActionTypes.MultiAction
  ): EventOperation<ActionTypes.AppAction> | null {
    if (
      this.state ===
      ManageUsersLicenseAssignmentsAndQueryAvailableLicensesState.INIT
    ) {
      this.state =
        ManageUsersLicenseAssignmentsAndQueryAvailableLicensesState.MANAGE_LICENSE_ASSIGMENTS;

      this.manageLicenseOperations = this.buildManageLicenseOperations(
        this.userAssignments,
        this.licenseIds
      );
    }

    if (
      this.state ===
      ManageUsersLicenseAssignmentsAndQueryAvailableLicensesState.MANAGE_LICENSE_ASSIGMENTS
    ) {
      if (this.manageLicenseOperations.length !== 0) {
        return this.manageLicenseOperations.pop() as EventOperation<ActionTypes.AppAction>;
      } else {
        this.state =
          ManageUsersLicenseAssignmentsAndQueryAvailableLicensesState.QUERY_AVAILABLE;
      }
    }
    if (
      this.state ===
      ManageUsersLicenseAssignmentsAndQueryAvailableLicensesState.QUERY_AVAILABLE
    ) {
      this.queryAvailableOperations = this.buildQueryAvailableOperations(
        this.userAssignments
      );

      this.state =
        ManageUsersLicenseAssignmentsAndQueryAvailableLicensesState.HANDLE_RESULT;
    }

    if (
      this.state ===
      ManageUsersLicenseAssignmentsAndQueryAvailableLicensesState.HANDLE_RESULT
    ) {
      if (this.queryAvailableOperations.length !== 0) {
        return this.queryAvailableOperations.pop() as EventOperation<ActionTypes.AppAction>;
      } else {
        this.state =
          ManageUsersLicenseAssignmentsAndQueryAvailableLicensesState.DONE;
      }
    }
    return null;
  }

  private buildQueryAvailableOperations(
    changed: {
      [userId: string]: LicenseAssignment[];
    }[]
  ): EventOperation<ActionTypes.AppAction>[] {
    const ops: EventOperation<ActionTypes.AppAction>[] = [];
    const tmp: string[] = changed.map((c) => Object.keys(c)).flat();
    const uniqueUsers = new Set(tmp);
    uniqueUsers.forEach((v) => {
      ops.push({
        thunk: queryAvailableLicenses(this.sender, v),
      });
    });
    return ops;
  }
  private buildManageLicenseOperations(
    assignemnts: {
      [userId: string]: LicenseAssignment[];
    }[],
    licenses: string[]
  ): EventOperation<ActionTypes.AppAction>[] {
    const ops: EventOperation<ActionTypes.AppAction>[] = [];
    licenses.forEach((v, i) => {
      ops.push({
        thunk: manageUsersLicenseAssignments(
          this.sender,
          "any",
          v,
          assignemnts[i],
          this.orgId
        ),
      });
    });
    return ops;
  }
}




enum ManageClientsLicenseAssignmentsAndQueryAvailableLicensesState {
  INIT = 0,
  MANAGE_LICENSE_ASSIGMENTS,
  QUERY_AVAILABLE,
  HANDLE_RESULT,
  DONE,
}

class ManageClientsLicenseAssignmentsAndQueryAvailableLicensesProvider
    implements EventOperationProvider<ActionTypes.AppAction>
{
  private state: ManageClientsLicenseAssignmentsAndQueryAvailableLicensesState;
  private sender: ActionSender;
  private licenseIds: string[];
  private orgId: string;
  private clientAssignments: { [userId: string]: LicenseAssignment[] }[];
  private manageLicenseOperations: EventOperation<ActionTypes.AppAction>[];
  private queryAvailableOperations: EventOperation<ActionTypes.AppAction>[];

  constructor(
      sender: ActionSender,
      licenseIds: string[],
      clientAssignments: { [clientId: string]: LicenseAssignment[] }[],
      orgId: string
  ) {
    this.state =
        ManageClientsLicenseAssignmentsAndQueryAvailableLicensesState.INIT;
    this.sender = sender;
    this.licenseIds = licenseIds;
    this.orgId = orgId;
    this.clientAssignments = clientAssignments;
    this.manageLicenseOperations = [];
    this.queryAvailableOperations = [];
  }

  /**
   * Method for providing next thunk/function to be executed in this chain.
   * @param sender
   * @param multiAction
   */
  next(
      sender: ActionSender,
      multiAction: ActionTypes.MultiAction
  ): EventOperation<ActionTypes.AppAction> | null {
    if (
        this.state ===
        ManageClientsLicenseAssignmentsAndQueryAvailableLicensesState.INIT
    ) {
      this.state =
          ManageClientsLicenseAssignmentsAndQueryAvailableLicensesState.MANAGE_LICENSE_ASSIGMENTS;

      this.manageLicenseOperations = this.buildManageLicenseOperations(
          this.clientAssignments,
          this.licenseIds
      );
    }

    if (
        this.state ===
        ManageClientsLicenseAssignmentsAndQueryAvailableLicensesState.MANAGE_LICENSE_ASSIGMENTS
    ) {
      if (this.manageLicenseOperations.length !== 0) {
        return this.manageLicenseOperations.pop() as EventOperation<ActionTypes.AppAction>;
      } else {
        this.state =
            ManageClientsLicenseAssignmentsAndQueryAvailableLicensesState.QUERY_AVAILABLE;
      }
    }
    if (
        this.state ===
        ManageClientsLicenseAssignmentsAndQueryAvailableLicensesState.QUERY_AVAILABLE
    ) {
      this.queryAvailableOperations = this.buildQueryAvailableOperations(
          this.clientAssignments
      );

      this.state =
          ManageClientsLicenseAssignmentsAndQueryAvailableLicensesState.HANDLE_RESULT;
    }

    if (
        this.state ===
        ManageClientsLicenseAssignmentsAndQueryAvailableLicensesState.HANDLE_RESULT
    ) {
      if (this.queryAvailableOperations.length !== 0) {
        return this.queryAvailableOperations.pop() as EventOperation<ActionTypes.AppAction>;
      } else {
        this.state =
            ManageClientsLicenseAssignmentsAndQueryAvailableLicensesState.DONE;
      }
    }
    return null;
  }

  private buildQueryAvailableOperations(
      changed: {
        [clientId: string]: LicenseAssignment[];
      }[]
  ): EventOperation<ActionTypes.AppAction>[] {
    const ops: EventOperation<ActionTypes.AppAction>[] = [];
    const tmp: string[] = changed.map((c) => Object.keys(c)).flat();
    const uniqueClients = new Set(tmp);
    uniqueClients.forEach((v) => {
      ops.push({
        thunk: queryClientAvailableLicenses(this.sender, v, this.orgId),
      });
    });
    return ops;
  }
  private buildManageLicenseOperations(
      assignemnts: {
        [clientId: string]: LicenseAssignment[];
      }[],
      licenses: string[]
  ): EventOperation<ActionTypes.AppAction>[] {
    const ops: EventOperation<ActionTypes.AppAction>[] = [];
    licenses.forEach((v, i) => {
      ops.push({
        thunk: manageClientsLicenseAssignments(
            this.sender,
            "any",
            v,
            assignemnts[i],
            this.orgId
        ),
      });
    });
    return ops;
  }
}
