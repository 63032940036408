import { TooltipWrapper } from "@10duke/dukeui";
import SelectCell, { SelectCellProps } from "../select-cell/select-cell-view";

export interface SelectHeaderCellProps extends SelectCellProps {
  indeterminate: boolean;
  tooltips?: { selectAll: string | undefined; deselectAll: string | undefined };
  isSelect?: boolean;
}

/**
 * This looks like a component, smells like a component, but is not a component so using hooks for e.g.
 * localisation is not possible.
 * @param props
 * @constructor
 */
export default function SelectHeaderCell(props: SelectHeaderCellProps) {
  const { indeterminate, className, checked, tooltips, isSelect, ...others } =
    props;
  let classes = [];
  if (className) {
    classes.push(className);
  }
  if (indeterminate) {
    classes.push("indeterminate");
  }
  return (
    <div className={"duke-header-cell"} data-test-select-all-header>
      <TooltipWrapper
        tip={!isSelect ? tooltips?.deselectAll : tooltips?.selectAll}
        tipKey={"selectAllTooltip"}
        placement={"auto"}
      >
        <div className={"duke-tool-cell"}>
          <SelectCell
            className={classes.join(" ")}
            checked={checked}
            {...others}
          />
        </div>
      </TooltipWrapper>
    </div>
  );
}
