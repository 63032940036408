import { useIntl, FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppError } from "../../../model/AppError";
import { Collapse } from "react-bootstrap";
import "./error-screen-view.scss";
import {IconLibrary, Button, Screen} from "@10duke/dukeui";
import logo from "../../logo";

//<editor-fold desc="Props">
export interface ErrorScreenErrorProps {
  showError: boolean;
  onShowError: (b: boolean) => void;
}
export interface ErrorScreenProps extends ErrorScreenErrorProps {
  errors?: AppError<any>[];
}
//</editor-fold>
export default function ErrorScreen(props: ErrorScreenProps) {
  const intl = useIntl();
  const { errors, showError, onShowError } = props;
  return (
    <Screen
      id={"error-screen"}
      data-test-error-screen
      meta={{
        title: intl.formatMessage({
          defaultMessage: "Authentication failed",
          description:
            "Heading for the authorization error screen. This screen is shown after a failed login attempt or login status check.",
        }),
        description: intl.formatMessage({
          defaultMessage: "Authentication failure info page.",
          description:
            "window meta description for the authorization error screen",
        }),
      }}
      header={<img src={logo} alt={""} title={""} className={'logo'}/>}
      footer={
        <a
            className={"btn btn-lg w-100 btn-primary"}
          href={process.env.PUBLIC_URL + "/"}
        >
          <FormattedMessage
            defaultMessage="Continue"
            description={"continue button label"}
          />
        </a>
      }
    >
      <FontAwesomeIcon icon={IconLibrary.icons.faExclamationTriangle} className={"icon"} />
      <h1>
        <FormattedMessage
            defaultMessage="Oh no!"
            description="heading for the authorization error screen"
        />
      </h1>
      <p>
        <FormattedMessage
          defaultMessage="Something went wrong and authentication has failed. Please try again."
          description="message for the authorization error screen"
        />
      </p>
      <div className={"details accordion"}>
        <div className="accordion-item">
          <div className="accordion-header">
            <Button
              className={
                "accordion-button " + (showError ? "active" : "collapsed")
              }
              data-test-error-details-trigger
              action={(e) => {
                onShowError(!showError);
              }}
            >
              <FormattedMessage
                defaultMessage="{isVisible, select, true {Hide} false {Show} other {Toggle} } technical error details"
                description={
                  "Toggle error details button label. 'isVisible' = state of target"
                }
                values={{ isVisible: showError }}
              />
            </Button>
          </div>
          <Collapse
            in={showError}
            data-test-error-details
            className={"accordion-collapse"}
          >
            <div className={"collapsible-target"}>
              {errors &&
                (errors as AppError<any>[]).map((err) => {
                  return <p>{err.apiError.error_description}</p>;
                })}
            </div>
          </Collapse>
        </div>
      </div>
    </Screen>
  );
}
