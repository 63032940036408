import { AppState } from "../store/AppState";
import { Organization } from "../model/Organization";
import { User } from "../model/User";
import { OrganizationGroup } from "../model/OrganizationGroup";
import { OrganizationGroupInvitation } from "../model/OrganizationGroupInvitation";
import { OrganizationRole } from "../model/OrganizationRole";
import { OrgsById, OrgUserIdsByOrgId } from "../store/OrganizationState";
import { Entitlement } from "../model/entitlement/Entitlement";
import UserUtils from "./user";
import { License } from "../model/entitlement/License";
import { PermissionGrantsForPermission } from "../model/PermissionGrantsForPermission";
import {Client} from "../model/Client";
import {ClientGroup} from "../model/ClientGroup";
import {ClientGroupInvitation} from "../model/ClientGroupInvitation";

function compareSelectedOrganizationIdStates(next: AppState, prev: AppState) {
  return next.selectedOrganizationId === prev.selectedOrganizationId;
}
function compareSelectedOrganizationStates(next: AppState, prev: AppState) {
  return (
    compareSelectedOrganizationIdStates(next, prev) &&
    next.organizations === prev.organizations
  );
}
/**
 * Resolves selected organization id from state
 * @param state
 */
function selectSelectedOrganizationId(state: AppState): string | undefined {
  return state.selectedOrganizationId;
}
/**
 * Resolves selected organization either from explicitly set selection state or if there is only one
 * @param state
 */
function selectSelectedOrganization(state: AppState): Organization | undefined {
  const { selectedOrganizationId, organizations, appOrganizationIds } = state;
  let retVal = undefined;
  if (organizations && Object.keys(organizations).length) {
    if (selectedOrganizationId) {
      retVal = organizations[selectedOrganizationId];
    } else if (appOrganizationIds && appOrganizationIds.length === 1) {
      retVal = organizations[appOrganizationIds[0]];
    }
  }
  return retVal;
}

function compareOrganizationClientGroupsStates(
    next: AppState,
    prev: AppState
): boolean {
  return (
      compareSelectedOrganizationIdStates(next, prev) &&
      next.orgClientGroupIds === prev.orgClientGroupIds &&
      next.orgClientGroups === prev.orgClientGroups
  );
}
function compareOrganizationClientsClientGroupIdStates(
    next: AppState,
    prev: AppState
): boolean {
  return (
      compareSelectedOrganizationIdStates(next, prev) &&
      next.orgClientClientGroupIds === prev.orgClientClientGroupIds
  );
}

function compareOrganizationGroupsStates(
  next: AppState,
  prev: AppState
): boolean {
  return (
    compareSelectedOrganizationIdStates(next, prev) &&
    next.orgOrgGroupIds === prev.orgOrgGroupIds &&
    next.orgGroups === prev.orgGroups
  );
}
function compareOrganizationClientGroupClientIdsStates(
    next: AppState,
    prev: AppState
): boolean {
  return (
      compareSelectedOrganizationIdStates(next, prev) &&
      next.orgClientGroupClientIds === prev.orgClientGroupClientIds
  );
}
function compareOrganizationGroupUserIdsStates(
  next: AppState,
  prev: AppState
): boolean {
  return (
    compareSelectedOrganizationIdStates(next, prev) &&
    next.orgGroupUserIds === prev.orgGroupUserIds
  );
}

function selectOrganizationGroups(
    state: AppState
): OrganizationGroup[] | undefined {
  let retVal = undefined;
  const { orgOrgGroupIds, orgGroups } = state;
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  if (
      selectedOrganizationId &&
      orgOrgGroupIds &&
      orgOrgGroupIds[selectedOrganizationId] &&
      orgGroups
  ) {
    retVal = orgOrgGroupIds[selectedOrganizationId].map(
        (id: string) => orgGroups[id]
    );
  }
  return retVal;
}
function selectOrganizationClientGroups(
    state: AppState
): ClientGroup[] | undefined {
  let retVal = undefined;
  const { orgClientGroupIds, orgClientGroups } = state;
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  if (
      selectedOrganizationId &&
      orgClientGroupIds &&
      orgClientGroupIds[selectedOrganizationId] &&
      orgClientGroups
  ) {
    retVal = orgClientGroupIds[selectedOrganizationId].map(
        (id: string) => orgClientGroups[id]
    );
  }
  return retVal;
}

function selectOrganizationClients(
    state: AppState
): Client[] | undefined {
  let retVal = undefined;
  const { orgClientIds, orgClients } = state;
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  if (
      selectedOrganizationId &&
      orgClientIds &&
      orgClientIds[selectedOrganizationId] &&
      orgClients
  ) {
    retVal = orgClientIds[selectedOrganizationId].map(
        (id: string) => orgClients[id]
    );
  }
  return retVal;
}

function selectOrganizationClient(
    clientId: string,
    state: AppState
): Client | undefined {
  let retVal = undefined;
  const { orgClientIds, orgClients } = state;
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  if (
      selectedOrganizationId &&
      orgClientIds &&
      orgClientIds[selectedOrganizationId] &&
      orgClientIds[selectedOrganizationId].indexOf(clientId) >= 0 &&
      orgClients
  ) {
    retVal = orgClients[clientId]
  }
  return retVal;
}
function compareOrganizationClientsStates(
    next: AppState,
    prev: AppState
): boolean {
  return (
      compareSelectedOrganizationIdStates(next, prev) &&
      next.orgClientIds === prev.orgClientIds &&
      next.orgClients === prev.orgClients
  );
}

function selectOrganizationRoles(
  state: AppState
): OrganizationRole[] | undefined {
  let retVal = undefined;
  const { orgOrgRoleIds, orgRoles } = state;
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  if (
    selectedOrganizationId &&
    orgOrgRoleIds &&
    orgOrgRoleIds[selectedOrganizationId] &&
    orgRoles
  ) {
    retVal = orgOrgRoleIds[selectedOrganizationId].map(
      (id: string) => orgRoles[id]
    );
  }
  return retVal;
}

function selectOrganizationGroup(
    groupId: string,
    state: AppState
): OrganizationGroup | undefined {
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  return !!groupId &&
  !!selectedOrganizationId &&
  !!state.orgGroups &&
  !!state.orgOrgGroupIds &&
  !!state.orgOrgGroupIds[selectedOrganizationId] &&
  !!state.orgOrgGroupIds[selectedOrganizationId].includes(groupId)
      ? state.orgGroups[groupId]
      : undefined;
}

function selectOrganizationClientGroup(
    clientGroupId: string,
    state: AppState
): OrganizationGroup | undefined {
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  return !!clientGroupId &&
  !!selectedOrganizationId &&
  !!state.orgClientGroups &&
  !!state.orgClientGroupIds &&
  !!state.orgClientGroupIds[selectedOrganizationId] &&
  !!state.orgClientGroupIds[selectedOrganizationId].includes(clientGroupId)
      ? state.orgClientGroups[clientGroupId]
      : undefined;
}

function selectOrganizationRole(
  roleId: string,
  state: AppState
): OrganizationRole | undefined {
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  return !!roleId &&
    !!selectedOrganizationId &&
    !!state.orgRoles &&
    !!state.orgOrgRoleIds &&
    !!state.orgOrgRoleIds[selectedOrganizationId] &&
    !!state.orgOrgRoleIds[selectedOrganizationId].includes(roleId)
    ? state.orgRoles[roleId]
    : undefined;
}
function selectOrganizationRoleUserIds(
  roleId: string,
  state: AppState
): string[] | undefined {
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  return !!roleId &&
    !!selectedOrganizationId &&
    !!state.orgRoleUserIds &&
    !!state.orgOrgRoleIds &&
    !!state.orgOrgRoleIds[selectedOrganizationId] &&
    !!state.orgOrgRoleIds[selectedOrganizationId].includes(roleId)
    ? state.orgRoleUserIds[roleId]
    : undefined;
}
function selectOrganizationRolePermissions(
  roleId: string,
  state: AppState
): PermissionGrantsForPermission[] | undefined {
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  return !!roleId &&
    !!selectedOrganizationId &&
    !!state.orgRoles &&
    !!state.orgOrgRoleIds &&
    !!state.orgOrgRoleIds &&
    !!state.orgOrgRoleIds[selectedOrganizationId] &&
    !!state.orgRoleGrantedPermissions &&
    !!state.orgRoleGrantedPermissions[roleId]
    ? state.orgRoleGrantedPermissions[roleId].filter((v) => !!v)
    : undefined;
}
function selectOrganizationRoleOrganizationRoles(
  roleId: string,
  state: AppState
): OrganizationRole[] | undefined {
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  return !!roleId &&
    !!selectedOrganizationId &&
    !!state.orgRoles &&
    !!state.orgOrgRoleIds &&
    !!state.orgOrgRoleIds &&
    !!state.orgOrgRoleIds[selectedOrganizationId] &&
    !!state.orgRoleOrgRoleIds &&
    !!state.orgRoleOrgRoleIds[roleId]
    ? (state.orgRoleOrgRoleIds[roleId]
        .map((id) => (state.orgRoles ? state.orgRoles[id] : undefined))
        .filter((v) => !!v) as OrganizationRole[])
    : undefined;
}

function selectOrganizationClientGroupClientIds(
    groupId: string,
    state: AppState
): string[] | undefined {
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  return !!groupId &&
  !!selectedOrganizationId &&
  !!state.orgClientGroupClientIds &&
  !!state.orgClientGroupIds &&
  !!state.orgClientGroupIds[selectedOrganizationId] &&
  !!state.orgClientGroupIds[selectedOrganizationId].includes(groupId)
      ? state.orgClientGroupClientIds[groupId]
      : undefined;
}
function selectOrganizationGroupUserIds(
  groupId: string,
  state: AppState
): string[] | undefined {
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  return !!groupId &&
    !!selectedOrganizationId &&
    !!state.orgGroupUserIds &&
    !!state.orgOrgGroupIds &&
    !!state.orgOrgGroupIds[selectedOrganizationId] &&
    !!state.orgOrgGroupIds[selectedOrganizationId].includes(groupId)
    ? state.orgGroupUserIds[groupId]
    : undefined;
}
function selectClientClientGroupIds(id: string, state: AppState): string[] | undefined {
  return !!state.orgClientClientGroupIds ? state.orgClientClientGroupIds[id] : undefined;
}
function selectOrganizationEmployeesGroupId(
  state: AppState
): string | undefined {
  const groups = selectOrganizationGroups(state);
  const eg = !!groups ? groups.find((g) => g.type === "employees") : undefined;
  return !!eg ? eg.id : undefined;
}
function resolveOrganizationRolesFromState(
  state: AppState
): OrganizationRole[] | undefined {
  let retVal = undefined;
  const { orgOrgRoleIds, orgRoles } = state;
  const org = selectSelectedOrganization(state);
  const selectedOrganizationId = org ? org.id : undefined;
  if (
    selectedOrganizationId &&
    orgOrgRoleIds &&
    orgOrgRoleIds[selectedOrganizationId] &&
    orgRoles
  ) {
    retVal = orgOrgRoleIds[selectedOrganizationId].map(
      (id: string) => orgRoles[id]
    );
  }
  return retVal;
}
function selectOrganizationUsers(state: AppState): User[] | undefined {
  const orgId = selectSelectedOrganizationId(state);
  return !!orgId &&
    !!state.orgUserIds &&
    !!state.users &&
    !!state.orgUserIds[orgId]
    ? (state.orgUserIds[orgId].map((val) =>
        !!state.users ? state.users[val] : undefined
      ) as User[])
    : undefined;
}
function selectOrganizationUser(id: string, state: AppState): User | undefined {
  return selectOrganizationUsers(state)?.find((u) => u.id === id);
}
function selectOrganizationInvitations(
  state: AppState
): OrganizationGroupInvitation[] | undefined {
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  return !!selectedOrganizationId &&
    !!state.orgInvitations &&
    !!state.orgOrgInvitationIds &&
    !!state.orgOrgInvitationIds[selectedOrganizationId]
    ? (state.orgOrgInvitationIds[selectedOrganizationId].map((id) =>
        !!state.orgInvitations ? state.orgInvitations[id] : undefined
      ) as OrganizationGroupInvitation[])
    : undefined;
}
function selectOrganizationInvitation(
  invitationId: string,
  state: AppState
): OrganizationGroupInvitation | undefined {
  return selectOrganizationInvitations(state)?.find(
    (i) => i.id === invitationId
  );
}

/**
 * Check if given user is orgadmin for selected organization.
 * @param userId Id of user.
 * @param state Redux state.
 * @returns true if given user is orgadmin in given organization or false if not.
 * can return undefined if store is not properly initialized.
 */
export function resolveIsUserOrgAdminFromState(
  userId: string,
  state: AppState
): boolean | undefined {
  const orgAdmins = selectOrganizationAdminIds(state, false);
  return orgAdmins
    ? orgAdmins.findIndex((id) => id === userId) !== -1
    : undefined;
}

/**
 * Get count of orgadmins in selected organization.
 * @param state Redux state.
 * @returns Number of orgadmins in given organization. undefined if state not properly initialized.
 */
export function resolveOrgAdminCountFromState(
  state: AppState
): number | undefined {
  const result = selectOrganizationAdminIds(state, true);
  return result ? result.length : undefined;
}

/**
 * Retrieve array of user id's that have this organization's org admin role assigned.
 * @param state Redux state.
 * @param requireMembership set to true if result set is to be filter by membership
 * (user must be part of organization group for this organization in addition to having org admin role.)
 * @returns Array of user ids that are org admin for selected organization or undefined.
 */
export function selectOrganizationAdminIds(
  state: AppState,
  requireMembership: boolean
): string[] | undefined {
  const org = selectSelectedOrganizationId(state);
  if (!org) {
    return undefined;
  }

  // Retrieve orgadmin roles from store.
  const orgAdminRoles = resolveOrgAdminRolesFromState(org as string, state);
  if (!orgAdminRoles) {
    return undefined;
  }

  // Check store state for roles.
  const { orgRoleUserIds } = state;
  if (!orgRoleUserIds) {
    return undefined;
  }

  let userIds: string[] = [];

  // Get users that belong to orgadmin roles.
  for (const role of orgAdminRoles) {
    if (!orgRoleUserIds[role.id as string]) {
      return undefined;
    }

    userIds = userIds.concat(orgRoleUserIds[role.id as string]);
  }

  // filter by organization membership if required.
  if (requireMembership) {
    const { orgUserIds } = state;
    if (!orgUserIds || !orgUserIds[org as string]) {
      return undefined;
    }

    userIds = userIds.filter((userId) =>
      (orgUserIds as OrgUserIdsByOrgId)[org as string].includes(userId)
    );
  }

  // Return result.
  return userIds;
}

function compareOrganizationRolesStates(
  next: AppState,
  prev: AppState
): boolean {
  return (
    compareSelectedOrganizationIdStates(next, prev) &&
    next.orgOrgRoleIds === prev.orgOrgRoleIds &&
    next.orgRoles === prev.orgRoles
  );
}
function compareOrganizationRoleOrganizationRoleStates(
  next: AppState,
  prev: AppState
): boolean {
  return (
    compareSelectedOrganizationIdStates(next, prev) &&
    compareOrganizationRolesStates(next, prev) &&
    next.orgRoleOrgRoleIds === prev.orgRoleOrgRoleIds
  );
}
function compareOrganizationRolePermissionStates(
  next: AppState,
  prev: AppState
): boolean {
  return (
    compareSelectedOrganizationIdStates(next, prev) &&
    next.orgRoleGrantedPermissions === prev.orgRoleGrantedPermissions
  );
}
function compareOrganizationInvitationsStates(
    next: AppState,
    prev: AppState
): boolean {
  return (
      compareSelectedOrganizationIdStates(next, prev) &&
      next.orgOrgInvitationIds === prev.orgOrgInvitationIds &&
      next.orgInvitations === prev.orgInvitations
  );
}
function compareOrganizationClientInvitationsStates(
    next: AppState,
    prev: AppState
): boolean {
  return (
      compareSelectedOrganizationIdStates(next, prev) &&
      next.orgClientInvitationIds === prev.orgClientInvitationIds &&
      next.clientInvitations === prev.clientInvitations
  );
}

function compareOrganizationRoleUserIdsStates(
  next: AppState,
  prev: AppState
): boolean {
  return (
    compareSelectedOrganizationIdStates(next, prev) &&
    next.orgRoleUserIds === prev.orgRoleUserIds
  );
}
/**
 * Resolve id of one orgadmin role for selected organization.
 * @param state Current state.
 * @returns Organization admin role or undefined if none set.
 */
export function selectOrgAdminRoleId(state: AppState): string | undefined {
  const org = selectSelectedOrganization(state);
  if (!org) {
    return undefined;
  }

  const orgadminRoles = resolveOrgAdminRolesFromState(org.id as string, state);
  if (!orgadminRoles || orgadminRoles.length === 0) {
    return undefined;
  }

  return orgadminRoles[0].id;
}

function resolveOrgAdminRolesFromState(
  orgId: string,
  state: AppState
): OrganizationRole[] | undefined {
  const { orgOrgRoleIds, orgRoles } = state;

  if (!orgOrgRoleIds || !orgRoles || !orgOrgRoleIds[orgId]) {
    return undefined;
  }

  const orgAdminRoles: OrganizationRole[] = [];

  for (const idOfOrgRole of orgOrgRoleIds[orgId]) {
    const orgRole = orgRoles[idOfOrgRole];
    if (orgRole && orgRole.designator === "orgadmin") {
      orgAdminRoles.push(orgRole);
    }
  }

  return orgAdminRoles;
}
function compareOrganizationsStates(next: AppState, prev: AppState): boolean {
  return next.organizations === prev.organizations;
}
function selectOrganizations(state: AppState): OrgsById | undefined {
  return state.organizations;
}
function compareAppOrganizationsStates(
  next: AppState,
  prev: AppState
): boolean {
  return (
    compareOrganizationsStates(next, prev) &&
    next.appOrganizationIds === prev.appOrganizationIds
  );
}
function selectAppOrganizations(state: AppState): Organization[] | undefined {
  return !!state.organizations && !!state.appOrganizationIds
    ? (state.appOrganizationIds.map((id) =>
        !!state.organizations ? state.organizations[id] : undefined
      ) as Organization[])
    : undefined;
}
function selectHasOrganizations(state: AppState): boolean {
  return !!state.organizations && Object.keys(state.organizations).length > 0;
}

function selectOrganizationEntitlements(
  state: AppState
): Entitlement[] | undefined {
  const orgId = selectSelectedOrganizationId(state);
  return !!state.orgEntitlementIds &&
    !!state.entitlements &&
    !!orgId &&
    !!state.orgEntitlementIds[orgId]
    ? (state.orgEntitlementIds[orgId].map((id) =>
        !!state.entitlements ? state.entitlements[id] : undefined
      ) as Entitlement[])
    : undefined;
}

function compareOrganizationEntitlementsStates(
  next: AppState,
  prev: AppState
): boolean {
  return (
    compareSelectedOrganizationIdStates(next, prev) &&
    next.orgEntitlementIds === prev.orgEntitlementIds &&
    next.entitlements === prev.entitlements
  );
}
function compareOrganizationUserIdsStates(
  next: AppState,
  prev: AppState
): boolean {
  return (
    compareSelectedOrganizationIdStates(next, prev) &&
    next.orgUserIds === prev.orgUserIds
  );
}
function compareOrganizationUsersStates(
  next: AppState,
  prev: AppState
): boolean {
  return (
    compareOrganizationUserIdsStates(next, prev) &&
    UserUtils.compareUsersState(next, prev)
  );
}

function compareClientAvailableLicensesState(
    next: AppState,
    prev: AppState
): boolean {
  return next.clientAvailableLicenses === prev.clientAvailableLicenses;
}
function compareOrganizationLicenseIdsStates(
  next: AppState,
  prev: AppState
): boolean {
  return (
    compareSelectedOrganizationIdStates(next, prev) &&
    next.orgLicenseIds === prev.orgLicenseIds
  );
}
function compareOrganizationLicenseStates(
  next: AppState,
  prev: AppState
): boolean {
  return (
    compareOrganizationLicenseIdsStates(next, prev) &&
    next.licenses === prev.licenses
  );
}
function selectOrganizationClientInvitations(
    state: AppState
): ClientGroupInvitation[] | undefined {
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  return !!selectedOrganizationId &&
  !!state.clientInvitations &&
  !!state.orgClientInvitationIds &&
  !!state.orgClientInvitationIds[selectedOrganizationId]
      ? (state.orgClientInvitationIds[selectedOrganizationId].map((id) =>
          !!state.clientInvitations ? state.clientInvitations[id] : undefined
      ) as ClientGroupInvitation[])
      : undefined;
}
function selectOrganizationClientInvitation(
    invId: string,
    state: AppState
): ClientGroupInvitation | undefined {
  const selectedOrganizationId = selectSelectedOrganizationId(state);
  return !!selectedOrganizationId &&
  !!state.clientInvitations &&
  !!state.orgClientInvitationIds &&
  !!state.orgClientInvitationIds[selectedOrganizationId] &&
  state.orgClientInvitationIds[selectedOrganizationId].indexOf(invId) >= 0
      ? state.clientInvitations[invId]
      : undefined;
}
function selectOrganizationLicensesForEntitlement(
  entitlementId: string,
  state: AppState
): License[] | undefined {
  return selectOrganizationLicenses(state)?.filter(
    (l) => l && l.entitlementId === entitlementId
  ) as License[];
}
function selectOrganizationLicenses(state: AppState): License[] | undefined {
  const orgId = selectSelectedOrganizationId(state);
  return !!orgId &&
    !!state.orgLicenseIds &&
    !!state.orgLicenseIds[orgId] &&
    !!state.licenses
    ? (state.orgLicenseIds[orgId].map((lId: string) =>
        state.licenses ? state.licenses[lId] : undefined
      ) as License[])
    : undefined;
}

const OrganizationUtils = {
  resolveOrganizationRolesFromState,
  selectSelectedOrganizationId,
  selectSelectedOrganization,
  selectHasOrganizations,
  selectOrganizations,
  compareAppOrganizationsStates,
  selectAppOrganizations,
  selectOrganizationUsers,
  selectOrganizationUser,
  compareOrganizationClientGroupsStates,
  compareOrganizationGroupsStates,
  selectOrganizationGroups,
  selectOrganizationClientGroups,
  selectOrganizationClients,
  selectOrganizationRoles,
  selectOrganizationInvitation,
  selectOrganizationInvitations,
  resolveIsUserOrgAdminFromState,
  resolveOrgAdminCountFromState,
  selectOrganizationAdminIds,
  compareOrganizationRolesStates,
  compareOrganizationRoleOrganizationRoleStates,
  compareOrganizationRolePermissionStates,
  selectOrgAdminRoleId,
  compareSelectedOrganizationIdStates,
  compareSelectedOrganizationStates,
  compareOrganizationsStates,
  selectOrganizationEntitlements,
  compareOrganizationEntitlementsStates,
  compareOrganizationRoleUserIdsStates,
  compareOrganizationUserIdsStates,
  compareOrganizationUsersStates,
  selectOrganizationEmployeesGroupId,
  selectOrganizationGroup,
  selectOrganizationClientGroup,
  selectOrganizationRole,
  selectOrganizationGroupUserIds,
  selectOrganizationRoleUserIds,
  selectOrganizationRolePermissions,
  selectOrganizationRoleOrganizationRoles,
  compareOrganizationGroupUserIdsStates,
  compareOrganizationInvitationsStates,
  compareOrganizationLicenseIdsStates,
  selectOrganizationLicensesForEntitlement,
  compareOrganizationLicenseStates,
  selectOrganizationLicenses,
  compareOrganizationClientsStates,
  selectOrganizationClientInvitations,
  selectOrganizationClientInvitation,
  compareOrganizationClientInvitationsStates,
  selectOrganizationClientGroupClientIds,
  compareOrganizationClientGroupClientIdsStates,
  selectOrganizationClient,
  selectClientClientGroupIds,
  compareOrganizationClientsClientGroupIdStates,
  compareClientAvailableLicensesState,
};

export default OrganizationUtils;
