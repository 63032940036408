import { defineMessages } from "react-intl";
import { BaseObjLabels } from "../base-obj";
import { AuthorEditorFieldsLabels } from "../author-editor-fields";

/**
 * Localized labels for Organization fields
 */
export const OrganizationLabels = {
  ...BaseObjLabels,
  ...AuthorEditorFieldsLabels,
  ...defineMessages({
    // organizationId <- not used, ignoring
    name: {
      defaultMessage: "name",
      description: "Field label for organizations name (Organization.name)"
    },
    description: {
      defaultMessage: "description",
      description:
        "Field label for organizations description (Organization.description)"
    }
  })
};

export const OrganizationValues = {
  name: defineMessages({
    undefined: {
      defaultMessage: "Unknown",
      description:
        "Fallback value for missing organization name (Organization.name)"
    }
  })
};
