import { withCloseAfterExited } from "@10duke/dukeui";
import {
  default as View,
  EditOrganizationModalProps
} from "./edit-organization-modal-view";

const EditOrganizationModal = withCloseAfterExited<EditOrganizationModalProps>(
  View
);
export default EditOrganizationModal;
