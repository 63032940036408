import { useState, HTMLAttributes } from "react";
import { useDrag, useDrop } from "react-dnd";
import { TooltipWrapper } from "@10duke/dukeui";
import { TableColumn } from "../index";
import { createPortal } from "react-dom";

export interface HeaderCellProps
  extends Pick<HTMLAttributes<HTMLDivElement>, "children" | "onClick"> {
  tip?: string;
  tipClass?: string;
  column: TableColumn;
  index: number;
  moveColumn: (id: string, index: number) => void;
  findColumn: (id: string) => { column: any; index: number };
  allowDrag: boolean;
  tableHolderElement: HTMLDivElement | undefined;
}
export interface DragItem {
  originalIndex: number;
  id: string;
}
export default function HeaderCell(props: HeaderCellProps) {
  const {
    tip,
    tipClass,
    column,
    index,
    moveColumn,
    findColumn,
    children,
    onClick,
    allowDrag,
    tableHolderElement,
  } = props;
  const id = column.key || "cell_" + index;

  const originalIndex = findColumn(id).index;
  const [{ isDragging, canDrag }, drag] = useDrag(
    () => ({
      type: "column",
      item: { id, originalIndex },
      canDrag: (monitor) => {
        return column.disableDragging !== true && allowDrag;
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        canDrag: monitor.canDrag(),
      }),
      end: (item, monitor) => {
        const { id: droppedId, originalIndex } = item;
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          moveColumn(droppedId, originalIndex);
        }
      },
    }),
    [id, originalIndex, moveColumn]
  );
  const [headerCellElement, setHeaderCellElement] = useState<any>(undefined);

  const [{ isOver, canDrop }, drop] = useDrop({
    accept: "column",
    drop: (item: DragItem) => moveColumn(item.id, index),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const classes = ["duke-header-cell"];
  if (isOver) {
    classes.push("is-drag-over");
  }
  if (canDrop) {
    classes.push("is-droppable");
  }
  if (canDrag) {
    classes.push("is-draggable");
  }
  if (isDragging) {
    classes.push("is-dragging");
  }
  const refHandler = (el: HTMLDivElement) => {
    if (el) {
      setHeaderCellElement(el);
    }
    return drag(drop(el));
  };
  // get left relative to table holder parent
  const left =
    (headerCellElement ? headerCellElement.getBoundingClientRect().left : 0) -
    (tableHolderElement ? tableHolderElement.getBoundingClientRect().left : 0) +
    "px";
  const width = headerCellElement
    ? headerCellElement.getBoundingClientRect().width + "px"
    : "unset";

  return (
    <TooltipWrapper
      delay={{ show: 900, hide: 100 }}
      tip={tip}
      tipClass={tipClass}
      tipKey={id}
      placement={"auto"}
    >
      <div
        key={"cell-header-" + id}
        ref={refHandler}
        className={classes.join(" ")}
        onClick={onClick}
      >
        {children}
        {isOver &&
          canDrop &&
          createPortal(
            <div
              className={"drag-target-indicator"}
              style={{
                left: left,
              }}
            ></div>,
            tableHolderElement as HTMLDivElement
          )}
        {isDragging &&
          createPortal(
            <div
              className={"drag-source-mask"}
              style={{
                width: width,
                left: left,
              }}
            ></div>,
            tableHolderElement as HTMLDivElement
          )}
      </div>
    </TooltipWrapper>
  );
}
