import { defineMessages } from "react-intl";

/**
 * Localized labels for Base obj fields
 */
export const BaseObjLabels = defineMessages({
  id: {
    defaultMessage: "id",
    description: "Field label for the abstract object identifier (BaseObj.id)"
  }
});
