import { ActionSender } from "../model/ActionSender";
import * as ActionTypes from "./actionTypes";
import {
  buildActionThunk,
  buildMultiActionThunk,
  EventOperation,
  EventOperationProvider,
} from "./actionHelpers";
import { authzApi } from "../api";
import { ActionResult, AppAction } from "./actionTypes";
import {
  DownloadLicenseRequest,
  DownloadLicensesRequest,
} from "../model/DownloadLicenseRequest";
import { queryAvailableLicenses, queryLicenseUsage } from "./entActions";

/**
 *
 * @param sender
 * @param data
 */
export function downloadLicense(
  sender: ActionSender,
  data: DownloadLicenseRequest
): ActionTypes.AppThunkAction<ActionTypes.DownloadLicenseAction> {
  return buildActionThunk<ActionTypes.DownloadLicenseAction, any>(
    sender,
    ActionTypes.DOWNLOAD_LICENSE,
    async () => await downloadLicenseInternal(data),
    (type, result) => ({ type, data, result })
  );
}

/**
 *
 * @param data
 */
async function downloadLicenseInternal(
  data: DownloadLicenseRequest
): Promise<any> {
  const result = await authzApi.consumeLicenseForTokenDownload(data);
  return result;
}

/**
 *
 * @param sender
 * @param data
 */
export function downloadLicenses(
  sender: ActionSender,
  data: DownloadLicensesRequest
): ActionTypes.AppThunkAction<ActionTypes.MultiAction> {
  return buildMultiActionThunk(
    sender,
    new DownloadLicensesProvider(sender, data),
    true
  );
}
enum DownloadLicensesState {
  INIT = 0,
  CHECK_LICENSE,
  DOWNLOAD_LICENSE,
  UPDATE_USAGE,
  UPDATE_USAGE_TO_AVAILABLE,
  UPDATE_RESULTS,
  DONE,
}
class DownloadLicensesProvider
  implements EventOperationProvider<ActionTypes.AppAction>
{
  private state: DownloadLicensesState;
  private sender: ActionSender;
  private data: DownloadLicensesRequest;
  private downloadLicenseOperations: EventOperation<ActionTypes.AppAction>[];
  private checkLicenseOperations: EventOperation<ActionTypes.AppAction>[];
  private updateUsageOperations: EventOperation<ActionTypes.AppAction>[];
  private downLoadResults: ActionResult<AppAction>[];

  constructor(sender: ActionSender, data: DownloadLicensesRequest) {
    this.state = DownloadLicensesState.INIT;
    this.sender = sender;
    this.data = data;
    this.checkLicenseOperations = [];
    this.downloadLicenseOperations = [];
    this.updateUsageOperations = [];
    this.downLoadResults = [];
  }

  /**
   * Method for providing next thunk/function to be executed in this chain.
   * @param sender
   * @param multiAction
   */
  next(
    sender: ActionSender,
    multiAction: ActionTypes.MultiAction
  ): EventOperation<ActionTypes.AppAction> | null {
    if (this.state === DownloadLicensesState.INIT) {
      this.state = DownloadLicensesState.CHECK_LICENSE;

      this.checkLicenseOperations = this.buildLicenseOperations({
        ...this.data,
        doConsume: false,
      });
    }
    if (this.state === DownloadLicensesState.CHECK_LICENSE) {
      //
      if (this.checkLicenseOperations.length !== 0) {
        return this.checkLicenseOperations.shift() as EventOperation<ActionTypes.AppAction>;
      } else if (
        !!multiAction.results.find(
          (r) =>
            !(r as any).result ||
            !(r as any).data?.license?.name ||
            (r as any).result[(r as any).data.license.name] !== true
        )
      ) {
        // if even one of the checks fail, we skip the consume & update parts completely and return with the errors
        this.state = DownloadLicensesState.DONE;
      } else {
        // all checks passed, so we should be good to consume
        // the check results are not needed anymore so we remove them from the results
        multiAction.results = [];
        this.downloadLicenseOperations = this.buildLicenseOperations({
          ...this.data,
          doConsume: true,
        });
        this.state = DownloadLicensesState.DOWNLOAD_LICENSE;
      }
    }
    if (this.state === DownloadLicensesState.DOWNLOAD_LICENSE) {
      if (this.downloadLicenseOperations.length !== 0) {
        return this.downloadLicenseOperations.shift() as EventOperation<ActionTypes.AppAction>;
      } else {
        this.downLoadResults = multiAction.results;
        this.updateUsageOperations = this.buildUsageOperations(this.data);
        this.state = DownloadLicensesState.UPDATE_USAGE;
      }
    }
    if (this.state === DownloadLicensesState.UPDATE_USAGE) {
      if (this.updateUsageOperations.length !== 0) {
        return this.updateUsageOperations.shift() as EventOperation<ActionTypes.AppAction>;
      } else {
        this.state = DownloadLicensesState.UPDATE_USAGE_TO_AVAILABLE;
      }
    }
    if (this.state === DownloadLicensesState.UPDATE_USAGE_TO_AVAILABLE) {
      this.state = DownloadLicensesState.UPDATE_RESULTS;
      return {
        thunk: queryAvailableLicenses(
          this.sender,
          this.data.onBehalfOfId as string
        ),
      };
    }
    if (this.state === DownloadLicensesState.UPDATE_RESULTS) {
      this.state = DownloadLicensesState.DONE;
      // We only care abut the results of the actual downloads, as the rest of the actions are just to get around
      // API restrictions and complicated redux state updates, so we replace the results with those.
      if (this.downLoadResults && this.downLoadResults.length) {
        multiAction.results = this.downLoadResults;
      }
    }
    return null;
  }

  private buildLicenseOperations(
    data: DownloadLicensesRequest
  ): EventOperation<ActionTypes.AppAction>[] {
    const ops: EventOperation<ActionTypes.AppAction>[] = [];
    data.licenses.forEach((v: any) => {
      ops.push({
        thunk: downloadLicense(this.sender, {
          hardwareId: data.hardwareId,
          version: data.version,
          onBehalfOfId: data.onBehalfOfId,
          consumptionMode: data.consumptionMode,
          doConsume: data.doConsume,
          license: {
            name: v.name,
            licenseId: v.licenseId,
          },
        }),
      });
    });
    return ops;
  }
  private buildUsageOperations(
    data: DownloadLicensesRequest
  ): EventOperation<ActionTypes.AppAction>[] {
    const ops: EventOperation<ActionTypes.AppAction>[] = [];
    data.licenses
      .filter(
        (l, i) =>
          data.licenses.findIndex((f) => f.licenseId === l.licenseId) === i
      )
      .forEach((v: any) => {
        ops.push({
          thunk: queryLicenseUsage(this.sender, "any", v.licenseId),
        });
      });
    return ops;
  }
}
