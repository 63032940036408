import { useEffect, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {FeedbackEntry, Feedback, Modal} from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import {
  ClearErrorAction,
  DeleteOrgGroupAction,
  GetOrgGroupAction,
  isAddErrorAction,
} from "../../../actions/actionTypes";
import { OrganizationGroup } from "../../../model/OrganizationGroup";

//<editor-fold desc="Props">

export interface RemoveGroupModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  onRemoveGroup: (groupId: string) => Promise<DeleteOrgGroupAction>;
  onLoadGroup: (groupId: string) => Promise<GetOrgGroupAction>;
  onLoadGroups: () => void;
  group: OrganizationGroup | undefined | null;
  employeesGroupId: string | undefined;
  onClearError: (errorId: string) => ClearErrorAction;
}

export interface RemoveGroupModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}

export interface RemoveGroupModalProps
  extends RemoveGroupModalDOMProps,
    RemoveGroupModalDataProps,
    Omit<ClosableModalProps, "isReady"> {
  onShowFeedback: (feedback: FeedbackEntry) => void;
  groupId?: string;
}
//</editor-fold>

function RemoveGroupModal(props: RemoveGroupModalProps) {
  //<editor-fold desc="Local variables">
  let {
    group,
    groupId,
    employeesGroupId,
    onRemoveGroup,
    show,
    onClose,
    onShowFeedback,
    onLoadGroup,
    onLoadGroups,
    isReady,
    onExited,
    onClearError,
  } = props;
  const groupObjId = group ? group.id : undefined;
  // this is more like a variable than a hook
  const intl = useIntl();
  useEffect(() => {
    if (
      !!show &&
      !!groupId &&
      (groupObjId === undefined || (!!groupObjId && groupObjId !== groupId)) &&
      !!onLoadGroup
    ) {
      onLoadGroup(groupId).then((res) => {
        if (!groupObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, groupId, groupObjId, onLoadGroup, onClearError]);
  useEffect(() => {
    if (show && !employeesGroupId && onLoadGroups) {
      onLoadGroups();
    }
  }, [show, employeesGroupId, onLoadGroups]);

  //</editor-fold>

  return (
    <Modal
      onExited={onExited}
      onReloadData={() => {
        if ((group || groupId) && onLoadGroup) {
          onLoadGroup(group ? (group.id as string) : (groupId as string)).then(
            (res) => {
              if (!group && isAddErrorAction(res)) {
                // only clear error if no data exists, leave if previous data is still available and
                // let the api error notification handler show error
                onClearError(res.error?.errorId);
              }
            }
          );
        }
        if (onLoadGroups) {
          onLoadGroups();
        }
      }}
      isReady={isReady}
      data-test-remove-group-modal={group ? group.id : true}
      title={intl.formatMessage({
        defaultMessage: "Remove Group",
        description: "modal heading",
      })}
      show={show}
      onClose={onClose}
      primaryButton={
        group && group.id !== employeesGroupId
          ? {
              label: intl.formatMessage({
                defaultMessage: "Remove",
                description: "primary button label",
              }),
            }
          : {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
      }
      onPrimaryAction={() => {
        if (show && group && group.id !== employeesGroupId) {
          onRemoveGroup(group ? (group.id as string) : "not_possible").then(
            (res) => {
              if (!isAddErrorAction(res)) {
                onShowFeedback({
                  id: "removeGroup",
                  msg: intl.formatMessage(
                    {
                      defaultMessage: "Group {name} removed.",
                      description:
                        "success notification. 'name' = name of the group",
                    },
                    {
                      name:
                        "<strong>" +
                        (group ? group.name : "impossible") +
                        "</strong>",
                    }
                  ),
                  autoClose: true,
                  type: "success",
                });
              } else {
                onClearError(res.error?.errorId);
                onShowFeedback({
                  id: "removeGroup",
                  msg: intl.formatMessage(
                    {
                      defaultMessage: "Removing group {name} failed: {msg}",
                      description:
                        "failure notification. 'name' = name of the group, 'msg' = error message",
                    },
                    {
                      name:
                        "<strong>" +
                        (group ? group.name : "impossible") +
                        "</strong>",
                      msg: res.error.apiError.error_description,
                    }
                  ),
                  type: "danger",
                });
              }
            }
          );
        }
        onClose();
      }}
      secondaryButton={
        show && group && group.id !== employeesGroupId
          ? {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={group ? onClose : undefined}
    >
      <>
        {show && group && group.id !== employeesGroupId && (
          <p>
            <FormattedMessage
              defaultMessage="Are you sure you wish to remove the group {name} from this organization?"
              description={
                "confirm action message. 'name' = the name of the group"
              }
              values={{
                name: <strong>{group ? group.name : "impossible"}</strong>,
              }}
            />
          </p>
        )}
        {show && group && group.id === employeesGroupId && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p data-test-group-is-emmployees-group>
              <FormattedMessage
                defaultMessage="The employees group is special and cannot be removed."
                description="message to be shown when trying to remove the required employees group"
              />
            </p>
          </Feedback>
        )}
        {isReady && show && !group && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p data-test-group-not-found>
              <FormattedMessage
                defaultMessage="No matching group found."
                description="message to be shown when the group was not found"
              />
            </p>
          </Feedback>
        )}
      </>
    </Modal>
  );
}

export default RemoveGroupModal;
