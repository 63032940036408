import { connect } from "react-redux";
import ManageDeviceClientGroupDeviceClientsModal, {
  ManageDeviceClientGroupDeviceClientsModalProps as _ManageDeviceGroupDevicesModalProps,
} from "./manage-device-client-group-device-clients-modal-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import OrganizationUtils from "../../../utils/organization";
import {
  addClientsToOrganizationClientGroup,
  clearError, getOrganizationClientGroup,
  listClientsInOrganizationClientGroup, listOrganizationClients, removeClientsFromOrganizationClientGroup,
} from "../../../actions";
import { ManageDeviceClientGroupDeviceClientsModalDataProps } from "./manage-device-client-group-device-clients-modal-view";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";

export type ManageDeviceClientGroupClientDevicesModalProps = Omit<
  _ManageDeviceGroupDevicesModalProps,
  keyof ManageDeviceClientGroupDeviceClientsModalDataProps
>;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ManageDeviceGroupDevicesModalProps,
  ManageDeviceClientGroupClientDevicesModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ManageDeviceGroupDevicesModalProps,
  ManageDeviceClientGroupClientDevicesModalProps
>;

const sender: ActionSender = { key: "manage-device-client-group-client-devices" };

function mapStateToProps(
  state: AppState,
  ownProps: ManageDeviceClientGroupClientDevicesModalProps
): ReduxStateProps {
  let clients = OrganizationUtils.selectOrganizationClients(state);
  return {
    clients,
    groupMembers: OrganizationUtils.selectOrganizationClientGroupClientIds(
      ownProps.groupId as string,
      state
    ),
    group: OrganizationUtils.selectOrganizationClientGroup(
      ownProps.groupId as string,
      state
    ),
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientsStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientGroupsStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientGroupClientIdsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadClients: addSenderArgument(sender, listOrganizationClients),
  onLoadGroupMembers: addSenderArgument(sender, listClientsInOrganizationClientGroup),
  onAddClients: addSenderArgument(sender, addClientsToOrganizationClientGroup),
  onRemoveClients: addSenderArgument(sender, removeClientsFromOrganizationClientGroup),
  onLoadGroup: addSenderArgument(sender, getOrganizationClientGroup),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ManageDeviceClientGroupClientDevicesModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ManageDeviceClientGroupDeviceClientsModal);
