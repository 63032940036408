import * as ActionTypes from "../actions/actionTypes";
import { OrgGroupsById } from "../store/OrganizationGroupState";

export default function orgGroups(
  state: OrgGroupsById,
  action: ActionTypes.AppAction
): OrgGroupsById | null {
  const currentState = state || ({} as OrgGroupsById);
  switch (action.type) {
    case ActionTypes.LIST_ORG_GROUPS:
      const listOrgGroups = action as ActionTypes.ListOrgGroupsAction;
      const newOrgGroupsById = listOrgGroups.groups.reduce<OrgGroupsById>(
        (map, group) => {
          map[group.id as string] = group;
          return map;
        },
        {}
      );
      return { ...currentState, ...newOrgGroupsById };
    case ActionTypes.LIST_LICENSE_CONSUMING_ORG_GROUPS: {
      const actionResult = action as ActionTypes.ListLicenseConsumingOrgGroupsAction;
      const newOrgGroupsById = actionResult.groups.reduce<OrgGroupsById>(
        (map, group) => {
          map[group.id as string] = group;
          return map;
        },
        {}
      );
      return { ...currentState, ...newOrgGroupsById };
    }
    case ActionTypes.CREATE_ORG_GROUP:
      const createOrgGroup = action as ActionTypes.CreateOrgGroupAction;
      return {
        ...currentState,
        [createOrgGroup.group.id as string]: createOrgGroup.group
      };
    case ActionTypes.REPLACE_ORG_GROUP:
      const replaceOrgGroup = action as ActionTypes.ReplaceOrgGroupAction;
      return {
        ...currentState,
        [replaceOrgGroup.group.id as string]: replaceOrgGroup.group
      };
    case ActionTypes.GET_ORG_GROUP:
      const getOrgGroup = action as ActionTypes.GetOrgGroupAction;
      return {
        ...currentState,
        [getOrgGroup.group.id as string]: getOrgGroup.group
      };
    case ActionTypes.DELETE_ORG_GROUP:
      const delOrgGroup = action as ActionTypes.DeleteOrgGroupAction;
      return removeOrgGroup(delOrgGroup.orgGroupId, currentState);
    case ActionTypes.LIST_ORG_GROUPS_OF_USER:
      const listUserOrgGroups = action as ActionTypes.ListOrgGroupsOfUserAction;
      const newUserOrgGroupsById = listUserOrgGroups.groups.reduce<
        OrgGroupsById
      >((map, group) => {
        map[group.id as string] = group;
        return map;
      }, {});
      return { ...currentState, ...newUserOrgGroupsById };
    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

function removeOrgGroup(
  orgGroupId: string,
  currentState: OrgGroupsById
): OrgGroupsById {
  const { [orgGroupId]: _, ...remaining } = currentState;
  return remaining;
}

function handleErrorAction(
  currentState: OrgGroupsById,
  action: ActionTypes.AppAction
): OrgGroupsById {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  if (
    errorAction.error.action.type === ActionTypes.GET_ORG_GROUP &&
    errorAction.error.apiError.error === "404"
  ) {
    const typedError = action as ActionTypes.AddErrorAction<
      ActionTypes.GetOrgGroupAction
    >;
    finalState = removeOrgGroup(
      typedError.error.action?.orgGroupId as string,
      currentState
    );
  }

  return finalState;
}
