import { useState } from "react";
import { FeedbackContainerProps,withFeedback } from "@10duke/dukeui";
import RolesView, {
  RolesProps as _RolesProps,
  RolesStateProps,
  RolesDOMProps as _RolesDOMProps,
  RolesModalVisibilityProps as _RolesModalVisibilityProps,
} from "./roles-view";
import { OrganizationRole } from "../../../model/OrganizationRole";
export { ModalKeys } from "./roles-view";

export type RolesDOMProps = _RolesDOMProps;
export type RolesModalVisibilityProps = _RolesModalVisibilityProps;

export interface RolesProps extends Omit<_RolesProps, keyof RolesStateProps> {}

function Roles(props: RolesProps & FeedbackContainerProps) {
  const [selected, setSelected] = useState([] as OrganizationRole[]);
  const viewProps: _RolesProps = {
    selected: selected,
    onSetSelected: setSelected,
    ...props,
  };
  return <RolesView {...viewProps} />;
}
export default withFeedback<RolesProps & FeedbackContainerProps>(Roles);
