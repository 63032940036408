import { connect } from "react-redux";
import CreateOrganizationRoleModal, {
  CreateOrganizationRoleModalProps as _CreateOrganizationRoleModalProps,
} from "./create-organization-role-modal-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import OrganizationUtils from "../../../utils/organization";
import { CreateOrganizationRoleModalDataProps } from "./create-organization-role-modal-view";
import {
  clearError,
  createOrganizationRole,
  listOrganizationRoles,
  listPermissions,
  setOrganizationRolesOfOrganizationRole,
  setPermissionsOfOrganizationRole,
} from "../../../actions";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";
import PermissionsUtils from "../../../utils/permissions";
import { PermissionWithGrantedActions } from "../../../model/PermissionWithGrantedActions";

export type CreateOrganizationRoleModalProps = Omit<
  _CreateOrganizationRoleModalProps,
  keyof CreateOrganizationRoleModalDataProps
>;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _CreateOrganizationRoleModalProps,
  CreateOrganizationRoleModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _CreateOrganizationRoleModalProps,
  CreateOrganizationRoleModalProps
>;

const sender: ActionSender = { key: "create-organization-role" };

function mapStateToProps(
  state: AppState,
  ownProps: CreateOrganizationRoleModalProps
): ReduxStateProps {
  return {
    organizationId: OrganizationUtils.selectSelectedOrganizationId(state),
    permissions: PermissionsUtils.selectPermissions(state)?.map((p) => {
      const t: PermissionWithGrantedActions = {
        ...p,
        grantedActions: {
          allowedActions: [],
        },
      } as PermissionWithGrantedActions;
      return t;
    }),
    roles: OrganizationUtils.selectOrganizationRoles(state),
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    OrganizationUtils.compareSelectedOrganizationIdStates(next, prev) &&
    OrganizationUtils.compareOrganizationRolesStates(next, prev) &&
    InProgressUtils.compareInProgressStates(next, prev) &&
    PermissionsUtils.comparePermissionStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadPermissions: addSenderArgument(sender, listPermissions),
  onLoadRoles: addSenderArgument(sender, listOrganizationRoles),
  onSetRolePermissions: addSenderArgument(
    sender,
    setPermissionsOfOrganizationRole
  ),
  onCreateOrganizationRole: addSenderArgument(sender, createOrganizationRole),
  onSetIncludedRoles: addSenderArgument(
    sender,
    setOrganizationRolesOfOrganizationRole
  ),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  CreateOrganizationRoleModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(CreateOrganizationRoleModal);
