import * as ActionTypes from "../actions/actionTypes";
import { OrgGroupUserIdsByOrgGroupId } from "../store/OrganizationGroupState";
import {
  removeFromStateListsById,
  removeUsersFromStateLists,
} from "../util/ReducerUtils";

export default function orgGroupUserIds(
  state: OrgGroupUserIdsByOrgGroupId,
  action: ActionTypes.AppAction
): OrgGroupUserIdsByOrgGroupId | null {
  const currentState = state || ({} as OrgGroupUserIdsByOrgGroupId);
  switch (action.type) {
    case ActionTypes.LIST_USERS_IN_ORG_GROUP: {
      const listUsersInOrgGroup =
        action as ActionTypes.ListUsersInOrgGroupAction;
      const userIds = listUsersInOrgGroup.users.map(
        (user) => user.id as string
      );
      return { ...currentState, [listUsersInOrgGroup.orgGroupId]: userIds };
    }
    case ActionTypes.ADD_USERS_TO_ORG_GROUP: {
      const addUsersToOrgGroup = action as ActionTypes.AddUsersToOrgGroupAction;
      const beforeAddUsers = currentState[addUsersToOrgGroup.orgGroupId] || [];
      const afterAddUsers = [...beforeAddUsers, ...addUsersToOrgGroup.userIds];
      return {
        ...currentState,
        [addUsersToOrgGroup.orgGroupId]: afterAddUsers,
      };
    }
    case ActionTypes.IMPORT_ORGANIZATION_USERS: {
      const importOrganizationUsersAction =
        action as ActionTypes.ImportOrganizationUsersAction;
      const nextState = { ...currentState };

      importOrganizationUsersAction.users.forEach((user) =>
        (user.groupIds || []).forEach((groupId) => {
          const groupUsers = [...(nextState[groupId] || []), user.id as string];
          nextState[groupId] = groupUsers.filter(
            (id, i) => groupUsers.indexOf(id) === i
          );
        })
      );

      return nextState;
    }
    case ActionTypes.SET_USERS_IN_ORG_GROUP: {
      const setUsersInOrgGroup = action as ActionTypes.SetUsersInOrgGroupAction;
      return removeUsersFromStateLists<OrgGroupUserIdsByOrgGroupId>(action, {
        ...currentState,
        [setUsersInOrgGroup.orgGroupId]: [...setUsersInOrgGroup.userIds],
      });
    }
    case ActionTypes.REMOVE_USERS_FROM_ORG_GROUP: {
      const removeUsersFromOrgGroup =
        action as ActionTypes.RemoveUsersFromOrgGroupAction;
      const beforeRemoveUsers =
        currentState[removeUsersFromOrgGroup.orgGroupId] || [];
      const afterRemoveUsers = beforeRemoveUsers.filter(
        (userId) => removeUsersFromOrgGroup.userIds.indexOf(userId) === -1
      );
      return {
        ...currentState,
        [removeUsersFromOrgGroup.orgGroupId]: afterRemoveUsers,
      };
    }
    case ActionTypes.ADD_ORG_GROUP_FOR_USER: {
      const addOrgGroupForUser = action as ActionTypes.AddOrgGroupForUserAction;
      const beforeAddGroup = currentState[addOrgGroupForUser.orgGroupId] || [];
      const afterAddGroup = [...beforeAddGroup, addOrgGroupForUser.userId];
      return {
        ...currentState,
        [addOrgGroupForUser.orgGroupId]: afterAddGroup,
      };
    }
    case ActionTypes.REMOVE_ORG_GROUP_OF_USER: {
      const removeOrgGroupOfUser =
        action as ActionTypes.RemoveOrgGroupOfUserAction;
      const beforeRemoveGroup =
        currentState[removeOrgGroupOfUser.orgGroupId] || [];
      const afterRemoveGroup = beforeRemoveGroup.filter(
        (userId) => removeOrgGroupOfUser.userId !== userId
      );
      return removeUsersFromStateLists<OrgGroupUserIdsByOrgGroupId>(action, {
        ...currentState,
        [removeOrgGroupOfUser.orgGroupId]: afterRemoveGroup,
      });
    }
    case ActionTypes.DELETE_USER:
    case ActionTypes.REMOVE_USER_FROM_ORG: {
      return removeUsersFromStateLists<OrgGroupUserIdsByOrgGroupId>(
        action,
        currentState
      );
    }
    case ActionTypes.DELETE_ORG_GROUP: {
      const delOrgGroup = action as ActionTypes.DeleteOrgGroupAction;
      return deleteOrgGroup(delOrgGroup.orgGroupId, currentState);
    }

    case ActionTypes.ADD_ORG_GROUPS_FOR_USER: {
      const newState = { ...currentState };
      const addOrgGroupsForUser =
        action as ActionTypes.AddOrgGroupsForUserAction;
      addOrgGroupsForUser.orgGroupIds.forEach((orgGroupId) => {
        const usersOfGroup = currentState[orgGroupId] || [];
        if (!usersOfGroup.includes(addOrgGroupsForUser.userId)) {
          newState[orgGroupId] = [...usersOfGroup, addOrgGroupsForUser.userId];
        }
      });
      return newState;
    }
    case ActionTypes.SET_ORG_GROUPS_OF_USER: {
      const removed = removeUsersFromStateLists<OrgGroupUserIdsByOrgGroupId>(
        action,
        currentState
      );
      const setOrgGroupsForUser =
        action as ActionTypes.SetOrgGroupsOfUserAction;
      const newState = { ...removed };
      // wipe
      for (const orgGroupId in removed) {
        const currentUsersOfGroup = removed[orgGroupId] || [];
        if (currentUsersOfGroup.includes(setOrgGroupsForUser.userId)) {
          const cleanedUsersOfGroup = currentUsersOfGroup.filter(
            (userId) => userId !== setOrgGroupsForUser.userId
          );
          newState[orgGroupId] = cleanedUsersOfGroup;
        }
      }
      // add
      setOrgGroupsForUser.orgGroupIds.forEach((orgGroupId) => {
        newState[orgGroupId] = [
          ...(newState[orgGroupId] || []),
          setOrgGroupsForUser.userId,
        ];
      });
      return newState;
    }
    case ActionTypes.REMOVE_ORG_GROUPS_OF_USER: {
      const newState = removeUsersFromStateLists(action, currentState);
      const removeOrgGroupsOfUser =
        action as ActionTypes.RemoveOrgGroupsOfUserAction;
      removeOrgGroupsOfUser.orgGroupIds.forEach((orgGroupId) => {
        const usersOfGroup = (currentState[orgGroupId] || []).filter(
          (userId) => userId !== removeOrgGroupsOfUser.userId
        );
        newState[orgGroupId] = usersOfGroup;
      });
      return newState;
    }
    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

function deleteOrgGroup(
  orgGroupId: string,
  currentState: OrgGroupUserIdsByOrgGroupId
): OrgGroupUserIdsByOrgGroupId {
  const { [orgGroupId]: _, ...remaining } = currentState;
  return remaining;
}

function handleErrorAction(
  currentState: OrgGroupUserIdsByOrgGroupId,
  action: ActionTypes.AppAction
): OrgGroupUserIdsByOrgGroupId {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  if (
    errorAction.error.action.type === ActionTypes.GET_ORG_GROUP &&
    errorAction.error.apiError.error === "404"
  ) {
    const typedError =
      action as ActionTypes.AddErrorAction<ActionTypes.GetOrgGroupAction>;
    finalState = deleteOrgGroup(
      typedError.error.action?.orgGroupId as string,
      currentState
    );
  } else if (
    errorAction.error.action.type === ActionTypes.GET_USER &&
    errorAction.error.apiError.error === "404"
  ) {
    const typedError =
      action as ActionTypes.AddErrorAction<ActionTypes.GetUserAction>;
    finalState = removeFromStateListsById<OrgGroupUserIdsByOrgGroupId>(
      [typedError.error.action?.userId as string],
      currentState
    );
  }
  return finalState;
}
