import store, {
  getPendingAuthentication,
  saveStore,
  getAuthentication,
} from "../store";
import { isAuthenticatedAndAuthorized } from "./authentication";
import {
  startAuthn,
  addErrorForAuthnResponse,
  addErrorForErrorFromServer,
  setAuthn,
  clearAuthnStatus,
  setLogoutCompleted,
} from "../actions";
import { Unsubscribe } from "redux";
import { PendingAuthentication } from "../model/PendingAuthentication";
import { ApiError } from "../model/ApiError";
import { Authentication } from "../model/Authentication";
import _debug from "debug";
import { getAppBase } from "./env";
import { Authenticator, StartLoginResponse, Authentication as AuthenticationResponse } from "@10duke/web-client-pkce";
import {MATCH_LOCALE_PATTERN} from "../utils/locale";
const debug = _debug("AppGatekeeperAuthenticator:base");

enum AuthnHandlingStatus {
  /**
   * Authenticator has processed one of the possible authentication or logout operations
   * and there was nothing to be done, continue to the next operation.
   */
  Continue,

  /**
   * Browser has been redirected to another route of this application. The application should cease
   * all processing, and not render the UI.
   */
  Redirected,

  /**
   * Authenticator has completed handling with one of the following end states:
   *
   * - authenticated, SET_AUTHN action dispatched for setting the authenticated user to the Redux store
   * - logout completed, logout response received from the identity provider
   *
   */
  Handled,
}

class AuthnResponseError extends Error {
  error: ApiError;
  constructor(error: ApiError, message?: string) {
    super(message || error.error_description || error.error);
    this.error = error;
  }
}

/**
 * Authenticator intended to be called before rendering the application.
 * Checks if there is a valid authentication and OAuth authorization,
 * and redirects to authentication by setting window.location.href
 * if no valid authentication found.
 *
 * This base class implements mock authentication process by directly
 * issuing redirect representing authentication response from the server.
 * Authentication against an external OpenID Connect (OIDC) provider
 * can be implemented in a derived class.
 */
export default class AppGatekeeperAuthenticatorBase {
  /**
   * Stored nonce expiration in seconds.
   */
  public static readonly NONCE_EXPIRES_IN = 300;

  /**
   * ID token must not be issued in the future, but allow some leeway when checking iat.
   * Leeway in seconds.
   */
  public static readonly DEFAULT_ID_TOKEN_IAT_LEEWAY = 900;

  private static readonly STATE_PARAM_URL = "url";
  private static readonly STATE_PARAM_STATE = "state";

  private unsubscribeStateChange?: Unsubscribe;

  private logoutPath: string;
  private authenticator: Authenticator;
  private clientId: string;

  /**
   * Initializes a new instance of the authenticator.
   * @param authnUri URL of identity provider endpoint for authentication.
   * @param tokenUri URL of identity provider token endpoint.
   * @param sloUri URL of identity provider endpoint for single logout (with 10Duke custom SLO protocol).
   * @param clientId OAuth client id used by this application when communicating with the IdP.
   * @param jwksUri URL of identity provider endpoint for JWKS key service.
   * @param redirectUri OAuth redirect_uri for redirecting back to this application from the IdP.
   * @param logoutPath Local route path of this application for logout requests and logout
   *    responses from the IdP.
   * @param idTokenIatLeeway Maximum allowed leeway when checking validity of ID token iat (issued at) claim.
   */
  public constructor(
    authnUri: URL,
    tokenUri: URL,
    sloUri: URL,
    clientId: string,
    jwksUri: URL,
    redirectUri: URL,
    logoutPath: string,
    idTokenIatLeeway: number = AppGatekeeperAuthenticatorBase.DEFAULT_ID_TOKEN_IAT_LEEWAY
  ) {
    this.unsubscribeStateChange = undefined;
    this.logoutPath = logoutPath;
    this.clientId = clientId;
    this.authenticator = new Authenticator(
      authnUri,
      tokenUri,
      sloUri,
      jwksUri,
      clientId,
      redirectUri,
      "openid profile email",
      idTokenIatLeeway);
  }

  /**
   * Handles authentication, including logout.
   *
   * Checks if there is a valid authentication, or if there is an authentication response waiting
   * to be processed. Starts new authentication process if necessary.
   *
   * For logout, handles logout requests and logout responses from the identity provider.
   *
   * @param state Opaque state of the calling context to pass through the authentication
   *    process if this call starts authentication.
   * @returns true if application may continue running normally, false if browser is
   *    being redirected and there is nothing that should be done in the application
   */
  public async handleAuthentication(state?: string): Promise<boolean> {
    const logoutRequestHandlingStatus = await this.handleLogoutRequest();
    if (logoutRequestHandlingStatus !== AuthnHandlingStatus.Continue) {
      debug("logout request found and handled");
      return false;
    }

    const logoutResponseHandlingStatus = await this.handleLogoutResponse();
    if (logoutResponseHandlingStatus === AuthnHandlingStatus.Handled) {
      this.onLogoutHandled();
      debug("logout response found and handled");
      return true;
    }
    if (logoutResponseHandlingStatus === AuthnHandlingStatus.Redirected) {
      debug("redirected logout response to URL saved in the OAuth state");
      return false;
    }

    const authnRespHandlingStatus = await this.handleAuthenticationResponse();
    if (authnRespHandlingStatus === AuthnHandlingStatus.Handled) {
      debug(
        "authentication response found and handled by either setting authentication status or by setting error status"
      );
      return true;
    }
    if (authnRespHandlingStatus === AuthnHandlingStatus.Redirected) {
      debug(
        "redirected authentication response to URL saved in the OAuth state"
      );
      return false;
    }

    const authentication = store.getState() && store.getState().authentication;
    const authenticated = isAuthenticatedAndAuthorized(authentication);
    if (authenticated) {
      debug("existing authentication found: %o", {
        ...authentication,
        accessToken: "***",
      });
      return true;
    }

    // Start authentication by first setting app state to reflect that there
    // is a pending authentication, then handling the state change and redirecting
    // to IdP for authentication
    this.unsubscribeStateChange = store.subscribe(async () =>
      this.onStateChange()
    );
    debug("starting authentication");
    const stateForStartLogin = this.buildAuthenticationRequestState(state);
    const startLoginResponse = await this.authenticator.startLogin(stateForStartLogin);
    store.dispatch(startAuthn(
      startLoginResponse.url,
      startLoginResponse.codeVerifier,
      startLoginResponse.nonce,
      startLoginResponse.nonceIssuedAt));
    return false;
  }

  /**
   * Logout event handled callback
   * @protected
   */
  protected onLogoutHandled(): void {}

  /**
   * Checks if received an authentication response waiting to be handled, and
   * handles the response as necessary.
   */
  private async handleAuthenticationResponse(): Promise<AuthnHandlingStatus> {
    const authnResponse = this.readAuthenticationResponse();
    if (authnResponse === undefined) {
      debug("no authentication response found");
      return AuthnHandlingStatus.Continue;
    }

    const redirectTarget = await this.redirectAuthnResponse(authnResponse);
    if (redirectTarget) {
      debug(
        "authentication response redirected to %s",
        redirectTarget
      );
      return AuthnHandlingStatus.Redirected;
    }

    const error = authnResponse.get("error");
    if (error) {
      debug("authentication response has error sent by server: %s", error);
      this.clearAuthenticationStatus();
      this.notifyErrorResponse(authnResponse);
      return AuthnHandlingStatus.Handled;
    }

    try {
      await this.authenticate(authnResponse);
    } catch (err) {
      debug("authentication error: %o", err);
      this.clearAuthenticationStatus();
      this.notifyAuthnResponseError(err as AuthnResponseError);
    }

    return AuthnHandlingStatus.Handled;
  }

  private clearAuthenticationStatus(): void {
    debug("clearing authentication status");
    store.dispatch(clearAuthnStatus());
  }

  /**
   * Validates authentication code response from server, requests for access token,
   * and sets local authentication state.
   * @param authnCodeResponse Authentication code response from server.
   */
  private async authenticate(authnCodeResponse: URLSearchParams): Promise<void> {
    debug("authentication response: %s", authnCodeResponse.toString());

    const pendingAuthentication = getPendingAuthentication();
    if (pendingAuthentication === undefined) {
      debug("no pending authentication found, authentication will not be completed");
      return;
    }

    if (!authnCodeResponse.has("code")) {
      throw new AuthnResponseError({
        error: "invalid_authn_response",
        error_description: "missing code",
      });
    }
    let code = authnCodeResponse.get("code") as string;
    const responseState = authnCodeResponse.get("state");

    const startLoginResponse: StartLoginResponse = {
      ...pendingAuthentication,
    }
    const authenticationResponse = await this.completeAuthentication(
      startLoginResponse,
      code,
      responseState || undefined);
    const accessToken = authenticationResponse.getAccessToken();
    const expiresIn = authenticationResponse.getAccessTokenResponse().expires_in;
    const userDisplayName = authenticationResponse.getUserDisplayName();
    const idToken = authenticationResponse.getIdToken();
    debug("access token response received, expires in %d", expiresIn);

    let state: string | undefined = undefined;
    if (responseState && responseState.length > 0) {
      const stateParams = new URLSearchParams(responseState);
      state =
        stateParams.get(AppGatekeeperAuthenticatorBase.STATE_PARAM_STATE) ||
        undefined;
    }

    const now = new Date().getTime();
    const authentication: Authentication = {
      accessToken,
      accessTokenReceived: now,
      accessTokenExpiresIn: expiresIn,
      userId: idToken.sub,
      authnIssued: idToken.iat * 1000,
      authnExpires: idToken.exp * 1000,
      userDisplayName,
      userEmail: idToken.email,
      userLocale: idToken.locale,
      state,
    };
    debug(
      "setting authentication for user %s (%s)",
      authenticationResponse.getUserId(),
      authenticationResponse.getUserEmail());
    if (!isAuthenticatedAndAuthorized(authentication)) {
      throw new AuthnResponseError({
        error: "authentication_not_valid",
        error_description:
          "authentication data received from server is not valid",
      });
    }

    store.dispatch(setAuthn(authentication));
  }

  protected async completeAuthentication(
      startLoginResponse: StartLoginResponse,
      code: string,
      state?: string): Promise<AuthenticationResponse> {
    debug("completing authentication by exchanging code to access token and id token");
    return await this.authenticator.completeAuthentication(startLoginResponse, code, state);
  }

  private async redirectLogoutResponse(
    authnResponse: URLSearchParams
  ): Promise<string | undefined> {
    const authnRequestState = authnResponse.get("RelayState");
    if (
      authnRequestState === undefined ||
      authnRequestState === null ||
      authnRequestState.length === 0
    ) {
      return undefined;
    }

    const stateParams = new URLSearchParams(authnRequestState);
    if (!stateParams.has(AppGatekeeperAuthenticatorBase.STATE_PARAM_URL)) {
      return undefined;
    }

    let currentUrl = this.getCurrentUrlWithoutAuthzCodeParams();
    const targetUrl = stateParams.get(
      AppGatekeeperAuthenticatorBase.STATE_PARAM_URL
    ) as string;
    const appBase: string = getAppBase();
    if (targetUrl) {
      if (
        new URL(currentUrl).pathname ===
        new URL(targetUrl, window.location.href.split(appBase)[0]).pathname
      ) {
        return undefined;
      }
      const currentParts: string[] = currentUrl.split(appBase);
      const targetParts: string[] =
        targetUrl.indexOf(appBase) >= 0 ? targetUrl.split(appBase) : [];
      const currentUrlPrecedingBase: string = currentParts[0];
      const targetUrlPrecedingBase: string | undefined =
        targetParts.length > 0 ? targetParts[0] : undefined;
      if (
        !targetUrlPrecedingBase ||
        !targetUrlPrecedingBase.startsWith(currentUrlPrecedingBase)
      ) {
        return undefined;
      } else {
        let potentialLocale = targetUrlPrecedingBase.replace(
            currentUrlPrecedingBase,
            ""
        );
        if (potentialLocale.startsWith('/')) {
          potentialLocale = potentialLocale.substring(1);
        }
        if (
            potentialLocale !== "" &&
            !potentialLocale.match(MATCH_LOCALE_PATTERN)
        ) {
          return undefined;
        }
      }
      const redirectTo =
        stateParams.get(AppGatekeeperAuthenticatorBase.STATE_PARAM_URL) +
        "?" +
        authnResponse.toString();
      window.location.replace(redirectTo);
      return redirectTo;
    } else {
      return undefined;
    }
  }

  private async redirectAuthnResponse(
    authnResponse: URLSearchParams
  ): Promise<string | undefined> {
    const authnRequestState = authnResponse.get("state");
    if (
      authnRequestState === undefined ||
      authnRequestState === null ||
      authnRequestState.length === 0
    ) {
      return undefined;
    }

    const stateParams = new URLSearchParams(authnRequestState);
    if (!stateParams.has(AppGatekeeperAuthenticatorBase.STATE_PARAM_URL)) {
      return undefined;
    }

    let currentUrl = this.getCurrentUrlWithoutAuthzCodeParams();
    const targetUrl = stateParams.get(
      AppGatekeeperAuthenticatorBase.STATE_PARAM_URL
    ) as string;
    debug(
      "current url (without authz code params): %s, target url from state: %s",
      currentUrl,
      targetUrl);
    if (currentUrl === targetUrl) {
      debug("current url and target url match, not redirecting");
      return undefined;
    }

    debug("current url and target url do not match, redirecting to target url");
    const appBase: string = getAppBase();
    const currentParts: string[] = currentUrl.split(appBase);
    const targetParts: string[] =
      targetUrl.indexOf(appBase) >= 0 ? targetUrl.split(appBase) : [];
    const currentUrlPrecedingBase: string = currentParts[0];
    const targetUrlPrecedingBase: string | undefined =
      targetParts.length > 0 ? targetParts[0] : undefined;
    if (
      !targetUrlPrecedingBase ||
      !targetUrlPrecedingBase.startsWith(currentUrlPrecedingBase)
    ) {
      return undefined;
    } else {
      let potentialLocale = targetUrlPrecedingBase.replace(
        currentUrlPrecedingBase,
        ""
      );
      if (potentialLocale.startsWith('/')) {
        potentialLocale = potentialLocale.substring(1);
      }
      if (
        potentialLocale !== "" &&
        !potentialLocale.match(MATCH_LOCALE_PATTERN)
      ) {
        return undefined;
      }
    }

    const redirectTo =
      stateParams.get(AppGatekeeperAuthenticatorBase.STATE_PARAM_URL) +
      "?" +
      authnResponse.toString();
    window.location.replace(redirectTo);
    return redirectTo;
  }

  private notifyAuthnResponseError(error: AuthnResponseError): void {
    if (error && error.error) {
      store.dispatch(
        addErrorForAuthnResponse(error.error, "HANDLE_AUTHN_RESPONSE")
      );
    }
  }

  private notifyErrorResponse(authnResponse: URLSearchParams): void {
    store.dispatch(
      addErrorForErrorFromServer(
        authnResponse.get("error") as string,
        "HANDLE_AUTHN_RESPONSE",
        authnResponse.get("error_description") || undefined,
        authnResponse.get("error_uri") || undefined
      )
    );
  }

  private readAuthenticationResponse(): URLSearchParams | undefined {
    if (
      window.location.search === undefined ||
      window.location.search === null ||
      window.location.search.length < 2
    ) {
      return undefined;
    }

    const responseParams = new URLSearchParams(window.location.search);
    if (!responseParams.has("code")) {
      return undefined;
    }
    const urlWithoutAuthzCodeParams = this.getCurrentUrlWithoutAuthzCodeParams();
    window.history.replaceState(window.history.state, '', urlWithoutAuthzCodeParams);
    return responseParams;
  }

  private async onStateChange(): Promise<void> {
    const pendingAuthentication = getPendingAuthentication();
    if (pendingAuthentication) {
      if (this.unsubscribeStateChange) {
        this.unsubscribeStateChange();
        this.unsubscribeStateChange = undefined;
      }

      try {
        await this.startAuthentication(pendingAuthentication);
      } catch (err) {
        debug("starting authentication failed: %o", err);
        this.clearAuthenticationStatus();
        this.notifyAuthnResponseError(err as AuthnResponseError);
      }
    }
  }

  private async startAuthentication(
    pendingAuthentication: PendingAuthentication
  ): Promise<void> {
    const authnUrl = pendingAuthentication.url;
    saveStore();

    debug(`start authn by sending to ${authnUrl}`);
    await this.sendAuthenticationRequest(authnUrl);
  }

  protected async sendAuthenticationRequest(authnUrl: URL): Promise<void> {
    window.location.assign(authnUrl.toString());
  }

  private buildAuthenticationRequestState(state?: string): string {
    const stateParams = new URLSearchParams();
    let currentUrl = this.getCurrentUrlWithoutAuthzCodeParams();
    stateParams.append(
      AppGatekeeperAuthenticatorBase.STATE_PARAM_URL,
      currentUrl
    );
    if (state) {
      stateParams.append(
        AppGatekeeperAuthenticatorBase.STATE_PARAM_STATE,
        state
      );
    }
    return stateParams.toString();
  }

  protected getCurrentUrlWithoutAuthzCodeParams(): string {
    return window.location.href.replace(/[\\?\\&]code=[^\\&]*/g, '').replace(/[\\?\\&]state=[^\\&]*/g, '');
  }

  protected getClientId(): string {
    return this.clientId;
  }

  private async handleLogoutRequest(): Promise<AuthnHandlingStatus> {
    if (!this.isLogoutRequest()) {
      return AuthnHandlingStatus.Continue;
    }

    if (getAuthentication()) {
      const unsubscribe = store.subscribe(async () => {
        const currentAuthn = getAuthentication();
        if (currentAuthn === undefined || currentAuthn === null) {
          unsubscribe();
          await this.sendLogoutResponse();
        }
      });

      this.clearAuthenticationStatus();
    } else {
      await this.sendLogoutResponse();
    }

    return AuthnHandlingStatus.Handled;
  }

  private async sendLogoutResponse(): Promise<void> {
    const logoutRequestParams = new URLSearchParams(
      window.location.search.substr(1)
    );
    const relayState = logoutRequestParams.get("RelayState") || undefined;

    const logoutResponse = await this.authenticator.handleLogoutRequest(relayState);
    const logoutResponseUrl = logoutResponse.url;
    window.location.assign(logoutResponseUrl.toString());
  }

  private async handleLogoutResponse(): Promise<AuthnHandlingStatus> {
    if (!this.isLogoutResponse()) {
      return AuthnHandlingStatus.Continue;
    }

    const logoutResponseParams = new URLSearchParams(
      window.location.search.substr(1)
    );
    const redirectTarget = await this.redirectLogoutResponse(
      logoutResponseParams
    );
    if (redirectTarget) {
      debug(
        "logout response redirected to %s",
        redirectTarget
      );
      return AuthnHandlingStatus.Redirected;
    }

    const relayState = logoutResponseParams.get("RelayState") || undefined;

    store.dispatch(setLogoutCompleted({ state: relayState }));

    return AuthnHandlingStatus.Handled;
  }

  private isLogoutRequest(): boolean {
    return (
      this.isLogoutCase() &&
      window.location.search.indexOf("success=") === -1 &&
      window.location.search.indexOf("RelayState=") !== -1
    );
  }

  private isLogoutResponse(): boolean {
    return (
      this.isLogoutCase() && window.location.search.indexOf("success=") !== -1
    );
  }

  private isLogoutCase(): boolean {
    let retVal = window.location.pathname.endsWith(this.logoutPath);
    if (!retVal && this.logoutPath.endsWith("/")) {
      retVal = window.location.pathname.endsWith(
        this.logoutPath.substr(0, this.logoutPath.length - 1)
      );
    } else if (!retVal) {
      retVal = window.location.pathname.endsWith(this.logoutPath + "/");
    }
    return retVal;
  }
}
