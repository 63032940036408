import { useEffect, useRef, useState } from "react";
import View, {
  CreateDeviceClientGroupModalProps as _CreateDeviceClientGroupModalProps,
  CreateDeviceClientGroupModalStateProps,
} from "./create-device-client-group-modal-view";
import { Entitlement } from "../../../model/entitlement/Entitlement";
import _ from "lodash";
import { withCloseAfterExited } from "@10duke/dukeui";

export type CreateDeviceClientGroupModalProps = Omit<
  _CreateDeviceClientGroupModalProps,
  keyof CreateDeviceClientGroupModalStateProps
>;

const ViewWithCloseAfterExited =
  withCloseAfterExited<_CreateDeviceClientGroupModalProps>(View);

export default function CreateDeviceClientGroupModal(
  props: CreateDeviceClientGroupModalProps
) {
  const {
    show,
    entitlements,
    ...other
  } = props;
  const entitlementsRef = useRef(entitlements);
  if (!_.isEqual(entitlementsRef.current, entitlements)) {
    entitlementsRef.current = entitlements;
  }
  const entitlementsRefCurrent = entitlementsRef.current;
  const [groupEntitlements, setGroupEntitlements] = useState<string[]>([]);
  const [activeSearch, onSetActiveSearch] = useState("");
  useEffect(() => {
    if (!show) {
      setGroupEntitlements([]);
      onSetActiveSearch("");
    } else {
      setGroupEntitlements((cur) => {
        return cur.filter((v) =>
          entitlementsRefCurrent
            ? entitlementsRefCurrent.findIndex((e) => e.id === v) >= 0
            : false
        );
      });
    }
  }, [show, entitlementsRefCurrent]);
  const onSetEntitlements = (ent: Entitlement[]) => {
    let newEdited: string[] | undefined = ent.map((v) => v.id as string);
    setGroupEntitlements(newEdited);
  };
  return (
    <ViewWithCloseAfterExited
      {...other}
      entitlements={entitlements}
      show={show}
      onSetActiveSearch={onSetActiveSearch}
      activeSearch={activeSearch}
      groupEntitlements={groupEntitlements}
      onSetSelected={onSetEntitlements}
      selected={
        entitlements && groupEntitlements && groupEntitlements.length
          ? (groupEntitlements
              .map((v) => entitlements.find((ev) => ev.id === v))
              .filter((fv) => !!fv) as Entitlement[])
          : []
      }
    />
  );
}
