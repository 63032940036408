import { User, UserForCreate } from "../model/User";
import { UserProfile } from "../model/entitlement/UserProfile";
import { TechnicalActor } from "../model/entitlement/TechnicalActor";
import { LicenseUsage } from "../model/entitlement/LicenseUsage";
import { isValid } from "../util/objectUtil";
import { AppState } from "../store/AppState";

function resolveDisplayName(
  usr: User | UserProfile | TechnicalActor | undefined | null,
  fallback: string
): string {
  let retVal = fallback;
  if (usr) {
    if (usr.displayName) {
      retVal = usr.displayName;
    } else {
      const aUsr = usr as User | UserProfile;
      if (aUsr && aUsr.firstName && aUsr.lastName) {
        retVal = aUsr.firstName + " " + aUsr.lastName;
      } else if (aUsr && aUsr.lastName) {
        retVal = aUsr.lastName;
      } else if (aUsr && aUsr.firstName) {
        retVal = aUsr.firstName;
      } else if (aUsr && (aUsr as User) && (aUsr as User).nickname) {
        retVal = (aUsr as User).nickname as string;
      }
    }
  }
  return retVal;
}

function isConsuming(
  usr: User | UserProfile | TechnicalActor,
  lic: LicenseUsage | null | undefined
): boolean {
  let retVal = false;
  if (lic && lic.users) {
    const licUsr = lic.users.find((val) => val.id === usr.id);
    if (licUsr && licUsr.assignments) {
      for (let i = 0; i < licUsr.assignments.length; i += 1) {
        if (licUsr.assignments[i].sessions) {
          const sess = licUsr.assignments[i].sessions;
          if (sess) {
            for (let j = 0; j < sess.length; j += 1) {
              retVal = isValid(sess[j]);
              if (retVal) {
                break;
              }
            }
          }
        }
        if (retVal) {
          break;
        }
      }
    }
  }
  return retVal;
}

export interface UserWithStatus extends User {
  status?: "inactive" | "admin" | "member" | "suspended";
  isOrgAdmin: boolean;
}

function userWithStatus(usr: User): UserWithStatus {
  let status = resolveUserStatus(usr);
  return {
    ...usr,
    status,
  } as UserWithStatus;
}

function usersWithStatus(usrs?: User[]): UserWithStatus[] {
  if (usrs) {
    return usrs.map(userWithStatus);
  } else {
    return [];
  }
}
function resolveUserStatus(
  u: User
): "inactive" | "admin" | "member" | "suspended" {
  let status: "inactive" | "admin" | "member" | "suspended";
  let validUntil;
  if (
    u.validUntil !== null &&
    u.validUntil !== undefined &&
    u.validUntil !== ""
  ) {
    validUntil = new Date(u.validUntil);
  }
  const cur = new Date().getTime();
  if (validUntil && new Date().getTime() > validUntil.getTime()) {
    status = "suspended";
  } else if ((u as any).isOrgAdmin) {
    status = "admin";
  } else if (
    !u.lastSignOn ||
    cur - new Date(u.lastSignOn).getTime() > 30 * 24 * 60 * 60 * 1000
  ) {
    status = "inactive";
  } else {
    status = "member";
  }
  return status;
}

function transformToUser(user: UserForCreate): User {
  const { groupIds, organizationRoleIds, ...plainUser } = user;
  return plainUser;
}

function selectUser(id: string, state: AppState): User | undefined {
  return !!state.users ? state.users[id] : undefined;
}

function compareUserGroupIdsState(next: AppState, prev: AppState): boolean {
  return next.userOrgGroupIds === prev.userOrgGroupIds;
}
function compareUserRoleIdsState(next: AppState, prev: AppState): boolean {
  return next.userOrgRoleIds === prev.userOrgRoleIds;
}
function selectUserGroupIds(id: string, state: AppState): string[] | undefined {
  return !!state.userOrgGroupIds ? state.userOrgGroupIds[id] : undefined;
}
function selectUserRoleIds(id: string, state: AppState): string[] | undefined {
  return !!state.userOrgRoleIds ? state.userOrgRoleIds[id] : undefined;
}

function compareUsersState(next: AppState, prev: AppState): boolean {
  return next.users === prev.users;
}
function compareUserAvailableLicensesState(
  next: AppState,
  prev: AppState
): boolean {
  return next.userAvailableLicenses === prev.userAvailableLicenses;
}

const EMAIL_VALIDATION_PATTERN =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const UserUtils = {
  resolveUserStatus,
  userWithStatus,
  usersWithStatus,
  resolveDisplayName,
  isConsuming,
  transformToUser,
  selectUser,
  selectUserGroupIds,
  selectUserRoleIds,
  compareUsersState,
  compareUserGroupIdsState,
  compareUserRoleIdsState,
  compareUserAvailableLicensesState,
  EMAIL_VALIDATION_PATTERN,
};
export default UserUtils;
