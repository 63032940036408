import DeviceClientGroupsComp, { ModalKeys } from "../../components/pages/device-client-groups";
import { useParams, useHistory, useLocation } from "react-router-dom";
export { ModalKeys } from "../../components/pages/device-client-groups";
function DeviceClientGroups() {
  const history = useHistory();
  const location = useLocation();
  let { modal, clientGroupId, secondaryId } = useParams<any>();
  const resolveBase = () => {
    let base: string = location.pathname;
    if (modal) {
      base = base.replace(`/${modal}`, "");
    }
    if (clientGroupId) {
      base = base.replace(`/${clientGroupId}`, "");
    }
    if (secondaryId) {
      base = base.replace(`/${secondaryId}`, "");
    }

    if (base.endsWith("/")) {
      base = base.substr(0, base.length - 1);
    }
    return base;
  };
  const onShowModal = (key: ModalKeys, cgrpId?: string, secId?: string) => {
    let path: string[] = [resolveBase()];
    if (cgrpId) {
      path.push(cgrpId);
    }
    path.push(key);
    if (secId) {
      path.push(secId);
    }
    history.push(path.join("/"));
  };

  const onHideModal = (callback?: () => void) => {
    history.push(resolveBase());

    if (callback && typeof callback === "function") {
      callback();
    }
  };
  return (
    <DeviceClientGroupsComp
      showModal={{ key: modal as ModalKeys, clientGroupId, secondaryId }}
      onShowModal={onShowModal}
      onHideModal={onHideModal}
    />
  );
}
export default DeviceClientGroups;
