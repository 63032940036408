import { connect } from "react-redux";
import OrganizationUtils from "../../../utils/organization";
import {
  RemoveDeviceClientGroupModalDataProps,
  RemoveDeviceClientGroupModalProps as _RemoveDeviceClientGroupModalProps,
} from "./remove-device-client-group-modal-view";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import {
  clearError,
  deleteOrganizationClientGroup,
  getOrganizationClientGroup,
} from "../../../actions";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";
import RemoveDeviceClientGroupModal from "./remove-device-client-group-modal-container";
import InProgressUtils from "../../../utils/in-progress";

export interface RemoveDeviceClientGroupModalProps
  extends Omit<_RemoveDeviceClientGroupModalProps, keyof RemoveDeviceClientGroupModalDataProps> {}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _RemoveDeviceClientGroupModalProps,
  RemoveDeviceClientGroupModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _RemoveDeviceClientGroupModalProps,
  RemoveDeviceClientGroupModalProps
>;

const sender: ActionSender = { key: "remove-device-client-group" };

function mapStateToProps(
  state: AppState,
  ownProps: RemoveDeviceClientGroupModalProps
): ReduxStateProps {
  return {
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    clientGroup: ownProps.clientGroupId
      ? OrganizationUtils.selectOrganizationClientGroup(ownProps.clientGroupId, state)
      : undefined,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientGroupsStates(next, prev)
  );
}
const dispatchActions: ReduxDispatchProps = {
  onRemoveClientGroup: addSenderArgument(sender, deleteOrganizationClientGroup),
  onLoadClientGroup: addSenderArgument(sender, getOrganizationClientGroup),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  RemoveDeviceClientGroupModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(RemoveDeviceClientGroupModal);
