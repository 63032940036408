import { LicenseWithCredits } from "../../../model/entitlement/LicenseWithCredits";
import {
  LICENSED_ITEM_1,
  LICENSED_ITEM_3,
  LICENSED_ITEM_4,
  LICENSED_ITEM_5,
  LICENSED_ITEM_6,
} from "./licensedItems";
import { LICENSE_MODEL_1 } from "./licenseModels";
import { LicenseUserWithAssignmentsAndSessions } from "../../../model/entitlement/LicenseUserWithAssignmentsAndSessions";
import { ORG_1_USERS } from "./org1Members";
import { ORG_1_ENTITLEMENT_1 } from "./org1Entitlements";
import { ORG_1_ENTITLEMENT_2 } from "./org1Entitlements";

const ORG_1_LICENSE_1_ID: string = "5c5ac5e7-077e-403e-bafb-839404e603e1";
const ORG_1_LICENSE_3_ID: string = "7a8421e9-bfbf-4de3-af6b-b6e19b954245";
const ORG_1_LICENSE_4_ID: string = "93285dc3-f6bb-440d-a554-41da2ff09d65";
const ORG_1_LICENSE_5_ID: string = "52dfaeea-a7cc-40e8-9e36-ea543ad19227";
const ORG_1_LICENSE_6_ID: string = "7c6c405b-d64c-4dc3-ac10-b261be47c8f3";
const ORG_1_LICENSE_7_ID: string = "9b87eda0-6d39-11ec-90d6-0242ac120003";
const ORG_1_LICENSE_8_ID: string = "0cc2bf95-63d7-49fb-b863-02891604340d";

const ORG_1_LICENSE_1: LicenseWithCredits = {
  id: ORG_1_LICENSE_1_ID,
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T07:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-06-11T07:01:00.000Z",
  validFrom: "2020-06-11T07:00:00.000Z",
  validUntil: "2120-06-11T07:00:00.000Z",
  active: true,
  entitlementId: ORG_1_ENTITLEMENT_1.id,
  licensedItem: LICENSED_ITEM_1,
  licenseModelId: LICENSE_MODEL_1.id as string,
  licenseModelName: LICENSE_MODEL_1.name,
  effectiveLicenseModel: LICENSE_MODEL_1,
  seatsTaken: 1,
  seatsReserved: 1,
  seatsTotal: 10,
  seatCountCredits: [
    {
      id: "1f0fe667-3ad8-4a1b-aa25-87ff0137eabc",
      authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
      created: "2020-06-11T07:00:00.000Z",
      validFrom: "2020-06-11T07:00:00.000Z",
      validUntil: "2100-06-11T07:00:00.000Z",
      active: true,
      licenseId: ORG_1_LICENSE_1_ID,
      licenseOrderLineId: "aa971543-a76a-40fd-9fbf-0e6c49b7aeb3",
      seatCount: 10,
      seatsConsumed: 1,
    },
  ],
};
const ORG_1_LICENSE_3: LicenseWithCredits = {
  id: ORG_1_LICENSE_3_ID,
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T07:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-06-11T07:01:00.000Z",
  validFrom: "2020-06-11T07:00:00.000Z",
  validUntil: "2120-06-11T07:00:00.000Z",
  active: true,
  entitlementId: ORG_1_ENTITLEMENT_2.id,
  licensedItem: LICENSED_ITEM_3,
  licenseModelId: LICENSE_MODEL_1.id as string,
  licenseModelName: LICENSE_MODEL_1.name,
  effectiveLicenseModel: LICENSE_MODEL_1,
  seatsTaken: 1,
  seatsReserved: 1,
  seatsTotal: 10,
  seatCountCredits: [
    {
      id: "60cc7471-0782-4663-87cc-4123d7b824f3",
      authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
      created: "2020-06-11T07:00:00.000Z",
      validFrom: "2020-06-11T07:00:00.000Z",
      validUntil: "2100-06-11T07:00:00.000Z",
      active: true,
      licenseId: ORG_1_LICENSE_3_ID,
      licenseOrderLineId: "237bb6bc-d1d1-4996-88de-3c91a9897032",
      seatCount: 10,
      seatsConsumed: 0,
    },
  ],
};
const ORG_1_LICENSE_4: LicenseWithCredits = {
  id: ORG_1_LICENSE_4_ID,
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T07:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-06-11T07:01:00.000Z",
  validFrom: "2020-06-11T07:00:00.000Z",
  validUntil: new Date(new Date().getTime() + 3600000 * 24).toISOString(),
  active: true,
  entitlementId: ORG_1_ENTITLEMENT_2.id,
  licensedItem: LICENSED_ITEM_4,
  licenseModelId: LICENSE_MODEL_1.id as string,
  licenseModelName: LICENSE_MODEL_1.name,
  effectiveLicenseModel: LICENSE_MODEL_1,
  seatsTaken: 1,
  seatsReserved: 1,
  seatsTotal: 10,
  seatCountCredits: [
    {
      id: "4058c198-de8b-49d4-a0f6-470fdb02db06",
      authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
      created: "2020-06-11T07:00:00.000Z",
      validFrom: "2020-06-11T07:00:00.000Z",
      validUntil: "2100-06-11T07:00:00.000Z",
      active: true,
      licenseId: ORG_1_LICENSE_4_ID,
      licenseOrderLineId: "b033d57a-26ac-40ff-b8d5-fd534ec7a99e",
      seatCount: 10,
      seatsConsumed: 0,
    },
  ],
};
const ORG_1_LICENSE_5: LicenseWithCredits = {
  id: ORG_1_LICENSE_5_ID,
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T07:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-06-11T07:01:00.000Z",
  validFrom: "2020-06-11T07:00:00.000Z",
  validUntil: new Date(new Date().getTime() - 3600000 * 24).toISOString(),
  active: true,
  entitlementId: ORG_1_ENTITLEMENT_2.id,
  licensedItem: LICENSED_ITEM_5,
  licenseModelId: LICENSE_MODEL_1.id as string,
  licenseModelName: LICENSE_MODEL_1.name,
  effectiveLicenseModel: LICENSE_MODEL_1,
  seatsTaken: 1,
  seatsReserved: 1,
  seatsTotal: 0,
  seatCountCredits: [
    {
      id: "d7febcaf-ce06-4cf5-abca-579caf06d3d7",
      authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
      created: "2020-06-11T07:00:00.000Z",
      validFrom: "2020-06-11T07:00:00.000Z",
      validUntil: "2100-06-11T07:00:00.000Z",
      active: true,
      licenseId: ORG_1_LICENSE_5_ID,
      licenseOrderLineId: "b033d57a-26ac-40ff-b8d5-fd534ec7a99e",
      seatCount: 10,
      seatsConsumed: 0,
    },
  ],
};
const ORG_1_LICENSE_6: LicenseWithCredits = {
  id: ORG_1_LICENSE_6_ID,
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T07:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-06-11T07:01:00.000Z",
  validFrom: "2020-06-11T07:00:00.000Z",
  validUntil: "2120-06-11T07:00:00.000Z",
  active: false,
  entitlementId: ORG_1_ENTITLEMENT_2.id,
  licensedItem: LICENSED_ITEM_6,
  licenseModelId: LICENSE_MODEL_1.id as string,
  licenseModelName: LICENSE_MODEL_1.name,
  effectiveLicenseModel: LICENSE_MODEL_1,
  seatsTaken: 1,
  seatsReserved: 1,
  seatsTotal: 10,
  seatCountCredits: [
    {
      id: "d7febcaf-ce06-4cf5-abca-579caf06d3d7",
      authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
      created: "2020-06-11T07:00:00.000Z",
      validFrom: "2020-06-11T07:00:00.000Z",
      validUntil: "2100-06-11T07:00:00.000Z",
      active: true,
      licenseId: ORG_1_LICENSE_6_ID,
      licenseOrderLineId: "b033d57a-26ac-40ff-b8d5-fd534ec7a99e",
      seatCount: 10,
      seatsConsumed: 0,
    },
  ],
};

const ORG_1_LICENSE_7: LicenseWithCredits = {
  id: ORG_1_LICENSE_7_ID,
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T07:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-06-11T07:01:00.000Z",
  validFrom: "2020-06-11T07:00:00.000Z",
  validUntil: "2020-07-11T07:00:00.000Z",
  active: true,
  entitlementId: ORG_1_ENTITLEMENT_1.id,
  licensedItem: LICENSED_ITEM_1,
  licenseModelId: LICENSE_MODEL_1.id as string,
  licenseModelName: LICENSE_MODEL_1.name,
  effectiveLicenseModel: LICENSE_MODEL_1,
  seatsTaken: 1,
  seatsReserved: 1,
  seatsTotal: 10,
  seatCountCredits: [
    {
      id: "1f0fe667-3ad8-4a1b-aa25-87ff0137eabc",
      authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
      created: "2020-06-11T07:00:00.000Z",
      validFrom: "2020-06-11T07:00:00.000Z",
      validUntil: "2100-06-11T07:00:00.000Z",
      active: true,
      licenseId: ORG_1_LICENSE_7_ID,
      licenseOrderLineId: "aa971543-a76a-40fd-9fbf-0e6c49b7aeb3",
      seatCount: 10,
      seatsConsumed: 1,
    },
  ],
};
const ORG_1_LICENSE_8: LicenseWithCredits = {
  id: ORG_1_LICENSE_8_ID,
  authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  created: "2020-06-11T07:00:00.000Z",
  editorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
  modified: "2020-06-11T07:01:00.000Z",
  validFrom: new Date(new Date().getTime() + 3600000 * 24).toISOString(),
  validUntil: undefined,
  active: true,
  entitlementId: ORG_1_ENTITLEMENT_1.id,
  licensedItem: LICENSED_ITEM_1,
  licenseModelId: LICENSE_MODEL_1.id as string,
  licenseModelName: LICENSE_MODEL_1.name,
  effectiveLicenseModel: LICENSE_MODEL_1,
  seatsTaken: 1,
  seatsReserved: 1,
  seatsTotal: 10,
  seatCountCredits: [
    {
      id: "1f0fe667-3ad8-4a1b-aa25-87ff0137eabc",
      authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
      created: "2020-06-11T07:00:00.000Z",
      validFrom: "2020-06-11T07:00:00.000Z",
      validUntil: "2100-06-11T07:00:00.000Z",
      active: true,
      licenseId: ORG_1_LICENSE_8_ID,
      licenseOrderLineId: "aa971543-a76a-40fd-9fbf-0e6c49b7aeb3",
      seatCount: 10,
      seatsConsumed: 1,
    },
  ],
};

export const ORG_1_LICENSES: LicenseWithCredits[] = [
  ORG_1_LICENSE_1,
  ORG_1_LICENSE_3,
  ORG_1_LICENSE_4,
  ORG_1_LICENSE_5,
  ORG_1_LICENSE_6,
  ORG_1_LICENSE_7,
  ORG_1_LICENSE_8,
];

const ORG_1_LICENSE_1_USER_1: LicenseUserWithAssignmentsAndSessions = {
  ...ORG_1_USERS[0],
  assignments: [
    {
      id: "8476a28f-e847-4e02-89b3-e8c571bf96e3",
      authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
      created: "2020-06-11T07:00:00.000Z",
      validFrom: "2020-06-11T07:00:00.000Z",
      validUntil: "2100-06-11T07:00:00.000Z",
      type: "reserved",
      sessions: [
        {
          id: "645ef0c8-4f53-4716-b7f0-581177be098e",
          authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
          created: "2020-06-11T07:00:00.000Z",
          validFrom: "2020-06-11T07:00:00.000Z",
          validUntil: "2100-06-11T07:00:00.000Z",
          leases: [
            {
              id: "3576bb41-8f31-41bf-952e-fc7cb7a2c79d",
              authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
              created: "2020-06-11T07:00:00.000Z",
              validFrom: "2020-06-11T07:00:00.000Z",
              validUntil: "2100-06-11T07:00:00.000Z",
              mode: "online",
            },
          ],
        },
      ],
    },
  ],
};

const ORG_1_LICENSE_3_USER_1: LicenseUserWithAssignmentsAndSessions = {
  ...ORG_1_USERS[0],
  assignments: [
    {
      id: "51a0e8b9-6daa-42ce-8f00-e2c3543738e9",
      authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
      created: "2020-06-11T07:00:00.000Z",
      validFrom: "2020-06-11T07:00:00.000Z",
      validUntil: "2100-06-11T07:00:00.000Z",
      type: "reserved",
      sessions: [
        {
          id: "62e57b8a-c02c-4790-b374-d52577f04a49",
          authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
          created: "2020-06-11T07:00:00.000Z",
          validFrom: "2020-06-11T07:00:00.000Z",
          validUntil: "2020-07-11T07:00:00.000Z",
          leases: [
            {
              id: "6fa88812-c4f7-4055-b748-72ad3479109a",
              authorId: "30235843-4642-442c-b0b5-ffdd13764bc8",
              created: "2020-06-11T07:00:00.000Z",
              validFrom: "2020-06-11T07:00:00.000Z",
              validUntil: "2020-07-11T07:00:00.000Z",
              mode: "online",
            },
          ],
        },
      ],
    },
  ],
};

const ORG_1_LICENSE_1_USERS: LicenseUserWithAssignmentsAndSessions[] = [
  ORG_1_LICENSE_1_USER_1,
];

const ORG_1_LICENSE_3_USERS: LicenseUserWithAssignmentsAndSessions[] = [
  ORG_1_LICENSE_3_USER_1,
];

export const ORG_1_LICENSE_USERS_BY_LICENSE_ID = {
  [ORG_1_LICENSE_1.id as string]: ORG_1_LICENSE_1_USERS,
  [ORG_1_LICENSE_3.id as string]: ORG_1_LICENSE_3_USERS,
  [ORG_1_LICENSE_4.id as string]: [],
  [ORG_1_LICENSE_5.id as string]: [],
  [ORG_1_LICENSE_6.id as string]: [],
  [ORG_1_LICENSE_7.id as string]: [],
  [ORG_1_LICENSE_8.id as string]: [],
};
