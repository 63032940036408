import * as ActionTypes from "../actions/actionTypes";
import { EntitlementIdsByOrgGroupId } from "../store/OrganizationGroupState";

export default function orgGroupEntitlementIds(
  state: EntitlementIdsByOrgGroupId,
  action: ActionTypes.AppAction
): EntitlementIdsByOrgGroupId | null {
  const currentState = state || ({} as EntitlementIdsByOrgGroupId);

  switch (action.type) {
    case ActionTypes.SET_ENTITLEMENTS_CONSUMED_BY_ORG_GROUP: {
      const actionResult = action as ActionTypes.SetEntitlementsConsumedByOrgGroup;
      return {
        ...currentState,
        [actionResult.groupId]: [...actionResult.entitlementIds]
      };
    }
    case ActionTypes.LIST_ENTITLEMENTS_CONSUMED_BY_ORG_GROUP: {
      const actionResult = action as ActionTypes.ListEntitlementsConsumedByOrgGroup;
      const entitlementIds = actionResult.entitlements.map(
        ent => ent.id as string
      );
      return { ...currentState, [actionResult.groupId]: entitlementIds };
    }
    case ActionTypes.REMOVE_LICENSE_CONSUMING_ORG_GROUP: {
      const actionResult = action as ActionTypes.RemoveLicenseConsumingOrgGroupAction;

      const beforeDelete = currentState[actionResult.groupId] || [];
      const afterDelete = beforeDelete.filter(
        entitlementId => entitlementId !== actionResult.entId
      );
      return {
        ...currentState,
        [actionResult.groupId]: afterDelete
      };
    }
    case ActionTypes.START_AUTHN:
      return null;
    default:
      return state || null;
  }
}
