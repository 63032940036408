import InvitationsCardView, {InvitationsCardProps as _InvitationsCardProps} from "./invitations-card-view";
import {useCallback} from "react";

export interface InvitationsCardProps extends _InvitationsCardProps {
  onLoadDeviceClientGroupInvitations: () => {};
}

function InvitationsCard(props: InvitationsCardProps) {
  const {
    onLoadInvitations,
    onLoadDeviceClientGroupInvitations,
    ...other
  } = props;

  const onLoadInvs = useCallback(() => {
    const promises = [];
    if (other.enableUsers) {
      promises.push(onLoadInvitations())
    }
    if (other.enableClients) {
      promises.push(onLoadDeviceClientGroupInvitations())
    }
    return Promise.all(promises);
  }, [other.enableClients,other.enableUsers, onLoadInvitations, onLoadDeviceClientGroupInvitations]);
  return <InvitationsCardView {...other} onLoadInvitations={onLoadInvs} />
}
export default InvitationsCard;
