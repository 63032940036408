import { withCloseAfterExited } from "@10duke/dukeui";
import {
  default as View,
  RemoveInvitationModalProps
} from "./remove-invitation-modal-view";

const RemoveInvitationModal = withCloseAfterExited<RemoveInvitationModalProps>(
  View
);
export default RemoveInvitationModal;
