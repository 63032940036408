import { useEffect, useRef, useState } from "react";
import View, {
  ViewDeviceClientGroupModalProps as _ViewDeviceClientGroupModalProps,
  ViewDeviceClientGroupModalStateProps,
} from "./view-device-client-group-modal-view";
import { Entitlement } from "../../../model/entitlement/Entitlement";
import _ from "lodash";
import { withCloseAfterExited } from "@10duke/dukeui";

export type ViewDeviceClientGroupModalProps = Omit<
  _ViewDeviceClientGroupModalProps,
  keyof ViewDeviceClientGroupModalStateProps
>;
const ViewWithCloseAfterExited =
  withCloseAfterExited<_ViewDeviceClientGroupModalProps>(View);
export default function ViewDeviceClientGroupModalModal(props: ViewDeviceClientGroupModalProps) {
  const { show, entitlements, groupEntitlements, ...other } = props;

  const groupEntitlementsRef = useRef(groupEntitlements);
  if (!_.isEqual(groupEntitlementsRef.current, groupEntitlements)) {
    groupEntitlementsRef.current = groupEntitlements;
  }
  const groupEntitlementsRefCurrent = groupEntitlementsRef.current;

  const [editedEntitlements, setEditedEntitlements] = useState<
    Entitlement[] | undefined
  >(groupEntitlements);
  const [hasEntitlementChanges, setHasEntitlementChanges] =
    useState<boolean>(false);
  const [activeSearch, onSetActiveSearch] = useState("");

  useEffect(() => {
    if (groupEntitlementsRefCurrent) {
      setEditedEntitlements(groupEntitlementsRefCurrent);
      setHasEntitlementChanges(false);
    }
  }, [
    groupEntitlementsRefCurrent,
    setEditedEntitlements,
    setHasEntitlementChanges,
  ]);

  useEffect(() => {
    if (!show) {
      setEditedEntitlements(undefined);
      setHasEntitlementChanges(false);
    }
  }, [show, setEditedEntitlements]);

  const onSetEntitlements = (ents: Entitlement[]) => {
    let newEdited: Entitlement[] = ents;
    if (groupEntitlements && newEdited) {
      const groupEntitlementIds = groupEntitlements
        .map((v) => v.id as string)
        .sort((a, b) => {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          }
          return 0;
        })
        .join("");
      const newEditedIds = newEdited
        .map((v) => v.id as string)
        .sort((a, b) => {
          if (a < b) {
            return -1;
          } else if (a > b) {
            return 1;
          }
          return 0;
        })
        .join("");
      setHasEntitlementChanges(groupEntitlementIds !== newEditedIds);
    }
    setEditedEntitlements(newEdited);
  };

  return (
    <ViewWithCloseAfterExited
      {...other}
      hasEntitlementChanges={hasEntitlementChanges}
      show={show}
      groupEntitlements={editedEntitlements}
      entitlements={entitlements}
      selected={editedEntitlements || []}
      onSetSelected={onSetEntitlements}
      activeSearch={activeSearch}
      onSetActiveSearch={onSetActiveSearch}
    />
  );
}
