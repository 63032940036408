import { connect } from "react-redux";
import { AppState } from "../../store/AppState";
import OrganizationUtils from "../../utils/organization";
import { setOrg } from "../../actions";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../util/typeUtil";
import AuthenticationUtils from "../../utils/authentication";
import InProgressUtils from "../../utils/in-progress";
import AppMenus, {AppMenusInputProps, AppMenusProps as _AppMenusProps, AppMenusUser} from "./app-menus-view";

// Own props, i.e. props that this component takes as input
interface AppMenusProps extends AppMenusInputProps {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_AppMenusProps, AppMenusProps>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
    _AppMenusProps,
    AppMenusProps
>;

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    disableOrganizationSelect:
      InProgressUtils.selectInProgressLength(state) > 0,
    selectedOrganization: OrganizationUtils.selectSelectedOrganization(state),
    organizations: OrganizationUtils.selectAppOrganizations(state),
    user: {
      id: AuthenticationUtils.selectAuthenticationUserId(state) as string,
      name: AuthenticationUtils.selectAuthenticationUserDisplayName(state),
      email: AuthenticationUtils.selectAuthenticationUserEmail(state),
    } as AppMenusUser,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareSelectedOrganizationIdStates(next, prev) &&
    AuthenticationUtils.compareAuthenticationUserDisplayNameStates(
      next,
      prev
    ) &&
    AuthenticationUtils.compareAuthenticationUserIdStates(next, prev) &&
    AuthenticationUtils.compareAuthenticationUserEmailStates(next, prev) &&
    OrganizationUtils.compareAppOrganizationsStates(next, prev)
  );
}
const dispatchActions: ReduxDispatchProps = {
  onSelectOrganization: setOrg,
};
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  AppMenusProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(AppMenus);
