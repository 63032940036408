import { useContext, useEffect, useMemo, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { ClosableModalProps } from "../closable-modal-props";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {FieldList, IconLibrary, FormInput, FormInputUtils, FeedbackEntry, Feedback, Modal} from "@10duke/dukeui";
import {
  ClearErrorAction,
  GetOrgClientGroupAction,
  isAddErrorAction,
  ReplaceOrgClientGroupAction,
  SetEntitlementsConsumedByOrgGroup,
} from "../../../actions/actionTypes";
import { Entitlement } from "../../../model/entitlement/Entitlement";
import Table from "../../table";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import { ModalKeys as DeviceGroupModalKeys } from "../../pages/device-client-groups";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavigateAfterAction from "../../navigate-after-action";
import {ClientGroup} from "../../../model/ClientGroup";
import {ClientGroupLabels} from "../../../localization/client-group";

//<editor-fold desc="Props">

export interface ViewDeviceClientGroupModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface ViewDeviceClientGroupModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {
  onShowFeedback: (feedback: FeedbackEntry | FeedbackEntry[]) => void;
}
export interface ViewDeviceClientGroupModalInputProps
  extends ViewDeviceClientGroupModalVisibilityProps {
  edit?: boolean;
  allowEdit?: boolean;
  clientGroupId: string | undefined;
}
export interface ViewDeviceClientGroupModalStateProps {
  hasEntitlementChanges: boolean;

  selected: Entitlement[];
  onSetSelected: (selection: Entitlement[]) => void;
  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;
}
export interface ViewDeviceClientGroupModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  organizationId?: string;
  clientGroup: ClientGroup | undefined | null;
  groupEntitlements?: Entitlement[];
  onLoadClientGroup: (clientGroupId: string) => Promise<GetOrgClientGroupAction>;
  onUpdateClientGroup?: (clientGroup: ClientGroup) => Promise<ReplaceOrgClientGroupAction>;
  onLoadClientGroupEntitlements?: (orgId: string, clientGroupId: string) => void;
  onSetClientGroupEntitlements?: (
    orgId: string,
    clientGroupId: string,
    entitlementIds: string[]
  ) => Promise<SetEntitlementsConsumedByOrgGroup>;

  entitlements?: Entitlement[];
  onLoadEntitlements?: (orgId: string) => void;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface ViewDeviceClientGroupModalProps
  extends ViewDeviceClientGroupModalDOMProps,
    ViewDeviceClientGroupModalInputProps,
    ViewDeviceClientGroupModalStateProps,
    ViewDeviceClientGroupModalDataProps {}
//</editor-fold>

function ViewDeviceClientGroupModal(props: ViewDeviceClientGroupModalProps) {
  //<editor-fold desc="Local variables">
  let {
    clientGroup,
    clientGroupId,
    onLoadClientGroup,
    show,
    onClose,
    edit,
    allowEdit,
    onUpdateClientGroup,
    onShowFeedback,
    onSetClientGroupEntitlements,
    groupEntitlements,
    onLoadClientGroupEntitlements,
    onLoadEntitlements,
    entitlements,
    organizationId,
    hasEntitlementChanges,
    selected,
    onSetSelected,
    activeSearch,
    onSetActiveSearch,
    isReady,
    onExited,
    onClearError,
  } = props;

  const { conf } = useContext(UIConfiguration);
  const groupConf =
    conf.functionality && conf.functionality["device-client-groups"]
      ? conf.functionality["device-client-groups"]
      : {};
  // this is more like a variable than a hook
  const intl = useIntl();
  //</editor-fold>

  //<editor-fold desc="Hooks">
  const history = useHistory();
  const defaultValues = useMemo(
    () => ({
      name: clientGroup ? clientGroup.name : "",
      description: clientGroup ? clientGroup.description : "",
    }),
    [clientGroup]
  );
  const { register, handleSubmit, formState, reset, watch } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const { errors } = formState;

  const formValues = watch();

  if (show && groupEntitlements === undefined && !onLoadClientGroupEntitlements) {
    throw new Error(
      "ViewDeviceClientGroupModal: Required props missing. groupEntitlements or onLoadClientGroupEntitlements must be defined"
    );
  }
  if (show && entitlements === undefined && !onLoadEntitlements) {
    throw new Error(
      "ViewDeviceClientGroupModal: Required props missing. entitlements or onLoadEntitlements must be defined"
    );
  }
  if (show && allowEdit && (!onSetClientGroupEntitlements || !onUpdateClientGroup)) {
    throw new Error(
      "ViewGroupModal: Required props missing. onSetClientGroupEntitlements and onUpdateClientGroup must be defined when allowEdit"
    );
  }
  const groupObjId = clientGroup ? clientGroup.id : undefined;
  useEffect(() => {
    if (show && organizationId && groupObjId && onLoadClientGroupEntitlements) {
      onLoadClientGroupEntitlements(organizationId, groupObjId as string);
    }
  }, [show, organizationId, groupObjId, onLoadClientGroupEntitlements]);

  useEffect(() => {
    if (
      show &&
      organizationId &&
      entitlements === undefined &&
      onLoadEntitlements
    ) {
      onLoadEntitlements(organizationId);
    }
  }, [show, organizationId, entitlements, onLoadEntitlements]);
  useEffect(() => {
    if (
      show &&
        clientGroupId &&
      (groupObjId === undefined || (groupObjId && groupObjId !== clientGroupId)) &&
      onLoadClientGroup
    ) {
      onLoadClientGroup(clientGroupId).then((res) => {
        if (!groupObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, clientGroupId, groupObjId, onLoadClientGroup, onClearError]);

  useEffect(() => {
    if (defaultValues && edit) {
      reset(defaultValues);
    }
  }, [defaultValues, edit, reset]);
  //</editor-fold>
  return (
    <Modal
      onExited={onExited}
      onReloadData={() => {
        if (organizationId && (clientGroup || clientGroupId) && onLoadClientGroupEntitlements) {
          onLoadClientGroupEntitlements(
            organizationId,
              clientGroup ? (clientGroup.id as string) : (clientGroupId as string)
          );
        }
        if (organizationId && onLoadEntitlements) {
          onLoadEntitlements(organizationId);
        }
        if ((clientGroup || clientGroupId) && onLoadClientGroup) {
          onLoadClientGroup(clientGroup ? (clientGroup.id as string) : (clientGroupId as string)).then(
            (res) => {
              if (!clientGroup && isAddErrorAction(res)) {
                // only clear error if no data exists, leave if previous data is still available and
                // let the api error notification handler show error
                onClearError(res.error?.errorId);
              }
            }
          );
        }
      }}
      isReady={isReady}
      data-test-view-client-group-modal
      title={
        !isReady || clientGroup
          ? !edit
            ? intl.formatMessage({
                defaultMessage: "Device client group details",
                description: "modal heading",
              })
            : intl.formatMessage({
                defaultMessage: "Edit device client group details",
                description: "modal heading for edit state",
              })
          : intl.formatMessage({
              defaultMessage: "Device Client group not found",
              description: "modal heading when device client group not found",
            })
      }
      show={show}
      onClose={onClose}
      backdrop={formState.isDirty || hasEntitlementChanges ? "static" : true}
      primaryButton={
        clientGroup && allowEdit
          ? !edit
            ? {
                label: intl.formatMessage({
                  defaultMessage: "Edit",
                  description: "primary button label",
                }),
              }
            : {
                label: intl.formatMessage({
                  defaultMessage: "Apply",
                  description: "primary button label for edit state",
                }),
                disabled:
                  (errors !== undefined &&
                    errors !== null &&
                    Object.keys(errors).length > 0) ||
                  !(formState.isDirty || hasEntitlementChanges),
              }
          : {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
      }
      onPrimaryAction={
        clientGroup && allowEdit
          ? !edit
            ? () => {
                history.push(
                  "/device-client-groups/" +
                    (clientGroup ? (clientGroup.id as string) : "not_possible") +
                    "/edit"
                );
              }
            : () => {
                handleSubmit((data) => {
                  const ng: any = {
                    ...clientGroup,
                    ...data,
                  };
                  delete ng.entitlements;
                  if (onUpdateClientGroup && onSetClientGroupEntitlements) {
                    onUpdateClientGroup(ng as OrganizationGroup).then((res) => {
                      if (!isAddErrorAction(res)) {
                        const feedback: FeedbackEntry[] = [
                          {
                            id: "deviceClientGroupEditSuccess",
                            msg: intl.formatMessage(
                              {
                                defaultMessage: "Device client group {name} updated.",
                                description:
                                  "success notification for updating device client group. 'name' = name of the device client group",
                              },
                              {
                                name: "<strong>" + ng.name + "</strong>",
                              }
                            ),
                            autoClose: true,
                            type: "success",
                          },
                        ];
                        if (onSetClientGroupEntitlements && hasEntitlementChanges) {
                          onSetClientGroupEntitlements(
                            organizationId as string,
                            res.group.id as string,
                            groupEntitlements
                              ? groupEntitlements.map((v) => v.id as string)
                              : []
                          ).then((rres) => {
                            if (!isAddErrorAction(rres)) {
                              feedback.push({
                                id: "edit_device_client_group_entitlements",
                                msg: intl.formatMessage(
                                  {
                                    defaultMessage:
                                      "Entitlements added for {name}.",
                                    description:
                                      "success notification for updating device client group entitlements. 'name' = name of the device client group",
                                  },
                                  {
                                    name:
                                      "<strong>" + res.group.name + "</strong>",
                                  }
                                ),
                                autoClose: true,
                                type: "success",
                              });
                            } else {
                              onClearError(rres.error?.errorId);
                              feedback.push({
                                id: "edit_device_client_group_entitlements",
                                msg: intl.formatMessage(
                                  {
                                    defaultMessage:
                                      "Adding Entitlements for {name} failed.",
                                    description:
                                      "failure notification for updating device client group entitlements. 'name' = name of the device client group",
                                  },
                                  {
                                    name:
                                      "<strong>" + res.group.name + "</strong>",
                                  }
                                ),
                                type: "danger",
                              });
                            }
                            onShowFeedback(feedback);
                            onClose();
                          });
                        } else {
                          onShowFeedback(feedback);
                          onClose();
                        }
                      } else {
                        onClearError(res.error?.errorId);
                        onShowFeedback({
                          id:
                            "deviceClientGroupEditFailure_" +
                            (clientGroup ? (clientGroup.id as string) : "not_possible"),
                          msg: intl.formatMessage(
                            {
                              defaultMessage: "Updating device client group {name} failed.",
                              description:
                                "failure notification for updating device client group. 'name' = name of the device client group",
                            },
                            {
                              name:
                                "<strong>" +
                                (!!clientGroup ? clientGroup.name : ng.name) +
                                "</strong>",
                            }
                          ),
                          type: "danger",
                        });
                        onClose();
                      }
                    });
                  }
                  onClose();
                })();
              }
          : onClose
      }
      secondaryButton={
        clientGroup && allowEdit
          ? !edit
            ? {
                label: intl.formatMessage({
                  defaultMessage: "Close",
                  description: "close button label",
                }),
              }
            : {
                label: intl.formatMessage({
                  defaultMessage: "Cancel",
                  description: "secondary button label",
                }),
              }
          : undefined
      }
      onSecondaryAction={
        clientGroup && allowEdit
          ? !edit
            ? onClose
            : () => {
                history.push(
                  "/device-client-groups/" +
                    (clientGroup ? (clientGroup.id as string) : "not_possible") +
                    "/show"
                );
              }
          : undefined
      }
    >
      {(!isReady || clientGroup) && !edit && (
        <>
          <FieldList
            asOneLiners={true}
            fields={{
              name: {
                label: intl.formatMessage(ClientGroupLabels.name),
                value: clientGroup ? clientGroup.name : "",
              },
              description: {
                label: intl.formatMessage(ClientGroupLabels.description),
                value: clientGroup ? clientGroup.description : "",
              },
              entitlements: {
                label: (
                  <FormattedMessage
                    defaultMessage="Entitlements"
                    description={"field label"}
                  />
                ),
                value: (
                  <>
                    {groupEntitlements &&
                      groupEntitlements.length > 0 &&
                      groupEntitlements.map((ent, i) => (
                        <>
                          <span key={"groupEntitlements_" + ent.id}>
                            {groupConf.showEntitlement && (
                              <NavigateAfterAction
                                href={
                                  "/device-client-groups/" +
                                    DeviceGroupModalKeys.showEntitlement +
                                  "/" +
                                  ent.id
                                }
                                action={onClose}
                              >
                                {ent.name}
                              </NavigateAfterAction>
                            )}
                            {!groupConf.showEntitlement && ent.name}
                          </span>
                          {groupEntitlements && i < groupEntitlements.length - 1
                            ? ", "
                            : undefined}
                        </>
                      ))}
                    {!groupEntitlements ||
                      (groupEntitlements.length === 0 && (
                        <FormattedMessage
                          defaultMessage="No assigned entitlements"
                          description={"display value for no entitlements"}
                        />
                      ))}
                  </>
                ),
              },
            }}
          />
        </>
      )}
      {(isReady || clientGroup) && edit && (
        <Form noValidate data-test-edit>
          <FormInput
            data-test-view-group-modal-group-name
            label={intl.formatMessage(ClientGroupLabels.name)}
            field="name"
            register={register}
            registerOptions={{
              required: true,
            }}
            hasValue={!!formValues["name"]}
            resolveValidity={FormInputUtils.validityResolver(formState)}
          >
            {errors &&
              errors.name &&
              errors.name.type &&
              errors.name.type === "required" && (
                <Form.Control.Feedback type="invalid">
                  <FontAwesomeIcon
                    icon={IconLibrary.icons.faExclamationCircle}
                    className={"icon"}
                  />
                  <span className={"copy"}>
                    {intl.formatMessage(
                      {
                        defaultMessage: "{name} is required.",
                        description:
                          "Field validation error. 'name' = Field label for device client group name (ClientGroup.name)",
                      },
                      {
                        name: intl.formatMessage(ClientGroupLabels.name),
                      }
                    )}
                  </span>
                </Form.Control.Feedback>
              )}
          </FormInput>
          <FormInput
            data-test-view-group-modal-group-description
            label={
              <FormattedMessage
                defaultMessage="Description"
                description={"field label"}
              />
            }
            field="description"
            type={"textarea"}
            register={register}
            registerOptions={{
              required: false,
            }}
            hasValue={!!formValues["description"]}
            resolveValidity={FormInputUtils.validityResolver(formState)}
          >
          </FormInput>
          {entitlements && (
            <Form.Group>
              <Table<Entitlement>
                disableLoadingIndicator={!isReady}
                maxRows={5}
                compact={true}
                data-test-select-entitlements
                header={
                  <Form.Label>
                    <FormattedMessage
                      defaultMessage="Entitlements"
                      description={"field label"}
                    />
                  </Form.Label>
                }
                pagination={false}
                data={entitlements}
                identifyingColumn={"id"}
                search={entitlements.length > TABLE_SEARCH_THRESHOLD}
                activeSearch={activeSearch}
                onSearch={onSetActiveSearch}
                columnToggle={false}
                reset={false}
                selection={{
                  multi: true,
                  selectAll: true,
                }}
                onSelectionChanged={onSetSelected}
                selected={selected}
                columns={[
                  {
                    key: "id",
                    label: intl.formatMessage(ClientGroupLabels.id),
                    isTechnical: true,
                    hidden: true,
                  },
                  {
                    key: "name",
                    label: intl.formatMessage({
                      defaultMessage: "Entitlement",
                      description:
                        "Column heading for the entitlements to select",
                    }),
                    sortable: true,
                    renderer: (props: {
                      cell: any;
                      row: any;
                      rowIndex: Number;
                      rendererData: any;
                    }) => {
                      return (
                        <>
                          {groupConf.showEntitlement && (
                            <NavigateAfterAction
                              href={
                                "/device-client-groups/" +
                                  DeviceGroupModalKeys.showEntitlement +
                                "/" +
                                props.row.id
                              }
                              action={onClose}
                            >
                              {props.row.name}
                            </NavigateAfterAction>
                          )}
                          {!groupConf.showEntitlement && props.row.name}
                        </>
                      );
                    },
                  },
                ]}
              />
            </Form.Group>
          )}
        </Form>
      )}
      {!clientGroup && isReady && (
        <Feedback type={"danger"} show={true} asChild={true}>
          <p>
            <FormattedMessage
              defaultMessage="Something went wrong and the device client group could not be loaded. The device client group have been removed or you don't have sufficient access rights."
              description="message to be shown when there is no device client group to display"
            />
          </p>
        </Feedback>
      )}
    </Modal>
  );
}

export default ViewDeviceClientGroupModal;
