import { connect } from "react-redux";
import { AppState } from "../../../store/AppState";
import UserLicensesModalView, {
  UserLicensesModalDataProps,
  UserLicensesModalVisibilityProps,
  UserLicensesModalProps as _UserLicensesModalProps,
} from "./user-licenses-modal-container";
import { manageUsersLicenseAssignmentsAndQueryAvailableLicenses } from "../../../actions/entActions";
import { queryAvailableLicensesWithUsage } from "../../../actions/queryAvailableLicensesWithUsageMultiAction";
import {
  convertAssignmentsToSingleAssignmentForSettingAssignmentsCall,
  sortUserLicensedItemAssignmentsDataForManageReservation,
} from "../../../util/licenseUtil";
import { UserLicensedItemAssignments } from "../../../model/entitlement/UserLicensedItemAssignments";
import {
  PickReduxStateProps,
  PickReduxDispatchProps,
} from "../../../util/typeUtil";
import { clearError, getUser } from "../../../actions";
import OrganizationUtils from "../../../utils/organization";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import LicenseUtils from "../../../utils/license";
import UserUtils from "../../../utils/user";
import { addSenderArgument } from "../../../actions/actionHelpers";
import { LicenseAssignment } from "../../../model/entitlement/LicenseAssignment";
import {releaseLicenseLeaseAction} from "../../../actions/releaseLicenseLeaseAction";

// Own props, i.e. props that this component takes as input
export interface UserLicensesModalProps
  extends UserLicensesModalDataProps,
    UserLicensesModalVisibilityProps {}

type ReduxStateProps = PickReduxStateProps<
  _UserLicensesModalProps,
  UserLicensesModalProps
>;
type ReduxDispatchProps = PickReduxDispatchProps<
  _UserLicensesModalProps,
  UserLicensesModalProps
>;

const sender: ActionSender = { key: "users-licenses-modal" };

function mapStateToProps(
  state: AppState,
  ownProps: UserLicensesModalProps
): ReduxStateProps {
  const { userId } = ownProps;
  let licenses = undefined;
  if (userId) {
    licenses = LicenseUtils.selectUserLicensedItemAssignments(userId, state);
    if (licenses) {
      licenses = licenses.map((l) => {
        return sortUserLicensedItemAssignmentsDataForManageReservation(l);
      });
    }
  }
  return {
    user: !!userId ? UserUtils.selectUser(userId, state) : undefined,
    licenses,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}
function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    UserUtils.compareUsersState(next, prev) &&
    LicenseUtils.compareLicensesStates(next, prev) &&
    LicenseUtils.compareLicenseAssignmentsStates(next, prev) &&
    OrganizationUtils.compareOrganizationLicenseIdsStates(next, prev) &&
    UserUtils.compareUserAvailableLicensesState(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadUser: addSenderArgument(sender, getUser),
  onApply: onApply,
  onLoadLicenses: addSenderArgument(sender, queryAvailableLicensesWithUsage),
  onClearError: clearError,
  onReleaseLicenseLease: addSenderArgument(sender, releaseLicenseLeaseAction),
};

function onApply(
  lics: UserLicensedItemAssignments[] | undefined,
  userId: string
): any {
  if (!lics || !userId) {
    throw new Error("Nothing to set");
  }
  const licenseIds: string[] = [];
  const assignements: { [userId: string]: LicenseAssignment[] }[] = [];
  lics.forEach((lic) => {
    const tmp_ass =
      convertAssignmentsToSingleAssignmentForSettingAssignmentsCall(
        lic.assignments[0].assignments
      );
    if (!!tmp_ass && tmp_ass.length > 0) {
      licenseIds.push(lic.assignments[0].license.id as string);
      assignements.push({
        [userId]: tmp_ass,
      });
    }
  });
  return manageUsersLicenseAssignmentsAndQueryAvailableLicenses(
    sender,
    licenseIds,
    assignements
  );
}

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  UserLicensesModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(UserLicensesModalView);
