import * as ActionTypes from "../actions/actionTypes";
import { OrgUserIdsByOrgId } from "../store/OrganizationState";
import {
  removeFromStateListsById,
  removeUsersFromStateLists,
} from "../util/ReducerUtils";

export default function orgUserIds(
  state: OrgUserIdsByOrgId,
  action: ActionTypes.AppAction
): OrgUserIdsByOrgId | null {
  const currentState = state || ({} as OrgUserIdsByOrgId);
  switch (action.type) {
    case ActionTypes.LIST_ORG_USERS: {
      const listOrgUsersCompleted = action as ActionTypes.ListOrgUsersAction;
      const userIds = listOrgUsersCompleted.users.map(
        (user) => user.id as string
      );
      return { ...currentState, [listOrgUsersCompleted.orgId]: userIds };
    }
    case ActionTypes.IMPORT_ORGANIZATION_USERS: {
      const importOrganizationUsers =
        action as ActionTypes.ImportOrganizationUsersAction;

      const existingUserIds = currentState[importOrganizationUsers.orgId] || [];
      const newUserIds = importOrganizationUsers.users.map(
        (user) => user.id as string
      );
      const orgUsers = [...existingUserIds, ...newUserIds];
      return {
        ...currentState,
        [importOrganizationUsers.orgId]: orgUsers.filter(
          (id, i) => orgUsers.indexOf(id) === i
        ),
      };
    }

    case ActionTypes.DELETE_ORG_GROUP:
    case ActionTypes.REMOVE_USERS_FROM_ORG_GROUP:
    case ActionTypes.SET_USERS_IN_ORG_GROUP:
    case ActionTypes.SET_ORG_GROUPS_OF_USER:
    case ActionTypes.REMOVE_ORG_GROUPS_OF_USER:
    case ActionTypes.REMOVE_USER_FROM_ORG:
    case ActionTypes.DELETE_USER:
      return removeUsersFromStateLists<OrgUserIdsByOrgId>(action, currentState);

    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

function handleErrorAction(
  currentState: OrgUserIdsByOrgId,
  action: ActionTypes.AppAction
): OrgUserIdsByOrgId {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  if (
    errorAction.error.action.type === ActionTypes.GET_USER &&
    errorAction.error.apiError.error === "404"
  ) {
    const typedError =
      action as ActionTypes.AddErrorAction<ActionTypes.GetUserAction>;
    finalState = removeFromStateListsById<OrgUserIdsByOrgId>(
      [typedError.error.action?.userId as string],
      currentState
    );
  }

  return finalState;
}
