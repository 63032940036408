import {
  ViewDeviceClientInvitationModalProps as _ViewDeviceClientInvitationModalProps,
  ViewDeviceClientInvitationModalDataProps,
} from "./view-device-client-invitation-modal-view";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import { ActionSender } from "../../../model/ActionSender";
import { AppState } from "../../../store/AppState";
import InProgressUtils from "../../../utils/in-progress";
import OrganizationUtils from "../../../utils/organization";
import { addSenderArgument } from "../../../actions/actionHelpers";
import {
  clearError, getOrganizationClientGroupInvitation,
} from "../../../actions";
import { connect } from "react-redux";
import ViewDeviceClientInvitationModal from "./view-device-client-invitation-modal-container";
import {populateDeviceClientInvitationExpiredStatus} from "../../../utils/deviceClientGroupInvitation";

export interface ViewDeviceClientInvitationModalProps
  extends Omit<_ViewDeviceClientInvitationModalProps, keyof ViewDeviceClientInvitationModalDataProps> {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ViewDeviceClientInvitationModalProps,
  ViewDeviceClientInvitationModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ViewDeviceClientInvitationModalProps,
  ViewDeviceClientInvitationModalProps
>;
const sender: ActionSender = { key: "view-device-client-invitation" };

function mapStateToProps(
  state: AppState,
  ownProps: ViewDeviceClientInvitationModalProps
): ReduxStateProps {
  return {
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    invitation: ownProps.invitationId
      ? populateDeviceClientInvitationExpiredStatus(OrganizationUtils.selectOrganizationClientInvitation(
          ownProps.invitationId,
          state
        ))
      : undefined,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientInvitationsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadInvitation: addSenderArgument(
    sender,
    getOrganizationClientGroupInvitation
  ),
  onClearError: clearError,
};
export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ViewDeviceClientInvitationModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ViewDeviceClientInvitationModal);
