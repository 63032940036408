import { useContext, useEffect, HTMLAttributes } from "react";
import { useIntl } from "react-intl";
import { SummaryCard, IconLibrary, FieldList, TooltipWrapper } from "@10duke/dukeui";
import { Link } from "react-router-dom";
import { LicenseWithCredits } from "../../../model/entitlement/LicenseWithCredits";
import LicenseUtils from "../../../utils/licensed-item";
import { ModalKeys } from "../../pages/licenses";
import {
  isAboutToExpire,
  isScheduled,
  isValid,
  sortToLatestByCreatedOrUpdated,
} from "../../../util/objectUtil";
import { ButtonGroup } from "react-bootstrap";
import LicenseValidityBadge, {
  resolveLicenseValidity,
} from "../../badges/license-validity-badge";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import { LicenseValues } from "../../../localization/license";
import "./licenses-card-view.scss";
import {hasAction} from "../../../ui-configuration/configuration-tools";
interface LicensesCardDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface LicensesCardProps extends LicensesCardDOMProps {
  isReady: boolean;
  licenses?: LicenseWithCredits[] | null;
  validCount?: number;
  invalidCount?: number;
  expiringCount?: number;
  scheduledCount?: number;
  deactivatedCount?: number;
  onLoadLicenses?: () => {};
  moreAction: () => void;
}

function LicensesCard(props: LicensesCardProps) {
  const {
    licenses,
    onLoadLicenses,
    moreAction,
    validCount,
    invalidCount,
    expiringCount,
    scheduledCount,
    deactivatedCount,
    isReady,
  } = props;
  const intl = useIntl();
  const { conf } = useContext(UIConfiguration);
  const licensesConf =
    conf.functionality && conf.functionality.licenses
      ? conf.functionality.licenses
      : {};
  const headerButton = {
    label: intl.formatMessage({
      defaultMessage: "View",
      description: "label for the view button",
    }),
    action: moreAction,
  };
  if (!onLoadLicenses && licenses === undefined) {
    throw new Error(
      "UsersCard: Required props missing. Either licenses or onLoadLicenses must be defined"
    );
  }
  const fields: any = {
    total: {
      label: intl.formatMessage(SummaryCard.TotalLabel),
      value: licenses ? licenses.length : 0,
      itemClass: "total",
    },
  };
  if (invalidCount) {
    fields.invalid = {
      label: intl.formatMessage(LicenseValues.validityStatus.invalid),
      value: invalidCount,
      itemClass: "invalid",
    };
  }
  if (expiringCount) {
    fields.expiring = {
      label: intl.formatMessage(LicenseValues.validityStatus.expiring),
      value: expiringCount,
      itemClass: "expiring",
    };
  }
  if (scheduledCount) {
    fields.scheduled = {
      label: intl.formatMessage(LicenseValues.validityStatus.scheduled),
      value: scheduledCount,
    };
  }
  if (validCount) {
    fields.valid = {
      label: intl.formatMessage(LicenseValues.validityStatus.valid),
      value: validCount,
      itemClass: "valid",
    };
  }
  if (deactivatedCount) {
    fields.deactivatedCount = {
      label: intl.formatMessage(LicenseValues.validityStatus.deactivated),
      value: deactivatedCount,
      itemClass: "deactivated",
    };
  }
  const isLicensesUndefined = licenses === undefined;
  useEffect(() => {
    if (isLicensesUndefined && onLoadLicenses) {
      onLoadLicenses();
    }
  }, [isLicensesUndefined, onLoadLicenses]);

  return (
    <SummaryCard
      className={"licenses-card"}
      onReloadData={() => {
        if (onLoadLicenses) {
          onLoadLicenses();
        }
      }}
      isReady={isReady}
      data-test-licenses-card
      title={intl.formatMessage({
        defaultMessage: "Licenses",
        description: "heading for the card",
      })}
      headerButton={headerButton}
      items={
        licenses && licenses.length
          ? licenses
              // expired
              .filter((l) => !isValid(l) && !isScheduled(l))
              .filter((l: any) => l.active !== false)
              .sort(sortToLatestByCreatedOrUpdated)
              // about to expire
              .concat(
                licenses
                  .filter((l) => isValid(l) && isAboutToExpire(l))
                  .filter((l: any) => l.active !== false)
                  .sort(sortToLatestByCreatedOrUpdated)
              )
              // scheduled
              .concat(
                licenses
                  .filter((l) => !isValid(l) && isScheduled(l))
                  .filter((l: any) => l.active !== false)
                  .sort(sortToLatestByCreatedOrUpdated)
              )
              // valid
              .concat(
                licenses
                  .filter((l) => isValid(l) && !isAboutToExpire(l))
                  .filter((l: any) => l.active !== false)
                  .sort(sortToLatestByCreatedOrUpdated)
              )
              // not active
              .concat(
                licenses
                  .filter((l: any) => l.active === false)
                  .sort(sortToLatestByCreatedOrUpdated)
              )
              .map((l) => {
                const Wrapper = hasAction(licensesConf.rowActions, 'show') ? Link : "span";
                const wrapperProps: any = {
                  className: "item-copy",
                };
                if (hasAction(licensesConf.rowActions, 'show')) {
                  wrapperProps.to =
                    (("/licenses/" + l.id) as string) + "/" + ModalKeys.show;
                }
                const lName = LicenseUtils.resolveDisplayName(
                  (l as any).licensedItem,
                  intl.formatMessage(
                    LicenseValues.licensedItem.displayName.undefined
                  )
                );
                return (
                  <>
                    <Wrapper {...wrapperProps}>
                      <TooltipWrapper
                        tipKey={"license_" + l.id}
                        tip={lName}
                        placement={"top"}
                      >
                        <span className={"label"}>{lName}</span>
                      </TooltipWrapper>
                      <LicenseValidityBadge
                        validFrom={l.validFrom}
                        validUntil={l.validUntil}
                        validity={resolveLicenseValidity(l)}
                      />
                    </Wrapper>
                    {hasAction(licensesConf.rowActions, 'usage') && (
                      <ButtonGroup size={"sm"}>
                          <Link
                            className={"btn btn-secondary custom-base"}
                            to={
                              (("/licenses/" + l.id) as string) +
                              "/" +
                              ModalKeys.usage
                            }
                          >
                            {intl.formatMessage({
                              defaultMessage: "Usage",
                              description: "label for the usage button",
                            })}
                          </Link>
                      </ButtonGroup>
                    )}
                  </>
                );
              })
          : undefined
      }
      footer={<FieldList inline={true} fields={fields} />}
    >
      {licenses && licenses.length === 0 && (
        <div data-test-no-items className={"no-data"}>
          <div className={"icon"}>{IconLibrary.customIcons.noData}</div>
          <p>
            {intl.formatMessage({
              defaultMessage: "No licenses to show.",
              description: "display value for no licenses",
            })}
          </p>
        </div>
      )}
    </SummaryCard>
  );
}
export default LicensesCard;
