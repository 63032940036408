import { useEffect, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { FieldList, Feedback, Modal } from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import { User } from "../../../model/User";
import UserUtils, { UserWithStatus } from "../../../utils/user";
import UserStatusBadge from "../../badges/user-status-badge";
import {
  ClearErrorAction,
  GetUserAction,
  isAddErrorAction,
} from "../../../actions/actionTypes";
import {
  UserLabels,
  UserValues,
  UserWithStatusLabels,
} from "../../../localization/user";

//<editor-fold desc="Props">

const resolveUserName = (
  user: User | undefined | null,
  intl: { formatMessage: (v: any, v2?: any) => string }
) =>
  UserUtils.resolveDisplayName(
    user,
    intl.formatMessage(UserValues.displayName.undefined)
  );

export interface ViewUserModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface ViewUserModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  user?: UserWithStatus | null;
  onLoadUser: (id: string) => Promise<GetUserAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface ViewUserModalProps
  extends ViewUserModalDOMProps,
    ViewUserModalDataProps,
    Omit<ClosableModalProps, "isReady"> {
  userId?: string;
}
//</editor-fold>

function ViewUserModal(props: ViewUserModalProps) {
  //<editor-fold desc="Local variables">
  let {
    user,
    userId,
    onLoadUser,
    onClearError,
    show,
    onClose,
    isReady,
    onExited,
  } = props;

  const userObjId = user ? user.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!userId &&
      (userObjId === undefined || (!!userObjId && userObjId !== userId)) &&
      !!onLoadUser
    ) {
      onLoadUser(userId).then((res) => {
        if (!userObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, userId, userObjId, onLoadUser, onClearError]);
  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>

  return (
    <Modal
      onReloadData={() => {
        if ((user || userId) && onLoadUser) {
          onLoadUser(user ? (user.id as string) : (userId as string)).then(
            (res) => {
              if (!user && isAddErrorAction(res)) {
                // only clear error if no data exists, leave if previous data is still available and
                // let the api error notification handler show error
                onClearError(res.error?.errorId);
              }
            }
          );
        }
      }}
      onExited={onExited}
      isReady={isReady}
      data-test-view-user-modal
      title={
        !isReady || user
          ? intl.formatMessage(
              {
                defaultMessage: "{name}: profile details",
                description: "modal heading. 'name' = name of the user",
              },
              {
                name: resolveUserName(user, intl),
              }
            )
          : intl.formatMessage({
              defaultMessage: "User not found",
              description: "modal heading when user can't be found",
            })
      }
      show={show}
      onClose={onClose}
      primaryButton={{
        label: intl.formatMessage({
          defaultMessage: "Close",
          description: "primary button label",
        }),
      }}
      onPrimaryAction={onClose}
    >
      {isReady && !user && (
        <Feedback type={"danger"} show={true} asChild={true}>
          <p>
            <FormattedMessage
              defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
              description="message to be shown when there is no user to display"
            />
          </p>
        </Feedback>
      )}
      {(user || !isReady) && (
        <>
          <FieldList
            asOneLiners={true}
            fields={{
              displayName: {
                label: intl.formatMessage(UserLabels.displayName),
                value: user ? user.displayName : "",
              },
              status: {
                label: intl.formatMessage(UserWithStatusLabels.status),
                value: user ? <UserStatusBadge status={user.status} /> : "",
              },
              professionalTitle: {
                label: intl.formatMessage(UserLabels.professionalTitle),
                value: user ? user.professionalTitle : "",
              },
              firstName: {
                label: intl.formatMessage(UserLabels.firstName),
                value: user ? user.firstName : "",
              },
              lastName: {
                label: intl.formatMessage(UserLabels.lastName),
                value: user ? user.lastName : "",
              },
              nickname: {
                label: intl.formatMessage(UserLabels.nickname),
                value: user ? user.nickname : "",
              },
              email: {
                label: intl.formatMessage(UserLabels.email),
                value: user ? (
                  <a
                    href={"mailto://" + user.email}
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    {user.email}
                  </a>
                ) : (
                  ""
                ),
              },
              phoneNumber: {
                label: intl.formatMessage(UserLabels.phoneNumber),
                value:
                  user && user.phoneNumber ? (
                    <a
                      href={"tel://" + user.phoneNumber}
                      target={"_blank"}
                      rel="noreferrer"
                    >
                      {user.phoneNumber}
                    </a>
                  ) : (
                    ""
                  ),
              },
            }}
          />
        </>
      )}
    </Modal>
  );
}

export default ViewUserModal;
