import { withCloseAfterExited } from "@10duke/dukeui";
import {
  default as View,
  ViewEntitlementModalProps
} from "./view-entitlement-modal-view";

const ViewEntitlementModal = withCloseAfterExited<ViewEntitlementModalProps>(
  View
);
export default ViewEntitlementModal;
