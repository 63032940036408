import { defineMessages } from "react-intl";

/**
 * Localized labels for AuthorEditorFields fields
 */
export const AuthorEditorFieldsLabels = {
  ...defineMessages({
    /*
    editorId?: string;
    authorId?: string;
       */
    created: {
      defaultMessage: "created",
      description:
        "Field label for creation time of the object (AuthorEditorFields.created)"
    },
    modified: {
      defaultMessage: "updated",
      description:
        "Field label for last update time of the object (AuthorEditorFields.modified)"
    }
  })
};
