import { useEffect, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {FeedbackEntry, Feedback, Modal} from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import {
  ClearErrorAction,
  DeleteOrgClientGroupAction,
  GetOrgClientGroupAction,
  isAddErrorAction,
} from "../../../actions/actionTypes";
import {ClientGroup} from "../../../model/ClientGroup";

//<editor-fold desc="Props">

export interface RemoveDeviceClientGroupModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  onRemoveClientGroup: (clientGroupId: string) => Promise<DeleteOrgClientGroupAction>;
  onLoadClientGroup: (groupId: string) => Promise<GetOrgClientGroupAction>;
  clientGroup: ClientGroup | undefined | null;
  onClearError: (errorId: string) => ClearErrorAction;
}

export interface RemoveDeviceClientGroupModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}

export interface RemoveDeviceClientGroupModalProps
  extends RemoveDeviceClientGroupModalDOMProps,
    RemoveDeviceClientGroupModalDataProps,
    Omit<ClosableModalProps, "isReady"> {
  onShowFeedback: (feedback: FeedbackEntry) => void;
  clientGroupId?: string;
}
//</editor-fold>

function RemoveDeviceClientGroupModal(props: RemoveDeviceClientGroupModalProps) {
  //<editor-fold desc="Local variables">
  let {
    clientGroup,
    clientGroupId,
    onRemoveClientGroup,
    show,
    onClose,
    onShowFeedback,
    onLoadClientGroup,
    isReady,
    onExited,
    onClearError,
  } = props;
  const groupObjId = clientGroup ? clientGroup.id : undefined;
  // this is more like a variable than a hook
  const intl = useIntl();
  useEffect(() => {
    if (
      !!show &&
      !!clientGroupId &&
      (groupObjId === undefined || (!!groupObjId && groupObjId !== clientGroupId)) &&
      !!onLoadClientGroup
    ) {
      onLoadClientGroup(clientGroupId).then((res) => {
        if (!groupObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, clientGroupId, groupObjId, onLoadClientGroup, onClearError]);

  //</editor-fold>

  return (
    <Modal
      onExited={onExited}
      onReloadData={() => {
        if ((clientGroup || clientGroupId) && onLoadClientGroup) {
          onLoadClientGroup(clientGroup ? (clientGroup.id as string) : (clientGroupId as string)).then(
            (res) => {
              if (!clientGroup && isAddErrorAction(res)) {
                // only clear error if no data exists, leave if previous data is still available and
                // let the api error notification handler show error
                onClearError(res.error?.errorId);
              }
            }
          );
        }
      }}
      isReady={isReady}
      data-test-remove-device-client-group-modal={clientGroup ? clientGroup.id : true}
      title={intl.formatMessage({
        defaultMessage: "Remove device client group",
        description: "modal heading",
      })}
      show={show}
      onClose={onClose}
      primaryButton={
        clientGroup
          ? {
              label: intl.formatMessage({
                defaultMessage: "Remove",
                description: "primary button label",
              }),
            }
          : {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
      }
      onPrimaryAction={() => {
        if (show && clientGroup) {
          onRemoveClientGroup(clientGroup ? (clientGroup.id as string) : "not_possible").then(
            (res) => {
              if (!isAddErrorAction(res)) {
                onShowFeedback({
                  id: "removeDeviceClientGroup",
                  msg: intl.formatMessage(
                    {
                      defaultMessage: "Device client group {name} removed.",
                      description:
                        "success notification. 'name' = name of the group",
                    },
                    {
                      name:
                        "<strong>" +
                        (clientGroup ? clientGroup.name : "impossible") +
                        "</strong>",
                    }
                  ),
                  autoClose: true,
                  type: "success",
                });
              } else {
                onClearError(res.error?.errorId);
                onShowFeedback({
                  id: "removeDeviceClientGroup",
                  msg: intl.formatMessage(
                    {
                      defaultMessage: "Removing device client group {name} failed: {msg}",
                      description:
                        "failure notification. 'name' = name of the group, 'msg' = error message",
                    },
                    {
                      name:
                        "<strong>" +
                        (clientGroup ? clientGroup.name : "impossible") +
                        "</strong>",
                      msg: res.error.apiError.error_description,
                    }
                  ),
                  type: "danger",
                });
              }
            }
          );
        }
        onClose();
      }}
      secondaryButton={
        show && clientGroup
          ? {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={clientGroup ? onClose : undefined}
    >
      <>
        {show && clientGroup && (
          <p>
            <FormattedMessage
              defaultMessage="Are you sure you wish to remove the device client group {name}?"
              description={
                "confirm action message. 'name' = the name of the device client group"
              }
              values={{
                name: <strong>{clientGroup ? clientGroup.name : "impossible"}</strong>,
              }}
            />
          </p>
        )}
        {isReady && show && !clientGroup && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p data-test-device-client-group-not-found>
              <FormattedMessage
                defaultMessage="No matching device client group found."
                description="message to be shown when the device client group was not found"
              />
            </p>
          </Feedback>
        )}
      </>
    </Modal>
  );
}

export default RemoveDeviceClientGroupModal;
