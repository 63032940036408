import { defineMessages } from "react-intl";
import { BaseObjLabels } from "../base-obj";
import { ValidityFieldsLabels } from "../validity-fields";

/**
 * Localized labels for OrganizationGroupInvitation fields
 */
export const ClientGroupInvitationLabels = {
  ...BaseObjLabels,
  // Add AuthorEditorFields if needed
  ...ValidityFieldsLabels,
  ...defineMessages({
    // organizationRoleIds
    groupIds: {
      defaultMessage: "device client groups",
      description:
        "Field label for targeted device client groups (ClientGroupInvitation.groupIds)",
    },
    // organizationId
    // clientRoleIds
    email: {
      defaultMessage: "recipient email",
      description:
        "Field label for device client invitation recipient email (ClientGroupInvitation.email)",
    },
    invitationState: {
      defaultMessage: "state",
      description:
        "Field label for the state of the device client  invitation (ClientGroupInvitation.invitationState)",
    },
    // clientData
    // memberWelcomeUrl
    // invitationCompletedUrl
    // claimedAt
    // declinedAt
    // revokedAt
    recipientName: {
      defaultMessage: "recipient name",
      description:
        "Field label for the name of the recipient (ClientGroupInvitation.recipientName)",
    },
    clientName: {
      defaultMessage: "device client name",
      description:
          "Field label for the name of the client (ClientGroupInvitation.clientName)",
    },
    allowClientId: {
      defaultMessage: "device clientid",
      description:
          "Field label for the name of the client id (ClientGroupInvitation.allowClientId)",
    },
    inviterName: {
      defaultMessage: "inviter name",
      description:
        "Field label for the name of the inviter (ClientGroupInvitation.inviterName)",
    },
    // recipientCountryCode
    // recipientLanguageCode
    invitationScopeInformation: {
      defaultMessage: "message",
      description:
        "Field label for a custom message attached to the device client invitation (ClientGroupInvitation.invitationScopeInformation)",
    },
  }),
};

/**
 * Localized values for OrganizationGroupInvitation fields
 */
export const ClientGroupInvitationValues = {
  invitationState: defineMessages({
    deliveryRequested: {
      defaultMessage: "pending",
      description:
        "Label for invitation state when the device client group invitation has been sent and its waiting for a response from the recipient (ClientGroupInvitation.invitationState 'deliveryRequested')",
    },
    updated: {
      defaultMessage: "updated",
      description:
        "Label for invitation state when the device client group invitation has been updated (ClientGroupInvitation.invitationState 'updated')",
    },
    created: {
      defaultMessage: "unsent",
      description:
        "Label for invitation state when the device client group invitation has been created (ClientGroupInvitation.invitationState 'created')",
    },
    revoked: {
      defaultMessage: "revoked",
      description:
        "Label for invitation state when the device client group invitation has been revoked (ClientGroupInvitation.invitationState 'revoked')",
    },
    accepted: {
      defaultMessage: "accepted",
      description:
        "Label for invitation state when the device client group invitation has been accepted (ClientGroupInvitation.invitationState 'accepted')",
    },
    declined: {
      defaultMessage: "declined",
      description:
          "Label for invitation state when the device client group invitation has been declined (ClientGroupInvitation.invitationState 'declined')",
    },
    expired: {
      defaultMessage: "expired",
      description:
          "Label for invitation state when the device client group invitation has expired (ClientGroupInvitation.invitationState 'expired')",
    },
  }),
};
