import { AppState } from "../store/AppState";
import { Permission } from "../model/Permission";

function selectPermissions(state: AppState): Permission[] | undefined {
  return state.permissions
    ? (Object.keys(state.permissions)
        .map((key) => (state.permissions ? state.permissions[key] : undefined))
        .filter((v) => !!v) as Permission[])
    : undefined;
}

function comparePermissionStates(next: AppState, prev: AppState): boolean {
  return next.permissions === prev.permissions;
}
const PermissionsUtils = {
  selectPermissions,
  comparePermissionStates,
};
export default PermissionsUtils;
