import { useContext, useEffect, HTMLAttributes } from "react";
import { useIntl } from "react-intl";
import { SummaryCard, IconLibrary, FieldList, TooltipWrapper } from "@10duke/dukeui";
import { Link } from "react-router-dom";
import { ButtonGroup } from "react-bootstrap";
import { ModalKeys } from "../../pages/users";
import { User } from "../../../model/User";
import UserUtils, { UserWithStatus } from "../../../utils/user";
import { sortToLatestByCreatedOrUpdated } from "../../../util/objectUtil";
import UserStatusBadge from "../../badges/user-status-badge";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import { UserValues, UserWithStatusValues } from "../../../localization/user";
import "./users-card-view.scss";
import {hasAction} from "../../../ui-configuration/configuration-tools";
const resolveUserName = (
  user: User | undefined | null,
  intl: { formatMessage: (v: any, v2?: any) => string }
) =>
  UserUtils.resolveDisplayName(
    user,
    intl.formatMessage(UserValues.displayName.undefined)
  );

interface UsersCardDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface UsersCardProps extends UsersCardDOMProps {
  isReady: boolean;
  users?: UserWithStatus[] | null;
  onLoadUsers?: () => {};

  adminCount?: number;
  onLoadAdminCount: () => void;

  inactiveCount?: number;
  suspendedCount?: number;

  moreAction: () => void;
}

function UsersCard(props: UsersCardProps) {
  const {
    users,
    onLoadUsers,
    inactiveCount,
    suspendedCount,
    adminCount,
    onLoadAdminCount,
    moreAction,
    isReady,
  } = props;
  const intl = useIntl();
  const { conf } = useContext(UIConfiguration);
  const usersConf =
    conf.functionality && conf.functionality.users
      ? conf.functionality.users
      : {};
  const headerButton = {
    label: intl.formatMessage({
      defaultMessage: "View",
      description: "label for the view button",
    }),
    action: moreAction,
  };
  if (!onLoadUsers && users === undefined) {
    throw new Error(
      "UsersCard: Required props missing. Either users or onLoadUsers must be defined"
    );
  }
  const isUsersUndefined = users === undefined;
  useEffect(() => {
    if (isUsersUndefined && onLoadUsers) {
      onLoadUsers();
    }
  }, [isUsersUndefined, onLoadUsers]);

  useEffect(() => {
    if (adminCount === undefined) {
      onLoadAdminCount();
    }
  }, [adminCount, onLoadAdminCount]);
  const fields: any = {
    total: {
      label: intl.formatMessage(SummaryCard.TotalLabel),
      value: users ? users.length : 0,
      itemClass: "total",
    },
  };
  if (suspendedCount) {
    fields.suspended = {
      label: intl.formatMessage(UserWithStatusValues.status.suspended),
      value: suspendedCount,
      itemClass: "suspended",
    };
  }
  if (inactiveCount) {
    fields.inactive = {
      label: intl.formatMessage(UserWithStatusValues.status.inactive),
      info: intl.formatMessage({
        defaultMessage:
          "Inactive means that the user has not logged in for 30 days.",
        description: "field info",
      }),
      value: inactiveCount,
      itemClass: "inactive",
    };
  }
  if (adminCount) {
    fields.admins = {
      label: intl.formatMessage(UserWithStatusValues.status.admin),
      value: adminCount,
      itemClass: "admin",
    };
  }
  return (
    <SummaryCard
      className={"users-card"}
      onReloadData={() => {
        if (onLoadUsers) {
          onLoadUsers();
        }
        if (onLoadAdminCount) {
          onLoadAdminCount();
        }
      }}
      isReady={isReady}
      data-test-users-card
      title={intl.formatMessage({
        defaultMessage: "Users",
        description: "heading for the card",
      })}
      headerButton={headerButton}
      items={
        users && users.length
          ? users
              .filter((u) => u.status === "suspended")
              .sort(sortToLatestByCreatedOrUpdated)
              .concat(
                users
                  .filter((u) => u.status === "inactive")
                  .sort(sortToLatestByCreatedOrUpdated)
              )
              .concat(
                users
                  .filter((u) => u.status === "admin")
                  .sort(sortToLatestByCreatedOrUpdated)
              )
              .concat(
                users
                  .filter(
                    (u) =>
                      u.status !== "suspended" &&
                      u.status !== "inactive" &&
                      u.status !== "admin"
                  )
                  .sort(sortToLatestByCreatedOrUpdated)
              )
              .map((u) => {
                const Wrapper = hasAction(usersConf.rowActions, 'show') ? Link : "span";
                const wrapperProps: any = {
                  className: "item-copy",
                };
                if (hasAction(usersConf.rowActions, 'show')) {
                  wrapperProps.to =
                    (("/users/" + u.id) as string) + "/" + ModalKeys.show;
                }
                const uName = resolveUserName(u, intl);
                return (
                  <>
                    <Wrapper {...wrapperProps}>
                      <TooltipWrapper
                        tipKey={"user_" + u.id}
                        tip={uName}
                        placement={"top"}
                      >
                        <span className={"label"}>{uName}</span>
                      </TooltipWrapper>
                      <UserStatusBadge status={u.status} />
                    </Wrapper>
                    {(hasAction(usersConf.rowActions, 'group') || hasAction(usersConf.rowActions, 'licenses')) && (
                      <ButtonGroup size={"sm"}>
                        {hasAction(usersConf.rowActions, 'groups') && (
                          <Link
                            className={"btn btn-secondary custom-base"}
                            to={
                              (("/users/" + u.id) as string) +
                              "/" +
                              ModalKeys.groups
                            }
                          >
                            {intl.formatMessage({
                              defaultMessage: "Groups",
                              description: "label for groups button",
                            })}
                          </Link>
                        )}
                        {hasAction(usersConf.rowActions, 'licenses') && (
                          <Link
                            className={"btn btn-secondary custom-base"}
                            to={
                              (("/users/" + u.id) as string) +
                              "/" +
                              ModalKeys.licenses
                            }
                          >
                            {intl.formatMessage({
                              defaultMessage: "Licenses",
                              description: "label for licenses button",
                            })}
                          </Link>
                        )}
                      </ButtonGroup>
                    )}
                  </>
                );
              })
          : undefined
      }
      footer={<FieldList inline={true} fields={fields} />}
    >
      {users && users.length === 0 && (
        <div data-test-no-items className={"no-data"}>
          <div className={"icon"}>{IconLibrary.customIcons.noData}</div>
          <p>
            {intl.formatMessage({
              defaultMessage: "No users to show.",
              description:
                "shown in place of users when there are no users to show",
            })}
          </p>
        </div>
      )}
    </SummaryCard>
  );
}
export default UsersCard;
