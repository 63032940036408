import { useState } from "react";
import {FeedbackContainerProps,withFeedback} from "@10duke/dukeui";
import DeviceClientGroupsView, {
  DeviceClientGroupsProps as _DeviceClientGroupsProps,
  DeviceClientGroupsStateProps,
  DeviceClientGroupsDOMProps as _DeviceClientGroupsDOMProps,
  DeviceClientGroupsModalVisibilityProps as _DeviceClientGroupsModalVisibilityProps,
} from "./device-client-groups-view";
import {ClientGroup} from "../../../model/ClientGroup";
export { ModalKeys } from "./device-client-groups-view";

export type DeviceClientGroupsDOMProps = _DeviceClientGroupsDOMProps;
export type DeviceClientGroupsModalVisibilityProps = _DeviceClientGroupsModalVisibilityProps;

export interface DeviceClientGroupsProps
  extends Omit<_DeviceClientGroupsProps, keyof DeviceClientGroupsStateProps> {}

function DeviceClientGroups(props: DeviceClientGroupsProps & FeedbackContainerProps) {
  const [selected, setSelected] = useState([] as ClientGroup[]);
  const [activeSearch, onSetActiveSearch] = useState("");
  const viewProps: _DeviceClientGroupsProps = {
    selected: selected,
    activeSearch: activeSearch,
    onSetActiveSearch: onSetActiveSearch,
    onSetSelected: setSelected,
    ...props,
  };
  return <DeviceClientGroupsView {...viewProps} />;
}
export default withFeedback<DeviceClientGroupsProps & FeedbackContainerProps>(DeviceClientGroups);
