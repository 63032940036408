import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {
  buildActionThunk,
  buildMultiActionThunk,
  ensureSelectedOrgId,
  EventOperation,
  EventOperationProvider,
  forceUndefined
} from "./actionHelpers";
import { OrganizationGroupInvitation } from "../model/OrganizationGroupInvitation";
import { CreateOrgGroupInvitationAction } from "./actionTypes";

/**
 * Lists all organization group invitations of an organization.
 * @param sender Component requesting for the action
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function listOrganizationsOrganizationGroupInvitations(
  sender: ActionSender,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListOrgOrgGroupInvitationsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.ListOrgOrgGroupInvitationsAction,
    OrganizationGroupInvitation[]
  >(
    sender,
    ActionTypes.LIST_ORG_ORG_GROUP_INVITATIONS,
    async () =>
      await idpApi.listOrganizationsOrganizationGroupInvitations(
        orgIdOrDefault
      ),
    (type, invitations) => ({
      type,
      invitations: forceUndefined(invitations),
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Gets an organization group invitation by id.
 * @param sender Component requesting for the action
 * @param invitationId The invitation id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function getOrganizationsOrganizationGroupInvitation(
  sender: ActionSender,
  invitationId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.GetOrgOrgGroupInvitationAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.GetOrgOrgGroupInvitationAction,
    OrganizationGroupInvitation
  >(
    sender,
    ActionTypes.GET_ORG_ORG_GROUP_INVITATION,
    async () =>
      await idpApi.getOrganizationsOrganizationGroupInvitation(
        invitationId,
        orgIdOrDefault
      ),
    (type, invitation) => ({
      type,
      invitation: forceUndefined(invitation),
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Creates a new invitation. Please note that this operation only creates the invitation,
 * and does not send it to the invitee.
 * @param sender Component requesting for the action
 * @param invitation OrganizationGroupInvitation object describing the invitation to create.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function createOrganizationGroupInvitation(
  sender: ActionSender,
  invitation: OrganizationGroupInvitation,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.CreateOrgGroupInvitationAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.CreateOrgGroupInvitationAction,
    OrganizationGroupInvitation
  >(
    sender,
    ActionTypes.CREATE_ORG_GROUP_INVITATION,
    async () => await idpApi.createOrganizationGroupInvitation(invitation),
    (type, invitation) => ({
      type,
      invitation: forceUndefined(invitation),
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Sends the invitation by email to the recipient so the recipient can can
 * accept or decline the invitation.
 * @param sender Component requesting for the action
 * @param invitationId The invitation id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function sendOrganizationGroupInvitation(
  sender: ActionSender,
  invitationId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.SendOrgGroupInvitationAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.SendOrgGroupInvitationAction,
    OrganizationGroupInvitation
  >(
    sender,
    ActionTypes.SEND_ORG_GROUP_INVITATION,
    async () => await idpApi.sendOrganizationGroupInvitation(invitationId),
    (type, invitation) => ({
      type,
      invitation: forceUndefined(invitation),
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Revokes an invitation, which means that a previously delivered email and the
 * link in it can no longer be used.
 * @param sender Component requesting for the action
 * @param invitationId The invitation id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function revokeOrganizationGroupInvitation(
  sender: ActionSender,
  invitationId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.RevokeOrgGroupInvitationAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.RevokeOrgGroupInvitationAction,
    OrganizationGroupInvitation
  >(
    sender,
    ActionTypes.REVOKE_ORG_GROUP_INVITATION,
    async () => await idpApi.revokeOrganizationGroupInvitation(invitationId),
    (type, invitation) => ({
      type,
      invitation: forceUndefined(invitation),
      orgId: orgIdOrDefault
    })
  );
}

/**
 * Deletes an invitation.
 * @param sender Component requesting for the action
 * @param invitationId The invitation id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function deleteOrganizationGroupInvitation(
  sender: ActionSender,
  invitationId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.DeleteOrgGroupInvitationAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.DeleteOrgGroupInvitationAction, void>(
    sender,
    ActionTypes.DELETE_ORG_GROUP_INVITATION,
    async () => await idpApi.deleteOrganizationGroupInvitation(invitationId),
    type => ({
      type,
      invitationId: invitationId,
      orgId: orgIdOrDefault
    })
  );
}
export function createAndSendOrganizationGroupInvitation(
  sender: ActionSender,
  invitation: OrganizationGroupInvitation,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.MultiAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);

  return buildMultiActionThunk(
    sender,
    new CreateAndSendOrganizationGroupInvitationProvider(
      sender,
      invitation,
      orgIdOrDefault
    ),
    false
  );
}
enum CreateAndSendOrganizationGroupInvitationState {
  INIT = 0,
  SEND,
  DONE
}

class CreateAndSendOrganizationGroupInvitationProvider
  implements EventOperationProvider<ActionTypes.AppAction> {
  private state: CreateAndSendOrganizationGroupInvitationState;
  private sender: ActionSender;
  private invitation: OrganizationGroupInvitation;
  private orgId: string;

  constructor(
    sender: ActionSender,
    invitation: OrganizationGroupInvitation,
    orgId: string
  ) {
    this.state = CreateAndSendOrganizationGroupInvitationState.INIT;
    this.sender = sender;
    this.invitation = invitation;
    this.orgId = orgId;
  }

  next(
    sender: ActionSender,
    multiAction: ActionTypes.MultiAction
  ): EventOperation<ActionTypes.AppAction> | null {
    if (this.state === CreateAndSendOrganizationGroupInvitationState.INIT) {
      this.state = CreateAndSendOrganizationGroupInvitationState.SEND;
      return {
        thunk: createOrganizationGroupInvitation(
          this.sender,
          this.invitation,
          this.orgId
        )
      };
    }
    if (this.state === CreateAndSendOrganizationGroupInvitationState.SEND) {
      this.state = CreateAndSendOrganizationGroupInvitationState.DONE;
      return {
        thunk: sendOrganizationGroupInvitation(
          this.sender,
          (multiAction.results[0] as CreateOrgGroupInvitationAction).invitation
            .id as string,
          this.orgId
        )
      };
    }
    return null;
  }
}
