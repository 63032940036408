import { connect } from "react-redux";
import OrganizationUtils from "../../../utils/organization";
import Groups, {
  GroupsDOMProps,
  GroupsProps as _GroupsProps,
  GroupsModalVisibilityProps
} from "./groups-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../../util/typeUtil";
import { listOrganizationGroups } from "../../../actions";
import { ActionSender } from "../../../model/ActionSender";
import AuthenticationUtils from "../../../utils/authentication";
import { addSenderArgument } from "../../../actions/actionHelpers";

export { ModalKeys } from "./groups-container";

export interface GroupsProps
  extends GroupsDOMProps,
    GroupsModalVisibilityProps {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_GroupsProps, GroupsProps>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<_GroupsProps, GroupsProps>;

const sender: ActionSender = { key: "groups" };

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    userName: AuthenticationUtils.selectAuthenticationUserDisplayName(state),
    userId: AuthenticationUtils.selectAuthenticationUserId(state),
    groups: OrganizationUtils.selectOrganizationGroups(state),
    employeesGroupId: OrganizationUtils.selectOrganizationEmployeesGroupId(
      state
    )
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    AuthenticationUtils.compareAuthenticationUserDisplayNameStates(
      next,
      prev
    ) &&
    AuthenticationUtils.compareAuthenticationUserIdStates(next, prev) &&
    OrganizationUtils.compareOrganizationGroupsStates(next, prev)
  );
}
const dispatchActions: ReduxDispatchProps = {
  onLoadGroups: addSenderArgument(sender, listOrganizationGroups)
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  GroupsProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual
})(Groups);
