import { FormattedMessage } from "react-intl";
import { IconLibrary, ProgressIndicator, Button } from "@10duke/dukeui";
import "./no-data-indicator-view.scss";
import {TableFilterInternal} from "../filter-tools/filter-tools-view";

export interface NoDataIndicatorProps<D> {
  data?: D[];
  disableLoadingIndicator?: boolean;
  loadFailed?: boolean;
  search?: boolean;
  activeSearch?: string;
  onSearch?: (s: string) => void;
  filters?: TableFilterInternal[] | undefined;
}

export default function NoDataIndicator<D>(props: NoDataIndicatorProps<D>) {
  const {
    data,
    disableLoadingIndicator,
    loadFailed,
    search,
    activeSearch,
    onSearch,
    filters,
  } = props;
  const hasSearch = !!(search && activeSearch && activeSearch.length);
  const hasFilter = !!(filters && filters.filter((f) => f.isActive === true).length)
  return (
    <div className={"no-data-indicator-cell"}>
      {data === undefined && !disableLoadingIndicator && !loadFailed ? (
        <ProgressIndicator
            data-test-table-loading
            show={true}
            variant={'lg'}
            backdrop={false}
        />
      ) : undefined}
      {data !== undefined && !loadFailed && (
        <div data-test-table-empty-alert>
          {(!hasSearch && !hasFilter) && (
            <h4>
              <div className={"icon"}>{IconLibrary.customIcons.noData}</div>
              <div>
                <FormattedMessage
                  defaultMessage="No results to show."
                  description="message to be shown when the table is empty and there is no active search/filter"
                />
              </div>
            </h4>
          )}
          {(hasSearch || hasFilter) && (
            <>
              <h4>
                <div className={"icon"}>
                  {IconLibrary.customIcons.noDataMatch}
                </div>
                <div>
                  <FormattedMessage
                    defaultMessage="No matching results to show"
                    description="title to be shown when the table is empty and there is an active search/filter"
                  />
                </div>
              </h4>
              <p>
                {hasSearch && (
                <Button
                  type="button"
                  className={"btn custom-base"}
                  variant={"primary"}
                  action={() => {
                    if (onSearch) {
                      onSearch("");
                    }
                  }}
                >
                  <FormattedMessage
                    defaultMessage="Clear active search"
                    description="clear search button label to be shown when the table is empty and there is an active search/filter"
                  />
                </Button>
                    )}
                {hasFilter && (
                    <Button
                        type="button"
                        variant={"primary"}
                        className={"btn custom-base"}
                        action={() => {
                          if (filters) {
                            filters.forEach((f) => {
                              if (f.isActive) {
                                f.handler(false);
                              }
                            })
                          }
                        }}
                    >
                      <FormattedMessage
                          defaultMessage="Clear filters"
                          description="clear filters button label to be shown when the table is empty and there is an active filter"
                      />
                    </Button>
                )}
              </p>
            </>
          )}
        </div>
      )}
      {data === undefined && loadFailed && !disableLoadingIndicator && (
        <>
          <h4 className={"loadFailed"}>
            <div className={"icon"}>{IconLibrary.customIcons.noDataAccess}</div>
            <div>
              <FormattedMessage
                defaultMessage="Loading failed"
                description="message to be shown when the table is empty and there's been a loading error"
              />
            </div>
          </h4>
          <p className={"loadFailed"}>
            <FormattedMessage
              defaultMessage="Please try again, if the problem persists contact your administrator."
              description="Message to be shown when the table is empty and there's been a loading error. The administrator here is not the same as the 'admin' used elsewhere, different translation is preferred. This refers to a person responsible for deploying and managing this application."
            />
          </p>
        </>
      )}
    </div>
  );
}
