import * as ActionTypes from "../actions/actionTypes";
import { User } from "../model/User";
import { UsersById } from "../store/UserState";
import UserUtils from "../utils/user";
import {
  removeFromStateById,
  removeUsersFromState,
} from "../util/ReducerUtils";

export default function users(
  state: UsersById,
  action: ActionTypes.AppAction
): UsersById | null {
  const currentState = state || ({} as UsersById);
  switch (action.type) {
    case ActionTypes.LIST_ORG_USERS:
      const listOrgUsersCompleted = action as ActionTypes.ListOrgUsersAction;
      return merge(currentState, listOrgUsersCompleted.users);
    case ActionTypes.LIST_USERS_IN_ORG_GROUP:
      const listUsersInOrgGroup =
        action as ActionTypes.ListUsersInOrgGroupAction;
      return merge(currentState, listUsersInOrgGroup.users);
    case ActionTypes.IMPORT_USERS:
      const importUsersAction = action as ActionTypes.ImportUsersAction;
      return merge(currentState, importUsersAction.users);
    case ActionTypes.IMPORT_ORGANIZATION_USERS:
      const importOrganizationUsersAction =
        action as ActionTypes.ImportOrganizationUsersAction;
      const usersWithOutGroupIds = importOrganizationUsersAction.users.map(
        (userWithGroupIds) => UserUtils.transformToUser(userWithGroupIds)
      );
      return merge(currentState, usersWithOutGroupIds);
    case ActionTypes.GET_USER:
      const getUser = action as ActionTypes.GetUserAction;
      return { ...currentState, [getUser.user.id as string]: getUser.user };

    case ActionTypes.DELETE_USER:
    case ActionTypes.REMOVE_USER_FROM_ORG:
      return removeUsersFromState<UsersById>(action, currentState);

    case ActionTypes.SET_USER_SUSPENDED:
      const setUserSuspended = action as ActionTypes.SetUserSuspendedAction;
      return {
        ...currentState,
        [setUserSuspended.user.id as string]: setUserSuspended.user,
      };
    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

function merge(currentState: UsersById, users: User[]): UsersById {
  const newUsersById = users.reduce<UsersById>((map, user) => {
    map[user.id as string] = user;
    return map;
  }, {});
  return { ...currentState, ...newUsersById };
}
function handleErrorAction(
  currentState: UsersById,
  action: ActionTypes.AppAction
): UsersById {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  if (
    errorAction.error.action.type === ActionTypes.GET_USER &&
    errorAction.error.apiError.error === "404"
  ) {
    const typedError =
      action as ActionTypes.AddErrorAction<ActionTypes.GetUserAction>;
    finalState = removeFromStateById<UsersById>(
      [typedError.error.action?.userId as string],
      currentState
    );
  }

  return finalState;
}
