import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {IconLibrary} from "@10duke/dukeui";

export interface SortCaretProps {
  onSort: (d: string, asc: boolean) => void;
  order: "asc" | "desc" | undefined;
  field: string;
}

export default function SortCaret(props: SortCaretProps) {
  const { onSort, field, order } = props;
  return (
    <span
      className={"duke-sort fa-layers fa-fw"}
      onClick={(e) => {
        onSort(field, order ? order !== "asc" : false);
        e.stopPropagation();
      }}
    >
      <FontAwesomeIcon icon={IconLibrary.icons.faSort}></FontAwesomeIcon>
      {order === "asc" && (
        <FontAwesomeIcon
          icon={IconLibrary.icons.faSortUp}
          className={"active"}
        ></FontAwesomeIcon>
      )}
      {order === "desc" && (
        <FontAwesomeIcon
          icon={IconLibrary.icons.faSortDown}
          className={"active"}
        ></FontAwesomeIcon>
      )}
    </span>
  );
}
