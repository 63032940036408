import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {ClientGroup} from "../model/ClientGroup";
import {
  buildActionThunk,
  ensureSelectedOrgId,
  forceUndefined,
} from "./actionHelpers";
import {
  RemoveClientGroupsOfClientResult
} from "../api/IdpApi";

/**
 * Lists organization client groups assigned to a client.
 * @param sender
 * @param clientId
 * @param organizationId
 */
export function listClientGroupsOfClient(
  sender: ActionSender,
  clientId: string,
  organizationId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListClientGroupsOfClientAction> {
  const orgId = organizationId || ensureSelectedOrgId();
  return buildActionThunk<
    ActionTypes.ListClientGroupsOfClientAction,
    ClientGroup[]
  >(
    sender,
    ActionTypes.LIST_CLIENT_GROUPS_OF_CLIENT,
    async () => await idpApi.listClientGroupsOfClient(clientId, orgId),
    (type, groups) => ({
      type,
      groups: forceUndefined(groups),
      clientId,
    })
  );
}

/**
 * Adds client groups for a client
 * @param sender
 * @param clientGroupIds
 * @param clientId
 */
export function addClientGroupsForClient(
  sender: ActionSender,
  clientGroupIds: string[],
  clientId: string
): ActionTypes.AppThunkAction<ActionTypes.AddClientGroupsForClientAction> {
  return buildActionThunk<ActionTypes.AddClientGroupsForClientAction, void>(
    sender,
    ActionTypes.ADD_CLIENT_GROUPS_FOR_CLIENT,
    async () => await idpApi.addClientGroupsForClient(clientGroupIds, clientId),
    (type) => ({
      type,
      clientGroupIds,
      clientId,
    })
  );
}

/**
 * Removes client groups of client
 * @param sender
 * @param clientGroupIds
 * @param clientId
 */
export function removeClientGroupsOfClient(
  sender: ActionSender,
  clientGroupIds: string[],
  clientId: string
): ActionTypes.AppThunkAction<ActionTypes.RemoveClientGroupsOfClientAction> {
  const orgId = ensureSelectedOrgId();
  return buildActionThunk<
    ActionTypes.RemoveClientGroupsOfClientAction,
      RemoveClientGroupsOfClientResult // no idea what this should be
  >(
    sender,
    ActionTypes.REMOVE_CLIENT_GROUPS_OF_CLIENT,
    async () =>
      await idpApi.removeClientGroupsOfClient(clientGroupIds, clientId),
    (type, data) => ({
      type,
      clientGroupIds,
      clientId,
      orgId,
    })
  );
}
