import { Jose } from "jose-jwe-jws";
import AppGatekeeperAuthenticatorBase from "./AppGatekeeperAuthenticatorBase";
import _debug from "debug";
import { XWindowEvents } from "@10duke/dukeui";
import { IdTokenFields, StartLoginResponse, Authentication as AuthenticationResponse, AccessTokenResponse } from "@10duke/web-client-pkce";
import { generateRandomString } from "./random";
import { getMockSignerPrivateKey, getMockSignerPublicKey } from "./mockKeys";

const debug = _debug("AppGatekeeperAuthenticator");

export default class AppGatekeeperAuthenticator extends AppGatekeeperAuthenticatorBase {
  private jwskUri: URL;
  private mock: boolean;

  /**
   * Initializes a new instance of the authenticator.
   * @param authnUri URL of identity provider endpoint for authentication.
   * @param tokenUri URL of identity provider token endpoint.
   * @param sloUri URL of identity provider endpoint for single logout (with 10Duke custom SLO protocol).
   * @param clientId OAuth client id used by this application when communicating with the IdP.
   * @param jwksUri URL of identity provider endpoint for JWKS key service.
   * @param redirectUri OAuth redirect_uri for redirecting back to this application from the IdP.
   * @param logoutPath Local route path of this application for logout requests and logout
   *    responses from the IdP.
   * @param mock If true, use mock authentication instead of real authentication.
   * @param idTokenIatLeeway Maximum allowed leeway when checking validity of ID token iat (issued at) claim.
   */
  public constructor(
    authnUri: URL,
    tokenUri: URL,
    sloUri: URL,
    clientId: string,
    jwksUri: URL,
    redirectUri: URL,
    logoutPath: string,
    mock: boolean = false,
    idTokenIatLeeway: number = AppGatekeeperAuthenticatorBase.DEFAULT_ID_TOKEN_IAT_LEEWAY
  ) {
    super(
      authnUri,
      tokenUri,
      sloUri,
      clientId,
      jwksUri,
      redirectUri,
      logoutPath,
      idTokenIatLeeway
    );
    this.jwskUri = jwksUri;
    this.mock = mock;
  }

  protected onLogoutHandled(): void {
    XWindowEvents.fireLogout('orgadmin');
  }

  protected async completeAuthentication(
      startLoginResponse: StartLoginResponse,
      code: string,
      state?: string): Promise<AuthenticationResponse> {
    if (!this.mock) {
      return await super.completeAuthentication(startLoginResponse, code, state);
    }

    debug("completing mock authentication by creating a mock access token response");
    return await this.buildMockAccessTokenResponse(startLoginResponse, code, state);
  }

  protected async sendAuthenticationRequest(authnUrl: URL): Promise<void> {
    if (!this.mock) {
      return await super.sendAuthenticationRequest(authnUrl);
    }

    debug(
      "mock authentication, do not start authn but return mock authn response instead"
    );
    const authnResponseUrl = await this.buildMockAuthnResponseUrl(authnUrl);
    const urlStr = authnResponseUrl.toString();
    debug("send mock authn response by redirecting to %s", urlStr);
    window.location.assign(urlStr);
    if (
      urlStr === this.getCurrentUrlWithoutAuthzCodeParams()
    ) {
      debug("reloading required for sending mock authn response");
      window.location.reload();
    }
  }

  private static getIdpIssuerId(authnUrl: URL): string {
    return `${authnUrl.protocol}//${authnUrl.host}`;
  }

  private async buildMockAuthnResponseUrl(authnUrl: URL): Promise<string> {
    const authnParams = authnUrl.searchParams;
    let authnResponseUrl = new URL(authnParams.get("redirect_uri") as string);

    const code = generateRandomString(32);
    const state = authnParams.get("state");

    const responseParams = new URLSearchParams();
    responseParams.append("code", code);
    if (state) {
      responseParams.append("state", state);
    }

    return `${authnResponseUrl}?${responseParams.toString()}`;
  }

  private async buildMockAccessTokenResponse(
      startLoginResponse: StartLoginResponse,
      code: string,
      state?: string): Promise<AuthenticationResponse> {
    const accessToken = generateRandomString(32);
    const idToken = await this.buildMockIdToken(
      startLoginResponse,
      accessToken);
    // Verify id token as it would be verified in real authentication
    const idTokenPayload = (await this.verifyIdToken(idToken)).payload;
    const idTokenFields = JSON.parse(idTokenPayload) as IdTokenFields;
    const accessTokenResponse: AccessTokenResponse = {
      access_token: accessToken,
      token_type: "Bearer",
      expires_in: 31536000,
      id_token: idToken,
    };

    return new AuthenticationResponse(accessTokenResponse, idTokenFields, state);
  }

  /**
   * Verifies signature of the received OpenID Connect ID token.
   * @param idToken The received ID token as a string.
   * @returns Verification result for successfully verified ID token.
   *    "payload" field contains the ID token payload as a JSON string.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private async verifyIdToken(idToken: string): Promise<any> {
    const cryptographer = new Jose.WebCryptographer();
    cryptographer.setContentSignAlgorithm("RS256");
    const signerPubKey = await getMockSignerPublicKey();
    const verifier = new Jose.JoseJWS.Verifier(
      cryptographer,
      idToken,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      (keyId) => new Promise<CryptoKey>((resolve) => resolve(signerPubKey))
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let verificationResults: any = undefined;
    try {
      verificationResults = await verifier.verify();
    } catch (err) {
      throw new Error(
        "ID token signature verification failed"
      );
    }
    debug("ID token verification results: %o", verificationResults);
    const verificationResult = verificationResults[0];
    if (
      !verificationResult.verified ||
      verificationResult.payload === undefined
    ) {
      throw new Error(
        "signature verification failed"
      );
    }

    // js-jose handles UTF-8 input incorrectly, fix encoding
    verificationResult.payload = decodeURIComponent(
      verificationResult.payload
        .split("")
        .map(
          (c: string) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
        )
        .join("")
    );

    return verificationResult;
  }

  private async buildMockIdToken(
    startLoginResponse: StartLoginResponse,
    accessToken: string
  ): Promise<string> {
    const payload = await this.buildMockIdTokenPayload(startLoginResponse, accessToken);
    const key = await getMockSignerPrivateKey();
    const cryptographer = new Jose.WebCryptographer();
    cryptographer.setContentSignAlgorithm("RS256");
    const signer = new Jose.JoseJWS.Signer(cryptographer);
    await signer.addSigner(key, "idp");
    const signResult = await signer.sign(payload);
    return signResult.CompactSerialize();
  }

  private async buildMockIdTokenPayload(
    startLoginResponse: StartLoginResponse,
    accessToken: string
  ): Promise<IdTokenFields> {
    const now = new Date();
    const expiresInMillis = 3600000;

    const iss = AppGatekeeperAuthenticator.getIdpIssuerId(startLoginResponse.url);
    const sub = "6d862e41-cbc9-4ad5-8951-7d26b0143e79";
    const aud = this.getClientId();
    const exp = Math.floor((now.getTime() + expiresInMillis) / 1000);
    const iat = Math.floor(now.getTime() / 1000);
    const nonce = startLoginResponse.nonce;
    // const at_hash = oidcTokenHash.generate(accessToken, "RS256");
    const expectedHash = await this.digestMessage(accessToken);
    const at_hash = this.arrayBufferToBase64(expectedHash.slice(0, 16))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "");

    const name = "April S. Workman";
    const given_name = "April";
    const family_name = "Workman";
    const email = "AprilSWorkman@acme.inc";
    const email_verified = true;

    return {
      iss,
      sub,
      aud,
      exp,
      iat,
      nonce,
      at_hash,
      name,
      given_name,
      family_name,
      email,
      email_verified,
    };
  }

  private async digestMessage(message: string) {
    const encoder = new TextEncoder();
    const data = encoder.encode(message);
    const hash = await crypto.subtle.digest("SHA-256", data);
    return hash;
  }

  private arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
}
