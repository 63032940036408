import { connect } from "react-redux";
import { AppState } from "../../../store/AppState";
import LicensesView, {
  LicensesDOMProps,
  LicensesModalVisibilityProps,
  LicensesProps as _LicensesProps
} from "./licenses-container";
import { listOrganizationEntitlementsAndEntitlementLicensesWithUsage } from "../../../actions/entActions";
import OrganizationUtils from "../../../utils/organization";
import {
  aggregateValidSeatCountCreditDataForLicense,
  aggregateValidUseCountCreditDataForLicense,
  aggregateValidUseTimeCreditDataForLicense
} from "../../../util/licenseUtil";
import {
  PickReduxDispatchProps,
  PickReduxStateProps
} from "../../../util/typeUtil";
import { Entitlement } from "../../../model/entitlement/Entitlement";
import { LicenseWithCreditsAndEntitlementName } from "./licenses-view";
import { ActionSender } from "../../../model/ActionSender";
import LicenseUtils from "../../../utils/license";
import { addSenderArgument } from "../../../actions/actionHelpers";

export { ModalKeys } from "./licenses-container";

interface LicensesProps
  extends LicensesDOMProps,
    LicensesModalVisibilityProps {}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<_LicensesProps, LicensesProps>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<_LicensesProps, LicensesProps>;

const sender: ActionSender = { key: "licensesPage" };

function mapStateToProps(state: AppState): ReduxStateProps {
  const orgLicenses = OrganizationUtils.selectOrganizationLicenses(state);
  const entitlements:
    | Entitlement[]
    | undefined = OrganizationUtils.selectOrganizationEntitlements(state);
  let orgLicensesWithAnotherType:
    | LicenseWithCreditsAndEntitlementName[]
    | undefined;
  if (!!entitlements && !!orgLicenses) {
    orgLicensesWithAnotherType = orgLicenses.map(lic => {
      let l = aggregateValidSeatCountCreditDataForLicense(lic);
      l = aggregateValidUseCountCreditDataForLicense(l);
      l = aggregateValidUseTimeCreditDataForLicense(l);
      if (entitlements && l.entitlementId) {
        const ent = entitlements.find(v => v.id === l.entitlementId);
        (l as LicenseWithCreditsAndEntitlementName).entitlementName = ent
          ? ent.name
          : undefined;
      } else {
        (l as LicenseWithCreditsAndEntitlementName).entitlementName = undefined;
      }
      const creditTypes = [];
      if (l.effectiveLicenseModel) {
        if (l.effectiveLicenseModel.credits?.type === "seatCount") {
          creditTypes.push("seat");
        }
        if (l.effectiveLicenseModel.credits?.type === "useCount") {
          creditTypes.push("count");
        }
        if (l.effectiveLicenseModel.credits?.type === "useTime") {
          creditTypes.push("time");
        }
      }
      if (creditTypes.length === 0) {
        creditTypes.push("seat");
      }
      (l as LicenseWithCreditsAndEntitlementName).creditTypes = creditTypes;
      return l;
    }) as LicenseWithCreditsAndEntitlementName[];
  }
  return {
    licenses: orgLicensesWithAnotherType
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    OrganizationUtils.compareOrganizationEntitlementsStates(next, prev) &&
    OrganizationUtils.compareOrganizationLicenseStates(next, prev) &&
    LicenseUtils.compareLicenseUsageAndAssignmentsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadLicenses: addSenderArgument(
    sender,
    listOrganizationEntitlementsAndEntitlementLicensesWithUsage
  )
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  LicensesProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual
})(LicensesView);
