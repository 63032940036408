import { AppState } from "../../store/AppState";
import { connect } from "react-redux";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../util/typeUtil";
import ActionErrorNotification, {
  ActionErrorNotificationProps as _ActionErrorNotificationProps,
} from "./action-error-notification-container";
import { clearError } from "../../actions";

interface ActionErrorNotificationProps {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ActionErrorNotificationProps,
  ActionErrorNotificationProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ActionErrorNotificationProps,
  ActionErrorNotificationProps
>;

// const sender: ActionSender = { key: "index" };

function mapStateToProps(state: AppState): ReduxStateProps {
  return {
    errors: state?.errors,
  };
}
function areStatesEqual(next: AppState, prev: AppState): boolean {
  return next.errors === prev.errors;
}

const dispatchActions: ReduxDispatchProps = {
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ActionErrorNotificationProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ActionErrorNotification);
