import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {
  buildActionThunk,
  ensureSelectedOrgId,
  forceUndefined,
} from "./actionHelpers";
import { OrganizationGroup } from "../model/OrganizationGroup";
import { DeleteOrganizationGroupResult } from "../api/IdpApi";

/**
 * Starts loading data of all groups of an organization.
 * @param sender Component requesting for the action
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function listOrganizationGroups(
  sender: ActionSender,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListOrgGroupsAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ListOrgGroupsAction, OrganizationGroup[]>(
    sender,
    ActionTypes.LIST_ORG_GROUPS,
    async () => await idpApi.listOrganizationGroups(orgIdOrDefault),
    (type, groups) => ({
      type,
      groups: forceUndefined(groups),
      orgId: orgIdOrDefault,
    })
  );
}

/**
 * Creates a new organization group.
 * @param sender Component requesting for the action
 * @param orgGroup Data of the organization group to create
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function createOrganizationGroup(
  sender: ActionSender,
  orgGroup: OrganizationGroup,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.CreateOrgGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.CreateOrgGroupAction, OrganizationGroup>(
    sender,
    ActionTypes.CREATE_ORG_GROUP,
    async () => await idpApi.createOrganizationGroup(orgGroup, orgIdOrDefault),
    (type, group) => ({
      type,
      group: forceUndefined(group),
      orgId: orgIdOrDefault,
    })
  );
}

/**
 * Updates organization group data by replacing existing data with the given data.
 * @param sender Component requesting for the action
 * @param orgGroup OrganizationGroup object describing the new organization group data.
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function replaceOrganizationGroup(
  sender: ActionSender,
  orgGroup: OrganizationGroup,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ReplaceOrgGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ReplaceOrgGroupAction, OrganizationGroup>(
    sender,
    ActionTypes.REPLACE_ORG_GROUP,
    async () => await idpApi.replaceOrganizationGroup(orgGroup, orgIdOrDefault),
    (type, group) => ({
      type,
      group: forceUndefined(group),
      orgId: orgIdOrDefault,
    })
  );
}

/**
 * Gets an organization group of an organization by id.
 * @param sender Component requesting for the action
 * @param orgGroupId The organization group id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function getOrganizationGroup(
  sender: ActionSender,
  orgGroupId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.GetOrgGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.GetOrgGroupAction, OrganizationGroup>(
    sender,
    ActionTypes.GET_ORG_GROUP,
    async () => await idpApi.getOrganizationGroup(orgGroupId, orgIdOrDefault),
    (type, group) => ({
      type,
      group: forceUndefined(group),
      orgId: orgIdOrDefault,
      orgGroupId: orgGroupId,
    })
  );
}

/**
 * Deletes an organization group of an organization by id.
 * @param sender Component requesting for the action
 * @param orgGroupId The organization group id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function deleteOrganizationGroup(
  sender: ActionSender,
  orgGroupId: string,
  orgId?: string,
  opts?: {
    cleanUpLicenseReservations?: boolean;
    returnNoMembershipsUserIds?: boolean;
  }
): ActionTypes.AppThunkAction<ActionTypes.DeleteOrgGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.DeleteOrgGroupAction,
    DeleteOrganizationGroupResult
  >(
    sender,
    ActionTypes.DELETE_ORG_GROUP,
    async () =>
      await idpApi.deleteOrganizationGroup(orgGroupId, orgIdOrDefault, opts),
    (type, data) => ({
      type,
      orgGroupId,
      orgId: orgIdOrDefault,
      membershipRemovingResult: forceUndefined(data),
    })
  );
}
