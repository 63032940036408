import { connect } from "react-redux";
import ManageUsersRolesModal, {
  ManageUsersRolesModalProps as _ManageUsersRolesModalProps,
} from "./manage-users-roles-modal-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import OrganizationUtils from "../../../utils/organization";
import {
  addOrganizationRolesForUser,
  clearError,
  getUser,
  listOrganizationRoles,
  listOrganizationRolesOfUser,
  removeOrganizationRolesOfUser,
} from "../../../actions";
import { ManageUsersRolesModalDataProps } from "./manage-users-roles-modal-view";
import InProgressUtils from "../../../utils/in-progress";
import AuthenticationUtils from "../../../utils/authentication";
import UserUtils from "../../../utils/user";
import { addSenderArgument } from "../../../actions/actionHelpers";

export type ManageUsersRolesModalProps = Omit<
  _ManageUsersRolesModalProps,
  keyof ManageUsersRolesModalDataProps
>;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ManageUsersRolesModalProps,
  ManageUsersRolesModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ManageUsersRolesModalProps,
  ManageUsersRolesModalProps
>;

const sender = { key: "manage-users-roles" };

function mapStateToProps(
  state: AppState,
  ownProps: ManageUsersRolesModalProps
): ReduxStateProps {
  const roles = OrganizationUtils.selectOrganizationRoles(state);
  return {
    actorId: AuthenticationUtils.selectAuthenticationUserId(state) as string,
    roles,
    orgAdminRoleId: OrganizationUtils.selectOrgAdminRoleId(state),
    user: ownProps.userId
      ? UserUtils.selectUser(ownProps.userId, state)
      : undefined,
    usersRoles: ownProps.userId
      ? UserUtils.selectUserRoleIds(ownProps.userId, state)
      : undefined,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    AuthenticationUtils.compareAuthenticationUserIdStates(next, prev) &&
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationRolesStates(next, prev) &&
    UserUtils.compareUsersState(next, prev) &&
    UserUtils.compareUserRoleIdsState(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadRoles: addSenderArgument(sender, listOrganizationRoles),
  onLoadUsersRoles: addSenderArgument(sender, listOrganizationRolesOfUser),
  onAddRoles: addSenderArgument(sender, addOrganizationRolesForUser),
  onRemoveRoles: addSenderArgument(sender, removeOrganizationRolesOfUser),
  onLoadUser: addSenderArgument(sender, getUser),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ManageUsersRolesModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ManageUsersRolesModal);
