import {OrganizationGroupInvitation} from "../model/OrganizationGroupInvitation";

export interface OrganizationGroupInvitationWithExpiredStatus extends Omit<OrganizationGroupInvitation, "invitationState"> {
  invitationState?:
      | "accepted"
      | "created"
      | "updated"
      | "declined"
      | "deliveryRequested"
      | "revoked"
      | "expired";
}
function isExpired(inv: OrganizationGroupInvitation) {
  if (
      inv.invitationState !== "accepted" &&
      inv.invitationState !== "declined" &&
      inv.invitationState !== "revoked"
  ) {
    let validUntil;
    const now = new Date().getTime();
    if (inv.validUntil === "now()") {
      validUntil = now;
    } else if (inv.validUntil) {
      validUntil = new Date(inv.validUntil).getTime();
    }
    if (validUntil && validUntil <= now) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}
export function populateExpiredStatus(inv?: OrganizationGroupInvitation) : OrganizationGroupInvitationWithExpiredStatus|undefined {
  if (inv) {
    return {
      ...inv,
      invitationState: isExpired(inv) ? "expired" : inv.invitationState,
    } as OrganizationGroupInvitationWithExpiredStatus;
  } else {
    return undefined;
  }
}
