import { EntApi, ID_ANY } from "../EntApi";
import { ProductItem } from "../../model/entitlement/ProductItem";
import { LicenseOrder } from "../../model/entitlement/LicenseOrder";
import { OrganizationGroup } from "../../model/OrganizationGroup";
import { LicenseFieldOpts } from "../../model/entitlement/LicenseFieldOpts";
import { LicenseWithCredits } from "../../model/entitlement/LicenseWithCredits";
import { LicenseUsage } from "../../model/entitlement/LicenseUsage";
import { LicenseAssignment } from "../../model/entitlement/LicenseAssignment";
import { LicenseSession } from "../../model/entitlement/LicenseSession";
import { QueryAvailableLicensesOpts } from "../../model/entitlement/QueryAvailableLicensesOpts";
import { LicenseWithOwnerAndSingleUserAssignments } from "../../model/entitlement/LicenseWithOwnerAndSingleUserAssignments";
import MockBase from "./MockBase";
import { LicenseUserWithAssignmentsAndSessions } from "../../model/entitlement/LicenseUserWithAssignmentsAndSessions";
import { LicenseAssignmentWithSessions } from "../../model/entitlement/LicenseAssignmentWithSessions";
import { MOCK_DB } from "./mockData";
import { generateRandomUuid } from "@10duke/dukeui";
import { isValid } from "../../util/objectUtil";
import { aggregateValidSeatCountCreditData } from "../../util/licenseUtil";
import { Entitlement } from "../../model/entitlement/Entitlement";
import { ORG_ENTITLEMENTS } from "./mockData/orgEntitlements";
import {
  getEnvParam,
  REACT_APP_MOCK_DATA_DELAY_MAX,
  REACT_APP_MOCK_DATA_DELAY_MIN,
} from "../../util/env";
import { sleep } from "../../util/sleep";
import apiInfo from "../../gen/api/entitlement/info.json";
import {ClientGroup} from "../../model/ClientGroup";

const delayMin: number = parseInt(
  getEnvParam(REACT_APP_MOCK_DATA_DELAY_MIN, "0")
);
const delayMax: number = parseInt(
  getEnvParam(REACT_APP_MOCK_DATA_DELAY_MAX, "0")
);
async function randomDelay() {
  const t = Math.random() * (delayMax - delayMin) + delayMin;
  if (t) {
    await sleep(t);
  }
  return;
}

class MockEnt extends MockBase implements EntApi {
  getApiInfo(): Promise<{ version: string; [key: string]: any }> {
    return new Promise((resolve) => {
      resolve(apiInfo);
    });
  }
  public getProductItems(productId: string): Promise<ProductItem[]> {
    throw new Error("Not implemented by the mock API");
  }
  public listEntitlementConsumingUsers(orgId: string, entId: string): Promise<string[]> {
    throw new Error("Not implemented by the mock API");
  }
  public listEntitlementConsumingClients(orgId: string, entId: string): Promise<string[]> {
    throw new Error("Not implemented by the mock API");
  }


  public fulfillLicenseOrder(
    orgId: string,
    licenseOrder: LicenseOrder
  ): Promise<LicenseOrder> {
    throw new Error("Not implemented by the mock API");
  }

  public changeLicenseOrder(
    orgId: string,
    licenseOrder: LicenseOrder
  ): Promise<LicenseOrder> {
    throw new Error("Not implemented by the mock API");
  }

  listEntitlementsConsumedByCliGroup(orgId: string, groupId: string): Promise<Entitlement[]> {
    throw new Error("Not implemented by the mock API");
  }
  public cancelLicenseOrder(
    orgId: string,
    orderId: string
  ): Promise<LicenseOrder> {
    throw new Error("Not implemented by the mock API");
  }

  public async listLicenseConsumingCliGroups(
      orgId: string,
      entId: string
  ): Promise<ClientGroup[]> {

    throw new Error("Not implemented by the mock API");
  }
  public async listLicenseConsumingOrgGroups(
    orgId: string,
    entId: string
  ): Promise<OrganizationGroup[]> {
    await randomDelay();
    const orgGroupIdsByEntId =
      MOCK_DB.ORG_GROUP_IDS_BY_ORGANIZATION_ID_AND_BY_ENTITLEMENT_ID[orgId];

    if (!orgGroupIdsByEntId) {
      return [];
    }

    const orgGroupIds = orgGroupIdsByEntId[entId] || [];

    return orgGroupIds.map((groupId) => {
      return this.findOrganizationGroup(orgId, groupId);
    });
  }

  public async listEntitlementsConsumedByOrgGroup(
    orgId: string,
    groupId: string
  ): Promise<Entitlement[]> {
    await randomDelay();
    const orgGroupIdsByEntId =
      MOCK_DB.ORG_GROUP_IDS_BY_ORGANIZATION_ID_AND_BY_ENTITLEMENT_ID[orgId];

    if (!orgGroupIdsByEntId) {
      return [];
    }

    const entsConsumedByGroup: string[] = [];
    for (const entId in orgGroupIdsByEntId) {
      const groupIds = orgGroupIdsByEntId[entId];
      if (groupIds.includes(groupId)) {
        entsConsumedByGroup.push(entId);
      }
    }

    return ORG_ENTITLEMENTS[orgId].filter((ent) =>
      entsConsumedByGroup.includes(ent.id as string)
    );
  }

  public async setEntitlementsConsumedByCliGroup(
      orgId: string,
      groupId: string,
      entitlementIds: string[]
  ): Promise<void> {
    throw new Error("Not implemented by the mock API");
  }
  public async setEntitlementsConsumedByOrgGroup(
    orgId: string,
    groupId: string,
    entitlementIds: string[]
  ): Promise<void> {
    await randomDelay();
    let orgGroupIdsByEntId =
      MOCK_DB.ORG_GROUP_IDS_BY_ORGANIZATION_ID_AND_BY_ENTITLEMENT_ID[orgId];

    if (!orgGroupIdsByEntId) {
      orgGroupIdsByEntId = {};
      MOCK_DB.ORG_GROUP_IDS_BY_ORGANIZATION_ID_AND_BY_ENTITLEMENT_ID[orgId] =
        orgGroupIdsByEntId;
    }

    // wipe.
    for (const entId in orgGroupIdsByEntId) {
      const groupIds = orgGroupIdsByEntId[entId];
      if (groupIds.includes(groupId)) {
        groupIds.splice(groupIds.indexOf(groupId), 1);
      }
    }

    // add.
    entitlementIds.forEach((entId) => {
      const groupIds = orgGroupIdsByEntId[entId] || [];
      groupIds.push(groupId);
      orgGroupIdsByEntId[entId] = groupIds;
    });
  }

  private findOrganizationGroup(
    orgId: string,
    groupId: string
  ): OrganizationGroup {
    const groups = MOCK_DB.ALL_GROUPS_BY_ORG_ID[orgId];
    if (!groups) {
      throw this.build404();
    }

    const group = groups.find((group) => group.id === groupId);
    if (!group) {
      throw this.build404();
    }

    return group;
  }

  public setLicenseConsumingOrgGroups(
    orgId: string,
    entId: string,
    groupIds: string[]
  ): Promise<void> {
    throw new Error("Not implemented by the mock API");
  }

  public async addLicenseConsumingOrgGroup(
    orgId: string,
    entId: string,
    groupId: string
  ): Promise<void> {
    await randomDelay();
    let orgGroupIdsByEntId =
      MOCK_DB.ORG_GROUP_IDS_BY_ORGANIZATION_ID_AND_BY_ENTITLEMENT_ID[orgId];
    if (!orgGroupIdsByEntId) {
      orgGroupIdsByEntId = {};
      MOCK_DB.ORG_GROUP_IDS_BY_ORGANIZATION_ID_AND_BY_ENTITLEMENT_ID[orgId] =
        orgGroupIdsByEntId;
    }

    let orgGroupIdsForEnt = orgGroupIdsByEntId[entId];
    if (!orgGroupIdsForEnt) {
      orgGroupIdsForEnt = [];
      orgGroupIdsByEntId[entId] = orgGroupIdsForEnt;
    }

    if (!orgGroupIdsForEnt.includes(groupId)) {
      orgGroupIdsForEnt.push(groupId);
    }
  }

  public async removeLicenseConsumingOrgGroup(
    orgId: string,
    entId: string,
    groupId: string
  ): Promise<void> {
    await randomDelay();
    const orgGroupIdsByEntId =
      MOCK_DB.ORG_GROUP_IDS_BY_ORGANIZATION_ID_AND_BY_ENTITLEMENT_ID[orgId];
    if (!orgGroupIdsByEntId) {
      throw this.build404();
    }

    const orgGroupIdsForEnt = orgGroupIdsByEntId[entId];

    if (!orgGroupIdsForEnt || !orgGroupIdsForEnt.includes(groupId)) {
      throw this.build404();
    }

    orgGroupIdsForEnt.forEach((idOfGroup, index) => {
      if (idOfGroup === groupId) {
        orgGroupIdsForEnt.splice(index, 1);
      }
    });
  }

  public async listEntitlementLicenses(
    orgId: string,
    entId: string,
    licenseFieldOpts?: LicenseFieldOpts
  ): Promise<LicenseWithCredits[]> {
    await randomDelay();
    // licenseFieldOpts currently not supported by the mock implementation
    return this.getLicensesInternal(orgId, entId);
  }

  public async queryLicenseUsage(
    orgId: string,
    entId: string,
    licenseId: string
  ): Promise<LicenseUsage> {
    await randomDelay();
    return this.getLicenseUsageInternal(orgId, licenseId);
  }

  public async getLicenseAssignments(
    orgId: string,
    entId: string,
    licenseId: string,
    userId: string
  ): Promise<LicenseAssignment[]> {
    await randomDelay();
    const orgLicenses = this.getLicensesInternal(orgId, entId);
    return this.getLicenseAssignmentsInternal(
      orgLicenses,
      this.getOrgLicenseUsersByLicenseIdMap(orgId),
      licenseId,
      userId
    );
  }

  public async manageLicenseAssignments(
    orgId: string,
    entId: string,
    licenseId: string,
    userId: string,
    licenseAssignments: LicenseAssignment[]
  ): Promise<LicenseAssignment[]> {
    await randomDelay();
    const orgLicenses = this.getLicensesInternal(orgId, entId);
    return this.manageAssignmentsInternal(
      orgLicenses,
      this.getOrgLicenseUsersByLicenseIdMap(orgId),
      licenseId,
      userId,
      licenseAssignments
    );
  }

  public async queryClientAvailableLicenses(
      clientId: string,
      queryAvailableLicensesOpts?: QueryAvailableLicensesOpts
  ): Promise<LicenseWithOwnerAndSingleUserAssignments[]> {
    throw new Error("Not implemented by the mock API");
  }

  public async manageClientLicenseAssignments(
      orgId: string,
      entId: string,
      licenseId: string,
      clientId: string,
      licenseAssignments: LicenseAssignment[]
  ): Promise<LicenseAssignment[]> {
    throw new Error("Not implemented by the mock API");
  }

  public async queryClientLicenseUsage(clientId: string, { includeEffectiveModel, includeAggregatedLicensedItems }: {
    includeEffectiveModel?: boolean;
    includeAggregatedLicensedItems?: boolean;
  }): Promise<LicenseWithOwnerAndSingleUserAssignments[]> {
    throw new Error("Not implemented by the mock API");
  }

  public async queryAvailableLicenses(
    userId: string,
    queryAvailableLicensesOpts?: QueryAvailableLicensesOpts
  ): Promise<LicenseWithOwnerAndSingleUserAssignments[]> {
    // queryAvailableLicensesOpts is not fully supported by the current mock implementation
    await randomDelay();
    // queryAvailableLicensesOpts currently not supported by the mock implementation

    const definedOrgId = queryAvailableLicensesOpts?.ownerOrganizationId;
    const userOrgIds = this.getOrgIdsByUserId(userId);
    const appliedOrgIds = definedOrgId
      ? userOrgIds.filter((id) => id === definedOrgId)
      : userOrgIds;
    this.emptyTo404(appliedOrgIds);

    const licensesByOrgId: { [orgId: string]: LicenseWithCredits[] } = {};
    for (const userOrgId of appliedOrgIds) {
      licensesByOrgId[userOrgId] = this.getLicensesInternal(userOrgId, "any");
    }

    const retValue: LicenseWithOwnerAndSingleUserAssignments[] = [];
    for (const orgId in licensesByOrgId) {
      const orgLicenses = licensesByOrgId[orgId];
      for (const orgLicense of orgLicenses) {
        const assignments = this.getLicenseAssignmentsInternal(
          [orgLicense],
          this.getOrgLicenseUsersByLicenseIdMap(orgId),
          orgLicense.id as string,
          userId
        );
        const availableLicense: LicenseWithOwnerAndSingleUserAssignments = {
          ...orgLicense,
          assignments,
          owner: { ...this.getOrgById(orgId), objectType: "Organization" },
        };
        retValue.push(availableLicense);
      }
    }

    return retValue;
  }

  public moveLicense(
    orgId: string,
    entId: string,
    licenseId: string,
    destEntId: string,
    licenseFieldOpts?: LicenseFieldOpts
  ): Promise<LicenseWithCredits> {
    throw new Error("Not implemented by the mock API");
  }

  public async listOrganizationEntitlements(
    orgId: string
  ): Promise<Entitlement[]> {
    await randomDelay();
    const entitlements = MOCK_DB.ORG_ENTITLEMENTS[orgId];
    return this.undefinedTo404(entitlements);
  }

  public async getOrganizationEntitlement(
    orgId: string,
    entId: string
  ): Promise<Entitlement> {
    await randomDelay();
    const entitlements = MOCK_DB.ORG_ENTITLEMENTS[orgId];
    const entitlement = entitlements.find((ent) => ent.id === entId);
    if (!entitlement) {
      throw this.build404();
    }
    return entitlement;
  }

  private getLicensesInternal(
    orgId: string,
    entId: string
  ): LicenseWithCredits[] {
    let licenses: LicenseWithCredits[] | undefined =
      MOCK_DB.ORG_LICENSES[orgId];

    this.undefinedTo404(licenses);

    if (
      entId !== ID_ANY &&
      licenses?.findIndex((lic) => entId === lic.entitlementId) === -1
    ) {
      // No entitlement with the given id found
      throw this.build404();
    }

    const foundLicenses = licenses as LicenseWithCredits[];
    return entId === ID_ANY
      ? foundLicenses
      : foundLicenses.filter((lic) => entId === lic.entitlementId);
  }

  private getLicenseUsageInternal(
    orgId: string,
    licenseId: string
  ): LicenseUsage {
    let retValue: LicenseUsage | undefined = this.getOrgLicenseUsageByLicenseId(
      MOCK_DB.ORG_LICENSES[orgId],
      this.getOrgLicenseUsersByLicenseIdMap(orgId),
      licenseId
    );

    this.undefinedTo404(retValue);

    return retValue as LicenseUsage;
  }

  private getOrgLicenseUsageByLicenseId(
    orgLicenses: LicenseWithCredits[],
    licenseUsersByLicenseId: {
      [licenseId: string]: LicenseUserWithAssignmentsAndSessions[];
    },
    licenseId: string
  ): LicenseUsage | undefined {
    const license = orgLicenses.find((lic) => lic.id === licenseId);
    if (license === undefined) {
      return;
    }

    const licenseUsers = licenseUsersByLicenseId[license.id as string];

    return { ...license, users: licenseUsers };
  }

  private getLicenseAssignmentsInternal(
    licenses: LicenseWithCredits[],
    licenseUsersByLicenseId: {
      [licenseId: string]: LicenseUserWithAssignmentsAndSessions[];
    },
    licenseId: string,
    userId: string
  ): LicenseAssignment[] {
    const licenseUsage = this.getOrgLicenseUsageByLicenseId(
      licenses,
      licenseUsersByLicenseId,
      licenseId
    );
    this.undefinedTo404(licenseUsage);
    if (licenseUsage && licenseUsage.users && licenseUsage.users.length) {
      return licenseUsage.users
        .filter(
          (licenseUser) => licenseUser?.id === userId && licenseUser.assignments
        )
        .flatMap(
          (licenseUser) =>
            licenseUser.assignments as LicenseAssignmentWithSessions
        )
        .map((assignmentWithSessions) => {
          const { sessions: _, ...other } = assignmentWithSessions;
          return other;
        });
    }
    return [];
  }

  private manageAssignmentsInternal(
    licenses: LicenseWithCredits[],
    licenseUsersByLicenseId: {
      [licenseId: string]: LicenseUserWithAssignmentsAndSessions[];
    },
    licenseId: string,
    userId: string,
    licenseAssignments: LicenseAssignment[]
  ): LicenseAssignment[] {
    const licenseUsage = this.getOrgLicenseUsageByLicenseId(
      licenses,
      licenseUsersByLicenseId,
      licenseId
    );
    this.undefinedTo404(licenseUsage);
    const { users, index } = this.getUserInternal(userId);
    const user = users[index];

    if (user && user.lastName === "McPhailure") {
      throw this.buildTestError();
    }

    if (licenseAssignments.length !== 1) {
      throw new Error(
        "manageLicenseAssignments must be called with exactly one LicenseAssignment"
      );
    }

    const licenseAssignmentToSet = { ...licenseAssignments[0] };
    if (licenseAssignmentToSet.id) {
      throw new Error(
        "LicenseAssignment.id must not be set when calling manageLicenseAssignments"
      );
    }

    licenseAssignmentToSet.id = generateRandomUuid();
    licenseAssignmentToSet.validFrom =
      licenseAssignmentToSet.validFrom === "now()"
        ? new Date().toISOString()
        : licenseAssignmentToSet.validFrom;
    const foundLicenseUsage = licenseUsage as LicenseUsage;
    const licenseUsers = foundLicenseUsage.users || [];
    let licenseUserIndex = licenseUsers.findIndex(
      (licenseUser) => licenseUser.id === userId
    );
    if (licenseUserIndex !== -1) {
      let licenseUser = licenseUsers[licenseUserIndex];
      if (
        licenseUser.assignments &&
        licenseAssignmentToSet.type === "floating"
      ) {
        licenseUser.assignments = licenseUser.assignments
          .map((ass) => ({ ...ass, type: licenseAssignmentToSet.type }))
          .filter((ass) => ass.sessions && ass.sessions.length > 0);
      }

      if (licenseAssignmentToSet.type !== "floating") {
        const newAssignments = licenseUser.assignments
          ? licenseUser.assignments.filter((ass) => ass.type !== "denied")
          : [];
        const reservedAssignment = newAssignments.find(
          (ass) => ass.type === "reserved"
        );
        if (reservedAssignment) {
          reservedAssignment.type = licenseAssignmentToSet.type;
        } else {
          newAssignments.push(licenseAssignmentToSet);
        }
        licenseUser.assignments = newAssignments;
      }
    } else {
      const newLicenseUser = { ...user, assignments: [licenseAssignmentToSet] };
      licenseUsers.push(newLicenseUser);
    }

    licenseUsersByLicenseId[licenseId] = licenseUsers;

    const license = licenses.find(
      (license) => license.id === licenseId
    ) as LicenseWithCredits;
    this.updateSeatUsageByAssignments(license, licenseUsers);

    return this.getLicenseAssignmentsInternal(
      licenses,
      licenseUsersByLicenseId,
      licenseId,
      userId
    );
  }

  private updateSeatUsageByAssignments(
    license: LicenseWithCredits,
    licenseUsers: LicenseUserWithAssignmentsAndSessions[]
  ): void {
    if (!license.seatCountCredits) {
      return;
    }

    const allAssignments = licenseUsers
      .filter((licUser) => licUser.assignments)
      .map((licUser) => licUser.assignments as LicenseAssignmentWithSessions[])
      .flatMap((ass) => ass);

    const validReservations = allAssignments.filter((ass) =>
      isValidReservation(ass)
    );

    const validSessions = allAssignments
      .filter((ass) => ass.sessions)
      .flatMap((ass) => ass.sessions)
      .filter((session) => session)
      .filter((session) => isValid(session as LicenseSession));

    let seatsTaken: number = 0;
    allAssignments.forEach((ass) => {
      if (isValidReservation(ass)) {
        seatsTaken++;
      } else if (ass.sessions) {
        for (const session of ass.sessions) {
          if (isValid(session)) {
            seatsTaken++;
            break;
          }
        }
      }
    });

    // Aggregate seat count credits which is a ahortcut for the mock implementation,
    // doesn't work correctly if there are multiple credits
    const aggregatedSeatCountCredit = aggregateValidSeatCountCreditData(
      license.seatCountCredits
    );
    aggregatedSeatCountCredit.seatsConsumed = validSessions.length;
    license.seatCountCredits = [aggregatedSeatCountCredit];

    license.seatsTaken = seatsTaken;
    license.seatsReserved = validReservations.length;
  }

  private getOrgLicenseUsersByLicenseIdMap(orgId: string): {
    [licenseId: string]: LicenseUserWithAssignmentsAndSessions[];
  } {
    const retValue = MOCK_DB.ORG_LICENSE_USERS_BY_LICENSE_ID[orgId];
    if (retValue === undefined) {
      throw new Error(`Unknown orgId ${orgId}`);
    }
    return retValue;
  }
}

function isValidReservation(assignment: LicenseAssignment): boolean {
  if (!assignment) {
    return false;
  }

  return isValid(assignment) && assignment.type === "reserved";
}

export default MockEnt;
