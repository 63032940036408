import { ButtonGroup, Form, FormGroup } from "react-bootstrap";
import { IconLibrary, Button } from "@10duke/dukeui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InvitationRecipient } from "./create-invitation-modal-view";
import UserUtils from "../../../utils/user";
import { useIntl } from "react-intl";
import { OrganizationGroupInvitationLabels } from "../../../localization/organization-group-invitation";
import { UseFormRegister } from "react-hook-form";
import { useId } from "react";
export interface RecipientListItemProps {
  item: InvitationRecipient;
  showEdit: boolean;
  onShowEdit: (f: boolean) => void;
  disableEditButtons: boolean;
  onDismissItem: () => void;
  resolveEditFormValidity: (f: string) => boolean | undefined;
  formValues: any;
  register: UseFormRegister<InvitationRecipient>;
  errors: any;
  formIsValid: boolean;
  onApplyItem: () => void;
  isDuplicate: (s: string) => boolean;
  editTip: string;
  dismissTip: string;
}
export default function RecipientListItem(props: RecipientListItemProps) {
  const {
    item,
    showEdit,
    onShowEdit,
    disableEditButtons,
    onDismissItem,
    resolveEditFormValidity,
    formValues,
    register,
    errors,
    formIsValid,
    onApplyItem,
    isDuplicate,
    editTip,
    dismissTip,
  } = props;
  const intl = useIntl();
  const idPrefix = useId();
  return (
    <li className={"recipient-list-item"}>
      {!showEdit && (
        <>
          <span>
            {item.recipientName} ({item.email})
          </span>
          <ButtonGroup size={"sm"}>
            <Button
              data-test-edit-button
              type={"button"}
              disabled={disableEditButtons}
              variant={"primary"}
              className={"btn custom-base"}
              action={() => {
                onShowEdit(true);
              }}
              tooltip={editTip}
              icon={<FontAwesomeIcon icon={IconLibrary.icons.faPen} fixedWidth={true} />}
            />
            <Button
              data-test-dismiss-button
              type={"button"}
              disabled={disableEditButtons}
              variant={"danger"}
              className="btn custom-base"
              action={() => {
                onDismissItem();
                onShowEdit(false);
              }}
              tooltip={dismissTip}
              icon={<FontAwesomeIcon
                  icon={IconLibrary.icons.faTrashAlt}
                  fixedWidth={true}
              />}
            />
          </ButtonGroup>
        </>
      )}
      {showEdit && (
        <>
          <fieldset className={"input-group"}>
            <FormGroup
              data-test-recipient-name
              controlId={idPrefix + "recipientName"}
              className={"input-group-input-wrapper"}
            >
              <Form.Control
                defaultValue={item.recipientName}
                {...{
                  className:
                    (resolveEditFormValidity("recipientName") === true
                      ? "is-valid"
                      : resolveEditFormValidity("recipientName") === false
                      ? "is-invalid"
                      : "") +
                    ((formValues ? !!formValues.recipientName : false)
                      ? " has-value"
                      : "") +
                    " " +
                    "form-control",
                }}
                {...register("recipientName", {
                  required: true,
                })}
              />
              <Form.Label>
                {intl.formatMessage(
                  OrganizationGroupInvitationLabels.recipientName
                )}
              </Form.Label>
              {errors &&
                errors.recipientName &&
                errors.recipientName.type &&
                errors.recipientName.type === "required" && (
                  <Form.Control.Feedback type="invalid">
                    <FontAwesomeIcon
                      icon={IconLibrary.icons.faExclamationCircle}
                      className={"icon"}
                    />
                    <span className={"copy"}>
                      {intl.formatMessage(
                        {
                          defaultMessage: "{recipientName} is required.",
                          description:
                            "Field validation error. 'recipientName' = Field label for the name of the recipient (OrganizationGroupInvitation.recipientName)",
                        },
                        {
                          recipientName: intl.formatMessage(
                            OrganizationGroupInvitationLabels.recipientName
                          ),
                        }
                      )}
                    </span>
                  </Form.Control.Feedback>
                )}
            </FormGroup>
            <FormGroup
              data-test-email
              controlId={idPrefix + "email"}
              className={"input-group-input-wrapper"}
            >
              <Form.Control
                defaultValue={item.email}
                {...{
                  className:
                    (resolveEditFormValidity("email") === true
                      ? "is-valid"
                      : resolveEditFormValidity("email") === false
                      ? "is-invalid"
                      : "") +
                    ((formValues ? !!formValues.email : false)
                      ? " has-value"
                      : "") +
                    " " +
                    "form-control",
                }}
                {...register("email", {
                  required: true,
                  validate: (value: string) => {
                    return !isDuplicate(value);
                  },
                  pattern: {
                    value: UserUtils.EMAIL_VALIDATION_PATTERN,
                    message: intl.formatMessage(
                      {
                        defaultMessage: "{email} is not valid.",
                        description:
                          "Field validation error. 'email' = Field label for invitation recipient email (OrganizationGroupInvitation.email)",
                      },
                      {
                        email: intl.formatMessage(
                          OrganizationGroupInvitationLabels.email
                        ),
                      }
                    ),
                  },
                })}
              />
              <Form.Label>
                {intl.formatMessage(OrganizationGroupInvitationLabels.email)}
              </Form.Label>
              {errors &&
                errors.email &&
                errors.email.type &&
                errors.email.type === "required" && (
                  <Form.Control.Feedback type="invalid">
                    <FontAwesomeIcon
                      icon={IconLibrary.icons.faExclamationCircle}
                      className={"icon"}
                    />
                    <span className={"copy"}>
                      {intl.formatMessage(
                        {
                          defaultMessage: "{email} is required.",
                          description:
                            "Field validation error. 'email' = Field label for invitation recipient email (OrganizationGroupInvitation.email)",
                        },
                        {
                          email: intl.formatMessage(
                            OrganizationGroupInvitationLabels.email
                          ),
                        }
                      )}
                    </span>
                  </Form.Control.Feedback>
                )}
              {errors &&
                errors.email &&
                errors.email.type &&
                errors.email.type === "pattern" && (
                  <Form.Control.Feedback type="invalid">
                    <FontAwesomeIcon
                      icon={IconLibrary.icons.faExclamationCircle}
                      className={"icon"}
                    />
                    <span className={"copy"}>{errors.email.message}</span>
                  </Form.Control.Feedback>
                )}
              {errors &&
                errors.email &&
                errors.email.type &&
                errors.email.type === "validate" && (
                  <Form.Control.Feedback type="invalid">
                    <FontAwesomeIcon
                      icon={IconLibrary.icons.faExclamationCircle}
                      className={"icon"}
                    />
                    <span className={"copy"}>
                      {intl.formatMessage(
                        {
                          defaultMessage: "{email} must be unique.",
                          description:
                            "Field validation error. 'email' = Field label for invitation recipient email (OrganizationGroupInvitation.email)",
                        },
                        {
                          email: intl.formatMessage(
                            OrganizationGroupInvitationLabels.email
                          ),
                        }
                      )}
                    </span>
                  </Form.Control.Feedback>
                )}
            </FormGroup>
            <div
              className={"input-group-append"}
            >
              <Button
                data-test-resolve-button
                type={"button"}
                variant={"primary"}
                className="btn custom-base"
                disabled={!formIsValid}
                action={onApplyItem}
                icon={IconLibrary.customIcons.applyEdit}
                tooltip={
                  formIsValid
                      ? intl.formatMessage({
                        defaultMessage: "Apply",
                        description:
                            "tooltip for recipient list item apply button",
                      })
                      : intl.formatMessage({
                        defaultMessage: "Fix errors to apply",
                        description:
                            "tooltip for disabled recipient list item apply button",
                      })
                }
              />
            </div>
            <div
              className="input-group-append"
            >
              <Button
                data-test-cancel-button
                type={"button"}
                variant={"danger"}
                className="btn custom-base"
                action={() => {
                  onShowEdit(false);
                }}
                icon={IconLibrary.customIcons.cancelEdit}
                tooltip={intl.formatMessage({
                  defaultMessage: "Cancel",
                  description: "tooltip for recipient list item cancel button",
                })}
              />
            </div>
          </fieldset>
        </>
      )}
    </li>
  );
}
