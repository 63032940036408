import {useEffect, HTMLAttributes, useMemo} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {FieldList, IconLibrary, FormInput,FormInputUtils,FeedbackContainerProps, FeedbackEntry, Feedback, Modal} from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import {
  ClearErrorAction, GetOrgClientAction,
  isAddErrorAction, ReplaceClientAction,
} from "../../../actions/actionTypes";
import {Client} from "../../../model/Client";
import {ClientLabels} from "../../../localization/client";
import {Oauth20Client} from "../../../model/Oauth20Client";
import {Oauth20ClientLabels} from "../../../localization/oauth20-client/oauth20-client";
import {useForm} from "react-hook-form";
import {useHistory} from "react-router-dom";
import {Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//<editor-fold desc="Props">


export interface ViewDeviceClientModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface ViewDeviceClientModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  client?: Client;
  onLoadClient: (id: string) => Promise<GetOrgClientAction>;
  onUpdateClient: (client: Client) => Promise<ReplaceClientAction>;
  onClearError: (errorId: string) => ClearErrorAction;

}
export interface ViewDeviceClientModalProps
  extends ViewDeviceClientModalDOMProps,
      Pick<FeedbackContainerProps, "onShowFeedback">,
    ViewDeviceClientModalDataProps,
    Omit<ClosableModalProps, "isReady"> {
  clientId?: string;
  edit?: boolean;
  allowEdit?: boolean;
}
//</editor-fold>

function ViewDeviceClientModal(props: ViewDeviceClientModalProps) {
  //<editor-fold desc="Local variables">
  let {
    client,
    clientId,
    onLoadClient,
    onUpdateClient,
    onClearError,
    show,
    onClose,
    isReady,
    onExited,
    edit,
    allowEdit,
    onShowFeedback,
  } = props;

  const clientObjId = client ? client.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!clientId &&
      (clientObjId === undefined || (!!clientObjId && clientObjId !== clientId)) &&
      !!onLoadClient
    ) {
      onLoadClient(clientId).then((res) => {
        if (!clientObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, clientId, clientObjId, onLoadClient, onClearError]);
  // this is more like a variable than a hook
  const intl = useIntl();

  const defaultValues = useMemo(
      () => ({
        name: client ? client.name : "",
        clientId: client ? (client as Oauth20Client).clientId : "",
      }),
      [client]
  );

  const { register, handleSubmit, formState, reset, watch } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const { errors } = formState;

  const history = useHistory();
  const formValues = watch();
  useEffect(() => {
    if (defaultValues && edit) {
      reset(defaultValues);
    }
  }, [defaultValues, edit, reset]);
  //</editor-fold>

  return (
    <Modal
      onReloadData={() => {
        if ((client || clientId) && onLoadClient) {
          onLoadClient(client ? (client.id as string) : (clientId as string)).then(
            (res) => {
              if (!client && isAddErrorAction(res)) {
                // only clear error if no data exists, leave if previous data is still available and
                // let the api error notification handler show error
                onClearError(res.error?.errorId);
              }
            }
          );
        }
      }}
      onExited={onExited}
      isReady={isReady}
      data-test-view-client-modal
      title={
        !isReady || client
              ? intl.formatMessage(
              {
                    defaultMessage: "{name}: device client details",
                    description: "modal heading. 'name' = name of the client",
                  },
                  {
                    name: client?.name || ''
                  }
                )
              : intl.formatMessage({
                  defaultMessage: "Device client not found",
                  description: "modal heading when device client can't be found",
                })
      }
      show={show}
      onClose={onClose}
      primaryButton={
        client && allowEdit
            ? !edit
                ? {
                  label: intl.formatMessage({
                    defaultMessage: "Edit",
                    description: "primary button label",
                  }),
                }
                : {
                  label: intl.formatMessage({
                    defaultMessage: "Apply",
                    description: "primary button label for edit state",
                  }),
                  disabled:
                      (errors !== undefined &&
                          errors !== null &&
                          Object.keys(errors).length > 0) ||
                      !(formState.isDirty),
                }
            : {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
      }
      onPrimaryAction={
        client && allowEdit
            ? !edit
                ? () => {
                  history.push(
                      "/device-clients/" +
                      (client ? (client.id as string) : "not_possible") +
                      "/edit"
                  );
                }
                : () => {
                  handleSubmit((data) => {
                    const ng: any = {
                      ...client,
                      ...data,
                    };

                    if (onUpdateClient) {
                      onUpdateClient(ng as Client).then((res) => {
                        const feedback: FeedbackEntry[] = []
                        if (!isAddErrorAction(res)) {
                          feedback.push(
                            {
                              id: "clientEditSuccess",
                              msg: intl.formatMessage(
                                  {
                                    defaultMessage: "Device client name {name} updated.",
                                    description:
                                        "success notification for updating device client. 'name' = name of the client",
                                  },
                                  {
                                    name: "<strong>" + ng.name + "</strong>",
                                  }
                              ),
                              autoClose: true,
                              type: "success",
                            },
                          );
                        } else {
                          onClearError(res.error?.errorId);
                          feedback.push({
                            id:
                                "clientEditFailure_" +
                                (client ? (client.id as string) : "not_possible"),
                            msg: intl.formatMessage(
                                {
                                  defaultMessage: "Updating device client {name} failed.",
                                  description:
                                      "failure notification for updating device client client. 'name' = name of the client",
                                },
                                {
                                  name:
                                      "<strong>" +
                                      (!!client ? client.name : ng.name) +
                                      "</strong>",
                                }
                            ),
                            type: "danger",
                          });
                        }
                        onShowFeedback(feedback);
                        onClose();
                      });
                    }
                  })();
                }
            : onClose
      }
      secondaryButton={
        client && allowEdit
            ? !edit
                ? {
                  label: intl.formatMessage({
                    defaultMessage: "Close",
                    description: "close button label",
                  }),
                }
                : {
                  label: intl.formatMessage({
                    defaultMessage: "Cancel",
                    description: "secondary button label",
                  }),
                }
            : undefined
      }
      onSecondaryAction={
        client && allowEdit
            ? !edit
                ? onClose
                : () => {
                  history.push(
                      "/device-clients/" +
                      (client ? (client.id as string) : "not_possible") +
                      "/show"
                  );
                }
            : undefined
      }
    >
      {isReady && !client && (
        <Feedback type={"danger"} show={true} asChild={true}>
          <p>
            <FormattedMessage
              defaultMessage="Something went wrong and the device client could not be loaded. The device client may have been removed or you don't have sufficient access rights."
              description="message to be shown when there is no device client to display"
            />
          </p>
        </Feedback>
      )}
      {(client || !isReady) && !edit && (
        <>
          <FieldList
            asOneLiners={true}
            fields={{
              name: {
                label: intl.formatMessage(ClientLabels.name),
                value: client ? client.name : "",
              },
              clientId: {
                label: intl.formatMessage(Oauth20ClientLabels.clientId),
                value: client ? (client as Oauth20Client).clientId : (
                  ""
                ),
              },
            }}
          />
        </>
      )}
      {(client || !isReady) && edit && (
          <Form noValidate data-test-edit>
            <FormInput
                data-test-view-group-modal-group-name
                label={intl.formatMessage(ClientLabels.name)}
                field="name"
                register={register}
                registerOptions={{
                  required: true,
                }}
                hasValue={!!formValues["name"]}
                resolveValidity={FormInputUtils.validityResolver(formState)}
            >
              {errors &&
                  errors.name &&
                  errors.name.type &&
                  errors.name.type === "required" && (
                      <Form.Control.Feedback type="invalid">
                        <FontAwesomeIcon
                            icon={IconLibrary.icons.faExclamationCircle}
                            className={"icon"}
                        />
                        <span className={"copy"}>
                    {intl.formatMessage(
                        {
                          defaultMessage: "{name} is required.",
                          description:
                              "Field validation error. 'name' = Field label for device client name (Client.name)",
                        },
                        {
                          name: intl.formatMessage(ClientLabels.name),
                        }
                    )}
                  </span>
                      </Form.Control.Feedback>
                  )}
            </FormInput>
            <FormInput
                data-test-view-group-modal-group-type
                label={intl.formatMessage(Oauth20ClientLabels.clientId)}
                field="clientId"
                register={register}
                registerOptions={{}}
                hasValue={!!formValues["clientId"]}
                readOnly={true}
                resolveValidity={FormInputUtils.validityResolver(formState)}
            >
              <Form.Text className={"info-feedback"}>
                <FontAwesomeIcon icon={IconLibrary.icons.faInfoCircle} className={"icon"} />
                <FormattedMessage
                    defaultMessage='The clientId of the client cannot be edited.'
                    description={
                      "field info for disabled client id edit"
                    }
                />
              </Form.Text>
            </FormInput>
          </Form>
      )}
    </Modal>
  );
}

export default ViewDeviceClientModal;
