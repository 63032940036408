import * as ActionTypes from "../actions/actionTypes";
import {ClientClientGroupIdsByClientId} from "../store/ClientState";
import {removeFromStateById} from "../util/ReducerUtils";

export default function clientClientGroupIds(
  state: ClientClientGroupIdsByClientId,
  action: ActionTypes.AppAction
): ClientClientGroupIdsByClientId | null {
  const currentState = state || ({} as ClientClientGroupIdsByClientId);
  switch (action.type) {
    case ActionTypes.LIST_CLIENT_GROUPS_OF_CLIENT:
      const listClientGroupsOfClient =
        action as ActionTypes.ListClientGroupsOfClientAction;
      const clientGroupIds = listClientGroupsOfClient.groups.map(
        (group) => group.id as string
      );
      return { ...currentState, [listClientGroupsOfClient.clientId]: clientGroupIds };
    case ActionTypes.ADD_CLIENTS_TO_CLIENT_GROUP: {
      const addClientsToClientGroup = action as ActionTypes.AddClientsToClientGroupAction;
      return addClientGroups(currentState,
          addClientsToClientGroup.clientIds,
          [addClientsToClientGroup.clientGroupId])
    }
    case ActionTypes.ADD_CLIENT_GROUPS_FOR_CLIENT: {
      const addClientGroupsForClient =
          action as ActionTypes.AddClientGroupsForClientAction;
      return addClientGroups(currentState,
          [addClientGroupsForClient.clientId],
          addClientGroupsForClient.clientGroupIds)
    }
    case ActionTypes.REMOVE_CLIENTS_FROM_CLIENT_GROUP: {
      const removeClientsFromClientGroup =
          action as ActionTypes.RemoveClientsFromClientGroupAction;
      return removeClientGroups(currentState,
          removeClientsFromClientGroup.clientIds,
          [removeClientsFromClientGroup.clientGroupId]);
    }
    case ActionTypes.REMOVE_CLIENT_GROUPS_OF_CLIENT: {
      const removeClientGroupsOfClient =
        action as ActionTypes.RemoveClientGroupsOfClientAction;
      return removeClientGroups(currentState,
          [removeClientGroupsOfClient.clientId],
          removeClientGroupsOfClient.clientGroupIds);
    }
    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

function addClientGroups(currentState: ClientClientGroupIdsByClientId, clientIds: string[], clientGroupIds: string []): ClientClientGroupIdsByClientId {
  const afterAdd: ClientClientGroupIdsByClientId = {};
  clientIds.forEach((cid) => {
    afterAdd[cid] = (currentState[cid] || []).concat(clientGroupIds).filter(
        (clientGroupId, i, arr) => arr.indexOf(clientGroupId) === i
    );
  });
  return {
    ...currentState,
    ...afterAdd,
  };
}
function removeClientGroups(currentState: ClientClientGroupIdsByClientId, clientIds: string[], clientGroupIds: string []): ClientClientGroupIdsByClientId {
  const afterRemove: ClientClientGroupIdsByClientId = {};
  clientIds.forEach((cid) => {
    afterRemove[cid] = (currentState[cid] || []).filter(
        (groupId) => !clientGroupIds.includes(groupId)
    );
  });
  return {
    ...currentState,
    ...afterRemove,
  };
}
function handleErrorAction(
  currentState: ClientClientGroupIdsByClientId,
  action: ActionTypes.AppAction
): ClientClientGroupIdsByClientId {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }
  if (
    errorAction.error.action.type === ActionTypes.GET_ORG_CLIENT_GROUP &&
    errorAction.error.apiError.error === "404"
  ) {
    const typedError =
      action as ActionTypes.AddErrorAction<ActionTypes.GetOrgClientGroupAction>;
    finalState = removeFromStateById(
      [typedError.error.action?.orgClientGroupId as string],
      currentState
    );
  }
  return finalState;
}
