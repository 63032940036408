import { useEffect, useRef, useState } from "react";
import View, {
  DeviceClientLicensesModalProps as _DeviceClientLicensesModalProps,
  DeviceClientLicensesModalDataProps as _DeviceClientLicensesModalDataProps,
  DeviceClientLicensesModalVisibilityProps as _DeviceClientLicensesModalVisibilityProps,
  DeviceClientLicensesModalStateProps,
} from "./device-client-licenses-modal-view";
import { UserLicensedItemAssignments } from "../../../model/entitlement/UserLicensedItemAssignments";
import _ from "lodash";
import { withCloseAfterExited } from "@10duke/dukeui";
import { resolveLicenseValidity } from "../../badges/license-validity-badge";
import { LicenseValidityType } from "../../badges/license-validity-badge/license-validity-badge-view";
import { FeedbackContainerProps,withFeedback } from "@10duke/dukeui";
export type DeviceClientLicensesModalVisibilityProps =
  _DeviceClientLicensesModalVisibilityProps;
export type DeviceClientLicensesModalDataProps = _DeviceClientLicensesModalDataProps;

export type DeviceClientLicensesModalProps = Omit<
  _DeviceClientLicensesModalProps,
  keyof DeviceClientLicensesModalStateProps
>;
const ViewWithCloseAfterExited =
  withCloseAfterExited<_DeviceClientLicensesModalProps>(View);
function DeviceClientLicensesModal(
  props: DeviceClientLicensesModalProps & FeedbackContainerProps
) {
  const { licenses, clientId, show, ...other } = props;

  const licensesRef = useRef(licenses);
  if (!_.isEqual(licensesRef.current, licenses)) {
    licensesRef.current = licenses;
  }
  const licensesRefCurrent = licensesRef.current;
  const [showAll, setShowAll] = useState(false);

  const [activeSearch, onSetActiveSearch] = useState("");
  const [slicenses, setLicenses] = useState<
    UserLicensedItemAssignments[] | undefined
  >(_.cloneDeep(licenses));
  useEffect(() => {
    if (show && clientId) {
      if (showAll) {
        setLicenses(
          licensesRefCurrent ? _.cloneDeep(licensesRefCurrent) : undefined
        );
      } else if (licensesRefCurrent) {
        setLicenses(
          _.cloneDeep(licensesRefCurrent).filter((itm) => {
            if (!showAll && !!itm) {
              const validity =
                itm.assignments &&
                itm.assignments.length &&
                itm.assignments[0] &&
                itm.assignments[0].license
                  ? resolveLicenseValidity(itm.assignments[0].license)
                  : undefined;
              return (
                validity === LicenseValidityType.valid ||
                validity === LicenseValidityType.expiring
              );
            } else {
              return true;
            }
          })
        );
      } else {
        setLicenses(undefined);
      }
    } else {
      setLicenses(undefined);
    }
  }, [show, clientId, showAll, licensesRefCurrent]);
  const onToggleReservation = (id: string) => {
    if (slicenses) {
      const clone = _.cloneDeep(slicenses) as UserLicensedItemAssignments[];
      const ind = clone.findIndex((itm: UserLicensedItemAssignments) => {
        return itm.licenseId === id;
      });
      const itemAssignmentClone = clone[ind].assignments[0];
      const userIsConsuming = clone[ind].isConsuming;

      let isReserve: boolean;
      if (
        itemAssignmentClone.assignments &&
        itemAssignmentClone.assignments.length &&
        itemAssignmentClone.assignments[0] &&
        itemAssignmentClone.assignments[0].id
      ) {
        // real 2nd level assignment
        if (
          itemAssignmentClone.assignments[0].type &&
          itemAssignmentClone.assignments[0].type === "reserved"
        ) {
          isReserve = false;
          itemAssignmentClone.assignments[0].type = "floating";
        } else {
          isReserve = true;
          itemAssignmentClone.assignments[0].type = "reserved";
        }
      } else if (
        itemAssignmentClone.assignments &&
        itemAssignmentClone.assignments.length &&
        itemAssignmentClone.assignments[0].type === "reserved"
      ) {
        // dummy 2nd level, remove checked by removing dummy
        isReserve = false;
        itemAssignmentClone.assignments = [];
      } else {
        // no 2nd level, add checked by adding dummy
        isReserve = true;
        itemAssignmentClone.assignments = [{ type: "reserved" }];
      }
      if (isReserve) {
        if (itemAssignmentClone && itemAssignmentClone.assignments) {
          if (itemAssignmentClone.assignments[0].validFrom) {
            if (
              new Date(
                itemAssignmentClone.assignments[0].validFrom as string
              ).getTime() > new Date().getTime()
            ) {
              itemAssignmentClone.assignments[0].validFrom = "now()";
            }
          } else {
            itemAssignmentClone.assignments[0].validFrom = "now()";
          }
          if (itemAssignmentClone.assignments[0].validUntil) {
            if (
              new Date(
                itemAssignmentClone.assignments[0].validUntil as string
              ).getTime() < new Date().getTime()
            ) {
              itemAssignmentClone.assignments[0].validUntil = undefined;
            }
          }
        }

        // do not modify seat counters for clone if user is consuming selected license.
        if (!userIsConsuming) {
          if (itemAssignmentClone.license.seatsTaken !== undefined) {
            itemAssignmentClone.license.seatsTaken += 1;
          } else {
            // fallback.
            itemAssignmentClone.license.seatsTaken = 1;
          }
        }
      } else {
        // do not modify seat counters for clone if user is consuming selected license.
        if (!userIsConsuming) {
          if (itemAssignmentClone.license.seatsTaken !== undefined) {
            itemAssignmentClone.license.seatsTaken -= 1;
          } else {
            // fallback.
            itemAssignmentClone.license.seatsTaken = 0;
          }
        }
      }

      setLicenses(clone);
    }
  };
  const onToggleDenial = (id: string) => {
    if (slicenses) {
      const clone = _.cloneDeep(slicenses) as UserLicensedItemAssignments[];
      const ind = clone.findIndex((itm: UserLicensedItemAssignments) => {
        return itm.licenseId === id;
      });
      const itemAssignmentClone = clone[ind].assignments[0];
      const userIsConsuming = clone[ind].isConsuming;

      let isDenial;
      let wasReserved = false;
      if (
        itemAssignmentClone.assignments &&
        itemAssignmentClone.assignments.length &&
        itemAssignmentClone.assignments[0] &&
        itemAssignmentClone.assignments[0].id
      ) {
        // real 2nd level assignment
        if (
          itemAssignmentClone.assignments[0].type &&
          itemAssignmentClone.assignments[0].type === "reserved"
        ) {
          wasReserved = true;
        }
        if (
          itemAssignmentClone.assignments[0].type &&
          itemAssignmentClone.assignments[0].type === "denied"
        ) {
          isDenial = false;
          itemAssignmentClone.assignments[0].type = "floating";
        } else {
          isDenial = true;
          itemAssignmentClone.assignments[0].type = "denied";
        }
      } else if (
        itemAssignmentClone.assignments &&
        itemAssignmentClone.assignments.length &&
        itemAssignmentClone.assignments[0].type === "denied"
      ) {
        // dummy 2nd level, remove checked by removing dummy
        wasReserved = true;
        isDenial = false;
        itemAssignmentClone.assignments = [];
      } else {
        // no 2nd level, add checked by adding dummy
        isDenial = true;
        itemAssignmentClone.assignments = [{ type: "denied" }];
      }
      if (isDenial) {
        if (itemAssignmentClone && itemAssignmentClone.assignments) {
          if (itemAssignmentClone.assignments[0].validFrom) {
            if (
              new Date(
                itemAssignmentClone.assignments[0].validFrom as string
              ).getTime() > new Date().getTime()
            ) {
              itemAssignmentClone.assignments[0].validFrom = "now()";
            }
          } else {
            itemAssignmentClone.assignments[0].validFrom = "now()";
          }
          if (itemAssignmentClone.assignments[0].validUntil) {
            if (
              new Date(
                itemAssignmentClone.assignments[0].validUntil as string
              ).getTime() < new Date().getTime()
            ) {
              itemAssignmentClone.assignments[0].validUntil = undefined;
            }
          }
        }

        // do not modify seat counters for clone if user is consuming selected license.
        if (!userIsConsuming && wasReserved) {
          if (itemAssignmentClone.license.seatsTaken !== undefined) {
            itemAssignmentClone.license.seatsTaken -= 1;
          }
        }
      }
      setLicenses(clone);
    }
  };
  const onToggleShowAll = () => {
    setShowAll(!showAll);
  };
  return (
    <ViewWithCloseAfterExited
      {...other}
      activeSearch={activeSearch}
      onSetActiveSearch={onSetActiveSearch}
      licenses={slicenses}
      onToggleReservation={onToggleReservation}
      onToggleDenial={onToggleDenial}
      clientId={clientId}
      show={show}
      licensesChanged={!_.isEqual(slicenses, licenses)}
      showAll={showAll}
      onToggleShowAll={onToggleShowAll}
    />
  );
}
export default withFeedback<DeviceClientLicensesModalProps & FeedbackContainerProps>(
    DeviceClientLicensesModal
);
