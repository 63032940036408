import { connect } from "react-redux";
import ManageRoleMembersModal, {
  ManageRoleMembersModalProps as _ManageRoleMembersModalProps,
} from "./manage-role-members-modal-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import OrganizationUtils from "../../../utils/organization";
import {
  addUsersToOrgRole,
  listOrganizationUsers,
  listUsersInOrganizationRole,
  removeUsersFromOrgRole,
  getOrganizationRole,
  listOrganizationRoles,
  clearError,
} from "../../../actions";
import { ManageRoleMembersModalDataProps } from "./manage-role-members-modal-view";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import AuthenticationUtils from "../../../utils/authentication";
import { addSenderArgument } from "../../../actions/actionHelpers";

export type ManageRoleMembersModalProps = Omit<
  _ManageRoleMembersModalProps,
  keyof ManageRoleMembersModalDataProps
>;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _ManageRoleMembersModalProps,
  ManageRoleMembersModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _ManageRoleMembersModalProps,
  ManageRoleMembersModalProps
>;

const sender: ActionSender = { key: "manage-role-members" };

function mapStateToProps(
  state: AppState,
  ownProps: ManageRoleMembersModalProps
): ReduxStateProps {
  const orgAdmins = OrganizationUtils.selectOrganizationAdminIds(state, false);
  let users = OrganizationUtils.selectOrganizationUsers(state);
  if (users) {
    users = users.map((usr) => {
      const u = !!usr ? { ...usr } : usr;
      (u as any).isOrgAdmin = orgAdmins
        ? orgAdmins.indexOf(u.id as string) !== -1
        : false;
      return u;
    });
  }
  return {
    users,
    roleMembers: OrganizationUtils.selectOrganizationRoleUserIds(
      ownProps.roleId as string,
      state
    ),
    role: OrganizationUtils.selectOrganizationRole(
      ownProps.roleId as string,
      state
    ),
    actorId: AuthenticationUtils.selectAuthenticationUserId(state) as string,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    orgAdminRoleId: OrganizationUtils.selectOrgAdminRoleId(state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    AuthenticationUtils.compareAuthenticationUserIdStates(next, prev) &&
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationRoleUserIdsStates(next, prev) &&
    OrganizationUtils.compareOrganizationRolesStates(next, prev) &&
    OrganizationUtils.compareOrganizationUsersStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadOrgadminRole: addSenderArgument(sender, listOrganizationRoles),
  onLoadUsers: addSenderArgument(sender, listOrganizationUsers),
  onLoadRoleMembers: addSenderArgument(sender, listUsersInOrganizationRole),
  onAddMembers: addSenderArgument(sender, addUsersToOrgRole),
  onRemoveMembers: addSenderArgument(sender, removeUsersFromOrgRole),
  onLoadRole: addSenderArgument(sender, getOrganizationRole),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  ManageRoleMembersModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(ManageRoleMembersModal);
