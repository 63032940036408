import { useIntl } from "react-intl";
import { StatusBadge } from "@10duke/dukeui";
import "./role-type-badge-view.scss";

//<editor-fold desc="Props">
export interface RoleTypeBadgeProps {
  type: "orgadmin" | "role" | string | undefined;
}
//</editor-fold>

function RoleTypeBadge(props: RoleTypeBadgeProps) {
  //<editor-fold desc="Local variables">
  const intl = useIntl();
  let { type } = props;
  const d: any = {};
  if (type === "orgadmin") {
    d.label = intl.formatMessage({
      defaultMessage: "Admin",
      description: "Label for the designated admin role type.",
    });
    d.tip = intl.formatMessage(
      {
        defaultMessage:
          "{value}: Designated organization admin role. Inherits permissions set at system level.",
        description:
          "tooltip content for role type admin. value=role type label",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
      }
    );
    d.badgeStyle = "role orgadmin";
  } else if (type === "role") {
    d.label = intl.formatMessage({
      defaultMessage: "Custom",
      description: "Type label for a custom role.",
    });
    d.tip = intl.formatMessage(
      {
        defaultMessage: "{value}: A custom role.",
        description:
          "tooltip content for role type custom. value=role type label",
      },
      {
        value: <strong key={"value"}>{d.label}</strong>,
      }
    );
    d.badgeStyle = "role basic-role";
  }
  return <StatusBadge item={d} />;
}
export default RoleTypeBadge;
