import * as ActionTypes from "../actions/actionTypes";
import {EntitlementIdsByOrgClientGroupId} from "../store/ClientGroupState";

export default function orgClientGroupEntitlementIds(
  state: EntitlementIdsByOrgClientGroupId,
  action: ActionTypes.AppAction
): EntitlementIdsByOrgClientGroupId | null {
  const currentState = state || ({} as EntitlementIdsByOrgClientGroupId);

  switch (action.type) {
    case ActionTypes.SET_ENTITLEMENTS_CONSUMED_BY_ORG_CLIENT_GROUP: {
      const actionResult = action as ActionTypes.SetEntitlementsConsumedByOrgClientGroup;
      return {
        ...currentState,
        [actionResult.clientGroupId]: [...actionResult.entitlementIds]
      };
    }
    case ActionTypes.LIST_ENTITLEMENTS_CONSUMED_BY_ORG_CLIENT_GROUP: {
      const actionResult = action as ActionTypes.ListEntitlementsConsumedByOrgClientGroup;
      const entitlementIds = actionResult.entitlements.map(
        ent => ent.id as string
      );
      return { ...currentState, [actionResult.clientGroupId]: entitlementIds };
    }
    case ActionTypes.START_AUTHN:
      return null;
    default:
      return state || null;
  }
}
