import { defineMessages } from "react-intl";
import { BaseObjLabels } from "../base-obj";
import { LicenseValues } from "../license";
import {
  UserLicenseAssignmentStatusLabels,
  UserLicenseAssignmentStatusValues
} from "../user-license-assignment-status";

/**
 * Localized labels for "UI Model" UserLicensedItemAssignments fields
 */
export const UserLicensedItemAssignmentsLabels = {
  // Add AuthorEditorFields if needed
  licensedItemId: BaseObjLabels.id,
  licenseId: BaseObjLabels.id,
  ...UserLicenseAssignmentStatusLabels,
  ...defineMessages({
    licensedItemDisplayName: {
      defaultMessage: "license",
      description:
        "Field label for licensed item display name (UserLicensedItemAssignmentsLabels.licensedItemDisplayName)"
    }
  })
};

/**
 * Localized values for LicenseModel fields
 */
export const UserLicensedItemAssignmentsValues = {
  ...UserLicenseAssignmentStatusValues,
  licensedItemDisplayName: LicenseValues.licensedItem.displayName
};
