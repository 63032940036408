import { defineMessages } from "react-intl";
import { BaseObjLabels } from "../base-obj";
import { ValidityFieldsLabels } from "../validity-fields";
import { LicenseModelLabels, LicenseModelValues } from "../license-model";
import { LicensedItemLabels, LicensedItemValues } from "../licensed-item";
import { AggregatedUseCountCreditLabels } from "../aggregated-use-count-credit";
import { AggregatedUseTimeCreditLabels } from "../aggregated-use-time-credit";

/**
 * Localized labels for "UI Model" License fields
 */
export const LicenseLabels = {
  ...BaseObjLabels,
  // Add AuthorEditorFields if needed
  ...ValidityFieldsLabels,
  ...defineMessages({
    // active?: boolean;
    // entitlementId?: string;
    // licenseModelId?: string;
    // licenseModelName?: string;
    // scopeBindings?: LicenseScope[];
    seatsTaken: {
      defaultMessage: "seats: consumed",
      description: "Field label for sum of consumed seats (License.seatsTaken)",
    },
    seatsReserved: {
      defaultMessage: "reserved",
      description:
        "Field label for sum of reserved seats (License.seatsReserved)",
    },
    seatsTotal: {
      defaultMessage: "seats: total",
      description: "Field label for total sum of seats (License.seatsTotal)",
    },
    seatsAvailable: {
      defaultMessage: "seats: available",
      description:
        "Field label for sum of available seats (License.seatsAvailable)",
    },
    validityStatus: {
      defaultMessage: "state",
      description:
        "Field label for licenses validity status (License.validityStatus)",
    },
    entitlementName: {
      defaultMessage: "entitlement",
      description:
        "Field label for the entitlement name (License.entitlementName)",
    },
    creditTypes: {
      defaultMessage: "type",
      description:
        "Field label for the used credit types (License.creditTypes)",
    },
    resolvedAllowedVersions: {
      defaultMessage: "Allowed versions",
      description:
        "Field label for the resolved allowed versions (License.resolvedAllowedVersions)",
    },
  }),
  effectiveLicenseModel: LicenseModelLabels,
  licensedItem: LicensedItemLabels,
  aggregatedUseCountCredits: AggregatedUseCountCreditLabels,
  aggregatedUseTimeCredits: AggregatedUseTimeCreditLabels,
};

/**
 * Localized values for License fields
 */
export const LicenseValues = {
  // no extension needed for now
  validityStatus: defineMessages({
    valid: {
      defaultMessage: "valid",
      description:
        "Label for licenses validity status when the license is valid. i.e. current date is within its validity period (License.validityStatus 'valid')",
    },
    invalid: {
      defaultMessage: "expired",
      description:
        "Label for licenses validity status when the license is invalid (effectively expired, as all other invalid cases are covered elsewhere). i.e. current date is not within its validity period and the status is not 'scheduled' (License.validityStatus 'invalid')",
    },
    expiring: {
      defaultMessage: "expiring",
      description:
        "Label for licenses validity status when the license is about to expire (License.validityStatus 'expiring')",
    },
    scheduled: {
      defaultMessage: "scheduled",
      description:
        "Label for licenses validity status when the license will become valid in the future (License.validityStatus 'scheduled')",
    },
    deactivated: {
      defaultMessage: "deactivated",
      description:
        "Label for licenses validity status when the license is deactivated (License.validityStatus 'deactivated')",
    },
  }),
  effectiveLicenseModel: LicenseModelValues,
  licensedItem: LicensedItemValues,
  seatsTotal: defineMessages({
    undefined: {
      defaultMessage: "Unlimited",
      description:
          "Display value for undefined total seat count. Undefined seats is considered as unlimited/infinite amount of seats"
    }
  }),
  seatsAvailable: defineMessages({
    undefined: {
      defaultMessage: "Unlimited",
      description:
          "Display value for undefined available seat count. Undefined seats is considered as unlimited/infinite amount of seats"
    }
  })
};
