import { Action } from "redux";
import { Organization } from "../model/Organization";
import { AppError } from "../model/AppError";
import { Authentication } from "../model/Authentication";
import { User, UserForCreate } from "../model/User";
import { OrganizationGroup } from "../model/OrganizationGroup";
import { OrganizationRole } from "../model/OrganizationRole";
import { OrganizationGroupInvitation } from "../model/OrganizationGroupInvitation";
import { InternalPermissionWithGrantedActions } from "../model/InternalPermissionWithGrantedActions";
import { PermissionGrantsForPermission } from "../model/PermissionGrantsForPermission";
import { InProgress } from "../model/InProgress";
import { ThunkAction } from "redux-thunk";
import { AppState } from "../store/AppState";
import { LogoutCompleted } from "../model/LogoutCompleted";
import { LicenseFieldOpts } from "../model/entitlement/LicenseFieldOpts";
import { LicenseUsage } from "../model/entitlement/LicenseUsage";
import { LicenseAssignment } from "../model/entitlement/LicenseAssignment";
import { QueryAvailableLicensesOpts } from "../model/entitlement/QueryAvailableLicensesOpts";
import { LicenseWithOwnerAndSingleUserAssignments } from "../model/entitlement/LicenseWithOwnerAndSingleUserAssignments";
import { Entitlement } from "../model/entitlement/Entitlement";
import { DownloadLicenseRequest } from "../model/DownloadLicenseRequest";
import { Permission } from "../model/Permission";
import {
  MembershipRemovingResult,
  OrganizationRemovingResult,
} from "../api/IdpApi";
import {Client} from "../model/Client";
import {ClientGroup} from "../model/ClientGroup";
import {ClientGroupInvitation} from "../model/ClientGroupInvitation";

// base interface for actions of this application
export type AppAction = Action<string>;
export interface RemoveMembershipResult extends AppAction {
  membershipRemovingResult: MembershipRemovingResult;
}
export interface RemoveOrganizationResult extends AppAction {
  userId: string;
  orgId: string;
  organizationRemovingResult: OrganizationRemovingResult;
}

// thunk action type for thunk that returns given action type or AddErrorAction
export type AppThunkAction<A extends AppAction> = ThunkAction<
  Promise<A | AddErrorAction<A>>,
  AppState,
  unknown,
  AppAction
>;

/*
 * action types (used as "type" field value in Redux actions) and interfaces describing each action
 */

// pseudo-action object used for carrying data of multiple actions
export const MULTI_ACTION = "MULTI_ACTION";
export type ActionResult<A extends AppAction> = A | AddErrorAction<A>;
export interface MultiAction extends AppAction {
  // results of the actual actions carried out
  results: ActionResult<AppAction>[];
}

// add error info
export const ADD_ERROR = "ADD_ERROR";
export interface AddErrorAction<A extends AppAction> extends AppAction {
  error: AppError<A>;
}
export function isAddErrorAction<A extends AppAction>(
  obj: any
): obj is AddErrorAction<A> {
  return "type" in obj && obj["type"] === ADD_ERROR;
}

// remove error with the given error id from the application state
export const CLEAR_ERROR = "CLEAR_ERROR";
export interface ClearErrorAction extends AppAction {
  errorId: string;
}

// remove all errors from the application state
export const CLEAR_ALL_ERRORS = "CLEAR_ALL_ERRORS";
export interface ClearAllErrorsAction extends AppAction {}

// add in-progress info
export const ADD_IN_PROGRESS = "ADD_IN_PROGRESS";
export interface AddInProgressAction<A extends AppAction> extends AppAction {
  operation: InProgress<A>;
}

// remove in-progress info with the given operation id from the application state
export const CLEAR_IN_PROGRESS = "CLEAR_IN_PROGRESS";
export interface ClearInProgressAction extends AppAction {
  operationId: string;
}

// remove all in-progress infos from the application state
export const CLEAR_ALL_IN_PROGRESS_INFOS = "CLEAR_ALL_IN_PROGRESS_INFOS";
export interface ClearAllInProgressInfosAction extends AppAction {}

// request clearing the whole redux store, mainly for test purposes
export const CLEAR_ALL = "CLEAR_ALL";
export interface ClearAllAction extends AppAction {}

// start authentication process
export const START_AUTHN = "START_AUTHN";
export interface StartAuthnAction extends AppAction {
    /**
     * URL where the browser must be directed for starting the authentication process.
     */
    url: URL;
    /**
     * PKCE code verifier for securing the authentication process.
     */
    codeVerifier: string;
    /**
     * OpenID Connect nonce used for securing the authentication process.
     */
    nonce: string;
    /**
     * Nonce issued timestamp.
     */
    nonceIssuedAt: number;
}

// set authenticated user
export const SET_AUTHN = "SET_AUTHN";
export interface SetAuthnAction extends AppAction {
  authn: Authentication;
}

// set logout completed info
export const SET_LOGOUT_COMPLETED = "LOGOUT_COMPLETED";
export interface SetLogoutCompletedAction extends AppAction {
  logoutCompleted: LogoutCompleted;
}

// clears states related to user authentication and pending authentication
export const CLEAR_AUTHN_STATUS = "CLEAR_AUTHN_STATUS";
export interface ClearAuthnStatusAction extends AppAction {}

// loading completed for organizations that can managed by the authenticated user
export const LOAD_ORGS = "LOAD_ORGS";
export interface LoadOrgsAction extends AppAction {
  organizations: Organization[];
}

// loading org by id completed
export const GET_ORG = "GET_ORG";
export interface GetOrgAction extends AppAction {
  organization: Organization;
}

// update org by replacing org data with new data completed
export const REPLACE_ORG = "REPLACE_ORG";
export interface ReplaceOrgAction extends AppAction {
  organization: Organization;
}

// setting MFA requirement for org completed
export const SET_MFA_REQUIRED = "SET_MFA_REQUIRED";
export interface SetMfaRequiredAction extends AppAction {
  orgId: string;
  required: boolean;
}

// set organization to manage
export const SET_ORG = "SET_ORG";
export interface SetOrgAction extends AppAction {
  id: string;
}

// loading completed for all members of an organization
export const LIST_ORG_USERS = "LIST_ORG_USERS";
export interface ListOrgUsersAction extends AppAction {
  orgId: string;
  users: User[];
}

// loading completed for all groups of an organization
export const LIST_ORG_GROUPS = "LIST_ORG_GROUPS";
export interface ListOrgGroupsAction extends AppAction {
  orgId: string;
  groups: OrganizationGroup[];
}

// Load all clients of an organization
export const LIST_ORG_CLIENTS = "LIST_ORG_CLIENTS";
export interface ListOrgClientsAction extends AppAction {
  orgId: string;
  clients: Client[];
}

// Load all clients of an organization's client group
export const LIST_ORG_CLIENTS_IN_ORG_CLIENT_GROUP = "LIST_ORG_CLIENTS_IN_ORG_CLIENT_GROUP";
export interface ListOrgClientsInOrgClientGroupAction extends AppAction {
  orgId: string;
  groupId: string;
  clients: Client[];
}

// load all client groups of an organization
export const LIST_ORG_CLIENT_GROUPS = "LIST_ORG_CLIENT_GROUPS";
export interface ListOrgClientGroupsAction extends AppAction {
  orgId: string;
  clientGroups: ClientGroup[];
}

// create organization client group completed
export const CREATE_ORG_CLIENT_GROUP = "CREATE_ORG_CLIENT_GROUP";
export interface CreateOrgClientGroupAction extends AppAction {
  orgId: string;
  clientGroup: ClientGroup;
}

// create organization group completed
export const CREATE_ORG_GROUP = "CREATE_ORG_GROUP";
export interface CreateOrgGroupAction extends AppAction {
  orgId: string;
  group: OrganizationGroup;
}

// update organization group by replacing group data with new data completed
export const REPLACE_ORG_GROUP = "REPLACE_ORG_GROUP";
export interface ReplaceOrgGroupAction extends AppAction {
  orgId: string;
  group: OrganizationGroup;
}

// update organization client group by replacing client group data with new data completed
export const REPLACE_ORG_CLIENT_GROUP = "REPLACE_ORG_CLIENT_GROUP";
export interface ReplaceOrgClientGroupAction extends AppAction {
  orgId: string;
  group: ClientGroup;
}

// get organization group completed
export const GET_ORG_GROUP = "GET_ORG_GROUP";
export interface GetOrgGroupAction extends AppAction {
  orgId: string;
  group: OrganizationGroup;
  orgGroupId: string;
}


// get organization client group completed
export const GET_ORG_CLIENT_GROUP = "GET_ORG_CLIENT_GROUP";
export interface GetOrgClientGroupAction extends AppAction {
  orgId: string;
  group: ClientGroup;
  orgClientGroupId: string;
}

// get user completed
export const GET_ORG_CLIENT = "GET_ORG_CLIENT";
export interface GetOrgClientAction extends AppAction {
  orgId: string;
  clientId: string;
  client: Client;
}

// delete organization group completed
export const DELETE_ORG_GROUP = "DELETE_ORG_GROUP";
export interface DeleteOrgGroupAction extends RemoveMembershipResult {
  orgId: string;
  orgGroupId: string;
}

// delete organization client completed
export const DELETE_ORG_CLIENT = "DELETE_ORG_CLIENT";
export interface DeleteOrgClientAction extends AppAction {
  orgId: string;
  clientId: string;
}

// delete organization client group completed
export const DELETE_ORG_CLIENT_GROUP = "DELETE_ORG_CLIENT_GROUP";
export interface DeleteOrgClientGroupAction extends AppAction {
  orgId: string;
  orgClientGroupId: string;
}

// loading completed for all roles of an organization
export const LIST_ORG_ROLES = "LIST_ORG_ROLES";
export interface ListOrgRolesAction extends AppAction {
  orgId: string;
  roles: OrganizationRole[];
}

// create organization role completed
export const CREATE_ORG_ROLE = "CREATE_ORG_ROLE";
export interface CreateOrgRoleAction extends AppAction {
  orgId: string;
  role: OrganizationRole;
}

// update organization role by replacing role data with new data completed
export const REPLACE_ORG_ROLE = "REPLACE_ORG_ROLE";
export interface ReplaceOrgRoleAction extends AppAction {
  orgId: string;
  role: OrganizationRole;
}

// get organization role completed
export const GET_ORG_ROLE = "GET_ORG_ROLE";
export interface GetOrgRoleAction extends AppAction {
  orgId: string;
  role: OrganizationRole;
}

// delete organization role completed
export const DELETE_ORG_ROLE = "DELETE_ORG_ROLE";
export interface DeleteOrgRoleAction extends AppAction {
  orgId: string;
  orgRoleId: string;
}

// get user completed
export const GET_USER = "GET_USER";
export interface GetUserAction extends AppAction {
  userId: string;
  user: User;
}

// delete user completed
export const DELETE_USER = "DELETE_USER";
export interface DeleteUserAction extends AppAction {
  userId: string;
}

// update user by replacing user data with new data completed
export const REPLACE_USER = "REPLACE_USER";
export interface ReplaceUserAction extends AppAction {
  user: User;
}

// not "org" in naming as there is no org param support
// update client by replacing client data with new data completed
export const REPLACE_CLIENT = "REPLACE_CLIENT";
export interface ReplaceClientAction extends AppAction {
  client: Client;
}

// setting user suspension status completed
export const SET_USER_SUSPENDED = "SET_USER_SUSPENDED";
export interface SetUserSuspendedAction extends AppAction {
  user: User;
  suspended: boolean;
}

// delete user's MFA configuration (TOTP credentials) completed
export const DELETE_OTP_CREDENTIAL = "DELETE_OTP_CREDENTIAL";
export interface DeleteOtpCredentialAction extends AppAction {
  userId: string;
}

// list organization groups in which user is a member completed
export const LIST_ORG_GROUPS_OF_USER = "LIST_ORG_GROUPS_OF_USER";
export interface ListOrgGroupsOfUserAction extends AppAction {
  userId: string;
  groups: OrganizationGroup[];
}

// list organization client groups in which client is a member completed
export const LIST_CLIENT_GROUPS_OF_CLIENT = "LIST_CLIENT_GROUPS_OF_CLIENT";
export interface ListClientGroupsOfClientAction extends AppAction {
  clientId: string;
  groups: ClientGroup[];
}


export const LIST_ORG_ROLES_OF_USER = "LIST_ORG_ROLES_OF_USER";
export interface ListOrgRolesOfUserAction extends AppAction {
  userId: string;
  roles: OrganizationRole[];
}

// add user as member of organization group completed
export const ADD_ORG_GROUP_FOR_USER = "ADD_ORG_GROUP_FOR_USER";
export interface AddOrgGroupForUserAction extends AppAction {
  userId: string;
  orgGroupId: string;
}
export const ADD_ORG_ROLE_FOR_USER = "ADD_ORG_ROLE_FOR_USER";
export interface AddOrgRoleForUserAction extends AppAction {
  userId: string;
  orgRoleId: string;
}

// remove user from being member of organization group completed
export const REMOVE_ORG_GROUP_OF_USER = "REMOVE_ORG_GROUP_OF_USER";
export interface RemoveOrgGroupOfUserAction extends RemoveOrganizationResult {
  userId: string;
  orgGroupId: string;
}

// add organization groups for a user
export const ADD_ORG_GROUPS_FOR_USER = "ADD_ORG_GROUPS_FOR_USER";
export interface AddOrgGroupsForUserAction extends AppAction {
  userId: string;
  orgGroupIds: string[];
}

// add organization client groups for a client
export const ADD_CLIENT_GROUPS_FOR_CLIENT = "ADD_CLIENT_GROUPS_FOR_CLIENT";
export interface AddClientGroupsForClientAction extends AppAction {
  clientId: string;
  clientGroupIds: string[];
}


export const ADD_ORG_ROLES_FOR_USER = "ADD_ORG_ROLES_FOR_USER";
export interface AddOrgRolesForUserAction extends AppAction {
  userId: string;
  orgRoleIds: string[];
}

// set organization groups of a user
export const SET_ORG_GROUPS_OF_USER = "SET_ORG_GROUPS_OF_USER";
export interface SetOrgGroupsOfUserAction extends RemoveOrganizationResult {
  userId: string;
  orgGroupIds: string[];
}

// remove organization groups of a user
export const REMOVE_ORG_GROUPS_OF_USER = "REMOVE_ORG_GROUPS_OF_USER";
export interface RemoveOrgGroupsOfUserAction extends RemoveOrganizationResult {
  userId: string;
  orgGroupIds: string[];
}

// remove client groups of a client
export const REMOVE_CLIENT_GROUPS_OF_CLIENT = "REMOVE_CLIENT_GROUPS_OF_CLIENT";
export interface RemoveClientGroupsOfClientAction extends AppAction {
  clientId: string;
  clientGroupIds: string[];
}

export const REMOVE_ORG_ROLES_OF_USER = "REMOVE_ORG_ROLES_OF_USER";
export interface RemoveOrgRolesOfUserAction extends AppAction {
  userId: string;
  orgRoleIds: string[];
}

// list users who are members of organization group completed
export const LIST_USERS_IN_ORG_GROUP = "LIST_USERS_IN_ORG_GROUP";
export interface ListUsersInOrgGroupAction extends AppAction {
  orgGroupId: string;
  orgId: string;
  users: User[];
}

// add users to organization role completed.
export const ADD_USERS_TO_ORG_ROLE = "ADD_USERS_TO_ORG_ROLE";
export interface AddUsersToOrgRoleAction extends AppAction {
  orgRoleId: string;
  orgId: string;
  userIds: string[];
}

// remove users from organization role completed.
export const REMOVE_USERS_FROM_ORG_ROLE = "REMOVE_USERS_FROM_ORG_ROLE";
export interface RemoveUsersFromOrgRoleAction extends AppAction {
  orgRoleId: string;
  orgId: string;
  userIds: string[];
}

// add user to organization role completed.
export const ADD_USER_TO_ORG_ROLE = "ADD_USER_TO_ORG_ROLE";
export interface AddUserToOrgRoleAction extends AppAction {
  orgRoleId: string;
  orgId: string;
  userId: string;
}

// remove user from organization role completed.
export const REMOVE_USER_FROM_ORG_ROLE = "REMOVE_USER_FROM_ORG_ROLE";
export interface RemoveUserFromOrgRoleAction extends AppAction {
  orgRoleId: string;
  orgId: string;
  userId: string;
}

// add users as members of organization group completed
export const ADD_USERS_TO_ORG_GROUP = "ADD_USERS_TO_ORG_GROUP";
export interface AddUsersToOrgGroupAction extends AppAction {
  orgGroupId: string;
  orgId: string;
  userIds: string[];
}

// add clients as members of organization client group completed
export const ADD_CLIENTS_TO_CLIENT_GROUP = "ADD_CLIENTS_TO_CLIENT_GROUP";
export interface AddClientsToClientGroupAction extends AppAction {
  clientGroupId: string;
  orgId: string;
  clientIds: string[];
}

// remove clients from being members of organization client group completed
export const REMOVE_CLIENTS_FROM_CLIENT_GROUP = "REMOVE_CLIENTS_FROM_CLIENT_GROUP";
export interface RemoveClientsFromClientGroupAction extends AppAction {
  clientGroupId: string;
  orgId: string;
  clientIds: string[];
}

// set users as members of organization group completed
export const SET_USERS_IN_ORG_GROUP = "SET_USERS_IN_ORG_GROUP";
export interface SetUsersInOrgGroupAction
  extends AddUsersToOrgGroupAction,
    RemoveMembershipResult {}

// remove users from being members of organization group completed
export const REMOVE_USERS_FROM_ORG_GROUP = "REMOVE_USERS_FROM_ORG_GROUP";
export interface RemoveUsersFromOrgGroupAction extends RemoveMembershipResult {
  orgGroupId: string;
  orgId: string;
  userIds: string[];
}

// remove user from all groups and roles of organization
export const REMOVE_USER_FROM_ORG = "REMOVE_USER_FROM_ORG";
export interface RemoveUserFromOrgAction extends AppAction {
  userId: string;
  orgId: string;
  orgGroupIds: string[];
  orgRoleIds: string[];
}

// list organization group invitations of an organization completed
export const LIST_ORG_ORG_GROUP_INVITATIONS = "LIST_ORG_ORG_GROUP_INVITATIONS";
export interface ListOrgOrgGroupInvitationsAction extends AppAction {
  orgId: string;
  invitations: OrganizationGroupInvitation[];
}

// list organization client group invitations of an organization completed
export const LIST_ORG_CLIENT_GROUP_INVITATIONS = "LIST_ORG_CLIENT_GROUP_INVITATIONS";
export interface ListOrgClientGroupInvitationsAction extends AppAction {
  orgId: string;
  invitations: ClientGroupInvitation[];
}

// create and send organization client group invitation completed
export const CREATE_AND_SEND_ORG_CLIENT_GROUP_INVITATION = "CREATE_AND_SEND_ORG_CLIENT_GROUP_INVITATION";
export interface CreateAndSendOrgClientGroupInvitationAction extends AppAction {
  orgId: string;
  invitation: ClientGroupInvitation;
}

// get organization group invitation of an organization completed
export const GET_ORG_ORG_GROUP_INVITATION = "GET_ORG_ORG_GROUP_INVITATION";
export interface GetOrgOrgGroupInvitationAction extends AppAction {
  orgId: string;
  invitation: OrganizationGroupInvitation;
}

// get organization client group invitation of an organization completed
export const GET_ORG_CLIENT_GROUP_INVITATION = "GET_ORG_CLIENT_GROUP_INVITATION";
export interface GetOrgClientGroupInvitationAction extends AppAction {
  orgId: string;
  invitation: ClientGroupInvitation;
}

// create organization group invitation completed
export const CREATE_ORG_GROUP_INVITATION = "CREATE_ORG_GROUP_INVITATION";
export interface CreateOrgGroupInvitationAction extends AppAction {
  orgId: string;
  invitation: OrganizationGroupInvitation;
}

// send organization group invitation completed
export const SEND_ORG_GROUP_INVITATION = "SEND_ORG_GROUP_INVITATION";
export interface SendOrgGroupInvitationAction extends AppAction {
  orgId: string;
  invitation: OrganizationGroupInvitation;
}

// send organization client group invitation completed
export const SEND_ORG_CLIENT_GROUP_INVITATION = "SEND_ORG_CLIENT_GROUP_INVITATION";
export interface SendOrgClientGroupInvitationAction extends AppAction {
  orgId: string;
  invitation: ClientGroupInvitation;
}

// remove organization group invitation completed
export const REVOKE_ORG_GROUP_INVITATION = "REVOKE_ORG_GROUP_INVITATION";
export interface RevokeOrgGroupInvitationAction extends AppAction {
  orgId: string;
  invitation: OrganizationGroupInvitation;
}

// revoke organization client group invitation completed
export const REVOKE_ORG_CLIENT_GROUP_INVITATION = "REVOKE_ORG_CLIENT_GROUP_INVITATION";
export interface RevokeOrgClientGroupInvitationAction extends AppAction {
  orgId: string;
  invitation: ClientGroupInvitation;
}

// remove organization group invitation completed
export const DELETE_ORG_GROUP_INVITATION = "DELETE_ORG_GROUP_INVITATION";
export interface DeleteOrgGroupInvitationAction extends AppAction {
  orgId: string;
  invitationId: string;
}

// remove organization client group invitation completed
export const DELETE_ORG_CLIENT_GROUP_INVITATION = "DELETE_ORG_CLIENT_GROUP_INVITATION";
export interface DeleteOrgClientGroupInvitationAction extends AppAction {
  orgId: string;
  invitationId: string;
}

// list organization roles of organization role completed
export const LIST_ORG_ROLES_OF_ORG_ROLE = "LIST_ORG_ROLES_OF_ORG_ROLE";
export interface ListOrganizationRolesOfOrgRoleAction extends AppAction {
  orgId: string;
  orgRoleId: string;
  roles: OrganizationRole[];
}
// set organization roles of organization role completed
export const SET_ORG_ROLES_OF_ORG_ROLE = "SET_ORG_ROLES_OF_ORG_ROLE";
export interface SetOrganizationRolesOfOrgRoleAction extends AppAction {
  orgId: string;
  orgRoleId: string;
  roles: OrganizationRole[];
}

// list permissions of organization role completed
export const LIST_PERMISSIONS_OF_ORG_ROLE = "LIST_PERMISSIONS_OF_ORG_ROLE";
export interface ListPermissionsOfOrgRoleAction extends AppAction {
  orgId: string;
  orgRoleId: string;
  permissions: InternalPermissionWithGrantedActions[];
}

// list permissions
export const LIST_PERMISSIONS = "LIST_PERMISSIONS";
export interface ListPermissions extends AppAction {
  orgId: string;
  permissions: Permission[];
}

// add permissions for organization role completed
export const ADD_PERMISSIONS_FOR_ORG_ROLE = "ADD_PERMISSIONS_FOR_ORG_ROLE";
export interface AddPermissionsForOrgRoleAction extends AppAction {
  orgId: string;
  orgRoleId: string;
  grants: PermissionGrantsForPermission[];
}

// set permissions of organization role completed
export const SET_PERMISSIONS_OF_ORG_ROLE = "SET_PERMISSIONS_OF_ORG_ROLE";
export interface SetPermissionsOfOrgRoleAction extends AppAction {
  orgId: string;
  orgRoleId: string;
  grants: PermissionGrantsForPermission[];
}

// remove permissions of organization role completed
export const REMOVE_PERMISSIONS_OF_ORG_ROLE = "REMOVE_PERMISSIONS_OF_ORG_ROLE";
export interface RemovePermissionsOfOrgRoleAction extends AppAction {
  orgId: string;
  orgRoleId: string;
  permissionIds: string[];
}

// remove a permission of organization role completed
export const REMOVE_PERMISSION_OF_ORG_ROLE = "REMOVE_PERMISSION_OF_ORG_ROLE";
export interface RemovePermissionOfOrgRoleAction extends AppAction {
  orgId: string;
  orgRoleId: string;
  permissionId: string;
}

// List users in organization role
export const LIST_USERS_IN_ORG_ROLE = "LIST_USERS_IN_ORG_ROLE";
export interface ListUsersInOrgRoleAction extends AppAction {
  orgRoleId: string;
  orgId: string;
  users: User[];
}

export interface LicensesAction extends AppAction {
  licenses: LicenseWithOwnerAndSingleUserAssignments[];
}
export const LIST_ENT_CONSUMING_USERS = "LIST_ENT_CONSUMING_USERS";
export interface ListEntConsumingUsersAction extends AppAction {
  entId: string;
  orgId: string;
  userIds: string[];
}
export const LIST_ENT_CONSUMING_CLIENTS = "LIST_ENT_CONSUMING_CLIENTS";
export interface ListEntConsumingClientsAction extends AppAction {
  entId: string;
  orgId: string;
  clientIds: string[];
}

export const LIST_ENT_LICENSES = "LIST_ENT_LICENSES";
export interface ListEntLicensesAction extends LicensesAction {
  entId: string;
  orgId: string;
  licenseFieldOpts?: LicenseFieldOpts;
  // Actual type of licenses is LicenseWithCredits for this action.
  // This action extends LicensesAction for simpler processing.
  // licenses: LicenseWithCredits[];
}

export interface LicenseUsageField {
  licenseUsage?: LicenseUsage;
}

export const QUERY_LICENSE_USAGE = "QUERY_LICENSE_USAGE";
export interface QueryLicenseUsageAction extends AppAction, LicenseUsageField {
  entId: string;
  orgId: string;
  licenseId: string;
  licenseUsage: LicenseUsage;
}

export const QUERY_CLIENT_LICENSE_USAGE = "QUERY_CLIENT_LICENSE_USAGE";
export interface QueryClientLicenseUsageAction extends AppAction {
  clientId: string;
  licenseUsage: LicenseWithOwnerAndSingleUserAssignments[];
}

export const DOWNLOAD_LICENSE = "DOWNLOAD_LICENSE";
export interface DownloadLicenseAction extends AppAction {
  data: DownloadLicenseRequest;
  result: string;
}

export const RELEASE_LICENSE_LEASE = "RELEASE_LICENSE_LEASE";
export interface ReleaseLeaseResult {
  [leaseId: string]: boolean|string|number;
  iss: string;
  sub: string;
  exp: number;
  iat: number;
}

export interface ReleaseLicenseLeaseAction extends AppAction {
  release: {
    leaseId: string,
    /**
     * id of an assignment to remove if any.
     */
    removeAssignmentId?: string
  }[];
  result: ReleaseLeaseResult|ReleaseLeaseResult[];
}

export const LIST_ENT_LICENSES_WITH_USAGE = "LIST_ENT_LICENSES_WITH_USAGE";
export interface ListEntLicensesWithUsageAction extends ListEntLicensesAction {
  // Actual type of licenses is LicenseUsage for this action.
  // This action extends ListEntLicensesAction / LicensesAction for simpler processing.
  // licenses: LicenseUsage[];
}

export interface LicenseAssignmentAction extends AppAction {
  licenseAssignments: LicenseAssignment[];
}

export interface LicenseAssignmentManagementBaseAction
  extends LicenseAssignmentAction {
  entId: string;
  orgId: string;
  licenseId: string;
}

export interface LicenseAssignmentManagementAction
  extends LicenseAssignmentManagementBaseAction,
    LicenseUsageField {
  userId: string;
  // New license usage is populated in ManageUserLicenseAssignmentsAction but not in
  // GetUserLicenseAssignmentsAction. Declared here to simplify processing in reducers.
  licenseUsage?: LicenseUsage;
}

export const GET_USER_LICENSE_ASSIGNMENTS = "GET_USER_LICENSE_ASSIGNMENTS";
export interface GetUserLicenseAssignmentsAction
  extends LicenseAssignmentManagementAction {}

export const MANAGE_USER_LICENSE_ASSIGNMENTS =
  "MANAGE_USER_LICENSE_ASSIGNMENTS";
export interface ManageUserLicenseAssignmentsAction
  extends LicenseAssignmentManagementAction {
  licenseUsage: LicenseUsage;
  errors: { [userId: string]: any };
}

export const MANAGE_USERS_LICENSE_ASSIGNMENTS =
  "MANAGE_USERS_LICENSE_ASSIGNMENTS";
export interface ManageUsersLicenseAssignmentsAction
  extends LicenseAssignmentManagementBaseAction,
    LicenseUsageField {
  userAssignments: { [userId: string]: LicenseAssignment[] };
  licenseUsage: LicenseUsage;
  errors: { [userId: string]: any };
}

export const MANAGE_CLIENTS_LICENSE_ASSIGNMENTS =
    "MANAGE_CLIENTS_LICENSE_ASSIGNMENTS";
export interface ManageClientsLicenseAssignmentsAction
    extends LicenseAssignmentManagementBaseAction,
        LicenseUsageField {
  clientAssignments: { [clientId: string]: LicenseAssignment[] };
  licenseUsage: LicenseUsage;
  errors: { [clientId: string]: any };
}

export const QUERY_AVAILABLE_LICENSES = "QUERY_AVAILABLE_LICENSES";
export interface QueryAvailableLicensesAction
  extends LicensesAction,
    LicenseAssignmentAction {
  userId: string;
  queryAvailableLicensesOpts?: QueryAvailableLicensesOpts;
}

export const QUERY_CLIENT_AVAILABLE_LICENSES = "QUERY_CLIENT_AVAILABLE_LICENSES";
export interface QueryClientAvailableLicensesAction
    extends LicensesAction,
        LicenseAssignmentAction {
  clientId: string;
  queryAvailableLicensesOpts?: QueryAvailableLicensesOpts;
}

export const LIST_ORG_ENTITLEMENTS = "LIST_ORG_ENTITLEMENTS";
export interface ListEntitlementsAction extends AppAction {
  orgId: string;
  entitlements: Entitlement[];
}

export const GET_ORG_ENTITLEMENT = "GET_ORG_ENTITLEMENT";
export interface GetOrgEntitlementAction extends AppAction {
  orgId: string;
  entitlement: Entitlement;
}

export const LIST_LICENSE_CONSUMING_ORG_GROUPS =
    "LIST_LICENSE_CONSUMING_ORG_GROUPS";
export interface ListLicenseConsumingOrgGroupsAction extends AppAction {
  orgId: string;
  entId: string;
  groups: OrganizationGroup[];
}

export const LIST_LICENSE_CONSUMING_ORG_CLIENT_GROUPS =
    "LIST_LICENSE_CONSUMING_ORG_CLIENT_GROUPS";
export interface ListLicenseConsumingOrgClientGroupsAction extends AppAction {
  orgId: string;
  entId: string;
  groups: ClientGroup[];
}

export const ADD_LICENSE_CONSUMING_ORG_GROUP =
  "ADD_LICENSE_CONSUMING_ORG_GROUP";
export interface AddLicenseConsumingOrgGroupAction extends AppAction {
  orgId: string;
  entId: string;
  groupId: string;
}

export const REMOVE_LICENSE_CONSUMING_ORG_GROUP =
  "REMOVE_LICENSE_CONSUMING_ORG_GROUP";
export interface RemoveLicenseConsumingOrgGroupAction extends AppAction {
  orgId: string;
  entId: string;
  groupId: string;
}

export const LIST_ENTITLEMENTS_CONSUMED_BY_ORG_GROUP =
    "LIST_ENTITLEMENTS_CONSUMED_BY_ORG_GROUP";
export interface ListEntitlementsConsumedByOrgGroup extends AppAction {
  orgId: string;
  groupId: string;
  entitlements: Entitlement[];
}

export const LIST_ENTITLEMENTS_CONSUMED_BY_ORG_CLIENT_GROUP =
    "LIST_ENTITLEMENTS_CONSUMED_BY_ORG_CLIENT_GROUP";
export interface ListEntitlementsConsumedByOrgClientGroup extends AppAction {
  orgId: string;
  clientGroupId: string;
  entitlements: Entitlement[];
}

export const SET_ENTITLEMENTS_CONSUMED_BY_ORG_GROUP =
    "SET_ENTITLEMENTS_CONSUMED_BY_ORG_GROUP";
export interface SetEntitlementsConsumedByOrgGroup extends AppAction {
  orgId: string;
  groupId: string;
  entitlementIds: string[];
}

export const SET_ENTITLEMENTS_CONSUMED_BY_ORG_CLIENT_GROUP =
    "SET_ENTITLEMENTS_CONSUMED_BY_ORG_CLIENT_GROUP";
export interface SetEntitlementsConsumedByOrgClientGroup extends AppAction {
  orgId: string;
  clientGroupId: string;
  entitlementIds: string[];
}

// import users to system
export const IMPORT_USERS = "IMPORT_USERS";
export interface ImportUsersAction extends AppAction {
  users: User[];
}

// import users to organization
export const IMPORT_ORGANIZATION_USERS = "IMPORT_ORGANIZATION_USERS";
export interface ImportOrganizationUsersAction extends AppAction {
  orgId: string;
  users: UserForCreate[];
}
