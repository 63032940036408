import { useContext, useEffect, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {FeedbackEntry, Feedback, ConfirmModal} from "@10duke/dukeui";
import { User } from "../../../model/User";
import UserUtils from "../../../utils/user";
import { OrganizationGroup } from "../../../model/OrganizationGroup";
import {
  AddOrgGroupsForUserAction,
  ClearErrorAction,
  GetUserAction,
  isAddErrorAction,
  RemoveOrgGroupsOfUserAction,
} from "../../../actions/actionTypes";
import Table from "../../table/table-container";
import { TABLE_SEARCH_THRESHOLD } from "../../table/table-view";
import { ModalKeys } from "../../pages/groups";
import GroupTypeBadge from "../../badges/group-type-badge";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import { Form } from "react-bootstrap";
import { ClosableModalProps } from "../closable-modal-props";
import NavigateAfterAction from "../../navigate-after-action";
import { UserValues } from "../../../localization/user";
import { OrganizationGroupLabels } from "../../../localization/organization-group";
import "./manage-users-groups-modal-view.scss";
import {hasAction} from "../../../ui-configuration/configuration-tools";

const resolveUserName = (
  user: User | undefined | null,
  intl: { formatMessage: (v: any, v2?: any) => string }
) =>
  UserUtils.resolveDisplayName(
    user,
    intl.formatMessage(UserValues.displayName.undefined)
  );

export interface ManageUsersGroupsModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}

export interface ManageUsersGroupsModalVisibilityProps
  extends Pick<
    ClosableModalProps,
    "show" | "onClose" | "onExited"
  > {
  onShowFeedback: (feedback: FeedbackEntry | FeedbackEntry[]) => void;
}
export interface ManageUsersGroupsModalInputProps
  extends ManageUsersGroupsModalVisibilityProps {
  userId?: string;
}
export interface ManageUsersGroupsModalStateProps {
  selected: OrganizationGroup[];
  onSetSelected: (selection: OrganizationGroup[]) => void;
  groupsToAdd: OrganizationGroup[];
  groupsToRemove: OrganizationGroup[];
  activeSearch?: string;
  onSetActiveSearch: (s: string) => void;
}
export interface ManageUsersGroupsModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  actorId: string;
  user?: User | null;
  onLoadUser?: (id: string) => Promise<GetUserAction>;

  groups?: OrganizationGroup[];
  onLoadGroups?: () => void;

  employeesGroupId?: string;

  usersGroups?: string[];
  onLoadUsersGroups?: (id: string) => void;

  onAddGroups?: (
    groups: string[],
    usrId: string
  ) => Promise<AddOrgGroupsForUserAction>;
  onRemoveGroups?: (
    groups: string[],
    usrId: string
  ) => Promise<RemoveOrgGroupsOfUserAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface ManageUsersGroupsModalProps
  extends ManageUsersGroupsModalDOMProps,
    ManageUsersGroupsModalInputProps,
    ManageUsersGroupsModalStateProps,
    ManageUsersGroupsModalDataProps {}
//</editor-fold>

function ManageUsersGroupsModal(props: ManageUsersGroupsModalProps) {
  //<editor-fold desc="Local variables">
  let {
    user,
    userId,
    actorId,
    onLoadUser,
    groups,
    onLoadGroups,
    onAddGroups,
    onRemoveGroups,
    usersGroups,
    onLoadUsersGroups,
    show,
    onClose,
    onShowFeedback,
    groupsToAdd,
    groupsToRemove,
    employeesGroupId,
    onSetSelected,
    selected,
    activeSearch,
    onSetActiveSearch,
    isReady,
    onExited,
    onClearError,
  } = props;
  if (show && !onAddGroups) {
    throw new Error(
      "ManageUsersGroupsModal: Required props missing. The onAddGroups is required"
    );
  }
  if (show && !onRemoveGroups) {
    throw new Error(
      "ManageUsersGroupsModal: Required props missing. The onRemoveGroups is required"
    );
  }
  if (show && user === undefined && (!userId || !onLoadUser)) {
    throw new Error(
      "ManageUsersGroupsModal: Required props missing. The onLoadUser is required with userId"
    );
  }
  if (show && !groups && !onLoadGroups) {
    throw new Error(
      "ManageUsersGroupsModal: Required props missing. Either groups or onLoadGroups must be provided"
    );
  }
  if (show && !usersGroups && !onLoadUsersGroups) {
    throw new Error(
      "ManageUsersGroupsModal: Required props missing. Either usersGroups or onLoadUsersGroups must be provided"
    );
  }
  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>

  const userObjId = user ? user.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!onLoadUser &&
      !!userId &&
      (userObjId === undefined || (!!userObjId && userObjId !== userId))
    ) {
      onLoadUser(userId).then((res) => {
        if (!userObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, onLoadUser, userId, userObjId, onClearError]);

  useEffect(() => {
    if (!!show && !groups && !!onLoadGroups) {
      onLoadGroups();
    }
  }, [show, groups, onLoadGroups]);

  useEffect(() => {
    if (!!show && !!userObjId && !!onLoadUsersGroups) {
      onLoadUsersGroups(userObjId as string);
    }
  }, [show, userObjId, onLoadUsersGroups]);

  const { conf } = useContext(UIConfiguration);
  const groupConf =
    conf.functionality && conf.functionality.groups
      ? conf.functionality.groups
      : {};
  return (
    <ConfirmModal
      id={"manage-users-groups-modal"}
      onExited={onExited}
      onReloadData={() => {
        if (onLoadGroups) {
          onLoadGroups();
        }
        if (userId && onLoadUsersGroups) {
          onLoadUsersGroups(userId);
        }
        if (userId && onLoadUser) {
          onLoadUser(userId).then((res) => {
            if (!user && isAddErrorAction(res)) {
              // only clear error if no data exists, leave if previous data is still available and
              // let the api error notification handler show error
              onClearError(res.error?.errorId);
            }
          });
        }
      }}
      isReady={isReady}
      data-test-manage-users-groups-modal={user ? user.id : true}
      title={
        user
          ? intl.formatMessage(
              {
                defaultMessage: "{name}: groups",
                description: "modal heading. 'name' = name of the user",
              },
              {
                name: resolveUserName(user, intl),
              }
            )
          : intl.formatMessage({
              defaultMessage: "User not found",
              description: "modal heading when user not found",
            })
      }
      confirmTitle={intl.formatMessage(
        {
          defaultMessage: "{name}: confirm groups",
          description:
            "modal heading for confirming action. 'name' = name of the user",
        },
        {
          name: resolveUserName(user, intl),
        }
      )}
      confirmContent={
        <>
          <p data-test-confirm-changes-message>
            <FormattedMessage
              defaultMessage="Are you sure you wish to apply the following changes to the group memberships?"
              description={"Confirm action message"}
            />
          </p>
          {groupsToRemove.length > 0 && (
            <>
              {employeesGroupId &&
                groupsToRemove.findIndex(
                  (val) => val.id === employeesGroupId
                ) >= 0 && (
                  <Feedback type={"warning"} show={true} asChild={true}>
                    <p data-test-employees-group-removal-warning>
                      <FormattedMessage
                        defaultMessage="Please note that removing the employees group from a user may restrict their access across connected services and your ability to manage them."
                        description="message to be shown when there is no user to display"
                      />
                    </p>
                  </Feedback>
                )}
              <strong>
                <FormattedMessage
                  defaultMessage="Groups to remove"
                  description={"heading for summary of groups to remove"}
                />
              </strong>
              <ul>
                {groupsToRemove.map((g) => (
                  <li key={"toRemove" + (g.id as string)}>{g.name}</li>
                ))}
              </ul>
            </>
          )}
          {groupsToAdd.length > 0 && (
            <>
              <strong>
                <FormattedMessage
                  defaultMessage="Groups to add"
                  description={"heading for summary of groups to add"}
                />
              </strong>
              <ul>
                {groupsToAdd.map((g) => (
                  <li key={"toAdd" + (g.id as string)}>{g.name}</li>
                ))}
              </ul>
            </>
          )}
        </>
      }
      acceptButton={{
        label: intl.formatMessage({
          defaultMessage: "Yes",
          description: "confirm action button label",
        }),
      }}
      cancelButton={{
        label: intl.formatMessage({
          defaultMessage: "No",
          description: "cancel confirmation button label",
        }),
      }}
      show={show}
      onClose={onClose}
      skipConfirm={!user}
      backdrop={
        groupsToAdd.length > 0 || groupsToRemove.length > 0 ? "static" : true
      }
      primaryButton={{
        label: !user
          ? intl.formatMessage({
              defaultMessage: "Close",
              description: "close button label",
            })
          : intl.formatMessage({
              defaultMessage: "Apply",
              description: "primary button label",
            }),
        disabled:
          !!user && groupsToAdd.length === 0 && groupsToRemove.length === 0,
        tooltip:
          !!user && groupsToAdd.length === 0 && groupsToRemove.length === 0
            ? intl.formatMessage({
                defaultMessage: "There no changes to apply.",
                description: "tooltip for disabled primary button",
              })
            : undefined,
      }}
      onPrimaryAction={() => {
        if (show && user) {
          if (onAddGroups && onRemoveGroups) {
            const actions = [];
            if (groupsToAdd.length) {
              actions.push(
                onAddGroups(
                  groupsToAdd.map((val) => val.id as string),
                  user.id as string
                ).catch((error) => error)
              );
            }
            if (groupsToRemove.length) {
              actions.push(
                onRemoveGroups(
                  groupsToRemove.map((val) => val.id as string),
                  user.id as string
                ).catch((error) => error)
              );
            }
            if (actions.length) {
              // should aways come here
              Promise.all(actions).then(
                (res) => {
                  const feedback: FeedbackEntry[] = [];
                  let addResult;
                  let removeResult;
                  if (groupsToAdd.length) {
                    addResult = res[0];
                    if (groupsToRemove.length) {
                      removeResult = res[1];
                    }
                  } else if (groupsToRemove.length) {
                    removeResult = res[0];
                  } else {
                    throw Error("This should never happen");
                  }
                  if (addResult && addResult.error) {
                    onClearError(addResult.error?.errorId);
                    feedback.push({
                      id:
                        "manageUsersGroups_add_" +
                        (user ? (user.id as string) : ""),
                      msg: intl.formatMessage(
                        {
                          defaultMessage: "{name}: Adding groups failed.",
                          description:
                            "failure notification. 'name' = name of the user",
                        },
                        {
                          name:
                            "<strong>" +
                            resolveUserName(user, intl) +
                            "</strong>",
                        }
                      ),
                      type: "danger",
                    });
                  } else if (addResult) {
                    feedback.push({
                      id:
                        "manageUsersGroups_add_" +
                        (user ? (user.id as string) : ""),
                      msg: intl.formatMessage(
                        {
                          defaultMessage:
                            "{name}: {count} {count, plural, one {group} other {groups}} added.",
                          description:
                            "Success notification. 'name' = name of the user, 'count' = amount of groups added",
                        },
                        {
                          name:
                            "<strong>" +
                            resolveUserName(user, intl) +
                            "</strong>",
                          count: groupsToAdd.length,
                        }
                      ),
                      autoClose: true,
                      type: "success",
                    });
                  }
                  if (removeResult && removeResult.error) {
                    onClearError(removeResult.error?.errorId);
                    feedback.push({
                      id:
                        "manageUsersGroups_remove_" +
                        (user ? (user.id as string) : ""),
                      msg: intl.formatMessage(
                        {
                          defaultMessage: "{name}: Removing groups failed.",
                          description:
                            "failure notification. 'name' = name of the user",
                        },
                        {
                          name:
                            "<strong>" +
                            resolveUserName(user, intl) +
                            "</strong>",
                        }
                      ),
                      type: "danger",
                    });
                  } else if (removeResult) {
                    feedback.push({
                      id:
                        "manageUsersGroups_remove_" +
                        (user ? (user.id as string) : ""),
                      msg: intl.formatMessage(
                        {
                          defaultMessage:
                            "{name}: {count} {count, plural, one {group} other {groups}} removed.",
                          description:
                            "Success notification. 'name' = name of the user, 'count' = amount of groups removed.",
                        },
                        {
                          name:
                            "<strong>" +
                            resolveUserName(user, intl) +
                            "</strong>",
                          count: groupsToRemove.length,
                        }
                      ),
                      autoClose: true,
                      type: "success",
                    });
                  }
                  if (feedback.length) {
                    onShowFeedback(feedback);
                  }
                },
                (rej) => {
                  throw new Error("Should not happen");
                }
              );
            }
          }
        }
        onClose();
      }}
      secondaryButton={
        !!user
          ? {
              label: intl.formatMessage({
                defaultMessage: "Cancel",
                description: "secondary button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={onClose}
    >
      {user && (
        <Form.Group className={"form-table"}>
          <Table<OrganizationGroup>
            disableLoadingIndicator={!isReady}
            maxRows={15}
            compact={true}
            header={
              <Form.Label>
                <FormattedMessage
                  defaultMessage="Select groups"
                  description={"field label"}
                />
              </Form.Label>
            }
            data-test-select-groups
            search={groups && groups.length > TABLE_SEARCH_THRESHOLD}
            activeSearch={activeSearch}
            onSearch={onSetActiveSearch}
            columnToggle={false}
            reset={false}
            data={groups ? groups : isReady ? [] : undefined}
            pagination={false}
            identifyingColumn={"id"}
            selection={{
              multi: true,
              selectAll: true,
              disabledFor:
                actorId === (user ? (user.id as string) : false) &&
                employeesGroupId &&
                usersGroups &&
                usersGroups.includes(employeesGroupId)
                  ? [employeesGroupId]
                  : [],
            }}
            onSelectionChanged={onSetSelected}
            selected={selected}
            columns={[
              {
                key: "id",
                label: intl.formatMessage(OrganizationGroupLabels.id),
                isTechnical: true,
                hidden: true,
              },
              {
                key: "name",
                label: intl.formatMessage({
                  defaultMessage: "Group",
                  description: "column heading for the groups to select",
                }),
                sortable: true,
                renderer: (props: {
                  cell: any;
                  row: any;
                  rowIndex: Number;
                  rendererData: any;
                }) => {
                  return (
                    <>
                      <span
                        key={"link" + props.row.id}
                        className={"link-holder"}
                      >
                        {hasAction(groupConf.rowActions, 'show') ? (
                          <NavigateAfterAction
                            href={
                              "/groups/" + props.row.id + "/" + ModalKeys.show
                            }
                            action={onClose}
                          >
                            {props.cell}
                          </NavigateAfterAction>
                        ) : props.cell}
                      </span>
                      <GroupTypeBadge type={props.row.type} />
                    </>
                  );
                },
                tipRenderer: (props: {
                  cell: any;
                  row: any;
                  rowIndex: Number;
                  rendererData: any;
                }) => {
                  return (
                    <>
                      {actorId === (user ? (user.id as string) : false) &&
                      employeesGroupId ===
                        (props.row.id ? (props.row.id as string) : false) &&
                      usersGroups &&
                      usersGroups.includes(props.row.id as string) ? (
                        intl.formatMessage({
                          defaultMessage:
                            "You're not allowed to remove yourself from the employees group.",
                          description: "tooltip for disabled self removal",
                        })
                      ) : (
                        <FormattedMessage
                          defaultMessage="Click to select or deselect."
                          description="tooltip for toggling group selection"
                        />
                      )}
                    </>
                  );
                },
              },
            ]}
          />
        </Form.Group>
      )}
      {isReady && !user && (
        <Feedback type={"danger"} show={true} asChild={true}>
          <p>
            <FormattedMessage
              defaultMessage="Something went wrong and the user could not be loaded. The user may have been removed or you don't have sufficient access rights."
              description="message to be shown when there is no user to display"
            />
          </p>
        </Feedback>
      )}
    </ConfirmModal>
  );
}

export default ManageUsersGroupsModal;
