import { withCloseAfterExited } from "@10duke/dukeui";
import {
  default as View,
  ViewInvitationModalProps
} from "./view-invitation-modal-view";

const ViewInvitationModal = withCloseAfterExited<ViewInvitationModalProps>(
  View
);
export default ViewInvitationModal;
