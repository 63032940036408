import { defineMessages } from "react-intl";
import { BaseObjLabels } from "../base-obj";
import {
  UserLicenseAssignmentStatusLabels,
  UserLicenseAssignmentStatusValues
} from "../user-license-assignment-status";

/**
 * Localized labels for "UI Model" LicenseUsageByUsers fields
 */
export const LicenseUsageByUsersLabels = {
  ...BaseObjLabels,
  ...UserLicenseAssignmentStatusLabels,
  ...defineMessages({
    name: {
      defaultMessage: "user",
      description:
        "Field label for details of user consuming a license (LicenseUsageByUsers.name)"
    }
  })
};

export const LicenseUsageByUsersValues = {
  ...UserLicenseAssignmentStatusValues
};
