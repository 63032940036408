import { getEnvParam, envParamExists, getSrvBase } from "../util/env";
import { LocaleManager } from "../utils/locale";
/**
 * Environment parameter for IdP API implementation to use.
 */
const API_IMPL_ENV_PARAM_AUTHZ = "REACT_APP_AUTHZ_API_IMPL";

/**
 * Environment parameter for IdP API implementation to use.
 */
const API_IMPL_ENV_PARAM_IDP = "REACT_APP_IDP_API_IMPL";

/**
 * Environment parameter for Entitlement API implementation to use.
 */
const API_IMPL_ENV_PARAM_ENT = "REACT_APP_ENT_API_IMPL";

/**
 * Environment parameter for base url of the login UI (sso-ui).
 */
const USER_UI_BASE_ENV_PARAM = "REACT_APP_USER_UI_BASE";
const USER_UI_BASE_DEFAULT = "/user";

/**
 * Enviroment parameter for IdP authorization endpoint path.
 */
const IDP_AUTHZ_PATH_ENV_PARAM = "REACT_APP_IDP_AUTHZ_PATH";

/**
 * Enviroment parameter for IdP token endpoint path.
 */
const IDP_TOKEN_PATH_ENV_PARAM = "REACT_APP_IDP_TOKEN_PATH";

/**
 * Enviroment parameter for IdP single logout endpoint path.
 */
const IDP_SLO_PATH_ENV_PARAM = "REACT_APP_IDP_SLO_PATH";

/**
 * Environment parameter for base url of the IdP API (no trailing slash).
 */
const IDP_API_BASE_ENV_PARAM = "REACT_APP_IDP_API_BASE";
const IDP_API_BASE_DEFAULT = "/api/idp/v1";

/**
 * Environment parameter for base url of the Entitlement API (no trailing slash).
 */
const ENT_API_BASE_ENV_PARAM = "REACT_APP_ENT_API_BASE";
const ENT_API_BASE_DEFAULT = "/api/entitlement/v1";

/**
 * Environment parameter for base url of the Download license API (no trailing slash).
 */
const AUTHZ_API_BASE_ENV_PARAM = "REACT_APP_AUTHZ_API_BASE";
const AUTHZ_API_BASE_DEFAULT = "/authz";

/**
 * Environment parameter for client id used by this applicaction.
 */
const CLIENT_ID_ENV_PARAM = "REACT_APP_CLIENT_ID";
const CLIENT_ID_DEFAULT = "orgadmin";

/**
 * Environment parameter for route of this application for logout requests and responses
 * from the identity provider.
 */
const LOGOUT_PATH_ENV_PARAM = "REACT_APP_LOGOUT_PATH";
const LOGOUT_PATH_DEFAULT = "/logout";

/**
 * Relative path of the OAuth 2.0 authorization endpoint in the login UI app (sso-ui).
 */
const AUTHZ_PATH = "/oauth20/authz";

/**
 * Relative path of the OAuth 2.0 / 10Duke custom single logout endpoint in the login UI app (sso-ui).
 */
const SLO_PATH = "/oauth20/signout";

const JWKS_PATH_ENV_PARAM = "REACT_APP_JWKS_PATH";
/**
 * Relative path of the JWKS endpoint in the IdP.
 */
/* eslint-disable no-template-curly-in-string */
/* tslint:disable:no-template-curly-in-string */
const JWKS_PATH_DEFAULT =
  getUserUIBase().replace("${locale}", "") + "/.well-known/jwks.json";

/**
 * Relative path of the 10Duke Enterprise OAuth 2.0 token endpoint in the login UI app (sso-ui).
 */
/* eslint-disable no-template-curly-in-string */
/* tslint:disable:no-template-curly-in-string */
const TOKEN_PATH_DEFAULT = 
  getUserUIBase().replace("${locale}", "") + "/oauth20/token";

/* tslint:enable:no-template-curly-in-string */
/* eslint-enable no-template-curly-in-string */
/**
 * API to select (mock or real).
 */
export enum ApiImplementation {
  real,
  internalMock,
}

/**
 * Gets API implementation to use for the Download license.
 */
export function getSelectedAuthzApiImpl(): ApiImplementation {
  const envParam = API_IMPL_ENV_PARAM_AUTHZ;
  const apiImplParam = getEnvParam(envParam);
  const apiImpl: ApiImplementation | undefined = (ApiImplementation as any)[
    apiImplParam
  ];
  if (apiImpl === undefined) {
    throw new Error(`Invalid value of ${envParam}: ${apiImplParam}`);
  }
  return apiImpl;
}

/**
 * Gets API implementation to use for the IdP API.
 */
export function getSelectedIdpApiImpl(): ApiImplementation {
  const envParam = API_IMPL_ENV_PARAM_IDP;
  const apiImplParam = getEnvParam(envParam);
  const apiImpl: ApiImplementation | undefined = (ApiImplementation as any)[
    apiImplParam
  ];
  if (apiImpl === undefined) {
    throw new Error(`Invalid value of ${envParam}: ${apiImplParam}`);
  }
  return apiImpl;
}

/**
 * Gets API implementation to use for the Entitlement API.
 */
export function getSelectedEntApiImpl(): ApiImplementation {
  const envParam = API_IMPL_ENV_PARAM_ENT;
  const apiImplParam = getEnvParam(envParam);
  const apiImpl: ApiImplementation | undefined = (ApiImplementation as any)[
    apiImplParam
  ];
  if (apiImpl === undefined) {
    throw new Error(`Invalid value of ${envParam}: ${apiImplParam}`);
  }
  return apiImpl;
}

/**
 * Gets base URL of the login UI (no trailing slash).
 */
export function getUserUIBase(): string {
  return getEnvParam(
    USER_UI_BASE_ENV_PARAM,
    getSrvBase() + USER_UI_BASE_DEFAULT
  );
}

/**
 * Gets base URL of the IdP API to use (no trailing slash).
 */
export function getIdpApiBase(): string {
  return (
    getSrvBase() + getEnvParam(IDP_API_BASE_ENV_PARAM, IDP_API_BASE_DEFAULT)
  );
}

/**
 * Gets base URL of the IdP API to use (no trailing slash).
 */
export function getEntApiBase(): string {
  return (
    getSrvBase() + getEnvParam(ENT_API_BASE_ENV_PARAM, ENT_API_BASE_DEFAULT)
  );
}

function getIdpAuthzPath(): string {
  return getEnvParam(IDP_AUTHZ_PATH_ENV_PARAM, undefined);
}

function getIdpSloPath(): string {
  return getEnvParam(IDP_SLO_PATH_ENV_PARAM, undefined);
}
/**
 * Gets base URL
 */
export function getAuthzApiBase(): string {
  return new URL(
    getSrvBase() +
      getEnvParam(AUTHZ_API_BASE_ENV_PARAM, AUTHZ_API_BASE_DEFAULT),
    window.location.href
  ).toString();
}

/**
 * Gets the OAuth client id used by this application.
 */
export function getClientId(): string {
  return getEnvParam(CLIENT_ID_ENV_PARAM, CLIENT_ID_DEFAULT);
}

/**
 * Gets URL of the OAuth 2.0 authorization endpoint.
 */
export function getAuthzUrl(): URL {
  let url;
  if (envParamExists(IDP_AUTHZ_PATH_ENV_PARAM)) {
    url = getSrvBase() + getIdpAuthzPath();
  } else {
    url = getUserUIBase() + AUTHZ_PATH;
  }
  url = LocaleManager.localizeUrl(url, true);

  return new URL(url, window.location.href);
}

/**
 * Gets URL of the OAuth 2.0 token endpoint.
 */
export function getTokenUrl(): URL {
  const srvBase = getSrvBase();
  const resolvedString = getEnvParam(IDP_TOKEN_PATH_ENV_PARAM, TOKEN_PATH_DEFAULT);
  return new URL(srvBase + resolvedString, window.location.href);
}

/**
 * Gets URL of the OAuth 2.0 / 10Duke custom single logout endpoint.
 */
export function getSloUrl(): URL {
  let url;
  if (envParamExists(IDP_SLO_PATH_ENV_PARAM)) {
    url = getSrvBase() + getIdpSloPath();
  } else {
    url = getUserUIBase() + SLO_PATH;
  }
  url = LocaleManager.localizeUrl(url, true);

  return new URL(url, window.location.href);
}

/**
 * Gets URL of the JWKS endpoint of the identity provider.
 */
export function getJwksUrl(): URL {
  const srvBase = getSrvBase();
  const resolvedString = getEnvParam(JWKS_PATH_ENV_PARAM, JWKS_PATH_DEFAULT);
  return new URL(srvBase + resolvedString, window.location.href);
}

/**
 * Gets route of this application for logout requests and responses
 * from the identity provider.
 */
export function getLogoutPath(): string {
  return getEnvParam(LOGOUT_PATH_ENV_PARAM, LOGOUT_PATH_DEFAULT);
}
