import * as ActionTypes from "../actions/actionTypes";

export default function selectedOrganizationId(
  state: string,
  action: ActionTypes.AppAction
): string | null {
  switch (action.type) {
    case ActionTypes.LOAD_ORGS:
      const loadOrgsCompleted = action as ActionTypes.LoadOrgsAction;
      const loadedOrgIds = loadOrgsCompleted.organizations.map(
        (org) => org.id as string
      );
      if (state && loadedOrgIds.indexOf(state) >= 0) {
        // valid selection
        return state;
      } else if (loadedOrgIds.length === 1) {
        // no selection or selection invalid but only one to chooce from
        return loadedOrgIds[0];
      } else {
        // no selection, 0 or >1 organizations explicit choice required
        return null;
      }
    case ActionTypes.SET_ORG:
      const setOrg = action as ActionTypes.SetOrgAction;
      return setOrg.id;
    case ActionTypes.ADD_ERROR:
      const errAction =
        action as ActionTypes.AddErrorAction<ActionTypes.LoadOrgsAction>;
      if (errAction.error.action?.type === ActionTypes.LOAD_ORGS) {
        return null;
      } else {
        return state || null;
      }
    // case ActionTypes.START_AUTHN:
    // case ActionTypes.SET_LOGOUT_COMPLETED:
    // Unlike many other state fields, do not clear selected org id when starting new authentication or when logging out
    default:
      return state || null;
  }
}
