import { connect } from "react-redux";
import OrganizationUtils from "../../../utils/organization";
import {
  RemoveDeviceClientModalDataProps,
  RemoveDeviceClientModalProps as _RemoveDeviceClientModalProps,
} from "./remove-device-client-modal-view";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import {clearError, deleteOrganizationClient, getClient} from "../../../actions";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";
import RemoveDeviceClientModal from "./remove-device-client-modal-container";
import InProgressUtils from "../../../utils/in-progress";

export interface RemoveDeviceClientModalProps
  extends Omit<_RemoveDeviceClientModalProps, keyof RemoveDeviceClientModalDataProps> {
  // for some reason in this case the omit does not remove all keys with keyof  RemoveGroupModalDataProps like it does
  // elsewhere. So far I have not been able to figure out a way to inspect/debug what is actually there to be removed.
}
// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _RemoveDeviceClientModalProps,
  RemoveDeviceClientModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _RemoveDeviceClientModalProps,
  RemoveDeviceClientModalProps
>;

const sender: ActionSender = { key: "remove-device-client" };

function mapStateToProps(
  state: AppState,
  ownProps: RemoveDeviceClientModalProps
): ReduxStateProps {
  return {
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
    client: ownProps.clientId
      ? OrganizationUtils.selectOrganizationClient(ownProps.clientId, state)
      : undefined,
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationClientsStates(next, prev)
  );
}
const dispatchActions: ReduxDispatchProps = {
  onDeleteClient: addSenderArgument(sender, deleteOrganizationClient),
  onLoadClient: addSenderArgument(sender, getClient),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  RemoveDeviceClientModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(RemoveDeviceClientModal);
