import { useState } from "react";
import ErrorScreenView, {
  ErrorScreenErrorProps,
  ErrorScreenProps as _ErrorScreenProps,
} from "./error-screen-view";

type ErrorScreenProps = Omit<_ErrorScreenProps, keyof ErrorScreenErrorProps>;

function ErrorScreen(props: ErrorScreenProps) {
  const [showError, onShowError] = useState<boolean>(false);
  return (
    <ErrorScreenView
      {...props}
      showError={showError}
      onShowError={onShowError}
    />
  );
}
export default ErrorScreen;
