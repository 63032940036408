import { defineMessages } from "react-intl";
import { BaseObjLabels } from "../base-obj";
import { ValidityFieldsLabels } from "../validity-fields";

/**
 * Localized labels for User fields
 */
export const UserLabels = {
  ...BaseObjLabels,
  // Add AuthorEditorFields if needed
  ...ValidityFieldsLabels,
  ...defineMessages({
    displayName: {
      defaultMessage: "display name",
      description: "Field label for user's display name (User.displayName)"
    },
    email: {
      defaultMessage: "email",
      description: "Field label for user's email address (User.email)"
    },
    professionalTitle: {
      defaultMessage: "professional title",
      description:
        "Field label for user's professional title (User.professionalTitle)"
    },
    nickname: {
      defaultMessage: "nickname",
      description: "Field label for user's nickname (User.nickname)"
    },
    lastName: {
      defaultMessage: "last name",
      description: "Field label for user's last name (User.lastName)"
    },
    firstName: {
      defaultMessage: "first name",
      description: "Field label for user's first name (User.firstName)"
    },
    // picture <- not used, ignoring
    phoneNumber: {
      defaultMessage: "phone number",
      description: "Field label for user's phone number (User.phoneNumber)"
    }
    // naturalId <- not used, ignoring
    // lastSignOn <- not used, ignoring
  })
};
export const UserValues = {
  displayName: defineMessages({
    undefined: {
      defaultMessage: "Unknown",
      description:
        "Fallback value for missing display name of a user (User.displayName)"
    }
  })
};
