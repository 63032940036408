import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import {
  buildActionThunk,
  ensureSelectedOrgId,
  forceUndefined,
} from "./actionHelpers";
import { User } from "../model/User";
import {
  RemoveUsersFromOrgGroupResult,
  SetUsersInOrgGroupResult,
} from "../api/IdpApi";

/**
 * Gets all users who are members of a group.
 * @param sender Component requesting for the action
 * @param orgGroupId The group id
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function listUsersInOrganizationGroup(
  sender: ActionSender,
  orgGroupId: string,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListUsersInOrgGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ListUsersInOrgGroupAction, User[]>(
    sender,
    ActionTypes.LIST_USERS_IN_ORG_GROUP,
    async () =>
      await idpApi.listUsersInOrganizationGroup(orgGroupId, orgIdOrDefault),
    (type, users) => ({
      type,
      users: forceUndefined(users),
      orgGroupId,
      orgId: orgIdOrDefault,
    })
  );
}

/**
 * Adds multiple users as members of an organization group.
 * @param sender Component requesting for the action
 * @param orgGroupId The group id
 * @param userIds Array of user ids to add
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function addUsersToOrganizationGroup(
  sender: ActionSender,
  orgGroupId: string,
  userIds: string[],
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.AddUsersToOrgGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.AddUsersToOrgGroupAction, void>(
    sender,
    ActionTypes.ADD_USERS_TO_ORG_GROUP,
    async () =>
      await idpApi.addUsersToOrganizationGroup(
        orgGroupId,
        userIds,
        orgIdOrDefault
      ),
    (type) => ({
      type,
      orgGroupId,
      userIds,
      orgId: orgIdOrDefault,
    })
  );
}

/**
 * Replaces all current group members with the specified users.
 * @param sender Component requesting for the action
 * @param orgGroupId The group id
 * @param userIds Array of user ids to be new members of the group
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function setUsersInOrganizationGroup(
  sender: ActionSender,
  orgGroupId: string,
  userIds: string[],
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.SetUsersInOrgGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.SetUsersInOrgGroupAction,
    SetUsersInOrgGroupResult
  >(
    sender,
    ActionTypes.SET_USERS_IN_ORG_GROUP,
    async () =>
      await idpApi.setUsersInOrganizationGroup(
        orgGroupId,
        userIds,
        orgIdOrDefault
      ),
    (type, data) => ({
      type,
      orgGroupId,
      userIds,
      orgId: orgIdOrDefault,
      membershipRemovingResult: forceUndefined(data),
    })
  );
}

/**
 * Removes members from a group.
 * @param sender Component requesting for the action
 * @param orgGroupId The group id
 * @param userIds Array of user ids to remove
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function removeUsersFromOrganizationGroup(
  sender: ActionSender,
  orgGroupId: string,
  userIds: string[],
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.RemoveUsersFromOrgGroupAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<
    ActionTypes.RemoveUsersFromOrgGroupAction,
    RemoveUsersFromOrgGroupResult
  >(
    sender,
    ActionTypes.REMOVE_USERS_FROM_ORG_GROUP,
    async () =>
      await idpApi.removeUsersFromOrganizationGroup(
        orgGroupId,
        userIds,
        orgIdOrDefault
      ),
    (type, data) => ({
      type,
      orgGroupId,
      userIds,
      orgId: orgIdOrDefault,
      membershipRemovingResult: forceUndefined(data),
    })
  );
}
