import { defineMessages } from "react-intl";

/**
 * Localized labels for ValidityFields fields
 */
export const ValidityFieldsLabels = {
  ...defineMessages({
    validFrom: {
      defaultMessage: "valid from",
      description:
        "Field label for the start time of validity period (ValidityFields.validFrom)"
    },
    validUntil: {
      defaultMessage: "valid until",
      description:
        "Field label for the end of validity period (ValidityFields.validUntil)"
    }
  })
};
