import { useContext, useEffect, HTMLAttributes } from "react";
import { useIntl } from "react-intl";
import { SummaryCard, IconLibrary, FieldList, TooltipWrapper } from "@10duke/dukeui";
import { Link } from "react-router-dom";
import { ModalKeys } from "../../pages/device-client-groups";
import { ButtonGroup } from "react-bootstrap";
import { sortToLatestByCreatedOrUpdated } from "../../../util/objectUtil";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import {ClientGroup} from "../../../model/ClientGroup";
import {hasAction} from "../../../ui-configuration/configuration-tools";

interface ClientGroupsCardDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface ClientGroupsCardProps extends ClientGroupsCardDOMProps {
  isReady: boolean;
  groups?: ClientGroup[] | null;
  onLoadGroups?: () => void;

  moreAction: () => void;
}

function ClientGroupsCard(props: ClientGroupsCardProps) {
  const { groups, onLoadGroups, moreAction, isReady } = props;
  const intl = useIntl();
  const { conf } = useContext(UIConfiguration);
  const groupsConf =
    conf.functionality && conf.functionality["device-client-groups"]
      ? conf.functionality["device-client-groups"]
      : {};
  const headerButton = {
    label: intl.formatMessage({
      defaultMessage: "View",
      description: "View button label",
    }),
    action: moreAction,
  };
  if (!onLoadGroups && groups === undefined) {
    throw new Error(
      "ClientGroupsCard: Required props missing. Either groups or onLoadGroups must be defined"
    );
  }
  const isGroupsUndefined = groups === undefined;
  useEffect(() => {
    if (isGroupsUndefined && onLoadGroups) {
      onLoadGroups();
    }
  }, [isGroupsUndefined, onLoadGroups]);
  return (
    <SummaryCard
      onReloadData={() => {
        if (onLoadGroups) {
          onLoadGroups();
        }
      }}
      isReady={isReady}
      data-test-client-groups-card
      title={intl.formatMessage({
        defaultMessage: "Client groups",
        description: "heading for the card",
      })}
      headerButton={headerButton}
      items={
        groups && groups.length
          ? groups.sort(sortToLatestByCreatedOrUpdated).map((g) => {
              const Wrapper = hasAction(groupsConf.rowActions, 'show') ? Link : "span";
              const wrapperProps: any = {
                className: "item-copy",
              };
              if (hasAction(groupsConf.rowActions, 'show')) {
                wrapperProps.to =
                  (("/device-client-groups/" + g.id) as string) + "/" + ModalKeys.show;
              }
              return (
                <>
                  <Wrapper {...wrapperProps}>
                    <TooltipWrapper
                      tipKey={"clientGroup_" + g.id}
                      tip={g.name}
                      placement={"top"}
                    >
                      <span className={"label"}>
                        {g.name}
                      </span>
                    </TooltipWrapper>
                  </Wrapper>
                  {(hasAction(groupsConf.rowActions, 'deviceClients') || hasAction(groupsConf.rowActions, 'invite')) && (
                    <ButtonGroup size={"sm"}>
                      {hasAction(groupsConf.rowActions, 'deviceClients') && (
                        <Link
                          className={"btn btn-secondary custom-base"}
                          to={
                            (("/device-client-groups/" + g.id) as string) +
                            "/" +
                            ModalKeys.deviceClients
                          }
                        >
                          {intl.formatMessage({
                            defaultMessage: "Clients",
                            description: "label for the device clients button",
                          })}
                        </Link>
                      )}
                      {hasAction(groupsConf.rowActions, 'invite') && (
                        <Link
                          className={"btn btn-secondary custom-base"}
                          to={
                            (("/device-client-groups/" + g.id) as string) +
                            "/" +
                            ModalKeys.invite
                          }
                        >
                          {intl.formatMessage({
                            defaultMessage: "Invite",
                            description: "label for the invite button",
                          })}
                        </Link>
                      )}
                    </ButtonGroup>
                  )}
                </>
              );
            })
          : undefined
      }
      footer={
        <FieldList
          inline={true}
          fields={{
            total: {
              label: intl.formatMessage(SummaryCard.TotalLabel),
              value: groups ? groups.length : 0,
              itemClass: "total",
            },
          }}
        />
      }
    >
      {groups && groups.length === 0 && (
        <div data-test-no-items className={"no-data"}>
          <div className={"icon"}>{IconLibrary.customIcons.noData}</div>
          <p>
            {intl.formatMessage({
              defaultMessage: "No device client groups to show.",
              description: "display value for no device client groups",
            })}
          </p>
        </div>
      )}
    </SummaryCard>
  );
}
export default ClientGroupsCard;

