import { defineMessages } from "react-intl";

/**
 * Localized labels for "UI Model" UserLicenseAssignmentStatus fields
 */
export const UserLicenseAssignmentStatusLabels = {
  ...defineMessages({
    hasReservation: {
      defaultMessage: "reserved",
      description:
        "Field label for a field indicating if the user has a reserved license (UserLicenseAssignmentStatus.hasReservation)",
    },
    hasDenial: {
      defaultMessage: "blocked",
      description:
        "Field label for a field indicating if the user has ben denied from using the license (UserLicenseAssignmentStatus.hasDenial)",
    },
    isConsuming: {
      defaultMessage: "in use",
      description:
        "Field label for a field indicating if the user is actively using the licenses (UserLicenseAssignmentStatus.isConsuming)",
    },
  }),
};
/**
 * Localized values for UserLicenseAssignmentStatus fields
 */
export const UserLicenseAssignmentStatusValues = {
  // no extension needed for now
  isConsuming: defineMessages({
    false: {
      defaultMessage: "Not active",
      description:
        "value for when user is not actively using a license (UserLicenseAssignmentStatus.isConsuming 'false')",
    },
  }),
};
