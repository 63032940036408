import { useState } from "react";
import { FeedbackContainerProps,withFeedback } from "@10duke/dukeui";
import LicensesView, {
  LicensesProps as _LicensesProps,
  LicensesDOMProps as _LicensesDOMProps,
  LicensesModalVisibilityProps as _LicensesModalVisibilityProps,
  LicensesStateProps,
  LicenseWithCreditsAndEntitlementName,
} from "./licenses-view";
export { ModalKeys } from "./licenses-view";
export type LicensesModalVisibilityProps = _LicensesModalVisibilityProps;
export type LicensesDOMProps = _LicensesDOMProps;
export interface LicensesProps
  extends Omit<_LicensesProps, keyof LicensesStateProps> {}
function Licenses(props: LicensesProps & FeedbackContainerProps) {
  // selection from the table
  const [selected, setSelected] = useState<
    LicenseWithCreditsAndEntitlementName[]
  >([]);

  const viewProps: _LicensesProps = {
    selected: selected,
    onSetSelected: setSelected,
    ...props,
  };
  return <LicensesView {...viewProps} />;
}
export default withFeedback<LicensesProps & FeedbackContainerProps>(Licenses);
