import { useEffect, HTMLAttributes } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {FeedbackEntry, Feedback, Modal} from "@10duke/dukeui";
import { ClosableModalProps } from "../closable-modal-props";
import {
  ClearErrorAction,
  DeleteOrgRoleAction,
  GetOrgRoleAction,
  isAddErrorAction,
} from "../../../actions/actionTypes";
import { OrganizationRole } from "../../../model/OrganizationRole";

//<editor-fold desc="Props">

export interface RemoveRoleModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  onRemoveRole: (groupId: string) => Promise<DeleteOrgRoleAction>;
  onLoadRole: (groupId: string) => Promise<GetOrgRoleAction>;
  role: OrganizationRole | undefined | null;
  orgAdminRoleId: string | undefined;
  onLoadOrgadminRole: () => void;
  onClearError: (errorId: string) => ClearErrorAction;
}

export interface RemoveRoleModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title" | "role"> {}

export interface RemoveRoleModalProps
  extends RemoveRoleModalDOMProps,
    RemoveRoleModalDataProps,
    Omit<ClosableModalProps, "isReady"> {
  onShowFeedback: (feedback: FeedbackEntry) => void;
  roleId?: string;
}
//</editor-fold>

function RemoveRoleModal(props: RemoveRoleModalProps) {
  //<editor-fold desc="Local variables">
  let {
    role,
    roleId,
    onRemoveRole,
    show,
    onClose,
    onShowFeedback,
    onLoadRole,
    isReady,
    onExited,
    orgAdminRoleId,
    onLoadOrgadminRole,
    onClearError,
  } = props;
  const roleObjId = role ? role.id : undefined;
  // this is more like a variable than a hook
  const intl = useIntl();
  useEffect(() => {
    if (
      !!show &&
      !!roleId &&
      (roleObjId === undefined || (!!roleObjId && roleObjId !== roleId)) &&
      !!onLoadRole
    ) {
      onLoadRole(roleId).then((res) => {
        if (!roleObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, roleId, roleObjId, onLoadRole, onClearError]);
  useEffect(() => {
    if (!!show && !orgAdminRoleId && !!onLoadOrgadminRole) {
      onLoadOrgadminRole();
    }
  }, [show, orgAdminRoleId, onLoadOrgadminRole]);
  //</editor-fold>

  return (
    <Modal
      onExited={onExited}
      onReloadData={() => {
        if ((role || roleId) && onLoadRole && onLoadOrgadminRole) {
          onLoadRole(role ? (role.id as string) : (roleId as string)).then(
            (res) => {
              if (!role && isAddErrorAction(res)) {
                // only clear error if no data exists, leave if previous data is still available and
                // let the api error notification handler show error
                onClearError(res.error?.errorId);
              }
            }
          );
          onLoadOrgadminRole();
        }
      }}
      isReady={isReady}
      data-test-remove-role-modal={role ? role.id : true}
      title={intl.formatMessage({
        defaultMessage: "Remove Role",
        description: "modal heading",
      })}
      show={show}
      onClose={onClose}
      primaryButton={
        role && role.id !== orgAdminRoleId
          ? {
              label: intl.formatMessage({
                defaultMessage: "Remove",
                description: "primary button label",
              }),
            }
          : {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
      }
      onPrimaryAction={() => {
        if (show && role && role.id !== orgAdminRoleId) {
          onRemoveRole(role ? (role.id as string) : "not_possible").then(
            (res) => {
              if (!isAddErrorAction(res)) {
                onShowFeedback({
                  id: "removeRole",
                  msg: intl.formatMessage(
                    {
                      defaultMessage: "Role {name} removed.",
                      description:
                        "success notification. 'name' = name of the role",
                    },
                    {
                      name:
                        "<strong>" +
                        (role ? role.name : "impossible") +
                        "</strong>",
                    }
                  ),
                  autoClose: true,
                  type: "success",
                });
              } else {
                onClearError(res.error?.errorId);
                onShowFeedback({
                  id: "removeRole",
                  msg: intl.formatMessage(
                    {
                      defaultMessage: "Removing role {name} failed: {msg}",
                      description:
                        "failure notification. 'name' = name of the role, 'msg' = error message",
                    },
                    {
                      name:
                        "<strong>" +
                        (role ? role.name : "impossible") +
                        "</strong>",
                      msg: res.error.apiError.error_description,
                    }
                  ),
                  type: "danger",
                });
              }
            }
          );
        }
        onClose();
      }}
      secondaryButton={
        show && role && role.id !== orgAdminRoleId
          ? {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={role ? onClose : undefined}
    >
      <>
        {show && role && role.id !== orgAdminRoleId && (
          <p>
            <FormattedMessage
              defaultMessage="Are you sure you wish to remove the role {name} from this organization?"
              description={
                "confirm action message. 'name' = the name of the role"
              }
              values={{
                name: <strong>{role ? role.name : "impossible"}</strong>,
              }}
            />
          </p>
        )}
        {show && role && role.id === orgAdminRoleId && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p data-test-role-is-admin-role>
              <FormattedMessage
                defaultMessage="The admin role is special and cannot be removed."
                description="message to be shown when trying to remove the required admin role"
              />
            </p>
          </Feedback>
        )}
        {isReady && show && !role && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p data-test-role-not-found>
              <FormattedMessage
                defaultMessage="No matching role found."
                description="message to be shown when the role was not found"
              />
            </p>
          </Feedback>
        )}
      </>
    </Modal>
  );
}

export default RemoveRoleModal;
