import { connect } from "react-redux";
import { AppState } from "../../../store/AppState";
import OrganizationUtils from "../../../utils/organization";
import LicensesCard, {
  LicensesCardProps as _LicensesCardProps,
} from "./licenses-card-view";

import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import {
  aggregateValidSeatCountCreditDataForLicense,
  aggregateValidUseCountCreditDataForLicense,
  aggregateValidUseTimeCreditDataForLicense,
} from "../../../util/licenseUtil";
import { LicenseWithCreditsAndEntitlementName } from "../../pages/licenses/licenses-view";
import { LicenseWithCredits } from "../../../model/entitlement/LicenseWithCredits";
import { listEntitlementLicensesWithUsage } from "../../../actions/entActions";
import {
  isAboutToExpire,
  isScheduled,
  isValid,
} from "../../../util/objectUtil";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";

export interface LicensesCardProps
  extends Pick<_LicensesCardProps, "moreAction"> {}

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _LicensesCardProps,
  LicensesCardProps
>;

// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _LicensesCardProps,
  LicensesCardProps
>;

const sender: ActionSender = { key: "licensesCard" };

function mapStateToProps(state: AppState): ReduxStateProps {
  let orgLicenses = OrganizationUtils.selectOrganizationLicenses(state);
  if (orgLicenses) {
    orgLicenses = orgLicenses.map((lic) => {
      let l = aggregateValidSeatCountCreditDataForLicense(lic);
      l = aggregateValidUseCountCreditDataForLicense(l);
      l = aggregateValidUseTimeCreditDataForLicense(l);
      const creditTypes = [];
      if (l.effectiveLicenseModel) {
        if (l.effectiveLicenseModel.credits?.type === 'seatCount') {
          creditTypes.push("seat");
        }
        if (l.effectiveLicenseModel.credits?.type === 'useCount') {
          creditTypes.push("count");
        }
        if (l.effectiveLicenseModel.credits?.type === 'useTime') {
          creditTypes.push("time");
        }
      }
      if (creditTypes.length === 0) {
        creditTypes.push("seat");
      }
      (l as LicenseWithCreditsAndEntitlementName).creditTypes = creditTypes;
      return l;
    }) as LicenseWithCredits[];
  }

  const validCount = !!orgLicenses
    ? orgLicenses.filter(isValid).filter((l: any) => l.active !== false).length
    : 0;
  const invalidCount = !!orgLicenses
    ? orgLicenses
        .filter((l: LicenseWithCredits) => !isValid(l))
        .filter((l: any) => l.active !== false).length
    : 0;
  const expiringCount = !!orgLicenses
    ? orgLicenses
        .filter((l: LicenseWithCredits) => isValid(l) && isAboutToExpire(l))
        .filter((l: any) => l.active !== false).length
    : 0;
  const scheduledCount = !!orgLicenses
    ? orgLicenses
        .filter((l: LicenseWithCredits) => !isValid(l) && isScheduled(l))
        .filter((l: any) => l.active !== false).length
    : 0;
  const deactivatedCount = !!orgLicenses
    ? orgLicenses.filter((l: any) => l.active === false).length
    : 0;
  return {
    licenses: orgLicenses,
    validCount,
    invalidCount,
    expiringCount,
    scheduledCount,
    deactivatedCount,
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationLicenseStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadLicenses: () => listEntitlementLicensesWithUsage(sender, "any"),
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  LicensesCardProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(LicensesCard);
