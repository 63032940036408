import { connect } from "react-redux";
import CreateOrganizationGroupModal, {
  CreateOrganizationGroupModalProps as _CreateOrganizationGroupModalProps,
} from "./create-organization-group-modal-container";
import { AppState } from "../../../store/AppState";
import {
  PickReduxDispatchProps,
  PickReduxStateProps,
} from "../../../util/typeUtil";
import OrganizationUtils from "../../../utils/organization";
import {
  listOrganizationEntitlements,
  setEntitlementsConsumedByOrgGroup,
} from "../../../actions/entActions";
import { CreateOrganizationGroupModalDataProps } from "./create-organization-group-modal-view";
import { clearError, createOrganizationGroup } from "../../../actions";
import InProgressUtils from "../../../utils/in-progress";
import { ActionSender } from "../../../model/ActionSender";
import { addSenderArgument } from "../../../actions/actionHelpers";

export type CreateOrganizationGroupModalProps = Omit<
  _CreateOrganizationGroupModalProps,
  keyof CreateOrganizationGroupModalDataProps
>;

// Input props provided to the wrapped component by this connect component, using mapStateToProps
type ReduxStateProps = PickReduxStateProps<
  _CreateOrganizationGroupModalProps,
  CreateOrganizationGroupModalProps
>;
// Dispatch props provided to the wrapped component by this connect component
type ReduxDispatchProps = PickReduxDispatchProps<
  _CreateOrganizationGroupModalProps,
  CreateOrganizationGroupModalProps
>;

const sender: ActionSender = { key: "create-organization-group" };

function mapStateToProps(
  state: AppState,
  ownProps: CreateOrganizationGroupModalProps
): ReduxStateProps {
  return {
    organizationId: OrganizationUtils.selectSelectedOrganizationId(state),
    entitlements: OrganizationUtils.selectOrganizationEntitlements(state),
    isReady: InProgressUtils.selectNotInProgress(sender.key, state),
  };
}

function areStatesEqual(next: AppState, prev: AppState): boolean {
  return (
    OrganizationUtils.compareSelectedOrganizationIdStates(next, prev) &&
    InProgressUtils.compareInProgressStates(next, prev) &&
    OrganizationUtils.compareOrganizationEntitlementsStates(next, prev)
  );
}

const dispatchActions: ReduxDispatchProps = {
  onLoadEntitlements: addSenderArgument(sender, listOrganizationEntitlements),
  onSetGroupEntitlements: addSenderArgument(
    sender,
    setEntitlementsConsumedByOrgGroup
  ),
  onCreateOrganizationGroup: addSenderArgument(sender, createOrganizationGroup),
  onClearError: clearError,
};

export default connect<
  ReduxStateProps,
  ReduxDispatchProps,
  CreateOrganizationGroupModalProps,
  AppState
>(mapStateToProps, dispatchActions, null, {
  areStatesEqual,
})(CreateOrganizationGroupModal);
