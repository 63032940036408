import { useEffect, HTMLAttributes } from "react";
import {FeedbackEntry, Feedback, Modal} from "@10duke/dukeui";
import { OrganizationGroupInvitation } from "../../../model/OrganizationGroupInvitation";
import { FormattedMessage, useIntl } from "react-intl";
import { ClosableModalProps } from "../closable-modal-props";
import {
  ClearErrorAction,
  GetOrgOrgGroupInvitationAction,
  isAddErrorAction,
  SendOrgGroupInvitationAction,
} from "../../../actions/actionTypes";

//<editor-fold desc="Props">

export interface ResendInvitationModalDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface ResendInvitationModalDataProps
  extends Pick<ClosableModalProps, "isReady"> {
  invitation?: OrganizationGroupInvitation | null;
  onResendInvitation: (id: string) => Promise<SendOrgGroupInvitationAction>;
  onLoadInvitation: (id: string) => Promise<GetOrgOrgGroupInvitationAction>;
  onClearError: (errorId: string) => ClearErrorAction;
}
export interface ResendInvitationModalProps
  extends ResendInvitationModalDOMProps,
    ResendInvitationModalDataProps,
    Omit<ClosableModalProps, "isReady"> {
  onShowFeedback: (feedback: FeedbackEntry) => void;
  invitationId?: string;
}
//</editor-fold>

function ResendInvitationModal(props: ResendInvitationModalProps) {
  //<editor-fold desc="Local variables">
  let {
    invitation,
    invitationId,
    onLoadInvitation,
    onResendInvitation,
    show,
    onClose,
    onShowFeedback,
    isReady,
    onExited,
    onClearError,
  } = props;

  const invitationObjId = invitation ? invitation.id : undefined;
  useEffect(() => {
    if (
      !!show &&
      !!onLoadInvitation &&
      !!invitationId &&
      (invitationObjId === undefined ||
        (!!invitationObjId && invitationObjId !== invitationId))
    ) {
      onLoadInvitation(invitationId).then((res) => {
        if (!invitationObjId && isAddErrorAction(res)) {
          // only clear error if no data exists, leave if previous data is still available and
          // let the api error notification handler show error
          onClearError(res.error?.errorId);
        }
      });
    }
  }, [show, onLoadInvitation, invitationId, invitationObjId, onClearError]);
  // this is more like a variable than a hook
  const intl = useIntl();

  //</editor-fold>

  return (
    <Modal
      onReloadData={() => {
        if ((invitation || invitationId) && onLoadInvitation) {
          onLoadInvitation(
            invitation ? (invitation.id as string) : (invitationId as string)
          ).then((res) => {
            if (!invitation && isAddErrorAction(res)) {
              // only clear error if no data exists, leave if previous data is still available and
              // let the api error notification handler show error
              onClearError(res.error?.errorId);
            }
          });
        }
      }}
      onExited={onExited}
      isReady={isReady}
      data-test-resend-invitation-modal={invitation ? invitation.id : true}
      title={intl.formatMessage({
        defaultMessage: "Resend invitation",
        description: "modal heading",
      })}
      show={show}
      onClose={onClose}
      primaryButton={
        invitation &&
        invitation.invitationState !== "revoked" &&
        invitation.invitationState !== "declined" &&
        invitation.invitationState !== "accepted"
          ? {
              label: intl.formatMessage({
                defaultMessage: "Resend",
                description: "primary button label",
              }),
            }
          : {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
      }
      onPrimaryAction={() => {
        if (
          invitation &&
          invitation.invitationState !== "revoked" &&
          invitation.invitationState !== "declined" &&
          invitation.invitationState !== "accepted"
        ) {
          onResendInvitation(invitation.id as string).then((res) => {
            if (!isAddErrorAction(res)) {
              onShowFeedback({
                id: ("resend_" + res.invitation.id) as string,
                msg: intl.formatMessage(
                  {
                    defaultMessage: "Invitation for {name} resent.",
                    description:
                      "success notification. 'name' = recipient email",
                  },
                  {
                    name:
                      "<strong>" +
                      (invitation && invitation.email
                        ? invitation.email
                        : "impossible") +
                      "</strong>",
                  }
                ),
                autoClose: true,
                type: "success",
              });
            } else {
              onClearError(res.error?.errorId);
              onShowFeedback({
                id:
                  "resend_" +
                  (invitation ? (invitation.id as string) : "impossible"),
                msg: intl.formatMessage(
                  {
                    defaultMessage:
                      "Resending invitation for {name} failed: {msg}",
                    description:
                      "failure notification. 'name' = recipient email, 'msg' = error message",
                  },
                  {
                    name:
                      "<strong>" +
                      (invitation ? invitation.email : "impossible") +
                      "</strong>",
                    msg: res.error.apiError.error_description,
                  }
                ),
                type: "danger",
              });
            }
          });
        }
        onClose();
      }}
      secondaryButton={
        invitation &&
        invitation.invitationState !== "revoked" &&
        invitation.invitationState !== "declined" &&
        invitation.invitationState !== "accepted"
          ? {
              label: intl.formatMessage({
                defaultMessage: "Close",
                description: "close button label",
              }),
            }
          : undefined
      }
      onSecondaryAction={invitation ? onClose : undefined}
    >
      <>
        {show && invitation && (
          <>
            {invitation.invitationState !== "revoked" &&
              invitation.invitationState !== "declined" &&
              invitation.invitationState !== "accepted" && (
                <p>
                  <FormattedMessage
                    defaultMessage="Resend the invitation for {name}?"
                    description={
                      "Confirm action message. 'name' = recipient email"
                    }
                    values={{
                      name: (
                        <strong>
                          {invitation ? invitation.email : "impossible"}
                        </strong>
                      ),
                    }}
                  />
                </p>
              )}
            {(invitation.invitationState === "revoked" ||
              invitation.invitationState === "declined" ||
              invitation.invitationState === "accepted") && (
              <Feedback type={"danger"} show={true} asChild={true}>
                <p data-test-invitation-not-resendable>
                  <FormattedMessage
                    defaultMessage="Invitation for {name} is already closed and can no longer be resent."
                    description="message to be shown when the invitation is already closed. 'name' = recipient email"
                    values={{
                      name: (
                        <strong>
                          {invitation ? invitation.email : "impossible"}
                        </strong>
                      ),
                    }}
                  />
                </p>
              </Feedback>
            )}
          </>
        )}
        {isReady && show && !invitation && (
          <Feedback type={"danger"} show={true} asChild={true}>
            <p data-test-invitation-not-found>
              <FormattedMessage
                defaultMessage="No matching invitation found."
                description="message to be shown when there is no invitation to display"
              />
            </p>
          </Feedback>
        )}
      </>
    </Modal>
  );
}

export default ResendInvitationModal;
