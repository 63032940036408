import * as ActionTypes from "../actions/actionTypes";
import { PermissionsById } from "../store/PermissionState";
import { toInternalPermission } from "../model/InternalPermissionWithGrantedActions";

export default function permissions(
  state: PermissionsById,
  action: ActionTypes.AppAction
): PermissionsById | null {
  const currentState = state || ({} as PermissionsById);
  switch (action.type) {
    case ActionTypes.LIST_PERMISSIONS_OF_ORG_ROLE:
      const permsOfOrgRole =
        action as ActionTypes.ListPermissionsOfOrgRoleAction;
      const newRolePermissionsById =
        permsOfOrgRole.permissions.reduce<PermissionsById>(
          (map, permission) => {
            map[permission.id as string] = toInternalPermission(permission);
            return map;
          },
          {}
        );
      return { ...currentState, ...newRolePermissionsById };
    case ActionTypes.LIST_PERMISSIONS:
      const perms = action as ActionTypes.ListPermissions;
      const newPermissionsById = perms.permissions.reduce<PermissionsById>(
        (map, permission) => {
          map[permission.id as string] = toInternalPermission(permission);
          return map;
        },
        {}
      );
      return { ...currentState, ...newPermissionsById };
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
