import * as ActionTypes from "../actions/actionTypes";
import {ConsumerAvailableLicensesByConsumerId} from "../store/LicenseState";
import {
  removeFromStateById,
  removeUsersFromState,
} from "../util/ReducerUtils";
import {ReleaseLeaseResult, ReleaseLicenseLeaseAction} from "../actions/actionTypes";

export default function userAvailableLicenses(
  state: ConsumerAvailableLicensesByConsumerId,
  action: ActionTypes.AppAction
): ConsumerAvailableLicensesByConsumerId | null {
  const currentState = state || ({} as ConsumerAvailableLicensesByConsumerId);
  switch (action.type) {
    case ActionTypes.QUERY_AVAILABLE_LICENSES:
      const queryAvailableLicenses =
        action as ActionTypes.QueryAvailableLicensesAction;
      const userId = queryAvailableLicenses.userId;
      const licenses = queryAvailableLicenses.licenses;
      const newAvailableLicenses = licenses.map((lic) => ({
        licenseId: lic.id as string,
        assignmentIds: lic.assignments
          ? lic.assignments.map((ass) => ass.id as string)
          : [],
      }));
      return { ...currentState, [userId]: newAvailableLicenses };

    case ActionTypes.DELETE_USER:
    case ActionTypes.SET_ORG_GROUPS_OF_USER:
    case ActionTypes.REMOVE_ORG_GROUPS_OF_USER:
    case ActionTypes.DELETE_ORG_GROUP:
    case ActionTypes.REMOVE_USERS_FROM_ORG_GROUP:
    case ActionTypes.SET_USERS_IN_ORG_GROUP:
    case ActionTypes.REMOVE_USER_FROM_ORG:
      return removeUsersFromState<ConsumerAvailableLicensesByConsumerId>(
        action,
        currentState
      );
    case ActionTypes.RELEASE_LICENSE_LEASE:
      const releaseLicenseLeaseAction = action as ReleaseLicenseLeaseAction;
      return releaseLicenseLease(releaseLicenseLeaseAction.release, releaseLicenseLeaseAction.result, currentState);
    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}
function releaseLicenseLease(
    release: {leaseId: string, removeAssignmentId?: string }[],
    res: ReleaseLeaseResult|ReleaseLeaseResult[],
    currentState: ConsumerAvailableLicensesByConsumerId) {
  let result = { ...currentState };
  release.forEach((r) => {
    if (r.leaseId && res && ((res as ReleaseLeaseResult)[r.leaseId] || (res.length && (res as ReleaseLeaseResult[]).find((f: any) => f[r.leaseId])))) {
      // success
      const uIds = Object.keys(result);
      for (let i = 0; i < uIds.length; i += 1) {
        for (let j = 0; j < result[uIds[i]].length; j += 1) {
          if (result[uIds[i]][j].assignmentIds && result[uIds[i]][j].assignmentIds.length) {
            result[uIds[i]][j] = {
              ...result[uIds[i]][j],
              assignmentIds: result[uIds[i]][j].assignmentIds.filter((ass) => ass !== r.removeAssignmentId),
            };
          }
        }
      }
    }
  });
  return result;
}

function deleteLicense(
  licenseId: string,
  currentState: ConsumerAvailableLicensesByConsumerId
): ConsumerAvailableLicensesByConsumerId {
  let result = currentState;

  for (const userId in currentState) {
    const userAvailableLicensesAfterDelete = result[userId].filter(
      (userAvailableLicense) => userAvailableLicense.licenseId !== licenseId
    );
    result = { ...result, [userId]: userAvailableLicensesAfterDelete };
  }

  return result;
}

function handleErrorAction(
  currentState: ConsumerAvailableLicensesByConsumerId,
  action: ActionTypes.AppAction
): ConsumerAvailableLicensesByConsumerId {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  const type = errorAction.error.action.type;

  if (
    type === ActionTypes.GET_USER &&
    errorAction.error.apiError.error === "404"
  ) {
    const typedError =
      action as ActionTypes.AddErrorAction<ActionTypes.GetUserAction>;
    finalState = removeFromStateById<ConsumerAvailableLicensesByConsumerId>(
      [typedError.error.action?.userId as string],
      currentState
    );
  } else if (
    type === ActionTypes.QUERY_LICENSE_USAGE ||
    type === ActionTypes.GET_USER_LICENSE_ASSIGNMENTS
  ) {
    if (errorAction.error.apiError.error === "404") {
      const typedError =
        action as ActionTypes.AddErrorAction<ActionTypes.QueryLicenseUsageAction>;
      finalState = deleteLicense(
        typedError.error.action?.licenseId as string,
        currentState
      );
    }
  }

  return finalState;
}
