import * as ActionTypes from "./actionTypes";
import { idpApi } from "../api";
import { ActionSender } from "../model/ActionSender";
import { Organization } from "../model/Organization";
import { User } from "../model/User";
import {
  buildActionThunk,
  buildMultiActionThunk,
  ensureSelectedOrgId,
  EventOperation,
  EventOperationProvider,
  forceUndefined
} from "./actionHelpers";
import {listEntitlementConsumingClients, listEntitlementConsumingUsers, queryLicenseUsage} from "./entActions";
import {listOrganizationClients} from "./orgClientActions";

/**
 * Sets the organization selected to be managed in the application.
 * @param orgId Id of the organization to manage
 */
export function setOrg(orgId: string): ActionTypes.SetOrgAction {
  return {
    type: ActionTypes.SET_ORG,
    id: orgId
  };
}

/**
 * Starts loading data of organizations that the authenticated user
 * can manage in this application.
 * @param sender Component requesting for the action
 */
export function loadOrgs(
  sender: ActionSender
): ActionTypes.AppThunkAction<ActionTypes.LoadOrgsAction> {
  return buildActionThunk<ActionTypes.LoadOrgsAction, Organization[]>(
    sender,
    ActionTypes.LOAD_ORGS,
    async () => await idpApi.getAppOrganizations(),
    (type, organizations) => ({
      type,
      organizations: forceUndefined(organizations)
    })
  );
}

/**
 * Starts loading data of an organization by id.
 * @param sender Component requesting for the action
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function getOrg(
  sender: ActionSender,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.GetOrgAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.GetOrgAction, Organization>(
    sender,
    ActionTypes.GET_ORG,
    async () => await idpApi.getOrganization(orgIdOrDefault),
    (type, organization) => ({
      type,
      organization: forceUndefined(organization)
    })
  );
}

/**
 * Updates organization data by replacing existing data with the given data.
 * @param sender Component requesting for the action
 * @param org The new organization data
 */
export function replaceOrg(
  sender: ActionSender,
  org: Organization
): ActionTypes.AppThunkAction<ActionTypes.ReplaceOrgAction> {
  return buildActionThunk<ActionTypes.ReplaceOrgAction, Organization>(
    sender,
    ActionTypes.REPLACE_ORG,
    async () => await idpApi.replaceOrganization(org),
    (type, organization) => ({
      type,
      organization: forceUndefined(organization)
    })
  );
}

/**
 * Marks second factor authentication (MFA) as required or not required for
 * users of an organization.
 * @param sender Component requesting for the action
 * @param required true to mark MFA required, false to mark MFA optional
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function setMfaRequired(
  sender: ActionSender,
  required: boolean,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.SetMfaRequiredAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.SetMfaRequiredAction, void>(
    sender,
    ActionTypes.SET_MFA_REQUIRED,
    async () => await idpApi.setMfaRequired(required, orgIdOrDefault),
    type => ({
      type,
      orgId: orgIdOrDefault,
      required
    })
  );
}

/**
 * Starts loading data of all members of an organization.
 * @param sender Component requesting for the action
 * @param orgId The organization id. If not given, id of organization currently
 *    managed in the application is used.
 */
export function listOrganizationUsers(
  sender: ActionSender,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.ListOrgUsersAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);
  return buildActionThunk<ActionTypes.ListOrgUsersAction, User[]>(
    sender,
    ActionTypes.LIST_ORG_USERS,
    async () => await idpApi.listAllOrganizationUsers(orgIdOrDefault),
    (type, users) => ({
      type,
      users: forceUndefined(users),
      orgId: orgIdOrDefault
    })
  );
}
export function queryOrganizationLicenseEntitlementUsersAndLicenseUsage(
  sender: ActionSender,
  licenseId: string,
  entId: string,
  includeClients: boolean,
  orgId?: string
): ActionTypes.AppThunkAction<ActionTypes.MultiAction> {
  const orgIdOrDefault = ensureSelectedOrgId(orgId);

  return buildMultiActionThunk(
    sender,
    new QueryOrganizationLicenseEntitlementUsersAndLicenseUsageProvider(
      sender,
      licenseId,
      orgIdOrDefault,
      entId,
      includeClients
    ),
    false
  );
}

enum QueryOrganizationLicenseEntitlementUsersAndLicenseUsageState {
  INIT = 0,
  LIST_USERS,
  LIST_CLIENTS,
  LIST_ENT_USERS,
  LIST_ENT_CLIENTS,
  DONE
}

class QueryOrganizationLicenseEntitlementUsersAndLicenseUsageProvider
  implements EventOperationProvider<ActionTypes.AppAction> {
  private state: QueryOrganizationLicenseEntitlementUsersAndLicenseUsageState;
  private sender: ActionSender;
  private licenseId: string;
  private orgId: string;
  private entId: string;
  private includeClients: boolean;

  constructor(sender: ActionSender, licenseId: string, orgId: string,  entId: string, includeClients:boolean) {
    this.state = QueryOrganizationLicenseEntitlementUsersAndLicenseUsageState.INIT;
    this.sender = sender;
    this.licenseId = licenseId;
    this.orgId = orgId;
    this.entId = entId;
    this.includeClients = includeClients;
  }

  /**
   * Method for providing next thunk/function to be executed in this chain.
   * @param sender
   * @param multiAction
   */
  next(
    sender: ActionSender,
    multiAction: ActionTypes.MultiAction
  ): EventOperation<ActionTypes.AppAction> | null {
    if (this.state === QueryOrganizationLicenseEntitlementUsersAndLicenseUsageState.INIT) {
      this.state = this.includeClients ? QueryOrganizationLicenseEntitlementUsersAndLicenseUsageState.LIST_USERS : QueryOrganizationLicenseEntitlementUsersAndLicenseUsageState.LIST_CLIENTS;
      return {
        thunk: listOrganizationUsers(this.sender, this.orgId)
      };
    }
    if (this.state === QueryOrganizationLicenseEntitlementUsersAndLicenseUsageState.LIST_USERS) {
      this.state = QueryOrganizationLicenseEntitlementUsersAndLicenseUsageState.LIST_CLIENTS;
      return {
        thunk: listOrganizationClients(this.sender, this.orgId)
      };
    }
    if (this.state === QueryOrganizationLicenseEntitlementUsersAndLicenseUsageState.LIST_CLIENTS) {
      this.state = this.includeClients ? QueryOrganizationLicenseEntitlementUsersAndLicenseUsageState.LIST_ENT_USERS : QueryOrganizationLicenseEntitlementUsersAndLicenseUsageState.LIST_ENT_CLIENTS;
      return {
        thunk: listEntitlementConsumingUsers(this.sender, this.entId, this.orgId)
      };
    }
    if (this.state === QueryOrganizationLicenseEntitlementUsersAndLicenseUsageState.LIST_ENT_USERS) {
      this.state = QueryOrganizationLicenseEntitlementUsersAndLicenseUsageState.LIST_ENT_CLIENTS;
      return {
        thunk: listEntitlementConsumingClients(this.sender, this.entId, this.orgId)
      };
    }
    if (this.state === QueryOrganizationLicenseEntitlementUsersAndLicenseUsageState.LIST_ENT_CLIENTS) {
      this.state = QueryOrganizationLicenseEntitlementUsersAndLicenseUsageState.DONE;
      return {
        thunk: queryLicenseUsage(this.sender, "any", this.licenseId)
      };
    }
    return null;
  }
}
