import * as ActionTypes from "../actions/actionTypes";
import { LicensesById } from "../store/LicenseState";
import { updateLicense } from "../util/licenseUtil";
import { isValid } from "../util/objectUtil";
import {
  LicenseAssignmentAction,
  ReleaseLeaseResult,
  ReleaseLicenseLeaseAction,
  RemoveMembershipResult,
  RemoveOrganizationResult,
} from "../actions/actionTypes";
import {LicenseUserWithAssignmentsAndSessions} from "../model/entitlement/LicenseUserWithAssignmentsAndSessions";
import {LicenseWithOwner} from "../model/entitlement/LicenseWithOwner";
import {LicenseUsage} from "../model/entitlement/LicenseUsage";
import {LicenseWithOwnerAndSingleUserAssignments} from "../model/entitlement/LicenseWithOwnerAndSingleUserAssignments";

export default function licenses(
  state: LicensesById,
  action: ActionTypes.AppAction
): LicensesById | null {
  const currentState = state || ({} as LicensesById);
  switch (action.type) {
    case ActionTypes.LIST_ENT_LICENSES:
    case ActionTypes.LIST_ENT_LICENSES_WITH_USAGE:
    case ActionTypes.QUERY_AVAILABLE_LICENSES: {
      const licensesAction = action as ActionTypes.LicensesAction;
      const licenses = licensesAction.licenses;
      const stateAfterOperation = {...currentState};
      for (const license of licenses) {
        const licenseId = license.id as string;
        // Strip out members that are not part of the result type: LicenseWithOwner & LicenseUsage
        const {
          assignments,
          licenseAssignments,
          ...typedLicense
        } = license as LicenseWithOwnerAndSingleUserAssignments & LicenseAssignmentAction;
        stateAfterOperation[licenseId] = updateLicense<LicenseWithOwner & LicenseUsage>(
            typedLicense as LicenseWithOwner & LicenseUsage,
            stateAfterOperation[licenseId]
        );
      }
      return stateAfterOperation;
    }
    case ActionTypes.QUERY_CLIENT_AVAILABLE_LICENSES: {
      const licensesAction = action as ActionTypes.QueryClientAvailableLicensesAction;
      const licenses = licensesAction.licenses;
      const stateAfterOperation = {...currentState};
      for (const license of licenses) {
        const licenseId = license.id as string;
        // Strip out members that are not part of the result type: LicenseWithOwner & LicenseUsage
        const {
          assignments,
          licenseAssignments,
          ...typedLicense
        } = license as LicenseWithOwnerAndSingleUserAssignments & LicenseAssignmentAction;
        stateAfterOperation[licenseId] = updateLicense<LicenseWithOwner & LicenseUsage>(
            typedLicense as LicenseWithOwner & LicenseUsage,
            stateAfterOperation[licenseId]
        );
      }
      return stateAfterOperation;

    }

    case ActionTypes.QUERY_LICENSE_USAGE:
    case ActionTypes.GET_USER_LICENSE_ASSIGNMENTS:
    case ActionTypes.MANAGE_USER_LICENSE_ASSIGNMENTS:
    case ActionTypes.MANAGE_USERS_LICENSE_ASSIGNMENTS:
      const licenseUsage = action as ActionTypes.LicenseUsageField;

      if (licenseUsage.licenseUsage) {
        const stateAfterQuery = { ...currentState };
        const queriedLicenseId = licenseUsage.licenseUsage.id as string;
        stateAfterQuery[queriedLicenseId] = updateLicense<LicenseWithOwner & LicenseUsage>(
          licenseUsage.licenseUsage as LicenseWithOwner & LicenseUsage,
          stateAfterQuery[queriedLicenseId]
        );
        return stateAfterQuery;
      }
      return state;
    case ActionTypes.DELETE_ORG_GROUP:
    case ActionTypes.SET_USERS_IN_ORG_GROUP:
    case ActionTypes.REMOVE_USERS_FROM_ORG_GROUP:
    case ActionTypes.REMOVE_USER_FROM_ORG:
      const removeMembershipAction = action as RemoveMembershipResult;
      return deleteUsers(
        removeMembershipAction.membershipRemovingResult?.noMembershipUserIds,
        currentState
      );
    case ActionTypes.SET_ORG_GROUPS_OF_USER:
    case ActionTypes.REMOVE_ORG_GROUPS_OF_USER:
    case ActionTypes.REMOVE_ORG_GROUP_OF_USER:
      const removeOrgAction = action as RemoveOrganizationResult;
      return deleteUsers(
        removeOrgAction.organizationRemovingResult?.noMembershipOrgIds?.find(
          (f) => removeOrgAction.orgId === f
        )
          ? [removeOrgAction.userId]
          : undefined,
        currentState
      );
    case ActionTypes.RELEASE_LICENSE_LEASE:
      const releaseLicenseLeaseAction = action as ReleaseLicenseLeaseAction;
      return releaseLicenseLease(releaseLicenseLeaseAction.release, releaseLicenseLeaseAction.result, currentState);
    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

/**
 * Removes released leases from state. If removed lease is the last lease of a session, removes the session as well. If removed session is the last session of an assignment that is not of type `reserved` or `denied`, removes the assignment as well. Increases seatsTaken count when a seat is released.
 */
function releaseLicenseLease(release: { leaseId: string, removeAssignmentId?: string }[], res: ReleaseLeaseResult|ReleaseLeaseResult[], currentState: LicensesById) {
  const result = { ...currentState };
  release.forEach((r) => {
    if (r.leaseId && res && ((res as ReleaseLeaseResult)[r.leaseId] || (res.length && (res as ReleaseLeaseResult[]).find((f: any) => f[r.leaseId])))) {
      const licenseIds = Object.keys(result);
      licenseIds.forEach((lid) => {
        if (result[lid] && result[lid].users?.length) {
          result[lid].users = result[lid].users?.map((u, i) => {
            let reduceTakenCount = 0;
            const assignments = u.assignments?.map((f) => {
              if (r.removeAssignmentId && f.id === r.removeAssignmentId) {
                // assignment with session and lease removed, update taken seat count
                reduceTakenCount += 1;
                return undefined;
              }
              const sessions = f.sessions?.map((s) => {
                // remove matching lease
                const leases = s.leases?.filter((l) => l.id !== r.leaseId);
                // if more leases left, return session with updated leases, else remove session
                if (leases && leases.length) {
                  return {
                    ...s,
                    leases
                  };
                } else {
                  // session and lease removed, update taken seat count
                  reduceTakenCount += 1;
                  return undefined;
                }
              }).filter((filt) => !!filt);

              if (f.type === "reserved" && isValid(f)) {
                // reserved assignment continues to consume seat count, so we restore previously removed
                reduceTakenCount -= 1;
              }
              // update assignment
              return {
                ...f,
                sessions
              };
            }).filter((f) => !!f);
            // update user if more assignments, else remove

            if (reduceTakenCount > 0 && result[lid].seatsTaken) {
              result[lid].seatsTaken =
                  (result[lid].seatsTaken || 0) - reduceTakenCount;
            }

            return assignments && assignments.length ? {
              ...u,
              assignments,
            } : undefined;
          }).filter((f) => !!f) as LicenseUserWithAssignmentsAndSessions[];
        }
      })
    }
  });
  return result;
}
/**
 * Removes the removed users data from the licenses data
 * @param userIds
 * @param currentState
 */
function deleteUsers(
  userIds: string[] | undefined,
  currentState: LicensesById
): LicensesById {
  let result = currentState;
  if (userIds) {
    // loop through all licenses in state
    const lics = Object.keys(result);
    for (let i = 0; i < lics.length; i += 1) {
      // helper for calculating the updated reservation and taken counts so we do not need to do the loops twice
      let reduceReservationCount = 0;
      let reduceTakenCount = 0;

      if (result[lics[i]].users) {
        // loop through all the users and filter out the once that are removed
        result[lics[i]].users = result[lics[i]].users?.filter((u) => {
          // check if user is to be removed
          const t = userIds.find((f) => f === u.id);
          if (!!t) {
            // the user in question is removed, loop through all their assignments
            u.assignments?.forEach((a) => {
              // resolve all valid leases
              const leases = a.sessions
                ?.map((s) => s.leases?.filter((l) => isValid(l)))
                .flat(1);
              if (!leases || !leases.length) {
                // No valid leases found, reduce taken count
                reduceTakenCount += 1;
              }
              if (a.type === "reserved" && isValid(a)) {
                // a valid reservation was found, reduce reservation count.
                reduceReservationCount += 1;
              }
            });
          }
          //
          return !t;
        });
      }
      // Update the reserved count
      if (reduceReservationCount && result[lics[i]].seatsReserved) {
        result[lics[i]].seatsReserved =
          (result[lics[i]].seatsReserved || 0) - reduceReservationCount;
      }
      // Update the taken count
      if (reduceTakenCount && result[lics[i]].seatsTaken) {
        result[lics[i]].seatsTaken =
          (result[lics[i]].seatsTaken || 0) - reduceTakenCount;
      }
    }
  }
  return result;
}

function deleteLicense(
  licenseId: string,
  currentState: LicensesById
): LicensesById {
  const { [licenseId]: _, ...remaining } = currentState;
  return remaining;
}

function handleErrorAction(
  currentState: LicensesById,
  action: ActionTypes.AppAction
): LicensesById {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  const type = errorAction.error.action.type;

  if (
    type === ActionTypes.QUERY_LICENSE_USAGE ||
      type === ActionTypes.GET_USER_LICENSE_ASSIGNMENTS
  ) {
    if (errorAction.error.apiError.error === "404") {
      const typedError =
        action as ActionTypes.AddErrorAction<ActionTypes.QueryLicenseUsageAction>;
      finalState = deleteLicense(
        typedError.error.action?.licenseId as string,
        currentState
      );
    }
  }

  return finalState;
}
