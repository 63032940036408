import * as ActionTypes from "../actions/actionTypes";
import {GroupIdsByEntitlementId} from "../store/EntitlementState";


export default function entitlementGroupIds(
    state: GroupIdsByEntitlementId,
    action: ActionTypes.AppAction
): GroupIdsByEntitlementId | null {
  const currentState = state || ({} as GroupIdsByEntitlementId);

  switch (action.type) {

    case ActionTypes.LIST_LICENSE_CONSUMING_ORG_GROUPS: {
      const actionResult = action as ActionTypes.ListLicenseConsumingOrgGroupsAction;
      return {
        ...currentState,
        [actionResult.entId]: actionResult.groups.map((g) => g.id as string)
      };
    }
    case ActionTypes.ADD_LICENSE_CONSUMING_ORG_GROUP: {
      const actionResult = action as ActionTypes.AddLicenseConsumingOrgGroupAction;

      let groupIdsOfEntitlement = currentState[actionResult.entId] || [];
      let afterAdd = groupIdsOfEntitlement.filter(
          (grpId) => grpId !== actionResult.groupId
      );
      afterAdd.push(actionResult.groupId);

      return {
        ...currentState,
        [actionResult.entId]: afterAdd
      };
    }
    case ActionTypes.REMOVE_LICENSE_CONSUMING_ORG_GROUP: {
      const actionResult = action as ActionTypes.RemoveLicenseConsumingOrgGroupAction;

      const beforeDelete = currentState[actionResult.entId] || [];
      const afterDelete = beforeDelete.filter(
          (grpId) => grpId !== actionResult.groupId
      );
      return {
        ...currentState,
        [actionResult.entId]: afterDelete
      };
    }
    case ActionTypes.START_AUTHN:
      return null;
    default:
      return state || null;
  }
}
