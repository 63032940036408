import { kebabCase } from "change-case";

/**
 * Creates a paramsCase version of each collection, to ease comparison of keys with formatting differences
 * @param obj
 */
function populateParamsCaseVersions(obj: {
  [collection: string]: { [key: string]: string };
}) {
  const keys = Object.keys(obj);
  for (let i = 0; i < keys.length; i += 1) {
    const collection = obj[keys[i]];
    const paramsCaseCollection: any = {};
    const collectionKeys = Object.keys(collection);
    for (let j = 0; j < collectionKeys.length; j += 1) {
      paramsCaseCollection[kebabCase(collectionKeys[j])] =
        collection[collectionKeys[j]];
    }
    obj[keys[i] + "-params-case"] = paramsCaseCollection;
  }
  return obj;
}

/**
 * Collections of data key mappings.
 */
export const USER_DATA_MAPPINGS: {
  [collection: string]: { [key: string]: string };
} = populateParamsCaseVersions({
  Google: {
    Name: "displayName",
    "Given Name": "firstName",
    "Family Name": "lastName",
    Nickname: "nickname",
    "E-mail 1 - Value": "email",
    "Organization 1 - Title": "professionalTitle",
    // 'Photo': 'picture',
    "Phone 1 - Value": "phoneNumber"
  },
  Outlook: {
    "First Name": "firstName",
    "Last Name": "lastName",
    "E-mail Address": "email",
    "Job Title": "professionalTitle",
    "Primary Phone": "phoneNumber",
    "Home Phone": "phoneNumber",
    "Home Phone 2": "phoneNumber",
    "Mobile Phone": "phoneNumber",
    "Other Phone": "phoneNumber"
  }
});

/**
 displayName?: string;
 email: string;
 professionalTitle?: string;
 nickname?: string;
 lastName: string;
 firstName: string;
 phoneNumber?: string;
 */

export const REQUIRED_USER_FIELDS = ["email", "lastName", "firstName"];
export const OPTIONAL_USER_FIELDS = [
  "displayName",
  "professionalTitle",
  "nickname",
  // "picture",
  "phoneNumber"
  //"naturalId"
  // "lastSignOn",
];
export const ALL_USER_FIELDS = REQUIRED_USER_FIELDS.concat(
  OPTIONAL_USER_FIELDS
);

export interface CSVData {
  [key: string]: any;
}
