import * as ActionTypes from "../actions/actionTypes";
import { OrgGroupIdsByOrgId } from "../store/OrganizationGroupState";
import {OrgClientIdsByOrgId} from "../store/OrganizationState";

export default function orgClientIdsReducer(
  state: OrgClientIdsByOrgId,
  action: ActionTypes.AppAction
): OrgGroupIdsByOrgId | null {
  const currentState = state || ({} as OrgClientIdsByOrgId);
  switch (action.type) {
    case ActionTypes.LIST_ORG_CLIENTS:
      const listOrgClients = action as ActionTypes.ListOrgClientsAction;
      const newOrgClientIds = listOrgClients.clients.map(
        client => client.id as string
      );
      return { ...currentState, [listOrgClients.orgId]: newOrgClientIds };
    case ActionTypes.DELETE_ORG_CLIENT:
      const deleteClient = action as ActionTypes.DeleteOrgClientAction;
      const beforeDelete = currentState[deleteClient.orgId] || [];
      const afterDelete = beforeDelete.filter(clId => clId !== deleteClient.clientId);
      return {
        ...currentState,
        [deleteClient.orgId]: afterDelete
      };
    case ActionTypes.ADD_ERROR:
      return handleErrorAction(currentState, action);
    case ActionTypes.START_AUTHN:
    case ActionTypes.SET_LOGOUT_COMPLETED:
      return null;
    default:
      return state || null;
  }
}

function handleErrorAction(
  currentState: OrgClientIdsByOrgId,
  action: ActionTypes.AppAction
): OrgClientIdsByOrgId {
  let finalState = currentState;

  const errorAction = action as ActionTypes.AddErrorAction<any>;

  if (
    !errorAction.error ||
    !errorAction.error.action ||
    !errorAction.error.apiError
  ) {
    return finalState;
  }

  if (
    errorAction.error.apiError.error === "404" &&
    errorAction.error.action.type === ActionTypes.GET_ORG_CLIENT
  ) {
    const typedError = action as ActionTypes.AddErrorAction<
      ActionTypes.GetOrgClientAction
    >;

    const beforeDelete = currentState[typedError.error.action?.orgId as string] || [];
    const afterDelete = beforeDelete.filter(clId => clId !== typedError.error.action?.clientId as string);
    finalState = {
      ...currentState,
      [typedError.error.action?.orgId as string]: afterDelete
    };
  }


  return finalState;
}
