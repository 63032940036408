import { useContext, useEffect, HTMLAttributes } from "react";
import { useIntl } from "react-intl";
import { SummaryCard, IconLibrary, FieldList, TooltipWrapper } from "@10duke/dukeui";
import { Link } from "react-router-dom";
import { ModalKeys } from "../../pages/device-clients";
import { ButtonGroup } from "react-bootstrap";
import { sortToLatestByCreatedOrUpdated } from "../../../util/objectUtil";
import UIConfiguration from "../../../ui-configuration/configuration-provider";
import {Client} from "../../../model/Client";
import {hasAction} from "../../../ui-configuration/configuration-tools";


interface ClientsCardDOMProps
  extends Omit<HTMLAttributes<HTMLDivElement>, "title"> {}
export interface ClientsCardProps extends ClientsCardDOMProps {
  isReady: boolean;
  clients?: Client[] | null;
  onLoadClients?: () => void;

  moreAction: () => void;
}

function ClientsCard(props: ClientsCardProps) {
  const { clients, onLoadClients, moreAction, isReady } = props;
  const intl = useIntl();
  const { conf } = useContext(UIConfiguration);
  const clientsConf =
    conf.functionality && conf.functionality["device-clients"]
      ? conf.functionality["device-clients"]
      : {};
  const headerButton = {
    label: intl.formatMessage({
      defaultMessage: "View",
      description: "View button label",
    }),
    action: moreAction,
  };
  if (!onLoadClients && clients === undefined) {
    throw new Error(
      "ClientsCard: Required props missing. Either clients or onLoadClients must be defined"
    );
  }
  const isClientsUndefined = clients === undefined;
  useEffect(() => {
    if (isClientsUndefined && onLoadClients) {
      onLoadClients();
    }
  }, [isClientsUndefined, onLoadClients]);
  return (
    <SummaryCard
      onReloadData={() => {
        if (onLoadClients) {
          onLoadClients();
        }
      }}
      isReady={isReady}
      data-test-cliens-card
      title={intl.formatMessage({
        defaultMessage: "Clients",
        description: "heading for the card",
      })}
      headerButton={headerButton}
      items={
        clients && clients.length
          ? clients.sort(sortToLatestByCreatedOrUpdated).map((g) => {
              const Wrapper = hasAction(clientsConf.rowActions, 'show') ? Link : "span";
              const wrapperProps: any = {
                className: "item-copy",
              };
              if (hasAction(clientsConf.rowActions, 'show')) {
                wrapperProps.to =
                  (("/device-clients/" + g.id) as string) + "/" + ModalKeys.show;
              }
              return (
                <>
                  <Wrapper {...wrapperProps}>
                    <TooltipWrapper
                      tipKey={"client_" + g.id}
                      tip={g.name}
                      placement={"top"}
                    >
                      <span className={"label"}>
                        {g.name}
                      </span>
                    </TooltipWrapper>
                  </Wrapper>
                  {(hasAction(clientsConf.rowActions, 'groups') || hasAction(clientsConf.rowActions, 'licenses')) && (
                    <ButtonGroup size={"sm"}>
                      {hasAction(clientsConf.rowActions, 'groups') && (
                        <Link
                          className={"btn btn-secondary custom-base"}
                          to={
                            (("/device-clients/" + g.id) as string) +
                            "/" +
                            ModalKeys.groups
                          }
                        >
                          {intl.formatMessage({
                            defaultMessage: "Groups",
                            description: "label for the device client groups button",
                          })}
                        </Link>
                      )}
                      {hasAction(clientsConf.rowActions, 'licenses') && (
                        <Link
                          className={"btn btn-secondary custom-base"}
                          to={
                            (("/device-clients/" + g.id) as string) +
                            "/" +
                            ModalKeys.licenses
                          }
                        >
                          {intl.formatMessage({
                            defaultMessage: "Licenses",
                            description: "label for the licenses button",
                          })}
                        </Link>
                      )}
                    </ButtonGroup>
                  )}
                </>
              );
            })
          : undefined
      }
      footer={
        <FieldList
          inline={true}
          fields={{
            total: {
              label: intl.formatMessage(SummaryCard.TotalLabel),
              value: clients ? clients.length : 0,
              itemClass: "total",
            },
          }}
        />
      }
    >
      {clients && clients.length === 0 && (
        <div data-test-no-items className={"no-data"}>
          <div className={"icon"}>{IconLibrary.customIcons.noData}</div>
          <p>
            {intl.formatMessage({
              defaultMessage: "No device clients to show.",
              description: "display value for no device clients",
            })}
          </p>
        </div>
      )}
    </SummaryCard>
  );
}
export default ClientsCard;

